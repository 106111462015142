import React ,{useEffect, useRef, useState}from 'react'
import { useForm } from "react-hook-form";
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector } from 'react-redux';
import { SSVDashboardAPI, managEngineer } from '../../../../common/config';
import { FetchSingleRecordAction, TierListReducerAction, addUserAction } from '../../../../store/actions/createTicketManageFields';
import { dateObjectFull } from '../../../../common/variables';
import SSVDashboard from '../../Rf Performance/ssvDashboard';
const moment = require('moment');
const SSVEngineer = (props) => {
    const styles = {
        width: "400px",
    
      };
      let count =useRef(1)
           
      const [subDate,setSubDate]=useState("")
      const [ssvSubmitDate,setSSVSubmitDate]=useState("")
      const [ssvApprovalDate,setSSVApprovalDate]=useState("")
      const [issueBox,setIssueBox]=useState(false)

      const { register, getValue, setValue, handleSubmit, setError, formState: { errors } } = useForm()
      const option =useSelector((state)=>{
        console.log("jhvhjvbkljzbxcuyfdcusdv=",state)
        let fetchData=state.OneCommonResponse.singleIXSite 
        console.log("kbhjb sdfcsdfedfe=",fetchData)
        if(fetchData && Object.keys(fetchData).length>0){
            if(count.current==1){
            if(fetchData.ssvSubmitDate){
              setSSVSubmitDate(new Date(fetchData.ssvSubmitDate))
              }
            if(fetchData.SSVApprovalDate){
              setSSVApprovalDate(new Date(fetchData.SSVApprovalDate))
              }
            setValue("ssvEngineer",fetchData.ssvEngineer)
            setValue("CommentsNNP&SSS",fetchData["CommentsNNP&SSS"])
            setValue("SSSCOPStatus",fetchData.SSSCOPStatus)
            setValue("ssvStatus",fetchData.ssvStatus)
            setValue("issueRemarks",fetchData.ssvStatus)
            count.current =2
        }
        }

      })
      const findengineer = (id) => {
        setEngineerOpen(id)
        // console.log(id,"id")
        // if (id != undefined) {
    
        //   let idObject = engineerArray.filter((item) => {
        //     item.name == id
        //   })
        //   console.log(idObject,"idObject")
        //   // setValue("engineerOpeningtt",idObject)
    
        // }
      }
      let engineerArray = []
      const dispatch=useDispatch()
      const projectData  =useSelector((state)=>{
        let tier = state.CommonResponse.listtier
        if (tier && tier.length != 0) {
          engineerArray = tier.map((item) => {
            return <option>{item.name}</option>;
          })
        }
        return{engineerArray}
      })
      const [EngineerOpen, setEngineerOpen] = useState([]);

    const handleSelect=(e)=>{
      console.log("dfvsdvgsdrgvrw=",e.target.value)
      if(e.target.value=="Submitted With Issue Pending"){
        setIssueBox(true)
      }
      else{
        if(issueBox==true){
          setIssueBox(false)
        }
      }
    } 
    const  onSubmit=(data)=>{
        data["ssvSubmitDate"]= moment(ssvSubmitDate).format("MM/DD/YYYY")
        data["SSVApprovalDate"]=moment(ssvApprovalDate).format("MM/DD/YYYY")
        
        console.log("the data is =",data)
        dispatch(addUserAction(SSVDashboardAPI+"/"+props.id,data))

    }

    useEffect(()=>{
      dispatch(TierListReducerAction(managEngineer))
      dispatch(FetchSingleRecordAction(SSVDashboardAPI,props.id))
    },[])
  return (
    <form className='form-row' onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group col-6">
            <label>SSV Engineer</label>
            
            <input style={{ height: "32px" }}
                            type="text"
                            {...register("ssvEngineer", {
                              required: "Please select Engineer Support E911"
                            })}
                            onBlur={(e) => { findengineer(e.target.value) }}
                            list="engOpen"
                            className="form-control"
                            ID="inputAddress"
                            defaultValue={EngineerOpen}
                            placeholder="Select Engineer"
                          />

                          <datalist id="engOpen">
                            {projectData.engineerArray}
                          </datalist>
                          {errors.engineerSupportingenno && (
                            <p className="error">
                              {errors.engineerSupportingenno.message}
                            </p>
                          )}
                        </div>
        <div className="form-group col-6">
            <label>SSV Submit Date</label>
      
            <DatePicker
                          style={styles}
                          className="form-control"
                          selected={ssvSubmitDate}
                          onChange={(e) => { setSSVSubmitDate(e); }}
                          // showTimeSelect
                          popperPlacement="bottom"
                          // excludeOutOfBoundsTimes
                        //   timeFormat="HH:mm"
                          // timeIntervals={60}
                          // timeCaption="Time"
                          dateFormat="MM/dd/yyyy"
                          // minDate={new Date()}
                        //   maxDate={addDays(new Date(), 31)}
                        />
        </div>
        <div className="form-group col-6">
            <label>SSV Approval Date</label>
            <DatePicker
                          style={styles}
                          className="form-control"
                          selected={ssvApprovalDate}
                          onChange={(e) => { setSSVApprovalDate(e); }}
                          // showTimeSelect
                          popperPlacement="bottom"
                          // excludeOutOfBoundsTimes
                          // timeFormat="HH:mm"
                          // timeIntervals={null}
                          // timeCaption="Time"
                          dateFormat="MM/dd/yyyy"
                          // minDate={new Date()}
                        //   maxDate={addDays(new Date(), 31)}
                        />
        </div>
        <div className="form-group col-6">
            <label>SSV Status</label>
            <select className='form-group'
            // onChange={handleSelect}
            {
                ...register("ssvStatus",{
                  onChange:(e)=>{handleSelect(e)}
                })
            } >
                <option selected disabled value={''}>Select</option>
                <option value={"Pending"}>Pending</option>
                <option value={"Submitted"}>Submitted</option>
                <option value={"Submitted With Issue Pending"}>Submitted With Issue Pending</option>
                <option value={"Approved"}>Approved</option>
                <option value={"Assign To Other Vender"}>Assign To Other Vender</option>
                <option value={"Drive Not Done"}>Drive Not Done</option>
                </select>
            </div>
            
            <div style={{display:issueBox?"block":"none"}} className="form-group col-6">
              <label>Issue Remarks</label>
              <input className='form-group'
              {
                ...register("issueRemarks")
              }
              ></input>
            </div>
            <div className="d-flex justify-content-center col-12">
                <button type='submit' className="btn btn-primary btn-xs py-2 px-4 mt-2">Submit</button>
        </div>
    </form>
  )
}

export default SSVEngineer