import React, { useRef,useState } from 'react'
import MUIDataTable from "mui-datatables";
import { useEffect } from 'react';
import { Row, Card, Col,Dropdown, Button, Modal, Container } from "react-bootstrap";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { accountApi, addAcccount, deleteAcccount } from '../../../../common/config';
import swal from 'sweetalert';
import { advancdExportOnecustomDataTableOptionObject, customDataTableOptionObject, getMuiThemeCommon } from "../../../../common/variables";
import { AccountReducerAction,OneAccountReducerAction, addAcccountReducerAction,deleteAcccountReducerAction, popUpUpdate, updateAcccountReducerAction } from '../../../../store/actions/createTicketManageFields';
import { useDispatch,useSelector } from 'react-redux';
const ManageAccount = () => {
    const svg1 = (
      <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect x="0" y="0" width="24" height="24"></rect>
          <circle fill="#000000" cx="5" cy="12" r="2"></circle>
          <circle fill="#000000" cx="12" cy="12" r="2"></circle>
          <circle fill="#000000" cx="19" cy="12" r="2"></circle>
        </g>
      </svg>
    );
    let row = [];
   let objectId=''
   const [modalCentered, setModalCentered] = useState(false);
   const [errors, setErrors] = useState({});
   const [dValue,setDvalue] =useState('')
   const [rowId,setRowId]=useState('')
   const [checkAddUpdate,setAddUpdate] =useState(true)
   const [closer,setCloser] =useState(true)
   
  //  const [newAccount,setAccount]=useState('')
      const newAccount=useRef()
   let oneaccount=""
   
   
   // setAccount(oneaccount)
   console.log(oneaccount,"oneaccount")
    const getMuiTheme = () => createTheme({
      components: {
        MUIDataTableBodyCell: {
          styleOverrides:{
            root: {
                backgroundColor: "transparent"
            }
          }
        }
      }
    })
    const updateHandler =(e)=>{
        console.log("button update clicked",e)

        dispatch(updateAcccountReducerAction(addAcccount,e,{accountName:newAccount}))

    }
    const deleteHandler =(e)=>{
      console.log("button delete clicked",e)

      swal({
        title: "Are you sure?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if(willDelete){
          dispatch(deleteAcccountReducerAction(deleteAcccount,e))
          setCloser(true)
        }
        });
      
    }
    

    const editpopupHandler =(e)=>{
     console.log("lkm kljnkjnjkbi;ki=",e)
      console.log("button editpopupHandler clicked",e)
      // dispatch(OneAccountReducerAction(accountApi+"/"+e))
      setErrors({})
      setModalCentered(true)
      // dispatch(deleteAcccountReducerAction(deleteAcccount,e))
    }
    const updateAccount =()=>{
      console.log("object id =",rowId)
      if(newAccount.current.value==""){
        setErrors({"account":"Account is Manodatry"})
        return false
      }
      dispatch(updateAcccountReducerAction(addAcccount,rowId,{accountName:newAccount.current.value}))
      setCloser(true)
    }
    const addAccount=()=>{
        if(newAccount.current.value==""){
          setErrors({"account":"Account is Manodatry"})
          return false
        }
        dispatch(addAcccountReducerAction(addAcccount,{accountName:newAccount.current.value}))
        setCloser(true)
        console.log("input field=",newAccount)
    }
    const dispatch =useDispatch();
    let accountArry =[]

    
    // let datava =useSelector((state)=>{

    // });
    
    let data =useSelector((state)=>{
        console.log("dfwef=",state)

        if (state.popUpState) {
          console.log(state.popUpState.popstatus.status,closer,"popUpStatestatus")
          if ((state.popUpState.popstatus.status == 201 && closer)||(state.popUpState.popstatus.status == 200 && closer)) {
            setCloser(false)
            dispatch(popUpUpdate({}))
            dispatch(AccountReducerAction(accountApi))
            setModalCentered(false)
            // newAccount.current.value=''
            setDvalue('')
            
          }
        }
        let neeaccount=state.CommonResponse.accounts
        if(neeaccount.length !=0 && newAccount==""){
          oneaccount=neeaccount[0].accountName
          
        }
        
        let account=state.CommonResponse.accounts
        console.log("response =dscvfsfvd=",state.CommonResponse)
        if(account.length !=0){
          accountArry=account.map((item,index)=>{
            return {
                    sNo:index+1,
                    account :item.accountName,
                    edit   :<><button className="btn btn-primary shadow btn-xs sharp mr-1 editButton" onClick={() =>{ editpopupHandler(item); setDvalue(item.accountName);setAddUpdate(false);setRowId(item.uniqueid)}}><i className="fa fa-pencil"></i></button>
                    <button onClick={(e)=>deleteHandler(item.uniqueid)} className="btn btn-danger shadow btn-xs sharp deleteButton"><i className="fa fa-trash"></i></button></>
                  //   <Dropdown>
                    
                  //   <Dropdown.Toggle
                  //     variant="success"
                  //     className="light sharp i-false"
                  //   >
                  //     {svg1}
                  //   </Dropdown.Toggle>
                  //   <Dropdown.Menu>
                    
                  //     <Dropdown.Item onClick={() =>{ editpopupHandler(item); setDvalue(item.accountName);setAddUpdate(false);setRowId(item.uniqueid)}}>Edit</Dropdown.Item>
                  //     <Dropdown.Item onClick={(e)=>deleteHandler(item.uniqueid)}>Delete</Dropdown.Item>
                  //   </Dropdown.Menu>
                  // </Dropdown>
                    // <button value={item.uniqueid} onClick={(e)=>updateHandler(e)}>Click me</button>
                    
                }
          })
        }
        
      
        console.log(accountArry)
      
      return {accountArry}
      })

      const noOfRow=data.accountArry.length
      // data.accountArry.push(
      //   {   
      //       sNo:noOfRow+1,
      //       account:<input type='text' placeholder='Add Account' value={newAccount} onChange={(e)=>setAccount(e.target.value)}/>,
      //       edit : <button onClick={()=>addAccount()}>Add Account</button>
            
      //   }
      // )

    console.log("data =",data)
    useEffect(()=>{
        dispatch(AccountReducerAction(accountApi))
    },[newAccount])
    const columns = [
        {
      
         name: "sNo",
         label: "S.no",
         options: {
          filter: false,
          sort: false,
          searchable:false,
          setCellHeaderProps: () => ({
            style: {
                whiteSpace: "wrap",
                width:"130px",
                alignItem:"center",
              background:"#143b64",
              color:"white"
  
            }
          })
         }
        },
        {
         name: "account",
         label: "Account",
         options: {
          filter: false,
          setCellHeaderProps: () => ({
            style: {
                whiteSpace: "wrap",
                alignItem:"center",
              background:"#143b64",
              color:"white"
  
            }
          })
         }
        },
        {
         name: "edit",
         label: "Action",
         options: {
          filter: false,
          sort: false,
          print:false,
          searchable:false,
          setCellHeaderProps: () => ({
            style: {
                whiteSpace: "wrap",
                alignItem:"center",
                width:"130px",
                background:"#143b64",
                color:"white"
  
            }
          })
         }
        },
        
       ];
       
       const handleChange = (e) => {
        console.log("the current anser= ", e.target.value)
      }
    
      const AddCustomButton = () => (
        <fregment style={{ float: "right", display: "flex", flexDirection: row, justifyContent: "space-between" }}>
          <Button
          variant="primary btn-xs  "
          type="button"
          className="btn btn-primary btn-xs mx-2 py-2 my-3"
          onClick={() =>{ setModalCentered(true);setDvalue('') ;setErrors({});setAddUpdate(true)}}>
            Add Account
          </Button>
          <Modal className="fade" show={modalCentered}>
            <Modal.Header>
            {checkAddUpdate?<Modal.Title>Add Account</Modal.Title>:<Modal.Title>Update Record</Modal.Title>}
                <Button
                  onClick={() => setModalCentered(false)}
                  variant=""
                  className="close">
                <span>&times;</span>
                </Button>
                </Modal.Header>
                <Modal.Body>
                  <input type='text' placeholder='Add Account' required ref={newAccount} defaultValue={dValue}/>

                  {errors && errors.account ? <p className="error">{errors.account}</p> : ""}
                </Modal.Body>
                <Modal.Footer>
                  <Button
                  onClick={() => setModalCentered(false)}
                  variant="danger btn-xs"
                >
                  Close
                </Button>
                {checkAddUpdate?<Button onClick={()=>{addAccount()}} variant="primary btn-xs">Save changes</Button>
                  :<Button onClick={()=>{updateAccount()}} variant="primary btn-xs">Update changes</Button>  
              }
                </Modal.Footer>
            </Modal>
              
        </fregment>
      )
    //    const data = [
    //     { name: "Joe James", company: "In Progress", city: "Yonkers", state: "NY" },
    //     { name: "John Walsh", company: "Completed", city: "Hartford", state: "CT" },
    //     { name: "Bob Herm", company: "Awaited", city: "Tampa", state: "FL" },
    //     { name: "James Houston", company: "NA", city: "Dallas", state: "TX" },
    //    ];
       
       const options = {
        download:"false",
         filterType: 'none',
         selectableRows: false
       };
       const optios = {
        filterType: "dropdown",
        responsive: "scroll"
      };
  return (
    <>
       
        
        

		  <ThemeProvider theme={getMuiThemeCommon()}>

        <MUIDataTable
        
          title={"Manage Account"}
          data={data.accountArry}
          columns={columns}
          options={advancdExportOnecustomDataTableOptionObject(AddCustomButton,true,"Account_","",false,"Account Name",false)}
          // options={optios}
        />
      </ThemeProvider>
    </>
  )
}

export default ManageAccount