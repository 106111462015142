import React, { useEffect, useState, useRef } from "react";
import { Button, Dropdown } from "react-bootstrap";
import "../../../css/customStyle.css";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import Modal from "react-bootstrap/Modal";
import Tab from "react-bootstrap/Tab";
import GradingSharpIcon from "@mui/icons-material/GradingSharp";
import ClearIcon from "@mui/icons-material/Clear";

import RefreshIcon from "@mui/icons-material/Refresh";
import PlaceIcon from "@mui/icons-material/Place";
import Tabs from "react-bootstrap/Tabs";
import Tooltip from "@material-ui/core/Tooltip";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Row, Col, Card, Nav } from "react-bootstrap";
import RanSiteTracker from "../ProjectTrackerComponent/RanSiteTracker";
import MUIDataTable from "mui-datatables";

import { useSelector, useDispatch } from "react-redux";
import {
  NetworkDatabaseAction,
  CSVUploadAction,
  PDFUploadAction,
  MopDatabaseAction,
  PDFUploadActionreturn,
} from "../../../store/actions/dashboardActions";
import { Link, useHistory } from "react-router-dom";
import { actionTypes } from "redux-form";
import {
  AccountReducerAction,
  addAcccountReducerAction,
  allMapSiteReducerAction,
  clearFetchSingleRecordAction,
  deleteDataUsingFormData,
  ExportDownloadReducerAction,
  ExportXLSXDownloadReducerAction,
  FetchSingleRecordAction,
  FetechSingleMops,
  getFilterDropDown,
  getMOPInstructionAction,
  GetTimeLineAction,
  LCLmopDashboardReducerAction,
  popUpUpdate,
  ResetLCLmopDashboardReducerAction,
} from "../../../store/actions/createTicketManageFields";
import {
  bulkUpload,
  listSiteId,
  mopDataApi,
  multiRFDS,
  CsvUpload,
  networkDashbordSiteHistory,
  projectCode,
  singleProjectApi,
  timelineApi,
  siteDblistSiteId,
  allMapSites,
  mopDashSiteStatus,
  baseURL,
  export_mop,
  mopFilters,
  MOPInstructionApi,
  getDownTImeParsed,
} from "../../../common/config";
import SingleProject from "../ProjectModule/SingleProject";
import ProjectData from "../ReusableComponents/ProjectData";
import RanHistorical from "../ReusableComponents/RanHistorical";
import { UploadFile } from "@mui/icons-material";
import CustomeModal from "../Custom/CustomeModal";
import SiteApprovalModal from "../Custom/Modal Body/SiteApprovalModal";
import { DateRange } from "react-date-range";
import { exportToCSV, ExportToExcel } from "../../../common/ExportToExcel";
import swal from "sweetalert";
import Map from "../../../icons/Svg/Map";
import MapView from "../Custom/Modal Body/MapView";
import Delete from "../../../icons/Svg/Delete";
import "./style.css";
import { useForm } from "react-hook-form";
import moment from "moment";
import { commonzone, format24Hour, fun_time_zonechanger, getdatedatalistnext, getMuiThemeCommon, more_info, smart_more_info } from "../../../common/variables";
import DatePicker from "react-datepicker";
import { useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import BulkApproveModal from "../Custom/Modal Body/BulkApproveModal";
import { style } from "@mui/system/Stack/createStack";
import { TableFooter } from "material-ui";
import CommonModal from "../Custom/CommonModal";
import { getMuiTheme } from "material-ui/styles";
import TableComponent from "../../../common/TableComponent";
import RFSiteStatus from "../Custom/Modal Body/RFSiteStatus";
import Add from "../../../icons/Svg/Add";
import GoNoGoModal from "../Custom/Modal Body/GoNoGoModal";
import '../../../css/muiDatatable.css';
import FileSaver from "file-saver";
import AdvMapView from "../Custom/Modal Body/AdvMapView";
import AdvMapViewLoad from "../Custom/Modal Body/AdvMapViewLoad";
import MopFilterList from "../Custom/Modal Body/MopFilterList";
import FilterData from "../../../icons/Svg/FilterData";
import UpdatedSiteApprovalModal from "../Custom/Modal Body/UpdatedSiteApprovalModal";
import ManageProjectDetail from "../Custom/Modal Body/ManageProjectDetail";
import MopManageProjectDetail from "../Custom/Modal Body/MopManageProjectDetail";
const Mop = () => {
  const colors = {
    "Post-check Ongoing": "postCheckOngoing",
    "pending": "pending-color pendingBg",
    "Post-Check Completed - Validation ongoing": "badge-success",
    "Post-Check Completed": "badge-success",
    "Cancelled": "badge-danger",
    "Completed": "badge-success",
    "Ongoing": "inProgress",
    "No Show": "noShow"
  }
  const colorCodeObj = {
    "Login Awaited - No Show": "loginAwaitedNoShow",
    "Scheduled": "Scheduled",
    "In Progress": "inProgress",
    "In Progress - Hold": "inProgressHold",
    "Completed": "Completed",
    "pending": "pending-color pendingBg",
  }
  let siteworkCondition = ["Login Awaited - CR Approved", "Pending"];
  let assignWorking = ["Post-Check Completed"];
  let siteStatusColor = {
    Approved: "",
    Rejected: "badge-danger",
    Pending: "pending-color",
  };

  // let filteredData=[]
  const location = useLocation();
  const history = useHistory();
  const [filterModal, setFilterModal] = useState(false)
  const [headerTitle, setHeaderTitle] = useState("Create MOP")
  const [accordianState, setAccordianState] = useState(false);
  const [totalTicketsinMop, settotalTicketsinMop] = useState(0);

  const [remarks, setRemarks] = useState();
  const [modalData, setModalData] = useState([]);

  const [mopInstructionsId, setmopInstructionsId] = useState("")
  const [mopInstructionsData, setmopInstructionsData] = useState([])
  const [slogsopernerData, setslogsopernerData] = useState()

  const [modalState, setModalState] = useState(false);
  const [siteUId, setSiteId] = useState();
  const [xlShow, setXlShow] = useState(false);
  const [xlsShow, setXlsShow] = useState(false);
  const [ranData, setRanData] = useState({});
  const [bRFDS, setBRFDS] = useState(false);
  const [uId, setUId] = useState("");
  const [closer, setCloser] = useState(false);
  const [popissue, setpopissue] = useState(false);
  const [filterBadges, setFilterBadge] = useState()
  const [modaluId, setmodalUId] = useState("");
  const [work, setWork] = useState("");
  const [fullUrL, setfullUrL] = useState("");
  const [BulkIdSelected, setBulkIdSelected] = useState([]);
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});

  // const [counterPP, setCountPP] = useState(20);
  const [exportApiData, setexportApiData] = useState("");
  const [bulkApproveModalState, setBulkApproveModal] = useState(false);
  const [commonModalState, setcommonModalState] = useState(false);
  const [commonModalSize, setcommonModalSize] = useState(false);
  const [commonModalBody, setcommonModalBody] = useState("");
  const [commonModalTitle, setcommonModalTitle] = useState("");
  const [commonModalHeader, setcommonModalHeader] = useState("");

  // let newStart=50/


  const [newStart, setnewStart] = useState(50);
  const [siteFile, uploadedFile] = useState([]);
  const [tempericon, settempericon] = useState("");
  const [tempertext, settempertext] = useState("");
  const [temperature, settemperature] = useState();
  const [fileshow, setfileShow] = useState(false);
  const [CsrArray, setCsrArray] = useState([]);
  const [modalHeading, setModalHeading] = useState("")
  const [CsrArraymsg0, setCsrArraymsg0] = useState("");
  const [CsrArraymsg1, setCsrArraymsg1] = useState("");
  const [CsrArraymsg2, setCsrArraymsg2] = useState("");
  const [showmodalfileshow, setshowmodalfileshow] = useState(false);
  const [showmodalweather, setshowmodalweather] = useState(false);
  let showpdfpopup = false;

  const [filterValue, setFilterValue] = useState({});
  const [counterPP, setCountPP] = useState(15);
  const [RowCount, setRowCount] = useState(0);

  const [currentPP, setcurrentPP] = useState(0);
  const [maxPage, setmaxpage] = useState(0);
  const [ResetFilter, setResetFilter] = useState(false);

  // //console.log(window.innerWidth, " ");

  const [datarow, setData] = React.useState();
  const [showDatePicker, setDatePicker] = useState(false);

  const [fstartdate, setfstartdate] = useState();
  const [fenddate, setfenddate] = useState();
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);
  const [mapModal, setMapModal] = useState(false);
  // const [wDetail,setWData]= useState()
  const [counter, setCount] = useState(1);
  const [test, setTest] = useState(false);

  let [reseter, setreseter] = useState(1);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  //console.log("hvgkuysgvakucysavuyerfcerg======", filterValue)
  const owneroperner = (name, email, mobile) => {
    return (
      <>
        <div >
          <table
            style={{ height: "auto", "max-height": "375px" }}
            className="table "
          >
            <tbody>
              <tr>
                <td
                  style={{
                    border: "2px solid black",
                    color: "white",
                    backgroundColor: "#143b64",
                    fontWeight: "bold",
                  }}
                >
                  Name
                </td>
                <td style={{ border: "2px solid black", color: "black" }}>
                  {name}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "2px solid black",
                    color: "white",
                    backgroundColor: "#143b64",
                    fontWeight: "bold",
                  }}
                >
                  Email
                </td>
                <td style={{ border: "2px solid black", color: "black" }}>
                  {email}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "2px solid black",
                    color: "white",
                    backgroundColor: "#143b64",
                    fontWeight: "bold",
                  }}
                >
                  Contact No
                </td>
                <td style={{ border: "2px solid black", color: "black" }}>
                  {mobile}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
    {
      /* <div class="modal-body">
          <p className="text-center text-primary">Name : {name}</p>

          <p className="text-center text-primary">Email : {email}</p>

          <p className="text-center text-primary">Contact no. : {mobile}</p>
        </div> */
    }
  };

  let badgecolor = {
    Pending: "pending-color",
    Approved: "badge-success",
    Rejected: "badge-danger",
  };

  // edit
  const owner = (email, contact) => { };
  const [filteredData, setfilteredData] = useState([])
  const [filliopen, setfilliopen] = useState(false)

  const [siteDetail, setSiteDetailInfo] = useState({
    "5GId": "",
    BASEPOs: "",
    CBNComment: "",
    CBNFile: "",
    CDDFile: "",
    CRPOs: "",
    CXTowerCrewName: "",
    IXFieldEngineerName: "",
    LTEId: "",
    RFDSPdfFile: "",
    RFDSPdfFile: "",
    RIOTFile: "",
    RNDCIQCsvFile: "",
    SranCabinets: "",
    UMTSId: "",
    account: "",
    cityState: "",
    clusterEngineerEmail: "",
    clusterEngineerName: "",
    clusterEngineerPhone: "",
    crCode: "",
    cranCabinets: "",
    dateOfAddition: "",
    dateTime: "",
    engineerAddingProject: "",
    equipment: "",
    fopsEngineerEmail: "",
    fopsEngineerName: "",
    fopsEngineerPhone: "",
    fopsManagerEmail: "",
    fopsManagerName: "",
    fopsManagerPhone: "",
    group: "",
    gsm: "",
    latitude: "",
    loginLogoutsHistory: "",
    longitute: "",
    market: "",
    mixedModeId: "",
    projectBucket: "",
    projectCode: "",
    projectStatus: "",
    region: "",
    rfEngineerEmail: "",
    rfEngineerName: "",
    rfEngineerPhone: "",
    siteAddress: "",
    siteDevelopmentManagerEmail: "",
    siteDevelopmentManagerName: "",
    masiteDevelopmentManagerPhonerket: "",
    siteId: "",
    siteType: "",
    tMobileMangerEmail: "",
    tMobileMangerName: "",
    tMobileMangerPhone: "",
    technology: "",
    troubleTickets: "",
    weekOfAddition: "",
  });
  const checkBoxRef = useRef();
  const setDateFilter = (e) => {
    e.preventDefault();
    dateRange[0].startDate = formatDate(dateRange[0].startDate);
    dateRange[0].endDate = formatDate(dateRange[0].endDate);
    console.log("the filtered data=", dateRange);
    dispatch(
      AccountReducerAction(mopDataApi + "?start=" + dateRange[0].startDate + "&end=" + dateRange[0].endDate, true)
    );
  };
  const selectedRow = (ar) => {
    let approvedId = [];
    for (let i = 0; i < ar.length; i++) {
      if (ar[i].checked == true) {
        // //console.log("the selected id s=", ar[i].value);
        approvedId.push(ar[i].value);
      }
    }
    return approvedId;
  };

  const CommonModalFun = (body, title, header, type = "md") => {
    setcommonModalState(true);
    setcommonModalSize(type);
    dispatch(popUpUpdate({}));

    dispatch(clearFetchSingleRecordAction())
    setCloser(true);
    setpopissue(true);
    setcommonModalTitle(title);
    setcommonModalBody(body);
    setcommonModalHeader(header);
  };

  const uncheckselectedRow = (ar) => {
    for (let i = 0; i < ar.length; i++) {
      if (ar[i].checked == true) {
        // //console.log("the selected id s=", ar[i].checked);
        ar[i].checked = false;
      }
    }
  };

  const BulkDelete = () => {
    let selectedCheck = document.querySelectorAll("#siteId");

    // //console.log(selectedCheck);
    // if(typeof(selectedCheck)==typeof([]) && selectedCheck.length>0)
    let deleteRow = selectedRow(selectedCheck);
    // //console.log(deleteRow);
    if (deleteRow.length == 0) {
      swal("Oops", "Please select atleast one record", "info");
    } else {
      swal({
        title: "Are you sure for bulk Delete ?",
        text: "Please click on ok for Bulk Delete",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          dispatch(
            addAcccountReducerAction(bulkUpload, {
              ticketId: deleteRow,
              status: "delete",
            })
          );
          swal("Bulk Approval completed successfully", {
            icon: "success",
          });
          uncheckselectedRow(selectedCheck);
        }
      });
    }
  };

  const BulkApproveFun = (role) => {
    let selectedCheck = document.querySelectorAll("#siteId");

    // //console.log(selectedCheck);
    // if(typeof(selectedCheck)==typeof([]) && selectedCheck.length>0)
    let appRow = selectedRow(selectedCheck);
    // //console.log(appRow);
    if (appRow.length == 0) {
      swal("Oops", "Please select atleast one record", "info");
    } else {
      swal({
        title: "Are you sure for bulk Approval ?",
        text: "Please click on ok for Bulk Approve",
        icon: "info",
        buttons: true,
        dangerMode: false,
      }).then((willDelete) => {
        if (willDelete) {
          const reqData = {
            ticketId: appRow,
            status: "Approved",
            department: role,
            reason: "Bulk Approve",
          };

          dispatch(addAcccountReducerAction(bulkUpload, reqData));

          swal("Bulk Approval completed successfully", {
            icon: "success",
          });
          uncheckselectedRow(selectedCheck);
        }
      });
    }
  };

  const BulkUpload = () => {
    let approvedId = [];
    let selectedCheck = document.querySelectorAll("#siteId");
    if (typeof selectedCheck == typeof [] && selectedCheck.length > 0)
      // selectedCheck.map((check)=>{
      //   // check.checked=true
      //   //console.log("the checked is =",check)
      // })

      for (let i = 0; i < selectedCheck.length; i++) {
        if (selectedCheck[i].checked == true) {
          //console.log("the selected id s=", selectedCheck[i].value);
          approvedId.push(selectedCheck[i].value);
        }
      }
    if (approvedId.length == 0) {
      swal("Oops", "Please select atleast one record", "info");
    } else {
      dispatch(popUpUpdate({}));
      setCloser(true);
      setpopissue(true);
      setBulkIdSelected(approvedId);

      //console.log(bulkApproveModalState, "1bulkApproveModalState");
      setBulkApproveModal(true);
      //console.log(bulkApproveModalState, "2bulkApproveModalState");
      // swal({
      //   title: "Are you sure?",
      //   text: "Please click on ok for Bulk Approval",
      //   icon: "warning",
      //   buttons: true,
      //   dangerMode: true,
      // }).then((willDelete) => {
      //   if (willDelete) {

      //     dispatch(
      //       addAcccountReducerAction(bulkUpload, {
      //         ticketId: approvedId,
      //         status: "approve",
      //       })
      //     );
      //     swal("Bulk Approval completed successfully", {
      //       icon: "success",
      //     });

      //     uncheckselectedRow(selectedCheck);
      //   }
      // });
    }
    uncheckselectedRow(selectedCheck);
  };

  const ClearFilter = () => {
    // alert("ClearFilter")
    //console.log("ClearFilter", "ClearFilter");
    setfstartdate();
    setfenddate();
    setResetFilter(true)
    setnewStart(50)
    let Filleddata = dispatch(ResetLCLmopDashboardReducerAction())
    console.log(Filleddata,"FilleddataFilleddata")
    dispatch(MopDatabaseAction(mopDataApi + Filleddata, true, true));
  };
  //console.log("mop odal viewer=", modalState);
  const BulkSelect = (id) => {
    // checkBoxRef.current
    //console.log("the item======", id);
    //  checkBoxRef.map((item)=>{

    //     item.current.checked=true
    //  })
    // setBSelect(!bselect);
    let selectedCheck = document.querySelectorAll("#siteId");
    //console.log("the item======", selectedCheck);
    if (typeof selectedCheck == typeof [] && selectedCheck.length > 0)
      // selectedCheck.map((check)=>{
      //   // check.checked=true
      //   //console.log("the checked is =",check)
      // })
      for (let i = 0; i < selectedCheck.length; i++) {
        selectedCheck[i].checked = id;

      }
  };

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  const fetechDetail = (id, work, headerDetail = "", timeCondition = false) => {

    if ((work == "rf" || work == "ops" || work == "dev") && timeCondition) {
      swal("Oops", "Activity End time finished", "info");
    } else {

      setHeaderTitle(headerDetail)
      setModalState(true);
      //console.log("clearFetchSingleRecordAction");
      dispatch(clearFetchSingleRecordAction());
      dispatch(FetechSingleMops([]));

      //console.log("clearFetchSingleRecordAction");
      setWork(work);
      setUId(id);
      setmodalUId("toewta");
      //console.log(work);
      // if (work != "Add") {
      //   dispatch(FetchSingleRecordAction(mopDataApi, id))
      // }

    }
  };
  //   setModalState(true);
  //   dispatch(clearFetchSingleRecordAction(""));
  //   setWork(work);
  //   setUId(id);
  //   if (work != "Add") {
  //     dispatch(FetchSingleRecordAction(mopDataApi, id));
  //   }
  // };
  const openMapModal = () => {
    setMapModal(true);
  };
  const fetechDetailnew = (id) => {
    setModalState(true);
    setUId(id);
    dispatch(FetchSingleRecordAction(singleProjectApi, id));
  };

  // let columns=[]
  let timeComparison = []
  let rowData = [];
  let excelrowData = [];
  let rowDataOne = [];
  let networkSiteStatus = [];
  let siteStatus = []
  let siteArray = [];
  let CsvArray = [];
  let userDetails = JSON.parse(window.localStorage.getItem("userDetails"));
  //console.log("the user details is=", userDetails);
  const handleFilterChange = (columnId, value) => {
    // Store the selected filter value
    setFilterValue((prevFilterValue) => ({
      ...prevFilterValue,
      [columnId]: value,
    }));
  };
  const weatheroptions = {
    method: "GET",
    headers: {
      "X-RapidAPI-Key": "3fcaddea4fmsh91b698b2db49f01p1ceac8jsne8c5a19de5f4",
      "X-RapidAPI-Host": "weatherapi-com.p.rapidapi.com",
    },
  };

  const weatheroperner = (lat, long, siteId, ticketId, date) => {
    let weather;
    let newdate = date.split("/");
    //console.log(lat, long, siteId, ticketId, date, "lat,long,date");
    fetch(
      `https://weatherapi-com.p.rapidapi.com/forecast.json?q=${lat},${long}&dt=${newdate[2] + "-" + newdate[0] + "-" + newdate[1]
      }`,
      weatheroptions
    )
      .then((response) => response.json())
      .then((response) => {
        //console.log(response, "response");
        let data = response;
        settempericon(data.icon);
        settempertext(data.text);
        let onedata = data.forecast;
        //console.log(
        //   onedata.forecastday.length,
        //   onedata.forecastday.date,
        //   "onedata.length"
        // );
        let datalog = (
          <>
            <div className="weather" style={{ color: "black", overflowY: "scroll", paddingBottom: "0.5rem" }}>
              <div className="row">
                <div className="col-md-6" style={{ height: "fit-content" }}>
                  Current Condition
                  <br />
                  <img src={data.current.condition.icon}></img>
                  {data.current.condition.text}
                  <br />
                  Humidity {data.current.humidity}
                  <br />
                  <span className="font-weight-bold">Location</span>
                  <br />
                  Country:- {data.location.country}
                  <br />
                  Localtime:-{" "}
                  {moment(new Date(data.location.localtime)).format(
                    "MM/DD/YYYY"
                  )}
                  <br />
                  Name:- {data.location.name}
                  <br />
                  Region:- {data.location.region}
                  <br />
                  Time Zone:- {data.location.tz_id}
                  <table border="0" className="showone">
                    <tr>
                      <td colSpan={4} className="text-center">
                        Temperature
                      </td>
                    </tr>
                    <tr>
                      <td>C&deg;</td>
                      <td>{data.current.temp_c}</td>
                      <td>F&deg;</td>
                      <td>{data.current.temp_f}</td>
                    </tr>
                    <tr>
                      <td colSpan={4} className="text-center">
                        Wind Details
                      </td>
                    </tr>
                    <tr>
                      <td>Wind Degree</td>
                      <td>{data.current.wind_degree}</td>
                      <td>Wind Dir</td>
                      <td>{data.current.wind_dir}</td>
                    </tr>
                    <tr>
                      <td>Wind Kph</td>
                      <td>{data.current.wind_kph}</td>
                      <td>Wind Mph</td>
                      <td>{data.current.wind_mph}</td>
                    </tr>
                  </table>
                </div>

                <div className="col-md-6" style={{ height: "fit-content" }}>

                  {onedata.forecastday ? (
                    onedata.forecastday.length > 0 ? (

                      moment(onedata.forecastday[0].date, "YYYY/MM/DD").isAfter(moment()) ?

                        <>
                          Upcoming Condition
                          <br />
                          Date :
                          {onedata.forecastday[0].date ? moment(onedata.forecastday[0].date, "YYYY/MM/DD").format("MM/DD/YYYY") : ""}
                          { }
                          <br />
                          <img
                            src={onedata.forecastday[0].day.condition.icon}
                          ></img>
                          {onedata.forecastday[0].day.condition.text}
                          <br />
                          <table border="0" className="showone">
                            <tr>
                              <td>Temperature</td>
                              <td>C&deg;</td>
                              <td>F&deg;</td>
                            </tr>
                            <tr>
                              <td>Min.</td>
                              <td>{onedata.forecastday[0].day.mintemp_c}</td>
                              <td>{onedata.forecastday[0].day.mintemp_f}</td>
                            </tr>
                            <tr>
                              <td>Avg.</td>
                              <td>{onedata.forecastday[0].day.avgtemp_c}</td>
                              <td>{onedata.forecastday[0].day.avgtemp_f}</td>
                            </tr>
                            <tr>
                              <td>Max.</td>
                              <td>{onedata.forecastday[0].day.maxtemp_c}</td>
                              <td>{onedata.forecastday[0].day.maxtemp_f}</td>
                            </tr>
                            <tr>
                              <td colSpan={3} className="text-center">
                                Wind Details
                              </td>
                            </tr>
                            <tr>
                              <td>Max Wind Kph</td>
                              <td colSpan={2}>{onedata.forecastday[0].day.maxwind_kph}</td>
                            </tr>
                            <tr>
                              <td>Max Wind Mph</td>
                              <td colSpan={2}>{onedata.forecastday[0].day.maxwind_mph}</td>
                            </tr>
                          </table>
                        </> : ""
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </>
        );
        settemperature(datalog);
        return weather.text;
      })
      .catch((err) => console.error(err));

    setshowmodalweather(true);
    setModalHeading("Weather Details for Ticket ID: " + ticketId + " (" + siteId + ")")

    //console.log(weather, "weather");
  };




  const slogsoperner = (resultlogs, mopInstructionsDatalist) => {

    //console.log("resultlogs", resultlogs);
    setModalHeading("Site Logs")
    let mopresultlogs = resultlogs["mopresultlogs"];
    //console.log(mopresultlogs,"__________mopresultlogs")
    let ardatalog = mopresultlogs?.map((it) => {
      return (
        <>
          <tr>
            <td style={{ border: "2px solid black" }}>
              {it.fopsstatus}
              {it.devstatus}
              {it.rfstatus}
            </td>
            <td style={{ border: "2px solid black" }}>
              {it.fopsreason}
              {it.devreason}
              {it.rfreason}
            </td>
            <td style={{ border: "2px solid black" }}>
              {it.lastUpdated_fops}
              {it.lastUpdated_dev}
              {it.lastUpdated_rf}
            </td>
            {/* <td style={{ border: "2px solid black" }}>{it.update_id}</td> */}
            <td style={{ border: "2px solid black" }}>{it.update_id || ""}</td>
            <td style={{ border: "2px solid black" }}>{it.approver_Profile}</td>
            <td style={{ border: "2px solid black" }}>{it.behalf_of}</td>

          </tr>
        </>
      );
    });

    let ardatalogtop = (
      <>
        <div
          className="table-responsive"
          style={{ color: "black", border: "0px solid black" }}
        >
          <table className="table "
            style={{ height: "auto" }}

          >
            <thead>
              <td style={{ width: "15%;", paddingTop: "11px", fontSize: "13px", height: "45px" }}>Status</td>
              <td style={{ width: "40%;", paddingTop: "11px", fontSize: "13px", height: "45px" }}>Reason</td>
              <td style={{ width: "15%;", paddingTop: "11px", fontSize: "13px", height: "45px" }}>Last Updated By</td>
              <td style={{ width: "10%;", paddingTop: "11px", fontSize: "13px", height: "45px" }}>Name</td>
              <td style={{ width: "10%;", paddingTop: "11px", fontSize: "13px", height: "45px" }}>Approve By</td>
              <td style={{ width: "10%;", paddingTop: "11px", fontSize: "13px", height: "45px" }}>Behalf Of</td>
            </thead>
            <tbody>{ardatalog}</tbody>
          </table>
        </div>
      </>
    );

    let sowmopresultlogs = resultlogs["mopresultsowlogs"];
    let sowdatalog = sowmopresultlogs.map((it) => {
      return (
        <>
          <tr>
            <td style={{ border: "2px solid black" }}>{it.sowDetail}</td>
            <td style={{ border: "2px solid black" }}>{it.timestamp}</td>
          </tr>
        </>
      );
    });
    let pagremarkresultlogs = resultlogs["logadddatagoNoGoRemarklogs"];
    let pagremarkdatalog = pagremarkresultlogs.map((it) => {
      return (
        <>
          <tr>
            <td style={{ border: "2px solid black" }}>{it.status}</td>
            <td style={{ border: "2px solid black" }}>{it.goNoGoRemark}</td>
            <td style={{ border: "2px solid black" }}>{it.timestamp}</td>
            <td style={{ border: "2px solid black" }}>{it.name}</td>
          </tr>
        </>
      );
    });

    //console.log(mopInstructionsData, "mopInstructionsData")

    let vsiteInformationLog = mopInstructionsDatalist.map((it) => {

      //console.log(it, "  ")
      return (
        <>
          <tr>
            <td style={{ border: "2px solid black" }}>{it.SiteCode}</td>
            <td style={{ border: "2px solid black" }}>{it.SiteAccess}</td>
            <td style={{ border: "2px solid black" }}>{it.AccessDetails}</td>
            <td style={{ border: "2px solid black" }}>{it.AccessDetailsUpdated}</td>
          </tr>
        </>
      );
    });

    let mopresultjclogs = resultlogs["mopresultjclogs"];

    let jcdatalog = mopresultjclogs.map((it) => {
      return (
        <>
          <tr>
            <td style={{ border: "2px solid black" }}>
              {it.justificationComment}
            </td>
            <td style={{ border: "2px solid black" }}>{it.timestamp}</td>
          </tr>
        </>
      );
    });

    let mopresultjsrlogs = resultlogs["mopresultjsrlogs"];
    let jsrdatalog = mopresultjsrlogs.map((it) => {
      return (
        <>
          <tr>
            <td style={{ border: "2px solid black" }}>
              {it.justificationForRequest}
            </td>
            <td style={{ border: "2px solid black" }}>{it.timestamp}</td>
          </tr>
        </>
      );
    });

    //     //console.log(datalog, "datalog");
    //     settemperature(
    //       <>
    //         {/* <table style={{ color: "black" }} border="0">
    //           <tr>
    //             <th>Status</th>
    //             <th>Reason</th>
    //             <th>Last Updated</th>
    //             <th>Update By</th>
    //             <th>Approver Profile</th>
    //             <th>Behalf Of</th>
    //           </tr>

    //           {datalog}
    //         </table> */}

    // <p>
    //   <a class="btn btn-primary btn-xs " data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
    //     SoW
    //   </a>
    //   <button class="btn btn-primary btn-xs mx-2" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
    //     Technology
    //   </button>
    //   <button class="btn btn-primary btn-xs mx-2" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
    //     Sector Impacted
    //   </button>
    //   <button class="btn btn-primary btn-xs mx-2" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
    //    Approval/Rejection
    //   </button>
    // </p>
    // <div class="collapse" id="collapseExample">
    //   <div class="card card-body">
    //     {datalog}
    //   </div>
    // </div>

    // </>
    // );

    settemperature(
      <Tabs
        defaultActiveKey="ApprovalRejection"
        transition={false}
        id="noanim-tab-example"
        className="mb-3"
      >
        <Tab eventKey="ApprovalRejection" title="Approval/Rejection">
          {ardatalogtop}
        </Tab>
        <Tab eventKey="justificationrequest" title="Justification For Request">
          <div className="">
            <table
              style={{ height: "auto", "max-height": "350px" }}
              className="table "
            >
              <thead>
                <td style={{ width: "30%;", fontSize: "13px", height: "45px", paddingTop: "11px", border: "2px solid black" }}>
                  Justification For Request
                </td>
                <td style={{ width: "70%;", fontSize: "13px", height: "45px", paddingTop: "11px", border: "2px solid black" }}>
                  Timestamp
                </td>
              </thead>
              <tbody>{jsrdatalog}</tbody>
            </table>
          </div>
        </Tab>
        <Tab eventKey="justificationcomments" title="Justification Comments">
          <div className="table-responsive ">
            <table
              style={{ height: "auto", "max-height": "350px" }}
              className="table "
            >
              <thead>
                <td style={{ width: "30%;", fontSize: "13px", height: "45px", paddingTop: "11px", paddingTop: "11px", }}>Justification Comments</td>
                <td style={{ width: "70%;", fontSize: "13px", height: "45px", paddingTop: "11px", paddingTop: "11px", }}>Timestamp</td>
              </thead>
              <tbody>{jcdatalog}</tbody>
            </table>
          </div>
        </Tab>
        <Tab eventKey="SOWdetails" title="SOW details">
          <div className="table-responsive ">
            <table
              style={{ height: "auto", "max-height": "350px" }}
              className="table "
            >
              <thead>
                <td style={{ width: "30%;", fontSize: "13px", height: "45px", paddingTop: "11px" }}>SOW Detail</td>
                <td style={{ width: "70%;", fontSize: "13px", height: "45px", paddingTop: "11px" }}>Timestamp</td>
              </thead>
              <tbody>{sowdatalog}</tbody>
            </table>
          </div>
        </Tab>
        <Tab eventKey="PAGAdminRemarks" title="PAG Admin Remarks">
          <div className="table-responsive table-light">
            <table
              style={{ height: "auto", "max-height": "350px" }}
              className="table "
            >
              <thead>
                <td style={{ width: "20%;", fontSize: "13px", height: "45px", paddingTop: "11px" }}>Status</td>
                <td style={{ width: "30%;", fontSize: "13px", height: "45px", paddingTop: "11px" }}>Remark</td>
                <td style={{ width: "40%;", fontSize: "13px", height: "45px", paddingTop: "11px" }}>Timestamp</td>
                <td style={{ width: "10%;", fontSize: "13px", height: "45px", paddingTop: "11px" }}>User</td>
              </thead>
              <tbody>{pagremarkdatalog}</tbody>
            </table>
          </div>
        </Tab>
        <Tab eventKey="SiteAccessInfo" title="Site Access Info">
          <div className="table-responsive table-light">
            <table
              style={{ height: "auto", "max-height": "350px" }}
              className="table "
            >
              <thead>
                <td style={{ width: "20%;", fontSize: "13px", height: "45px", paddingTop: "11px" }}>S:Site Code</td>
                <td style={{ width: "10%;", fontSize: "13px", height: "45px", paddingTop: "11px" }}>S:24x7 Site Access?</td>
                <td style={{ width: "50%;", fontSize: "13px", height: "45px", paddingTop: "11px" }}>S:Site Access Details</td>
                <td style={{ width: "10%;", fontSize: "13px", height: "45px", paddingTop: "11px" }}>S:Site Access Details Updated by </td>
              </thead>
              <tbody>{vsiteInformationLog}</tbody>
            </table>
          </div>
        </Tab>
      </Tabs>
    );
    setshowmodalweather(true);
  };
  // weatheroperner("","","")

  const count = useSelector((state) => {
    //console.log("the state is =", state);
    // let projectHistory=state.CommonResponse.networkDatabaseSiteStatus
    // if(projectHistory && projectHistory.length>0){
    //   networkSiteStatus=projectHistory
    // }
    let filterObj = state.OneCommonResponse.filterDropDown

    //console.warn("csdvfdvgbedgtbe", state)
    if (state.popUpState) {
      if ((state.popUpState.popstatus.status == 200 || state.popUpState.popstatus.status == 201) && closer) {
        if (popissue) {
          //console.log(bulkApproveModalState, "bulkApproveModalState");
          setpopissue(false);
          setCloser(false);
          setcommonModalState(false);
          setBulkApproveModal(false);
          setModalState(false);
          //console.log(bulkApproveModalState, "bulkApproveModalState");
          // dispatch(AccountReducerAction(allTechnologyApi))
          popUpUpdate({});
        }
      }
    }
    let allProjects = state.CommonResponse.startAwaitedSite;
    // state.OneCommonResponse.singleMops=[]
    // //console.log("all projects is =",allProjects)
    if (allProjects && allProjects.length > 0) {
      siteArray = allProjects.map((project) => {
        return <option value={project.uniqueSiteId}>{project.siteId}</option>;
      });
    }
    if (state.sites.sites && state.sites.sites.length > 0) {
      if (state.sites.sites) {
      }
      const pdfData = state.CommonResponse.fetchedpdfdata;
      // //console.log(pdfData.length, pdfData, "setshowmodalfileshow");

      // //console.log(showpdfpopup, "setshowpdfpopupout");
      if (showpdfpopup) {
        if (pdfData.length == 5) {
          //console.log(showpdfpopup, "setshowpdfpopup1");
          showpdfpopup = false;
          //console.log(showpdfpopup, "setshowpdfpopup2");
          // //console.log(
          //   pdfData,
          //   pdfData[3],
          //   +pdfData[4] == 0 && +pdfData[3] == 0,
          //   pdfData[4],
          //   "pdfData"
          // );
          if (pdfData[3] == 0 && pdfData[4] == 0) {
            setCsrArraymsg0("All Data Updated Successfully");
          }
          let strr2;
          strr2 = pdfData[1].map((item) => {
            return <li>{item} is not in database</li>;
          });
          setCsrArraymsg1(strr2);

          let strrnew;
          strrnew = pdfData[2].map((item) => {
            return <li>{item}</li>;
          });

          setCsrArraymsg2(strrnew);

          //console.log(CsvArray, "CsvArray iNeer");
          setfileShow(false);
          setshowmodalfileshow(true);
        }
      }
      if (state.sites.sites) {
        if (Object.keys(state.sites.sites).length > 0) {
          excelrowData = state.sites.sites.map((item) => {
            //console.log(item, "item,oneitemitem", item["sectorsImpacted"]["values"]);

            let arrnew = {};

            arrnew["Ticket"] = item["ticketId"];
            arrnew["Ticket Creation Date Time"] = getdatedatalistnext(item["mopCreatedAt"]);
            arrnew["Site ID"] = item["siteId"];
            arrnew["Company"] = item["company"];
            arrnew["SOW"] = item["sowDetails"]["values"].toString();
            arrnew["Activity Start Date Time"] = item["mopStartTime"];
            arrnew["Activity End Date Time"] = item["mopEndTime"];
            arrnew["Down Time Start"] = item["downMopStartTime"];
            arrnew["Down Time End"] = item["downMopStartTime"];
            arrnew["Down Time (H)"] = item["mopDownTime"];
            arrnew["GC Last Updated Time"] = getdatedatalistnext(item["mopUpdatedAt"]);
            arrnew["Mop Status"] = item['mopRFSiteStatus'];
            arrnew["SOW Status"] = item["ran_status"] ? item["ran_status"] : "Pending";

            arrnew["GO/No Go"] = item["goNo"];
            arrnew["Development Approval Status"] = item["devstatus"];
            arrnew["RF Approval Status"] = item["rfstatus"];
            arrnew["fops Approval Status"] = item["fopsstatus"];
            arrnew["Pag Admin Remarks"] = item["goNoGoRemark"];

            arrnew["DEVELOPMENT APPROVAL OR REJECT COMMENT"] = item["devreason"];
            arrnew["RF APPROVAL OR REJECT COMMENT"] = item["rfreason"];
            arrnew["FOPS APPROVAL OR REJECT COMMENT"] = item["fopsreason"];
            arrnew["Development Approve/Reject Date"] = getdatedatalistnext(item["lastUpdated_dev"]);
            arrnew["Rf Approve/Reject Date"] = getdatedatalistnext(item["lastUpdated_rf"]);
            arrnew["FOPS Approve/Reject Date"] = getdatedatalistnext(item["lastUpdated_fops"]);

            arrnew["Project Code"] = item["projectCode"];
            arrnew["Market"] = item["market"];
            arrnew["Sector Impacted"] = item["sectorsImpacted"]["values"].toString();
            arrnew["Technology Affected"] = item["techtake"] ? item["techtake"].join(", ") : ""
            arrnew["Type of Work (SI/NSI)"] = item["typeOfWork"];
            arrnew["CR #"] = item["crNumber"];
            arrnew["Development or Operations Work"] = item["group"];
            arrnew["Vendor"] = item["crewvendor"];

            arrnew["Owner"] = item["owner"];
            arrnew["Owner E-Mail"] = item["ownerEmail"];
            arrnew["Owner Contact"] = item["OwnerContact"];
            arrnew["SoW Details"] = item["sowDetail"];
            arrnew["Justification for Request"] = item["justificationForRequest"];
            arrnew["Justification Comments"] = item["justificationComment"];
            arrnew["Sector by sector outage"] = item["sectorByOutage"];
            arrnew["Crane Needed"] = item["CraneNeeded"];

            //console.log(arrnew, "arrnew");

            return arrnew;
          }  //MOP EXPORT
          )
          //   arrnew["Ticket"] = item["ticketId"];
          //   arrnew["Site ID"] = item["siteId"];
          //   arrnew["Company"] = item["company"];
          //   arrnew["SoW"] = item["sowDetails"]["values"].toString();
          //   arrnew["Start Date/Time"] = item["mopStartTime"];
          //   arrnew["End Date/Time"] = item["mopEndTime"];
          //   arrnew["Down Start Date/Time"] = item["downMopStartTime"];
          //   arrnew["Down End Date/Time"] = item["downMopStartTime"];
          //   arrnew["Down Time (h)"] = item["mopDownTime"];
          //   arrnew["GO No Go STATUS"] = item["goNo"];
          //   arrnew["Site Development Approval Status"] = item["devstatus"];
          //   arrnew["RF Approval Status"] = item["rfstatus"];
          //   arrnew["fops Approval Status"] = item["fopsstatus"];
          //   arrnew["DEVELOPMENT APPROVAL OR REJECT COMMENT"] =
          //     item["devreason"];
          //   arrnew["RF APPROVAL OR REJECT COMMENT"] = item["rfreason"];
          //   arrnew["FOPS APPROVAL OR REJECT COMMENT"] = item["fopsreason"];
          //   arrnew["Project Code"] = item["projectCode"];
          //   arrnew["Market"] = item["market"];
          //   arrnew["Sector Impacted"] =
          //     item["sectorsImpacted"]["values"].toString();
          //   arrnew["Technology Affected"] =
          //     item["techtake"]?item["techtake"].join(", "):""
          //   arrnew["Type of Work (SI/NSI)"] = item["typeOfWork"];
          //   arrnew["Development or Operations Work"] = item["group"];
          //   arrnew["CR/Wo #"] = item["crNumber"];
          //   arrnew["Owner"] = item["owner"];
          //   arrnew["Owner E-Mail"] = item["ownerEmail"];
          //   arrnew["Owner Contact"] = item["OwnerContact"];
          //   arrnew["SoW Details"] = item["sowDetail"];
          //   arrnew["Justification for Request"] =
          //     item["justificationForRequest"];
          //   arrnew["Justification Comments"] = item["justificationComment"];
          //   arrnew["Sector by sector outage"] = item["sectorByOutage"];
          //   arrnew["Crane Needed"] = item["CraneNeeded"];

          //   //console.log(arrnew, "arrnew");

          //   return arrnew;
          // });
          if (RowCount != state.sites.sites.length) {
            setRowCount(state.sites.sites.length)
          }
          // RowCount=
          //console.log("in the else part = RowCount", RowCount, state.sites);
          let i = 0;
          rowData = state.sites.sites.map((item) => {
            // item['siteId']="huygftyftyyttt667567ctfcfy"
            // if(item["mopApproval"]==1){
            // 116fc525bcbf9742ed553a249f66a4d3
            // datatable json data//
            item["5GId"] = "";
            item["LTEId"] = "";
            item["UMTSId"] = "";
            item["mixedModeId"] = "";

            
            // item["ticketIdLink"] = <p onClick={(e)=>{
            //   setXlsShow(prev=>!prev)
            //   dispatch(FetchSingleRecordAction(getDownTImeParsed, item.ranSupport,"/logs"));
            //   setRanData(item)

            // }}>{item["ticketId"]}</p>;
            //console.log(item["mopResult"],"mopResultmopResult")
            
            let preCheckRequest = "";
            let postCheckRequest = "";
            let mopEndTime = item["mopEndTime"];
            let mopStartTime = item["mopStartTime"];
            let mopDownTime = item["downMopEndTime"];
            let activityColor = "";
            let mopStartPreDiff;
            let mopEndPostDiff;

            if (item["mopResult"]) {
              activityColor = "red";
            } else {
              if (item?.ran?.postCheckRequest) {

                postCheckRequest = item?.ran?.postCheckRequest
                const mopEnd = new Date(mopEndTime);
                const postCheck = new Date(postCheckRequest);
                preCheckRequest = item?.ran?.preCheckRequest
                const mopStart = new Date(mopStartTime);
                const preCheck = new Date(preCheckRequest);
                const mopDownT = new Date(mopDownTime)


                //console.log(mopStart, preCheck, "logoutPostcheck");

                mopStartPreDiff = (preCheck.getTime() - mopStart.getTime()) / (1000 * 60);

                //console.log(mopDownT, "mopDownTmopDownT")

                //console.log(mopStart, preCheck, "logoutPostcheck");
                //console.log(mopStartPreDiff, "mopStartPreDiff");


                if ((mopStartPreDiff <= 10 && mopStartPreDiff >= -10) && postCheck < mopEnd) {
                  activityColor = "green";
                } else {
                  activityColor = "yellow";
                }
                //console.log(mopStartPreDiff, "15540 id difference")
              }
              else {
                activityColor = ""
              }
            }



            item["ticketIdLink"] = <p style={{ backgroundColor: activityColor ,color: (activityColor === "green" || activityColor === "red") ? "white" : "black",}} onClick={(e) => {
              setXlsShow(prev => !prev)
              dispatch(FetchSingleRecordAction(getDownTImeParsed, item.ranSupport, "/logs"));
              setRanData(item)

            }}>{item["ticketId"]}</p>;


            item["newrfstatus"] = item["rfstatus"];

            item["newfopsstatus"] = item["fopsstatus"];

            item["newdevstatus"] = item["devstatus"];

            let tec = "";
            let newSow = "";
            let newSectorsImpacted = "";

            //console.log("sgrthdhr", item);
            if (item["techtake"] && item["techtake"] != null) {
              let techArray = item["techtake"].join(", ");
              item["temp"] = smart_more_info(techArray, "", "Technology", CommonModalFun)
            }

            if (item["techByTech"] && item["techByTech"] != null) {
              let techArray = item["techByTech"]["values"].join(", ");
              item["temptechByTech"] = smart_more_info(techArray, "", "Technology", CommonModalFun)
            }


            if (item["projectCode"] && item["projectCode"] != null) {
              item["newprojectCode"] = item["projectCode"].join(", ");
            }
            if (item["sectorsImpacted"] && item["sectorsImpacted"] != null) {
              let techArray = item["sectorsImpacted"];
              if (techArray.values.length != 0) {
                //  item['technology'].forEach((item)=>{
                //   tec +=item.label +','
                //  })

                for (let i = 0; i < techArray.values.length; i++) {
                  //console.log("sgrthdhr", techArray.values[i]);
                  newSectorsImpacted += techArray.values[i] + "," + "\n";
                }
                //  item['technology']=tec
              }
              item["newSectorsImpacted"] = (
                <p
                  style={{
                    margin: "0px",
                    padding: "0px",
                    overflowY: "hidden",
                  }}
                >
                  {" "}
                  {newSectorsImpacted}
                </p>
              );
            }
            //console.log("the item detail =", item, item["ransiteStatus"], siteworkCondition.indexOf(item["ransiteStatus"]));
            item["newsowDetail"] = (
              <p className={"text-black my-0 modalHoverClass"}
                data-toggle="tooltip"
                data-placement="top"
                title="Click Here For More Details"
                onClick={() =>
                  CommonModalFun(
                    <p
                      style={{
                        border: "2px solid black",
                        color: "black",
                        padding: 2,
                      }}
                      className="CommonModal"
                    >
                      {item["sowDetail"]}
                    </p>,
                    "Comment",
                    "SOW Details"
                  )
                }
              >{more_info(item["sowDetail"])}
              </p>
            );

            if (item["sowDetails"] && item["sowDetails"] != null) {
              let sowArray = item["sowDetails"];
              if (sowArray.values.length != 0) {
                //  item['technology'].forEach((item)=>{
                //   tec +=item.label +','
                //  })

                for (let i = 0; i < sowArray.values.length; i++) {
                  //console.log("sgrthdhr", sowArray.values[i]);
                  newSow += sowArray.values[i] + "," + "\n";
                }
                //  item['technology']=tec
              }
              item["newSow"] = newSow;
            }

            item["ransiteStatus"] = item["ran_status"] ? item["ran_status"] : "Pending"
            if (item["ran_bucket"] == "Scheduled" && item["ran_status"] == "Login Awaited - No Show") {
              item["showransiteStatus"] = <button className={`badge py-1 badge-custom-size loginAwaitedNoShow`}  >{item["ran_status"] ? item["ran_status"] : "Pending"}</button>
            } else if (item["ran_status"] == "Post-Check Completed - Validation ongoing") {
              item["showransiteStatus"] = <button className={`badge py-1 badge-custom-size ${colors[item["ran_status"]]}`}  >{item["ran_status"] ? item["ran_status"] == "Post-Check Completed - Validation ongoing" ? "Post-Check Completed" : item["ran_status"] : "Pending"}</button>
            }
            else {
              item["showransiteStatus"] = <button className={`badge py-1 badge-custom-size ${colorCodeObj[item["ran_bucket"] || "pending"]}`}  >{item["ran_status"] ? item["ran_status"] : "Pending"}</button>
            }

            let current = new Date()
            let timeznal = fun_time_zonechanger(commonzone, current)
            // timeznal.format("MM/DD/YYYY HH:mm")>moment(setdate).format("MM/DD/YYYY HH:mm")

            //console.log(timeznal.format("MM/DD/YYYY HH:mm") > moment(item["mopEndTime"]).format("MM/DD/YYYY HH:mm"), timeznal.format("MM/DD/YYYY HH:mm"), moment(item["mopEndTime"]).format("MM/DD/YYYY HH:mm"), "mopEndTime")

            let timeCondition = timeznal.format("MM/DD/YYYY HH:mm") > moment(item["mopEndTime"]).format("MM/DD/YYYY HH:mm")


            //console.log(siteworkCondition.indexOf(item["ransiteStatus"]) != -1, item["ransiteStatus"], "ransiteStatus")
            if (userDetails.rolename == "RF Engineer" && siteworkCondition.indexOf(item["ransiteStatus"]) != -1) {
              // item["siteApprovalAction"] = (<><p className={`badge badge-custom-size ${badgecolor[item["rfstatus"]]} my-0 p-1`} onClick={() => fetechDetail(item.mopuniqueId, "rf")}>{item["rfstatus"]}</p></>);
              // item["siteDevelopmentApprovalStatus"] = (<><p className={`badge badge-custom-size ${badgecolor[item["devstatus"]]} my-0 p-1`} onClick={() => fetechDetail(item.mopuniqueId, "dev")}>{item["devstatus"]}</p></>);
              if (item["group"] == "Development") {
                item["siteApprovalAction"] = (<><p className={`badge badge-custom-size ${badgecolor[item["rfstatus"]]} my-0 p-1`} onClick={() => fetechDetail(item.mopuniqueId, "rf", "RF Approval Status", timeCondition)}>{item["rfstatus"]}</p></>);
                item["siteDevelopmentApprovalStatus"] = (<><p className={`badge badge-custom-size ${badgecolor[item["devstatus"]]} my-0 p-1`} onClick={() => fetechDetail(item.mopuniqueId, "dev", "Development Approval Status", timeCondition)}>{item["devstatus"]}</p></>);
              }
              if (item["group"] == "Operation") {
                item["siteApprovalAction"] = (<><p className={`badge badge-custom-size ${badgecolor[item["rfstatus"]]} my-0 p-1`} onClick={() => fetechDetail(item.mopuniqueId, "rf", "RF Approval Status", timeCondition)}>{item["rfstatus"]}</p></>);
                item["FOPSApprovalStatus"] = (<><p className={`badge badge-custom-size ${badgecolor[item["fopsstatus"]]} my-0 p-1`} onClick={() => fetechDetail(item.mopuniqueId, "ops", "Operation Approval Status", timeCondition)}>{item["fopsstatus"]}</p></>);
              }


              // if (item["mopRF"] == 1) {
              //   item["siteApprovalAction"] = (
              //     <>
              //       <p
              //         className={
              //           item["rfstatus"] == "Approved"
              //             ? "badge badge-custom-size badge-success p-1 my-0"
              //             : "badge badge-custom-size badge-danger p-1 my-0"
              //         }
              //         onClick={() => fetechDetail(item.mopuniqueId, "rf")}
              //       >
              //         {item["rfstatus"]}
              //       </p>
              //     </>
              //   );
              // } else {
              //   item["siteApprovalAction"] = (
              //     <>
              //       <p
              //         className="badge badge-custom-size badge-primary pending-color p-1 my-0"
              //         onClick={() => fetechDetail(item.mopuniqueId, "rf")}
              //       >
              //         Pending
              //       </p>
              //     </>
              //   );
              // }

              // if (
              //   item["mopDevelopment"] == 1 &&
              //   item["group"] == "Development"
              // ) {
              //   item["siteDevelopmentApprovalStatus"] = (
              //     <>
              //       <p
              //         className={
              //           `badge badge-custom-size ${badgecolor[item["devstatus"]]} my-0 p-1`
              //           // item["devstatus"] == "Approved"
              //           //   ? "badge badge-custom-size badge-success my-0 p-1 "
              //           //   : "badge badge-custom-size badge-custom-size badge-danger my-0 p-1"
              //         }
              //         onClick={() => fetechDetail(item.mopuniqueId, "dev")}
              //       >
              //         {item["devstatus"]}
              //       </p>
              //     </>
              //   );
              // } else if (item["group"] == "Development") {
              //   item["siteDevelopmentApprovalStatus"] = (
              //     <>
              //       <p
              //         className="badge badge-custom-size badge-primary pending-color my-0 p-1"
              //         onClick={() => fetechDetail(item.mopuniqueId, "dev")}
              //       >
              //         Pending
              //       </p>
              //     </>
              //   );
              // }
              // if (item["mopOperation"] == 1 && item["group"] == "Operation") {
              //   item["FOPSApprovalStatus"] = (
              //     <>
              //       <p
              //         className={
              //           `badge badge-custom-size ${badgecolor[item["fopsstatus"]]} my-0 p-1`
              //           // item["fopsstatus"] == "Approved"
              //           //   ? "badge badge-custom-size badge-success p-1 my-0"
              //           //   : "badge badge-custom-size badge-danger p-1 my-0"
              //         }
              //         onClick={() => fetechDetail(item.mopuniqueId, "ops")}
              //       >
              //         {item["fopsstatus"]}
              //       </p>
              //     </>
              // );
              // } else if (item["group"] == "Operation") {
              //   item["FOPSApprovalStatus"] = (
              //     <>
              //       <p
              //         className="badge badge-custom-size badge-primary pending-color p-1 my-0"
              //         onClick={() => fetechDetail(item.mopuniqueId, "ops")}
              //       >
              //         Pending
              //       </p>
              //     </>
              //   );
              // }  
            } else if (
              userDetails.rolename == "Development" &&
              siteworkCondition.indexOf(item["ransiteStatus"]) != -1
            ) {
              //rf button
              // item["siteApprovalAction"] = (<><p className={`badge badge-custom-size ${badgecolor[item["rfstatus"]]} my-0 p-1`}>{item["rfstatus"]}</p></>);
              // item["siteDevelopmentApprovalStatus"] = (<><p className={`badge badge-custom-size ${badgecolor[item["devstatus"]]} my-0 p-1`}onClick={() => fetechDetail(item.mopuniqueId, "dev")}>{item["devstatus"]}</p></>);



              if (item["group"] == "Development") {
                item["siteApprovalAction"] = (<><p className={`badge badge-custom-size ${badgecolor[item["rfstatus"]]} my-0 p-1`}>{item["rfstatus"]}</p></>);
                item["siteDevelopmentApprovalStatus"] = (<><p className={`badge badge-custom-size ${badgecolor[item["devstatus"]]} my-0 p-1`} onClick={() => fetechDetail(item.mopuniqueId, "dev", "Development Approval Status", timeCondition)}>{item["devstatus"]}</p></>)
              }
              // item["FOPSApprovalStatus"] = (<><p className={ `badge badge-custom-size ${badgecolor[item["fopsstatus"]]} my-0 p-1`} onClick={() => fetechDetail(item.mopuniqueId, "ops")}>{item["fopsstatus"]}</p></>);

              // if (item["mopRF"] == 1) {
              //   item["siteApprovalAction"] = (
              //     <>
              //       <p
              //         className={

              //           `badge badge-custom-size ${badgecolor[item["rfstatus"]]} my-0 p-1`
              //           // item["rfstatus"] == "Approved"
              //           //   ? "badge badge-custom-size badge-success p-1 my-0"
              //           //   : "badge badge-custom-size badge-danger p-1"
              //         }
              //       >
              //         {item["rfstatus"]}
              //       </p>
              //     </>
              //   );
              // } else {
              //   item["siteApprovalAction"] = (
              //     <>
              //       <p className="badge badge-custom-size badge-primary pending-color p-1 my-0">
              //         Pending
              //       </p>
              //     </>
              //   );
              // }
              // if (
              //   item["mopDevelopment"] == 1 &&
              //   item["group"] == "Development"
              // ) {
              //   item["siteDevelopmentApprovalStatus"] = (
              //     <>
              //       <p
              //         className={

              //           `badge badge-custom-size ${badgecolor[item["devstatus"]]} my-0 p-1`
              //           // item["devstatus"] == "Approved"
              //           //   ? "badge badge-custom-size badge-success p-1 my-0"
              //           //   : "badge badge-custom-size badge-danger p-1 my-0"
              //         }
              //         onClick={() => fetechDetail(item.mopuniqueId, "dev")}
              //       >
              //         {item["devstatus"]}
              //       </p>
              //     </>
              //   );
              // } else if (item["group"] == "Development") {
              //   item["siteDevelopmentApprovalStatus"] = (
              //     <>
              //       <p

              //         // className="badge badge-custom-size badge-primary pending-color p-1 my-0"
              //         className="badge badge-custom-size badge-primary pending-color p-1 my-0"
              //         onClick={() => fetechDetail(item.mopuniqueId, "dev")}
              //       >
              //         Pending
              //       </p>
              //     </>
              //   );
              // }
            } else if (userDetails.rolename == "FOPS" && siteworkCondition.indexOf(item["ransiteStatus"]) != -1) {

              // if (item["group"] == "Development") {
              //   item["siteApprovalAction"] = (<><p className={`badge badge-custom-size ${badgecolor[item["rfstatus"]]} my-0 p-1`}>{item["rfstatus"]}</p></>);
              //   item["siteDevelopmentApprovalStatus"] = (<><p className={`badge badge-custom-size ${badgecolor[item["devstatus"]]} my-0 p-1`}>{item["devstatus"]}</p></>);
              // }


              if (item["group"] == "Operation") {
                item["siteApprovalAction"] = (<><p className={`badge badge-custom-size ${badgecolor[item["rfstatus"]]} my-0 p-1`}>{item["rfstatus"]}</p></>);
                item["FOPSApprovalStatus"] = (<><p className={`badge badge-custom-size ${badgecolor[item["fopsstatus"]]} my-0 p-1`} onClick={() => fetechDetail(item.mopuniqueId, "ops", "Operation Approval Status", timeCondition)}>{item["fopsstatus"]}</p></>);
              }

              // if (item["mopRF"] == 1) {
              // } else {
              //   item["siteApprovalAction"] = (
              //     <>
              //       <p className="badge badge-custom-size badge-primary pending-color p-1 my-0">
              //         Pending
              //       </p>
              //     </>
              //   );
              // }

              // if (
              //   item["mopDevelopment"] == 1 &&
              //   item["group"] == "Development"
              // ) {
              //   item["siteDevelopmentApprovalStatus"] = (
              //     <>
              //       <p
              //         className={

              //           `badge badge-custom-size ${badgecolor[item["devstatus"]]} my-0 p-1`
              //           // item["devstatus"] == "Approved"
              //           //   ? "badge badge-custom-size badge-success p-1 my-0"
              //           //   : "badge badge-custom-size badge-danger p-1 my-0"
              //         }
              //       >
              //         {item["devstatus"]}
              //       </p>
              //     </>
              //   );
              // } else if (item["group"] == "Development") {
              //   item["siteDevelopmentApprovalStatus"] = (
              //     <>
              //       <p className="badge badge-custom-size badge-primary pending-color p-1 my-0">
              //         Pending
              //       </p>
              //     </>
              //   );
              // }
              // if (item["mopOperation"] == 1 && item["group"] == "Operation") {
              //   } else if (item["group"] == "Operation") {
              //   item["FOPSApprovalStatus"] = (
              //     <>
              //       <p
              //         className="badge badge-custom-size badge-primary pending-color p-1 my-0"
              //         onClick={() => fetechDetail(item.mopuniqueId, "ops")}
              //       >
              //         Pending
              //       </p>
              //     </>
              //   );
              // }
            } else if (
              userDetails.rolename == "GC Login" ||
              userDetails.rolename == "PAG User" ||
              userDetails.rolename == "PAG Admin" ||
              userDetails.rolename == "PAG Engineer" ||
              userDetails.rolename == "Manage Engineer" ||
              userDetails.rolename == "Super Admin" ||
              userDetails.rolename == "Administrator" ||
              (userDetails.rolename == "Manage Engineer" && userDetails.department.values.includes("MOP")) ||
              siteworkCondition.indexOf(item["ransiteStatus"]) == -1
            ) {

              if (item["group"] == "Development") {
                item["siteApprovalAction"] = (<><p className={`badge badge-custom-size ${badgecolor[item["rfstatus"]]} my-0 p-1`}>{item["rfstatus"]}</p></>);
                item["siteDevelopmentApprovalStatus"] = (<><p className={`badge badge-custom-size ${badgecolor[item["devstatus"]]} my-0 p-1`}>{item["devstatus"]}</p></>);
              }
              if (item["group"] == "Operation") {
                item["siteApprovalAction"] = (<><p className={`badge badge-custom-size ${badgecolor[item["rfstatus"]]} my-0 p-1`}>{item["rfstatus"]}</p></>);
                item["FOPSApprovalStatus"] = (<><p className={`badge badge-custom-size ${badgecolor[item["fopsstatus"]]} my-0 p-1`}>{item["fopsstatus"]}</p></>);
              }



              // if (item["mopRF"] == 1) {
              //   item["siteApprovalAction"] = (
              //     <>
              //       <p
              //         className={
              //           `badge badge-custom-size ${badgecolor[item["rfstatus"]]} my-0 p-1`
              //           // item["rfstatus"] == "Approved"
              //           //   ? "badge badge-custom-size badge-success p-1 my-0"
              //           //   : "badge badge-custom-size badge-danger p-1 my-0"
              //         }
              //       >
              //         {item["rfstatus"]}
              //       </p>
              //     </>
              //   );
              // } else {
              //   item["siteApprovalAction"] = (
              //     <>
              //       <p className="badge badge-custom-size badge-primary pending-color p-1 my-0">
              //         Pending
              //       </p>
              //     </>
              //   );
              // }
              // //console.log(
              //   item["mopDevelopment"],
              //   item["group"],
              //   item["devstatus"],
              //   "datatatata"
              // );
              // if (
              //   item["mopDevelopment"] == 1 &&
              //   item["group"] == "Development"
              // ) {
              //   item["siteDevelopmentApprovalStatus"] = (
              //     <>
              //       <p
              //         className={
              //           `badge badge-custom-size ${badgecolor[item["devstatus"]]} my-0 p-1`
              //           // item["devstatus"] == "Approved"
              //           //   ? "badge badge-custom-size badge-success p-1 my-0"
              //           //   : "badge badge-custom-size badge-danger p-1 my-0"
              //         }
              //       >
              //         {item["devstatus"]}
              //       </p>
              //     </>
              //   );
              // } else if (item["group"] == "Development") {
              //   item["siteDevelopmentApprovalStatus"] = (
              //     <>
              //       <p className={`badge badge-custom-size ${badgecolor[item["devstatus"]]} my-0 p-1`}>
              //         {item["devstatus"]}
              //       </p>
              //     </>
              //   );
              // }
              // if (item["mopOperation"] == 1 && item["group"] == "Operation") {
              //   item["FOPSApprovalStatus"] = (
              //     <>
              //       {/* <p
              //         className={
              //           item["fopsstatus"] == "Approved"
              //             ? "badge badge-custom-size badge-success p-1 my-0"
              //             : "badge badge-custom-size badge-danger p-1 my-0"
              //         }
              //       > */}

              //       <p className={`badge badge-custom-size ${badgecolor[item["fopsstatus"]]} my-0 p-1`}>
              //         {item["fopsstatus"]}
              //       </p>
              //     </>
              //   );
              // } else if (item["group"] == "Operation") {
              //   item["FOPSApprovalStatus"] = (
              //     <>

              //       <p className={`badge badge-custom-size ${badgecolor[item["fopsstatus"]]} my-0 p-1`}>
              //         {/* <p className="badge badge-custom-size badge-primary pending-color p-1 my-0"> */}
              //         {item["fopsstatus"]}
              //       </p>
              //     </>
              //   );
              // }
            }
            if (userDetails.rolename == "GC Login") {
              item["update"] = (
                <>
                  {item["workStart"] == 0 ? (
                    <p
                      className={
                        "badge badge-custom-size badge-primary p-1 my-0"
                      }
                      onClick={() => fetechDetail(item.mopuniqueId, "edit", "Edit MOP Request")}
                    >
                      Edit
                    </p>
                  ) : (
                    ""
                  )}
                </>
              );
              item["duplicate"] = (
                <>
                  <p
                    className={"badge badge-custom-size badge-primary p-1 my-0"}
                    onClick={() => fetechDetail(item.mopuniqueId, "update", "Duplicate MOP Request")}
                  >
                    Duplicate
                  </p>
                </>
              );
            }
            if (
              (userDetails.rolename == "GC Login" ||
                userDetails.rolename == "PAG Engineer" ||
                userDetails.rolename == "Manage Engineer" ||
                (userDetails.rolename == "Manage Engineer" && userDetails.department.values.includes("MOP")))
            ) {


              item["crewAdd"] =

                item["crewAssign"] == 1 ? (
                  <>
                    <p
                      className={
                        "badge badge-custom-size badge-warning p-1 my-0"
                      }
                      onClick={() => assignWorking.indexOf(item["ransiteStatus"]) == -1 ? fetechDetail(item.mopuniqueId, "assigner", "Add Crew") : ""}
                    >
                      Assign
                    </p>
                  </>
                ) : item["crewAssign"] == 2 ? (
                  <>
                    <p
                      className={
                        "badge badge-custom-size badge-primary p-1 my-0"
                      }
                      onClick={() =>
                        assignWorking.indexOf(item["ransiteStatus"]) == -1 ? fetechDetail(item.mopuniqueId, "reassigner", "Assigned Crew") : ""
                      }
                    >
                      Assigned
                    </p>
                    {/* <p style={{marginLeft:"3px"}}
                        className={ "badge badge-small-custom-size badge-danger p-1 my-0" }
                        onClick={() => fetechDetail(item.mopuniqueId, "reassigner")}
                      >
                        Edit
                      </p> */}
                  </>
                ) : (
                  ""
                );

            }

            item["goNoNew"] = (
              <p
                className={
                  item["goNo"] == "Go"
                    ? "badge badge-custom-size badge-success p-1 my-0"
                    : "badge badge-custom-size badge-warning p-1 my-0"
                }
                onClick={() =>
                  userDetails.rolename == "PAG Admin" ?
                    CommonModalFun(
                      <GoNoGoModal isPag={userDetails.rolename == "PAG Admin" ? true : false} type="Go & No Go Status Remarks" typename="Go & No Go Status Remarks" isShow={true} mopRFSiteStatus={item.mopRFSiteStatus} siteGroup={item.group} unique_id_up={item.mopuniqueId} />,
                      "Comment",
                      "Go/No Go"
                    )
                    : ""

                }
              >
                {item["goNo"]}
              </p>
            );
            item["justificationForRequest"].slice(0, 64)

            // if(text.length>1){
            // showText=
            // }
            // else{
            //   showText=text[0]
            // }
            item["justificationrequestnew"] = (
              <p
                className={"text-black my-0 modalHoverClass"}

                data-toggle="tooltip"
                data-placement="top"
                title="Click Here For More Details"
                onClick={() =>
                  CommonModalFun(
                    <p
                      style={{
                        border: "2px solid black",
                        color: "black",
                        padding: 2,
                      }}
                      className="CommonModal"
                    >
                      {item["justificationForRequest"]}
                    </p>,
                    "Comment",
                    "Justification Request"
                  )
                }
              >
                {
                  more_info(item["justificationForRequest"])
                }
              </p>
            );


            item["LastUpdatedSiteDevelopment"] = getdatedatalistnext(item["lastUpdated_dev"]);
            item["LastUpdatedRF"] = getdatedatalistnext(item["lastUpdated_rf"]);
            item["LastUpdatedOperation"] = getdatedatalistnext(item["lastUpdated_fops"]);
            item["mopCreatedAt"] = getdatedatalistnext(item["mopCreatedAt"]);
            item["cpyowner"] = (
              <>
                <button
                  type="button"
                  className="badge badge-custom-size backgroundPurple p-1 my-0"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Click Here For More Details"
                  onClick={() =>
                    CommonModalFun(
                      owneroperner(
                        item["owner"],
                        item["ownerEmail"],
                        item["OwnerContact"]
                      ),
                      "Owner",
                      "Owner"
                    )
                  }
                >
                  Owner
                </button>
              </>
            );
            item["mopUpdatedAt"] = getdatedatalistnext(item["mopUpdatedAt"]);

            if (
              userDetails.rolename == "FOPS" &&
              item["group"] == "Operation"
            ) {
              item["checkbox"] = (
                <input
                  type="checkbox"
                  id="siteId"
                  name="siteId"
                  value={item["ticketId"]}
                />
              );
            }

            if (
              userDetails.rolename == "Development" &&
              item["group"] == "Development"
            ) {
              item["checkbox"] = (
                <input
                  type="checkbox"
                  id="siteId"
                  name="siteId"
                  value={item["ticketId"]}
                />
              );
            }
            let customStyle = {
              ":after": {
                margin: "0px 2.4rem;"
              }
            }
            if (userDetails.rolename == "RF Engineer") {
              item["checkbox"] = (
                <input
                  // height="10px"
                  // border="3px solid #143b64"
                  type="checkbox"
                  id="siteId"
                  name="siteId"
                  value={item["ticketId"]}
                // style={customStyle}
                />
              );
            }

            item["wdeatil"] = (
              <button
                className="badge badge-custom-size backgroundPurple p-1 my-0"
                onClick={() => {
                  settemperature(
                    <center>
                      <span className="loader">Loading</span>
                    </center>
                  );
                  weatheroperner(
                    item["latitude"],
                    item["longitude"],
                    item["siteId"],
                    item["ticketId"],
                    item["mopStartTime"].split(" ")[0]
                  );
                }}
              >
                Weather
              </button>
            );

            item["justificationCommentNew"] = (
              <p
                className={"text-black my-0 modalHoverClass"}
                data-toggle="tooltip"
                data-placement="top"
                title="Click Here For More Details"
                onClick={() =>
                  CommonModalFun(
                    <p
                      style={{
                        border: "2px solid black",
                        color: "black",
                        padding: 2,
                      }}
                      className="CommonModal"
                    >
                      {item["justificationComment"]}
                    </p>,
                    "Comment",
                    "Justification Comment"
                  )
                }
              >
                {more_info(item["justificationComment"])}
              </p>
            );
            item["rfSiteStatus"] =
              <p
                className={"text-black my-0  modalHoverClass"}
                style={{ padding: "6px" }}
                onClick={() =>
                  userDetails.rolename == "PAG Admin"
                    //  || userDetails.rolename == "RF Engineer" 
                    ?
                    CommonModalFun(

                      <RFSiteStatus mopRFSiteStatus={item.mopRFSiteStatus} unique_id_up={item.mopuniqueId} />,
                      "Comment",
                      "RF Site Status"
                    )
                    : ''
                }
              >
                {item['mopRFSiteStatus'] ? <p className={`badge badge-custom-size ${colors[item['mopRFSiteStatus'] || "pending"]} p-1 my-0`}>{item['mopRFSiteStatus']}</p> : userDetails.rolename == "PAG Admin" ? <p className={'badge badge-primary'} style={{ width: "50px" }}><Add /></p> : ""}
                {/* {item['mopRFSiteStatus']?<p className={`badge badge-custom-size ${colors[item['mopRFSiteStatus'] || "pending"]} p-1 my-0`}>{item['mopRFSiteStatus']}</p>:userDetails.rolename == "PAG Admin" || userDetails.rolename == "RF Engineer"?<p className={'badge badge-primary'} style={{ width: "50px" }}><Add /></p>:""} */}
              </p>


            item["PAGAdminRemarking"] = (
              <div
                className=""
                data-toggle="tooltip"
                data-placement="top"
                title="Click Here For More Details"
                onClick={() =>

                  CommonModalFun(
                    <GoNoGoModal isPag={userDetails.rolename == "PAG Admin" ? true : false} data={item['goNoGoRemark']} isShow={false} type="PAG Admin Remarks" typename="" mopRFSiteStatus={item.mopRFSiteStatus} siteGroup={item.group} unique_id_up={item.mopuniqueId} />,
                    "Comment",
                    "PAG Admin Remarks"
                  )

                }
              >
                {userDetails.rolename != "PAG Admin" ? item['goNoGoRemark'] ? <p
                >{item['goNoGoRemark'] ? more_info(item["goNoGoRemark"]) : ""}</p> : "" : ""}
                {userDetails.rolename == "PAG Admin" ?
                  item['goNoGoRemark'] ?
                    <p >
                      {more_info(item["goNoGoRemark"])}
                    </p> :
                    <p className={'badge badge-primary'} style={{ width: "10px" }}><Add /></p> :
                  ""}
              </div>
            );




            item["devreasonaddon"] = (
              <p
                className={
                  item["devstatus"] == "Approved"
                    ? "devreason text-success my-0"
                    : "devreason text-danger my-0"
                }
                data-toggle="tooltip"
                data-placement="top"
                title="Click Here For More Details"
                onClick={() =>
                  CommonModalFun(
                    <p
                      style={{
                        border: "2px solid black",
                        color: "black",
                        padding: 2,
                      }}


                      className="CommonModal"
                    >
                      {item["devreason"]}
                    </p>,

                    "Comment",
                    "Development Approval or Reject Comment"
                  )
                }
              >
                {item["devreason"] ? more_info(item["devreason"]) : ""}
              </p>
            );

            item["rfreasonaddon"] = (
              <p
                className={
                  item["rfstatus"] == "Approved"
                    ? "devreason text-success my-0"
                    : "devreason text-danger my-0"
                }
                data-toggle="tooltip"
                data-placement="top"
                title="Click Here For More Details"
                onClick={() =>
                  CommonModalFun(
                    <p
                      style={{
                        border: "2px solid black",
                        color: "black",
                        padding: 2,
                      }}
                      className="CommonModal"
                    >
                      {item["rfreason"]}
                    </p>,
                    "Comment",
                    "RF Approval or Reject Comment"
                  )
                }
              >
                {item["rfreason"] ? more_info(item["rfreason"]) : ""}
              </p>
            );

            item["fopsreasonaddon"] = (
              <p
                className={
                  item["fopsstatus"] == "Approved"
                    ? "devreason text-success my-0"
                    : "devreason text-danger my-0"
                }
                data-toggle="tooltip"
                data-placement="top"
                title="Click Here For More Details"
                onClick={() =>
                  CommonModalFun(
                    // item["fopsreason"],
                    <p
                      style={{
                        border: "2px solid black",
                        color: "black",
                        padding: 2,
                      }}
                      className="CommonModal"
                    >
                      {item["fopsreason"]}

                    </p>,
                    "Comment",
                    "FOPS Approval or Reject comment"
                  )
                }
              >
                {item["fopsreason"] ? more_info(item["fopsreason"]) : ""}
              </p>
            );

            item["slogger"] = (
              <button
                className="badge badge-custom-size backgroundPurple p-1 my-0"
                onClick={() => {
                  dispatch(getMOPInstructionAction([]))
                  if (item.mopInstructionsId && item.mopInstructionsId != "") {
                    //console.log(item.mopInstructionsId, item.group, item.devstatus, item.fopsstatus, item.rfstatus, "resultlogs")

                    if (item.group == "Development") {
                      if (item.devstatus == "Approved" || item.rfstatus == "Approved") {
                        dispatch(AccountReducerAction(MOPInstructionApi + "/uniqueid/" + item.mopInstructionsId))
                      } else {
                        dispatch(AccountReducerAction(MOPInstructionApi + "/SiteCode/" + item.siteId))
                      }
                    }
                    if (item.group == "Operation") {
                      if (item.fopsstatus == "Approved" || item.rfstatus == "Approved") {
                        dispatch(AccountReducerAction(MOPInstructionApi + "/uniqueid/" + item.mopInstructionsId))
                      } else {
                        dispatch(AccountReducerAction(MOPInstructionApi + "/SiteCode/" + item.siteId))
                      }
                    }
                  }
                  setslogsopernerData(item);
                  slogsoperner(item, []);
                  setmopInstructionsId("")
                  setmopInstructionsData([])
                }}
              >
                Site Logs
              </button>
            );

            i = i + 1;
            //console.log(item["wdeatil"]);
            // item["map"]=<fregment onClick={() => openMapModal()}><Map /></fregment>
            return item;
            // }
          });
          //console.log(rowData,"_____rowData_____")

          //console.log(state.sites.sites);
          if (state.sites.sites.length > 0) {
            rowDataOne = state.sites.sites[0];
            Object.keys(rowDataOne).map((key, index) => {
              //console.log(key, "key");
              if (key != "projectStatusDescr") {
                // columns.push(
                //   {
                //     name: key,
                //     selector: key,
                //     sortable: true
                //     }
                //   )
              }
            });
          }
        }
      }
    }

    let mopSiteStatus = state.CommonResponse.mopSiteStatus;
    let MopCount = state.countState.mopCount
    //console.log(MopCount, totalTicketsinMop != MopCount, "settotalTicketsinMop")
    if (totalTicketsinMop != MopCount) {
      settotalTicketsinMop(MopCount)
    }
    // if()
    siteStatus = mopSiteStatus
    //console.log(CsvArray, "CsvArrayCsvArray");

    let mopInstructions = state.CommonResponse.mopInstructions
    //console.log(mopInstructionsId, mopInstructions, "mopInstructionsmopInstructions")
    if (mopInstructions.length > 0) {
      //console.log(mopInstructionsId != mopInstructions[0].uniqueid, "mopInstructionsmopInstructions")
      if (mopInstructionsId != mopInstructions[0].uniqueid) {
        setmopInstructionsId(mopInstructions[0].uniqueid)
        setmopInstructionsData(mopInstructions)
        //console.log(slogsopernerData, "mopInstructionsData")
        slogsoperner(slogsopernerData, mopInstructions)
      }
    }
    return { networkSiteStatus, siteStatus, siteArray, CsvArray, MopCount, filterObj, mopInstructions };
  });

  //for(let i=0;i<rowData.length;i++){
  // if(wDetail.weather){
  //     rowData[0]['wDetail']=wDetail.weather
  //  }

  //console.log("CsvArray", count, "count");
  const fetechSingleProject = (id = undefined, siteId) => {
    //console.log("single pro ", id);
    // //console.log("the actual site id=",siteId)
    setUId(id);
    setModalState(true);

    dispatch(FetchSingleRecordAction(projectCode, id));
  };
  const bulkRfds = () => {
    setBRFDS(true);
  };
  let columnMop = [];


  if (
    userDetails.rolename == "RF Engineer" ||
    userDetails.rolename == "FOPS" ||
    userDetails.rolename == "Development"
  ) {
    columnMop.push({
      label: <input type="checkbox" style={{ width: "15px !important" }}
        id="chkPassport"
        className="btn btn-xs btn-primary mx-2"
        data-toggle="tooltip"
        data-placement="top"
        title="Select All Table"
        onClick={(e) => BulkSelect(e.target.checked)}
      />,
      name: "checkbox",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            padding: "1px 1px",
            background: "#143b64",
            position: "sticky",
            color: "white",
            zIndex: 101,
            left: "-2px",
            minWidth: '50px',
            maxWidth: '50px',
            outline: "2px solid black",
          },
        }),
        setCellProps: () => ({
          style: {
            overflowY: "hidden",
            outline: "2px solid black",
            position: "sticky",
            padding: "0px 1px",
            zIndex: 99,
            left: "-2px",
            minWidth: '50px',
            maxWidth: '50px',
            background: "White",
          },
        }),
      },
    }, {
      name: "ticketIdLink",
      label: "Ticket Id",
      sortable: true,
      options: {
        filter: true,
        filterType: "textField",
        filterOptions: count.filterObj.ticketIdies,

        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            position: "sticky",
            outline: "2px solid black",
            left: "48px",
            boxShadow: "5px 2px 5px grey",

            background: "White",
            zIndex: 99,
            minWidth: '80px',
            maxWidth: '80px',

          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            minWidth: '80px',
            maxWidth: '80px',
            position: "sticky",
            paddingRight: 0,
            left: "48px",
            outline: "2px solid black",
            background: "#143b64",
            boxShadow: "5px 2px 5px grey",

            color: "white",

            zIndex: 101,
          },
        }),
      },
    },

      // {
      //   name: "mopCreatedAt",
      //   label: "Ticket Creation Date Time",
      //   sortable: true,

      //   options: {
      //     // customBodyRender : value => <div style={{ border : "2px solid black" , height : '100%' , width : '100%'}}>{value}</div> ,
      //     filter: false,
      //     setCellProps: () => ({
      //       style: {
      //         // borderRight: "2px solid black",
      //         whiteSpace: "nowrap",
      //         alignItem: "center",
      //         position: "sticky",
      //         minWidth: '180px',
      //         maxWidth: '180px',
      //         left: "128px",
      //         background: "White",
      //         zIndex: 99,
      //         postition: 'sticky',
      //         outline: "2px solid black"
      //         // left : '60px',

      //       },
      //     }),
      //     setCellHeaderProps: () => ({
      //       style: {
      //         // position:"fixed",
      //         whiteSpace: "nowrap",
      //         alignItem: "center",
      //         minWidth: '180px',
      //         maxWidth: '180px',
      //         padding: "1px 1px",
      //         background: "#143b64",
      //         color: "white",
      //         outline: "2px solid black",
      //         postition: 'sticky',
      //         left: '128px',
      //         zIndex: 101
      //       },
      //     }),
      //   },
      // },
      {
        label: "Site Id",
        name: "siteId",
        sortable: true,

        options: {
          filter: true,
          filterType: "textField",
          filterOptions: count.filterObj.companies,
          setCellProps: () => ({
            className: "custom-header-font",
            style: {
              // fontSize: "20px !important",
              // borderRight: "2px solid black",
              whiteSpace: "nowrap",
              alignItem: "center",
              position: "sticky",
              left: "130px",
              "min-width": "100px",
              "max-width": "100px",
              outline: "2px solid black",
              background: "White",
              zIndex: 100,
            },
          }),
          setCellHeaderProps: () => ({
            className: "custom-header-font",
            style: {
              // fontSize: "20px !important",
              whiteSpace: "nowrap",
              alignItem: "center",
              textAlign: "center",
              position: "sticky",
              left: "130px",
              background: "#143b64",
              color: "white",
              outline: "2px solid black",
              zIndex: 101,
              "min-width": "100px",
              "max-width": "100px"
            },
          }),
        },
      }
    );

  } else {
    columnMop.push(
      {
        name: "ticketIdLink",
        label: "Ticket Id",
        sortable: true,

        options: {
          filter: true,
          filterType: "textField",


          setCellProps: () => ({
            style: {
              whiteSpace: "wrap",

              position: "sticky",
              outline: "2px solid black",
              left: "1px",
              boxShadow: "5px 2px 5px grey",
              width: "80px",
              background: "White",
              zIndex: 100,
              minWidth: '80px',
              maxWidth: '80px',

            },
          }),
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: "wrap",
              minWidth: '80px',
              maxWidth: '80px',
              position: "sticky",
              paddingRight: 0,
              left: -1,
              width: "80px",
              outline: "2px solid black",
              background: "#143b64",
              boxShadow: "5px 2px 5px grey",

              color: "white",

              zIndex: 101,
            },
          }),
        },
      },

      // {
      //   name: "mopCreatedAt",
      //   label: "Ticket Creation Date Time",
      //   sortable: true,

      //   options: {
      //     // customBodyRender : value => <div style={{ border : "2px solid black" , height : '100%' , width : '100%'}}>{value}</div> ,
      //     filter: false,
      //     setCellProps: () => ({
      //       style: {
      //         outline: "2px solid black",
      //         // borderRight: "2px solid black",
      //         whiteSpace: "nowrap",
      //         alignItem: "center",
      //         position: "sticky",
      //         left: "80px",
      //         background: "White",
      //         zIndex: 100,
      //         postition: 'sticky',
      //         minWidth: "140px",
      //         // left : '60px',

      //       },
      //     }),
      //     setCellHeaderProps: () => ({
      //       style: {
      //         // position:"fixed",
      //         whiteSpace: "nowrap",
      //         alignItem: "center",
      //         minWidth: "140px",
      //         padding: "1px 1px",
      //         background: "#143b64",
      //         color: "white",
      //         outline: "2px solid black",
      //         postition: 'sticky',
      //         left: '80px',
      //         zIndex: 110
      //       },
      //     }),
      //   },
      // },
      {
        label: "Site Id",
        name: "siteId",
        sortable: true,

        options: {
          filter: true,
          filterType: "textField",

          setCellProps: () => ({
            className: "custom-header-font",
            style: {
              // borderRight: "2px solid black",
              whiteSpace: "nowrap",
              alignItem: "center",
              position: "sticky",
              left: "80px",
              outline: "2px solid black",
              background: "White",
              "min-width": "100px",
              "max-width": "100px",
              zIndex: 100,
            },
          }),
          setCellHeaderProps: () => ({
            className: "custom-header-font",
            style: {
              whiteSpace: "nowrap",
              alignItem: "center",
              textAlign: "center",
              position: "sticky",
              left: "80px",
              background: "#143b64",
              color: "white",
              outline: "2px solid black",
              zIndex: 101,
              "min-width": "100px",
              "max-width": "100px"
            },
          }),
        },
      }
    )
  }





  columnMop.push({
    name: "company",
    label: "Company",
    sortable: true,
    options: {
      filter: true,
      filterOptions: count.filterObj.companies,
      setCellHeaderProps: () => ({
        style: {
          minWidth: "200px",
          whiteSpace: "wrap",
          alignItem: "center",
          background: "#143b64",
          color: "white",
          paddingRight: "0px",
          outline: "1px solid black",
        },
      }),
      setCellProps: () => ({
        style: {
          paddingRight: "0px",
          minWidth: "100px",
          overflowY: "hidden",
          textAlign: "center",
          padding: "1px 1px",

          outline: "1px solid black",
          background: "White",
        },
      }),
    },
  },
    {
      name: "newSow",
      label: "SoW",
      sortable: true,
      options: {
        filter: true,
        filterOptions: count.filterObj.sowList,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            minWidth: "20px",
            background: "#143b64",
            color: "white",
            outline: "1px solid black",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "190px",
            padding: "1px 1px",

            outline: "1px solid black",
            background: "White",
          },
        }),
      },
    },
    {
      name: "mopStartTime",
      label: "Activity Start Date Time",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            minWidth: "120px",
            padding: "1px 1px",
            background: "#143b64",
            color: "white",
            padding: "1px 1px",
            outline: "1px solid black",
          },
        }),
      },
    },
    {
      name: "mopEndTime",
      label: "Activity End Date Time",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            minWidth: "120px",
            padding: "1px 1px",
            background: "#143b64",
            outline: "1px solid black",
            color: "white",
          },
        }),
      },
    },
    {
      name: "downMopStartTime",
      label: "Down Time Start",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            minWidth: "125px",
            padding: "1px 1px",
            background: "#143b64",
            outline: "1px solid black",
            color: "white",
          },
        }),
      },
    },
    {
      name: "downMopEndTime",
      label: "Down Time End",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            minWidth: "125px",
            padding: "1px 1px",
            background: "#143b64",
            color: "white",
            outline: "1px solid black",
          },
        }),
      },
    },
    {
      name: "mopDownTime",
      label: "Down Time (H)",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            minWidth: "74px",
            padding: "1px 1px",
            outline: "1px solid black",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            minWidth: "74px",
            overflowY: "hidden",
            textAlign: "center",
            padding: "1px 1px",
            outline: "1px solid black",

            background: "White",
          },
        }),
      },
    },





    {
      name: "rfSiteStatus",
      label: "MOP Status",
      sortable: true,
      options: {
        display: true,
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            "min-width": "100px",
            "max-width": "100px",
            background: "#143b64",
            outline: "1px solid black",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            textAlign: "center",
            "min-width": "100px",
            "max-width": "100px",
            padding: "1px 1px",
            background: "White",
            outline: "1px solid black",
          },
        }),
      },
    },
    {
      name: "mopRFSiteStatus",
      label: "MOP Status",
      sortable: true,
      options: {
        display: false,
        filter: true,
      },
    },


    {
      name: "ransiteStatus",
      label: "SoW Status",
      sortable: true,
      options: {
        filter: true,
        display: false,
        filterType: 'multiselect',
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            minWidth: "150px",
            // maxWidth:"3px",
            padding: "1px 1px",
            outline: "1px solid black",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "150px",
            padding: "1px 1px",

            outline: "1px solid black",
            background: "White",
          },
        }),
      },
    },


    {
      name: "showransiteStatus",
      label: "SoW Status",
      sortable: true,
      options: {
        filter: false,
        filterType: 'multiselect',
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            outline: "1px solid black",
            alignItem: "center",
            minWidth: "150px",
            // maxWidth:"3px",
            padding: "1px 1px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "150px",
            padding: "1px 1px",
            outline: "1px solid black",
            background: "White",
          },
        }),
      },
    },

    {
      name: "goNoNew",
      label: "GO/NO GO",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            outline: "1px solid black",
            padding: "1px 1px",
            background: "#143b64",
            color: "white",
          },
        }),
      },
    })
  if (
    userDetails.rolename == "GC Login" ||
    userDetails.rolename == "PAG Engineer" ||
    userDetails.rolename == "Manage Engineer" ||
    (userDetails.rolename == "Manage Engineer" && userDetails.department.values.includes("MOP"))
  ) {
    columnMop.push({
      name: "crewAdd",
      label: "Assign Crew",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            minWidth: "150px",
            outline: "1px solid black",
            padding: "1px 1px",
            background: "#143b64",
            color: "white",
          },
        }),
      },
    });
  }
  columnMop.push({
    name: "siteDevelopmentApprovalStatus",
    label: "Development Approval Status",

    sortable: true,
    options: {
      filter: false,
      setCellHeaderProps: () => ({
        style: {
          whiteSpace: "no wrap",
          alignItem: "center",
          minWidth: "145px",
          padding: "1px 1px",
          background: "#143b64",
          outline: "1px solid black",
          color: "white",
        },
      }),
      setCellProps: () => ({
        style: {
          overflowY: "hidden",
          textAlign: "center",
          padding: "1px 1px",
          outline: "1px solid black",
          minWidth: "25px",
          background: "White",
        },
      }),
    },
  },
    {
      name: "siteApprovalAction",
      label: "RF Approval Status",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            minWidth: "150px",
            padding: "1px 1px",
            outline: "1px solid black",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            overflowY: "hidden",
            textAlign: "center",
            padding: "1px 1px",
            minWidth: "80px",
            outline: "1px solid black",
            background: "White",
          },
        }),
      },
    },
    {
      name: "FOPSApprovalStatus",
      label: "FOPS Approval Status",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            minWidth: "125px",
            padding: "1px 1px",
            outline: "1px solid black",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            overflowY: "hidden",
            textAlign: "center",
            outline: "1px solid black",
            padding: "1px 1px",
            minWidth: "80px",
            background: "White",
          },
        }),
      },
    },
    {
      name: "PAGAdminRemarking",
      label: "PAG Admin Remarks",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            outline: "1px solid black",
            "min-width": "200px",
            "max-width": "200px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            textAlign: "center",
            "min-width": "200px",
            "max-width": "200px",
            outline: "1px solid black",
            padding: "1px 1px",
            background: "White",
          },
        }),
      },
    },
    {
      name: "devreasonaddon",
      label: "Development approval or reject comment",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            minWidth: "250px",
            outline: "1px solid black",
            padding: "1px 1px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            overflowY: "hidden",
            outline: "1px solid black",
            textAlign: "center",
            padding: "1px 1px",
            background: "White",
          },
        }),
      },
    },
    {
      name: "rfreasonaddon",
      label: "RF approval or reject comment",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            minWidth: "250px",
            padding: "1px 1px",
            background: "#143b64",
            outline: "1px solid black",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            textAlign: "center",
            padding: "1px 1px",
            background: "White",
            outline: "1px solid black",
          },
        }),
      },
    },
    {
      name: "fopsreasonaddon",
      label: "FOPS approval or reject comment",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            minWidth: "250px",
            padding: "1px 1px",
            outline: "1px solid black",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            overflowY: "hidden",
            textAlign: "center",
            padding: "1px 1px",
            outline: "1px solid black",
            background: "White",
          },
        }),
      },
    },



    {
      name: "LastUpdatedSiteDevelopment",
      label: "Development Approve/ Reject Date",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            minWidth: "160px",
            padding: "1px 1px",
            outline: "1px solid black",
            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "LastUpdatedRF",
      label: "RF Approve/ Reject Date",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            minWidth: "150px",
            outline: "1px solid black",
            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "LastUpdatedOperation",
      label: "FOPS Approve/ Reject Date",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            minWidth: "155px",
            padding: "1px 1px",
            background: "#143b64",
            outline: "1px solid black",
            color: "white",
          },
        }),
      },
    })

  if (userDetails.rolename == "GC Login") {
    columnMop.push(
      {
        name: "update",
        label: "Update",
        sortable: true,
        options: {
          filter: false,
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: "wrap",
              outline: "1px solid black",
              alignItem: "center",

              padding: "1px 1px",
              background: "#143b64",
              color: "white",
            },
          }),
        },
      },
      {
        name: "duplicate",
        label: "Duplicate",
        sortable: true,
        options: {
          filter: false,
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: "wrap",
              outline: "1px solid black",
              alignItem: "center",
              minWidth: "150px",
              padding: "1px 1px",
              background: "#143b64",
              color: "white",
            },
          }),
        },
      }
    );
  }


  columnMop.push(
    {
      name: "mopCreatedAt",
      label: "Ticket Creation Date Time",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            minWidth: "150px",
            outline: "1px solid black",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            textAlign: "center",
            minWidth: "135px",
            padding: "1px 1px",
            background: "White",
            outline: "1px solid black",
          },
        }),
      },
    },
    {
      name: "mopUpdatedAt",
      label: "GC Last Updated Time",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            minWidth: "150px",
            outline: "1px solid black",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            textAlign: "center",
            minWidth: "135px",
            padding: "1px 1px",
            background: "White",
            outline: "1px solid black",
          },
        }),
      },
    },
    {
      label: "Project Code",
      name: "newprojectCode",
      sortable: true,
      options: {
        filter: false,
        setCellProps: () => ({
          style: {
            outline: "2px solid black",
            whiteSpace: "pre-wrap",
            alignItem: "center",
            minWidth: "300px",
            maxWidth: "300px",
            background: "White",
            outline: "1px solid black",
            zIndex: 99,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            textAlign: "center",
            background: "#143b64",
            outline: "1px solid black",
            color: "white",
            zIndex: 100,
          },
        }),
      },
    },
    {
      name: "market",
      label: "Market",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            background: "#143b64",
            color: "white",
            outline: "1px solid black",
          },
        }),
        setCellProps: () => ({
          style: {
            overflowY: "hidden",
            textAlign: "center",
            padding: "1px 1px",
            outline: "1px solid black",

            background: "White",
          },
        }),
      },
    },

    {
      name: "newSectorsImpacted",
      label: "Sectors Impacted",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            // alignItem: "center",
            // overflow: "hidden",
            background: "#143b64",
            color: "white",
            outline: "1px solid black",
            minWidth: "175px",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            // overflowY: "hidden",
            minWidth: "175px",
            padding: "1px 1px",
            outline: "1px solid black",
            background: "White",
          },
        }),
      },
    },
    {
      name: "temp",
      label: "Technology Affected",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            overflow: "hidden",
            background: "#143b64",
            margin: "auto",
            "min-width": "350px",
            outline: "1px solid black",
            "max-width": "6000px",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",

            padding: "1px 1px",
            outline: "1px solid black",

            background: "White",
          },
        }),
      },
    },
    {
      name: "temptechByTech",
      label: "Technology By Technology Outage",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            overflow: "hidden",
            background: "#143b64",
            margin: "auto",
            "min-width": "350px",
            outline: "1px solid black",
            "max-width": "6000px",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",

            padding: "1px 1px",
            outline: "1px solid black",

            background: "White",
          },
        }),
      },
    },
    {
      name: "typeOfWork",
      label: "Type Of Work(SI/NSI)",
      sortable: true,
      options: {
        filter: true,
        filterOptions: count.filterObj.typeOfWork,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            textAlign: "left",
            outline: "1px solid black",
            minWidth: "125px",
            padding: "1px 1px 1px 0px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            minWidth: "125px",
            overflowY: "hidden",
            textAlign: "center",
            padding: "1px 1px",
            outline: "1px solid black",
            background: "White",
          },
        }),
      },
    },

    {
      name: "crNumber",
      label: "CR #",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",

            background: "#143b64",
            color: "white",
            outline: "1px solid black",
          },
        }),
        setCellProps: () => ({
          style: {
            overflowY: "hidden",
            textAlign: "center",
            padding: "1px 1px",
            outline: "1px solid black",
            background: "White",
          },
        }),
      },
    },
    {
      name: "group",
      label: "Development or Operations Work",
      sortable: true,
      options: {
        filter: true,
        filterOptions: count.filterObj.vendors,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            minWidth: "150px",
            padding: "1px 1px",
            background: "#143b64",
            outline: "1px solid black",
            color: "white",
          },
        }),
      },
    },
    {
      name: "crewvendor",
      label: "Vendor",
      sortable: true,
      options: {
        filter: true,
        filterOptions: count.filterObj.vendors,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            minWidth: "150px",
            padding: "1px 1px",
            background: "#143b64",
            outline: "1px solid black",
            color: "white",
          },
        }),
      },
    },
    {
      name: "cpyowner",
      label: "Owner",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            overflow: "visible",
            background: "#143b64",
            outline: "1px solid black",
            color: "white",
          },
        }),
      },
    },
    {
      name: "newsowDetail",
      label: "SoW Details",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            "min-width": "250px",
            outline: "1px solid black",
            background: "#143b64",
            color: "white",
          },
        }),
      },
    },

    {
      name: "justificationrequestnew",
      label: "Justification Request",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            outline: "1px solid black",
            minWidth: "250px",
            padding: "1px 1px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            textAlign: "center",
            padding: "1px 1px",
            outline: "1px solid black",
            background: "White",
          },
        }),
      },
    },
    {
      name: "justificationCommentNew",
      label: "Justification Comments",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            minWidth: "250px",
            padding: "1px 1px",
            outline: "1px solid black",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            textAlign: "center",
            padding: "1px 1px",
            outline: "1px solid black",
            background: "White",
          },
        }),
      },
    },



    // {
    //   name: "ownerEmail",
    //   label: "Owner Email",
    //   sortable: true,
    //   options: {
    //     filter: false,
    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",
    //  alignItem:"center",

    //         background: "#143b64",
    //       },
    //     }),
    //   },
    // },
    // {
    //   name: "OwnerContact",
    //   label: "Owner Contact",
    //   sortable: true,
    //   options: {
    //     filter: false,
    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",
    // alignItem:"center",

    //         background: "#2393b9",
    //       },
    //     }),
    //   },
    // },

    {
      name: "sectorByOutage",
      label: "Sector by sector Outage",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            minWidth: "125px",
            outline: "1px solid black",
            padding: "1px 1px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            overflowY: "hidden",
            textAlign: "center",
            padding: "1px 1px",
            outline: "1px solid black",
            background: "White",
          },
        }),
      },
    },
    {
      name: "CraneNeeded",
      label: "Crane Needed",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            outline: "1px solid black",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            overflowY: "hidden",
            textAlign: "center",
            padding: "1px 1px",
            outline: "1px solid black",
            background: "White",
          },
        }),
      },
    },


    {
      name: "slogger",
      label: "Site Status Logs",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            background: "#143b64",
            minWidth: "110px",
            padding: "1px 1px",
            outline: "1px solid black",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "80px",
            padding: "1px 1px",
            outline: "1px solid black",
            background: "White",
          },
        }),
      },
    },
    {
      name: "wdeatil",
      label: "Wdetail",
      sortable: true,
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            color: "white",
            outline: "1px solid black",
          },
        }),
      },
    },
    // {
    //   label:"Map View",
    //   name:"map",
    //   options: {
    //             filter: true,
    //             setCellHeaderProps: () => ({
    //               style: {
    //                 whiteSpace: "wrap",
    // alignItem:"center",

    //                 background: "#143b64",

    //               }
    //             })
    //           }
    // }
    //,

    {
      name: "newdevstatus",
      label: "Site Development Approval Status",
      sortable: true,
      options: {
        display: false,
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            minWidth: "150px",
            padding: "1px 1px",
            background: "#143b64",
            outline: "1px solid black",
            color: "white",
          },
        }),
      },
    },
    {
      name: "newrfstatus",
      label: "RF Approval Status",
      sortable: true,
      options: {
        display: false,
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            minWidth: "150px",
            padding: "1px 1px",
            outline: "1px solid black",
            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "goNo",
      label: "GoNo",
      sortable: true,
      options: {
        display: false,
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",

            outline: "1px solid black",
            padding: "1px 1px",
            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "newfopsstatus",
      label: "FOPS Approval Status",
      sortable: true,
      options: {
        display: false,
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            minWidth: "125px",
            outline: "1px solid black",
            padding: "1px 1px",
            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
  );




  columnMop.push(



  )
  const openModalHandler = (item) => {
    setSiteDetailInfo(item);
    let tempModalData = Object.keys(item).map((key, index) => {
      //console.log("mnijioubniulbodbfvodr=", key, index);
      if (key != "action") {
        return (
          <>
            {" "}
            <hr />
            <div className="form-group col-md-6  col-sm-12 col-xm-12">
              <label htmlFor="inputPassword4">{key}</label>

              <br></br>
              <label>{item[key]}</label>
            </div>
            <hr />
          </>
        );
      }
    });
    setModalData(tempModalData);
  };
  const dispatch = useDispatch();
  function accordianHandler() {
    setAccordianState(!accordianState);
  }

  let current = new Date()
  let timeznal = fun_time_zonechanger(commonzone, current)
  function showDetails(id) {
    setXlShow(true);
    setSiteId(id);
    //console.log("dfghedrhetsetghrrtd", id);
    dispatch(GetTimeLineAction(timelineApi, id));
    dispatch(FetchSingleRecordAction(singleProjectApi, id));
  }
  let height_screen;
  height_screen = window.innerHeight - 100;

  //console.log(height_screen, "height_screen");

  createTheme("solarized", {
    text: {
      primary: "inherit",
      secondary: "inherit",
    },
    background: {
      default: "inherit",
    },
    context: {
      background: "inherit",
      text: "inherit",
    },
    divider: {
      default: "inherit",
    },
    button: {
      default: "inherit",
      hover: "rgba(0,0,0,.08)",
      focus: "rgba(255,255,255,.12)",
      disabled: "rgba(255, 255, 255, .34)",
    },
    fontSize: "10px",
    sortFocus: {
      default: "inherit",
    },
  });
  // muidatatable all theme //
  // let getMuiTheme = () => createTheme({
  //   components: {
  //     MuiButtonBase: {
  //       styleOverrides: {
  //         root: {
  //           "line-height": "1 !important",
  //           "padding": "6px 0px"
  //         },
  //       }
  //     },
  //     MUIDataTableBodyCell: {
  //       styleOverrides: {
  //         root: {
  //           maxHeight: 45,
  //           fontSize: 13,
  //           padding: "0px 2px !important",
  //           margin: 0,
  //           overflow: "hidden"
  //         },
  //       }
  //     },
  //     // MuiToolbar: {
  //     //   styleOverrides: {
  //     //     root: {
  //     //       backgroundColor: '#f00',

  //     //     },
  //     //   },
  //     // },
  //     MuiTableCell: {
  //       styleOverrides: {
  //         head: {

  //           maxHeight: 20,
  //           fontSize: 15,
  //           margin: 0,
  //           paddingTop: 0,
  //           paddingBottom: 0
  //         },
  //       },
  //     },
  //     MUIDataTableSelectCell: {
  //       styleOverrides: {
  //         headerCell: {
  //           backgroundColor: 'blue',
  //         },
  //       },
  //     },
  //     MuiTableFooter: {
  //       styleOverrides: {
  //         root: {
  //           '& .MuiToolbar-root': {
  //             backgroundColor: 'white',
  //           },
  //         },
  //       },
  //     },
  //   },
  // });

  //on submiting the excel
  const submitHandler = async (e, check = null) => {
    e.preventDefault();
    //console.log(showpdfpopup, "showpdfpopup11");
    showpdfpopup = true;
    //console.log(showpdfpopup, "showpdfpopup12");
    let form = new FormData();
    form.append("uploadFile", siteFile);
    form.append("uploadfor", "bulkMopOperation");
    //console.log(
    //   "resp",
    //   dispatch(PDFUploadActionreturn(CsvUpload, form, "mopbulkfile"))
    // );
    //console.log("form data =", form);
  };
  //console.log("the excel data is =", excelrowData);
  const uploadCsvFile = (e) => {
    let files = [];
    // let fileArray = [];
    // files.push(e.target.files)
    // //console.log("the filre is =",e.target.files[0])
    // for (let i = 0; i < files[0].length; i++) {
    //   fileArray.push(URL.createObjectURL(files[0][i]))
    // }

    //console.log(e.target.files[0], "ON_CHANGE_FILES:", e.target.files.length);

    uploadedFile(e.target.files[0]);
  };

  const setDataVariables = async () => {
    dispatch(MopDatabaseAction(mopDataApi, true));
  };

  const AddButton = () => (
    <>
      {/* <fregment style={{ float: "right" }}> */}
      {/* {
              userDetails.rolename == "FOPS" ||
              userDetails.rolename == "Development" ? (
                <>
                  <button
                    className="btn btn-xs btn-primary"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Bulk Approve "
                    onClick={() => {
                      BulkApproveFun(userDetails.rolename == "FOPS"?"Operations":"Development");
                    }}
                  >
                    <GradingSharpIcon />
                  </button>
                </>
              ) : (
                ""
              )} */}
      <p onClick={filterhandler} className=" mx-2 " style={{ "align-self": "center" }}><FilterData /></p>

      <>

        <button
          className="btn btn-xs btn-primary mx-2 my-3"
          data-toggle="tooltip"
          data-placement="bottom"
          title="Refresh"
          onClick={() => { ClearFilter(); setFilterBadge() }}
          style={{ padding: "6px" }}
        >
          <RefreshIcon />
        </button>

      </>

      {userDetails.rolename == "RF Engineer" ||
        userDetails.rolename == "FOPS" ||
        userDetails.rolename == "Development" ? (
        <>
          <button
            style={{ minHeight: "0px" }}
            className="btn btn-xs btn-primary"
            data-toggle="tooltip"
            data-placement="top"
            title="Bulk Approve "
            onClick={() => {
              BulkUpload();
            }}
          >
            <GradingSharpIcon />
          </button>
        </>
      ) : (
        ""
      )}
      {/* {userDetails.rolename == "RF Engineer" ? ( */}
      {1 == 1 ? (
        <>
          <button
            className="btn btn-xs btn-primary mx-2 my-3"
            style={{ padding: "6px" }}
            data-toggle="tooltip"
            data-placement="top"
            title="Map View "
            onClick={() => setMapModal(true)}
          >
            <PlaceIcon />
          </button>
        </>
      ) : (
        ""
      )}
      {/* <button className="btn btn-xs btn-primary mx-2 my-3 " data-toggle="tooltip" data-placement="top" title=" Date Filter " onClick={() => setDatePicker(!showDatePicker)}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z" />
                <path d="M6.17 18a3.001 3.001 0 0 1 5.66 0H22v2H11.83a3.001 3.001 0 0 1-5.66 0H2v-2h4.17zm6-7a3.001 3.001 0 0 1 5.66 0H22v2h-4.17a3.001 3.001 0 0 1-5.66 0H2v-2h10.17zm-6-7a3.001 3.001 0 0 1 5.66 0H22v2H11.83a3.001 3.001 0 0 1-5.66 0H2V4h4.17zM9 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm6 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-6 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
              </svg>
            </button> */}
      {userDetails.rolename == "RF Engineer" ||
        userDetails.rolename == "FOPS" ||
        userDetails.rolename == "Development" ? (
        <>
          {/* <button
              className="btn btn-xs btn-primary mx-2  my-3"
              data-toggle="tooltip"
              data-placement="top"
              title="Select All Table"
              onClick={() => BulkSelect()}
            >
              <SelectAllIcon />
            </button> */}
          {/* <button
                    className="btn btn-xs btn-danger mx-2 my-3"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Bulk Delete "
                    onClick={() => BulkDelete()}
                  >
                    <Delete />
                  </button> */}
        </>
      ) : (
        ""
      )}
      {/* </fregment> */}
      <div id="mopDatePickerContainer" style={{ display: "flex" }}>
        <>
          <DatePicker

            placeholderText="MM/DD/YYYY"
            // id="color-of-datepicker"
            popperPlacement='left'
            className="form-control"
            selected={fstartdate}


            onChange={(e) => {

              setfstartdate(e);
              // setfenddate(e);
            }}
            dateFormat="MM/dd/yyyy"
          // minDate={new Date()}
          />
          <DatePicker
            placeholderText="MM/DD/YYYY"
            className="form-control"

            // id="color-of-datepicker"
            popperPlacement='left'
            selected={fenddate}
            onChange={(e) => {
              setfenddate(e);

            }}
            dateFormat="MM/dd/yyyy"
            minDate={fstartdate}
          />
        </>
      </div>

      <Button onClick={filterdate} className="btn btn-xs btn-primary mx-2 py-2">
        Filter
      </Button>

      <Button onClick={exportAllData} className="btn btn-xs btn-primary mx-2 py-2">
        Export
      </Button>
      {/* <ExportToExcel

        apiData={excelrowData}
        fileName={"MopRequest_" + moment(new Date()).format("MM-DD-YYYY")}
      /> */}
    </>
  );
  let filteredDataii = [];
  const handleChangePage = (event, newPage) => {
    // setPageIndex(newPage);
    //console.log("the curreent page is =", event)
  };
  function filterhandler() {

    dispatch(getFilterDropDown([]))
    dispatch(AccountReducerAction(mopFilters))
    setcommonModalState(true)
    setcommonModalBody(<MopFilterList setfullUrL={setfullUrL} setcommonModalState={setcommonModalState} setFilterBadge={setFilterBadge} featureExport={setexportApiData} />)
    setcommonModalTitle("MOP Filters")
    setcommonModalHeader("MOP Filter")

  }

  function addtomap(action, tableState) {
    //console.log(tableState.columns.findIndex(element => element.name == "ticketId"), tableState.columns.findIndex(element => element.name == "siteId"), tableState, "e.tableState")

    let oneDa = []
    let twoDa = []
    let filli = tableState.displayData.map((e) => {
      //console.log(e, "e.data")
      filteredDataii.push("hello")
      oneDa.push(e.data[tableState.columns.findIndex(element => element.name == "ticketId")])
      twoDa.push(e.data[tableState.columns.findIndex(element => element.name == "siteId")])
      return ""
    })

    if (filliopen) {
      //console.log(filli, "fillifilli")
      setfilteredData([oneDa, twoDa])
      setfilliopen(false)
    }

  }

  //console.log(RowCount, counterPP, currentPP, "currentPPcurrentPPcurrentPP")
  //console.log(totalTicketsinMop, "totalTicketsinMop")
  //muidatatable realated option part//
  //console.log(RowCount, "RowCountRowCountRowCountRowCount")
  if (ResetFilter) {
    setcurrentPP(0)
    setResetFilter(false)
  }
  const options = {
    download: false,
    viewColumns: false,

    // customToolbar:<p onClick={filterhandler}> filter</p>,

    // sortOrder: {
    //   name: 'ticketId',
    //   direction: 'desc'
    // },
    // rowsPerPage: 25,
    rowsPerPageOptions: [15, 30, 45],
    // setFilterChipProps: (colIndex, colName, data) => {
    //   //console.log("xkhabdgjhwvxhdkvwed==",colIndex, colName, data);
    // },
    onFilterChange: (columnChanged, columnId, value) => {
      // //console.log(`onFilterChange columnChanged: ${columnChanged}`);
      //console.log("onFilterChange columnChanged: ", value);
      //console.log("onFilterChange columnChanged: ", columnId);
      let filter = ""
      columnId.map((item, index) => {
        if (item.length > 0) {
          filter = filter + columnMop[index].name + "=" + item.join(",") + "&"
        }
      })
      console.log("thbhjbflfv =", filter)
      dispatch(MopDatabaseAction(mopDataApi + "?" + filter.slice(0, -1), true))


    },
    fixedSelectColumn: true,

    print: false,
    onFilterModelChange: () => {

    },
    filtering: false,
    // tableBodyMaxHeight: "600px",
    customToolbar: AddButton,
    searchPlaceholder: "Search by Site ID",

    onFilterDialogClose: (changedColumn, filterList) => {
      //console.log("onFilterDialogClose filter=", changedColumn, filterList);
      setfilliopen(true)
    },
    onFilterChipClose: () => {
      setfilliopen(true)
    },
    count: totalTicketsinMop,

    // onFilterChange: (changedColumn, filterList) => {
    //   //console.log("inside filter=", changedColumn, filterList);
    // },
    onTableChange: addtomap,

    // setfilteredData(filteredDataii)
    // //console.log("filteredData_filteredData=", filteredDataii, tableState.displayData)
    // },
    selectableRows: false,
    responsive: "scroll",
    overflowY: "scroll",

    filter: false,
    rowsPerPage: counterPP,
    // onChangeRowsPerPage: rowsPerPage => {
    //   // setState(rowsPerPage);
    //   setCountPP(rowsPerPage)
    // },

    onChangeRowsPerPage: rowsPerPage => {
      // setState(rowsPerPage);
      setCountPP(rowsPerPage)

      // setagdm(true)

      //console.log(currentPP, RowCount > rowsPerPage)
      // if(RowCount>rowsPerPage){

      // }
      // dispatch(MopDatabaseAction(`${mopDataApi}?sp=${rowsPerPage}&cp=${currentPP}`));

    },
    onChangePage: currentPage => {
      // setState(rowsPerPage);
      console.log("innnnnn")
      setcurrentPP(currentPage)
      if (currentPage > maxPage) {
        setmaxpage(currentPage)
      }

      // if(currentPage*currentPP)

      //console.log(RowCount, currentPage + 1, (currentPage + 1) * counterPP, "RowCount,counterPPRowCount,counterPP")
      // if(currentPP<currentPage && currentPage>maxPage){
      //   // setagdm(true)
      //   dispatch(MopDatabaseAction(`${mopDataApi}?sp=${counterPP}&cp=${currentPage}`));
      // }

      let dateUrl = ""
      let totalRow = rowData?.length
      console.log(totalRow,"tototoalallrowww")
      if (fenddate && fstartdate) {
        dateUrl = `&start=${moment(fstartdate).format("MM/DD/YYYY")}&end=${moment(fenddate).format("MM/DD/YYYY")}`
      }
      let start = ""
      let end = 0
      console.log(newStart, "newStartnewStartnewStart")
      if (newStart < (currentPage + 1) * counterPP) {
        start = "sp=" + newStart + "&cp=" + 50
        end = 1
        // newStart=newStart+50
      } else {
        if (newStart < totalTicketsinMop) {
          start = "sp=" + newStart + "&cp=" + 50
          end = 1
          // newStart=newStart+50
        }
      }


      if (end == 1) {
        //console.log(start, "startstartstart")
        // customDispatch(networkGetCSVReducerAction("/networkDatabase" + dynamic_dashboard+start));

        let Filleddata = dispatch(LCLmopDashboardReducerAction())
        console.log(Filleddata,"FilleddataFilleddata")
        console.log(Filleddata,"||",start,"||",fullUrL,"_____apiData______")
        dispatch(MopDatabaseAction(`${mopDataApi}${Filleddata}${Filleddata == "" ? "?" + start : "&" + start}${fullUrL != "" ? "&" + fullUrL.replace("/listMops?", "") : ""}`, false, false));
        setnewStart(newStart + 50)
      }
      // if(RowCount<(currentPage+1)*counterPP){
      // }else{
      //   if(count.MopCount>RowCount+1){
      //     dispatch(MopDatabaseAction(`${mopDataApi}?sp=${RowCount+1}&cp=${RowCount+100}${dateUrl}`,false));
      //   }
      // }

      //console.log(currentPage, "currentPagecurrentPagecurrentPage")

    },

    // searchOpen: true,
    // disable search icon
    search: false,

    // onDownload: (buildHead, buildBody, columns, data) => {
    //   buildHead = () => {
    //      return ["col-1","col-2"]
    //   }
    //   buildBody = () => {
    //    return [["A", "B"],["C", "D"]];
    //   }
    //   return "\uFEFF" + buildHead() + buildBody();
    // },
    // rowsSelected: selectedRows,
    // rowSelectionKey: "id",
    // onRowSelectionChange: (
    //      currentRowsSelected,
    //      allRowsSelected,
    //      rowsSelected,  // this is the array of `rowSelectionKey` values
    //    ) => {
    //       setSelectedRows(allRowsSelected)
    //    },
    // onTableChange: (action, state) => {
    //   //console.log(action);
    //   //console.dir(state);
    // },
    // customToolbarSelect: selectedRows => (
    //   <Tooltip title="edit">
    //     <button
    //       onClick={() => {
    //         //console.log("the selected rows =",selectedRows)
    //         // //console.log(rows[selectedRows.data[0].dataIndex]);
    //       }}
    //       style={{
    //         marginRight: "24px",
    //         height: "48px",
    //         top: "50%",
    //         display: "block",
    //         position: "relative",
    //         transform: "translateY(-50%)"
    //       }}
    //     >Click Me
    //       {/* <EditIcon /> */}
    //     </button>
    //   </Tooltip>
    // ),

    filterType: "dropdown",
    // responsive: "scrollfullHeight",
    fixedHeader: true,
    fixedFooter: true,
    page: currentPP,


    // expandableRows: false,
    // expandableRows:true,
    // renderExpandableRow:(data,rowMeta)=>{
    //   //console.log(" ",data,"  rowMeta =",rowData[rowMeta.rowIndex])
    //   return (
    //     <React.Fragment>
    //         <div>
    //           <ul className='row mx-2 '>
    //             <li className='col-md-5'><span>Market :</span><span>{rowData[rowMeta.rowIndex].market}</span></li>
    //             <li className='col-md-5'><span>Account :</span><span>{rowData[rowMeta.rowIndex].account}</span></li>
    //             <li className='col-md-5'><span>Group :</span><span>{rowData[rowMeta.rowIndex].group}</span></li>
    //             <li className='col-md-5'><span>Account :</span><span>{rowData[rowMeta.rowIndex].account}</span></li>
    //             <li className='col-md-5'><span>Company :</span><span>{rowData[rowMeta.rowIndex].company}</span></li>
    //             <li className='col-md-5'><span>Owner :</span><span>{rowData[rowMeta.rowIndex].owner}</span></li>
    //             <li className='col-md-5'><span>Owner-Email :</span><span>{rowData[rowMeta.rowIndex].email}</span></li>
    //             <li className='col-md-5'><span>Owner Contact :</span><span>{rowData[rowMeta.rowIndex].OwnerContact}</span></li>
    //             <li className='col-md-5'><span>Type Of Work(SI/NSI) :</span><span>{rowData[rowMeta.rowIndex].typeOfWork}</span></li>
    //             <li className='col-md-5'><span>Sector by sector Outage :</span><span>{rowData[rowMeta.rowIndex].sectorByOutage}</span></li>
    //             <li className='col-md-5'><span>Crane Needed :</span><span>{rowData[rowMeta.rowIndex].CraneNeeded}</span></li>
    //             <li className='col-md-5'><span>CR NO :</span><span>{rowData[rowMeta.rowIndex].crNumber}</span></li>
    //             <li className='col-md-5'><span>Justification Comments :</span><span>{rowData[rowMeta.rowIndex].justificationComment}</span></li>
    //             <li className='col-md-5'><span>Start Date Time :</span><span>{rowData[rowMeta.rowIndex].startTime}</span></li>
    //             <li className='col-md-5'><span>End Date Time :</span><span>{rowData[rowMeta.rowIndex].endTime}</span></li>
    //             <li className='col-md-5'><span>Down Time (H) :</span><span>{rowData[rowMeta.rowIndex].DownTime}</span></li>
    //             <li className='col-md-5'><span>Sectors Impacted :</span><span>{rowData[rowMeta.rowIndex].sectorsImpacted ?
    //               rowData[rowMeta.rowIndex].sectorsImpacted.values.map((item)=>{
    //                 return item+','
    //               })
    //               :''
    //           }</span></li>
    //             < li className='col-md-5'><span>SoW :</span><span>{rowData[rowMeta.rowIndex].sowDetails ?
    //               rowData[rowMeta.rowIndex].sowDetails.values.map((item)=>{
    //                 return item+','
    //               })
    //               :''
    //           }</span></li>
    //             <li className='col-md-5'><span>Technology :</span><span>{rowData[rowMeta.rowIndex].technology.values.length?
    //               rowData[rowMeta.rowIndex].technology.values.map((item)=>{
    //                 return item+','
    //               })
    //               :''
    //           }</span></li>
    //             <li className='col-md-5'><span>SoW  Details :</span><span>{rowData[rowMeta.rowIndex].sowDetail}</span></li>
    //             <li className='col-md-5'><span>MOP :</span><span>{rowData[rowMeta.rowIndex].mop}</span></li>
    //             {/* <li><span>Account :</span><span>{rowData[rowMeta.rowIndex].account}</span></li> */}
    //           </ul>
    //         </div>

    //     </React.Fragment>
    //   )
    // }
  };

  const exportAllData = () => {

    let Filleddata = dispatch(LCLmopDashboardReducerAction())
    dispatch(ExportXLSXDownloadReducerAction(export_mop, "MopRequest_" + moment(new Date()).format("MM-DD-YYYY"), Filleddata))
    // FileSaver.saveAs(baseURL+"/export/mop", "MopRequest_" + moment(new Date()).format("MM-DD-YYYY"))
    // history.push(baseURL+"/export/mop")
  }

  const filterdate = () => {
    //console.log(fstartdate, fenddate, "fstartdate,fenddate");

    if (fenddate && fstartdate) {
      let customFullUrl = `${mopDataApi}?start=${moment(fstartdate).format("MM/DD/YYYY")}&end=${moment(fenddate).format("MM/DD/YYYY")}`

      setexportApiData(customFullUrl)
      let Filleddata = dispatch(LCLmopDashboardReducerAction({ dateUrl: `start=${moment(fstartdate).format("MM/DD/YYYY")}&end=${moment(fenddate).format("MM/DD/YYYY")}` }))

      console.log(Filleddata, "FilleddataFilleddata")
      dispatch(MopDatabaseAction(mopDataApi + Filleddata), true);

      setResetFilter(true)
      return;
    }

    if (fstartdate == undefined && fenddate == undefined) {
      swal("Oops", "Please select Start & End Date", "info");
      return;
    }
    if (fstartdate == undefined) {
      swal("Oops", "Please select Start Date", "info");
      return;
    }

    if (fenddate == undefined) {
      swal("Oops", "Please select End Date", "info");
      return;
    }
  };

  const filehandleClose = () => {
    reset({});
    setfileShow(false);
  };
  const filehandleShow = () => {
    reset({});
    setfileShow(true);
  };

  const closehandleClose = () => {
    reset({});
    setCsrArraymsg0("");
    setCsrArraymsg1("");
    setCsrArraymsg2("");
    setexportApiData(mopDataApi)
    dispatch(MopDatabaseAction(mopDataApi));
    setshowmodalfileshow(false);
  };

  const closehandleCloseweather = () => {
    reset({});
    settempertext("");
    settempericon("");
    setshowmodalweather(false);
  };

  // useEffect(() => {
  //   setModalState(location.pathname == "/PAG/Mop/database/open" ? true : false);
  //   if (modalState) {
  //     if (userDetails.rolename == "GC Login") {
  //       fetechDetail(undefined, "Add");
  //     }
  //   }
  // }, [modalState]);

  useEffect(() => {

    //console.log("useEffect of mop Dashboard", "AGENC")

    dispatch(allMapSiteReducerAction(allMapSites))
    dispatch(AccountReducerAction(mopDashSiteStatus))
    dispatch(AccountReducerAction(mopFilters))
    setModalState(location.pathname == "/PAG/Mop/database/open" ? true : false);
    if (modalState) {
      if (userDetails.rolename == "GC Login") {

        fetechDetail(undefined, "Add", "Create MOP");
        setHeaderTitle("Create MOP")
      }
    }
    dispatch(AccountReducerAction(siteDblistSiteId));
    setDataVariables();
    setexportApiData(mopDataApi)
  }, [count.totalTickets]);


  //console.log("filteredData_filteredData", filteredData, filteredData)

  return (
    <fregment style={{ overflow: "hidden", height: "100%" }}>
      <CustomeModal
        xlShow={mapModal}
        setXlShow={setMapModal}
        Body={AdvMapView}
        filteredMapData={filteredData}
        Header={"MAP View"}
        modalSize={"xl"}
      />

      {/* <CommonModal 
        modalSize={"xm"} 
        setXlShow={setMapModal} 
        xlShow={mapModal} 
        Body={MapView} 
        Header={"MAP View"}/> */}

      <Modal
        size="xl"
        show={xlShow}
        onHide={() => setXlShow(false)}
        aria-labelledby="example-modal-sizes-title-xl"
        dropdown="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-xl">
            <p>Site Details</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">{/* <SingleProject id={siteUId}/> */}</div>

          <Row>
            <Col xl={8}>
              <Card>
                <Card.Header>
                  <Card.Title>Summary</Card.Title>
                </Card.Header>
                <Card.Body>
                  {/* <!-- Nav tabs --> */}
                  <div className="default-tab">
                    <Tabs
                      defaultActiveKey="ran"
                      id="uncontrolled-tab-example"
                      className="mb-3"
                    >
                      <Tab eventKey="site" title="Site Details">
                        <ProjectData id={siteUId} />
                      </Tab>
                      {/* <Tab eventKey="map" title="MAP View"> */}
                      <>
                        {/*Google map*/}
                        {/* <div
    id="map-container-google-1"
    className="z-depth-1-half map-container"
    style={{ height: 300 }}
  >
    <iframe
      src="https://maps.google.com/maps?q=manhatan&t=&z=13&ie=UTF8&iwloc=&output=embed"
      frameBorder={0}
      style={{ border: 0,height:300 }}
      allowFullScreen="true"
    />
  </div> */}
                        {/*Google Maps*/}
                      </>

                      {/* </Tab> */}
                      <Tab eventKey="ran" title="RAN Historical">
                        <RanHistorical id={siteUId} />
                      </Tab>
                    </Tabs>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col xl={4}>
              <Card>
                <Card.Header>
                  <Card.Title>MAP</Card.Title>
                </Card.Header>
                <Card.Body>
                  {/* <!-- Nav tabs --> */}
                  <div className="custom-tab-1">
                    <Tabs
                      defaultActiveKey="map"
                      id="uncontrolled-tab-example"
                      className="mb-3"
                    >
                      <Tab eventKey="map" title="MAP View">
                        <>
                          {/*Google map*/}
                          <div
                            id="map-container-google-1"
                            className="z-depth-1-half map-container"
                            style={{ height: 300 }}
                          >
                            <iframe
                              src="https://maps.google.com/maps?q=manhatan&t=&z=13&ie=UTF8&iwloc=&output=embed"
                              frameBorder={0}
                              style={{ border: 0, height: 300 }}
                              allowFullScreen="true"
                            />
                          </div>
                          {/*Google Maps*/}
                        </>
                      </Tab>
                    </Tabs>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <div className="row">
        <div className="col-md-6">{/* <h4>Mop Dashboard</h4> */}</div>
      </div>

      <div
        className="col-12"
        style={{ display: showDatePicker ? "block" : "none" }}
      >
        <div className="card">
          <div className="card-header bg-primary">
            <div className="card-title">
              <i className="fa fa-filter mx-2"></i>
              Filter
            </div>
          </div>
          <div className="card-body">
            <form onSubmit={(e) => setDateFilter(e)}>
              <DateRange
                editableDateInputs={true}
                onChange={(item) => setDateRange([item.selection])}
                moveRangeOnFirstSelection={false}
                ranges={dateRange}
              />
              <Button type="submit" className="btn-xl ml-2">
                Submit
              </Button>
            </form>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Project detail
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">X</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-row">{modalData}</div>
              </form>
            </div>
            {/* <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          data-dismiss="modal"
        >
          Close
        </button>
        <button type="button" className="btn btn-primary">
          Save changes
        </button>
      </div> */}
          </div>
        </div>
      </div>

      <div className="col-md-13" style={{ padding: "10px" }}>
        {/* <div className="form-group col-md-4">
          <label for="exampleFormControlSelect1">Site Id</label>
          <select
            style={{ height: "32px" }}
            className="form-control"
            id="exampleFormControlSelect1"
          >
            <option selected disabled value="">
              select
            </option>
            {count.siteArray}
          </select>
        </div> */}
        <div className="row " style={{ marginTop: "-30px" }}>
          <div className="col-md-6">
            {/* <div className="">
              <ExportToExcel
                apiData={excelrowData}
                fileName={
                  "MopRequest_" + moment(new Date()).format("MM/DD/YYYY")
                }
              />
            </div> */}
            <div className="date-picker-changes"></div>
            &nbsp; &nbsp;
            <div className="date-picker-changes"></div>
            {/* {userDetails.rolename == "RF Engineer" ? ( */}{" "}
            {/*if use this featue then uncomment this line and comment next line */}
            {"2" == "1" ? (
              <>
                &nbsp; &nbsp; &nbsp;
                <Button
                  className="btn btn-xs btn-primary float-right"
                  onClick={() => {
                    filehandleShow();
                    reset({});
                  }}
                >
                  Upload Excel File
                </Button>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="col-md-6 ">
            {/* {userDetails.rolename == "GC Login" ? (
              <>
                <button
                  style={{ float: "right" }}
                  className="btn btn-xs btn-primary"
                  onClick={() => {
                   setModalState(prev => true)
                   //console.log("clicked")
                  }}
                >
                  Create Mop Request
                </button>
              </>
            ) : (
              ""
            )} */}

            {/* <fregment style={{ float: "right" }}>
              {
              userDetails.rolename == "FOPS" ||
              userDetails.rolename == "Development" ? (
                <>
                  <button
                    className="btn btn-xs btn-primary"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Bulk Approve "
                    onClick={() => {
                      BulkApproveFun(userDetails.rolename == "FOPS"?"Operations":"Development");
                    }}
                  >
                    <GradingSharpIcon />
                  </button>
                </>
              ) : (
                ""
              )}
              
              {userDetails.rolename == "RF Engineer" ? (
                  <>
                    <button
                      className="btn btn-xs btn-primary"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Bulk Approve "
                      onClick={() => {
                        BulkUpload();
                      }}
                    >
                      <GradingSharpIcon />
                    </button>
                  </>
                ) : (
                  ""
              )}
              {userDetails.rolename == "RF Engineer" ? (
                <>
                  <button
                    className="btn btn-xs btn-primary mx-2 my-3"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Map View "
                    onClick={() => setMapModal(true)}
                  >
                    <PlaceIcon />
                  </button>
                </>
              ) : (
                ""
              )} */}
            {/* <button className="btn btn-xs btn-primary mx-2 my-3 " data-toggle="tooltip" data-placement="top" title=" Date Filter " onClick={() => setDatePicker(!showDatePicker)}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" width="24" height="24"><path fill="none" d="M0 0h24v24H0z" />
                <path d="M6.17 18a3.001 3.001 0 0 1 5.66 0H22v2H11.83a3.001 3.001 0 0 1-5.66 0H2v-2h4.17zm6-7a3.001 3.001 0 0 1 5.66 0H22v2h-4.17a3.001 3.001 0 0 1-5.66 0H2v-2h10.17zm-6-7a3.001 3.001 0 0 1 5.66 0H22v2H11.83a3.001 3.001 0 0 1-5.66 0H2V4h4.17zM9 6a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm6 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-6 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
              </svg>
            </button> */}

            {/* {userDetails.rolename == "RF Engineer" ||
              userDetails.rolename == "FOPS" ||
              userDetails.rolename == "Development" ? (
                <>
                  <button
                    className="btn btn-xs btn-primary mx-2  my-3"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Select All Table"
                    onClick={() => BulkSelect()}
                  >
                    <SelectAllIcon />
                  </button> */}

            {/* <button
                    className="btn btn-xs btn-danger mx-2 my-3"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Bulk Delete "
                    onClick={() => BulkDelete()}
                  >
                    <Delete />
                  </button> */}
            {/* </>
              ) : (
                ""
              )}
            </fregment> */}
          </div>
        </div>

        <div className="">
          {/* {userDetails.rolename == "RF Engineer" ? (
            <>
              &nbsp; &nbsp; &nbsp;
              <Button
                className="btn btn-xs btn-primary float-right"
                onClick={() => {
                  filehandleShow();
                  reset({});
                }}
              >
                Upload Excel File
              </Button>
            </>
          ) : (
            ""
          )} */}
          {/* {mapModal 
          ?<CustomeModal xlShow={mapModal} setXlShow={setMapModal} Body={MapView} modalSize={"xm"} />
          : <CustomeModal xlShow={modalState} setXlShow={setModalState} Body={SiteApprovalModal} uniqueId={uId} work={work} ctr={1} />
        } */}
        </div>

        <Modal show={showmodalfileshow} size="md" backdrop="static">
          <Modal.Header className="CommonModalHeader" style={{ padding: "0.3rem 1.875rem" }}>
            {/* <Modal.Title>Bulk Uploads</Modal.Title> */}
            <Modal.Title id="example-modal-sizes-title-xl ">
              <h5 className="CommonModalHeaderHeading p-0">Bulk Uploads</h5>
              <Button
                variant=""
                className="close CommonModalClose"
                onClick={() => { setXlShow(false) }}
              >
                <span className="CommonModalClose">&times;</span>
              </Button>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {CsrArray.length > 3}
            {CsrArraymsg0}
            <ul itemType="disc">{CsrArraymsg1}</ul>
            <ul itemType="disc">{CsrArraymsg2}</ul>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={closehandleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showmodalweather} size="xl">
          <Modal.Header
            className="CommonModalHeader" style={{ padding: "0.3rem 1.875rem" }}
          >
            <Modal.Title id="example-modal-sizes-title-xl ">
              <h5 className="CommonModalHeaderHeading p-0">{modalHeading}</h5>
              <Button
                variant=""
                className="close CommonModalClose"
                onClick={() => {
                  dispatch(getMOPInstructionAction([]))
                  setshowmodalweather(false)
                }}
              >
                <span className="CommonModalClose">&times;</span>
              </Button>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{temperature}</Modal.Body>

          <Modal.Footer>
            <Button variant="secondary " onClick={closehandleCloseweather}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={fileshow} onHide={filehandleClose} size="md" backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>Bulk Uploads</Modal.Title>
          </Modal.Header>
          {/* <Modal.Body> */}
          <form
            onSubmit={(e) => submitHandler(e)}
            encType="multipart/form-data"
            className="mx-2"
          >
            <div className="input-group mb-3 fileSection">
              <div>Bulk Excel</div>
              <br />
              <div>
                <label
                  style={{ padding: "0px" }}
                  class="custom-file-upload btn btn-outline-primary col-md-12"
                >
                  <input
                    type="file"
                    className="btn btn-xs btn-primary"
                    id="inputGroupFile04"
                    aria-describedby="inputGroupFileAddon04"
                    aria-label="Upload"
                    name="file"
                    multiple
                    onChange={uploadCsvFile}
                  />
                  <i class="fa fa-cloud-upload"></i> Choose File
                </label>
                {/* <label>Remarks</label>
                  <input type='text' className="form-control" value={remarks} onChange={(e)=>{setRemarks(e.target.value)}}></input> */}
              </div>

              <button
                className="btn btn-xs btn-primary"
                type="submit"
                id="inputGroupFileAddon04"
              >
                Submit
              </button>
            </div>
          </form>
        </Modal>
        {/* {
  modaluId=="toewta"? */}
        <CustomeModal
          xlShow={modalState}
          setXlShow={setModalState}
          Header={headerTitle}
          Body={UpdatedSiteApprovalModal}
          uniqueId={uId}
          work={work}
          ctr={1}
        />
        <CustomeModal
          xlShow={bulkApproveModalState}
          setXlShow={setBulkApproveModal}
          Body={BulkApproveModal}
          selectedId={BulkIdSelected}
          modalSize={"xm"}
        />





        <Modal
          // size="xl"
          dialogClassName="full-screen-modal"
          class={"full-screen-modal"}
          show={xlsShow}
          onHide={() => setXlsShow(false)}
          aria-labelledby="example-modal-sizes-title-xl"
          backdrop="static"
        >

          {console.log(siteUId, "siteUId", siteUId)}
          <Modal.Header closeButton className="CommonModalHeader">
            <Modal.Title id="example-modal-sizes-title-xl" >
              <h4 className="CommonModalHeaderHeading">Site Details - {ranData?.ticketId}</h4>
              <button className="close CommonModalClose" onClick={() => setXlsShow(false)}><span className="CommonModalClose">&times;</span></button>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body >
            <MopManageProjectDetail ranData={ranData}/>
          </Modal.Body>
        </Modal>

        <CommonModal
          modalSize={commonModalSize}
          setXlShow={setcommonModalState}
          xlShow={commonModalState}
          Body={commonModalBody}
          Header={commonModalHeader}
          Title={commonModalTitle}
        ></CommonModal>
        {/* <div
          style={{
            position: "relative",
            height: "86vh",
            overflowX: "scroll",
            top: "10px",
          }}
        >
          <div
            style={{
              width: "100%",
              position: "absolute",
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          > */}
        {/* "start table" */}


        {count.siteStatus.length > 0 ? (
          <RanSiteTracker from={"mop"} addClass={"mopsiteTracker"} setfullUrL={setfullUrL} siteStatus={count.siteStatus} setReset={setResetFilter} featureExport={setexportApiData} />
        ) : (
          <p></p>
        )}
        <div className="mui-t-h"
          style={{ top: 0, bottom: 0, left: 0, right: 0 }}
        // style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}
        >
          {/* {filterBadges} */}
          {filterBadges?.length > 0 ? <p style={{ color: "black", border: "0px solid black" }} className={"badge"}>Selected Filter : &nbsp;&nbsp;</p> : ""}{filterBadges}

          <ThemeProvider theme={getMuiThemeCommon()}>
            <MUIDataTable
              data={rowData}
              columns={columnMop}
              options={options}
              onChangeColumnHidden={(changedColumnId, value) => {
                // handleFilterChange(changedColumnId, value)
                //console.warn("the filter vkjkhkjhalues =", value)
              }}
            // customToolbar={<p>Cleck me</p>}
            // getRowId={(row) => //console.log("the row id",row.internalId)}
            />
          </ThemeProvider>
        </div>
        {/* <TableComponent rowData={rowData} columns={columnMop} /> */}
        {/* </div>
        </div> */}
        {/* <DataTableExtensions 
						
						columns={columns}
						data={rowData}
						exportHeaders={true}
						
						>
						<DataTable
              columns={columns}
              data={rowData}
							theme='solarized'
							defaultSortAsc={false}
							pagination
							highlightOnHover
						/>
						</DataTableExtensions> */}
      </div>
    </fregment>
  );
};

export default Mop;
