import React, { useEffect } from 'react'
import { Tab, Tabs } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { addAcccount, allTechnologyApi, getAllDropDown, getAllDropDownList, goNoGoAction, rfApprovalActionChange } from '../../../../common/config';
import { addAcccountReducerAction } from '../../../../store/actions/createTicketManageFields';
// import { addAcccountReducerAction, updateAcccountReducerAction } from '../../../components/vendor/'
const RFSiteStatus = (props) => {

  const { register, setValue, handleSubmit, getValues, watch, reset, formState: { errors } } = useForm({})
  const dispatch = useDispatch();

  console.log(props, "propspropspropsprops")

  if (props.type == "Edit") {
    // setValue("value",props.oldData.optionValue) 
  }
  const onSubmit = async (data) => {
    console.log("the data to submit is =", data)

    // data["dropDownId"]=props.parentuniqueId
    console.log(data, "data")

    // if(props.type=="Add"){
    dispatch(addAcccountReducerAction(rfApprovalActionChange + "/" + props.unique_id_up, data))
    // }
    // if(props.type=="Edit"){
    //   dispatch(updateAcccountReducerAction(getAllDropDownList,props.oldData.uniqueId,data))
    // }




    // propsprops

    // reset({})
    // setValue("value",'')
  }
  useEffect(() => {
    // reset({})
    // setValue("value",'')
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group col-md-12">

        {props.isShow}
        {/* <label for="exampleFormControlSelect1"> */}
          {/* RF Site Status */}
        {/* </label> */}
        <select style={{ height: "40px" }}
          type="text"
          className="form-control"
          id="input "
          // placeholder="Enter Technology Name"
          {
          ...register("mopRFSiteStatus")
          }
        >
          <option value={""}>Select</option>
          <option value={"Completed"}>Completed</option>
          <option value={"Ongoing"}>Ongoing</option>
          <option value={"Cancelled"}>Cancelled</option>
          <option value={"No Show"}>No Show</option>
        </select>
        {errors.mopRFSiteStatus && (
          <p className="error">{errors.mopRFSiteStatus.message}</p>
        )}
      </div>
      <button type="submit" className="btn btn-primary btn-xs mt-3">
        Submit
      </button>
    </form>
  )
}

export default RFSiteStatus