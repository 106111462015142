import { height, padding } from "@mui/system";
import React from "react";
import { useSelector } from "react-redux";
import { getdatedatalistnext } from "../../../../common/variables";
import OneSiteMapView from "./OneSiteMapView";

import '../../../../css/borderCustom.css'
import CBN_RIOT_Logs from "./cbn_riot_logs";
import { useLocation } from "react-router-dom";
import DowntimeList from "./DowntimeList";


const ManageProjectDetail = () => {
  const location = useLocation()
  let index = window.location.href.split("/")
  let dashboardType = index.slice(-2)[0]
  let siteDowntimeDetails = useSelector((state) => {
    return state.OneCommonResponse.singleSiteDownTime
  })


  console.log(siteDowntimeDetails?.tableData, "siteDowntimeDetails")
  let siteDetail = useSelector((state) => {
    console.log("the url =", state);
    // console.log(siteDetail.OneCommonResponse.singleSiteDownTime,'testingdata')
    // Ensure state.OneCommonResponse.singleSiteDownTime is an array
    // if (Array.isArray(state.OneCommonResponse.singleSiteDownTime)) {
    //   const uniqueBands = [...new Set(state.OneCommonResponse.singleSiteDownTime.map(item => item.Band))];
    //   console.log(uniqueBands,'uniqueBandsuniqueBands');
    // } else {
    //   console.error("state.OneCommonResponse.singleSiteDownTime is not an array.");
    // }


    // console.log("precheck=", state.OneCommonResponse.singleSite.preCheckRequest);
    // console.log("postcheck=", state.OneCommonResponse.singleSite.postCheckDelivered);

    // console.log("the server is njskchdkcdf=", dashboardType)

    let site = dashboardType == "ix" ? state.OneCommonResponse.singleIXSite : state.OneCommonResponse.singleSite
    console.log("the sites is the =", site);
    let pSow = "";
    let pSowArray = site["sowList"] ? site["sowList"].values : [];
    if (pSowArray && pSowArray.length > 0) {
      //  item['technology'].forEach((item)=>{
      //   tec +=item.label +','
      //  })
      // console.log("sgrthdhr", pSowArray.length);
      for (let i = 0; i < pSowArray.length; i++) {
        console.log("sgrthdhr", pSowArray[i]);
        pSow += pSowArray[i] + " , ";
      }
      //  item['technology']=tec
    }
    let ttidsData = []
    if (site.troubleTickets && site.troubleTickets.length > 0) {
      ttidsData = site.troubleTickets.map((itemInner) => {
        return itemInner.ttid;
      });
    }
    let sowList = "";
    let sowArray = site["sowDetails"] ? site["sowDetails"].values : [];
    if (sowArray && sowArray.length > 0) {
      //  item['technology'].forEach((item)=>{
      //   tec +=item.label +','
      //  })
      console.log("sgrthdhr", sowArray.length);
      for (let i = 0; i < sowArray.length; i++) {
        console.log("sgrthdhr", sowArray[i]);
        sowList += sowArray[i] + " , ";
      }
      //  item['technology']=tec

    }
    return {
      site, pSow, sowList, ttidsData, 
      preCheckRequest: state.OneCommonResponse.singleSite.preCheckRequest,
      postCheckDelivered: state.OneCommonResponse.singleSite.postCheckRequest, 
      allData: state.OneCommonResponse.singleSiteDownTime
    };

  });




  console.log(siteDetail, " -the single sites is");




  
  return (
    <>
      <>
        <ul className="nav nav-pills mb-3 p-2" id="pills-tab" role="tablist">
          <li className="nav-item">
            <a
              className="color-nav-link nav-link active"
              id="pills-home-tab"
              data-toggle="pill"
              href="#pills-home"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
            >
              Site Details
            </a>
          </li>

          <li className="nav-item">
            <a
              className="nav-link color-nav-link"
              id="pills-profile-tab"
              data-toggle="pill"
              href="#pills-profile"
              role="tab"
              aria-controls="pills-profile"
              aria-selected="false"
            >
              MAP View
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link color-nav-link"
              id="pills-contact-tab"
              data-toggle="pill"
              href="#pills-contact"
              role="tab"
              aria-controls="pills-contact"
              aria-selected="false"
            >
              RAN Historical
            </a>
          </li>
          {dashboardType == "ix" ?
            <li className="nav-item">
              <a
                className="nav-link color-nav-link"
                id="preriot-history-tab"
                data-toggle="pill"
                href="#preriot-history"
                role="tab"
                aria-controls="preriot-history"
                aria-selected="false"
              >
                Pre RIOT History
              </a>
            </li>
            : <></>}
          {location.pathname === '/PAG/ix/dashboard' && (<li className="nav-item">
            <a
              className="color-nav-link nav-link"
              id="pills-preval-tab"
              data-toggle="pill"
              href="#pills-preval"
              role="tab"
              aria-controls="pills-preval"
              aria-selected="false"
            >
              Pre Validation
            </a>
          </li>)}
          <li className="nav-item">
            <a
              className="nav-link color-nav-link"
              id="riot-history-tab"
              data-toggle="pill"
              href="#riot-history"
              role="tab"
              aria-controls="riot-history"
              aria-selected="false"
            >
              RIOT History
            </a>
          </li>

          <li className="nav-item">
            <a
              className="nav-link color-nav-link"
              id="cbn-history-tab"
              data-toggle="pill"
              href="#cbn-history"
              role="tab"
              aria-controls="cbn-history"
              aria-selected="false"
            >
              CBN History
            </a>
          </li>


          <li className="nav-item">
            <a
              className="nav-link color-nav-link"
              id="downtime-tab"
              data-toggle="pill"
              href="#downtime"
              role="tab"
              aria-controls="downtime"
              aria-selected="false"
            >
              DOWNTIME
            </a>
          </li>
          {location.pathname === '/PAG/ix/dashboard' && (<li className="nav-item">
            <a
              className="color-nav-link nav-link"
              id="pills-postval-tab"
              data-toggle="pill"
              href="#pills-postval"
              role="tab"
              aria-controls="pills-postval"
              aria-selected="false"
            >
              Post Validation
            </a>
          </li>)}

        </ul>
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane fade show active "
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <div className="card  p-2" style={{ width: "100%" }}>
              <table
                style={{ height: "110px", width: "100%" }}
                className="tablee table-bordered pop-table showone">
                <thead>
                  <tr>
                    <th
                      className="showonetrorg"
                      style={{
                        backgroundColor: "#f16722 !important",
                        fontSize: 13,
                        textAlign: "center",
                        fontWeight: "600",
                      }}
                      colSpan={4}
                    >
                      <span style={{ color: "white" }}>GC Login</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      Sow Details
                    </th>
                    <td>
                      <span className="ng-binding ">{siteDetail.sowList ? siteDetail.sowList.values ? siteDetail.sowList.values.join() : "" : ""}</span>
                    </td>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      Sow Comment
                    </th>
                    <td>
                      <span className="ng-binding">
                        {siteDetail.site.sowComments}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      Nesting Time
                    </th>
                    <td className="ng-binding">
                      {siteDetail.site.nestingTime}
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                style={{ height: "110px", width: "100%" }}
                className=" table-bordered pop-table mt-4 showone"
              >
                <thead>
                  <tr>
                    <th
                      className="showonetrorg"
                      style={{
                        backgroundColor: "#f16722 !important ",
                        fontSize: 13,
                        textAlign: "center",
                        fontWeight: 600,
                      }}
                      colSpan={6}
                    >
                      <span style={{ color: "white" }}>Scheduling Info</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      Account
                    </th>
                    <td
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                      className="ng-binding"
                    >
                      {siteDetail.site.ndResult
                        ? siteDetail.site.ndResult.account
                        : ""}
                    </td>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      MARKET
                    </th>
                    <td
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                      className="ng-binding"
                    >
                      {siteDetail.site.ndResult
                        ? siteDetail.site.ndResult.market
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      Cabinet ID
                    </th>
                    <td
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                      colSpan={5}
                      className="ng-binding"
                    >
                      GSM;,,,, UTMS;,,,,, LTE:,,,,, 5G;,,,,,
                    </td>
                  </tr>






                  <tr>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      CX Crew Vendor
                    </th>
                    <td
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                      className="ng-binding"
                    >
                      {siteDetail.site.cxCrewId ? siteDetail.site.cxCrewId.crewType == "CX" ? siteDetail.site.cxCrewId.vendor : "" : ""}
                      {siteDetail.site.ixCrewId ? siteDetail.site.ixCrewId.crewType == "CX" ? siteDetail.site.ixCrewId.vendor : "" : ""}
                    </td>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      CX Crew Lead Contact Number
                    </th>
                    <td
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                      className="ng-binding"
                    >
                      {siteDetail.site.cxCrewId ? siteDetail.site.cxCrewId.crewType == "CX" ? siteDetail.site.cxCrewId.mobilenumber : "" : ""}
                      {siteDetail.site.ixCrewId ? siteDetail.site.ixCrewId.crewType == "CX" ? siteDetail.site.ixCrewId.mobilenumber : "" : ""}
                      {/* {siteDetail.site.cxCrewId ? siteDetail.site.cxCrewId.mobilenumber : ""} */}
                    </td>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      CX Crew Lead Name
                    </th>
                    <td
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                      className="ng-binding"
                    >
                      {siteDetail.site.cxCrewId ? siteDetail.site.cxCrewId.crewType == "CX" ? siteDetail.site.cxCrewId.name : "" : ""}
                      {siteDetail.site.ixCrewId ? siteDetail.site.ixCrewId.crewType == "CX" ? siteDetail.site.ixCrewId.name : "" : ""}
                      {/* {siteDetail.site.cxCrewId ? siteDetail.site.cxCrewId.name : ""} */}
                    </td>
                  </tr>
                  <tr>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      CX Crew Lead Company
                    </th>
                    <td
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                      className="ng-binding"
                    >

                      {siteDetail.site.cxCrewId ? siteDetail.site.cxCrewId.crewType == "CX" ? siteDetail.site.cxCrewId.crewLeadCompanyId : "" : ""}
                      {siteDetail.site.ixCrewId ? siteDetail.site.ixCrewId.crewType == "CX" ? siteDetail.site.ixCrewId.crewLeadCompanyId : "" : ""}
                      {/* {siteDetail.site.cxCrewId ? siteDetail.site.cxCrewId.crewLeadCompanyId : ""} */}
                    </td>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      CX Crew Lead E-mail ID
                    </th>
                    <td
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                      className="ng-binding"
                    >

                      {siteDetail.site.cxCrewId ? siteDetail.site.cxCrewId.crewType == "CX" ? siteDetail.site.cxCrewId.email : "" : ""}
                      {siteDetail.site.ixCrewId ? siteDetail.site.ixCrewId.crewType == "CX" ? siteDetail.site.ixCrewId.email : "" : ""}
                      {/* {siteDetail.site.cxCrewId ? siteDetail.site.cxCrewId.email : ""} */}

                    </td>
                  </tr>
                  <tr>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      IX Crew Vendor
                    </th>
                    <td
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                      className="ng-binding"
                    >

                      {siteDetail.site.cxCrewId ? siteDetail.site.cxCrewId.crewType == "IX" ? siteDetail.site.cxCrewId.vendor : "" : ""}
                      {siteDetail.site.ixCrewId ? siteDetail.site.ixCrewId.crewType == "IX" ? siteDetail.site.ixCrewId.vendor : "" : ""}
                      {/* {siteDetail.site.ixCrewId ? siteDetail.site.ixCrewId.vendor : ""} */}
                    </td>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      IX Crew Lead Contact Number
                    </th>
                    <td
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                      className="ng-binding"
                    >

                      {siteDetail.site.cxCrewId ? siteDetail.site.cxCrewId.crewType == "IX" ? siteDetail.site.cxCrewId.mobilenumber : "" : ""}
                      {siteDetail.site.ixCrewId ? siteDetail.site.ixCrewId.crewType == "IX" ? siteDetail.site.ixCrewId.mobilenumber : "" : ""}
                      {/* {siteDetail.site.ixCrewId ? siteDetail.site.ixCrewId.mobilenumber : ""} */}
                    </td>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      IX Crew Lead Name
                    </th>
                    <td
                      style={{ width: 230, fontWeight: "400", padding: "5px" }}
                      className="ng-binding"

                    >

                      {siteDetail.site.cxCrewId ? siteDetail.site.cxCrewId.crewType == "IX" ? siteDetail.site.cxCrewId.name : "" : ""}
                      {siteDetail.site.ixCrewId ? siteDetail.site.ixCrewId.crewType == "IX" ? siteDetail.site.ixCrewId.name : "" : ""}
                      {/* {siteDetail.site.ixCrewId ? siteDetail.site.ixCrewId.name : ""} */}
                    </td>
                  </tr>
                  <tr>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      IX Crew Lead Company
                    </th>
                    <td
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                      className="ng-binding"
                    >
                      {siteDetail.site.cxCrewId ? siteDetail.site.cxCrewId.crewType == "IX" ? siteDetail.site.cxCrewId.crewLeadCompanyId : "" : ""}
                      {siteDetail.site.ixCrewId ? siteDetail.site.ixCrewId.crewType == "IX" ? siteDetail.site.ixCrewId.crewLeadCompanyId : "" : ""}
                      {/* {siteDetail.site.ixCrewId ? siteDetail.site.ixCrewId.crewLeadCompanyId : ""} */}

                    </td>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      IX Crew Lead E-mail ID
                    </th>
                    <td
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                      className="ng-binding"
                    >
                      {siteDetail.site.cxCrewId ? siteDetail.site.cxCrewId.crewType == "IX" ? siteDetail.site.cxCrewId.email : "" : ""}
                      {siteDetail.site.ixCrewId ? siteDetail.site.ixCrewId.crewType == "IX" ? siteDetail.site.ixCrewId.email : "" : ""}
                      {/* {siteDetail.site.ixCrewId ? siteDetail.site.ixCrewId.email : ""} */}

                    </td>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      Service Affecting
                    </th>
                    <td
                      style={{ width: 230, fontWeight: "400", padding: "5px" }}
                      className="ng-binding"
                    >
                      {siteDetail.site.serviceAffected}
                    </td>
                  </tr>
                  <tr>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      Day MOP or Night MOP
                    </th>
                    <td
                      style={{ width: 230, fontWeight: "400", padding: "5px" }}
                      className="ng-binding"
                    >
                      {siteDetail.site.dayNightMop}
                    </td>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      Reason for Day MOP
                    </th>
                    <td className="ng-binding">{siteDetail.site.dayMopReason}</td>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      TMO Outage Approval
                    </th>
                    <td
                      style={{ width: 230, fontWeight: "400", padding: "5px" }}
                      className="ng-binding"
                    >
                      {siteDetail.site.RF_Approval}</td>
                  </tr>
                  <tr>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      TMO Deployment Manager
                    </th>
                    <td
                      style={{ width: 230, fontWeight: "400", padding: "5px" }}
                      className="ng-binding"
                    >{siteDetail.site.tmoDeploymentManager}</td>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      Planned SoW
                    </th>
                    <td
                      style={{ width: 230, fontWeight: "400", padding: "5px" }}
                      className="ng-binding"
                    >
                      {siteDetail.pSow}
                    </td>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      TMO Outage Approved MW Time
                    </th>
                    <td
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                        color: "white",
                        fontSize: "11px",
                      }}
                      className="ng-binding"
                    >
                      {siteDetail.site && siteDetail.site.length != 0 ? siteDetail.site.RF_Approved_EndTime_Time && siteDetail.site.RF_Approved_EndTime_Time ? getdatedatalistnext(siteDetail.site.RF_Approved_MW_Time).split(" ")[1] + " to " + getdatedatalistnext(siteDetail.site.RF_Approved_EndTime_Time).split(" ")[1] : "" : ""}
                    </td>
                  </tr>
                  <tr>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      Cancellation Reason(if any)
                    </th>
                    <td
                      colSpan={6}
                      style={{ width: 230, textAlign: "left" }}
                      className="ng-binding"
                    />
                  </tr>
                </tbody>
              </table>
              <table
                style={{ height: "200px", width: "100%" }}
                className=" table-bordered pop-table mt-4 showone"
              >
                <thead>
                  <tr>
                    <th
                      className="showonetrorg"
                      style={{
                        backgroundColor: "#f16722 !important",
                        fontSize: 13,
                        textAlign: "center",
                        fontWeight: 600,
                      }}
                      colSpan={6}
                    >
                      <span style={{ color: "white" }}>PRECHECK</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      Precheck Engineer
                    </th>
                    <td>
                      <span className="ng-binding">
                        {siteDetail.site.preCheckEngineer}
                      </span>
                    </td>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      UMTS IP Contact
                    </th>
                    <td>
                      <span className="ng-binding">
                        {siteDetail.site.UMTSIpContactPre}
                      </span>
                    </td>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      UMTS Alarm
                    </th>
                    <td>
                      <span className="ng-binding">
                        {siteDetail.site.UMTSAlarmPre}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      LTE IP Contact
                    </th>
                    <td>
                      <span className="ng-binding">
                        {siteDetail.site.LTEIpContactPre}
                      </span>
                    </td>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      LTE Alarm
                    </th>
                    <td>
                      <span className="ng-binding">
                        {siteDetail.site.LTEAlarmPre}
                      </span>
                    </td>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      5G IP Contact
                    </th>
                    <td>
                      <span className="ng-binding">
                        {siteDetail.site.fiveGIpContactPre}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      5G Alarm
                    </th>
                    <td>
                      <span className="ng-binding">
                        {siteDetail.site.FiveGAlarmPre}
                      </span>
                    </td>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      BSC IP Contact
                    </th>
                    <td>
                      <span className="ng-binding">
                        {siteDetail.site.BSCIpContactPre}
                      </span>
                    </td>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      GSM Alarm
                    </th>
                    <td>
                      <span className="ng-binding">
                        {siteDetail.site.GSMAlarmPre}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      Login Precheck Deliver Date
                    </th>
                    <td>
                      <span className="ng-binding">
                        {siteDetail.site.preCheckDelivered}
                      </span>
                    </td>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      Precheck Alarm Issue
                    </th>
                    <td colSpan={3}>
                      <div>
                        <span className="ng-binding">
                          {siteDetail.site.precheckAlarmIssue}
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table
                style={{ height: "200px", width: "100%" }}
                className=" table-bordered pop-table mt-4 showone"
              >
                <thead>
                  <tr>
                    <th
                      className="showonetrorg"
                      style={{
                        backgroundColor: "#f16722 !important",
                        fontSize: 13,
                        textAlign: "center",
                        fontWeight: 600,
                      }}
                      colSpan={6}
                    >
                      <span style={{ color: "white" }}>POSTCHECK</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      Postcheck Engineer
                    </th>
                    <td
                      style={{ width: 230, fontWeight: "400", padding: "5px" }}
                    >
                      <span className="ng-binding">
                        {siteDetail.site.postCheckEngineer}
                      </span>
                    </td>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      5G Downtime(If Any)
                    </th>
                    <td style={{ width: 70 }}>
                      <span className="ng-binding">
                        {siteDetail.site.post5GDownTime}
                      </span>
                    </td>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      GSM Downtime(If Any)
                    </th>
                    <td
                      style={{ width: 230, fontWeight: "400", padding: "5px" }}
                    >
                      <span className="ng-binding">
                        {siteDetail.site.postGsmDownTime}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      UMTS Downtime(If Any)
                    </th>
                    <td
                      style={{ width: 230, fontWeight: "400", padding: "5px" }}
                    >
                      <span className="ng-binding">
                        {siteDetail.site.postUmtsDownTime}
                      </span>
                    </td>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      LTE Downtime(If Any)
                    </th>
                    <td style={{ width: 70 }}>
                      <span className="ng-binding">
                        {siteDetail.site.postLteDownTime}
                      </span>
                    </td>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      BSC IP Contact
                    </th>
                    <td
                      style={{ width: 230, fontWeight: "400", padding: "5px" }}
                    >
                      <span className="ng-binding">
                        {siteDetail.site.postBSCIpContact}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      GSM Alarm
                    </th>
                    <td
                      style={{ width: 230, fontWeight: "400", padding: "5px" }}
                    >
                      <span className="ng-binding">
                        {siteDetail.site.postGSMAlarm}
                      </span>
                    </td>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      UMTS IP Contact
                    </th>
                    <td style={{ width: 70 }}>
                      <span className="ng-binding">
                        {siteDetail.site.postUmtsIpContact}
                      </span>
                    </td>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      UMTS Alarm
                    </th>
                    <td
                      style={{ width: 230, fontWeight: "400", padding: "5px" }}
                    >
                      <span className="ng-binding">
                        {siteDetail.site.postUMTSlAlarm}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      5G IP Contact
                    </th>
                    <td
                      style={{ width: 230, fontWeight: "400", padding: "5px" }}
                    >
                      <span className="ng-binding">
                        {siteDetail.site.post5GIPContact}
                      </span>
                    </td>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      5G Alarm
                    </th>
                    <td style={{ width: 70 }}>
                      <span className="ng-binding">
                        {siteDetail.site.post5GAlarm}
                      </span>
                    </td>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      LTE IP Contact
                    </th>
                    <td
                      style={{ width: 230, fontWeight: "400", padding: "5px" }}
                    >
                      <span className="ng-binding">
                        {siteDetail.site.postLTEIPContact}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      LTE Alarm
                    </th>
                    <td
                      style={{ width: 230, fontWeight: "400", padding: "5px" }}
                    >
                      <span className="ng-binding">
                        {siteDetail.site.postLteAlarm}
                      </span>
                    </td>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      Was TS or Scripting was Required
                    </th>
                    <td style={{ width: 70 }}>
                      <span className="ng-binding">
                        {siteDetail.site.wasTSOrScriptingWasRequired}
                      </span>
                    </td>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      Scripting Engineer
                    </th>
                    <td
                      style={{ width: 230, fontWeight: "400", padding: "5px" }}
                    >
                      <span className="ng-binding"></span>
                    </td>
                  </tr>
                  <tr>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      TT(s) Opend
                    </th>
                    <td
                      style={{ width: 70 }}
                    >
                      {/* ngIf: postcheckdata[0].ttidstatus=='0' */}
                      {/* ngIf: postcheckdata[0].ttidstatus!='0' */}
                      <span
                        className="ng-binding"
                      >
                        {siteDetail.site.ttOpen}
                        {/* {siteDetail.ttidsData.length > 0 ? "Yes":"No"} */}
                      </span>
                      {/* end ngIf: postcheckdata[0].ttidstatus!='0' */}
                    </td>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      TT ID(s)
                    </th>
                    <td style={{ width: 70 }}>
                      {/* ngIf: postcheckdata[0].ttidstatus!='0' */}

                      <span
                        className="ng-binding ng-scope"
                      >


                        {siteDetail.ttidsData.join(", ")}

                      </span>

                    </td>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      Site Up Time
                    </th>
                    <td
                      style={{ width: 230, fontWeight: "400", padding: "5px" }}
                    >
                      <span className="ng-binding">
                        {siteDetail.site.postSiteUpTime}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      Activity went out of approved window
                    </th>
                    <td
                      style={{ width: 230, fontWeight: "400", padding: "5px" }}
                    >
                      <span className="ng-binding">
                        {siteDetail.site.activityWentOutOfApprovedWindow}
                      </span>
                    </td>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      Time after approved window
                    </th>
                    <td
                      style={{ width: 230, fontWeight: "400", padding: "5px" }}
                    >
                      <span className="ng-binding">
                        {siteDetail.site.timeAfterApprovedWindow}
                      </span>
                    </td>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      Logout Time Post Delivered Date
                    </th>
                    <td style={{ width: 70 }}>
                      <span className="ng-binding">
                        {siteDetail.site.postCheckDelivered ? getdatedatalistnext(siteDetail.site.postCheckDelivered) : ""}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      Activity Status(Site Level)
                    </th>
                    <td colSpan={5}
                      style={{ width: 230, fontWeight: "400", padding: "5px" }}
                    >
                      <span className="ng-binding">
                        {siteDetail.site.postActivityStatusSiteLevel}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      Post check alarms/Issues
                    </th>
                    <td
                      style={{ width: 230, fontWeight: "400", padding: "5px" }}
                      colSpan={5}
                    >
                      <div
                        style={{
                          padding: 2,
                          marginBottom: 2,
                          minWidth: 250,
                        }}
                      >
                        <span className="ng-binding">
                          {siteDetail.site.postCheckAlarmIssue}
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th
                      style={{
                        width: 230,
                        fontWeight: "400",
                        padding: "5px",
                        color: "white",
                        fontSize: "11px",
                      }}
                    >
                      Activity Notes
                    </th>
                    <td
                      style={{ width: 230, fontWeight: "400", padding: "5px" }}
                      colSpan={5}
                    >
                      <div>
                        <span className="ng-binding">
                          {siteDetail.site.postActivityNotes}
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
          >
            <OneSiteMapView siteDetail={siteDetail.site}></OneSiteMapView>
          </div>

          <div
            className="tab-pane fade"
            id="pills-preval"
            role="tabpanel"
            aria-labelledby="pills-preval-tab"
          >
            <div className="card p-2" style={{ overflowX: "auto" }} >
              <table
                className=" table-bordered showone"
                id="table"
                style={{ overflowX: "scroll" }}
              >
                <thead>
                  <tr style={{ color: "white", background: "#f16722  !important" }}>
                    <th
                      style={{
                        color: "white",
                        color: "white",
                        fontSize: "11px",
                        minWidth: "150px"
                      }}
                    >
                      SCF Availability
                    </th>
                    <th
                      style={{
                        color: "white",
                        color: "white",
                        fontSize: "11px",
                        minWidth: "100px"
                      }}
                    >
                      RFDS - HW check
                    </th>
                    <th
                      style={{
                        color: "white",
                        color: "white",
                        fontSize: "11px",
                        minWidth: "160px"
                      }}
                    >
                      Port Matrix - Connection Check
                    </th>
                    <th
                      style={{
                        color: "white",
                        color: "white",
                        fontSize: "11px",
                        minWidth: "180px"
                      }}
                    >
                      Remote connectivity Parameters Check
                    </th>
                    <th
                      style={{
                        color: "white",
                        color: "white",
                        fontSize: "11px",
                        minWidth: "180px"
                      }}
                    >
                      Plan verification
                    </th>
                    <th
                      style={{
                        color: "white",
                        color: "white",
                        fontSize: "11px",
                        minWidth: "180px"
                      }}
                    >
                      Remarks
                    </th>

                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {
                      siteDetail.site.PreVSCFAvailability != undefined ?
                        <td
                          style={{
                            color: "black",
                            fontSize: "11px",
                            minWidth: "180px"
                          }}
                        >
                          {/* SCFAvailability{siteDetail.site.SCFAvailability} */}
                          {siteDetail.site.PreVSCFAvailability == true ? "Yes" : "No"}
                        </td>
                        : ""}
                    {siteDetail.site.PreVRfdsHwCheck != undefined ? <td
                      style={{
                        color: "black",
                        fontSize: "11px",
                        minWidth: "180px"
                      }}
                    >
                      {/* RfdsHwCheck {siteDetail.site.RfdsHwCheck} */}
                      {siteDetail.site.PreVRfdsHwCheck == true ? "Yes" : "No"}
                    </td> : ""}
                    {siteDetail.site.PreVPortMatrixConnectionCheck != undefined ? <td
                      style={{
                        color: "black",
                        fontSize: "11px",
                        minWidth: "180px"
                      }}
                    >
                      {/* PortMatrixConnectionCheck {siteDetail.site.PortMatrixConnectionCheck} */}
                      {siteDetail.site.PreVPortMatrixConnectionCheck == true ? "Yes" : "No"}
                    </td> : ""}
                    {siteDetail.site.PreVRemoteConnectivityParametersCheck != undefined ? <td
                      style={{
                        color: "black",
                        fontSize: "11px",
                        minWidth: "180px"
                      }}
                    >
                      {/* RemoteConnectivityParametersCheck{siteDetail.site.RemoteConnectivityParametersCheck} */}
                      {siteDetail.site.PreVRemoteConnectivityParametersCheck == true ? "Yes" : "No"}
                    </td> : ""}
                    {siteDetail.site.PreVPlanVerification != undefined ? <td
                      style={{
                        color: "black",
                        fontSize: "11px",
                        minWidth: "180px"
                      }}
                    >
                      {/* PlanVerification{siteDetail.site.PlanVerification} */}
                      {siteDetail.site.PreVPlanVerification == true ? "Yes" : "No"}
                    </td> : ""}
                    {siteDetail.site.preValidationRemarks != undefined ? <td
                      style={{
                        color: "black",
                        fontSize: "11px",
                        minWidth: "180px"
                      }}
                    >
                      {/* preValidationRemarks{siteDetail.site.preValidationRemarks!=undefined?siteDetail.site.preValidationRemarks==true?"True":"False":""} */}
                      {siteDetail.site.preValidationRemarks}
                    </td> : ""}
                  </tr>

                </tbody>
              </table>
            </div>

          </div>

          <div
            className="tab-pane fade"
            id="pills-postval"
            role="tabpanel"
            aria-labelledby="pills-postval-tab"
            style={{ overflowX: "auto" }}
          >
            <div className="card p-2" style={{ overflowX: "auto" }}>
              <table
                className=" table-bordered showone"
                id="table"
                style={{ overflowX: "scroll" }}
              >
                <thead>
                  <tr style={{ color: "white", background: "#f16722  !important" }}>
                    <th
                      style={{
                        color: "white",
                        color: "white",
                        fontSize: "11px",
                        minWidth: "150px"
                      }}
                    >
                      Sector Swap
                    </th>
                    <th
                      style={{
                        color: "white",
                        color: "white",
                        fontSize: "11px",
                        minWidth: "100px"
                      }}
                    >
                      Fiber Swap
                    </th>
                    <th
                      style={{
                        color: "white",
                        color: "white",
                        fontSize: "11px",
                        minWidth: "160px"
                      }}
                    >
                      Sync Setting
                    </th>
                    <th
                      style={{
                        color: "white",
                        color: "white",
                        fontSize: "11px",
                        minWidth: "180px"
                      }}
                    >
                      RET validation
                    </th>
                    <th
                      style={{
                        color: "white",
                        color: "white",
                        fontSize: "11px",
                        minWidth: "180px"
                      }}
                    >
                      VSWR
                    </th>
                    <th
                      style={{
                        color: "white",
                        color: "white",
                        fontSize: "11px",
                        minWidth: "190px"
                      }}
                    >
                      RTWP
                    </th>
                    <th
                      style={{
                        color: "white",
                        color: "white",
                        fontSize: "11px",
                        minWidth: "180px"
                      }}
                    >
                      SFP
                    </th>
                    <th
                      style={{
                        color: "white",
                        color: "white",
                        fontSize: "11px",
                        minWidth: "100px"
                      }}
                    >
                      All Plan executed
                    </th>
                    <th
                      style={{
                        color: "white",
                        color: "white",
                        fontSize: "11px",
                        minWidth: "180px"
                      }}
                    >
                      EAC
                    </th>
                    <th
                      style={{
                        color: "white",
                        color: "white",
                        fontSize: "11px",
                        minWidth: "180px"
                      }}
                    >
                      Remarks
                    </th>

                  </tr>
                </thead>
                <tbody>

                  {siteDetail.site.PostVSectorSwap != undefined ? <td
                    style={{
                      color: "black",
                      fontSize: "11px",
                      minWidth: "180px"
                    }}
                  >
                    {/* PlanVerification{siteDetail.site.PlanVerification} */}
                    {siteDetail.site.PostVSectorSwap == true ? "Yes" : "No"}
                  </td> : ""}
                  {siteDetail.site.PostVFiberSwap != undefined ? <td
                    style={{
                      color: "black",
                      fontSize: "11px",
                      minWidth: "180px"
                    }}
                  >
                    {/* PlanVerification{siteDetail.site.PlanVerification} */}
                    {siteDetail.site.PostVFiberSwap == true ? "Yes" : "No"}
                  </td> : ""}
                  {siteDetail.site.PostVSyncSetting != undefined ? <td
                    style={{
                      color: "black",
                      fontSize: "11px",
                      minWidth: "180px"
                    }}
                  >
                    {/* PlanVerification{siteDetail.site.PlanVerification} */}
                    {siteDetail.site.PostVSyncSetting == true ? "Yes" : "No"}
                  </td> : ""}
                  {siteDetail.site.PostVRETValidation != undefined ? <td
                    style={{
                      color: "black",
                      fontSize: "11px",
                      minWidth: "180px"
                    }}
                  >
                    {/* PlanVerification{siteDetail.site.PlanVerification} */}
                    {siteDetail.site.PostVRETValidation == true ? "Yes" : "No"}
                  </td> : ""}
                  {siteDetail.site.PostVVSWR != undefined ? <td
                    style={{
                      color: "black",
                      fontSize: "11px",
                      minWidth: "180px"
                    }}
                  >
                    {/* PlanVerification{siteDetail.site.PlanVerification} */}
                    {siteDetail.site.PostVVSWR == true ? "Yes" : "No"}
                  </td> : ""}
                  {siteDetail.site.PostVRTWP != undefined ? <td
                    style={{
                      color: "black",
                      fontSize: "11px",
                      minWidth: "180px"
                    }}
                  >
                    {/* PlanVerification{siteDetail.site.PlanVerification} */}
                    {siteDetail.site.PostVRTWP == true ? "Yes" : "No"}
                  </td> : ""}
                  {siteDetail.site.PostVSFP != undefined ? <td
                    style={{
                      color: "black",
                      fontSize: "11px",
                      minWidth: "180px"
                    }}
                  >
                    {/* PlanVerification{siteDetail.site.PlanVerification} */}
                    {siteDetail.site.PostVSFP == true ? "Yes" : "No"}
                  </td> : ""}
                  {siteDetail.site.PostVallPlanExecuted != undefined ? <td
                    style={{
                      color: "black",
                      fontSize: "11px",
                      minWidth: "180px"
                    }}
                  >
                    {/* PlanVerification{siteDetail.site.PlanVerification} */}
                    {siteDetail.site.PostVallPlanExecuted == true ? "Yes" : "No"}
                  </td> : ""}


                  {siteDetail.site.PostVEAC != undefined ? <td
                    style={{
                      color: "black",
                      fontSize: "11px",
                      minWidth: "180px"
                    }}
                  >
                    {/* PlanVerification{siteDetail.site.PlanVerification} */}
                    {siteDetail.site.PostVEAC == true ? "Yes" : "No"}
                  </td> : ""}
                  {siteDetail.site.postValidationRemarks != undefined ? <td
                    style={{
                      color: "black",
                      fontSize: "11px",
                      minWidth: "180px"
                    }}
                  >
                    {/* PlanVerification{siteDetail.site.PlanVerification} */}
                    {siteDetail.site.postValidationRemarks}
                  </td> : ""}



                </tbody>
              </table>
            </div>
          </div>

          <div
            className="tab-pane fade"
            id="cbn-history"
            role="tabpanel"
            style={{ height: "500px", overflow: "scroll" }}
            aria-labelledby="cbn-history-tab"
          >
            <CBN_RIOT_Logs type={"cbn"} cbn_riot_details={siteDetail.site.cbn_ran_data}></CBN_RIOT_Logs>
          </div>

          <div
            className="tab-pane fade"
            id="preriot-history"
            role="tabpanel"
            style={{ height: "500px", overflow: "scroll" }}
            aria-labelledby="preriot-history-tab"
          >

            <CBN_RIOT_Logs type={"preriot"} cbn_riot_details={siteDetail.site.preriot_ran_data}></CBN_RIOT_Logs>
          </div>
          <div
            className="tab-pane fade"
            id="riot-history"
            role="tabpanel"
            style={{ height: "500px", overflow: "scroll" }}
            aria-labelledby="riot-history-tab"
          >
            <CBN_RIOT_Logs type={"riot"} cbn_riot_details={siteDetail.site.riot_ran_data}></CBN_RIOT_Logs>
          </div>


          <div
            className="tab-pane fade"
            id="pills-contact"
            role="tabpanel"
            aria-labelledby="pills-contact-tab"
            style={{ overflowX: "scroll" }}
          >
            <div className="card p-2" >
              <table
                className=" table-bordered showone"
                id="table"
                style={{ overflowX: "scroll" }}
              >
                <thead>
                  <tr style={{ color: "white", background: "#f16722  !important" }}>
                    <th
                      style={{
                        color: "white",
                        color: "white",
                        fontSize: "11px",
                        minWidth: "150px"
                      }}
                    >
                      Scheduled_Date
                    </th>
                    <th
                      style={{
                        color: "white",
                        color: "white",
                        fontSize: "11px",
                        minWidth: "100px"
                      }}
                    >
                      Site Id
                    </th>
                    <th
                      style={{
                        color: "white",
                        color: "white",
                        fontSize: "11px",
                        minWidth: "160px"
                      }}
                    >
                      Technology
                    </th>
                    <th
                      style={{
                        color: "white",
                        color: "white",
                        fontSize: "11px",
                        minWidth: "180px"
                      }}
                    >
                      Service Affecting
                    </th>
                    <th
                      style={{
                        color: "white",
                        color: "white",
                        fontSize: "11px",
                        minWidth: "180px"
                      }}
                    >
                      Planned SoW
                    </th>
                    <th
                      style={{
                        color: "white",
                        color: "white",
                        fontSize: "11px",
                        minWidth: "190px"
                      }}
                    >
                      Activity Status(Site Level)
                    </th>
                    <th
                      style={{
                        color: "white",
                        color: "white",
                        fontSize: "11px",
                        minWidth: "180px"
                      }}
                    >
                      Cancelled/Rollback Reason
                    </th>
                    <th
                      style={{
                        color: "white",
                        color: "white",
                        fontSize: "11px",
                        minWidth: "100px"
                      }}
                    >
                      Trouble Ticket
                    </th>
                    <th
                      style={{
                        color: "white",
                        color: "white",
                        fontSize: "11px",
                        minWidth: "180px"
                      }}
                    >
                      Trouble Ticket Id
                    </th>
                    <th
                      style={{
                        color: "white",
                        color: "white",
                        fontSize: "11px",
                        minWidth: "180px"
                      }}
                    >
                      Activity WentOut of Approved Window
                    </th>
                    <th
                      style={{
                        color: "white",
                        color: "white",
                        fontSize: "11px",
                        minWidth: "180px"
                      }}
                    >
                      Time After Approved Window
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* {siteDetail.site.sowComments} */}
                  {console.log(siteDetail.site, siteDetail.site.ranHistoryNew, "siteDetail.site.ranHistoryNew")}
                  {/* {console.log(siteDetail.site.ranHistoryNew?siteDetail.site.ranHistoryNew.length>0:siteDetail.site.ranHistoryNew,siteDetail.site.ranHistoryran ? siteDetail.site.ranHistoryran:"", "ranHistoryransiteDetail.site")} */}
                  {siteDetail.site.ranHistoryNew ? siteDetail.site.ranHistoryNew.map((itdata) => {
                    { console.log(itdata.crNumber, "siteDetail.siteanHistoryran") }
                    return <tr className="ng-scope">
                      <td className="ng-binding">{getdatedatalistnext(itdata["scheduleDate"])}</td>
                      <td className="ng-binding">{siteDetail.site.ndResult.siteId}</td>
                      <td className="ng-binding">{itdata.techtake ? itdata.techtake.join(", ") : ""}</td>
                      <td className="ng-binding">{itdata.serviceAffected}</td>
                      <td className="ng-binding">{itdata["sowList"].values.join(", ")}</td>
                      <td className="ng-binding">{itdata.postActivityStatusSiteLevel}</td>
                      <td className="ng-binding">{itdata.postsitecancelrollbackreason}</td>
                      {/* <td className="ng-binding">{siteDetail.site.troubleTickets.length>0?"YES":"NO"}</td> */}
                      {/* <td className="ng-binding">{siteDetail.site.troubleTickets.length>0?"YES":"NO"}</td>
                      <td className="ng-binding">{siteDetail.site.troubleTickets.map((iqp)=>{
                        return iqp.ttid
                      })}</td> */}
                      <td className="ng-binding">{itdata.ttOpen}</td>
                      <td className="ng-binding">{itdata.ttId}</td>
                      <td className="ng-binding">{itdata.activityWentOutOfApprovedWindow}</td>
                      <td className="ng-binding">{itdata.timeAfterApprovedWindow}</td>
                    </tr>
                    // <td className="ng-binding">{itdata.crStatus}</td>
                  }) : ""}

                </tbody>
              </table>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="downtime"
            role="tabpanel"
            style={{ height: "100%", overflow: "scroll" }}
            aria-labelledby="downtime-tab"
          >


            <DowntimeList siteDetail={siteDetail} from={"ran"}/>
          </div>

        </div>

      </>
    </>
  );
};

export default ManageProjectDetail;
