import React, { useEffect, useState, ComponentType, type } from "react";
import { Button, Dropdown } from "react-bootstrap";
import "../../../css/customStyle.css";
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { exportToCSV, ExportToExcel } from "../../../common/ExportToExcel";

import { Row, Col, Card, Nav } from 'react-bootstrap'
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { DateRange } from 'react-date-range';
import swal from 'sweetalert';
import moment from "moment"
import {
  NetworkDatabaseAction,
  CSVUploadAction,
} from "../../../store/actions/dashboardActions";
import { useHistory } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { google_map_api, center, dbdatetodate, customDataTableOptionObject, getMuiThemeCommon, getdatedatalistnext, dbdatetomoment, rancustomDataTableOptionObject, wrapText, smart_more_info, cmncustomDataTableOptionObject } from "../../../common/variables";
import { AccountReducerAction, addUserAction, ExportXLSXDownloadReducerAction, FetchSingleRecordAction, getFilterDropDown, GetTimeLineAction, LCLNestingReducerAction, popUpUpdate, ResetLCLNestingReducerAction } from "../../../store/actions/createTicketManageFields";
import { approveOrDisapprove, getAllDropDown, listSiteId, ranDashboard, ranSiteStatus, singleProjectApi, timelineApi, reqPost, reqPreCheckCancel, ranDashboardTimeLine, reqPostCheckCancel, ranSpilOver, ranNesting, export_nesting_ran_tracker, ranNesting_tracker, ranNesting_report, commonfilters } from "../../../common/config";
import RanSiteTracker from "../ProjectTrackerComponent/RanSiteTracker";
import RanHistorical from "../ReusableComponents/RanHistorical";
import SingleProject from "../ProjectModule/SingleProject";
import ProjectData from "../ReusableComponents/ProjectData";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { fontSize } from "@mui/system";
import CustomeModal from "../Custom/CustomeModal";
import CustomTable from "../Custom/CustomTable";
import RequestPreCheck from "../Custom/Modal Body/RequestPreCheck";
import { RangeDatePicker } from "@y0c/react-datepicker";
import { Input } from "reactstrap";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { id } from "date-fns/locale";
import { includeInnerHTML } from "@syncfusion/ej2-base";
import MapView from "../Custom/Modal Body/MapView";
import CommonModal from "../Custom/CommonModal";
import RefreshIcon from "@mui/icons-material/Refresh";
import DatePicker from "react-datepicker";
import FilterData from "../../../icons/Svg/FilterData";
import NestingFilter from "../Custom/Modal Body/NestingTrackerFilter";

const RanNestingTracker = () => {
  const [accordianState, setAccordianState] = useState(false);
  const [siteFile, uploadedFile] = useState();
  const [remarks, setRemarks] = useState();
  const [markersdatacount, setmarkerscount] = useState(1);
  const [markersdata, setmarkersdata] = useState([]);
  const [selectedCenter, setSelectedCenter] = useState(null);
  const [xlShow, setXlShow] = useState(false);
  const [crewModal, setCrewModal] = useState(false)
  const [reqPreModal, setReqPreModal] = useState(false)
  const [siteDetail, setSiteDetail] = useState({})
  const [siteUId, setSiteId] = useState()
  const history = useHistory();
  const [showDatePicker, setDatePicker] = useState(false)
  const [closer, setCloser] = useState(false);
  const [mapModal, setMapModal] = useState(false)

  
  const [fstartdate, setfstartdate] = useState();
  
  const [ResetFilter, setResetFilter] = useState(false);
  const [popissue, setpopissue] = useState(false);
  
  const [OneTimere, setOneTimere] = useState(false);
  
  const [RowCount, setRowCount] = useState(0);
  const [fenddate, setfenddate] = useState();


  const [commonModalState, setcommonModalState] = useState(false);
  const [commonModalSize, setcommonModalSize] = useState(false);
  const [commonModalBody, setcommonModalBody] = useState("");
  const [commonModalTitle, setcommonModalTitle] = useState("");
  const [commonModalHeader, setcommonModalHeader] = useState("");
  const [filterBadges, setFilterBadge] = useState()
  const [fullUrL, setfullUrL] = useState("");
  const [commonModalBodyFilter, setcommonModalBodyFilter] = useState("");
  const [commonModalStateFilter, setcommonModalStateFilter] = useState(false);
  const [commonModalHeaderFilter, setcommonModalHeaderFilter] = useState("");
  const [commonModalSizeFilter, setcommonModalSizeFilter] = useState(false);
  const [commonModalTitleFilter, setcommonModalTitleFilter] = useState("");
  const [exportApiData, setexportApiData] = useState("");
  


  const [dateRange, setDateRange] = useState([{
    startDate: new Date(),
    endDate: null,
    key: "selection"
  }])


  console.log(history, "history")

  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
  const [uniqueId, setUniqueId] = useState()
  const CommonModalFun = (body, title, header, type = "md") => {
    setcommonModalState(true);
    setcommonModalSize(type);
    setcommonModalTitle(title);
    setcommonModalBody(body);
    setcommonModalHeader(header);
  };


  const setDateFilter = (e) => {
    e.preventDefault();

    if (fstartdate == undefined && fenddate == undefined) {
      swal("Oops", "Please select Start & End Date", "info");
      return;
    }
    if (fstartdate == undefined) {
      swal("Oops", "Please select Start Date", "info");
      return;
    }

    if (fenddate == undefined) {
      swal("Oops", "Please select End Date", "info");
      return;
    }
    if (fstartdate > fenddate) {
      swal("Oops", "End Date is greater than Start Date", "info");
      return;
    }
    let start = formatDate(fstartdate);
    let end = formatDate(fenddate);
    let currentUrl = window.location.href;
    let urlArray = currentUrl.split("?");

    
    setResetFilter(true)

    

    let Filleddata=dispatch(LCLNestingReducerAction({dateUrl:`start=${start}&end=${end}`}))
    console.log(Filleddata,"FilleddataFilleddata")
    dispatch(AccountReducerAction(ranNesting_tracker + Filleddata,true));
    

    // dispatch(
    //   AccountReducerAction(
    //     ranNesting_tracker +
    //     "?start=" +
    //     moment(fstartdate).format("MM/DD/yyyy") +
    //     "&end=" +
    //     moment(fenddate).format("MM/DD/yyyy")
    //   )
    // );
  };


  function filterhandler() {
    dispatch(getFilterDropDown([]))
    dispatch(AccountReducerAction(commonfilters))
    // dispatch(AccountReducerAction(ranfilters))
    setcommonModalStateFilter(true)
    setcommonModalSizeFilter("xl")
    setcommonModalBodyFilter(<NestingFilter setfullUrL={setfullUrL} setcommonModalState={setcommonModalStateFilter} setFilterBadge={setFilterBadge} featureExport={setexportApiData} />)
    setcommonModalTitleFilter("SLA Report Filters")
    setcommonModalHeaderFilter("SLA Report Filter")
  }
  const dispatch = useDispatch();
  let getMuiTheme = () => createTheme({
    components: {
      MuiButtonBase: {
        styleOverrides: {
          root: {
            "line-height": "1 !important",
            "padding": "6px 0px"
          },
        }
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {

            maxHeight: 45,
            fontSize: 13,
            overflowY: "auto",
            padding: "0px 2px",

            margin: "auto"

            // maxHeight: 45,
            //   fontSize: 10,
            //   overflowY: "hidden",
            //   overflowX: "auto",
            //   padding: "3px",
            //   margin: "auto",
            //   textAlign: "center",
            //   width: "max-content",


          },
        }
      },
      // MuiToolbar: {
      //   styleOverrides: {
      //     root: {
      //       backgroundColor: '#f00',

      //     },
      //   },
      // },
      MuiTableCell: {
        styleOverrides: {
          head: {

            maxHeight: 20,
            fontSize: 15,
            margin: 0,
            paddingTop: 0,
            paddingBottom: 0
          },
        },
      },
      MUIDataTableSelectCell: {
        styleOverrides: {
          headerCell: {
            backgroundColor: 'blue',
          },
        },
      },
      MuiTableFooter: {
        styleOverrides: {
          root: {
            '& .MuiToolbar-root': {
              backgroundColor: 'white',
            },
          },
        },
      },
    },
  });

  let columns = [
    
    {
      label: "Schedule Date",
      name: "scheduleDate",
      sortable: true,
      options: {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 101
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      },
    },
    {
      label: "Week #",
      name: "scheduleWeek",
      options: {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            position: "sticky",
            zIndex: 101,
            minWidth: "68px",
            left: "138px",
            color: "white",
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            outline:"1.5px solid black",
            overflowY: "hidden",
            minWidth: "68px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
            left: "138px",
          },
        }),
      },
    },
    
    // {
    //   "name": "scheduleDate",
    //   "label": "Schedule Date",
    //   "sortable": false,
    //   options: {
    //     filter: true,

    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "nowrap",
    //         alignItem: "center",
    //         padding: "1px 1px",

    //         background: "#143b64",
    //         color: "white",
    //       },
    //     }),
    //     setCellProps: () => ({
    //       style: {
    //         whiteSpace: "nowrap",

    //         overflowY: "hidden",

    //         padding: "1px 1px",

    //         background: "White",
    //       },
    //     }),
    //   }
    // },
    // {
    //   "name": "scheduleWeek",
    //   "label": "Week #",
    //   "sortable": false,
    //   options: {
    //     filter: true,

    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",
    //         // minWidth:"150px",
    //         background: "#143b64",
    //         color: "white"

    //       }
    //     })
    //   }
    // },

    
    {
      label: "Site ID",
      name: "siteId",
      options: {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            position: "sticky",
            zIndex: 101,
            minWidth: "68px",
            left: "208px",
            color: "white",
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            outline:"1.5px solid black",
            overflowY: "hidden",
            minWidth: "68px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
            left: "208px",
          },
        }),
      },
    },
    // {
    //   "name": "scheduleWeek",
    //   "label": "Week #",
    //   "sortable": false,
    //   options: {
    //     filter: true,

    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",
    //         "min-width": "50px",
    //         "max-width": "60px",
    //         background: "#143b64",
    //         color: "white"

    //       }
    //     })
    //   }
    // },
    // {
    //   "name": "scheduleDate",
    //   "label": "Schedule Date",
    //   "sortable": false,
    //   options: {
    //     filter: true,

    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",
    //         "min-width": "125px",
    //         "max-width": "140px",
    //         background: "#143b64",
    //         color: "white"

    //       }
    //     })
    //   }
    // },
    {
      "name": "market",
      "label": "Market",
      "sortable": false,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "min-width": "50px",
            "max-width": "60px",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    // {
    //   "name": "siteId",
    //   "label": "Site ID",
    //   "sortable": false,
    //   options: {
    //     filter: true,

    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",
    //         "min-width": "90px",
    //         "max-width": "140px",
    //         background: "#143b64",
    //         color: "white"

    //       }
    //     })
    //   }
    // },
    {
      "name": "serviceAffected",
      "label": "Service Affecting",
      "sortable": false,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            minWidth: "150px",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      "name": "pSow",
      "label": "Planned SoW",
      "sortable": false,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            minWidth: "260px",
            maxWidth: "300px",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      "name": "sowCommentsModal",
      "label": "SoW Comments",
      "sortable": false,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            "overflow-wrap":"anywhere",
            whiteSpace: "wrap",
            "min-width": "300px",
            "max-width": "300px",
            background: "#143b64",
            color: "white"

          }
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            textAlign: "center",
            "min-width": "200px",
            'overflow-wrap':'anywhere',
            "max-width": "200px",
            padding: "1px 1px",
            background: "White",
          },
        }),
      }
    },
    {
      "name": "ttId",
      "label": "MPULSE TT ID(s)",
      "sortable": false,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            background: "#143b64",
            "min-width": "300px",
            color: "white"

          }
        })
      }
    },
    {
      "name": "pierttId",
      "label": "PIER TT ID(s)",
      "sortable": false,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            minWidth: "300px",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      "name": "ttimpact",
      "label": "TT Impact",
      "sortable": false,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "min-width": "300px",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      "name": "ttvendor",
      "label": "HardCost Vendor",
      "sortable": false,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "min-width": "300px",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      "name": "cxixcrewcompany",
      "label": "CX/IX Crew Company",
      "sortable": false,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "min-width": "100px",
            "max-width": "120px",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      "name": "ttowner",
      "label": "TT Owner",
      "sortable": false,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "min-width": "300px",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      "name": "LastNestingStart",
      "label": "Last Nesting Start",
      "sortable": false,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            minWidth: "150px",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      "name": "LastNestingExpire",
      "label": "Last Nesting Expire",
      "sortable": false,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            minWidth: "150px",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      "name": "nestingTime",
      "label": "Total Nesting Time",
      "sortable": false,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "min-width": "120px",
            "max-width": "140px",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      "name": "unnestedTime",
      "label": "Un-Nested Time",
      "sortable": false,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "min-width": "100px",
            "max-width": "120px",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      "name": "remarks",
      "label": "Remarks",
      "sortable": false,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            minWidth: "150px",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      "name": "LTPCR",
      "label": "GC Login Time",
      "sortable": false,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            minWidth: "150px",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      "name": "LTPOD",
      "label": "GC Logout Time",
      "sortable": false,
      options: {
        filter: true,


        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            minWidth: "150px",
            background: "#143b64",
            color: "white"

          }
        })
      }
    }
  ];

  <th scope="col">CX Crew Vendor</th>
  let cxTableHeader = ['CX Crew Vendor', 'CX Crew Lead Contact Number', 'CX Crew Lead Name', 'CX Crew Lead Company', 'CX Crew Lead E-mail ID']
  let ixTableHeader = ['IX Crew Vendor', 'IX Crew Lead Contact Number', 'IX Crew Lead Name', 'IX Crew Lead Company', 'IX Crew Lead E-mail ID']
  let rowData = [];
  let rowDataOne = [];
  let mark = [];
  let row = [];
  let ranCount=0

  let excelData = []
  let datamark = [];
  let siteStatus = [];
  function createmarker(mark) {
    console.log(datamark, "createmarkerdatamark");

    setmarkersdata(datamark);

    console.log(...markersdata, "markersdata");
  }
  function openModal(data, id = null) {

    console.log("openModal", data, reqPreModal)
    if (data == 'reqPreCheck') {
      setReqPreModal(true)
      console.log("the req premodal =", data)
      setUniqueId(id)
      setCloser(true)

    }
    else
      if (id == null) {
        setCrewModal(true)
        setSiteDetail(data)
        console.log("th dta is  =", reqPreModal)
        console.log("the main data is =", siteDetail)
      }
  }





  const count = useSelector((state) => {
    console.log("the state is=", state);
    let sitesdata = state.CommonResponse.ranNestingTracker;
    if (sitesdata) {
      if (markersdatacount == 1) {
        // setmarkersdata(sitesdata)
        console.log(state.sites.sites, "sitesdata");
        createmarker(sitesdata);
        sitesdata.map((marker) => {
          if (marker.latitude != "" && marker.longitute != "") {
            datamark.push({
              latitude: parseFloat(marker.latitude),
              longitute: parseFloat(marker.longitute),
              id: marker.latitude,
            });
          }
        });

        console.log(datamark, "datamark");
        setmarkerscount(0);
      }
      // sitesdata.map(item=>{
      //   if(item.latitude!='' && item.longitute!=''){
      //     if(markersdatacount==1){
      //       createmarker(mark)
      //       setmarkerscount(0)
      //     }

      //   }
      // })
    }



    let ranSiteStatus = state.CommonResponse.ranSiteStatus
    // console.log("the ran site status =",state.CommonResponse.ranSiteStatus)
    if (ranSiteStatus && ranSiteStatus.length > 0) {
      console.log("the ran site status =", ranSiteStatus)
      siteStatus = ranSiteStatus
    }
    console.log(mark, "mark");

    if (Object.keys(state.CommonResponse.ranNestingTracker).length > 0) {
      if (state.CommonResponse.ranNestingTracker.length > 0) {


        rowData = state.CommonResponse.ranNestingTracker.map((item, index) => {

          console.log("i am caling", state)
          item['action'] = <><button className="btn btn-primary shadow btn-xs sharp mr-1"  ><i className="fa fa-pencil"></i></button>
            <button className="btn btn-danger shadow btn-xs sharp"><i className="fa fa-trash"></i></button></>
          item["5GId"] = "";
          item["LTEId"] = "";
          item["UMTSId"] = "";
          item["mixedModeId"] = "";
          item['crNo'] = item.crNumber

          const myMomentObject = dbdatetomoment(getdatedatalistnext(item["scheduleDate"]))
          item["scheduleWeek"] = myMomentObject.isoWeekday(1).week()
          item["account"] = (
            <p>{item.ndResult ? item.ndResult.account : ""}</p>
          );
          // item["market"] = (
          //   <p>{item.ndResult ? item.ndResult.market : ""}</p>
          // );
          // item["siteId"] = <p>{item.ndResult?item.ndResult.siteId:""}</p>;
          if (item["mopDownTime"]) {
            let cTime = new Date()
            let sdate = moment(new Date(item["mop9StartTime"])).format("MM/DD/YYYY")
            console.log("the date is =", sdate)
            console.log("the date is =", moment(item["mopStartTime"]).format("MM/DD/YYYY"))
            // console.log("the date is =",item["mopDownTime"])
            // console.log("the real data=",cTime.getHours())
            // console.log("the real datasdasa=",cTime.getDate())
            // console.log("the real datdadasdasdasdaada=", sdate.getDate())

            // console.log("the date is =",item["mopDownTime"].getDate())
            // if(sdate.getMonth()==cTime.getMonth() && sdate.getDate()==cTime.getDate() && cTime.getHours()>=sdate.getHours() ){
            //   let remainingTime =sdate.getHours()>=cTime.getHours()
            //   console.log("the difference is =",remainingTime)
            //   item['progress']=<div style={{ width: 40, height: 40 }}><CircularProgressbar value={remainingTime/100} maxValue={1} text={`${remainingTime}%`} /></div>
            // }
            console.log("the real time=", (new Date(item["mopDownTime"])).getTime())
          }

          // item['siteId'] = <a href={'/PAG/ran/support/dashboard/'+item.newuniqueId} target="_blank">{item.siteId}</a>
          // console.log("item is =", item["technology"]);
          // item['technology']=''

          // dasdas

          item['LTPCR'] = item.preCheckRequest? getdatedatalistnext(item.preCheckRequest): ""
          item['LTPCD'] = item.preCheckDelivered? getdatedatalistnext(item.preCheckDelivered): ""
          item['LTPOR'] = item.postCheckRequest? getdatedatalistnext(item.postCheckRequest): ""
          item['LTPOD'] = item.postCheckDelivered? getdatedatalistnext(item.postCheckDelivered): ""


          let ttId=[]
          let pierttId=[]
          let ttimpact=[]
          let ttvendor=[]
          let ttowner=[]
          item.troubleTickets.map((onett)=>{
            ttId.push(onett.ttid)
            pierttId.push(onett.tmoPierTtid)
            ttimpact.push(onett.impact)
            ttvendor.push(onett.vendor)
            ttowner.push(onett.engineerOpeningtt)
          })
          item['ttId'] = ""

          // console.log(ttdata["ttId"],"ttdatattdatattdata")

          // item['ttId']=ttId.join(", ")
          // item['pierttId']=pierttId.join(", ")
          // item['ttimpact']=ttimpact.join(", ")
          // item['ttvendor']=ttvendor.join(", ")
          // item['ttowner']=ttowner.join(", ")

          
          item['ttId']=smart_more_info(ttId.join(", "),"","TT Id",CommonModalFun)
          item['pierttId']=smart_more_info(pierttId.join(", "),"","Pier TT Id",CommonModalFun)
          item['ttimpact']=smart_more_info(ttimpact.join(", "),"","TT Id",CommonModalFun)
          item['ttvendor']=smart_more_info(ttvendor.join(", "),"","TT Id",CommonModalFun)
          item['ttowner']=smart_more_info(ttowner.join(", "),"","TT Id",CommonModalFun)



          // if(item.ranStatus !="Login Awaited - CR Approved" && item.ranStatus !="Login Awaited - CR Approved")
          // else if(item.)
          item['sowCommentsModal'] = (
            <p
              className={"text-black my-0 modalHoverClass"}
              onClick={() =>
                CommonModalFun(

                  <p style={{ border: "2px solid black", color: "black", padding: 2 }} className="CommonModal">{item['sowComments']}</p>,
                  "Comment",
                  "Sow Comments"
                )
              }
            >
              {wrapText(item['sowComments'])}
            </p>
          )
          item['LastNestingStart'] = item.nestresult ? item.nestresult.nestingStart : ""
          item['nestingTime'] = item.nestresult ? item.nestresult.nestType == "Nesting Time Gap" ? item.nestresult.unnestingHour :item.nestresult.nestingHour : ""
          
          item['LastNestingExpire'] = item.nestresult ? item.nestresult.nestingEnd : ""
          item['unnestedTime'] = item.nestresult ? item.nestresult.nestType == "Nesting Time Gap" ? <p className="badge badge-danger py-1 px-3">YES</p> : <p className="badge pendingBg py-1 px-3 ">NO</p> : ""

          let pSow = "";
          let pSowArray = item["sowList"].values;
          if (pSowArray && pSowArray.length > 0) {
            //  item['technology'].forEach((item)=>{
            //   tec +=item.label +','
            //  })
            console.log("sgrthdhr", pSowArray.length);
            for (let i = 0; i < pSowArray.length; i++) {
              console.log("sgrthdhr", pSowArray[i]);
              pSow += pSowArray[i] + ",";
            }
            //  item['technology']=tec
          }
          // console.log("item is tec =", tec);

          //  console.log("item is =",item['technology'])
          // item['technology']=tec
          console.log("the psow is =", pSow)
          item["pSow"] = pSow;
          //Technology


          //  console.log("item is =",item['technology'])
          // item['technology']=tec
          item["cxixcrewcompany"] = item.cxixuserIdresult?item.cxixuserIdresult.crewLeadCompanyId:""

          // excelData=columns.map((oneColumn)=>
          // let excelObject={}
          // for(let i=0;i<columns.length;i++){

          //   excelObject[columns[i].label]='item['+columns[i].name + ']'

          // }
          // excelData.push(excelObject)

          // console.log("the excel data is =",excelData)          
          return item;
        });
        excelData = state.CommonResponse.ranDashboard.map((item) => {

          let pSow = "";
          let pSowArray = item["sowList"].values;
          if (pSowArray && pSowArray.length > 0) {
            //  item['technology'].forEach((item)=>{
            //   tec +=item.label +','
            //  })
            console.log("sgrthdhr", pSowArray.length);
            for (let i = 0; i < pSowArray.length; i++) {
              console.log("sgrthdhr", pSowArray[i]);
              pSow += pSowArray[i] + ",";
            }
            //  item['technology']=tec
          }
          // console.log("item is tec =", tec);

          //  console.log("item is =",item['technology'])
          // item['technology']=tec
          console.log("the psow is =", pSow)
          item["pSow"] = pSow;
          if (item['mopStartTime'] && item['mopEndTime']) {
            let mopSTime = item['mopStartTime'].split(" ").pop()
            let mopETime = item['mopEndTime'].split(" ").pop()
            item['rfMWTime'] = mopSTime + " to " + mopETime
          }
          // let excelObject = {
          //   "Schedule Date": item["mopStartTime"],
          //   "Main Id": item.ndResult.siteId,
          //   "Service Affected": item["serviceAffected"],
          //   "Technology": item["temp"],
          //   "Project Code": item["projectCode"],
          //   "RAN Bucket": item["ranBucket"],
          //   "Cr Number": item["crNo"],
          //   "Cr Status": item["crStatus"],
          //   "Planned SOW": item["pSow"],
          //   "RAN Status": item["ranStatus"],
          //   "Equipment": item["equipment"],
          //   "Day or Night MOP": item["dayNightMop"],
          //   "Day MOP Reason": item["dayMopReason"],
          //   "Rf Approval MW Time": item["rfMWTime"],
          //   // "Manual PreCheck Request": item["mPrecheckReq"],
          //   "Login Time PreCheck Request(mobile)": dbdatetodate(item.preCheckRequest),
          //   "Pre-Checks": '',// item["preCheck"],
          //   "Login Time Precheck Delivered": item[""],// item["LTPCD"],
          //   "Nesting Time": item["mopDownTime"],
          //   // "Progress": item["progress"],
          //   "Crew": "",// item["crew"],
          //   "Call Test Activity": item[""],// item["CTA"],
          //   "Manual Postcheck Request": item[""],// item["postCh"],
          //   "Logout Time Postcheck Request": item[""],// item["LTPOR"],
          //   "Post Checks": item[""],// item["postChecks"],
          //   "Logout Time Postcheck Delivered": item[""],// item["LTPOD"],
          //   "Pier Updated": item[""],
          //   // "Site Type": item.ndResult.siteType
          // }
          // return excelObject
        })


      }
    }


    
    // let totalCount=state.countState.ranNestingTrackerCount
    ranCount=state.countState.ranNestingTrackerCount!=0?state.countState.ranNestingTrackerCount:ranCount
    

    console.log("columns", state.sites.sites.TotalTicket);

    return { rowData, columns, mark, siteStatus, ranCount };
  });

  const options = {

    download: false,
    viewColumns: false,
    print: false,
    filterType: "none",
    selectableRows: false,
    responsive: true,
  };


  const mapStyles = {
    width: "100%",
    height: "100%",
  };

  function accordianHandler() {
    setAccordianState(!accordianState);
  }

  createTheme("solarized", {
    text: {
      primary: "inherit",
      secondary: "inherit",
    },
    background: {
      default: "inherit",
    },
    context: {
      background: "inherit",
      text: "inherit",
    },
    divider: {
      default: "inherit",
    },
    button: {
      default: "inherit",
      hover: "rgba(0,0,0,.08)",
      focus: "rgba(255,255,255,.12)",
      disabled: "rgba(255, 255, 255, .34)",
    },
    sortFocus: {
      default: "inherit",
    },
  });
  const handleChange = (e) => {
    console.log("the current anser= ", e.target.value)
  }


  const ClearFilter = () => {
    console.log("ClearFilter", "ClearFilter");
    setfstartdate();
    setfenddate();

    setOneTimere(true)

    setResetFilter(true)
    // dispatch(AccountReducerAction(ranNesting_tracker,true,true));

    setFilterBadge()
    let Filleddata=dispatch(ResetLCLNestingReducerAction())
    dispatch(AccountReducerAction(ranNesting_tracker+Filleddata,true,true));
  };

  
  const exportAllData = () => {

    
    dispatch(ExportXLSXDownloadReducerAction(export_nesting_ran_tracker,"RAN_NESTING_TRACKER_" + moment(new Date()).format("MM-DD-YYYY")))
  }


  const AddCustomButton = () => (
    <fregment
      style={{
        float: "right",
        display: "flex",
        flexDirection: row,
        justifyContent: "space-between",
      }}
    >
      <p onClick={filterhandler} className=" mx-2 " style={{ "align-self": "center" }}><FilterData /></p>



      <>
        <button
          className="btn btn-xs btn-primary mx-2 my-3"
          data-toggle="tooltip"
          data-placement="top"
          title="Refresh"
          onClick={() => {
            ClearFilter();
            // history.push("/PAG/ran/support/dashboard")
          }}
          style={{ padding: "6px" }}
        >
          <RefreshIcon />
        </button>
      </>


      <div
        style={{
          width: "46vh",
          display: "flex",
          alignItems: "center",

          margin: "18px 10px auto 10px"
        }}
      >
        <DatePicker
          placeholderText="MM/DD/YYYY"
          id="color-of-datepicker"
          className="date-picker-changes "
          selected={fstartdate}
          autoComplete={"off"}
          onChange={(e) => {
            setfstartdate(e);
            // setfenddate(e);
          }}
          dateFormat="MM/dd/yyyy"
        />
        <DatePicker
          placeholderText="MM/DD/YYYY"
          className="date-picker-changes mx-1"
          id="color-of-datepicker"
          selected={fenddate}
          autoComplete={"off"}
          onChange={(e) => {
            setfenddate(e);
          }}
          minDate={fstartdate}
          dateFormat="MM/dd/yyyy"
        />
      </div>
      <button
        variant="float-right"
        className="btn btn-primary btn-xs mx-2 py-2 my-3 float-right d-flex justify-content-center align-items-center"
        // onClick={() => setDatePicker(!showDatePicker)}
        onClick={(e) => setDateFilter(e)}
      >
        <i class="fa fa-filter mx-2"></i>Filter
      </button>

      {/* {userDetails.rolename !== "GC Login"
        // && (
        // <button
        //   className="btn btn-primary btn-xs mx-2 py-2 my-3 d-flex justify-content-center align-items-center"
        //   data-toggle="modal"
        //   data-target="#exampleModall"
        //   onClick={() => setMapModal(true)}
        // >
        //   Map View
        // </button>
        // )
      }
       */}
      <Button onClick={exportAllData} className="btn btn-primary btn-xs mx-2 py-2 my-3 d-flex justify-content-center align-items-center">
        Export
      </Button>

      {/* <ExportToExcel
        apiData={excelData}
        fileName={"RanDashboard_Tracker" + moment(new Date()).format("MMDDYYYY")}
      /> */}
    </fregment>
  );

  // const AddCustomButton = () => (
  //   <fregment style={{ float: "right", display: "flex", flexDirection: row, justifyContent: "space-between" }}>
  //     <ExportToExcel
  //       apiData={excelData}
  //       fileName={"RanDashboard_" + moment(new Date()).format("MM/DD/YYYY")}
  //     />


  //   </fregment>
  // )



  console.log("yhe row data isn=", rowData);

  console.log("the excel data is =", excelData, URL)
  useEffect(() => {
    const url = window.location.href.split("/")
    let id = url.pop()
    dispatch(AccountReducerAction(ranNesting_tracker,true,true));

  }, []);
  return (
    <>
      <CommonModal
        modalSize={commonModalSize}
        setXlShow={setcommonModalState}
        xlShow={commonModalState}
        Body={commonModalBody}
        Header={commonModalHeader}
        Title={commonModalTitle}
      ></CommonModal>

      <CommonModal
        modalSize={commonModalSizeFilter}
        setXlShow={setcommonModalStateFilter}
        xlShow={commonModalStateFilter}
        Body={commonModalBodyFilter}
        Header={commonModalHeaderFilter}
        Title={commonModalTitleFilter}
      ></CommonModal>
      <div className="mui-t-h"
        style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}
      >

        <ThemeProvider theme={getMuiThemeCommon()}>

          <MUIDataTable
            data={rowData}
            columns={columns}
            // options={customDataTableOptionObject(AddCustomButton)}
            // options={rancustomDataTableOptionObject(AddCustomButton,false,"RanTracker", "","",{},AccountReducerAction,ranNesting_tracker,history.location.search,rowData.length,count.totalCount,[fstartdate,fenddate],OneTimere,setOneTimere,setResetFilter,ResetFilter)}
            // cmncustomDataTableOptionObject(toolbarButton, RowCount, totalCount,OneTimere,setOneTimere,setResetFilter,ResetFilter,filterOn=true,searchOn=true)
            options={cmncustomDataTableOptionObject(AddCustomButton,RowCount,count.ranCount,OneTimere,setOneTimere,setResetFilter,ResetFilter,false,false,ResetLCLNestingReducerAction,ranNesting_tracker)}
            
          />
        </ThemeProvider>
      </div>
    </>
  );
};

export default RanNestingTracker;
