import React,{useState,useEffect} from 'react'
import { useSelector } from 'react-redux';

const Table4 = ({id}) => {
       
const[data,setData]=useState([]);
let siteInfo={}
// const[data,setData]=useState([]);
  // let  siteDetail={siteInfo:{}}
  let siteDetail= useSelector((state)=>{
    console.log("the url =",state)
  let site =state.OneCommonResponse.singleSite
  
  if(typeof(site)== typeof({}) ){
    siteInfo=site
    console.log("th e sit e is sit=",siteInfo)
    return {siteInfo}
  }
  
  })
  return (
    <div> <table className="table-bordered " style={{ width: "100%",overflow:"auto" }}>
    <tr>
      <th>Postcheck Engineer</th>
      <td >{}</td>
    </tr>

    <tr>
      <th >5G Downtime(If Any)</th>
      <td >{siteDetail? siteDetail.siteInfo['post5GDownTime']?siteDetail.siteInfo['post5GDownTime']:"":""}</td>
    </tr>

    <tr>
      <th >GSM Downtime(If Any)	</th>
      <td >{siteDetail? siteDetail.siteInfo.postGsmDownTime?siteDetail.siteInfo.postGsmDownTime:"":""}</td>
    </tr>

  
    <tr>
      <th >UMTS Downtime(If Any)</th>
      <td >{siteDetail? siteDetail.siteInfo.postUmtsDownTime?siteDetail.siteInfo.postUmtsDownTime:"":""}</td>
    </tr>

    <tr>
      <th >LTE Downtime(If Any):</th>
      <td >{siteDetail? siteDetail.siteInfo.postLteDownTime?siteDetail.siteInfo.postLteDownTime:"":""}</td>
    </tr>
    <tr>
      <th>BSC IP Contact</th>
      <td ></td>
    </tr>

    <tr>
      <th >GSM Alarm</th>
      <td >{siteDetail? siteDetail.siteInfo.postGSMAlarm?siteDetail.siteInfo.postGSMAlarm:"":""}</td>
    </tr>

    <tr>
      <th >Nesting Time</th>
      <td ></td>
    </tr>
    <tr>
      <th >UMTS IP Contact</th>
      <td ></td>
    </tr>
    { data .map((item) => ( 
    <tr>
      <th >UMTS Alarm	</th>
      <td >{siteDetail? siteDetail.siteInfo.PostUMTSAlarm?siteDetail.siteInfo.PostUMTSAlarm:"":""}</td>
    </tr>
    ))}
    <tr>
      <th >5G IP Contact</th>
      <td ></td>
    </tr>
    {/* <tr>
      <th>Sow Details</th>
      <td >Adarsh</td>
    </tr> */}
    <tr>
      <th >5G Alarm</th>
      <td >{siteDetail? siteDetail.siteInfo['Post5GAlarm']?siteDetail.siteInfo['Post5GAlarm']:"":""}</td>
    </tr>
    {/* <tr>
      <th >LTE IP Contact</th>
      <td >5</td>
    </tr> */}
    <tr>
      <th >LTE Alarm</th>
      <td ></td>
    </tr>
    <tr>
      <th >Was TS or Scripting was Required</th>
      <td >{siteDetail? siteDetail.siteInfo.wasTSOrScriptingWasRequired?siteDetail.siteInfo.wasTSOrScriptingWasRequired:"":""}</td>
    </tr>
    {/* <tr>
      <th>Scripting Engineer</th>
      <td >Adarsh</td>
    </tr> */}
    {/* { data .map((item) => (   */}
    <tr>
      <th >TT(s) Opend</th>
      <td >{siteDetail? siteDetail.siteInfo.ttOpen?siteDetail.siteInfo.ttOpen:"":""}</td>
    </tr>


    <tr>
      <th >TT ID(s)</th>
      <td >{siteDetail? siteDetail.siteInfo.ttId?siteDetail.siteInfo.ttId:"":""}</td>
    </tr> 
    {/* <tr>
      <th >Site Up Time:</th>
      <td >{item.siteUpTime}</td>
    </tr> */}

    <tr>
      <th >Activity went out of approved window	:</th>
      <td >{siteDetail? siteDetail.siteInfo.ActivityWentOutOfApprovedWindow?siteDetail.siteInfo.ActivityWentOutOfApprovedWindow:"":""}</td>
    </tr>
    <tr>
      <th >Time after approved window	:</th>
      <td >{siteDetail? siteDetail.siteInfo.TimeAfterApprovedWindow?siteDetail.siteInfo.TimeAfterApprovedWindow:"":""}</td>
    </tr>

    <tr>
      <th >Logout Time Post Delivered Date:</th>
      <td >{siteDetail? siteDetail.siteInfo.logoutTime?siteDetail.siteInfo.logoutTime:"":""}</td>
    </tr>
    <tr>
      <th >Activity Status(Site Level)</th>
      <td ></td>
    </tr>

    <tr>
      <th >Post check alarms/Issues:</th>
      <td >{siteDetail? siteDetail.siteInfo.postCheckAlarmIssue?siteDetail.siteInfo.postCheckAlarmIssue:"":""}</td>
    </tr>
 
    <tr>
      <th >Activity Notes</th>
      <td >{siteDetail? siteDetail.siteInfo.postActivityNotes?siteDetail.siteInfo.postActivityNotes:"":""}</td>
    </tr>
    
  </table></div>
  )
}

export default Table4;