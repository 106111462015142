import React from 'react'

const CloseOutReport = () => {
  return (
    <>
        <h4>Close Out Report</h4>
    </>
  )
}

export default CloseOutReport