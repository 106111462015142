import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import MapView from "./Modal Body/MapView";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import { Button, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ExportDownloadReducerAction, ExportXLSXDownloadReducerAction, ExportZipDownloadReducerAction, getMOPInstructionAction, popUpUpdate } from "../../../store/actions/createTicketManageFields";
import CommonModal from "./CommonModal";
import { baseURL } from "../../../common/config";
import Download from "../../../icons/Svg/Download";

const CustomeModal = ({ siteDetail,Header, xlShow,filteredMapData, setXlShow, Body, tableHeader, tableHeaderSecond, uniqueId, selectedId, work, modalSize, ctr, mapdata ,nestDetail,callFunction}) => {
  console.log("the data is =", siteDetail?.ndResult?.siteId,Header, xlShow,filteredMapData, setXlShow, Body, tableHeader, tableHeaderSecond, uniqueId, selectedId, work, modalSize, ctr, mapdata ,nestDetail,callFunction)
  console.log("the site details id =", tableHeaderSecond)
  console.log("filteredMapData id =", filteredMapData)
  console.log("the swork", work)
  const history = useHistory()
  let currentUrl =window.location.href
  currentUrl=currentUrl.split("/")
  let isOpen =currentUrl.pop()

  const count = useSelector((state) => {
    console.log(state,"statestatestate")

    let allData=state.CommonResponse.mobileDataCopTimeline
    return {allData}
  })
  
  const [commonModalState, setcommonModalState] = useState(false);
  const [commonModalSize, setcommonModalSize] = useState(false);
  const [commonModalBody, setcommonModalBody] = useState("");
  const [commonModalTitle, setcommonModalTitle] = useState("");
  const [commonModalHeader, setcommonModalHeader] = useState("");
  
  const [oneimgcommonModalState, setoneimgcommonModalState] = useState(false);
  const [oneimgcommonModalSize, setoneimgcommonModalSize] = useState(false);
  const [oneimgcommonModalBody, setoneimgcommonModalBody] = useState("");
  const [oneimgcommonModalTitle, setoneimgcommonModalTitle] = useState("");
  const [oneimgcommonModalHeader, setoneimgcommonModalHeader] = useState("");
  const [downUrl, setdownUrl] = useState("");
  
  const [popissue, setpopissue] = useState(false);
  const [closer, setCloser] = useState(false);
  console.log("the is open",isOpen=="open")

  const dispatch = useDispatch();
  
  const CommonimgModalFun = (body, title, header, type = "md") => {
    setoneimgcommonModalState(true);
    setoneimgcommonModalSize(type);
    setoneimgcommonModalTitle(title);
    setoneimgcommonModalBody(body);
    setoneimgcommonModalHeader(header);
  }

  const downloadFile=(url)=>{
    console.log(count.allData.timeline,"count.allData")
    // setdownUrl(count.allData.timeline)
    dispatch(ExportDownloadReducerAction(url,siteDetail?.ndResult?.siteId+"_"+moment(new Date()).format("MMDDYYYYHHMMSS"),"zip"))

  }
  const CommonModalFun = (body, title, header, type = "md") => {
    let data;
    console.log(count.allData,"allData.timeline")
    

    if(title=="Timeline"){
      
      // let odata=""
      let odata = count.allData.timeline.map((oneitm,index)=>{
        console.log(oneitm.text,oneitm.time,"oneitmCommonModalFun")
        return <tr>
          <td style={{ width: "30%;", paddingTop: "11px", fontSize: "13px", height: "45px",border: "2px solid black" }}>
            {index+1}
          </td>
          <td style={{ width: "30%;", paddingTop: "11px", fontSize: "13px", height: "45px",border: "2px solid black" }}>
            {oneitm.text}
          </td>
          <td style={{ width: "30%;", paddingTop: "11px", fontSize: "13px", height: "45px",border: "2px solid black" }}>
            {oneitm.time}
          </td></tr>
      })
      console.log(odata,"odataodata")
      data=<><h4>TIMELINE</h4><table className="table">
        <thead>
          <td style={{ width: "30%;", paddingTop: "11px", fontSize: "13px", height: "45px",border: "2px solid black" }}>S.No.</td>
          <td style={{ width: "35%;", paddingTop: "11px", fontSize: "13px", height: "45px",border: "2px solid black" }}>Value</td>
          <td style={{ width: "35%;", paddingTop: "11px", fontSize: "13px", height: "45px",border: "2px solid black" }}>Time</td>
        </thead>
        <tbody>
          {odata}
        </tbody>
      </table></>
    }

    if(title=="COP Details"){
      
      // let odata=""
      let pre=[];
      let post=[];
      count.allData.eosImageData.map((oneitm,index)=>{
        console.log(oneitm.text,oneitm.time,"oneitmCommonModalFun")
        if(oneitm["_id"]=="Pre"){
          // pre.push(
            pre=oneitm.eosdata.map((oneeos,index)=>{

              console.log(oneeos)
              return <tr>
                <td style={{ width: "30%;", paddingTop: "11px", fontSize: "13px", height: "45px",border: "2px solid black" }}>
                  {index+1}
                </td>
                <td style={{ width: "30%;", paddingTop: "11px", fontSize: "13px", height: "45px",border: "2px solid black" }}>
                  {oneeos.result}
                </td>
                <td style={{ width: "30%;", paddingTop: "11px", fontSize: "13px", height: "45px",border: "2px solid black" }}>
                  {/* {} */}
                  <div onClick={(e)=>{
                    CommonimgModalFun(<><img src={baseURL+oneeos.filePath} style={{"width":"100%"}}></img></>,oneeos.result+" Image Preview",oneeos.result+" Image Preview","")
                  }}><a className ="badge badge-success"><Download/></a></div>
                  {/* <a href= {baseURL+oneeos.filePath} target="_blank" className ="badge badge-success" download><Download/></a> */}
                </td>
              </tr>
            })
          // )
        }else{
          post=oneitm.eosdata.map((oneeos,index)=>{
            return <tr>
              <td style={{ width: "30%;", paddingTop: "11px", fontSize: "13px", height: "45px",border: "2px solid black" }}>
                {index+1}
              </td>
              <td style={{ width: "30%;", paddingTop: "11px", fontSize: "13px", height: "45px",border: "2px solid black" }}>
                {oneeos.result}
              </td>
              <td style={{ width: "30%;", paddingTop: "11px", fontSize: "13px", height: "45px",border: "2px solid black" }}>
                {/* {oneeos.filePath} */}
                
                <div onClick={(e)=>{
                    CommonimgModalFun(<><img src={baseURL+oneeos.filePath} style={{"width":"100%"}}></img></>,oneeos.result+" Image Preview",oneeos.result+" Image Preview","")
                  }}><a className ="badge badge-success"><Download/></a></div>
                  
                  {/* <a href= {baseURL+oneeos.filePath} target="_blank" className ="badge badge-success" download><Download/></a> */}
              </td>
            </tr>
          })
        }
      })

      console.log(post,pre,"odataodata")
      data=<>
      {/* <div className="row"> */}
        <div className="col-md-6" style={{float:"left"}}>
          <h4>COP PRE</h4>
          <table className="table">
            <thead>
              <td style={{ width: "30%;", paddingTop: "11px", fontSize: "13px", height: "45px",border: "2px solid black" }}>S.No.</td>
              <td style={{ width: "35%;", paddingTop: "11px", fontSize: "13px", height: "45px",border: "2px solid black" }}>COP</td>
              <td style={{ width: "35%;", paddingTop: "11px", fontSize: "13px", height: "45px",border: "2px solid black" }}>Image</td>
            </thead>
            <tbody>
              {pre}
            </tbody>
          </table>
        </div>
        <div className="col-md-6" style={{float:"right"}}>
          <h4>COP POST</h4>
          <table className="table">
            <thead>
              <td style={{ width: "30%;", paddingTop: "11px", fontSize: "13px", height: "45px",border: "2px solid black" }}>S.No.</td>
              <td style={{ width: "35%;", paddingTop: "11px", fontSize: "13px", height: "45px",border: "2px solid black" }}>COP</td>
              <td style={{ width: "35%;", paddingTop: "11px", fontSize: "13px", height: "45px",border: "2px solid black" }}>Image</td>
            </thead>
            <tbody>
              {post}
            </tbody>
          </table>
        </div>
      {/* </div> */}
      </>
    }
    console.log(count.allData.eosImageData,data,count.allData.timeline,"countcountcountcountallDataallData")
    setcommonModalState(true);
    setcommonModalSize(type);
    dispatch(popUpUpdate({}));
    setCloser(true);
    setpopissue(true);
    setcommonModalTitle(title);
    setcommonModalBody(data);
    setcommonModalHeader(header);


  };

  return (
    <>
    <Modal
      size={modalSize ? modalSize : "xl"}
      show={xlShow}
      onHide={() =>setXlShow(false)}
      aria-labelledby="example-modal-sizes-title-xl"
      backdrop="static"
    >
      <Modal.Header className="CommonModalHeader">
        <Modal.Title id="example-modal-sizes-title-xl ">
          <h4 className="CommonModalHeaderHeading">{Header}</h4>
          <Button
            variant=""
            className="close CommonModalClose"
            onClick={() =>{
               if(isOpen=="open") {
              console.log("the nav")
              history.push("/PAG/Mop/database") 
              dispatch(getMOPInstructionAction([]))
              console.log("the navigation done")
              setXlShow(false)
            }
            else{
               setXlShow(false)}
            }}
          >
            <span className="CommonModalClose">&times;</span>
          </Button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "white",padding:"0px !important" }}>
        <div className="mt-2">
          {tableHeader ?
            <>

            {console.log("siteDetaillogger",siteDetail)}
              {/* <Tabs
                defaultActiveKey={siteDetail ? siteDetail.cxCrewId? siteDetail.cxCrewId.name?"home":"profile":"profile":""}
                id="fill-tab-example"
                className="mb-3"
                fill
              >
                <Tab eventKey="home" title="CX Crew"> */}
                  <Body tableHeader={tableHeader} siteDetail={siteDetail }  />
                {/* </Tab>

                {tableHeaderSecond ? <Tab eventKey="profile" title="IX Crew">
                  <Body tableHeader={tableHeaderSecond} crewDetial={siteDetail.ixCrewId ? siteDetail.ixCrewId : null} />
                </Tab> : <></>}
              </Tabs> */}
              <div className="py-3 d-flex justify-content-center align-items-center">
              <button className="btn btn-xs mt-5 btn-primary mx-1">
              <Link
                    className="text-white"

                    onClick={(e)=>{callFunction("crewInfo",siteDetail.ranSupportId)}}
                    // to={"/PAG/ran/OntheFlyForm/crewInfo/" + siteDetail.ranSupportId}
                  >
                   Handover
                  </Link></button>
                <button onClick={(e)=>{CommonModalFun("COP Details","COP Details","COP Details","lg")}} className="btn btn-xs mt-5 btn-primary mx-1">Cop Details</button>
                <button onClick={(e)=>{CommonModalFun("Timeline","Timeline","Timeline","xl")}} className="btn btn-xs mt-5 btn-primary mx-1">Timeline</button>
                {
                count?.allData?.mobileCrewimagesdata?.length>0?
                  <button onClick={(e)=>{downloadFile(count?.allData?.mobileCrewimagesfilePath)}} className="btn btn-xs mt-5 btn-primary mx-1">Uploaded Image</button>
                :""
                }
                </div>
            </>
            :

            selectedId ?
              <Body selectedId={selectedId} />
              :
              nestDetail?<Body nestDetail={nestDetail}  />
              :
              modalSize ?
                <Body filteredMapData={filteredMapData} mapdata={mapdata} />
                :
                work ? <Body id={uniqueId} work={work} ctr={ctr} setXlShow={setXlShow} />
                  : <Body id={uniqueId} ctr={ctr} setXlShow={setXlShow} />
        

          }
        </div>
      </Modal.Body>
    </Modal>
    

    <CommonModal
    modalSize={commonModalSize}
    setXlShow={setcommonModalState}
    xlShow={commonModalState}
    Body={commonModalBody}
    Header={commonModalHeader}
    Title={commonModalTitle}
  ></CommonModal>
  <CommonModal
    modalSize={oneimgcommonModalState}
    setXlShow={setoneimgcommonModalState}
    xlShow={oneimgcommonModalState}
    Body={oneimgcommonModalBody}
    Header={oneimgcommonModalHeader}
    Title={oneimgcommonModalTitle}
  ></CommonModal>


  
  </>



  );
};

export default CustomeModal;
