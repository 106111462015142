import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AccountReducerAction, LCLlssvDashboardReducerAction, LCLranDashboardReducerAction, LCLssvDashboardReducerAction, LCLssvlssvDashboardReducerAction, getFilterDropDown } from '../../../../store/actions/createTicketManageFields'
import { mopDataApi, mopFilters, LSSVDashboardAPI, mergedlSSVApproverDashboardAPI } from '../../../../common/config'
import { MopDatabaseAction } from '../../../../store/actions/dashboardActions'
import DatePicker from "react-datepicker";
import moment from "moment"
const ApprovalSSVLSSVFilterList = ({ setFilterBadge, setfullUrL, setcommonModalState, featureExport }) => {
    // Launch SSS NNP. --> (Site ID, Launch Engineer, SSS & NNP Engineer, RTT Date, UUU Engineer, UUU Date, Launch Date, NNP Baseline Reference Date, SSS COP Submit Date,  SSS COP Approval Date)
    const dispatch = useDispatch()
    let compOptionArry = []
    let sowListArry = []
    let typeOfWorkArry = []
    let vendorsArry = []
    let psowArry = []
    let siteID = []
    let assignedEngineer = []
    let dayNightMop = []
    let sendData = useRef("")
    let filterOptions = useRef([])
    let launchEngineer = []

    let fullURL = "fc=True"
    let selectedFilter = []

    const [fullFilter, setfullFilter] = useState({})
    let handleChange = (dropDown, e) => {
        // if(e.target.value!=""){
        // if(filterOptions.current.indexOf(e.target.value)==-1){
        //     filterOptions.current.push(e.target.value)
        // }

        // fullFilter[dropDown] = e.target.value
        if (dropDown === "LRttDate" || dropDown === "LUUUDate" || dropDown === "ssvSubmitDate" || dropDown === "SSSCOPSubmitDate" || dropDown === "LSSVLaunchDate" || dropDown === "SSSCOPSubmitDate" || dropDown === "ssvRttDate") {
            fullFilter[dropDown] = moment(e.target.value).format("MM-DD-YYYY")
        }
        else {
            fullFilter[dropDown] = e.target.value
        }
        // sendData.current=sendData.current +dropDown+"="+e.target.value+"&"
        console.warn("cknbdbkjreef=", sendData)
        // }


        setfullUrL(fullURL)
        featureExport(fullURL)

        console.log(fullFilter, fullURL, "fullFilterfullFilter")
        // fullFilter
        // fullFilter.map((oneFilter)=>{
        //   console.log(oneFilter,"oneFilteroneFilter")  
        // })




    }



    const submitData = () => {

        let selData = []

        Object.keys(fullFilter).map((oneFilter, index) => {

            const value = fullFilter[oneFilter];
            selData.push(value)
            console.log(oneFilter, "oneFilteroneFilter")
            // if(index!=0){
            //     fullURL="?"+oneFilter+"="+value
            // }else{
            fullURL = fullURL + "&" + oneFilter + "=" + encodeURIComponent(value)
            // }

        })
        selectedFilter = selData.map((item) => {
            return <p className='badge badge-info'>{item}</p>
        })
        setFilterBadge(selectedFilter)

        let Filleddata = dispatch(LCLssvlssvDashboardReducerAction({ filterUrl: `${"fc=True&" + fullURL}` }))

        dispatch(AccountReducerAction(mergedlSSVApproverDashboardAPI + Filleddata, true));
        setcommonModalState(false)
    }


    const count = useSelector((state) => {
        console.log("the state is =", state);
        let filterObj = state.OneCommonResponse.filterDropDown
        console.warn("jxhbkjvbjacvkecfrwgvw=", filterObj)
        typeOfWorkArry.push(<option value={"Pending"}>Pending</option>)
        typeOfWorkArry.push(<option value={"Approved"}>Approved</option>)
        typeOfWorkArry.push(<option value={"Rejected"}>Rejected</option>)
        typeOfWorkArry.unshift(<option disabled selected value={""}>Select</option>)
   
        let dropDown = {
            "SSVCOPStatus":{"label":"SSV Status","values":typeOfWorkArry},
            "LSSVCOPStatus":{"label":"SSS & NNP Status","values":typeOfWorkArry},
        }
        // a) SSV Dashboard. --> (Site ID, RTT Date, UUU Date, Drive Date, SSV Submit Date, SSV Approval Date, RE submitted date)
  
        let dropdownSkel = Object.keys(dropDown).map((item, index) => {

            // console.warn(",mdscbvbcvwchkuvcwsvr=",dropDown.item)
            console.warn(",mdscbvbcvwchkuvcwsvr=", dropDown[item]["values"])

            if (dropDown[item]['dType'] == "S") {
                return (
                    <div className='form-group col-12'>
                        <label>{dropDown[item]["label"]}</label>
                        <input className='form-control' onChange={(e) => handleChange(item, e)} list={index}
                            style={{ height: "32px" }} />
                        <datalist id={index}>

                            {dropDown[item]["values"]}
                        </datalist>

                    </div>
                )
            }
            else if (dropDown[item]['dType'] == "M") {

            }
            else if (dropDown[item]['dType'] == "datePicker") {
                return (
                    <div className='form-group col-12'>
                        <label>{dropDown[item]["label"]}</label>
                        <input className='form-control' type="date" onChange={(e) => handleChange(item, e)} style={{ height: "32px" }} />
                    </div>
                )
            }


            else {
                return (
                    <div className='form-group col-12'>
                        <label>{dropDown[item]["label"]}</label>
                        <select className='form-control'

                            onChange={(e) => handleChange(item, e)}
                        >
                            {
                                dropDown[item]["values"]
                            }
                        </select>
                    </div>
                )
            }
        })

        return ({ filterObj, dropDown, dropdownSkel })
    })


    useEffect(() => {

        // dispatch(getFilterDropDown([]))
        // dispatch(AccountReducerAction(ranfilters))

    }, [])
    return (
        <>
            <div className='row'>
                {count.dropdownSkel}

                {console.log(count.dropdownSkel, "count.dropdownSkel")}

            </div>

            <button onClick={(e) => {
                submitData()
            }} className={'btn btn-xs btn-primary mx-2 py-2 btn btn-primary'}>SEARCH</button>
        </>
    )
}

export default ApprovalSSVLSSVFilterList