import React, { useEffect } from 'react'
import { Tab, Tabs } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { addAcccount, allTechnologyApi, getAllDropDown, getAllDropDownList, goNoGoAction, mopSiteStatus } from '../../../../common/config';
import { addAcccountReducerAction } from '../../../../store/actions/createTicketManageFields';
// import { addAcccountReducerAction, updateAcccountReducerAction } from '../../../components/vendor/'
const GoNoGoModal = (props) => {


  console.log(props,"propspropsprops")
  const { register, setValue, handleSubmit, getValues, watch, reset, formState: { errors } } = useForm({})
  const dispatch = useDispatch();

  console.log(props.isShow,props.isShow==true,props.isShow==false, "propsprops")

  if (props.type == "Edit") {
    // setValue("value",props.oldData.optionValue) 
  }
  const onSubmit = async (data) => {
    console.log("the data to submit is =", data)

    // data["dropDownId"]=props.parentuniqueId
    console.log(data, "data")
    data["siteGroup"]=props.siteGroup
    data["typerr"]=props.type
    

    // if(props.type=="Add"){
    dispatch(addAcccountReducerAction(goNoGoAction+"/"+props.unique_id_up, data))
    // }
    // if(props.type=="Edit"){
    //   dispatch(updateAcccountReducerAction(getAllDropDownList,props.oldData.uniqueId,data))
    // }




    // propsprops

    // reset({})
    setValue("value", '')
  }
  useEffect(() => {
    // reset({})
    setValue("value", '')
  }, [])

  console.log("remarks data" , props.isShow,props.isShow==true,props.isShow==false,)
  
  if(!props.isPag){
    return (
      <div style={{ color : "black" , fontSize : "15px"}}>
      {props.data}
      </div>
    )
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group col-md-12"  >

        {props.isShow?<>
        <label for="exampleFormControlSelect1">
          Go & No Go Status
        </label>
        <select style={{ height: "40px" }}
          type="text"
          className="form-control"
          id="input "
          
          // placeholder="Enter Technology Name"
          {
          ...register("goNo", {
            required: "Please select Go or No Go status"
          })
          }
        >
          <option selected disabled value="">Select</option>
          <option value={"Go"}>Go</option>
          <option value={"No Go"}>No Go</option>
        </select>
        {errors.goNoGOMopStatus && (
          <p className="error">{errors.goNoGOMopStatus.message}</p>
        )}</>
        :""}
      </div>
      <div className='form-group col-md-12' >
        {props.typename!=""?
        <label for="exampleFormControlSelect1">
          {props.typename}
        </label>:""}
        <textarea style={{ height: "40px" }} className="form-control" defaultValue={props.data || ""}
          {
          ...register("goNoGoRemark", {
            required: "Please enter remarks"
          })
          }
        ></textarea>
        {errors.goNoGoRemark && (
          <p className="error">{errors.goNoGoRemark.message}</p>
        )}
      </div>
      <button type="submit" className="btn btn-primary btn-xs mt-3">
        Submit
      </button>
    </form>
  )
}

export default GoNoGoModal