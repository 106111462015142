import React, { useState } from 'react'
import MUIDataTable from "mui-datatables";
import { useEffect } from 'react';
import { Row, Card, Col,Dropdown, Button, Modal, Container } from "react-bootstrap";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AccountReducerAction,OneUserReducerAction, addUserAction,deleteAcccountReducerAction, updateAcccountReducerAction, RoleReducerAction,UserListReducerAction } from '../../../../store/actions/createTicketManageFields';
import { useDispatch,useSelector } from 'react-redux';
import { Link } from "react-router-dom";

import { useForm } from "react-hook-form";
import { black } from 'material-ui/styles/colors';
import { deleteOssApi, ossAddApi, ossApi } from '../../../../common/config';
import { CSVUploadAction, PDFUploadAction } from '../../../../store/actions/dashboardActions';
import { google_map_api, center, dbdatetodate, customDataTableOptionObject } from "../../../../common/variables";

const ManageOss = () => {
  const [siteFile, uploadedFile] = useState();
  let pStatusArry=[]
  let ossIdsData =[]
  let ossType =[]
  // let editTrue=0
  const options = {
    filterType: 'none',
    selectableRows: false,
    responsive: true
  };

  const {register,setValue,handleSubmit,reset,formState:{errors}} =useForm({});
  const [show, setShow] = useState(false);



  const handleClose = () => {
    reset({})
    setShow(false);
  }
  const handleShow = () => 
  {
    reset({})
    setShow(true)
  }

  
    
  const [modalCentered, setModalCentered] = useState(false);
  const [editTrue,seteditTrue]=useState(0)
  const [updateTrue,setupdateTrue]=useState(0)
  const [formtype,addformtype]=useState('')
  const [editOject,setEditObject] =useState('')
    const getMuiTheme = () => createTheme({
      components: {
        MUIDataTableBodyCell: {
          styleOverrides:{
            root: {
                backgroundColor: "transparent",
                color:black
            }
          }
        }
      }
    })
    

    console.log()
    const updateHandler =(e)=>{
        console.log("button update clicked",e)

        // dispatch(addUserAction(addAcccount,e))

    }
    const submitHandler = async (e) => {
      e.preventDefault();
      // console.log("remarks =",remarks)
      console.log("file=",siteFile)
      console.log(columns)
      const form = new FormData();
      form.append("uploadFile", siteFile);
      form.append("uploadfor","GSM_UTMS")
      // form.append("remarks",remarks);
      // setRemarks("")
      // uploadedFile()
      dispatch(CSVUploadAction(form))
      // setCountRender(1)
      console.log("form data =",form)
      // await csvhandleChange(options)
    };

    const uploadCsvFile = (e) => {
      console.log(e.target.files[0])
      uploadedFile(e.target.files[0]);
    };

    const deleteHandler =(e)=>{
      console.log("button delete clicked",e)
      dispatch(deleteAcccountReducerAction(deleteOssApi,e))
    }
    

    const editpopupHandler =(e)=>{
        
      reset({})
      console.log("button editpopupHandler clicked",e.OssId)
      seteditTrue(1)
      setEditObject(e)
      setValue("OssId",e.OssId)
      setValue("ossType",e.ossTypeId.$oid)
    }

    

    const onSubmit= async (data)=>{
      console.log(data,"dassdaas")
      

      if(editTrue==1){
        console.log("edit button clicked")
        dispatch(updateAcccountReducerAction(ossAddApi,editOject.uniqueid,data))
      }
      else{
        dispatch(addUserAction(ossAddApi,data))
        handleClose()
      }

    }
  
    const dispatch =useDispatch();

    let data =useSelector((state)=>{
        // console.log("dfwef=",state)
        // let onelistuser=state.OneCommonResponse.listuser

        // console.log(onelistuser.length,editTrue,"onelistuser")
        // if(onelistuser.length>0 && editTrue==1){
        //   oneaccount=onelistuser[0]
        //   console.log(oneaccount,"oneaccount")
        //   seteditTrue(0)
        //   seteditdataHandler(oneaccount)
        // }
        
        console.log("the state is =",state.CommonResponse.oss)
        let ossIds =state.CommonResponse.oss.ossData
        if(ossIds && ossIds.length>0 && ossIds[0]!=""){
            ossIdsData =ossIds.map((item,index)=>{
                return {
                    sNo:index+1,
                    ossId:item.OssId,
                    ossType:item.ossType,
                    action:<div className="d-flex">
                    <Link
                      href="#"
                      onClick={() =>{ handleShow();editpopupHandler(item);addformtype("Edit");}}
                      className="btn btn-primary shadow btn-xs sharp mr-1"
                    >
                      <i className="fa fa-pencil"></i>
                    </Link>
                    <Link
                      href="#"
                      onClick={(e)=>deleteHandler(item.uniqueid)}
                      className="btn btn-danger shadow btn-xs sharp"
                    >
                      <i className="fa fa-trash"></i>
                    </Link>
                  </div>
                }
            })

            console.log("ossIdsData",ossIdsData)
        }
        let ossRespType =state.CommonResponse.oss.ossType
        console.log("OSs -=",ossRespType)
        if(ossRespType && ossRespType.length >0){
          ossType=ossRespType.map((item)=>{
            return <option value={item.uniqueid}>{item.name}</option>
          })
        }

      return {ossType,ossIdsData}
      })
    useEffect(()=>{
        dispatch(AccountReducerAction(ossApi))
    },[])
    const columns = [
        {
      
         name: "sNo",
         label: "S.no",
         options: {
          filter: false,
          sort: false,
          searchable:false,
          setCellHeaderProps: () => ({
            style: {
             
                alignItem:"center",
              background:"#143b64",
              color:"white"
  
            }
          })
         }
        },
        {
         name: "ossId",
         label: "Oss Id",
         options: {
          filter: true,
          setCellHeaderProps: () => ({
            style: {
             
                alignItem:"center",
              background:"#143b64",
              color:"white"
  
            }
          })
         }
        },
        {
            name: "ossType",
            label: "Oss Type",
            options: {
             filter: true,
             setCellHeaderProps: () => ({
              style: {
               
                  alignItem:"center",
                background:"#143b64",
                color:"white"
    
              }
            })
            }
           },
        {
         name: "action",
         label: "Action",
         options: {
          filter: false,
          sort: false,
          print:false,
          searchable:false,
          setCellHeaderProps: () => ({
            style: {
             
                alignItem:"center",
              background:"#143b64",
              color:"white"
  
            }
          })
         }
        } 
       ];

       let row=[];
       
       const handleChange = (e) => {
        console.log("the current anser= ", e.target.value)
      }
    
      const AddCustomButton = () => (
        <fregment style={{ float: "right", display: "flex", flexDirection: row, justifyContent: "space-between" }}>
           <Button className="btn-xs mt-3 mr-2 mb-3 float-right"  onClick={() => {handleShow();seteditTrue(0);reset({});addformtype("Add")}}>Add Oss</Button>
          <Modal show={show} onHide={handleClose} >
        <Modal.Header closeButton>
          <Modal.Title style={{color:"white"}}>{formtype} User</Modal.Title>
        </Modal.Header>
        <Modal.Body >

        <div className="card " style={{ width: "100%" }}>
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div className="accordion-item">
                <form onSubmit={handleSubmit(onSubmit)}>
                
                  <div>
                    <div className="accordion-body">
                      <div className="form-row mt-2 p-2">
                      <div className="form-group col-md-12">
                          <label for="exampleFormControlSelect1">
                           Oss Type<span style={{color:"red"}}>*</span>
                          </label>
                          <select
                            style={{height:"40px"}}
                            className="form-control"
                            id="exampleFormControlSelect1"
                            {...register("ossType",{
                                required:"Please select Oss",
                            })}>


                            <option selected disabled value ="">select</option>
                            {data.ossType}
                            
                          </select>
                          {errors.ossType && <p className="error">{errors.ossType.message}</p>}
                        </div>
                        <div className="form-group col-md-12">
                          <label for="exampleFormControlSelect1">
                          Oss Id
                          </label>
                          <input style={{height:"40px"}}
                            type="text"
                            className="form-control"
                            id="input "
                            placeholder="Enter Oss"
                            {
                              ...register("OssId",{
                                required:"Please enter oss id",
                                minLength:{
                                    value:5,
                                    message:"Please enter atlest 5 Characters"
                                },
                                maxLength:{
                                    value:10,
                                    message:"Only 10 Characters are allowed"
                                }
                              })
                            }
                          />
                          {errors.OssId && (
                            <p className="error">{errors.OssId.message}</p>
                          )}
                        </div>
                      
                        

                      </div>
                    </div>
                  </div>
                  <button type="submit" className="btn btn-primary btn-xs">
                    Submit
                  </button>
                  </form>
                </div>
                
              </div>
            </div>
          
        </Modal.Body>
        <Modal.Footer>
         
        </Modal.Footer>
      </Modal>
      <div>
              <label class="custom-file-upload btn btn-outline-primary btn-xs mt-3 mr-2 mb-3 col-md-12">
               <input
                type="file"
                className="form-control btn btn-primary mt-3 mr-2 mb-3"
                id="inputGroupFile04"
                aria-describedby="inputGroupFileAddon04"
                aria-label="Upload"
                name="file"
            onChange={uploadCsvFile}
          /> 
                <i class="fa fa-cloud-upload"></i> Choose File
              </label>
              {/* <label>Remarks</label>
              <input type='text' className="form-control" value={remarks} onChange={(e)=>{setRemarks(e.target.value)}}></input> */}

            </div>
        
          <button
            className="btn btn-outline-primary btn-xs mt-3 mr-2 mb-3 col-xm-12"
            onClick={submitHandler}
            id="inputGroupFileAddon04"
          >
            Submit
          </button>
        
        </fregment>
      )
  return (
    <>
       
         


     
            

	<ThemeProvider theme={getMuiTheme()}>

         <MUIDataTable
         title={"List Oss"}
            data={data.ossIdsData}
            columns={columns}
            options={customDataTableOptionObject(AddCustomButton)}
            
        /> 
      </ThemeProvider>
    </>
  )
}

export default ManageOss