import React, { useEffect, useRef, useState } from 'react'
import DatePicker from "react-datepicker";
import { useForm } from 'react-hook-form';
import { FetchSingleRecordAction, addUserAction } from '../../../../store/actions/createTicketManageFields';
import { LSSVDashboardAPI } from '../../../../common/config';
import { useDispatch, useSelector } from 'react-redux';
const moment = require('moment');
const NNPBaseline = (props) => {
  let dispatch = useDispatch()
  let [NNPBaselineReferenceDate, setNNPBaselineReferenceDate] = useState()
  const styles = {
    width: "400px",
  };
  let count = useRef(1)
  const { register, getValue, setValue, handleSubmit, setError, formState: { errors } } = useForm()
  const option = useSelector((state) => {
    let fetchData = state.OneCommonResponse.singleIXSite
    if (fetchData && Object.keys(fetchData).length > 0) {
      console.warn("jkxdbwfkce.ielgrbed==", fetchData)

      if (count.current == 1) {
        if (fetchData.NNPBaselineReferenceDate) {
          setNNPBaselineReferenceDate(new Date(fetchData.NNPBaselineReferenceDate))
        }
        setValue("NNP4GStatus", fetchData.NNP4GStatus,{shouldTouch:true,shouldValidate:true})
        setValue("OverallNNP5GStatus", fetchData.OverallNNP5GStatus,{shouldTouch:true,shouldValidate:true})
        setValue("CounttoMarketAvg", fetchData.CounttoMarketAvg,{shouldTouch:true,shouldValidate:true})
        // setValue("NNPBaselineReferenceDate",fetchData.NNPBaselineReferenceDate)
        count.current = 2
      }
    }

  })
  const onSubmit = (data) => {
    data["NNPBaselineReferenceDate"] = moment(NNPBaselineReferenceDate).format("MM/DD/YYYY")

    console.log("the data is knbjkdhjebvevker=", data)
    dispatch(addUserAction(LSSVDashboardAPI + "/" + props.id, data))
  }


  useEffect(() => {
    dispatch(FetchSingleRecordAction(LSSVDashboardAPI, props.id))
  }, [])
  return (
    <form className='row' onSubmit={handleSubmit(onSubmit)}>
      <div className='form-group  col-6'>
        <label>NNP Baseline Reference Date</label>
        <DatePicker
          style={styles}
          className="form-control"
          selected={NNPBaselineReferenceDate}
          onChange={(e) => {
            setNNPBaselineReferenceDate(e);
          }}
          popperPlacement="bottom"
          dateFormat="MM/dd/yyyy"
        />
      </div>
      <div className='form-group  col-6'>
        <label>NNP 4G Status</label>

        <select className='form-control' {...register("NNP4GStatus")} >
          <option value="Pass">Pass</option>
          <option value="Fail">Fail</option>
          <option value="Warning">Warning</option>
          <option value="NA">NA</option>
        </select>
        {/* <input type="text" className="form-control" {...register("NNP4GStatus")} /> */}
      </div>
      <div className='form-group  col-6'>
        <label>Overall NNP 5G Status</label>
        <select className='form-control' {...register("OverallNNP5GStatus")} >
          <option value="Pass">Pass</option>
          <option value="Fail">Fail</option>
          <option value="Warning">Warning</option>
          <option value="NA">NA</option>
        </select>
        {/* <input type="text" className="form-control" {...register("OverallNNP5GStatus")} /> */}
      </div>
      <div className='form-group  col-6'>
        <label>Count to Market Avg</label>
        <select className='form-control' {...register("CounttoMarketAvg")} >
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
        {/* <input type="text" className="form-control" {...register("CounttoMarketAvg")} /> */}
      </div>
      <div className='col-12 d-flex justify-content-center'>
        <button className="btn btn-primary btn-xs py-2 px-4 mt-2" type='submit'>
          Submit
        </button>
      </div>
    </form>
  )
}

export default NNPBaseline