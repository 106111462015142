import React, { useState } from 'react'
import MUIDataTable from "mui-datatables";
import { useEffect } from 'react';
import { Row, Card, Col, Dropdown, Button, Modal, Container } from "react-bootstrap";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { accountApi, addUser, deleteUser, listRole_twoApi, getMarket, crewCompanyApi, listUserApi_two, vendorApi, addAcccount, listOneUser, updateUser, CsvUpload, tierapi, managEngineer } from '../../../../common/config';
import { AccountReducerAction, OneUserReducerAction, addUserAction, deleteAcccountReducerAction, GetMarketReducerAction, getCrewCompanyAction, updateAcccountReducerAction, RoleReducerAction, UserListReducerAction, TierListReducerAction, OneTierReducerAction, popUpUpdate } from '../../../../store/actions/createTicketManageFields';
import { useDispatch, useSelector } from 'react-redux';
import { MultiSelect } from "react-multi-select-component";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { black } from 'material-ui/styles/colors';
import { PDFUploadActionreturn } from '../../../../store/actions/dashboardActions';
import { google_map_api, center, dbdatetodate, customDataTableOptionObject } from "../../../../common/variables";

const ManageSite = () => {

  let pStatusArry = []
  let vStatusArry = []
  let mStatusArry = []
  let cStatusArry = []

  // let editTrue=0
  const options = {
    download: "false",
    filterType: 'none',
    selectableRows: false,
    responsive: true
  };

  let accountArry = []
  let tierArry = []

  const { register, setValue, handleSubmit, reset, formState: { errors } } = useForm({});
  const [show, setShow] = useState(false);
  const [closer, setCloser] = useState(false);
  const [siteFile, uploadedFile] = useState([]);
  const [fileshow, setfileShow] = useState(false);
  let [sImpact, setSectorImpact] = useState([])
  let [marketts, setMarkets] = useState([])



  const submitHandler = async (e) => {
    e.preventDefault();
    console.log("form==", "form")
    let newform = new FormData();
    newform.append("uploadFile", siteFile);
    newform.append("uploadfor", "Crew")
    console.log("form==", newform)
    console.log("resp", dispatch(PDFUploadActionreturn(CsvUpload, newform, "usercrew")))
    setShow(false);
  };


  const uploadCsvFile = (e) => {
    // let files=[]
    console.log(e.target.files, "files")
    uploadedFile(e.target.files[0])

  };

  const handleClose = () => {
    reset({})
    setShow(false);
  }
  const handleShow = () => {
    reset({})
    setShow(true)
  }

  const filehandleClose = () => {
    reset({})
    setfileShow(false);
  }
  const filehandleShow = () => {
    reset({})
    setfileShow(true)
  }

  const handleSectorImpect = (e) => {
    setSectorImpact(e)

    console.log(sImpact, "sImpact")
  }

  const handleMarket = (e) => {
    setMarkets(e)

    console.log(marketts, "marketts")
  }

  let sectorsImpacted = [
    {
      value: "RAN",
      label: "RAN"
    },
    {
      value: "TROUBLE TICKET",
      label: "TROUBLE TICKET"
    },
    {
      value: "E911 CALL TEST",
      label: "E911 CALL TEST"
    },
    {
      value: "48Hrs ALARM",
      label: "48Hrs ALARM"
    },
    {
      value: "POST IX RF SUPPORT",
      label: "POST IX RF SUPPORT"
    },
    {
      value: "CIQ SCRIPTING",
      label: "CIQ SCRIPTING"
    },
    {
      value: "PROJECT DATABASE",
      label: "PROJECT DATABASE"
    },
    {
      value: "T MOBILE DASHBOARD",
      label: "T MOBILE DASHBOARD"
    },
    {
      value: "DASHBOARD",
      label: "DASHBOARD"
    },
    {
      value: "TRAINING",
      label: "TRAINING"
    },
    {
      value: "ADMIN",
      label: "ADMIN"
    },
  ]
  // const uploadCsvFile = (e) => {
  //   let files=[]

  //   console.log(e.target.files[0],"ON_CHANGE_FILES:", e.target.files.length);

  //   for(let i=0;i<e.target.files.length;i++){
  //     files.push(e.target.files[i])
  //   }
  //   console.log(...files)
  //   uploadedFile(files)

  // };



  const [modalCentered, setModalCentered] = useState(false);
  const [newAccount, setAccount] = useState({})
  const [editTrue, seteditTrue] = useState(0)
  const [ven, setVen] = useState('')

  const [updateTrue, setupdateTrue] = useState(0)
  const [formtype, addformtype] = useState('')





  let oneaccount = {}


  // setAccount(oneaccount)
  //  console.log(oneaccount,"oneaccount")
  const getMuiTheme = () => createTheme({
    components: {
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            backgroundColor: "transparent",
            color: black
          }
        }
      }
    }
  })


  console.log()
  const updateHandler = (e) => {
    console.log("button update clicked", e)

    dispatch(addUserAction(addAcccount, e))

  }
  const deleteHandler = (e) => {
    console.log("button delete clicked", e)

    dispatch(deleteAcccountReducerAction(managEngineer, e, "tier"))
    setCloser(true)
  }


  const editpopupHandler = (e) => {
    seteditTrue(1)
    reset({})
    console.log("button editpopupHandler clicked", e)
    dispatch(OneTierReducerAction(managEngineer + "/" + e.uniqueid))
  }

  const seteditdataHandler = (e) => {
    reset({})
    setupdateTrue(1)
    setValue("email", e.email, { shouldTouch: true });
    setValue("password", e.password, { shouldTouch: true });
    setValue("mobilenumber", e.mobilenumber, { shouldTouch: true });
    setValue("name", e.name, { shouldTouch: true });
    setValue("companyName", e.companyName, { shouldTouch: true });

    // console.log(e.$oid,"e.$oid")
    setValue("userrole", e.userroleuniqueid, { shouldTouch: true });
    setValue("accountId", e.accountuniqueid, { shouldTouch: true });
    setValue("vendor", e.vendorId, { shouldTouch: true });
    // setVen(e.vendorId);
    console.log("vener", e.vendorId, "vener")
    setValue("name", e.name, { shouldTouch: true });
    setValue("uniqueid", e.uniqueid, { shouldTouch: true });
    let arr = []
    arr = e.department.values.map((item) => {
      return {
        value: item,
        label: item,
      };
    })

    setSectorImpact(arr)

    let arrm = []
    arrm = e.markets.values.map((item) => {
      return {
        value: item,
        label: item,
      };
    })

    setMarkets(arrm)
  }



  const onSubmit = async (data) => {

    let arrmarketts = { values: [] }
    let tiier = { values: [] }
    tiier.values = sImpact.map((item) => {
      return item.value
    })
    data['department'] = tiier
    arrmarketts.values = marketts.map((item) => {
      return item.value
    })
    data['markets'] = arrmarketts
    console.log(data, "dassdaas")

    if (data.uniqueid != undefined) {
      dispatch(updateAcccountReducerAction(managEngineer, data.uniqueid, data, "department"));
      console.log("updateAcccountReducerAction")
      setCloser(true)
    }
    else {
      dispatch(addUserAction(managEngineer, data, "department"))
      setCloser(true)
    }

  }

  const dispatch = useDispatch();


  // let datava =useSelector((state)=>{

  // });

  let data = useSelector((state) => {
    console.log("dfwef=", state.popUpState)
    let onelistuser = state.OneCommonResponse.listuser

    // console.log(onelistuser.length, editTrue, "onelistuser")
    if (onelistuser.length > 0 && editTrue == 1) {
      oneaccount = onelistuser[0]
      console.log(oneaccount, "oneaccount")
      seteditTrue(0)
      seteditdataHandler(oneaccount)
    }

    // console.log(state.popUpState,"popUpState")

    if (state.popUpState) {
      // console.log(state.popUpState.popstatus.status==201,"popUpStatestatus")
      if (state.popUpState.popstatus.status == 201 && closer) {
        setCloser(false)
        handleClose()
        dispatch(TierListReducerAction(managEngineer))
        popUpUpdate({})
      }
    }


    let tier = state.CommonResponse.listtier

    let account = state.CommonResponse.accounts
    let index = 0
    if (tier && tier.length != 0) {
      tierArry = tier.map((item) => {

        console.log(item)
        index = index + 1
        return {

          sNo: index,
          name: item.name,
          companyName: item.companyName,
          email: item.email,
          mobilenumber: item.mobilenumber,
          markets: item.markets?item.markets.values?item.markets.values.join(", "):"":'',
          department: item.department.values.join(", "),
          action: <div className="d-flex">
            <Link
              href="#"
              onClick={() => { editpopupHandler(item); reset({}); addformtype("Edit"); handleShow(); }}
              className="btn btn-primary shadow btn-xs sharp mr-1"
            >
              <i className="fa fa-pencil"></i>
            </Link>
            <Link
              href="#"
              onClick={(e) => deleteHandler(item.uniqueid)}
              className="btn btn-danger shadow btn-xs sharp"
            >
              <i className="fa fa-trash"></i>
            </Link>
          </div>
          // <button value={item.uniqueid} onClick={(e)=>updateHandler(e)}>Click me</button>

        }
      })
    }


    if (account != 0) {
      accountArry = account.map((item) => {
        return <option value={item.uniqueid}>{item.accountName}</option>
      })
    }


    let userroledata = state.CommonResponse.userrole
    console.log(state, "state.CommonResponse")
    console.log(userroledata, "projectStatus")
    if (userroledata != 0) {
      pStatusArry = userroledata.map((item) => {
        return <option value={item.uniqueid}>{item.rolename}</option>
      })

    }


    let markarr = []
    let marketroledata = state.CommonResponse.markets
    if (marketroledata != 0) {
      mStatusArry = marketroledata.map((item) => {
        return <option value={item.uniqueid}>{item.marketName}</option>
      })

    }
    let crewroledata = state.CommonResponse.crewCompany
    if (crewroledata != 0) {
      cStatusArry = crewroledata.map((item) => {
        return <option value={item.uniqueid}>{item.crewCompanyName}</option>
      })

    }

    let vendorroledata = state.CommonResponse.vendor
    if (vendorroledata != 0) {
      vStatusArry = vendorroledata.map((item) => {
        console.log(ven, item.uniqueid, "ven==item.uniqueid")
        return <option value={item.uniqueid}>{item.vendorName}</option>
        // if(ven==item.uniqueid){
        //   return <option selected value={item.uniqueid}>{item.vendorName}</option>
        // }else{
        //   return <option value={item.uniqueid}>{item.vendorName}</option>
        // }
      })

    }

    console.log(pStatusArry, "pStatusArry")

    return { tierArry, accountArry, pStatusArry, mStatusArry, oneaccount, cStatusArry, vStatusArry }
  })

  console.log(data, "data")
  const noOfRow = data.accountArry.length
  // data.accountArry.push(
  //   {   
  //       sNo:noOfRow+1,
  //       account:<input type='text' placeholder='Add Account' value={newAccount} onChange={(e)=>setAccount(e.target.value)}/>,
  //       edit : <button onClick={()=>addAccount()}>Add Account</button>

  //   }
  // )




  useEffect(() => {
    dispatch(RoleReducerAction(listRole_twoApi))
    dispatch(AccountReducerAction(accountApi))
    dispatch(TierListReducerAction(managEngineer))

    dispatch(UserListReducerAction(listUserApi_two))
    dispatch(GetMarketReducerAction(getMarket))
    dispatch(AccountReducerAction(crewCompanyApi))
    dispatch(AccountReducerAction(vendorApi))

  }, [newAccount])
  const columns = [
    {

      name: "sNo",
      label: "S.no",
      options: {
        filter: false,
        sort: false,
        searchable:false,
        setCellHeaderProps: () => ({
          style: {
           
              alignItem:"center",
            background:"#143b64",
            color:"white"

          }
        })
      }
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
           
              alignItem:"center",
            background:"#143b64",
            color:"white"

          }
        })
      }
    },
    {
      name: "companyName",
      label: "Company Name",
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
           
              alignItem:"center",
            background:"#143b64",
            color:"white"

          }
        })
      }
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
           
              alignItem:"center",
            background:"#143b64",
            color:"white"

          }
        })
      }
    },
    {
      name: "mobilenumber",
      label: "Mobile Number",
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
           
              alignItem:"center",
            background:"#143b64",
            color:"white"

          }
        })
      }
    },
    {
      name: "markets",
      label: "Markets",
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
           
              alignItem:"center",
            background:"#143b64",
            color:"white"

          }
        })
      }
    },
    {
      name: "department",
      label: "Departments",
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
           
              alignItem:"center",
            background:"#143b64",
            color:"white"

          }
        })
      }
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        print:false,
        searchable:false,
        setCellHeaderProps: () => ({
          style: {
           
              alignItem:"center",
            background:"#143b64",
            color:"white"

          }
        })
      }
    }

  ];

  let row=[];
       
  const handleChange = (e) => {
   console.log("the current anser= ", e.target.value)
 }

 const AddCustomButton = () => (
   <fregment style={{ float: "right", display: "flex", flexDirection: row, justifyContent: "space-between" }}>
   <div>
          <Button className="btn-xs mt-3 mr-2 mb-2 float-right" onClick={() => { handleShow(); reset({}); addformtype("Add") }}>Add Site</Button>
          {/* <Button className="btn-xs mt-3 mr-2 mb-2 float-right" onClick={() => { filehandleShow(); reset({}); addformtype("Upload ") }}>Upload File</Button> */}
        </div>
      
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title >{formtype} Manage Site</Modal.Title>
        </Modal.Header>
        <Modal.Body >

          <div className="card " style={{ width: "100%" }}>
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <form onSubmit={handleSubmit(onSubmit)}>

                  <div>
                    <div className="accordion-body">
                      <div className="form-row mt-2 p-2">
                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                            Site ID (LTE ID)
                          </label>
                          <input style={{ height: "40px" }}
                            type="text"
                            className="form-control"
                            id="input "
                            placeholder="Enter Name"

                            {
                            ...register("siteId")
                            }
                          />
                        </div>

                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                            Company Name
                          </label>
                          <input style={{ height: "40px" }}
                            type="text"
                            className="form-control"
                            id="input "
                            placeholder="Enter Name"

                            {
                            ...register("companyName")
                            }
                          />
                        </div>

                        {/* <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                            {" "}
                            Vendor <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            style={{ height: "40px" }}
                            className="form-control"
                            id="exampleFormControlSelect1"
                            {...register("vendor")}>

                            {data.vStatusArry}

                          </select>
                          {errors.siteId && <p className="error">{errors.siteId.message}</p>}
                        </div> */}
                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                            Mobile Number
                          </label>
                          <input style={{ height: "40px" }}
                            type="text"
                            className="form-control"
                            id="input "
                            placeholder="Enter Mobile Number"

                            {
                            ...register("mobilenumber")
                            }
                          />
                        </div>


                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                            Email
                          </label>
                          <input style={{ height: "40px" }}
                            type="text"
                            className="form-control"
                            id="input "
                            placeholder="Enter Email"

                            {
                            ...register("email")
                            }
                          />
                        </div>

                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                            Password
                          </label>
                          <input style={{ height: "40px" }}
                            type="text"
                            className="form-control"
                            id="input "
                            placeholder="Enter Name"

                            {
                            ...register("password")
                            }
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                            {" "}
                            Department <span style={{ color: "red" }}>*</span>
                          </label>
                          {/* <select
                            style={{ height: "40px" }}
                            className="form-control"
                            id="exampleFormControlSelect1"
                            {...register("userrole")}>


                            <option disabled selected>Select Tier</option>
                            {data.mStatusArry}

                          </select> */}




                          <MultiSelect
                            className="light"
                            options={sectorsImpacted}
                            onChange={handleSectorImpect}
                            value={sImpact}
                          />
                          {errors.siteId && <p className="error">{errors.siteId.message}</p>}
                        </div>

                        {/* <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                            {" "}
                            Market <span style={{ color: "red" }}>*</span>
                          </label>
                          <MultiSelect
                            className="light"
                            options={mStatusArry}
                            onChange={handleMarket}
                            value={marketts}
                          />


                          {errors.siteId && <p className="error">{errors.siteId.message}</p>}
                        </div> */}



                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                            {" "}
                            Select Account <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            style={{ height: "40px" }}
                            className="form-control"
                            id="exampleFormControlSelect1"
                            {
                            ...register("accountId")
                            }
                          >
                            <option disabled selected>Select Account</option>
                            {data.accountArry}
                          </select>
                          {errors.siteId && <p className="error">{errors.siteId.message}</p>}
                        </div>

                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                            {" "}
                            Market <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            style={{ height: "40px" }}
                            className="form-control"
                            id="exampleFormControlSelect1"
                            {...register("marketId")}>


                            <option disabled selected>Select Market</option>
                            {data.mStatusArry}

                          </select>
                          {errors.siteId && <p className="error">{errors.siteId.message}</p>}
                        </div>

                        {/* <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                            {" "}
                            Market <span style={{ color: "red" }}>*</span>
                          </label>
                          <MultiSelect
                            className="light"
                            options={mStatusArry}
                            onChange={handleMarket}
                            value={marketts}
                          />


                          {errors.siteId && <p className="error">{errors.siteId.message}</p>}
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <button type='submit' className='btn btn-primary col-xs' > Submit</button>
                </form>
              </div>

            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>




      <Modal show={fileshow} onHide={filehandleClose} size="sm">
        <Modal.Header closeButton>
          <Modal.Title >{formtype} Crew</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body> */}
        <form onSubmit={(e) => submitHandler(e)} encType="multipart/form-data" className="mx-2">
          <div className="input-group mb-3 fileSection">

            <div>
              <label class="custom-file-upload btn btn-outline-primary col-md-12">
                <input
                  type="file"
                  className="form-control btn btn-primary"
                  id="inputGroupFile04"
                  aria-describedby="inputGroupFileAddon04"
                  aria-label="Upload"
                  name="file"
                  multiple
                  onChange={uploadCsvFile}
                />
                <i class="fa fa-cloud-upload"></i> Choose File
              </label>
              {/* <label>Remarks</label>
                  <input type='text' className="form-control" value={remarks} onChange={(e)=>{setRemarks(e.target.value)}}></input> */}

            </div>

            <button
              className=" btn-primary"
              type="submit"
              id="inputGroupFileAddon04"
            >
              Submit
            </button>
          </div>

        </form>
        {/* <div className="form-row mt-2 p-2">
            <div>
              Crew File :
              <label class="custom-file-upload btn btn-outline-primary btn-xs col-md-12">
                <input style={{ height: "32px" }}
                  type="file"
                  className="form-control btn btn-primary"
                  id="inputGroupFile04"
                  aria-describedby="inputGroupFileAddon04"
                  aria-label="Upload"
                  name="file"
                  onChange={uploadCsvFile}

                />
                <i class="fa fa-cloud-upload"></i> Choose Crew File
              </label>
            </div>
          </div> */}


        {/* </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary btn-xs" onClick={filehandleClose}>
            Close
          </Button>
          <Button variant="primary btn-xs" onClick={submitHandler}>
            Upload File
          </Button>
        </Modal.Footer> */}
      </Modal>


   </fregment>
 )
  

  //    const data = [
  //     { name: "Joe James", company: "In Progress", city: "Yonkers", state: "NY" },
  //     { name: "John Walsh", company: "Completed", city: "Hartford", state: "CT" },
  //     { name: "Bob Herm", company: "Awaited", city: "Tampa", state: "FL" },
  //     { name: "James Houston", company: "NA", city: "Dallas", state: "TX" },
  //    ];





  return (
    <>
 
        


      {/* <ThemeProvider theme={getMuiTheme()}> */}

      <MUIDataTable
      title={"Manage Site"}
        data={data.tierArry}
        columns={columns}
        options={customDataTableOptionObject(AddCustomButton)}
      />
      {/* </ThemeProvider> */}
    </>
  )
}

export default ManageSite