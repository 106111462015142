import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import "../../../css/customStyle.css";
import DataTableExtensions from "react-data-table-component-extensions";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Row, Col, Card, Button, Nav } from "react-bootstrap";
import RanSiteTracker from "../ProjectTrackerComponent/RanSiteTracker";
import MUIDataTable from "mui-datatables";
import { useSelector, useDispatch } from "react-redux";
import {
  NetworkDatabaseAction,
  CSVUploadAction,
  PDFUploadAction,
} from "../../../store/actions/dashboardActions";
import {
  customDataTableOptionObject,
  getMuiThemeCommon,
} from "../../../common/variables";

import { Link, useHistory } from "react-router-dom";
import { actionTypes } from "redux-form";
import {
  AccountReducerAction,
  clearFetchSingleRecordAction,
  clearGetSingleProjectDatabase,
  FetchSingleRecordAction,
  GetTimeLineAction,
} from "../../../store/actions/createTicketManageFields";
import {
  listSiteId,
  multiRFDS,
  networkDashbordSiteHistory,
  projectCode,
  singleProjectApi,
  timelineApi,
} from "../../../common/config";
import SingleProject from "../ProjectModule/SingleProject";
import ProjectData from "../ReusableComponents/ProjectData";
import RanHistorical from "../ReusableComponents/RanHistorical";
import { UploadFile } from "@mui/icons-material";
import CustomeModal from "../Custom/CustomeModal";
import SiteApprovalModal from "../Custom/Modal Body/SiteApprovalModal";
import { ThemeProvider, createTheme } from "@mui/material/styles";
const NetworkDatabase = () => {
  const history = useHistory();
  const [accordianState, setAccordianState] = useState(false);
  const [siteFile, uploadedFile] = useState([]);
  const [remarks, setRemarks] = useState();
  const [modalData, setModalData] = useState([]);
  const [modalState, setModalState] = useState(false);
  const [siteUId, setSiteId] = useState();
  const [xlShow, setXlShow] = useState(false);
  const [bRFDS, setBRFDS] = useState(false);
  const [uId, setUId] = useState("");
  const [work, setWork] = useState("");
  const [siteDetail, setSiteDetailInfo] = useState({
    "5GId": "",
    BASEPOs: "",
    CBNComment: "",
    CBNFile: "",
    CDDFile: "",
    CRPOs: "",
    CXTowerCrewName: "",
    IXFieldEngineerName: "",
    LTEId: "",
    RFDSPdfFile: "",
    RFDSPdfFile: "",
    RIOTFile: "",
    RNDCIQCsvFile: "",
    SranCabinets: "",
    UMTSId: "",
    account: "",
    cityState: "",
    clusterEngineerEmail: "",
    clusterEngineerName: "",
    clusterEngineerPhone: "",
    crCode: "",
    cranCabinets: "",
    dateOfAddition: "",
    dateTime: "",
    engineerAddingProject: "",
    equipment: "",
    fopsEngineerEmail: "",
    fopsEngineerName: "",
    fopsEngineerPhone: "",
    fopsManagerEmail: "",
    fopsManagerName: "",
    fopsManagerPhone: "",
    group: "",
    gsm: "",
    latitude: "",
    loginLogoutsHistory: "",
    longitute: "",
    market: "",
    mixedModeId: "",
    projectBucket: "",
    projectCode: "",
    projectStatus: "",
    region: "",
    rfEngineerEmail: "",
    rfEngineerName: "",
    rfEngineerPhone: "",
    siteAddress: "",
    siteDevelopmentManagerEmail: "",
    siteDevelopmentManagerName: "",
    masiteDevelopmentManagerPhonerket: "",
    siteId: "",
    siteType: "",
    tMobileMangerEmail: "",
    tMobileMangerName: "",
    tMobileMangerPhone: "",
    technology: "",
    troubleTickets: "",
    weekOfAddition: "",
  });

  const darkTheme = createTheme({
    components: {
      MUIDataTable: {
        styleOverrides: {
          root: {
            // backgroundColor: '#red',
            height: 700,
          },
          paper: {
            boxShadow: "none",
          },
        },
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            maxHeight: 45,
            fontSize: 13,
            overflowY: "auto",
            padding: "0px 2px !important",

            margin: 0,
          },
        },
      },
      // MuiToolbar: {
      //   styleOverrides: {
      //     root: {
      //       backgroundColor: '#f00',

      //     },
      //   },
      // },
      MuiTableCell: {
        styleOverrides: {
          head: {
            maxHeight: 20,
            fontSize: 15,
            margin: 0,
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
      },
      MUIDataTableSelectCell: {
        styleOverrides: {
          headerCell: {
            backgroundColor: "blue",
          },
        },
      },
      MuiTableFooter: {
        styleOverrides: {
          root: {
            "& .MuiToolbar-root": {
              backgroundColor: "white",
            },
          },
        },
      },
    },
  });
  const fetechDetail = (id, work) => {
    setModalState(true);
    setWork(work);
    setUId(id);

    dispatch(clearGetSingleProjectDatabase());
  };

  const fetechDetailnew = (id) => {
    setModalState(true);
    setUId(id);
    dispatch(FetchSingleRecordAction(singleProjectApi, id));
  };

  // let columns=[]
  let rowData = [];
  let rowDataOne = [];
  let networkSiteStatus = [];

  let userDetails = JSON.parse(window.localStorage.getItem("userDetails"));
  console.log("the user details is=", userDetails);
  const count = useSelector((state) => {
    console.log("the state is =", state);
    let projectHistory = state.CommonResponse.networkDatabaseSiteStatus;
    if (projectHistory && projectHistory.length > 0) {
      networkSiteStatus = projectHistory;
    }
    if (Object.keys(state.sites.sites).length > 0) {
      console.log("rfrgwfefwrw=", state.sites.sites);
      if (state.sites.sites.tableData) {
        rowData = state.sites.sites.tableData.map((item) => {
          item["action"] = (
            <>
              <button
                className="btn btn-success shadow btn-xs sharp mr-1"
                data-toggle="modal"
                data-target="#exampleModal"
                onClick={() => openModalHandler(item)}
              >
                <i className="fa fa-eye"></i>
              </button>
              <button className="btn btn-primary shadow btn-xs sharp mr-1">
                <i className="fa fa-pencil"></i>
              </button>
              <button className="btn btn-danger shadow btn-xs sharp">
                <i className="fa fa-trash"></i>
              </button>
            </>
          );
          item["5GId"] = "";
          item["LTEId"] = "";
          item["UMTSId"] = "";
          item["mixedModeId"] = "";
          let tec = "";

          if (item["technology"] && item["technology"] != null) {
            tec = item["technology"].values.join(", ");
            item["temp"] = tec;
          }

          // User orented Column Action
          if (userDetails.rolename == "RF Engineer") {
            if (item["mopApproval"] == 1) {
              if (item["rfstatus"] == "Approved") {
                item["siteApprovalAction"] = (
                  <>
                    <p
                      className="badge badge-success p-2"
                      onClick={() => fetechDetail(item.newuniqueId)}
                    >
                      <Link
                        to={"/PAG/rf/network/siteApproval/" + item.newuniqueId}
                        style={{ color: "white" }}
                      >
                        Approved
                      </Link>
                    </p>
                  </>
                );
              } else if (item["rfstatus"] == "Disapproved") {
                item["siteApprovalAction"] = (
                  <>
                    <p
                      className="badge badge-danger p-2"
                      onClick={() => fetechDetail(item.newuniqueId)}
                    >
                      <Link
                        to={"/PAG/rf/network/siteApproval/" + item.newuniqueId}
                        style={{ color: "white" }}
                      >
                        Disapproved
                      </Link>
                    </p>
                  </>
                );
              } else {
                item["siteApprovalAction"] = (
                  <>
                    <p
                      className="badge badge-primary p-2"
                      onClick={() => fetechDetail(item.newuniqueId)}
                    >
                      <Link
                        to={"/PAG/rf/network/siteApproval/" + item.newuniqueId}
                        style={{ color: "white" }}
                      >
                        Pending
                      </Link>
                    </p>
                  </>
                );
              }
            }
            if (item["mopApproval"] == 1 && item["group"] == "Development") {
              if (item["devstatus"] == "Approved") {
                item["siteDevelopmentApprovalStatus"] = (
                  <>
                    <p
                      className="badge badge-success p-2"
                      onClick={() => fetechDetail(item.newuniqueId)}
                    >
                      <Link
                        to={"/PAG/dev/network/siteApproval/" + item.newuniqueId}
                        style={{ color: "white" }}
                      >
                        Approved
                      </Link>
                    </p>
                  </>
                );
              } else if (item["devstatus"] == "Disapproved") {
                item["siteDevelopmentApprovalStatus"] = (
                  <>
                    <p
                      className="badge badge-danger p-2"
                      onClick={() => fetechDetail(item.newuniqueId)}
                    >
                      <Link
                        to={"/PAG/dev/network/siteApproval/" + item.newuniqueId}
                        style={{ color: "white" }}
                      >
                        Disapproved
                      </Link>
                    </p>
                  </>
                );
              } else {
                item["siteDevelopmentApprovalStatus"] = (
                  <>
                    <p
                      className="badge badge-primary p-2"
                      onClick={() => fetechDetail(item.newuniqueId)}
                    >
                      <Link
                        to={"/PAG/dev/network/siteApproval/" + item.newuniqueId}
                        style={{ color: "white" }}
                      >
                        Pending
                      </Link>
                    </p>
                  </>
                );
              }
            }
            if (item["mopApproval"] == 1 && item["group"] == "Operation") {
              if (item["fopsstatus"] == "Approved") {
                item["FOPSApprovalStatus"] = (
                  <>
                    <p
                      className="badge badge-success p-2"
                      onClick={() => fetechDetail(item.newuniqueId)}
                    >
                      <Link
                        to={"/PAG/ops/network/siteApproval/" + item.newuniqueId}
                        style={{ color: "white" }}
                      >
                        Approved
                      </Link>
                    </p>
                  </>
                );
              } else if (item["fopsstatus"] == "Disapproved") {
                item["FOPSApprovalStatus"] = (
                  <>
                    <p
                      className="badge badge-danger p-2"
                      onClick={() => fetechDetail(item.newuniqueId)}
                    >
                      <Link
                        to={"/PAG/ops/network/siteApproval/" + item.newuniqueId}
                        style={{ color: "white" }}
                      >
                        Disapproved
                      </Link>
                    </p>
                  </>
                );
              } else {
                item["FOPSApprovalStatus"] = (
                  <>
                    <p
                      className="badge badge-primary p-2"
                      onClick={() => fetechDetail(item.newuniqueId)}
                    >
                      <Link
                        to={"/PAG/ops/network/siteApproval/" + item.newuniqueId}
                        style={{ color: "white" }}
                      >
                        Pending
                      </Link>
                    </p>
                  </>
                );
              }
            }
            // item["siteApprovalAction"]=<><button className ="btn btn-success shadow btn-xs sharp mr-1" ><Link to={"/PAG/network/siteApproval/"+item.newuniqueId} style={{color:"white"}}>Action</Link></button></>
          } else if (userDetails.rolename == "Development") {
            if (item["mopApproval"] == 1 && item["group"] == "Development") {
              if (item["devstatus"] == "Approved") {
                item["siteDevelopmentApprovalStatus"] = (
                  <>
                    <p
                      className="badge badge-success p-2"
                      onClick={() => fetechDetail(item.newuniqueId)}
                    >
                      <Link
                        to={"/PAG/dev/network/siteApproval/" + item.newuniqueId}
                        style={{ color: "white" }}
                      >
                        Approved
                      </Link>
                    </p>
                  </>
                );
              } else if (item["devstatus"] == "Disapproved") {
                item["siteDevelopmentApprovalStatus"] = (
                  <>
                    <p
                      className="badge badge-danger p-2"
                      onClick={() => fetechDetail(item.newuniqueId)}
                    >
                      <Link
                        to={"/PAG/dev/network/siteApproval/" + item.newuniqueId}
                        style={{ color: "white" }}
                      >
                        Disapproved
                      </Link>
                    </p>
                  </>
                );
              } else {
                item["siteDevelopmentApprovalStatus"] = (
                  <>
                    <p
                      className="badge badge-primary p-2"
                      onClick={() => fetechDetail(item.newuniqueId)}
                    >
                      <Link
                        to={"/PAG/dev/network/siteApproval/" + item.newuniqueId}
                        style={{ color: "white" }}
                      >
                        Pending
                      </Link>
                    </p>
                  </>
                );
              }
            }
            // item["siteApprovalAction"]=<><button className ="btn btn-success shadow btn-xs sharp mr-1" ><Link to={"/PAG/network/siteApproval/"+item.newuniqueId} style={{color:"white"}}>Action</Link></button></>
          } else if (userDetails.rolename == "FOPS") {
            if (item["fopsstatus"] == "Approved") {
              item["FOPSApprovalStatus"] = (
                <>
                  <p
                    className="badge badge-success p-2"
                    onClick={() => fetechDetail(item.newuniqueId)}
                  >
                    <Link
                      to={"/PAG/ops/network/siteApproval/" + item.newuniqueId}
                      style={{ color: "white" }}
                    >
                      Approved
                    </Link>
                  </p>
                </>
              );
            } else if (item["fopsstatus"] == "Disapproved") {
              item["FOPSApprovalStatus"] = (
                <>
                  <p
                    className="badge badge-danger p-2"
                    onClick={() => fetechDetail(item.newuniqueId)}
                  >
                    <Link
                      to={"/PAG/ops/network/siteApproval/" + item.newuniqueId}
                      style={{ color: "white" }}
                    >
                      Disapproved
                    </Link>
                  </p>
                </>
              );
            } else {
              item["FOPSApprovalStatus"] = (
                <>
                  <p
                    className="badge badge-primary p-2"
                    onClick={() => fetechDetail(item.newuniqueId)}
                  >
                    <Link
                      to={"/PAG/ops/network/siteApproval/" + item.newuniqueId}
                      style={{ color: "white" }}
                    >
                      Pending
                    </Link>
                  </p>
                </>
              );
            }
            // item["siteApprovalAction"]=<><button className ="btn btn-success shadow btn-xs sharp mr-1" ><Link to={"/PAG/network/siteApproval/"+item.newuniqueId} style={{color:"white"}}>Action</Link></button></>
          } else if (
            userDetails.rolename == "Vendor (SP) CX Crew Manager" ||
            userDetails.rolename == "Vendor (ASP) IX Crew Manager"
          ) {
            if (item["mopApproval"] == 1) {
              item["applyMOPRequest"] = (
                <p className="badge badge-success p-2">Requested</p>
              );
            }
            // else{
            //   item["applyMOPRequest"]=<button className='btn btn-sm btn-primary' onClick={()=>{fetechSingleProject(item.newuniqueId)}}><Link to={"/PAG/crew/network/siteApproval/"+item.newuniqueId}  style={{color:"white"}}>Apply</Link></button>
            // }
          }
          item["siteId"] = (
            <p
              className="text-primary"
              onClick={() => fetechDetail(item.newuniqueId)}
            >
              <Link
                to={"/PAG/network/database/" + item.newuniqueId}
                style={{ color: "#6610f2" }}
              >
                {item.siteId}
              </Link>
            </p>
          );
          // item['siteId'] =<p  className="text-primary" onClick={()=>showDetails(item.newuniqueId)}>{item.siteId}</p>
          return item;
        });
      } else {
        console.log("in the else part =");
        if (state.sites.sites.tableData) {
          rowData = state.sites.sites.tableData.map((item) => {
            // item['siteId']= item.siteId
            // if(item["mopApproval"]==1){
            item["5GId"] = "";
            item["LTEId"] = "";
            item["UMTSId"] = "";
            item["mixedModeId"] = "";
            let tec = "";
            let newSow = "";
            let newSectorsImpacted = "";
            console.log("sgrthdhr", item);
            if (item["technology"] && item["technology"] != null) {
              let techArray = item["technology"];
              if (techArray.values.length != 0) {
                //  item['technology'].forEach((item)=>{
                //   tec +=item.label +','
                //  })

                for (let i = 0; i < techArray.values.length; i++) {
                  console.log("sgrthdhr", techArray.values[i]);
                  tec += techArray.values[i] + ", ";
                }
                //  item['technology']=tec
              }
              item["temp"] = tec;
            }
            if (item["sectorsImpacted"] && item["sectorsImpacted"] != null) {
              let techArray = item["sectorsImpacted"];
              if (techArray.values.length != 0) {
                //  item['technology'].forEach((item)=>{
                //   tec +=item.label +','
                //  })

                for (let i = 0; i < techArray.values.length; i++) {
                  console.log("sgrthdhr", techArray.values[i]);
                  newSectorsImpacted += techArray.values[i] + ", ";
                }
                //  item['technology']=tec
              }
              item["newSectorsImpacted"] = newSectorsImpacted;
            }
            console.log("the item detail =", item);
            item["newsowDetail"] = (
              <p style={{ width: "150px", height: "125px", overflow: "auto" }}>
                {item.sowDetail}
              </p>
            );
            if (item["sowDetails"] && item["sowDetails"] != null) {
              let sowArray = item["sowDetails"];
              if (sowArray.values.length != 0) {
                //  item['technology'].forEach((item)=>{
                //   tec +=item.label +','
                //  })

                for (let i = 0; i < sowArray.values.length; i++) {
                  console.log("sgrthdhr", sowArray.values[i]);
                  newSow += sowArray.values[i] + ", ";
                }
                //  item['technology']=tec
              }
              item["newSow"] = newSow;
            }
            if (userDetails.rolename == "RF Engineer") {
              if (
                item["rfstatus"] == "Approved" &&
                item["devstatus"] == "Approved"
              ) {
                item["goNo"] = "Approved";
              } else if (
                item["rfstatus"] == "Approved" &&
                item["fopsstatus"] == "Approved"
              ) {
                item["goNo"] = "Approved";
              }
              if (item["mopApproval"] == 1) {
                if (item["rfstatus"] == "Approved") {
                  item["siteApprovalAction"] = (
                    <>
                      <p
                        className="badge badge-success p-2"
                        onClick={() => fetechDetail(item.newuniqueId)}
                      >
                        Approved
                      </p>
                    </>
                  );
                } else if (item["rfstatus"] == "Disapproved") {
                  item["siteApprovalAction"] = (
                    <>
                      <p
                        className="badge badge-danger p-2"
                        onClick={() => fetechDetail(item.newuniqueId)}
                      >
                        Disapproved
                      </p>
                    </>
                  );
                } else {
                  item["siteApprovalAction"] = (
                    <>
                      <p
                        className="badge badge-primary p-2"
                        onClick={() => fetechDetail(item.newuniqueId, "rf")}
                      >
                        Pending
                      </p>
                    </>
                  );
                }
              }
              if (item["mopApproval"] == 1 && item["group"] == "Development") {
                if (item["devstatus"] == "Approved") {
                  item["siteDevelopmentApprovalStatus"] = (
                    <>
                      <p
                        className="badge badge-success p-2"
                        onClick={() => fetechDetail(item.newuniqueId)}
                      >
                        Approved
                      </p>
                    </>
                  );
                } else if (item["devstatus"] == "Disapproved") {
                  item["siteDevelopmentApprovalStatus"] = (
                    <>
                      <p
                        className="badge badge-danger p-2"
                        onClick={() => fetechDetail(item.newuniqueId)}
                      >
                        Disapprovedin
                      </p>
                    </>
                  );
                } else {
                  item["siteDevelopmentApprovalStatus"] = (
                    <>
                      <p
                        className="badge badge-primary p-2"
                        onClick={() => fetechDetail(item.newuniqueId, "dev")}
                      >
                        Pending
                      </p>
                    </>
                  );
                }
              }
              if (item["mopApproval"] == 1 && item["group"] == "Operation") {
                if (item["fopsstatus"] == "Approved") {
                  item["FOPSApprovalStatus"] = (
                    <>
                      <p
                        className="badge badge-success p-2"
                        onClick={() => fetechDetail(item.newuniqueId)}
                      >
                        Approved
                      </p>
                    </>
                  );
                } else if (item["fopsstatus"] == "Disapproved") {
                  item["FOPSApprovalStatus"] = (
                    <>
                      <p
                        className="badge badge-danger p-2"
                        onClick={() => fetechDetail(item.newuniqueId)}
                      >
                        Disapproved
                      </p>
                    </>
                  );
                } else {
                  item["FOPSApprovalStatus"] = (
                    <>
                      <p
                        className="badge badge-primary p-2"
                        onClick={() => fetechDetail(item.newuniqueId, "ops")}
                      >
                        Pending
                      </p>
                    </>
                  );
                }
              }
              // item["siteApprovalAction"]=<><button className ="btn btn-success shadow btn-xs sharp mr-1" ><Link to={"/PAG/network/siteApproval/"+item.newuniqueId} style={{color:"white"}}>Action</Link></button></>
            } else if (userDetails.rolename == "Development") {
              if (item["mopApproval"] == 1 && item["group"] == "Development") {
                if (item["devstatus"] == "Approved") {
                  item["siteDevelopmentApprovalStatus"] = (
                    <>
                      <p
                        className="badge badge-success p-2"
                        onClick={() => fetechDetail(item.newuniqueId)}
                      >
                        Approved
                      </p>
                    </>
                  );
                } else if (item["devstatus"] == "Disapproved") {
                  item["siteDevelopmentApprovalStatus"] = (
                    <>
                      <p
                        className="badge badge-danger p-2"
                        onClick={() => fetechDetail(item.newuniqueId)}
                      >
                        Disapproved
                      </p>
                    </>
                  );
                } else {
                  item["siteDevelopmentApprovalStatus"] = (
                    <>
                      <p
                        className="badge badge-primary p-2"
                        onClick={() => fetechDetail(item.newuniqueId, "dev")}
                      >
                        Pending
                      </p>
                    </>
                  );
                }
                if (
                  item["rfstatus"] == "Approved" &&
                  item["devstatus"] == "Approved"
                ) {
                  item["goNo"] = "Approved";
                } else if (
                  item["rfstatus"] == "Approved" &&
                  item["fopsstatus"] == "Approved"
                ) {
                  item["goNo"] = "Approved";
                }
              }
              console.log("the rf status=", item["rfstatus"]);
              if (item["rfstatus"]) {
                item["siteApprovalAction"] = item["rfstatus"];
              } else {
                item["siteApprovalAction"] = <p>Pending</p>;
              }
            } else if (userDetails.rolename == "FOPS") {
              if (item["mopApproval"] == 1 && item["group"] == "Operation")
                if (item["fopsstatus"] == "Approved") {
                  item["FOPSApprovalStatus"] = (
                    <>
                      <p
                        className="badge badge-success p-2"
                        onClick={() => fetechDetail(item.newuniqueId)}
                      >
                        Approved
                      </p>
                    </>
                  );
                } else if (item["fopsstatus"] == "Disapproved") {
                  item["FOPSApprovalStatus"] = (
                    <>
                      <p
                        className="badge badge-danger p-2"
                        onClick={() => fetechDetail(item.newuniqueId)}
                      >
                        Disapproved
                      </p>
                    </>
                  );
                } else {
                  item["FOPSApprovalStatus"] = (
                    <>
                      <p
                        className="badge badge-primary p-2"
                        onClick={() => fetechDetail(item.newuniqueId, "ops")}
                      >
                        Pending
                      </p>
                    </>
                  );
                }
              if (
                item["rfstatus"] == "Approved" &&
                item["devstatus"] == "Approved"
              ) {
                item["goNo"] = "Approved";
              } else if (
                item["rfstatus"] == "Approved" &&
                item["fopsstatus"] == "Approved"
              ) {
                item["goNo"] = "Approved";
              }
              // item["siteApprovalAction"]=<><button className ="btn btn-success shadow btn-xs sharp mr-1" ><Link to={"/PAG/network/siteApproval/"+item.newuniqueId} style={{color:"white"}}>Action</Link></button></>
            } else if (
              userDetails.rolename == "Vendor (SP) CX Crew Manager" ||
              userDetails.rolename == "Vendor (ASP) IX Crew Manager" ||
              userDetails.rolename == "GC Login"
            ) {
              // if(item["mopApproval"]==1){
              item["applyMOPRequest"] = (
                <p className="badge badge-success p-2">Requested</p>
              );
              // }
              // else{
              //   item["applyMOPRequest"]=<button className='btn btn-sm btn-primary' onClick={()=>{fetechSingleProject(item.newuniqueId)}}>Apply</button>
              // }

              if (
                item["rfstatus"] == "Approved" &&
                item["devstatus"] == "Approved"
              ) {
                item["goNo"] = "Go";
              } else if (
                item["rfstatus"] == "Approved" &&
                item["fopsstatus"] == "Approved"
              ) {
                item["goNo"] = "GO";
              } else {
                item["goNo"] = "NOGO";
              }
              if (item["mopApproval"] == 1) {
                if (item["rfstatus"] == "Approved") {
                  item["siteApprovalAction"] = (
                    <>
                      <p className="badge badge-success p-2">Approved</p>
                    </>
                  );
                } else if (item["rfstatus"] == "Disapproved") {
                  item["siteApprovalAction"] = (
                    <>
                      <p className="badge badge-danger p-2">Disapproved</p>
                    </>
                  );
                } else {
                  item["siteApprovalAction"] = (
                    <>
                      <p className="badge badge-primary p-2">Pending</p>
                    </>
                  );
                }
              }
              if (item["mopApproval"] == 1 && item["group"] == "Development") {
                if (item["devstatus"] == "Approved") {
                  item["siteDevelopmentApprovalStatus"] = (
                    <>
                      <p className="badge badge-success p-2">Approved</p>
                    </>
                  );
                } else if (item["devstatus"] == "Disapproved") {
                  item["siteDevelopmentApprovalStatus"] = (
                    <>
                      <p className="badge badge-danger p-2">Disapprovedin</p>
                    </>
                  );
                } else {
                  item["siteDevelopmentApprovalStatus"] = (
                    <>
                      <p className="badge badge-primary p-2">Pending</p>
                    </>
                  );
                }
              }

              if (item["mopApproval"] == 1 && item["group"] == "Operation") {
                if (item["fopsstatus"] == "Approved") {
                  item["FOPSApprovalStatus"] = (
                    <>
                      <p className="badge badge-success p-2">Approved</p>
                    </>
                  );
                } else if (item["fopsstatus"] == "Disapproved") {
                  item["FOPSApprovalStatus"] = (
                    <>
                      <p className="badge badge-danger p-2">Disapproved</p>
                    </>
                  );
                } else {
                  item["FOPSApprovalStatus"] = (
                    <>
                      <p className="badge badge-primary p-2">Pending</p>
                    </>
                  );
                }
              }
            }

            return item;
            // }
          });
        }
      }
      console.log(state.sites.sites.tableData);
      if (
        state.sites.sites.tableData &&
        state.sites.sites.tableData.length > 0
      ) {
        rowDataOne = state.sites.sites.tableData[0];
        Object.keys(rowDataOne).map((key, index) => {
          console.log(key, "key");
          if (key != "projectStatusDescr") {
            // columns.push(
            //   {
            //     name: key,
            //     selector: key,
            //     sortable: true
            //     }
            //   )
          }
        });
      }
    }

    return { totalTickets: state.sites.sites.TotalTicket, networkSiteStatus };
  });
  // columns.unshift({
  //   name:"action",
  //   aelector:'action'
  //   // selector:"Action",
  //   // sortable:false
  // })
  // if(rowDataOne!=0){
  //   console.log("agsdhgsafgsdhgfjghdfgsfadgsfhfjhgfgdfghmhgfgfhf",rowDataOne)
  // let tempModalData=  Object.keys(rowDataOne).map((key, index) => {
  //     console.log("mnijioubniulbodbfvodr=",key,index)
  //   return <div className="form-group col-md-4">
  //           <label htmlFor="inputPassword4">{key}</label>
  //           <div>{index}</div>
  //         </div>

  // })
  // }
  const fetechSingleProject = (id = undefined, siteId) => {
    console.log("single pro ", id);
    // console.log("the actual site id=",siteId)
    setUId(id);
    setModalState(true);

    dispatch(FetchSingleRecordAction(projectCode, id));
    // setCtrCount(1)
    // setTech()

    // setModalState(false)

    // dispatch(NetworkDatabaseAction())
  };
  const bulkRfds = () => {
    setBRFDS(true);
  };

  // let columns=[]
  let columns = [
    // {
    //   label:"Action",
    //   name:"action",
    // },
    {
      label: "Main Id",
      name: "siteId",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
          "min-width" : "110px",
            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      label: "Project Code",
      name: "projectCode",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "min-width" : "140px",
            "max-width"  :"170px",
            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "account",
      label: "Account",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "temp",
      label: "Technology",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "min-width": "300px",
            "max-width": "300px",
            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "projectBucket",
      label: "Project Bucket",
      customBodyRender: (item) => console.log("from customBodyRender =", item),
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "projectStatus",
      label: "Project Status",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "equipment",
      label: "Equipment",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
  ];
  let columnMop = [
    // {
    //   label:"Action",
    //   name:"action",
    // },
    {
      label: "Main Id",
      name: "siteId",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            "min-width" : "85px",
            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      label: "Project Code",
      name: "projectCode",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            "min-width" : "140px",
            "max-width" : "160px",
            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "market",
      label: "Market",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },

    {
      name: "newSectorsImpacted",
      label: "Sectors Impacted",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "temp",
      label: "Technology",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            "min-width": "350px",
            "max-width": "400px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
    },
    {
      name: "company",
      label: "Company",
      sortable: true,

      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "owner",
      label: "Owner",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "ownerEmail",
      label: "Owner Email",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "OwnerContact",
      label: "Owner Contact",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "typeOfWork",
      label: "Type Of Work(SI/NSI)",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "sectorByOutage",
      label: "Sector by sector Outage",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "CraneNeeded",
      label: "Crane Needed",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "crNumber",
      label: "CR NO",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
             "min-width" : "100px",
             "max-width" : "130px",
            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "justificationForRequest",
      label: "Justification Request",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "justificationComment",
      label: "Justification Comments",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "startTime",
      label: "Start Date Time",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "endTime",
      label: "End Date Time",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "DownTime",
      label: "Down Time (H)",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "newSow",
      label: "SoW",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "newsowDetail",
      label: "SoW  Details",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },

    {
      name: "mop",
      label: "MOP",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },

    {
      name: "goNo",
      label: "GO/NOGO",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "siteDevelopmentApprovalStatus",
      label: "Site Development Approval Status",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "siteApprovalAction",
      label: "RF Approval Status",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "FOPSApprovalStatus",
      label: "FOPS Approval Status",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "",
      label: "Develpment approval or reject comment",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",

            minWidth: "200px",
            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "",
      label: "RF approval or reject comment",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",

            minWidth: "200px",
            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "",
      label: "FOPS approval or reject comment",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",

            minWidth: "200px",
            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "group",
      label: "Group",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    //,{
    //   name:"siteDevelopmentApprovalStatus",
    //   label:"Site Development Approval Status",
    //   sortable:true
    // },{
    //   name:"siteApprovalAction",
    //   label:"RF Approval Status",
    //   sortable:true
    // },{
    //   name:"FOPSApprovalStatus",
    //   label:"FOPS Approval Status",
    //   sortable:true
    // }
  ];

  // if(userDetails.rolename=="Administrator"){
  // columns.push({
  //   name:"siteApprovalAction",
  //   label:"Site Approval Action",
  //   sortable:true
  // })
  // columns.push({
  //   name:"siteApproval",
  //   label:"Site Approval",
  //   sortable:true
  // })
  //   columns.push({
  //         name: "account",
  //         label: "Account",
  //         sortable: true,
  //       },
  //       {
  //         name: "projectBucket",
  //         label: "Project Bucket",
  //         customBodyRender: (item) => console.log("from customBodyRender =", item),
  //         sortable: true,
  //       },
  //       {
  //         name: "projectStatus",
  //         label: "Project Status",
  //         sortable: true,
  //       },
  //       {
  //         name: "equipment",
  //         label: "Equipment",
  //         sortable: true,
  //       },)

  // }
  // else if(userDetails.rolename=="RF Engineer"){
  // columns.push({
  //   name:"siteApprovalAction",
  //   label:"Site Approval Action",
  //   sortable:true
  // })
  // columns.push({
  //   name:"siteApproval",
  //   label:"Site Approval",
  //   sortable:true
  // })
  //   columnMop.push({
  //     name:"siteDevelopmentApprovalStatus",
  //     label:"Site Development Approval Status",
  //     sortable:true
  //   },{
  //     name:"siteApprovalAction",
  //     label:"RF Approval Status",
  //     sortable:true
  //   },{
  //     name:"FOPSApprovalStatus",
  //     label:"FOPS Approval Status",
  //     sortable:true
  //   },
  //   {
  //     name:"group",
  //     label:"Group",
  //     sortable:true
  //    })

  // }
  // else if(userDetails.rolename=="Development"){
  //   columnMop.push({
  //     name:"siteDevelopmentApprovalStatus",
  //     label:"Site Development Approval Status",
  //     sortable:true
  //   },
  //   )

  // }
  // else if(userDetails.rolename=="FOPS"){
  //   columnMop.push(
  //   {
  //     name:"group",
  //     label:"Group",
  //     sortable:true
  //    })

  // }
  // else if(userDetails.rolename=="Vendor (SP) CX Crew Manager" || userDetails.rolename=="Vendor (ASP) IX Crew Manager"){
  // else if(userDetails.rolename=="GC Login"){
  //   columnMop.push(

  //   // ,{
  //   //   name:"siteDevelopmentApprovalStatus",
  //   //   label:"Site Development Approval Status",
  //   //   sortable:true
  //   // },{
  //   //   name:"siteApprovalAction",
  //   //   label:"RF Approval Status",
  //   //   sortable:true
  //   // },{
  //   //   name:"FOPSApprovalStatus",
  //   //   label:"FOPS Approval Status",
  //   //   sortable:true
  //   // }
  //   )
  // }
  const openModalHandler = (item) => {
    setSiteDetailInfo(item);
    let tempModalData = Object.keys(item).map((key, index) => {
      console.log("mnijioubniulbodbfvodr=", key, index);
      if (key != "action") {
        return (
          <>
            {" "}
            <hr />
            <div className="form-group col-md-6  col-sm-12 col-xm-12">
              <label htmlFor="inputPassword4">{key}</label>

              <br></br>
              <label>{item[key]}</label>
            </div>
            <hr />
          </>
        );
      }
    });
    setModalData(tempModalData);
  };
  const dispatch = useDispatch();
  function accordianHandler() {
    setAccordianState(!accordianState);
  }
  function showDetails(id) {
    setXlShow(true);
    setSiteId(id);
    console.log("dfghedrhetsetghrrtd", id);
    dispatch(GetTimeLineAction(timelineApi, id));
    dispatch(FetchSingleRecordAction(singleProjectApi, id));
  }

  console.log("columns", rowData);
  createTheme("solarized", {
    text: {
      primary: "inherit",
      secondary: "inherit",
    },
    background: {
      default: "inherit",
    },
    context: {
      background: "inherit",
      text: "inherit",
    },
    divider: {
      default: "inherit",
    },
    button: {
      default: "inherit",
      hover: "rgba(0,0,0,.08)",
      focus: "rgba(255,255,255,.12)",
      disabled: "rgba(255, 255, 255, .34)",
    },
    sortFocus: {
      default: "inherit",
    },
  });
  const handleChange = (e) => {
    console.log("the current anser= ", e.target.value);
  };
  let row = [];

  const AddCustomButton = () => (
    <Link
      className="text-white btn btn-primary btn-xs "
      style={{ padding: "9px 12px" }}
      to={{
        pathname: "/PAG/createProject",
        rdfsItem: "",
      }}
    >
      Create Ticket
    </Link>
  );

  //on submiting the excel
  const submitHandler = async (e, check = null) => {
    e.preventDefault();

    if (check == "runRFDS") {
      console.log("inside RFDS", siteFile);
      // console.log("remarks =",remarks)
      console.log("file=", siteFile);
      console.log(columns);
      let temp = [];
      // temp.push(...siteFile)
      let form = new FormData();

      // for(let i=0;i<siteFile.length;i++){
      //   form.append('uploadFile',siteFile[i].file, siteFile[i].name)
      // }
      // form.append('uploadFile',siteFile)

      console.log(siteFile, siteFile.length);
      for (let i = 0; i < siteFile.length; i++) {
        form.append("uploadFile", siteFile[i]);
      }
      form.append("uploadfor", "multipleRfdsFile");

      // for (const key of Object.keys(siteFile)) {
      //   formData.append('imgCollection', siteFile[key])
      // }
      // form.append("uploadfor","dsfs");
      // setRemarks("")
      console.log("file=", form);
      dispatch(PDFUploadAction(multiRFDS, form));
      console.log("form data =", form);
    }
    // else{
    // console.log("remarks =",remarks)
    // console.log("file=",siteFile)
    // console.log(columns)
    // const form = new FormData();
    // form.push("uploadFile", siteFile);
    // form.push("uploadfor","Network_Database")
    // // form.append("remarks",remarks);
    // setRemarks("")
    // // uploadedFile()
    // dispatch(CSVUploadAction(form))
    // console.log("form data =",form)
    // }
  };
  const uploadCsvFile = (e) => {
    let files = [];
    // let fileArray = [];
    // files.push(e.target.files)
    // console.log("the filre is =",e.target.files[0])
    // for (let i = 0; i < files[0].length; i++) {
    //   fileArray.push(URL.createObjectURL(files[0][i]))
    // }

    console.log(e.target.files[0], "ON_CHANGE_FILES:", e.target.files.length);

    for (let i = 0; i < e.target.files.length; i++) {
      files.push(e.target.files[i]);
    }
    console.log(...files);
    uploadedFile(files);
  };

  const setDataVariables = async () => {
    dispatch(NetworkDatabaseAction());
  };

  let getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTable: {
          styleOverrides: {
            root: {
              //   backgroundColor: '#red',
              height: 710,
            },
            paper: {
              boxShadow: "none",
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              maxHeight: 45,
              fontSize: 13,
              overflowY: "auto",
              padding: "0px 2px !important",
              margin: 0,
              textAlign: "center",
            },
          },
        },
        // MuiToolbar: {
        //   styleOverrides: {
        //     root: {
        //       backgroundColor: '#f00',

        //     },
        //   },
        // },
        MuiTableCell: {
          styleOverrides: {
            head: {
              maxHeight: 20,
              fontSize: 15,
              margin: 0,
              paddingTop: 0,
              paddingBottom: 0,
            },
          },
        },
        MUIDataTableSelectCell: {
          styleOverrides: {
            headerCell: {
              backgroundColor: "blue",
            },
          },
        },
        MuiTableFooter: {
          styleOverrides: {
            root: {
              "& .MuiToolbar-root": {
                backgroundColor: "white",
              },
            },
          },
        },
      },
    });

  const options = {
    download: false,
    filter: false,
    // onFilterChange :(changedColumn,filterList)=>{
    //   console.log(changedColumn,filterList)
    // },
    selectableRows: false,
    filterType: "dropdown",
    responsive: "scrollMaxHeight",
    count: 100,
    rowsPerPage: 25,
    expandableRows: false,
    rowsPerPageOptions: [25, 50, 75],
    // expandableRows:true,
    // renderExpandableRow:(data,rowMeta)=>{
    //   console.log(" ",data,"  rowMeta =",rowData[rowMeta.rowIndex])
    //   return (
    //     <React.Fragment>
    //         <div>
    //           <ul className='row mx-2 '>
    //             <li className='col-md-5'><span>Market :</span><span>{rowData[rowMeta.rowIndex].market}</span></li>
    //             <li className='col-md-5'><span>Account :</span><span>{rowData[rowMeta.rowIndex].account}</span></li>
    //             <li className='col-md-5'><span>Group :</span><span>{rowData[rowMeta.rowIndex].group}</span></li>
    //             <li className='col-md-5'><span>Account :</span><span>{rowData[rowMeta.rowIndex].account}</span></li>
    //             <li className='col-md-5'><span>Company :</span><span>{rowData[rowMeta.rowIndex].company}</span></li>
    //             <li className='col-md-5'><span>Owner :</span><span>{rowData[rowMeta.rowIndex].owner}</span></li>
    //             <li className='col-md-5'><span>Owner-Email :</span><span>{rowData[rowMeta.rowIndex].email}</span></li>
    //             <li className='col-md-5'><span>Owner Contact :</span><span>{rowData[rowMeta.rowIndex].OwnerContact}</span></li>
    //             <li className='col-md-5'><span>Type Of Work(SI/NSI) :</span><span>{rowData[rowMeta.rowIndex].typeOfWork}</span></li>
    //             <li className='col-md-5'><span>Sector by sector Outage :</span><span>{rowData[rowMeta.rowIndex].sectorByOutage}</span></li>
    //             <li className='col-md-5'><span>Crane Needed :</span><span>{rowData[rowMeta.rowIndex].CraneNeeded}</span></li>
    //             <li className='col-md-5'><span>CR NO :</span><span>{rowData[rowMeta.rowIndex].crNumber}</span></li>
    //             <li className='col-md-5'><span>Justification Comments :</span><span>{rowData[rowMeta.rowIndex].justificationComment}</span></li>
    //             <li className='col-md-5'><span>Start Date Time :</span><span>{rowData[rowMeta.rowIndex].startTime}</span></li>
    //             <li className='col-md-5'><span>End Date Time :</span><span>{rowData[rowMeta.rowIndex].endTime}</span></li>
    //             <li className='col-md-5'><span>Down Time (H) :</span><span>{rowData[rowMeta.rowIndex].DownTime}</span></li>
    //             <li className='col-md-5'><span>Sectors Impacted :</span><span>{rowData[rowMeta.rowIndex].sectorsImpacted ?
    //               rowData[rowMeta.rowIndex].sectorsImpacted.values.map((item)=>{
    //                 return item+','
    //               })
    //               :''
    //           }</span></li>
    //             < li className='col-md-5'><span>SoW :</span><span>{rowData[rowMeta.rowIndex].sowDetails ?
    //               rowData[rowMeta.rowIndex].sowDetails.values.map((item)=>{
    //                 return item+','
    //               })
    //               :''
    //           }</span></li>
    //             <li className='col-md-5'><span>Technology :</span><span>{rowData[rowMeta.rowIndex].technology.values.length?
    //               rowData[rowMeta.rowIndex].technology.values.map((item)=>{
    //                 return item+','
    //               })
    //               :''
    //           }</span></li>
    //             <li className='col-md-5'><span>SoW  Details :</span><span>{rowData[rowMeta.rowIndex].sowDetail}</span></li>
    //             <li className='col-md-5'><span>MOP :</span><span>{rowData[rowMeta.rowIndex].mop}</span></li>
    //             {/* <li><span>Account :</span><span>{rowData[rowMeta.rowIndex].account}</span></li> */}
    //           </ul>
    //         </div>

    //     </React.Fragment>
    //   )
    // }
  };

  useEffect(() => {
    dispatch(AccountReducerAction(listSiteId));
    dispatch(AccountReducerAction(networkDashbordSiteHistory));
    setDataVariables();
  }, [count.totalTickets]);
  return (
    <>
      <Modal
        size="xl"
        show={xlShow}
        onHide={() => setXlShow(false)}
        aria-labelledby="example-modal-sizes-title-xl"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-xl">
            <p>Site Details</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mt-2">{/* <SingleProject id={siteUId}/> */}</div>

          <Row>
            <Col xl={8}>
              <Card>
                <Card.Header>
                  <Card.Title>Summary</Card.Title>
                </Card.Header>
                <Card.Body>
                  {/* <!-- Nav tabs --> */}
                  <div className="default-tab">
                    <Tabs
                      defaultActiveKey="ran"
                      id="uncontrolled-tab-example"
                      className="mb-3"
                    >
                      <Tab eventKey="site" title="Site Details">
                        <ProjectData id={siteUId} />
                      </Tab>
                      {/* <Tab eventKey="map" title="MAP View"> */}
                      <>
                        {/*Google map*/}
                        {/* <div
    id="map-container-google-1"
    className="z-depth-1-half map-container"
    style={{ height: 300 }}
  >
    <iframe
      src="https://maps.google.com/maps?q=manhatan&t=&z=13&ie=UTF8&iwloc=&output=embed"
      frameBorder={0}
      style={{ border: 0,height:300 }}
      allowFullScreen="true"
    />
  </div> */}
                        {/*Google Maps*/}
                      </>

                      {/* </Tab> */}
                      <Tab eventKey="ran" title="RAN Historical">
                        <RanHistorical id={siteUId} />
                      </Tab>
                    </Tabs>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col xl={4}>
              <Card>
                <Card.Header>
                  <Card.Title>MAP</Card.Title>
                </Card.Header>
                <Card.Body>
                  {/* <!-- Nav tabs --> */}
                  <div className="custom-tab-1">
                    <Tabs
                      defaultActiveKey="map"
                      id="uncontrolled-tab-example"
                      className="mb-3"
                    >
                      <Tab eventKey="map" title="MAP View">
                        <>
                          {/*Google map*/}
                          <div
                            id="map-container-google-1"
                            className="z-depth-1-half map-container"
                            style={{ height: 300 }}
                          >
                            <iframe
                              src="https://maps.google.com/maps?q=manhatan&t=&z=13&ie=UTF8&iwloc=&output=embed"
                              frameBorder={0}
                              style={{ border: 0, height: 300 }}
                              allowFullScreen="true"
                            />
                          </div>
                          {/*Google Maps*/}
                        </>
                      </Tab>
                    </Tabs>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      {/* <h4>{userDetails.rolename=="Administrator"?"Project Database":"Mop Dashboard"}</h4> */}

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content ">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Project detail
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">X</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-row">{modalData}</div>
              </form>
            </div>
            {/* <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          data-dismiss="modal"
        >
          Close
        </button>
        <button type="button" className="btn btn-primary">
          Save changes
        </button>
      </div> */}
          </div>
        </div>
      </div>

      <div className="row">
        {userDetails.rolename == "Administrator" ? (
          <>
            <div className="basic-dropdown px-3 my-3 pb-0">
              {/* <Dropdown drop='right' >
                  <Dropdown.Toggle className='btn btn-primary btn-xs ' >
                    Create Ticket
                  </Dropdown.Toggle>
                  <Dropdown.Menu  className='bg-primary'>

                  <Link className="text-white"  to={{
            pathname: "/PAG/createProject",
            rdfsItem: ""
        }}>Single</Link>
                  
                    <button  className="dropButton"onClick={()=> setAccordianState(!accordianState)}><Dropdown.Item href='#' className='btn btn-primary btn-xs '>Bulk</Dropdown.Item></button>
         
                  </Dropdown.Menu>
                </Dropdown> */}
            </div>

            <div
              className={
                accordianState
                  ? "col-12 card px-3 pt-3 pb-0 showAccordian"
                  : bRFDS
                  ? "col-12 card px-3 pt-3 pb-0 showAccordian"
                  : "col-12 card px-3 pt-3 pb-0 hideAccordian"
              }
              style={{
                display: accordianState ? "block" : bRFDS ? "block" : "none",
              }}
            >
              <form
                onSubmit={(e) => submitHandler(e, "runRFDS")}
                encType="multipart/form-data"
                className="mx-2"
              >
                <div className="input-group mb-3 fileSection">
                  <div>
                    <label class="custom-file-upload btn btn-outline-primary btn-xs col-md-12">
                      <input
                        type="file"
                        className="form-control btn btn-primary btn-xs"
                        id="inputGroupFile04"
                        aria-describedby="inputGroupFileAddon04"
                        aria-label="Upload"
                        name="file"
                        multiple
                        onChange={uploadCsvFile}
                      />
                      <i class="fa fa-cloud-upload"></i> Choose File
                    </label>
                    {/* <label>Remarks</label>
                <input type='text' className="form-control" value={remarks} onChange={(e)=>{setRemarks(e.target.value)}}></input> */}
                  </div>

                  <button
                    className="btn btn-outline-primary btn-xs col-xm-12"
                    type="submit"
                    id="inputGroupFileAddon04"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </>
        ) : (
          ""
        )}

        <div className="card col-12">
          {userDetails.rolename == "Administrator" ? (
            count.networkSiteStatus.length > 0 ? (
              <RanSiteTracker
                from={"network"}
                siteStatus={count.networkSiteStatus}
              />
            ) : (
              <p>Please wait a while project history is loading</p>
            )
          ) : (
            ""
          )}
        </div>

        <CustomeModal
          xlShow={modalState}
          setXlShow={setModalState}
          Body={SiteApprovalModal}
          uniqueId={uId}
          work={work}
        />

<div className='mui-t-h'
          style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}
        >
          <ThemeProvider theme={getMuiThemeCommon()}>
            <MUIDataTable
              data={rowData}
              columns={
                userDetails.rolename == "Administrator" ? columns : columnMop
              }
              options={customDataTableOptionObject(AddCustomButton,true,'', "","",false,"projectDataBase",networkDashbordSiteHistory)}
            />
          </ThemeProvider>
        </div>
        {/* <DataTableExtensions 
						
						columns={columns}
						data={rowData}
						exportHeaders={true}
						
						>
						<DataTable
              columns={columns}
              data={rowData}
							theme='solarized'
							defaultSortAsc={false}
							pagination
							highlightOnHover
						/>
						</DataTableExtensions> */}
      </div>
    </>
  );
};

export default NetworkDatabase;
