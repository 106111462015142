import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateSite } from '../../../../common/config'
import { addUserAction, popUpUpdate } from '../../../../store/actions/createTicketManageFields'

const SelectBox = (props) => {
    const dispatch=useDispatch()
    const [getVal,setVal]=useState('')
    const [old,setold]=useState('')
    const optionsArray= props.optionsList.map((item)=>{
        return <option value={item}>{item}</option>
    })
    if(props.oldData!=old){
        setVal(props.oldData)
        setold(props.oldData)
    }
    

    const handleValue=(e)=>{
        setVal(e.target.value)
    }
    const handleSubmit=(e)=>{
        let sendData={
            [props.keyName]:getVal,
            // "updateFor":props.extraKey
        }
        if(props.extraKey){
            sendData["updateFor"]=props.extraKey
        }
        if(props.sendUrl){
            
            dispatch(addUserAction(props.sendUrl+"/"+props.id,sendData))
        }
        // alert(sendData);
        else{
            console.log("lnhjsfavoubsdc =",sendData," c lndvcvwd  ",props.id)
            dispatch(addUserAction(updateSite+"/"+props.id,sendData))
        }
        
        dispatch(popUpUpdate({}))
        props.setCloser(true)
    }
    optionsArray.unshift(<option selected disabled value={""}>Select</option>)
  return (
    <div
    
    className='form-group col-12'>
        <label>{props.label}</label>
        <select
        value={getVal}
        className='form-control'
        onChange={handleValue}
        >
            {optionsArray}
        </select> 
        <button  className='badge badge-primary m-2' onClick={handleSubmit}>Submit</button>
    </div>
  )
}

export default SelectBox