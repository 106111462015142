import React, { useState } from "react";
import { ReactMultiEmailInput } from 'react-multi-email-input';
import 'react-multi-email-input/dist/style.css'
import { registerField } from "redux-form";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { addUserAction } from "../../../store/actions/createTicketManageFields";
import { mailSender } from "../../../common/config";
const Mystyles = {
    fontFamily: "sans-serif",
    width: "500px",
    border: "1px solid #eee",
    padding: "25px",
    margin: "20px"
  };
  
function Email() {
  const [emailList, setEmailList] = useState([])
  const dispatch =useDispatch()
  const {register,setValue,handleSubmit,getValues,watch,reset,formState:{errors}} =useForm();

  const onSubmit =async (data)=>{

    dispatch(addUserAction(mailSender,data))    
  }
  return (
    <>
        <div class="container">
      <div class="row">
        <div class="col align-self-center">
          
          {/* <!-- contact form --> */}
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* <!-- name --> */}
            {/* <!-- email --> */}
            <div class="form-group">
              <label for="email">Email address</label>
              <ReactMultiEmailInput class="form-control" style={{background:"transparent",border:"1px solid #3e454d"}}
                styles={Mystyles}
                emails={emailList}
                setEmails={setEmailList}

            />
            </div>
            

            {/* <!-- subject --> */}
            <div class="form-group">
              <label for="subject">Subject</label>
              <input
                type="text"
                name="subject"
                class="form-control"
                id="subject"
                placeholder="enter email subject"
                {
                    ...register("subject",{
                        required:"Please enter subject"
                    })
                }
              />
            </div>

            <div class="form-group">
              <label for="email_body">Message</label>
              <textarea
                class="form-control"
                id="email_body"
                rows="5"
                {
                    ...register("message",{
                        required:"Please enter message"
                    })
                }
              ></textarea>
            </div>

            <button type="submit" class="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
       
        {/* {emailList && <p id="result">{emailList.join(', ') || 'empty'}</p>} */}
    </>
  );
}

export default Email;