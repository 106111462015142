import React, { useEffect, useState } from "react";

import { Row, Col, Card, Button, Modal, Nav } from 'react-bootstrap'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Mobilepost from './Mobilepost';
import Mobilepre from './Mobilepre';
import Ndsite from './Ndsite';
import Nscripting from './Nscripting';
import Preexisting from './Preexisting';
import Rnd from './Rnd'
import Sowdetails from './Sowdetails';
import Technology from './Technology';
import Tttypes from './Tttypes';

import { Link } from "react-router-dom";
import ManageGroup from "./ManageGroup";
import { AccountReducerAction, SingleDropDownAction } from "../../../../store/actions/createTicketManageFields";
import { getAllDropDown, getAllDropDownList } from "../../../../common/config";
import { useDispatch, useSelector } from "react-redux";
import TTCategory from "./TTCategory";


// import { Row, Col, Card, Button, Dropdown, ButtonGroup } from 'react-bootstrap'

// import Sonnet from '../../components/Sonnet';

function Manage() {

  const [modalCentered, setModalCentered] = useState(false);
  const [currentSet, setcurrentSet] = useState("ND-RND CIQ Status");
  const [changeStart, setChangeStart] = useState({});
  const [datac, setdatac] = useState();
  
  

  const findvalue=(element,value)=>{
      if(element.dropDownName == value){
        console.log("the element =",element)
        return element
      }
  }


  const dispatch = useDispatch()
  let sow=[]
  let id = useSelector((state) => {
    let temp = state.CommonResponse.allDropDown
    if(state.CommonResponse.allDropDown  && state.CommonResponse.allDropDown.length>0){
      sow=temp
    }
    console.log("sow",sow)
    

    console.log("the updated state=", state)

    return{sow}
  })
  const findSelectedTabValue=(value)=>{
    // console.log("the sow is ====",id.sow)
    // console.log("the value is =",id.sow.filter(function (student,v=value) {
    //   console.log("the element =",student)
    //   return student.dropDownName == v}))
    console.log("the sow =",id.sow)

      setcurrentSet(value)
      let tempDropArry=id.sow
      for(let i=0;i<tempDropArry.length;i++){
        console.log("the ==",tempDropArry[i])
        console.log("the valide==",value)

              if(tempDropArry[i].dropDownName==value){
                console.log("the ==",tempDropArry[i])
                setChangeStart(tempDropArry[i])
                dispatch(SingleDropDownAction([]))
                dispatch(AccountReducerAction(getAllDropDown+"/"+tempDropArry[i].dropDownName))
              }

      }

    //   let uId =id.sow.reduce(findvalue,value)
    // setChangeStart(id[0])
    
    
    console.log("the the active tab is =",changeStart)
  }
  console.log(id,"ididid")
  useEffect(() => {
    dispatch(AccountReducerAction(getAllDropDownList))

    //   getAppData();
    //setData(document.querySelectorAll(".custom-checkbox"));
  },[]);

  return (
    <Row>

      <Col xl={12}>
        <Card>

          <Card.Body>
            {/* <!-- Nav tabs --> */}


            <div className='default-tab mt-4 '>
              <Tabs
                defaultActiveKey="ND-RND CIQ Status"
                transition={false}
                onSelect={(e) => {
                  console.log("the e is =",e)
                  findSelectedTabValue(e)
                }
                
              }
                id="noanim-tab-example"
                className="mb-3"


              >

                <Tab eventKey="ND-RND CIQ Status" title="ND-RND CIQ Status" style={{ fontSize: "10px" }} >

                  <hr></hr>
                    {changeStart && currentSet=="ND-RND CIQ Status"?
                      <Rnd change={changeStart}/>
                      :""
                    }
                </Tab>
                <Tab eventKey="ND-Site Type" title="ND-Site Type" style={{ fontSize: "10px" }} >
                    {changeStart && currentSet=="ND-Site Type"?
                      <Ndsite change={changeStart}/>
                      :""
                    }
                </Tab>
                <Tab eventKey="ND-Scripting Status" title="ND-Scripting Status" style={{ fontSize: "10px" }} >
                    {changeStart && currentSet=="ND-Scripting Status"?
                      <Nscripting change={changeStart}/>
                      :""
                    }
                </Tab>
                <Tab eventKey="TT type" title="TT- Types" style={{ fontSize: "10px" }} 
                  //   onClick={()=>{
                  //     console.log("vskjfnjdl")
                  //    let filteredData= id.sow.filter(dropdown=>dropdown.dropDownName=="TT type")
                  //    console.log("the lod stsadad=",filteredData)
                  //    setChangeStart(filteredData)
                  // }
                // }
                >
                  {/* {
                    changeStart?<Tttypes dropdown={changeStart}/>
                    :""
                  } */}
                  {changeStart  && currentSet=="TT type"?
                      <Tttypes change={changeStart}/>
                      :""
                    }
                  
                </Tab>

                <Tab eventKey="TT Category" title="TT- Category" style={{ fontSize: "10px" }} >
                  {changeStart  && currentSet=="TT Category"?
                      <TTCategory change={changeStart}/>
                      :""
                    }
                  
                </Tab>
                <Tab eventKey="SOW Details" title="SOW Details" style={{ fontSize: "10px" }} >
                {/* <Tab eventKey="Group" title="Group Master" style={{ fontSize: "10px" }} > */}
                    {changeStart && currentSet=="SOW Details"?
                      <Sowdetails change={changeStart}/>
                      :""
                    }
                </Tab>
                <Tab eventKey="COP Pre" title="Mobile App COP Pre" style={{ fontSize: "10px" }} >
                    {changeStart && currentSet=="COP Pre"?
                      <Mobilepre change={changeStart}/>
                      :""
                    }
                </Tab>
                <Tab eventKey="COP Post" title="Mobile App COP Post" style={{ fontSize: "10px" }} >
                    {changeStart && currentSet=="COP Post"?
                      <Mobilepost change={changeStart}/>
                      :""
                    }
                </Tab>
                <Tab eventKey="Technology Master" title="Technology Master" style={{ fontSize: "10px" }} >
                  {currentSet=="Technology Master"?
                  <Technology />
                  :""}
                </Tab>
                <Tab eventKey="Pre-Existing TM" title="Pre-Existing TM" style={{ fontSize: "10px" }} >
                    {changeStart  && currentSet=="Pre-Existing TM"?
                      <Preexisting change={changeStart}/>
                      :""
                    }
                  
                </Tab>
                <Tab eventKey="Group" title="Group Master" style={{ fontSize: "10px" }} >
                    {changeStart && currentSet=="Group"?
                      <ManageGroup change={changeStart}/>
                      :""
                    }
                </Tab>
              </Tabs>
            </div>
          </Card.Body>
        </Card>
      </Col>

    </Row>

  );
}

export default Manage;