import React from "react";
import { MultiSelect } from "react-multi-select-component";
import { AccountReducerAction, FetchSingleRecordAction, TierListReducerAction, addUserAction, popUpUpdate } from "../../../store/actions/createTicketManageFields";
import { OneSiteDatabase, OneSiteDatabaseProjectCode, OneSiteDatabaseProjectId, ixModule, managEngineer, ranDashboard, singleProjectApi, siteDblistSiteId } from "../../../common/config";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import DatePicker from 'react-datepicker';
import { columnsArray, commonzone, fun_time_zonechanger, moment_common_full_date_format } from "../../../common/variables";
import AdvancedCommonCrew from "../Forms/Ran Form/advancedcommoncrew";
import { useHistory, useLocation } from "react-router-dom";
const EditIXModule = () => {
  const styles = {
    width: "400px",

  };


  let allProjects = []
  let newTtempArray = []
  const [closer, setCloser] = useState(false)
  let projectIdArray = []
  let dispatch = useDispatch()
  const location = useLocation();
  const fullUrl = location.pathname.split("/")
  const fullUrlid = fullUrl.pop()
  const formype = fullUrl.pop()
  console.log(location, location.pathname, "location,location.pathname");
  let [showReason, setShowReason] = useState(false)
  let [singleSitestate, setsingleSitestate] = useState({});
  let [SelProjectId, setSelProjectId] = useState({});
  let [SelProjectCode, setSelProjectCode] = useState([]);
  let [selectProjectId, setProjectId] = useState([])
  let [Market, setMarket] = useState("")
  let [siteId, setSiteId] = useState("")
  let [ctrCount, setCtrCount] = useState(1);
  let [startDateError, setStartDateError] = useState(false)
  const [siteFile, uploadedFile] = useState([]);
  const [setdate, setStartDate] = useState("");
  const [EngineerOpen, setEngineerOpen] = useState([]);
  let [selectProjectCode, setProjectCode] = useState([])
  let history = useHistory()
  let preOptions = ["Yes", "No", "NA"]
  let preOptionswithPending = ["Yes", "No", "Pending", "NA"]

  let jsxpreOptions = preOptions.map((itm) => {
    return <option value={itm}>{itm}</option>
  })
  let jsxpreOptionsPending = preOptionswithPending.map((itm) => {
    return <option value={itm}>{itm}</option>
  })
  let siteArray = [];
  let siteInfo = []
  let engineerArray = [];
  const { register, getValue, setValue, handleSubmit, setError, formState: { errors } } = useForm()
  let options = useSelector((state) => {
    siteInfo = state.OneCommonResponse.singleIXSite;
    console.log("th e stacdsfcsd=", siteInfo)
    // console.log( siteInfo, siteInfo.length != 0, "ctrCount")
    if (ctrCount == 1 && siteInfo && siteInfo.length != 0) {
      console.log("the single site is =", siteInfo)
      setValue("postActivityStatusSiteLevel", siteInfo.postActivityStatusSiteLevel)
      setValue("SchedulerLoginIntegrationEngineer", siteInfo.SchedulerAssignedIntegrationEngineer)
      setValue("CVGET", siteInfo.CVGET)
      setValue("PreDCGK", siteInfo.PreDCGK)
      setValue("NFSDLoginSnap", siteInfo.NFSDLoginSnap)
      setValue("IPreCheckAI", siteInfo.IPreCheckAI)

      if (siteInfo.SchedulerStatus == "Canceled" || siteInfo.SchedulerStatus == "Rollback") {
        setShowReason(true)
      } else {
        setShowReason(false)
      }
      setsingleSitestate(siteInfo)
      setEngineerOpen(siteInfo.postActivityStatusSiteLevel)
      console.log("cbjhsdvchjsdbchjwvfcugw=", singleSitestate)
      siteInfo?.projectaUniqueId.map((oneSt) => {
        SelProjectCode.push(oneSt.projectCode)
        setSiteId(oneSt.siteId)
        setMarket(oneSt.market)
        selectProjectId.push(oneSt.projectId)
      })
      // siteInfo.pUniqueIdDetails.map((oneSt) => {
      //   SelProjectCode.push(oneSt.projectCode)
      //   setSiteId(oneSt.siteId)
      //   setMarket(oneSt.market)
      //   selectProjectId.push(oneSt.projectId)
      // })

      setCtrCount(2)
    }


    if (state.popUpState) {
      // console.log(state.popUpState.popstatus.status==201,"popUpStatestatus")
      if (state.popUpState.popstatus.status == 201 && closer) {
        setCloser(false)
        popUpUpdate({})
        // dispatch(AccountReducerAction(ixModule))
        history.push("/PAG/ix/dashboard")
      }
    }

    let tier = state.CommonResponse.listtier
    if (tier && tier.length != 0) {
      engineerArray = tier.map((item) => {
        return <option>{item.name}</option>;
      })
    }
    return { siteArray, engineerArray }
  })

  const findDSiteId = (id) => {
    console.log(id, "findDSiteId", allProjects)
    if (id != undefined) {
      //   setcustomesite(id)
      //   setProjectId([])
      setProjectCode([])
      setSelProjectCode([])
      //   setTech([])
      findDProjectId(id, 1)

      // if(stateonce){
      //   setstateonce(false)
      //   dispatch(clearProjectId())
      // }

      setValue("siteId", id)
      let abc =
        setValue("siteUniqueId", allProjects.filter((item) => {
          console.log("the cldfvldfxsdcdsfvbfdgbxv=", item)
          if (item.siteId == id) {
            return (item)
          }
        })[0].uniqueSiteId
        )
      if (allProjects.length > 0)
        console.log("the respasfsdvgfdsbdtrh =", ...allProjects.filter((item) => item.siteId == id))
      let idObject = allProjects.filter((item) => item.siteId == id)
      if (idObject.length > 0) {
        // setond(true)
        dispatch(FetchSingleRecordAction(OneSiteDatabaseProjectCode, allProjects.filter((item) => item.siteId == id)[0].uniqueSiteId))
      }
    }
    else {
    }
  }

  const onSubmit = async (data) => {
    delete data["ixCrewId"]
    delete data["cxCrewId"]
    if (data["SchedulerNote"] == undefined) {
      delete data["SchedulerNote"]
    }
    if (formype == "ILogout") {
      data["ILogoutActivityCompleteDate"] = fun_time_zonechanger(commonzone, setdate).format(moment_common_full_date_format)
    }

    const form = new FormData();
    console.log(data, siteFile, siteFile, "datadatadatadata")
    let dataKeys = Object.keys(data)

    dataKeys.map((itti) => {
      form.append(itti, data[itti]);
    })

    if (formype == "FLogin" && siteFile.length > 0) {
      form.append("FLoginNFSDLoginSnapFile", siteFile[0]);
      // data["ILogoutActivityCompleteDate"]=fun_time_zonechanger(commonzone, setdate).format(moment_common_full_date_format)
    }

    if (formype == "FLogout" && siteFile.length > 0) {
      form.append("FLogoutNFSDLogoutSnapFile", siteFile[0]);
    }

    if (formype == "ILogin" && siteFile.length > 0) {
      form.append("ILoginPreLogsUploadFile", siteFile[0]);
    }

    if (formype == "ILogout" && siteFile.length > 0) {
      form.append("ILogoutPostLogsUploadFile", siteFile[0]);
    }

    form.append("formype", formype)



    // 
    // data["updateType"]=formype
    console.log("the data iss =", data)
    console.log("the form iss =", form)

    // form.append("uploadFile", siteFile);
    dispatch(addUserAction(ixModule + "/" + fullUrlid, form))
    setCloser(true)
  }

  const uploadCsvFile = (e) => {
    let files = []

    console.log(e.target.files[0], "ON_CHANGE_FILES:", e.target.files.length);

    for (let i = 0; i < e.target.files.length; i++) {
      files.push(e.target.files[i])
    }
    console.log(...files)
    uploadedFile(files)

  };
  const FetechAllDetail = (id) => {
    // let test = projectIdArray.filter((item) => item.uniqueid == id)[0]
    // console.log(test, "test")
    // setPId(id)
    // setppProjctId(id)
    setProjectId(id)
    let selectedId = id.map((item) => {
      return item.value
    })
    // dispatch(FetchSingleRecordAction(ranDashboard, selectedId));
    dispatch(FetchSingleRecordAction(OneSiteDatabase, selectedId.join(",")))
    // setCtrCount(1)
    setValue("projectUniqueId", selectedId.join(","))
    // /console.log("FetechAllDetail", id)
  }
  console.log("the col =", columnsArray("S.no	Site ID	Project Type	Project ID	RTT Actual	DIY Vendor	Integrator Name	GC	Monitoring start Date	Time Monitoring Start (CST TZ)	0 Hour Status	0 Hr Rprt Sent	24 Hour Status	24 Hr Rprt Sent	48 Hour Status	48 Hr Rprt Sent	72 Hour Status	72 Hr Rprt Sent	Final Status	Integration Alarm Free report	End (CST TZ)	Comments	Unlock Date by RF"))
  const findDProjectId = (id, def = 0, pcode = []) => {

    // setCountRender(1)
    // console.log("projectCodeArray", id, projectCodeArray)
    // setppProjectCode(id)

    // setppProjctId('')
    let selectedIds = []
    let nselectedIds = ""
    if (def == 0) {
      setProjectCode(id)
      selectedIds = id.map((item) => {
        return item.value
      })
      nselectedIds = selectedIds.join(",")
    } else {
      let testpcode = []

      // testpcode=projectCodeArray.map((ittm)=>{
      //   if(pcode.indexOf(ittm.label)!=-1){
      //     return {
      //       label:ittm.label,
      //       value:ittm.value
      //     }
      //   }
      // })
      // setProjectCode(testpcode)
      nselectedIds = id
    }
    // console.log(OneSiteDatabase + id, id, projectCodeArray, "projectCodeArray")

    setSelProjectCode(nselectedIds.split(","))
    setValue("projectCode", nselectedIds)
    setValue("projectcodeUniqueId", selectedIds.join(","))
    dispatch(FetchSingleRecordAction(OneSiteDatabaseProjectId, nselectedIds))



  }

  console.log(errors, "fullerrorsallShower")

  useEffect(() => {
    dispatch(AccountReducerAction(siteDblistSiteId))
    dispatch(TierListReducerAction(managEngineer))
    dispatch(FetchSingleRecordAction(ixModule, fullUrlid))
  }, [])
  return (
    <fregment className="m-6">

      <form className="form-row" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group col-md-3">
          <label for="exampleFormControlSelect1">
            Scheduled Date
          </label>
          <input className='form-control'
            style={{ height: "32px" }}
            value={singleSitestate.scheduleDate}
            disabled
          />
        </div>
        <div className="form-group col-md-3">
          <label for="exampleFormControlSelect1">
            Site Id
          </label>
          <input className='form-control'
            style={{ height: "32px" }}
            value={siteId}
            disabled
          />
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="inputEmail4">Project Code</label>

          <input className='form-control'
            style={{ height: "32px" }}
            value={SelProjectCode.join(", ")}
            disabled
          />
        </div>
        <div className="form-group col-md-3 ">
          <label >Project Id</label>
          <input className='form-control'
            style={{ height: "32px" }}
            value={selectProjectId.join(", ")}
            disabled
          />
          {/* {errors.projectUniqueId && (
                        <p className="error">
                          {errors.projectUniqueId.message}
                        </p>
                      )} */}
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="inputEmail4">Account</label>

          <input className='form-control'
            style={{ height: "32px" }}
            value={singleSitestate.ndResult ? singleSitestate.ndResult.account : ""}
            disabled
          />
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="inputEmail4">Market</label>

          <input className='form-control'
            style={{ height: "32px" }}
            value={Market}
            disabled
          />
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="inputEmail4">Activity Date</label>

          <input className='form-control'
            style={{ height: "32px" }}
            value={singleSitestate.scheduleDate}
            disabled
          />
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="inputEmail4">MOP</label>
          <input className='form-control'
            style={{ height: "32px" }}
            value={singleSitestate.dayNightMop}
            disabled
          />

        </div>
        <div className="form-group col-md-3">
          <label htmlFor="inputEmail4">SOW</label>

          <input className='form-control'
            style={{ height: "32px" }}
            value={singleSitestate.sowList ? singleSitestate.sowList.values.join(", ") : ""}
            disabled
          />


        </div>
        <div className="form-group col-md-3">
          <label htmlFor="inputEmail4">Service Affected</label>

          <input className='form-control'
            style={{ height: "32px" }}
            value={singleSitestate.serviceAffected}
            disabled
          />

        </div>
        <div className="form-group col-md-3">
          <label htmlFor="inputEmail4">Pre HW Configuration</label>
          <input className='form-control'
            style={{ height: "32px" }}
            value={singleSitestate.preHWLayerConfiguration ? singleSitestate.preHWLayerConfiguration.values.join() : singleSitestate.techtake ? singleSitestate.techtake.join(", ") : ""}
            disabled
          />

        </div>
        <div className="form-group col-md-3">
          <label htmlFor="inputEmail4">Post HW Configuration</label>
          <input className='form-control'
            style={{ height: "32px" }}
            value={singleSitestate.postHWLayerConfiguration ? singleSitestate.postHWLayerConfiguration.values.join() : ""}
            disabled
          />

        </div>
        {/* <div className="form-group col-md-3">
          <label htmlFor="inputEmail4">Integration/RAN Assigned Engineer</label>

          <input
            style={{ height: "32px" }}
            type="text"
            {...register("IntegrationRANAssignedEngineer", { required: "Reason is required" })}
            className="form-control"
          />
        </div> */}

        {
          singleSitestate.SchedulerNote ?
            <div className="form-group col-md-3">
              <label htmlFor="inputEmail4">Scheduler Note</label>
              <input className='form-control'
                style={{ height: "32px" }}
                value={singleSitestate.SchedulerNote}
                disabled
              />
            </div>
            :
            <div className="form-group col-md-3">
              <label htmlFor="inputEmail4">Scheduler Note</label>
              <input className='form-control'
                style={{ height: "32px" }}

                {
                ...register("SchedulerNote")
                }
              // disabled
              />
            </div>
        }
        {/* <div className="form-group col-md-3">
          <label htmlFor="inputEmail4">Scheduler Note</label>
          <input className='form-control'
            style={{ height: "32px" }}
            value={singleSitestate.SchedulerNote}
            disabled
          />
        </div> */}
        <div className="form-group col-md-3">
          <label for="exampleFormControlSelect1">
            {" "}
            IX/RAN Assigned Engineer
          </label>
          <input className='form-control'
            style={{ height: "32px" }}
            value={singleSitestate.SchedulerAssignedIntegrationEngineer || singleSitestate.assignedEngineer}
            disabled
          />

        </div>

        {/* <div className="form-group col-md-3">
          <label htmlFor="inputEmail4">Status</label>

          <input
            style={{ height: "32px" }}
            type="text"
            className="form-control"
            {...register("SchedulerStatus", { required: "Reason is required" })}
          />
        </div> */}

        <div className="form-group col-md-3">
          <label htmlFor="inputEmail4">Status</label>

          <select
            style={{ height: "32px" }}
            type="text"

            // onChange={(e)=>{
            //   console.log("thehdewfwef=",e.target.value)
            //   e.target.value=="Canceled"||e.target.value=="Rollback"?setShowReason(true):setShowReason(false)}}
            {...register("postActivityStatusSiteLevel", {
              onChange: (e) => {
                console.log("watch", e.target.value);
                e.target.value == "Cancelled" || e.target.value == "Rollback" ? setShowReason(true) : setShowReason(false)
              },
              required: "Reason is required"
            })}
            className="custom-select-style form-control"
          >
            <option selected disabled value="">Select...</option>
            <option value="Scheduled">Scheduled</option>
            <option value="Ongoing">Ongoing</option>
            <option value="Rollback">Rollback</option>
            <option value="On-Hold">On-Hold</option>
            <option value="Cancelled">Cancelled</option>
            <option value="Completed">Completed</option>
            {/* <option value="Completed with No Alarm">Completed with No Alarm</option>
            <option value="Completed with NSI Alarm">Completed with NSI Alarm</option>
            <option value="Completed with SI Alarm">Completed with SI Alarm</option> */}
          </select>
          {errors.postActivityStatusSiteLevel && (
            <p className="error">
              {errors.postActivityStatusSiteLevel.message}
            </p>
          )}
          {/* </div> */}

          {/* <div className="form-group col-md-3">
          <label htmlFor="inputEmail4">Status</label>
          <input className='form-control'
            style={{ height: "32px" }}
            value={singleSitestate.SchedulerStatus  ||singleSitestate.postActivityStatusSiteLevel}
            disabled
          /> */}
        </div>
        {showReason ?
          <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
            <label htmlFor="inputEmail4">Cancel/Rollback Reason</label>
            <input className='form-control'
              style={{ height: "32px" }}
              value={singleSitestate.SchedulerCancelRollbackReason}
              disabled
            />

          </div> : ""}
        {singleSitestate.ixCrewDetails ? singleSitestate.ixCrewDetails.uniqueid ? <AdvancedCommonCrew label={"FE :"} errors={errors} register={register} setValue={setValue} visible={"IX"} crewType={"IXCrew"} currentValueName={singleSitestate.ixCrewDetails.uniqueid} currentValueId={singleSitestate.ixCrewDetails.uniqueid} disenb={true} /> : "" : ""}
        {/* <AdvancedCommonCrew label={"FE :"} errors={errors} register={register} setValue={setValue} visible={"IX"} crewType={"IXCrew"} currentValueName={''} currentValueId={""} /> */}
        <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
          <label htmlFor="inputEmail4">WO/CR Number</label>
          <input className='form-control'
            style={{ height: "32px" }}
            value={singleSitestate.wonwrk || singleSitestate.crNumber}
            disabled
          />
        </div>
        {/* {formype == "FLogin" && singleSitestate.FEnStatus == 0 ?
          <>
            <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">EIM Login</label>

              <input
                style={{ height: "32px" }}
                // type="text"
                placeholder="Enter EIM Login"
                {...register("EIMLogin", {
                  required: "EIM Login is required"
                })}
                className="form-control"
              />

            </div> 
            <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">NFSD Login</label>

              <input
                placeholder="Enter NFSD Login"
                style={{ height: "32px" }}
                // type="text"
                {...register("NFSDLogin", { required: "NFSD Login is required" })}
                className="form-control"
              />

            </div> 
            <div className="form-group col-md-3">
              <label htmlFor="inputEmail4">NFSD Login Snap</label>

              <select
                style={{ height: "32px" }}
                type="text"
                {...register("NFSDLoginSnap", {

                  required: "Reason is required"
                })}
                className="form-control"
              >
                <option selected disabled value="">Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="Awaited">Awaited</option>
                <option value="NA">NA</option>
              </select>
            </div> 

             <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">Reason For Login NFSD Snap NA</label>

              <input
                placeholder="Reason For Login NFSD Snap NA"
                style={{ height: "32px" }}
                
                {...register("ReasonNFSDLoginNA", { required: "NFSD Login is required" })}
                className="form-control"
              />

            </div> 

              </>:""}

         
            <div className="form-group col-md-3">
              <label htmlFor="inputEmail4">NFSD Login Snap</label>

              <div>
                Upload File:
                <label class="custom-file-upload btn btn-outline-primary btn-xs col-md-12">

                  <input
                    type="file"
                    className="form-control btn btn-primary "
                    id="inputGroupFile04"
                    aria-describedby="inputGroupFileAddon04"
                    aria-label="Upload"
                    name="file"
                    multiple
                    placeholder={'Select file'}
                    onChange={uploadCsvFile}
                    {...register("logInTimePreCheckDelivered")}
                  />
                  <i class="fa fa-cloud-upload"></i> Choose File
                </label>
                <label>{siteFile.length > 0 ? siteFile.length : "No"} File Selected</label>

                Select file
                {/* </input> */}
        {/* </div>
            </div>




            <div className="form-group col-md-3">
              <label htmlFor="inputEmail4">View</label>

              <button type="button" className="btn btn-primary">EOS</button>
            </div> */}




        {/* {formype == "FLogout" && singleSitestate.FEnStatus == 1 ?
          <>
             <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">EIM Login</label>
              <input className='form-control'
                style={{ height: "32px" }}
                value={singleSitestate.EIMLogin}
                disabled
              />


            </div>
            <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">NFSD Login</label>
              <input className='form-control'
                style={{ height: "32px" }}
                value={singleSitestate.NFSDLogin}
                disabled
              />


            </div> 
            <div className="form-group col-md-3">
              <label htmlFor="inputEmail4">NFSD Login Snap</label>
              <input className='form-control'
                style={{ height: "32px" }}
                value={singleSitestate.NFSDLoginSnap}
                disabled
              />
            </div> 
             <div className="form-group col-md-3">
              <label htmlFor="inputEmail4">NFSD Login Snap File</label>

              <div>
                Upload File:
                <label class="custom-file-upload btn btn-outline-primary btn-xs col-md-12">

                  <input
                    type="file"
                    className="form-control btn btn-primary "
                    id="inputGroupFile04"
                    aria-describedby="inputGroupFileAddon04"
                    aria-label="Upload"
                    name="file"
                    multiple
                    placeholder={'Select file'}
                    onChange={uploadCsvFile}
                    ></input>
                  <i class="fa fa-cloud-upload"></i> Choose File
                </label>
                <label>{siteFile.length > 0 ? siteFile.length : "No"} File Selected</label>

              </div>
            </div>  

            <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">FE Notes</label>
              <input
                placeholder="Enter NFSD Login"
                style={{ height: "32px" }}
                // type="text"
                {...register("FENotes")}
                className="form-control"
              />

            </div>

            <div className="form-group col-md-3">
              <label htmlFor="inputEmail4">View</label>

              <button type="button" className="btn btn-primary">EOS</button>
            </div>

            <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">EOS Status</label>
              <input
                placeholder="Enter NFSD Login"
                style={{ height: "32px" }}
                // type="text"
                {...register("EOSStatus")}
                className="form-control"
              />

            </div>


            <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">Logout Ticket Number</label>
              <input
                placeholder="Enter Logout Ticket Number"
                style={{ height: "32px" }}
                // type="text"
                {...register("LogoutTicketNumber")}
                className="form-control"
              />

            </div>


            <div className="form-group col-md-3">
              <label htmlFor="inputEmail4">NFSD Logout Snap</label>

              <select
                style={{ height: "32px" }}
                type="text"
                {...register("NFSDLogoutSnap", {

                  required: "Reason is required"
                })}
                className="form-control"
              >
                <option selected disabled value="">Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="Awaited">Awaited</option>
                <option value="NA">NA</option>
              </select>
            </div>


            <div className="form-group col-md-3">
              <label htmlFor="inputEmail4">NFSD Logout Snap Upload</label>

              <div>
                Upload File:
                <label class="custom-file-upload btn btn-outline-primary btn-xs col-md-12">

                  <input
                    type="file"
                    className="form-control btn btn-primary "
                    id="inputGroupFile04"
                    aria-describedby="inputGroupFileAddon04"
                    aria-label="Upload"
                    name="file"
                    multiple
                    placeholder={'Select file'}
                    onChange={uploadCsvFile}
                  //   {...register("logInTimePreCheckDelivered")}
                  />
                  <i class="fa fa-cloud-upload"></i> Choose File
                </label>
                <label>{siteFile.length > 0 ? siteFile.length : "No"} File Selected</label>

                {/* Select file */}
        {/* </input> */}
        {/* </div>
            </div>


            <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">Reason For Logout NFSD Snap NA</label>
              <input
                placeholder="Enter NFSD Logout"
                style={{ height: "32px" }}
                // type="text"
                {...register("ReasonNFSDLogoutNA")}
                className="form-control"
              />

            </div> */}
        {/* <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">NFSD Logout Snap</label>
              <input
                placeholder="Enter NFSD Login"
                style={{ height: "32px" }}
                // type="text"
                {...register("FENotes")}
                className="form-control"
              />

            </div> */}
        {/* <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">BB5216 Asset Tag #</label>
              <input
                placeholder="Enter BB5216 Asset Tag #"
                style={{ height: "32px" }}
                // type="text"
                {...register("BBftosAssetTag")}
                className="form-control"
              />

            </div>

            <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">BB5216 Serial #</label>
              <input
                placeholder="Enter BB5216 Serial #"
                style={{ height: "32px" }}
                // type="text"
                {...register("BBftosSerial")}
                className="form-control"
              />

            </div>

            <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">XMU Asset Tag #</label>
              <input
                placeholder="Enter XMU Asset Tag #"
                style={{ height: "32px" }}
                // type="text"
                {...register("XMUAssetTag")}
                className="form-control"
              />

            </div>


            <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">XMU Serial #</label>
              <input
                placeholder="Enter XMU Serial"
                style={{ height: "32px" }}
                // type="text"
                {...register("XMUSerial")}
                className="form-control"
              />

            </div>


            <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">DU Type Removed</label>
              <input
                placeholder="Enter DU Type Removed"
                style={{ height: "32px" }}
                // type="text"
                {...register("DUTypeRemoved")}
                className="form-control"
              />

            </div>

            <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">Qty Removed</label>
              <input
                placeholder="Enter Qty Removed"
                style={{ height: "32px" }}
                // type="text"
                {...register("QtyRemoved")}
                className="form-control"
              />

            </div>


            <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">DUS Asset Tag #</label>
              <input
                placeholder="Enter DUS Asset Tag #"
                style={{ height: "32px" }}
                // type="text"
                {...register("DUSAssetTag")}
                className="form-control"
              />

            </div>


            <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">DUS Serial #</label>
              <input
                placeholder="Enter DUS Serial #"
                style={{ height: "32px" }}
                // type="text"
                {...register("DUSSerial")}
                className="form-control"
              />

            </div>


            <div className="form-group col-md-3">
              <label htmlFor="inputEmail4">Site Type</label>

              <select
                style={{ height: "32px" }}
                type="text"
                {...register("SiteType", {

                  required: "Reason is required"
                })}
                className="form-control"
              >
                <option selected disabled value="">Select</option>
                <option value="Indoor">Indoor</option>
                <option value="Outdoor">Outdoor</option>
                <option value="Other">Other</option>
              </select>
            </div>


            <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">Total Miles Driven</label>
              <input
                placeholder="Enter Total Miles Driven"
                style={{ height: "32px" }}
                // type="text"
                {...register("TotalMilesDriven")}
                className="form-control"
              />

            </div>


 */}

        {/* // </> : ""} */}



        {(formype == "FLogout" || formype == "FLogin") && singleSitestate.FEnStatus == 2 ?
          <>
            {/* <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">EIM Login</label>
              <input className='form-control'
                style={{ height: "32px" }}
                value={singleSitestate.EIMLogin}
                disabled
              />
            </div> */}
            {/* <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">NFSD Login</label>
              <input className='form-control'
                style={{ height: "32px" }}
                value={singleSitestate.NFSDLogin}
                disabled
              />


            </div> */}


            {/* <div className="form-group col-md-3">
              <label htmlFor="inputEmail4">NFSD Login Snap</label>
              <input className='form-control'
                style={{ height: "32px" }}
                value={singleSitestate.NFSDLoginSnap}
                disabled
              />


            </div> */}
          </>
          : ""}

        {(formype == "FLogout" || formype == "FLogin") && singleSitestate.FEnStatus == 2 ?
          <>
            <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">FE Notes</label>
              <input className='form-control'
                style={{ height: "32px" }}
                value={singleSitestate.FENotes}
                disabled
              />

            </div>

            <div className="form-group col-md-3">
              <label htmlFor="inputEmail4">View</label>

              <button type="button" className="btn btn-primary">EOS</button>
            </div>

            <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">EOS Status</label>

              <input className='form-control'
                style={{ height: "32px" }}
                value={singleSitestate.EOSStatus}
                disabled
              />


            </div>


            <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">Logout Ticket Number</label>

              <input className='form-control'
                style={{ height: "32px" }}
                value={singleSitestate.LogoutTicketNumber}
                disabled
              />


            </div>


            <div className="form-group col-md-3">
              <label htmlFor="inputEmail4">NFSD Logout Snap</label>

              <input className='form-control'
                style={{ height: "32px" }}
                value={singleSitestate.NFSDLogoutSnap}
                disabled
              />

            </div>


            {/* <div className="form-group col-md-3">
              <label htmlFor="inputEmail4">NFSD Logout Snap Upload</label>

              <div>
                Upload File:
                <label class="custom-file-upload btn btn-outline-primary btn-xs col-md-12">

                  <input
                    type="file"
                    className="form-control btn btn-primary "
                    id="inputGroupFile04"
                    aria-describedby="inputGroupFileAddon04"
                    aria-label="Upload"
                    name="file"
                    multiple
                    placeholder={'Select file'}
                    onChange={uploadCsvFile}
                  />
                  <i class="fa fa-cloud-upload"></i> Choose File
                </label>
                <label>{siteFile.length > 0 ? siteFile.length : "No"} File Selected</label>

              </div>
            </div> */}


            <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">Reason For Logout NFSD Snap NA</label>


              <input className='form-control'
                style={{ height: "32px" }}
                value={singleSitestate.ReasonNFSDLogoutNA}
                disabled
              />


            </div>
            {/* <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">NFSD Logout Snap</label>
              <input
                placeholder="Enter NFSD Login"
                style={{ height: "32px" }}
                // type="text"
                {...register("FENotes")}
                className="form-control"
              />

            </div> */}
            {/* <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">BB5216 Asset Tag #</label>


              <input className='form-control'
                style={{ height: "32px" }}
                value={singleSitestate.BBftosAssetTag}
                disabled
              />


            </div>

            <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">BB5216 Serial #</label>


              <input className='form-control'
                style={{ height: "32px" }}
                value={singleSitestate.BBftosSerial}
                disabled
              />


            </div>

            <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">XMU Asset Tag #</label>

              <input className='form-control'
                style={{ height: "32px" }}
                value={singleSitestate.XMUAssetTag}
                disabled
              />

            </div>


            <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">XMU Serial #</label>

              <input className='form-control'
                style={{ height: "32px" }}
                value={singleSitestate.XMUSerial}
                disabled
              />

            </div>


            <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">DU Type Removed</label>

              <input className='form-control'
                style={{ height: "32px" }}
                value={singleSitestate.DUTypeRemoved}
                disabled
              />

            </div>

            <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">Qty Removed</label>

              <input className='form-control'
                style={{ height: "32px" }}
                value={singleSitestate.QtyRemoved}
                disabled
              />

            </div>


            <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">DUS Asset Tag #</label>

              <input className='form-control'
                style={{ height: "32px" }}
                value={singleSitestate.DUSAssetTag}
                disabled
              />

            </div>


            <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">DUS Serial #</label>

              <input className='form-control'
                style={{ height: "32px" }}
                value={singleSitestate.DUSSerial}
                disabled
              />

            </div>


            <div className="form-group col-md-3">
              <label htmlFor="inputEmail4">Site Type</label>

              <input className='form-control'
                style={{ height: "32px" }}
                value={singleSitestate.SiteType}
                disabled
              />

            </div>


            <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">Total Miles Driven</label>
              <input className='form-control'
                style={{ height: "32px" }}
                value={singleSitestate.TotalMilesDriven}
                disabled
              />

            </div>


 */}

          </> : ""}


        {formype == "ILogin" ?
          <>
            <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">Script Files</label>

              <input
                style={{ height: "32px" }}
                // type="text"
                placeholder="Enter Script File"
                {...register("CVGET", {
                  // required: "CVGET is required"
                })}
                className="form-control"
              />

            </div>

            <div className="form-group col-md-3">
              <label htmlFor="inputEmail4">Pre Dump</label>

              <select
                style={{ height: "32px" }}
                type="text"
                {...register("PreDCGK", {

                  // required: "Pre DCGK is required"
                })}
                className="form-control"
              >
                <option selected disabled value="">Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="NA">NA</option>
              </select>
            </div>

            <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">IX/RAN LOGIN Engineer</label>

              {/* <input
                  placeholder="IX/RAN LOGIN Engineer"
                  style={{ height: "32px" }}
                  // type="text"
                  {...register("SchedulerLoginIntegrationEngineer", { required: "NFSD Login is required" })}
                  className="form-control"
                /> */}

              <input style={{ height: "32px" }}
                type="text"
                {...register("SchedulerLoginIntegrationEngineer", {
                  // required: "IX/RAN LOGIN Eng is required"
                })}
                onBlur={(e) => { setEngineerOpen(e.target.value) }}
                list="engOpen"
                className="form-control"
                ID="inputAddress"
                defaultValue={EngineerOpen}
                placeholder="Select Engineer"
              />

              <datalist id="engOpen">
                {options.engineerArray}
              </datalist>``

            </div>



            <div className="form-group col-md-3">
              <label htmlFor="inputEmail4">Pre Check sent to Customer</label>

              <select
                style={{ height: "32px" }}
                type="text"
                {...register("NFSDLoginSnap", {

                  // required: "Reason is required"
                })}
                className="form-control"
              >
                <option selected disabled value="">Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="Pending">Pending</option>
                <option value="NA">NA</option>
              </select>
            </div>



            {/* <div className="form-group col-md-3">
              <label htmlFor="inputEmail4">Pre Logs Upload</label>

              <div>
                Upload File:
                <label class="custom-file-upload btn btn-outline-primary btn-xs col-md-12">

                  <input
                    type="file"
                    className="form-control btn btn-primary "
                    id="inputGroupFile04"
                    aria-describedby="inputGroupFileAddon04"
                    aria-label="Upload"
                    name="file"
                    multiple
                    placeholder={'Select file'}
                    onChange={uploadCsvFile}
                  //   {...register("logInTimePreCheckDelivered")}
                  />
                  <i class="fa fa-cloud-upload"></i> Choose File
                </label>
                <label>{siteFile.length > 0 ? siteFile.length : "No"} File Selected</label>

                {/* Select file */}
            {/* </input> */}
            {/* </div> 
            </div> */}


            <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">Pre Check Alarms/Issues</label>

              <input
                placeholder="Pre Check Alarms/Issues"
                style={{ height: "32px" }}
                // type="text"
                {...register("IPreCheckAI", {
                  // required: "Pre Check Alarms/Issues"
                })}
                className="form-control"
              />

            </div>
          </> : ""}


        {formype == "ILogout" ?
          <>


            <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">Script Files</label>

              <input className='form-control'
                style={{ height: "32px" }}
                value={singleSitestate.CVGET}
                disabled
              />


            </div>

            <div className="form-group col-md-3">
              <label htmlFor="inputEmail4">Pre Dump</label>

              <input className='form-control'
                style={{ height: "32px" }}
                value={singleSitestate.PreDCGK}
                disabled
              />


            </div>

            <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">IX/RAN LOGIN Engineer</label>

              <input className='form-control'
                style={{ height: "32px" }}
                value={singleSitestate.SchedulerLoginIntegrationEngineer}
                disabled
              />


            </div>

            <div className="form-group col-md-3">
              <label htmlFor="inputEmail4">Pre Check sent to Customer</label>

              <input className='form-control'
                style={{ height: "32px" }}
                value={singleSitestate.NFSDLoginSnap}
                disabled
              />


            </div>



            <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">Pre Check Alarms/Issues</label>
              <input className='form-control'
                style={{ height: "32px" }}
                value={singleSitestate.IPreCheckAI}
                disabled
              />

            </div>




            {/* ======================================================================================= */}


            <div className="form-group col-md-3">
              <label htmlFor="inputEmail4">GS</label>

              <select
                style={{ height: "32px" }}
                type="text"
                {...register("GS", {

                  required: "GS is required"
                })}
                className="form-control"
              >
                <option selected disabled value="">Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="NA">NA</option>
              </select>
              {errors.GS && (
                <p className="error">
                  {errors.GS.message}
                </p>
              )}
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="inputEmail4">Post Dump</label>

              <select
                style={{ height: "32px" }}
                type="text"
                {...register("PostDCGK", {

                  required: "Reason is required"
                })}
                className="form-control"
              >
                <option selected disabled value="">Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="NA">NA</option>
              </select>
              {errors.PostDCGK && (
                <p className="error">
                  {errors.PostDCGK.message}
                </p>
              )}
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="inputEmail4">IX Reports sent to Customer</label>

              <select
                style={{ height: "32px" }}
                type="text"
                {...register("IXReportssenttoCustomer", {

                  required: "Reason is required"
                })}
                className="form-control"
              >
                <option selected disabled value="">Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="Pending">Pending</option>
              </select>
              {errors.IXReportssenttoCustomer && (
                <p className="error">
                  {errors.IXReportssenttoCustomer.message}
                </p>
              )}
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="inputEmail4">Logs Uploaded</label>

              <select
                style={{ height: "32px" }}
                type="text"
                {...register("LogsUploaded", {

                  required: "Reason is required"
                })}
                className="form-control"
              >
                <option selected disabled value="">Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
                <option value="NA">NA</option>
              </select>

              {errors.LogsUploaded && (
                <p className="error">
                  {errors.LogsUploaded.message}
                </p>
              )}
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="inputEmail4">KPI Required</label>

              <select
                style={{ height: "32px" }}
                type="text"
                {...register("KPIRequired", {

                  required: "Reason is required"
                })}
                className="form-control"
              >
                <option selected disabled value="">Select</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              {errors.KPIRequired && (
                <p className="error">
                  {errors.KPIRequired.message}
                </p>
              )}
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="inputEmail4">Activity Complete Date</label>

              <DatePicker
                style={styles}
                className="form-control"
                selected={setdate}
                onChange={(e) => { setStartDate(e); setStartDateError(false) }}
                showTimeSelect
                popperPlacement="top"
                // excludeOutOfBoundsTimes
                timeFormat="HH:mm"
                timeIntervals={60}
                timeCaption="Time"
                dateFormat="MM/dd/yyyy hh:mm aa"
                minDate={new Date()}
              //   maxDate={addDays(new Date(), 31)}
              />
              <p className='error' style={{ display: startDateError ? "block" : "none" }}>Required Field</p>
            </div>


            <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">Activity Complete Time</label>

              <input
                placeholder="Activity Complete Time"
                style={{ height: "32px" }}
                // type="text"
                {...register("ActivityCompleteTimePST")}
                className="form-control"
              />
              {errors.ActivityCompleteTimePST && (
                <p className="error">
                  {errors.ActivityCompleteTimePST.message}
                </p>
              )}
            </div>

            <div className="form-group col-md-3">
              <label htmlFor="inputEmail4">Post Logs Upload</label>

              <div>
                Upload File:
                <label class="custom-file-upload btn btn-outline-primary btn-xs col-md-12">

                  <input
                    type="file"
                    className="form-control btn btn-primary "
                    id="inputGroupFile04"
                    aria-describedby="inputGroupFileAddon04"
                    aria-label="Upload"
                    name="file"
                    multiple
                    placeholder={'Select file'}
                    onChange={uploadCsvFile}
                  //   {...register("logInTimePreCheckDelivered")}
                  />
                  <i class="fa fa-cloud-upload"></i> Choose File
                </label>
                <label>{siteFile.length > 0 ? siteFile.length : "No"} File Selected</label>

                {/* Select file */}
                {/* </input> */}
              </div>
            </div>

            <div className="form-group col-md-3">
              <label for="exampleFormControlSelect1">

                IX/RAN LOGOUT Engg
              </label>
              <input style={{ height: "32px" }}
                type="text"
                {...register("SchedulerLogoutIntegrationEngineer", {
                  required: "IX/RAN LOGOUT Engg is required"
                })}
                onBlur={(e) => { setEngineerOpen(e.target.value) }}
                list="engOpen"
                className="form-control"
                ID="inputAddress"
                // defaultValue={EngineerOpen}
                placeholder="Select Engineer"
              />

              <datalist id="engOpen">
                {options.engineerArray}
              </datalist>

              {errors.SchedulerLogoutIntegrationEngineer && (
                <p className="error">{errors.SchedulerLogoutIntegrationEngineer.message}</p>
              )}
            </div>

            <div className="form-group col-md-3">
              <label htmlFor="inputEmail4">PSAP Status</label>

              <select
                style={{ height: "32px" }}
                type="text"
                {...register("PSAPStatus", {

                  required: "PSAP Status is required"
                })}
                className="form-control"
              >
                <option selected disabled value="">Select</option>
                <option value="Canceled">Canceled</option>
                <option value="Completed with No Alarm">Completed with No Alarm</option>
                <option value="Completed with NSI Alarm">Completed with NSI Alarm</option>
                <option value="Completed with SI Alarm">Completed with SI Alarm</option>
                <option value="Ongoing">Ongoing</option>
                <option value="Rollback">Rollback</option>
                <option value="Scheduled">Scheduled</option>
              </select>
              
              {errors.PSAPStatus && (
                <p className="error">{errors.PSAPStatus.message}</p>
              )}
            </div>

            <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">Post Check Alarms/Issues</label>

              <input
                placeholder="Post Check Alarms/Issues"
                style={{ height: "32px" }}
                // type="text"
                {...register("IPostCheckAI", { required: "Post Check Alarms/Issues is required" })}
                className="form-control"
              />
               {errors.IPostCheckAI && (
                <p className="error">{errors.IPostCheckAI.message}</p>
              )}
            </div>

            <div className="form-group col-md-3" style={{ display: true ? "block" : "none" }}>
              <label htmlFor="inputEmail4">Comments</label>

              <input
                placeholder="Comments"
                style={{ height: "32px" }}
                // type="text"
                {...register("ILogoutComments", { required: "Comments is required" })}
                className="form-control"
              />
              
              {errors.ILogoutComments && (
                <p className="error">{errors.ILogoutComments.message}</p>
              )}
            </div>

          </> : ""}



        <div className="d-flex justify-content-center mb-5" style={{ width: "100%" }}>
          {(((formype == "FLogout" || formype == "FLogin") && singleSitestate.FEnStatus != 2) || ((formype == "ILogin" || formype == "ILogout") && singleSitestate.InStatus != 2)) ? <>
            <button type="submit" className="btn btn-primary btn-xs py-2 px-4 mt-2" >
              Submit
            </button>
            <button className="btn btn-danger btn-xs py-2  px-4 mr-2 mt-2 mx-2" onClick={() => history.push("/PAG/ix/dashboard")}>Back</button>
          </> :

            <button className="btn btn-danger btn-xs py-2  px-4 mr-2 mt-2 mx-2" onClick={() => history.push("/PAG/ix/dashboard")}>Back</button>}
        </div>

      </form>
    </fregment>
  );
};

export default EditIXModule;
