import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { addAcccountReducerAction, addUserAction, popUpUpdate } from '../../../../store/actions/createTicketManageFields'
import DatePicker from 'react-datepicker';
import moment from "moment"
const SingleDateModal = (props) => {
  const dispatch =useDispatch()
  const [getDat,setDat]=useState("")
  const [oneTime,setoneTime]=useState(true)
  const [oldData,setoldData]=useState(true)

  console.log(getDat,"props.getOld")
  console.log(props.getOld + ":00",props.getOld!=oldData,new Date(props.getOld + ":00"),"props.getOld")
  if(props.getOld && props.getOld!=oldData){
    setoneTime(true)
    setoldData(props.getOld)
    setDat(new Date(props.getOld + ":00"))
  }
  if(props.getOld==""){
    setDat("")
  }
  
  const handleSubmit=()=>{
    console.log("jbvsugfouergo=",props)
    dispatch(addAcccountReducerAction(props.sendUrl+"/"+props.uid,{[props.dataKey] : moment(getDat).format("MM/DD/YYYY hh:mm")}))
    
    dispatch(popUpUpdate({}))
    props.setCloser(true)
    
  }
  console.log("jbvsugfouergo=",props)
  const styles = {
    width: "400px",

  };
  return (
   <div className='form-group'>
    <label>{props.label}</label>

    <DatePicker
                          style={styles}
                          className="form-control"
                          selected={getDat}
                          onChange={(e) => { setDat(e); }}
                          showTimeSelect
                          popperPlacement="top"
                          timeFormat="HH:mm"
                          // excludeOutOfBoundsTimes
                          // timeFormat=""
                          timeIntervals={60}
                          timeCaption="Time"
                          dateFormat="MM/dd/yyyy hh:mm"
                          // minDate={new Date()}
                          // maxDate={addDays(new Date(), 31)}
                        />
    <button onClick={handleSubmit} className="badge-primary badge mt-3">Submit</button>
   </div>
  )
}

export default SingleDateModal