import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { apiPreCheckTool, apiixPreCheckTool, baseURL, check_sla_met_api, ixModule, multiRFDS, projectCode, ranDashboard, runPreCheck, singleProjectApi } from '../../../../common/config';
import { AccountReducerAction, addUserAction, clearFetchSingleRecordAction, FetchSingleRecordAction, GetttProject, LCLranDashboardReducerAction, popUpUpdate, SaveLastSavedData, setLoaderAction } from '../../../../store/actions/createTicketManageFields';
import { PDFUploadAction } from '../../../../store/actions/dashboardActions';
import FileSaver from 'file-saver';

import swal from "sweetalert";
import CommonModal from '../../Custom/CommonModal';
import SlaMetRemark from '../../Custom/Modal Body/SlaMetRemark';

const PreCheck = ({ type }) => {
  let dispatch = useDispatch()
  const history = useHistory();
  const location = useLocation();


  const url = window.location.href.split("/")
  let urlindexid = url.pop()
  let activeUrl = url.pop()

  console.log("location", type, location, "history", history)
  const [PreCheckToolModal, setPreCheckToolModal] = useState(false);
  const [PdfUrl, setPdfUrl] = useState("");
  const [uploadedattachFile, setuploadedattachFile] = useState([]);
  const [uploadedattachImage, setuploadedattachImage] = useState([]);
  const [previewuploadedattachImage, setpreviewuploadedattachImage] = useState([]);
  const [textuploadedattachImage, settextuploadedattachImage] = useState([]);
  const [codeattachImage, setcodeattachImage] = useState("");
  const [PdfWebUrl, setPdfWebUrl] = useState("");
  const [closer, setCloser] = useState(false);
  const [MoveCloser, setMoveCloser] = useState(false);
  const [MoveCloserSla, setMoveCloserSla] = useState(false);
  const [chackerSlaData, setchackerSlaData] = useState(false);
  const [dataSlaData, setdataSlaData] = useState(false);
  const [FileUploadError, setFileUploadError] = useState("");

  const [commonModalState, setcommonModalState] = useState(false);
  const [commonModalSize, setcommonModalSize] = useState(false);
  const [commonModalBody, setcommonModalBody] = useState();
  const [commonModalTitle, setcommonModalTitle] = useState("");
  const [commonModalHeader, setcommonModalHeader] = useState("");
  const [formDataState, setformDataState] = useState();

  const [one, setOne] = useState(true);
  const [Onone, setOnOne] = useState(true);
  let currentSiteStatus = 5
  const [siteFile, uploadedFile] = useState([]);
  const [singleSite, setsingleSite] = useState([]);
  let index = window.location.href.split("/").pop()
  // let siteInfo = {}
  const [setdata, setsetdata] = useState(true);
  const [siteId, setsiteId] = useState(true);
  const [onenTimer, setonenTimer] = useState(true);
  const [oldonenTimer, setoldonenTimer] = useState(true);
  const [siteInfo, setsiteInfo] = useState({});

  const { register, setValue, handleSubmit,getValues, watch, reset, formState: { errors } } = useForm();
  let fileParsed = {}
  let site;

  let newAlarm, newTemp;



  let optionArray = ["Yes", "No", "NA"]
  let alarmOption = ["Yes", "No", "NA"]

  if (one) {
    newAlarm = alarmOption.map((item, index) => {
      return <option value={item}>{item}</option>
    })
    newTemp = optionArray.map((item, index) => {
      return <option value={item}>{item}</option>
    })
    newAlarm.unshift(<option value=''>Select</option>)
    newTemp.unshift(<option value=''>Select</option>)
    setOne(false)
  }



  console.log("dropDownAlarmObjdropDownAlarmObj")
  const [dropDownAlarmObj, setdropDownAlarmObj] = useState({
    gsmStatus: newTemp,
    umtsIPContact: newTemp,
    lteIPContact: newTemp,
    fiveGIPContact: newTemp,
    bscIPContact: newTemp,

    GSMAlarms: newAlarm,
    fiveGAlarms: newAlarm,
    umtsAlarms: newAlarm,
    lteAlarm: newAlarm,

  })
  // let curl =window.location.href.split("/")
  console.log("the kjfiuebrfkhgc=",url)
  let dashboardType=url.slice(-1)[0]
  console.log("bhjcbdshbcvsd=",dashboardType)
  let siteDetail = useSelector((state) => {
    console.log("the url state oldDataState =")
    // console.log(state.oldDataState.precheckData,"oldDataStateprecheckData")
    if(oldonenTimer){
      setoldonenTimer(false)
      if(state.oldDataState.precheckData){
        let listOldData=Object.keys(state.oldDataState.precheckData)
        if(listOldData.length>0){
    
          // setValues()
          // console.log(state.oldDataState.precheckData,"oldDataStateprecheckData")
          if(state.oldDataState.precheckData.data){
    
            console.log(state.oldDataState.precheckData.data,"oldDataStateprecheckData")

            let oldPreDataObj=state.oldDataState.precheckData.data
            // oldPreDataObj.map((oldPreDataObjitm)=>{
            //   console.log(oldPreDataObj,"oldPreDataObj")
            // })
            let listOldPreData=Object.keys(state.oldDataState.precheckData.data)
            listOldPreData.map((oneitm)=>{
              console.log(oneitm,oldPreDataObj,oldPreDataObj[oneitm],`oldPreDataObj.${oneitm}`,`oldPreDataObj.oneitm`,"oldPreDataObj")
              setValue(oneitm,oldPreDataObj[oneitm])
            })

            console.log(oldPreDataObj,"oldPreDataObj.GSMAlarmPre")
            // setValue("GSMAlarmPre", oldPreDataObj.GSMAlarmPre)
            // setValue("FiveGAlarmPre", "Yes")
            // setValue("UMTSAlarmPre", "Yes")
            // setValue("LTEAlarmPre", "Yes")
            // setValue("BSCIpContactPre", oldPreDataObj.BSCIpContactPre,{shouldTouch:true,shouldValidate:true})
            // setValue("fiveGIpContactPre", oldPreDataObj.fiveGIpContactPre,{shouldTouch:true,shouldValidate:true})
            // setValue("LTEIpContactPre", oldPreDataObj.LTEIpContactPre,{shouldTouch:true,shouldValidate:true})
            // setValue("UMTSIpContactPre", oldPreDataObj.UMTSIpContactPre,{shouldTouch:true,shouldValidate:true})
            // Object.keys(oldPreDataObj).map((key, index) => (
            //   setValue(key,oldPreDataObj[key],{shouldTouch:true,shouldValidate:true})
            //   // console.log(oldPreDataObj,key,oldPreDataObj[key],"oldPreDataObj",`oldPreDataObj[${key}]`)
            // ))
            // console.log(listOldPreData,oldPreDataObj,"listOldPreDataoldPreDataObj")
            // if(listOldPreData.length>0){
            //   let formAllData={
            //     forType:"Precheck",
            //     data:{}
            //   }
            //   dispatch(SaveLastSavedData(formAllData))
            // }
          }
          
        }
      }
    }
    
    // siteInfo = site
    // let tempSite
    // let siteTemp=dashboardType=="ixDashboard"?state.OneCommonResponse.singleIXSite :state.OneCommonResponse.singleSite
    // if (siteTemp){
    //   tempSite=siteTemp
    // }
    let tempSite = dashboardType=="ixDashboard"?state.OneCommonResponse.singleIXSite :state.OneCommonResponse.singleSite
console.log("thknejfbwifubhei=",tempSite)
    // console.log(tempSite, tempSite.ran_id, site == undefined, "teststateset")

    if ((tempSite && tempSite.length != 0  && dashboardType=="ixDashboard" ) ||(tempSite && tempSite.length != 0 && tempSite.ran_id)) {
      // setonenTimer(false)
      console.log(tempSite, "tempSitetempSitetempSite", tempSite.ran_id, site)
      site =dashboardType=="ixDashboard"?state.OneCommonResponse.singleIXSite :state.OneCommonResponse.singleSite


    }


    // if (site && site.ran_id && onenTimer) {
    //   // setsiteInfo(site)
    // 


    if (site && site.numberStatus && setdata) {
      console.log(site, "sitesitesite")
      setsetdata(false)
      setsiteId(site.ndResult.siteId? site.ndResult.siteId: site.siteId)
      if (site && site.numberStatus > 5) {

        dropDownAlarmObj.gsmStatus = optionArray.map((item) => {
          if (siteInfo.GSMStatusPre && item == siteInfo.GSMStatusPre) {
            return <option selected value={item}>{item}</option>
          } else {
            return <option value={item}>{item}</option>
          }
        })
        dropDownAlarmObj.umtsIPContact = optionArray.map((item) => {
          if (siteInfo.UMTSIpContactPre && item == siteInfo.UMTSIpContactPre) {
            return <option selected value={item}>{item}</option>
          } else {
            return <option value={item}>{item}</option>
          }
        })
        dropDownAlarmObj.lteIPContact = optionArray.map((item) => {
          if (siteInfo.LTEIpContactPre && item == siteInfo.LTEIpContactPre) {
            return <option selected value={item}>{item}</option>
          } else {
            return <option value={item}>{item}</option>
          }
        })
        dropDownAlarmObj.fiveGIPContact = optionArray.map((item) => {
          if (siteInfo.fiveGIpContactPre && item == siteInfo.fiveGIpContactPre) {
            return <option selected value={item}>{item}</option>
          } else {
            return <option value={item}>{item}</option>
          }
        })
        dropDownAlarmObj.bscIPContact = optionArray.map((item) => {
          if (siteInfo.BSCIpContactPre && item == siteInfo.BSCIpContactPre) {
            return <option selected value={item}>{item}</option>
          } else {
            return <option value={item}>{item}</option>
          }
        })
        console.log(fileParsed, "inset fileParsed")
        dropDownAlarmObj.GSMAlarms = alarmOption.map((item) => {
          if (siteInfo.GSMStatusPre && item == siteInfo.GSMStatusPre) {
            return <option selected value={item}>{item}</option>
          } else {
            return <option value={item}>{item}</option>
          }
        })
        dropDownAlarmObj.fiveGAlarms = alarmOption.map((item) => {
          if (siteInfo.FiveGAlarmPre && item == siteInfo.FiveGAlarmPre) {
            return <option selected value={item}>{item}</option>
          } else {
            return <option value={item}>{item}</option>
          }
        })
        dropDownAlarmObj.umtsAlarms = alarmOption.map((item) => {
          if (siteInfo.UMTSAlarmPre && item == siteInfo.UMTSAlarmPre) {
            return <option selected value={item}>{item}</option>
          } else {
            return <option value={item}>{item}</option>
          }
        })
        dropDownAlarmObj.lteAlarm = alarmOption.map((item) => {
          if (siteInfo.LTEAlarmPre && item == siteInfo.LTEAlarmPre) {
            return <option selected value={item}>{item}</option>
          } else {
            return <option value={item}>{item}</option>
          }
        })


      }
      // else {
      //   dropDownAlarmObj.gsmStatus = optionArray.map((item, index) => {
      //     if (index == 0) {
      //       return <option selected value={""} disabled>Select</option>
      //     }
      //     else {
      //       return <option value={item}>{item}</option>
      //     }
      //   })
      // }
      console.log("th e sit e is sit=", siteInfo)
    }

    console.log(state, "state.popUpState")




    if (state.popUpState) {

      if(state.popUpState.popstatus.status == 201 && MoveCloserSla){
        setMoveCloser(false)
        dispatch(popUpUpdate({}));
        setMoveCloserSla(false)
        dispatch(setLoaderAction(false))
        if(dashboardType=="ixDashboard"){
          dispatch(AccountReducerAction(ixModule));
          history.push("/PAG/ix/dashboard")
        }
        else{
          
          let Filleddata=dispatch(LCLranDashboardReducerAction())
          dispatch(AccountReducerAction(ranDashboard+Filleddata,true));
          // dispatch(AccountReducerAction(ranDashboard,true));
          history.push("/PAG/ran/support/dashboard")

        }
      }
      if (state.popUpState.popstatus.status == 200 && state.popUpState.popstatus.data.msg=="SLA Met Check" && MoveCloser) {
        // dispatch(setLoaderAction(true))
        console.log(state.popUpState.popstatus.data.msg=="SLA Met Check", "popstatuspopstatuspopstatus")
        setMoveCloser(false)
        let slamentt = state.popUpState.popstatus.data.data
        dispatch(popUpUpdate({}));
        if (slamentt[1] == "No") {
          // dispatch(setLoaderAction(false))
          setcommonModalBody(<SlaMetRemark formDataState={formDataState} setdataSlaData={setdataSlaData} setchackerSlaData={setchackerSlaData} selectedId={urlindexid} SLAType={"Pre"} SLAMet={slamentt[1]} />)
          setcommonModalState(true)
          
        } else {
          
          if(dashboardType=="ixDashboard"){
      
            dispatch(addUserAction(runPreCheck + index+"?ix=True", formDataState))
          }
          else{
            dispatch(addUserAction(runPreCheck + index, formDataState))
      
          }
          // dispatch(addUserAction(runPreCheck + index, formDataState))
          dispatch(popUpUpdate({}));
          setMoveCloserSla(true)
        }

        // history.push("/PAG/ran/support/dashboard")
      }
      if (state.popUpState.popstatus.status == 200 && closer) {
        if (state.popUpState.popstatus.data.code == 200) {

          console.log(state.popUpState.popstatus.data, "popstatus")
          fileParsed = state.popUpState.popstatus.data
          setCloser(false)
          dispatch(popUpUpdate({}));
          // history.push(baseURL + "/tmo_post_check/downloads/" + state.popUpState.popstatus.data)
          // window.open(baseURL + "/tmo_post_check/downloads/" + state.popUpState.popstatus.data)

          // setfileParsed(state.popUpState.popstatus.data)
          console.log(fileParsed, "fileParsed")
          setsetdata(false)
          setValue("GSMAlarmPre", fileParsed.twoGAlarm)
          setValue("FiveGAlarmPre", fileParsed.fiveGAlarm)
          setValue("UMTSAlarmPre", fileParsed.threeGAlarm)
          setValue("LTEAlarmPre", fileParsed.fourGAlarm)
          setValue("BSCIpContactPre", fileParsed.twoG)
          setValue("fiveGIpContactPre", fileParsed.fiveG)
          setValue("LTEIpContactPre", fileParsed.fourG)
          setValue("UMTSIpContactPre", fileParsed.threeG)


          // console.log(state.popUpState.popstatus,"state.popUpState.popstatus")
          // setPdfUrl("/var/www/html/pag/pag-backend/flask_app_db/FlaskTool/folder/Download_TMO_Post/" + state.popUpState.popstatus.data)
          // setPdfWebUrl(baseURL + "/tmo_post_check/downloads/" + state.popUpState.popstatus.data)
          let filename = fileParsed.downloadPath.split("/").pop()
          // let filePath = baseURL + "/tmo_post_check/downloads/" + filename
          let filePath = ""
          if(dashboardType=="ixDashboard"){
            filePath = baseURL + "/tmo_post_check_ix/downloads/" + filename
          }else{
            filePath = baseURL + "/tmo_post_check/downloads/" + filename
          }

          swal({
            title: "Do you want to download report?",
            text: "",
            icon: "",
            buttons: true,
            // dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              // <Link to={filePath}></Link>
              FileSaver.saveAs(filePath, filename)
              setPdfUrl(filePath)
              setPdfWebUrl(filePath)
            } else {
              setPdfWebUrl("")
              setPdfUrl(filePath)
            }
          })


          let newdropDown = {
            gsmStatus: '',
            umtsIPContact: '',
            lteIPContact: '',
            fiveGIPContact: '',
            bscIPContact: '',
            GSMAlarms: '',
            fiveGAlarms: '',
            umtsAlarms: '',
            lteAlarm: '',

          }

          newdropDown.bscIPContact = optionArray.map((item) => {
            console.log(fileParsed && item == fileParsed.twoG, "fileParsed,item,fileParsed.twoG", fileParsed, item, fileParsed.twoG)
            if (fileParsed && item == fileParsed.twoG) {
              return <option selected value={item}>{item}</option>
            } else {
              return <option value={item}>{item}</option>
            }
          })
          newdropDown.fiveGIPContact = optionArray.map((item) => {
            if (fileParsed && item == fileParsed.fiveG) {
              return <option selected value={item}>{item}</option>
            } else {
              return <option value={item}>{item}</option>
            }
          })
          newdropDown.umtsIPContact = optionArray.map((item) => {
            if (fileParsed && item == fileParsed.threeG) {
              return <option selected value={item}>{item}</option>
            } else {
              return <option value={item}>{item}</option>
            }
          })
          newdropDown.lteIPContact = optionArray.map((item) => {
            if (fileParsed && item == fileParsed.fourG) {
              return <option selected value={item}>{item}</option>
            } else {
              return <option value={item}>{item}</option>
            }
          })

          newdropDown.GSMAlarms = alarmOption.map((item) => {
            if (fileParsed && item == fileParsed.twoGAlarm) {
              return <option selected value={item}>{item}</option>
            } else {
              return <option value={item}>{item}</option>
            }
          })
          newdropDown.fiveGAlarms = alarmOption.map((item) => {
            if (fileParsed && item == fileParsed.fiveGAlarm) {
              return <option selected value={item}>{item}</option>
            } else {
              return <option value={item}>{item}</option>
            }
          })
          newdropDown.umtsAlarms = alarmOption.map((item) => {
            if (fileParsed && item == fileParsed.threeGAlarm) {
              return <option selected value={item}>{item}</option>
            } else {
              return <option value={item}>{item}</option>
            }
          })
          newdropDown.lteAlarm = alarmOption.map((item) => {
            if (fileParsed && item == fileParsed.fourGAlarm) {
              return <option selected value={item}>{item}</option>
            } else {
              return <option value={item}>{item}</option>
            }
          })

          console.log(newdropDown, "newdropDownnewdropDown")


          setdropDownAlarmObj(newdropDown)
          setPreCheckToolModal(false)
          setOnOne(false)

        } else {

          dispatch(popUpUpdate({}));
          swal({
            title: "Please Upload Files for " + siteId,
            text: "",
            icon: "",
            // dangerMode: true,
          })
        }


      }
    }

    console.log(siteInfo, "fileParseddropDownAlarmObj")


    return { site }

  })

  console.log(siteDetail, "siteDetailsiteDetailsiteDetailsaddadsa")

  let Precheckoperner = () => {
    setPreCheckToolModal(true)
  }





  const attachFile = (e) => {
    // let files=[]
    console.log(e.target.files[0], "files")

    // let files = []


    // for (let i = 0; i < e.target.files.length; i++) {
    //   files.push(e.target.files[i])
    // }
    setuploadedattachFile(e.target.files[0])
    if (e.target.files.length > 0) {
      setFileUploadError("")
    }



  };




  const addTextBox = (index, e) => {
    uploadedattachImage.splice(e, 1)
    settextuploadedattachImage.push(e.target.value)
    console.log(e, "indesed")
    let images_data = previewuploadedattachImage.map((item, index) => {
      console.log(item, "item")
      return <tr>
        <td><img width={100} src={item}></img></td>
        <td><input type='text'></input></td>
        <td><button type='button' onClick={(e) => { removeImageData(index) }}>Remove</button></td>
      </tr>
    })
    // setuploadedattachImage(images_data)
    console.log(e, "code data")
  }

  const removeImageData = (e) => {
    uploadedattachImage.splice(e, 1)
    previewuploadedattachImage.splice(e, 1)
    console.log(e, "indesed")
    let images_data = previewuploadedattachImage.map((item, index) => {
      console.log(item, "item")
      return <tr>
        <td><img width={100} src={item}></img></td>
        <td><input type='text'></input></td>
        <td><button type='button' onClick={(e) => { removeImageData(index) }}>Remove</button></td>
      </tr>
    })
    // setuploadedattachImage(images_data)
    console.log(e, "code data")
  }


  const attachImage = (e) => {
    console.log(e.target.files, uploadedattachImage, "files")

    uploadedattachImage.push(e.target.files[0])
    previewuploadedattachImage.push(URL.createObjectURL(e.target.files[0]))

    let images_data = previewuploadedattachImage.map((item, index) => {
      console.log(item, "item")
      return <tr>
        <td><img width={100} src={item}></img></td>
        <td><input type='text' onClick={(e) => { addTextBox(index, e) }} ></input></td>
        <td><button type='button' onClick={(e) => { removeImageData(index) }}>Remove</button></td>
      </tr>
    })

    // setcodeattachImage(images_data)

    console.log(images_data, codeattachImage, "images_data")


  };

  const closehandleCloseweather = () => {
    setPreCheckToolModal(false)
  };


  const topageraiseTT = (siteDetaildata, page) => {
    setoldonenTimer(true)
    console.log(getValues(),"getValuesgetValues")
    let oller=getValues()
    delete oller.preCheckEngineer
    let formAllData={
      forType:"Precheck",
      data:oller
    }
    dispatch(SaveLastSavedData(formAllData))

    if (siteDetaildata) {
      console.log(siteDetaildata, "siteDetaildatatrobleTicke")

      dispatch(GetttProject([]))

      dispatch(clearFetchSingleRecordAction())
      let data = {
        "siteUniqueId":  siteDetaildata?siteDetaildata.dtSiteId:"",
        "ranId": index,
        "createFrom": "Pre Check",
        "creatorId": index,
        "projectUniqueId": siteDetaildata? siteDetaildata.dtprojectUniqueId:"",
        "index": 0
      }
      // history.push({
      //   pathname: `/PAG/trobleTicke/form/pre/${index}`
      // })

      
		  history.push(`/PAG/trobleTicke/form/pre/${index}`,{formDataFull:getValues()})
    }
    // }
    // if(page=="back"){
    //   history.goBack()
    // }
  }

  const topage = (page) => {
    if (page == "raiseTT") {
      if(dashboardType=="ixDashboard"){
        history.push({
          pathname: `/PAG/trobleTicke/form/ixDashboard/${index}`
        })
      }
      else{
        history.push("/PAG/trobleTicke/form")
      }
      }
    if (page == "back") {
      history.goBack()
    }
  }





  const userDetails = JSON.parse(localStorage.getItem("userDetails"))

  const onSubmit = async (data) => {
    dispatch(popUpUpdate({}));
    setMoveCloser(true)


    let newform = new FormData();
    for (var key in data) {
      newform.append(key, data[key]);
    }

    // if(PdfUrl=="" && uploadedattachFile.length==0){

    //   setFileUploadError("Please Upload File")
    //   return
    // }
    console.log("the send data is =", uploadedattachFile.length, uploadedattachFile)
    // 

    newform.append("PretoolFile", PdfUrl);
    if (uploadedattachFile) {
      newform.append("uploadFile", uploadedattachFile);
    }
    // if (uploadedattachFile.length > 0) {
    //   newform.append("uploadImage", uploadedattachImage);
    // }

    // dispatch(addUserAction(runPreCheck+siteInfo.ranuniqueId, data))    


    let newJson={
      "parentId":urlindexid,
      "type":"Pre"
    }

    setformDataState(newform)
    // if(chackerSlaData){
    //   dispatch(addUserAction(runPreCheck + index, newform))
    // }else{
      if(dashboardType=="ixDashboard"){
        
        dispatch(addUserAction(check_sla_met_api +"?ix=True", newJson))
      }
      else{
        dispatch(addUserAction(check_sla_met_api, newJson))
      }
    // }

    // history.push("/PAG/ran/support/dashboard")
  }


  const submitHandler = async (e, check = null) => {
    e.preventDefault();

    if (check == "runRFDS") {
      console.log("inside RFDS", siteFile)
      // console.log("remarks =",remarks)
      console.log("file=", siteFile)
      let temp = []
      // temp.push(...siteFile)
      let form = new FormData();

      // for(let i=0;i<siteFile.length;i++){
      //   form.append('uploadFile',siteFile[i].file, siteFile[i].name)
      // }
      // form.append('uploadFile',siteFile)

      console.log(siteFile, siteFile.length);
      for (let i = 0; i < siteFile.length; i++) {
        form.append("all-files", siteFile[i]);
      }
      form.append("Other_Customer", true)
      form.append("siteId", siteId)
      // form.append("siteId", "DA04480S")
      console.log("file=", form)
      // dispatch(PDFUploadAction(apiPreCheckTool, form, false))
      
      if(dashboardType=="ixDashboard"){
        dispatch(PDFUploadAction(apiixPreCheckTool, form, false))
      }else{
        dispatch(PDFUploadAction(apiPreCheckTool, form, false))
      }
      console.log("form data =", form)
      dispatch(popUpUpdate({}));
      setCloser(true)


    }
  };
  const uploadCsvFile = (e) => {
    let files = []

    console.log(e.target.files[0], "ON_CHANGE_FILES:", e.target.files.length);

    for (let i = 0; i < e.target.files.length; i++) {
      files.push(e.target.files[i])
    }
    console.log(...files)
    uploadedFile(files)

  };

  const onPreCheckSubmit = async (data) => {
    console.log("the send data is =", data)
  }


  useEffect(() => {

    // dispatch(clearFetchSingleRecordAction())
    // // if(Onone){
    // //   setsiteInfo()
    // // }
    // dispatch(FetchSingleRecordAction(ranDashboard, index))
  }, [dropDownAlarmObj])
  return (
    <>

      <Modal show={PreCheckToolModal} size="md">
        <Modal.Header
          variant="secondary "
          closeButton
          onClick={closehandleCloseweather}
        >
          <Modal.Title>Pre Check Tool</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <form onSubmit={(e) => { submitHandler(e, "runRFDS") }} encType="multipart/form-data" className="mx-2">
            <div class="form-group col-md-12">
              <div>
                Upload File:
                <label class="custom-file-upload btn btn-outline-primary btn-xs col-md-12">

                  <input
                    type="file"
                    className="form-control btn btn-primary "
                    id="inputGroupFile04"
                    aria-describedby="inputGroupFileAddon04"
                    aria-label="Upload"
                    name="file"
                    multiple
                    placeholder={'Select file'}
                    onChange={uploadCsvFile}
                  //   {...register("logInTimePreCheckDelivered")}
                  />
                  <i class="fa fa-cloud-upload"></i> Choose File
                </label>
                <label>{siteFile.length > 0 ? siteFile.length : "No"} File Selected</label>

                {/* Select file */}
                {/* </input> */}
              </div>
            </div>
            <button type="submit" className="btn btn-primary btn-xs mx-1">
              Submit
            </button>
          </form>
        </Modal.Body>

        {/* <Modal.Footer>
          <Button variant="secondary " onClick={closehandleCloseweather}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>





      <form onSubmit={handleSubmit(onSubmit)}>

        {/* {
        siteInfo
        ?
      
        <> */}
        <div className="form-row">
          {console.log(siteDetail.site, "precheck613")}
          <div className="form-group col-md-4">
            <label htmlFor="inputEmail4"><span style={{ color: "red" }} >*</span>SiteId</label>
            <input style={{ height: "40px" }}
              type="text"
              className="form-control"
              id="inputEmail4"
              name='siteid'
              readOnly
              defaultValue={siteDetail.site ? siteDetail.site.ndResult ? siteDetail.site.ndResult.siteId?siteDetail.site.ndResult.siteId: siteDetail.site.siteId: '' : ""}

            />
          </div>
          {
                                                    dashboardType=="ixDashboard"?
                                                    <div className="form-group col-md-4">
                                                    <label for="exampleFormControlSelect1">Pre HW/Layer Configuration</label>
                                                    <input
                                                        style={{ height: "32px" }}
                                                        type="text"
                                                        className="form-control"
                                                        id="input "
                                                        readOnly
                                                        value={
                                                          siteDetail.site?siteDetail.site.techtake ? siteDetail.site.techtake.join(", ") : siteDetail.site.preHWLayerConfiguration?siteDetail.site.preHWLayerConfiguration.values.join(","):"":""
                                                        }
                                                    // {...register("market")}
                                                    />
                                                </div>
                                                    :
          <div className="form-group col-md-4">
            <label htmlFor="inputPassword4"><span style={{ color: "red" }} >*</span>Technology</label>
            <input style={{ height: "40px" }}
              type="text"
              className="form-control"
              id="inputPassword4"
              name='technology'
              readOnly
              value={siteDetail.site ? siteDetail.site.techtake ? siteDetail.site.techtake.join(", ") : "" : ""}
            // {...register("technology")}
            />
          </div>
}
          <div className="form-group col-md-4">
            <label htmlFor="inputPassword4"><span style={{ color: "red" }} >*</span>
              Precheck Engineer</label>
            <input style={{ height: "40px" }} className='form-control' readOnly name="Precheck Engineer" id="browser"
              {...register("preCheckEngineer")}
              defaultValue={userDetails.name}
            />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="inputState">Account</label>
            <input style={{ height: "40px" }}
              type="text"
              className="form-control"
              id="inputPassword4"
              name='technology'
              readOnly
              value={siteDetail.site ? siteDetail.site.ndResult ? siteDetail.site.ndResult.account ||siteDetail.site.account: "" : ""}
            // {...register("account")}
            />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="inputState">Market</label>
            <input style={{ height: "40px" }}
              type="text"
              className="form-control"
              id="inputPassword4"
              name='technology'
              readOnly
              value={siteDetail.site ? siteDetail.site.ndResult ? siteDetail.site.ndResult.market|| siteDetail.site.market : "" : ""}
            // {...register("market")}
            />
          </div>
        </div>
        {/* <div className="form-group col-md-4">

            <label htmlFor="inputState">GSM Status</label>
            <select style={{ height: "40px" }} id="inputState" className="form-control"
              {...register("GSMStatusPre", {
                required: "GSM Status",
              })}
            >
              {
                dropDownAlarmObj.gsmStatus
              } */}
        {/*              
              <option selected disabled value=''>Choose</option>
              <option value={"Not Reachable"}>Not Reachable</option>
              <option value={"Enabled"}>Enabled</option>
              <option value={"Partially Enabled"}>Partially Enabled</option>
              <option value={"NA"}>NA</option>  */}
        {/* </select>
            {errors.GSMStatusPre ? <p className="error">{errors.GSMStatusPre.message}</p> : ""}
          </div> */}


        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="inputState">BSC IP Contact</label>
            {
              siteDetail.site && siteDetail.site.numberStatus == currentSiteStatus
                ? <>
                  <select style={{ height: "40px" }} id="inputState" className="form-control"
                    {...register("BSCIpContactPre", {
                      required: "BSC IP Contact",
                    })}
                  >
                    {
                      dropDownAlarmObj.bscIPContact
                    }
                  </select>
                  {errors.BSCIpContactPre ? <p className="error">{errors.BSCIpContactPre.message}</p> : ""}
                </>
                :
                <input
                  type='text'
                  readOnly
                  className='form-control'
                  value={siteDetail.site ? siteDetail.site.BSCIpContactPre : ""}
                />
            }
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="inputState">GSM Alarms</label>
            {
              siteDetail.site && siteDetail.site.numberStatus == currentSiteStatus
                ? <>
                  <select style={{ height: "40px" }} id="inputState" className="form-control" name='LTE IP Contact'
                    {...register("GSMAlarmPre", {
                      required: "GSM Alarms",
                    })}
                  >
                    {
                      dropDownAlarmObj.GSMAlarms
                    }
                  </select>
                  {errors.GSMAlarmPre ? <p className="error">{errors.GSMAlarmPre.message}</p> : ""}
                </>
                :
                <input
                  type='text'
                  readOnly
                  className='form-control'
                  value={siteDetail.site ? siteDetail.site.GSMAlarmPre : ""}
                />
            }
          </div>

        </div>


        <div className="form-row">

          <div className="form-group col-md-4">
            <label htmlFor="inputState">UMTS IP Contact</label>
            {
              siteDetail.site && siteDetail.site.numberStatus == currentSiteStatus
                ? <>
                  <select style={{ height: "40px" }} id="inputState" className="form-control"
                    {...register("UMTSIpContactPre", {
                      required: "UMTS IP Contact",
                    })}
                  >
                    {
                      dropDownAlarmObj.umtsIPContact
                    }
                  </select>
                  {errors.UMTSIpContactPre ? <p className="error">{errors.UMTSIpContactPre.message}</p> : ""}
                </>
                :
                <input
                  type='text'
                  readOnly
                  className='form-control'
                  value={siteDetail.site ? siteDetail.site.UMTSIpContactPre : ""}
                />
            }

          </div>
          <div className="form-group col-md-4">
            <label htmlFor="inputState">UMTS Alarms</label>
            {
              siteDetail.site && siteDetail.site.numberStatus == currentSiteStatus
                ? <>
                  <select style={{ height: "40px" }} id="inputState" className="form-control" name='LTE IP Contact'
                    {...register("UMTSAlarmPre", {
                      required: "UMTS Alarms",
                    })}
                  >
                    {
                      dropDownAlarmObj.umtsAlarms
                    }
                  </select>
                  {errors.UMTSAlarmPre ? <p className="error">{errors.UMTSAlarmPre.message}</p> : ""}
                </>
                :
                <input
                  type='text'
                  readOnly
                  className='form-control'
                  value={siteDetail.site ? siteDetail.site.UMTSAlarmPre : ""}
                />
            }
          </div>
        </div>

        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="inputState">LTE IP Contact</label>
            {
              siteDetail.site && siteDetail.site.numberStatus == currentSiteStatus
                ? <>
                  <select style={{ height: "40px" }} id="inputState" className="form-control"
                    {...register("LTEIpContactPre", {
                      required: "LTE IP Contact",
                    })}
                  >
                    {dropDownAlarmObj.lteIPContact}

                  </select>
                  {errors.LTEIpContactPre ? <p className="error">{errors.LTEIpContactPre.message}</p> : ""}
                </>
                :
                <input
                  type='text'
                  readOnly
                  className='form-control'
                  value={siteDetail.site ? siteDetail.site.LTEIpContactPre : ""}
                />
            }
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="inputState">LTE Alarm
            </label>
            {
              siteDetail.site && siteDetail.site.numberStatus == currentSiteStatus
                ? <>
                  <select style={{ height: "40px" }} id="inputState" className="form-control" name='LTE Alarm'
                    {...register("LTEAlarmPre", {
                      required: "LTE Alarm",
                    })}
                  >
                    {dropDownAlarmObj.lteAlarm}
                  </select>
                  {errors.LTEAlarmPre ? <p className="error">{errors.LTEAlarmPre.message}</p> : ""}
                </>
                :
                <input
                  type='text'
                  readOnly
                  className='form-control'
                  value={siteDetail.site ? siteDetail.site.LTEAlarmPre : ""}
                />
            }
          </div>


        </div>
        <div className="form-row">
          <div className="form-group col-md-4">
            <label htmlFor="inputState">5G IP Contact</label>
            {
              siteDetail.site && siteDetail.site.numberStatus == currentSiteStatus
                ? <>
                  <select style={{ height: "40px" }} id="inputState" className="form-control"
                    {...register("fiveGIpContactPre", {
                      required: "5G IP Contact",
                    })}
                  >
                    {
                      dropDownAlarmObj.fiveGIPContact
                    }
                  </select>
                  {errors.fiveGIpContactPre ? <p className="error">{errors.fiveGIpContactPre.message}</p> : ""}
                </>
                :
                <input
                  type='text'
                  readOnly
                  className='form-control'
                  value={siteDetail.site ? siteDetail.site.fiveGIpContactPre : ""}
                />
            }
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="inputState">5G Alarms</label>
            {
              siteDetail.site && siteDetail.site.numberStatus == currentSiteStatus
                ? <>
                  <select style={{ height: "40px" }} id="inputState" className="form-control" name='LTE IP Contact'
                    {...register("FiveGAlarmPre", {
                      required: "5G Alarms",
                    })}
                  >
                    {dropDownAlarmObj.fiveGAlarms}
                  </select>
                  {errors.FiveGAlarmPre ? <p className="error">{errors.FiveGAlarmPre.message}</p> : ""}
                </>
                :
                <input
                  type='text'
                  readOnly
                  className='form-control'
                  value={siteDetail.site ? siteDetail.site.FiveGAlarmPre : ""}
                />
            }
          </div>

        </div>


        {/* <div className="form-group col-md-4">
          <label htmlFor="inputState">5G NR Alarms
          </label>
          <select style={{ height: "40px" }} id="inputState" className="form-control"
            {...register("5GNRAlarmsPre")}
          >
            <option selected disabled value=''>Choose</option>
            <option value={"Yes"}>yes</option>
            <option value={"No"}>No</option>
            <option value={"NA"}>NA</option>
          </select>
        </div> */}




        <div className="form-row">

          <div className="form-group col-md-4">
            <label htmlFor="inputCity">Pre Activity Notes</label>
            {
              siteDetail.site && siteDetail.site.numberStatus == currentSiteStatus
                ? <>
                  <textarea style={{ height: "40px" }} type="text"

                    value={siteDetail.site ? siteDetail.site.precheckAlarmIssue : ''}
                    className="form-control" id="inputCity" name='Pre Activity Notes'
                    {...register("precheckAlarmIssue", {
                      required: "Pre Activity Notes",
                    })}
                  />
                  {errors.precheckAlarmIssue ? <p className="error">{errors.precheckAlarmIssue.message}</p> : ""}
                </>
                :
                <input
                  type='text'
                  readOnly
                  className='form-control'
                  value={siteDetail.site ? siteDetail.site.precheckAlarmIssue : ""}
                />
            }
          </div>
          {/* <div className="form-group col-md-4">
                <label htmlFor="inputCity">Log In Time Precheck delivered</label>
                <input style={{ height: "40px" }} type="date" className="form-control" id="inputCity" name='Precheck Alarm Issue'
                {...register("logInTimePreCheckDelivered")}
                />
              </div> */}


          {
            PdfWebUrl != "" ?
              <>
                <div class="form-group col-md-2">
                  {siteDetail.site && siteDetail.site.numberStatus == currentSiteStatus
                    ?
                    <>
                      <div>

                        Upload File:
                        <label class="custom-file-upload btn btn-outline-primary btn-xs col-md-12">
                          <input
                            type="file"
                            className="form-control btn btn-primary "
                            id="inputGroupFile04"
                            aria-describedby="inputGroupFileAddon04"
                            aria-label="Upload"
                            name="file"
                            onChange={attachFile}
                          //   {...register("logInTimePreCheckDelivered")}
                          />
                          <i class="fa fa-cloud-upload"></i> Choose File
                        </label>
                        <label>{uploadedattachFile.length == 0 ? "No Files Selected" : "1 files selected"}</label>
                      </div>
                      {FileUploadError != "" ? <p className="error">{FileUploadError}</p> : ""}

                    </>
                    : ""}
                </div>
              </>
              : ""
          }
        </div>

        {/* : */}

        {/* <div class="form-group col-md-2">
                <div> */}
        {/* <label>Upload File : </label> */}
        {/* <br />
                  <br />

                  {PdfWebUrl != "" ?
                    <a style={{ color: "black" }} href={PdfWebUrl}>Download From Here</a> : ""}
                </div>
              </div> */}
        {/* </> */}
        {/* } */}



        {/* <div class="form-group col-md-8">
            <div>

              <table>
                <tr>
                  <td></td>
                  <td></td>
                  <td><label class="custom-file-upload btn btn-outline-primary btn-xs col-md-12">
                  <input
                    type="file"
                    className="form-control btn btn-primary "
                    id="inputGroupFile04"
                    aria-describedby="inputGroupFileAddon04"
                    aria-label="Upload"
                    name="file"
                    onChange={attachImage}
                  //   {...register("logInTimePreCheckDelivered")}
                  />
                  <i class="fa fa-cloud-upload"></i> Choose File
                </label></td>
                </tr>
                <tr>
                  <td>Image</td>
                  <td>Comment</td>
                  <td>Action</td>
                </tr>
                {codeattachImage}
               
              </table>
            </div>
          </div> */}








        {siteDetail.site && siteDetail.site.numberStatus == currentSiteStatus && activeUrl == "preCheck"
          ?

          <div className='text-center '>

            <button type="submit" className="btn btn-primary btn-xs mx-1">
              Submit
            </button>
            <button type="button" onClick={() => {
              Precheckoperner()
            }} className="btn btn-primary btn-xs  mx-1">
              Precheck Tool
            </button>

            <button type="button" onClick={() => topageraiseTT(siteDetail.site, "raiseTT")} className="btn btn-primary btn-xs  mx-1">
              Raise Trouble Ticket
            </button>
            <button type="button" onClick={() => topage("back")} className="btn btn-primary btn-xs  mx-1">
              Back
            </button>
          </div>
          : ""
        }
      </form >


      <CommonModal
        modalSize={"md"}
        setXlShow={setcommonModalState}
        xlShow={commonModalState}
        Body={commonModalBody}
        Header={commonModalHeader}
        Title={commonModalTitle}
      ></CommonModal>

    </>


  )
}

export default PreCheck