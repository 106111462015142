

import React, { useEffect, useState, useRef } from 'react'
import { Dropdown } from 'react-bootstrap'
import { saveAs } from 'file-saver';
// import "../../../css/customStyle.css"
// import DataTable, { createTheme } from 'react-data-table-component';
// import DataTableExtensions from 'react-data-table-component-extensions';
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux'
import { NetworkDatabaseAction, CSVUploadAction, PAG_maindashboardAction } from "../../../store/actions/dashboardActions"
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
import { FormControl } from 'react-bootstrap'
import moment from "moment"
import "react-datepicker/dist/react-datepicker.css";

// import "../../../css/custom.css"



// import { Chart } from "react-google-charts";
import Chart from 'react-apexcharts';
import { GetChartAction } from '../../../store/actions/createTicketManageFields';

// awsDashboard
import DatePicker from "react-datepicker";
import { CustomInput } from 'reactstrap';

// charts
import candyTheme from "fusioncharts/themes/fusioncharts.theme.candy";
import FusionCharts from "fusioncharts";
import ReactFusioncharts from "react-fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ExcelExport from 'fusioncharts/fusioncharts.excelexport';
import { dashboardCustomer, dashboardVendor, sitesAgingCustomer, sitesCountCustomer, ttAgingVendor, ttCountVendor, warRoomActivity, awsDashboard, aws_callLogsdb, aws_callLogschartdb } from '../../../common/config';

ReactFusioncharts.fcRoot(FusionCharts, charts, candyTheme);

const AwsDashboard = () => {

    let now = new Date();
    let startt = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
    let endd = moment(startt).add(1, "days").subtract(1, "seconds");
    const [accordianState, setAccordianState] = useState(false)
    const [oneTime, setoneTime] = useState(true)
    const [oneTime1, setoneTime1] = useState(true)
    const [siteFile, uploadedFile] = useState();
    const [remarks, setRemarks] = useState();
    const [warchangeWeek, setvwarchangeWeek] = useState();
    const [warWeek, setwarWeekName] = useState("Week");
    const [changeWeek, setChangeWeek] = useState();
    const [nochangeWeek, setnoChangeWeek] = useState();
    const [Week, setWeekName] = useState("Week");
    const [StartEndStr_new, setStartEndStr_new] = useState("");
    const [StartEndStr, setStartEndStr] = useState("");
    const [adingDate, setadingDate] = useState();
    const [start, setstart] = useState(startt);
    const [end, setend] = useState(endd);
    const [startDate, setStartDate] = useState();
    const [warstartDate, setwarStartDate] = useState(new Date());

    const [dateRange, setDateRange] = useState([null, null]);
    const [wardateRange, setwarDateRange] = useState([null, null]);
    const [rangestartDate, rangeendDate] = dateRange;
    const [warrangestartDate, warrangeendDate] = wardateRange;



    let columns = []

    let morethanenew = []
    let lessthannew = []
    let assignvendornew = []
    let rowData = []
    var posts = []
    var new_posts = []
    var tableData_new = {};

    var TotalTicket = "--"
    var piechartdata = [
        ["Task", "Hours per Day"]
    ];

    var piechartoptions = {
        is3D: true,
        backgroundColor: 'transparent',
        responsive: true,
        legend: {
            position: 'right',
            textStyle: { fontSize: 10, color: 'black' },
        },
    };

    var downtable_columns = []
    var downtable_data = []

    var unique_weeks = []
    var vendor = []
    var open = []
    var close = []

    const count = useSelector((state) => {
        let vendornew = []
        let warvendornew = []
        let opennew = []
        let closenew = []

        let cxnew = []
        let ixnew = []
        let troubleshootingnew = []
        let e911new = []

        let weekks = []
        let ticketsData;

        const aws = {
            "Dallas Login": "dallasLogin",
            "Dallas Logout": "dallasLogout",
            "Dallas Outbound": "dallasOutbound",
            "Dallas TSH": "dallasTsh"
        }


        // let awsLegends = []
        let awsValues = {
            dallasLogin: [],
            dallasLogout: [],
            dallasOutbound: [],
            dallasTsh: []
        }
        let awsLabels = []




        console.table(typeof (state.chartState.awsChart), "state.chartState.awsChart")

        if (typeof (state.chartState.awsChart) != "string") {
            console.log(state.chartState.awsChart, "state.chartState.awsChart")

            let cttAwsLabel = []
            state?.chartState?.awsChart?.forEach(data => {
                if (cttAwsLabel.indexOf(data.label) == -1) {
                    cttAwsLabel.push(data.label)
                }
            })

            console.log(cttAwsLabel, "cttAwsLabel")

            cttAwsLabel.map((oneIte) => {


                state?.chartState?.awsChart?.forEach(data => {
                    console.log(oneIte, data,oneIte == data.label, "oneIte,data.label")
                    if (oneIte == data.label) {
                        awsLabels.push(oneIte)
                        console.log(data.value, oneIte, "data.value,oneIte")
                        console.log(oneIte, "oneIte", data.label, "data.label", data.value, "data.value", data.name, "awsLabelsawsLabels")
                        // awsLegends.push(data.name)
                        console.log(data.name, aws, awsValues, "data.name,aws,awsValues")
                        awsValues[aws[data.name]].push(data.value)
                    }


                })

            })
            console.log(awsValues, "awsValues", awsLabels, "awsLabels", "awsLabelsawsValues")
            let label = []
            awsLabels.forEach(data => {
                if (!label.includes(data)) {
                    label.push(data)
                }
            })

            awsLabels = label.map(data => data.replaceAll("_", " ").toLowerCase().split(" ").map(data => `${data[0].toUpperCase()}${data.slice(1)}`).join(" "))

        }


        let awsValuesNew = {
            dallasLogin: [],
            dallasLogout: [],
            dallasOutbound: [],
            dallasTsh: []
        }
        let awsLabels_new = []




        console.table(typeof (state.chartState.awsChartCall), "state.chartState.awsChart")

        
        if (typeof (state.chartState.awsChartCall) != "string") {
            console.log(state.chartState.awsChartCall, "state.chartState.awsChart")
            let cttAwsLabelChart = []
            state?.chartState?.awsChartCall?.forEach(data => {
                if (cttAwsLabelChart.indexOf(data.label) == -1) {
                    cttAwsLabelChart.push(data.label)
                }
            })
            
            cttAwsLabelChart.map((oneIte) => {
                state?.chartState?.awsChartCall?.forEach(data => {

                    // if()
                    // awsLabels_new.push(data.label)

                    // // awsLegends.push(data.name)
                    // awsValuesNew[aws[data.name]].push(data.value)
                    if (oneIte == data.label) {
                        awsLabels_new.push(oneIte)
                        console.log(data.value, oneIte, "data.value,oneIte")
                        console.log(oneIte, "oneIte", data.label, "data.label", data.value, "data.value", data.name, "awsLabelsawsLabels")
                        // awsLegends.push(data.name)
                        console.log(data.name, aws, awsValues, "data.name,aws,awsValues")
                        awsValuesNew[aws[data.name]].push(data.value)
                    }

                })
            })
            console.log(awsValues, "awsValues", awsLabels, "awsLabels", "awsLabelsawsValues")
            let label = []
            awsLabels_new.forEach(data => {
                if (!label.includes(data)) {
                    label.push(data)
                }
            })

            awsLabels_new = label.map(data => data.replaceAll("_", " ").toLowerCase().split(" ").map(data => `${data[0].toUpperCase()}${data.slice(1)}`).join(" "))

        }
        // console.log(state, "state")
        // if (state.chartState.ttVendorChartData && state.chartState.ttVendorChartData.length > 0) {
        // let rowData = state.chartState.ttVendorChartData ? state.chartState.ttVendorChartData.length > 0 ? state.chartState.ttVendorChartData : [] : []
        // let warrowData = state.chartState.warRoomCoustomer ? state.chartState.warRoomCoustomer.length > 0 ? state.chartState.warRoomCoustomer : [] : []
        // let agingrowData = state.chartState.ttVendorAgingChartData ? state.chartState.ttVendorAgingChartData.length > 0 ? state.chartState.ttVendorAgingChartData : [] : []

        // ticketsData = state.chartState.vendorDashboard ? state.chartState.vendorDashboard : {}


        // if (oneTime1 == oneTime1) {
        //     if (agingrowData && agingrowData.length > 0) {
        //         // setoneTime1(false)
        //         // console.log("agingrowData", agingrowData)
        //         agingrowData.map((item) => {

        //             let inner = item.add
        //             // console.log("agingrowDatainner", inner)

        //             if (inner.length == 2) {
        //                 if (inner[0].status == "greater") {
        //                     morethanenew.push(inner[0].count)
        //                 }
        //                 if (inner[0].status == "less") {
        //                     lessthannew.push(inner[0].count)
        //                 }
        //                 if (inner[1].status == "greater") {
        //                     morethanenew.push(inner[1].count)
        //                 }
        //                 if (inner[1].status == "less") {
        //                     lessthannew.push(inner[1].count)
        //                 }
        //             }
        //             if (inner.length == 1) {
        //                 if (inner[0].status == "less") {
        //                     lessthannew.push(inner[0].count)
        //                     morethanenew.push(0)
        //                 }
        //                 if (inner[0].status == "greater") {
        //                     morethanenew.push(inner[0].count)
        //                     lessthannew.push(0)
        //                 }
        //             }
        //             if (inner.length == 0) {
        //                 morethanenew.push(0)
        //                 lessthannew.push(0)
        //             }
        //             assignvendornew.push(item._id.vendor)
        //         })

        //     }
        // }

        // if (rowData) {
        //     rowData.map((item) => {

        //         if (1 == 1) {
        //             // console.log(item.data, "item.data")
        //             let inner = item.data

        //             // console.log(inner[0].status == "Close", "inner")
        //             if (inner.length == 2) {
        //                 // console.log(inner[0].status, "innerstatus")
        //                 if (inner[0].status == "Open") {
        //                     // console.log(inner[0].count, "innerstatus")
        //                     opennew.push(inner[0].count)
        //                 }
        //                 if (inner[0].status == "Close") {
        //                     // console.log(inner[0].count, "innerstatus")
        //                     closenew.push(inner[0].count)
        //                 }

        //                 if (inner[1].status == "Open") {
        //                     // console.log(inner[1].count, "innerstatus")
        //                     opennew.push(inner[1].count)
        //                 }
        //                 if (inner[1].status == "Close") {
        //                     // console.log(inner[1].count, "innerstatus")
        //                     closenew.push(inner[1].count)
        //                 }

        //             }


        //             if (inner.length == 1) {
        //                 // console.log(inner[0].status, "innerstatus")
        //                 if (inner[0].status == "Open") {
        //                     // console.log(inner[0].count, "innerstatus")
        //                     opennew.push(inner[0].count)
        //                     closenew.push(0)
        //                 }
        //                 if (inner[0].status == "Close") {
        //                     // console.log(inner[0].count, "innerstatus")
        //                     closenew.push(inner[0].count)
        //                     opennew.push(0)
        //                 }

        //             }

        //             if (inner.length == 0) {

        //                 closenew.push(0)
        //                 opennew.push(0)

        //             }



        //             vendornew.push(item._id.vendor)
        //             // console.log(opennew, closenew, vendornew, "innerdsadaas")



        //         }
        //     })
        // }

        // if (warrowData) {
        //     warrowData.map((item) => {
        //         // console.log(item, "warrowData")

        //         if (1 == 1) {
        //             // console.log(item.data, "item.data")
        //             let inner = item.data

        //             // console.log(inner.length, "lengthwarrowData")
        //             if (inner.length == 4) {
        //                 console.log(inner, "innerwarrowData")
        //                 inner.map((inthe) => {
        //                     // console.log("inthe.status", inthe.status)
        //                     if (inthe.status == "CX") {
        //                         cxnew.push(inthe.count)
        //                     }
        //                     if (inthe.status == "IX") {
        //                         ixnew.push(inthe.count)
        //                     }
        //                     if (inthe.status == "Troubleshooting") {
        //                         troubleshootingnew.push(inthe.count)
        //                     }
        //                     if (inthe.status == "E911") {
        //                         e911new.push(inthe.count)
        //                     }
        //                 })
        //             }

        //             if (inner.length == 3) {
        //                 // console.log(inner, "innerwarrowData")


        //                 inner.map((inthe) => {
        //                     // console.log("inthe.status", inthe.status)
        //                     let cxLocal, ixLocal, tlocal, enlocal = 0;
        //                     if (inthe.status == "IX") {
        //                         cxnew.push(inthe.count)
        //                         cxLocal = 1
        //                     }
        //                     if (inthe.status == "E911") {
        //                         ixnew.push(inthe.count)
        //                         ixLocal = 1
        //                     }
        //                     if (inthe.status == "CX") {
        //                         troubleshootingnew.push(inthe.count)
        //                         tlocal = 1
        //                     }
        //                     if (inthe.status == "Troubleshooting") {
        //                         e911new.push(inthe.count)
        //                         enlocal = 1
        //                     }
        //                     if (enlocal == 0) {
        //                         e911new.push(0)
        //                     }
        //                     if (tlocal == 0) {
        //                         troubleshootingnew.push(0)
        //                     }
        //                     if (ixLocal == 0) {
        //                         ixnew.push(0)
        //                     }
        //                     if (cxLocal == 0) {
        //                         e911new.push(0)
        //                     }

        //                 })
        //             }

        //             if (inner.length == 2) {
        //                 // console.log(inner, "innerwarrowData")


        //                 inner.map((inthe) => {
        //                     // console.log("inthe.status", inthe.status)
        //                     let cxLocal, ixLocal, tlocal, enlocal = 0;
        //                     if (inthe.status == "IX") {
        //                         cxnew.push(inthe.count)
        //                         cxLocal = 1
        //                     }
        //                     if (inthe.status == "E911") {
        //                         ixnew.push(inthe.count)
        //                         ixLocal = 1
        //                     }
        //                     if (inthe.status == "CX") {
        //                         troubleshootingnew.push(inthe.count)
        //                         tlocal = 1
        //                     }
        //                     if (inthe.status == "Troubleshooting") {
        //                         e911new.push(inthe.count)
        //                         enlocal = 1
        //                     }
        //                     if (enlocal == 0) {
        //                         e911new.push(0)
        //                     }
        //                     if (tlocal == 0) {
        //                         troubleshootingnew.push(0)
        //                     }
        //                     if (ixLocal == 0) {
        //                         ixnew.push(0)
        //                     }
        //                     if (cxLocal == 0) {
        //                         e911new.push(0)
        //                     }

        //                 })
        //             }


        //             if (inner.length == 1) {
        //                 // console.log(inner[0].status, "innerstatus")
        //                 if (inner[0].status == "IX") {
        //                     cxnew.push(0)
        //                     ixnew.push(inner[0].count)
        //                     troubleshootingnew.push(0)
        //                     e911new.push(0)
        //                 }
        //                 if (inner[0].status == "CX") {
        //                     cxnew.push(inner[0].count)
        //                     ixnew.push(0)
        //                     troubleshootingnew.push(0)
        //                     e911new.push(0)
        //                 }
        //                 if (inner[0].status == "E911") {
        //                     cxnew.push(0)
        //                     ixnew.push(0)
        //                     troubleshootingnew.push(0)
        //                     e911new.push(inner[0].count)
        //                 }
        //                 if (inner[0].status == "Troubleshooting") {
        //                     cxnew.push(0)
        //                     ixnew.push(0)
        //                     troubleshootingnew.push(inner[0].count)
        //                     e911new.push(0)
        //                 }

        //             }

        //             if (inner.length == 0) {
        //                 cxnew.push(0)
        //                 ixnew.push(0)
        //                 troubleshootingnew.push(0)
        //                 e911new.push(0)
        //             }



        //             warvendornew.push(item._id.crewCompanyName)
        //             // console.log(cxnew, ixnew, troubleshootingnew, e911new, "innerdsadaas")



        //         }
        //     })
        // }



        // for (let i = 1; i <= 52; i++) {
        //     let weekday = "0" + i.toString()
        //     if (changeWeek == weekday) {
        //         weekks.push(<option selected value={i < 10 ? "0" + i.toString() : i.toString()}>{i < 10 ? "0" + i.toString() : i.toString()}</option>)
        //     } else {
        //         weekks.push(<option value={i < 10 ? "0" + i.toString() : i.toString()}>{i < 10 ? "0" + i.toString() : i.toString()}</option>)
        //     }
        // }


        // return { awsLabels_new,awsLabels, awsValues, opennew, closenew, vendornew, weekks, ticketsData, lessthannew, morethanenew, assignvendornew, cxnew, ixnew, troubleshootingnew, e911new, warvendornew }
        return { awsLabels_new, awsLabels, awsValues, awsValuesNew }

    })


    console.log("countcountcount",count,"count")

    let dataSource = {
        chart: {
            valueFontSize: 10,
            valuePosition: 'outside',
            caption: `Call Function Vs. # Of Call (${StartEndStr})`,
            yaxisname: "# of calls",
            captionFontSize: "14",
            theme: "candy",
            drawcrossline: "1",
            dataLabel: '1',
            dataLabelFontSize: '12',
            dataLabelFontBold: '1',
            dataLabelFontColor: '#ffffff',
            dataLabelPadding: '5',
            dataLabelPlacement: 'middle',
            plottooltext: "<b>$label</b><br>Value: $value",
            exportEnabled: true,
            exportAtClient: true,
            exportfilename: "AWS Call Logs"
        },

        categories: [
            {
                category: count?.awsLabels?.map(data => {
                    return {
                        label: data, "labelFontSize": "10"
                    }
                }) || []

            }
        ],
        dataset: [
            {
                seriesname: "Dallas Login",
                "seriesNameFontSize": "10",
                data: count?.awsValues?.dallasLogin?.map(data => {
                    return {

                        value: data,
                        ...(+data !== 0 && { showValue: "1" }) // show data value

                    }
                }) || []

            },
            {
                seriesname: "Dallas Logout",
                "seriesNameFontSize": "10",
                data: count?.awsValues?.dallasLogout?.map(data => {
                    return {

                        value: data,
                        ...(+data !== 0 && { showValue: "1" }) // show data value

                    }
                }) || []

            },
            {
                seriesname: "Dallas Outbound",
                "seriesNameFontSize": "10",
                data: count?.awsValues?.dallasOutbound?.map(data => {
                    return {

                        value: data,
                        ...(+data !== 0 && { showValue: "1" }) // show data value

                    }
                }) || []

            },
            {
                seriesname: "Dallas TSH",
                data: count?.awsValues?.dallasTsh?.map(data => {
                    return {

                        value: data,
                        ...(+data !== 0 && { showValue: "1" }) // show data value

                    }
                })

            },
        ]
    }


    let dataSource2 = {
        chart: {

            valueFontSize: 10,
            valuePosition: 'outside',
            caption: `Avg. Queue Handled Times (${StartEndStr_new})`,
            yaxisname: "Time in seconds",
            captionFontSize: "14",
            theme: "candy",
            drawcrossline: "1",
            dataLabel: '1',
            dataLabelFontSize: '12',
            dataLabelFontBold: '1',
            dataLabelFontColor: '#ffffff',
            dataLabelPadding: '5',
            dataLabelPlacement: 'middle',
            plottooltext: "<b>$label</b><br>Value: $value",
            exportEnabled: true,
            exportAtClient: true,
            exportfilename: "AWS Call Logs"
        },

        categories: [
            {
                category: count?.awsLabels_new?.map(data => {
                    return {
                        label: data, "labelFontSize": "10"
                    }
                }) || []

            }
        ],
        dataset: [
            {
                seriesname: "Dallas Login",
                "seriesNameFontSize": "10",
                data: count?.awsValuesNew?.dallasLogin?.map(data => {
                    return {

                        value: data,
                        ...(+data !== 0 && { showValue: "1" }) // show data value

                    }
                }) || []

            },
            {
                seriesname: "Dallas Logout",
                "seriesNameFontSize": "10",
                data: count?.awsValuesNew?.dallasLogout?.map(data => {
                    return {

                        value: data,
                        ...(+data !== 0 && { showValue: "1" }) // show data value

                    }
                }) || []

            },
            {
                seriesname: "Dallas Outbound",
                "seriesNameFontSize": "10",
                data: count?.awsValuesNew?.dallasOutbound?.map(data => {
                    return {

                        value: data,
                        ...(+data !== 0 && { showValue: "1" }) // show data value

                    }
                }) || []

            },
            {
                seriesname: "Dallas TSH",
                "seriesNameFontSize": "10",
                data: count?.awsValuesNew?.dallasTsh?.map(data => {
                    return {

                        value: data,
                        ...(+data !== 0 && { showValue: "1" }) // show data value

                    }
                })

            },
        ]
    }


    const chartRef1 = useRef(null);

    // Handle export of chart data to Excel
    const handleExport = () => {
        chartRef1.current.chartInstance.exportChart({
            exportFormat: 'xlsx', // Specify the export format
            exportFileName: 'monthly_sales', // Specify the export file name
            exportCallback: function (data) {
                // Handle the downloaded data
                saveAs(data, `${dataSource.dataSource.chart.caption}.xlsx`);
            }
        });
    };

    const dispatch = useDispatch()
    function accordianHandler() {
        setAccordianState(!accordianState)
    }



    function changeWeekF(e, typ = undefined) {
        if (typ == "no") {
            // console.log(e, "changeWeek")
            setChangeWeek(e)
        } else {
            // console.log(e.target.value, "changeWeek")
            setChangeWeek(e.target.value)
        }
    }

    let percent = "--";
    let type = 0;
    // console.log(count.ticketsData, "count.ticketsData")
    if (count.ticketsData) {
        if (oneTime && count.ticketsData.currentWeek) {

            setoneTime(false)
            setChangeWeek(count.ticketsData.currentWeek)
            setvwarchangeWeek(count.ticketsData.currentWeek)
            // changeWeekF(count.ticketsData.currentWeek, "no")
            // console.log(count.ticketsData.currentWeek, "count.ticketsData")
            setadingDate(count.ticketsData.adingDate)
        }


        if (count.ticketsData.yesterdayOpen == 0) {
            percent = count.ticketsData.todayOpen * 100
            type = 1
        }
        else if (count.ticketsData.todayOpen == 0) {
            percent = count.ticketsData.yesterdayOpen * 100
            type = 2
        }
        else if (count.ticketsData.todayOpen > count.ticketsData.yesterdayOpen) {
            percent = count.ticketsData.todayOpen * 100 / count.ticketsData.yesterdayOpen
            type = 1
        }
        else if (count.ticketsData.todayOpen < count.ticketsData.yesterdayOpen) {
            percent = count.ticketsData.yesterdayOpen * 100 / count.ticketsData.todayOpen
            type = 2
        }

        else if (count.ticketsData.todayOpen == count.ticketsData.yesterdayOpen) {
            percent = "100"
        }

        percent = percent + "%"
    }

    // let now = new Date();
    // let start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
    // let end = moment(start).add(1, "days").subtract(1, "seconds");
    let ranges = {
        "Today Only": [moment(start), moment(end)],
        "Yesterday Only": [moment(start).subtract(1, "days"), moment(end).subtract(1, "days")],
        "3 Days": [moment(start).subtract(3, "days"), moment(end)]
    }
    let local = {
        "format": "DD-MM-YYYY HH:mm",
        "sundayFirst": false,
        days: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'So'],
        months: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',],
        fromDate: 'From Date',
        toDate: 'To Date',
        selectingFrom: 'Selecting From',
        selectingTo: 'Selecting To',
        maxDate: 'Max Date',
        close: 'Close',
        apply: 'Apply',
        cancel: 'Cancel'
    }

    function applyCallback(startDate, endDate) {
        this.setState({
            start: startDate,
            end: endDate
        }
        )
    }

    function yearWiseCaller(stdate, from) {
        // console.log(stdate, "yearWiseCaller", "logger")

        let minDate = moment(stdate).startOf("year")
        let maxDate = moment(stdate).endOf("year")
        if (from == warRoomActivity) {
            setvwarchangeWeek(minDate.format("yyyy"))
            setwarWeekName("Year ")
        } else {
            setChangeWeek(minDate.format("yyyy"))
            setWeekName("Year ")
        }
        commonapicaller(minDate, maxDate, from)
    }

    // function rangeWiseCaller(updateDate, from) {
    //     console.warn("setDateRange", updateDate)

    //     let minDate = moment(updateDate[0])

    //     if (updateDate[1] != null) {
    //         let maxDate = moment(updateDate[1])

    //         /// code starts
    //         console.warn("iiiii =>",minDate , maxDate)
    //         const timeDiff = Math.abs(minDate.getTime() - maxDate.getTime());

    //         // Convert milliseconds to hours
    //         const hoursDiff = timeDiff / 3600000;
    //          console.warn("hoursDiff => ", hoursDiff)
    //         // If the time difference is greater than 24 hours, cancel it
    //         if (hoursDiff > 24) {
    //             return
    //         }


    //         // code ends

    //         commonapicaller(minDate, maxDate, from)
    //         if (from == warRoomActivity) {
    //             setvwarchangeWeek(minDate.format("MM/DD/yyyy") + " To " + maxDate.format("MM/DD/yyyy"))
    //             setwarWeekName("From ")
    //         } else {
    //             setChangeWeek(minDate.format("MM/DD/yyyy") + " To " + maxDate.format("MM/DD/yyyy"))
    //             setWeekName("From ")
    //         }
    //     }




    //     // console.log(dateRange, "yearWiseCaller", "logger")
    //     // console.log(dateRange, "")
    // }

    function rangeWiseCaller(updateDate, from) {
        console.warn("setDateRange", updateDate);

        let minDate = moment(updateDate[0]);

        if (updateDate[1] != null) {
            let maxDate = moment(updateDate[1]);

            /// code starts
            console.warn("iiiii =>", minDate, maxDate);
            const timeDiff = Math.abs(maxDate.valueOf() - minDate.valueOf());

            // Convert milliseconds to hours
            const hoursDiff = timeDiff / (1000 * 60 * 60);
            console.warn("hoursDiff => ", hoursDiff);

            // If the time difference is greater than 24 hours, cancel it
            if (hoursDiff > 24) {
                return;
            }

            // code ends

            commonapicaller(minDate, maxDate, from);
            if (from == warRoomActivity) {
                setvwarchangeWeek(minDate.format("MM/DD/yyyy") + " To " + maxDate.format("MM/DD/yyyy"));
                setwarWeekName("From ");
            } else {
                setChangeWeek(minDate.format("MM/DD/yyyy") + " To " + maxDate.format("MM/DD/yyyy"));
                setWeekName("From ");
            }
        }

        // console.log(dateRange, "yearWiseCaller", "logger")
        // console.log(dateRange, "")
    }

    function quaterWiseCaller(date, from) {
        let minDate = moment(date).startOf("quarter")
        let maxDate = moment(date).endOf("quarter")
        commonapicaller(minDate, maxDate, from)
        if (from == warRoomActivity) {
            setvwarchangeWeek(minDate.format("Q") + ", Year " + minDate.format("yyyy"))
            setwarWeekName("Quarter ")
        } else {
            setChangeWeek(minDate.format("Q") + ", Year " + minDate.format("yyyy"))
            setWeekName("Quarter ")
        }
        // console.log(date, "quaterWiseCaller", "logger")
    }
    function monthWiseCaller(date, from) {
        let minDate = moment(date).startOf("month")
        let maxDate = moment(date).endOf("month")
        if (from == warRoomActivity) {
            setvwarchangeWeek(minDate.format("MMMM") + ", Year " + minDate.format("yyyy"))
            setwarWeekName("Month ")
        } else {
            setChangeWeek(minDate.format("MMMM") + ", Year " + minDate.format("yyyy"))
            setWeekName("Month ")
        }
        commonapicaller(minDate, maxDate, from)
    }
    function weekWiseCaller(nowe, from) {
        console.log("awsDashboard =>", nowe, from)
        // console.log(nowe.target.value, startDate, "nowe", "")
        let minDate = moment().year(nowe.target.value.split("-")[0]).week(nowe.target.value.split("-")[1].replace("W", "")).startOf("week").isoWeekday(1)
        let maxDate = moment().year(nowe.target.value.split("-")[0]).week(nowe.target.value.split("-")[1].replace("W", "")).endOf("week").isoWeekday(1)
        // console.log(moment().week, maxDate, "maxDatemaxDate")

        if (from == awsDashboard) {
            setvwarchangeWeek(nowe.target.value.split("-")[1].replace("W", "") + ", Year " + minDate.format("yyyy"))
            setwarWeekName("Week ")
        } else {
            setChangeWeek(nowe.target.value.split("-")[1].replace("W", "") + ", Year " + minDate.format("yyyy"))
            setWeekName("Week ")
        }
        commonapicaller(minDate, maxDate, from)
    }


    function customweekWiseCaller(nowe, from) {
        console.log("awsDashboard =>", nowe, from)
        // console.log(nowe.target.value, startDate, "nowe", "")
        let minDate = moment().year(nowe.target.value.split("-")[0]).week(nowe.target.value.split("-")[1].replace("W", "")).startOf("week").isoWeekday(1)
        let maxDate = moment().year(nowe.target.value.split("-")[0]).week(nowe.target.value.split("-")[1].replace("W", "")).endOf("week").isoWeekday(1)
        // console.log(moment().week, maxDate, "maxDatemaxDate")

        if (from == awsDashboard) {
            setvwarchangeWeek(nowe.target.value.split("-")[1].replace("W", "") + ", Year " + minDate.format("yyyy"))
            setwarWeekName("Week ")
        } else {
            setChangeWeek(nowe.target.value.split("-")[1].replace("W", "") + ", Year " + minDate.format("yyyy"))
            setWeekName("Week ")
        }
        commonapicaller(minDate, maxDate, from)
    }

    function commonapicaller(apiStartDate, apiEndDate, from) {
        // console.log("loggerloggerlogger", `${from}`, `?start=${apiStartDate.format("MM/DD/YYYY_hh:mm:ss")}&end=${apiEndDate.format("MM/DD/YYYY_hh:mm:ss")}`)
        // dispatch(GetChartAction(`${from}`, `?start=${apiStartDate.format("MM/DD/YYYY_hh:mm:ss")}&end=${apiEndDate.format("MM/DD/YYYY_hh:mm:ss")}`))

        console.log(from,aws_callLogsdb,aws_callLogschartdb,"aws_callLogschartdb")
        if(from==aws_callLogsdb){
            setStartEndStr(apiStartDate.format("MM-DD-YYYY")+" To "+apiEndDate.format("MM-DD-YYYY"))
        }
        if(from==aws_callLogschartdb){
            setStartEndStr_new(apiStartDate.format("MM-DD-YYYY")+" To "+apiEndDate.format("MM-DD-YYYY"))
        }
        dispatch(GetChartAction(`${from}`, `?start=${apiStartDate.format("MM-DD-YYYY")}&end=${apiEndDate.format("MM-DD-YYYY")}`))


    }

    // let maxDate = moment(start).add(24, "hour")

    useEffect(() => {

        // console.log("ttCountVendor", ttCountVendor)
        // dispatch(GetChartAction(sitesCountCustomer, ""))
        // dispatch(GetChartAction(dashboardCustomer, ""))
        // dispatch(GetChartAction(sitesAgingCustomer, ""))
        // dispatch(GetChartAction(warRoomActivity, ""))

        // console.log("ttCountVendor", ttCountVendor)
        var currentDateR = moment();
        var weekStart = currentDateR.clone().startOf('isoWeek');
        var weekEnd = currentDateR.clone().endOf('isoWeek');

        // dispatch(GetChartAction(`${sitesCountCustomer}`, `?start=${weekStart.format("MM/DD/YYYY_hh:mm:ss")}&end=${weekEnd.format("MM/DD/YYYY_hh:mm:ss")}`))
        // dispatch(GetChartAction(ttCountVendor, ""))
        // dispatch(GetChartAction(dashboardCustomer, ""))
        // dispatch(GetChartAction(sitesAgingCustomer, ""))
        // dispatch(GetChartAction(warRoomActivity, ""))
        console.log(weekStart, weekEnd, "weekEndweekEnd")
        // weekWiseCaller()
        commonapicaller(weekStart, weekEnd, aws_callLogsdb)
        commonapicaller(weekStart, weekEnd, aws_callLogschartdb)
    }, [nochangeWeek])

    console.group()
    // console.warn(count, "count===>")
    console.groupEnd()

    console.log("count => ", count)


    return (
        <>
            {/* <div className='row mb-3'>


            <div className="col-6" style={{ padding: "10px" }}> */}
            {/* <div className='d-flex justify-content-end' style={{ background: 'rgb(38, 42, 51)', color: "white" , padding : "10px" , paddingBottom : "2px"}}>
                    <div style={{cursor: "pointer", background: "black", width: "38px", height: "38px", borderRadius: "50%", padding: "8px" }}>
                        <svg fill='white' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M4 19H20V12H22V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V12H4V19ZM14 9H19L12 16L5 9H10V3H14V9Z"></path></svg>
                    </div>
                </div> */}
            {/* <div className='form-group d-flex justify-content-start' style={{ background: 'rgb(38, 42, 51)', color: "white" }}> */}
            {/* <label className='mx-2'>Week</label>
      <select onChange={(e) => changeWeekF(e)} style={{ width: '20%' }}>
        {count.weekks}
      </select>
       */}
            {/* <div> */}
            {/* <DateTimeRangeContainer

          ranges={ranges}
          start={start}
          end={end}
          local={local}
          maxDate={maxDate}
          style={{display: 'flex', maxWidth: 'fit-content' }}
          applyCallback={applyCallback}
        >
          <FormControl
            id="formControlsTextB"
            type="text"
            label="Text"
            placeholder="Enter text"
          />
        </DateTimeRangeContainer> */}


            {/* <div className='form-group d-flex justify-content-start'> */}
            {/* <label className='mx-2'>Week</label>
                <select onChange={(e) => changeWeekF(e)} style={{ width: '20%' }}>
                  {count.weekks}
                </select>
                 */}
            {/* <div>
                  <DateTimeRangeContainer
                    smartMode={true}
                    noMobileMode={true}
                    ranges={ranges}
                    start={start}
                    end={end}
                    local={local}
                    maxDate={maxDate}
                    style={{ display: 'flex' }}
                    applyCallback={applyCallback}
                  >
                    <FormControl
                      id="formControlsTextB"
                      type="text"
                      label="Text"
                      placeholder="Enter text"
                    />
                  </DateTimeRangeContainer>
                </div> */}
            {/* <div className='form-group row' style={{margin: '0px'}}> */}


            <div className='mb-4' style={{ display: "grid", gridTemplateColumns: "repeat(2,50%)", gridTemplateRows: "repeat(5,180px)" }}>
                <div style={{ gridColumn: "span 1", gridRow: "span 3", padding: "0.4rem" }}>

                    <div className='form-group row' style={{ background: 'rgb(38, 42, 51)', color: "white" }}>

                        <div style={{ "flex-basis": '10%' }}>
                            <label style={{ color: "white" }}>Year</label>
                            <DatePicker
                                selected={warstartDate}
                                className={'newFormatting'}
                                onChange={(date) => {
                                    setwarStartDate(date)
                                    yearWiseCaller(date, aws_callLogsdb)
                                }}
                                showYearPicker
                                placeholderText='Select'
                                dateFormat="yyyy"

                            />
                        </div>
                        <div style={{ "flex-basis": '15%' }}>
                            <label style={{ color: "white" }}>Quarter</label>
                            <DatePicker
                                selected={warstartDate}
                                className={'newFormatting'}
                                onChange={(date) => {
                                    setwarStartDate(date)
                                    quaterWiseCaller(date, aws_callLogsdb)
                                }}
                                dateFormat="yyyy, QQQ"
                                placeholderText='Select'
                                showQuarterYearPicker
                            />

                        </div>
                        <div style={{ "flex-basis": '15%' }}>
                            <label style={{ color: "white" }}>Month</label>
                            <DatePicker
                                selected={warstartDate}
                                className={'newFormatting'}
                                onChange={(date) => {
                                    setwarStartDate(date)
                                    monthWiseCaller(date, aws_callLogsdb)
                                }}
                                dateFormat="MMM/yyyy"
                                placeholderText='Select'
                                showMonthYearPicker
                            />

                        </div>

                        <div style={{ "flex-basis": '25%' }}>
                            <label style={{ color: "white" }}>Week</label>

                            <div class="react-datepicker-wrapper">
                                <div class="react-datepicker__input-container">

                                    <input type='week'
                                        className={'newFormatting'}
                                        placeholder='Select Week'
                                        style={{ height: "30px" }}
                                        onChange={(date) => {
                                            weekWiseCaller(date, aws_callLogsdb)
                                        }}
                                    />
                                </div>
                            </div>

                        </div>

                        <div style={{ "width": '35%' }}>
                            <label style={{ color: "white" }}>Select Date</label>
                            <div >
                                <DatePicker
                                    selectsRange={true}
                                    className={'newFormatting'}
                                    placeholderText={"Select Date"}
                                    startDate={warrangestartDate}
                                    endDate={warrangeendDate}
                                    onChange={(update) => {
                                        setwarDateRange(update);
                                        rangeWiseCaller(update, aws_callLogsdb)
                                    }}
                                />
                            </div>


                        </div>

                    </div>


                    {/* </div>
                                </div> */}
                    {/* </div> */}
                    {/* </div> */}
                    <ReactFusioncharts
                        fcEvent-exportComplete={() => handleExport()} ref={chartRef1}
                        type="mscolumn2d"
                        width="100%"
                        height="250"
                        dataFormat="JSON"
                        dataSource={dataSource}
                    />
                    <div>
                        <table style={{ width: "100%" }} className="chartTable">
                            <thead>
                                <tr style={{ border: "1px", borderStyle: "solid", borderColor: "rgb(38, 42, 51)" }}>
                                    <td style={{ background: "rgb(38, 42, 51)", fontSize: "10px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{"Activity"}</td>
                                    {count?.awsLabels?.length ? count?.awsLabels?.map(data => <td style={{ background: "rgb(38, 42, 51)", fontSize: "10px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data || ""}</td>) : ['Contacts Handled', 'Contacts Abandoned', 'Contacts Hold Abandons', 'Contacts Transferred Out', 'Contacts Transferred Out From Queue'].map(data => <td style={{ background: "rgb(38, 42, 51)", fontSize: "10px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data || ""}</td>)}
                                </tr>
                            </thead>
                            <tbody>

                                <tr style={{ border: "1px", borderStyle: "solid", borderColor: "rgb(38, 42, 51)" }}>
                                    <td style={{ fontSize: "10px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>Dallas Login</td>
                                    {count?.awsValues?.dallasLogin?.length ? count?.awsValues?.dallasLogin?.map(data => <td style={{ fontSize: "10px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data}</td>) : Array(5).fill(" ").map(data => <td style={{ fontSize: "10px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data}</td>)}
                                </tr>
                                <tr style={{ border: "1px", borderStyle: "solid", borderColor: "rgb(38, 42, 51)" }}>
                                    <td style={{ fontSize: "10px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>Dallas Logout</td>
                                    {count?.awsValues?.dallasLogout?.length ? count?.awsValues?.dallasLogout?.map(data => <td style={{ fontSize: "10px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data}</td>) : Array(5).fill(" ").map(data => <td style={{ fontSize: "10px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data}</td>)}
                                </tr>
                                <tr style={{ border: "1px", borderStyle: "solid", borderColor: "rgb(38, 42, 51)" }}>
                                    <td style={{ fontSize: "10px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>Dallas Outbound</td>
                                    {count?.awsValues?.dallasOutbound?.length ? count?.awsValues?.dallasOutbound?.map(data => <td style={{ fontSize: "10px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data}</td>) : Array(5).fill(" ").map(data => <td style={{ fontSize: "10px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data}</td>)}
                                </tr>
                                <tr style={{ border: "1px", borderStyle: "solid", borderColor: "rgb(38, 42, 51)" }}>
                                    <td style={{ fontSize: "10px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>Dallas TSH</td>
                                    {count?.awsValues?.dallasTsh?.length ? count?.awsValues?.dallasTsh?.map(data => <td style={{ fontSize: "10px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data}</td>) : Array(5).fill(" ").map(data => <td style={{ fontSize: "10px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data}</td>)}
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
                {/* </div> */}


                {/* <div className="col-6" style={{ padding: "10px" }}> */}
                {/* <div className='d-flex justify-content-end' style={{  background: 'rgb(38, 42, 51)', color: "white" , padding : "10px" , paddingBottom : "2px"}}>
                    <div style={{ cursor: "pointer", background: "black", width: "38px", height: "38px", borderRadius: "50%", padding: "8px" }}>
                        <svg fill='white' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M4 19H20V12H22V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V12H4V19ZM14 9H19L12 16L5 9H10V3H14V9Z"></path></svg>
                    </div>
                </div> */}
                {/* <div className='form-group d-flex justify-content-start' style={{ background: 'rgb(38, 42, 51)', color: "white" }}> */}
                {/* <label className='mx-2'>Week</label>
      <select onChange={(e) => changeWeekF(e)} style={{ width: '20%' }}>
        {count.weekks}
      </select>
       */}
                {/* <div> */}
                {/* <DateTimeRangeContainer

          ranges={ranges}
          start={start}
          end={end}
          local={local}
          maxDate={maxDate}
          style={{display: 'flex', maxWidth: 'fit-content' }}
          applyCallback={applyCallback}
        >
          <FormControl
            id="formControlsTextB"
            type="text"
            label="Text"
            placeholder="Enter text"
          />
        </DateTimeRangeContainer> */}


                {/* <div className='form-group d-flex justify-content-start'> */}
                {/* <label className='mx-2'>Week</label>
                <select onChange={(e) => changeWeekF(e)} style={{ width: '20%' }}>
                  {count.weekks}
                </select>
                 */}
                {/* <div>
                  <DateTimeRangeContainer
                    smartMode={true}
                    noMobileMode={true}
                    ranges={ranges}
                    start={start}
                    end={end}
                    local={local}
                    maxDate={maxDate}
                    style={{ display: 'flex' }}
                    applyCallback={applyCallback}
                  >
                    <FormControl
                      id="formControlsTextB"
                      type="text"
                      label="Text"
                      placeholder="Enter text"
                    />
                  </DateTimeRangeContainer>
                </div> */}
                {/* <div className='row row-custom' style={{margin: '0px'}}> */}

                <div style={{ gridColumn: "span 1", gridRow: "span 2", padding: "0.4rem" }}>
                    <div className='form-group row' style={{ background: 'rgb(38, 42, 51)', color: "white" }}>
                        <div style={{ "flex-basis": '10%' }}>
                            <label style={{ color: "white" }}>Year</label>
                            <DatePicker
                                selected={warstartDate}
                                onChange={(date) => {
                                    setwarStartDate(date)
                                    yearWiseCaller(date, aws_callLogschartdb)
                                }}
                                showYearPicker
                                placeholderText='Select'
                                className='newFormatting'
                                dateFormat="yyyy"

                            />
                        </div>
                        <div style={{ "flex-basis": '15%' }}>
                            <label style={{ color: "white" }}>Quarter</label>
                            <DatePicker
                                selected={warstartDate}
                                onChange={(date) => {
                                    setwarStartDate(date)
                                    quaterWiseCaller(date, aws_callLogschartdb)
                                }}
                                dateFormat="yyyy, QQQ"
                                className='newFormatting'
                                placeholderText='Select'
                                showQuarterYearPicker
                            />

                        </div>
                        <div style={{ "flex-basis": '15%' }}>
                            <label style={{ color: "white" }}>Month</label>
                            <DatePicker
                                selected={warstartDate}
                                onChange={(date) => {
                                    setwarStartDate(date)
                                    monthWiseCaller(date, aws_callLogschartdb)
                                }}
                                dateFormat="MMM/yyyy"
                                className='newFormatting'
                                placeholderText='Select'
                                showMonthYearPicker
                            />


                        </div>
                        <div style={{ "flex-basis": '25%' }}>
                            <label style={{ color: "white" }}>Week</label>

                            <div class="react-datepicker-wrapper">
                                <div class="react-datepicker__input-container">

                                    <input type='week'
                                        placeholder='Select Week'
                                        className='newFormatting'
                                        style={{ height: "30px" }}
                                        onChange={(date) => {
                                            weekWiseCaller(date, aws_callLogschartdb)
                                        }}
                                    />
                                </div>
                            </div>

                        </div>

                        <div style={{ "flex-basis": '35%' }}>
                            <label style={{ color: "white" }}>Select Date</label>
                            <div>
                                <DatePicker
                                    selectsRange={true}
                                    className='newFormatting'
                                    placeholderText={"Select Date"}
                                    startDate={warrangestartDate}
                                    endDate={warrangeendDate}
                                    onChange={(update) => {
                                        setwarDateRange(update);
                                        rangeWiseCaller(update, aws_callLogschartdb)
                                    }}
                                />
                            </div>


                        </div>

                    </div>
                    <ReactFusioncharts
                        type="mscolumn2d"
                        width="100%"
                        height="250"
                        dataFormat="JSON"
                        dataSource={dataSource2}
                    />
                    <div>
                        <table style={{ width: "100%" }} className="chartTable">
                            <thead>
                                <tr style={{ border: "1px", borderStyle: "solid", borderColor: "rgb(38, 42, 51)" }}>
                                    <td style={{ background: "rgb(38, 42, 51)", fontSize: "10px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{"Activity"}</td>
                                    {count?.awsLabels_new?.length ? count?.awsLabels_new?.map(data => <td style={{ background: "rgb(38, 42, 51)", fontSize: "10px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data || ""}(s)</td>) : ['Contacts Handled', 'Contacts Abandoned', 'Contacts Hold Abandons', 'Contacts Transferred Out', 'Contacts Transferred Out From Queue'].map(data => <td style={{ background: "rgb(38, 42, 51)", fontSize: "10px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data || ""}</td>)}
                                </tr>
                            </thead>
                            <tbody>

                                <tr style={{ border: "1px", borderStyle: "solid", borderColor: "rgb(38, 42, 51)" }}>
                                    <td style={{ fontSize: "10px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>Dallas Login</td>
                                    {count?.awsValuesNew?.dallasLogin?.length ? count?.awsValuesNew?.dallasLogin?.map(data => <td style={{ fontSize: "10px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data}</td>) : Array(5).fill(" ").map(data => <td style={{ fontSize: "10px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data}</td>)}
                                </tr>
                                <tr style={{ border: "1px", borderStyle: "solid", borderColor: "rgb(38, 42, 51)" }}>
                                    <td style={{ fontSize: "10px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>Dallas Logout</td>
                                    {count?.awsValuesNew?.dallasLogout?.length ? count?.awsValuesNew?.dallasLogout?.map(data => <td style={{ fontSize: "10px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data}</td>) : Array(5).fill(" ").map(data => <td style={{ fontSize: "10px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data}</td>)}
                                </tr>
                                <tr style={{ border: "1px", borderStyle: "solid", borderColor: "rgb(38, 42, 51)" }}>
                                    <td style={{ fontSize: "10px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>Dallas Outbound</td>
                                    {count?.awsValuesNew?.dallasOutbound?.length ? count?.awsValuesNew?.dallasOutbound?.map(data => <td style={{ fontSize: "10px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data}</td>) : Array(5).fill(" ").map(data => <td style={{ fontSize: "10px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data}</td>)}
                                </tr>
                                <tr style={{ border: "1px", borderStyle: "solid", borderColor: "rgb(38, 42, 51)" }}>
                                    <td style={{ fontSize: "10px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>Dallas TSH</td>
                                    {count?.awsValuesNew?.dallasTsh?.length ? count?.awsValuesNew?.dallasTsh?.map(data => <td style={{ fontSize: "10px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data}</td>) : Array(5).fill(" ").map(data => <td style={{ fontSize: "10px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data}</td>)}
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
            <div style={{ visibility: "hidden" }}>
                {setTimeout(() => { document.querySelectorAll("[pointer-events='bounding-box']").forEach(data => data.style.display = "none") }, 2000)}
            </div>
        </>
    )
}

export default AwsDashboard