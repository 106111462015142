import React, { useEffect, useState } from 'react'
import { advancdExportOnecustomDataTableOptionObject, columnsArray, customDataTableOptionObject, getMuiThemeCommon } from '../../../common/variables'
import MUIDataTable from 'mui-datatables'
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { SSVApproverDashboardAPI, SSVDashboardAPI, baseURL, commonfilters, dynamic_dashboard, export_mop, ranDashboard, templatelssv, templatessvurl } from '../../../common/config';
import { AccountReducerAction, ExportXLSXDownloadReducerAction, ResetLCLLSSVReducerAction, ResetLCLSSVReducerAction, clearIxSingleSite, deleteAcccountReducerAction, getFilterDropDown, popUpUpdate } from '../../../store/actions/createTicketManageFields';
import { useDispatch, useSelector } from 'react-redux';
import CommonModal from '../Custom/CommonModal';
import FilterData from "../../../icons/Svg/FilterData";
import SSVEngineer from '../Custom/Modal Body/SSVEngineer';
import SSVDriver from '../Custom/Modal Body/SSVDriver';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import SSVModal from '../Custom/Modal Body/SSVModal';
import CommonBulkUploader from '../Custom/CommonBulkUploader';
import moment from "moment";
import { Button } from 'react-bootstrap';
import RefreshIcon from "@mui/icons-material/Refresh";
import swal from 'sweetalert';
import { common } from '../../../services/AdminService';
import SSVFilterList from '../Custom/Modal Body/SSVFilterList';
import SSSCOP from '../Custom/Modal Body/SSSCOP';
import DownloadNew from '../../../icons/Svg/DownloadNew';
const SSVDashboardApproval = () => {
  const dispatch = useDispatch();

  const [ResetFilter, setResetFilter] = useState(false);
  const [commonModalState, setcommonModalState] = useState(false);
  const [commonModalSize, setcommonModalSize] = useState(false);
  const [commonModalTitle, setcommonModalTitle] = useState("");
  const [commonModalBody, setcommonModalBody] = useState("");
  const [commonModalHeader, setcommonModalHeader] = useState("");
  const [fullUrL, setfullUrL] = useState("");
  const [exportApiData, setexportApiData] = useState("");
  const [filterBadges, setFilterBadge] = useState()
  const [ssvEngineerModal, setModalState] = useState(false)
  const [closer, setCloser] = useState(false)
  let [modalBdy, setModal] = useState(false)
  const [ssvDriveModal, setDriveModalState] = useState(false)
  const [modalBody, setModalBody] = useState({
    heading: "",
    body: ""
  })
  let rowData = []

  const CommonModalFun = (body, title, header) => {
    dispatch(popUpUpdate({}));
    setcommonModalBody(body);
    setcommonModalHeader(header);
    setcommonModalState(true);
  };

  const deleteHandler = (e, url) => {
    console.log("button delete clicked", e)

    swal({
      title: "Are you sure?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          console.log("the will dele=", willDelete)
          setModalState(true)
          dispatch(deleteAcccountReducerAction(url, e))
          dispatch(AccountReducerAction(SSVApproverDashboardAPI));
        }
      });
  }
  const exportAllData = () => {
    dispatch(ExportXLSXDownloadReducerAction(templatessvurl, "SSVTemplate_" + moment(new Date()).format("MM-DD-YYYY")))
  }

  function filterhandler() {

    dispatch(getFilterDropDown([]))
    // dispatch(AccountReducerAction(ssvfilters))
    dispatch(AccountReducerAction(commonfilters))
    setcommonModalState(true)
    setcommonModalSize("xl")
    setcommonModalBody(<SSVFilterList setfullUrL={setfullUrL} setcommonModalState={setcommonModalState} setFilterBadge={setFilterBadge} featureExport={setexportApiData} />)
    setcommonModalTitle("SSV Filters")
    setcommonModalHeader("SSV Filter")
  }
  let count = useSelector((state) => {
    console.log(state.popUpState.popstatus,"state.popUpState.popstatus")
    if (state.popUpState) {
      if ((state.popUpState.popstatus.status == 201 && commonModalState)) {
        dispatch(popUpUpdate({}));
        setModalState(false);
        setcommonModalState(false)
        dispatch(AccountReducerAction(SSVApproverDashboardAPI))
      }
    }
    // console.log("nj,bkjcbhshudkcbdfhbfvbdvf====",dashboardData)
    let sitesdata = state.CommonResponse.SSVApproverDashboardData
    if (sitesdata && sitesdata.length > 0) {
      rowData = sitesdata.map((item) => {
        // item["siteID"]=item.dtResult?item.dtResult.siteName:""
        // item['projectId']=item.dtResult?item.dtResult.projectId:""

        
        item["SSSCOPApprovalBy"]=item?.approverresult?.name

        if (item["projectUniqueId"] && item["projectUniqueId"].length > 0) {
          let projectUniqueIdFlag = []
          item["projectUniqueId"].map((itm) => {
            projectUniqueIdFlag.push(itm.projectId)
          })
          let planTypeData = []
          item["projectUniqueId"].map((itm) => {
            planTypeData.push(itm.projectCode)
          })

          // item["projectUniqueIdFlag"]=item["projectUniqueId"][0].projectId
          item["projectUniqueIdFlag"] = projectUniqueIdFlag.join(", ")

          item["planType"] = planTypeData.join(", ")

          item["vendor"] = item["projectUniqueId"][0].HardCostVendor
          item["projectType"] = item["projectUniqueId"][0].projectType
        }


        item["gcdata"] = item["crew_status"] ? item["crew_status"].name : ""
        item['ssvEngineermodal'] = <p className='badge badge-primary'
          onClick={() => { setModalState(true); setModalBody({ body: <SSVEngineer id={item.ssvUniqueId} />, heading: "SSV Details" }) }}>{item.ssvEngineer ? item.ssvEngineer : "SSV Details"}</p>
        item["driveDateModal"] = <p className='badge badge-primary' onClick={() => { setModalState(true); setModalBody({ body: <SSVDriver id={item.ssvUniqueId} />, heading: "Drive Test" }) }}>{item.driveDate ? item.driveDate : "Drive Test"}</p>
        // item["ssvSubmitDateFlag"] = <p
        //   className='badge badge-primary'
        //   onClick={() => { setModalState(true); setModalBody({ body: <SSVModal id={item.ssvUniqueId} />, heading: "Update SSV" }) }}
        // >
        //   {
        //     item.ssvSubmitDate ? item.ssvSubmitDate : "Update SSV"}
        // </p>

        item["SSVFileUploadNew"]=<p>
          {item.SSVFileUpload ?
          <a href={baseURL + "/uploads/" + item.SSVFileUpload} className="" download><DownloadNew /></a>
          : ""
        }
        </p>
        item["SSSCOPStageUpdate"] = <p

          className='badge mx-auto badge-success'
          onClick={() =>
            CommonModalFun(
              // <LockUnlockModalBody apiType={"request"} oldData={[]} siteIdData={item.siteId} ranIdData={item.ranSupportId} />
              <SSSCOP valueFrom={"SSV"} from={"ssv"} id={item.ssvUniqueId} />
              ,
              "Update",
              "Update"
            )
          }
        >
          Update
        </p>

        item["ssvSubmitDateFlag"] = item.ssvSubmitDate
        return (item)
      })
      console.log("sdcdscdscfdsfc=", rowData)
    }
    return ({ rowData })
  })

  const ClearFilter = () => {
    // alert("ClearFilter")
    console.log("ClearFilter", "ClearFilter");
    // setfstartdate();
    // setfenddate();
    setResetFilter(true)

    setFilterBadge()
    let Filleddata = dispatch(ResetLCLSSVReducerAction())
    dispatch(AccountReducerAction(SSVApproverDashboardAPI + Filleddata, true));

    // dispatch(AccountReducerAction(listennoTicketApi+Filleddata,true,true));
  };



  let AddSSV = () => (
    <>

      {/* <p onClick={filterhandler} className=" mx-2 " style={{ "align-self": "center" }}><FilterData /></p>
      <button
        className="btn btn-xs btn-primary mx-2 my-3"
        data-toggle="tooltip"
        data-placement="bottom"
        title="Refresh"
        onClick={() => { ClearFilter(); }}
        style={{ padding: "6px" }}
      >
        <RefreshIcon />
      </button>
      <Button onClick={exportAllData} className="btn btn-xs btn-primary mx-2 py-2">
        Template
      </Button>
      <Link to="/PAG/ssv/add" className="btn btn-xs btn-primary mx-2 py-2">Add SSV</Link>
      <CommonBulkUploader styles={{ marginBottom: "1rem" }} typeForm={"SSV  Bulk Uploads"} shortCode={"Bulk_SSV"} afterLoad={"/ssv" + dynamic_dashboard} classes={'btn btn-xs btn-primary mx-2 my-3 py-2'} /> */}
    </>
  )

  // console.log("thjcvojdfnvijfsrn=",columnsArray("Site ID	Plan Type	Project ID	Project Type	HC Vendor	GC	SSV Engineer	RTT Date	RTT lookup frm 72 Hr Tracker	Unlock Date	Drive Date	Drive Status	Drive test person	Drive route & site details status	Drive route Sent Date	SSV Submit Date	SSV Appoval Date	SSV Status (Pending, Submitted, Approved)	Comments/issue	re submitted  date"))
  // let columns = [
  //   {
  //     "name": "siteId",
  //     "label": "Site ID",
  //     "sortable": false,
  //     "options": {
  //       filter: true,
  //       className: "abcd",
  //       setCellHeaderProps: () => ({
  //         style: {
  //           whiteSpace: "wrap",
  //           left: "0",
  //           alignItem: "center",
  //           padding: "1px 1px",
  //           background: "#143b64",
  //           minWidth: "140px",
  //           color: "white",
  //           position: "sticky",
  //           zIndex: 101,
  //           outline: "1.5px solid black"
  //         },
  //       }),
  //       setCellProps: () => ({
  //         style: {
  //           whiteSpace: "wrap",
  //           left: "0",
  //           overflowY: "hidden",
  //           minWidth: "140px",
  //           padding: "1px 1px",
  //           position: "sticky",
  //           background: "White",
  //           zIndex: 100,
  //         },
  //       }),
  //     }
  //   },
  //   {
  //     "name": "planType",
  //     "label": "Plan Type",
  //     "sortable": false,
  //     "options": {
  //       filter: true,
  //       className: "abcd",
  //       setCellHeaderProps: () => ({
  //         style: {
  //           whiteSpace: "wrap",
  //           left: "0",
  //           alignItem: "center",
  //           padding: "1px 1px",
  //           background: "#143b64",
  //           minWidth: "140px",
  //           color: "white",
  //           position: "sticky",
  //           zIndex: 100,
  //           outline: "1.5px solid black"
  //         },
  //       }),
  //       setCellProps: () => ({
  //         style: {
  //           whiteSpace: "wrap",
  //           left: "0",
  //           overflowY: "hidden",
  //           minWidth: "140px",
  //           padding: "1px 1px",
  //           position: "sticky",
  //           background: "White",
  //           zIndex: 99,
  //         },
  //       }),
  //     }
  //   },
  //   {
  //     "name": "projectUniqueIdFlag",
  //     "label": "Project ID",
  //     "sortable": false,
  //     "options": {
  //       filter: true,
  //       className: "abcd",
  //       setCellHeaderProps: () => ({
  //         style: {
  //           whiteSpace: "wrap",
  //           left: "0",
  //           alignItem: "center",
  //           padding: "1px 1px",
  //           background: "#143b64",
  //           minWidth: "140px",
  //           color: "white",
  //           position: "sticky",
  //           zIndex: 100,
  //           outline: "1.5px solid black"
  //         },
  //       }),
  //       setCellProps: () => ({
  //         style: {
  //           whiteSpace: "wrap",
  //           left: "0",
  //           overflowY: "hidden",
  //           minWidth: "140px",
  //           padding: "1px 1px",
  //           position: "sticky",
  //           background: "White",
  //           zIndex: 99,
  //         },
  //       }),
  //     }
  //   },
  //   // {
  //   //   "name": "ssvEngineermodal",
  //   //   // "label": "SSV Details",
  //   //   "label": "SSV submit",
  //   //   "sortable": false,
  //   //   "options": {
  //   //     filter: true,
  //   //     print: false,
  //   //     className: "abcd",
  //   //     setCellHeaderProps: () => ({
  //   //       style: {
  //   //         whiteSpace: "wrap",
  //   //         left: "0",
  //   //         alignItem: "center",
  //   //         padding: "1px 1px",
  //   //         background: "#143b64",
  //   //         minWidth: "140px",
  //   //         color: "white",
  //   //         position: "sticky",
  //   //         zIndex: 100,
  //   //         outline: "1.5px solid black"
  //   //       },
  //   //     }),
  //   //     setCellProps: () => ({
  //   //       style: {
  //   //         whiteSpace: "wrap",
  //   //         left: "0",
  //   //         overflowY: "hidden",
  //   //         minWidth: "140px",
  //   //         padding: "1px 1px",
  //   //         position: "sticky",
  //   //         background: "White",
  //   //         zIndex: 99,
  //   //       },
  //   //     }),
  //   //   }
  //   // },
  //   {
  //     "name": "ssvSubmitDateFlag",
  //     "label": "SSS COP Submit Date",
  //     "sortable": false,
  //     "options": {
  //       filter: true,
  //       print: false,
  //       className: "abcd",
  //       setCellHeaderProps: () => ({
  //         style: {
  //           whiteSpace: "wrap",
  //           left: "0",
  //           alignItem: "center",
  //           padding: "1px 1px",
  //           background: "#143b64",
  //           minWidth: "140px",
  //           color: "white",
  //           position: "sticky",
  //           zIndex: 100,
  //           outline: "1.5px solid black"
  //         },
  //       }),
  //       setCellProps: () => ({
  //         style: {
  //           whiteSpace: "wrap",
  //           left: "0",
  //           overflowY: "hidden",
  //           minWidth: "140px",
  //           padding: "1px 1px",
  //           position: "sticky",
  //           background: "White",
  //           zIndex: 99,
  //         },
  //       }),
  //     }
  //   },
  //   {
  //     "name": "SSVApprovalDate",
  //     "label": "SSS COP Approval Date",
  //     "sortable": false,
  //     "options": {
  //       filter: true,
  //       className: "abcd",
  //       setCellHeaderProps: () => ({
  //         style: {
  //           whiteSpace: "wrap",
  //           left: "0",
  //           alignItem: "center",
  //           padding: "1px 1px",
  //           background: "#143b64",
  //           minWidth: "140px",
  //           color: "white",
  //           position: "sticky",
  //           zIndex: 100,
  //           outline: "1.5px solid black"
  //         },
  //       }),
  //       setCellProps: () => ({
  //         style: {
  //           whiteSpace: "wrap",
  //           left: "0",
  //           overflowY: "hidden",
  //           minWidth: "140px",
  //           padding: "1px 1px",
  //           position: "sticky",
  //           background: "White",
  //           zIndex: 99,
  //         },
  //       }),
  //     }
  //   },
    
  //   {
  //     "name": "SSVStatus",
  //     "label": "SSV Status (Pending, Submitted, Approved)",
  //     "sortable": false,
  //     "options": {
  //       filter: true,
  //       className: "abcd",
  //       setCellHeaderProps: () => ({
  //         style: {
  //           whiteSpace: "wrap",
  //           left: "0",
  //           alignItem: "center",
  //           padding: "1px 1px",
  //           background: "#143b64",
  //           minWidth: "240px",
  //           color: "white",
  //           position: "sticky",
  //           zIndex: 100,
  //           outline: "1.5px solid black"
  //         },
  //       }),
  //       setCellProps: () => ({
  //         style: {
  //           whiteSpace: "wrap",
  //           left: "0",
  //           overflowY: "hidden",
  //           minWidth: "140px",
  //           padding: "1px 1px",
  //           position: "sticky",
  //           background: "White",
  //           zIndex: 99,
  //         },
  //       }),
  //     }
  //   },
  //   {
  //     "name": "SSVCommentIssue",
  //     "label": "Comments/issue",
  //     "sortable": false,
  //     "options": {
  //       filter: true,
  //       className: "abcd",
  //       setCellHeaderProps: () => ({
  //         style: {
  //           whiteSpace: "wrap",
  //           left: "0",
  //           alignItem: "center",
  //           padding: "1px 1px",
  //           background: "#143b64",
  //           minWidth: "140px",
  //           color: "white",
  //           position: "sticky",
  //           zIndex: 100,
  //           outline: "1.5px solid black"
  //         },
  //       }),
  //       setCellProps: () => ({
  //         style: {
  //           whiteSpace: "wrap",
  //           left: "0",
  //           overflowY: "hidden",
  //           minWidth: "140px",
  //           padding: "1px 1px",
  //           position: "sticky",
  //           background: "White",
  //           zIndex: 99,
  //         },
  //       }),
  //     }
  //   },{
  //     "name": "SSSCOPStageUpdate",
  //     "label": "Update",
  //     "sortable": false,
  //     "options": {
  //       filter: true,
  //       className: "abcd",
  //       setCellHeaderProps: () => ({
  //         style: {
  //           whiteSpace: "wrap",
  //           alignItem: "center",
  //           padding: "1px 1px",
  //           background: "#143b64",
  //           minWidth: "120px",
  //           color: "white",
  //           position: "sticky",
  //           zIndex: 100,
  //           outline: "1.5px solid black"
  //         },
  //       }),
  //       setCellProps: () => ({
  //         style: {
  //           whiteSpace: "wrap",
  //           overflowY: "hidden",
  //           minWidth: "120px",
  //           padding: "1px 1px",
  //           position: "sticky",
  //           background: "White",
  //           zIndex: 99,
  //         },
  //       }),
  //     }
  //   },
  // ]

  let columns = [
    {
      "name": "siteId",
      "label": "Site ID",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            left: "0px",
            color: "white",
            position: "sticky",
            zIndex: 101,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 100,
            left: "0px",
          },
        }),
      }
    },
    {
      "name": "planType",
      "label": "Plan Type",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "projectUniqueIdFlag",
      "label": "Project ID",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "ssvSubmitDate",
      "label": "SSV COP Submit Date",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        print: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },


    {
      "name": "ssvRttDate",
      "label": "RTT Date",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },

    {
      "name": "ssvUUUDate",
      "label": "UUU Date",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    
    {
      "name": "SSSCOPApprovalDate",
      "label": "SSV COP Approval Date",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "SSSCOPApprovalBy",
      "label": "SSV COP Approval By",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    
    {
      "name": "SSSCOPStatus",
      "label": "SSV COP Status",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "RemarksLSSVSSSCOPStatus",
      "label": "Remarks",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "240px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },

    {
      "name": "SSVFileUploadNew",
      "label": "File",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "120px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "120px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "SSSCOPStageUpdate",
      "label": "Update",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "120px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "120px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
  ]


  useEffect(() => {
    dispatch(AccountReducerAction(SSVApproverDashboardAPI));
  }, [])
  return (
    <div>
      <CommonModal
        modalSize={'md'}
        setXlShow={setModalState}
        xlShow={ssvEngineerModal}
        Body={modalBody.body}
        Header={modalBody.heading}
      />
      <CommonModal
        modalSize={commonModalSize}
        setXlShow={setcommonModalState}
        xlShow={commonModalState}
        Body={commonModalBody}
        Header={commonModalHeader}
        Title={commonModalTitle}
      ></CommonModal>

      <ThemeProvider theme={getMuiThemeCommon()}>
        <MUIDataTable
          data={count.rowData}
          columns={columns}
          options={advancdExportOnecustomDataTableOptionObject(AddSSV, true, "ranDashbord",)}
        />
      </ThemeProvider>
    </div>
  )
}

export default SSVDashboardApproval