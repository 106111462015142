import React, { useRef,useState } from 'react'
import MUIDataTable from "mui-datatables";
import { useEffect } from 'react';
import { Row, Card, Col,Dropdown, Button, Modal, Container } from "react-bootstrap";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { accountApi, addMarketApi, deleteAcccount, deleteMarket, getMarket, timeZoneApi } from '../../../../common/config';
import { advancdExportOnecustomDataTableOptionObject, customDataTableOptionObject, getMuiThemeCommon } from "../../../../common/variables";
import swal from 'sweetalert';
import { addMarketReducerAction,AccountReducerAction,OneAccountReducerAction, addAcccountReducerAction,deleteAcccountReducerAction, updateAcccountReducerAction, GetMarketReducerAction, popUpUpdate } from '../../../../store/actions/createTicketManageFields';
import { useDispatch,useSelector } from 'react-redux';
import { timeZones } from '../../../../common/variables';
import ManageTimeZone from './ManageTimeZone';
import CustomeModal from '../../Custom/CustomeModal';
import RSSIVSWR from '../../Custom/Modal Body/RSSIVSWR';
import CommonModal from '../../Custom/CommonModal';
import SFP from '../../Custom/SFP';
const ManageMarket = () => {
    let accountArry =[]
    let timezoneArry =[]

    
    // let Options =useSelector((state)=>{
      
      
    // })

    // let accountOptions =useSelector((state)=>{
      
    //     let account=state.CommonResponse.accounts
    //     if(account !=0){
    //       accountArry=account.map((item)=>{
    //         return <option value={item.uniqueid}>{item.accountName}</option>
    //       })
    //     }
      
    //   return accountArry
    //   })
      // console.log("Account OPtion=",accountOptions)
      // console.log("All OPtion=",Options)

      
   const [modalCentered, setModalCentered] = useState(false);
   const [closer, setCloser] = useState(false);
   const [dValue,setDvalue] =useState('')
   const [rowId,setRowId]=useState('')
   const [errors, setErrors] = useState({});
   const [checkAddUpdate,setAddUpdate] =useState(true)
   const [reqPreModal, setReqPreModal] = useState(false);
   const [rssiModal,setRssIModal] =useState(false)
   const [modalBody,setModalBody] =useState()
  //  const [newAccount,setAccount]=useState('')
      const newMarket=useRef()
      const accountRef =useRef()
      const eosRecieverRef =useRef()
      const timeZoneRef =useRef()
   let oneMarket=""
   
   const timeZonesOptions=timeZones.map((zones)=>{
    return <option value={zones}>{zones}</option>
   })
   // setAccount(oneaccount)
   console.log(oneMarket,"oneaccount")
    const getMuiTheme = () => createTheme({
      components: {
        MUIDataTableBodyCell: {
          styleOverrides:{
            root: {
                backgroundColor: "transparent"
            }
          }
        }
      }
    })
    const updateHandler =(e)=>{
        console.log("button update clicked",e)

        dispatch(updateAcccountReducerAction(addMarketApi,e,{marketName:newMarket.current.value,account:accountRef.current.value,timezone :"63aaebbc1a9fe73c55656e23"}))

    }
    const deleteHandler =(e)=>{
      console.log("button delete clicked",e)
      swal({
        title: "Are you sure?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if(willDelete){
          dispatch(deleteAcccountReducerAction(deleteMarket,e))
          setCloser(true)
        }
        });
      
    }
    
    const editpopupHandler =(e)=>{
     console.log("lkm kljnkjnjkbi;ki=",e)
      console.log("button editpopupHandler clicked",e)
      
      setErrors({})
      setModalCentered(true)
 
    }
    const updateAccount =()=>{
      console.log("object id =",rowId)
      let ctr=0
      let errorr ={}
      if(newMarket.current.value==""){
        errorr["market"]="Market is Manodatry"
        // return false
        ctr=1
      }
      if(eosRecieverRef.current.value==""){
        errorr["eosReciever"]="EOS Reciever is Manodatry"
        // return false
        ctr=1
      }

      if(ctr==1){
        setErrors(errorr)
        return false
      }
      
      dispatch(updateAcccountReducerAction(addMarketApi,rowId,{marketName:newMarket.current.value,account:accountRef.current.value,timezone :timeZoneRef.current.value,eosReciever:eosRecieverRef.current.value}))
      
      // setModalCentered(false)
          setCloser(true)
    }
    const addMarket=()=>{
        let ctr=0
        let errorr ={}
        console.log("Add Account=",newMarket.current.value," account value=",accountRef.current.value)
            // newAccount.current.value=''
            if(newMarket.current.value==""){
              errorr["market"]="Market is Manodatry"
              // return false
              ctr=1
            }

            if(eosRecieverRef.current.value==""){
              errorr["eosReciever"]="EOS Reciever is Manodatry"
              // return false
              ctr=1
            }

            if(ctr==1){
              setErrors(errorr)
              return false
            }
      
          dispatch(addMarketReducerAction(addMarketApi,{marketName:newMarket.current.value,account:accountRef.current.value,timezone :timeZoneRef.current.value,eosReciever:eosRecieverRef.current.value}))
          // setModalCentered(false)
          // newMarket.current.value=''

          console.log("input field=",newMarket)
          // dispatch(GetMarketReducerAction(getMarket))
          // setModalCentered(false)
          setCloser(true)

    }
    const dispatch =useDispatch();
    let marketArry =[]

    let data =useSelector((state)=>{


      if (state.popUpState) {
        console.log(state.popUpState.popstatus.status,closer,"popUpStatestatus")
        if ((state.popUpState.popstatus.status == 201 && closer)||(state.popUpState.popstatus.status == 200 && closer)) {
          setCloser(false)
          dispatch(popUpUpdate({}))
          dispatch(GetMarketReducerAction(getMarket))
          setModalCentered(false)
          setErrors({})
        }
      }

      let account=state.CommonResponse.accounts
      // if(account !=0){
        accountArry=[]
        accountArry=account.map((item)=>{
          return <option value={item.uniqueid}>{item.accountName}</option>
        })
      // }
      let timezone=state.CommonResponse.timeZones
      // if(timezone !=0){
        timezoneArry=[]
        timezoneArry=timezone.map((item)=>{
          return <option value={item.uniqueid}>{item.zone}</option>
        })
      // }
    
        console.log("dfwef=",state)
        let availableMarkets=state.CommonResponse.markets
        if(availableMarkets.length !=0){
          oneMarket=availableMarkets[0].marketName
        }

        let markets=state.CommonResponse.markets
        console.log("response =dscvfsfvd=",state.CommonResponse)
        if(markets.length !=0){
            marketArry=markets.map((item,index)=>{
            return {
                    sNo:index+1,
                    account :item.account,
                    market :item.marketName,
                    timezone :item.timezone,
                    eosReciever:item.eosReciever,
                    action   :<><button className="btn btn-primary shadow btn-xs sharp mr-1 editButton" onClick={() =>{ editpopupHandler(item);setErrors({}); setDvalue(item);setAddUpdate(false);setRowId(item.uniqueid)}}><i className="fa fa-pencil"></i></button>
                    <button onClick={(e)=>deleteHandler(item.uniqueid)} className="btn btn-danger shadow btn-xs sharp deleteButton"><i className="fa fa-trash"></i></button></>,
                    RSSIVSWR:<button className="btn btn-primary shadow btn-xs sharp mr-1 editButton" onClick={() =>{ setRssIModal(true);setModalBody(<RSSIVSWR/>)}}><i className="fa fa-pencil"></i></button>,
                    sfp:<SFP/>
                }
          })
        }
        
      
        console.log(marketArry)
      
      return {marketArry,accountArry,timezoneArry}
      })

      const noOfRow=data.marketArry.length
      // data.accountArry.push(
      //   {   
      //       sNo:noOfRow+1,
      //       account:<input type='text' placeholder='Add Account' value={newAccount} onChange={(e)=>setAccount(e.target.value)}/>,
      //       edit : <button onClick={()=>addAccount()}>Add Account</button>
            
      //   }
      // )

    console.log("data =",data)
    useEffect(()=>{
        dispatch(GetMarketReducerAction(getMarket))
        dispatch(AccountReducerAction(accountApi))
        // dispatch(AccountReducerAction(timeZoneApi))
    },[])
    const columns = [
        {
      
         name: "sNo",
         label: "S.no",
         options: {
          filter: false,
          sort: false,
          searchable:false,
          setCellHeaderProps: () => ({
            style: {
                whiteSpace: "wrap",
                alignItem:"center",
              background:"#143b64",
              color:"white",
              width:"20px",
  
            }
          })
         }
        },
        {
      
          name: "account",
          label: "Account",
          options: {
           filter: true,
           setCellHeaderProps: () => ({
             style: {
                 whiteSpace: "wrap",
                 alignItem:"center",
               background:"#143b64",
               width:"60px",
               color:"white"
   
             }
           })
          }
         },
        {
         name: "market",
         label: "Market Name",
         options: {
          filter: true,
          setCellHeaderProps: () => ({
            style: {
                whiteSpace: "wrap",
                alignItem:"center",
              background:"#143b64",
              width:"60px",
              color:"white"
  
            }
          })
         }
        },
        {
      
          name: "timezone",
          label: "Time Zone",
          options: {
           filter: true,
           setCellHeaderProps: () => ({
             style: {
                 whiteSpace: "wrap",
                 alignItem:"center",
                 width:"60px",
               background:"#143b64",
               color:"white"
   
             }
           })
          }
         },
        {
         name: "eosReciever",
         label: "Email Id for EOS Mail Receiver ",
         options: {
          filter: true,
          setCellHeaderProps: () => ({
            style: {
                whiteSpace: "wrap",
                alignItem:"center",
                width:"130px",
              background:"#143b64",
              color:"white"
  
            }
          })
         }
        },{
          name: "action",
          label: "Action ",
          options: {
            filter: false,
            sort: false,
            print:false,
            searchable:false,
           setCellHeaderProps: () => ({
             style: {
                 whiteSpace: "wrap",
                 alignItem:"center",
                 width:"130px",
               background:"#143b64",
               color:"white"
   
             }
           })
          }
         },
       ];
       let row=[];
       const handleChange = (e) => {
        console.log("the current anser= ", e.target.value)
      }
    
      const AddCustomButton = () => (
        <fregment style={{ float: "right", display: "flex", flexDirection: row, justifyContent: "space-between" }}>
         <Button
          variant="primary btn-xs "
          type="button"
          className="btn btn-primary btn-xs mx-2 py-2 my-3"
          onClick={() =>{ setModalCentered(true);setDvalue('') ;setAddUpdate(true)}}>
            Add Market
          </Button>
          <Modal className="fade" show={modalCentered}>
            <Modal.Header>
                {checkAddUpdate?<Modal.Title>Add Market</Modal.Title>:<Modal.Title>Update Market</Modal.Title>}
                <Button
                  onClick={() => setModalCentered(false)}
                  variant=""
                  className="close">
                <span>&times;</span>
                </Button>
                </Modal.Header>
                <Modal.Body>
                <div className="form-group col-md-12">
                  <label htmlFor="inputCity">Market</label>
                  <input type='text' className='form-control' placeholder='Add Market' ref={newMarket} defaultValue={dValue.marketName}/>
                  {errors && errors.market ? <p className="error">{errors.market}</p> : ""}
                </div>  
                <div className="form-group col-md-12">
                  <label htmlFor="inputCity">Account</label>
                  <select
                    className="form-control"
                    id="exampleFormControlSelect1"
                    ref ={accountRef}
                    >
                    
                    {/* {accountOptions} */}
                    {data.accountArry}

                  </select>
                </div>


                <div className="form-group col-md-12">
                
                  <label htmlFor="inputCity">Timezone</label>
                  <select
                      className="form-control"
                      id="exampleFormControlSelect1"
                      ref ={timeZoneRef}
                      value={dValue.timezoneId}
                      >
                      
                      {/* {timeZonesOptions} */}
                      {data.timezoneArry}

                  </select>
                </div>

                <div className="form-group col-md-12">
                
                  <label htmlFor="inputCity">EOS Reciever E-Mail Id</label>
                  <input type='text' className='form-control' placeholder='Add EOS Reciever E-Mail Id separate by comma' ref={eosRecieverRef} defaultValue={dValue.eosReciever}/>
                  {errors && errors.eosReciever ? <p className="error">{errors.eosReciever}</p> : ""}
                </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                  onClick={() => setModalCentered(false)}
                  variant="danger btn-xs"
                >
                  Close
                </Button>
                {checkAddUpdate?<Button onClick={()=>{addMarket()}} variant="primary btn-xs">Save changes</Button>
                  :<Button onClick={()=>{updateAccount()}} variant="primary btn-xs">Update changes</Button>  
              }
                </Modal.Footer>
            </Modal>
              
        </fregment>
      )
    //    const data = [
    //     { name: "Joe James", company: "In Progress", city: "Yonkers", state: "NY" },
    //     { name: "John Walsh", company: "Completed", city: "Hartford", state: "CT" },
    //     { name: "Bob Herm", company: "Awaited", city: "Tampa", state: "FL" },
    //     { name: "James Houston", company: "NA", city: "Dallas", state: "TX" },
    //    ];
       
       const options = {
        download:"false",
         filterType: 'none',
         selectableRows: false
       };
      
  return (
    <>
     <ManageTimeZone/>
     <CommonModal
              modalSize={"xm"}
              xlShow={rssiModal}
              setXlShow={setRssIModal}
              Header={"RSSI/VSWR"}
              Body={modalBody}
              // uniqueId={''}
              Title={'Post Validation'}
    />

		  <ThemeProvider theme={getMuiThemeCommon()}>

        <MUIDataTable
        
           title={"ManageMarket"}
            data={data.marketArry}
            columns={columns}
            // options={customDataTableOptionObject(AddCustomButton,true,"Market")}
            options={advancdExportOnecustomDataTableOptionObject(AddCustomButton,true,"Market_","",false,"Market Name",false)}
            
        />
      </ThemeProvider>
    </>
  )
}

export default ManageMarket