import React, { useEffect, useState } from "react";
import { logout } from "../store/actions/AuthActions";

const useLogout = (startTime,dispatch,history) => {
  const [timer, setTimer] = useState(startTime);

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (timer > 0) {
        console.log("hello","helloamit",timer)
        setTimer(timer - 1);
      }
    }, 10000);
    // console.log(history.location.pathname)
    if(history.location.pathname != "/login"){
        if(timer==0){
            // console.log(history)
            dispatch(logout(history))
        }
    }
    
    const resetTimeout = () => {
      setTimer(startTime);
    };
    const events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress"
    ];
    for (let i in events) {
      window.addEventListener(events[i], resetTimeout);
    }
    return () => {
      clearInterval(myInterval);
      for (let i in events) {
        window.removeEventListener(events[i], resetTimeout);
      }
    };
  });
  return timer;
};

export default useLogout;
