import React,{useState,useEffect} from 'react'
import { useSelector } from 'react-redux';

const Table3 = ({id}) => {
      
const[data,setData]=useState([]);
let siteInfo={}
let siteDetail =useSelector((state)=>{
  console.log("the url =",state)
let site =state.OneCommonResponse.singleSite

if(typeof(site)== typeof({}) ){
  siteInfo=site
  console.log("th e sit e is sit=",siteInfo)
  return {siteInfo}
}

})

  return (
    <div>  <table className="table-bordered" style={{ width: "100%"}}>
          
    <tr>
      <th>Precheck Engineer</th>
      <td >{siteDetail? siteDetail.siteInfo.preCheckEngineer?siteDetail.siteInfo.preCheckEngineer:"":""}</td>
    </tr>
        
    <tr>
      <th >UMTS IP Contact</th>
      <td ></td>
    </tr>
    
    <tr>
      <th >UMTS Alarm</th>
      <td >{siteDetail? siteDetail.siteInfo.UMTSAlarm?siteDetail.siteInfo.UMTSAlarm:"":""}</td>
    </tr>
    <tr>
      <th >LTE IP Contact</th>
      <td >5</td>
    </tr>
    
    <tr>
      <th >LTE Alarm</th>
      <td >{siteDetail? siteDetail.siteInfo.LTEAlarm?siteDetail.siteInfo.LTEAlarm:"":""}</td>
    </tr>
    
    <tr>
      <th>5G IP Contact</th>
      <td ></td>
    </tr>
    <tr>
      <th >5G Alarm</th>
      <td >{siteDetail? siteDetail.siteInfo['5GAlarm']?siteDetail.siteInfo['5GAlarm']:"":""}</td>
    </tr>

    <tr>
      <th >BSC IP Contact</th>
      <td ></td>
    </tr>

    <tr>
      <th >GSM Alarm</th>
      <td >{siteDetail? siteDetail.siteInfo.GSMAlarm?siteDetail.siteInfo.GSMAlarm:"":""}</td>
    </tr>

    <tr>
      <th >Login Precheck Deliver Date</th>
      <td >{siteDetail? siteDetail.siteInfo.logInTimePreCheckDelivered?siteDetail.siteInfo.logInTimePreCheckDelivered:"":""}</td>
    </tr>
     { data .map((item) => (  
    <tr>
      <th >Precheck Alarm Issue</th>
      <td >{siteDetail? siteDetail.siteInfo.precheckAlarmIssue?siteDetail.siteInfo.precheckAlarmIssue:"":""}</td>
    </tr>
     ))}
    
  </table></div>
  )
}

export default Table3;