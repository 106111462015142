import React, { useEffect, useState, ComponentType, type } from "react";
import { Button, Dropdown } from "react-bootstrap";
import "../../../css/customStyle.css";
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { exportToCSV, ExportToExcel } from "../../../common/ExportToExcel";
import DatePicker from "react-datepicker";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Row, Col, Card, Nav } from 'react-bootstrap'
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { DateRange } from 'react-date-range';
import swal from 'sweetalert';
import moment from "moment"
import {
  NetworkDatabaseAction,
  CSVUploadAction,
} from "../../../store/actions/dashboardActions";
import { useHistory } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { google_map_api, center, dbdatetodate,customDataTableOptionObject, getMuiThemeCommon, advancdcustomDataTableOptionObject, dateOnly, dbdatetomoment, more_info } from "../../../common/variables";
import { AccountReducerAction, addUserAction, clearFetchSingleRecordAction, ExportXLSXDownloadReducerAction, FetchSingleRecordAction, GetennoProject, getFilterDropDown, GetTimeLineAction, LCLENOOReducerAction, LCLTTReducerAction, ResetLCLENOOReducerAction, ResetLCLTTReducerAction } from "../../../store/actions/createTicketManageFields";
import { approveOrDisapprove, getAllDropDown, listSiteId, ranDashboard, ranSiteStatus, singleProjectApi, timelineApi, reqPost, reqPreCheckCancel, ranDashboardTimeLine, reqPostCheckCancel, listtroubleTicketApi, listennoTicketApi, export_enoo_dashboard, enoofilters, ttfilters } from "../../../common/config";
import RanSiteTracker from "../ProjectTrackerComponent/RanSiteTracker";
import RanHistorical from "../ReusableComponents/RanHistorical";
import SingleProject from "../ProjectModule/SingleProject";
import ProjectData from "../ReusableComponents/ProjectData";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { fontSize } from "@mui/system";
import CustomeModal from "../Custom/CustomeModal";
import CustomTable from "../Custom/CustomTable";
import RequestPreCheck from "../Custom/Modal Body/RequestPreCheck";
import { RangeDatePicker } from "@y0c/react-datepicker";
import { Input } from "reactstrap";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { id } from "date-fns/locale";
import { includeInnerHTML } from "@syncfusion/ej2-base";
import MapView from "../Custom/Modal Body/MapView";
import CommonModal from "../Custom/CommonModal";
import { e911colorCode } from "../../../common/common_color_code";
// import ENOOFilterList from "../Custom/Modal Body/ENOOFilterListjs";
import FilterData from "../../../icons/Svg/FilterData";
import ENOOFilterList from "../Custom/Modal Body/ENOOFilterList.js";

const E911Dashboard = () => {
  const [accordianState, setAccordianState] = useState(false);
  const [siteFile, uploadedFile] = useState();
  const [remarks, setRemarks] = useState();
  const [markersdatacount, setmarkerscount] = useState(1);
  const [markersdata, setmarkersdata] = useState([]);
  const [selectedCenter, setSelectedCenter] = useState(null);
  const [xlShow, setXlShow] = useState(false);
  const [exportApiData, setexportApiData] = useState("");
  const [crewModal, setCrewModal] = useState(false)
  const [reqPreModal, setReqPreModal] = useState(false)
  const [siteDetail, setSiteDetail] = useState({})
  const [siteUId, setSiteId] = useState()
  const history = useHistory();
  const [showDatePicker, setDatePicker] = useState(false)
  const [mapModal, setMapModal] = useState(false)
  const [filterBadges,setFilterBadge] =useState()
  const [fstartdate, setfstartdate] = useState();
  const [fenddate, setfenddate] = useState();
  const [commonModalState, setcommonModalState] = useState(false);
  const [commonModalSize, setcommonModalSize] = useState(false);
  const [commonModalBody, setcommonModalBody] = useState("");
  const [commonModalTitle, setcommonModalTitle] = useState("");
  const [commonModalHeader, setcommonModalHeader] = useState("");
  const [ResetFilter, setResetFilter] = useState(false);
  const [fullUrL, setfullUrL] = useState("");
  const [dateRange, setDateRange] = useState([{
    startDate: new Date(),
    endDate: null,
    key: "selection"
  }])

  const CommonModalFun = (body, title, header, type = "md") => {
    setcommonModalState(true);
    setcommonModalSize(type);
    setcommonModalTitle(title);
    setcommonModalBody(body);
    setcommonModalHeader(header);
  };

  function filterhandler(){
    
    dispatch(getFilterDropDown([]))
    dispatch(AccountReducerAction(enoofilters))
    setcommonModalState(true)
    setcommonModalBody(<ENOOFilterList setfullUrL={setfullUrL} setcommonModalState={setcommonModalState} setFilterBadge={setFilterBadge} featureExport={setexportApiData}/>)
    setcommonModalTitle("E911 Filters")
    setcommonModalHeader("E911 FIlter")

  }

  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
  const [uniqueId, setUniqueId] = useState()
  let getMuiTheme = () => createTheme({
    components: {
      MuiButtonBase: {
        styleOverrides: {
          root: {
            "line-height": "1 !important",
            "padding": "6px 0px"
          },
        }
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {

            maxHeight: 45,
            fontSize: 13,
            overflowY: "auto",
            padding: "0px 2px  !important",

            margin: 0


          },
        }
      },
      // MuiToolbar: {
      //   styleOverrides: {
      //     root: {
      //       backgroundColor: '#f00',

      //     },
      //   },
      // },
      MuiTableCell: {
        styleOverrides: {
          head: {

            maxHeight: 20,
            fontSize: 15,
            margin: 0,
            paddingTop: 0,
            paddingBottom: 0
          },
        },
      },
      MUIDataTableSelectCell: {
        styleOverrides: {
          headerCell: {
            backgroundColor: 'blue',
          },
        },
      },
      MuiTableFooter: {
        styleOverrides: {
          root: {
            '& .MuiToolbar-root': {
              backgroundColor: 'white',
            },
          },
        },
      },
    },
  });
  const reqPostCheck = (e) => {
    swal({
      title: "Are you sure?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          console.log("the will dele=", willDelete)
          dispatch(AccountReducerAction(reqPost + e))
          dispatch(AccountReducerAction(ranDashboard))
          swal("Post Check Requsted", {
            icon: "success",
          });
        }
      });

  }



   function clearOldData(mark) {
    dispatch(GetennoProject([]))
    dispatch(clearFetchSingleRecordAction())
    dispatch(AccountReducerAction(listennoTicketApi + "/" + mark));
    history.push(`/PAG/E911CallTest/form/add`)
  }
  // const setDateFilter = (e) => {
  //   e.preventDefault()
  //   dateRange[0].startDate = formatDate(dateRange[0].startDate)
  //   dateRange[0].endDate = formatDate(dateRange[0].endDate)
  //   console.log("the filtered data=", dateRange)
  //   let currentUrl = window.location.href
  //   let urlArray = currentUrl.split("?")
  //   if (currentUrl.includes("?")) {

  //     dispatch(AccountReducerAction(ranDashboard + "?" + urlArray.pop() + "&start=" + dateRange[0].startDate + "&end=" + dateRange[0].endDate))
  //   }
  //   else {
  //     dispatch(AccountReducerAction(ranDashboard + "?start=" + dateRange[0].startDate + "&end=" + dateRange[0].endDate))
  //   }
  // }

  const setDateFilter = (e) => {
    e.preventDefault();

    if (fstartdate == undefined && fenddate == undefined) {
      swal("Oops", "Please select Start & End Date", "info");
      return;
    }
    if (fstartdate == undefined) {
      swal("Oops", "Please select Start Date", "info");
      return;
    }

    if (fenddate == undefined) {
      swal("Oops", "Please select End Date", "info");
      return;
    }
    if (fstartdate > fenddate) {
      swal("Oops", "End Date is greater than Start Date", "info");
      return;
    }
    // dateRange[0].startDate = formatDate(dateRange[0].startDate);
    // dateRange[0].endDate = formatDate(dateRange[0].endDate);
    // console.log("the filtered data=", dateRange);
    let start = formatDate(fstartdate);
    let end = formatDate(fenddate);
    let currentUrl = window.location.href;
    let urlArray = currentUrl.split("?");    
    setResetFilter(true)

    setexportApiData(ranDashboard + "?start=" + moment(fstartdate).format("MM/DD/YYYY") + "&end=" + moment(fenddate).format("MM/DD/YYYY"))
    
    let Filleddata=dispatch(LCLENOOReducerAction({dateUrl:`start=${start}&end=${end}`}))
    console.log(Filleddata,"FilleddataFilleddata")
    dispatch(AccountReducerAction(listennoTicketApi + Filleddata,true));
      // dispatch(AccountReducerAction(ranDashboard + "start=" + start + "&end=" + end,true));
    // }
  };
  const ApprovalHandler = (item) => {
    console.log("the item ApprovalHandler is =.", item)
    dispatch(addUserAction(approveOrDisapprove + item.ranSupportId, { status: "Approve" }))
  }
  const cancelPreCheckReq = (id) => {
    dispatch(AccountReducerAction(reqPreCheckCancel + id))
  }

  const cancelPostCheckReq = (id) => {
    dispatch(AccountReducerAction(reqPostCheckCancel + id))
  }

  const startcalltest = (id) => {
    // dispatch(AccountReducerAction(reqPostCheckCancel + id))
  }
  const rejectHandler = (item) => {
    dispatch(addUserAction(approveOrDisapprove + item.ranSupportId, { status: "Not Approve" }))
  }
  let columns = [
    // {
    //   label: "Date Of Add",
    //   name: "addDateNew",
    //   options: {
    //     filter: true,
    //     className: "abcd",
    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "nowrap",
    //         background:"#143b64",
    //         color:"white",
    //         "min-width":"120px"

    //       }
    //     })
    //   }
    // }, {
    //   name: "siteId",
    //   label: "Site Id",
    //   sortable: true,
    //   options: {
    //     filter: true,

    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "nowrap",
    //         overflowX: "hidden",
    //        background:"#143b64",
    //           color:"white",       "min-width" : "90px",
    //           "max-width" : "140px"

    //       }
    //     })
    //   }
    // },

    {
      label: "Date Of Add",
      name: "addDateNew",
      sortable: true,
      options: {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 101
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      },
    },
    {
      label: "Week #",
      name: "scheduleWeek",
      options: {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            position: "sticky",
            zIndex: 101,
            minWidth: "68px",
            left: "138px",
            color: "white",
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            outline:"1.5px solid black",
            overflowY: "hidden",
            minWidth: "68px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
            left: "138px",
          },
        }),
      },
    },
    {
      label: "Site ID",
      name: "siteId",
      options: {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            position: "sticky",
            zIndex: 101,
            minWidth: "68px",
            left: "208px",
            color: "white",
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            outline:"1.5px solid black",
            overflowY: "hidden",
            minWidth: "68px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
            left: "208px",
          },
        }),
      },
    },
    {
      name: "projectCodenew",
      label: "Project Code",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            minWidth:"300px",
           background:"#143b64",
            color:"white"

          }
        })
      }
    },
   
    {
      name: "projectId",
      label: "Project Id",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            minWidth:"300px",
           background:"#143b64",
            color:"white"

          }
        })
      }
    },
    {
      name: "techtemp",
      label: "Technology",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "min-width": "350px",
            "max-width": "400px",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },
     {
      name: "account",
      label: "Account",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
            "max-width": "100px",
            "min-width": "100px",
           background:"#143b64",
              color:"white"

          }
        })
      }
    }, {
      name: "market",
      label: "Market",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },
    {
      name: "alphaSectornew",
      label: "Alpha Sector",
      sortable: true,
      options: {
        filter: true,
        print:false,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "max-width": "230px",
            "min-width": "230px",
            overflowX: "hidden",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },{
      name: "betaSectornew",
      label: "Beta Sector",
      sortable: true,
      options: {
        filter: true,
        print:false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "max-width": "230px",
            "min-width": "230px",
            overflowX: "hidden",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },{
      name: "gammaSectornew",
      label: "Gamma Sector",
      sortable: true,
      options: {
        filter: true,

        print:false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
            "max-width": "230px",
            "min-width": "230px",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },{
      name: "deltaSectornew",
      label: "Delta Sector",
      sortable: true,
      options: {
        filter: true,
        print:false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
            "max-width": "230px",
            "min-width": "230px",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },{
      name: "epsilonSectornew",
      label: "Epsilon Sector",
      sortable: true,
      options: {
        filter: true,
        print:false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
            "max-width": "230px",
            "min-width": "230px",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },{
      name: "zetaSectornew",
      label: "Zeta Sector",
      sortable: true,
      options: {
        filter: true,
        print:false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
            "max-width": "230px",
            "min-width": "230px",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },
    {
      name: "alphaSector",
      label: "Alpha Sector",
      sortable: true,
      options: {
        filter: true,

        display:false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
            "max-width": "230px",
            "min-width": "230px",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },{
      name: "betaSector",
      label: "Beta Sector",
      sortable: true,
      options: {
        filter: true,

        display:false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
            "max-width": "230px",
            "min-width": "230px",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },{
      name: "gammaSector",
      label: "Gamma Sector",
      sortable: true,
      options: {
        filter: true,

        display:false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
            "max-width": "230px",
            "min-width": "230px",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },{
      name: "deltaSector",
      label: "Delta Sector",
      sortable: true,
      options: {
        filter: true,
        display:false,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
            "max-width": "230px",
            "min-width": "230px",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },{
      name: "epsilonSector",
      label: "Epsilon Sector",
      sortable: true,
      options: {
        filter: true,

        display:false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
            "max-width": "230px",
            "min-width": "230px",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },{
      name: "zetaSector",
      label: "Zeta Sector",
      sortable: true,
      options: {
        filter: true,
        
        display:false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
            "max-width": "230px",
            "min-width": "230px",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },{
      name: "integrationDate",
      label: "Integration Date",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },{
      name: "ennoCallTestDate",
      label: "E911 / Call Test Date",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
            "max-width": "95px",
            "min-width": "95px",
            
           background:"#143b64",
              color:"white"

          }
        })
      }
    },{
      name: "engineerSupportingenno",
      label: "Engineer Filling E911 Form",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            minWidth:"200px",
            overflowX: "hidden",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },{
      name: "openTT",
      label: "TT (s) Opened?",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
            minWidth:"150px",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },{
      name: "ttid",
      label: "TT ID (s)",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
            minWidth:"300px",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },{
      name: "testPhoneNumber",
      label: "Test Phone Number",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
            "max-width": "100px",
            "min-width": "100px",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },{
      name: "remarkModal",
      label: "Remarks",
      sortable: true,
      options: {
        filter: true,
        print:false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
            "max-width": "200px",
            "min-width": "200px",
            background:"#143b64",
            color:"white"

          }
        })
      }
    },{
      name: "remarks",
      label: "Remarks",
      sortable: true,
      options: {
        filter: true,
        display:false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
            "max-width": "120px",
            "min-width": "120px",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },{
      sortable: true,
         options: {
           filter: true,
   
           setCellHeaderProps: () => ({
             style: {
               whiteSpace: "wrap",
               overflowX: "hidden",
               minWidth:"250px",
              background:"#143b64",
              color:"white"
   
             }
           })
         },
      "name": "psapixvendor",
      "label": "PSAP Done By:IX Crew Vendor"
     },
   
   
             
     {
      sortable: true,
         options: {
           filter: true,
   
           setCellHeaderProps: () => ({
             style: {
               whiteSpace: "wrap",
               overflowX: "hidden",
              background:"#143b64",
              minWidth:"250px",
              color:"white"
   
             }
           })
         },
      "name": "psapixcontact",
      "label": "PSAP Done By:IX Crew Lead Contact Number"
     },
     {
      sortable: true,
         options: {
           filter: true,
   
           setCellHeaderProps: () => ({
             style: {
               whiteSpace: "wrap",
               overflowX: "hidden",
               minWidth:"250px",
              background:"#143b64",
              color:"white"
   
             }
           })
         },
      "name": "psapixname",
      "label": "PSAP Done By:IX Crew Lead Name"
     },
     {
      sortable: true,
         options: {
           filter: true,
   
           setCellHeaderProps: () => ({
             style: {
               whiteSpace: "wrap",
               overflowX: "hidden",
               minWidth:"250px",
              background:"#143b64",
              color:"white"
   
             }
           })
         },
      "name": "psapixcompany",
      "label": "PSAP Done By:IX Crew Lead Company"
     },
     {
      sortable: true,
         options: {
           filter: true,
   
           setCellHeaderProps: () => ({
             style: {
               whiteSpace: "wrap",
               overflowX: "hidden",
               minWidth:"250px",
              background:"#143b64",
              color:"white"
   
             }
           })
         },
      "name": "psapixemail",
      "label": "PSAP Done By:IX Crew Lead E-mail ID"
     }

  ];

  <th scope="col">CX Crew Vendor</th>
  let cxTableHeader = ['CX Crew Vendor', 'CX Crew Lead Contact Number', 'CX Crew Lead Name', 'CX Crew Lead Company', 'CX Crew Lead E-mail ID']
  let ixTableHeader = ['IX Crew Vendor', 'IX Crew Lead Contact Number', 'IX Crew Lead Name', 'IX Crew Lead Company', 'IX Crew Lead E-mail ID']
  let rowData = [];
  let rowDataOne = [];
  let mark = [];
  let row = [];
  let datamark = [];
  let siteStatus = [];
  function createmarker(mark) {
    console.log(datamark, "createmarkerdatamark");

    setmarkersdata(datamark);

    console.log(...markersdata, "markersdata");
  }
  function openModal(data, id = null) {
    if (data == 'reqPreCheck') {
      setReqPreModal(true)
      console.log("the req premodal =", data)
      setUniqueId(id)

    }
    else
      if (id == null) {
        setCrewModal(true)
        setSiteDetail(data)
        console.log("th dta is  =", reqPreModal)
        console.log("the main data is =", siteDetail)
      }
  }
  function showDetails(id) {
    setXlShow(true)
    setSiteId(id.ranSupportId)
    console.log("dfghedrhetsetghrrtd", id)
    dispatch(GetTimeLineAction(ranDashboardTimeLine, id.ranSupportId))
    dispatch(FetchSingleRecordAction(ranDashboard, id.ranSupportId))
  }
  const count = useSelector((state) => {
    console.log("the state is=", state);
    let totalCount=state.countState.listEnooCount
    let sitesdata = state.CommonResponse.ranDashboard;
    if (sitesdata) {
      
      if (markersdatacount == 1) {
        // setmarkersdata(sitesdata)
        console.log(state.sites.sites, "sitesdata");
        createmarker(sitesdata);
        sitesdata.map((marker) => {
          if (marker.latitude != "" && marker.longitute != "") {
            datamark.push({
              latitude: parseFloat(marker.latitude),
              longitute: parseFloat(marker.longitute),
              id: marker.latitude,
            });
          }
        });

        console.log(datamark, "datamark");
        setmarkerscount(0);
      }
      // sitesdata.map(item=>{
      //   if(item.latitude!='' && item.longitute!=''){
      //     if(markersdatacount==1){
      //       createmarker(mark)
      //       setmarkerscount(0)
      //     }

      //   }
      // })
    }
    let enno_all_data = state.CommonResponse.enno_data

    // console.log("the ran site status =",state.CommonResponse.ranSiteStatus)
    if (ranSiteStatus && ranSiteStatus.length > 0) {
      console.log("the ran site status =", ranSiteStatus)
      siteStatus = ranSiteStatus
    }
    console.log(enno_all_data, "enno_all_data");

    if (Object.keys(state.CommonResponse.enno_data).length > 0) {
      if (state.CommonResponse.enno_data.length > 0) {


        rowData = state.CommonResponse.enno_data.map((item, index) => {
          console.log("i am caling", state)

          let mergedPId
          mergedPId=item["projectDataa"].map((itm)=>{
            return itm.projectId
          })
          item["projectId"]=mergedPId.join(", ")
          let openTT=item["troubleTickets"].map((itm)=>{
            return itm.ttid
          })
          item["openTT"]=openTT.length>0?"YES":"NO"
          item["ttid"]=openTT.join(", ")
          item["psapixcontact"]=item.ixCrewId?.mobilenumber
          item["psapixvendor"]=item.ixCrewId?.vendorId
          item["psapixname"]=item.ixCrewId?.name
          item["psapixcompany"]=item.ixCrewId?.crewLeadCompanyId
          item["psapixemail"]=item.ixCrewId?.email

          item["addDateNew"]=dateOnly(item.addDate)

          
          const myMomentObject = dbdatetomoment(item.addDate)

          item["scheduleWeek"] = myMomentObject.isoWeekday(1).week()
          item["alphaSectornew"]=<p className={`badge badge-custom-size ${e911colorCode[item.alphaSector]}`}>{item.alphaSector}</p>
          item["betaSectornew"]=<p className={`badge badge-custom-size ${e911colorCode[item.betaSector]}`}>{item.betaSector}</p>
          item["gammaSectornew"]=<p className={`badge badge-custom-size ${e911colorCode[item.gammaSector]}`}>{item.gammaSector}</p>
          item["deltaSectornew"]=<p className={`badge badge-custom-size ${e911colorCode[item.deltaSector]}`}>{item.deltaSector}</p>
          item["epsilonSectornew"]=<p className={`badge badge-custom-size ${e911colorCode[item.epsilonSector]}`}>{item.epsilonSector}</p>
          item["zetaSectornew"]=<p className={`badge badge-custom-size ${e911colorCode[item.zetaSector]}`}>{item.zetaSector}</p>
          

          // e911colorCode


          

          

          
          item["projectCodenew"]=item["projectCode"].join(", ")

          let temptechaff = ""

          let techAffectedArray = item["techAffected"].values;
          if (techAffectedArray && techAffectedArray.length > 0) {
            //  item['technology'].forEach((item)=>{
            //   tec +=item.label +','
            //  })
            for (let i = 0; i < techAffectedArray.length; i++) {
              console.log("sgrthdhr", techAffectedArray[i]);
              temptechaff += techAffectedArray[i] + ", ";
            }
            //  item['technology']=tec
          }
          //  console.log("item is =",item['technology'])
          // item['technology']=tec
          item["temptechAffected"] = temptechaff;
          let tecttdata = ""

          let techttdataArray = item["ttdata"].values;
          if (techttdataArray && techttdataArray.length > 0) {
            //  item['technology'].forEach((item)=>{
            //   tec +=item.label +','
            //  })
            for (let i = 0; i < techttdataArray.length; i++) {
              console.log("sgrthdhr", techttdataArray[i]);
              tecttdata += techttdataArray[i] + ", ";
            }
            //  item['technology']=tec
          }
          //  console.log("item is =",item['technology'])
          // item['technology']=tec
          item["remarkModal"]=(
            <p
              className={"text-black my-0 modalHoverClass" }
              
              onClick={() =>
                CommonModalFun(

                  <p style={{ border: "2px solid black", color: "black", padding: 2 }} className="CommonModal">{item['remarks']}</p>,
                  "Comment",
                  "Remarks"
                )
              }
            >
              {more_info(item['remarks'])}
              
            </p>
          )
          item["ttdatatemp"] = tecttdata;
          item['projectUniqueId'] = item.projectUniqueId ? item.projectUniqueId.$oid : ""
          let tec = ""

          let techArray = item["technology"].values;
          if (techArray && techArray.length > 0) {
            //  item['technology'].forEach((item)=>{
            //   tec +=item.label +','
            //  })
            console.log("sgrthdhr", techArray.length);
            for (let i = 0; i < techArray.length; i++) {
              console.log("sgrthdhr", techArray[i]);
              tec += techArray[i] + ", ";
            }
            //  item['technology']=tec
          }
          console.log("item is tec =", tec);

          //  console.log("item is =",item['technology'])
          // item['technology']=tec
          // item["techtemp"] = tec;
          
          item["techtemp"] = item["techuniqueId"]?item["techuniqueId"]:"";
          return item;
        });
        console.log("jdbcjhbsdaikc", state);
      }
    }

    console.log("columns", state.sites.sites.TotalTicket);

    return { rowData, columns, mark, siteStatus, totalCount };
  });

  const options = {

    download: false,
    viewColumns: false,
    print: false,
    filterType: "none",
    selectableRows: false,
    responsive: true,
  };


  const mapStyles = {
    width: "100%",
    height: "100%",
  };

  const dispatch = useDispatch();
  function accordianHandler() {
    setAccordianState(!accordianState);
  }

  createTheme("solarized", {
    text: {
      primary: "inherit",
      secondary: "inherit",
    },
    background: {
      default: "inherit",
    },
    context: {
      background: "inherit",
      text: "inherit",
    },
    divider: {
      default: "inherit",
    },
    button: {
      default: "inherit",
      hover: "rgba(0,0,0,.08)",
      focus: "rgba(255,255,255,.12)",
      disabled: "rgba(255, 255, 255, .34)",
    },
    sortFocus: {
      default: "inherit",
    },
  });

  const handleChange = (e) => {
    console.log("the current anser= ", e.target.value)
  }

  const sortedOrder={
    // name: 'addDate',
    // direction: 'desc'
  }


  const exportAllData = () => {
    
    let Filleddata=dispatch(LCLENOOReducerAction())
    // let bodyData=dispatch(LCLBodyReducerAction())
    dispatch(ExportXLSXDownloadReducerAction(export_enoo_dashboard,"ENOO DASHBOARD_" + moment(new Date()).format("MM-DD-YYYY"),Filleddata))

    // console.log(Filleddata,"FilleddataFilleddata")
    // dispatch(ExportXLSXDownloadReducerAction(export_tt_dashboard,"TTDASHBOARD_" + moment(new Date()).format("MM-DD-YYYY"),Filleddata,bodyData))
  }

  

  const ClearFilter = () => {
    // alert("ClearFilter")
    console.log("ClearFilter", "ClearFilter");
    setfstartdate();
    setfenddate();
    setResetFilter(true)

    setFilterBadge()
    let Filleddata=dispatch(ResetLCLENOOReducerAction())
    dispatch(AccountReducerAction(listennoTicketApi+Filleddata,true,true));
  };


  const AddButton = () => (
    <>
    <p onClick={filterhandler} className=" mx-2 " style={{"align-self": "center"}}><FilterData/></p>
    
    <>

      <button
        className="btn btn-xs btn-primary mx-2 my-3"
        data-toggle="tooltip"
        data-placement="bottom"
        title="Refresh"
        onClick={() =>{ ClearFilter();}}
        style={{ padding: "6px" }}
      >
        <RefreshIcon />
      </button>

      <div
        style={{
          width: "46vh",
          display: "flex",
          alignItems: "center",
          margin: "18px 10px auto 10px"
        }}>
        <DatePicker
          placeholderText="MM/DD/YYYY"
          id="color-of-datepicker"
          className="date-picker-changes "
          popperPlacement=  'left'
          selected={fstartdate}
          autoComplete={"off"}
          onChange={(e) => {
            setfstartdate(e);
            // setfenddate(e);
          }}
          dateFormat="MM/dd/yyyy"
        />
        <DatePicker
          placeholderText="MM/DD/YYYY"
          className="date-picker-changes mx-1"
          id="color-of-datepicker"
          popperPlacement=  'left'
          autoComplete={"off"}
          selected={fenddate}
          onChange={(e) => {
            setfenddate(e);
          }}
          dateFormat="MM/dd/yyyy"
        />
      </div>
      <button
        variant="float-right"
        className="btn btn-primary btn-xs mx-2 py-2 my-3 float-right d-flex justify-content-center align-items-center"
        // onClick={() => setDatePicker(!showDatePicker)}
        onClick={(e) => setDateFilter(e)}
      >
        <i class="fa fa-filter mx-2"></i>Filter
      </button>
          
      </>
    <button className='btn btn-xs btn-primary float-right' style={{ padding : "9px 12px"}} onClick={() => clearOldData()}>Add E911 Call Test Ticket</button>
    <Button onClick={exportAllData} className="btn btn-primary btn-xs mx-2 py-2 my-3 d-flex justify-content-center align-items-center">
        Export
      </Button>
    </>
  );


  // const AddCustomButton = () => (
  //   <fregment style={{ float: "right", display: "flex", flexDirection: row, justifyContent: "space-between" }}>
   
          
         
  //   </fregment>
  // )
  console.log("yhe row data isn=", count.rowData);
  //on submiting the excel
  const submitHandler = async (e) => {
    e.preventDefault();
    console.log("remarks =", remarks);
    console.log("file=", siteFile);
    console.log(columns);
    const form = new FormData();
    form.append("uploadFile", siteFile);
    form.append("uploadfor", "Network_Database");
    // form.append("remarks",remarks);
    setRemarks("");
    // uploadedFile()
    dispatch(CSVUploadAction(form));
    console.log("form data =", form);
  };
  const uploadCsvFile = (e) => {
    console.log(e.target.files[0]);
    uploadedFile(e.target.files[0]);
  };

  const setDataVariables = async () => {
    dispatch(NetworkDatabaseAction());
  };

  const containerStyle = {
    width: "400px",
    height: "400px",
    position: "initial",
  };

  const AnyReactComponent = ({ text }) => <div>{text}</div>;

  const handleApiLoaded = (map, maps) => {
    // use map and maps objects
  };

  useEffect(() => {
    dispatch(AccountReducerAction(listennoTicketApi,true,true));
    // dispatch(AccountReducerAction(ranSiteStatus))
    // dispatch(AccountReducerAction(getAllDropDown))
    // dispatch(AccountReducerAction(listSiteId))

    if (!datamark) {
      setmarkersdata(datamark);
    }
    console.log(markersdata, "markersdata");
  }, [markersdata]);
  return (
    <>
           <CommonModal
          modalSize={commonModalSize}
          setXlShow={setcommonModalState}
          xlShow={commonModalState}
          Body={commonModalBody}
          Header={commonModalHeader}
          Title={commonModalTitle}
        ></CommonModal>

      {/* <button className='btn btn-xs btn-primary float-right' my-1 onClick={() => history.push("/PAG/E911CallTest/form")}>Add E911 Call Test Ticket</button> */}
      {/* <div className="mui-t-h"
          style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}
        > */}

      {filterBadges?.length>0?<p style={{color: "black",border:"0px solid black"}}className={"badge"}>Selected Filter : &nbsp;&nbsp;</p>:""}{filterBadges}
            
      <ThemeProvider theme={getMuiThemeCommon()}>

        <MUIDataTable
          data={rowData}
          columns={columns}
          options={advancdcustomDataTableOptionObject(AddButton,false,"E911_tracker_","","",sortedOrder, "listenno", "",rowData.length,count.totalCount,false,false,"",false)}
          
        />
      </ThemeProvider>
      {/* </div> */}
    </>
  );
};

export default E911Dashboard;
