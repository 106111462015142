import { InfoWindowF } from "@react-google-maps/api";
import React from "react";

export function OnInfo({ selected }) {


    console.log(selected,"1selectedselectedselectednew")
    return (
        <InfoWindowF
              position={{
                lat: selected[0].latitude,
                lng: selected[0].longitude,
              }}
              clickable={true}
            //   onCloseClick={() => onCloseSelect()}
            >{
              
                // selected.ticket ?
                  <>
                  {console.log(selected,"selectedselectedselectednew")}
                    
                  
                  
                    <table>
                      <tr className='maptabletr'>
                        <th>Ticket Id</th>
                        <th>Site Id</th>
                        <th>Type of Work</th>
                        <th>Status</th>
                        <th>Location</th>
                      </tr>
                      
                      {selected.map((oss)=>{
                        return <>
                        <tr className='maptabletr'>
                          <td className='maptabletd'>{oss.ticket?oss.ticket:"NA"}</td>
                          <td className='maptabletd'>{oss.siteId}</td>
                          <td className='maptabletd'>{oss.typeOfWork?oss.typeOfWork:"NA"}</td>
                          <td className='maptabletd'>{oss.goNo?oss.goNo:"NA"}</td>
                          <td className='maptabletd'>{oss.address}</td>
                        </tr>
                        </>
                      })}
                      
                    </table>
                  </>
                  // :<>
                  //   <table>
                  //     <tr className='maptabletr'>
                  //       <th>Site Id</th>
                  //       <th>Location</th>
                  //       <th>Status</th>
                  //     </tr>

                  //     <tr className='maptabletr'>
                  //       <td className='maptabletd'>{selected.siteId}</td>
                  //       <td className='maptabletd'>{selected.address}</td>
                  //       <td className='maptabletd'>No Work</td>
                  //     </tr>
                  //   </table>
                  // </> 
              }
            </InfoWindowF>
    );
  }
  
  export const UsInfowindow = React.memo(OnInfo);
  