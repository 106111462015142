
export const FirstPageGone={
    "Super Admin":"/PAG/customer/dashboard",
    "Administrator":"/PAG/customer/dashboard",
    "GC Login True":"/PAG/Mop/database",
    "GC Login False":"/PAG/ran/support/dashboard",
    "FOPS":"/PAG/Mop/database",
    "Development":"/PAG/Mop/database",
    "RF Engineer":"/PAG/Mop/database",
    "Manage Engineer":"/PAG/customer/dashboard"
}


