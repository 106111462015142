import React, { Fragment, useState, useEffect } from "react";
import "../../../../css/customStyle.css";
import { SplitButton, ButtonGroup, Dropdown, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import { useForm } from "react-hook-form";
import { NetworkDatabaseAction } from "../../../../store/actions/dashboardActions";
import "../../../../css/formError.css";
import moment from "moment"

import { accountApi, addCrNumber, allTechnologyApi, listSiteId, listUserApi_two, managEngineer, managTMOEngineer, networkOneSiteDatabaseprojectId, OneSiteDatabase, OneSiteDatabaseProjectCode, OneSiteDatabaseProjectId, projectCode, ranAddSupport, ranOneSiteDatabaseprojectId, ranOneSiteProjectCode, singleProjectApi, siteDblistSiteId } from "../../../../common/config";
import { AccountReducerAction, addUserAction, clearFetchSingleRecordAction, clearGetSingleProjectDatabase, GetRanSingleProject, popUpUpdate, TierListReducerAction, UserListReducerAction, clearProjectId, ClearProductCode } from "../../../../store/actions/createTicketManageFields";

import { FetchSingleRecordAction } from "../../../../store/actions/createTicketManageFields";
import { default as ReactSelect, components } from "react-select";
import { getValue } from "@mui/system";

import { Tab, Tabs } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import DatePicker from "react-datepicker";
import "./custom_style.css"
import { commonsowDetail, mopcommonsowDetail, projectIdValidator, techChecker, techCheckerwitharray } from "../../../../common/variables";
import swal from "@sweetalert/with-react";
import CommonCrew from "../Ran Form/commoncrew";

const NetworkTwo = () => {



  let [oneprid, setoneprid] = useState('')
  let [cxixtype, setcxixtype] = useState(true)


  const [countRender, setCountRender] = useState(1)
  let [ppProjectCode, setppProjectCode] = useState('')
  let [pncurrentValueId, setpncurrentValueId] = useState(false)

  const [EngineerOpen, setEngineerOpen] = useState([]);

  let [customesite, setcustomesite] = useState('')
  let [accordianOperner, setAccordianOperner] = useState('CXCrew')
  let allProjects = []


  const styles = {
    width: "400px",
  };
  const customStyles = {
    control: (base, state) => ({
      ...base,
      // background: "#24292D",
      minHeight: "2px",
      // match with the menu
      borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      }
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? ''
        : isSelected
          ? ''
          : undefined,
      zIndex: 1
    }),


    menu: base => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0
    }),
    menuList: styles => ({
      ...styles,
    })
  };

  let singleSite = []
  let pdsingleSite = [];

  let [oneTimeAccount, setoneTimeAccount] = useState(true)  
  let engineerArray = [];
  let siteArray = [];
  let array_cx_main = [];
  let array_cx_data = [];
  let array_ix_data = [];
  let array_ix_main = [];
  let listalldata = [];
  let accountArry=[]
  let technologyArray = []
  let multipleTechno = []
  let awaitedSiteArray = []
  let projectIdArray = []
  let projectIdOption = []
  let projectCodeOption = []
  let projectCodeArray = []
  let newTtempArray = []
  let tempArr = []
  // dispatch(fetechSingleProject(singleProjectApi,id))
  let [plannedSow, setPlannedSow] = useState([]);
  let [CustomError, setCustomError] = useState({

  });

  let [selectProjectCode, setProjectCode] = useState([])
  let [selectnewProjectId, setselectnewProjectId] = useState([])
  let [selectProjectId, setProjectId] = useState([])
  let [tech, setTech] = useState([]);
  let [selectedTech, setSelectedTech] = useState()
  let [sassumeTime, setassumeTime] = useState()
  let [sstartTime, setstartTime] = useState()

  let [SelselectProjectCodename, setSelProjectCodename] = useState()

  let [ctrCount, setCtrCount] = useState(1);
  let [actualSiteId, setSiteId] = useState()
  let [ppProjectId, setppProjctId] = useState('')
  let history = useHistory()
  let plannedSowArray = [
    {
      value: "E911-CallTest",
      label: "E911-Call Test",
    },
    {
      value: "CX",
      label: "CX",
    },
    {
      value: "IX",
      label: "IX",
    },
    {
      value: "troubleShooting",
      label: "Troubleshooting",
    },
  ];
  const plannedSowChangleHandler = (e) => {
    console.log("Pow multiple select=", e);
    // if(e.length!=0){
    //   setCustomError(...CustomError,{"plannedSow":true})
    // }
    // else{
    //   setCustomError(...CustomError,{"plannedSow":false})
    // }
    setPlannedSow(e);
  };




  const findDSiteId = (id) => {
    console.log(id, "findDSiteId", allProjects)
    if (id != undefined && customesite!=id) {
      setcustomesite(id)
      setoneprid('')
      setppProjctId('')
      setTech([])
      setProjectId([])
      setProjectCode([])
      dispatch(clearGetSingleProjectDatabase())
      dispatch(clearProjectId())
      dispatch(ClearProductCode())
      projectData.singleSite = []
      if (allProjects.length > 0)
        console.log("the respasfsdvgfdsbdtrh =", ...allProjects.filter((item) => item.siteId == id))
      let idObject = allProjects.filter((item) => item.siteId == id)
      if (idObject.length > 0) {
        // setond(true)

        // dispatch(FetchSingleRecordAction(projectCode, allProjects.filter((item) => item.siteId == id)[0].uniqueSiteId))   //from project Databasee
        dispatch(FetchSingleRecordAction(OneSiteDatabaseProjectCode, allProjects.filter((item) => item.siteId == id)[0].uniqueSiteId))   //from network Databasee
      }
    }
    else {
      // setSiteError(true)
    }
  }


  const fetechSingleProject = (id, siteId) => {
    console.log("single pro ");
    // console.log("the actual site id=",siteId)

    dispatch(FetchSingleRecordAction(projectCode, id))
    setCtrCount(1)
    setTech()

  };
  const FetechAllDetail = (id) => {
    setProjectId(id)
    let tempidd = []
    console.log("the selected value=", id, tempidd)
    let selectedId = id.map((item) => {
      tempidd.push(item.mainId)
      return item.value
    })

    console.log("the selected value=", tempidd)

    setselectnewProjectId(tempidd)
    if (id.length == 0) {
      // clearProjectId
      dispatch(clearGetSingleProjectDatabase())
    }

    setTecheer(id)

    setValue("projectUniqueId", selectedId.join(","))
    // dispatch(FetchSingleRecordAction(singleProjectApi, selectedId.join(",")));  //from project Database
    dispatch(FetchSingleRecordAction(OneSiteDatabase, selectedId.join(",")))  //from network Database
  }
  const techhandleChange = (e) => {
    console.log("multiselect=", tech);
    // setTechnologyError("");
    setSelectedTech(e);
  };
  const addCrDetails = () => {
    let userId = JSON.parse(localStorage.getItem("userDetails"))
    userId = userId.uniqueid
    const sendData = {
      crnumber: getValues("crNumber"),
      siteId: getValues("siteUniqueId"),
      userId: userId
    }
    dispatch(addUserAction(addCrNumber, sendData))
  }

  const findengineer = (id) => {
    setEngineerOpen(id)
  }
  const projectData = useSelector((state) => {
    console.log("the State is=", state);
    allProjects = state.CommonResponse.startAwaitedSite
    pdsingleSite = []
    // pdsingleSite = state.OneCommonResponse.pdsingleSite; /// for project
    pdsingleSite = state.csvResponse.singleSite; /// for network

    let technology = state.CommonResponse.technology;
    // if (technology != 0) {

    //   technologyArray = technology.map((item) => {
    //     return {
    //       value: item.uniqueid,
    //       label: item.technologyName,
    //     };
    //   });

    // }


    let account = state.CommonResponse.accounts;
    if (account.length > 0 && accountArry.length == 0) {
      accountArry = account.map((item, index) => {
        if (index == 0 && oneTimeAccount) {
          // setoneTimeAccount(false)
          // setValue("account", item.uniqueid)
          // return <option selected value={item.uniqueid}>{item.accountName}</option>
        }
        return <option style={{ background: "white" }} value={item.uniqueid}>{item.accountName}</option>;
      });
    }


    console.log("all projects is =", pdsingleSite)
    multipleTechno = []


    if (technology.length > 0 && technologyArray.length == 0) {
      if (selectProjectId.length > 0) {
        console.log("comeToStart")
        // setmultiTechnology([])
        console.log(technology, "technology")
        technologyArray = selectProjectId.map((ids) => {
          console.log("the ids -=", ids)
          let pId = {
            label: ids.label,
            value: ids.label,
            disabled: true
          }
          multipleTechno.push(pId)
          let tmpArry = []
          tmpArry = technology.map((item) => {
            return {
              value: item.technologyName + "_CP_" + ids.value,
              label: item.technologyName,
            };
          });


          multipleTechno.push(...tmpArry)
          // setmultiTechnology(multiTechnologylcl)
        })
        console.log(multipleTechno, "multiTechnologymultiTechnology")

        // settechsetter(false)
      }
      // }
    }

    // if (pdsingleSite && pdsingleSite.length > 0) {
    //   pdsingleSite.map((onePd) => {
    //     console.log(onePd, "onePd")
    //     let sitelabel = {
    //       label: onePd.projectId,
    //       value: onePd.projectId,
    //       disabled: true
    //     }
    //     multipleTechno.push(sitelabel)
    //     let defTech = []
    //     defTech = technology.map((defaultTech) => {

    //       console.log(defaultTech, onePd, "defaultTechdefaultTech")
    //       return {
    //         value: defaultTech.technologyName + "_CP_" + onePd.projectIdUniqueId,
    //         label: defaultTech.technologyName,
    //       };
    //     })
    //     multipleTechno.push(...defTech)
    //   })
    // }
    if (allProjects && allProjects.length != 0) {
      siteArray = allProjects.map((project) => {
        // console.log("the project is =========",project)
        return <option>{project.siteId}</option>;
      });
      singleSite = []
      singleSite = state.OneCommonResponse.singleSite;

      // if (singleSite && singleSite.technology) {
      //   let sitelabel = {
      //     label: singleSite.projectId,
      //     value: singleSite.projectId,
      //     disabled: true
      //   }
      //   multipleTechno.push(sitelabel)
      //   let defTech = []
      //   defTech = singleSite.technology.values.map((defaultTech) => {
      //     return {
      //       value: defaultTech + "_CP_" + singleSite.projectIdUniqueId,
      //       label: defaultTech,
      //     };
      //   })
      //   multipleTechno.push(...defTech)
      // }
      // else if (singleSite && singleSite.length > 1) {
      //   console.log(singleSite, "singleSitesingleSitesingleSite")
      //   // setTech(singleSite.technology)
      //   technologyArray = singleSite.map((sites) => {
      //     let sitelabel = {
      //       label: sites.projectId,
      //       value: sites.projectId,
      //       disabled: true
      //     }
      //     multipleTechno.push(sitelabel)
      //     let defTech = []
      //     defTech = sites.technology.values.map((defaultTech) => {
      //       return {
      //         value: defaultTech + "_CP_" + sites.projectIdUniqueId,
      //         label: defaultTech
      //       };
      //     })
      //     multipleTechno.push(...defTech)
      //   })
      //   // technologyArray = singleSite.technology.values.map((item) => {
      //   //   return {
      //   //     value: item,
      //   //     label: item,
      //   //   };
      //   // });
      //   console.log("the response singleSite is the =", state.OneCommonResponse.singleSite)
      //   // setCtrCount(2)
      // }
    }
    //
    let allAwaitedSite = state.CommonResponse.startAwaitedSite
    // if(allAwaitedSite && allAwaitedSite.length>0){
    // awaitedSiteArray
    // }
    // let projectCodeArray = state.OneCommonResponse.productCode
    // if (projectCodeArray && projectCodeArray.length > 0) {
    //   projectCodeOption = projectCodeArray.map((item) => {
    //     return <option value={item.uniqueid}>{item.projectCode}</option>
    //   })
    // }





    projectCodeArray = state.OneCommonResponse.productCode
    if (projectCodeArray && projectCodeArray.length > 0) {
      projectCodeOption = projectCodeArray.map((item, index) => {
        console.log(oneprid == item.uniqueid, oneprid, item.uniqueid, "oneprid,item.uniqueid")
        // if (oneprid == item.uniqueid) {
        //   return <option selected value={item.uniqueid}>{item.projectCode}</option>
        // } else {
        //   return <option value={item.uniqueid}>{item.projectCode}</option>
        // }


        if (oneprid == item.uniqueid) {
          // if (index == 0) {
          return {
            value: item.uniqueid,
            label: item.projectCode,
          };

          // <>
          //   <option disabled value=''>Select</option>
          //   <option selected value={item.uniqueid}>{item.projectCode}</option>
          // </>
          // }
          // else {
          //   return <option value={item.uniqueid}>{item.projectCode}</option>
          // }
        } else {
          // if (index == 0) {
          //   return <>
          //     <option disabled selected value=''>Select</option>
          //     <option value={item.uniqueid}>{item.projectCode}</option>
          //   </>
          // } else {
          return {
            value: item.uniqueid,
            label: item.projectCode,
          };

          <option value={item.uniqueid}>{item.projectCode}</option>
          // }
        }
      })

      // setValue("projectUniqueId",oneprid,{shouldValidate:true,})
    }
    newTtempArray = []
    projectIdArray = state.OneCommonResponse.productId
    if (projectIdArray && projectIdArray.length > 0) {
      let selectOpt = []
      console.log("the projectId array =", projectIdArray)
      projectIdArray = projectIdArray.map((item, index) => {
        //  let selectOpt=[]
        console.log(ppProjectId, item, "ppProjectId codertest")
        if (ppProjectId == item.uniqueid) {
          console.log("the data is =", item)
          let availableId = []
          let anyArry = item.data
          availableId = item.data.map((id) => {
            return {
              label: id.projectId,
              value: id.uniqueid,
              group: item.projectCode,
              mainId: id.projectIduniqueid
            }
          })

          newTtempArray.push({
            label: item.projectCode,
            value: item.projectCode,
            disabled: true,
          })
          newTtempArray.push(...availableId)
          return (item)
          // console.log("the seelected mfklmkmlsdkf=",selectOpt)
          // projectIdArray.push(...selectOpt)
          // return(null)
          // <>
          //     <option disabled value=''>Select</option>
          //     <option selected value={item.uniqueid}>{item.projectId}</option>
          //   </>
          // }
          // else {
          //   return <option value={item.uniqueid}>{item.projectId}</option>
          // }
        }
        else {
          //   if (index == 0) {
          console.log("the data isdwefwea =", item.data)
          let availableId = []
          let anyArry = item.data
          if (anyArry) {
            availableId = anyArry.map((id) => {
              return {
                label: id.projectId,
                value: id.uniqueid,
                group: item.projectCode,
                mainId: id.projectIduniqueid
              }
            })
          }


          newTtempArray.push({
            label: item.projectCode,
            value: item.projectCode,
            disabled: true,
          })
          newTtempArray.push(...availableId)
          return (item)
          // console.log("the seelected mfklmkmlsdkf=",selectOpt)
          // projectIdArray.push(...selectOpt)
          // return(null)
          // return <>
          //   <option disabled selected value=''>Select</option>
          //   <option value={item.uniqueid}>{item.projectId}</option>
          // </>
          // } else {
          //   return <option value={item.uniqueid}>{item.projectId}</option>
          // }
        }

      })
      console.log("state.CommonResponse.technology=", projectIdOption)
      // tempArr=projectIdOption.map((item)=>{
      //   item.map((id)=>{
      //     te
      //   })
      // })
    }
    let tier = state.CommonResponse.listtier
    if (tier && tier.length != 0) {
      engineerArray = tier.map((item) => {
        return <option>{item.name}</option>;
      })
    }
    console.log("state.CommonResponse.technology=", tempArr)
    console.log("siteArray =", technologyArray);
    return { siteArray,accountArry, pdsingleSite, singleSite, technologyArray, newTtempArray, projectIdOption, multipleTechno, projectCodeOption, engineerArray };
  });

  console.log(projectData, "projectDataprojectData")
  const resetForm = (e) => {
    console.log("call to reset form")
    multipleTechno = []

    setpncurrentValueId(true)

    reset({
      siterId: '',
      serviceAffected: '',
      ixCrewId: '',
      cxCrewId: '',
      crNumber: '',
      RF_Approval: 'Pending',
      requestForm: '',
      dayNightMop: '',
      dayMopReason: '',
      tmoDeploymentManager: ''
    }, {
      keepTouched: true
    })
    setPlannedSow([])
    setassumeTime()
    setMopState(false)
    setstartTime()
    setEngineerOpen()
    setProjectCode([])
    setProjectId([])
    setTech([])
    dispatch(clearFetchSingleRecordAction())
    dispatch(GetRanSingleProject({}))

  }


  console.log(projectData, "projectDataprojectData")
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();

  const [closer, setCloser] = useState(false);
  const [umtsArray, setUmt] = useState([]);

  const [cxArray, setcx] = useState([]);
  const [ixArray, setix] = useState([]);

  const [lteArray, setLte] = useState([]);
  let [lteCount, setLteCount] = useState(1);
  let [count, setCount] = useState(1);
  let [mopReason, setMopState] = useState(false)
  let [errore, seterrore] = useState(false)
  const [newAccount, setAccount] = useState({});

  let Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const addUMTSFields = () => {
    setCount(count++);

    if (count <= 7) {
      setUmt([
        ...umtsArray,
        <div className="form-group col-md-4">
          <input
            style={{ height: "32px" }}
            type="text"
            className="form-control"
            placeholder={"UMTS Id " + count}
          />
        </div>,
      ]);
      setCount(count++);
    }
  };
  const addLTEFields = () => {
    setLteCount(lteCount++);

    if (lteCount <= 9) {
      setLte([
        ...lteArray,
        <div className="form-group col-md-4">
          <label for="exampleFormControlSelect1">LTE ID {lteCount}</label>
          <input
            style={{ height: "32px" }}
            type="text"
            className="form-control"
            placeholder={"LTE Id " + lteCount}
          />
        </div>,
      ]);
      setLteCount(lteCount++);
    }
  };
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({});



  const setTecheer = (selectedname) => {
    let alltt = []
    // console.log("setTecheer",selectedname, selectProjectId, allTechnology)
    let sellid = []


    selectedname.map((ids) => {
      console.log("the ids -=", ids)
      sellid.push(ids.value)
    })

    let arr = []
    console.log(tech, "techitemitemitemitem")

    tech.map((item) => {

      console.log(item, sellid, "itemitemitemitem")

      if (sellid.indexOf(item.value.split("_CP_")[1]) != -1) {
        arr.push({
          value: item.value,
          label: item.value.split("_CP_")[0],
        });
      }

    })

    console.log(arr, "techarr")
    setTech(arr)
  }



  const onSubmit = async (data) => {

    console.log("data is ===", "sassumeTime", sassumeTime, "sstartTime", sstartTime)


    data['RF_Approved_MW_Time'] = moment(sstartTime).format("MM/DD/YYYY HH:mm")
    data['RF_Approved_Assume_Time'] = moment(sassumeTime).format("HH:mm")

    console.log("data is ===", data);
    if (tech.length == 0 || plannedSow.length == 0 || sassumeTime == undefined || sassumeTime == undefined) {
      seterrore(true)
      console.log("errorcapture")
      return

    }


    seterrore(false)
    let userId = JSON.parse(localStorage.getItem("userDetails"))
    userId = userId.uniqueid
    data['userId'] = userId



    console.log(data, "techkaitem")
    let sendTech = {
      values: []
    }
    sendTech.values = tech.map((item) => {

      console.log(item, "techkaitem")
      return item.value
    })
    data['technology'] = sendTech

    let sendSow = { values: [] }
    sendSow.values = plannedSow.map((item) => {
      return item.value
    })
    data['sowList'] = sendSow
    delete data.CXCrewVendor
    delete data.CXCrewPhone
    delete data.CXCrewName
    delete data.CXCrewEmail
    delete data.IXCrewVendor
    delete data.IXCrewPhone
    delete data.IXCrewName
    delete data.IXCrewEmail
    if (data.ixCrewId == undefined) {
      data['ixCrewId'] = ""
      data['cxixuserId'] = data.cxCrewId
    }
    if (data.cxCrewId == undefined) {
      data['cxCrewId'] = ""
      data['cxixuserId'] = data.ixCrewId
    }

    // if (projectIdValidator(SelselectProjectCodename, selectProjectId)) {
    //   // setDisable(false)
    //   swal({
    //     title: "Alert !",
    //     text: "Please Select Project Id In All The Selected Project Code",
    //     icon: "info",
    //   })
    //   return null
    // }

    // if (techCheckerwitharray(selectnewProjectId, tech)) {
    //   {
    //     swal({
    //       title: "Alert !",
    //       text: "Please Select Technology In All The Selected Project Id",
    //       icon: "info",
    //     })
    //     return null
    //   }
    // }

    if (1==1) {
      if (projectIdValidator(SelselectProjectCodename, selectProjectId)) {
        // setDisable(false)
        swal({
          title: "Alert !",
          text: "Please Select Project Id In All The Selected Project Code",
          icon: "info",
        })
        return null
      }
      if (techChecker(selectProjectId, tech)) {
        // setDisable(false)
        swal({
          title: "Alert !",
          text: "Please Select Technology In All The Selected Project Id",
          icon: "info",
        })
        return null
      }
    }


    dispatch(addUserAction(ranAddSupport, data))
    setCloser(true);
    dispatch(popUpUpdate({}));
  };


  const findDProjectId = (id) => {
    setCountRender(1)
    setProjectCode(id)
    let selectedIds = []
    let selectedname = []
    selectedIds = id.map((item) => {
      selectedname.push(item.label)
      return item.value
    })

    if (id.length == 0) {
      // clearProjectId
      dispatch(clearProjectId())
    }

    setSelProjectCodename(selectedname)


    console.log("projectCodeArray", selectedIds.join(","), id)
    let test = projectCodeArray.filter((item) => item.uniqueid == id)[0]
    console.log(test, "codertest")

    if (test) {
      if (test.length > 0) {
        setppProjectCode(test.projectCode)
      }
    }

    console.log(OneSiteDatabase + id, id, projectCodeArray, "projectCodeArray")
    setoneprid(id)
    // dispatch(FetchSingleRecordAction(networkOneSiteDatabaseprojectId, selectedIds))   //from project Database

    // dispatch(FetchSingleRecordAction(OneSiteDatabaseProjectId, nselectedIds))  // from network Database
    let newProjectIdSel = []
    let newIdProjectIdSel = []
    let temp_new_id = []
    selectProjectId.map((item) => {
      if (selectedname.indexOf(item.group) != -1) {
        newProjectIdSel.push(item)
        newIdProjectIdSel.push(item.value)

        temp_new_id.push(item.mainId)
      }
    })
    setProjectId(newProjectIdSel)
    console.log(tech, newProjectIdSel, selectedIds, "techkatech")
    setTecheer(newProjectIdSel)
    FetechAllDetail(newProjectIdSel)
    // findDProjectId(newProjectIdSel)


    setValue("projectId", newIdProjectIdSel.join(","))
    setValue("projectcodeUniqueId", selectedIds.join(","))


    dispatch(FetchSingleRecordAction(OneSiteDatabaseProjectId, selectedIds.join(",")))


  }


  const techChangleHandler = (e) => {
    console.log("Pow multiple select=", e);
    setTech(e);
  };
  const onOptionChange = (e) => {
    let val = e.target.value;

    array_cx_main.push(
      <option selected disabled>
        Select
      </option>
    );
    array_ix_main.push(
      <option selected disabled>
        Select
      </option>
    );

    if (listalldata) {
      if (val == "LeadName") {
        listalldata.map((item) => {
          if (item.crewType == "CX") {
            array_cx_main.push(<option value={item.uniqueid}>{item.name}</option>);
          }
          if (item.crewType == "IX") {
            array_ix_main.push(<option value={item.uniqueid}>{item.name}</option>);
          }
        });
        console.log(array_ix_main, "array_ix_main");
      } else if (val == "Email") {
        listalldata.map((item) => {
          if (item.crewType == "CX") {
            array_cx_main.push(<option value={item.uniqueid}>{item.email}</option>);
          }
          if (item.crewType == "IX") {
            array_ix_main.push(<option value={item.uniqueid}>{item.email}</option>);
          }
        })
      } else if (val == "ContactNumber") {

        listalldata.map((item) => {
          if (item.crewType == "CX") {
            array_cx_main.push(<option value={item.uniqueid}>{item.mobilenumber}</option>);
          }
          if (item.crewType == "IX") {
            array_ix_main.push(<option value={item.uniqueid}>{item.mobilenumber}</option>);
          }
        })
      }
    }



    setcx(array_cx_main);
    setix(array_ix_main)
  };

  let data = useSelector((state) => {
    console.log(state, "statestatestate");


    if (state.popUpState) {
      // console.log(state.popUpState.popstatus.status==201,"popUpStatestatus")
      if (state.popUpState.popstatus.status == 201 && closer) {
        setCloser(false);
        dispatch(popUpUpdate({}));

        history.push("/PAG/ran/support/dashboard")
      }
    }

    // let list_user=state
    listalldata = state.CommonResponse.listuser;
    listalldata.map((item) => {
      if (item.crewType == "CX") {
        array_cx_data.push(item);
      }
      if (item.crewType == "IX") {
        array_ix_data.push(item);
      }
    })
    return { array_cx_data, array_ix_data };
  });

  console.log(data, "datadatadata")
  // const handleChange = (event, nane, arra) => {
  //   setcxixtype(false)

  //   let ind = event.target.selectedIndex - 1;
  //   console.log("array_cx_data,array_ix_data", ind, nane, array_ix_data, array_cx_data);
  //   if (nane == "IXCrew") {

  //     console.log("array_ix_data,array_cx_data", array_ix_data[ind]);
  //     setValue("CXCrew" + "Name", "");
  //     setValue("CXCrew" + "Email", "");
  //     setValue("CXCrew" + "Phone", "");
  //     setValue("CXCrew" + "Vendor", "")
  //     setValue("CXCrew" + "Company", "")
  //     setValue("cxCrewId" + "")
  //     if (array_ix_data[ind]) {
  //       setValue(nane + "Name", array_ix_data[ind].name);
  //       setValue(nane + "Email", array_ix_data[ind].email);
  //       setValue(nane + "Phone", array_ix_data[ind].mobilenumber);
  //       setValue(nane + "Vendor", array_ix_data[ind].vendorresult[0].vendorName)
  //       setValue(nane + "Company", array_ix_data[ind].crewresult[0].crewCompanyName)
  //     }
  //   } else {
  //     console.log("array_ix_data,array_cx_data", array_cx_data[ind])
  //     setValue("IXCrew" + "Name", "");
  //     setValue("IXCrew" + "Email", "");
  //     setValue("IXCrew" + "Phone", "");
  //     setValue("IXCrew" + "Vendor", "")
  //     setValue("IXCrew" + "Company", "")
  //     setValue("ixCrewId" + "")


  //     if (array_cx_data[ind]) {
  //       setValue(nane + "Name", array_cx_data[ind].name);
  //       setValue(nane + "Email", array_cx_data[ind].email);
  //       setValue(nane + "Phone", array_cx_data[ind].mobilenumber);
  //       setValue(nane + "Vendor", array_cx_data[ind].vendorresult[0].vendorName)
  //       setValue(nane + "Company", array_cx_data[ind].crewresult[0].crewCompanyName)
  //     }
  //   }

  // };

  function checkErrorRaise() {
    if (Object.keys(errors).length == 0) {
      return false
    } else {
      return true
    }
  }
  useEffect(() => {
    dispatch(UserListReducerAction(listUserApi_two));
    dispatch(AccountReducerAction(allTechnologyApi));
    dispatch(AccountReducerAction(siteDblistSiteId))  //from network Databasee
    // dispatch(AccountReducerAction(listSiteId))   //from project Databasee
    dispatch(TierListReducerAction(managTMOEngineer))
    
    dispatch(AccountReducerAction(accountApi));
  }, []);

  console.log(errors, Object.keys(errors).length, CustomError, "errors")

  return (
    <Fragment>
      {/* <PageTItle activeMenu='validation' motherMenu='Form' /> */}

      <div style={{ display: "block", width: "100%", marginTop: "-1.3rem" }}>
        {/* <h4>React-Bootstrap Tab Component</h4> */}

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row mt-3">
            <div className="card " style={{ width: "100%", marginLeft: "10px" }}>
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingOneDetails">

                    {/* <Button
                      variant=" btn btn-primary btn-xs float-right mr-1"
                      data-toggle="modal"
                      data-target="#exampleModal"
                    >
                      New Site
                    </Button> */}

                    <div
                      className="modal fade"
                      id="exampleModal"
                      tabIndex={-1}
                      role="dialog"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                              Add New Site
                            </h5>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">�</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <form>
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                  Account<span style={{ color: "red" }}>*</span>
                                </label>
                                <select
                                  style={{ height: "32px" }}
                                  className="form-control"
                                  id="exampleFormControlSelect1"
                                  placeholder="None Selected"
                                // {...register("Technology")}
                                >
                                  <option>--None Selected--</option>
                                  <option>2</option>
                                  <option>3</option>
                                  <option>4</option>
                                  <option>5</option>
                                </select>
                              </div>
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                  Market<span style={{ color: "red" }}>*</span>
                                </label>
                                <select
                                  select
                                  style={{ height: "32px" }}
                                  className="form-control"
                                  id="exampleFormControlSelect1"
                                  placeholder="None Selected"
                                // {...register("Technology", {})}
                                >
                                  <option>--None Selected--</option>
                                  <option>2</option>
                                  <option>3</option>
                                  <option>4</option>
                                  <option>5</option>
                                </select>
                              </div>
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                  Site ID (LTE ID)
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <select
                                  select
                                  style={{ height: "32px" }}
                                  className="form-control"
                                  id="exampleFormControlSelect1"
                                  placeholder="None Selected"
                                // {...register("Technology", {
                                //   required: "Select Technology",
                                // })}
                                >
                                  <option>--None Selected--</option>
                                  <option>2</option>
                                  <option>3</option>
                                  <option>4</option>
                                  <option>5</option>
                                </select>
                              </div>
                              <div className="form-group">
                                <label htmlFor="exampleInputEmail1">
                                  CIQ Engineer Assigned
                                  <span style={{ color: "red" }}>*</span>
                                </label>
                                <select
                                  select
                                  style={{ height: "32px" }}
                                  className="form-control"
                                  id="exampleFormControlSelect1"
                                  placeholder="None Selected"
                                // {...register("Technology", {
                                //   required: "Select Technology",
                                // })}
                                >
                                  <option>--None Selected--</option>
                                  <option>2</option>
                                  <option>3</option>
                                  <option>4</option>
                                  <option>5</option>
                                </select>
                              </div>
                              <button
                                type="submit"
                                className=" form -control btn btn-primary btn-xs ml-5 "
                              >
                                Submit
                              </button>
                            </form>
                          </div>
                          {/* <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          data-dismiss="modal"
        >
          Close
        </button>
        <button type="button" className="btn btn-primary">
          Save changes
        </button>
      </div> */}
                        </div>
                      </div>
                    </div>
                  </h2>
                  <div>
                    <div className="accordion-body">
                      <div className="form-row mt-2 p-2">
                        {/* <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">
                            Site Id
                          </label>
                          <input style={{height:"32px"}} 
                            type="text"
                            className="form-control"
                            id="input "
                            placeholder="Enter Side Id"
                            {
                              ...register("siteId",{
                                required:"Site Id is required",
                                minLength:{
                                  value:8,
                                  message:"Please enter valid site id"
                                },
                                maxLength:{
                                  value:16,
                                  message:"Only 16 characters are allowed"
                                }
                              })
                            }
                          />
                          {errors.siteId && <p className="error">{errors.siteId.message}</p>}
                        </div> */}
                        {/* <div className="form-group col-md-4"> */}
                        {/* <label for="exampleFormControlSelect1">
                            Site Id
                          </label>
                          <input className="form-control" list="cars"
                      
                          //  onChange={(e)=>{
                            // setCrCodeState(e.target.value)
                            // setCrCodeError('')
                          // }}
                            // {
                            //   ...register("siteId",{
                            //     required:"Site Id is required",
                            //     minLength:{
                            //       value:5,
                            //       message:"Please enter valid site id"
                            //     },
                            //     maxLength:{
                            //       value:16,
                            //       message:"Only 16 characters are allowed"
                            //     }
                            //   })
                            // }
                            
                          />
                          <datalist id="cars">
                              <option value="BMW" />
                              <option value="Bentley" />
                              <option value="Mercedes" />
                              <option value="Audi" />
                              <option value="Volkswagen" />
                          </datalist>
                        </div> */}




                        <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">Site Id</label>
                          <input className='form-control' list="cars"
                            style={{ height: "32px" }}
                            // defaultValue={} 
                            autoComplete="nope"
                            {
                            ...register("siterId", {
                              autoComplete: false,
                              required: "Please select Site Id",
                              onBlur: (e) => {
                                findDSiteId(e.target.value)
                              }
                            })}
                          />

                          <datalist id="cars">
                            {projectData.siteArray}
                          </datalist>
                          {errors.siterId && (
                            <p className="error">{errors.siterId.message}</p>
                          )}
                        </div>
                        <div className="form-group col-md-3">
                          <label>Project Code</label>
                          <MultiSelect
                            className="text-dark"
                            hasSelectAll={false}
                            options={projectData.projectCodeOption}
                            onChange={findDProjectId}
                            value={selectProjectCode}
                          />

                          {/* <select
                            style={{ height: "32px" }}
                            className="form-control"
                            {
                            ...register("projectcodeUniqueId", {
                              required: "Please select Project Code",
                              onChange: (e) => { findDProjectId(e.target.value) }
                            }
                            )
                            }
                          >
                            {projectData.projectCodeOption}
                          </select> */}

                          {errors.projectcodeUniqueId && (
                            <p className="error">
                              {errors.projectcodeUniqueId.message}
                            </p>
                          )}
                        </div>
                        {/* <div className="form-group col-md-4 ">
                          <label>Project Code</label>
                          <select
                            style={{ height: "32px" }}
                            className="form-control"
                            {
                            ...register("projectUniqueId",
                              { onChange: (e) => { FetechAllDetail(e.target.value) } }
                            )
                            }
                          >
                            <option selected disabled value=''>select</option>
                            {projectData.projectCodeOption}
                          </select>
                        </div> */}

                        <div className="form-group col-md-3 ">
                          <label >Project Id</label>
                          <MultiSelect
                            className="text-dark"
                            hasSelectAll={false}
                            options={projectData.newTtempArray}
                            onChange={FetechAllDetail}
                            value={selectProjectId}
                          />

                          {/* <select
                            style={{ height: "32px" }}
                            className="form-group"
                            isSearchable={true}
                            {
                            ...register("projectUniqueId", {
                              required: "Please select project Id",
                              onChange: (e) => {
                                FetechAllDetail(e.target.value);
                              }
                            }
                            )
                            }
                          >
                            {projectData.projectIdOption}
                          </select> */}
                          {errors.projectUniqueId && (
                            <p className="error">
                              {errors.projectUniqueId.message}
                            </p>
                          )}
                        </div>

                        {/* <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">Account</label>
                          <input
                            style={{ height: "32px" }}
                            type="text"
                            className="form-control"
                            id="input "
                            readOnly
                            value={
                              projectData.pdsingleSite ? projectData.pdsingleSite.account ? projectData.pdsingleSite.account : projectData.pdsingleSite.length > 0 ? projectData.pdsingleSite[0].account : "" : ""
                            }
                          // {...register("account")}
                          />
                          {errors.accountt && (
                            <p className="error">{errors.accountt.message}</p>
                          )}
                        </div> */}


                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4" >Account </label>
                          {
                            // userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" || (userDetails.rolename == "GC Login" && (whatWork != "assigner" && whatWork != "reassigner")) ?
                              <>
                                <select style={{ height: "32px" }}
                                  className="form-control"
                                  id="exampleFormControlSelect1"
                                  {...register("account", {
                                    required: "Please select account"
                                  })}
                                >
                                  <option value="">Select</option>
                                  {projectData.accountArry}
                                </select>
                                {errors.account && (
                                  <p className="error">
                                    {errors.account.message}
                                  </p>
                                )}
                              </>
                          }

                        </div>
                        {console.log(projectData.singleSite, "projectData.singleSite")}
                        <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">Market</label>
                          <input
                            style={{ height: "32px" }}
                            type="text"
                            className="form-control"
                            id="input "
                            readOnly
                            value={
                              projectData.pdsingleSite ? projectData.pdsingleSite.market ? projectData.pdsingleSite.market : projectData.pdsingleSite.length > 0 ? projectData.pdsingleSite[0].market : "" : ""
                            }
                          // {...register("market")}
                          />
                        </div>


                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4">Technology </label>

                          <MultiSelect
                            options={multipleTechno}
                            onChange={techChangleHandler}
                            value={tech}
                            required
                            hasSelectAll={false}
                          />
                          {(checkErrorRaise() || errore) && tech.length == 0 && (
                            <p className="error">Please Select Technology</p>
                          )}

                        </div>

                        <div className="form-group col-md-3 ">
                        <label for="exampleFormControlSelect1">
                          CR No
                        </label>
                        <input
                          className="form-control"
                          style={{ height: "32px" }}
                          placeholder="CR+9 digits or CHG+9 digits"
                          {
                            ...register("crNumber", {
                              required: "Please Enter CR Number",
                              pattern: {
                                value: /^(CR|CHG)[0-9]{9}$/,
                                message: "Please Enter A Valid CR or CHG Number (CR+9 digits or CHG+9 digits)"
                              }
                            })
                          }
                        />
                        {errors.crNumber && (
                          <p className="error">
                            {errors.crNumber.message}
                          </p>
                        )}
                      </div>




                        <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">
                            Planned SoW
                          </label>
                          <MultiSelect
                            options={mopcommonsowDetail}
                            isMulti

                            name="colors"
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            components={{ Option }}
                            onChange={plannedSowChangleHandler}
                            value={plannedSow}
                            allowSelectAll={true}
                            styles={customStyles}
                            variant="danger"
                            classNamePrefix="select"
                          />

                          {(checkErrorRaise() || errore) && plannedSow.length == 0 && (
                            <p className="error">Please Select Planned SOW</p>
                          )}
                          {/* {errors.planned  && <p className="error">{errors.planned.message}</p>} */}
                        </div>
                        {/* <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">
                            TMO Deployment Manager
                          </label>
                          <select
                            select
                            style={{height:"32px"}}
                            className="form-control"
                            id="exampleFormControlSelect1"
                            {...register("tmoDevelopmentManagers")}
                          >
                            <option selected disabled value="">
                              select
                            </option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </select>
                          {errors.tmo && (
                            <p className="error">{errors.tmo.message}</p>
                          )}
                        </div>

                        <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">
                            TMO Outage Approval?
                          </label>
                          <select
                            style={{height:"32px"}}
                            className="form-control"
                            id="exampleFormControlSelect1"
                            {...register("TMOOutageApproval")}
                          >
                            <option selected disabled value="">
                              select
                            </option>
                            <option>Pending</option>
                            <option>Approved</option>
                            <option>Not-Approved</option>
                          </select>
                        </div> */}
                        <div className="form-group col-md-3">
                          <label for="appt">
                            TMO Outage Approved MW Time(Start Time)
                          </label>

                          {/* <input
                            style={{ height: "32px" }}
                            type="time"
                            id="appt"
                            name="appt"
                            className="form-control"
                            {...register("startTime")}
                          /> */}

                          <DatePicker
                            className="form-control"
                            showTimeSelect
                            style={styles}
                            selected={sstartTime}
                            onChange={(e) => { setstartTime(e); }}
                            showTimeSelectOnly
                            timeFormat="HH:mm"
                            timeIntervals={60}
                            timeCaption="Time"
                            dateFormat="HH:mm aa"
                          />

                          {(checkErrorRaise() || errore) && sstartTime == undefined && (
                            <p className="error">Please Select TMO Outage Approved MW Time</p>
                          )}

                        </div>
                        <div className="form-group col-md-3">
                          <label for="appt">
                            TMO Outage Approved(Time it will take)
                          </label>

                          {/* <input
                            style={{ height: "32px" }}
                            type="time"
                            id="appt"
                            step={3600000}
                            name="appt"
                            className="form-control"
                            {...register("assumeTime")}
                          /> */}

                          <>
                            <DatePicker
                              className="form-control"
                              showTimeSelect
                              style={styles}
                              selected={sassumeTime}
                              onChange={(e) => { setassumeTime(e); }}
                              showTimeSelectOnly
                              timeFormat="HH:mm"

                              timeIntervals={60}
                              timeCaption="Hour"
                              dateFormat="HH:mm"
                            />
                            {/* <p className='error' style={{ display: startDateError ? "block" : "none" }}>Required Field</p> */}
                          </>

                          {(checkErrorRaise() || errore) && sassumeTime == undefined && (
                            <p className="error">Please Select TMO Outage Approved MW Time</p>
                          )}
                        </div>

                        {/* <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">TMO Deployment Manager</label>
                          <input
                            style={{ height: "32px" }}
                            className="form-control"
                            id="exampleFormControlSelect1"
                            // onSelect={(e)=>{
                            //   console.log("single pro ",e)
                            //     // dispatch(fetechSingleProject(singleProjectApi,id))
                            // }}
                            {...register("tmoDeploymentManager", {
                              required: "Please select TMO Deployment Manager"
                            })}
                          /> */}

                        {/* {projectData.siteArray} */}
                        {/* {errors.uniqueid && (
                            <p className="error">{errors.uniqueid.message}</p>
                          )}
                        </div> */}

                        <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">
                            {" "}
                            TMO Deployment Manager
                          </label>
                          <input style={{ height: "32px" }}
                            type="text"
                            {...register("tmoDeploymentManager", {
                              required: "Please select TMO Deployment Manager"
                            })}
                            onBlur={(e) => { findengineer(e.target.value) }}
                            list="engOpen"
                            className="form-control"
                            ID="inputAddress"
                            defaultValue={EngineerOpen}
                            placeholder="Select TMO Deployment Manager"
                          />

                          <datalist id="engOpen">
                            {projectData.engineerArray}
                            <option>{"NA"}</option>
                          </datalist>
                          {errors.tmoDeploymentManager && (
                            <p className="error">{errors.tmoDeploymentManager.message}</p>
                          )}
                        </div>
                        <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">
                            Service Affecting
                          </label>
                          <select
                            style={{ height: "32px" }}
                            className="form-control"
                            id="exampleFormControlSelect1"
                            {...register("serviceAffected", {
                              required: "Please Select Service Affected"
                            })}
                          >
                            <option selected disabled value="">
                              Select
                            </option>
                            <option>Yes</option>
                            <option>No</option>
                          </select>
                          {errors.serviceAffected && (
                            <p className="error">{errors.serviceAffected.message}</p>
                          )}
                        </div>

                        <div className="form-group col-md-12">
                          <label for="exampleFormControlSelect1">
                            {" "}
                            Cabinet IDs
                          </label>
                          <input style={{ height: "32px" }}
                            type="text"
                            disabled
                            className="form-control"
                            ID="inputAddress"
                            placeholder="GSM;,,,, UTMS;,,,,, LTE:,,,,, 5G;,,,,,"
                          />
                        </div>

                        <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">
                            TMO Outage Approval?

                          </label>
                          <select
                            style={{ height: "32px" }}
                            className="form-control"
                            id="exampleFormControlSelect1"
                            {...register("RF_Approval", {
                              required: "Please Select TMO Outage"
                            })}
                          >
                            <option selected>Pending</option>
                            <option>Approved</option>
                            <option>Not Approved</option>
                          </select>
                          {errors.RF_Approval && (
                            <p className="error">{errors.RF_Approval.message}</p>
                          )}
                        </div>
                        <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">
                            Request From
                          </label>
                          <select
                            style={{ height: "32px" }}
                            className="form-control"
                            id="exampleFormControlSelect1"
                            {...register("requestForm", {
                              required: "Please Select Request From"
                            })}
                          >
                            <option selected disabled value="">
                              Select
                            </option>
                            <option value="Email">Email</option>
                            <option value="Bridge">Bridge</option>
                          </select>
                          {errors.requestForm && (
                            <p className="error">{errors.requestForm.message}</p>
                          )}
                        </div>
                        <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">
                            Day Or Night MOP
                          </label>
                          <select
                            style={{ height: "32px" }}
                            className="form-control"
                            id="exampleFormControlSelect1"
                            {...register("dayNightMop", {
                              required: "Please an option",
                              onChange: () => getValues("dayNightMop") == "Day" ? setMopState(true) : setMopState(false)
                            })}
                          >
                            <option selected disabled value="">
                              Select
                            </option>
                            <option value="Day">Day Mop</option>
                            <option value="Night">Night Mop</option>
                          </select>
                          {errors.dayNightMop && (
                            <p className="error">{errors.dayNightMop.message}</p>
                          )}
                        </div>


                        <div className="form-group col-md-3" style={{ visibility: mopReason ? 'visible' : 'hidden' }}>
                          <label>Reason for Day Mop</label>
                          <textarea className="form-control"

                            {...mopReason ?
                              { ...register("dayMopReason", { required: "Enter Day MOP Reason", }) } :
                              {
                                ...register("dayMopReason", {
                                  required: false
                                })
                              }}>

                          </textarea>
                          {errors.dayMopReason && (
                            <p className="error">{errors.dayMopReason.message}</p>
                          )}
                        </div>

                        <CommonCrew errors={errors} register={register} setValue={setValue} crewType={"CXCrew"} currentValueName={""} currentValueId={""} sst={pncurrentValueId} setsst={setpncurrentValueId} />


                        {/* <div className="card" style={{ width: "100%"  ,marginTop : "1rem"}}>

                          <Tabs
                            defaultActiveKey={accordianOperner}
                            id="uncontrolled-tab-example"
                            className="mb-3"
                            onSelect={(e) => {
                              setAccordianOperner(e)
                            }}
                          >
                            <Tab eventKey="CXCrew" title="CX Crew">
                              <div className="accordion-body">
                                <div className="form-row mt-2 p-2">
                                  <div className="form-group col-md-4">
                                    <label for="exampleFormControlSelect1">
                                      CX Search By work----
                                    </label>

                                    <div>
                                      <div class="form-check-inline">
                                        <label class="form-check-label">
                                          <input
                                            style={{ height: "20px" }}
                                            type="radio"
                                            className="form-check-input"
                                            value="ContactNumber"
                                            onChange={onOptionChange}
                                            name="optradio"
                                          />
                                          Contact Number
                                        </label>
                                      </div>
                                      <div class="form-check-inline">
                                        <label class="form-check-label">
                                          <input
                                            style={{ height: "20px" }}
                                            type="radio"
                                            className="form-check-input"
                                            value="Email"
                                            onChange={onOptionChange}
                                            name="optradio"
                                          />
                                          E-mail
                                        </label>
                                      </div>
                                      <div class="form-check-inline disabled">
                                        <label class="form-check-label">
                                          <input
                                            style={{ height: "20px" }}
                                            type="radio"
                                            className="form-check-input"
                                            value="LeadName"
                                            onChange={onOptionChange}
                                            name="optradio"
                                          />
                                          Lead Name
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group col-md-5">
                                    <label for="exampleFormControlSelect1">
                                      <span style={{ color: "red" }}>*</span>
                                      Enter Lead Name
                                    </label>


                                    <select style={{ height: "32px" }}
                                      className="form-control"
                                      id="exampleFormControlSelect1"
                                      // {...register("cxCrewId", {

                                      //   onChange: (e) => {
                                      //     handleChange(e, "CXCrew", "b");
                                      //   },
                                      // })}

                                      {...accordianOperner == "CXCrew" ?
                                        {
                                          ...register("cxCrewId", {
                                            required: "Please Select CX Crew",
                                            onChange: (e) => {
                                              handleChange(e, "CXCrew", "b");
                                            }
                                          })
                                        } :
                                        {
                                          ...register("cxCrewId", {
                                            required: false
                                          })
                                        }}
                                    >
                                      {cxArray}

                                    </select>
                                    {errors.cxCrewId && (
                                      <p className="error">{errors.cxCrewId.message}</p>
                                    )}
                                  </div>{" "}
                                  <div className="form-group col-md-2 mt-2">
                                    <label for="exampleFormControlSelect1"></label>
                                  </div>
                                  <div className="form-group col-md-4">
                                    <label for="exampleFormControlSelect1">
                                      CX Crew Vendor
                                    </label>

                                    <input
                                      style={{ height: "32px" }}
                                      type="text"
                                      className="form-control"
                                      readOnly
                                      {...register("CXCrewVendor")}
                                    />
                                  </div>
                                  <div className="form-group col-md-4 ">
                                    <label for="exampleFormControlSelect1">
                                      CX Crew Lead Contact Number
                                    </label>
                                    <input
                                      style={{ height: "32px" }}
                                      type="text"
                                      className="form-control"
                                      readOnly
                                      {...register("CXCrewPhone")}
                                    />
                                  </div>
                                  <div className="form-group col-md-4">
                                    <label for="exampleFormControlSelect1">
                                      CX Crew Lead Name
                                    </label>
                                    <input
                                      style={{ height: "32px" }}
                                      type="text"
                                      className="form-control"
                                      id="input "
                                      readOnly
                                      {...register("CXCrewName")}
                                    />
                                  </div>
                                  <div className="form-group col-md-4">
                                    <label for="exampleFormControlSelect1">
                                      CX Crew Lead Company{" "}
                                    </label>
                                    <input
                                      style={{ height: "32px" }}
                                      type="text"
                                      className="form-control"
                                      id="input "
                                      readOnly
                                      {...register("CXCrewCompany")}
                                    />
                                  </div>
                                  <div className="form-group col-md-4">
                                    <label for="exampleFormControlSelect1">
                                      CX Crew Lead E-mail ID
                                    </label>
                                    <input
                                      style={{ height: "32px" }}
                                      type="text"
                                      className="form-control"
                                      id="input "
                                      readOnly
                                      {...register("CXCrewEmail")}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Tab>
                            <Tab eventKey="IXCrew" title="IX Crew">

                              <div className="accordion-body">
                                <div className="form-row mt-2 p-2">
                                  <div className="form-group col-md-4">
                                    <label for="exampleFormControlSelect1">
                                      IX Search By----
                                    </label>

                                    <div>
                                      <div class="form-check-inline">
                                        <label class="form-check-label">
                                          <input
                                            style={{ height: "20px" }}
                                            type="radio"
                                            className="form-check-input"
                                            value="ContactNumber"
                                            onChange={onOptionChange}
                                            name="optradio"
                                          />
                                          Contact Number
                                        </label>
                                      </div>
                                      <div class="form-check-inline">
                                        <label class="form-check-label">
                                          <input
                                            style={{ height: "20px" }}
                                            type="radio"
                                            className="form-check-input"
                                            value="Email"
                                            onChange={onOptionChange}
                                            name="optradio"
                                          />
                                          E-mail
                                        </label>
                                      </div>
                                      <div class="form-check-inline disabled">
                                        <label class="form-check-label">
                                          <input
                                            style={{ height: "20px" }}
                                            type="radio"
                                            className="form-check-input"
                                            value="LeadName"
                                            onChange={onOptionChange}
                                            name="optradio"
                                          />
                                          Lead Name
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group col-md-5">
                                    <label for="exampleFormControlSelect1">
                                      <span style={{ color: "red" }}>*</span>
                                      Enter Lead Name
                                    </label>


                        


                                    <select style={{ height: "32px" }}
                                      className="form-control"
                                      id="exampleFormControlSelect1"
                                      {...register("ixCrewId", {
                                        onChange: (e) => {
                                          handleChange(e, "IXCrew", "b");
                                        },
                                      })}

                                      {...accordianOperner == "IXCrew" ?
                                        {
                                          ...register("ixCrewId", {
                                            required: "Please Select IX Crew",
                                            onChange: (e) => {
                                              handleChange(e, "IXCrew", "b");
                                            }
                                          })
                                        } :
                                        {
                                          ...register("ixCrewId", {
                                            required: false
                                          })
                                        }}
                                    >
                                      {ixArray}
                                    </select>
                                    {errors.ixCrewId && (
                                      <p className="error">{errors.ixCrewId.message}</p>
                                    )}
                                  </div>{" "}
                                  <div className="form-group col-md-2 mt-2">
                                    <label for="exampleFormControlSelect1"></label>
                                  </div>

                                  <div className="form-group col-md-4">
                                    <label for="exampleFormControlSelect1">
                                      IX Crew Vendor
                                    </label>
                                    <input
                                      style={{ height: "32px" }}
                                      type="text"
                                      className="form-control"
                                      readOnly
                                      defaultValue={"---Select---"}
                                      {...register("IXCrewVendor")}
                                    />
                                  </div>
                                  <div className="form-group col-md-4 ">
                                    <label for="exampleFormControlSelect1">
                                      IX Crew Lead Contact Number
                                    </label>
                                    <input
                                      style={{ height: "32px" }}
                                      type="text"
                                      className="form-control"
                                      readOnly
                                      {...register("IXCrewPhone")}
                                    />
                                  </div>
                                  <div className="form-group col-md-4">
                                    <label for="exampleFormControlSelect1">
                                      IX Crew Lead Name
                                    </label>
                                    <input
                                      style={{ height: "32px" }}
                                      type="text"
                                      className="form-control"
                                      id="input "
                                      readOnly
                                      {...register("IXCrewName")}
                                    />
                                  </div>
                                  <div className="form-group col-md-4">
                                    <label for="exampleFormControlSelect1">
                                      IX Crew Lead Company{" "}
                                    </label>
                                    <input
                                      style={{ height: "32px" }}
                                      type="text"
                                      className="form-control"
                                      id="input "
                                      readOnly
                                      {...register("IXCrewCompany")}
                                    />
                                  </div>
                                  <div className="form-group col-md-4">
                                    <label for="exampleFormControlSelect1">
                                      IX Crew Lead E-mail ID
                                    </label>
                                    <input
                                      style={{ height: "32px" }}
                                      type="text"
                                      className="form-control"
                                      id="input "
                                      readOnly
                                      {...register("IXCrewEmail")}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Tab>
                          </Tabs>

                        </div> */}

                      </div>
                      <div className="form-row p-2">



                      </div>


                    </div>


                  </div>
                </div>
                <div className="py-3 d-flex justify-content-center align-items-center">
                  <button type="submit" className="btn btn-primary btn-xs ">
                    Submit
                  </button>
                  <button type="button" onClick={() => resetForm()} className="btn btn-xs mx-2 text-white" style={{ background: "#a1a10d" }}>Reset</button>
                  <button className="btn btn-danger btn-xs mx-2" onClick={() => history.push("/PAG/ran/support/dashboard")}>Back</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default NetworkTwo;
