import React, { useEffect, useState, ComponentType, type } from "react";
import { Button, Dropdown } from "react-bootstrap";
import "../../../css/customStyle.css";
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { exportToCSV, ExportToExcel } from "../../../common/ExportToExcel";

import { Row, Col, Card, Nav } from 'react-bootstrap'
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { DateRange } from 'react-date-range';
import swal from 'sweetalert';
import moment from "moment"
import {
  NetworkDatabaseAction,
  CSVUploadAction,
} from "../../../store/actions/dashboardActions";
import { useHistory } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { google_map_api, center, dbdatetodate, getMuiThemeCommon, customDataTableOptionObject, advancdcustomDataTableOptionObject, dateOnly, dbdatetomoment, more_info } from "../../../common/variables";
import { AccountReducerAction, addUserAction, clearFetchSingleRecordAction, deleteAcccountReducerAction, ExportXLSXDownloadReducerAction, FetchSingleRecordAction, GetclearennoProject, GetennoProject, getFilterDropDown, GetTimeLineAction, GetttProject } from "../../../store/actions/createTicketManageFields";
import { approveOrDisapprove, getAllDropDown, listSiteId, ranDashboard, ranSiteStatus, singleProjectApi, timelineApi, reqPost, reqPreCheckCancel, ranDashboardTimeLine, reqPostCheckCancel, listtroubleTicketApi, listennoTicketApi, deleteMarket, enooaddApi, export_enoo_tracker, enoofilters } from "../../../common/config";
import RanSiteTracker from "../ProjectTrackerComponent/RanSiteTracker";
import RanHistorical from "../ReusableComponents/RanHistorical";
import SingleProject from "../ProjectModule/SingleProject";
import ProjectData from "../ReusableComponents/ProjectData";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { fontSize } from "@mui/system";
import CustomeModal from "../Custom/CustomeModal";
import CustomTable from "../Custom/CustomTable";
import RequestPreCheck from "../Custom/Modal Body/RequestPreCheck";
import { RangeDatePicker } from "@y0c/react-datepicker";
import { Input } from "reactstrap";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { id } from "date-fns/locale";
import { includeInnerHTML } from "@syncfusion/ej2-base";
import MapView from "../Custom/Modal Body/MapView";
import CommonModal from "../Custom/CommonModal";
import { e911colorCode } from "../../../common/common_color_code";
import ENOOFilterList from "../Custom/Modal Body/ENOOFilterList";
import FilterData from "../../../icons/Svg/FilterData";

const E911Tracker = () => {

  const [commonModalState, setcommonModalState] = useState(false);
  const [commonModalSize, setcommonModalSize] = useState(false);
  const [commonModalBody, setcommonModalBody] = useState("");
  const [commonModalTitle, setcommonModalTitle] = useState("");
  const [commonModalHeader, setcommonModalHeader] = useState("");


  const [accordianState, setAccordianState] = useState(false);
  const [siteFile, uploadedFile] = useState();
  const [remarks, setRemarks] = useState();
  const [markersdatacount, setmarkerscount] = useState(1);
  const [markersdata, setmarkersdata] = useState([]);
  const [selectedCenter, setSelectedCenter] = useState(null);
  const [xlShow, setXlShow] = useState(false);
  const [crewModal, setCrewModal] = useState(false)
  const [reqPreModal, setReqPreModal] = useState(false)
  const [siteDetail, setSiteDetail] = useState({})
  const [siteUId, setSiteId] = useState()
  const history = useHistory();
  const [exportApiData, setexportApiData] = useState("");
  const [closer, setCloser] = useState(false);
  const [fullUrL, setfullUrL] = useState("");
  const [showDatePicker, setDatePicker] = useState(false)
  const [mapModal, setMapModal] = useState(false)
  const [filterBadges,setFilterBadge] =useState()
  const [dateRange, setDateRange] = useState([{
    startDate: new Date(),
    endDate: null,
    key: "selection"
  }])


  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  const CommonModalFun = (body, title, header, type = "md") => {
    setcommonModalState(true);
    setcommonModalSize(type);
    setcommonModalTitle(title);
    setcommonModalBody(body);
    setcommonModalHeader(header);
  };

  const [uniqueId, setUniqueId] = useState()
  let getMuiTheme = () => createTheme({
    components: {
      MuiButtonBase: {
        styleOverrides: {
          root: {
            "line-height": "1 !important",
            "padding": "6px 0px"
          },
        }
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {

            maxHeight: 45,
            fontSize: 13,
            overflowY: "auto",
            padding: "0px 2px !important",

            margin: 0


          },
        }
      },
      // MuiToolbar: {
      //   styleOverrides: {
      //     root: {
      //       backgroundColor: '#f00',

      //     },
      //   },
      // },
      MuiTableCell: {
        styleOverrides: {
          head: {

            maxHeight: 20,
            fontSize: 15,
            margin: 0,
            paddingTop: 0,
            paddingBottom: 0
          },
        },
      },
      MUIDataTableSelectCell: {
        styleOverrides: {
          headerCell: {
            backgroundColor: 'blue',
          },
        },
      },
      MuiTableFooter: {
        styleOverrides: {
          root: {
            '& .MuiToolbar-root': {
              backgroundColor: 'white',
            },
          },
        },
      },
    },
  });
  const deleteData = (e) => {
    swal({
      title: "Are you sure?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          console.log("the will dele=", willDelete)
          dispatch(AccountReducerAction(reqPost + e))
          dispatch(AccountReducerAction(ranDashboard))
          swal("Post Check Requsted", {
            icon: "success",
          });
        }
      });

  }

  const deleteHandler =(e)=>{
    swal({
      title: "Are you sure?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          console.log("button delete clicked",e)
          setCloser(true)
          dispatch(deleteAcccountReducerAction(enooaddApi,e))
          dispatch(AccountReducerAction(listennoTicketApi));
          // swal("Data Deleted Successfully", {
          //   icon: "success",
          // });
        }
      });
    
    
  }


  
  const exportAllData = () => {
    dispatch(ExportXLSXDownloadReducerAction(export_enoo_tracker,"ENOO TRACKER_" + moment(new Date()).format("MM-DD-YYYY")))
  }


  const AddButton = () => (
    <>
    <p onClick={filterhandler} className=" mx-2 " style={{"align-self": "center"}}><FilterData/></p>
    
    <Button onClick={exportAllData} className="btn btn-primary btn-xs mx-2 py-2 my-3 d-flex justify-content-center align-items-center">
      Export
    </Button>
    </>
  );

  function filterhandler(){
    
    dispatch(getFilterDropDown([]))
    dispatch(AccountReducerAction(enoofilters))
    setcommonModalState(true)
    setcommonModalBody(<ENOOFilterList setfullUrL={setfullUrL} setcommonModalState={setcommonModalState} setFilterBadge={setFilterBadge} featureExport={setexportApiData}/>)
    setcommonModalTitle("E911 Filters")
    setcommonModalHeader("E911 Filter")

  }
  const setDateFilter = (e) => {
    e.preventDefault()
    dateRange[0].startDate = formatDate(dateRange[0].startDate)
    dateRange[0].endDate = formatDate(dateRange[0].endDate)
    console.log("the filtered data=", dateRange)
    let currentUrl = window.location.href
    let urlArray = currentUrl.split("?")
    if (currentUrl.includes("?")) {

      dispatch(AccountReducerAction(ranDashboard + "?" + urlArray.pop() + "&start=" + dateRange[0].startDate + "&end=" + dateRange[0].endDate))
    }
    else {
      dispatch(AccountReducerAction(ranDashboard + "?start=" + dateRange[0].startDate + "&end=" + dateRange[0].endDate))
    }
  }
  const ApprovalHandler = (item) => {
    console.log("the item ApprovalHandler is =.", item)
    dispatch(addUserAction(approveOrDisapprove + item.ranSupportId, { status: "Approve" }))
  }
  const cancelPreCheckReq = (id) => {
    dispatch(AccountReducerAction(reqPreCheckCancel + id))
  }

  const cancelPostCheckReq = (id) => {
    dispatch(AccountReducerAction(reqPostCheckCancel + id))
  }

  const startcalltest = (id) => {
    // dispatch(AccountReducerAction(reqPostCheckCancel + id))
  }
  const rejectHandler = (item) => {
    dispatch(addUserAction(approveOrDisapprove + item.ranSupportId, { status: "Not Approve" }))
  }
  let columns = [
    
    {
      label: "Date Of Add",
      name: "addDateNew",
      sortable: true,
      options: {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 101
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      },
    },
    {
      label: "Week #",
      name: "scheduleWeek",
      options: {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            position: "sticky",
            zIndex: 101,
            minWidth: "68px",
            left: "138px",
            color: "white",
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            outline:"1.5px solid black",
            overflowY: "hidden",
            minWidth: "68px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
            left: "138px",
          },
        }),
      },
    },
    {
      label: "Site ID",
      name: "siteId",
      options: {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            position: "sticky",
            zIndex: 101,
            minWidth: "68px",
            left: "208px",
            color: "white",
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            outline:"1.5px solid black",
            overflowY: "hidden",
            minWidth: "68px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
            left: "208px",
          },
        }),
      },
    },
    // {
    //   label: "Date Of Add",
    //   name: "addDateNew",
    //   options: {
    //     filter: true,
    //     print:true,
    //     className: "abcd",
    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "nowrap",
    //         background:"#143b64",
    //         color:"white",
    //         "min-width":"120px"
    //       }
    //     })
    //   }
    // }, {
    //   name: "siteId",
    //   label: "Site Id",
    //   sortable: true,
    //   options: {
    //     filter: true,
    //     print:true,

    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "nowrap",
    //         overflowX: "hidden",
    //         background:"#143b64",
    //         color:"white",       
    //         "min-width" : "90px",
    //         "max-width" : "140px"

    //       }
    //     })
    //   }
    // },
    {
      name: "projectCodenew",
      label: "Project Code",
      sortable: true,
      options: {
        filter: true,
        print:true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            minWidth:"300px",
           background:"#143b64",
            color:"white"

          }
        })
      }
    },
   
    {
      name: "projectId",
      label: "Project Id",
      sortable: true,
      options: {
        filter: true,
        print:true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            minWidth:"300px",
           background:"#143b64",
            color:"white"

          }
        })
      }
    },
    {
      name: "techtemp",
      label: "Technology",
      sortable: true,
      options: {
        filter: true,
        print:true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "min-width": "350px",
            "max-width": "400px",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },
     {
      name: "account",
      label: "Account",
      sortable: true,
      options: {
        filter: true,
        print:true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
            "max-width": "100px",
            "min-width": "100px",
           background:"#143b64",
              color:"white"

          }
        })
      }
    }, {
      name: "market",
      label: "Market",
      sortable: true,
      options: {
        filter: true,
        print:true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },
    {
      name: "alphaSectornew",
      label: "Alpha Sector",
      sortable: true,
      options: {
        filter: true,
        print:false,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "max-width": "230px",
            "min-width": "230px",
            overflowX: "hidden",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },{
      name: "betaSectornew",
      label: "Beta Sector",
      sortable: true,
      options: {
        filter: true,
        print:false,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "max-width": "230px",
            "min-width": "230px",
            overflowX: "hidden",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },{
      name: "gammaSectornew",
      label: "Gamma Sector",
      sortable: true,
      options: {
        filter: true,
        print:false,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
            "max-width": "230px",
            "min-width": "230px",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },{
      name: "deltaSectornew",
      label: "Delta Sector",
      sortable: true,
      options: {
        filter: true,
        print:false,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
            "max-width": "230px",
            "min-width": "230px",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },{
      name: "epsilonSectornew",
      label: "Epsilon Sector",
      sortable: true,
      options: {
        filter: true,
        print:false,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
            "max-width": "230px",
            "min-width": "230px",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },{
      name: "zetaSectornew",
      label: "Zeta Sector",
      sortable: true,
      options: {
        filter: true,
        print:false,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
            "max-width": "230px",
            "min-width": "230px",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },
    {
      name: "alphaSector",
      label: "Alpha Sector",
      sortable: true,
      options: {
        filter: true,

        display:false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
            "max-width": "230px",
            "min-width": "230px",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },{
      name: "betaSector",
      label: "Beta Sector",
      sortable: true,
      options: {
        filter: true,

        display:false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
            "max-width": "230px",
            "min-width": "230px",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },{
      name: "gammaSector",
      label: "Gamma Sector",
      sortable: true,
      options: {
        filter: true,

        display:false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
            "max-width": "230px",
            "min-width": "230px",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },{
      name: "deltaSector",
      label: "Delta Sector",
      sortable: true,
      options: {
        filter: true,
        display:false,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
            "max-width": "230px",
            "min-width": "230px",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },{
      name: "epsilonSector",
      label: "Epsilon Sector",
      sortable: true,
      options: {
        filter: true,

        display:false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
            "max-width": "230px",
            "min-width": "230px",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },{
      name: "zetaSector",
      label: "Zeta Sector",
      sortable: true,
      options: {
        filter: true,
        
        display:false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
            "max-width": "230px",
            "min-width": "230px",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },
    
    {
      name: "integrationDate",
      label: "Integration Date",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },{
      name: "ennoCallTestDate",
      label: "E911 / Call Test Date",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
            "max-width": "95px",
            "min-width": "95px",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },{
      name: "engineerSupportingenno",
      label: "Engineer Filling E911 Form",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
            minWidth:"150px",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },{
      name: "openTT",
      label: "TT (s) Opened?",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
            minWidth:"150px",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },{
      name: "ttid",
      label: "TT ID (s)",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
            minWidth:"300px",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },{
      name: "testPhoneNumber",
      label: "Test Phone Number",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
            "max-width": "100px",
            "min-width": "100px",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },{
      name: "remarksModal",
      label: "Remarks",
      sortable: true,
      options: {
        filter: true,
        print:false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
            "max-width": "200px",
            "min-width": "200px",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },
    {
      name: "remarks",
      label: "Remarks",
      sortable: true,
      options: {
        filter: true,
        display:false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
            "max-width": "120px",
            "min-width": "120px",
           background:"#143b64",
              color:"white"

          }
        })
      }
    },{
      sortable: true,
         options: {
           filter: true,
   
           setCellHeaderProps: () => ({
             style: {
               whiteSpace: "wrap",
               overflowX: "hidden",
               minWidth:"250px",
              background:"#143b64",
              color:"white"
   
             }
           })
         },
      "name": "psapixvendor",
      "label": "PSAP Done By:IX Crew Vendor"
     },
   
   
             
     {
      sortable: true,
         options: {
           filter: true,
   
           setCellHeaderProps: () => ({
             style: {
               whiteSpace: "wrap",
               overflowX: "hidden",
              background:"#143b64",
              minWidth:"250px",
              color:"white"
   
             }
           })
         },
      "name": "psapixcontact",
      "label": "PSAP Done By:IX Crew Lead Contact Number"
     },
     {
      sortable: true,
         options: {
           filter: true,
   
           setCellHeaderProps: () => ({
             style: {
               whiteSpace: "wrap",
               overflowX: "hidden",
               minWidth:"250px",
              background:"#143b64",
              color:"white"
   
             }
           })
         },
      "name": "psapixname",
      "label": "PSAP Done By:IX Crew Lead Name"
     },
     {
      sortable: true,
         options: {
           filter: true,
   
           setCellHeaderProps: () => ({
             style: {
               whiteSpace: "wrap",
               overflowX: "hidden",
               minWidth:"250px",
              background:"#143b64",
              color:"white"
   
             }
           })
         },
      "name": "psapixcompany",
      "label": "PSAP Done By:IX Crew Lead Company"
     },
     {
      sortable: true,
         options: {
           filter: true,
   
           setCellHeaderProps: () => ({
             style: {
               whiteSpace: "wrap",
               overflowX: "hidden",
               minWidth:"250px",
              background:"#143b64",
              color:"white"
   
             }
           })
         },
      "name": "psapixemail",
      "label": "PSAP Done By:IX Crew Lead E-mail ID"
     },
    {
      sortable: true,
         options: {
           filter: true,
           print:false,
           setCellHeaderProps: () => ({
             style: {
               whiteSpace: "wrap",
               overflowX: "hidden",
              background:"#143b64",
              "max-width": "130px",
              "min-width": "122px",

              color:"white"
   
             }
           })
         },
      "name": "raiseTT",
      "label": "Raise Trouble Ticket"
     },
     {
      sortable: true,
         options: {
           filter: true,
           print:false,
           setCellHeaderProps: () => ({
             style: {
               whiteSpace: "wrap",
               overflowX: "hidden",
             
              background:"#143b64",
              color:"white"
   
             }
           })
         },
      "name": "actions",
      "label": "Action"
     }

  ];

  <th scope="col">CX Crew Vendor</th>
  let cxTableHeader = ['CX Crew Vendor', 'CX Crew Lead Contact Number', 'CX Crew Lead Name', 'CX Crew Lead Company', 'CX Crew Lead E-mail ID']
  let ixTableHeader = ['IX Crew Vendor', 'IX Crew Lead Contact Number', 'IX Crew Lead Name', 'IX Crew Lead Company', 'IX Crew Lead E-mail ID']
  let rowData = [];
  let rowDataOne = [];
  let mark = [];
  let row = [];
  let datamark = [];
  let siteStatus = [];
  function createmarker(mark) {
    console.log(datamark, "createmarkerdatamark");

    setmarkersdata(datamark);

    console.log(...markersdata, "markersdata");
  }
  function openModal(data, id = null) {
    if (data == 'reqPreCheck') {
      setReqPreModal(true)
      console.log("the req premodal =", data)
      setUniqueId(id)

    }
    else
      if (id == null) {
        setCrewModal(true)
        setSiteDetail(data)
        console.log("th dta is  =", reqPreModal)
        console.log("the main data is =", siteDetail)
      }
  }
  function showDetails(id) {
    setXlShow(true)
    setSiteId(id.ranSupportId)
    console.log("dfghedrhetsetghrrtd", id)
    dispatch(GetTimeLineAction(ranDashboardTimeLine, id.ranSupportId))
    dispatch(FetchSingleRecordAction(ranDashboard, id.ranSupportId))
  }


  function clearOldData(mark,marking) {
    dispatch(GetclearennoProject([]))
    dispatch(clearFetchSingleRecordAction())
    dispatch(AccountReducerAction(listennoTicketApi + "/" + mark));
    history.push(`/PAG/E911CallTest/form/editenno/${mark}`)
  }


  const topageraiseTT = (siteDetaildata) => {
    
    dispatch(GetttProject([]))
    
    dispatch(clearFetchSingleRecordAction())
    if (siteDetaildata) {
      history.push({
        pathname: `/PAG/trobleTicke/form/e911/${siteDetaildata}`
      })
    }
    console.log(siteDetaildata.siteInfo, "siteDetaildata")
    // }
    // if(page=="back"){
    //   history.goBack()
    // }
  }
  const count = useSelector((state) => {
    console.log("the state is=", state);

    
    let totalCount=state.countState.listEnooCount

    // if (state.popUpState) {
    //   if (state.popUpState.popstatus.status == 201 && closer) {
    //     history.push("/PAG/E911/dashboard")
    //   }
    // }
    let sitesdata = state.CommonResponse.ranDashboard;
    if (sitesdata) {
      if (markersdatacount == 1) {
        // setmarkersdata(sitesdata)
        console.log(state.sites.sites, "sitesdata");
        createmarker(sitesdata);
        sitesdata.map((marker) => {
          if (marker.latitude != "" && marker.longitute != "") {
            datamark.push({
              latitude: parseFloat(marker.latitude),
              longitute: parseFloat(marker.longitute),
              id: marker.latitude,
            });
          }
        });

        console.log(datamark, "datamark");
        setmarkerscount(0);
      }
      // sitesdata.map(item=>{
      //   if(item.latitude!='' && item.longitute!=''){
      //     if(markersdatacount==1){
      //       createmarker(mark)
      //       setmarkerscount(0)
      //     }

      //   }
      // })
    }
    let enno_all_data = state.CommonResponse.enno_data

    // console.log("the ran site status =",state.CommonResponse.ranSiteStatus)
    if (ranSiteStatus && ranSiteStatus.length > 0) {
      console.log("the ran site status =", ranSiteStatus)
      siteStatus = ranSiteStatus
    }
    console.log(enno_all_data, "enno_all_data");

    if (Object.keys(state.CommonResponse.enno_data).length > 0) {
      if (state.CommonResponse.enno_data.length > 0) {


        rowData = state.CommonResponse.enno_data.map((item, index) => {
          console.log("i am caling", state)


          let temptechaff = ""

          let techAffectedArray = item["techAffected"].values;
          if (techAffectedArray && techAffectedArray.length > 0) {
            //  item['technology'].forEach((item)=>{
            //   tec +=item.label +','
            //  })
            for (let i = 0; i < techAffectedArray.length; i++) {
              console.log("sgrthdhr", techAffectedArray[i]);
              temptechaff += techAffectedArray[i] + ", ";
            }
            //  item['technology']=tec
          }
          //  console.log("item is =",item['technology'])
          // item['technology']=tec
          item["temptechAffected"] = temptechaff;
          let tecttdata = ""

          let techttdataArray = item["ttdata"].values;
          if (techttdataArray && techttdataArray.length > 0) {
            //  item['technology'].forEach((item)=>{
            //   tec +=item.label +','
            //  })
            for (let i = 0; i < techttdataArray.length; i++) {
              console.log("sgrthdhr", techttdataArray[i]);
              tecttdata += techttdataArray[i] + ", ";
            }
            //  item['technology']=tec
          }
          //  console.log("item is =",item['technology'])
          // item['technology']=tec
          item["ttdatatemp"] = tecttdata;
          let tec = ""

          let techArray = item["technology"].values;
          if (techArray && techArray.length > 0) {
            //  item['technology'].forEach((item)=>{
            //   tec +=item.label +','
            //  })
            console.log("sgrthdhr", techArray.length);
            for (let i = 0; i < techArray.length; i++) {
              console.log("sgrthdhr", techArray[i]);
              tec += techArray[i] + ", ";
            }
            //  item['technology']=tec
          }


          let mergedPId=item["projectDataa"].map((itm)=>{
            return itm.projectId
          })
          item["projectId"]=mergedPId.join(", ")
          // let openTT=item["troubleTickets"].map((itm)=>{
          //   return itm.ttid
          // })

          // let openTT=item.OpenTTids.split(", ")
          // item["openTT"]=openTT.length>0?"YES":"NO"
          // item["ttid"]=openTT.join(", ")
          let openTT=item["troubleTickets"].map((itm)=>{
            return itm.ttid
          })
          item["openTT"]=openTT.length>0?"YES":"NO"
          item["ttid"]=openTT.join(", ")
          // item["psapixcontact"]=item.ixCrewId.mobilenumber
          // item["psapixvendor"]=item.ixCrewId.vendorId
          // item["psapixname"]=item.ixCrewId.name
          // item["psapixcompany"]=item.ixCrewId.crewLeadCompanyId
          // item["psapixemail"]=item.ixCrewId.email
          item["psapixcontact"]=item.ixCrewId?.mobilenumber
          item["psapixvendor"]=item.ixCrewId?.vendorId
          item["psapixname"]=item.ixCrewId?.name
          item["psapixcompany"]=item.ixCrewId?.crewLeadCompanyId
          item["psapixemail"]=item.ixCrewId?.email
          console.log("item is tec =", tec);
          
          item["projectCodenew"]=item["projectCode"].join(", ")

          item['remarksModal']=(
            <p
              className={"text-black my-0 modalHoverClass"}
              onClick={() =>
                CommonModalFun(

                  <p style={{ border: "2px solid black", color: "black", padding: 2 }} className="CommonModal">{item['remarks']}</p>,
                  "Comment",
                  "Remarks"
                )
              }
            >
              {more_info(item['remarks'])}
            </p>
          )
          //  console.log("item is =",item['technology'])
          // item['technology']=tec
          // item["techtemp"] = tec;
          
          item["techtemp"] = item["techuniqueId"]?item["techuniqueId"]:"";
          item["raiseTT"] =<>
          <Link
              
              // to={{
              //   pathname: "/PAG/trobleTicke/form/e911/"+item["projectUniqueId"],
              //   creator: {
              //     "creatorId": item["ennouniqueId"],
              //     "createFrom": "E911",
              //     "siteUniqueId": item["siteUniqueId"],
              //     "ranId": item["ranresultId"],
              //     "projectUniqueId": item["projectUniqueId"],
              //     "index": index
              //   } // your data array of objects
              // }}
            ><button onClick={(e)=>{topageraiseTT(item["ranresultId"])}} className="btn btn-primary shadow btn-xs" >Raise TT</button>
            </Link></>

          





          item["actions"] = <div className="d-flex"><button className="btn btn-primary shadow btn-xs sharp mr-1 d-flex justify-content-center align-items-center" onClick={() =>{clearOldData(item["enooTechWiseId"],item["ennouniqueId"])}}><i className="fa fa-pencil"></i></button>
          <button onClick={(e)=>deleteHandler(item.enooTechWiseId )} className="btn btn-danger shadow btn-xs sharp d-flex justify-content-center align-items-center"><i className="fa fa-trash"></i></button></div>;

          
          item["temptechAffected"] = temptechaff;
          item["addDateNew"]=dateOnly(item.addDate)
          const myMomentObject = dbdatetomoment(item.addDate)
          item["scheduleWeek"] = myMomentObject.isoWeekday(1).week()
          item["alphaSectornew"]=<p className={`badge badge-custom-size ${e911colorCode[item.alphaSector]}`}>{item.alphaSector}</p>
          item["betaSectornew"]=<p className={`badge badge-custom-size ${e911colorCode[item.betaSector]}`}>{item.betaSector}</p>
          item["gammaSectornew"]=<p className={`badge badge-custom-size ${e911colorCode[item.gammaSector]}`}>{item.gammaSector}</p>
          item["deltaSectornew"]=<p className={`badge badge-custom-size ${e911colorCode[item.deltaSector]}`}>{item.deltaSector}</p>
          item["epsilonSectornew"]=<p className={`badge badge-custom-size ${e911colorCode[item.epsilonSector]}`}>{item.epsilonSector}</p>
          item["zetaSectornew"]=<p className={`badge badge-custom-size ${e911colorCode[item.zetaSector]}`}>{item.zetaSector}</p>


          return item;
        });
        console.log("jdbcjhbsdaikc", state);
      }
    }

    console.log("columns", state.sites.sites.TotalTicket);

    return { rowData, columns, mark, siteStatus, totalCount };
  });

  console.log(count,"contcontcont")

  const options = {

    download: false,
    viewColumns: false,
    print: false,
    filterType: "none",
    selectableRows: false,
    
 fixedHeader: true,
 fixedFooter: true,
 tableBodyHeight: "100%",
 responsive: "scroll",
 overflowY: "scroll",
    rowsPerPage: 15,
    count: 100,
    rowsPerPageOptions: [15, 30, 45 , 60],
  };


  const mapStyles = {
    width: "100%",
    height: "100%",
  };

  const dispatch = useDispatch();
  function accordianHandler() {
    setAccordianState(!accordianState);
  }

  createTheme("solarized", {
    text: {
      primary: "inherit",
      secondary: "inherit",
    },
    background: {
      default: "inherit",
    },
    context: {
      background: "inherit",
      text: "inherit",
    },
    divider: {
      default: "inherit",
    },
    button: {
      default: "inherit",
      hover: "rgba(0,0,0,.08)",
      focus: "rgba(255,255,255,.12)",
      disabled: "rgba(255, 255, 255, .34)",
    },
    sortFocus: {
      default: "inherit",
    },
  });
  console.log("yhe row data isn=", count.rowData);
  //on submiting the excel
  const submitHandler = async (e) => {
    e.preventDefault();
    console.log("remarks =", remarks);
    console.log("file=", siteFile);
    console.log(columns);
    const form = new FormData();
    form.append("uploadFile", siteFile);
    form.append("uploadfor", "Network_Database");
    // form.append("remarks",remarks);
    setRemarks("");
    // uploadedFile()
    dispatch(CSVUploadAction(form));
    console.log("form data =", form);
  };
  const uploadCsvFile = (e) => {
    console.log(e.target.files[0]);
    uploadedFile(e.target.files[0]);
  };

  const setDataVariables = async () => {
    dispatch(NetworkDatabaseAction());
  };

  const containerStyle = {
    width: "400px",
    height: "400px",
    position: "initial",
  };

  const AnyReactComponent = ({ text }) => <div>{text}</div>;

  const handleApiLoaded = (map, maps) => {
    // use map and maps objects
  };


  const sortedOrder={
    // name: 'addDate',
    // direction: 'desc'
  }
  
  useEffect(() => {
    dispatch(AccountReducerAction(listennoTicketApi,true,true));
    // dispatch(AccountReducerAction(ranSiteStatus))
    // dispatch(AccountReducerAction(getAllDropDown))
    // dispatch(AccountReducerAction(listSiteId))

    if (!datamark) {
      setmarkersdata(datamark);
    }
    console.log(markersdata, "markersdata");
  }, [markersdata]);
  return (
    <>
       <CommonModal
          modalSize={commonModalSize}
          setXlShow={setcommonModalState}
          xlShow={commonModalState}
          Body={commonModalBody}
          Header={commonModalHeader}
          Title={commonModalTitle}
        ></CommonModal>
  <div className="mui-t-h"
          style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}
        >
          
        {console.log(count.totalCount,"count.totalCountcount.totalCountcount.totalCount")}
      <ThemeProvider theme={getMuiThemeCommon()}>
        <MUIDataTable
          data={rowData}
          columns={columns}
          options={advancdcustomDataTableOptionObject(AddButton,false,"E911_tracker_","","",sortedOrder, "listenno", "",rowData.length,count.totalCount,false)}
          
        />
      </ThemeProvider>
      </div>
    </>
  );
};

export default E911Tracker;
