import React, { useEffect, useState } from 'react'


import PerfectScrollbar from 'react-perfect-scrollbar'
import { Link } from 'react-router-dom'
import { Row, Col, Card, Accordion,Button } from 'react-bootstrap'

import "./Style.css"
import Table1 from './Table1'
import Table2 from './Table2'
import Table3 from './Table3'
import Table4 from './Table4'
import { FetchSingleRecordAction } from '../../../store/actions/createTicketManageFields'
import { ranDashboard } from '../../../common/config'
import { useDispatch } from 'react-redux'



const ProjectData = ({id}) => {
  const dispatch=useDispatch()
  const [activeAccordionGradient, setActiveAccordionGradient] = useState(0)



  useEffect(()=>{
    console.log(" ")
    // dispatch(FetchSingleRecordAction(ranDashboard,id))
  })

  return (
   <>
    <div className='p-0'>
         
    <div className="container">
    <div id="accordion" className="accordion">
        <div className="card mb-0">
            <div className="card-header collapsed" data-toggle="collapse" href="#collapseOne">
                <a className="card-title">
                  GC Login
                </a>
            </div>
            <div id="collapseOne" className="card-body collapse" data-parent="#accordion" >
            <Table1 id={id}/>
            </div>
            <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo">
                <a className="card-title">
                  Schedule Information
                </a>
            </div>
            <div id="collapseTwo" className="card-body collapse" data-parent="#accordion" >
            <Table2 id={id}/>
            </div>
            <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree">
                <a className="card-title">
                  Precheck
                </a>
            </div>
            <div id="collapseThree" className="collapse" data-parent="#accordion" >
            <Table3 id={id}/>
            </div>
            <div className="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapsefour">
                <a className="card-title">
                  Postcheck
                </a>
            </div>
            <div id="collapsefour" className="collapse" data-parent="#accordion" >
            <Table4 id={id}/>
            </div>
        </div>
    </div>
</div>
          </div>
        

   </>
  )
}

export default ProjectData