import React, { useLayoutEffect,createContext, useEffect, useState } from "react";

export const ThemeContext = createContext();
const ThemeContextProvider = (props) => {
   const body = document.querySelector("body");
    const [windowWidth, setWindowWidth] = useState(0);
    const [windowHeight, setWindowHeight] = useState(0);
    const [backgroundTheme,setBackgroundTheme] =useState(0)
    useLayoutEffect(() => {
		const body = document.querySelector("body");
   
    const color =localStorage.getItem("color")
    
    const setThemeHandler =(no)=>{
			console.log("colorCode","colorCode is ",no)
			localStorage.setItem("colorCode",JSON.stringify(no))
			// window.location.reload()
	  }
    if(color==null){
      localStorage.setItem("color","light")
      setBackgroundTheme("light")
			setThemeHandler(2)
    }
    else{
      console.log("color=",color)
      setBackgroundTheme("light")
			setThemeHandler(2)
    }

    let colorCode =localStorage.getItem("colorCode")
    
    var aaa = document.querySelector("#main-wrapper");
    colorCode=colorCode ?"color_"+colorCode:"color_1"    
    console.log(backgroundTheme,"classList",aaa)
		let resizeWindow = () => {
			setWindowWidth(window.innerWidth);
			setWindowHeight(window.innerHeight);

      console.log(aaa?aaa.getAttribute("show"):"","themecontext")
			window.innerWidth >= 768 && window.innerWidth < 1300
			? body.setAttribute("data-sidebar-style", "overlay")
			: window.innerWidth <= 768
			? body.setAttribute("data-sidebar-style", "overlay")
			: body.setAttribute("data-sidebar-style", "overlay");
		};
        body.setAttribute("data-typography", "poppins");
        // body.setAttribute("data-theme-version", "light");
        body.setAttribute("data-theme-version", backgroundTheme);
        body.setAttribute("data-layout", "vertical");
        body.setAttribute("data-nav-headerbg",colorCode);
        body.setAttribute("data-headerbg", colorCode);
        body.setAttribute("data-sidebar-style", "overlay");
        body.setAttribute("data-sibebarbg", colorCode);
        body.setAttribute("data-primary", "color_1");
        body.setAttribute("data-sidebar-position", "fixed");
        body.setAttribute("data-header-position", "fixed");
        body.setAttribute("data-container", "wide");
        body.setAttribute("direction", "ltr");
        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    }, [backgroundTheme]);
    return (
    <ThemeContext.Provider
      value={{
        body,
		windowWidth,
		windowHeight
	}}
    >
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
