import React, { useEffect }  from 'react'
import Historytable from './Historytable'

const RanHistorical = ({id}) => {

  
useEffect(()=>{

},[])
  return (
    <>

<Historytable id={id}/>

    </>
  )
}

export default RanHistorical;