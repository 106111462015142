import React, { useEffect, useRef, useState } from 'react'
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { FetchSingleRecordAction, addUserAction } from '../../../../store/actions/createTicketManageFields';
import { SSVDashboardAPI } from '../../../../common/config';
import { useDispatch, useSelector } from 'react-redux';
const moment = require('moment');
const SSVModal = (props) => {
  const { register, setValue,getValues , handleSubmit, setError, formState: { errors } } = useForm({
    criteriaMode: 'all',
  });
    let [ssvSubmitDate,setSSVSubmitDate] =useState("")
    let [ssvApprovalDate,setSSVApprovalDate] =useState("")
    let [ssvResubmitDate,setSSVStatus] =useState("")
    let dispatch =useDispatch()
    let count =useRef(1)
    const styles = {
        width: "400px",
      };
      const option =useSelector((state)=>{
        console.log("jhvhjvbkljzbxcuyfdcusdv=",state)
        let fetchData=state.OneCommonResponse.singleIXSite 
        console.log("kbhjb sdfcsdfedfe=",fetchData)
        if(fetchData && Object.keys(fetchData).length>0){
            if(count.current==1){
            if(fetchData.ssvSubmitDate){
              setSSVSubmitDate(new Date(fetchData.ssvSubmitDate))
              }
            if(fetchData.SSVApprovalDate){
              setSSVApprovalDate(new Date(fetchData.SSVApprovalDate))
              }
            if(fetchData.ssvResubmitDate){
              setSSVStatus(new Date(fetchData.ssvResubmitDate))
              }
            setValue("SSVStatus",fetchData.SSVStatus)
            setValue("SSVCommentIssue",fetchData["SSVCommentIssue"])

            count.current =2
        }
        }

      })


      const  onSubmit=(data)=>{
        data["ssvSubmitDate"]= moment(ssvSubmitDate).format("MM/DD/YYYY")
        data["SSVApprovalDate"]=moment(ssvApprovalDate).format("MM/DD/YYYY")
        data["ssvResubmitDate"]=moment(ssvResubmitDate).format("MM/DD/YYYY")
        console.log("the data is =",data)
        dispatch(addUserAction(SSVDashboardAPI+"/"+props.id,data))

    }
    useEffect(()=>{
      dispatch(FetchSingleRecordAction(SSVDashboardAPI,props.id))
    },[])
  return (

    <form className="row"  onSubmit={handleSubmit(onSubmit)}>
          <div className='form-group col-6'>
            <label>SSV Submit Date</label>
            <DatePicker
          style={styles}
          className="form-control"
          selected={ssvSubmitDate}
          onChange={(e) => {
            setSSVSubmitDate(e);
          }}
          popperPlacement="bottom"
          dateFormat="MM/dd/yyyy"
        />
        </div>
        <div className='form-group col-6'>
            <label>SSV Approval Date</label>
            <DatePicker
          style={styles}
          className="form-control"
          selected={ssvApprovalDate}
          onChange={(e) => {
            setSSVApprovalDate(e);
          }}
          popperPlacement="bottom"
          dateFormat="MM/dd/yyyy"
        />
        </div>
        <div className='form-group col-6'>
            <label>SSV Status</label>
            <select 
            className='form-group'
             {...register("SSVStatus")}
            >
              <option disabled selected value={""}>Select</option>
              <option value={"Pending"}>Pending</option>
              <option value={"Submitted"}>Submitted</option>
              <option value={"Approved"}>Approved</option>
            </select>
        </div>
        <div className='form-group col-6'>
            <label>Comment/Issue</label>
            <input type ="text"  className='form-control  '  {...register("SSVCommentIssue")}/>
        </div>
        <div className='form-group col-6'>
            <label>Re-Sumitted Date</label>
            <DatePicker
          style={styles}
          className="form-control"
          selected={ssvResubmitDate}
          onChange={(e) => {
            setSSVStatus(e);
          }}
          popperPlacement="bottom"
          dateFormat="MM/dd/yyyy"
        />
        </div>
        <div className='col-12 d-flex justify-content-center m-2'>
            <button type='submit' className='badge badge-info'>Submit</button>
        </div>
    </form>
  )
}

export default SSVModal