import React from 'react'
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import * as XLSX from "xlsx";
import swal from 'sweetalert';

export const ExportToExcel = ({ apiData, fileName }) => {



    console.log(apiData, fileName)
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToCSV = (apiData, fileName) => {
       if(apiData.length>0){ 
        const ws = XLSX.utils.json_to_sheet(apiData);

        const title = fileName;
        const header = Object.keys(apiData[0])

        const data = apiData;

        //Create a workbook with a worksheet
        let workbook = new Workbook();
        let worksheet = workbook.addWorksheet(fileName);
        let cols=[]
        header.map(itt=>{
            cols.push({header:itt,key: itt})
        })

        worksheet.columns = cols;

        //Add Row and formatting
        // worksheet.mergeCells('C1', 'F4');
        // let titleRow = worksheet.getCell('C1');
        // titleRow.value = title
        // titleRow.font = {
        //     name: 'Calibri',
        //     size: 16,
        //     underline: 'single',
        //     bold: true,
        //     color: { argb: '0085A3' }
        // }
        // titleRow.alignment = { vertical: 'middle', horizontal: 'center' }

        // Date
        // worksheet.mergeCells('G1:H4');
        // let d = new Date();
        // let date = d.getDate() + '-' + d.getMonth() + '-' + d.getFullYear();
        // let dateCell = worksheet.getCell('G1');
        // dateCell.value = date;
        // dateCell.font = {
        //     name: 'Calibri',
        //     size: 12,
        //     bold: true
        // }
        // dateCell.alignment = { vertical: 'middle', horizontal: 'center' }


        // //Blank Row 
        // worksheet.addRow([]);

        //Adding Header Row
        let headerRow = worksheet.addRow(header);
        headerRow.eachCell((cell, number) => {
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '143B64' },
                bgColor: { argb: '' }
            }
            cell.font = {
                bold: true,
                color: { argb: 'FFFFFF' },
                size: 13,
                name: "poppins"

            }
        })

        // Adding Data with Conditional Formatting
        data.forEach((d,i) => {

            console.log(d,"data")
            let row=worksheet.addRow(d);
            console.log("the row is =",row)
            // row.forEach((item,index)=>{
            // let rowcell=item.getCell(index+1)
            //     rowcell.font={
            //         name: "poppins"
            //     }
            // })
            row.font={
                name: "poppins"
            }
            // let rowcell=row.getCell(i+1)
            // rowcell.font={
            //     name: "poppins"
            // }
            const color_dict={
                "Approved":"2BC155",
                "Pending":"F5E949",
                "Rejected":"B03636",
                "Go":"2BC155",
                "No Go":"B03636"
            }
            let  fos = row.getCell(15);
            console.log("the row is =",row)
        
            let goNoGoColumn=row.getCell(14)
            let sds = row.getCell(16);
            let rfs = row.getCell(17);
            


            // let color = '';
            // if (+sales.value < 200000) {
            //     color = 'FF9999'
            // }

            console.log(sds,sds.value,color_dict[sds["Site Development Approval Status"]],"colorType")
            const borderStyle={
                style:1
            }
            goNoGoColumn.fill={
                type: 'pattern',
        
                pattern: 'solid',
                fgColor: { argb: color_dict[goNoGoColumn.value] }
            }
            
            sds.fill = {
                type: 'pattern',
                border:borderStyle,
                pattern: 'solid',
                fgColor: { argb: color_dict[sds.value] }
            }

            rfs.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: color_dict[rfs.value] }
            }

            fos.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: color_dict[fos.value] }
            }
        }
        );

        worksheet.spliceRows(1,1)

        // worksheet.addRows(data)

        // console.log(data,"totladata")

        // worksheet.getColumn(3).width = 20;
        // worksheet.addRow([]);

        
        // worksheet.insertRow(1, {id: 1, name: 'John Doe', dob: new Date(1970,1,1)});
        // worksheet.insertRow(1, {id: 2, name: 'Jane Doe', dob: new Date(1965,1,7)});

        //Footer Row
        // let footerRow = worksheet.addRow(['Employee Sales Report Generated from example.com at ' + date]);
        // footerRow.getCell(1).fill = {
        //     type: 'pattern',
        //     pattern: 'solid',
        //     fgColor: { argb: 'FFB050' }
        // };

        //Merge Cells
        // worksheet.mergeCells(`A${footerRow.number}:F${footerRow.number}`);

        //Generate & Save Excel File
        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            fs.saveAs(blob, title + '.xlsx');
        })
    }
    else{
        swal({
            title: "Oops",
            text: "No Data Avilable To Export",
            icon: "info",
            button: "Ok",
        })
    }
    };

    return (
        <button className='btn btn-primary btn-xs mx-2 py-2 my-3' onClick={(e) => exportToCSV(apiData, fileName)}>Export</button>
    );
};
