import React, { useState } from 'react'
/// React router dom
import {Switch, Route } from 'react-router-dom'
/// Css
import './index.css'
import './chart.css'
import './step.css'

/// Layout
import Nav from './layouts/nav'
import Footer from './layouts/Footer'

/// Dashboard
import Home from './components/Dashboard/Home';
import EventList from './components/Dashboard/EventList';
import EventPage from './components/Dashboard/EventPage';
import Analytics from './components/Dashboard/Analytics';
import Reviews from './components/Dashboard/Reviews';
import Customers from './components/Dashboard/Customers';
import Task from './components/Dashboard/Task';

/// App
import AppProfile from './components/AppsMenu/AppProfile/AppProfile'
import PostDetails from './components/AppsMenu/AppProfile/PostDetails'
import Compose from './components/AppsMenu/Email/Compose/Compose'
import Inbox from './components/AppsMenu/Email/Inbox/Inbox'
import Read from './components/AppsMenu/Email/Read/Read'
import Calendar from './components/AppsMenu/Calendar/Calendar'
import PAG_Dashboard from './components/PAG/Home'
import PAG_Filed from './components/Filed/ElementTwo'
import PAG_Network from './components/Network/Networkdata'
import TicketTrackerDashboard from './components/TroubleTicket/TroubleTicketDashboard'
import TicketTracker from './components/TroubleTicket/TicketTracker'
import RanDashboard  from './components/Ran/RanDashboard'
import RanSupportTracker from './components/Ran/RanSupportTracker'
import RanSupportDaliyReport from './components/Ran/RanSupportDaliyReport'
import RanSlaReport from './components/Ran/RanSlaReport'
import NetworkDatabase from './components/Network Database/NetworkDatabase'
// import CustomerDashboard from './components/Customer/CustomerDashboard'
import CustomerDashboard from './components/editeddashboards/CustomerDashboard'
import CxIXApprovals from './components/Customer/CxIXApprovals'
import COPApprovals from './components/Customer/COPApprovals'
import COPApprovalReports from './components/Customer/COPApprovalReports'
import VendorScoreCard from './components/Customer/VendorScoreCard'
import POSTIXDashBoard from './components/POST IX RF SUPPORT/DashBoard'
import PostTracker  from './components/POST IX RF SUPPORT/Tracker' 
import KPIDegratedSite from './components/POST IX RF SUPPORT/KPIDegratedSite'
import OnAir from './components/POST IX RF SUPPORT/OnAir'
import CloseOutReport from './components/POST IX RF SUPPORT/CloseOutReport'
import Ransupportform from './components/Forms/Element/Ransupportform'
import CreateTickeFields from './components/Manage Admin/CreateTicketFields/CreateTickeFields'
import managercrew from './components/Forms/Element/managercrew'
import ManageAccount from './components/Manage Admin/Manage Account/ManageAccount'
import ManageCrewCompany from './components/Manage Admin/Manage Crew Company/ManageCrewCompany'
import ManageRfds from './components/Network Database/ManageRfdsbulk/ManageRfdsbulk'

import ManageVendor from './components/Manage Admin/Manage Vendor/ManageVendor'
import AddUserrole from './components/Manage Admin/Userrole/userrole'
import AddUsercrew from './components/Manage Admin/Usercrew/usercrew'
import Manage from "./components/Manage Admin/ManageDropdown/Manage"
import ManageOss from './components/Manage Admin/Manage Oss/ManageOss'


import ManageMarket from './components/Manage Admin/Manage Market/ManageMarket'
/// Product List
import ProductGrid from './components/AppsMenu/Shop/ProductGrid/ProductGrid'
import ProductList from './components/AppsMenu/Shop/ProductList/ProductList'
import ProductDetail from './components/AppsMenu/Shop/ProductGrid/ProductDetail'
import Checkout from './components/AppsMenu/Shop/Checkout/Checkout'
import Invoice from './components/AppsMenu/Shop/Invoice/Invoice'
import ProductOrder from './components/AppsMenu/Shop/ProductOrder'
import EcomCustomers from './components/AppsMenu/Shop/Customers/Customers'



import IXModule from './components/IX Module New/IXModule'
import IXCalendar from './components/IX Module New/IXCalender'
import EditIXModule from './components/IX Module New/EditIXModule'
import IXDashboard from "./components/IX Module New/IXDashboard"
import IXForm from "./components/IX Module New/IXFrom"



/// Charts
import RechartJs from './components/charts/rechart'
import ChartJs from './components/charts/Chartjs'
import Chartist from './components/charts/chartist'
import SparklineChart from './components/charts/Sparkline'
import ApexChart from './components/charts/apexcharts'

/// Bootstrap
import UiAlert from './components/bootstrap/Alert'
import UiAccordion from './components/bootstrap/Accordion'
import UiBadge from './components/bootstrap/Badge'
import UiButton from './components/bootstrap/Button'
import UiModal from './components/bootstrap/Modal'
import UiButtonGroup from './components/bootstrap/ButtonGroup'
import UiListGroup from './components/bootstrap/ListGroup'
import UiMediaObject from './components/bootstrap/MediaObject'
import UiCards from './components/bootstrap/Cards'
import UiCarousel from './components/bootstrap/Carousel'
import UiDropDown from './components/bootstrap/DropDown'
import UiPopOver from './components/bootstrap/PopOver'
import UiProgressBar from './components/bootstrap/ProgressBar'
import UiTab from './components/bootstrap/Tab'
import UiPagination from './components/bootstrap/Pagination'
import UiGrid from './components/bootstrap/Grid'
import UiTypography from './components/bootstrap/Typography'

/// Plugins
import Select2 from './components/PluginsMenu/Select2/Select2'
import Nestable from './components/PluginsMenu/Nestable/Nestable'
import MainNouiSlider from './components/PluginsMenu/Noui Slider/MainNouiSlider'
import MainSweetAlert from './components/PluginsMenu/Sweet Alert/SweetAlert'
import Toastr from './components/PluginsMenu/Toastr/Toastr'
import JqvMap from './components/PluginsMenu/Jqv Map/JqvMap'
import Lightgallery from './components/PluginsMenu/Lightgallery/Lightgallery'


/// Widget
import Widget from './pages/Widget'

/// Table
import DataTable from './components/table/DataTable'
import BootstrapTable from './components/table/BootstrapTable'
import SortingTable from "./components/table/SortingTable/SortingTable";
import FilteringTable from "./components/table/FilteringTable/FilteringTable";


/// Form
import ReduxForm from "./components/Forms/ReduxForm/ReduxForm";
import WizardForm from "./components/Forms/ReduxWizard/Index";
import Element from './components/Forms/Element/Element'
import Edit from './components/Forms/Edit/Editpage'
import NetworkTwo from './components/NetworkTwo/NetworkTwo'
import Wizard from './components/Forms/Wizard/Wizard'
import SummerNote from './components/Forms/Summernote/SummerNote'
import Pickers from './components/Forms/Pickers/Pickers'
import jQueryValidation from './components/Forms/jQueryValidation/jQueryValidation'


/// Pages
import Registration from './pages/Registration'
import Login from './pages/Login'
import LockScreen from './pages/LockScreen'
import Error400 from './pages/Error400'
import Error403 from './pages/Error403'
import Error404 from './pages/Error404'
import Error500 from './pages/Error500'
import Error503 from './pages/Error503'
import Todo from './pages/Todo';

//Scroll To Top
import ScrollToTop from './layouts/ScrollToTop';
import SingleProject from './components/ProjectModule/SingleProject'
import ManageCr from './components/Manage Admin/Manage Cr/ManageCr'
import OnTheFlyForm from './components/Forms/Ran Form/OnTheFly'
import Email from './components/Ran/Email'
import TroubleTicketForm from './components/TroubleTicket/TroubleTicketForm'
import FiltererdProject from './components/Independent components/FiltererdProject'
import EditProject from './components/Forms/Element/EditProject'
import SiteApproval from './components/Forms/Element/SiteApproval'
import NetworkDatabasebulkfile from './components/bulk/networkDatabasebulkfile'
import Inflightbulkfile from './components/bulk/inflightbulkfile'
import Magentabulkfile from './components/bulk/magentabulkfile'
import Mop from './components/Mop/Mop'
import Technology from './components/Manage Admin/ManageDropdown/Technology'
import Tmobiledeployment from './components/Manage Admin/Userrole/tmobiledeployment'
import ManageTier from './components/Manage Admin/Manage Tier/managetier'
import ManageEngineer from './components/Manage Admin/Userrole/manageEngineer'
import ManageSite from './components/Manage Admin/Manage Site/manageSite'

import E911form from './components/E911 Call Test/E911form'
import E911Dashboard from './components/E911 Call Test/E911Dashboard'
import E911Tracker from './components/E911 Call Test/E911Tracker'
import ExecutiveSpilloverReport from './components/Ran/ExecutiveSpilloverReport'
import RanNestingReport from './components/Ran/ranNestingReport'
// import VendorDashboard from './components/vendor/VendorDashboard'
import VendorDashboard from './components/editeddashboards/VendorDashboard'
import ManageGeoFencing from './components/Manage Admin/Manage GeoFencing/ManageGeoFencing'
import ChangePassword from './components/ChangePassword/ChangePassword'
import RanNestingTracker from './components/Ran/ranNestingTracker'
// import IXModule from './components/IX Module/IXModule'
import SevenTwo from './components/72Alarm/SevenTwo'
import RTTDashboard from './components/RTT/RTTDashboard'
// import IXCalendar from './components/IX Module/IXCalender'
// import EditIXModule from './components/IX Module/EditIXModule'
import SSVDashboard from './components/Rf Performance/ssvDashboard'
import LaunchSSS from './components/Rf Performance/LaunchSSS'
import AwsDashboard from './components/editeddashboards/AwsDashboard'
import ManagePierTT from './components/Manage Admin/Manage TTPierGroup/ManagePierTT'
import ManageUtilization from './components/Manage Admin/Manage Utilization/ManageUtilization'
import SevenTracker from './components/72Alarm/sevenTracker'
import RTTForm from './components/RTT/RTTForm'
import AddSevenTwo from './components/72Alarm/AddSevenTwo'
import Sheet from './components/Rf Performance/Sheet'
import AddSSV from './components/Rf Performance/AddSSV'
import AddLaunchSSV from './components/Rf Performance/AddLaunchSSV'
import SpeedTest from './components/E911 Call Test/SpeedTest'
import ManageMOPInstruction from './components/Manage Admin/Manage MOP Instruction/ManageMOPInstruction'
import NewHome from './components/Dashboard/NewHome'
import LaunchSSSApproval from './components/Rf Performance/LaunchSSSApproval'
import SSVDashboardApproval from './components/Rf Performance/SSVDashboardApproval'
import ProjectList from './components/ProjectList/ProjectList'
import ScoreCard from './components/editeddashboards/ScoreCard'
import ManageVendorScoreCard from './components/Manage Admin/Manage Utilization/ManageVendorScoreCard'
import ViewProjectList from './components/ProjectList/ViewProjectList'
import MergedLaunchSSSApproval from './components/Rf Performance/MergedLaunchSSSApproval'
import CombineMergedLaunchSSSApproval from './components/Rf Performance/CombineMergedLaunchSSSApproval'


const Markup = () => {
  let path = window.location.pathname
  path = path.split('/')
  path = path[path.length - 1]
  let pagePath = path.split('-').includes('page')
  const [activeEvent, setActiveEvent] = useState(!path)

  const routes = [
    /// Dashboard
  // { url: '', component: Home },
  // { url: '', component: NewHome },
  { url: '', component: CustomerDashboard },
  
  { url: 'dashboard', component: Home },
	{ url: 'event-list', component: EventList },
	{ url: 'event', component: EventPage },
	{ url: 'analytics', component: Analytics },
	{ url: 'reviews', component: Reviews },
	{ url: 'customers', component: Customers },
	{ url: 'task', component: Task },

    /// Apps

    
    { url: 'inflight', component: Inflightbulkfile },
    { url: 'magenta', component: Magentabulkfile },
    { url: 'networkDatabase', component: NetworkDatabasebulkfile },
    { url: 'app-profile', component: AppProfile },
    { url: 'post-details', component: PostDetails },
    { url: 'email-compose', component: Compose },
    { url: 'email-inbox', component: Inbox },
    { url: 'email-read', component: Read },
    { url: 'PAG/dashboard', component: PAG_Dashboard },
    { url: 'PAG/Filed', component: PAG_Filed},
    { url: 'PAG/Network',component:PAG_Network},
    { url: 'PAG/troubleTickets/dashboard', component:TicketTrackerDashboard },
    { url: 'PAG/E911/dashboard', component:E911Dashboard },
    { url: 'PAG/E911/Tracker', component:E911Tracker },
    { url: 'PAG/troubleTickets/tracker', component :TicketTracker},
    { url: 'PAG/ran/support/dashboard', component :RanDashboard},
    { url: 'PAG/ran/support/tracker', component :RanSupportTracker},
    { url: 'PAG/ran/support/dailyReport' ,component:RanSupportDaliyReport},
    { url: 'PAG/ran/sla/report', component:RanSlaReport},
    { url: 'PAG/network/database' ,component :NetworkDatabase},
    { url: 'PAG/network/database/:id', component:EditProject},
    { url: 'PAG/cx-ix/approvals', component:CxIXApprovals},
    { url: 'PAG/cop/approvals', component:COPApprovals},
    { url: 'PAG/cop/approval/reports', component:COPApprovalReports},
    { url: 'PAG/customer/vendor/scorecard', component:VendorScoreCard},
    { url: 'PAG/post-ix-rf/support/dashboard', component:POSTIXDashBoard},
    { url: 'PAG/post-ix-rf/support/tracker', component:PostTracker},
    { url: 'PAG/kpi/degradedSite/dashboard', component:KPIDegratedSite},
    { url : 'PAG/post-ix-rf/onAir', component:OnAir},
    { url : 'PAG/post-ix-rf/closeoutReport', component:CloseOutReport},
    { url :'PAG/manage/geoFencing', component:ManageGeoFencing},
    { url :'PAG/ChangePassword', component:ChangePassword},
    { url :'PAG/manage/account', component:ManageAccount},
    { url :'PAG/manage/vendor', component:ManageVendor},
    { url :'PAG/manage/MOPInstruction', component:ManageMOPInstruction},
    
    { url :'PAG/manage/crewcompany', component:ManageCrewCompany},
    { url :'PAG/userAccount', component:AddUserrole},
    { url :'PAG/userCrew', component:AddUsercrew},
    { url :'PAG/manage/utilization', component:ManageUtilization},
    { url :'PAG/manage/scoreCard', component:ManageVendorScoreCard},
    { url : 'PAG/ran/support/dashboard/:id', component:SingleProject},
    { url : 'PAG/manage/cr', component:ManageCr} ,
    { url : 'PAG/vendorScoreCard/dashboard' , component : VendorScoreCard},
    { url  :'PAG/manage/oss', component:ManageOss},
    { url : 'PAG/manage/market', component:ManageMarket},
    { url : 'PAG/T-Mobile', component:Tmobiledeployment},
    { url : 'PAG/Tier', component:ManageTier},
    { url : 'PAG/manageEngineer', component:ManageEngineer},
    { url : 'PAG/ManageSite', component:ManageSite},
    { url : 'PAG/sendMail' , component:Email},
    { url : 'PAG/manage/rfds' , component:ManageRfds},
    { url : 'PAG/manage/technology', component:Technology},
    { url : 'PAG/Mop/database' ,component:Mop },
    { url : 'PAG/Mop/database/open' ,component:Mop },
    { url : "PAG/trobleTicke/form/:from/:ranId", component:TroubleTicketForm},
    { url : "PAG/trobleTicke/form/:from", component:TroubleTicketForm},
    { url : "PAG/:type/network/siteApproval/:id",component:SiteApproval },
    { url : "PAG/E911CallTest/form/:dir" ,component:E911form},
    { url : "PAG/E911CallTest/form/:dir/:id" ,component:E911form},
    { url : "PAG/SpeedTest/form/:dir" ,component:SpeedTest},
    { url : "PAG/SpeedTest/form/:dir/:id" ,component:SpeedTest},
    { url : "PAG/ran/executivespillover/report" , component:ExecutiveSpilloverReport},
    { url : "PAG/ran/nestingTime/report" , component:RanNestingReport},
    { url : "PAG/ran/nestingTime/tracker" , component:RanNestingTracker},
    { url : "PAG/ssv/dashboard",component:SSVDashboard},
    { url : "PAG/launchSSSNNP/dashboard",component:LaunchSSS},
    { url : "PAG/ssv/Approval/dashboard",component:SSVDashboardApproval},
    { url : "PAG/merged/Approval/dashboard",component:MergedLaunchSSSApproval},
    { url : "PAG/combine/Approval/dashboard",component:CombineMergedLaunchSSSApproval},
    { url : "PAG/launchSSSNNP/Approval/dashboard",component:LaunchSSSApproval},
    { url : 'PAG/aws/dashboard' , component : AwsDashboard},
    { url : 'PAG/customer/dashboard', component:CustomerDashboard},
    { url : 'PAG/ProjectList', component:ProjectList},
    { url : 'PAG/ViewProjectList/:urlsiteid', component:ViewProjectList},

    
    { url : 'PAG/vendor/dashboard', component:VendorDashboard},
    { url : "PAG/sevenTwo/form" ,component:AddSevenTwo},
    { url : 'PAG/vendorScore/dashboard' , component : ScoreCard},
    { url : "PAG/rfperformance/sheet",component:Sheet},
    { url : "PAG/ssv/add",component :AddSSV}, 
    { url : "PAG/ssv/edit/:id",component :AddSSV}, 
    /// Chart
    { url: 'chart-sparkline', component: SparklineChart },
    { url: 'chart-chartjs', component: ChartJs },
    { url: 'chart-chartist', component: Chartist },
    { url: 'chart-apexchart', component: ApexChart },
    { url: 'chart-rechart', component: RechartJs },

    /// Bootstrap
    { url: 'ui-alert', component: UiAlert },
    { url: 'ui-badge', component: UiBadge },
    { url: 'ui-button', component: UiButton },
    { url: 'ui-modal', component: UiModal },
    { url: 'ui-button-group', component: UiButtonGroup },
    { url: 'ui-accordion', component: UiAccordion },
    { url: 'ui-list-group', component: UiListGroup },
    { url: 'ui-media-object', component: UiMediaObject },
    { url: 'ui-card', component: UiCards },
    { url: 'ui-carousel', component: UiCarousel },
    { url: 'ui-dropdown', component: UiDropDown },
    { url: 'ui-popover', component: UiPopOver },
    { url: 'ui-progressbar', component: UiProgressBar },
    { url: 'ui-tab', component: UiTab },
    { url: 'ui-pagination', component: UiPagination },
    { url: 'ui-typography', component: UiTypography },
    { url: 'ui-grid', component: UiGrid },
    
    /// Plugin
    { url: 'uc-select2', component: Select2 },
    { url: 'uc-nestable', component: Nestable },
    { url: 'uc-noui-slider', component: MainNouiSlider },
    { url: 'uc-sweetalert', component: MainSweetAlert },
    { url: 'uc-toastr', component: Toastr },
    { url: 'map-jqvmap', component: JqvMap },
    { url: 'uc-lightgallery', component: Lightgallery },


    /// Widget
    { url: 'widget-basic', component: Widget },

    /// Shop
    { url: 'ecom-product-grid', component: ProductGrid },
    { url: 'ecom-product-list', component: ProductList },
    { url: 'ecom-product-detail', component: ProductDetail },
    { url: 'ecom-product-order', component: ProductOrder },
    { url: 'ecom-checkout', component: Checkout },
    { url: 'ecom-invoice', component: Invoice },
    { url: 'ecom-product-detail', component: ProductDetail },
    { url: 'ecom-customers', component: EcomCustomers },


    { url : "PAG/ix/module",component:IXModule},
    { url : "PAG/ix/module/:type/:uniqueId",component:EditIXModule},
      { url : "PAG/ix/calender",component:IXCalendar},
      { url : "PAG/ix/calendera",component:IXCalendar},
    { url : "PAG/ix/dashboard",component:IXDashboard},
    // { url : "PAG/speedTest/form/:dir" ,component:IXForm},
    // { url : "PAG/speedTest/form/:dir/:id" ,component:IXForm},
    /// Form
    
    { url: 'form-redux', component: ReduxForm },
    { url: 'form-redux-wizard', component: WizardForm },
    { url: 'form-element', component: Element },
    {url: 'form-edit', component:Edit},
    { url: 'form-wizard', component: Wizard },
    { url: 'form-wizard', component: Wizard },
    { url: 'form-editor-summernote', component: SummerNote },
    { url: 'form-pickers', component: Pickers },
    { url: 'form-validation-jquery', component: jQueryValidation },
    { url: 'PAG/createProject', component :Element},
    {url:'PAG/createNetwork', component :NetworkTwo},
    {url: 'PAG/RanSupportticket',component:Ransupportform},
    {url:'PAG/managercrew',component:managercrew},
    {url :'PAG/ran/OntheFlyForm/:id', component:OnTheFlyForm},
    {url :'PAG/ran/OntheFlyForm/:dbType/:tb/:id', component:OnTheFlyForm },
    {url :"PAG/manage/dropDown", component:Manage},
    {url : "PAG/manage/ttPIERGroup",component :ManagePierTT },
    { url : "PAG/ix/module",component:IXModule},
    { url : "PAG/ix/module/:type/:uniqueId",component:EditIXModule},
    { url : "PAG/72alarm/dashboard",component:SevenTwo},
    { url : "PAG/launch/ssv" ,component:AddLaunchSSV},
    { url : "PAG/launch/ssv/edit/:id" ,component:AddLaunchSSV},
    // { url : "PAG/72alarm/tracker",component:SevenTracker},
    { url : "PAG/rtt/dashboard",component:RTTDashboard},
    { url : "PAG/rtt/form" ,component:RTTForm},
    { url : "PAG/rtt/form/WildCard/:id" ,component:RTTForm},
    { url : "PAG/rtt/form/edit/:id" ,component:RTTForm},
    { url : "PAG/ix/calender",component:IXCalendar},
    /// table
    { url: 'table-datatable-basic', component: DataTable },
    { url: 'table-bootstrap-basic', component: BootstrapTable },
    { url: 'table-filtering', component: FilteringTable },
    { url: 'table-sorting', component: SortingTable },

    /// pages
    { url: 'page-register', component: Registration },
    { url: 'page-lock-screen', component: LockScreen },
    { url: 'page-login', component: Login },
    { url: 'page-error-400', component: Error400 },
    { url: 'page-error-403', component: Error403 },
    { url: 'page-error-404', component: Error404 },
    { url: 'page-error-500', component: Error500 },
    { url: 'page-error-503', component: Error503 },
    { url: 'pag/Admin/manage/CreateTickets', component: CreateTickeFields },
  ]

  return (
       <> 
          <div
            id={`${!pagePath ? 'main-wrapper' : ''}`}
            className={`${!pagePath ? 'show ' : 'mh100vh'}`}
          >
            {!pagePath && (
              <Nav
                onClick={() => setActiveEvent(!activeEvent)}
                activeEvent={activeEvent}
                onClick2={() => setActiveEvent(false)}
                onClick3={() => setActiveEvent(true)}
              />
            )}
            <div
              className={` ${!path && activeEvent ? 'rightside-event' : ''} ${
                !pagePath ? 'content-body' : ''
              }`}
            >
              <div
                className={`${!pagePath ? 'container-fluid' : ''}`}
                style={{ minHeight: '100%' }}
              >
                <Switch>
                  {routes.map((data, i) => (
                    <Route
                      key={i}
                      exact
                      path={`/${data.url}`}
                      component={data.component}
                    />
                  ))}
                </Switch>
              </div>
            </div>
            {!pagePath && <Footer />}
          </div>
         <ScrollToTop />
       </>
  )
}

export default Markup
