import React, { useEffect } from 'react'
import { Timeline, TimelineEvent } from '@mailtop/horizontal-timeline'
import { FaBug, FaRegCalendarCheck, FaRegFileAlt,FaClipboardCheck } from 'react-icons/fa'
import {RiLoginCircleFill} from 'react-icons/ri'
import {FcApproval} from 'react-icons/fc'
import { GetTimeLineAction } from '../../../store/actions/createTicketManageFields'
import { ranDashboardTimeLine, timelineApi } from '../../../common/config'
import { useDispatch, useSelector } from 'react-redux'
const SingleProject = ({id}) => {
const dispatch=useDispatch()
let timelineArray=[]
let iconArray =[RiLoginCircleFill,FcApproval,FaRegFileAlt,FaClipboardCheck,FaRegCalendarCheck,FaBug]
let colorArray =['#87a2c7','#9c2919',,"#2a9d8f","#3a5a40","#fb5607","#84a59d","#d00000","#9e0059","#9e2a2b"]
let projectJouney=[]
const timeline =useSelector((state)=>{
    console.log("the state is =",state)
    projectJouney =state.OneCommonResponse.timelineLogs
    if(projectJouney && projectJouney.length >0){
      console.log("the timelinedata =",projectJouney)
        timelineArray=projectJouney[0].array.map((item,index)=>{
            console.log("the indexis =",index)
            return  <TimelineEvent 
                        color={colorArray[index]}
                        icon={iconArray[index]}
                        title={item.projectStatus}
                        subtitle={item.date}
                        variant={"small"}
                        // topBottomPosition="500px" widthOfPosition="10px"
                    />
        })
    }
    return {timelineArray}
})

useEffect(()=>{
            let currentUrl =window.location.href
            let siteId= currentUrl.split('/').pop()
            console.log(currentUrl)
            // dispatch(GetTimeLineAction(ranDashboardTimeLine,id))
    },[])
  return (
    <Timeline minEvents={projectJouney ?projectJouney[0]?projectJouney[0].array.length:0:0}   >
  {/* <TimelineEvent
    icon={FaRegFileAlt}
    title='Em rascunho'
    subtitle='26/03/2019 09:51'
    
  />
  <TimelineEvent
    color='#87a2c7'
    icon={FaRegCalendarCheck}
    title='Agendado'
    subtitle='26/03/2019 09:51'
  />
  <TimelineEvent style={{fontSize:"10px"}}
    color='#9c2919'
    
    icon={FaBug}
    title='Erro'
    subtitle='26/03/2019 09:51'
    action={{
      label: 'Ver detalhes...',
      onClick: () => window.alert('Erro!')
    }}
  /> */}
  {timeline.timelineArray}
</Timeline>
  )
}

export default SingleProject