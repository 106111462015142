import React, { Fragment, useState, useEffect } from "react";
import { SplitButton, ButtonGroup, Dropdown, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import { useForm } from "react-hook-form";
// import { addCrNumber, allTechnologyApi, listSiteId, listUserApi_two, projectCode, ranAddSupport, singleProjectApi } from "../../../common/config";;
// import { AccountReducerAction, addUserAction, FetchSingleRecordAction, UserListReducerAction } from "../../../store/actions/createTicketManageFields";
import { default as ReactSelect, components } from "react-select";
import { getValue } from "@mui/system";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { NetworkDatabaseAction } from "../../../store/actions/dashboardActions";
import { AccountReducerAction, addUserAction, clearFetchSingleRecordAction, FetchSingleRecordAction, popUpUpdate, TierListReducerAction, updateAcccountReducerAction, UserListReducerAction } from "../../../store/actions/createTicketManageFields";
import { accountApi, addCrNumber, allTechnologyApi, crewCompanyApi, getAllDropDown, ixModule, listennoTicketApi, listSiteId, listtroubleTicketApi, listUserApi_two, managEngineer, OneRanTech, OneSiteDatabase, OneSiteDatabaseProjectCode, OneSiteDatabaseProjectId, pierTTGroupapi, projectCode, ranAddSupport, ranDashboard, ranlistSite, ranOneSiteDatabaseprojectId, ranOneSiteProjectCode, SevenTwoAlarm, singleProjectApi, siteDblistSiteId, troubleTicketApi, wildCardSevenTwo, SevenTwoAlarmFetchSingle } from "../../../common/config";
// import {   OneSiteDatabaseProjectId, projectCode, ranAddSupport, ranDashboard, ranlistSite, ranOneSiteDatabaseprojectId, ranOneSiteProjectCode, SevenTwoAlarm, SevenTwoAlarmFetchSingle, singleProjectApi, siteDblistSiteId, troubleTicketApi, wildCardSevenTwo } from "../../../common/config";
import { MultiSelect } from "react-multi-select-component";
import { Co2Sharp } from "@mui/icons-material";
import moment from "moment"
import { aging_fun, commonzone, moment_common_full_date_format, momnt_ageofdata, time_zonechanger } from "../../../common/variables";
import CommonModal from "../Custom/CommonModal";
import Add from "../../../icons/Svg/Add";
import TTHistoryCmts from "../Custom/Modal Body/TTHistoryCmts";

import DatePicker from "react-datepicker";
const NetworkTwo = () => {



  const userDetails = JSON.parse(localStorage.getItem("userDetails"))
  const useQuery = () => new URLSearchParams(useLocation().search);
  const history = useHistory()
  const query = useQuery();
  var path = window.location.pathname;

  // console.log(path.includes("ttdb"), "pathpathpath")
  const passedParams = useParams()

  // console.log(passedParams, "passedParams")


  let pageFromdata = "Trouble Ticket"
  let parentId = "Self"

  const dispatch = useDispatch();
  const Location = useLocation()

  // let ttGroup=["DA - Development - Adam Surak
  // Dallas MCPS War Room
  // DA - FIELD OPS
  // DA - Development - Daniel Bonilla
  // DA – RF Engineering
  // DA - Dev Ops  - (Do not use – please remove)
  // DA - CONSTRUCTION - (Do not use  – please remove)
  // DA - Development - Christopher Carrillo"]

  // let editableId = query.get('ids')
  // let type = query.get('type')
  let editableId, type, edit_index, creatorId, dataObj;
  // console.log(history.location, "history.location")
  // if(history.location){
  //   if(history.location.state){
  //     editableId = history.location.state.ids
  //     type = history.location.state.type
  //     edit_index = history.location.state.index
  //   }
  //   if(history.location.creator){
  //     parentId = history.location.creator.creatorId
  //     pageFromdata=history.location.creator.createFrom
  //     dataObj={
  //       "siteUniqueId":history.location.creator.siteUniqueId,
  //       "ranId":history.location.creator.ranId,
  //       "projectUniqueId":history.location.creator.projectUniqueId,

  //     }
  //   }
  // }


  // console.log(history, dataObj, "history")

  const customStyles = {
    control: (base, state) => ({
      ...base,
      // background: "#24292D",
      minHeight: "2px",
      // match with the menu
      borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
      // Overwrittes the different states of border
      // borderColor: state.isFocused ? "yellow" : "green",
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        // Overwrittes the different states of border
        // borderColor: state.isFocused ? "red" : "blue"
      }
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      background: isFocused
        ? ''
        : isSelected
          ? ''
          : undefined,
      zIndex: 1
    }),


    menu: base => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0
    }),
    menuList: styles => ({
      ...styles,
    })
  };
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({});
  let singleSite = {};
  let siteArray = [];
  let siteArrayAll = [];
  let ttcategory = [];
  let tttypes=[];

  let tttypesArray=[]
  let ttcategoryArraynew=[]
  
  let engineerArray = [];
  let oneTTData = [];
  let crewCompanyArray = [];
  let vendorDataArray = [];

  let multipleTechno = []
  let array_main = [];
  let listalldata = [];
  let technologyArray = []
  let projectCodeArray = []
  let projectIdArray = []
  let projectIdOption = []
  let crewOption = []
  let newTtempArray = []

  const [show, setShow] = useState(false);
  const [cate, setCate] = useState([]);

  const [customData, setcustomData] = useState({
    "tt_desc": "",
    "tt_summary": "",
    "actionstt": ""
  });
  // // console.log(Location.state.formDataFull,"Location.state")


  const [commonModalState, setcommonModalState] = useState(false);
  const [commonModalSize, setcommonModalSize] = useState(false);
  const [commonModalBody, setcommonModalBody] = useState("");
  const [commonModalTitle, setcommonModalTitle] = useState("");
  const [commonModalHeader, setcommonModalHeader] = useState("");
  const [submitOnceClick, setSubmitOnceClick] = useState(false);
  const [npcode, setpcode] = useState("");
  const [npidd, setpidd] = useState("");
  const [sidd, setsidd] = useState("");
  const [estdd, setestdd] = useState("");
  const [ttOpenDate, setttOpenDate] = useState("");

  const [ttdataidd, setttdataidd] = useState("");
  const [ttaffdataidd, setttaffdataidd] = useState("");



  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [umtsArray, setUmt] = useState([]);
  const [uploadedattachImage, setuploadedattachImage] = useState([]);
  const [uploadedattachFile, setuploadedattachFile] = useState([]);


  const [cxArray, setcx] = useState([]);
  const [ixArray, setix] = useState([]);
  const [EngineerOpen, setEngineerOpen] = useState(userDetails.name);

  const [lteArray, setLte] = useState([]);
  let [lteCount, setLteCount] = useState(1);
  let [count, setCount] = useState(1);
  let [mopReason, setMopState] = useState(false)
  const [Account, setAccount] = useState("");
  const [AccountId, setAccountId] = useState("");
  const [Market, setMarket] = useState("");

  const [closer, setCloser] = useState(false);
  const [Ncloser, setNCloser] = useState(false);
  let awaitedSiteArray = []
  let projectCodeOption = []
  // dispatch(fetechSingleProject(singleProjectApi,id))
  let [plannedSow, setPlannedSow] = useState([]);
  let [techaff, settechaff] = useState([]);
  let [Loadonsinglse, setLoadonsinglse] = useState(true);

  let [ttdata, setttdata] = useState([]);
  let [renderOne, setrenderOne] = useState(true);
  let [siteIdVal, setsiteIdVal] = useState("");

  let [RenderDrOne, setRenderDrOne] = useState(true);


  let [vendorArray, setvendorArray] = useState([]);
  let [CompanyForm, setCompanyForm] = useState([]);
  let [VendorForm, setVendorForm] = useState([]);
  let [sstartTime, setstartTime] = useState();



  let [oneprid, setoneprid] = useState('')
  let [thePId, setPId] = useState('')
  let [ttStatus, setttStatus] = useState(true)

  let [ond, setond] = useState(true)
  let [ocnd, setocnd] = useState(true)
  let [opind, setopind] = useState(true)
  let [type_cat, settype_cat] = useState("new")
  let [onenew, setonenew] = useState(true)

  const [countRender, setCountRender] = useState(1)
  let [ppProjectCode, setppProjectCode] = useState('')
  let [ppProjectId, setppProjctId] = useState('')
  let [ppOneSite, setppOneSite] = useState('')
  const [checkboxOpen, setCheckboxOpen] = useState("")


  let [selectProjectCode, setProjectCode] = useState([])

  let [SelselectProjectCodename, setSelProjectCodename] = useState()
  let [SelProjectCode, setSelProjectCode] = useState()
  let [selectProjectId, setProjectId] = useState([])
  let customError = {
    "projectCode": "Please Select Project Code",
    "projectId": "Please Select Site Project Id",
    "technology": "Please Select Technology",
    "technologyAffected": "Please Select Technology Affected",
    "tTType": "Please Select TT Type",
    "psetor": "Please Select Estimated Time of Recover",
    "uploadFile": "Please Upload File",
    "uploadImage": "Please Upload Image "
  }
  let [techie, setTech] = useState([]);
  let [Obj, setObj] = useState({});
  let [selectedTech, setSelectedTech] = useState()
  let [Siteone, setSiteone] = useState(true)

  let [ctrCount, setCtrCount] = useState(1);
  let [actualSiteId, setSiteId] = useState()

  const topage = (page) => {
    if (page == "back") {
      if (path.includes("ttdb")) {
        dispatch(AccountReducerAction(listtroubleTicketApi));
        history.push("/PAG/troubleTickets/dashboard")
      }
      if (path.includes("e911")) {
        dispatch(AccountReducerAction(listennoTicketApi));
        history.push("/PAG/E911/Tracker")
      }
      if (path.includes("post")) {
        history.push("/PAG/ran/OntheFlyForm/ranDashboard/postCheck/" + path.split("/").pop())
      }
      if (path.includes("ixDashboard")) {
        history.push("/PAG/ix/dashboard/" + path.split("/").pop())
      }
      if (path.includes("ixSevenTwo") || path.includes("ixSevenTwoWildCard")) {
        history.push("/PAG/72alarm/dashboard")
      }
      if (path.includes("ixSevenTwo") || path.includes("SevenTwoWildCard")) {
        history.push("/PAG/72alarm/dashboard")
      }
      if (path.includes("pre")) {
        history.push("/PAG/ran/OntheFlyForm/ranDashboard/preCheck/" + path.split("/").pop(), history?.location?.state)
        // history.push("/PAG/ran/OntheFlyForm/ranDashboard/preCheck/" + path.split("/").pop())
      }
      if (path.includes("ttedit")) {
        // dispatch(AccountReducerAction(listtroubleTicketApi));
        history.push("/PAG/troubleTickets/tracker")
      }
      // setrenderOne(true)
      // history.goBack()
    }
    // if (page == "back") {


    //   // setrenderOne(true)
    //   // history.goBack()
    // }
  }


  // console.log(cate,"catecalltoaddTTcat")


  let techAffectedArray = [
    {
      value: "GSM",
      label: "GSM",
    },
    {
      value: "UTMS",
      label: "UTMS",
    },
    {
      value: "LTE",
      label: "LTE",
    },
    {
      value: "5G",
      label: "5G",
    },
  ];

  let tttypeArray = [
    {
      value: "CX",
      label: "CX",
    },
    {
      value: "IX",
      label: "IX",
    },
    {
      value: "E911-CallTest",
      label: "E911-Call Test",
    },
    {
      value: "48 Hr. Alarm",
      label: "48 Hr. Alarm",
    },
    {
      value: "Post IX RF Support",
      label: "Post IX RF Support",
    },
    {
      value: "Customer Request",
      label: "Customer Request",
    },
    {
      value: "PAG Internal",
      label: "PAG Internal",
    },
    {
      value: "Troubleshooting",
      label: "Troubleshooting",
    },
    {
      value: "TMO FOPS",
      label: "TMO FOPS",
    },
    {
      value: "TMO RF",
      label: "TMO RF",
    },
    {
      value: "RTWP",
      label: "RTWP",
    },
  ];
  let plannedSowArray = [
    {
      value: "E911-CallTest",
      label: "E911-Call Test",
    },
    {
      value: "CX",
      label: "CX",
    },
    {
      value: "IX",
      label: "IX",
    },
    {
      value: "troubleShooting",
      label: "Troubleshooting",
    },
  ];


  let accountArry = []

  let pdsingleSite = [];

  let [oneTimeAccount, setoneTimeAccount] = useState(true)

  const techAffectedChangleHandler = (e) => {
    // console.log("Pow multiple select=", e);
    settechaff(e);
  };
  const plannedSowChangleHandler = (e) => {
    // console.log("Pow multiple select=", e);
    setPlannedSow(e);
  };
  const ttChangleHandler = (e) => {
    // console.log("Pow multiple select=", e);
    setttdata(e);
  };



  const techChangleHandler = (e) => {
    // console.log("Pow multiple select=", e);
    setPlannedSow(e);
  };

  const fetechSingleProjectBySiteId = (siteId) => {

  }

  const fetechSingleProject = (id) => {
    // finndName=>finndName.name==id
    // console.log("single fetechSingleProject pro ", id, siteArrayAll, siteArrayAll.findIndex(one => one.siteId == id));


    // setsiteIdVal(siteArrayAllId)
    // siteArray.findIndex(one=>one.siteId==id)
    // // console.log("the actual site id=",siteId)
    // setValue("siteUniqueId", id)

    let siteind = siteArrayAll.findIndex(one => one.siteId == id)
    if (siteind != -1) {
      // let siteArrayAllId=siteArrayAll[siteind].siteUniqueId
      let siteArrayAllId = siteArrayAll[siteind].uniqueSiteId

      setppOneSite(siteArrayAllId)
      setProjectCode([])
      setProjectId([])
      setPlannedSow([])

      setValue("siteUniqueId", id)


      if (path.includes("ttdb")) {
        dispatch(clearFetchSingleRecordAction())
      }
      dispatch(FetchSingleRecordAction(OneSiteDatabaseProjectCode, siteArrayAllId))
    }
    // setCtrCount(1)


  };

  const changeCompany = (id) => {

    // console.log(id, "changeCompany")
    // // console.log("single pro target.value", crewOption,crewOption.filter((item) => item.crewCompanyName == id)[0].vendor);
    setCompanyForm(id)
    let vendorList = crewOption.filter((item) => item.crewCompanyName == id)[0].vendor
    if (vendorList) {
      // console.log(vendorList.values, "vendorList")
      setvendorArray(vendorList.values)
    } else {
      setvendorArray([])
    }
    // console.log("the actual site id=", vendorArray)
    setValue("company", id)
    // setCtrCount(1)

  };

  const FetechAllDetailSingle = (id) => {
    // console.log("FetechAllDetailSingle")
    // dispatch(FetchSingleRecordAction(OneRanTech, id));
  }


  const FetechAllDetail = (id) => {
    setProjectId(id)
    let test = projectIdArray.filter((item) => item.uniqueid == id)[0]
    // console.log(test, "test")

    let tprjid = []
    let newuniqueProjetId = []
    let selectedId = id.map((item) => {
      tprjid.push(item)
      return item.value
    })

    // console.log(newuniqueProjetId, "newuniqueProjetIdnewuniqueProjetId")
    // setPId(test.projectId)
    // if (test) {
    //   setond(false)
    //   if (test.length > 0) {
    //     setppProjctId(test.projectId)

    //   }
    // }
    // dispatch(FetchSingleRecordAction(singleProjectApi, id));
    // setppProjctId(test.projectId)

    setValue("projectUniqueId", selectedId.join(","))
    dispatch(FetchSingleRecordAction(OneSiteDatabase, selectedId.join(",")));
    setTecheer(tprjid)
    // dispatch(FetchSingleRecordAction(ranDashboard, id))
    // // setCtrCount(1)
    // // console.log("FetechAllDetail", id)
  }

  const setTecheer = (selectedname) => {
    let alltt = []
    // console.log("setTecheer", selectedname)
    let sellid = []


    selectedname.map((ids) => {
      // console.log("the ids -=", ids)
      sellid.push(ids.value)
    })

    let arr = []
    // console.log(plannedSow, "techitemitemitemitem")

    plannedSow.map((item) => {

      // console.log(item, sellid, "itemitemitemitem")

      if (sellid.indexOf(item.value.split("_CP_")[1]) != -1) {
        arr.push({
          value: item.value,
          label: item.value.split("_CP_")[0],
        });
      }

    })

    // console.log(arr, "techarr")
    setPlannedSow(arr)

    techaff.map((item) => {

      // console.log(item, sellid, "itemitemitemitem")

      if (sellid.indexOf(item.value.split("_CP_")[1]) != -1) {
        arr.push({
          value: item.value,
          label: item.value.split("_CP_")[0],
        });
      }

    })

    // console.log(arr, "techarr")
    settechaff(arr)
  }

  // const setTecheer = (selectedname) => {
  //   let alltt = []
  //   // console.log("setTecheer", selectedname)
  //   let sellid = []


  //   selectedname.map((ids) => {
  //     console.log("the ids -=", ids)
  //     sellid.push(ids.mainId)
  //   })

  //   let arr = []
  //   console.log(plannedSow, sellid, "techitemitemitemitem")

  //   plannedSow.map((item) => {

  //     console.log(sellid, "sellid", item.value.split("_CP_")[1], "itemitemitemitem")

  //     if (sellid.indexOf(item.value.split("_CP_")[1]) != -1) {
  //       arr.push({
  //         value: item.value,
  //         label: item.value.split("_CP_")[0],
  //       });
  //     }

  //   })

  //   console.log(arr, "techarr")
  //   setTech(arr)
  // }

  const findDProjectId = (id, def = 0, pcode) => {

    setCountRender(1)
    // console.log("projectCodeArray", id, projectCodeArray)
    setppProjectCode(id)

    setppProjctId('')
    let selectedIds = []
    let selectedname = []
    let nselectedIds = ""

    if (def == 0) {
      setProjectCode(id)
      selectedIds = id.map((item) => {
        // console.log(item, "itemprojectCodeArray")
        selectedname.push(item.label)
        return item.value
      })
      nselectedIds = selectedIds.join(",")
    } else {
      let testpcode = []
      pcode.map((onetm) => {
        selectedname.push(onetm)
      })
      nselectedIds = id
    }


    // console.log(OneSiteDatabase + id, id, projectCodeArray, selectProjectId, selectedname, "project899CodeArray")
    setSelProjectCodename(selectedname)
    setSelProjectCode(nselectedIds.split(","))

    let newProjectIdSel = []
    let newIdProjectIdSel = []
    if (def == 0) {
      selectProjectId.map((item) => {
        // console.log(item, "itemitemite--00m")
        if (selectedname.indexOf(item.group) != -1) {
          newProjectIdSel.push(item)
          newIdProjectIdSel.push(item.value)
        }
      })
      setProjectId(newProjectIdSel)
      // console.log(plannedSow, newProjectIdSel, "techkatechtechkatech")
      setTecheer(newProjectIdSel)
      // findDProjectId(newProjectIdSel)
    }

    FetechAllDetail(newProjectIdSel)
    dispatch(FetchSingleRecordAction(OneSiteDatabaseProjectId, selectedIds))

    setValue("projectCodeId", selectedIds)
  }

  const CommonModalFun = (body, title, header, type = "md") => {

    dispatch(popUpUpdate({}));
    setNCloser(true)
    setcommonModalState(true);
    setcommonModalSize(type);
    setcommonModalTitle(title);
    setcommonModalBody(body);
    setcommonModalHeader(header);
  };


  const techhandleChange = (e) => {
    // console.log("multiselect=", techie);
    // setTechnologyError("");
    setSelectedTech(e);
  };

  const techAffectedChange = (e) => {
    // console.log("multiselect=", techie);
    // setTechnologyError("");
    setSelectedTech(e);
  };

  const projectData = useSelector((state) => {
    // console.log("the State is=", state);
    let allProjects = state.CommonResponse.startAwaitedSite
    // // console.log("all projects is =",state.popUpState.popstatus.status,closer)
    pdsingleSite = []
    // pdsingleSite = state.OneCommonResponse.pdsingleSite; /// for project
    pdsingleSite = state.csvResponse.singleSite;

    if (state.popUpState) {

      if ((state.popUpState.popstatus.status == 200 && Ncloser)) {
        setcommonModalState(false);
        // console.log(state.popUpState.popstatus, "state.popUpState.popstatus")
        customData[state.popUpState.popstatus.data[0]] = state.popUpState.popstatus.data[1]

        setNCloser(false)
      }

      if ((state.popUpState.popstatus.status == 201 && closer) || (state.popUpState.popstatus.status == 200 && closer)) {
        dispatch(popUpUpdate({}));
        // setrenderOne(true)
        if (path.includes("ttdb")) {
          dispatch(AccountReducerAction(listtroubleTicketApi));
          history.push("/PAG/troubleTickets/dashboard")
        }
        if (path.includes("e911")) {
          dispatch(AccountReducerAction(listennoTicketApi));
          history.push("/PAG/ran/OntheFlyForm/preCheck")
        }
        if (path.includes("post")) {
          history.push("/PAG/ran/OntheFlyForm/ranDashboard/postCheck/" + path.split("/").pop())
        }
        if (path.includes("ixDashboard")) {
          history.push("/PAG/ix/dashboard/" + path.split("/").pop())
        }
        if (path.includes("ixSevenTwo") || path.includes("ixSevenTwoWildCard")) {
          history.push("/PAG/72alarm/dashboard")
        }
        if (path.includes("ixSevenTwo") || path.includes("SevenTwoWildCard")) {
          history.push("/PAG/72alarm/dashboard")
        }
        if (path.includes("pre")) {
          history.push("/PAG/ran/OntheFlyForm/ranDashboard/preCheck/" + path.split("/").pop())
        }
        if (path.includes("ttedit")) {
          // dispatch(AccountReducerAction(listtroubleTicketApi));
          history.push("/PAG/troubleTickets/tracker")
        }
        // history.goBack()
        setCloser(false)
      }
    }

    let account = state.CommonResponse.accounts;
    if (account.length > 0 && accountArry.length == 0) {
      accountArry = account.map((item, index) => {
        if (oneTimeAccount) {
          setoneTimeAccount(false)
          // setValue("account", item.uniqueid)
          // return <option selected value={item.uniqueid}>{item.accountName}</option>
        }
        return <option style={{ background: "white" }} value={item.uniqueid}>{item.accountName}</option>;
      });
    }




    if (allProjects && allProjects.length != 0) {
      // if (path.includes("ttdb")) {

      siteArrayAll = allProjects

      siteArray = allProjects.map((project) => {
        // console.log("the project is =========", project)
        if (ppOneSite == project.siteUniqueId) {
          return <option>{project.siteId}</option>;
          // return <option selected value={project.siteUniqueId}>{project.siteId}</option>;
        } else {
          return <option>{project.siteId}</option>;
        }

      });
      // }

      singleSite = path.includes("ixDashboard") || path.includes("SevenTwoWildCard") ? state.OneCommonResponse.singleIXSite : state.OneCommonResponse.singleSite;
      // console.log("cbwbduefgyyugvfe", singleSite)

      // // console.log(ctrCount,singleSite,"ctrCountctrCountctrCount")
      if (ctrCount == 1 && singleSite && singleSite.dtSiteId) {

        // console.log(ctrCount, singleSite, "ctrCountctrCountctrCount")
        // setTech(singleSite.technology)
        setppOneSite(singleSite.dtSiteId)
        fetechSingleProject(singleSite.dtSiteId)
        // setObj({
        //   "market": "",
        //   "market": ""
        // })

        let dbpcode = []
        let dbpid = []

        let pcodesonly = []
        let pidonly = []


        singleSite.projectbUniqueId.map((onwtm) => {
          dbpcode.push({
            label: onwtm.projectCode,
            value: onwtm.pId
          })
          pcodesonly.push(onwtm.projectCode)
          pidonly.push(onwtm.projectId)
          dbpid.push({
            label: onwtm.projectId,
            value: onwtm.pId
          })

        })
        setsidd(singleSite.ndResult.siteId)
        console.warn("cjnkldabc", singleSite.ndResult.siteId)
        if (singleSite.techtake) {
          setttdataidd(singleSite.techtake.join(", "))
          setttaffdataidd(singleSite.techtake.join(", "))

        }
        else if (singleSite.preHWLayerConfiguration) {
          setttdataidd(singleSite.preHWLayerConfiguration.values.join(","))
          setttaffdataidd(singleSite.preHWLayerConfiguration.values.join(","))

        }
        setAccount(singleSite.ndResult.account)
        let accindex = account.findIndex(one => one.accountName == singleSite.ndResult.account)
        console.warn(account, accindex, "accountaccountaccount")
        if (accindex >= 0) {
          setAccountId(account[accindex].uniqueid)
        }
        setpidd(pidonly.join(", "))
        setpcode(pcodesonly.join(", "))
        findDProjectId(dbpcode)
        FetechAllDetail(dbpid)
        setValue("siteUniqueId", singleSite.dtSiteId)
        // console.log("the response is the =", state.OneCommonResponse.singleSite)
        setCtrCount(2)
      }
    }
    // let technology = state.CommonResponse.technology;
    // if (technology != 0) {

    //   technologyArray = technology.map((item) => {
    //     return {
    //       value: item.uniqueid,
    //       label: item.technologyName,
    //     };
    //   });

    // }

    // console.log(state, "statestatestatestatestate")
    let rsingleSite = state.OneCommonResponse.singleRanTechSite;

    let onsinglse = state.OneCommonResponse.singleSite



    multipleTechno = []


    let technology = state.CommonResponse.technology;
    if (technology.length > 0 && technologyArray.length == 0) {
      if (selectProjectId.length > 0) {
        // console.log("comeToStart")
        // setmultiTechnology([])
        // console.log(technology, "technology")
        technologyArray = selectProjectId.map((ids) => {
          // console.log("the ids -=", ids)
          let pId = {
            label: ids.label,
            value: ids.label,
            disabled: true
          }
          multipleTechno.push(pId)

          let tmpArry = []
          tmpArry = technology.map((item) => {
            return {
              value: item.technologyName + "_CP_" + ids.value,
              group: ids.label,
              label: item.technologyName,
            };
          });


          multipleTechno.push(...tmpArry)
          // setmultiTechnology(multiTechnologylcl)
        })
        // console.log(multipleTechno, "multiTechnologymultiTechnology")

        // settechsetter(false)
      }
      // }
    }






    // if (rsingleSite && rsingleSite.technology) {


    //   let sitelabel = {
    //     label: rsingleSite.projectId,
    //     value: rsingleSite.projectId,
    //     disabled: true
    //   }
    //   multipleTechno.push(sitelabel)
    //   let defTech = []
    //   defTech = rsingleSite.technology.map((defaultTech) => {
    //     return {
    //       value: defaultTech + "_CP_" + rsingleSite.newuniqueId,
    //       label: defaultTech,
    //     };
    //   })
    //   multipleTechno.push(...defTech)
    // }


    // else 



    if (onsinglse && onsinglse.projectaUniqueId && onsinglse.length != 0) {
      // multipleTechno = []
      if (Loadonsinglse) {
        setLoadonsinglse(false)
        setValue("account", onsinglse.ndResult.account)
        setValue("market", onsinglse.ndResult.market)
        // console.log(onsinglse, onsinglse.projectbUniqueId, "onsinglse")
        onsinglse.projectbUniqueId.map((itm) => {
          // console.log(itm, "itmitmitmitm")
          // let sitelabel = {
          //   label: itm.projectId,
          //   value: itm.projectId,
          //   disabled: true
          // }
          // plannedSow.push(sitelabel)
          let defTech = []
          onsinglse.technology.values.map((defaultTech) => {
            if (defaultTech.split("_CP_")[1] == itm.pId) {
              defTech.push({
                value: defaultTech,
                label: defaultTech.split("_CP_")[0]
              })
            }
          })
          plannedSow.push(...defTech)

          techaff.push(...defTech)


        })
      }



      // technologyArray = onsinglse.projectaUniqueId.map((sites,index) => {
      //   let sitelabel = {
      //     label: sites.projectId,
      //     value: sites.projectId,
      //     disabled: true
      //   }
      //   multipleTechno.push(sitelabel)
      //   let defTech = []
      //   defTech = sites.technology.map((defaultTech) => {
      //     return {
      //       value: defaultTech + "_CP_" + sites.pId,
      //       label: defaultTech
      //     };
      //   })
      //   multipleTechno.push(...defTech)
      // })
    }

    if (path.includes("ttdb")) {
      if (rsingleSite.length >= 1) {
        // setAccount(rsingleSite[0].account)
        // setMarket(rsingleSite[0].market)


        rsingleSite.map((oneVal) => {
          // console.log(oneVal, "oneValoneValoneVal")
        })

        setValue("account", rsingleSite[0].account)
        setValue("market", rsingleSite[0].market)
        // console.log(rsingleSite, "singleSitesingleSitesingleSite")
        // setTech(singleSite.technology)
        technologyArray = rsingleSite.map((sites) => {
          let sitelabel = {
            label: sites.projectId,
            value: sites.projectId,
            disabled: true
          }
          multipleTechno.push(sitelabel)
          let defTech = []
          defTech = sites.technology.map((defaultTech) => {
            return {
              value: defaultTech + "_CP_" + sites.newuniqueId,
              label: defaultTech,
              group: sites.projectId,

            };
          })
          multipleTechno.push(...defTech)
        })
        // technologyArray = singleSite.technology.values.map((item) => {
        //   return {
        //     value: item,
        //     label: item,
        //   };
        // });
        // console.log("the response singleSite is the =", multipleTechno, state.OneCommonResponse.singleSite)
        // setCtrCount(2)
      }
    }





    projectIdArray = state.OneCommonResponse.productId
    if (projectIdArray && projectIdArray.length > 0) {
      projectIdOption = projectIdArray.map((item) => {
        // console.log(ppProjectId, item.uniqueid, "ppidd")
        if (ppProjectId == item.uniqueid) {
          if (opind) {
            FetechAllDetail(item.uniqueid)
            setopind(false)
          }
          let availableId = []
          let anyArry = item.data
          availableId = item.data.map((id) => {

            // console.log(id, "ididididididid")
            return {
              label: id.projectId,
              group: item.projectCode,
              value: id.uniqueid
            }
          })

          newTtempArray.push({
            label: item.projectCode,
            value: item.projectCode,
            disabled: true,
          })
          newTtempArray.push(...availableId)
          return (item)
          // return <option selected value={item.uniqueid}>{item.projectId}</option>
        } else {
          let availableId = []
          let anyArry = item.data
          if (anyArry) {
            availableId = anyArry.map((id) => {
              // console.log(id, "ididididididid")
              return {
                label: id.projectId,
                group: item.projectCode,
                value: id.uniqueid
              }
            })
          }


          newTtempArray.push({
            label: item.projectCode,
            value: item.projectCode,
            disabled: true,
          })
          newTtempArray.push(...availableId)
          return (item)


          // return <option value={item.uniqueid}>{item.projectId}</option>
        }
      })
    }




    let allAwaitedSite = state.CommonResponse.startAwaitedSite
    // if(allAwaitedSite && allAwaitedSite.length>0){
    // awaitedSiteArray
    // }
    let projectCodeArray = state.OneCommonResponse.productCode
    if (projectCodeArray && projectCodeArray.length > 0) {
      projectCodeOption = projectCodeArray.map((item) => {
        // console.log(oneprid == item.uniqueid, oneprid, item.uniqueid, "oneprid,item.uniqueid")
        if (oneprid == item.uniqueid) {
          return {
            value: item.uniqueid,
            label: item.projectCode,
          };
          // return <option selected value={item.uniqueid}>{item.projectCode}</option>
        } else {
          return {
            value: item.uniqueid,
            label: item.projectCode,
          }
          // return <option value={item.uniqueid}>{item.projectCode}</option>
        }
      })

      // setValue("projectUniqueId",oneprid,{shouldValidate:true,})
    }
    // console.log("state.CommonResponse.technology=", state)
    // console.log("siteArray =", technologyArray);

    ttcategoryArraynew = state.CommonResponse.singleADropDown
    
    let ttcategoryArray = ["CX", "IX", "E911 Call Test - Speed Test", "RF", "Alarms", "RSSI/RTWP", "VSWR", "Hardware", "Software", "License", "RET"]
    
    if (ttcategoryArray && ttcategoryArray.length > 0) {
      ttcategory = ttcategoryArray.map((item) => {
        return <option value={item}>{item}</option>
      })
    }

    
    tttypesArray = state.CommonResponse.singleDropDown
    
    if (tttypesArray && tttypesArray.length > 0) {
      tttypes = tttypesArray.map((item) => {
        return <option value={item.optionValue}>{item.optionValue}</option>
      })
    }

    


    let tier = state.CommonResponse.listtier
    if (tier && tier.length != 0) {
      engineerArray = tier.map((item) => {
        return <option>{item.name}</option>;
      })
    }

    console.log(dataObj, "dataObj")
    if (dataObj && RenderDrOne) {
      setocnd(true)
      setopind(true)
      setRenderDrOne(false)
      // // // console.log("the project is =========", dataObj.siteUniqueId)
      // // console.log(dataObj.siteUniqueId, "ctrCountctrCountctrCount")
      setValue("siteUniqueId", dataObj.siteUniqueId)
      setValue("ranprojectId", dataObj.ranId)
      setValue("projectUniqueId", dataObj.projectUniqueId)
      fetechSingleProject(dataObj.siteUniqueId)
      // findDProjectId(dataObj.projectUniqueId)

      setppProjctId(dataObj.ranId)
    }
    console.log(errors, "errors")
    if (path.includes("ttedit")) {
      // // // console.log(oneTTData, oneTTData.length != 0, oneTTData.ttid, renderOne, oneTTData && oneTTData.length != 0 && oneTTData.ttid && renderOne, "state.CommonResponse.tt_data")
      oneTTData = state.CommonResponse.tt_data[0]

      console.log(oneTTData,"oneTTDataoneTTData")
      if (oneTTData && oneTTData.length != 0 && oneTTData.ttid && renderOne) {
        // // console.log(oneTTData, "oneTTData")

       
        setrenderOne(false)
        setValue("impact", oneTTData.impact)
        setValue("tmoPierTtid", oneTTData.tmoPierTtid)
        setValue("groupAssignedTTPier", oneTTData.groupAssignedTTPierId, { shouldTouch: true })
        setCompanyForm(oneTTData.company)
        setVendorForm(oneTTData.vendor)
        setocnd(true)
        setopind(true)
        setValue("vendor", oneTTData.vendor)
        // setValue("tt_desc", oneTTData.tt_desc)
        // setValue("tt_summary", oneTTData.tt_summary)
        // setValue("actionstt", oneTTData.actionstt)

        // setcustomData({...customData,tt_desc:oneTTData.tt_desc})
        // setcustomData({...customData,tt_summary:oneTTData.tt_summary})
        customData["tt_desc"] = oneTTData.tt_desc
        customData["tt_summary"] = oneTTData.tt_summary
        customData["actionstt"] = oneTTData.actionstt
        // // console.log(customData, "customDatacustomData")
        setValue("day", oneTTData.day)
        setValue("hh", oneTTData.hh)
        setValue("mm", oneTTData.mm)
        setValue("mm", oneTTData.mm)
        setValue("account", oneTTData.account)
        setAccount(oneTTData.account)
        setMarket(oneTTData.market)
        setValue("market", oneTTData.market)
        setValue("siteUniqueId", oneTTData.siteUniqueId)
        setsidd(oneTTData.siteId)
        setestdd(oneTTData.estdTime)
        setttOpenDate(oneTTData.ttOpenDate)

        let techArray = oneTTData["technology"].values;
        let tec = ""
        if (techArray && techArray.length > 0) {
          // // console.log("sgrthdhr", techArray.length);
          for (let i = 0; i < techArray.length; i++) {
            // // console.log("sgrthdhr", techArray[i]);
            tec += techArray[i].split("_CP_")[0] + ", ";
          }
          //  item['technology']=tec
        }

        setttdataidd(tec)


        let techafArray = oneTTData["techAffected"].values;
        let tecaf = ""
        if (techafArray && techafArray.length > 0) {
          // // console.log("sgrthdhr", techafArray.length);
          for (let i = 0; i < techafArray.length; i++) {
            // console.log("sgrthdhr", techafArray[i]);
            tecaf += techafArray[i].split("_CP_")[0] + ", ";
          }
          //  item['technology']=tec
        }

        setttaffdataidd(tecaf)

        // setValue("projectUniqueId", oneTTData.ndResult.projectUniqueId.$oid)


        setValue("engineerOpeningtt", oneTTData.engineerOpeningtt)
        setpcode(oneTTData.projectCode.join(", "))
        let newpii = [];
        let pcodd = oneTTData.projectDataa.map((e) => {
          newpii.push(e.newId)
          return e.projectId
        })
        setpidd(pcodd.join(", "))
        setValue("projectCodeId", newpii.join(","))
        setValue("projectUniqueId", newpii.join(","))
        setValue("ttStatus", oneTTData.ttStatus)

        setttStatus(oneTTData.ttStatus)
        if (oneTTData.ttStatus == "Close" || oneTTData.ttStatus == "Terminated") {
          setValue("engineerClosingtt", oneTTData.engineerClosingtt)
        }


        setstartTime(new Date(oneTTData.setestdd + ":00"))


        // console.log(oneTTData.projectCode.join(", "), "ctrCountctrCountctrCount")
        // setValue("nprojectCode", )
        // setValue("nprojectId", )

        setEngineerOpen(oneTTData.engineerOpeningtt)
        // // console.log(oneTTData.ndResult.projectUniqueId,"oneTTData.projectUniqueId")

        // fetechSingleProject(oneTTData.siteUniqueId)
        // findDProjectId(oneTTData.ndResult.projectUniqueId.$oid)
        // setppProjctId(oneTTData.ranprojectId)
        let resplannedSow = []


        // console.log(oneTTData, "oneTTDataoneTTDataoneTTData")
        resplannedSow = oneTTData?.technology?.values?.map((item) => {
          return {
            value: item,
            label: item.split("_CP_")[0]
          }
        })

        let restechaff = []
        restechaff = oneTTData?.techAffected?.values?.map((item) => {
          return {
            value: item,
            label: item
          }
        })

        console.log(oneTTData.ttdata,typeof(oneTTData.ttdata),"oneTTData.ttdata")
        if(typeof(oneTTData.ttdata)=="object"){
          
          let resttdata = []

        // console.log(oneTTData.ttdata, "oneTTData.ttdata")


          if (oneTTData.ttdata.values) {
            resttdata = oneTTData?.ttdata?.values?.map((item) => {
              return {
                value: item,
                label: item
              }
            })
          } else {
            resttdata = oneTTData?.ttdata.split(",").map((item) => {
              return {
                value: item,
                label: item
              }
            })
          }
          setttdata(resttdata)
          
          settype_cat("old")
          
          setValue("ttCategory", oneTTData.ttCategory,{shouldValidate:true,shouldTouch:true})
        }

        
        if(typeof(oneTTData.ttdata)=="string"){
          setValue("ttType",oneTTData.ttdata,{shouldValidate:true,shouldTouch:true})

          calltoaddTTcat(oneTTData.ttdata,oneTTData.ttCategory)
          // setValue("ttCategory", oneTTData.ttCategory,{shouldValidate:true,shouldTouch:true})
          
          settype_cat("new")
          
        }

        

        setPlannedSow(resplannedSow)
        settechaff(restechaff)



        // console.log(plannedSow, ttdata, techaff, "plannedSow", newpii.join(","))
        setonenew(newpii.join(","))
        // if(onenew){
        //   FetechAllDetailSingle(onenew)
        // }
      }
    }



    crewOption = state.CommonResponse.crewCompany
    if (crewOption && crewOption.length != 0) {
      crewCompanyArray = crewOption.map((item) => {
        if (CompanyForm == item.crewCompanyName) {
          if (ocnd) {
            changeCompany(CompanyForm)
            setocnd(false)
          }
          return <option selected>{item.crewCompanyName}</option>;
        } else {
          return <option>{item.crewCompanyName}</option>;
        }
      })
    }

    let pierTTGrouplist = state.CommonResponse.pierTTGroup
    let pierTTArray = []
    if (pierTTGrouplist && pierTTGrouplist.length != 0) {
      pierTTArray = pierTTGrouplist.map((item) => {
        return <option value={item.uniqueid}>{item.groupName}</option>;
      })
    }



    // console.log(vendorArray, "vendorArray")
    vendorDataArray = vendorArray.map((item) => {

      if (item == VendorForm) {
        return <option selected>{item}</option>
      } else {
        return <option>{item}</option>
      }
    })




    let tttypeArray = []
    let singleDropDown = state.CommonResponse.singleDropDown

    singleDropDown.map((onesingle) => {
      // console.log(onesingle)
      tttypeArray.push({
        value: onesingle.optionValue,
        label: onesingle.optionValue
      })
    })




    // console.log(vendorDataArray, "vendorDataArray")
    // let tier = state.CommonResponse.listtier
    // if (tier && tier.length != 0) {
    //   engineerArray = tier.map((item) => {
    //     return <option>{item.name}</option>;
    //   })
    // }


    return { siteArray, accountArry, pdsingleSite, singleSite, technologyArray, projectCodeOption, engineerArray, oneTTData, newTtempArray, projectIdOption, ttcategory, tttypes, crewCompanyArray, vendorDataArray, tier, tttypeArray, pierTTArray };
  });



  // console.log("projectDataprojectData", projectData)



  let Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const addUMTSFields = () => {
    setCount(count++);

    if (count <= 7) {
      setUmt([
        ...umtsArray,
        <div className="form-group col-md-4">
          <input
            style={{ height: "32px" }}
            type="text"
            className="form-control"
            placeholder={"UMTS Id " + count}
          />
        </div>,
      ]);
      setCount(count++);
    }
  };

  let techAffected = [
    {
      value: "GSM",
      label: "GSM"
    },
    {
      value: "UTMS",
      label: "UTMS"
    },
    {
      value: "LTE",
      label: "LTE"
    },
    {
      value: "5G",
      label: "5G"
    }
  ]
  const addLTEFields = () => {
    setLteCount(lteCount++);

    if (lteCount <= 9) {
      setLte([
        ...lteArray,
        <div className="form-group col-md-4">
          <label for="exampleFormControlSelect1">LTE ID {lteCount}</label>
          <input
            style={{ height: "32px" }}
            type="text"
            className="form-control"
            placeholder={"LTE Id " + lteCount}
          />
        </div>,
      ]);
      setLteCount(lteCount++);
    }
  };

  const onSubmit = async (data) => {
    console.log("data is ===", data);

    dispatch(popUpUpdate({}));
    // if (selectProjectCode.length == 0 || selectProjectId.length == 0 || plannedSow.length == 0 || ttdata.length == 0 || uploadedattachImage.length == 0 || uploadedattachFile.length == 0) {

    if(type_cat=="old"){
      if (path.includes("ttedit")) {
        if (ttdata.length == 0) {
          if (submitOnceClick) {
            errorlen = 1
            return false
          }
        }
      } else {
        if (selectProjectCode.length == 0 || selectProjectId.length == 0 || plannedSow.length == 0 || ttdata.length == 0) {
          if (submitOnceClick) {
            errorlen = 1
            return false
          }
        }
      }
    }else{
      console.log("error ineno",selectProjectCode.length,selectProjectId.length,plannedSow.length,)
      if (!path.includes("ttedit")) {
      
        console.log("error ineno")
        if (selectProjectCode.length == 0 || selectProjectId.length == 0 || plannedSow.length == 0) {
          if (submitOnceClick) {
            errorlen = 1
            return false
          }
        }
      }
    }
    
    let userId = JSON.parse(localStorage.getItem("userDetails"))
    userId = userId.uniqueid

    console.log("1472"+"error ineno"+"1472")
    // ttOpenDate
    // data['ttOpenDate'] = userId
    data['userId'] = userId

    data['assume'] = ''
    let sendTech = {
      values: []
    }
    sendTech.values = plannedSow.map((item) => {
      return item.value
    })
    data['technology'] = sendTech

    let sendtechaff = { values: [] }
    sendtechaff.values = techaff.map((item) => {
      return item.value
    })
    data['techAffected'] = sendtechaff

    


    // console.log(data, "troubleTicketApi")


    let newform = new FormData();
    if (path.includes("ixSevenTwo")) {
      newform.append("dashboardType", "ixSevenTwo");
    }

    
    newform.append("type_cat", type_cat);
    if(type_cat=="old"){
      let sendSow = { values: [] }
      sendSow.values = ttdata.map((item) => {
        return item.value
      })
      data['ttdata'] = sendSow
      newform.append("ttdata", data['ttdata']["values"]);
    }else{
      newform.append("ttdata", data['ttType']);
    }
    newform.append("userId", userId);
    if (uploadedattachFile.length > 0) {
      newform.append("uploadFile", uploadedattachFile);
    }
    if (uploadedattachImage.length > 0) {
      newform.append("uploadImage", uploadedattachImage);
    }
    if ("engineerClosingtt" in data) {
      newform.append("engineerClosingtt", data['engineerClosingtt']);
    }

    newform.append("pageFromdata", pageFromdata);
    newform.append("groupAssignedTTPier", data['groupAssignedTTPier']);
    if (passedParams.ranId) {
      newform.append("creatorId", creatorId);
      newform.append("ranprojectId", passedParams.ranId);
    }
    newform.append("ttCategory", data["ttCategory"]);
    newform.append("impact", data["impact"]);
    newform.append("tmoPierTtid", data["tmoPierTtid"]);
    newform.append("ttStatus", data["ttStatus"]);
    newform.append("engineerOpeningtt", data["engineerOpeningtt"]);
    // newform.append("siteUniqueId", data["siteUniqueId"]);




    newform.append("cabinetId", data["cabinetId"]);
    newform.append("company", data["company"]);
    newform.append("vendor", data["vendor"]);

    let estdTime = moment(sstartTime).format("MM/DD/YYYY") + " 18:00"
    if (path.includes("ttedit")) {
      let dataDate = momnt_ageofdata(ttOpenDate, estdTime, moment_common_full_date_format)
      let dateSplitter = dataDate.split(" ")
      newform.append("day", dateSplitter[0]);
      newform.append("hh", dateSplitter[2]);
      newform.append("mm", dateSplitter[4]);
      dispatch(updateAcccountReducerAction(troubleTicketApi, path.split("/").pop(), newform))
    } else {

      let TTOpenDate = moment(currentDate).format(moment_common_full_date_format)
      let dataDate = momnt_ageofdata(TTOpenDate, estdTime, moment_common_full_date_format)
      let dateSplitter = dataDate.split(" ")
      newform.append("day", dateSplitter[0]);
      newform.append("hh", dateSplitter[2]);
      newform.append("mm", dateSplitter[4]);
      newform.append("tt_desc", data["tt_desc"]);
      newform.append("tt_summary", data["tt_summary"]);
      newform.append("actionstt", data["actionstt"]);
      newform.append("technology", data['technology']["values"]);
      newform.append("techAffected", data['techAffected']["values"]);
      newform.append("ttOpenDate", TTOpenDate);
      newform.append("siteUniqueId", ppOneSite);
      newform.append("setestdd", estdTime);

      if (AccountId != "") {
        newform.append("account", AccountId);
      } else {
        newform.append("account", getValues("account"));
      }
      newform.append("market", projectData.pdsingleSite ? projectData.pdsingleSite.market ? projectData.pdsingleSite.market : projectData.pdsingleSite.length > 0 ? projectData.pdsingleSite[0].market : "" : "");
      newform.append("projectUniqueId", data['projectUniqueId']);
      newform.append("projectCodeId", data['projectCodeId']);
      dispatch(addUserAction(troubleTicketApi, newform))
    }

    setCloser(true)
    // history.push("/PAG/ran/support/dashboard")
  };


  const dateObject = new Date();
  // const currentDate = moment().format("MM/DD/YYYY");
  const currentDate = time_zonechanger(commonzone, "MM/DD/YYYY HH:mm")
  const onOptionChange = (e) => {
    let val = e.target.value;

    array_main = (
      <option selected disabled>
        Select
      </option>
    );

    if (listalldata) {
      if (val == "LeadName") {
        array_main = listalldata.map((item) => {
          return <option value={item.uniqueid}>{item.name}</option>;
        });
        // console.log(array_main, "array_main");
      } else if (val == "Email") {
        array_main = listalldata.map((item) => {
          return <option value={item.uniqueid}>{item.email}</option>;
        });
      } else if (val == "ContactNumber") {
        array_main = listalldata.map((item) => {
          return <option value={item.uniqueid}>{item.mobilenumber}</option>;
        });
      }
    }

    setcx(array_main);
    setix(array_main)
  };

  const findengineer = (id, typer) => {
    if (id == userDetails.name) {
      setEngineerOpen(id)
      setValue(typer, id)
    } else if (projectData.tier.findIndex(finndName => finndName.name == id) != -1) {
      setEngineerOpen(id)
      setValue(typer, id)
    } else {
      setValue(typer, "")
      setEngineerOpen("")
    }
    // // console.log(id,"id")
    // if (id != undefined) {

    //   let idObject = engineerArray.filter((item) => {
    //     item.name == id
    //   })
    //   // console.log(idObject,"idObject")
    //   // setValue("engineerOpeningtt",idObject)

    // }
  }

  let data = useSelector((state) => {
    // console.log(state, "statestatestate");

    // let list_user=state
    listalldata = state.CommonResponse.listuser;

    return { array_main };
  });

  const handleChange = (event, nane, arra) => {


    let ind = event.target.selectedIndex;
    // console.log("gdrgjeio;rghiuersgh,eriusoso", listalldata[ind].uniqueid);
    // setValue(nane+"Id",listalldata[ind].uniqueid)
    setValue(nane + "Name", listalldata[ind].name);
    setValue(nane + "Email", listalldata[ind].email);
    // setValue(nane+"Vendor",listalldata[ind].)
    // setValue(nane+"Company",listalldata[ind].)
    setValue(nane + "Phone", listalldata[ind].mobilenumber);

    setValue(nane + "Vendor", listalldata[ind].vendorresult[0].vendorName)

    setValue(nane + "Company", listalldata[ind].crewresult[0].crewCompanyName)
    // if(ind==0){
    //   setValue(nane+"Name","Name")
    //   setValue(nane+"Email","Email")
    //   setValue(nane+"Phone","Phone")
    // }

    // if(ind!=0){

    //   filteruserlist.map((item)=>{
    //     // console.log(item.role==arra)
    //     if(item.role==arra){
    //       // console.log(item.data[ind-1])

    //       let {roleEmail,roleName,roleNumber}=item.data[ind-1]

    //       // console.log(roleEmail,roleName,roleNumber,"roleEmail,roleName,roleNumber")
    //       setValue(nane+"Name",roleName)
    //       setValue(nane+"Email",roleEmail)
    //       setValue(nane+"Phone",roleNumber)
    //     }
    //   })

    // }
  };


  const attachImage = (e) => {
    // let files=[]
    // console.log(e.target.files, "files")
    setuploadedattachImage(e.target.files[0])

  };

  const attachFile = (e) => {
    // let files=[]
    // console.log(e.target.files, "files")
    setuploadedattachFile(e.target.files[0])

  };


  let errorlen = Object.keys(errors).length

  if (errorlen == 0) {

    // if (selectProjectCode.length == 0 || selectProjectId.length == 0 || plannedSow.length == 0 || ttdata.length == 0 || uploadedattachImage.length == 0 || uploadedattachFile.length == 0) {
    if (selectProjectCode.length == 0 || selectProjectId.length == 0 || plannedSow.length == 0 || ttdata.length == 0 || sstartTime == undefined) {
      if (submitOnceClick) {
        errorlen = 1
      }
    } else {
      errorlen = 0
    }
  }

  useEffect(() => {
    dispatch(AccountReducerAction(accountApi));
    dispatch(UserListReducerAction(listUserApi_two));
    dispatch(AccountReducerAction(allTechnologyApi));
    dispatch(AccountReducerAction(siteDblistSiteId))
    dispatch(TierListReducerAction(managEngineer))
    // dispatch(AccountReducerAction(ranlistSite))
    dispatch(AccountReducerAction(crewCompanyApi))


    dispatch(AccountReducerAction(pierTTGroupapi))
    dispatch(AccountReducerAction(getAllDropDown + "/TT type"))

    if (passedParams.ranId && path.includes("ixSevenTwo")) {

      dispatch(FetchSingleRecordAction(SevenTwoAlarmFetchSingle, passedParams.ranId))
    }
    else if (passedParams.ranId && path.includes("SevenTwoWildCard")) {

      console.warn("jnclkjebchiecefv")
      dispatch(FetchSingleRecordAction(SevenTwoAlarmFetchSingle, passedParams.ranId))
    }
    else if (passedParams.ranId && path.includes("ixDashboard")) {

      console.warn("jnclkjebchiecefv")
      dispatch(FetchSingleRecordAction(ixModule, passedParams.ranId))
    }
    else {
      dispatch(FetchSingleRecordAction(ranDashboard, passedParams.ranId))
    }
    if (path.includes("ttedit")) {
      dispatch(AccountReducerAction(listtroubleTicketApi + "/" + path.split("/").pop()));
      FetechAllDetailSingle(onenew)
    }

    dispatch(AccountReducerAction(getAllDropDown + "/" + "TT Category",false,false))
    dispatch(AccountReducerAction(getAllDropDown + "/" + "TT type",false,true))

  }, [renderOne]);

  function calltoaddTTcat(value,data=""){
    // console.log("calltoaddTTcat",value,ttcategoryArray)
    let filttcat=[]
    ttcategoryArraynew.map((itm)=>{
      // alert(itm.Type==value,"calltoaddTTcat")

      // console.log(itm,"calltoaddTTcat")
      if(itm.Type==value){
        // alert(itm.Type,value,"calltoaddTTcat")

        if(data==itm.optionValue){
          
          setValue("ttCategory", data,{shouldValidate:true,shouldTouch:true})
          filttcat.push(<><option selected>{itm.optionValue}</option></>)
        }else{
          filttcat.push(<><option>{itm.optionValue}</option></>)
        }
        
      }
    })
    // console.log("calltoaddTTcat",filttcat)

    setCate(filttcat)
  }

  // // console.log(momnt_ageofdata(moment(currentDate).format(moment_common_full_date_format),moment(sstartTime).format("MM/DD/YYYY")+" 18:00",moment_common_full_date_format),"currentDate,sstartTime",moment(currentDate).format(moment_common_full_date_format),moment(sstartTime).format("MM/DD/YYYY")+" 06:00",moment_common_full_date_format,"currentDate,sstartTime")

  return (
    <Fragment>
      {/* <PageTItle activeMenu='validation' motherMenu='Form' /> */}

      <div style={{ display: "block", width: "100%" }}>
        {/* <h4>React-Bootstrap Tab Component</h4> */}

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row mt-3">
            <div className="card " style={{ width: "100%", marginLeft: "10px" }}>
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div className="accordion-item">

                  <div>
                    <div className="accordion-body">
                      <div className="form-row mt-2 p-2">
                        {/* <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">
                            Site Id
                          </label>
                          <input style={{height:"32px"}} 
                            type="text"
                            className="form-control"
                            id="input "
                            placeholder="Enter Side Id"
                            {
                              ...register("siteId",{
                                required:"Site Id is required",
                                minLength:{
                                  value:8,
                                  message:"Please enter valid site id"
                                },
                                maxLength:{
                                  value:16,
                                  message:"Only 16 characters are allowed"
                                }
                              })
                            }
                          />
                          {errors.siteId && <p className="error">{errors.siteId.message}</p>}
                        </div> */}
                        {/* <div className="form-group col-md-4"> */}
                        {/* <label for="exampleFormControlSelect1">
                            Site Id
                          </label>
                          <input className="form-control" list="cars"
                      
                          //  onChange={(e)=>{
                            // setCrCodeState(e.target.value)
                            // setCrCodeError('')
                          // }}
                            // {
                            //   ...register("siteId",{
                            //     required:"Site Id is required",
                            //     minLength:{
                            //       value:5,
                            //       message:"Please enter valid site id"
                            //     },
                            //     maxLength:{
                            //       value:16,
                            //       message:"Only 16 characters are allowed"
                            //     }
                            //   })
                            // }
                            
                          />
                          <datalist id="cars">
                              <option value="BMW" />
                              <option value="Bentley" />
                              <option value="Mercedes" />
                              <option value="Audi" />
                              <option value="Volkswagen" />
                          </datalist>
                        </div> */}
                        {!path.includes("ttedit") ? <>
                          <div className="form-group col-md-3">
                            <label htmlFor="inputEmail4">TT Open Date</label>
                            <input
                              style={{ height: "32px" }}
                              type="text"
                              className="form-control"
                              id="inputEmail4"
                              name="siteid"
                              disabled
                              defaultValue={currentDate}
                              value={currentDate}
                              {...register("ttOpenDate")}
                            />
                          </div></> : <><div className="form-group col-md-3">
                            <label htmlFor="inputEmail4">TT Open Date</label>
                            <input
                              style={{ height: "32px" }}
                              type="text"
                              className="form-control"
                              id="inputEmail4"
                              name="siteid"
                              disabled
                              defaultValue={ttOpenDate}
                              value={ttOpenDate}
                            />
                          </div>
                        </>



                        }


                        <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">Site Id</label>
                          {


                            //   <input className='form-control' list="cars"
                            //   style={{ height: "32px" }}
                            //   defaultValue={whatWork != "Add" && whatWork != undefined ? singleSitestate.siteId : ""}
                            //   onBlur={(e) => {
                            //     findDSiteId(e.target.value)
                            //   }}
                            // />
                            // <p className="error" style={{ display: siteError ? "bolck" : "none" }}>
                            //   Required Field
                            // </p>
                            // <datalist id="cars">

                            //   {options.siteArray}
                            // </datalist>
                            // editableId==undefined?
                            path.includes("ttdb") ? <>
                              <input
                                list="cars"
                                style={{ height: "32px" }}
                                className="form-control"
                                autoComplete={"off"}
                                id="exampleFormControlSelect1"
                                // onSelect={(e)=>{
                                //   // console.log("single pro ",e)
                                //     // dispatch(fetechSingleProject(singleProjectApi,id))
                                // }}
                                // value={siteIdVal}
                                {...register("siteUniqueId", {
                                  required: "Please select Site id",
                                  onChange: (e) => {
                                    // console.log("single pro ", e.target.value);

                                    fetechSingleProject(e.target.value);
                                  },
                                })}
                              />
                              {/* // <option selected disabled value="">
                                //   Select
                                // </option>
                                // {projectData.siteArray} */}


                              <datalist id="cars">

                                {projectData.siteArray}
                              </datalist>
                            </>
                              :

                              <input
                                style={{ height: "32px" }}
                                type="text"
                                className="form-control"
                                id="input "
                                readOnly
                                value={sidd}
                              />
                            //   <select
                            //   style={{ height: "32px" }}
                            //   className="form-control"
                            //   id="exampleFormControlSelect1"
                            //   disabled
                            //   // onSelect={(e)=>{
                            //   //   // console.log("single pro ",e)
                            //   //     // dispatch(fetechSingleProject(singleProjectApi,id))
                            //   // }}
                            // >
                            //   <option selected disabled value="">
                            //     Select
                            //   </option>
                            //   {projectData.siteArray}
                            // </select>
                          }
                          {errors.siteUniqueId && (
                            <p className="error">{errors.siteUniqueId.message}</p>
                          )}
                        </div>
                        <div className="form-group col-md-3">
                          <label>Project Code</label>
                          {
                            path.includes("ttdb") ?
                              <MultiSelect
                                className="text-dark"
                                hasSelectAll={false}
                                options={projectData.projectCodeOption}
                                onChange={findDProjectId}
                                value={selectProjectCode}
                              /> :
                              <input
                                style={{ height: "32px" }}
                                type="text"
                                className="form-control"
                                id="input "
                                readOnly
                                value={npcode}
                              />}
                          {errorlen > 0 && !path.includes("ttedit") && selectProjectCode.length == 0 ? <p className="error">{customError.projectCode}</p> : ""}
                          {/* <select
                            style={{ height: "32px" }}
                            className="form-control"
                            {
                            ...register("projectUniqueId",
                              { onChange: (e) => { findDProjectId(e.target.value) } }
                            )
                            }
                          >
                            <option selected disabled value=''>select</option>
                            {projectData.projectCodeOption}
                          </select> */}
                        </div>

                        <div className="form-group col-md-3 ">
                          <label >Project Id</label>

                          {path.includes("ttdb") ?
                            <MultiSelect
                              className="text-dark"
                              hasSelectAll={false}
                              options={projectData.newTtempArray}
                              onChange={FetechAllDetail}
                              value={selectProjectId}
                            /> :
                            <input
                              style={{ height: "32px" }}
                              type="text"
                              className="form-control"
                              id="input "
                              readOnly
                              value={npidd}
                            />}
                          {errorlen > 0 && !path.includes("ttedit") && selectProjectId.length == 0 ? <p className="error">{customError.projectId}</p> : ""}
                          {/* <select
                            style={{ height: "32px" }}
                            className="form-group"
                            isSearchable={true}
                            {
                            ...register("ranprojectId", {
                              required: "Please select project Id",
                              onChange: (e) => {
                                FetechAllDetail(e.target.value);
                              }
                            }
                            )
                            }
                          >
                            <option disabled value=''>select</option>
                            {projectData.projectIdOption}
                          </select>
                          {errors.ranprojectId && (
                            <p className="error">
                              {errors.ranprojectId.message}
                            </p>
                          )} */}
                        </div>
                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4" >Account </label>
                          {path.includes("ttdb") ?
                            // userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" || (userDetails.rolename == "GC Login" && (whatWork != "assigner" && whatWork != "reassigner")) ?
                            <>
                              <select style={{ height: "32px" }}
                                className="form-control"
                                id="exampleFormControlSelect1"
                                {...register("account", {
                                  required: "Please select account"
                                })}
                              >
                                <option value="">Select</option>
                                {projectData.accountArry}
                              </select>
                              {errors.account && (
                                <p className="error">
                                  {errors.account.message}
                                </p>
                              )}
                            </> : <input
                              style={{ height: "32px" }}
                              type="text"
                              className="form-control"
                              id="input "
                              readOnly
                              value={Account}
                            />
                          }

                        </div>
                        {/* <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">Account</label>
                          <input
                            style={{ height: "32px" }}
                            type="text"
                            className="form-control"
                            id="input "
                            readOnly
                            {...register("account")}
                          />
                          {errors.accountt && (
                            <p className="error">{errors.accountt.message}</p>
                          )}
                        </div> */}

                        {/* {console.log(projectData, "projectDataprojectData")} */}


                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4" >Market </label>
                          {!path.includes("ttedit") ?
                            // userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" || (userDetails.rolename == "GC Login" && (whatWork != "assigner" && whatWork != "reassigner")) ?
                            <>
                              <input
                                style={{ height: "32px" }}
                                type="text"
                                className="form-control"
                                id="input "
                                readOnly
                                value={
                                  projectData.pdsingleSite ? projectData.pdsingleSite.market ? projectData.pdsingleSite.market : projectData.pdsingleSite.length > 0 ? projectData.pdsingleSite[0].market : "" : ""
                                }
                                {...register("market")}
                              />
                              {errors.market && (
                                <p className="error">
                                  {errors.market.message}
                                </p>
                              )}
                            </> : <input
                              style={{ height: "32px" }}
                              type="text"
                              className="form-control"
                              id="input "
                              readOnly
                              value={Market}
                            />
                          }

                        </div>
                        {/* <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">Market</label>
                          
                        </div> */}


                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4">Technology </label>


                          {!path.includes("ttedit") ?
                            <MultiSelect
                              options={multipleTechno}
                              onChange={techChangleHandler}
                              value={plannedSow}
                              required
                              hasSelectAll={false}
                            /> :
                            <input
                              style={{ height: "32px" }}
                              type="text"
                              className="form-control"
                              id="input "
                              readOnly
                              value={ttdataidd}
                            />}

                          {errorlen > 0 && !path.includes("ttedit") && plannedSow.length == 0 ? <p className="error">{customError.technology}</p> : ""}
                        </div>
                        <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">
                            Technology Affected
                          </label>


                          {!path.includes("ttedit") ?
                            <MultiSelect
                              options={multipleTechno}
                              onChange={techAffectedChangleHandler}
                              value={techaff}
                              hasSelectAll={false}
                            /> : <input
                              style={{ height: "32px" }}
                              type="text"
                              className="form-control"
                              id="input "
                              readOnly
                              value={ttaffdataidd}
                            />}
                          {/* {console.log(errorlen, techaff.length == 0, "errorlenerrorlen")} */}
                          {errorlen > 0 && techaff.length == 0 ? <p className="error">{customError.technologyAffected}</p> : ""}
                          {/* {errors.planned  && <p className="error">{errors.planned.message}</p>} */}
                        </div>

                        {type_cat=="old" ?
                          <>
                            <div className="form-group col-md-3">
                              <label for="exampleFormControlSelect1">
                                TT Type
                              </label>
                              <MultiSelect
                                options={projectData.tttypeArray}
                                onChange={ttChangleHandler}
                                value={ttdata}
                              />
                              {errorlen > 0 && ttdata.length == 0 ? <p className="error">{customError.tTType}</p> : ""}
                              {/* {errors.planned  && <p className="error">{errors.planned.message}</p>} */}
                            </div>


                            <div className="form-group col-md-3">
                              <label for="exampleFormControlSelect1">TT Category</label>
                              <select
                                style={{ height: "32px" }}
                                className="form-control"
                                id="exampleFormControlSelect1"
                                // onSelect={(e)=>{
                                //   console.log("single pro ",e)
                                //     // dispatch(fetechSingleProject(singleProjectApi,id))
                                // }}
                                {...register("ttCategory", {
                                  required: "Please select TT Category",
                                })}
                              >
                                <option selected disabled value="">
                                  Select
                                </option>
                                {projectData.ttcategory}
                              </select>
                              {errors.ttCategory && (
                                <p className="error">{errors.ttCategory.message}</p>
                              )}
                            </div>
                          </>
                          : ""
                        }
                        {type_cat=="new" ?
                          <>
                            <div className="form-group col-md-3">
                              <label for="exampleFormControlSelect1">
                                TT Type
                              </label>
                              <select
                                style={{ height: "32px" }}
                                className="form-control"
                                id="exampleFormControlSelect1"
                                // onSelect={(e)=>{
                                //   console.log("single pro ",e)
                                //     // dispatch(fetechSingleProject(singleProjectApi,id))
                                // }}
                                {...register("ttType", {
                                  required: "Please select TT Type",
                                  onChange:(e)=>{
                                    calltoaddTTcat(e.target.value)
                                  }
                                })}
                              >
                                <option selected disabled value="">
                                  Select
                                </option>
                                {projectData.tttypes}
                              </select>
                              {errors.ttType && (
                                <p className="error">{errors.ttType.message}</p>
                              )}
                            </div>


                            <div className="form-group col-md-3">
                              <label for="exampleFormControlSelect1">TT Category</label>
                              <select
                                style={{ height: "32px" }}
                                className="form-control"
                                id="exampleFormControlSelect1"
                                // onSelect={(e)=>{
                                //   console.log("single pro ",e)
                                //     // dispatch(fetechSingleProject(singleProjectApi,id))
                                // }}
                                {...register("ttCategory", {
                                  required: "Please select TT Category",
                                })}
                              >
                                <option selected value="">
                                  Select
                                </option>
                                {cate}
                                {/* {projectData.ttcategory} */}
                              </select>
                              {errors.ttCategory && (
                                <p className="error">{errors.ttCategory.message}</p>
                              )}
                            </div>
                          </>
                          : ""
                        }

                        <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">Impact</label>
                          <select style={{ height: "32px" }}
                            {...register("impact", {
                              required: "Please select type of work"
                            })}
                            className="form-control" aria-label="Default select example">
                            <option selected value="">Select</option>
                            <option value="SI">SI</option>
                            <option value="Degradation">Degradation</option>
                            <option value="NSI">NSI</option>
                          </select>
                          {errors.impact && (
                            <p className="error">{errors.impact.message}</p>
                          )}
                        </div>





                        <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">
                            {" "}
                            TT Id
                          </label>
                          <input style={{ height: "32px" }}
                            type="text"
                            value={projectData.oneTTData ? projectData.oneTTData.ttid : ""}
                            className="form-control"
                            ID="inputAddress"
                            placeholder=""
                            readOnly
                          />
                        </div>

                        <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">
                            {" "}
                            TMO PIER TT Id
                          </label>
                          <input style={{ height: "32px" }}
                            type="text"
                            {...register("tmoPierTtid", {
                              required: "Please select type of work"
                            })}

                            className="form-control"
                            ID="inputAddress"
                            placeholder="Enter TMO PIER TT Id"
                          />
                          {errors.tmoPierTtid && (
                            <p className="error">{errors.tmoPierTtid.message}</p>
                          )}
                        </div>

                        <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">
                            {" "}
                            Group Assigned to TT in PIER
                          </label>
                          <select style={{ height: "32px" }}
                            {...register("groupAssignedTTPier", {
                              required: "Please select Group Assigned "
                            })}
                            className="form-control" aria-label="Default select example">
                            <option selected value="">Select</option>
                            {projectData.pierTTArray}
                          </select>
                          {errors.groupAssignedTTPier && (
                            <p className="error">{errors.groupAssignedTTPier.message}</p>
                          )}
                        </div>


                        <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">TT Status</label>
                          <select style={{ height: "32px" }}
                            {...register("ttStatus", {
                              required: "Please select type of work",
                              onChange: (e) => {
                                setttStatus(e.target.value);
                              }
                            })}
                            className="form-control" aria-label="Default select example">
                            {
                              path.includes("ttedit") ? <>
                                <option selected value="Open">Open</option>
                                <option value="Terminated">Terminated</option>
                                <option value="Close">Close</option>
                              </>
                                : <option value="Open">Open</option>
                            }
                          </select>
                        </div>

                        {
                          !path.includes("ttedit") ? <>
                            <div className="form-group col-md-3">
                              <label for="exampleFormControlSelect1">
                                {" "}
                                Engineer Opening TT
                              </label>
                              <input style={{ height: "32px" }}
                                type="text"
                                {...register("engineerOpeningtt", {
                                  required: "Please select Engineer Opening TT"
                                })}
                                onBlur={(e) => { findengineer(e.target.value, "engineerOpeningtt") }}
                                list="engOpen"
                                className="form-control"
                                ID="inputAddress"
                                defaultValue={EngineerOpen}
                                placeholder="Select Engineer"
                              />

                              <datalist id="engOpen">
                                {projectData.engineerArray}
                              </datalist>

                              {errors.engineerOpeningtt && (
                                <p className="error">{errors.engineerOpeningtt.message}</p>
                              )}
                            </div></> :
                            <>
                              <div className="form-group col-md-3">
                                <label for="exampleFormControlSelect1">
                                  {" "}
                                  Engineer Opening TT
                                </label>
                                <input style={{ height: "32px" }}
                                  type="text"
                                  {...register("engineerOpeningtt", {
                                    required: "Please select Engineer Opening TT"
                                  })}
                                  onBlur={(e) => { findengineer(e.target.value, "engineerOpeningtt") }}
                                  list="engOpen"
                                  className="form-control"
                                  ID="inputAddress"
                                  defaultValue={userDetails.name}
                                  placeholder="Select Engineer"
                                />

                                <datalist id="engOpen">
                                  {projectData.engineerArray}
                                </datalist>

                                {errors.engineerOpeningtt && (
                                  <p className="error">{errors.engineerOpeningtt.message}</p>
                                )}
                              </div>
                            </>
                        }


                        {ttStatus == "Close" || ttStatus == "Terminated" ? !path.includes("ttedit") ? <>
                          <div className="form-group col-md-3">
                            <label for="exampleFormControlSelect1">
                              {" "}
                              Engineer Closing / Terminating TT
                            </label>
                            <input style={{ height: "32px" }}
                              type="text"
                              {...register("engineerClosingtt", {
                                required: "Please select Engineer Closing / Terminating TT"
                              })}
                              onBlur={(e) => { findengineer(e.target.value, "engineerClosingtt") }}
                              list="engOpen"
                              className="form-control"
                              ID="inputAddress"
                              defaultValue={userDetails.name}
                              placeholder="Select Engineer"
                            />

                            <datalist id="engOpen">
                              {projectData.engineerArray}
                            </datalist>


                            {errors.engineerClosingtt && (
                              <p className="error">{errors.engineerClosingtt.message}</p>
                            )}

                          </div> </> : <>
                          <div className="form-group col-md-3">
                            <label for="exampleFormControlSelect1">
                              {" "}
                              Engineer Closing / Terminating TT
                            </label>
                            <input style={{ height: "32px" }}
                              type="text"
                              {...register("engineerClosingtt", {
                                required: "Please select Engineer Closing / Terminating TT"
                              })}
                              onBlur={(e) => { findengineer(e.target.value, "engineerClosingtt") }}
                              list="engOpen"
                              className="form-control"
                              ID="inputAddress"
                              defaultValue={EngineerOpen}
                              placeholder="Select Engineer"
                            />

                            <datalist id="engOpen">
                              {projectData.engineerArray}
                            </datalist>


                            {errors.engineerClosingtt && (
                              <p className="error">{errors.engineerClosingtt.message}</p>
                            )}

                          </div> </> : ""}



                        <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">Company</label>
                          <select
                            style={{ height: "32px" }}
                            className="form-control"
                            id="exampleFormControlSelect1"
                            // onSelect={(e)=>{
                            //   console.log("single pro ",e)
                            //     // dispatch(fetechSingleProject(singleProjectApi,id))
                            // }}
                            {...register("company", {
                              required: "Please select Company",
                              onChange: (e) => {
                                changeCompany(e.target.value);
                                // console.log("changeCompany ", e.target.value);
                              },
                            })}
                          >
                            <option selected disabled value="">
                              Select
                            </option>
                            {projectData.crewCompanyArray}
                          </select>
                          {errors.company && (
                            <p className="error">{errors.company.message}</p>
                          )}
                        </div>

                        <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">Vendor</label>
                          <select
                            style={{ height: "32px" }}
                            className="form-control"
                            id="exampleFormControlSelect1"
                            // onSelect={(e)=>{
                            //   console.log("single pro ",e)
                            //     // dispatch(fetechSingleProject(singleProjectApi,id))
                            // }}
                            {...register("vendor", {
                              required: "Please select Vendor",
                              onChange: (e) => {
                                setVendorForm(e.target.value)
                                // console.log("single pro ", e.target.value);
                              },
                            })}
                          >
                            <option selected disabled value="">
                              Select
                            </option>
                            {
                              projectData.vendorDataArray
                            }
                            <option value="NA">NA</option>
                          </select>
                          {errors.vendor && (
                            <p className="error">{errors.vendor.message}</p>
                          )}
                        </div>


                        <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">
                            {" "}
                            Attachment Upload File
                          </label>

                          <div className="input-group mb-3 fileSection">

                            <div>
                              <label class="custom-file-upload btn btn-outline-primary btn-xs ">
                                <input
                                  type="file"
                                  className="form-control btn btn-primary btn-xs"
                                  id="inputGroupFile04"
                                  aria-describedby="inputGroupFileAddon04"
                                  aria-label="Upload"
                                  name="file"
                                  onChange={attachFile}
                                />
                                <i class="fa fa-cloud-upload"></i> Choose File
                              </label>
                              {/* <label>Remarks</label>
                            <input type='text' className="form-control" value={remarks} onChange={(e)=>{setRemarks(e.target.value)}}></input> */}

                            </div>

                          </div>

                          {/* {errorlen > 0 && uploadedattachFile.length == 0 ? <p className="error">{customError.uploadFile}</p> : ""} */}

                        </div>


                        <div className="form-group col-md-3">
                          <label for="exampleFormControlSelect1">
                            {" "}
                            Attachment Upload Images
                          </label>
                          <div className="input-group mb-3 fileSection">

                            <div>
                              <label class="custom-file-upload btn btn-outline-primary btn-xs ">
                                <input
                                  type="file"
                                  className="form-control btn btn-primary btn-xs"
                                  id="inputGroupFile04"
                                  aria-describedby="inputGroupFileAddon04"
                                  aria-label="Upload"
                                  name="file"
                                  onChange={attachImage}
                                />
                                <i class="fa fa-cloud-upload"></i> Choose File
                              </label>
                              {/* <label>Remarks</label>
                            <input type='text' className="form-control" value={remarks} onChange={(e)=>{setRemarks(e.target.value)}}></input> */}

                            </div>

                          </div>
                          {/* {errorlen > 0 && uploadedattachImage.length == 0 ? <p className="error">{customError.uploadImage}</p> : ""} */}
                        </div>


                        <div className="form-group col-md-12">
                          <label for="exampleFormControlSelect1">Estimated Time of Recover</label>
                          <div className="form-row col-md-4">
                            {!path.includes("ttedit") ? <>
                              <DatePicker
                                className="form-control"
                                // style={styles}
                                minDate={new Date(currentDate + ":00")}
                                selected={sstartTime}
                                onChange={(e) => { setstartTime(e); }}
                                showDateSelectOnly
                                dateFormat="MM/dd/yyyy" /></> : <>
                              <DatePicker
                                className="form-control"
                                // style={styles}
                                minDate={new Date(ttOpenDate + ":00")}
                                selected={sstartTime}
                                onChange={(e) => { setstartTime(e); }}
                                showDateSelectOnly
                                dateFormat="MM/dd/yyyy" /></>
                            }

                            {errorlen > 0 && sstartTime == undefined ? <p className="error">{customError.psetor}</p> : ""}
                            {/* <div className="form-group col-md-3">
                              <label for="exampleFormControlSelect1">Day</label>
                              <input type="number" placeholder="DD" className="form-control col-md-12"
                                min={0}
                                {...register("day", {
                                  required: "Please select Day",
                                })}
                              ></input>
                              {errors.day && (
                                <p className="error">{errors.day.message}</p>
                              )}
                            </div>
                            &nbsp;
                            &nbsp;
                            <div className="form-group col-md-3">
                              <label for="exampleFormControlSelect1">Hour</label>
                              <input type="number" placeholder="HH" className="form-control col-md-12"
                                min={0}
                                {...register("hh", {
                                  required: "Please select HH",
                                })}
                              ></input>
                              {errors.hh && (
                                <p className="error">{errors.hh.message}</p>
                              )}
                            </div>
                            &nbsp;
                            &nbsp;

                            <div className="form-group col-md-3">
                              <label for="exampleFormControlSelect1">Minutes</label>
                              <input type="number" placeholder="MM" className="form-control col-md-12"
                                min={0}

                                {...register("mm", {
                                  required: "Please select MM",
                                })}
                              ></input>
                              {errors.mm && (
                                <p className="error">{errors.mm.message}</p>
                              )}
                            </div> */}
                          </div>
                        </div>


                        <div className="form-group col-md-12">
                          <label for="exampleFormControlSelect1">
                            {" "}
                            Cabinet IDs
                          </label>
                          <input style={{ height: "32px" }}
                            type="text"
                            {...register("cabinetId")}
                            disabled
                            className="form-control"
                            ID="inputAddress"
                            value="GSM;,,,, UTMS;,,,,, LTE:,,,,, 5G;,,,,,"
                            placeholder="GSM;,,,, UTMS;,,,,, LTE:,,,,, 5G;,,,,,"
                          />
                        </div>

                        {path.includes("ttedit") ? <><div className="form-group col-md-11">
                          <label for="exampleFormControlSelect1">
                            {" "}
                            TT Description and Comments
                          </label>
                          <textarea style={{ height: "32px" }}
                            type="text"
                            value={customData.tt_desc}
                            className="form-control"
                            ID="inputAddress"
                            disabled
                            cols={5}
                          />
                          {errors.tt_desc && (
                            <p className="error">{errors.tt_desc.message}</p>
                          )}
                        </div>
                          <div className="form-group col-md-1">
                            {/* <button style={{ marginTop: "33px" }}
                              type="button"
                              className="btn btn-primary btn-xs "
                              data-toggle="modal"
                              data-target="#exampleModal"
                            >
                              <span style={{ fontSize: "20px" }}>+</span>
                            </button> */}

                            <p
                              className={"text-black my-0  modalHoverClass"}
                              style={{ padding: "6px" }}
                              onClick={() => {
                                CommonModalFun(
                                  <TTHistoryCmts unique_id_up={path.split("/").pop()} from={"tt_desc"} />,
                                  "Comment",
                                  "Comment"
                                )
                              }}
                            >
                              <p className={`badge badge-custom-size badge-primary p-1 my-0`}><Add /></p>
                              {/* {item['mopRFSiteStatus']?<p className={`badge badge-custom-size ${colors[item['mopRFSiteStatus'] || "pending"]} p-1 my-0`}>{item['mopRFSiteStatus']}</p>:userDetails.rolename == "PAG Admin" || userDetails.rolename == "RF Engineer"?<p className={'badge badge-primary'} style={{ width: "50px" }}><Add /></p>:""} */}
                            </p>
                          </div>
                        </> : <div className="form-group col-md-12">
                          <label for="exampleFormControlSelect1">
                            {" "}
                            TT Description and Comments
                          </label>
                          <textarea style={{ height: "32px" }}
                            type="text"
                            {...register("tt_desc", {
                              required: "Please enter TT Description and Comments",
                            })}
                            className="form-control"
                            ID="inputAddress"
                            cols={5}
                          />
                          {errors.tt_desc && (
                            <p className="error">{errors.tt_desc.message}</p>
                          )}
                        </div>}


                        {path.includes("ttedit") ? <><div className="form-group col-md-11">
                          <label for="exampleFormControlSelect1">
                            {" "}
                            TT Detailed Summary Analysis
                          </label>
                          <textarea style={{ height: "32px" }}
                            type="text"
                            value={customData.tt_summary}
                            className="form-control"
                            ID="inputAddress"
                            disabled
                            cols={5}
                          />
                          {errors.tt_summary && (
                            <p className="error">{errors.tt_summary.message}</p>
                          )}
                        </div>
                          <div className="form-group col-md-1">
                            {/* <button style={{ marginTop: "33px" }}
                              type="button"
                              className="btn btn-primary btn-xs "
                              data-toggle="modal"
                              data-target="#exampleModal"
                            >
                              <span style={{ fontSize: "20px" }}>+</span>
                            </button> */}

                            <p
                              className={"text-black my-0  modalHoverClass"}
                              style={{ padding: "6px" }}
                              onClick={() => {
                                CommonModalFun(
                                  <TTHistoryCmts unique_id_up={path.split("/").pop()} from={"tt_summary"} />,
                                  "Summary",
                                  "Summary"
                                )
                              }}
                            >
                              <p className={`badge badge-custom-size badge-primary p-1 my-0`}><Add /></p>
                              {/* {item['mopRFSiteStatus']?<p className={`badge badge-custom-size ${colors[item['mopRFSiteStatus'] || "pending"]} p-1 my-0`}>{item['mopRFSiteStatus']}</p>:userDetails.rolename == "PAG Admin" || userDetails.rolename == "RF Engineer"?<p className={'badge badge-primary'} style={{ width: "50px" }}><Add /></p>:""} */}
                            </p>
                          </div>
                        </> : <div className="form-group col-md-12">
                          <label for="exampleFormControlSelect1">
                            {" "}
                            TT Detailed Summary Analysis
                          </label>
                          <textarea style={{ height: "32px" }}
                            type="text"
                            {...register("tt_summary", {
                              required: "Please enter TT Detailed Summary Analysis",
                            })}
                            className="form-control"
                            ID="inputAddress"
                            cols={5}
                          />
                          {errors.tt_summary && (
                            <p className="error">{errors.tt_summary.message}</p>
                          )}
                        </div>}
                        {/* <div className="form-group col-md-12">
                          <label for="exampleFormControlSelect1">
                            {" "}
                            TT Detailed Summary Analysis
                          </label>
                          <textarea style={{ height: "32px" }}
                            type="text"

                            {...register("tt_summary", {
                              required: "Please enter TT Description and Comments",
                            })}
                            className="form-control"
                            ID="inputAddress"
                            cols={5}
                          />
                          {errors.tt_summary && (
                            <p className="error">{errors.tt_summary.message}</p>
                          )}
                        </div> */}

                        {path.includes("ttedit") ? <><div className="form-group col-md-11">
                          <label for="exampleFormControlSelect1">
                            {" "}
                            Actions need to be taken to resolve TT
                          </label>
                          <textarea style={{ height: "32px" }}
                            type="text"
                            value={customData.actionstt}
                            className="form-control"
                            ID="inputAddress"
                            disabled
                            cols={5}
                          />
                          {errors.actionstt && (
                            <p className="error">{errors.actionstt.message}</p>
                          )}
                        </div>
                          <div className="form-group col-md-1">
                            {/* <button style={{ marginTop: "33px" }}
                              type="button"
                              className="btn btn-primary btn-xs "
                              data-toggle="modal"
                              data-target="#exampleModal"
                            >
                              <span style={{ fontSize: "20px" }}>+</span>
                            </button> */}

                            <p
                              className={"text-black my-0  modalHoverClass"}
                              style={{ padding: "6px" }}
                              onClick={() => {
                                CommonModalFun(
                                  <TTHistoryCmts unique_id_up={path.split("/").pop()} from={"actionstt"} />,
                                  "Action",
                                  "Action"
                                )
                              }}
                            >
                              <p className={`badge badge-custom-size badge-primary p-1 my-0`}><Add /></p>
                              {/* {item['mopRFSiteStatus']?<p className={`badge badge-custom-size ${colors[item['mopRFSiteStatus'] || "pending"]} p-1 my-0`}>{item['mopRFSiteStatus']}</p>:userDetails.rolename == "PAG Admin" || userDetails.rolename == "RF Engineer"?<p className={'badge badge-primary'} style={{ width: "50px" }}><Add /></p>:""} */}
                            </p>
                          </div>
                        </> : <div className="form-group col-md-12">
                          <label for="exampleFormControlSelect1">
                            {" "}
                            Actions need to be taken to resolve TT
                          </label>
                          <textarea style={{ height: "32px" }}
                            type="text"
                            {...register("actionstt", {
                              required: "Please enter Actions need to be taken to resolve TT",
                            })}
                            className="form-control"
                            ID="inputAddress"
                            cols={5}
                          />
                          {errors.actionstt && (
                            <p className="error">{errors.actionstt.message}</p>
                          )}
                        </div>}
                        {/* <div className="form-group col-md-12">
                          <label for="exampleFormControlSelect1">
                            {" "}
                            Actions need to be taken to resolve TT
                          </label>
                          <textarea style={{ height: "32px" }}
                            type="text"
                            {...register("actionstt", {
                              required: "Please enter TT Description and Comments",
                            })}
                            className="form-control"
                            ID="inputAddress"
                            cols={5}

                          />
                          {errors.actionstt && (
                            <p className="error">{errors.actionstt.message}</p>
                          )}
                        </div> */}


                      </div>
                    </div>
                  </div>


                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <button type="submit" onClick={((e) => { setSubmitOnceClick(true) })} className="btn btn-primary btn-xs mx-2">
                      Submit
                    </button>
                    <button type="button" onClick={() => topage("back")} className="btn btn-danger btn-xs mx-2">Back</button>
                  </div>
                </div >
              </div >
            </div >
          </div>
        </form >

      </div >

      <CommonModal
        modalSize={commonModalSize}
        setXlShow={setcommonModalState}
        xlShow={commonModalState}
        Body={commonModalBody}
        Header={commonModalHeader}
        Title={commonModalTitle}
      ></CommonModal>
    </Fragment >
  );
};

export default NetworkTwo;
