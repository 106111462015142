import { useSelector } from "react-redux"


import React from "react";
import { act } from "react";

const DowntimeList = ({ siteDetail, from }) => {

    console.log(siteDetail, "sitedetails______")


    function parseRFStart(dateString) {
        let [month, day, year, time] = dateString.split(/[/ ]/);
        return new Date(`${year}-${month}-${day}T${time}:00`);
    }

    function parseUpTime(dateString) {
        return new Date(dateString);
    }

    function compareDates(RFStartString, upTimeString, serviceImpact) {

        // let secondColor;
        // if(serviceImpact === "Sector Down"){
        // secondColor='#ffc000'
        // }
        // else{
        // secondColor ='#C1F0C8'
        // }

        let color;
        if (RFStartString && upTimeString) {
            const RFStart = parseRFStart(RFStartString);
            const upTime = parseUpTime(upTimeString);
            color = RFStart.getTime() < upTime.getTime() ? 'red' : '#ffc000';
        } else {
            color = '#ffc000';
        }
        return color;
    }


    // window.alert(RFStartString)

    console.log(from, siteDetail, "sadhsadsahjdgsajdgjas")
    let siteDowntimeDetails = useSelector((state) => {
        return state.OneCommonResponse.singleSiteDownTime
    })
    let RFStartString = "";

    console.log(siteDowntimeDetails, "siteDowntimeDetailssiteDowntimeDetails")

    // const RFTime = siteDetail.site.RF_Approved_EndTime_Time;

    // const UpTime= siteDowntimeDetails?.siteData?.map(ireq => ireq["Down Time in CST"]);




    // const mopStart = new Date(mopStartTime);
    // const logoutPostcheck = new Date(logoutPostcheckTime);

    // const activityTimeDifference = (logoutPostcheck.getTime() - mopStart.getTime()) / (1000 * 60);
    // console.log(activityTimeDifference,'activityTimeDifference <= 10')



    const mopStartTime = useSelector((state) => {

        console.log(state, ":statestatestatestate")
        if (state && state.sites && state.sites.sites && state.sites.sites.mopStartTime) {
            return state.sites.sites.mopStartTime;
        } else {
            return null;
        }
    });
    // console.log(mopStartT)

    let itwqd = -1;

    function getBackgroundColor(band) {
        if (band.startsWith("react")) {
            return "#FFFFFF"; // Color for bands starting with "react"
        } else if (band.startsWith("N")) {
            return "#B3B8B6"; // Color for bands starting with "N"
        } else if (band.startsWith("L")) {
            return "#F8C7AF"; // Color for bands starting with "L"
        } else if (band.startsWith("U")) {
            return "#D7BAED"; // Color for bands starting with "U"
        } else if (band.startsWith("G")) {
            return "#9ED3FE"; // Color for bands starting with "G"
        } else {
            return ""; // Default color
        }
    }


    const durationToSeconds = (duration) => {
        const [hours, minutes, seconds] = duration.split(':').map(Number);
        return hours * 3600 + minutes * 60 + seconds;
    };


    const secondsToDuration = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;
        return `${hours}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    };

    const calculateTimeDifference = (mopStartTime, logoutPostcheckTime, mopEndTime, actualDowntimeStart, actualDowntimeEnd) => {
        if (!mopStartTime || !logoutPostcheckTime) {
            return null;
        }

        const mopStart = new Date(mopStartTime);
        const logoutPostcheck = new Date(logoutPostcheckTime);

        const activityTimeDifference = (logoutPostcheck.getTime() - mopStart.getTime()) / (1000 * 60);
        console.log(activityTimeDifference, 'activityTimeDifference <= 10')

        let activityColor;
        if (activityTimeDifference <= 10 && activityTimeDifference >= -10) {
            activityColor = "#C1F0C8";
        } else if ((activityTimeDifference > 10 && activityTimeDifference <= 30) || (activityTimeDifference < -10 && activityTimeDifference >= -30)) {
            activityColor = "#FFC000";
        } else {
            activityColor = "red";
        }
        if (!actualDowntimeStart || !actualDowntimeEnd) {
            return activityColor;
        }

        const downtimeStart = new Date(actualDowntimeStart);
        console.log(downtimeStart, "downtimeStartdowntimeStartdowntimeStart")
        const downtimeEnd = new Date(actualDowntimeEnd);
        if (downtimeStart >= mopStartTime && downtimeEnd <= mopEndTime) {
            return activityColor; // Green
        } else {
            const downtimeDifference = Math.abs(downtimeStart.getTime() - mopStartTime.getTime()) / (1000 * 60);
            if (downtimeDifference > 1) {
                return "red"; // Red
            }
            return activityColor;
        }
    };

    const colorCondition = (activityEndTime, postCheckTime) => {
        if (!activityEndTime || !postCheckTime) {
            return null;
        }

        const endTime = new Date(activityEndTime);
        const postCheck = new Date(postCheckTime);

        if (postCheck > endTime) {
            return "red"; // Highlight in red if post-check is after activity end time
        }

        return ""; // No highlight otherwise
    };

    return <>


        <table style={{ height: "110px", width: "100%" }} className="tablee table-bordered pop-table showone">
            <tbody>


                <tr>
                    <th
                        style={{
                            width: "230px",
                            fontWeight: "400",
                            padding: "5px",
                            color: "white",
                            fontSize: "11px",
                        }}
                    >
                        MOP Approved Activity Start
                    </th>
                    {/* <td>
{
from == "ran" ? <span className="ng-binding">{siteDetail.site.RF_Approved_MW_Time}</span> : <span className="ng-binding">{siteDetail.ran.RF_Approved_MW_Time}</span>
}

</td> */}
                    <td>
                        {
                            from === "ran"
                                ? <span className="ng-binding">{siteDetail.site.RF_Approved_MW_Time}</span>
                                : <span className="ng-binding">{siteDetail.mopStartTime}</span>
                        }
                    </td>

                </tr>
                <tr>
                    <th
                        style={{
                            width: "230px",
                            fontWeight: "400",
                            padding: "5px",
                            color: "white",
                            fontSize: "11px",
                        }}
                    >
                        Login Time Precheck Request
                    </th>
                    <td style={{ backgroundColor: from == "ran" ? siteDetail.preCheckRequest ? calculateTimeDifference(siteDetail.site.RF_Approved_MW_Time, siteDetail.preCheckRequest) : "" : siteDetail.ran.preCheckRequest ? calculateTimeDifference(siteDetail.mopStartTime, siteDetail.ran.preCheckRequest) : "" }}>
                        {
                            from == "ran" ? <span className="ng-binding">{siteDetail.preCheckRequest}</span> : <span className="ng-binding">{siteDetail.ran.preCheckRequest}</span>
                        }
                    </td>
                </tr>
                <tr>
                    <th
                        style={{
                            width: "230px",
                            fontWeight: "400",
                            padding: "5px",
                            color: "white",
                            fontSize: "11px",
                        }}
                    >
                        MOP Approved Activity End
                    </th>
                    <td>
                        {
                            from == "ran" ? <span className="ng-binding">{siteDetail.site.RF_Approved_EndTime_Time}</span> : <span className="ng-binding">{siteDetail.mopEndTime}</span>
                        }
                        {/* <span className="ng-binding">{siteDetail.site.RF_Approved_EndTime_Time}</span> */}
                    </td>
                </tr>
                <tr>
                    <th
                        style={{
                            width: "230px",
                            fontWeight: "400",
                            padding: "5px",
                            color: "white",
                            fontSize: "11px",
                        }}
                    >
                        Logout Time Postcheck Request
                    </th>
                    <td
                        style={{
                            backgroundColor: from === "ran" ? siteDetail.postCheckDelivered ? colorCondition(siteDetail.site.RF_Approved_EndTime_Time, siteDetail.postCheckDelivered) : "" : siteDetail.ran.postCheckRequest ? colorCondition(siteDetail.mopEndTime, siteDetail.ran.postCheckRequest) : ""
                        }}
                    >
                        {
                            from === "ran" ? <span className="ng-binding">{siteDetail.postCheckDelivered}</span> : <span className="ng-binding">{siteDetail.ran.postCheckRequest}</span>
                        }
                    </td>
                </tr>


                <br />

                {/* {
siteDowntimeDetails?.siteData && siteDowntimeDetails?.siteData.length > 0 && <>
<tr>
<th className="thSiteDown1" colSpan="2" style={{ backgroundColor: "#143b64", color: "white", fontWeight: "bold" }}>
SITE DOWN
</th>
<th className="thSiteDown2" colSpan="2" style={{ backgroundColor: "#d86dcd !important", color: "black" }}>
Down Time in CST
</th>
<th className="thSiteDown3" colSpan="2" style={{ backgroundColor: "#d86dcd", color: "black" }}>
Up Time In CST
</th>
<th className="thSiteDown4" colSpan="2" style={{ backgroundColor: "#d86dcd", color: "black" }}>
Total Outage in HH:MM
</th>
</tr>
{(() => {
let totalSeconds = 0; // Initialize total outage duration in seconds

const mergedSiteDown = {};
siteDowntimeDetails?.siteData.forEach((ireq) => {
if (!mergedSiteDown.hasOwnProperty(ireq["Name"])) {
mergedSiteDown[ireq["Name"]] = [];
}
mergedSiteDown[ireq["Name"]].push(ireq);

// Calculate duration of each outage and add to total
totalSeconds += durationToSeconds(ireq["Total Outage in HH:MM"]);
});

// Calculate total duration in HH:MM format
const totalDuration = secondsToDuration(totalSeconds);

return (
<tr>
<td colSpan="2" style={{ backgroundColor: "#C6C6C6", color: "black" }}>
{Object.keys(mergedSiteDown).map((key, index) => (
<React.Fragment key={index}>
{key}<br />
</React.Fragment>
))}
</td>
<td colSpan="2" style={{ backgroundColor: "#C1F0C8", color: "black" }}>
{Object.keys(mergedSiteDown).map((key) => mergedSiteDown[key].map((ireq) => <span>{ireq["Down Time in CST"]}<br /></span>))}
</td>
<td colSpan="2" style={{ backgroundColor: "#C1F0C8", color: "black" }}>
{Object.keys(mergedSiteDown).map((key) => mergedSiteDown[key].map((ireq) => <span>{ireq["Up Time in CST"]}<br /></span>))}
</td>
<td colSpan="2" style={{ backgroundColor: "#C1F0C8", color: "black" }}>
{totalDuration}
</td>
</tr>
);
})()}
</>
} */}
                {/* {siteDowntimeDetails?.siteData && siteDowntimeDetails?.siteData.length > 0 && (
<>
<tr>
<th className="thSiteDown1" colSpan="2" style={{ backgroundColor: "#143b64", color: "white", fontWeight: "bold" }}>
SITE DOWN
</th>
<th className="thSiteDown2" colSpan="2" style={{ backgroundColor: "#d86dcd !important", color: "black" }}>
Down Time in CST
</th>
<th className="thSiteDown3" colSpan="2" style={{ backgroundColor: "#d86dcd", color: "black" }}>
Up Time In CST
</th>
<th className="thSiteDown4" colSpan="2" style={{ backgroundColor: "#d86dcd", color: "black" }}>
Total Outage in HH:MM
</th>
</tr>
{(() => {
const mergedSiteDown = {};
siteDowntimeDetails?.siteData.forEach((ireq) => {
if (!mergedSiteDown.hasOwnProperty(ireq["Name"])) {
mergedSiteDown[ireq["Name"]] = { data: [], totalSeconds: 0 };
}
mergedSiteDown[ireq["Name"]].data.push(ireq);
mergedSiteDown[ireq["Name"]].totalSeconds += durationToSeconds(ireq["Total Outage in HH:MM"]);
});

return (


// first Table
<>
{Object.keys(mergedSiteDown).map((key, index) => {
const siteData = mergedSiteDown[key].data;
const totalDuration = secondsToDuration(mergedSiteDown[key].totalSeconds);

const downTimes = siteData.map(ireq => ireq["Down Time in CST"]).join('<br />');
const upTimes = siteData.map(ireq => {
const RFStartString = siteDetail?.site?.RF_Approved_EndTime_Time;
const upTimeCST = ireq["Up Time in CST"];
let serviceImpact = ireq["Service Impact"]

const color = compareDates(RFStartString, upTimeCST, serviceImpact);
return <p className={color} style={{ background: color }}>{upTimeCST}</p>
});

return (
<tr key={index} style={{ borderBottom: "1px solid #000" }}>
<td colSpan="2" style={{ backgroundColor: "#C6C6C6", color: "black" }}>
{key}
</td>
<td colSpan="2" style={{ backgroundColor: "#C1F0C8", color: "black" }} dangerouslySetInnerHTML={{ __html: downTimes }}>
</td>
<td colSpan="2" style={{ color: "black" }}>
{upTimes}
</td>
<td colSpan="2" style={{ backgroundColor: "#C1F0C8", color: "black" }}>
{totalDuration}
</td>
</tr>
);
})}
</>
);
})()}
</>
)} */}

{siteDowntimeDetails?.siteData && siteDowntimeDetails?.siteData.length > 0 && (
    <>
        <tr>
            <th className="thSiteDown1" colSpan="2" style={{ backgroundColor: "#143b64", color: "white", fontWeight: "bold" }}>
                SITE DOWN
            </th>
            <th className="thSiteDown2" colSpan="2" style={{ backgroundColor: "#d86dcd !important", color: "black" }}>
                Down Time in CST
            </th>
            <th className="thSiteDown3" colSpan="2" style={{ backgroundColor: "#d86dcd", color: "black" }}>
                Up Time In CST
            </th>
            <th className="thSiteDown4" colSpan="2" style={{ backgroundColor: "#d86dcd", color: "black" }}>
                Total Outage in HH:MM:SS
            </th>
        </tr>
        {(() => {
            const mergedSiteDown = {};
            siteDowntimeDetails?.siteData.forEach((ireq) => {
                if (!mergedSiteDown.hasOwnProperty(ireq["Name"])) {
                    mergedSiteDown[ireq["Name"]] = { data: [], totalSeconds: 0 };
                }
                mergedSiteDown[ireq["Name"]].data.push(ireq);
                mergedSiteDown[ireq["Name"]].totalSeconds += durationToSeconds(ireq["Total Outage in HH:MM"]);
            });

            return (
                <>
                    {Object.keys(mergedSiteDown).map((key, index) => {
                        const siteData = mergedSiteDown[key].data;
                        const totalDuration = secondsToDuration(mergedSiteDown[key].totalSeconds);

                        // Combine all down times and up times for the current site
                        const downTimes = siteData.map(ireq => ireq["Down Time in CST"]).join('<br />');
                        const upTimes = siteData.map(ireq => {
                            const RFStartString = siteDetail?.site?.RF_Approved_EndTime_Time;
                            const upTimeCST = ireq["Up Time in CST"];
                            let serviceImpact = ireq["Service Impact"];

                            const color = compareDates(RFStartString, upTimeCST, serviceImpact);
                            return `<p class="${color}" style="background: ${color}">${upTimeCST}</p>`;
                        }).join('');

                        const rowBgColor = siteData.length > 1 ? '#ffc000' : '';

                        return (
                            <tr key={index} style={{ borderBottom: "1px solid #000", backgroundColor: rowBgColor }}>
                                <td colSpan="2" style={{ backgroundColor: "#C6C6C6", color: "black" }}>
                                    {key}
                                </td>
                                <td colSpan="2" style={{ backgroundColor: siteData.length > 1 ? "#ffc000" : "#C1F0C8", color: "black" }} dangerouslySetInnerHTML={{ __html: downTimes }}>
                                </td>
                                <td colSpan="2" style={{ backgroundColor: siteData.length > 1 ? "#ffc000" : "#C1F0C8", color: "black" }} dangerouslySetInnerHTML={{ __html: upTimes }}>
                                </td>
                                <td colSpan="2" style={{ backgroundColor: siteData.length > 1 ? "#ffc000" : "#C1F0C8", color: "black" }}>
                                    {totalDuration}
                                </td>
                            </tr>
                        );
                    })}
                </>
            );
        })()}
    </>
)}





                {/* new section */}
                {/* <br /> */}
                {/* <tr>
<th
rowSpan="2"
style={{
width: "230px",
fontWeight: "400",
padding: "5px",
color: "white",
fontSize: "11px",
}}
>
Bands/Sectors
</th>
<th
colSpan="2"
style={{
width: "230px",
fontWeight: "400",
padding: "5px",
color: "white",
fontSize: "11px",
backgroundColor:"#FF33FC !important"
}}
>
Alpha
</th>
<th
colSpan="2"
style={{
width: "230px",
fontWeight: "400",
padding: "5px",
color: "white",
fontSize: "11px",
}}
>
Total Outage in HH:MM
</th>
<th
colSpan="2"
style={{
width: "230px",
fontWeight: "400",
padding: "5px",
color: "white",
fontSize: "11px",
}}
>
Beta
</th>
<th
colSpan="2"
style={{
width: "230px",
fontWeight: "400",
padding: "5px",
color: "white",
fontSize: "11px",
}}
>
Outage in mins
</th>
<th
colSpan="2"
style={{
width: "230px",
fontWeight: "400",
padding: "5px",
color: "white",
fontSize: "11px",
}}
>
Gamma
</th>
<th
colSpan="2"
style={{
width: "230px",
fontWeight: "400",
padding: "5px",
color: "white",
fontSize: "11px",
}}
>
Total Outage in HH:MM
</th>
</tr>
<tr>
<td colSpan="2">00:00</td>
<td colSpan="2">00:00</td>
<td colSpan="2">00:00</td>
<td colSpan="2">00:00</td>
<td colSpan="2">00:00</td>
<td colSpan="2">00:00</td>
</tr>
{/* new section */}
                {/* <tr>
<th
rowSpan="2"
style={{
width: "230px",
fontWeight: "400",
padding: "5px",
color: "white",
fontSize: "11px",
}}
>
MOP APPROVED Downtime
</th>
</tr> */}


                {/* new with real data */}


            </tbody>
        </table>
        <br />
        {console.log(siteDowntimeDetails.tableData, 'siteDowntimeDetails')}


        {/* itwqd */}


        {
            (siteDetail.ran && siteDetail.ran.postCheckRequest) || (siteDetail.postCheckDelivered) ? <div>
                {

                    siteDowntimeDetails.tableData && [["Alpha", "Beta", "Gamma"], ["Delta", "Epsilon", "Zeta"]].map((itm, indexes) => {
                        itwqd = itwqd + 1
                        console.log(itm, "siteDowntimeDetails")
                        return <><table className="tableDowntime" style={{ width: "100%", color: "black", fontSize: "12px" }} border={1}>
                            <tr className="tableDowntime">
                                <td className="tdDowntime" style={{ backgroundColor: "#47d359" }} rowSpan={2}>
                                    Bands / Sectors
                                </td>
                                <td className="tdDowntime" style={{ backgroundColor: "#d86dcd" }} colSpan={2}>
                                    {itm[0]}
                                </td>
                                <td className="tdDowntime" style={{ backgroundColor: "#d86dcd" }}>
                                    Total Outage in HH:MM:SS
                                </td>
                                <td style={{ backgroundColor: "#d86dcd" }} colSpan={2}>
                                    {itm[1]}
                                </td>
                                <td className="tdDowntime" style={{ "text-wrap": "no-wrap", backgroundColor: "#d86dcd" }}>
                                    Total Outage in HH:MM:SS
                                </td>
                                <td className="tdDowntime" style={{ backgroundColor: "#d86dcd" }} colSpan={2}>
                                    {itm[2]}
                                </td>
                                <td className="tdDowntime" style={{ backgroundColor: "#d86dcd" }}>
                                    Total Outage in HH:MM:SS
                                </td>
                            </tr>
                            <tr>
                                <td className="tdDowntime">
                                    Down Time in CST
                                </td>
                                <td className="tdDowntime">
                                    Up Time in CST
                                </td>
                                <td className="tdDowntime">
                                </td>
                                <td className="tdDowntime">
                                    Down Time in CST
                                </td>
                                <td className="tdDowntime">
                                    Up Time in CST
                                </td>
                                <td className="tdDowntime">
                                </td>
                                <td className="tdDowntime">
                                    Down Time in CST
                                </td>
                                <td className="tdDowntime">
                                    Up Time in CST
                                </td>
                                <td>
                                </td>
                            </tr>
                            {siteDowntimeDetails.tableData[0].data.map((its, index) => {
                                return <>
                                    <tr>
                                        {/* <td>{itwqd*3}itwqd</td>
<td>{itwqd*3+1}itwqd</td>
<td>{itwqd*3+2}itwqd</td> */}

                                        {/* table 2 part 1 */}
                                        <td style={{ backgroundColor: getBackgroundColor(its["band"]) }}>{its["band"]}</td>
                                        {/* <td>{
siteDowntimeDetails.tableData[0]["data"].sort(function(a, b) {return a.bandsorter - b.bandsorter})[index]["band"]}
</td> */}
                                        <td style={{ backgroundColor: siteDowntimeDetails.tableData[itwqd * 3 ]["data"].sort(function (a, b) { return a.bandsorter - b.bandsorter })[index]["result"].length > 1 ? "#ffc000" : "#c1f0c8" }}>{
                                            siteDowntimeDetails?.tableData[itwqd * 3]["data"]?.sort(function (a, b) { return a.bandsorter - b.bandsorter })[index]["result"].map((ireq) => {
                                                return <>{ireq["Down Time in CST"]}<br /></>
                                            })}
                                        </td>
                                        <td style={{ backgroundColor: siteDowntimeDetails.tableData[itwqd * 3 ]["data"].sort(function (a, b) { return a.bandsorter - b.bandsorter })[index]["result"].length > 1 ? "#ffc000" : "#c1f0c8" }}>{
                                            siteDowntimeDetails.tableData[itwqd * 3]["data"].sort(function (a, b) { return a.bandsorter - b.bandsorter })[index]["result"].map((ireq) => {

                                                let singleTime;
                                                // console.log(siteDowntimeDetails.tableData[itwqd * 3 ]["data"].sort(function (a, b) { return a.bandsorter - b.bandsorter })[index]["result"].length,"zxcvdwdcw")
                                                if (siteDowntimeDetails.tableData[itwqd * 3 ]["data"].sort(function (a, b) { return a.bandsorter - b.bandsorter })[index]["result"].length > 1) {
                                                    singleTime = "newColor"
                                                }
                                                else {
                                                    singleTime = "oldColor"
                                                }

                                                console.log(singleTime, "sdksndfjksnkjnk")



                                                const RFStartString = siteDetail?.site?.RF_Approved_EndTime_Time;
                                                const upTimeCST = ireq["Up Time in CST"];
                                                let serviceImpact = ireq["Service Impact"]
                                                const color = compareDates(RFStartString, upTimeCST, serviceImpact);
                                                return <><p className={color} style={{ background: singleTime === 'oldColor' && color !== 'red' ? '#C1F0C8' : color }}>{upTimeCST}</p></>
                                            })}
                                        </td>




                                        {/* <td style={{backgroundColor:siteDowntimeDetails.tableData[itwqd*3+1]["data"].sort(function(a, b) {return a.bandsorter - b.bandsorter})[index]["result"].length>1?"#ffc000":"#c1f0c8"}}>{
siteDowntimeDetails.tableData[itwqd*3]["data"].sort(function(a, b) {return a.bandsorter - b.bandsorter})[index]["result"].map((ireq)=>{
return <>{ireq["Total Outage in HH:MM"]}<br/></>
})}
</td> */}
                                        <td style={{
                                            backgroundColor:
                                                siteDowntimeDetails.tableData[itwqd * 3 ]["data"]
                                                    .sort((a, b) => a.bandsorter - b.bandsorter)[index]["result"].length > 1
                                                    ? "#ffc000"
                                                    : "#c1f0c8"
                                        }}>
                                            {
                                                (() => {
                                                    const resultArray = siteDowntimeDetails.tableData[itwqd * 3]["data"]
                                                        .sort((a, b) => a.bandsorter - b.bandsorter)[index]["result"];

                                                    const totalSeconds = resultArray.reduce((total, ireq) => {
                                                        return total + durationToSeconds(ireq["Total Outage in HH:MM"]);
                                                    }, 0);

                                                    const totalDuration = secondsToDuration(totalSeconds);
                                                    if (totalDuration === "0:00:00") {
                                                        return "";
                                                    }

                                                    return totalDuration;
                                                })()
                                            }
                                        </td>

                                        {/* part 2 of table 2 */}

                                        <td style={{ backgroundColor: siteDowntimeDetails.tableData[itwqd * 3 + 1]["data"].sort(function (a, b) { return a.bandsorter - b.bandsorter })[index]["result"].length > 1 ? "#ffc000" : "#c1f0c8" }}>{
                                            siteDowntimeDetails.tableData[itwqd * 3 + 1]["data"].sort(function (a, b) { return a.bandsorter - b.bandsorter })[index]["result"].map((ireq) => {
                                                return <>{ireq["Down Time in CST"]}<br /></>
                                            })}
                                        </td>
                                        <td style={{ backgroundColor: siteDowntimeDetails.tableData[itwqd * 3 + 1]["data"].sort(function (a, b) { return a.bandsorter - b.bandsorter })[index]["result"].length > 1 ? "#ffc000" : "#c1f0c8" }}>{
                                            siteDowntimeDetails.tableData[itwqd * 3 + 1]["data"].sort(function (a, b) { return a.bandsorter - b.bandsorter })[index]["result"].map((ireq) => {
                                                let singleTime;

                                                if (siteDowntimeDetails.tableData[itwqd * 3 + 1]["data"].sort(function (a, b) { return a.bandsorter - b.bandsorter })[index]["result"].length > 1) {
                                                    singleTime = "newColor"
                                                }
                                                else {
                                                    singleTime = "oldColor"
                                                }



                                                const RFStartString = siteDetail?.site?.RF_Approved_EndTime_Time;
                                                const upTimeCST = ireq["Up Time in CST"];
                                                let serviceImpact = ireq["Service Impact"]
                                                const color = compareDates(RFStartString, upTimeCST, serviceImpact);
                                                return <><p className={color} style={{ background: singleTime === 'oldColor' && color !== 'red' ? '#C1F0C8' : color }}>{upTimeCST}</p></>
                                            })}
                                        </td>


                                        {/* <td style={{backgroundColor:siteDowntimeDetails.tableData[itwqd*3+1]["data"].sort(function(a, b) {return a.bandsorter - b.bandsorter})[index]["result"].length>1?"#ffc000":"#c1f0c8"}}>{
siteDowntimeDetails.tableData[itwqd*3+1]["data"].sort(function(a, b) {return a.bandsorter - b.bandsorter})[index]["result"].map((ireq)=>{
return <>{ireq["Total Outage in HH:MM"]}<br/></>
})}
</td> */}
                                        <td style={{
                                            backgroundColor:
                                                siteDowntimeDetails.tableData[itwqd * 3 + 1]["data"]
                                                    .sort((a, b) => a.bandsorter - b.bandsorter)[index]["result"].length > 1
                                                    ? "#ffc000"
                                                    : "#c1f0c8"
                                        }}>
                                            {
                                                (() => {
                                                    const resultArray = siteDowntimeDetails.tableData[itwqd * 3 + 1]["data"]
                                                        .sort((a, b) => a.bandsorter - b.bandsorter)[index]["result"];

                                                    const totalSeconds = resultArray.reduce((total, ireq) => {
                                                        return total + durationToSeconds(ireq["Total Outage in HH:MM"]);
                                                    }, 0);

                                                    // Convert total seconds to duration string
                                                    const totalDuration = secondsToDuration(totalSeconds);
                                                    if (totalDuration === "0:00:00") {
                                                        return "";
                                                    }

                                                    return totalDuration;
                                                })()
                                            }
                                        </td>


                                        {/* part 3 table 2 */}
                                        <td style={{ backgroundColor: siteDowntimeDetails.tableData[itwqd * 3 + 2]["data"].sort(function (a, b) { return a.bandsorter - b.bandsorter })[index]["result"].length > 1 ? "#ffc000" : "#c1f0c8" }}>{
                                            siteDowntimeDetails.tableData[itwqd * 3 + 2]["data"].sort(function (a, b) { return a.bandsorter - b.bandsorter })[index]["result"].map((ireq) => {
                                                return <>{ireq["Down Time in CST"]}<br /></>
                                            })}
                                        </td>

                                        <td style={{ backgroundColor: siteDowntimeDetails.tableData[itwqd * 3 + 2]["data"].sort(function (a, b) { return a.bandsorter - b.bandsorter })[index]["result"].length > 1 ? "#ffc000" : "#c1f0c8" }}>{
                                            siteDowntimeDetails.tableData[itwqd * 3 + 2]["data"].sort(function (a, b) { return a.bandsorter - b.bandsorter })[index]["result"].map((ireq) => {
                                                let singleTime;

                                                if (siteDowntimeDetails.tableData[itwqd * 3 + 2]["data"].sort(function (a, b) { return a.bandsorter - b.bandsorter })[index]["result"].length > 1) {
                                                    singleTime = "newColor"
                                                }
                                                else {
                                                    singleTime = "oldColor"
                                                }



                                                const RFStartString = siteDetail?.site?.RF_Approved_EndTime_Time;
                                                const upTimeCST = ireq["Up Time in CST"];
                                                let serviceImpact = ireq["Service Impact"]
                                                const color = compareDates(RFStartString, upTimeCST, serviceImpact);
                                                return <><p className={color} style={{ background: singleTime === 'oldColor' && color !== 'red' ? '#C1F0C8' : color }}>{upTimeCST}</p></>
                                            })}
                                        </td>
                                        {/* <td style={{backgroundColor:siteDowntimeDetails.tableData[itwqd*3+2]["data"].sort(function(a, b) {return a.bandsorter - b.bandsorter})[index]["result"].length>1?"#ffc000":"#c1f0c8"}}>{
siteDowntimeDetails.tableData[itwqd*3+2]["data"].sort(function(a, b) {return a.bandsorter - b.bandsorter})[index]["result"].map((ireq)=>{
{console.log( ireq["Total Outage in HH:MM"] ,'finaltest')}

return <>{ireq["Total Outage in HH:MM"]}<br/></>
})}
</td> */}
                                        <td style={{
                                            backgroundColor:
                                                siteDowntimeDetails.tableData[itwqd * 3 + 2]["data"]
                                                    .sort((a, b) => a.bandsorter - b.bandsorter)[index]["result"].length > 1
                                                    ? "#ffc000"
                                                    : "#c1f0c8"
                                        }}>
                                            {
                                                (() => {
                                                    const resultArray = siteDowntimeDetails.tableData[itwqd * 3 + 2]["data"]
                                                        .sort((a, b) => a.bandsorter - b.bandsorter)[index]["result"];


                                                    // Calculate total seconds
                                                    const totalSeconds = resultArray.reduce((total, ireq) => {
                                                        return total + durationToSeconds(ireq["Total Outage in HH:MM"]);
                                                    }, 0);

                                                    // Convert total seconds to duration string
                                                    const totalDuration = secondsToDuration(totalSeconds);
                                                    if (totalDuration === "0:00:00") {
                                                        return "";
                                                    }

                                                    return totalDuration;
                                                })()
                                            }
                                        </td>

                                    </tr>
                                </>
                            })

                            }
                        </table>

                            <br />
                            <br />

                        </>

                    })
                }

            </div>
                :
                <> <h1>Activity Not Started</h1>
                </>
        }
    </>
}

export default DowntimeList;