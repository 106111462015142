import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { FetchSingleRecordAction, TierListReducerAction, addUserAction } from "../../../../store/actions/createTicketManageFields";
import { SSVDashboardAPI, managEngineer } from "../../../../common/config";
const SSVDrive = (props) => {
  const dispatch = useDispatch()
  const styles = {
    width: "400px",
  };
  let engineerArray = []
  let count = useRef(1)
  let [driverDate, setdriverDate] = useState("");
  let [driveRouteSentDate, setdriveRouteSentDate] = useState("");
  
  const [EngineerOpen, setEngineerOpen] = useState([]);
  const {
    register,
    setValue,
    handleSubmit,
    setError,
    getError,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
  });
  let option = useSelector((state) => {
    let fetchData = state.OneCommonResponse.singleIXSite
    console.log("kbhjb sdfcsdfedfe=", fetchData)
    if (fetchData && Object.keys(fetchData).length > 0) {
      if (count.current == 1) {
        if (fetchData.driveRouteSentDate) {
          setdriveRouteSentDate(new Date(fetchData.driveRouteSentDate))
        }
        if (fetchData.driveDate) {
          setdriverDate(new Date(fetchData.driveDate))
        }
        setValue("driverStatus", fetchData.driverStatus,{shouldTouch:true,shouldValidate:true})
        setValue("driverTestPerson", fetchData.driverTestPerson,{shouldTouch:true,shouldValidate:true})
        setValue("driveRouteSiteDetailsStatus", fetchData.driveRouteSiteDetailsStatus,{shouldTouch:true,shouldValidate:true})
        count.current = 2
      }
    }
    let tier = state.CommonResponse.listtier
    if (tier && tier.length != 0) {
      engineerArray = tier.map((item) => {
        return <option>{item.name}</option>;
      })
    }
    return { engineerArray,tier }
  })

  console.log(errors,"errorserrors")

  const findengineer = (id) => {
    console.log("findengineer",option.tier,id,option.tier.findIndex(finndName=>finndName.name==id))
    console.log("findengineer",getError)
    // // delete errors.driverTestPerson
    if(option.tier.findIndex(finndName=>finndName.name==id)!=-1){
      // setError(driverTestPerson)
      setValue("driverTestPerson",id)
    }else{
      setError(true)
      setValue("driverTestPerson","")
      // setValue("driverTestPerson",id)
    }
  }

  const onSubmit = (data) => {
    data["driveDate"] = moment(driverDate).format("MM/DD/YYYY");
    data["driveRouteSentDate"] = moment(driveRouteSentDate).format("MM/DD/YYYY");
    console.log("the data is =", data);
    dispatch(addUserAction(SSVDashboardAPI + "/" + props.id, data))
  };
  useEffect(() => {
    // dispatch(TierListReducerAction(managEngineer))
    
    dispatch(TierListReducerAction(managEngineer))
    dispatch(FetchSingleRecordAction(SSVDashboardAPI, props.id))
  }, [])
  return (
    <form className="form-row" onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group col-6">
        <label>Drive Date</label>
        <DatePicker
          style={styles}
          className="form-control"
          selected={driverDate}
          onChange={(e) => {
            setdriverDate(e);
          }}
          popperPlacement="bottom"
          dateFormat="MM/dd/yyyy"
        />
        {/* <input className='form-group' type='date'
            {
                ...register("driverDate")
            } /> */}
      </div>
      <div className="form-group col-6">
        <label>Drive Status</label>
        <select className='form-control' {...register("driverStatus")} >
          <option value="Completed">Completed</option>
          <option value="Pending">Pending</option>
          <option value="Not Ready">Not Ready</option>
        </select>
        {/* // <input className="form-group" {...register("driverStatus")} /> */}
      </div>
      <div className="form-group col-6">
        <label>Drive Test Person</label>
        {/* <input className="form-group" {...register("driverTestPerson")} /> */}
        <input style={{ height: "32px" }}
          type="text"
          {...register("driverTestPerson", {
            required: "Please select Drive Test Person"
          })}
          onBlur={(e) => { findengineer(e.target.value) }}
          list="engOpen"
          className="form-control"
          ID="inputAddress"
          // defaultValue={EngineerOpen}
          placeholder="Select Engineer"
        />

        <datalist id="engOpen">
          {option.engineerArray}
        </datalist>
        {errors.driverTestPerson && (
          <p className="error">
            {errors.driverTestPerson.message}
          </p>
        )}
      </div>
      <div className="form-group col-6">
        <label>Drive route & site details status</label>

        <select className='form-control' {...register("driveRouteSiteDetailsStatus")} >
          <option value="Completed">Completed</option>
          <option value="Pending">Pending</option>
          {/* <option value="Not Ready">Not Ready</option> */}
        </select>
        {/* <input
          type="text"
          className="form-group"
          {...register("driveRouteSiteDetailsStatus")}
        ></input> */}
        {/* <select className='form-group'
            {
                ...register("ssvStatus")
            } >
            <option selected disabled value={''}>Select</option>
            <option value={"Pending"}>Pending</option>
            <option value={"Submitted"}>Submitted</option>
            <option value={"Approved"}>Approved</option>
            </select> */}
      </div>
      <div className="form-group col-6">
        <label>Drive route Sent Date</label>
        <DatePicker
          style={styles}
          className="form-control"
          selected={driveRouteSentDate}
          onChange={(e) => {
            setdriveRouteSentDate(e);
          }}
          popperPlacement="bottom"
          dateFormat="MM/dd/yyyy"
        />
        {/* <input className='form-group' type='date'
            {
                ...register("driveRouteSentDate")
            }
            ></input> */}
      </div>

      <div className="d-flex justify-content-center col-md-12">
        <button className="btn btn-primary btn-xs py-2 px-4 mt-2">
          Submit
        </button>
      </div>
    </form>
  );
};

export default SSVDrive;
