import React, { useRef, useState } from 'react'
import MUIDataTable from "mui-datatables";
import { useEffect } from 'react';
import { Row, Card, Col, Dropdown, Button, Modal, Container } from "react-bootstrap";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { vendorApi, addVendor, deleteVendor, getMarket, accountApi } from '../../../../common/config';
import { AccountReducerAction, GetMarketReducerAction, OneAccountReducerAction, addAcccountReducerAction, deleteAcccountReducerAction, popUpUpdate, updateAcccountReducerAction } from '../../../../store/actions/createTicketManageFields';
import { useDispatch, useSelector } from 'react-redux';
import { google_map_api, center, dbdatetodate, customDataTableOptionObject, advancdcustomDataTableOptionObject, advancdExportOnecustomDataTableOptionObject, getMuiThemeCommon } from "../../../../common/variables";
import CommonBulkUploader from '../../Custom/CommonBulkUploader';
import swal from 'sweetalert';
import { useForm } from 'react-hook-form';
const ManageVendor = () => {
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  let objectId = ''
  const [modalCentered, setModalCentered] = useState(false);
  const [closer, setCloser] = useState(false);
  // const [dValue, setDvalue] = useState('')
  const [rowId, setRowId] = useState('')
  const [erro, setErrors] = useState({});
  const [checkAddUpdate, setAddUpdate] = useState(true)
  //  const [newAccount,setAccount]=useState('')
  const newVendor = useRef()
  const { register, setValue, handleSubmit, reset, formState: { errors } } = useForm({});

  let oneaccount = ""




  // setAccount(oneaccount)
  console.log(errors, "errors")
  const getMuiTheme = () => createTheme({
    components: {
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            backgroundColor: "transparent"
          }
        }
      }
    }
  })


  const setDvalue = (e) => {
    console.log(e, "setDvalue")
    setValue("vendorName", e.vendorName)
    setValue("accountId", e.accountId, { shouldTouch: true, shouldDirty: true })
    setValue("marketId", e.marketId, { shouldTouch: true, shouldDirty: true })


  }

  const updateHandler = (e) => {
    console.log("button update clicked", e)

    dispatch(updateAcccountReducerAction(addVendor, e, { vendorName: newVendor }))

  }
  const deleteHandler = (e) => {
    console.log("button delete clicked", e)
    swal({
      title: "Are you sure?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(deleteAcccountReducerAction(deleteVendor, e))
        setCloser(true)
      }
    });

  }


  const editpopupHandler = (e) => {
    console.log("lkm kljnkjnjkbi;ki=", e)
    console.log("button editpopupHandler clicked", e)
    // dispatch(OneAccountReducerAction(accountApi+"/"+e))
    setModalCentered(true)
    // dispatch(deleteAcccountReducerAction(deleteAcccount,e))




  }
  const updateAccount = () => {
    console.log("object id =", rowId)

    if (newVendor.current.value == "") {
      setErrors({ "vendor": "Vendor is Manodatry" })
      return false
    }
    dispatch(updateAcccountReducerAction(addVendor, rowId, { vendorName: newVendor.current.value }))

    setCloser(true)
  }
  // const addAccount = () => {

  //   console.log("Add Account=", newVendor)
  //   // newAccount.current.value=''

  //   if (newVendor.current.value == "") {
  //     setErrors({ "vendor": "Vendor is Manodatry" })
  //     return false
  //   }
  //   dispatch(addAcccountReducerAction(addVendor, { vendorName: newVendor.current.value }))
  //   // setModalCentered(false)
  //   newVendor.current.value = ''

  //   console.log("input field=", newVendor)
  //   // dispatch(AccountReducerAction(vendorApi))
  //   setCloser(true)

  // }
  const dispatch = useDispatch();
  let accountArry = []


  // let datava =useSelector((state)=>{

  // });

  let data = useSelector((state) => {
    console.log("dfwef=", state)


    if (state.popUpState) {
      console.log(state.popUpState.popstatus.status, closer, "popUpStatestatus")
      if ((state.popUpState.popstatus.status == 201 && closer) || (state.popUpState.popstatus.status == 200 && closer)) {
        setCloser(false)
        dispatch(popUpUpdate({}))
        dispatch(AccountReducerAction(vendorApi))
        setModalCentered(false)

      }
      console.log(state.popUpState.popstatus.status == 409, closer, "state.popUpState.popstatus.status == 409 && closer")
      if (state.popUpState.popstatus.status == 409) {
        setCloser(false)
        dispatch(popUpUpdate({}))
        dispatch(AccountReducerAction(vendorApi))
        // setModalCentered(false)

      }
    }
    let neeaccount = state.CommonResponse.accounts
    if (neeaccount.length != 0 && newVendor == "") {
      oneaccount = neeaccount[0].vendorName
    }

    let account = state.CommonResponse.vendor
    console.log("response =dscvfsfvd=", state.CommonResponse)
    if (account.length != 0) {
      accountArry = account.map((item, index) => {
        console.log(item.vendorName, "vendorName")
        return {
          sNo: index + 1,
          vendor: item.vendorName,
          marketName: item.marketName,
          accountName: item.accountName,
          edit: <><button className="btn btn-primary shadow btn-xs sharp mr-1 editButton" onClick={() => { editpopupHandler(item); setDvalue(item); setAddUpdate(false); setRowId(item.uniqueid) }}><i className="fa fa-pencil"></i></button>
            <button onClick={(e) => deleteHandler(item.uniqueid)} className="btn btn-danger shadow btn-xs sharp deleteButton"><i className="fa fa-trash"></i></button></>
          //   <Dropdown>

          //   <Dropdown.Toggle
          //     variant="success"
          //     className="light sharp i-false"
          //   >
          //     {svg1}
          //   </Dropdown.Toggle>
          //   <Dropdown.Menu>

          //     <Dropdown.Item onClick={() =>{ editpopupHandler(item); setDvalue(item.vendorName);setAddUpdate(false);setRowId(item.uniqueid)}}>Edit</Dropdown.Item>
          //     <Dropdown.Item onClick={(e)=>deleteHandler(item.uniqueid)}>Delete</Dropdown.Item>
          //   </Dropdown.Menu>
          // </Dropdown>
          // <button value={item.uniqueid} onClick={(e)=>updateHandler(e)}>Click me</button>

        }
      })
    }


    let accountlist = state.CommonResponse.accounts
    let accountlistArry = accountlist.map((item) => {
      return <option value={item.uniqueid}>{item.accountName}</option>
    })

    let marketlist = state.CommonResponse.markets
    let marketlistArry = marketlist.map((item) => {
      return <option value={item.uniqueid}>{item.marketName}</option>
    })

    console.log(accountArry)

    return { accountArry, accountlistArry, marketlistArry }
  })

  const noOfRow = data.accountArry.length
  // data.accountArry.push(
  //   {   
  //       sNo:noOfRow+1,
  //       account:<input type='text' placeholder='Add Account' value={newAccount} onChange={(e)=>setAccount(e.target.value)}/>,
  //       edit : <button onClick={()=>addAccount()}>Add Account</button>

  //   }
  // )

  console.log("data =", data)
  useEffect(() => {
    dispatch(AccountReducerAction(vendorApi))
    dispatch(GetMarketReducerAction(getMarket))
    dispatch(AccountReducerAction(accountApi))

  }, [newVendor])
  const columns = [
    {

      name: "sNo",
      label: "S.No.",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        setCellHeaderProps: () => ({
          style: {

            alignItem: "center",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      name: "vendor",
      label: "Vendor Name",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({
          style: {

            alignItem: "center",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      name: "accountName",
      label: "Account",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({
          style: {

            alignItem: "center",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      name: "marketName",
      label: "Market Name",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({
          style: {

            alignItem: "center",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      name: "edit",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        print: false,
        searchable: false,
        setCellHeaderProps: () => ({
          style: {

            alignItem: "center",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },

  ];

  let row = [];

  const handleChange = (e) => {
    console.log("the current anser= ", e.target.value)
  }


  const onSubmit = async (data) => {
    console.log(data, rowId, checkAddUpdate, "onSubmit")

    if (checkAddUpdate) {
      dispatch(addAcccountReducerAction(addVendor, data))
    } else {
      dispatch(updateAcccountReducerAction(addVendor, rowId, data))
    }
    dispatch(popUpUpdate({}))
    setCloser(true)
  }

  const AddCustomButton = () => (
    <fregment style={{ float: "right", display: "flex", flexDirection: row, justifyContent: "space-between" }}>
      <Button
        variant="primary btn-xs mb-2"
        type="button"
        className="btn btn-primary btn-xs mx-2 py-2 my-3"
        onClick={() => { setModalCentered(true); setDvalue(''); setErrors({}); setAddUpdate(true) }}>
        Add Vendor
      </Button>
      <CommonBulkUploader typeForm={"Vendor"} shortCode={"Vendor"} classes={"btn btn-primary btn-xs mx-2 py-2 my-3"} afterLoad={vendorApi} />

      <Modal className="fade" show={modalCentered}>
        <Modal.Header>
          {checkAddUpdate ? <Modal.Title>Add Vendor</Modal.Title> : <Modal.Title>Update Record</Modal.Title>}
          <Button
            onClick={() => setModalCentered(false)}
            variant=""
            className="close">
            <span>&times;</span>
          </Button>
        </Modal.Header>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div>
              <div className="accordion-body">
                <div className="form-row mt-1 p-1">
                  <div className="form-group col-md-12">
                    <label for="exampleFormControlSelect1">
                      Vendor Name
                    </label>
                    <input style={{ height: "40px" }}
                      type="text"
                      className="form-control"
                      id="input "
                      placeholder="Enter Name"

                      {
                      ...register("vendorName", { required: "Vendor Name is required" })
                      }
                    />
                    {errors.vendorName && (
                      <p className="error">
                        {errors.vendorName.message}
                      </p>
                    )}
                  </div>
                  <div className="form-group col-md-12">
                    <label for="exampleFormControlSelect1">
                      Account
                    </label>
                    <select style={{ height: "40px" }}
                      className="form-control"
                      id="input "
                      placeholder="Enter Account"
                      {
                      ...register("accountId", { required: "Account Name is required" })
                      }
                    >

                      <option value={""}>Select Account</option>

                      {data.accountlistArry}
                    </select>
                    {errors.accountId && (
                      <p className="error">
                        {errors.accountId.message}
                      </p>
                    )}
                  </div>
                  <div className="form-group col-md-12">
                    <label for="exampleFormControlSelect1">
                      Market
                    </label>
                    <select style={{ height: "40px" }}
                      className="form-control"
                      id="input "
                      placeholder="Enter Market"
                      {
                      ...register("marketId", { required: "Market Name is required" })
                      }
                    >

                      <option value={""}>Select Market</option>

                      {data.marketlistArry}
                    </select>
                    {errors.marketId && (
                      <p className="error">
                        {errors.marketId.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>



            {/* <input type='text' placeholder='Add Vendor' ref={newVendor} defaultValue={dValue} />

          {errors && errors.vendor ? <p className="error">{errors.vendor}</p> : ""}

          <input type='text' placeholder='Add Vendor' ref={newVendor} defaultValue={dValue} />

          {errors && errors.vendor ? <p className="error">{errors.vendor}</p> : ""}

          <input type='text' placeholder='Add Vendor' ref={newVendor} defaultValue={dValue} />

          {errors && errors.vendor ? <p className="error">{errors.vendor}</p> : ""} */}

          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => setModalCentered(false)}
              variant="btn btn-danger col-xs"
            >
              Close
            </Button>


            <button type='submit' className='btn btn-primary col-xs' > Submit</button>
            {/* <Button
            onClick={() => setModalCentered(false)}
            variant="btn btn-danger col-xs"
          >
            Close
          </Button>

          
          <button type='submit' className='btn btn-primary col-xs' > Submit</button> */}
            {/* {checkAddUpdate ? <Button onClick={() => { addAccount() }} variant="primary btn-xs">Save changes</Button>
            : <Button onClick={() => { updateAccount() }} variant="primary btn-xs">Update changes</Button>
          } */}
          </Modal.Footer>
        </form>
      </Modal>
    </fregment>
  )

  //    const data = [
  //     {name: "Joe James", company: "In Progress", city: "Yonkers", state: "NY" },
  //     {name: "John Walsh", company: "Completed", city: "Hartford", state: "CT" },
  //     {name: "Bob Herm", company: "Awaited", city: "Tampa", state: "FL" },
  //     {name: "James Houston", company: "NA", city: "Dallas", state: "TX" },
  //    ];

  const options = {
    download: "false",
    filterType: 'none',
    selectableRows: false
  };

  return (
    <>


      <ThemeProvider theme={getMuiThemeCommon()}>

        <MUIDataTable

          title={"Manage Vendor"}
          data={data.accountArry}
          columns={columns}
          // options={advancdcustomDataTableOptionObject(AddCustomButton,true,"vendors")}
          options={advancdExportOnecustomDataTableOptionObject(AddCustomButton, true, "Vendor_", "", false, "Vendor Name", false)}
        />
      </ThemeProvider>
    </>
  )
}

export default ManageVendor