import React from 'react'
import MUIDataTable from "mui-datatables";
import { useEffect } from 'react';

const CreateTickeFields = () => {



const columns = [
  {

   name: "name",
   label: "Name",
   options: {
    filter: true,
    sort: true,
   }
  },
  {
   name: "company",
   label: "Company",
   options: {
    filter: true,
    sort: false,
   }
  },
  {
   name: "city",
   label: "City",
   options: {
    filter: true,
    sort: false,
   }
  },
  {
   name: "state",
   label: "State",
   options: {
    filter: true,
    sort: false,
   }
  },
 ];
 
 const data = [
  { name: "Joe James", company: "In Progress", city: "Yonkers", state: "NY" },
  { name: "John Walsh", company: "Completed", city: "Hartford", state: "CT" },
  { name: "Bob Herm", company: "Awaited", city: "Tampa", state: "FL" },
  { name: "James Houston", company: "NA", city: "Dallas", state: "TX" },
 ];
 
 const options = {
   filterType: 'checkbox',
 };

  return (
    <>
  <nav>
    <div className="nav nav-tabs" id="nav-tab" role="tablist">
      <button
        className="nav-link active "
        id="nav-home-tab"
        data-bs-toggle="tab"
        data-bs-target="#nav-home"
        type="button"
        role="tab"
        aria-controls="nav-home"
        aria-selected="true"
        
      >
      <div style={{fontSize:"10px",fontWeight:"800"}}>Network Database</div>
      <div style={{fontSize:"10px",fontWeight:"800"}}>RND CIQ Status</div>
      </button>
      
      <button
        className="nav-link"
        id="nav-site-tab"
        data-bs-toggle="tab"
        data-bs-target="#nav-site"
        type="button"
        role="tab"
        aria-controls="nav-site"
        aria-selected="false"
      >
          <div style={{fontSize:"11px",fontWeight:"800"}}>Network DB</div>
      <div style={{fontSize:"11px",fontWeight:"800"}}>Site Type</div>
      </button>
      <button
        className="nav-link"
        id="nav-Status-tab"
        data-bs-toggle="tab"
        data-bs-target="#nav-Status"
        type="button"
        role="tab"
        aria-controls="nav-Status"
        aria-selected="false"
      >
          <div style={{fontSize:"11px",fontWeight:"800"}}>Network DB</div>
      <div style={{fontSize:"11px",fontWeight:"800"}}>Scirpting Status</div>
      </button>
      <button
        className="nav-link"
        id="nav-Types-tab"
        data-bs-toggle="tab"
        data-bs-target="#nav-Types"
        type="button"
        role="tab"
        aria-controls="nav-Types"
        aria-selected="false"
      >
          <div style={{fontSize:"11px",fontWeight:"800"}}>TroubleTickets</div>
      <div style={{fontSize:"11px",fontWeight:"800"}}>TT Types</div>
      </button>
      <button
        className="nav-link"
        id="nav-Details-tab"
        data-bs-toggle="tab"
        data-bs-target="#nav-Details"
        type="button"
        role="tab"
        aria-controls="nav-Details"
        aria-selected="false"
      >
        <div style={{fontSize:"11px",fontWeight:"800"}}>RAN- Dashboard</div>
      <div style={{fontSize:"11px",fontWeight:"800"}}>Sow Details</div>
      </button>
      <button
        className="nav-link"
        id="nav-free-tab"
        data-bs-toggle="tab"
        data-bs-target="#nav-free"
        type="button"
        role="tab"
        aria-controls="nav-free"
        aria-selected="false"
      >
        <div style={{fontSize:"11px",fontWeight:"800"}}>MobileApp</div>
      <div style={{fontSize:"11px",fontWeight:"800"}}>Cop free</div>
      </button>
      <button
        className="nav-link"
        id="nav-post-tab"
        data-bs-toggle="tab"
        data-bs-target="#nav-post"
        type="button"
        role="tab"
        aria-controls="nav-post"
        aria-selected="false"
      >
         <div style={{fontSize:"11px",fontWeight:"800"}}>MobileApp</div>
      <div style={{fontSize:"11px",fontWeight:"800"}}>Cop post</div>
      </button>
      <button
        className="nav-link"
        id="nav-Master-tab"
        data-bs-toggle="tab"
        data-bs-target="#nav-Master"
        type="button"
        role="tab"
        aria-controls="nav-Master"
        aria-selected="false"
      >
          <div style={{fontSize:"11px",fontWeight:"800"}}>Technology</div>
      <div style={{fontSize:"11px",fontWeight:"800"}}>Master</div>
      </button>
      
      
      <button
        className="nav-link"
        id="nav-contact-tab"
        data-bs-toggle="tab"
        data-bs-target="#nav-contact"
        type="button"
        role="tab"
        aria-controls="nav-contact"
        aria-selected="false"
      >
          <div style={{fontSize:"11px",fontWeight:"800"}}>Pre-Existing</div>
      <div style={{fontSize:"11px",fontWeight:"800"}}>Technology Master</div>
      </button>
    </div>
  </nav>
  <div className="tab-content mt-2" id="nav-tabContent">
    <div
      className="tab-pane fade show active"
      id="nav-home"
      role="tabpanel"
      aria-labelledby="nav-home-tab"
    >
      <MUIDataTable
  title={"Manage List"}
  data={data}
  columns={columns}
  options={options}
/>
    </div>
    <div
      className="tab-pane fade"
      id="nav-site"
      role="tabpanel"
      aria-labelledby="nav-site-tab"
    >
      <MUIDataTable
  title={"Manage List"}
  data={data}
  columns={columns}
  options={options}
/>
    </div>
    <div
      className="tab-pane fade"
      id="nav-Status"
      role="tabpanel"
      aria-labelledby="nav-Status-tab"
    >
      <MUIDataTable
  title={"Manage List"}
  data={data}
  columns={columns}
  options={options}
/>
    </div>  <div
      className="tab-pane fade"
      id="nav-Types"
      role="tabpanel"
      aria-labelledby="nav-Types-tab"
    >
      <MUIDataTable
  title={"Manage List"}
  data={data}
  columns={columns}
  options={options}
/>
    </div>  <div
      className="tab-pane fade"
      id="nav-Details"
      role="tabpanel"
      aria-labelledby="nav-Details-tab"
    >
      <MUIDataTable
  title={"Manage List"}
  data={data}
  columns={columns}
  options={options}
/>
    </div>  <div
      className="tab-pane fade"
      id="nav-free"
      role="tabpanel"
      aria-labelledby="nav-free-tab"
    >
      <MUIDataTable
  title={"Manage List"}
  data={data}
  columns={columns}
  options={options}
/>
    </div>  <div
      className="tab-pane fade"
      id="nav-post"
      role="tabpanel"
      aria-labelledby="nav-post-tab"
    >
      <MUIDataTable
  title={"Manage List"}
  data={data}
  columns={columns}
  options={options}
/>
    </div> 
     <div
      className="tab-pane fade"
      id="nav-Master"
      role="tabpanel"
      aria-labelledby="nav-Master-tab"
    >
      <MUIDataTable
  title={"Manage List"}
  data={data}
  columns={columns}
  options={options}
/>
    </div>
    <div
      className="tab-pane fade"
      id="nav-contact"
      role="tabpanel"
      aria-labelledby="nav-contact-tab"
    >
      <MUIDataTable
  title={"Manage List"}
  data={data}
  columns={columns}
  options={options}
/>
    </div>
  </div>
</>

  )
}

export default CreateTickeFields