import { createTheme } from "@mui/material";
import moment from "moment";
import { saveAs } from "file-saver";
import { write, utils } from "xlsx";
import { Workbook } from "exceljs";
import * as fs from "file-saver";
import { useEffect, useState } from "react";
import { useRef } from "react";
import { milliseconds } from "date-fns";
import { useDispatch } from "react-redux";
import { AccountReducerAction, LCLENOOReducerAction, LCLTTCOLReducerAction, LCLTTReducerAction, LCLranDashboardReducerAction, inflightGetCSVReducerAction, magentaGetCSVReducerAction, networkGetCSVReducerAction } from "../store/actions/createTicketManageFields";
import { dynamic_dashboard, listennoTicketApi, listtroubleTicketApi, projectListApi, ranDashboard, ranTracker } from "./config";
import { useHistory } from "react-router-dom";

export const google_map_api = "AIzaSyAH033cDjKN9V6cAm5tntpU9GtDCueH5ZE";
export let currentUrl = window.location.href;




export const center = {
  lat: 28.535517,
  lng: 77.391029,
};
const dateObject = new Date();
export const currentDate = `${dateObject.getMonth() + 1
  }/${dateObject.getDate()}/${dateObject.getFullYear()}`;

export let userDetails = JSON.parse(window.localStorage.getItem("userDetails"));

export const format24Hour = ({ dd, mm, yyyy, HH, MM, SS }) => {
  return `${mm}/${dd}/${yyyy} ${HH}:${MM}`;
};
export const wrapText = (text) => {
  return (text ? text.slice(0, 64) + "..." : "")
}
export const reformArray = (orignalArray) => {
  let newArray = { values: [] };
  if (orignalArray.length > 0) {
    newArray.values = orignalArray.map((item) => {
      return item.label;
    });
  }
  return newArray;
};

export let dateOnly=(date)=>{
  return date.slice(0,10)
}

export let manageDropdownColumn = (fieldName, fieldNamelabel) => {
  let columns = [
    {
      name: "sNo",
      label: "S.no",
      sortable: false,
      options: {
        filter: false,
        sort: false,
        searchable:false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            minWidth: "50px",
            maxWidth: "50px",
            width: "50px",
            background: "#143b64",
            color: "white",
            outline:"1.5px solid black",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            alignItem: "center",
            overflowY: "hidden",
            minWidth: "5px",
            outline:"1.5px solid black",

            background: "White",
          },
        }),
      },
    },
    {
      name: fieldName,
      label: fieldNamelabel,
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            minWidth: "150px",
            background: "#143b64",
            color: "white",
            outline:"1.5px solid black",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            alignItem: "center",
            overflowY: "hidden",
            minWidth: "5px",
            outline:"1.5px solid black",
            background: "White",
          },
        }),
      },
    },
    {
      name: "action",
      label: "Action",
      sortable: false,
      options: {
        filter: false,
        sort: false,
        print:false,
        searchable:false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            minWidth: "50px",
            maxWidth: "50px",
            width: "50px",
            background: "#143b64",
            color: "white",
            outline:"1.5px solid black",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            alignItem: "center",
            overflowY: "hidden",
            minWidth: "5px",
            outline:"1.5px solid black",

            background: "White",
          },
        }),
      },
    },
  ];

  console.log(columns,"columnscolumnscolumns")
  return columns;
};


export let multiplemanageDropdownColumn = (onearr) => {
  let columns = [
    {
      name: "sNo",
      label: "S.no",
      sortable: false,
      options: {
        filter: false,
        sort: false,
        searchable:false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            minWidth: "50px",
            maxWidth: "50px",
            width: "50px",
            background: "#143b64",
            color: "white",
            outline:"1.5px solid black",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            alignItem: "center",
            overflowY: "hidden",
            minWidth: "5px",
            outline:"1.5px solid black",

            background: "White",
          },
        }),
      },
    }]

    onearr.map((itm)=>{
      columns.push({
        name: itm[0],
        label: itm[1],
        options: {
          filter: true,
          sort: true,
          setCellHeaderProps: () => ({
            style: {
              whiteSpace: "wrap",
              minWidth: "150px",
              background: "#143b64",
              color: "white",
              outline:"1.5px solid black",
            },
          }),
          setCellProps: () => ({
            style: {
              whiteSpace: "nowrap",
              alignItem: "center",
              overflowY: "hidden",
              minWidth: "5px",
              outline:"1.5px solid black",
              background: "White",
            },
          }),
        },
      }) 
    })

    columns.push({
      name: "action",
      label: "Action",
      sortable: false,
      options: {
        filter: false,
        sort: false,
        print:false,
        searchable:false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            minWidth: "50px",
            maxWidth: "50px",
            width: "50px",
            background: "#143b64",
            color: "white",
            outline:"1.5px solid black",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",

            alignItem: "center",
            overflowY: "hidden",
            minWidth: "5px",
            outline:"1.5px solid black",

            background: "White",
          },
        }),
      },
    });

  console.log(columns,"columnscolumnscolumns")
  return columns;
};
export let dbdatetodate = (dbdate) => {
  // moment
  console.log(dbdate, "dbdate");
  if (dbdate != undefined) {
    let datetime;
    if (dbdate.split("-").length == 2) {
      datetime = dbdate.split("-");
    }
    if (dbdate.split(" ").length == 2) {
      datetime = dbdate.split(" ");
    }

    let ddate = datetime[0].split("/");
    let dtime = datetime[1].split(":");
    let month = ddate[0];
    let date = ddate[1];
    let year = ddate[2];
    let hour = dtime[0];
    let minute = dtime[1];
    let second = dtime[2];
    let pformatDate = `${month}/${date}/${year} ${hour}:${minute}`;

    return pformatDate;
    console.log(
      moment(pformatDate, "MM-DD-YYYY").format("MM/DD/YYYY HH:mm:SS"),
      pformatDate,
      "fdbdate"
    );
  }
};

export let dateObjectFull = (dbdate) => {
  // moment
  console.log(dbdate, "dbdate1122");
  if (dbdate != undefined) {
    let datetime = dbdate.split(" ");
    let ddate = datetime[0].split("/");
    let dtime = datetime[1].split(":");
    let month = ddate[0];
    let date = ddate[1];
    let year = ddate[2];
    let hour = dtime[0];
    let minute = dtime[1];
    let second = dtime[2];

    let formattedDate = new Date(year, month, year, hour, minute, second);
    let pformatDate = `${month}/${date}/${year} ${hour}:${minute}:${second}`;

    console.log(
      moment(pformatDate, "MM-DD-YYYY").format("MM/DD/YYYY"),
      formattedDate,
      "fdbdate"
    );

    return formattedDate;
  }
};

export let ageofdatabytwo = (dbdate, dbdate1) => {
  // moment
  console.log(dbdate, dbdate1, "dbdate");
  if (dbdate1 != undefined) {
    let datetime1 = dbdate1.split(" ");
    let ddate1 = datetime1[0].split("/");
    let dtime1 = datetime1[1].split(":");
    let month1 = ddate1[0];
    let date1 = ddate1[1];
    let year1 = ddate1[2];
    let hour1 = dtime1[0];
    let minute1 = dtime1[1];
    let second1 = "00";

    let datetime = dbdate.split(" ");
    let ddate = datetime[0].split("/");
    let dtime = datetime[1].split(":");
    let month = ddate[0];
    let date = ddate[1];
    let year = ddate[2];
    let hour = dtime[0];
    let minute = dtime[1];
    let second = "00";

    let formattedDate = new Date(year, +month - 1, date, hour, minute, second);
    let curDate = new Date(year1, +month1 - 1, date1, hour1, minute1, second1);

    let d = curDate - formattedDate;
    let weekdays = Math.floor(d / 1000 / 60 / 60 / 24 / 7);
    let days = Math.floor(d / 1000 / 60 / 60 / 24 - weekdays * 7);
    let hours = Math.floor(d / 1000 / 60 / 60 - weekdays * 7 * 24 - days * 24);
    let minutes = Math.floor(
      d / 1000 / 60 - weekdays * 7 * 24 * 60 - days * 24 * 60 - hours * 60
    );
    let seconds = Math.floor(
      d / 1000 -
      weekdays * 7 * 24 * 60 * 60 -
      days * 24 * 60 * 60 -
      hours * 60 * 60 -
      minutes * 60
    );
    let milliseconds = Math.floor(
      d -
      weekdays * 7 * 24 * 60 * 60 * 1000 -
      days * 24 * 60 * 60 * 1000 -
      hours * 60 * 60 * 1000 -
      minutes * 60 * 1000 -
      seconds * 1000
    );

    return days + "day, " + hours + "hours, " + minutes + "min";

    return formattedDate;
  }
};

export let aging_fun = (sdbdate, edbdate, formattime, formatmome) => {
  require("moment-duration-format")
  // var sdbdate=moment(ssdbdate,formattime)
  // var edbdate=moment(eedbdate,formattime)
  console.log(sdbdate.format("YYYY/MM/DD HH:mm"), edbdate.format("YYYY/MM/DD HH:mm"), "moment_datemoment_date")
  let diffday = edbdate.diff(sdbdate, "days")
  let diffhour = edbdate.diff(sdbdate, "hours")
  let diffmin = edbdate.diff(sdbdate, "minutes")
  let diff = edbdate.diff(sdbdate)
  let durations = moment.duration(diff, "milliseconds")
  console.log(diffday, diffhour, diffmin, durations, "moment_datemoment_date")
  console.log(durations.days(), durations.format("D [Day] H [Hours] m [Minutes]", {
    trim: false
  }), "moment_datemoment_date")
  return durations.days(), durations.format(formatmome, {
    trim: false
  })
  // console.log(diff,durations.format("YYYY/MM/DD HH:mm"),moment.duration(diff),edbdate.diff(sdbdate,'milliseconds'),"moment_datemoment_date") 
}

export let momnt_hour_ageofdata = (dbdate, dbdate2, formattime) => {

  var momenttz = require('moment-timezone');
  console.log(dbdate, formattime, "moment_datemoment_date")
  var moment_start_date = moment(dbdate, formattime)
  var moment_end_date = moment(dbdate2, formattime)



  console.log(moment_start_date.format("YYYY/MM/DD HH:mm"), moment_end_date.format("YYYY/MM/DD HH:mm"), "moment_datemoment_date")


  console.log(moment_start_date, moment_end_date, "moment_datemoment_date")

  return aging_fun(moment_start_date, moment_end_date, formattime, "H [Hours] m [Minutes]")

}



export let int_momnt_hour_ageofdata = (dbdate, dbdate2, formattime) => {

  var momenttz = require('moment-timezone');
  console.log(dbdate, formattime, "moment_datemoment_date")
  var moment_start_date = moment(dbdate, formattime)
  var moment_end_date = moment(dbdate2, formattime)



  console.log(moment_start_date.format("YYYY/MM/DD HH:mm"), moment_end_date.format("YYYY/MM/DD HH:mm"), "moment_datemoment_date")


  console.log(moment_start_date, moment_end_date, "moment_datemoment_date")

  return aging_fun(moment_start_date, moment_end_date, formattime, "H")

}



export let momnt_ageofdata = (dbdate, dbdate2, formattime) => {

  var momenttz = require('moment-timezone');
  console.log(dbdate, dbdate2, formattime, "moment_datemoment_date")
  var moment_start_date = moment(dbdate, formattime)
  var moment_end_date = moment(dbdate2, formattime)



  console.log(moment_start_date.format("YYYY/MM/DD HH:mm"), moment_end_date.format("YYYY/MM/DD HH:mm"), "moment_datemoment_date")


  console.log(moment_start_date, moment_end_date, "moment_datemoment_date")

  return aging_fun(moment_start_date, moment_end_date, formattime, "D [Day] H [Hours] m [Minutes]")

}

export let ageofdata = (dbdate) => {
  // moment
  console.log(dbdate, "dbdateaging");
  if (dbdate != undefined) {
    let datetime = dbdate.split(" ");
    let ddate = datetime[0].split("/");
    let dtime = datetime[1].split(":");
    let month = ddate[0];
    let date = ddate[1];
    let year = ddate[2];
    let hour = dtime[0];
    let minute = dtime[1];
    let second = "00";

    let formattedDate = new Date(year, +month - 1, date, hour, minute, second);
    let curDate = new Date();
    let d = curDate - formattedDate;
    let weekdays = Math.floor(d / 1000 / 60 / 60 / 24 / 7);
    let days = Math.floor(d / 1000 / 60 / 60 / 24 - weekdays * 7);
    let hours = Math.floor(d / 1000 / 60 / 60 - weekdays * 7 * 24 - days * 24);
    let minutes = Math.floor(
      d / 1000 / 60 - weekdays * 7 * 24 * 60 - days * 24 * 60 - hours * 60
    );
    let seconds = Math.floor(
      d / 1000 -
      weekdays * 7 * 24 * 60 * 60 -
      days * 24 * 60 * 60 -
      hours * 60 * 60 -
      minutes * 60
    );
    let milliseconds = Math.floor(
      d -
      weekdays * 7 * 24 * 60 * 60 * 1000 -
      days * 24 * 60 * 60 * 1000 -
      hours * 60 * 60 * 1000 -
      minutes * 60 * 1000 -
      seconds * 1000
    );

    console.log(days + "day, " + hours + "hours, " + minutes + "min", "aging")
    return days + "day, " + hours + "hours, " + minutes + "min";

    return formattedDate;
  }
};



export let ageofdatavalue = (dbdate) => {
  // moment
  console.log(dbdate, "dbdateaging");
  if (dbdate != undefined) {
    let datetime = dbdate.split(" ");
    let ddate = datetime[0].split("/");
    let dtime = datetime[1].split(":");
    let month = ddate[0];
    let date = ddate[1];
    let year = ddate[2];
    let hour = dtime[0];
    let minute = dtime[1];
    let second = "00";

    let formattedDate = new Date(year, +month - 1, date, hour, minute, second);
    let curDate = new Date();
    let d = curDate - formattedDate;
    let weekdays = Math.floor(d / 1000 / 60 / 60 / 24 / 7);
    let days = Math.floor(d / 1000 / 60 / 60 / 24 - weekdays * 7);
    let hours = Math.floor(d / 1000 / 60 / 60 - weekdays * 7 * 24 - days * 24);
    let minutes = Math.floor(
      d / 1000 / 60 - weekdays * 7 * 24 * 60 - days * 24 * 60 - hours * 60
    );
    let seconds = Math.floor(
      d / 1000 -
      weekdays * 7 * 24 * 60 * 60 -
      days * 24 * 60 * 60 -
      hours * 60 * 60 -
      minutes * 60
    );
    let milliseconds = Math.floor(
      d -
      weekdays * 7 * 24 * 60 * 60 * 1000 -
      days * 24 * 60 * 60 * 1000 -
      hours * 60 * 60 * 1000 -
      minutes * 60 * 1000 -
      seconds * 1000
    );

    console.log(days + "day, " + hours + "hours, " + minutes + "min", "aging")
    return [days, hours, minutes];

    return formattedDate;
  }
};




export let ageofdatavalueCheck = (dbdate) => {
  // moment
  console.log(dbdate, "dbdateaging");
  if (dbdate != undefined) {
    let datetime = dbdate.split(" ");
    let ddate = datetime[0].split("/");
    let dtime = datetime[1].split(":");
    let month = ddate[0];
    let date = ddate[1];
    let year = ddate[2];
    let hour = dtime[0];
    let minute = dtime[1];
    let second = "00";

    let formattedDate = new Date(year, +month - 1, date, hour, minute, second);
    let curDate = new Date();
    let d = curDate > formattedDate;

    return d;

    return formattedDate;
  }
};





export let getdatedatalistnext = (dbdate) => {
  // moment
  console.log(dbdate, "dbdate");
  if (dbdate != undefined) {
    let datetime = dbdate.split(" ");
    let ddate = datetime[0].split("/");
    let dtime = datetime[1].split(":");
    let month = ddate[0];
    let date = ddate[1];
    let year = ddate[2];
    let hour = dtime[0];
    let minute = dtime[1];
    return `${month}/${date}/${year} ${hour}:${minute} CST`;
  }
};

export let ageofdatalistnext = (dbdate) => {
  // moment
  console.log(dbdate, "dbdate");
  if (dbdate != undefined) {
    let datetime = dbdate.split(" ");
    let ddate = datetime[0].split("/");
    let dtime = datetime[1].split(":");
    let month = ddate[0];
    let date = ddate[1];
    let year = ddate[2];
    let hour = dtime[0];
    let minute = dtime[1];

    let formattedDate = new Date(year, +month - 1, date, hour, minute, 0);
    let curDate = new Date();

    console.log(formattedDate, curDate, "formattedDate,curDate");
    let d = formattedDate - curDate;
    let weekdays = Math.floor(d / 1000 / 60 / 60 / 24 / 7);
    let years = Math.floor(d / 1000 / 60 / 60 / 24 - weekdays * 7);
    let months = Math.floor(d / 1000 / 60 / 60 / 24 - weekdays * 7);
    let days = Math.floor(d / 1000 / 60 / 60 / 24 - weekdays * 7);
    let hours = Math.floor(d / 1000 / 60 / 60 - weekdays * 7 * 24 - days * 24);
    let minutes = Math.floor(
      d / 1000 / 60 - weekdays * 7 * 24 * 60 - days * 24 * 60 - hours * 60
    );
    let seconds = Math.floor(
      d / 1000 -
      weekdays * 7 * 24 * 60 * 60 -
      days * 24 * 60 * 60 -
      hours * 60 * 60 -
      minutes * 60
    );
    let milliseconds = Math.floor(
      d -
      weekdays * 7 * 24 * 60 * 60 * 1000 -
      days * 24 * 60 * 60 * 1000 -
      hours * 60 * 60 * 1000 -
      minutes * 60 * 1000 -
      seconds * 1000
    );

    return [years, months, days, hours, minutes, seconds];

    return formattedDate;
  }
};



export function toHoursAndMinutes(totalMinutes) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  console.log(hours, minutes, "toHoursAndMinutes")
  return [padToTwoDigits(hours), padToTwoDigits(minutes)];
}


function padToTwoDigits(num) {
  return num.toString().padStart(2, '0');
}



export function getProgressBar(startTime, endTime) {

  console.log(startTime, endTime, "getProgressBar")
  const start = moment(startTime);
  const current = moment(new Date());
  const end = moment(endTime);
  const diffsc = moment.duration(current.diff(start));
  const diffse = moment.duration(end.diff(start));
  let percentage = diffsc.asHours() / diffse.asHours() * 100
  let hourdiff = diffse.asHours() - diffsc.asHours();
  console.log("start==>", start, "current==>", current, "end==>", end, "percentage==>", percentage.toFixed(), "hourdiff==>", hourdiff.toFixed(), "dataformatdate")

  return [percentage.toFixed(), hourdiff.toFixed()]
}


export function more_info(value) {

  // console.log(value,value.length>63,"valuemore_infovalue")
  if (value) {
    if (value.length > 63) {
      return value.slice(0, 64) + "..."
    } else {
      return value
    }
  } else {
    return ""
  }


}

export function digit_more_info(value,digit) {

  // console.log(value,value.length>63,"valuemore_infovalue")
  if (value) {
    if (value.length > digit-1) {
      return value.slice(0, digit) + "..."
    } else {
      return value
    }
  } else {
    return ""
  }


}

export function smart_more_info(value,head,cmts,fun) {

  return <p className={"text-black my-0 modalHoverClass"}
    data-toggle="tooltip"
    data-placement="top"
    title="Click Here For More Details"
    onClick={() =>
      fun(
        <p
          style={{
            border: "2px solid black",
            color: "black",
            padding: 2,
          }}
          className="CommonModal"
        >
          {value}
        </p>,
        head,
        cmts
      )
    }
    >{more_info(value)}</p>


}

export function columnsArray(columnString) {
  let header = columnString;
  let convertedHeader = header.split("\t");
  let transfromarray = [];
  transfromarray = convertedHeader.map((item) => {
    return {
      name: "",
      label: item,
      sortable: false,
      options: {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 101
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    };
  });
  return transfromarray;
}


export function techCheckerwitharray(pid, tech) {

  let test = 0
  let neewtech = tech.map((itm) => {
    console.log(itm.value.split("_CP_")[1], "itmitmitmitm")
    return itm.value.split("_CP_")[1]
  })

  let ctr = 0

  console.log(neewtech, tech, "neewtech,pid")
  pid.map((itt) => {
    if (neewtech.indexOf(itt) == -1) {
      ctr = 2
    }
  })

  if (ctr == 2) {
    return true
  } else {
    return false
  }
}


export function techChecker(pid, tech) {

  let test = 0
  pid.map((itm) => {
    console.log(pid, tech, "techChecker")
    var r = tech.some(i => i.value.includes(itm.value));
    console.log(r);
    if (r == false) {
      test = 1
    }
  })
  if (test == 0) {
    return false
  } else {
    return true
  }
}

export let commonsowDetail = [
  {
    value: "E911-CallTest",
    label: "E911-CallTest",
  },
  {
    value: "CX",
    label: "CX",
  },
  {
    value: "IX",
    label: "IX",
  },
  {
    value: "TroubleShooting",
    label: "Troubleshooting",
  },
];


export let mopcommonsowDetail = [
  {
    value: "E911-CallTest",
    label: "E911-CallTest",
  },
  {
    value: "CX",
    label: "CX",
  },
  {
    value: "IX",
    label: "IX",
  },
  {
    value: "TroubleShooting",
    label: "Troubleshooting",
  },
  {
    value: "Microwave upgrade",
    label: "Microwave upgrade",
  },
  {
    value: "MW Troubleshooting",
    label: "MW Troubleshooting",
  },
  {
    value: "MW IX",
    label: "MW IX",
  },
  
  {
    value: "MW CX",
    label: "MW CX",
  },
  {
    value: "Camlock",
    label: "Camlock",
  },
  {
    value: "Generator Work",
    label: "Generator Work",
  },
  
];
const handleChangePage = (event, newPage) => {
  // setPageIndex(newPage);
  console.log("the curreent page is =", event)
};
export function projectIdValidator(pcode, pid) {
  console.log(pcode, pid)
  let newb = []
  pid.map((item) => {
    newb.push(item.group)
  })
  console.log(pcode, "pcodepcodepcode")
  console.log(newb)
  let ctr = 1
  if (pcode) {
    pcode.map((itt) => {
      if (newb.indexOf(itt) == -1) {
        ctr = 2
      }
    })

  } else {
    return false
  }


  if (ctr == 2) {
    return true
  } else {
    return false
  }
}

// const  UseCustomeDispatch =()=>{
//   const customDispatch=useDispatch()
//   // useEffect(()=>{
//     // dispatch(inflightGetCSVReducerAction("/inflight" + dynamic_dashboard))
//   // },[])
//   return customDispatch
// }

function UseCounter() {
  const [counterPP, setCountPP] = useState(15);

  // useEffect(() => {
  //   console.log(counterPP, "rowsPerPageaa");
  //   setCountPP(counterPP)
  // }, [counterPP]);

  return [counterPP, setCountPP];
}
function UseNextFetchData() {
  const [fetch, setSeries] = useState(2)
  return [fetch, setSeries]
}
function UsePageChange() {
  const [pageNo, setPageNo] = useState(0)
  return [pageNo, setPageNo]
}


function UsePageCounter() {
  const [currentPP, setcurrentPP] = useState(0);

  useEffect(() => {
    // console.log(counterPP, "rowsPerPageaa");

    setcurrentPP(currentPP)
  }, [currentPP]);

  return [currentPP, setcurrentPP];
}

function MaxPageCounter() {
  const [maxPage, setmaxpage] = useState(0);

  useEffect(() => {
    console.log(maxPage, "maxPagemaxPage");

    // setCountPP(maxPage)
  }, [maxPage]);

  return [maxPage, setmaxpage];
}


const UseCustomeDispatch = () => {
  const customDispatch = useDispatch()
  // useEffect(()=>{
  // dispatch(inflightGetCSVReducerAction("/inflight" + dynamic_dashboard))
  // },[])
  return customDispatch
}


function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

function UseHis(){
  const history = useHistory();
  console.log(history,"historyhistoryhistoryhistory")
  return history
}
export const CustomRedirect=()=>{
  const history =useHistory()
  return history
}




export function rancustomDataTableOptionObject(toolbarButton, isDownload = false, fileNameValue = "testing", setfilliopen = "", addtomap = "", sortOrder, fuccall, urlcode,Searchurlcode, RowCount, totalCount, filteredDate,OneTimere,setOneTimere,setResetFilter,ResetFilter,filterOn=true,searchOn=true) {

  const [counterPP, setCountPP] = UseCounter()
  const [currentPP, setcurrentPP] = UsePageCounter()
  // const [counterPP, setCountPP] = UseCounter()
  console.log(OneTimere,RowCount, totalCount,Searchurlcode,urlcode,"rancustomDataTableOptionObject")
  let customDispatch = UseCustomeDispatch()
  const [maxPage, setmaxpage] = MaxPageCounter()

  if(OneTimere){
    setOneTimere(false)
    newStart=45
  }
  const options = {

    

    download: isDownload,
    sortOrder: sortOrder,
    onDownload: (buildHead, buildBody, columns, values) => {
      const fileName = fileNameValue + moment(new Date()).format("MM_DD_YYYY");
      const title = fileName;

      let header = []
      header = columns.map((item) => {
        return item.label != "Action" ? item.label : ""
      })
      console.log("the header is =", header)
      const data = values;



      //Create a workbook with a worksheet
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet(fileName);
      let cols = []
      header.map(itt => {
        console.log("the col object=", itt)
        cols.push({ header: itt, key: itt })
      })

      worksheet.columns = cols;

      //Add Row and formatting
      // worksheet.mergeCells('C1', 'F4');
      // let titleRow = worksheet.getCell('C1');
      // titleRow.value = title
      // titleRow.font = {
      //     name: 'Calibri',
      //     size: 16,
      //     underline: 'single',
      //     bold: true,
      //     color: { argb: '0085A3' }
      // }
      // titleRow.alignment = { vertical: 'middle', horizontal: 'center' }

      // Date
      // worksheet.mergeCells('G1:H4');
      // let d = new Date();
      // let date = d.getDate() + '-' + d.getMonth() + '-' + d.getFullYear();
      // let dateCell = worksheet.getCell('G1');
      // dateCell.value = date;
      // dateCell.font = {
      //     name: 'Calibri',
      //     size: 12,
      //     bold: true
      // }
      // dateCell.alignment = { vertical: 'middle', horizontal: 'center' }


      // //Blank Row 
      // worksheet.addRow([]);

      //Adding Header Row
      let headerRow = worksheet.addRow(header);
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '143B64' },
          bgColor: { argb: '' }
        }
        cell.font = {
          bold: true,
          color: { argb: 'FFFFFF' },
          size: 13,
          name: "poppins"

        }
      })

      // Adding Data with Conditional Formatting
      data.forEach((d, i) => {

        console.log(d.data, "data")
        let row = worksheet.addRow(d.data);
        console.log("the row is =", row)
        // row.forEach((item,index)=>{
        // let rowcell=item.getCell(index+1)
        //     rowcell.font={
        //         name: "poppins"
        //     }
        // })
        row.font = {
          name: "poppins"
        }
        // let rowcell=row.getCell(i+1)
        // rowcell.font={
        //     name: "poppins"
        // }
        const color_dict = {
          "Approved": "2BC155",
          "Pending": "F5E949",
          "Rejected": "B03636",
          "Go": "2BC155",
          "No Go": "B03636"
        }
        let fos = row.getCell(13);
        console.log("the row is =", fos)

        let goNoGoColumn = row.getCell(10)
        let sds = row.getCell(11);
        let rfs = row.getCell(12);



        // let color = '';
        // if (+sales.value < 200000) {
        //     color = 'FF9999'
        // }

        console.log(sds, sds.value, color_dict[sds["Site Development Approval Status"]], "colorType")
        const borderStyle = {
          style: 1
        }
        goNoGoColumn.fill = {
          type: 'pattern',

          pattern: 'solid',
          fgColor: { argb: color_dict[goNoGoColumn.value] }
        }

        sds.fill = {
          type: 'pattern',
          border: borderStyle,
          pattern: 'solid',
          fgColor: { argb: color_dict[sds.value] }
        }

        rfs.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: color_dict[rfs.value] }
        }

        fos.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: color_dict[fos.value] }
        }
      }
      );

      worksheet.spliceRows(1, 1)

      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
        saveAs(blob, title + '.xlsx');
        return false
      })
      return false
    },
    textLabels: {
      toolbar: {
        downloadCsv: "Download XLSX",
      },
    },
    viewColumns: false,
    // rowsPerPage: rpp,



    rowsPerPage: counterPP,

    onChangeRowsPerPage: rowsPerPage => {
      // setState(rowsPerPage);
      console.log(rowsPerPage, rowsPerPage, counterPP, "rowsPerPage")
      setCountPP(rowsPerPage)
      console.log(rowsPerPage, rowsPerPage, counterPP, "rowsPerPage")
    },


    onChangePage: currentPage => {
      // setState(rowsPerPage);
      setcurrentPP(currentPage)
      // if (currentPage > maxPage) {
      //   setmaxpage(currentPage)
      // }

      // if(currentPage*currentPP)

      console.log(RowCount.length, currentPage + 1, (currentPage + 1) * counterPP, "RowCount,counterPPRowCount,counterPP")
      // if(currentPP<currentPage && currentPage>maxPage){
      //   // setagdm(true)
      //   dispatch(MopDatabaseAction(`${mopDataApi}?sp=${counterPP}&cp=${currentPage}`));
      // }

      let dateUrl = ""
      if (filteredDate[0] && filteredDate[1]) {
        // let start = formatDate(filteredDate[0]);
        // let end = formatDate(filteredDate[1]);
        // dateUrl="&start=" + start + "&end=" + end

        dateUrl = `&start=${moment(filteredDate[0]).format("YYYY-MM-DD")}&end=${moment(filteredDate[1]).format("YYYY-MM-DD")}`
      }

      let fiterData=Searchurlcode.replace("?","&")

      // if(Searchurlcode!=""){
      //   fiterData=Searchurlcode.replace("?","&")
      // }else{
      //   fiterData
      // }


      console.log(newStart,totalCount,RowCount < (currentPage + 1) * counterPP,"RowCount < (currentPage + 1) * counterPP")
      let start=""
      let end=0
      let newVar=false
      if(newStart<totalCount){
      // if (RowCount < (currentPage + 1) * counterPP) {
        // console.log("/magenta","/magenta" + dynamic_dashboard,totalRow,(totalRow-((page + 1) * counterPP)+50))
        start="sp="+newStart+"&cp="+50
        end=1
        newVar=true
        newStart=newStart+50
        // customDispatch(magentaGetCSVReducerAction("/magenta" + dynamic_dashboard));
        // start=
        // console.log(totalRow,page,(page + 1) * counterPP,"inFlightchcker")
      }
      // else{

      //   if(RowCount<totalCount){
      //     start="sp="+newStart+"&cp="+50
      //     end=1
      //     newStart=newStart+50
      //     // customDispatch(magentaGetCSVReducerAction("/inflight" + dynamic_dashboard+"?sp"+totalRow+"&cp="+(totalRow+50)));
      //   }
      //   // end=
      // }

      console.log(newVar,"newVarnewVarnewVar")
      if(newVar){


        if(fileNameValue=="ranDashbord"){
          let Filleddata=customDispatch(LCLranDashboardReducerAction())
          if(Filleddata==""){
            customDispatch(AccountReducerAction(ranDashboard + Filleddata + "?" + start,false,false));
          }else{
            customDispatch(AccountReducerAction(ranDashboard + Filleddata + "&" + start,false,false));
          }
          
        }else{
          customDispatch(AccountReducerAction(urlcode+"?"+start+dateUrl+fiterData,false,false));
        }        
      }

      // if (RowCount < (currentPage + 1) * counterPP) {
      //   customDispatch(AccountReducerAction(`${urlcode}?sp=${RowCount}&cp=${(((currentPage + 1) * counterPP) - RowCount) + 50}${dateUrl}${fiterData}`, false, false));
      // } else {
      //   if (RowCount<totalCount) {
      //     customDispatch(AccountReducerAction(`${urlcode}?sp=${RowCount + 1}&cp=${50}${dateUrl}${fiterData}`, false, false));
      //   }
      // }
      // const history = UseHis();

      // console.log(currentPage,history, "currentPagecurrentPagecurrentPage")

    },






    rowsPerPageOptions: [15, 30, 45],
    count: totalCount,
    // fixedHeader: true,
    fixedSelectColumn: true,
    // tableBodyMaxHeight: "600px",
    print: false,
    onFilterChange: (changedColumn, filterList) => {
      console.log("inside filter=", changedColumn, filterList);
    },
    onFilterDialogClose: (changedColumn, filterList) => {
      console.log("onFilterDialogClose filter=", changedColumn, filterList);
      if (setfilliopen != "") {
        setfilliopen(true);
      }
    },
    onFilterChipClose: () => {
      if (setfilliopen != "") {
        setfilliopen(true);
      }
    },

    onFilterChange: (changedColumn, filterList) => {
      console.log("inside filter=", changedColumn, filterList);
    },
    onTableChange: addtomap,
    page:currentPP,
    search:searchOn,
    customToolbar: toolbarButton,
    searchPlaceholder: "Search by Site ID",
    onFilterChange: (changedColumn, filterList) => {
      console.log("inside filter=", changedColumn, filterList);
    },
    selectableRows: false,
    responsive: "scroll",
    overflowY: "scroll",
    filterType: "dropdown",
    // responsive: "scrollfullHeight",
    fixedHeader: true,
    fixedFooter: true,
    textAlign: center,
    filter:false
  };
  
  if(ResetFilter){
    setcurrentPP(0)
    setResetFilter(false)
  }

  console.log(options.page,currentPP,options,"optionsoptionsoptions")

  return options;

}



export function cmncustomDataTableOptionObject(toolbarButton, RowCount, totalCount,OneTimere,setOneTimere,setResetFilter,ResetFilter,filterOn=true,searchOn=true,globalState,apiUrl) {

  const [counterPP, setCountPP] = UseCounter()
  const [currentPP, setcurrentPP] = UsePageCounter()
  // console.log(OneTimere,RowCount, totalCount,Searchurlcode,urlcode,"rancustomDataTableOptionObject")
  let customDispatch = UseCustomeDispatch()
  const [maxPage, setmaxpage] = MaxPageCounter()

  if(OneTimere){
    setOneTimere(false)
    newStart=45
  }
  const options = {
    // download: isDownload,
    // sortOrder: sortOrder,
    viewColumns: false,
    rowsPerPage: counterPP,
    onChangeRowsPerPage: rowsPerPage => {
      // setState(rowsPerPage);
      console.log(rowsPerPage, rowsPerPage, counterPP, "rowsPerPage")
      setCountPP(rowsPerPage)
      console.log(rowsPerPage, rowsPerPage, counterPP, "rowsPerPage")
    },


    onChangePage: currentPage => {
      setcurrentPP(currentPage)
      // console.log(RowCount.length, currentPage + 1, (currentPage + 1) * counterPP, "RowCount,counterPPRowCount,counterPP")
      
      // let dateUrl = ""
      // if (filteredDate[0] && filteredDate[1]) {
      //   // let start = formatDate(filteredDate[0]);
      //   // let end = formatDate(filteredDate[1]);
      //   // dateUrl="&start=" + start + "&end=" + end

      //   dateUrl = `&start=${moment(filteredDate[0]).format("YYYY-MM-DD")}&end=${moment(filteredDate[1]).format("YYYY-MM-DD")}`
      // }

      // let fiterData=Searchurlcode.replace("?","&")

      // console.log(newStart,totalCount,RowCount < (currentPage + 1) * counterPP,"RowCount < (currentPage + 1) * counterPP")
      let start=0
      let end=0
      if(newStart<totalCount){
        start="sp="+newStart+"&cp="+50
        end=1
        newStart=newStart+50
      }

      if(newStart<totalCount){
        // if(fileNameValue=="ranDashbord"){

          
          let Filleddata=customDispatch(globalState())
          // dispatch(AccountReducerAction(ranNesting_tracker+Filleddata,true,true));
          // let Filleddata=customDispatch(LCLranDashboardReducerAction())
          if(Filleddata==""){
            customDispatch(AccountReducerAction(apiUrl + Filleddata + "?" + start,false,false));
          }else{
            customDispatch(AccountReducerAction(apiUrl + Filleddata + "&" + start,false,false));
          }
          
        // }else{
        //   customDispatch(AccountReducerAction(urlcode+"?"+start+dateUrl+fiterData,false,false));
        // }        
      }
    },






    rowsPerPageOptions: [15, 30, 45],
    count: totalCount,
    // fixedHeader: true,
    fixedSelectColumn: true,
    // tableBodyMaxHeight: "600px",
    print: false,
    // onFilterChange: (changedColumn, filterList) => {
    //   console.log("inside filter=", changedColumn, filterList);
    // },
    // onFilterDialogClose: (changedColumn, filterList) => {
    //   console.log("onFilterDialogClose filter=", changedColumn, filterList);
    //   if (setfilliopen != "") {
    //     setfilliopen(true);
    //   }
    // },
    // onFilterChipClose: () => {
    //   if (setfilliopen != "") {
    //     setfilliopen(true);
    //   }
    // },

    // onFilterChange: (changedColumn, filterList) => {
    //   console.log("inside filter=", changedColumn, filterList);
    // },
    // onTableChange: addtomap,
    page:currentPP,
    search:searchOn,
    customToolbar: toolbarButton,
    searchPlaceholder: "Search by Site ID",
    // onFilterChange: (changedColumn, filterList) => {
    //   console.log("inside filter=", changedColumn, filterList);
    // },
    selectableRows: false,
    responsive: "scroll",
    overflowY: "scroll",
    filterType: "dropdown",
    // responsive: "scrollfullHeight",
    fixedHeader: true,
    fixedFooter: true,
    textAlign: center,
    // filter:filterOn
    filter:false
  };
  
  if(ResetFilter){
    setcurrentPP(0)
    setResetFilter(false)
  }

  // console.log(options.page,currentPP,options,"optionsoptionsoptions")

  return options;

}

let newStart=45


//data table option object
export function customDataTableOptionObject(toolbarButton, isDownload = false, fileNameValue = "testing", setfilliopen = "", addtomap = "", sortOrder, url = "", asnurl = "",totalRow=0,totalCountDb=0,viewColumnsVal=false) {
  // console.log( url = "", asnurl = "",totalRow,totalCountDb,viewColumnsVal=false,"totalRowtotalRowtotalRowtotalRow")
  let noOfPAge
  const [counterPP, setCountPP] = UseCounter()
  const [pageNo, setPageNo] = UsePageChange()
  const [fetch, setSeries] = UseNextFetchData()
  let customDispatch = UseCustomeDispatch()
  if(totalRow==0){
    newStart=45
  }
  const options = {

    download: isDownload,
    sortOrder: sortOrder,
    count:totalCountDb,
    onDownload: (buildHead, buildBody, columns, values) => {
      const fileName = fileNameValue + moment(new Date()).format("MM_DD_YYYY");
      const title = fileName;

      let header = []
      header = columns.map((item) => {
        return item.label != "Action" ? item.label : ""
      })
      console.log("the header is =", header)
      const data = values;

      //Create a workbook with a worksheet
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet(fileName);
      let cols = []
      header.map(itt => {
        console.log("the col object=", itt)
        cols.push({ header: itt, key: itt })
      })

      worksheet.columns = cols;

      //Add Row and formatting
      // worksheet.mergeCells('C1', 'F4');
      // let titleRow = worksheet.getCell('C1');
      // titleRow.value = title
      // titleRow.font = {
      //     name: 'Calibri',
      //     size: 16,
      //     underline: 'single',
      //     bold: true,
      //     color: { argb: '0085A3' }
      // }
      // titleRow.alignment = { vertical: 'middle', horizontal: 'center' }

      // Date
      // worksheet.mergeCells('G1:H4');
      // let d = new Date();
      // let date = d.getDate() + '-' + d.getMonth() + '-' + d.getFullYear();
      // let dateCell = worksheet.getCell('G1');
      // dateCell.value = date;
      // dateCell.font = {
      //     name: 'Calibri',
      //     size: 12,
      //     bold: true
      // }
      // dateCell.alignment = { vertical: 'middle', horizontal: 'center' }


      // //Blank Row 
      // worksheet.addRow([]);

      //Adding Header Row
      let headerRow = worksheet.addRow(header);
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '143B64' },
          bgColor: { argb: '' }
        }
        cell.font = {
          bold: true,
          color: { argb: 'FFFFFF' },
          size: 13,
          name: "poppins"

        }
      })

      // Adding Data with Conditional Formatting
      data.forEach((d, i) => {

        console.log(d.data, "data")
        let row = worksheet.addRow(d.data);
        console.log("the row is =", row)
        // row.forEach((item,index)=>{
        // let rowcell=item.getCell(index+1)
        //     rowcell.font={
        //         name: "poppins"
        //     }
        // })
        row.font = {
          name: "poppins"
        }
        // let rowcell=row.getCell(i+1)
        // rowcell.font={
        //     name: "poppins"
        // }
        const color_dict = {
          "Approved": "2BC155",
          "Pending": "F5E949",
          "Rejected": "B03636",
          "Go": "2BC155",
          "No Go": "B03636"
        }
        let fos = row.getCell(13);
        console.log("the row is =", fos)

        let goNoGoColumn = row.getCell(10)
        let sds = row.getCell(11);
        let rfs = row.getCell(12);



        // let color = '';
        // if (+sales.value < 200000) {
        //     color = 'FF9999'
        // }

        console.log(sds, sds.value, color_dict[sds["Site Development Approval Status"]], "colorType")
        const borderStyle = {
          style: 1
        }
        goNoGoColumn.fill = {
          type: 'pattern',

          pattern: 'solid',
          fgColor: { argb: color_dict[goNoGoColumn.value] }
        }

        sds.fill = {
          type: 'pattern',
          border: borderStyle,
          pattern: 'solid',
          fgColor: { argb: color_dict[sds.value] }
        }

        rfs.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: color_dict[rfs.value] }
        }

        fos.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: color_dict[fos.value] }
        }
      }
      );

      worksheet.spliceRows(1, 1)

      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
        saveAs(blob, title + '.xlsx');
        return false
      })
      return false
    },
    textLabels: {
      toolbar: {
        downloadCsv: "Download XLSX",
      },
    },
    viewColumns: viewColumnsVal,
    // rowsPerPage: rpp,
    // count:totalCountDb,
    rowsPerPage: counterPP,

    onChangeRowsPerPage: rowsPerPage => {
      console.log("the row per page is=", fetch)
      if (rowsPerPage * (pageNo + 1) == 180 * (fetch - 1)) {
        customDispatch(inflightGetCSVReducerAction("/inflight" + dynamic_dashboard + "?series=" + fetch))
        setSeries(fetch + 1)
      }
      // console.log(rowsPerPage, rowsPerPage, counterPP, "rowsPerPage")
      setCountPP(rowsPerPage)
      console.log(rowsPerPage, "rowsPerPage")
    },


    rowsPerPageOptions: [15, 30, 45],
    // fixedHeader: true,
    fixedSelectColumn: true,
    // tableBodyMaxHeight: "600px",
    onChangePage: (page) => {
      console.log("the row per page is=", "fetch",fetch,"totalCountDb",totalCountDb,"totalRow",totalRow,"page",page,"counterPP",(page + 1) * counterPP,totalRow < (page + 1) * counterPP)
      console.log(totalCountDb,totalRow,page,(page + 1) * counterPP,"inFlightchcker")
      let start=0
      let end=0
      console.log(newStart,"newStartnewStartnewStartnewStart")
      if (totalRow < (page + 1) * counterPP) {
        // console.log("/magenta","/magenta" + dynamic_dashboard,totalRow,(totalRow-((page + 1) * counterPP)+50))
        start="?sp="+newStart+"&cp="+50
        end=1
        newStart=newStart+50
        // customDispatch(magentaGetCSVReducerAction("/magenta" + dynamic_dashboard));
        // start=
        // console.log(totalRow,page,(page + 1) * counterPP,"inFlightchcker")
      }else{

        if(totalRow<totalCountDb){
          start="?sp="+newStart+"&cp="+50
          end=1
          newStart=newStart+50
          // customDispatch(magentaGetCSVReducerAction("/inflight" + dynamic_dashboard+"?sp"+totalRow+"&cp="+(totalRow+50)));
        }
        // end=
      }

      console.log(start,"newStartnewStartnewStartnewStart")

      if (url == "inflight" && end==1) {
        console.log(start,"startstartstart")
        customDispatch(inflightGetCSVReducerAction("/inflight" + dynamic_dashboard+start));
      }
      if (url == "magenta" && end==1) {
        console.log(start,"startstartstart")
        customDispatch(magentaGetCSVReducerAction("/magenta" + dynamic_dashboard+start));
      }
      if (url == "networKDatabase" && end==1) {
        console.log(start,"startstartstart")
        customDispatch(networkGetCSVReducerAction("/networkDatabase" + dynamic_dashboard+start));
      }
      
      
      if (url == "projectList" && end==1) {
        console.log(start,"startstartstart")
        customDispatch(AccountReducerAction(projectListApi+start,false,false));
      }
      if (url == "ranTracker" && end==1) {
        console.log(start,"startstartstart")
        customDispatch(AccountReducerAction(ranTracker+start,false,false));
      }
      


      // if (counterPP * (page + 1) == 180 * (fetch - 1)) {
      //   if (url == "mergenta") {

      //     // if (RowCount < (currentPage + 1) * counterPP) {
      //     //   customDispatch(magentaGetCSVReducerAction("/magenta" + dynamic_dashboard));
      //     // } else {
      //     //   if (totalCount > RowCount + 1) {
      //     //     customDispatch(magentaGetCSVReducerAction("/magenta" + dynamic_dashboard));
      //     //   }
      //     // }
      //     customDispatch(magentaGetCSVReducerAction("/magenta" + dynamic_dashboard + "?series=" + fetch))
      //     setSeries(fetch + 1)
      //     // totalRow

      //   }
      //   console.log(totalRow,page,(page + 1) * counterPP,"inFlightchcker")
      //   if (url == "inflight") {
      //     // totalRow

          
      //     console.log(totalCountDb,totalRow,page,(page + 1) * counterPP,"inFlightchcker")
      //     if (totalRow < (page + 1) * counterPP) {
      //       console.log(totalRow,page,(page + 1) * counterPP,"inFlightchcker")
      //     }else{
            
      //     }


      //     customDispatch(inflightGetCSVReducerAction("/inflight" + dynamic_dashboard + "?series=" + fetch))
      //     setSeries(fetch + 1)
      //   }
      //   if (url == "networKDatabase") {
      //     customDispatch(networkGetCSVReducerAction("/networkDatabase" + dynamic_dashboard + "?series=" + fetch))
      //     setSeries(fetch + 1)
      //   }
      //   if (url == "projectDataBase") {
      //     customDispatch(AccountReducerAction(asnurl))
      //   }
      // }
      setPageNo(parseInt(page) + 1)
      console.log("the page is =", page, " the row per page is= ", counterPP)
    },
    print: false,
    onFilterChange: (changedColumn, filterList) => {
      console.log("inside filter=", changedColumn);
    },
    onFilterDialogClose: (changedColumn, filterList) => {
      console.log("onFilterDialogClose filter=", changedColumn, filterList);
      if (setfilliopen != "") {
        setfilliopen(true);
      }
    },
    onFilterChipClose: () => {
      if (setfilliopen != "") {
        setfilliopen(true);
      }
    },

    onFilterChange: (changedColumn, filterList) => {
      console.log("inside filter=", changedColumn, filterList);
    },
    onTableChange: addtomap,

    textLabels: {
      body: {
        noMatch: 'Loading...',
      }
    },

    customToolbar: toolbarButton,
    searchPlaceholder: "Search by Site ID",
    onFilterChange: (changedColumn, filterList) => {
      console.log("inside filter=", changedColumn, filterList);
    },
    selectableRows: false,
    responsive: "scroll",
    overflowY: "scroll",
    filterType: "dropdown",
    // responsive: "scrollfullHeight",
    fixedHeader: true,
    fixedFooter: true,
    textAlign: center,
    filter:false
  };

  return options;

}



export function advancdExportOnecustomDataTableOptionObject(toolbarButton, isDownload = false, fileNameValue = "testing", setfilliopen = "",viewColumnsVal=false,searchBy="",isfilter=true) {


  const [counterPP, setCountPP] = UseCounter()
  
  let customDispatch = UseCustomeDispatch()
  
  const options = {
    
    download: isDownload,
    onSearchChange:(searchText) => {
      console.log(searchText,"searchText,searchText")
    },
    onDownload: (buildHead, buildBody, columns, values) => {
      const fileName = fileNameValue + moment(new Date()).format("MM_DD_YYYY");
      const title = fileName;
      let header = []
      let headerIndex = []

      console.log(columns, values, "columns, data")
      let cols = []
      columns.map((item, index) => {
        console.log(item, item.print, "item.print")
        if (item.print) {
          cols.push({ header: item.label, key: item.label })
          headerIndex.push(index)
          header.push(item.label)
        }
      })
      console.log("the header is =", headerIndex, columns, values, cols, header)
      const data = values;

      //Create a workbook with a worksheet
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet(fileName);
      // header.map(itt => {
      //   console.log("the col object=", itt)

      // })

      worksheet.columns = cols;


      console.log(cols, "colscolscols")
      //Adding Header Row
      let headerRow = worksheet.addRow(header);
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '143B64' },
          bgColor: { argb: '' }
        }
        cell.font = {
          bold: true,
          color: { argb: 'FFFFFF' },
          size: 13,
          name: "poppins"

        }
      })


      console.log(data)

      // Adding Data with Conditional Formatting
      data.forEach((d, i) => {
        let newval = []
        d.data.map((itttm, ind) => {
          if (headerIndex.indexOf(ind) != -1) {
            newval.push(itttm)
          }
        })

        console.log(d, i, newval, "datadatadatadatadata")
        let row = worksheet.addRow(newval);
        console.log("the row is =", row)
        // row.forEach((item,index)=>{
        // let rowcell=item.getCell(index+1)
        //     rowcell.font={
        //         name: "poppins"
        //     }
        // })
        row.font = {
          name: "poppins"
        }
        // let rowcell=row.getCell(i+1)
        // rowcell.font={
        //     name: "poppins"
        // }
        const color_dict = {
          "Approved": "2BC155",
          "Pending": "F5E949",
          "Rejected": "B03636",
          "Go": "2BC155",
          "No Go": "B03636"
        }
        let fos = row.getCell(13);
        console.log("the row is =", fos)

        let goNoGoColumn = row.getCell(10)
        let sds = row.getCell(11);
        let rfs = row.getCell(12);



        // let color = '';
        // if (+sales.value < 200000) {
        //     color = 'FF9999'
        // }

        console.log(sds, sds.value, color_dict[sds["Site Development Approval Status"]], "colorType")
        const borderStyle = {
          style: 1
        }
        goNoGoColumn.fill = {
          type: 'pattern',

          pattern: 'solid',
          fgColor: { argb: color_dict[goNoGoColumn.value] }
        }

        sds.fill = {
          type: 'pattern',
          border: borderStyle,
          pattern: 'solid',
          fgColor: { argb: color_dict[sds.value] }
        }

        rfs.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: color_dict[rfs.value] }
        }

        fos.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: color_dict[fos.value] }
        }
      }
      );

      worksheet.spliceRows(1, 1)

      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
        saveAs(blob, title + '.xlsx');
        return false
      })
      return false
    },
    textLabels: {
      toolbar: {
        downloadCsv: "Download XLSX",
      },
    },
    viewColumns: false,
    // rowsPerPage: rpp,

    rowsPerPage: counterPP,

    onChangeRowsPerPage: rowsPerPage => {
      // setState(rowsPerPage);
      console.log(rowsPerPage, rowsPerPage, counterPP, "rowsPerPage")
      setCountPP(rowsPerPage)
      console.log(rowsPerPage, rowsPerPage, counterPP, "rowsPerPage")
    },

    rowsPerPageOptions: [15, 30, 45],
    onChangePage: (page) => {
      console.log("the page is =", page, " the row per page is= ", counterPP)
    },
    // fixedHeader: true,
    fixedSelectColumn: true,
    // tableBodyMaxHeight: "600px",
    print: false,
    onFilterChange: (changedColumn, filterList) => {
      console.log("inside filter=", changedColumn, filterList);
    },
    onFilterDialogClose: (changedColumn, filterList) => {
      console.log("onFilterDialogClose filter=", changedColumn, filterList);
      if (setfilliopen != "") {
        setfilliopen(true);
      }
    },
    onFilterChipClose: () => {
      if (setfilliopen != "") {
        setfilliopen(true);
      }
    },

    onFilterChange: (changedColumn, filterList) => {
      console.log("inside filter=", changedColumn, filterList);
    },
    // onTableChange: addtomap,

    customToolbar: toolbarButton,
    // searchPlaceholder: "Search by "+searchBy,
    searchPlaceholder: "Search...",

    
    onFilterChange: (changedColumn, filterList) => {
      console.log("inside filter=", changedColumn, filterList);
    },
    selectableRows: false,
    responsive: "scroll",
    overflowY: "scroll",
    filterType: "dropdown",
    filter:isfilter,
    // responsive: "scrollfullHeight",
    fixedHeader: true,
    fixedFooter: true,
    textAlign: center,
    filter:false
  };

  return options;

}



export function advancdcustomDataTableOptionObject(toolbarButton, isDownload = false, fileNameValue = "testing", setfilliopen = "", addtomap = "", sortOrder, url = "", asnurl = "",totalRow=0,totalCountDb=0,viewColumnsVal=false,viewFIl=true,filterCol="",search=true,listColumns=[]) {


  const [counterPP, setCountPP] = UseCounter()
  
  let customDispatch = UseCustomeDispatch()
  if(totalRow==0){
    newStart=45
  }
  const options = {
    
    download: isDownload,
    sortOrder: sortOrder,
    viewColumns: viewColumnsVal,
    count:totalCountDb,


    onDownload: (buildHead, buildBody, columns, values) => {
      const fileName = fileNameValue + moment(new Date()).format("MM_DD_YYYY");
      const title = fileName;
      let header = []
      let headerIndex = []

      console.log(columns, values, "columns, data")
      let cols = []
      columns.map((item, index) => {
        console.log(item, item.print, "item.print")
        if (item.print) {
          cols.push({ header: item.label, key: item.label })
          headerIndex.push(index)
          header.push(item.label)
        }
      })
      console.log("the header is =", headerIndex, columns, values, cols, header)
      const data = values;

      //Create a workbook with a worksheet
      let workbook = new Workbook();
      let worksheet = workbook.addWorksheet(fileName);
      // header.map(itt => {
      //   console.log("the col object=", itt)

      // })

      worksheet.columns = cols;


      console.log(cols, "colscolscols")
      //Adding Header Row
      let headerRow = worksheet.addRow(header);
      headerRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '143B64' },
          bgColor: { argb: '' }
        }
        cell.font = {
          bold: true,
          color: { argb: 'FFFFFF' },
          size: 13,
          name: "poppins"

        }
      })


      console.log(data)

      // Adding Data with Conditional Formatting
      data.forEach((d, i) => {
        let newval = []
        d.data.map((itttm, ind) => {
          if (headerIndex.indexOf(ind) != -1) {
            newval.push(itttm)
          }
        })

        console.log(d, i, newval, "datadatadatadatadata")
        let row = worksheet.addRow(newval);
        console.log("the row is =", row)
        // row.forEach((item,index)=>{
        // let rowcell=item.getCell(index+1)
        //     rowcell.font={
        //         name: "poppins"
        //     }
        // })
        row.font = {
          name: "poppins"
        }
        // let rowcell=row.getCell(i+1)
        // rowcell.font={
        //     name: "poppins"
        // }
        const color_dict = {
          "Approved": "2BC155",
          "Pending": "F5E949",
          "Rejected": "B03636",
          "Go": "2BC155",
          "No Go": "B03636"
        }
        let fos = row.getCell(13);
        console.log("the row is =", fos)

        let goNoGoColumn = row.getCell(10)
        let sds = row.getCell(11);
        let rfs = row.getCell(12);



        // let color = '';
        // if (+sales.value < 200000) {
        //     color = 'FF9999'
        // }

        console.log(sds, sds.value, color_dict[sds["Site Development Approval Status"]], "colorType")
        const borderStyle = {
          style: 1
        }
        goNoGoColumn.fill = {
          type: 'pattern',

          pattern: 'solid',
          fgColor: { argb: color_dict[goNoGoColumn.value] }
        }

        sds.fill = {
          type: 'pattern',
          border: borderStyle,
          pattern: 'solid',
          fgColor: { argb: color_dict[sds.value] }
        }

        rfs.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: color_dict[rfs.value] }
        }

        fos.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: color_dict[fos.value] }
        }
      }
      );

      worksheet.spliceRows(1, 1)

      workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
        saveAs(blob, title + '.xlsx');
        return false
      })
      return false
    },
    textLabels: {
      toolbar: {
        downloadCsv: "Download XLSX",
      },
    },
    // viewColumns: false,
    // rowsPerPage: rpp,

    rowsPerPage: counterPP,

    onViewColumnsChange: (changedColumn,action)=>{
      console.log(changedColumn,action,listColumns,"onViewColumnsChange")

      let cols=[]

      let indes=listColumns.findIndex(valee=>{
        if(valee.name==changedColumn){
          return valee.label
        }
         
      })
      

      console.log(cols,listColumns[indes].label,"onViewColumnsChange")
      customDispatch(LCLTTCOLReducerAction(listColumns[indes].label,action))
    },
    onTableChange: (action, state, data) => {
      console.log(action,"onViewColumnsChange");
      console.log(state,"onViewColumnsChange",data);
    },
    customBodyRender: (changedColumn,changedColumn1,action)=>{
      console.log(changedColumn,changedColumn1,action,"customBodyRender")
    },
    onChangeRowsPerPage: rowsPerPage => {
      // setState(rowsPerPage);
      console.log(rowsPerPage, rowsPerPage, counterPP, "rowsPerPage")
      setCountPP(rowsPerPage)
      console.log(rowsPerPage, rowsPerPage, counterPP, "rowsPerPage")
    },
    filter:viewFIl,
    search:search,
    rowsPerPageOptions: [15, 30, 45],
    onChangePage: (page) => {
      console.log("the row per page is=", "fetch",fetch,"totalCountDb",totalCountDb,"totalRow",totalRow,"page",page,"counterPP",(page + 1) * counterPP,totalRow < (page + 1) * counterPP)
      console.log(totalCountDb,totalRow,page,(page + 1) * counterPP,"inFlightchcker")
      let start=""
      let end=0
      console.log(newStart,"newStartnewStartnewStartnewStart")
      if (totalRow < (page + 1) * counterPP) {
        // console.log("/magenta","/magenta" + dynamic_dashboard,totalRow,(totalRow-((page + 1) * counterPP)+50))
        start="?sp="+newStart+"&cp="+45
        end=1
        newStart=newStart+50
        // customDispatch(magentaGetCSVReducerAction("/magenta" + dynamic_dashboard));
        // start=
        // console.log(totalRow,page,(page + 1) * counterPP,"inFlightchcker")
      }else{

        if(totalRow<totalCountDb){
          start="sp="+newStart+"&cp="+45
          end=1
          newStart=newStart+45
          // customDispatch(magentaGetCSVReducerAction("/inflight" + dynamic_dashboard+"?sp"+totalRow+"&cp="+(totalRow+50)));
        }
        // end=
      }

      console.log(start,"newStartnewStartnewStartnewStart")

      if (url == "inflight" && end==1) {
        console.log(start,"startstartstart")
        customDispatch(inflightGetCSVReducerAction("/inflight" + dynamic_dashboard+"?"+start));
      }
      if (url == "magenta" && end==1) {
        console.log(start,"startstartstart")
        customDispatch(magentaGetCSVReducerAction("/magenta" + dynamic_dashboard+"?"+start));
      }
      if (url == "networKDatabase" && end==1) {
        console.log(start,"startstartstart")
        customDispatch(networkGetCSVReducerAction("/networkDatabase" + dynamic_dashboard+"?"+start));
      }
      
      
      if (url == "listenno" && end==1) {
        // console.log(start,"startstartstart")
        // customDispatch(AccountReducerAction(listennoTicketApi+"?"+start,false,false));
        // customDispatch(AccountReducerAction(ranTracker+start));
        
        let Filleddata=customDispatch(LCLENOOReducerAction())
        console.log(Filleddata,"FilleddataFilleddata")
        // customDispatch(AccountReducerAction(listtroubleTicketApi + Filleddata,true));
        
        // console.log(start,"startstartstart")

        // console.log("no data please neglet")
        // if(filterCol!=""){
          customDispatch(AccountReducerAction(`${listennoTicketApi}${Filleddata}${Filleddata==""?"?":"&"}${start}`,false,false));
        // }
      }
      console.log(url == "listtt",end==1,url,end,listtroubleTicketApi+start+filterCol!=""?"&"+filterCol:"","checkckck")
      if (url == "listtt" && end==1) {

        let Filleddata=customDispatch(LCLTTReducerAction())
        console.log(Filleddata,"FilleddataFilleddata")
        // customDispatch(AccountReducerAction(listtroubleTicketApi + Filleddata,true));
        
        // console.log(start,"startstartstart")

        // console.log("no data please neglet")
        // if(filterCol!=""){
          customDispatch(AccountReducerAction(`${listtroubleTicketApi}${Filleddata}${Filleddata==""?"?":"&"}${start}`,false,false));
        // }
        // /
        // else{
        //   customDispatch(AccountReducerAction(listtroubleTicketApi+Filleddata,false,false));
        // }
        // customDispatch(AccountReducerAction(ranTracker+start));
      }
      
      


      // if (counterPP * (page + 1) == 180 * (fetch - 1)) {
      //   if (url == "mergenta") {

      //     // if (RowCount < (currentPage + 1) * counterPP) {
      //     //   customDispatch(magentaGetCSVReducerAction("/magenta" + dynamic_dashboard));
      //     // } else {
      //     //   if (totalCount > RowCount + 1) {
      //     //     customDispatch(magentaGetCSVReducerAction("/magenta" + dynamic_dashboard));
      //     //   }
      //     // }
      //     customDispatch(magentaGetCSVReducerAction("/magenta" + dynamic_dashboard + "?series=" + fetch))
      //     setSeries(fetch + 1)
      //     // totalRow

      //   }
      //   console.log(totalRow,page,(page + 1) * counterPP,"inFlightchcker")
      //   if (url == "inflight") {
      //     // totalRow

          
      //     console.log(totalCountDb,totalRow,page,(page + 1) * counterPP,"inFlightchcker")
      //     if (totalRow < (page + 1) * counterPP) {
      //       console.log(totalRow,page,(page + 1) * counterPP,"inFlightchcker")
      //     }else{
            
      //     }


      //     customDispatch(inflightGetCSVReducerAction("/inflight" + dynamic_dashboard + "?series=" + fetch))
      //     setSeries(fetch + 1)
      //   }
      //   if (url == "networKDatabase") {
      //     customDispatch(networkGetCSVReducerAction("/networkDatabase" + dynamic_dashboard + "?series=" + fetch))
      //     setSeries(fetch + 1)
      //   }
      //   if (url == "projectDataBase") {
      //     customDispatch(AccountReducerAction(asnurl))
      //   }
      // }
      // setPageNo(parseInt(page) + 1)
      console.log("the page is =", page, " the row per page is= ", counterPP)
    },
    // fixedHeader: true,
    fixedSelectColumn: true,
    // tableBodyMaxHeight: "600px",
    print: false,
    onFilterChange: (changedColumn, filterList) => {
      console.log("inside filter=", changedColumn, filterList);
    },
    onFilterDialogClose: (changedColumn, filterList) => {
      console.log("onFilterDialogClose filter=", changedColumn, filterList);
      if (setfilliopen != "") {
        setfilliopen(true);
      }
    },
    onFilterDialogOpen: (changedColumn, filterList) => {
      console.log("onFilterDialogClose filter=", changedColumn, filterList);
      if (setfilliopen != "") {
        setfilliopen(true);
      }
    },
    onFilterChipClose: () => {
      if (setfilliopen != "") {
        setfilliopen(true);
      }
    },

    onFilterChange: (changedColumn, filterList) => {
      console.log("inside filter=", changedColumn, filterList);
    },
    onTableChange: addtomap,

    customToolbar: toolbarButton,
    searchPlaceholder: "Search by Site ID",
    onFilterChange: (changedColumn, filterList) => {
      console.log("inside filter=", changedColumn, filterList);
    },
    selectableRows: false,
    responsive: "scroll",
    overflowY: "scroll",
    filterType: "dropdown",
    // responsive: "scrollfullHeight",
    fixedHeader: true,
    fixedFooter: true,
    textAlign: center,
    filter:false
  };

  return options;

}


export const moment_common_full_date_format = "MM/DD/YYYY HH:mm"


export function dbdatetomoment(date) {
  return moment(date, moment_common_full_date_format)
}

export let timeZones = [
  "Europe/Andorra",
  "Asia/Dubai",
  "Asia/Kabul",
  "Europe/Tirane",
  "Asia/Yerevan",
  "Antarctica/Casey",
  "Antarctica/Davis",
  "Antarctica/DumontDUrville", // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
  "Antarctica/Mawson",
  "Antarctica/Palmer",
  "Antarctica/Rothera",
  "Antarctica/Syowa",
  "Antarctica/Troll",
  "Antarctica/Vostok",
  "America/Argentina/Buenos_Aires",
  "America/Argentina/Cordoba",
  "America/Argentina/Salta",
  "America/Argentina/Jujuy",
  "America/Argentina/Tucuman",
  "America/Argentina/Catamarca",
  "America/Argentina/La_Rioja",
  "America/Argentina/San_Juan",
  "America/Argentina/Mendoza",
  "America/Argentina/San_Luis",
  "America/Argentina/Rio_Gallegos",
  "America/Argentina/Ushuaia",
  "Pacific/Pago_Pago",
  "Europe/Vienna",
  "Australia/Lord_Howe",
  "Antarctica/Macquarie",
  "Australia/Hobart",
  "Australia/Currie",
  "Australia/Melbourne",
  "Australia/Sydney",
  "Australia/Broken_Hill",
  "Australia/Brisbane",
  "Australia/Lindeman",
  "Australia/Adelaide",
  "Australia/Darwin",
  "Australia/Perth",
  "Australia/Eucla",
  "Asia/Baku",
  "America/Barbados",
  "Asia/Dhaka",
  "Europe/Brussels",
  "Europe/Sofia",
  "Atlantic/Bermuda",
  "Asia/Brunei",
  "America/La_Paz",
  "America/Noronha",
  "America/Belem",
  "America/Fortaleza",
  "America/Recife",
  "America/Araguaina",
  "America/Maceio",
  "America/Bahia",
  "America/Sao_Paulo",
  "America/Campo_Grande",
  "America/Cuiaba",
  "America/Santarem",
  "America/Porto_Velho",
  "America/Boa_Vista",
  "America/Manaus",
  "America/Eirunepe",
  "America/Rio_Branco",
  "America/Nassau",
  "Asia/Thimphu",
  "Europe/Minsk",
  "America/Belize",
  "America/St_Johns",
  "America/Halifax",
  "America/Glace_Bay",
  "America/Moncton",
  "America/Goose_Bay",
  "America/Blanc-Sablon",
  "America/Toronto",
  "America/Nipigon",
  "America/Thunder_Bay",
  "America/Iqaluit",
  "America/Pangnirtung",
  "America/Atikokan",
  "America/Winnipeg",
  "America/Rainy_River",
  "America/Resolute",
  "America/Rankin_Inlet",
  "America/Regina",
  "America/Swift_Current",
  "America/Edmonton",
  "America/Cambridge_Bay",
  "America/Yellowknife",
  "America/Inuvik",
  "America/Creston",
  "America/Dawson_Creek",
  "America/Fort_Nelson",
  "America/Vancouver",
  "America/Whitehorse",
  "America/Dawson",
  "Indian/Cocos",
  "Europe/Zurich",
  "Africa/Abidjan",
  "Pacific/Rarotonga",
  "America/Santiago",
  "America/Punta_Arenas",
  "Pacific/Easter",
  "Asia/Shanghai",
  "Asia/Urumqi",
  "America/Bogota",
  "America/Costa_Rica",
  "America/Havana",
  "Atlantic/Cape_Verde",
  "America/Curacao",
  "Indian/Christmas",
  "Asia/Nicosia",
  "Asia/Famagusta",
  "Europe/Prague",
  "Europe/Berlin",
  "Europe/Copenhagen",
  "America/Santo_Domingo",
  "Africa/Algiers",
  "America/Guayaquil",
  "Pacific/Galapagos",
  "Europe/Tallinn",
  "Africa/Cairo",
  "Africa/El_Aaiun",
  "Europe/Madrid",
  "Africa/Ceuta",
  "Atlantic/Canary",
  "Europe/Helsinki",
  "Pacific/Fiji",
  "Atlantic/Stanley",
  "Pacific/Chuuk",
  "Pacific/Pohnpei",
  "Pacific/Kosrae",
  "Atlantic/Faroe",
  "Europe/Paris",
  "Europe/London",
  "Asia/Tbilisi",
  "America/Cayenne",
  "Africa/Accra",
  "Europe/Gibraltar",
  "America/Godthab",
  "America/Danmarkshavn",
  "America/Scoresbysund",
  "America/Thule",
  "Europe/Athens",
  "Atlantic/South_Georgia",
  "America/Guatemala",
  "Pacific/Guam",
  "Africa/Bissau",
  "America/Guyana",
  "Asia/Hong_Kong",
  "America/Tegucigalpa",
  "America/Port-au-Prince",
  "Europe/Budapest",
  "Asia/Jakarta",
  "Asia/Pontianak",
  "Asia/Makassar",
  "Asia/Jayapura",
  "Europe/Dublin",
  "Asia/Jerusalem",
  "Asia/Kolkata",
  "Indian/Chagos",
  "Asia/Baghdad",
  "Asia/Tehran",
  "Atlantic/Reykjavik",
  "Europe/Rome",
  "America/Jamaica",
  "Asia/Amman",
  "Asia/Tokyo",
  "Africa/Nairobi",
  "Asia/Bishkek",
  "Pacific/Tarawa",
  "Pacific/Enderbury",
  "Pacific/Kiritimati",
  "Asia/Pyongyang",
  "Asia/Seoul",
  "Asia/Almaty",
  "Asia/Qyzylorda",
  "Asia/Qostanay", // https://bugs.chromium.org/p/chromium/issues/detail?id=928068
  "Asia/Aqtobe",
  "Asia/Aqtau",
  "Asia/Atyrau",
  "Asia/Oral",
  "Asia/Beirut",
  "Asia/Colombo",
  "Africa/Monrovia",
  "Europe/Vilnius",
  "Europe/Luxembourg",
  "Europe/Riga",
  "Africa/Tripoli",
  "Africa/Casablanca",
  "Europe/Monaco",
  "Europe/Chisinau",
  "Pacific/Majuro",
  "Pacific/Kwajalein",
  "Asia/Yangon",
  "Asia/Ulaanbaatar",
  "Asia/Hovd",
  "Asia/Choibalsan",
  "Asia/Macau",
  "America/Martinique",
  "Europe/Malta",
  "Indian/Mauritius",
  "Indian/Maldives",
  "America/Mexico_City",
  "America/Cancun",
  "America/Merida",
  "America/Monterrey",
  "America/Matamoros",
  "America/Mazatlan",
  "America/Chihuahua",
  "America/Ojinaga",
  "America/Hermosillo",
  "America/Tijuana",
  "America/Bahia_Banderas",
  "Asia/Kuala_Lumpur",
  "Asia/Kuching",
  "Africa/Maputo",
  "Africa/Windhoek",
  "Pacific/Noumea",
  "Pacific/Norfolk",
  "Africa/Lagos",
  "America/Managua",
  "Europe/Amsterdam",
  "Europe/Oslo",
  "Asia/Kathmandu",
  "Pacific/Nauru",
  "Pacific/Niue",
  "Pacific/Auckland",
  "Pacific/Chatham",
  "America/Panama",
  "America/Lima",
  "Pacific/Tahiti",
  "Pacific/Marquesas",
  "Pacific/Gambier",
  "Pacific/Port_Moresby",
  "Pacific/Bougainville",
  "Asia/Manila",
  "Asia/Karachi",
  "Europe/Warsaw",
  "America/Miquelon",
  "Pacific/Pitcairn",
  "America/Puerto_Rico",
  "Asia/Gaza",
  "Asia/Hebron",
  "Europe/Lisbon",
  "Atlantic/Madeira",
  "Atlantic/Azores",
  "Pacific/Palau",
  "America/Asuncion",
  "Asia/Qatar",
  "Indian/Reunion",
  "Europe/Bucharest",
  "Europe/Belgrade",
  "Europe/Kaliningrad",
  "Europe/Moscow",
  "Europe/Simferopol",
  "Europe/Kirov",
  "Europe/Astrakhan",
  "Europe/Volgograd",
  "Europe/Saratov",
  "Europe/Ulyanovsk",
  "Europe/Samara",
  "Asia/Yekaterinburg",
  "Asia/Omsk",
  "Asia/Novosibirsk",
  "Asia/Barnaul",
  "Asia/Tomsk",
  "Asia/Novokuznetsk",
  "Asia/Krasnoyarsk",
  "Asia/Irkutsk",
  "Asia/Chita",
  "Asia/Yakutsk",
  "Asia/Khandyga",
  "Asia/Vladivostok",
  "Asia/Ust-Nera",
  "Asia/Magadan",
  "Asia/Sakhalin",
  "Asia/Srednekolymsk",
  "Asia/Kamchatka",
  "Asia/Anadyr",
  "Asia/Riyadh",
  "Pacific/Guadalcanal",
  "Indian/Mahe",
  "Africa/Khartoum",
  "Europe/Stockholm",
  "Asia/Singapore",
  "America/Paramaribo",
  "Africa/Juba",
  "Africa/Sao_Tome",
  "America/El_Salvador",
  "Asia/Damascus",
  "America/Grand_Turk",
  "Africa/Ndjamena",
  "Indian/Kerguelen",
  "Asia/Bangkok",
  "Asia/Dushanbe",
  "Pacific/Fakaofo",
  "Asia/Dili",
  "Asia/Ashgabat",
  "Africa/Tunis",
  "Pacific/Tongatapu",
  "Europe/Istanbul",
  "America/Port_of_Spain",
  "Pacific/Funafuti",
  "Asia/Taipei",
  "Europe/Kiev",
  "Europe/Uzhgorod",
  "Europe/Zaporozhye",
  "Pacific/Wake",
  "America/New_York",
  "America/Detroit",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Indiana/Indianapolis",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Vevay",
  "America/Chicago",
  "America/Indiana/Tell_City",
  "America/Indiana/Knox",
  "America/Menominee",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/North_Dakota/Beulah",
  "America/Denver",
  "America/Boise",
  "America/Phoenix",
  "America/Los_Angeles",
  "America/Anchorage",
  "America/Juneau",
  "America/Sitka",
  "America/Metlakatla",
  "America/Yakutat",
  "America/Nome",
  "America/Adak",
  "Pacific/Honolulu",
  "America/Montevideo",
  "Asia/Samarkand",
  "Asia/Tashkent",
  "America/Caracas",
  "Asia/Ho_Chi_Minh",
  "Pacific/Efate",
  "Pacific/Wallis",
  "Pacific/Apia",
  "Africa/Johannesburg",
];


export const getMuiThemeCommon = (maxWidthAllow = "300px") =>

  createTheme({
    overrides: {
      height: "100%",
      'min-height': "100%"
    },
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            "min-height": "-webkit-fill-available"
          }
        }
      },




      MUIDataTable: {
        styleOverrides: {
          responsiveScroll:
          {
            height: "100% !important",
            'max-height': window.innerHeight - 200 + "px !important",
            'min-height': window.innerHeight - 200 + "px !important",
            // 'min-height': "100% !important"
          }
        },

      },
      //   MuiTableCell: {
      //     styleOverrides: {
      //       root:{
      //         outline:"2px solid red"
      //       },

      //   }
      // },
      MUIDataTableHeadCell: {
        styleOverrides: {
          //   root:{
          //     outline:"2px solid red"
          //   },
          sortActive: {
            color: "white"
          },
          fixedHeader: {
            top: "-3px !important"
          },
          toolButton:{
            "text-transform":"uppercase",
            margin:"0px",
            "min-width":"1psx"
          }
        }

      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            "line-height": "1 !important",
            padding: "0px 0px",

          },
        },
      },

      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            whiteSpace: "wrap",
            height: "fit-content",
            "max-width": "500px",
            "min-width": "fit-content",
            fontSize: 11,
            padding: "2px 2px !important",
            margin: 0,
            "text-align": "left",
          },
        },

      },
      // MuiToolbar: {
      //   styleOverrides: {
      //     root: {
      //       backgroundColor: '#f00',

      //     },
      //   },
      // },

      MuiButtonBase: {
        styleOverrides: {
          root: {
            padding: "5px 5px !important",
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            outline: "1.5px solid black"
          },
          head: {
            height: "fit-content",
            fontSize: 13,
            padding: 0,
            width: "fit-content",
            margin: "auto",
            "text-align": "left",
          },
        },
      },
      MUIDataTableSelectCell: {
        styleOverrides: {
          headerCell: {
            backgroundColor: "blue",
          },
        },
      },

      // MUIDataTableHeaderCell: {
      //   styleOverrides: {
      //     root: {
      //       maxWidth: maxWidthAllow,
      //       width : "300px"
      //     },
      //   },
      // },

      MUIDataTablePagination: {
        styleOverrides: {
          overflow: "hidden"
        }
      }
      ,
      MUIDataTablePagination: {
        styleOverrides: {
          root: {
            "font-size": "0.8rem"
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          root: {
            "font-size": "0.8rem"
          },
          toolbar: {
            paddingRight: "40px"
          }
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            "padding-right": "14px"
          },
        },
      },

      // MuiTableFooter: {
      //   styleOverrides: {

      //     root: {
      //       "& .MuiToolbar-root": {
      //         backgroundColor: "white",

      //         // backgroundColor: "red",
      //         height: 1,
      //         padding: " -1px",
      //         margin: "-9px",
      //         alignItems: "baseline",
      //         marginBottom: "-25px",
      //         overflow : "hidden",
      //         position : "sticky",
      //         bottom : 0,
      //         height: "50px",
      //       },
      //       " & .tss-jtlhu6-MuiTablePagination-root:last-child" : {
      //         overflow: "hidden",
      //       },
      //       " & .css-jtlhu6-MuiTablePagination-root:last-child" : {
      //         overflow: "hidden",
      //       },

      //       "& .tss-1taaxjc-MUIDataTable-paper": {
      //         boxShadow: "none",
      //         overflow: "hidden",
      //       },
      //     },
      //   },
      // },
      MuiTableFooter: {
        styleOverrides: {
          padding: 0,
        }
      }
    },
  });


export const commonzone = "America/North_Dakota/Center"


export function time_zonechanger(timezonech, formatting) {

  var momenttz = require('moment-timezone');
  var moment_date = moment().tz(timezonech).format(formatting)

  console.log(moment_date, "moment_datemoment_date")
  return moment_date;

}

export function tzonerefun(timezonech) {

  var momenttz = require('moment-timezone');
  var moment_date = moment().tz(timezonech)

  console.log(moment_date, "moment_datemoment_date")
  return moment_date;

}


export function fun_time_zonechanger(timezonech, date) {

  var momenttz = require('moment-timezone');
  var moment_date = moment(date).tz(timezonech)

  console.log(moment_date, "moment_date", "moment_date", "moment_datemoment_date")
  return moment_date;

}