import React from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { NetworkDatabaseUrl, ixModule, listtroubleTicketApi, mopDataApi, ranDashboard } from "../../../common/config";
import "../../../css/siteHistory.css"
import { AccountReducerAction, LCLixDashboardReducerAction, LCLranDashboardReducerAction, allMapSiteReducerAction } from "../../../store/actions/createTicketManageFields";
import { MopDatabaseAction } from "../../../store/actions/dashboardActions";
const RanSiteTracker = ({ siteStatus, from, addClass, setfullUrL, setReset, featureExport}) => {
    const dispatch = useDispatch()
    console.log("inside ran support ", siteStatus);
    let colorArray = ["text-primary  border-bottom border-primary", "border-bottom border-info text-info", "border-danger text-danger border-bottom", "text-success border-bottom border-success", "border-danger text-danger border-bottom", "text-success border-bottom border-success"]
    let newRancolorArray = ["text-primary  border-bottom border-primary", "border-bottom border-info text-info", "border-danger text-danger border-bottom", "text-success border-bottom border-success", "border-danger text-danger border-bottom", "text-success border-bottom border-success"]
    let dpccolorArray = ["bg-primary", "bg-secondary", "bg-danger", "bg-success", "bg-dark", "bg-info"]
    let dashboard = window.location.href
    let urlKey = dashboard.split("/")
    let dashboardType = urlKey.slice(-2, -1)
    let history = useHistory()
    console.log("the dashboard", dashboardType)

    const fetechFilterData = (id) => {
        if (dashboard.includes("network")) {
            dispatch(AccountReducerAction("/PAG/NetworkDatabase?status=" + id))
        }
        else if (dashboard.includes("support")) {
            console.log("false")
            dispatch(AccountReducerAction(ranDashboard + "?status=" + id))
        }
    }

    const arrFromObj={
        "network":["/PAG/network/database?status=","uniqueid","",""],
        "ran":["/PAG/ran/support/dashboard?status=","uniqueid","",""],
        "ix":["/PAG/ix/dashboard?status=","uniqueid","",""],
        "mop":["/PAG/Mop/database?fc=True&","subforien_field","=","dbKey"],
        "tt":["/PAG/listTroubleTicket?fc=True&","subforien_field","=","dbKey"]
    }


    const callForFilter=(from,a,b,c,d)=>{
        console.log(a+b+c+d,"propspropspropspropsprops")
        setReset(true)
        // history.push(a+b+c+d)
        if(from=="ran"){
            featureExport(ranDashboard + "?status=" + b)
            let Filleddata=dispatch(LCLranDashboardReducerAction({topUrl:`status=${b}`}))
            dispatch(AccountReducerAction(ranDashboard + Filleddata,true));
            // dispatch(AccountReducerAction(ranDashboard + "?status=" + b,false,true))
        }
        if(from=="ix"){
            featureExport(ixModule + "?status=" + b)
            let Filleddata=dispatch(LCLixDashboardReducerAction({topUrl:`status=${b}`}))
            dispatch(AccountReducerAction(ixModule + Filleddata,true));
            // dispatch(AccountReducerAction(ranDashboard + "?status=" + b,false,true))
        }
        if(from=="network"){
            dispatch(AccountReducerAction(NetworkDatabaseUrl+"?status=" + b))
        }
        if(from=="mop"){
            setfullUrL(mopDataApi + "?fc=True&" + b+c+d)
            featureExport(mopDataApi + "?fc=True&" + b+c+d)
            dispatch(MopDatabaseAction(mopDataApi + "?fc=True&" + b+c+d,true))
        }
        if(from=="tt"){
            setfullUrL(listtroubleTicketApi + "?fc=True&" + b+c+d)
            featureExport(listtroubleTicketApi + "?fc=True&" + b+c+d)
            dispatch(AccountReducerAction(listtroubleTicketApi + "?fc=True&" + b+c+d,true))
        }
    }

    // uniqueid

    return (
        <section className="siteHistory">
            {
                siteStatus.map((status, index) => {
                    console.log(status, "index", "status,index")
                    return status.visiblity ? <>
                        <ul className="partitions">
                            <li style={{ color: status.color }} key={status.uniqueid}>
                                {
                                    status.siteStatus
                                }
                            </li>
                            {
                                status.phases.map((phase) => {

                                    return phase.visiblity?
                                    // className="p-5"
                                    <span  style={{ color: "Black" }}>
                                        <li className="mt-addon1 " key={phase.uniqueid} onClick={()=>{callForFilter(from,arrFromObj[from][0],phase[arrFromObj[from][1]],arrFromObj[from][2]!=""?arrFromObj[from][2]:"",arrFromObj[from][3]!=""?phase[arrFromObj[from][3]]:"")}} style={{ "padding":"8px 0px" , cursor:"pointer","margin-top": "-0.75rem", display: "flex", justifyContent: 'space-between' }}>
                                        {/* <li className="mt-addon1 " key={phase.uniqueid} onClick={() => fetechFilterData(phase.uniqueid)} style={{ "margin-top": "-0.75rem", display: "flex", justifyContent: 'space-between' }}> */}
                                        <span>{phase.status}</span>
                                        <span className={`${addClass} ml-2`} style={{ color:phase.fontcolor, backgroundColor: phase.color, borderColor:phase.color.length==7?"black":phase.color }} >{
                                            phase.count
                                        }</span>
                                        </li>
                                    </span>
                                    :""

                                })
                            }
                        </ul></> : ""

                })
            }
        </section>
    );
};

export default RanSiteTracker;
