import { createTheme } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import React from 'react'
// import { ThemeProvider } from 'react-bootstrap'
import { ThemeProvider } from '@mui/material/styles';
import { advancdExportOnecustomDataTableOptionObject, customDataTableOptionObject, getMuiThemeCommon } from './variables'

const TableComponent = ({from,rowData,columns,addCustomToolbar,isDownload=false}) => {

  return (
    
    <ThemeProvider theme={getMuiThemeCommon()}>
      <MUIDataTable
        title={"Manage "+from}
        data={rowData}
        columns={columns}
        // options={customDataTableOptionObject(addCustomToolbar,isDownload)}
        options={advancdExportOnecustomDataTableOptionObject(addCustomToolbar,true,from+"_","",false,from,false)}
        />
    </ThemeProvider>
  )
}

export default TableComponent