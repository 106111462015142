import React, { Component, useEffect, useState } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
import { common } from '../../../../services/AdminService';
import { VendorScoreCardDashboard, firstNest, ranfilters, scoreCard, scorecardpiefilters, secondNest, secondStage } from '../../../../common/config';
import { AccountReducerAction, AccountReducerActionWithoutLoad, ChartStageWise, VendorScoreCardReducerAction, funVendorScoreCardDashboard, getBarChartData, getFilterDropDown, setLoaderAction } from '../../../../store/actions/createTicketManageFields';
import { useDispatch, useSelector } from 'react-redux';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import DatePicker from "react-datepicker";
//var CanvasJSReact = require('@canvasjs/react-charts');
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import swal from "sweetalert";
import moment from "moment";
import { MultiSelect } from 'react-multi-select-component';
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;


const Filter = ({ filtering, setfiltering, setDivBox }) => {

    const dispatch = useDispatch()


    const [legend, setLegend] = useState([])
    const [filterBadges, setFilterBadges] = useState([])
    // const [globalFilter, setGlobalFilter] = useState({
    //     "company": [],
    //     "vendor": [],
    //     "market": [],
    //     "chartType":""
    // })

    const [globalFilter, setGlobalFilter] = useState({
        "company": "",
        "vendor": "",
        "market": "",
        "chartType": ""
    })
    const [fstartdate, setfstartdate] = useState();
    const [fenddate, setfenddate] = useState();
    let [openFilter, setFilter] = useState(false)
    let [SecondGraph, setSecondGraph] = useState([])
    let [smartVal, setsmartVal] = useState({})
    let [AccountGraph, setAccountGraph] = useState("")
    let compOptionArry = []
    let marketArry = []
    let typeOfWorkArry = []
    let vendorsArry = []
    let psowArry = []
    let siteID = []
    let assignedEngineer = []
    let dayNightMop = []

    const [options, setoptions] = useState({
        animationEnabled: true,
        exportEnabled: false,
        theme: "light1", // "light1", "dark1", "dark2"
        title: {
            text: "SCORECARD"
        },
        data: [{
            type: "pie",
            indexLabel: "{label}: {y}%",
            startAngle: 0,
            dataPoints: []
        }]
    })


    const count = useSelector((state) => {
        console.log("the state is =", state);
        let filterObj = state.OneCommonResponse.filterDropDown
        console.warn("jxhbkjvbjacvkecfrwgvw=", state.OneCommonResponse.filterDropDown)
        if (filterObj && Object.keys(filterObj).length > 0) {

            // compOptionArry = filterObj?.companies.sort().map((item, index) => {
            //     // return [
            //     //     ...item.data.map({
            //     //         label: item.label, value: item.value, disabled: item.disabled
            //     //     })
            //     // ]
            //     return { label: item.label, value: item.value, disabled: item.disabled }
            // })


            // let newcompOptionArry = filterObj?.companies.sort().map((item, index) => {
            //     return [{label: item.label, value: item.value, disabled: item.disabled},...item.data]
            // })


            compOptionArry = compOptionArry.splice(0, compOptionArry.length);
            // console.log(compOptionArry,"compOptionArrycompOptionArry")
            // filterObj?.companies.map((item, index) => {
            //     compOptionArry.push({label: item.label, value: item.value, disabled: item.disabled})
            //     compOptionArry.push(...item.data)
            //     return [{label: item.label, value: item.value, disabled: item.disabled},...item.data]
            // })


            filterObj?.companies.sort().map((item, index) => {
                compOptionArry.push(<option value={item.label}>{item.label}</option>)
                // item.data.map((itm)=>{
                //     compOptionArry.push(<option value={itm.label}>{itm.label}</option>)
                // })
                // return vendorsArry.push(...item.data)
            })
            vendorsArry.splice(0, vendorsArry.length);

            filterObj?.vendors.sort().map((item, index) => {

                vendorsArry.push(<option value={item.vendorName}>{item.vendorName}</option>)
                // item.data.map((itm)=>{
                //     vendorsArry.push(<option value={itm.label}>{itm.label}</option>)
                // })
                // return vendorsArry.push(...item.data)
            })
            // filterObj?.vendors.map((item, index) => {
            //     vendorsArry.push({label: item.label, value: item.value, disabled: item.disabled})
            //     vendorsArry.push(...item.data)
            //     return [{label: item.label, value: item.value, disabled: item.disabled},...item.data]
            //     // return [{label: item.label, value: item.value, disabled: item.disabled},...item.data]
            // })


            // marketArry = filterObj?.market.map((item, index) => {
            //     return { label: item, value: item }
            // })

            marketArry = filterObj?.market.map((item, index) => {
                return <option value={item}>{item}</option>
            })

            // vendorsArry = filterObj?.vendors.sort().map((item, index) => {
            //     return { label: item.label, value: item.value, disabled: true, }
            // })





            console.log(compOptionArry, "compOptionArrycompOptionArry")

            typeOfWorkArry = filterObj?.showWise.map((item, index) => {
                return <option value={item}>{item}</option>
            })
            psowArry = filterObj?.plannedSow.map((item, index) => {
                return <option value={item}>{item}</option>
            })
            // vendorsArry = filterObj?.vendors.sort().map((item, index) => {
            //     return { label: item.label, value: item.value, disabled: true, }
            // })
            siteID = filterObj?.siteIdes.map((item, index) => {
                return <option value={item}>{item}</option>
            })
            dayNightMop = filterObj.dayNightMop.map((item, index) => {
                return <option value={item}>{item}</option>
            })
            assignedEngineer = filterObj.assignedEngineer.map((item, index) => {
                if (index == 0) {
                    return <option disabled selected value={""}>Select</option>
                }
                return <option value={item}>{item}</option>
            })
        }

        typeOfWorkArry.unshift(<option disabled selected value={""}>Select</option>)
        siteID.unshift(<option disabled selected value={""}>Select</option>)
        dayNightMop.unshift(<option disabled selected value={""}>Select</option>)
        assignedEngineer.unshift(<option disabled selected value={""}>Select</option>)
        psowArry.unshift(<option disabled selected value={""}>Select</option>)
        marketArry.unshift(<option disabled selected value={""}>Select</option>)
        vendorsArry.unshift(<option disabled selected value={""}>Select</option>)
        compOptionArry.unshift(<option disabled selected value={""}>Select</option>)


        // console.log(compOptionArry, "compOptionArrycompOptionArrycompOptionArry")

        let dropDown = {
            "siteId": { "label": "Site ID", "values": siteID, "dType": "S" },
            // "crewDataResult.crewLeadCompanyId": { "label": "GC Company", "values": <><MultiSelect className="text-dark" hasSelectAll={false} options={compOptionArry} onChange={(e) => { handleChange("company", e) }} value={globalFilter["company"]} /></>, "dType": "S" },
            // "crewDataResult.vendorId": { "label": "Hard Cost Vendor", "values": <><MultiSelect className="text-dark" hasSelectAll={false} options={vendorsArry} onChange={(e) => { handleChange("vendor", e) }} value={globalFilter["vendor"]} /></>, "dType": "S" },
            // "market": { "label": "Market", "values": <><MultiSelect className="text-dark" hasSelectAll={false} options={marketArry} onChange={(e) => { handleChange("market", e) }} value={globalFilter["market"]} /></> },
            // "crewDataResult.crewLeadCompanyId": { "label": "GC Company", "values": <><MultiSelect isMulti={false} className="text-dark" hasSelectAll={false} options={compOptionArry} onChange={(e) => { handleChange("company", e) }} value={globalFilter["company"]} /></>, "dType": "S" },
            // "crewDataResult.vendorId": { "label": "Hard Cost Vendor", "values": <><MultiSelect isMulti={false} className="text-dark" hasSelectAll={false} options={vendorsArry} onChange={(e) => { handleChange("vendor", e) }} value={globalFilter["vendor"]} /></>, "dType": "S" },
            // "market": { "label": "Market", "values": <><MultiSelect isMulti={false} className="text-dark" hasSelectAll={false} options={marketArry} onChange={(e) => { handleChange("market", e) }} value={globalFilter["market"]} /></> },
            "crewDataResult.crewLeadCompanyId": { "label": "Company", "values": <select value={globalFilter["company"]} onChange={(e) => { handleChangeValue("company", e) }}>{compOptionArry}</select> },
            "crewDataResult.vendorId": { "label": "Vendor", "values": <select value={globalFilter["vendor"]} onChange={(e) => { handleChangeValue("vendor", e) }}>{vendorsArry}</select> },
            "market": { "label": "Market", "values": <select value={globalFilter["market"]} onChange={(e) => { handleChangeValue("market", e) }}>{marketArry}</select> },
            "requestForm": { "label": "Chart Type", "values": <select value={globalFilter["chartType"]} onChange={(e) => { handleChangeValue("chartType", e) }}>{typeOfWorkArry}</select> },
            "plannedSow": { "label": "Planned SOW", "values": psowArry },
            "dayNightMop": { "label": "Day Night MOP", "values": dayNightMop },
            "assignedEngineer": { "label": "Assigned Engineer", "values": assignedEngineer, "dType": "S" }
        }



        return ({ filterObj, dropDown })
    })


    console.log(count, "cmpcountcountcount")


    const handleSliceClick = (e) => {
        // Check if the clicked data point has a URL property


        console.log(SecondGraph.indexOf(e.dataPoint.parent), SecondGraph, e.dataPoint.parent, "handleSliceClickhandleSliceClick")

        // if(["PunctualityRate"].indexOf(e.dataPoint.parent)!=-1){
        //   // props.setStageTwo(true)
        //   dispatch(ChartStageWise(secondStage + e.dataPoint.parent+props.filter,"two"))
        // }


        // dispatch(AccountReducerAction(firstNest + e.dataPoint.parent+props.filter))
        // dispatch(AccountReducerAction(secondNest + e.dataPoint.parent + "/" + "MobileComm" + props.filter))

        // props.setGlobalPieType(e.dataPoint.parent)



        // console.log('Clicked slice index:', clickedSliceIndex);
        // console.log(props, 'Cutstom data:', clickedSliceData);
        setFilter(true)
        // props.setType(true)



        dispatch(getBarChartData({}))

        // props.setCount(true)
        // props.settwoCount(true)
        // props.setThreeCount(true)
        // props.setfivercount(true)



        // if(["PunctualityRate","TroubleTicketRate"].indexOf(e.dataPoint.parent)!=-1){
        //   props.setFourCount(true)
        // }

        if (e.dataPoint.url) {
            // window.open(e.dataPoint.url, '_blank'); // Open URL in a new tab
        }
    };


    const handleEvent = (event, picker) => {
        console.log("dwedfwefdwerfw", picker.startDate.format('YYYY-MM-DD'));
        console.log("dwedfwefdwerfw", picker.endDate.format('YYYY-MM-DD'));
    }

    useEffect(() => {
        dispatch(AccountReducerActionWithoutLoad(scorecardpiefilters, false))
        let dataArr = []
        dispatch(setLoaderAction(true))
    }, [])





    const SetResetFilterGlobal = (e) => {
        e.preventDefault();

        console.log("fstartdate", fstartdate, "fstartdate")


        setGlobalFilter({
            "company": [],
            "vendor": [],
            "market": [],
            "chartType": ""
        })

        setFilterBadges([])

        setfenddate()
        setfstartdate()


        // setFilter(false)
        // props.setType(false)
        // props.setStageTwo(false)

        setfiltering("")

        setDivBox("")


        dispatch(funVendorScoreCardDashboard([]))
        dispatch(VendorScoreCardReducerAction(VendorScoreCardDashboard))
        // dispatch(getBarChartData({}))/
    }
    const SetFilterGlobal = (e) => {
        e.preventDefault();

        console.log("fstartdate", fstartdate, "fstartdate")


        if (fstartdate != undefined && fenddate == undefined) {
            swal("Oops", "Please select End Date", "info");
            return;
        }
        if (fenddate != undefined && fstartdate == undefined) {
            swal("Oops", "Please select Start Date", "info");
            return;
        }


        console.log(globalFilter, "globalFilter")
        // dateRange[0].startDate = formatDate(dateRange[0].startDate);
        // dateRange[0].endDate = formatDate(dateRange[0].endDate);
        // console.log("the filtered data=", dateRange);


        let badgemaker = []

        // let finalcompany = globalFilter["company"].map((itm) => {
        //     badgemaker.push(<p className='badge badge-info'>{itm.value}</p>)
        //     return encodeURIComponent(itm.value)
        // })
        // let finalvendor = globalFilter["vendor"].map((itm) => {
        //     badgemaker.push(<p className='badge badge-info'>{itm.value}</p>)
        //     return encodeURIComponent(itm.value)
        // })
        // let finalmarket = globalFilter["market"].map((itm) => {
        //     badgemaker.push(<p className='badge badge-info'>{itm.value}</p>)
        //     return encodeURIComponent(itm.value)
        // })






        // console.log(finalcompany.join("_filter_"), finalvendor.join("_filter_"), finalmarket.join("_filter_"), "finalcompany,finalvendor,finalmarket")

        let strFilter = ""

        let chartCheck = false

        let otherCheck = false

        if (globalFilter["chartType"] && globalFilter["chartType"] != "") {
            if (strFilter != "") {
                strFilter = strFilter + "&chartType=" + globalFilter["chartType"]
            }
            if (strFilter == "") {
                strFilter = "?chartType=" + globalFilter["chartType"]
            }


            chartCheck = true
        }






        if (globalFilter["company"] && globalFilter["company"] != "") {
            if (strFilter != "") {
                strFilter = strFilter + "&company=" + globalFilter["company"]
            }
            if (strFilter == "") {
                strFilter = "?company=" + globalFilter["company"]
            }

            otherCheck = true
        }


        if (globalFilter["vendor"] && globalFilter["vendor"] != "") {
            if (strFilter != "") {
                strFilter = strFilter + "&vendor=" + globalFilter["vendor"]
            }
            if (strFilter == "") {
                strFilter = "?vendor=" + globalFilter["vendor"]
            }

            otherCheck = true
        }


        if (globalFilter["market"] && globalFilter["market"] != "") {
            if (strFilter != "") {
                strFilter = strFilter + "&market=" + globalFilter["market"]
            }
            if (strFilter == "") {
                strFilter = "?market=" + globalFilter["market"]
            }

            otherCheck = true
        }
        // globalFilter["market"].map((itm) => {
        //     badgemaker.push(<p className='badge badge-info'>{itm.value}</p>)
        //     return encodeURIComponent(itm.value)
        // })

        // if (finalcompany.length > 0) {
        //     let newstrFilter = (strFilter != "" ? "&" : "?") + "company=" + finalcompany.join("_filter_")
        //     strFilter = strFilter + newstrFilter
        // }

        // if (finalvendor.length > 0) {
        //     let newstrFilter = (strFilter != "" ? "&" : "?") + "vendor=" + finalvendor.join("_filter_")
        //     strFilter = strFilter + newstrFilter
        // }

        // if (finalmarket.length > 0) {
        //     let newstrFilter = (strFilter != "" ? "&" : "?") + "market=" + finalmarket.join("_filter_")
        //     strFilter = strFilter + newstrFilter
        // }


        console.log(strFilter, "strFilterstrFilter")


        if (otherCheck == false && chartCheck == true) {
            // swal("OOPS", "You apply chart wise filter but you not select any market, vendor or company this combination is not allowed", "error");
            swal("OOPS", "You need to select market, vendor, or company when applying filter in chart to ensure proper data display.", "error");
            return
        }


        if (fstartdate != undefined && fenddate != undefined) {
            let start = moment(fstartdate).format("MM-DD-YYYY");
            let end = moment(fenddate).format("MM-DD-YYYY");
            let newstrFilter = (strFilter != "" ? "&" : "?") + "start=" + start + "&end=" + end
            strFilter = strFilter + newstrFilter

            badgemaker.push(<p className='badge badge-info'>{moment(fstartdate).format("MM/DD/YYYY")}</p>)
            badgemaker.push(<p className='badge badge-info'>{moment(fenddate).format("MM/DD/YYYY")}</p>)

        }
        setFilterBadges(badgemaker)


        setDivBox("")
        setfiltering(strFilter)

        dispatch(funVendorScoreCardDashboard([]))
        dispatch(VendorScoreCardReducerAction(VendorScoreCardDashboard + strFilter))

        // console.log("finalApiCallerUrl", strFilter)

        // props.setFilter(strFilter)
        // let currentUrl = window.location.href;
        // props.setType(false)


        // dispatch(getBarChartData({}))
    };
    let currentDate = new Date()
    let previousDate = currentDate
    previousDate.setMonth(currentDate.getMonth() - 1)
    // let handleChange = (dropDownName, e) => {


    //     console.log(dropDownName, "globalFilter")


    //     setGlobalFilter({
    //         ...globalFilter,
    //         [dropDownName]: e
    //     })

    // }



    let handleChangeValue = (dropDownName, e) => {
        smartVal[dropDownName] = e.target.value
        setGlobalFilter({
            ...globalFilter,
            [dropDownName]: e.target.value
        })
        const queryString = Object.keys(smartVal).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(smartVal[key])}`).join('&');

        let trl = scorecardpiefilters + "?" + queryString

        dispatch(getFilterDropDown({}))
        dispatch(AccountReducerActionWithoutLoad(trl, true))
    }
    let handleChange = (dropDownName, e) => {
        console.log("smartVal", smartVal, e.map((itm) => { return itm.value }), "smartVal")
        smartVal[dropDownName] = e.map((itm) => { return itm.value })
        console.log("smartVal", smartVal, "smartVal")
        const queryString = Object.keys(smartVal).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(smartVal[key])}`).join('&');
        console.log(queryString);
        console.log(smartVal, "smartVal")
        let trl = scorecardpiefilters + "?" + queryString
        console.log(trl, "trltrl")

        dispatch(getFilterDropDown({}))
        dispatch(AccountReducerActionWithoutLoad(trl, true))
        console.log(dropDownName, "globalFilter")
        setGlobalFilter({
            ...globalFilter,
            [dropDownName]: e
        })

    }


    console.log(globalFilter, "globalFilter")


    const dateMyStyle = {
        border: "2px solid #1d548f !important",
        height: "24px",
        borderRadius: "4px !important"
    }

    let index = 0
    return (
        <>
            {filterBadges?.length > 0 ? <p style={{ color: "black", border: "0px solid black" }} className={"badge"}>Selected Filter : &nbsp;&nbsp;</p> : ""}{filterBadges}

            <div className='row'>

                <div className='col-md-2'>
                    <label style={{ color: "white" }}>{count.dropDown["market"]["label"]}</label>
                    {/* <input className='form-control' onChange={(e) => handleChange("market", e)} list={"market"}
            style={{ height: "24px", border: "2px solid #1d548f !important", borderRadius: "4px !important" }} /> */}
                    {/* <datalist id={"market"}> */}
                    {count.dropDown["market"]["values"]}
                    {/* </datalist> */}

                </div>
                <div className='col-md-2'>
                    <label style={{ color: "white" }}>{count.dropDown["crewDataResult.vendorId"]["label"]}</label>
                    {/* <input className='form-control' onChange={(e) => handleChange("market", e)} list={"vendor"}
            style={{ height: "24px", border: "2px solid #1d548f !important", borderRadius: "4px !important" }} /> */}
                    {/* <datalist id={"vendor"}> */}
                    {count.dropDown["crewDataResult.vendorId"]["values"]}
                    {/* </datalist> */}



                </div>



                <div className='col-md-2'>
                    <label style={{ color: "white" }}>{count.dropDown["crewDataResult.crewLeadCompanyId"]["label"]}</label>
                    {/* <input className='form-control' onChange={(e) => handleChange("company", e)} list={"companny"}
            style={{ height: "24px", border: "2px solid #1d548f !important", borderRadius: "4px !important" }} /> */}
                    {/* <datalist id={"companny"}> */}
                    {count.dropDown["crewDataResult.crewLeadCompanyId"]["values"]}
                    {/* </datalist> */}

                </div>


                <div className='col-md-2'>
                    <label style={{ color: "white" }}>{count.dropDown["requestForm"]["label"]}</label>
                    {count.dropDown["requestForm"]["values"]}
                </div>




                <div className='col-md-3'>
                    <label style={{ color: "white" }}>Date</label>
                    <div
                        style={{
                            width: "auto",
                            display: "flex",
                            alignItems: "center",
                            margin: "0px"
                        }}>
                        <DatePicker
                            placeholderText="MM/DD/YYYY"
                            id="color-of-datepicker"
                            className="date-picker-changes "
                            popperPlacement='bottom'
                            selected={fstartdate}
                            autoComplete={"off"}
                            onChange={(e) => {
                                setfstartdate(e);
                                // setfenddate(e);
                            }}
                            dateFormat="MM/dd/yyyy"
                        />
                        <DatePicker
                            placeholderText="MM/DD/YYYY"
                            className="date-picker-changes mx-1"
                            id="color-of-datepicker"
                            popperPlacement='bottom'
                            autoComplete={"off"}
                            selected={fenddate}
                            onChange={(e) => {
                                setfenddate(e);
                            }}
                            dateFormat="MM/dd/yyyy"
                        />

                    </div>

                </div>


                <div className='col-md-1 d-flex flex-row '>
                    <button
                        className="col-md-4 btn btn-primary btn-xs mx-2 py-2 my-3 d-flex justify-content-center align-items-center"
                        style={{ position: "absolute", left: "-10px", padding: "4px", bottom: "-15px", marginTop: "120px!important;", backgroundColor: "rgb(220 220 220)", color: "black" }}
                        // onClick={() => setDatePicker(!showDatePicker)}
                        onClick={(e) => SetFilterGlobal(e)}
                    >
                        {/* Filter */}

                        <i class="fa fa-filter mx-2"></i>
                    </button>
                    <button
                        className="col-md-4 btn btn-primary btn-xs mx-2 py-2 my-3 d-flex justify-content-center align-items-center"
                        style={{ position: "absolute", right: "15px", padding: "4px", bottom: "-15px", marginTop: "120px!important;", backgroundColor: "rgb(220 220 220)", color: "black" }}
                        // onClick={() => setDatePicker(!showDatePicker)}
                        onClick={(e) => SetResetFilterGlobal(e)}
                    >
                        {/* Reset */}
                        
                        <i class="fa fa-refresh mx-2"></i>
                    </button>
                </div>
            </div>
        </>
    );
};


export default Filter




