import React, { useEffect, useState } from 'react'
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { center, currentUrl, google_map_api } from '../../../../common/variables';
import PlaceIcon from '@mui/icons-material/Place';
import { QrCode } from '@mui/icons-material';
import marker_image from "../../../../images/marker.png";
// "" '../../../../images';
import { useDispatch, useSelector } from 'react-redux';
import { store } from '../../../../store/store';
const AdvOneSiteMapView = ({ siteDetail }) => {

  console.log(siteDetail || siteDetail.length!=0,siteDetail,siteDetail.length,siteDetail.length!=0,"filteredMapData in MapView")
  let filteredMapData = siteDetail
  const dispatch = useDispatch()
  const google = window.google
  let dropDownId = []
  let sitesall = []
  const [selected, setSelected] = useState({});
  const [Centernotload, setCenternotload] = useState(true);
  const [center, setCenter] = useState();
  let openedPage = currentUrl.split("PAG/")
  console.log("vfdvdfv=", openedPage[1], "==dwcferfv==", openedPage[1] == "ran/support/dashboard")
  // console.log(store.getState(), "store")
  const onSelect = item => {
    setSelected(item);
  }

  const google_marker = {
    "yellow": "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png",
    "blue": "https://maps.gstatic.com/mapfiles/ms2/micons/blue-dot.png",
    "red": "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
    "gray": "https://maps.gstatic.com/mapfiles/ms2/micons/msmarker.shadow.png",
    "lightgreen": "http://maps.google.com/mapfiles/ms/icons/green-dot.png"
  }
  const status_color = {
    "Go": "badge badge-success",
    "Pending": "badge pending-color",
    "No Go": "badge badge-danger"
  }
  const containerStyle = {
    width: "100%",
    height: "400px",
    position: "initial",
  };


  if (Centernotload) {

    if(siteDetail){


      setCenter({
        lat: siteDetail.latitude,
        lng: siteDetail.longitude
      })
      setCenternotload(false)
    }
  }


  console.log(center,"centercenter")




  return (
    <>
      <div>
        <LoadScript googleMapsApiKey={google_map_api}>
          <GoogleMap
            mapContainerStyle={containerStyle}

            center={center}
            zoom={8}
          >

            {/* {console.log("siteDetail",siteDetail,siteDetail.length!=0,"siteDetailsiteDetailsiteDetail")} */}
            {siteDetail?

            <Marker
              position={{ lat: siteDetail.latitude, lng: siteDetail.longitude}}
              PinView={{ glyphColor: 'white', }}
              icon={{ url: google_marker["red"] }}
              text="My Marker"
              title={siteDetail.siteId + ", " + siteDetail.siteAddress}
              description='bye'
              onClick={() => onSelect(siteDetail)}
              slug={"Current location"}
              name={"Current location"}>

            </Marker>
            :""}
            {
              // callforcreate(siteDetail)
              //  => {
              //         callforcreate(onedata)
              //       })}
              //       {/* {marker.data[0].latitude != undefined && marker.data[0].longitude != undefined ?

              //          : ""} */}
              //     </>))
            }
            {console.log(selected,selected.length!=0,"selectedselectedselected")}
            {

              selected ?
              (
                selected.latitude  && selected.longitude  ? <InfoWindow
                  position={{
                    lat: selected.latitude,
                    lng: selected.longitude,
                  }}
                  clickable={true}
                  onCloseClick={() => setSelected({})}
                >{
                    <>
                      <p className='text-black'>
                        {/* Ticket Id: {selected.ticketId}<br />
                        Type of Work: {selected.typeOfWork}<br /> */}
                        {`${selected.siteId},${selected.siteAddress}`}
                        {/* Project Id: {selected.projectId}<br />
                        Project Code: {selected.projectCode}<br /> */}
                        {/* Bucket: <label>{selected.ranBucket}</label><br />
                        Status: <label>{selected.ranStatus}</label><br /> */}
                      </p>
                    </>
                  }
                </InfoWindow>
                  : ""
              )
              :""
            }

          </GoogleMap>
        </LoadScript>
      </div>
    </>
  )
}

export default AdvOneSiteMapView