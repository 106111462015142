import React from 'react'

const KPIDegratedSite = () => {
  return (
    <>
        <h4>KPI Degraded Sites Dashboard</h4>
    </>
  )
}

export default KPIDegratedSite