import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { addAcccount, addFDatatoran, allTechnologyApi, getAllDropDown, getAllDropDownList, goNoGoAction, managEngineer, managTMOEngineer, rfApprovalActionChange } from '../../../../common/config';
import { addAcccountReducerAction, TierListReducerAction } from '../../../../store/actions/createTicketManageFields';
// import { addAcccountReducerAction, updateAcccountReducerAction } from '../../../components/vendor/'
const AssignedEngineer = (props) => {

  const { register, setValue, handleSubmit, getValues, watch, reset, formState: { errors } } = useForm({})
  const dispatch = useDispatch();
  
  const [EngineerOpen, setEngineerOpen] = useState([]);
  const [eroor, setError] = useState(false);
  

  console.log(props, "propspropspropsprops")

  if (props.type == "Edit") {
    // setValue("value",props.oldData.optionValue) 
  }

  let engineerArray=[];


  const projectData = useSelector((state) => {
    console.log("the State is=", state);


    
    
    let tier = state.CommonResponse.listtier
    if (tier && tier.length != 0) {
      engineerArray = tier.map((item) => {
        return <option>{item.name}</option>;
      })
    }
    return { engineerArray,tier };
  });

  
  const findengineer = (id) => {
    console.log("findengineer",projectData.tier,id,projectData.tier.findIndex(finndName=>finndName.name==id))
    console.log("findengineer",errors)
    delete errors.assignedEngineer
    if(projectData.tier.findIndex(finndName=>finndName.name==id)!=-1){
      setError(false)
    }else{
      setError(true)
    }
    setEngineerOpen(id)
  }

  const onSubmit = async (data) => {
    console.log("the data to submit is =", data)

    // data["dropDownId"]=props.parentuniqueId
    console.log(data, "data")
    if(eroor){
      return
    }


    if(props.from=="Ran Dashboard"){
      dispatch(addAcccountReducerAction(addFDatatoran + "/" + props.unique_id_up, data))
    }else{
      dispatch(addAcccountReducerAction(addFDatatoran + "/" + props.unique_id_up+"?ix=True", data))
    }
    // if(props.type=="Add"){
    // }
    // if(props.type=="Edit"){
    //   dispatch(updateAcccountReducerAction(getAllDropDownList,props.oldData.uniqueId,data))
    // }




    // propsprops

    // reset({})
    // setValue("value",'')
  }

  console.log(errors,"errorserrors")
  useEffect(() => {
    // reset({})
    // setValue("value",'')
    dispatch(TierListReducerAction(managEngineer))
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group col-md-12">

        {props.isShow}
        <label for="exampleFormControlSelect1">
          Assign Engineer
        </label>


        <input style={{ height: "32px" }}
          type="text"
          {...register("assignedEngineer", {
            required: "Please select Assigned Engineer"
          })}
          onBlur={(e) => { findengineer(e.target.value) }}
          list="engOpen"
          className="form-control"
          ID="inputAddress"
          defaultValue={EngineerOpen}
          placeholder="Please select Assigned Engineer"
        />

        <datalist id="engOpen">
          {projectData.engineerArray}
        </datalist>
        {eroor && (
          <p className="error">Please select Assigned Engineer</p>
        )}
      </div>
      <button type="submit" className="btn btn-primary btn-xs mt-3">
        Submit
      </button>
    </form>
  )
}

export default AssignedEngineer