import { height, padding } from "@mui/system";
import React from "react";
import { useSelector } from "react-redux";
import { getdatedatalistnext } from "../../../../common/variables";
import OneSiteMapView from "./OneSiteMapView";

import '../../../../css/borderCustom.css'
import CBN_RIOT_Logs from "./cbn_riot_logs";
import { useLocation } from "react-router-dom";
import DowntimeList from "./DowntimeList";


const MopManageProjectDetail = ({ranData}) => {
  const location = useLocation()
  let index = window.location.href.split("/")
  let dashboardType = index.slice(-2)[0]
  let siteDowntimeDetails = useSelector((state) => {
    return state.OneCommonResponse.singleSiteDownTime
  })


  console.log(siteDowntimeDetails?.tableData, "siteDowntimeDetails")
  let siteDetail = useSelector((state) => {
    console.log("the url =", state);
    // console.log(siteDetail.OneCommonResponse.singleSiteDownTime,'testingdata')
    // Ensure state.OneCommonResponse.singleSiteDownTime is an array
    // if (Array.isArray(state.OneCommonResponse.singleSiteDownTime)) {
    //   const uniqueBands = [...new Set(state.OneCommonResponse.singleSiteDownTime.map(item => item.Band))];
    //   console.log(uniqueBands,'uniqueBandsuniqueBands');
    // } else {
    //   console.error("state.OneCommonResponse.singleSiteDownTime is not an array.");
    // }


    // console.log("precheck=", state.OneCommonResponse.singleSite.preCheckRequest);
    // console.log("postcheck=", state.OneCommonResponse.singleSite.postCheckDelivered);

    // console.log("the server is njskchdkcdf=", dashboardType)

    let site = dashboardType == "ix" ? state.OneCommonResponse.singleIXSite : state.OneCommonResponse.singleSite
    console.log("the sites is the =", site);
    let pSow = "";
    let pSowArray = site["sowList"] ? site["sowList"].values : [];
    if (pSowArray && pSowArray.length > 0) {
      //  item['technology'].forEach((item)=>{
      //   tec +=item.label +','
      //  })
      // console.log("sgrthdhr", pSowArray.length);
      for (let i = 0; i < pSowArray.length; i++) {
        console.log("sgrthdhr", pSowArray[i]);
        pSow += pSowArray[i] + " , ";
      }
      //  item['technology']=tec
    }
    let ttidsData = []
    if (site.troubleTickets && site.troubleTickets.length > 0) {
      ttidsData = site.troubleTickets.map((itemInner) => {
        return itemInner.ttid;
      });
    }
    let sowList = "";
    let sowArray = site["sowDetails"] ? site["sowDetails"].values : [];
    if (sowArray && sowArray.length > 0) {
      //  item['technology'].forEach((item)=>{
      //   tec +=item.label +','
      //  })
      console.log("sgrthdhr", sowArray.length);
      for (let i = 0; i < sowArray.length; i++) {
        console.log("sgrthdhr", sowArray[i]);
        sowList += sowArray[i] + " , ";
      }
      //  item['technology']=tec

    }
    return {
      site, pSow, sowList, ttidsData, preCheckRequest: state.OneCommonResponse.singleSite.preCheckRequest,
      postCheckDelivered: state.OneCommonResponse.singleSite.postCheckDelivered, allData: state.OneCommonResponse.singleSiteDownTime
    };

  });




  console.log(siteDetail, " -the single sites is");




  
  return (
    <>
      <>
        <ul className="nav nav-pills mb-3 p-2" id="pills-tab" role="tablist">
          {/* <li className="nav-item">
            <a
              className="color-nav-link nav-link active"
              id="pills-home-tab"
              data-toggle="pill"
              href="#pills-home"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
            >
              Site Details
            </a>
          </li>

          <li className="nav-item">
            <a
              className="nav-link color-nav-link"
              id="pills-profile-tab"
              data-toggle="pill"
              href="#pills-profile"
              role="tab"
              aria-controls="pills-profile"
              aria-selected="false"
            >
              MAP View
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link color-nav-link"
              id="pills-contact-tab"
              data-toggle="pill"
              href="#pills-contact"
              role="tab"
              aria-controls="pills-contact"
              aria-selected="false"
            >
              RAN Historical
            </a>
          </li>
          {dashboardType == "ix" ?
            <li className="nav-item">
              <a
                className="nav-link color-nav-link"
                id="preriot-history-tab"
                data-toggle="pill"
                href="#preriot-history"
                role="tab"
                aria-controls="preriot-history"
                aria-selected="false"
              >
                Pre RIOT History
              </a>
            </li>
            : <></>}
          {location.pathname === '/PAG/ix/dashboard' && (<li className="nav-item">
            <a
              className="color-nav-link nav-link"
              id="pills-preval-tab"
              data-toggle="pill"
              href="#pills-preval"
              role="tab"
              aria-controls="pills-preval"
              aria-selected="false"
            >
              Pre Validation
            </a>
          </li>)}
          <li className="nav-item">
            <a
              className="nav-link color-nav-link"
              id="riot-history-tab"
              data-toggle="pill"
              href="#riot-history"
              role="tab"
              aria-controls="riot-history"
              aria-selected="false"
            >
              RIOT History
            </a>
          </li>

          <li className="nav-item">
            <a
              className="nav-link color-nav-link"
              id="cbn-history-tab"
              data-toggle="pill"
              href="#cbn-history"
              role="tab"
              aria-controls="cbn-history"
              aria-selected="false"
            >
              CBN History
            </a>
          </li> */}


          <li className="nav-item">
            <a
              className="nav-link color-nav-link"
              id="downtime-tab"
              data-toggle="pill"
              href="#downtime"
              role="tab"
              aria-controls="downtime"
              aria-selected="false"
            >
              DOWNTIME
            </a>
          </li>
          {/* {location.pathname === '/PAG/ix/dashboard' && (<li className="nav-item">
            <a
              className="color-nav-link nav-link"
              id="pills-postval-tab"
              data-toggle="pill"
              href="#pills-postval"
              role="tab"
              aria-controls="pills-postval"
              aria-selected="false"
            >
              Post Validation
            </a>
          </li>)} */}

        </ul>
        
       <div>
         
          <div
            className="tab-pane fade"
            id="downtime"
            role="tabpanel"
            style={{ height: "100%", overflow: "scroll" }}
            aria-labelledby="downtime-tab"
          >


            <DowntimeList siteDetail={ranData} from={"mop"}/>
          </div>

        </div>

      </>
    </>
  );
};

export default MopManageProjectDetail;
