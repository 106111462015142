import React, { useEffect, useState } from 'react'
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindowF,
} from "@react-google-maps/api";
import { center, currentUrl, google_map_api } from '../../../../common/variables';
import PlaceIcon from '@mui/icons-material/Place';
import { QrCode } from '@mui/icons-material';
import marker_image from "../../../../images/marker.png";
// "" '../../../../images';
import { useDispatch, useSelector } from 'react-redux';
import { store } from '../../../../store/store';

import './stylee.css'
import { UsInfowindow } from './infowinmemo';
import OneMapMarker from './OneMapMarker';

const AdvMapView = ({ filteredMapData }) => {

  console.log(filteredMapData, "filteredMapData in MapView")
  const dispatch = useDispatch()
  const google = window.google
  let dropDownId = []
  let sitesall = []
  const [selected, setSelected] = useState({});
  const [Info, setInfo] = useState(false);
  const [Centernotload, setCenternotload] = useState(true);
  const [center, setCenter] = useState();
  let openedPage = currentUrl.split("PAG/")
  // console.log("vfdvdfv=", openedPage[1], "==dwcferfv==", openedPage[1] == "ran/support/dashboard")
  // console.log(store.getState(), "store")
  const response = useSelector((state) => {
    sitesall = state.csvResponse.getAllSiteMap
    // console.log(sitesall, "sitesall")

    sitesall.map(oneSiit=>{
      // console.log(oneSiit,"oneSiit")
      if (Centernotload && oneSiit["_id"].latitude && oneSiit["_id"].longitude) {
        setCenter({
          lat: oneSiit["_id"].latitude,
          lng: oneSiit["_id"].longitude
        })
        setCenternotload(false)
      }
    })
    

    // console.log(center, "sitesall")
  })

  const google_marker = {
    "yellow": "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png",
    "blue": "https://maps.gstatic.com/mapfiles/ms2/micons/blue-dot.png",
    "red": "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
    "gray": "https://maps.gstatic.com/mapfiles/ms2/micons/msmarker.shadow.png",
    "lightgreen": "http://maps.google.com/mapfiles/ms/icons/green-dot.png"
  }
  const status_color = {
    "Go": "badge badge-success",
    "Pending": "badge pending-color",
    "No Go": "badge badge-danger"
  }
  const containerStyle = {
    width: "100%",
    height: "400px",
    position: "initial",
  };

  const onSelect = item => {
    // console.log(item,"item clicker please show")
    setSelected(item);
    // <UsInfowindow></UsInfowindow>
    setInfo(true)
  }

  function onCloseSelect(){
    // console.log(item,"item clicker please show")
    setSelected({});
    setInfo(false)
  }



  useEffect(() => { }, []);


  // const createMarker = (markerObj) => {
  //   const marker = new window.google.maps.Marker({
  //     position: { lat: markerObj.lat, lng: markerObj.lng },
  //     map: googleMap,
  //     icon: {
  //       url: markerObj.icon,
  //       // set marker width and height
  //       scaledSize: new window.google.maps.Size(50, 50)
  //     },
  //     title: markerObj.title
  //   });
   
  //   const infowindow = new window.google.maps.InfoWindow({ content: markerObj.info });
  //   marker.addListener("click", () => infowindow.open(googleMap, marker));
   
  //   return marker;
  // }



  const markShow = (marking) => {
    // if (marking.typeOfWork == "SI") {
    //   if (marking.group == "Development") {
    //     if (marking.rfstatus == "Pending" && marking.devstatus == "Pending") {
    //       return google_marker["blue"]
    //     }
    //     if (marking.rfstatus == "Approved" && marking.devstatus == "Approved" && marking.crewAssign != 2) {
    //       return google_marker["red"]
    //     }
    //     return google_marker["gray"]
    //   }
    //   if (marking.group == "Operation") {
    //     if (marking.rfstatus == "Pending" && marking.devstatus == "Pending") {
    //       return google_marker["blue"]
    //     }
    //     if (marking.rfstatus == "Approved" && marking.devstatus == "Approved" && marking.crewAssign != 2) {
    //       return google_marker["red"]
    //     }
    //     return google_marker["yellow"]
    //   }
    // }

    // if (marking.typeOfWork == "NSI") {
    //   if (marking.group == "Development") {
    //     if (marking.rfstatus == "Rejected" || marking.devstatus == "Rejected" || marking.crewAssign == 2) {
    //       return google_marker["gray"]
    //     }
    //     return google_marker["yellow"]
    //   }
    //   if (marking.group == "Operation") {
    //     if (marking.rfstatus == "Rejected" && marking.fopsstatus == "Rejected" || marking.crewAssign == 2) {
    //       return google_marker["gray"]
    //     }
    //     return google_marker["yellow"]
    //   }
    // }

    return google_marker["gray"]
    console.log(marking, "marking")
  }









  function markmaker(onest) {

    // console.log(onest.typeOfWork == "SI", "onest.typeOfWork")
    if (onest.typeOfWork == "SI") {
      if (onest.goNo == "Go") {
        return google_marker["red"]
      } else {
        return google_marker["blue"]
      }
    } else {
      return google_marker["yellow"]
    }
  }

  function callforcreate(onesite) {
    // console.log(onesite, onesite.data.length, "onesite.data")

    let abc=[]
    let interHtml

    console.log(onesite.data,onesite,onesite.data.length == 1,"onesite.data.lengthonesite.data.length")
    
    if (onesite.data.length == 1) {
      console.log(onesite.data[0],onesite.data[0].ticket ? onesite.data[0].typeOfWork=="SI"?google_marker["blue"]:google_marker["yellow"] : google_marker["gray"],"onesite.data.lengthonesite.data.length")
      return <>


      {/* <OneMapMarker ></OneMapMarker> */}
        <OneMapMarker
          position={{ lat: onesite.data[0].latitude, lng: onesite.data[0].longitude, }}
          PinView={{ glyphColor: 'white', }}
          icon={{ url: onesite.data[0].ticket ? onesite.data[0].typeOfWork=="SI"?onesite.data[0].typeOfWork=="SI"?google_marker["red"]:google_marker["blue"]:google_marker["yellow"] : google_marker["gray"] }}
          text="My Marker"
          title={onesite.data[0].siteId + ", " + onesite.data[0].address}
          description='bye'
          onesite={onesite.data}
          slug={"Current location"}
          name={"Current location"}>

        </OneMapMarker>

        {/* {console.log(selected,"selectedselectedselected")}
        {
          Info?selected.siteId &&
          (
            selected.latitude != undefined && selected.longitude != undefined ? 
            <UsInfowindow selected={selected}></UsInfowindow>
              : ""
          ):""
        } */}

      </>

    } else {

      let valee=false
      let goNo=false
      let newvalee=0
      let newvaleetype=""

      
      onesite.data.map((ittww)=>{
        if(ittww.ticket){
          newvaleetype=ittww.typeOfWork
          newvalee=newvalee+1
        }
        if(ittww.typeOfWork=="NSI"){
          console.log(ittww,"ittww.ticketIdittww.ticketId")
        }
        if(ittww.ticket){
          valee=true
        }
        if(ittww.goNo){
          if(ittww.goNo=="Go" && ittww.typeOfWork=="SI"){
            goNo=true
          }
        }

      })

      return <>

      {console.log(valee.newvalee,newvaleetype,"valee.newvalee,newvaleetype")}

      
        <OneMapMarker
          position={{ lat: onesite.data[0].latitude, lng: onesite.data[0].longitude, }}
          PinView={{ glyphColor: 'white', }}
          icon={{ url: valee ? (newvalee==1) ? newvaleetype=="SI" ? goNo? google_marker["red"] : google_marker["blue"] : google_marker["yellow"] :google_marker["lightgreen"]:google_marker["gray"] }}
          text="My Marker"
          description='bye'
          onesite={onesite.data}
          slug={"Current location"}
          name={"Current location"}>

        </OneMapMarker>

        {/* {console.log(selected, "selected[0]")} */}
        {/* {
          Info?selected && selected.data && selected.data[0].siteId &&
          (
            selected.data[0].latitude != undefined && selected.data[0].longitude != undefined ? <InfoWindowF
              position={{
                lat: selected.data[0].latitude,
                lng: selected.data[0].longitude,
              }}
              clickable={true}
              onCloseClick={() => onCloseSelect()}
            >{
                <>
                  <table>
                    <tr className='maptabletr'>
                      <th>Ticket Id</th>
                      <th>Site Id</th>
                      <th>Type of Work</th>
                      <th>Status</th>
                      <th>Location</th>
                    </tr>

                    {console.log(selected.data, "selected.data")}

                    {
                      interHtml=selected.data.map((oneitm) => {
                        // console.log(abc,oneitm,"abcabc")
                        if(oneitm.ticket){
                          abc.push(oneitm.siteId)
                          return <>
                          <tr className='maptabletr'>
                            <td className='maptabletd'>{oneitm.ticket?oneitm.ticket:"NA"}</td>
                            <td className='maptabletd'>{oneitm.siteId}</td>
                            <td className='maptabletd'>{oneitm.typeOfWork?oneitm.typeOfWork:"NA"}</td>
                            <td className='maptabletd'>{oneitm.goNo?oneitm.goNo:"NA"}</td>
                            <td className='maptabletd'>{oneitm.address}</td>
                        </tr>
                        </>
                      }
                      })
                    }
                    {
                      interHtml=selected.data.map((oneitm) => {
                        console.log(abc,oneitm,"abcabc")
                        if((abc.indexOf(oneitm.siteId)==-1)){
                          abc.push(oneitm.siteId)
                          return <>
                          <tr className='maptabletr'>
                            <td className='maptabletd'>{oneitm.ticket?oneitm.ticket:"NA"}</td>
                            <td className='maptabletd'>{oneitm.siteId}</td>
                            <td className='maptabletd'>{oneitm.typeOfWork?oneitm.typeOfWork:"NA"}</td>
                            <td className='maptabletd'>{oneitm.goNo?oneitm.goNo:"NA"}</td>
                            <td className='maptabletd'>{oneitm.address}</td>
                          </tr>
                          </>
                        }
                        })
                    }

                    {interHtml}
                  </table>

                </>
              }
            </InfoWindowF>
              : ""
          ):""
        } */}

      </>

    }
    // if (onesite.data.length == 2) {
    //   console.log(onesite.data[1].ticket, "ashutosh sir")
    //   let getter;
    //   if (onesite.data[1].ticket != undefined) {
    //     getter = onesite.data[1]
    //   }
    //   if (onesite.data[0].ticket != undefined) {
    //     getter = onesite.data[0]
    //   }

    //   console.log(getter, "getter", filteredMapData, filteredMapData.indexOf(getter.ticket))
    //   if (filteredMapData.length == 0 || (filteredMapData.length > 0 && filteredMapData.indexOf(getter.ticket) != -1)) {

    //     let oneform = <>
    //       <Marker
    //         position={{ lat: getter.latitude, lng: getter.longitude, }}
    //         PinView={{ glyphColor: 'white', }}
    //         icon={{ url: markmaker(getter) }}
    //         text="My Marker"
    //         // onClick={this.onMarkerClick}
    //         // onMouseOver={() => onSelect(onesitedetail)}
    //         onMouseOver={() => onSelect(getter)}

    //         slug={"Current location"}
    //         name={"Current location"}>


    //       </Marker>
    //       {
    //         selected.siteId &&
    //         (
    //           selected.latitude != undefined && selected.longitude != undefined ? <InfoWindow
    //             position={{
    //               lat: selected.latitude,
    //               lng: selected.longitude,
    //             }}
    //             clickable={true}
    //             onCloseClick={() => setSelected({})}
    //           >{
    //               <>
    //               <table>
    //                 <tr className='maptabletr'>
    //                   <th>Ticket Id</th>
    //                   <th>Site Id</th>
    //                   <th>Type of Work</th>
    //                   <th>Status</th>
    //                   <th>Location</th>
    //                 </tr>
    //                 <tr className='maptabletr'>
    //                   <td className='maptabletd'>{selected.ticket}</td>
    //                   <td className='maptabletd'>{selected.siteId}</td>
    //                   <td className='maptabletd'>{selected.typeOfWork}</td>
    //                   <td className='maptabletd'>{selected.goNo}</td>
    //                   <td className='maptabletd'>{selected.address}</td>
    //                 </tr>

    //               </table>
    //                 {/* <p className='text-black'>
    //                   Ticket Id: {selected.ticket}<br />
    //                   Type of Work: {selected.typeOfWork}<br />
    //                   Site Id: {selected.siteId}<br />
    //                   Location: {selected.address}<br />
    //                   Status: <label>{selected.goNo}</label><br /></p> */}
    //               </>
    //             }
    //           </InfoWindow>
    //             : ""
    //         )
    //       }
    //     </>

    //     console.log(oneform, "oneformoneform")
    //     return oneform
    //   } else {
    //     return <Marker
    //       position={{ lat: onesite.data[0].latitude, lng: onesite.data[0].longitude, }}
    //       PinView={{ glyphColor: 'white', }}
    //       icon={{ url: google_marker["gray"] }}
    //       text="My Marker"
    //       title={onesite.data[0].siteId + ", " + onesite.data[0].address}
    //       description='bye'
    //       slug={"Current location"}
    //       name={"Current location"}>

    //     </Marker>
    //   }

    // }

    // if (onesite.data.length > 2) {
    //   let setteddata = ""
    //   console.log(onesite.data, "ashutosh sir")
    //   let showdataeddata = 0
    //   let showdataeddataindex = 0
    //   let oneform = onesite.data.map((onesitedetail,index) => {
    //     if (onesitedetail.ticket != undefined) {
    //       if (filteredMapData.length == 0 || (filteredMapData.length > 0 && filteredMapData.indexOf(onesitedetail.ticket) != -1)) {
    //         showdataeddata = 1
    //         showdataeddataindex=index
    //         setteddata = setteddata + `Ticket Id: ${onesitedetail.ticket}, Type of Work: ${onesitedetail.typeOfWork}, Site Id: ${onesitedetail.siteId}, Location: ${selected.address}, Status: <label>${onesitedetail.goNo}</label>`
    //       }
    //     }
    //   })

    //   console.log(onesite.data,showdataeddataindex, "oneformoneformoneformoneform")



    //   return (showdataeddata == 1) ? <>
    //     <Marker
    //       position={{ lat: onesite.data[showdataeddataindex].latitude, lng: onesite.data[showdataeddataindex].longitude, }}
    //       PinView={{ glyphColor: 'white', }}
    //       icon={{ url: google_marker["lightgreen"] }}
    //       text="My Marker"
    //       // onClick={this.onMarkerClick}
    //       // onMouseOver={() => onSelect(onesitedetail)}
    //       onMouseOver={() => onSelect(onesite.data[showdataeddataindex])}

    //       slug={"Current location"}
    //       name={"Current location"}>


    //     </Marker>

    //     {
    //       selected.siteId &&
    //       (
    //         selected.latitude != undefined && selected.longitude != undefined ? 
    //         <InfoWindow
    //           position={{
    //             lat: selected.latitude,
    //             lng: selected.longitude,
    //           }}
    //           clickable={true}
    //           onCloseClick={() => setSelected({})}
    //         >{
    //           <>

    //           <table>
    //                 <tr className='maptabletr'>
    //                   <th>Ticket Id</th>
    //                   <th>Site Id</th>
    //                   <th>Type of Work</th>
    //                   <th>Status</th>
    //                   <th>Location</th>
    //                 </tr>
    //                 <tr className='maptabletr'>
    //                   <td className='maptabletd'>{selected.ticket}</td>
    //                   <td className='maptabletd'>{selected.siteId}</td>
    //                   <td className='maptabletd'>{selected.typeOfWork}</td>
    //                   <td className='maptabletd'>{selected.goNo}</td>
    //                   <td className='maptabletd'>{selected.address}</td>
    //                 </tr>

    //               </table>

    //               </>
    //           }
    //         </InfoWindow>
    //          : ""
    //       )
    //     }
    //   </> : ""
    // }

  }








  return (
    <>
      <div>
        <LoadScript googleMapsApiKey={google_map_api}>
          <GoogleMap
            mapContainerStyle={containerStyle}

            center={center}
            zoom={8}
          >
            {
              sitesall.map((marker) => callforcreate(marker))
              //  => {
              //         callforcreate(onedata)
              //       })}
              //       {/* {marker.data[0].latitude != undefined && marker.data[0].longitude != undefined ?

              //          : ""} */}
              //     </>))
            }

            {/* {
              selected.projectCode &&
              (
                selected.latitude != undefined && selected.longitude != undefined ? <InfoWindow
                  position={{
                    lat: selected.latitude,
                    lng: selected.longitude,
                  }}
                  clickable={true}
                  onCloseClick={() => setSelected({})}
                >{
                    <>
                      <p className='text-black'>
                        Ticket Id: {selected.ticketId}<br />
                        Type of Work: {selected.typeOfWork}<br />
                        Site Id: {selected.siteId}<br />
                        Project Id: {selected.projectId}<br />
                        Project Code: {selected.projectCode}<br />
                        Status: <label>{selected.goNo}</label><br /></p>
                    </>
                  }
                </InfoWindow>
                  : ""
              )
            } */}

          </GoogleMap>
        </LoadScript>
      </div>
    </>
  )
}

export default AdvMapView