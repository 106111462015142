import React, { Fragment, useState, useEffect } from "react";
import { SplitButton, ButtonGroup, Dropdown, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import { useForm } from "react-hook-form";
import { default as ReactSelect, components } from "react-select";
import { getValue } from "@mui/system";
import { Tab, Tabs } from 'react-bootstrap';
import { useHistory, useLocation } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import CommonCrew from "../../Forms/Ran Form/commoncrew";
import { commonzone, time_zonechanger } from "../../../../common/variables";
import { AccountReducerAction,addAcccountReducerAction,popUpUpdate } from "../../../../store/actions/createTicketManageFields";
import { manageutilizationApi } from "../../../../common/config";
const ManageUtilization = () => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({});

  const [closer, setCloser] = useState(false);
  const [one, setone] = useState(true);
  const dispatch =useDispatch();

  

  
  const onSubmit = async (data) => {
    console.log(data,"dataonsubmitmanageutilization")

    dispatch(addAcccountReducerAction(manageutilizationApi, data))

    setCloser(true)
    popUpUpdate({})


  }


  let data =useSelector((state)=>{

    console.log("full state",state)
    let manageUtilization=state.CommonResponse.manageUtilization?state.CommonResponse.manageUtilization[0]:{}
    if (state.popUpState) {
      console.log(state.popUpState.popstatus.status,closer,"popUpStatestatus")
      if ((state.popUpState.popstatus.status==204 && closer)||(state.popUpState.popstatus.status == 201 && closer)||(state.popUpState.popstatus.status == 200 && closer)) {
        setCloser(false)
        dispatch(popUpUpdate({}))
        dispatch(AccountReducerAction(manageutilizationApi))
      }
    }

    return {manageUtilization}


  })

  console.log(data.manageUtilization,"datadatadata")

  if(data.manageUtilization && one){
    setone(false)
    Object.keys(data.manageUtilization).forEach((key,value)=>{

      console.log(key,value,data.manageUtilization,"key,value")
      setValue(key,data.manageUtilization[key])
    })
    
  }

  useEffect(() => {
    dispatch(AccountReducerAction(manageutilizationApi))
  }, []);

  return (
    <Fragment>
      {/* <PageTItle activeMenu='validation' motherMenu='Form' /> */}

      <div style={{ display: "block", width: "100%", marginTop: "-1.4rem" }}>
        {/* <h4>React-Bootstrap Tab Component</h4> */}

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row mt-3">
            <div className="card " style={{ width: "100%", marginLeft: "10px" }}>

              <h4>UTRAN/IX SUPPORT</h4>
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div className="accordion-item">

                <div>
                    <div className="accordion-body">
                      <div className="form-row mt-2 p-2">
                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4">Precheck(%)</label>
                          <input
                            style={{ height: "32px" }}
                            type="number"
                            min={0}
                            max={100}
                            className="form-control"
                            id="inputEmail4"
                            name="siteid"
                            {...register("precheck")}
                          />
                        </div>

                      
                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4">Postcheck(%)</label>
                          <input
                            style={{ height: "32px" }}
                            type="number"
                            min={0}
                            max={100}
                            className="form-control"
                            id="inputEmail4"
                            name="siteid"
                            {...register("postcheck")}
                          />
                        </div>

                      
                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4">TSH/Scripting(%)</label>
                          <input
                            style={{ height: "32px" }}
                            type="number"
                            min={0}
                            max={100}
                            className="form-control"
                            id="inputEmail4"
                            name="siteid"
                            {...register("tshScripting")}
                          />
                        </div>

                      
                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4">IX Completed(%)</label>
                          <input
                            style={{ height: "32px" }}
                            type="number"
                            min={0}
                            max={100}
                            className="form-control"
                            id="inputEmail4"
                            name="siteid"
                            {...register("ixCompleted")}
                          />
                        </div>

                      
                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4">IX Rollback(%)</label>
                          <input
                            style={{ height: "32px" }}
                            type="number"
                            min={0}
                            max={100}
                            className="form-control"
                            id="inputEmail4"
                            name="siteid"
                            {...register("ixRollback")}
                          />
                        </div>

                      </div>
                    </div>
                  </div>
                        

                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <button type="submit" className="btn btn-primary btn-xs mx-2">
                      Submit
                    </button>
                  </div>
                  {/* <button onClick={() => raiseTT()} type="button" className="btn btn-primary btn-xs mx-2">
                    Raise Trouble Ticket
                  </button> */}
                </div >
              </div >
            </div >

            <div className="card " style={{ width: "100%", marginLeft: "10px" }}>
              
              <h4>CIQ & Scripting Utilization</h4>
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div className="accordion-item">

                  <div>
                    <div className="accordion-body">
                      <div className="form-row mt-2 p-2">
                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4">CIQ(%)</label>
                          <input
                            style={{ height: "32px" }}
                            type="number"
                            min={0}
                            max={100}
                            className="form-control"
                            id="inputEmail4"
                            name="siteid"
                            {...register("ciq")}
                          />
                        </div>

                      
                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4">PCI Awaited(%)</label>
                          <input
                            style={{ height: "32px" }}
                            type="number"
                            min={0}
                            max={100}
                            className="form-control"
                            id="inputEmail4"
                            name="siteid"
                            {...register("PCIAwaited")}
                          />
                        </div>

                      
                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4">CIQ-In progress(%)</label>
                          <input
                            style={{ height: "32px" }}
                            type="number"
                            min={0}
                            max={100}
                            className="form-control"
                            id="inputEmail4"
                            name="siteid"
                            {...register("ciqInProgress")}
                          />
                        </div>

                      
                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4">CIQ Completed(%)</label>
                          <input
                            style={{ height: "32px" }}
                            type="number"
                            min={0}
                            max={100}
                            className="form-control"
                            id="inputEmail4"
                            name="siteid"
                            {...register("ciqCompleted")}
                          />
                        </div>

                      
                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4">Scripting(%)</label>
                          <input
                            style={{ height: "32px" }}
                            type="number"
                            min={0}
                            max={100}
                            className="form-control"
                            id="inputEmail4"
                            name="siteid"
                            {...register("scripting")}
                          />
                        </div>

                      </div>
                    </div>
                  </div>
                        

                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <button type="submit" className="btn btn-primary btn-xs mx-2">
                      Submit
                    </button>
                  </div>
                  {/* <button onClick={() => raiseTT()} type="button" className="btn btn-primary btn-xs mx-2">
                    Raise Trouble Ticket
                  </button> */}
                </div >
              </div >
            </div >

            <div className="card " style={{ width: "100%", marginLeft: "10px" }}>
              <h4>KPI Utilization</h4>
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div className="accordion-item">

                <div>
                    <div className="accordion-body">
                      <div className="form-row mt-2 p-2">
                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4">1st Hour KPI(%)</label>
                          <input
                            style={{ height: "32px" }}
                            type="number"
                            min={0}
                            max={100}
                            className="form-control"
                            id="inputEmail4"
                            name="siteid"
                            {...register("firstHourKP")}
                          />
                        </div>
                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4">2nd Hour KPI(%)</label>
                          <input
                            style={{ height: "32px" }}
                            type="number"
                            min={0}
                            max={100}
                            className="form-control"
                            id="inputEmail4"
                            name="siteid"
                            {...register("secondHourKP")}
                          />
                        </div>
                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4">3rd Hour KPI(%)</label>
                          <input
                            style={{ height: "32px" }}
                            type="number"
                            min={0}
                            max={100}
                            className="form-control"
                            id="inputEmail4"
                            name="siteid"
                            {...register("thirdHourKP")}
                          />
                        </div>
                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4">4th Hour KPI(%)</label>
                          <input
                            style={{ height: "32px" }}
                            type="number"
                            min={0}
                            max={100}
                            className="form-control"
                            id="inputEmail4"
                            name="siteid"
                            {...register("fourthHourKP")}
                          />
                        </div>
                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4">8th Hour KP(%)</label>
                          <input
                            style={{ height: "32px" }}
                            type="number"
                            min={0}
                            max={100}
                            className="form-control"
                            id="inputEmail4"
                            name="siteid"
                            {...register("eighthHourKP")}
                          />
                        </div>
                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4">1st Day KPI(%)</label>
                          <input
                            style={{ height: "32px" }}
                            type="number"
                            min={0}
                            max={100}
                            className="form-control"
                            id="inputEmail4"
                            name="siteid"
                            {...register("firstDayKPI")}
                          />
                        </div>

                      
                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4">2nd Day KPI(%)</label>
                          <input
                            style={{ height: "32px" }}
                            type="number"
                            min={0}
                            max={100}
                            className="form-control"
                            id="inputEmail4"
                            name="siteid"
                            {...register("secondDayKPI")}
                          />
                        </div>
                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4">3rd Day KPI(%)</label>
                          <input
                            style={{ height: "32px" }}
                            type="number"
                            min={0}
                            max={100}
                            className="form-control"
                            id="inputEmail4"
                            name="siteid"
                            {...register("thirdDayKPI")}
                          />
                        </div>
                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4">4th Day KPI(%)</label>
                          <input
                            style={{ height: "32px" }}
                            type="number"
                            min={0}
                            max={100}
                            className="form-control"
                            id="inputEmail4"
                            name="siteid"
                            {...register("fourthDayKPI")}
                          />
                        </div>

                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4">5th Day KPI(%)</label>
                          <input
                            style={{ height: "32px" }}
                            type="number"
                            min={0}
                            max={100}
                            className="form-control"
                            id="inputEmail4"
                            name="siteid"
                            {...register("fifthDayKPI")}
                          />
                        </div>

                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4">6th Day KPI(%)</label>
                          <input
                            style={{ height: "32px" }}
                            type="number"
                            min={0}
                            max={100}
                            className="form-control"
                            id="inputEmail4"
                            name="siteid"
                            {...register("sixthDayKPI")}
                          />
                        </div>
                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4">Final 7th Day KPI(%)</label>
                          <input
                            style={{ height: "32px" }}
                            type="number"
                            min={0}
                            max={100}
                            className="form-control"
                            id="inputEmail4"
                            name="siteid"
                            {...register("seventhDayKPI")}
                          />
                        </div>

                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4">48 Hrs(%)</label>
                          <input
                            style={{ height: "32px" }}
                            type="number"
                            min={0}
                            max={100}
                            className="form-control"
                            id="inputEmail4"
                            name="siteid"
                            {...register("fortyEightHrs")}
                          />
                        </div>
                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4">Closeout(%)</label>
                          <input
                            style={{ height: "32px" }}
                            type="number"
                            min={0}
                            max={100}
                            className="form-control"
                            id="inputEmail4"
                            name="siteid"
                            {...register("closeout")}
                          />
                        </div>
                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4">On Air(%)</label>
                          <input
                            style={{ height: "32px" }}
                            type="number"
                            min={0}
                            max={100}
                            className="form-control"
                            id="inputEmail4"
                            name="siteid"
                            {...register("onAir")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                        

                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <button type="submit" className="btn btn-primary btn-xs mx-2">
                      Submit
                    </button>
                  </div>
                  {/* <button onClick={() => raiseTT()} type="button" className="btn btn-primary btn-xs mx-2">
                    Raise Trouble Ticket
                  </button> */}
                </div >
              </div >
            </div >
          </div>
        </form >

      </div >
    </Fragment >
  );
};

export default ManageUtilization;
