import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { OneSiteDatabase, OneSiteDatabaseProjectCode, OneSiteDatabaseProjectId, SSVDashboard, SSVDashboardAPI, managEngineer, siteDblistSiteId, vendorApi } from '../../../common/config';
import { AccountReducerAction, ClearProductCode, FetchSingleRecordAction, TierListReducerAction, addUserAction, clearGetSingleProject, clearGetSingleProjectDatabase, clearProjectId, popUpUpdate } from '../../../store/actions/createTicketManageFields';
import DatePicker from "react-datepicker";
import { MultiSelect } from 'react-multi-select-component';
import { useForm } from 'react-hook-form';
import CommonCrew from '../Forms/Ran Form/commoncrew';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const moment = require('moment');
const AddSSV = () => {

  let history = useHistory()
  let siteArray = [];
  let projectCodeArray=[];
  let allProjects = []
  let projectCodeOption = []
  let newTtempArray = []
  let siteInfo = []
  let projectIdArray = []
  let engineerArray = []
  let vStatusArry = []
  let dispatch = useDispatch()
  let [pncurrentValueId, setpncurrentValueId] = useState(false)
  let [rttDateError, setRttDateError] = useState(false)
  const [closer, setCloser] = useState(false)
  let [UUUDateError, setUUUDateError] = useState(false);
  let [siteError, setSiteError] = useState(false)
  let [rttDate, setRTTDate] = useState("");
  let [singleSitestate, setsingleSitestate] = useState([]);
  let [ctrCount, setCtrCount] = useState(1);
  let [unlockDate, setUnlockDate] = useState("")
  let [ssvSubmitDate, setSSVSubmitDate] = useState("")
  let [ssvApprovalDate, setSSVApprovalDate] = useState("")
  const [launchEngineer, setLaunchEngineerOpen] = useState([]);
  let [SelselectProjectCode, setSelProjectCode] = useState([])
  let [selectProjectId, setProjectId] = useState([])
  let [selectProjectCode, setProjectCode] = useState([])
  
  let [oneprid, setoneprid] = useState('')
  let [ssvStatus, setSSVStatus] = useState("")
  let [withoutedit, setwithoutedit] = useState("")
  let DbName = useRef([]);
  let [selectedSited, setSelectId] = useState()
  let [selectedProjectCode, setSelectProjectCode] = useState()
  let [regionState, setRegion] = useState()
  let [ringCode, setRingCode] = useState()
  let [selectedMarket, setMarket] = useState()
  let [selectedProjectId, setSelectProjectId] = useState()
  let count = useRef(1)
  let currentUrl = window.location.href
  let siteData = {}
  const { register, getValue, setValue, handleSubmit, setError, formState: { errors } } = useForm()
  let options = useSelector((state) => {
    console.log(state,"state.CommonResponsestate.CommonResponse")
    let vendorroledata = state.CommonResponse.vendor
    if (vendorroledata != 0 && currentUrl.includes("add")) {
      vStatusArry = vendorroledata.map((item) => {
        // console.log(ven, item.uniqueid, "ven==item.uniqueid")
        return <option value={item.uniqueid}>{item.vendorName}</option>
        // if(ven==item.uniqueid){
        //   return <option selected value={item.uniqueid}>{item.vendorName}</option>
        // }else{
        //   return <option value={item.uniqueid}>{item.vendorName}</option>
        // }
      })

    }

    if (state.popUpState) {
      console.log(state.popUpState.popstatus.status, closer, (state.popUpState.popstatus.status == 201 && closer) || (state.popUpState.popstatus.status == 200 && closer), "popUpStatestatus201")
      if ((state.popUpState.popstatus.status == 201 && closer) || (state.popUpState.popstatus.status == 200 && closer)) {
        // dispatch(popUpUpdate({}))
        // setCloser(false)
        setCloser(false);
        dispatch(popUpUpdate({}));
        dispatch(AccountReducerAction(SSVDashboardAPI))
        history.push("/PAG/ssv/dashboard")
      }
    }
    let fetchData = state.OneCommonResponse.singleIXSite
    if (fetchData && Object.keys(fetchData).length > 0) {
      console.log("nbjkbjhbq", fetchData)
      siteData = fetchData

      if (vendorroledata != 0) {
        vStatusArry = vendorroledata.map((item) => {
          if (fetchData.vendor == item.vendorName) {
            console.log("kjxbjhbalvahduofwjklfcwhrfw===", fetchData.vendor == item.vendorName)
            return <option selected value={item.uniqueid}>{item.vendorName}</option>
          }
          else {
            return <option value={item.uniqueid}>{item.vendorName}</option>
          }
          // console.log(ven, item.uniqueid, "ven==item.uniqueid")
          // if(ven==item.uniqueid){
          //   return <option selected value={item.uniqueid}>{item.vendorName}</option>
          // }else{
          //   return <option value={item.uniqueid}>{item.vendorName}</option>
          // }
        })

      }
      if (count.current == 1) {

        setValue("ssvPlanType", fetchData.ssvPlanType)
        setValue("projectType", fetchData.projectType)
        setValue("SiteCategory", fetchData.SiteCategory)
        // setValue("vendor",fetchData.vendor)  
        setValue("LaunchEngineer", fetchData.LaunchEngineer)
        setValue("UUUEngineer", fetchData.UUUEngineer)
        setValue("SSSNNPEng", fetchData.SSSNNPEng)

        if (fetchData.crew_status && fetchData.crew_status.name != undefined) {
          console.log("setDbName.current")
          DbName.current.push(fetchData.crew_status.name)
          DbName.current.push(fetchData.crew_status.crewType == "IX" ? "IXCrew" : "CXCrew")
          DbName.current.push(fetchData.crew_status["unqId"])
          setwithoutedit(false)
        }else{
          DbName.current.push("")
          DbName.current.push("IXCrew")
          DbName.current.push("")
          setwithoutedit(true)
        }
        // if (fetchData.cxCrewId.name != undefined) {
        //     console.log("setDbName.current")
        //     DbName.current.push(fetchData.cxCrewId.name)
        //     DbName.current.push(fetchData.cxCrewId.crewType=="IX"?"IXCrew":"CXCrew")
        //     DbName.current.push(fetchData.cxCrewId["_id"]["$oid"])
        // }
        console.log("jkbkjbkjbkjbcdsf======", DbName.current)
        // setsingleSitestate(siteInfo)
        setSelectId(fetchData.siteId)
        setMarket(fetchData.projectUniqueId[0].market)
        if (fetchData.projectUniqueId.length > 0) {
          setRegion(fetchData.projectUniqueId[0].region)
        }
        if (fetchData.projectUniqueId.length > 0) {
          setRingCode(fetchData.projectUniqueId[0].RingCode)
        }
        let projectCode = fetchData["projectUniqueId"].map((item) => item.projectCode)
        setSelectProjectCode(projectCode.join(","))
        let productId = fetchData["projectUniqueId"].map((item) => item.projectId)
        setSelectProjectId(productId.join(","))
        console.log("ghjvkjbhdsavcsdjvfd=", fetchData["siteId"])
        if (fetchData.ssvRttDate) {

          setRTTDate(new Date(fetchData.ssvRttDate))
        }
        if (fetchData.ssvUUUDate) {
          setUnlockDate(new Date(fetchData.ssvUUUDate))
        }

        count.current = 2
      }
    }

    allProjects = state.CommonResponse.startAwaitedSite
    // console.log("all projects is =",allProjects)
    if (allProjects && allProjects.length > 0) {

      siteArray = allProjects.map((project) => {
        // console.log("the project is =========",project)
        return <option>{project.siteId}</option>;
      });
    }
    projectCodeArray = state.OneCommonResponse.productCode
    if (projectCodeArray && projectCodeArray.length > 0) {
      projectCodeOption = projectCodeArray.map((item, index) => {


        console.log(SelselectProjectCode, item.projectCode, SelselectProjectCode.indexOf(item.projectCode), selectProjectCode.findIndex(abcd => { console.log(abcd); return item.projectCode == abcd.label }), "SelselectProjectCode")
        if (SelselectProjectCode.indexOf(item.projectCode) >= 0 && selectProjectCode.findIndex(abcd => {
          return item.projectCode == abcd.label
        }) == -1) {
          selectProjectCode.push({
            value: item.uniqueid,
            label: item.projectCode,
          })

        }
        return {
          value: item.uniqueid,
          label: item.projectCode,
        };
      })

    }
    projectIdArray = state.OneCommonResponse.productId
    if (projectIdArray && projectIdArray.length > 0) {
      let projectIdOption = projectIdArray.map((item, index) => {
        let availableId = []
        let anyArry = item.data
        availableId = item.data.map((id) => {
          return {
            label: id.projectId,
            group: item.projectCode,
            value: id.uniqueid
          }
        })

        newTtempArray.push({
          label: item.projectCode,
          value: item.projectCode,
          disabled: true,
        })
        newTtempArray.push(...availableId)

      })
    }
    siteInfo = state.csvResponse.singleSite;
    console.log("th e stacdsfcsd=", state)
    // console.log( siteInfo, siteInfo.length != 0, "ctrCount")
    if (ctrCount == 1 && siteInfo && siteInfo.length != 0) {
      console.log("the single site is =", siteInfo)
      setsingleSitestate(siteInfo)
      setCtrCount(2)
    }

    let tier = state.CommonResponse.listtier
    if (tier && tier.length != 0) {
      engineerArray = tier.map((item) => {
        return <option>{item.name}</option>;
      })
    }
    return { siteArray, projectCodeOption, vStatusArry, engineerArray }
  })
  const styles = {
    width: "400px",
  };
  const findDSiteId = (id) => {
    console.log(id, "findDSiteId", allProjects)
    
    // setcustomesite(id)
    // setoneprid('')
    // setppProjctId('')
    setProjectCode([])
    setSelProjectCode([])
    setsingleSitestate({})
    setSelectProjectId([])
    setProjectId([])
    dispatch(clearGetSingleProjectDatabase())
    dispatch(clearProjectId())
    dispatch(ClearProductCode())
    let getter=allProjects.filter((item) => item.siteId == id)
    if (id != undefined && getter.length>0) {
      //   setcustomesite(id)
      //   setProjectId([])
      setProjectCode([])
      setSelProjectCode([])
      //   setTech([])
      // findDProjectId(id, 1)

      // if(stateonce){
      //   setstateonce(false)
      //   dispatch(clearProjectId())
      // }

      setValue("siteId", id)
      let abc =
        setValue("siteUniqueId", allProjects.filter((item) => item.siteId == id)[0].uniqueSiteId)
      setSiteError(false)
      if (allProjects.length > 0)
        console.log("the respasfsdvgfdsbdtrh =", ...allProjects.filter((item) => item.siteId == id))
      let idObject = allProjects.filter((item) => item.siteId == id)
      if (idObject.length > 0) {
        // setond(true)
        dispatch(FetchSingleRecordAction(OneSiteDatabaseProjectCode, allProjects.filter((item) => item.siteId == id)[0].uniqueSiteId))
      }
    }
    else {
      setSiteError(true)
    }
  }
  const findDProjectId = (id, def = 0, pcode = []) => {
    console.log(id,"findDProjectId")
    // setCountRender(1)
    // console.log("projectCodeArray", id, projectCodeArray)
    // setppProjectCode(id)

    // setppProjctId('')
    // let selectedIds = []
    // let nselectedIds = ""
    // if (def == 0) {
    //   setProjectCode(id)
    //   selectedIds = id.map((item) => {
    //     return item.value
    //   })
    //   nselectedIds = selectedIds.join(",")
    // } else {
    //   let testpcode = []

    //   // testpcode=projectCodeArray.map((ittm)=>{
    //   //   if(pcode.indexOf(ittm.label)!=-1){
    //   //     return {
    //   //       label:ittm.label,
    //   //       value:ittm.value
    //   //     }
    //   //   }
    //   // })
    //   // setProjectCode(testpcode)
    //   nselectedIds = id
    // }
    // // console.log(OneSiteDatabase + id, id, projectCodeArray, "projectCodeArray")

    // setSelProjectCode(nselectedIds.split(","))
    // setValue("projectCode", nselectedIds)
    // setValue("projectcodeUniqueId", selectedIds.join(","))
    // dispatch(FetchSingleRecordAction(OneSiteDatabaseProjectId, nselectedIds))

    // setCountRender(1)
    setProjectCode(id)
    let selectedIds = []
    let selectedname = []
    selectedIds = id.map((item) => {
      selectedname.push(item.label)
      return item.value
    })

    if (id.length == 0) {
      // clearProjectId
      dispatch(clearProjectId())
    }

    // setSelProjectCodename(selectedname)


    console.log("projectCodeArray", selectedIds.join(","), id)
    let test = projectCodeArray.filter((item) => item.uniqueid == id)[0]
    console.log(test, "codertest")

    // if (test) {
    //   if (test.length > 0) {
    //     setppProjectCode(test.projectCode)
    //   }
    // }

    console.log(OneSiteDatabase + id, id, projectCodeArray, "projectCodeArray")
    setoneprid(id)
    // dispatch(FetchSingleRecordAction(networkOneSiteDatabaseprojectId, selectedIds))   //from project Database

    // dispatch(FetchSingleRecordAction(OneSiteDatabaseProjectId, nselectedIds))  // from network Database
    let newProjectIdSel = []
    let newIdProjectIdSel = []
    let temp_new_id = []
    selectProjectId.map((item) => {
      if (selectedname.indexOf(item.group) != -1) {
        newProjectIdSel.push(item)
        newIdProjectIdSel.push(item.value)

        temp_new_id.push(item.mainId)
      }
    })
    setProjectId(newProjectIdSel)
    // console.log(tech, newProjectIdSel, selectedIds, "techkatech")
    // setTecheer(newProjectIdSel)
    FetechAllDetail(newProjectIdSel)
    // findDProjectId(newProjectIdSel)


    setValue("projectId", newIdProjectIdSel.join(","))
    setValue("projectcodeUniqueId", selectedIds.join(","))


    dispatch(FetchSingleRecordAction(OneSiteDatabaseProjectId, selectedIds.join(",")))



  }
  console.log("njknbkcjabckjas -=== ", DbName.current)
  const findLaunchEngineer = (e) => {
    setLaunchEngineerOpen(e)
  }
  const FetechAllDetail = (id) => {
    // let test = projectIdArray.filter((item) => item.uniqueid == id)[0]
    // console.log(test, "test")
    // setPId(id)
    // setppProjctId(id)
    setProjectId(id)
    let selectedId = id.map((item) => {
      return item.value
    })
    // dispatch(FetchSingleRecordAction(ranDashboard, selectedId));
    dispatch(FetchSingleRecordAction(OneSiteDatabase, selectedId.join(",")))
    // setCtrCount(1)
    setValue("projectUniqueId", selectedId.join(","))
    // /console.log("FetechAllDetail", id)
  }
  const onSubmit = (data) => {
    // if (rttDate == "" && unlockDate == "") {
    //   setRttDateError(true)
    //   setUUUDateError(true)
    // }
    // if (rttDate == "") {
    //   setRttDateError(true)
    //   return
    // }
    // if (unlockDate == "") {
    //   setUUUDateError(true)
    //   return

    // }
    // setRttDateError(false)
    // setUUUDateError(false)
    // data["ssvRttDate"] = moment(rttDate).format("MM/DD/YYYY")
    // data["ssvUUUDate"] = moment(unlockDate).format("MM/DD/YYYY")
    // console.log("the data is knbjkdhjebvevker=", data)
    dispatch(addUserAction(SSVDashboardAPI+"/"+currentUrl.split("/").pop(), data))
    dispatch(popUpUpdate({}))
    setCloser(true)
  }
  useEffect(() => {
    dispatch(AccountReducerAction(siteDblistSiteId))
    dispatch(AccountReducerAction(vendorApi))
    dispatch(TierListReducerAction(managEngineer))
    dispatch(FetchSingleRecordAction(SSVDashboardAPI, currentUrl.split("/").pop()))
  }, [])
  return (
    <>
      <form className='row' onSubmit={handleSubmit(onSubmit)}>
        {

          currentUrl.includes("edit") == false
            // console.log(":,mbhjvbxjsaxas",currentUrl.includes("edit"))
            ?
            <div className="form-group col-md-3">
              <label for="exampleFormControlSelect1">
                Site Id<span style={{ color: "red" }}>*</span>
              </label>
              <input className='form-control' list="cars"
                style={{ height: "32px" }}

                onBlur={(e) => {
                  findDSiteId(e.target.value)
                }}
              />
              <p className="error" style={{ display: siteError ? "bolck" : "none" }}>
                Required Field
              </p>
              <datalist id="cars">

                {options.siteArray}
              </datalist>
            </div>
            :
            <div className="form-group col-md-3">
              <label for="exampleFormControlSelect1">
                Site Id<span style={{ color: "red" }}>*</span>
              </label>
              <input className='form-control' type="text" defaultValue={selectedSited} disabled>
              </input>
            </div>
        }
        {

          currentUrl.includes("edit") == false
            // console.log(":,mbhjvbxjsaxas",currentUrl.includes("edit"))
            ?
            <div className="form-group col-md-3">
              <label htmlFor="inputEmail4">Plan Type<span style={{ color: "red" }}>*</span></label>

              <MultiSelect
                className="text-dark"
                hasSelectAll={false}
                options={options.projectCodeOption}
                onChange={findDProjectId}
                value={selectProjectCode}
              />
            </div>

            :
            <div className="form-group col-md-3">
              <label for="exampleFormControlSelect1">
                Project Code<span style={{ color: "red" }}>*</span>
              </label>
              <input className='form-control' type="text" defaultValue={selectedProjectCode} disabled>
              </input>
            </div>
        }
        {

          currentUrl.includes("edit") == false
            // console.log(":,mbhjvbxjsaxas",currentUrl.includes("edit"))
            ?
            <div className="form-group col-md-3 ">
              <label >Project Id<span style={{ color: "red" }}>*</span></label>
              <MultiSelect
                className="text-dark"
                hasSelectAll={false}

                options={newTtempArray}
                onChange={FetechAllDetail}
                value={selectProjectId}
              />
              {/* {errors.projectUniqueId && (
                   <p className="error">
                     {errors.projectUniqueId.message}
                   </p>
                 )} */}
            </div>

            :
            <div className="form-group col-md-3">
              <label for="exampleFormControlSelect1">
                Project Id<span style={{ color: "red" }}>*</span>
              </label>
              <input className='form-control' type="text" defaultValue={selectedProjectId} disabled>
              </input>
            </div>
        }

        {/* <div className='form-group col-md-3'>
            <label>Plan Type<span style={{ color: "red" }}>*</span></label>
            <input type ="text"  className='form-control '
            {
              ...register("ssvPlanType",{required :"Please enter Plan Type"})
            }
            
            />
              {errors.ssvPlanType && (  <p className='error'>{errors.ssvPlanType.message}</p>)}
        </div>
         */}
        {/* <div className='form-group col-md-3'>
          <label>Project Type<span style={{ color: "red" }}>*</span></label>
          <input type="text" className='form-control '
            {
            ...register("projectType", { required: "Please enter project type" })
            }
          />
          {errors.projectType && (<p className='error'>{errors.projectType.message}</p>)}
        </div> */}
        
        <div className='form-group col-md-3'>
          <label>Project Type</label>
          <input type="text" className='form-control ' defaultValue={singleSitestate?.projectType} readOnly />
        </div>
        <div className='form-group col-md-3'>
          <label>HC Vendor</label>
          <input type="text" className='form-control ' defaultValue={singleSitestate?.HardCostVendor} readOnly />
        </div>
        {/* <div className='form-group col-md-3'>
          <label>HC Vendor</label>
          <select className='form-control '
            {...register("vendor")}
          >
            {options.vStatusArry}
          </select>
        </div> */}
        {/* <div className='form-group col-md-3'>
            <label>GC</label>
            <input type ="text"  className='form-control '/>
        </div> */}
        <div className='form-group col-md-3'>
          <label>RTT Date<span style={{ color: "red" }}>*</span></label>
          <DatePicker
            style={styles}
            className="form-control"
            selected={rttDate}
            disabled
            onChange={(e) => {
              setRTTDate(e);
              // setRttDateError(true)
            }}
            popperPlacement="bottom"
            dateFormat="MM/dd/yyyy"
          />
          <p className='error' style={{ display: rttDateError ? "block" : "none" }}>Please select Date</p>
        </div>

        {/* <div className='form-group col-md-3'>
          <label>UUU Date<span style={{ color: "red" }}>*</span></label>
          <DatePicker
            style={styles}
            className="form-control"
            selected={unlockDate}
            onChange={(e) => {
              setUnlockDate(e);
              // setUUUDateError(true)
            }}
            popperPlacement="bottom"
            dateFormat="MM/dd/yyyy"
          />
          <p className='error' style={{ display: UUUDateError ? "block" : "none" }}>Please select Date</p>
        </div> */}
        {/* <div className="form-group col-md-3">
          <label>UUU Engineer<span style={{ color: "red" }}>*</span></label>

          <input style={{ height: "32px" }}
            type="text"
            {...register("UUUEngineer", {
              required: "Please select UUU Engineer"
            })}
            onBlur={(e) => { findLaunchEngineer(e.target.value) }}
            list="engOpen"
            className="form-control"
            ID="inputAddress"
            defaultValue={launchEngineer}
            placeholder="Select Engineer"
          />

          <datalist id="engOpen">
            {options.engineerArray}
          </datalist>
          {errors.UUUEngineer && (
            <p className="error">
              {errors.UUUEngineer.message}
            </p>
          )}
        </div> */}

        {
          currentUrl.includes("edit") ?
            DbName.current[1] && DbName.current[0] && DbName.current[2] ?
              <CommonCrew errors={errors} register={register} setValue={setValue} crewType={DbName.current[1]} currentValueName={DbName.current[0]} currentValueId={DbName.current[2]} />
              : "" : ""
        }

        {
          !currentUrl.includes("edit") && !withoutedit  ?
            <CommonCrew errors={errors} register={register} setValue={setValue} crewType={"CXCrew"} currentValueName={""} currentValueId={""} sst={pncurrentValueId} setsst={setpncurrentValueId} /> :
            ""
        }

        {
          withoutedit?
          <CommonCrew errors={errors} register={register} setValue={setValue} crewType={"CXCrew"} currentValueName={""} currentValueId={""} sst={pncurrentValueId} setsst={setpncurrentValueId} /> :
          ""
        }

        {/* <div className='form-group col-md-3'>
            <label>SSV Submit Date</label>
            <DatePicker
          style={styles}
          className="form-control"
          //selected={driverDate}
          onChange={(e) => {
            setSSVSubmitDate(e);
          }}
          popperPlacement="bottom"
          dateFormat="MM/dd/yyyy"
        />
        </div>
        <div className='form-group col-md-3'>
            <label>SSV Approval Date</label>
            <DatePicker
          style={styles}
          className="form-control"
          //selected={driverDate}
          onChange={(e) => {
            setSSVApprovalDate(e);
          }}
          popperPlacement="bottom"
          dateFormat="MM/dd/yyyy"
        />
        </div>
        <div className='form-group col-md-3'>
            <label>SSV Status(Pending,Submitted,Approved)</label>
            <select 
            className='form-group'
            >
              <option disabled selected value={""}>Select</option>
              <option value={"Pending"}>Pending</option>
              <option value={"Submitted"}>Submitted</option>
              <option value={"Approved"}>Approved</option>
            </select>
        </div>
        <div className='form-group col-md-3'>
            <label>Comment/Issue</label>
            <input type ="text"  className='form-control '/>
        </div>
        <div className='form-group col-md-3'>
            <label>Re-Sumitted Date</label>
            <DatePicker
          style={styles}
          className="form-control"
          //selected={driverDate}
          onChange={(e) => {
            setSSVStatus(e);
          }}
          popperPlacement="bottom"
          dateFormat="MM/dd/yyyy"
        />
        </div> */}
        <div className='col-12 d-flex justify-content-center'>
          <button className="btn btn-primary btn-xs py-2 mr-2 px-4 mt-2" type='submit'>
            Submit
          </button>

          <button className="btn btn-danger btn-xs py-2  px-4 mr-2 mt-2" onClick={() => history.push("/PAG/ssv/dashboard")}>Back</button>
        </div>
      </form>
      <br />
      <br />
    </>
  )
}

export default AddSSV