import axios from 'axios';
import { baseURL } from '../common/config';
import { store } from '../store/store';

const axiosInstance = axios.create({
    baseURL: baseURL,
});


const axiosInstanceblobFile = axios.create({
    baseURL: baseURL,
    responseType: 'blob',
});

const axiosInstancestreamFile = axios.create({
    baseURL: baseURL,
    responseType: 'arraybuffer',
});


axiosInstance.interceptors.request.use((config) => {
    console.log(config,"config")
    const state = store.getState();
    const token = state.auth.auth.idToken;
    config.params = config.params || {};
    config.headers['Authorization'] = "Bearer "+token;
	console.log(config,"config");
    return config;
});

axiosInstanceblobFile.interceptors.request.use((config) => {
    console.log(config,"config")
    const state = store.getState();
    const token = state.auth.auth.idToken;
    config.params = config.params || {};
    config.headers['Authorization'] = "Bearer "+token;
	console.log(config,"config");
    return config;
});
axiosInstancestreamFile.interceptors.request.use((config) => {
    console.log(config,"config")
    const state = store.getState();
    const token = state.auth.auth.idToken;
    config.params = config.params || {};
    config.headers['Authorization'] = "Bearer "+token;
	console.log(config,"config");
    return config;
});

// export default axiosInstanceFile;
export {axiosInstance,axiosInstanceblobFile,axiosInstancestreamFile};

