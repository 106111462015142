import React from 'react';
import { ComposedChart, XAxis, YAxis, Tooltip, Legend, Line, Bar, ReferenceLine, Brush, ResponsiveContainer, CartesianGrid } from 'recharts';
import { VendorScoreCardReducerAction, funVendorScoreCard } from '../../../../store/actions/createTicketManageFields';
import { useDispatch } from 'react-redux';
import swal from 'sweetalert';
import ExcelExport from './ExcelExport';



const CustomTooltip = ({ active, payload, label }) => {
    // if (active) {
    //     return (
    //         <div className="custom-tooltip">
    //             <p className="label text-white">
    //                 {`${label}`}
    //             </p>
    //             <p className="text-white">{`${payload.map((item) => {
    //                 return `${item.name}: ${item.value}\n`;
    //             }).join(', ')}`}</p>
    //         </div>
    //     );
    // }

    // return null;
    if (active && payload && payload.length) {
        return (
            <div style={{position: "relative", zIndex: 20,backgroundColor:"white",margin:"10px",padding:"10px" }} className="custom-tooltip">
                <p className="label text-black" style={{fontSize:"16px"}}>{label}</p>
                {payload.map((item, index) => (
                    <div key={index} className="tooltip-item d-flex flex-row ">
                        <span className="bar-color" style={{width:"20px",height:"20px",marginRight:"5px", backgroundColor: item.color,color: item.color }}></span>
                        <p className="text-black">{` ${item.name}: ${item.value}`}</p>
                    </div>
                ))}
            </div>
        );
    }

    return null;
};

const RechartLineChart = ({ extraFilter,filtering,toollabeling,table,emsg, xAxis, yAxis, lisData, colrenameList, dic_col, final_dta, nextUrl, backUrl, toollabel }) => {




    const data_l = final_dta

    const dispatch = useDispatch()



    console.log("lisData", lisData, "colrenameList", colrenameList, "dic_col", dic_col, "final_dta", final_dta, "dataRechartLineChartdata")



    const handleClick = (data, index) => {
        // Here you can define what happens when a bar is clicked


        if (data?.activeLabel) {
            if (nextUrl != "") {
                dispatch(funVendorScoreCard([]))
                dispatch(VendorScoreCardReducerAction(`${nextUrl}${data?.activeLabel}${filtering!=""?filtering+"&"+extraFilter:"?"+extraFilter}`))
            } else {
                swal({
                    title: "info",
                    icon: "info",
                    text: emsg,
                    timer: 2000
                }).then(() => {

                })
            }
        }
        console.log('Clicked bar data:', data);
    };
    return (
        <>
            <br />

            {
                backUrl != "" && <>
                    <svg fill="#ffffff" onClick={() => {
                        if (backUrl != "") {
                            dispatch(funVendorScoreCard([]))

                            dispatch(VendorScoreCardReducerAction(`${backUrl}${filtering!=""?filtering:""}`))
                        } else {
                            swal({
                                title: "info",
                                icon: "info",
                                text: "Last Stage Of Data",
                                timer: 2000
                            }).then(() => {

                            })
                        }
                    }} height="32px" width="32px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 219.151 219.151">

                        <g id="SVGRepo_bgCarrier" stroke-width="2" />

                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />

                        <g id="SVGRepo_iconCarrier"> <g> <path d="M109.576,219.151c60.419,0,109.573-49.156,109.573-109.576C219.149,49.156,169.995,0,109.576,0S0.002,49.156,0.002,109.575 C0.002,169.995,49.157,219.151,109.576,219.151z M109.576,15c52.148,0,94.573,42.426,94.574,94.575 c0,52.149-42.425,94.575-94.574,94.576c-52.148-0.001-94.573-42.427-94.573-94.577C15.003,57.427,57.428,15,109.576,15z" /> <path d="M94.861,156.507c2.929,2.928,7.678,2.927,10.606,0c2.93-2.93,2.93-7.678-0.001-10.608l-28.82-28.819l83.457-0.008 c4.142-0.001,7.499-3.358,7.499-7.502c-0.001-4.142-3.358-7.498-7.5-7.498l-83.46,0.008l28.827-28.825 c2.929-2.929,2.929-7.679,0-10.607c-1.465-1.464-3.384-2.197-5.304-2.197c-1.919,0-3.838,0.733-5.303,2.196l-41.629,41.628 c-1.407,1.406-2.197,3.313-2.197,5.303c0.001,1.99,0.791,3.896,2.198,5.305L94.861,156.507z" /> </g> </g>

                    </svg>

                </>
            }


            <br />


            <ExcelExport table={table} />

            <br />


            <p style={{ color: "white", marginLeft:"16px",fontSize:"14px" }}>{toollabeling}</p>
            
            <p style={{ color: "white", marginLeft:"16px",fontSize:"14px" }}>{toollabel}</p>
            <br />



            <ResponsiveContainer width="100%" height={400} id="export-container">
                <ComposedChart
                    onClick={handleClick}
                    data={data_l}
                    margin={{
                        top: 10,
                        right: 10,
                        bottom: 10,
                        left: 10,
                    }}
                >

                <CartesianGrid stroke={"#fff"} strokeDasharray='5 5'/>

                    {
                        xAxis.map((itw) => {
                            return <XAxis tick={{ fontSize: "10px", fill: '#ffffff' }} dataKey={itw} />
                        })
                    }
                    <YAxis tick={{ fontSize: "10px", fill: '#ffffff' }} yAxisId="left" />
                    <YAxis tick={{ fontSize: "10px", fill: '#ffffff' }} yAxisId="right" orientation="right" />

                    <Tooltip content={<CustomTooltip />} />
                    {/* <Tooltip wrapperStyle={{zIndex:10, background:"#000000"}}/> */}
                    {/* <Legend /> */}


                    <Legend verticalAlign="top" wrapperStyle={{ fontSize: "10px", color: "#ffffff", lineHeight: '20px' }} />
                    {/* <ReferenceLine y={0} stroke="#000" /> */}
                    <Brush dataKey="groupName" wrapperStyle={{}} height={15} startIndex={0} endIndex={1} stroke="#FF5F00"/>

                    {
                        yAxis.map((itq) => {

                            { console.log(lisData[itq]["color"], "itqitqitqitq") }
                            return lisData[itq]["chartType"] == "bar" ?
                                <Bar yAxisId={lisData[itq]["axixShow"]} dataKey={itq} stackId={lisData[itq]["stackId"]} fill={lisData[itq]["color"]} barSize={40} /> :
                                <Line yAxisId={lisData[itq]["axixShow"]} type="monotone" stackId={lisData[itq]["stackId"]} dataKey={itq} stroke={lisData[itq]["color"]} />

                        })
                    }

                    {/* <Bar yAxisId="left" dataKey="Open" fill="#008000" barSize={40} /> */}
                    {/* <Line yAxisId="right" type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
                </ComposedChart>
            </ResponsiveContainer>
        </>
    )
};

export default RechartLineChart;



// import React from 'react';
// import { BarChart, XAxis, YAxis, Tooltip, Legend, Bar } from 'recharts';

// const RechartLineChart = ({ data }) => {
//   const {
//     color,
//     label,
//     series,
//     minBarWidth,
//     yAxis,
//     chartStack,
//     chartType,
//     toollabel,
//     nextUrl,
//     table,
//     numDataPoints,
//     backUrl,
//   } = data;

//   return (
//     <div>
//       <h1>{toollabel}</h1>
//       <BarChart
//         width={800}
//         height={400}
//         data={series}
//         margin={{
//           top: 20,
//           right: 80,
//           bottom: 20,
//           left: 20,
//         }}
//         barCategoryGap={20}
//         barGap={10}
//         stackOffset={chartStack ? 'expand' : 'none'}
//       >
//         <XAxis dataKey="label" />
//         <YAxis {...yAxis} />
//         <Tooltip />
//         <Legend />
//         {series.map((entry, index) => (
//           <Bar
//             key={index}
//             dataKey="data"
//             stackId={chartStack ? '1' : undefined}
//             fill={color[index]}
//             minPointSize={minBarWidth}
//           />
//         ))}
//       </BarChart>
//       <a href={nextUrl}>Next</a>
//       {/* <table>
//         <thead>
//           <tr>
//             {table.header.map((header, index) => (
//               <th key={index}>{header}</th>
//             ))}
//           </tr>
//         </thead>
//         <tbody>
//           {table.data.map((row, rowIndex) => (
//             <tr key={rowIndex}>
//               {Object.values(row).map((cell, cellIndex) => (
//                 <td key={cellIndex}>{cell}</td>
//               ))}
//             </tr>
//           ))}
//         </tbody>
//       </table> */}
//     </div>
//   );
// };

// export default RechartLineChart;
