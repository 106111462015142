import React, { useState } from 'react'
import MUIDataTable from "mui-datatables";
import { useEffect } from 'react';
import { Row, Card, Col, Dropdown, Button, Modal, Container } from "react-bootstrap";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { AccountReducerAction, OneUserReducerAction, addUserAction, deleteAcccountReducerAction, updateAcccountReducerAction, RoleReducerAction, UserListReducerAction, popUpUpdate } from '../../../../store/actions/createTicketManageFields';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";

import { useForm } from "react-hook-form";
import { black } from 'material-ui/styles/colors';
import { deleteOssApi, ossAddApi, allTechnologyApi } from '../../../../common/config';
import { CSVUploadAction, PDFUploadAction } from '../../../../store/actions/dashboardActions';
import { google_map_api, center, dbdatetodate, customDataTableOptionObject, getMuiThemeCommon, advancdcustomDataTableOptionObject, advancdExportOnecustomDataTableOptionObject } from "../../../../common/variables";

const Technology = () => {
  const [siteFile, uploadedFile] = useState();
  const [time, onetime] = useState();
  let pStatusArry = []
  let technologyDataData = []
  let ossType = []
  // let editTrue=0
  const options = {
    filterType: 'none',
    selectableRows: false,
    responsive: true
  };

  const { register, setValue, handleSubmit, reset, formState: { errors } } = useForm({});
  const [show, setShow] = useState(false);



  const handleClose = () => {
    reset({})
    setShow(false);
  }
  const handleShow = () => {
    reset({})
    setShow(true)
  }



  const [modalCentered, setModalCentered] = useState(false);
  const [editTrue, seteditTrue] = useState(0)
  const [updateTrue, setupdateTrue] = useState(0)
  const [formtype, addformtype] = useState('')
  const [editOject, setEditObject] = useState('')
  const [closer, setCloser] = useState(false);
  const getMuiTheme = () => createTheme({
    components: {
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            backgroundColor: "transparent",
            color: black
          }
        }
      }
    }
  })


  console.log()
  const updateHandler = (e) => {
    console.log("button update clicked", e)

    // dispatch(addUserAction(addAcccount,e))

  }
  const submitHandler = async (e) => {
    e.preventDefault();
    // console.log("remarks =",remarks)
    console.log("file=", siteFile)
    console.log(columns)
    const form = new FormData();
    form.append("uploadFile", siteFile);
    form.append("uploadfor", "GSM_UTMS")
    // form.append("remarks",remarks);
    // setRemarks("")
    // uploadedFile()
    dispatch(CSVUploadAction(form))
    // setCountRender(1)
    console.log("form data =", form)
    // await csvhandleChange(options)
  };

  const uploadCsvFile = (e) => {
    console.log(e.target.files[0])
    uploadedFile(e.target.files[0]);
  };

  const deleteHandler = (e) => {
    console.log("button delete clicked", e)
    dispatch(deleteAcccountReducerAction(allTechnologyApi, e))
  }


  const editpopupHandler = (e) => {

    reset({})
    console.log("button editpopupHandler clicked", e.technologyName)
    seteditTrue(1)
    setEditObject(e)
    setValue("technologyName", e.technologyName)
  }



  const onSubmit = async (data) => {
    console.log(data, "dassdaas")


    if (editTrue == 1) {
      console.log("edit button clicked")
      dispatch(updateAcccountReducerAction(allTechnologyApi, editOject.uniqueid, data))
    }
    else {
      dispatch(addUserAction(allTechnologyApi, data))
    }
    setCloser(true)
    // popUpUpdate({})
  }

  const dispatch = useDispatch();

  let data = useSelector((state) => {
    // console.log("dfwef=",state)
    // let onelistuser=state.OneCommonResponse.listuser

    // console.log(onelistuser.length,editTrue,"onelistuser")
    // if(onelistuser.length>0 && editTrue==1){
    //   oneaccount=onelistuser[0]
    //   console.log(oneaccount,"oneaccount")
    //   seteditTrue(0)
    //   seteditdataHandler(oneaccount)
    // }

    console.log("the state is =", state)

    if (state.popUpState) {
      if ((state.popUpState.popstatus.status == 201 && closer)||(state.popUpState.popstatus.status == 200 && closer)) {
        setCloser(false)
        // handleClose()
        dispatch(popUpUpdate({}))
        dispatch(AccountReducerAction(allTechnologyApi))
        setModalCentered(false)
        
      }
    }
    let technologyData = state.CommonResponse.technology
    if (technologyData && technologyData.length > 0 ) {
      technologyDataData = technologyData.map((item, index) => {
        return {
          sNo: index + 1,
          technologyName: item.technologyName,
          action: <div className="d-flex">
            <Link
              href="#"
              onClick={() => { setModalCentered(true); editpopupHandler(item); addformtype("Edit"); }}
              className="btn btn-primary shadow btn-xs sharp mr-1 editButton"
            >
              <i className="fa fa-pencil"></i>
            </Link>
            <Link
              href="#"
              onClick={(e) => deleteHandler(item.uniqueid)}
              className="btn btn-danger shadow btn-xs sharp deleteButton"
            >
              <i className="fa fa-trash"></i>
            </Link>
          </div>
        }
      })

      console.log("ossIdsData", technologyDataData)
    }
    

    return { ossType, technologyDataData }
  })
  useEffect(() => {
    dispatch(AccountReducerAction(allTechnologyApi))
  }, [time])
  const columns = [
    {

      name: "sNo",
      label: "S.no",
      options: {
        filter: false,
        sort: false,
        searchable:false,
        setCellHeaderProps: () => ({
          style: {
            width:"80px",
            alignItem:"center",
            background:"#143b64",
            color:"white"

          }
        })
      }
    },
    {
      name: "technologyName",
      label: "Technology Name",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
           
              alignItem:"center",
            background:"#143b64",
            color:"white"

          }
        })
      }
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        print:false,
        searchable:false,
        setCellHeaderProps: () => ({
          style: {
            width:"80px",
            alignItem:"center",
            background:"#143b64",
            color:"white"

          }
        })
      }
    }
  ];
  let row=[];
       
  const handleChange = (e) => {
   console.log("the current anser= ", e.target.value)
 }

 const AddCustomButton = () => (
   <fregment style={{ float: "right", display: "flex", flexDirection: row, justifyContent: "space-between" }}>
    {/* <div className='col-md-12'> */}
        <Button className="btn btn-primary btn-xs mx-2 py-2 my-3" onClick={() => { setModalCentered(true);handleShow(); seteditTrue(0); reset({}); addformtype("Add") }}>Add Technology</Button>
      {/* </div> */}
      <Modal className="fade" show={modalCentered}>
        <Modal.Header>
          <Modal.Title>{formtype} Technology</Modal.Title>
                <Button
                  onClick={() => setModalCentered(false)}
                  variant=""
                  className="close">
                <span>&times;</span>
                </Button>
          <Modal.Title style={{ color: "white" }}>{formtype} User</Modal.Title>
        </Modal.Header>
        <Modal.Body >

          <div className="card " style={{ width: "100%" }}>
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <form onSubmit={handleSubmit(onSubmit)}>

                  <div>
                    <div className="accordion-body">
                      <div className="form-row mt-2 p-2">
                        <div className="form-group col-md-12">
                          <label for="exampleFormControlSelect1">
                            Technology Name
                          </label>
                          <input style={{ height: "40px" }}
                            type="text"
                            className="form-control"
                            id="input "
                            placeholder="Enter Technology Name"
                            {
                            ...register("technologyName", {
                              required: "Please enter Technology Name"
                            })
                            }
                          />
                          {errors.technologyName && (
                            <p className="error">{errors.technologyName.message}</p>
                          )}
                        </div>



                      </div>
                    </div>
                  </div>
                  <button type="submit" className="btn btn-primary btn-xs">
                    Submit
                  </button>
                </form>
              </div>

            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>
   </fregment>
 )
  return (
    <>

    


     

      <ThemeProvider theme={getMuiThemeCommon()}>

        <MUIDataTable
          title={"List Technology"}
          data={data.technologyDataData}
          columns={columns}
          // options={advancdcustomDataTableOptionObject(AddCustomButton,true,"Search By Technology")}
          options={advancdExportOnecustomDataTableOptionObject(AddCustomButton,true,"Technology_","",false,"Technology",false)}
        />
      </ThemeProvider>  
    </>
  )
}

export default Technology