import React, { useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { nestingOurApi, ranDashboard } from '../../../../common/config'
import { AccountReducerAction, addUserAction, FetchSingleRecordAction, popUpUpdate } from '../../../../store/actions/createTicketManageFields'

const NestingTimeComponent = (props) => {
    let [selectedNestOur, setNestOur] = useState("")
    let [NestOurRemark, setNestOurRemark] = useState("")
    let [closer, setCloser] = useState(false)
    let [nestVal, setnestVal] = useState(props.nestDetail.nestHour)


    let dispatch = useDispatch()


    const count = useSelector((state) => {
        console.log("the nest state is=", state);
        let sitesdata = state.CommonResponse.nestingTime || []
        if (state.popUpState.popstatus.status == 201 && closer) {
            setCloser(false)

            setnestVal(sitesdata?sitesdata.pop()?sitesdata.pop().totalnestingHour:props.nestDetail.nestHour:props.nestDetail.nestHour)
            dispatch(FetchSingleRecordAction(nestingOurApi,props.nestDetail.ranId));
            dispatch(AccountReducerAction(ranDashboard));
            dispatch(popUpUpdate({}))
        }

        console.log(sitesdata?sitesdata[sitesdata.length-1]:props.nestDetail.nestHour)
        
        let oldData = sitesdata.map((item) => {

            // console.log(item,"item.nestType")
            return <>
                <tr style={{backgroundColor:item.nestType=="Nesting Time Gap"?'red':'white',color:item.nestType=="Nesting Time Gap"?'white':'black'}}>
                    <td style={{ fontSize : "13px" }}>{props.nestDetail.siteId}</td>
                    <td style={{ fontSize : "13px" }}>{item.nestType}</td>
                    <td style={{ fontSize : "13px" }}>{item.nestingStart}</td>
                    <td style={{ fontSize : "13px" }}>{item.nestingEnd}</td>
                    <td style={{ fontSize : "13px" }}>{item.nestingHour}</td>
                    <td style={{ fontSize : "13px" }}>{item.unnestingHour}</td>
                    <td style={{ fontSize : "13px" }}>{item.totalnestingHour}</td>
                    <td style={{ fontSize : "13px" }}>{item.totalunnestingHour}</td>
                </tr>
            </>
        })
        return { oldData }
    })

    let handleSubmit = () => {
        setCloser(true)
        dispatch(popUpUpdate({}))
        console.log("the send data object=", { nestingOur: selectedNestOur, nestRemark: NestOurRemark, ranId: props.nestDetail.ranId })
        dispatch(addUserAction(nestingOurApi, { nestingHour: selectedNestOur, nestRemark: NestOurRemark, ranId: props.nestDetail.ranId, oldNest: props.nestDetail.nestHour, lastNestTime: props.nestDetail.lastNestTime }))
    }
    console.log("the detald=", props)
    let rowData = <>
        <tr>
            <td style={{ background : "#143b64" , color : "white" , fontSize : "13px"}}>Site Id</td>
            <td>{props.nestDetail.siteId}</td>
        </tr>
        <tr>
            <td style={{ background : "#143b64" , color : "white" , fontSize : "13px"}}>Precheck Req On</td>
            <td>{props.nestDetail.preReq}</td>
        </tr>
        <tr>
            <td style={{ background : "#143b64" , color : "white" , fontSize : "13px"}}>Nesting Time</td>
            <td>{nestVal}</td>
        </tr>
        <tr>
            <td style={{ background : "#143b64" , color : "white" , fontSize : "13px"}}>Nesting Time(hours)</td>
            <td>
                <select style={{ border : "1px solid gray"}}  value={selectedNestOur} onChange={(e) => setNestOur(e.target.value)}>
                    <option selected disabled value="" >select</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                    <option value="20">20</option>
                    <option value="21">21</option>
                    <option value="22">22</option>
                    <option value="23">23</option>
                    <option value="24">24</option>
                </select>
            </td>
        </tr>
        <tr>
            <td style={{ background : "#143b64" , color : "white" , fontSize : "13px"}}>Remarks</td>
            <td><input style={{ border : "1px solid gray"}} type='text' value={NestOurRemark} onChange={(e) => setNestOurRemark(e.target.value)}></input></td>
        </tr>
       
       
        
    </>
    return (
        <Tabs
            defaultActiveKey="home"
            id="fill-tab-example"
            className="mb-3"
            fill
        >
            <Tab eventKey='home' title='Add Nesting Time'>
                <div className="table-responsive table-light">
                    <table className="table ">
                        {/* <thead>
                            <tr style={{}}><th scope="col">Name</th><th scope="col">Value</th></tr>

                        </thead> */}
                        <tbody>
                            {rowData}
                        </tbody>
                    </table>
                </div> <div className='d-flex justify-content-center align-items-center'><button className='btn btn-sm my-3 py-1 px-2' style={{ background: "#143b64" , color:"white" }}  onClick={() => handleSubmit()}>Submit</button></div>

            </Tab>
            <Tab eventKey='detail' title='Nesting Time History'>
                <table className='showone' style={{color: "black" , width : "100%"}}>
                    <thead>
                        <th  style={{ background : "#143b64" , color : "white" , height : "45px", paddingLeft : "5px" ,   fontWeight : 500 , fontSize : "13px"}}>Site Id</th>
                        <th  style={{ background : "#143b64" , color : "white" , height : "45px", paddingLeft : "5px" ,   fontWeight : 500 , fontSize : "13px"}}>Upgradation Type</th>
                        <th  style={{ background : "#143b64" , color : "white" , height : "45px", paddingLeft : "5px" ,   fontWeight : 500 , fontSize : "13px"}}>Nesting Start</th>
                        <th  style={{ background : "#143b64" , color : "white" , height : "45px", paddingLeft : "5px" ,   fontWeight : 500 , fontSize : "13px"}}>Nesting Expire</th>
                        <th  style={{ background : "#143b64" , color : "white" , height : "45px", paddingLeft : "5px" ,   fontWeight : 500 , fontSize : "13px"}}>Nesting Time</th>
                        <th  style={{ background : "#143b64" , color : "white" , height : "45px", paddingLeft : "5px" ,   fontWeight : 500 , fontSize : "13px"}}>Un-Nested Time</th>
                        <th  style={{ background : "#143b64" , color : "white" , height : "45px", paddingLeft : "5px" ,   fontWeight : 500 , fontSize : "13px"}} >Total Time Nested</th>
                        <th style={{ background : "#143b64" , color : "white" ,  height : "45px", paddingLeft : "5px" ,  fontWeight : 500 , fontSize : "13px"}}>Total Time Un-Nested</th>
                    </thead>
                    <tbody>
                        {count.oldData}
                    </tbody>
                </table>
            </Tab>
        </Tabs>
    )
}

export default NestingTimeComponent