import React,{useEffect,useState} from 'react'


const FormData = () => {
   
  return (
    <>


<div className="table-responsive">
  <table className="table">
    <thead>
      <tr>
       
        <th scope="col" className=''>SiteId</th>
        <th scope="col" className=''>Technology</th>
        <th scope="col" className=''>CX Crew Vendor</th>
        <th scope="col" className=''>CX Crew Lead Contact Number</th>
        <th scope="col" className=''>CX Crew Lead Name</th>
        <th scope="col" className=''>CX Crew Lead Company</th>
        <th scope="col" className=''>CX Crew Lead E-mail ID</th>
        <th scope="col" className=''>IX Crew Vendor</th>
        <th scope="col" className=''>IX Crew Lead Contact Number</th>
        <th scope="col" className=''>IX Crew Lead Name</th>
        <th scope="col" className=''>IX Crew Lead Company</th>
        <th scope="col" className=''>IX Crew Lead E-mail ID</th>
        <th scope="col" className=''>Handover Date</th>
        
      </tr>
    </thead>
    <tbody>
    
         
            <tr>
            <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          </tr>
          
        
        
      
    </tbody>
  </table>
</div>


    </>
  )
}

export default FormData