import React, { useState } from 'react'
import MUIDataTable from "mui-datatables";
import { useEffect } from 'react';
import { Row, Card, Col,Dropdown, Button, Modal, Container } from "react-bootstrap";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { accountApi, addUser, deleteUser, listRoleApi, listUserApi,getMarket, addAcccount,listOneUser, updateUser } from '../../../../common/config';
import { AccountReducerAction,OneUserReducerAction, addUserAction,deleteAcccountReducerAction,GetMarketReducerAction, updateAcccountReducerAction, RoleReducerAction,UserListReducerAction } from '../../../../store/actions/createTicketManageFields';
import { useDispatch,useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { google_map_api, center, dbdatetodate, customDataTableOptionObject, advancdExportOnecustomDataTableOptionObject } from "../../../../common/variables";

import { useForm } from "react-hook-form";
import { black } from 'material-ui/styles/colors';
const AddUserrole = () => {

  let pStatusArry=[]
  let mStatusArry=[]
  // let editTrue=0
  const options = {
    download:"false",
    filterType: 'none',
    selectableRows: false,
    responsive: true
  };
 
  let accountArry =[]
  let userArry =[]
  
  const {register,setValue,handleSubmit,reset,formState:{errors}} =useForm({});
  const [show, setShow] = useState(false);

  const handleClose = () => {
    reset({})
    setShow(false);
  }
  const handleShow = () => 
  {
    reset({})
    setShow(true)
  }

  
    
  const [modalCentered, setModalCentered] = useState(false);
  const [newAccount,setAccount]=useState({})
  const [editTrue,seteditTrue]=useState(0)
  const [updateTrue,setupdateTrue]=useState(0)
  const [formtype,addformtype]=useState('')
  
  

    const svg1 = (
      <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect x="0" y="0" width="24" height="24"></rect>
          <circle fill="#000000" cx="5" cy="12" r="2"></circle>
          <circle fill="#000000" cx="12" cy="12" r="2"></circle>
          <circle fill="#000000" cx="19" cy="12" r="2"></circle>
        </g>
      </svg>
    );


   let oneaccount={}
   
   
   // setAccount(oneaccount)
  //  console.log(oneaccount,"oneaccount")
    const getMuiTheme = () => createTheme({
      components: {
        MUIDataTableBodyCell: {
          styleOverrides:{
            root: {
                backgroundColor: "transparent",
                color:black
            }
          }
        }
      }
    })
    

    console.log()
    const updateHandler =(e)=>{
        console.log("button update clicked",e)

        dispatch(addUserAction(addAcccount,e))
        

    }
    const deleteHandler =(e)=>{
      console.log("button delete clicked",e)
      dispatch(deleteAcccountReducerAction(deleteUser,e,"role"))
    }
    

    const editpopupHandler =(e)=>{
      seteditTrue(1)
      reset({})
      console.log("button editpopupHandler clicked",e)
      dispatch(OneUserReducerAction(listOneUser+"/"+e.uniqueid))
    }

    const seteditdataHandler =(e)=>{
      reset({})
      setupdateTrue(1)
      setValue("email", e.email, { shouldTouch: true });
      setValue("password",e.password, { shouldTouch: true });
      setValue("mobilenumber",e.mobilenumber, { shouldTouch: true });
      setValue("name",e.name, { shouldTouch: true });
      // console.log(e.$oid,"e.$oid")
      setValue("userrole",e.userroleuniqueid, { shouldTouch: true });
      setValue("accountId",e.accountuniqueid, { shouldTouch: true });
      setValue("name",e.name, { shouldTouch: true });
      setValue("uniqueid",e.uniqueid, { shouldTouch: true });
      setValue("marketId",e.marketId.$oid, { shouldTouch: true });

      
    }


    console.log(errors,"errors")

    

    const onSubmit= async (data)=>{
      console.log(data,"dassdaas")
      
      if(data.uniqueid!=undefined){
        dispatch(updateAcccountReducerAction(updateUser,data.uniqueid,data,"role"))
      }
      else{
        dispatch(addUserAction(addUser,data,"role"))
      }
      handleClose()

    }
  
    const dispatch =useDispatch();

    
    // let datava =useSelector((state)=>{

    // });

    let data =useSelector((state)=>{
        console.log("dfwef=",state)
        let onelistuser=state.OneCommonResponse.listuser

        console.log(onelistuser.length,editTrue,"onelistuser")
        if(onelistuser.length>0 && editTrue==1){
          oneaccount=onelistuser[0]
          console.log(oneaccount,"oneaccount")
          seteditTrue(0)
          seteditdataHandler(oneaccount)
        }
        


        let users=state.CommonResponse.listuser
        
        let account=state.CommonResponse.accounts
        let index=0
        if(users !=0){
          userArry=users.map((item)=>{

            console.log(item)
            index=index+1
            return {

              sNo:index,
              name :item.name,
              mobilenumber :item.mobilenumber,
              email :item.email,
              userrole :item.userrole,
              accountName :item.accountName,
              action   :<div className="d-flex">
              <Link
                href="#"
                onClick={() =>{ editpopupHandler(item);reset({});addformtype("Edit");handleShow();}}
                className="btn btn-primary shadow btn-xs sharp mr-1"
              >
                <i className="fa fa-pencil"></i>
              </Link>
              <Link
                href="#"
                onClick={(e)=>deleteHandler(item.uniqueid)}
                className="btn btn-danger shadow btn-xs sharp"
              >
                <i className="fa fa-trash"></i>
              </Link>
            </div>
              // <button value={item.uniqueid} onClick={(e)=>updateHandler(e)}>Click me</button>
              
          }
          })
        }


        if(account !=0){
          accountArry=account.map((item)=>{
            return <option value={item.uniqueid}>{item.accountName}</option>
          })
        }
        

        let userroledata =state.CommonResponse.userrole
        console.log(state.CommonResponse,"state.CommonResponse")
        console.log(userroledata,"projectStatus")
        if(userroledata !=0){
          pStatusArry=userroledata.map((item)=>{
            return <option value={item.uniqueid}>{item.rolename}</option>
          })

        }

        let marketroledata =state.CommonResponse.markets
        if(marketroledata !=0){
          mStatusArry=marketroledata.map((item)=>{
            return <option value={item.uniqueid}>{item.marketName}</option>
          })

        }
        console.log(pStatusArry,"pStatusArry")
      
      return {userArry,accountArry,pStatusArry,mStatusArry,oneaccount}
      })

      console.log(data,"data")
      const noOfRow=data.accountArry.length
      // data.accountArry.push(
      //   {   
      //       sNo:noOfRow+1,
      //       account:<input type='text' placeholder='Add Account' value={newAccount} onChange={(e)=>setAccount(e.target.value)}/>,
      //       edit : <button onClick={()=>addAccount()}>Add Account</button>
            
      //   }
      // )


    

    useEffect(()=>{
        dispatch(RoleReducerAction(listRoleApi))
        dispatch(AccountReducerAction(accountApi))
        dispatch(GetMarketReducerAction(getMarket))
        dispatch(UserListReducerAction(listUserApi))

    },[newAccount])
    const columns = [
        {
      
         name: "sNo",
         label: "S.no",
         options: {
          filter: false,
          sort: false,
          searchable:false,
          setCellHeaderProps: () => ({
            style: {
              
                alignItem:"center",
              background:"#143b64",
              color:"white"
  
            }
          })
         }
        },
        {
         name: "name",
         label: "Name",
         options: {
          filter: true,
          setCellHeaderProps: () => ({
            style: {
               
                alignItem:"center",
              background:"#143b64",
              color:"white"
  
            }
          })
         }
        },
        {
         name: "mobilenumber",
         label: "Mobile Number",
         options: {
          filter: true,
          setCellHeaderProps: () => ({
            style: {
              
                alignItem:"center",
              background:"#143b64",
              color:"white"
  
            }
          })
         }
        },
        {
         name: "email",
         label: "Email",
         options: {
          filter: true,
          setCellHeaderProps: () => ({
            style: {
               
                alignItem:"center",
              background:"#143b64",
              color:"white"
  
            }
          })
         }
        },
        {
         name: "userrole",
         label: "Userrole",
         options: {
          filter: true,
          setCellHeaderProps: () => ({
            style: {
              
                alignItem:"center",
              background:"#143b64",
              color:"white"
  
            }
          })
         }
        },
        {
         name: "accountName",
         label: "Account Name",
         options: {
          filter: true,
          setCellHeaderProps: () => ({
            style: {
             
                alignItem:"center",
              background:"#143b64",
              color:"white"
  
            }
          })
         }
        },
        {
         name: "action",
         label: "Action",
         options: {
          filter: false,
          sort: false,
          print:false,
          searchable:false,
          setCellHeaderProps: () => ({
            style: {
             
                alignItem:"center",
              background:"#143b64",
              color:"white"
  
            }
          })
         }
        }
        
       ];
       let row=[];
       
       const handleChange = (e) => {
        console.log("the current anser= ", e.target.value)
      }
    
      const AddCustomButton = () => (
        <fregment style={{ float: "right", display: "flex", flexDirection: row, justifyContent: "space-between" }}>
          <Button className="btn-xs mb-1 mr-2 "  onClick={() => {handleShow();reset({});addformtype("Add")}}>Add User</Button>
          <Modal show={show} onHide={handleClose}  size="lg" >
        <Modal.Header closeButton>
          <Modal.Title style={{color:"white"}}>{formtype} User</Modal.Title>
        </Modal.Header>
        <Modal.Body >

        <div className="card " style={{ width: "100%" }}>
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div className="accordion-item">
                <form onSubmit={handleSubmit(onSubmit)}>
                
                  <div>
                    <div className="accordion-body">
                      <div className="form-row mt-2 p-2">
                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                          Name
                          </label>
                          <input style={{height:"40px"}}
                            type="text"
                            className="form-control"
                            id="input "
                            placeholder="Enter Name"
                          
                            {
                              ...register("name")
                            }
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                          Mobile Number
                          </label>
                          <input style={{height:"40px"}}
                            type="text"
                            className="form-control"
                            id="input "
                            placeholder="Enter Mobile Number"
                          
                            {
                              ...register("mobilenumber")
                            }
                          />
                        </div>
                        
                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                          Password
                          </label>
                          <input style={{height:"40px"}}
                            type="text"
                            className="form-control"
                            id="input "
                            placeholder="Enter Password"
                          
                            {
                              ...register("password")
                            }
                          />
                        </div>
                        
                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                          Email
                          </label>
                          <input style={{height:"40px"}}
                            type="text"
                            className="form-control"
                            id="input "
                            placeholder="Enter Email"
                          
                            {
                              ...register("email")
                            }
                          />
                        </div>
                        

                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                            {" "}
                           User Role <span style={{color:"red"}}>*</span>
                          </label>
                          <select
                            style={{height:"40px"}}
                            className="form-control"
                            id="exampleFormControlSelect1"
                            {...register("userrole")}>


                            <option disabled selected>Select Role</option>
                            {data.pStatusArry}
                            
                          </select>
                          {errors.siteId && <p className="error">{errors.siteId.message}</p>}
                        </div>
                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                            {" "}
                            Select Account <span style={{color:"red"}}>*</span>
                          </label>
                          <select
                            style={{height:"40px"}}
                            className="form-control"
                            id="exampleFormControlSelect1"
                            {
                              ...register("accountId")
                            }
                          >
                          <option disabled selected>Select Account</option>
                            {data.accountArry}
                          </select>
                          {errors.siteId && <p className="error">{errors.siteId.message}</p>}
                        </div>

                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                            {" "}
                           Market <span style={{color:"red"}}>*</span>
                          </label>
                          <select
                            style={{height:"40px"}}
                            className="form-control"
                            id="exampleFormControlSelect1"
                            {...register("marketId")}>


                            <option disabled selected>Select Market</option>
                            {data.mStatusArry}
                            
                          </select>
                          {errors.siteId && <p className="error">{errors.siteId.message}</p>}
                        </div>

                      </div>
                    </div>
                  </div>
                  
                  <button type="submit" className="btn btn-primary btn-xs">
                    Submit
                  </button>
                  {/* <Button type='button' className='btn btn-primary  btn-xs '>Submit</Button> */}
                  </form>
                </div>
                
              </div>
            </div>
          
        </Modal.Body>
        <Modal.Footer>
         
        </Modal.Footer>
      </Modal>
        </fregment>
      )
       
    //    const data = [
    //     { name: "Joe James", company: "In Progress", city: "Yonkers", state: "NY" },
    //     { name: "John Walsh", company: "Completed", city: "Hartford", state: "CT" },
    //     { name: "Bob Herm", company: "Awaited", city: "Tampa", state: "FL" },
    //     { name: "James Houston", company: "NA", city: "Dallas", state: "TX" },
    //    ];



       
       
  return (
    <>
     
         




		  {/* <ThemeProvider theme={getMuiTheme()}> */}

        <MUIDataTable
        title={"List User Role"}
            data={data.userArry}
            columns={columns}
            // options={customDataTableOptionObject(AddCustomButton)}
            options={advancdExportOnecustomDataTableOptionObject(AddCustomButton,true,"UserRole_","",false,"User Role")}
        />
      {/* </ThemeProvider> */}
    </>
  )
}

export default AddUserrole