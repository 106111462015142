import React, { useEffect, useState, ComponentType, type } from "react";
import { Button, Dropdown } from "react-bootstrap";
import "../../../css/customStyle.css";
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { exportToCSV, ExportToExcel } from "../../../common/ExportToExcel";
import DatePicker from "react-datepicker";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Row, Col, Card, Nav } from 'react-bootstrap'
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { DateRange } from 'react-date-range';
import swal from 'sweetalert';
import moment from "moment"
import {
  NetworkDatabaseAction,
  CSVUploadAction,
} from "../../../store/actions/dashboardActions";
import { useHistory } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { google_map_api, center, dbdatetodate, dateObjectFull, ageofdata, ageofdatabytwo, getMuiThemeCommon, customDataTableOptionObject, advancdcustomDataTableOptionObject, wrapText, time_zonechanger, commonzone, moment_common_full_date_format, momnt_ageofdata, dateOnly, dbdatetomoment } from "../../../common/variables";
import { AccountReducerAction, addUserAction, clearFetchSingleRecordAction, deleteAcccountReducerAction, ExportXLSXDownloadReducerAction, FetchSingleRecordAction, GetclearttProject, getFilterDropDown, GetTimeLineAction, GetttProject, LCLTTReducerAction, ResetLCLTTReducerAction } from "../../../store/actions/createTicketManageFields";
import { approveOrDisapprove, getAllDropDown, listSiteId, ranDashboard, ranSiteStatus, singleProjectApi, timelineApi, reqPost, reqPreCheckCancel, ranDashboardTimeLine, reqPostCheckCancel, listtroubleTicketApi, troubleTicket, export_tt_tracker, ttfilters } from "../../../common/config";
import RanSiteTracker from "../ProjectTrackerComponent/RanSiteTracker";
import RanHistorical from "../ReusableComponents/RanHistorical";
import SingleProject from "../ProjectModule/SingleProject";
import ProjectData from "../ReusableComponents/ProjectData";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { fontSize } from "@mui/system";
import CustomeModal from "../Custom/CustomeModal";
import CustomTable from "../Custom/CustomTable";
import RequestPreCheck from "../Custom/Modal Body/RequestPreCheck";
import { RangeDatePicker } from "@y0c/react-datepicker";
import { Input } from "reactstrap";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { id } from "date-fns/locale";
import { includeInnerHTML } from "@syncfusion/ej2-base";
import MapView from "../Custom/Modal Body/MapView";
import NetworkTwo from "./TroubleTicketForm";
import CommonModal from "../Custom/CommonModal";
import FilterData from "../../../icons/Svg/FilterData";
import TTFilterList from "../Custom/Modal Body/TTFilterList";
import parse from 'html-react-parser'
const TicketTracker = () => {

  const [commonModalState, setcommonModalState] = useState(false);
  const [commonModalSize, setcommonModalSize] = useState(false);
  const [commonModalBody, setcommonModalBody] = useState("");
  const [commonModalTitle, setcommonModalTitle] = useState("");
  const [commonModalHeader, setcommonModalHeader] = useState("");


  const [accordianState, setAccordianState] = useState(false);
  const [siteFile, uploadedFile] = useState();
  const [fullUrL, setfullUrL] = useState("");
  const [remarks, setRemarks] = useState();
  const [markersdatacount, setmarkerscount] = useState(1);
  const [markersdata, setmarkersdata] = useState([]);
  const [selectedCenter, setSelectedCenter] = useState(null);
  const [xlShow, setXlShow] = useState(false);
  const [crewModal, setCrewModal] = useState(false)
  const [reqPreModal, setReqPreModal] = useState(false)
  const [siteDetail, setSiteDetail] = useState({})
  const [siteUId, setSiteId] = useState()
  const history = useHistory();
  const [showDatePicker, setDatePicker] = useState(false)
  const [closer, setCloser] = useState(false);
  const [mapModal, setMapModal] = useState(false)
  const [filterBadges,setFilterBadge] =useState()
  const [exportApiData, setexportApiData] = useState("");
  const [fstartdate, setfstartdate] = useState();
  const [fenddate, setfenddate] = useState();
  const [ResetFilter, setResetFilter] = useState(false);
  const [dateRange, setDateRange] = useState([{
    startDate: new Date(),
    endDate: null,
    key: "selection"
  }])


  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  const CommonModalFun = (body, title, header, type = "md") => {
    setcommonModalState(true);
    setcommonModalSize(type);
    setcommonModalTitle(title);
    setcommonModalBody(body);
    setcommonModalHeader(header);
  };


  const [uniqueId, setUniqueId] = useState()
  let getMuiTheme = () => createTheme({
    components: {
      MuiButtonBase: {
        styleOverrides: {
          root: {
            "line-height": "1 !important",
            "padding": "6px 0px"
          },
        }
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {

            maxHeight: 45,
            fontSize: 13,
            overflowY: "auto",
            padding: "0px 2px !important",

            margin: 0


          },
        }
      },
      // MuiToolbar: {
      //   styleOverrides: {
      //     root: {
      //       backgroundColor: '#f00',

      //     },
      //   },
      // },
      MuiTableCell: {
        styleOverrides: {
          head: {

            maxHeight: 20,
            fontSize: 15,
            margin: 0,
            paddingTop: 0,
            paddingBottom: 0
          },
        },
      },
      MUIDataTableSelectCell: {
        styleOverrides: {
          headerCell: {
            backgroundColor: 'blue',
          },
        },
      },
      MuiTableFooter: {
        styleOverrides: {
          root: {
            '& .MuiToolbar-root': {
              backgroundColor: 'white',
            },
          },
        },
      },
    },
  });
  const reqPostCheck = (e) => {
    swal({
      title: "Are you sure?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          console.log("the will dele=", willDelete)
          dispatch(AccountReducerAction(reqPost + e))
          dispatch(AccountReducerAction(ranDashboard))
          swal("Post Check Requsted", {
            icon: "success",
          });
        }
      });

  }



  const ClearFilter = () => {
    // alert("ClearFilter")
    console.log("ClearFilter", "ClearFilter");
    setfstartdate();
    setfenddate();
    setResetFilter(true)

    setFilterBadge()
    let Filleddata=dispatch(ResetLCLTTReducerAction())
    dispatch(AccountReducerAction(listtroubleTicketApi+Filleddata,true,true));
  };


  const setDateFilter = (e) => {
    e.preventDefault()
    dateRange[0].startDate = formatDate(dateRange[0].startDate)
    dateRange[0].endDate = formatDate(dateRange[0].endDate)
    console.log("the filtered data=", dateRange)
    let currentUrl = window.location.href
    let urlArray = currentUrl.split("?")
    if (currentUrl.includes("?")) {

      dispatch(AccountReducerAction(ranDashboard + "?" + urlArray.pop() + "&start=" + dateRange[0].startDate + "&end=" + dateRange[0].endDate))
    }
    else {
      dispatch(AccountReducerAction(ranDashboard + "?start=" + dateRange[0].startDate + "&end=" + dateRange[0].endDate))
    }
  }
  
  const ApprovalHandler = (item) => {
    console.log("the item ApprovalHandler is =.", item)
    dispatch(addUserAction(approveOrDisapprove + item.ranSupportId, { status: "Approve" }))
  }
  const cancelPreCheckReq = (id) => {
    dispatch(AccountReducerAction(reqPreCheckCancel + id))
  }

  const cancelPostCheckReq = (id) => {
    dispatch(AccountReducerAction(reqPostCheckCancel + id))
  }

  const startcalltest = (id) => {
    // dispatch(AccountReducerAction(reqPostCheckCancel + id))
  }
  const rejectHandler = (item) => {
    dispatch(addUserAction(approveOrDisapprove + item.ranSupportId, { status: "Not Approve" }))
  }
  let columns = [
    
    
    {
      label: "TT Open Date",
      name: "ttOpenDateNew",
      sortable: true,
      options: {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 101
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      },
    },
    {
      label: "Week #",
      name: "scheduleWeek",
      options: {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            position: "sticky",
            zIndex: 101,
            minWidth: "68px",
            left: "138px",
            color: "white",
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            outline:"1.5px solid black",
            overflowY: "hidden",
            minWidth: "68px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
            left: "138px",
          },
        }),
      },
    },
    {
      label: "Site ID",
      name: "siteId",
      options: {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            position: "sticky",
            zIndex: 101,
            minWidth: "68px",
            left: "208px",
            color: "white",
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            outline:"1.5px solid black",
            overflowY: "hidden",
            minWidth: "68px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
            left: "208px",
          },
        }),
      },
    },
    // {
    //   label: "TT Open Date",
    //   name: "ttOpenDateNew",
    //   options: {
    //     filter: true,
    //     print:true,
    //     className: "abcd",
    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "nowrap",
    //         "min-width":"145px",
    //         "max-width":"145px",
    //        background:"#143b64",
    //         color:"white"

    //       }
    //     })
    //   }
    // }, {
    //   label: "Week #",
    //   name: "weekNumber",
    //   options: {
    //     filter: true,
    //     print:true,
    //     className: "abcd",
    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "nowrap",
    //        background:"#143b64",
    //         color:"white"

    //       }
    //     })
    //   }
    // },
    // {
    //   name: "siteId",
    //   label: "Site Id",
    //   sortable: true,
    //   options: {
    //     filter: true,
    //     print:true,

    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "nowrap",
    //         overflowX: "hidden",
    //        background:"#143b64",
    //         color:"white",
    //         "min-width" : "90px",
    //         "max-width" : "140px"

    //       }
    //     })
    //   }
    // },
    {
      name: "projectCodenew",
      label: "Project Code",
      sortable: true,
      options: {
        filter: true,
        print:true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            minWidth:"300px",
           background:"#143b64",
            color:"white"

          }
        })
      }
    },
   
    {
      name: "projectId",
      label: "Project Id",
      sortable: true,
      options: {
        filter: true,
        print:true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            minWidth:"300px",
           background:"#143b64",
            color:"white"

          }
        })
      }
    },
    {
      name: "techtemp",
      label: "Technology",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "min-width": "350px",
            "max-width": "400px",
           background:"#143b64",
            color:"white"

          }
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            
          overflowY:"hidden",
          "min-width": "350px",
          "max-width": "400px",
          padding:"1px 1px",
         
            background: "White",
         

          },
        }),
      }
    }, {
      name: "account",
      label: "Account",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "max-width": "101px",
            "min-width": "101px",
            overflowX: "hidden",
           background:"#143b64",
            color:"white"

          }
        })
      }
    }, {
      name: "market",
      label: "Market",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
           background:"#143b64",
            color:"white"

          }
        })
      }
    }, {
      name: "cabinetId",
      label: "Cabinet Ids",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            minWidth:"200px",
            whiteSpace: "nowrap",
            overflowX: "hidden",
           background:"#143b64",
            color:"white"

          }
        })
      }
    }, {
      name: "temptechAffected",
      label: "Technology Affected",
      sortable: true,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
            "min-width": "350px",
            "max-width": "400px",
           background:"#143b64",
            color:"white"

          }
        })
      }
    },
    {
      name: "ttdatatemp",
      label: "TT Type",
      sortable: true,
      options: {
        filter: true,
        print:false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            "min-width":"400px",
            "max-width":"460px",
           background:"#143b64",
            color:"white"

          }
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            
          overflowY:"hidden",
          "min-width":"200px",
          "max-width":"250px",
          padding:"1px 1px",
         
            background: "White",
         

          },
        }),
      }
    }, {
      name: "tecttdata",
      label: "TT Type",
      sortable: true,
      options: {
        filter: true,
        display:false
      }
    },{
      name: "",
      label: "E911 Call Test Affected Sector",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "min-width":"135px",
           "max-width":"150px",
           background:"#143b64",
            color:"white"

          }
        })
      }
    },
    {
      name: "ttCategory",
      label: "TT Category",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "max-width": "100px",
            "min-width": "100px",
           background:"#143b64",
            color:"white"

          }
        })
      }
    },
    {
      name: "tt_descModal",
      label: "TT Description and Comments",
      sortable: true,
      options: {
        filter: true,
        print:false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "max-width": "300px",
            "min-width": "300px",
           background:"#143b64",
            color:"white"

          }
        })
      }
    },{
      name: "tt_desc",
      label: "TT Description",
      sortable: true,
      options: {
        filter: true,
        display:false,
      }
    },
    {
      name: "tt_summaryModal",
      label: "TT detailed Summary analysis",
      sortable: true,
      options: {
        filter: true,
        print:false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "max-width": "300px",
            "min-width": "300px",
           background:"#143b64",
            color:"white"

          }
        })
      }
    },{
      name: "tt_summary",
      label: "TT detailed Summary analysis",
      sortable: true,
      options: {
        filter: true,
        display:false,
      }
    },
    {
      name: "actionsttModal",
      label: "Actions need to be taken to resolve TT",
      sortable: true,
      options: {
        filter: true,
        print:false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            minWidth:"300px",
           background:"#143b64",
            color:"white"

          }
        })
      }
    },
    {
      name: "actionstt",
      label: "Actions need to be taken to resolve TT",
      sortable: true,
      options: {
        filter: true,
        display:false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            minWidth:"200px",
           background:"#143b64",
            color:"white"

          }
        })
      }
    },
    {
      name: "",
      label: "Attachment",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",

           background:"#143b64",
            color:"white"

          }
        })
      }
    },
    {
      name: "company",
      label: "Company Name",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",

           background:"#143b64",
            color:"white"

          }
        })
      }
    },
    {
      name: "vendor",
      label: "Vendor Name",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",

           background:"#143b64",
            color:"white"

          }
        })
      }
    }, {
      label: "Impact",
      name: "impact",
      sortable: true,
      options: {
        filter: true,
        print:false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",

           background:"#143b64",
            color:"white"

          }
        })
      }
    },{
      name: "impact",
      label: "Impact",
      sortable: true,
      options: {
        filter: true,
        display:false,

      }
    }, {
      label: "Group Assigned to TT",
      name: "groupAssignedTTPier",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            minWidth:"250px",
           background:"#143b64",
            color:"white"

          }
        })
      }
    }, {
      label: "SLA Met",
      name: "ttSlaMet",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",

           background:"#143b64",
            color:"white"

          }
        })
      }
    }, {
      label: "Engineer Opening TT",
      name: "engineerOpeningtt",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            minWidth:"150px",
           background:"#143b64",
            color:"white"

          }
        })
      }
    }, {
      label: "Estimated Time of Recover",
      name: "EstimatedTimeofRecover",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "min-width":"120px",
            "max-width":"150px",
           background:"#143b64",
            color:"white"

          }
        })
      }
    }, {
      name: "ttid",
      label: "TT ID",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            "max-width": "127px",
            "min-width": "122px",
           background:"#143b64",
            color:"white"

          }
        })
      }
    }, {
      name: "tmoPierTtid",
      label: "TMO Pier TT id",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "min-width":"120px",
            "max-width":"150px",
           background:"#143b64",
            color:"white"

          }
        })
      }
    }, {
      name: "ttStatus",
      label: "TT Status",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",

           background:"#143b64",
            color:"white"

          }
        })
      }
    }, {
      name: "aging",
      label: "Aging",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "min-width":"140px",
            "max-width":"140px",
           background:"#143b64",
            color:"white"

          }
        })
      }
    }, {
      name: "Comments",
      label: "Comments",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",

           background:"#143b64",
            color:"white"

          }
        })
      }
    }, {
      name: "engineerClosingtt",
      label: "Engineer Closing  / Terminating TT",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            minWidth:"150px",
           background:"#143b64",
            color:"white"

          }
        })
      }
    }, {
      name: "ttCloseDate",
      label: "TT Closed Date",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "min-width":"140px",
            "max-width":"150px",
           background:"#143b64",
            color:"white"

          }
        })
      }
    },
    // {
    //   name: "tt_summaryModal",
    //   label: "TT detailed Summary analysis",
    //   customBodyRender: (item) => console.log("from customBodyRender =", item),
    //   sortable: true,
    //   options: {
    //     filter: true,
    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",
    //         minWidth:"200px",
    //        background:"#143b64",
    //         color:"white"

    //       }
    //     })
    //   }
    // },
    {
      sortable: true,
      options: {
        filter: true,
        print:false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowX: "hidden",
           background:"#143b64",
            color:"white"

          }
        })
      },
      "name": "actions",
      "label": "Action"
    }
  ];

  <th scope="col">CX Crew Vendor</th>
  let cxTableHeader = ['CX Crew Vendor', 'CX Crew Lead Contact Number', 'CX Crew Lead Name', 'CX Crew Lead Company', 'CX Crew Lead E-mail ID']
  let ixTableHeader = ['IX Crew Vendor', 'IX Crew Lead Contact Number', 'IX Crew Lead Name', 'IX Crew Lead Company', 'IX Crew Lead E-mail ID']
  let rowData = [];
  let rowDataOne = [];
  let mark = [];
  let row = [];
  let datamark = [];
  let siteStatus = [];
  function createmarker(mark) {
    console.log(datamark, "createmarkerdatamark");

    setmarkersdata(datamark);

    console.log(...markersdata, "markersdata");
  }

  const sortedOrder={
    // name: 'ttOpenDate',
    // direction: 'desc'
  }
  function clearOldData(mark) {
    dispatch(GetclearttProject([]))
    
    dispatch(clearFetchSingleRecordAction())
    dispatch(AccountReducerAction(listtroubleTicketApi + "/" + mark));
    history.push("/PAG/trobleTicke/form/ttedit/"+mark)
  }

  
  function openModal(data, id = null) {
    if (data == 'reqPreCheck') {
      setReqPreModal(true)
      console.log("the req premodal =", data)
      setUniqueId(id)

    }
    else
      if (id == null) {
        setCrewModal(true)
        setSiteDetail(data)
        console.log("th dta is  =", reqPreModal)
        console.log("the main data is =", siteDetail)
      }
  }


  const deleteHandler = (e) => {
    console.log("button delete clicked", e)

    swal({
      title: "Are you sure?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          console.log("the will dele=", willDelete)
          setCloser(true)
          dispatch(deleteAcccountReducerAction(troubleTicket, e))
          dispatch(AccountReducerAction(listtroubleTicketApi));
        }
      });

    

  }
  function showDetails(id) {
    setXlShow(true)
    setSiteId(id.ranSupportId)
    console.log("dfghedrhetsetghrrtd", id)
    dispatch(GetTimeLineAction(ranDashboardTimeLine, id.ranSupportId))
    dispatch(FetchSingleRecordAction(ranDashboard, id.ranSupportId))
  }
  const count = useSelector((state) => {
    console.log("the state is=", state);
    
    
    let totalCount=state.countState.listttCount
    let sitesdata = state.CommonResponse.ranDashboard;
    if (sitesdata) {
      if (markersdatacount == 1) {
        // setmarkersdata(sitesdata)
        console.log(state.sites.sites, "sitesdata");
        createmarker(sitesdata);
        sitesdata.map((marker) => {
          if (marker.latitude != "" && marker.longitute != "") {
            datamark.push({
              latitude: parseFloat(marker.latitude),
              longitute: parseFloat(marker.longitute),
              id: marker.latitude,
            });
          }
        });

        console.log(datamark, "datamark");
        setmarkerscount(0);
      }
      // sitesdata.map(item=>{
      //   if(item.latitude!='' && item.longitute!=''){
      //     if(markersdatacount==1){
      //       createmarker(mark)
      //       setmarkerscount(0)
      //     }

      //   }
      // })
    }
    let tt_all_data = state.CommonResponse.tt_data
    // console.log("the ran site status =",state.CommonResponse.ranSiteStatus)
    if (ranSiteStatus && ranSiteStatus.length > 0) {
      console.log("the ran site status =", ranSiteStatus)
      siteStatus = ranSiteStatus
    }
    console.log(mark, "mark");

    if (Object.keys(state.CommonResponse.tt_data).length > 0) {
      if (state.CommonResponse.tt_data.length > 0) {


        rowData = state.CommonResponse.tt_data.map((item, index) => {
          console.log("i am caling", state)


          let temptechaff = ""

          let techAffectedArray = item["techAffected"].values;
          if (techAffectedArray && techAffectedArray.length > 0) {
            //  item['technology'].forEach((item)=>{
            //   tec +=item.label +','
            //  })
            for (let i = 0; i < techAffectedArray.length; i++) {
              console.log("sgrthdhr", techAffectedArray[i]);
              temptechaff += techAffectedArray[i].split("_CP_")[0] + ", ";
            }
            //  item['technology']=tec
          }
          //  console.log("item is =",item['technology'])
          // item['technology']=tec
          item["temptechAffected"] = temptechaff;
          let mergedPId
          mergedPId=item["projectDataa"].map((itm)=>{
            return itm.projectId
          })
          item["projectId"]=mergedPId.join(", ")
          item["projectCodenew"]=item["projectCode"].join(", ")
          item["cabinetId"]="GSM;,,,, UTMS;,,,,, LTE:,,,,, 5G;,,,,,"
          item["ttOpenDateNew"]=dateOnly(item["ttOpenDate"])
          item["ttSlaMet"]=item["ttSlaMet"]
          const myMomentObject = dbdatetomoment(item["ttOpenDate"])
          

          item["scheduleWeek"] = myMomentObject.isoWeekday(1).week()
          item["EstimatedTimeofRecover"] = item["day"]+" Day, "+item["hh"]+" Hours, "+item["mm"]+" Minutes";
          item["tt_descModal"]=(
            <p
              className={"text-black my-0 modalHoverClass"}
              onClick={() =>
                CommonModalFun(

                  <p style={{ border: "2px solid black", color: "black", padding: 2 }} className="CommonModal">{parse(item['tt_desc'].replaceAll("\n","<br>"))}</p>,
                  "Comment",
                  "TT Description"
                )
              }
            >
              {wrapText(item['tt_desc'])}
            </p>
          )
          item['tt_summaryModal']=(
            <p
              className={"text-black my-0 modalHoverClass"}
              onClick={() =>
                CommonModalFun(

                  <p style={{ border: "2px solid black", color: "black", padding: 2 }} className="CommonModal">{parse(item['tt_summary'].replaceAll("\n","<br>"))}</p>,
                  "Comment",
                  "TT Detail Summary Analysis"
                )
              }
            >
              {wrapText(item['tt_summary'])}
            </p>
          )
          item['actionsttModal']=(
            <p
              className={"text-black my-0 modalHoverClass"}
              onClick={() =>
                CommonModalFun(

                  <p style={{ border: "2px solid black", color: "black", padding: 2 }} className="CommonModal">{parse(item['actionstt'].replaceAll("\n","<br>"))}</p>,
                  "Comment",
                  "Actions need to be taken to resolve TT"
                )
              }
            >
              {wrapText(item['actionstt'])}
            </p>
          )
          console.log(item["ttStatus"],"ttstatus")
          if(item["ttStatus"]=="Close"){
            // item["aging"] = ageofdatabytwo(item["serverttOpenDate"],item["serverttCloseDate"])
            
            item["aging"] = momnt_ageofdata(item["ttOpenDate"],item["ttCloseDate"],moment_common_full_date_format)
          }else{
            // item["aging"] = ageofdata(item["serverttOpenDate"])
            item["aging"] = momnt_ageofdata(item["ttOpenDate"],time_zonechanger(commonzone,moment_common_full_date_format),moment_common_full_date_format)
          }

          item["actions"] = <div className="d-flex">
            <button onClick={((e)=>{
              clearOldData(item["ttUniqueId"])
            })} className="btn btn-primary shadow btn-xs sharp mr-1 d-flex justify-content-center align-items-center" ><i className="fa fa-pencil">
            </i></button>
            {/* <button className="btn btn-primary shadow btn-xs sharp mr-1" onClick={() => { NetworkTwo() }}><i className="fa fa-pencil"></i></button> */}
            <button onClick={(e) => deleteHandler(item.ttUniqueId)} className="btn btn-danger shadow btn-xs sharp d-flex justify-content-center align-items-center"><i className="fa fa-trash"></i></button></div>;

          let tecttdata = ""



          // let techttdataArray = item["ttdata"].values;
          // if (techttdataArray && techttdataArray.length > 0) {
          //   //  item['technology'].forEach((item)=>{
          //   //   tec +=item.label +','
          //   //  })
          //   for (let i = 0; i < techttdataArray.length; i++) {
          //     console.log("sgrthdhr", techttdataArray[i]);
          //     tecttdata += techttdataArray[i] + ", ";
          //   }
          //   //  item['technology']=tec
          // }
          if(typeof(item["ttdata"])=="object"){
            let techttdataArray = item["ttdata"]?item["ttdata"].values:[];
            if (techttdataArray && techttdataArray.length > 0) {
              //  item['technology'].forEach((item)=>{
              //   tec +=item.label +','
              //  })
              for (let i = 0; i < techttdataArray.length; i++) {
                console.log("sgrthdhr", techttdataArray[i]);
                tecttdata += techttdataArray[i] + ", ";
              }
              //  item['technology']=tec
            }
          }else{
            tecttdata=item["ttdata"]
          }
          
          item["tecttdata"]=tecttdata
          //  console.log("item is =",item['technology'])
          // item['technology']=tec
          item["ttdatatemp"] =(
            <p
              className={"text-black my-0 modalHoverClass"}
              onClick={() =>
                CommonModalFun(

                  <p style={{ border: "2px solid black", color: "black", padding: 2 }} className="CommonModal">{parse(tecttdata.replaceAll("\n","<br>"))}</p>,
                  "Comment",
                  "TT Type"
                )
              }
            >
              {tecttdata}
            </p>
          )
          let tec = ""

          let techArray = item["technology"].values;
          if (techArray && techArray.length > 0) {
            //  item['technology'].forEach((item)=>{
            //   tec +=item.label +','
            //  })
            console.log("sgrthdhr", techArray.length);
            for (let i = 0; i < techArray.length; i++) {
              console.log("sgrthdhr", techArray[i]);
              tec += techArray[i].split("_CP_")[0] + ", ";
            }
            //  item['technology']=tec
          }
          console.log("item is tec =", tec);

          //  console.log("item is =",item['technology'])
          // item['technology']=tec
          item["techtemp"] = tec;
          return item;
        });
        console.log("jdbcjhbsdaikc", state);
      }
    }

    // console.log("columns", state.sites.sites.TotalTicket);

    return { rowData, columns, mark, siteStatus, totalCount };
  });

  const options = {

    download: true,
    viewColumns: false,
    print: false,
    filterType: "none",
    selectableRows: false,
    
    rowsPerPage: 25,
    count: 100,
    rowsPerPageOptions: [25, 50, 75],

 fixedHeader: true,
 fixedFooter: true,
 tableBodyHeight: "100%",
 responsive: "scroll",
 overflowY: "scroll",
  };


  const mapStyles = {
    width: "100%",
    height: "100%",
  };

  const dispatch = useDispatch();
  function accordianHandler() {
    setAccordianState(!accordianState);
  }

  createTheme("solarized", {
    text: {
      primary: "inherit",
      secondary: "inherit",
    },
    background: {
      default: "inherit",
    },
    context: {
      background: "inherit",
      text: "inherit",
    },
    divider: {
      default: "inherit",
    },
    button: {
      default: "inherit",
      hover: "rgba(0,0,0,.08)",
      focus: "rgba(255,255,255,.12)",
      disabled: "rgba(255, 255, 255, .34)",
    },
    sortFocus: {
      default: "inherit",
    },
  });
  console.log("yhe row data isn=", rowData);
  //on submiting the excel
  const submitHandler = async (e) => {
    e.preventDefault();
    console.log("remarks =", remarks);
    console.log("file=", siteFile);
    console.log(columns);
    const form = new FormData();
    form.append("uploadFile", siteFile);
    form.append("uploadfor", "Network_Database");
    // form.append("remarks",remarks);
    setRemarks("");
    // uploadedFile()
    dispatch(CSVUploadAction(form));
    console.log("form data =", form);
  };
  const uploadCsvFile = (e) => {
    console.log(e.target.files[0]);
    uploadedFile(e.target.files[0]);
  };

  const setDataVariables = async () => {
    dispatch(NetworkDatabaseAction());
  };

  const containerStyle = {
    width: "400px",
    height: "400px",
    position: "initial",
  };

  const AnyReactComponent = ({ text }) => <div>{text}</div>;

  const handleApiLoaded = (map, maps) => {
    // use map and maps objects
  };

  // const exportAllData = () => {
  //   dispatch(ExportXLSXDownloadReducerAction(export_tt_tracker,"TTTracker_" + moment(new Date()).format("MM-DD-YYYY")))
  // }

  const exportAllData = () => {
    let Filleddata=dispatch(LCLTTReducerAction())
    console.log(Filleddata,"FilleddataFilleddata")
    dispatch(ExportXLSXDownloadReducerAction(export_tt_tracker,"TTTracker_" + moment(new Date()).format("MM-DD-YYYY"),Filleddata))
  }

  function filterhandler(){
    
    dispatch(getFilterDropDown([]))
    dispatch(AccountReducerAction(ttfilters))
    setcommonModalState(true)
    setcommonModalBody(<TTFilterList setfullUrL={setfullUrL} setcommonModalState={setcommonModalState} setFilterBadge={setFilterBadge} featureExport={setexportApiData}/>)
    setcommonModalTitle("TT Filters")
    setcommonModalHeader("TT Filter")

  }


  const AddButton = () => (
    <>
    <p onClick={filterhandler} className=" mx-2 " style={{"align-self": "center"}}><FilterData/></p>
    <>

      <button
        className="btn btn-xs btn-primary mx-2 my-3"
        data-toggle="tooltip"
        data-placement="bottom"
        title="Refresh"
        onClick={() =>{ ClearFilter();}}
        style={{ padding: "6px" }}
      >
        <RefreshIcon />
      </button>

      <div
        style={{
          width: "46vh",
          display: "flex",
          alignItems: "center",
          margin: "18px 10px auto 10px"
        }}>
        <DatePicker
          placeholderText="MM/DD/YYYY"
          id="color-of-datepicker"
          className="date-picker-changes "
          popperPlacement=  'left'
          selected={fstartdate}
          autoComplete={"off"}
          onChange={(e) => {
            setfstartdate(e);
            // setfenddate(e);
          }}
          dateFormat="MM/dd/yyyy"
        />
        <DatePicker
          placeholderText="MM/DD/YYYY"
          className="date-picker-changes mx-1"
          id="color-of-datepicker"
          popperPlacement=  'left'
          autoComplete={"off"}
          selected={fenddate}
          onChange={(e) => {
            setfenddate(e);
          }}
          dateFormat="MM/dd/yyyy"
        />
      </div>
      <button
        variant="float-right"
        className="btn btn-primary btn-xs mx-2 py-2 my-3 float-right d-flex justify-content-center align-items-center"
        // onClick={() => setDatePicker(!showDatePicker)}
        onClick={(e) => setDateFilter(e)}
      >
        <i class="fa fa-filter mx-2"></i>Filter
      </button>
          
      </>
    <Button onClick={exportAllData} className="btn btn-primary btn-xs mx-2 py-2 my-3 d-flex justify-content-center align-items-center">
        Export
      </Button>
    </>
  );

  useEffect(() => {
    dispatch(AccountReducerAction(listtroubleTicketApi,true,true));
    // dispatch(AccountReducerAction(ranSiteStatus))
    // dispatch(AccountReducerAction(getAllDropDown))
    // dispatch(AccountReducerAction(listSiteId))

    if (!datamark) {
      setmarkersdata(datamark);
    }
    console.log(markersdata, "markersdata");
  }, [markersdata]);
  return (
    <>
          <CommonModal
          modalSize={commonModalSize}
          setXlShow={setcommonModalState}
          xlShow={commonModalState}
          Body={commonModalBody}
          Header={commonModalHeader}
          Title={commonModalTitle}
        ></CommonModal>
          <div className="mui-t-h"
          style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}
        >

      <ThemeProvider theme={getMuiThemeCommon()}>

        <MUIDataTable
          data={rowData}
          columns={columns}
          options={advancdcustomDataTableOptionObject(AddButton,false,"Trouble_Ticket_tracker_","","",sortedOrder, "listtt", "",rowData.length,count.totalCount,false,false,exportApiData,false)}
          // options={advancdcustomDataTableOptionObject(null,true,"Trouble_Ticket_tracker_","","",sortedOrder)}
        />
      </ThemeProvider>
      </div>
    </>
  );
};

export default TicketTracker;
