import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { SevenTwoAlarm, ixModule } from '../../../../common/config'
import { AccountReducerAction, FetchSingleRecordAction, addAcccountReducerAction, popUpUpdate } from '../../../../store/actions/createTicketManageFields'

const SevenTwoUpdateStatus = (props) => {
    let [zeroReport, setZeroReport] = useState(true)
    let [twoFourReport, setTwoFourReport] = useState(true)
    let [fourEightReport, setFourEightReport] = useState(true)
    let [sevenTwoReport, setsevenTwoReport] = useState(true)
    let [twoFourStatus, setTwoFourStatus] = useState(true)
    let [fourEightStatus, setFourEightStatus] = useState(true)
    let [sevenTwoStatus, setsevenTwoStatus] = useState(true)
    let [finalStatus, setfinalStatus] = useState(true)
    let [ntegrationAlarm, setIntegrationAlarm] = useState(true)
    let [oldData, setoldData] = useState("")
    let savedData = useSelector((state) => {
        let info = state.OneCommonResponse.singleIXSite
        // console.log("lcndkjlsafhvuatsvdcsfcew",sitesdata)
        return ({ info })
    })





    let option = ["Passed", "Failed"]
    const dispatch = useDispatch()
    let dropOption = option.map((item) => {

        return <option value={item}>{item}</option>
    })
    dropOption.unshift(<option disabled selected value={""}>Select</option>)
    let report = ["Yes", "No"]
    let reportDropDown = report.map((item) => {
        return <option value={item}>{item}</option>
    })
    reportDropDown.unshift(<option disabled selected value={""}>Select</option>)
    const { register, setValue, handleSubmit, getValues, setError, formState: { errors } } = useForm()

    const ChangeHander = (e, typeCase) => {
        // console.log("the target value =",getValues("zeroHrReport"))
        // if(e.target.value =="Passed"){
        //     setZeroReport(true)
        //     console.log("the nk cksdbchukdjfcevbgedvgefv=",e.target.value)
        // }
        // else{
        //     setValue("zeroHrReport","")
        //     setZeroReport(false)
        // }

        console.log(e, typeCase, "e.target.value")
        if (typeCase == 0) {

            if (e == "Passed") {
                // setZeroStatus(false)
                setTwoFourStatus(false)
                setFourEightStatus(true)
                setsevenTwoStatus(true)
                setIntegrationAlarm(true)
                setfinalStatus(true)
            } else {
                // setZeroStatus(false)
                setTwoFourStatus(true)
                setFourEightStatus(true)
                setsevenTwoStatus(true)
                setIntegrationAlarm(true)
                setfinalStatus(true)
                setValue("zeroHrReport", "")
                setValue("twoFourHrStatus", "")
                setValue("twoFourHrReport", "")
                setValue("FourEightHrStatus", "")
                setValue("FourEightHrReport", "")
                setValue("sevenTwoStatus", "")
                setValue("sevenTwoReport", "")
                setValue("sevenTwoFinalStatus", "")
                setValue("IntegrationAlarm", "")
            }
            if (e != "") {
                setZeroReport(false)
                setTwoFourReport(true)
                setFourEightReport(true)
                setsevenTwoReport(true)
                setIntegrationAlarm(true)
                setfinalStatus(true)
                console.log("the nk cksdbchukdjfcevbgedvgefv=", e)
            }
            else {
                setValue("zeroHrReport", "")
                setValue("twoFourHrStatus", "")
                setValue("twoFourHrReport", "")
                setValue("FourEightHrStatus", "")
                setValue("FourEightHrReport", "")
                setValue("sevenTwoStatus", "")
                setValue("sevenTwoReport", "")
                setValue("sevenTwoFinalStatus", "")
                setValue("IntegrationAlarm", "")
                setTwoFourReport(true)
                setFourEightReport(true)
                setsevenTwoReport(true)
                setIntegrationAlarm(true)
                setfinalStatus(true)
            }
        }
        else if (typeCase == 2) {

            if (e == "Passed") {
                setTwoFourStatus(false)
                setFourEightStatus(false)
                setsevenTwoStatus(true)
                setIntegrationAlarm(true)
                setfinalStatus(true)
            } else {
                setTwoFourStatus(false)
                setFourEightStatus(true)
                setsevenTwoStatus(true)
                setIntegrationAlarm(true)
                setfinalStatus(true)
                setValue("twoFourHrReport", "")
                setValue("FourEightHrStatus", "")
                setValue("FourEightHrReport", "")
                setValue("sevenTwoStatus", "")
                setValue("sevenTwoReport", "")
                setValue("sevenTwoFinalStatus", "")
                setValue("IntegrationAlarm", "")
            }

            if (e != "") {
                setTwoFourReport(false)
                setFourEightReport(true)
                setsevenTwoReport(true)
                setIntegrationAlarm(true)
                setfinalStatus(true)
                console.log("the nk ddewknmldnwkferv=", e)
            }
            else {
                setValue("twoFourHrReport", "")
                setValue("FourEightHrStatus", "")
                setValue("FourEightHrReport", "")
                setValue("sevenTwoStatus", "")
                setValue("sevenTwoReport", "")
                setValue("sevenTwoFinalStatus", "")
                setValue("IntegrationAlarm", "")
                setTwoFourReport(true)
                setFourEightReport(true)
                setsevenTwoReport(true)
                setIntegrationAlarm(true)
                setfinalStatus(true)
            }
        }
        else if (typeCase == 4) {


            if (e == "Passed") {
                // setZeroStatus(false)
                setTwoFourStatus(false)
                setFourEightStatus(false)
                setsevenTwoStatus(false)
                setIntegrationAlarm(true)
                setfinalStatus(true)
            } else {
                // setZeroStatus(false)
                setTwoFourStatus(false)
                setFourEightStatus(false)
                setsevenTwoStatus(true)
                setIntegrationAlarm(true)
                setfinalStatus(true)
                
                setValue("FourEightHrReport", "")
                setValue("sevenTwoStatus", "")
                setValue("sevenTwoReport", "")
                setValue("sevenTwoFinalStatus", "")
                setValue("IntegrationAlarm", "")
            }

            if (e != "") {

                // setZeroReport(false)
                setTwoFourReport(false)
                setFourEightReport(false)
                setsevenTwoReport(true)
                setIntegrationAlarm(true)
                setfinalStatus(true)
                console.log("the nk cksdbchukdjfcevbgedvgefv=", e)
            }
            else {
                setValue("FourEightHrReport", "")
                setValue("sevenTwoStatus", "")
                setValue("sevenTwoReport", "")
                setValue("sevenTwoFinalStatus", "")
                setValue("IntegrationAlarm", "")
                // setZeroReport(false)
                setTwoFourReport(false)
                setFourEightReport(true)
                setsevenTwoReport(true)
                setIntegrationAlarm(true)
                setfinalStatus(true)
            }
        }
        else if (typeCase == 7) {

            if (e == "Passed") {
                // setZeroStatus(false)
                setTwoFourStatus(false)
                setFourEightStatus(false)
                setsevenTwoStatus(false)
                setIntegrationAlarm(false)
                setfinalStatus(false)
            } else {
                // setZeroStatus(false)
                setTwoFourStatus(false)
                setFourEightStatus(false)
                setsevenTwoStatus(false)
                setIntegrationAlarm(true)
                setfinalStatus(true)
                setValue("sevenTwoReport", "")
                setValue("sevenTwoFinalStatus", "")
                setValue("IntegrationAlarm", "")    
            }


            if (e != "") {
                setZeroReport(false)
                setTwoFourReport(false)
                setFourEightReport(false)
                setsevenTwoReport(false)
                console.log("the nk cksdbchukdjfcevbgedvgefv=", e)
            }
            else {
                setValue("sevenTwoReport", "")
                setValue("sevenTwoFinalStatus", "")
                setValue("IntegrationAlarm", "")
                setZeroReport(false)
                setTwoFourReport(false)
                setFourEightReport(false)
                setsevenTwoReport(true)
            }
        }





    }

    const onSubmit = async (data) => {
        let userDetails = JSON.parse(window.localStorage.getItem("userDetails"))
        console.log("the response is =", props.id)
        // if(props.addOrUpdate){
        //     let savedData=props.addOrUpdate
        let changesArray = []
        // let keysarray=Object.keys(data)
        // // console.log("hvtyiwadcvyiefstf===",savedData)
        // keysarray.map((item)=>{
        //     if(data[item]==""){
        //         delete data[item]
        //     }
        // })
        // console.log("njlsbckjbvshhskdvbdfb=",keysarray)
        let ReportDetail = {
            "zeroHrStatus": "0 Hour Status",
            "zeroHrReport": "0 Hour Report Sent",
            "twoFourHrStatus": "24 Hours Status",
            "twoFourHrReport": "24 Hours Report Sent",
            "FourEightHrStatus": "48 Hours Status",
            "FourEightHrReport": "48 Hours Report Sent",
            "sevenTwoStatus": "72 Hours Status",
            "sevenTwoReport": "72 Hours Report Sent",
            "sevenTwoFinalStatus": "Final Status",
            "IntegrationAlarm": "Integration Alarm"
        }
        let savedKey = Object.keys(data)
        savedKey.map((item) => {
            // console.log("nkjhbhjkbvergv=",savedData[item][0],"===cldjkhvijckvkdfsvg==",data[item])
            // if(data[item] && savedData[item][0]!="" && savedData[item][0]!=data[item]){
            let changes = {
                "userName": userDetails.name,
                "ixID": props.id
            }
            changes["ActivityStatus"] = data[item]
            changes["ActivityType"] = ReportDetail[item]
            changesArray.push(changes)
            // }
        })
        data["logsData"] = changesArray
        dispatch(addAcccountReducerAction(SevenTwoAlarm + props.id, data))


        dispatch(popUpUpdate({}))
        props.setCloser(true)
        // }
        // else{
        // console.log("bjhbdc==",data)
        // data.ReportAct=1
        // dispatch(addAcccountReducerAction(SevenTwoAlarm+props.id,data))
        // }

    }


    console.log(props.oldData, "props.oldData")

    if (oldData != props.oldData.ranSupportId) {

        let oleer = props.oldData
        setoldData(props.oldData.ranSupportId)

        setValue("zeroHrReport", props.oldData.zeroHrReport)
        setValue("zeroHrStatus", props.oldData.zeroHrStatus)

        setValue("twoFourHrStatus", props.oldData.twoFourHrStatus, { shouldValidate: true })
        setValue("twoFourHrReport", props.oldData.twoFourHrReport, { shouldValidate: true })
        setValue("FourEightHrStatus", props.oldData.FourEightHrStatus, { shouldValidate: true })
        setValue("FourEightHrReport", props.oldData.FourEightHrReport, { shouldValidate: true })
        setValue("sevenTwoStatus", props.oldData.sevenTwoStatus, { shouldValidate: true })
        setValue("sevenTwoReport", props.oldData.sevenTwoReport, { shouldValidate: true })
        setValue("sevenTwoFinalStatus", props.oldData.sevenTwoFinalStatus, { shouldValidate: true })
        setValue("IntegrationAlarm", props.oldData.IntegrationAlarm, { shouldValidate: true })

        console.log(oleer.zeroHrStatus,"oleer.zeroHrStatus")
        if (oleer.zeroHrStatus != "") {
            setZeroReport(false)
            setTwoFourReport(true)
            setFourEightReport(true)
            setsevenTwoReport(true)
            setIntegrationAlarm(true)
            setfinalStatus(true)
            if(oleer.zeroHrStatus == "Passed"){
                setZeroReport(false)
                setTwoFourStatus(false)
                setFourEightStatus(true)
                setsevenTwoStatus(true)
            }
        }
        else {
            setTwoFourStatus(true)
            setFourEightStatus(true)
            setsevenTwoStatus(true)
            setTwoFourReport(true)
            setFourEightReport(true)
            setsevenTwoReport(true)
            setIntegrationAlarm(true)
            setfinalStatus(true)
        }
        if (oleer.twoFourHrStatus != "") {
            setTwoFourReport(false)
            setFourEightReport(true)
            setsevenTwoReport(true)
            setIntegrationAlarm(true)
            setfinalStatus(true)
            if(oleer.twoFourHrStatus == "Passed"){
                setTwoFourStatus(false)
                setFourEightStatus(false)
                setsevenTwoStatus(true)
            }
        } 
        else {
            setFourEightStatus(true)
            setsevenTwoStatus(true)
            setFourEightReport(true)
            setIntegrationAlarm(true)
            setfinalStatus(true)
            setsevenTwoReport(true)
        }
        
        if (oleer.FourEightHrStatus != "") {
            setTwoFourReport(false)
            setFourEightReport(false)
            setsevenTwoReport(true)
            setIntegrationAlarm(true)
            setfinalStatus(true)
            if(oleer.FourEightHrStatus == "Passed"){
                setTwoFourStatus(false)
                setFourEightStatus(false)
                setsevenTwoStatus(false)
            }
        }
        else {
            setsevenTwoStatus(true)
            setsevenTwoReport(true)
            setIntegrationAlarm(true)
            setfinalStatus(true)
        }
        
        if (oleer.sevenTwoStatus != "") {
            setTwoFourReport(false)
            setFourEightReport(false)
            setsevenTwoReport(false)
            if(oleer.sevenTwoStatus == "Passed"){
                setTwoFourStatus(false)
                setFourEightStatus(false)
                setsevenTwoStatus(false)
                setIntegrationAlarm(false)
                setfinalStatus(false)
            }
        } 
        else {
            setIntegrationAlarm(true)
            setfinalStatus(true)
        }
    }

    console.log(sevenTwoStatus,fourEightStatus,twoFourStatus,sevenTwoReport,fourEightReport,twoFourReport,zeroReport,"allCOdeOfReport")
    useEffect(() => {
        console.log("cnbsjkckshugcbegfkwdufwer")
        dispatch(FetchSingleRecordAction(SevenTwoAlarm, props.id));
    }, [])

    return (
        <form className='form-row' onSubmit={handleSubmit(onSubmit)}>
            <div className='form-group col-6'>
                <lable style={{ "color": "black" }}>0 Hour Status</lable>
                <select className='form-control'
                    {
                    ...register("zeroHrStatus", {

                    })
                    }
                    onChange={(e) => ChangeHander(e.target.value, 0)}
                >
                    {dropOption}
                </select>
            </div>

            {console.log(!zeroReport, zeroReport, "zeroReport", savedData.info.zeroHrStatus, "savedData.info.zeroHrStatus")}

            <div className='form-group col-6'>
                <lable style={{ "color": "black" }}>0 Hours Report Sent</lable>
                <select className='form-control'
                    {
                    ...register("zeroHrReport", {

                    })
                    }

                    disabled={zeroReport}
                >
                    {reportDropDown}
                </select>
            </div>
            <div className='form-group col-6'>
                <lable style={{ "color": "black" }}>24 Hours Status</lable>
                <select className='form-control'
                    {
                    ...register("twoFourHrStatus")
                    }
                    onChange={(e) => ChangeHander(e.target.value, 2)}
                    disabled={twoFourStatus}
                >
                    {dropOption}
                </select>
            </div>
            <div className='form-group col-6'>
                <lable style={{ "color": "black" }}>24 Hours Report Sent</lable>
                <select className='form-control'
                    {
                    ...register("twoFourHrReport")
                    }
                    disabled={twoFourReport}
                >
                    {reportDropDown}
                </select>
            </div>
            <div className='form-group col-6'>
                <lable style={{ "color": "black" }}>48 Hour Status</lable>
                <select className='form-control'
                    {
                    ...register("FourEightHrStatus")
                    }
                    onChange={(e) => ChangeHander(e.target.value, 4)}
                    disabled={fourEightStatus}
                >
                    {dropOption}
                </select>
            </div>
            <div className='form-group col-6'>
                <lable style={{ "color": "black" }}>48 Hours Report Sent</lable>
                <select className='form-control'
                    {
                    ...register("FourEightHrReport")
                    }
                    disabled={fourEightReport}
                >
                    {reportDropDown}
                </select>
            </div>
            <div className='form-group col-6'>
                <lable style={{ "color": "black" }}>72 Hour Status</lable>
                <select className='form-control'
                    {
                    ...register("sevenTwoStatus")
                    }
                    onChange={(e) => ChangeHander(e.target.value, 7)}
                    disabled={sevenTwoStatus}
                >
                    {dropOption}
                </select>
            </div>
            <div className='form-group col-6'>
                <lable style={{ "color": "black" }}>72 Hours Report Sent</lable>
                <select className='form-control'
                    {
                    ...register("sevenTwoReport")
                    }
                    disabled={sevenTwoReport}
                >
                    {reportDropDown}
                </select>
            </div>
            <div className='form-group col-6'>
                <label>Add final Status</label>
                <select
                    className='form-control'
                    disabled={finalStatus}
                    {
                    ...register("sevenTwoFinalStatus")
                    }
                // onChange={(e)=>setSelectedOpt(e.target.value)}
                >
                    <option disabled selected value={""}>Select</option>
                    <option value={"Pass"}>Passed</option>
                    <option value={"Fail"}>Fail</option>
                    <option value={"Pending"}>Pending</option>
                </select>
            </div>
            <div className='form-group col-6'>
                <label>IX Alarm Free Report sent</label>
                <select
                    className='form-control'
                    disabled={ntegrationAlarm}
                    {
                    ...register("IntegrationAlarm")
                    }
                // onChange={(e)=>setSelectedOpt(e.target.value)}
                >
                    <option disabled selected value={""}>Select</option>
                    <option value={"Yes"}>Yes</option>
                    <option value={"No"}>No</option>
                    {/* <option  value={"Pending"}>Pending</option> */}
                </select>
            </div>
            <div className='col-12 align-content-center d-flex' >
                <button type="submit" className="badge badge-secondary">Submit</button>

            </div>

        </form>
    )
}

export default SevenTwoUpdateStatus