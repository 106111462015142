import React, { useState } from 'react'
import MUIDataTable from "mui-datatables";
import { useEffect } from 'react';
import { Row, Card, Col, Dropdown, Button, Modal, Container } from "react-bootstrap";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { accountApi, addUser, deleteUser, listRole_twoApi, getMarket, crewCompanyApi, listUserApi_two, vendorApi, addAcccount, listOneUser, updateUser, CsvUpload, dynamic_dashboard, export_inflight_dashboard } from '../../../common/config';
import { AccountReducerAction, OneUserReducerAction, addUserAction, deleteAcccountReducerAction, GetMarketReducerAction, getCrewCompanyAction, updateAcccountReducerAction, RoleReducerAction, UserListReducerAction, GetCSVReducerAction, inflightGetCSVReducerAction, ExportXLSXDownloadReducerAction } from '../../../store/actions/createTicketManageFields';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { black } from 'material-ui/styles/colors';
import { PDFUploadActionreturn } from '../../../store/actions/dashboardActions';
import { google_map_api, center, dbdatetodate, customDataTableOptionObject, getMuiThemeCommon } from "../../../common/variables";
import moment from "moment";
import TablePagination from '@mui/material/TablePagination';
import CommonBulkUploader from '../Custom/CommonBulkUploader';



const Inflightbulkfile = (props) => {
    console.log(props, "props")

    const url = window.location.href.split("/")
    let dynamicValue = url.pop()
    let pStatusArry = []
    let vStatusArry = []
    let mStatusArry = []
    let cStatusArry = []

    // let editTrue=0
    const options = {
        download: "false",
        filterType: 'none',
        selectableRows: false,
        responsive: true,
        responsive: "scrollMaxHeight",
    };

    let accountArry = []
    let userArry = []

    let columns = []

    const { register, setValue, handleSubmit, reset, formState: { errors } } = useForm({});
    const [show, setShow] = useState(false);
    const [siteFile, uploadedFile] = useState([]);
    const [fileshow, setfileShow] = useState(false);
    const [page, setPage] = useState(2);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [senter, setsenter] = useState(2);

    const handleChangePage = (abc) => {
        setPage(page + 1);
        console.log("handleChangePage",)
        if (abc.target.dataset.testid == "KeyboardArrowRightIcon") {
            setsenter(1)
            dispatch(inflightGetCSVReducerAction("/" + dynamicValue + dynamic_dashboard + "?page=" + page * 10))
            setPage(page + 1);
        } else if (abc.target.dataset.testid == "KeyboardArrowLeftIcon") {
            setPage(page - 1);
        }
    };

    const handleChangeRowsPerPage = () => {

        console.log("handleChangeRowsPerPage")
        setRowsPerPage(11);
        setPage(0);
    };

    const exportAllData = () => {
        dispatch(ExportXLSXDownloadReducerAction(export_inflight_dashboard,"INFLIGHT_" + moment(new Date()).format("MM-DD-YYYY")))
    }

    const submitHandler = async (e) => {
        e.preventDefault();
        console.log("form==", "form")
        let newform = new FormData();
        newform.append("uploadFile", siteFile);
        newform.append("uploadfor", dynamicValue)
        console.log("form==", newform)
        console.log("resp", dispatch(PDFUploadActionreturn(CsvUpload, newform, "usercrew")))
        setShow(false);
    };


    const uploadCsvFile = (e) => {
        // let files=[]
        console.log(e.target.files, "files")
        uploadedFile(e.target.files[0])

    };



    const filehandleClose = () => {
        reset({})
        setfileShow(false);
    }
    const filehandleShow = () => {
        reset({})
        setfileShow(true)
    }

    // const uploadCsvFile = (e) => {
    //   let files=[]

    //   console.log(e.target.files[0],"ON_CHANGE_FILES:", e.target.files.length);

    //   for(let i=0;i<e.target.files.length;i++){
    //     files.push(e.target.files[i])
    //   }
    //   console.log(...files)
    //   uploadedFile(files)

    // };



    const [modalCentered, setModalCentered] = useState(false);
    const [newAccount, setAccount] = useState({})
    const [editTrue, seteditTrue] = useState(0)
    const [updateTrue, setupdateTrue] = useState(0)
    const [formtype, addformtype] = useState('')





    let oneaccount = {}


    // setAccount(oneaccount)
    //  console.log(oneaccount,"oneaccount")
    let getMuiTheme = () =>
        createTheme({
            components: {
                MUIDataTable: {
                    styleOverrides: {
                        root: {
                            //   backgroundColor: '#red',
                            height: 700
                        },
                        paper: {
                            boxShadow: 'none',
                        },
                    },
                },
                MUIDataTableBodyCell: {
                    styleOverrides: {
                        root: {
                            maxHeight: 45,
                            fontSize: 11,
                            overflowY: "auto",
                            padding: "0px 2px !important",
                            margin: 0,
                            textAlign: 'center'
                        },
                    }
                },
                // MuiToolbar: {
                //   styleOverrides: {
                //     root: {
                //       backgroundColor: '#f00',

                //     },
                //   },
                // },
                MuiTableCell: {
                    styleOverrides: {
                        head: {
                            maxHeight: 20,
                            fontSize: 11,
                            margin: 0,
                            paddingTop: 0,
                            paddingBottom: 0
                        },
                    },
                },
                MUIDataTableSelectCell: {
                    styleOverrides: {
                        headerCell: {
                            backgroundColor: 'blue',
                        },
                    },
                },
                MuiTableFooter: {
                    styleOverrides: {
                        root: {
                            '& .MuiToolbar-root': {
                                backgroundColor: 'white',
                            },
                        },
                    },
                },
            },
        });

    console.log()
    const updateHandler = (e) => {
        console.log("button update clicked", e)

        dispatch(addUserAction(addAcccount, e))

    }
    const deleteHandler = (e) => {
        console.log("button delete clicked", e)
        dispatch(deleteAcccountReducerAction(deleteUser, e, "crew"))
    }


    const editpopupHandler = (e) => {
        seteditTrue(1)
        reset({})
        console.log("button editpopupHandler clicked", e)
        dispatch(OneUserReducerAction(listOneUser + "/" + e.uniqueid))
    }


    const onSubmit = async (data) => {
        console.log(data, "dassdaas")

        if (data.uniqueid != undefined) {
            dispatch(updateAcccountReducerAction(updateUser, data.uniqueid, data, "crew"))
        }
        else {
            dispatch(addUserAction(addUser, data, "crew"))
        }

    }

    const dispatch = useDispatch();
    let keyArray = []
    let labelMapping = []

    // columns = [
    //     {
    //         name: "MarketCombined",
    //         selector: "MarketCombined",
    //         sortable: true,
    //         label: "Market Combined", 
    //         options: {
    //           filter: true,
    //           sort: false,

    //           setCellHeaderProps: () => ({
    //             style: {
    //               padding : "3px 10px",
    //               fontSize : 13,
    //               fontWeight : 500,
    //              textAlign : "left",                  
    //              background:"#143b64",
    //               color:"white"

    //             }
    //           })
    //          }
    //         },

    //     {
    //         "name": "RingCode",
    //         "selector": "RingCode",
    //         "sortable": true,
    //         "label": "R:Ring Code", 
    //         options: {
    //           filter: true,
    //           sort: false,

    //           setCellHeaderProps: () => ({
    //             style: {
    //               padding : "3px 10px",
    //               fontSize : 13,
    //               fontWeight : 500,
    //              textAlign : "left",
    //               background:"#143b64",
    //               color:"white"

    //             }
    //           })
    //          }
    //         },
    //     {
    //         "name": "siteIdName",
    //         "selector": "siteIdName",
    //         "sortable": true,
    //         "label": "S:Site Code", 
    //         options: {
    //           filter: true,
    //           sort: false,

    //           setCellHeaderProps: () => ({
    //             style: {
    //               padding : "3px 10px",
    //               fontSize : 13,
    //               fontWeight : 500,
    //              textAlign : "left",
    //               background:"#143b64",
    //               color:"white"

    //             }
    //           })
    //          }
    //         },
    //     {
    //         "name": "siteStatus",
    //         "selector": "siteStatus",
    //         "sortable": true,
    //         "label": "S:Site Status", 
    //         options: {
    //           filter: true,
    //           sort: false,

    //           setCellHeaderProps: () => ({
    //             style: {
    //               padding : "3px 10px",
    //               fontSize : 13,
    //               fontWeight : 500,
    //              textAlign : "left",
    //               background:"#143b64",
    //               color:"white"

    //             }
    //           })
    //          }
    //         },
    //     {
    //         "name": "ProgramName",
    //         "selector": "ProgramName",
    //         "sortable": true,
    //         "label": "PJ:Program Name",
    //          options: {
    //           filter: true,
    //           sort: false,

    //           setCellHeaderProps: () => ({
    //             style: {
    //               padding : "3px 10px",
    //               fontSize : 13,
    //               fontWeight : 500,
    //              textAlign : "left",
    //               background:"#143b64",
    //               color:"white"

    //             }
    //           })
    //          }
    //         }
    //     // {
    //     //     "name": "projectCode",
    //     //     "selector": "projectCode",
    //     //     "sortable": true,
    //     //     "label": "PJ:Project Code",
    //     //      options: {
    //     //       filter: true,
    //     //       sort: false,

    //     //       setCellHeaderProps: () => ({
    //     //         style: {
    //     //           padding : "3px 10px",
    //     //           fontSize : 13,
    //     //           fontWeight : 500,
    //     //          textAlign : "left",
    //     //           background:"#143b64",
    //     //           color:"white"

    //     //         }
    //     //       })
    //     //      }
    //     //     }
    //         ,{
    //           "name": "projectId",
    //           "selector": "projectId",
    //           "sortable": true,
    //           "label": "PJ:Project Id",
    //            options: {
    //             filter: true,
    //             sort: false,

    //             setCellHeaderProps: () => ({
    //               style: {
    //                 padding : "3px 10px",
    //                 fontSize : 13,
    //                 fontWeight : 500,
    //                textAlign : "left",
    //                 background:"#143b64",
    //                 color:"white"

    //               }
    //             })
    //            }
    //           },
    //         {
    //         "name": "projectCode",
    //         "selector": "projectCode",
    //         "sortable": true,
    //         "label": "POR:Plan Type", 
    //         options: {
    //           filter: true,
    //           sort: false,

    //           setCellHeaderProps: () => ({
    //             style: {
    //               padding : "3px 10px",
    //               fontSize : 13,
    //               fontWeight : 500,
    //              textAlign : "left",
    //               background:"#143b64",
    //               color:"white"

    //             }
    //           })
    //          }
    //         },
    //     {
    //         "name": "CurrentPlanStatus",
    //         "selector": "CurrentPlanStatus",
    //         "sortable": true,
    //         "label": "POR:Tranche", 
    //         options: {
    //           filter: true,
    //           sort: false,

    //           setCellHeaderProps: () => ({
    //             style: {
    //               padding : "3px 10px",
    //               fontSize : 13,
    //               fontWeight : 500,
    //              textAlign : "left",
    //               background:"#143b64",
    //               color:"white"

    //             }
    //           })
    //          }
    //         },
    //     {
    //         "name": "latitude",
    //         "selector": "latitude",
    //         "sortable": true,
    //         "label": "S:Site Latitude", 
    //         options: {
    //           filter: true,
    //           sort: false,

    //           setCellHeaderProps: () => ({
    //             style: {
    //               padding : "3px 10px",
    //               fontSize : 13,
    //               fontWeight : 500,
    //              textAlign : "left",
    //               background:"#143b64",
    //               color:"white"

    //             }
    //           })
    //          }
    //         },
    //     {
    //         "name": "longitude",
    //         "selector": "longitude",
    //         "sortable": true,
    //         "label": "S:Site Longitude",
    //          options: {
    //           filter: true,
    //           sort: false,

    //           setCellHeaderProps: () => ({
    //             style: {
    //               padding : "3px 10px",
    //               fontSize : 13,
    //               fontWeight : 500,
    //              textAlign : "left",
    //               background:"#143b64",
    //               color:"white"

    //             }
    //           })
    //          }
    //         }
    // ]

    columns = [

        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "90px"

                    }
                })
            },
            'label': 'M:Market Abbr',
            'sortable': true,
            'name': 'marketAbbr',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        'min-width': "100px"

                    }
                })
            },
            'label': 'Market Combined',
            'sortable': true,
            'name': 'MarketCombined',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        'min-width': "90px"

                    }
                })
            },
            'label': 'R:Ring Code',
            'sortable': true,
            'name': 'RingCode',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        'min-width': "100px"

                    }
                })
            },
            'label': 'S:Site Code',
            'sortable': true,
            'name': 'siteIdName',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "80px"

                    }
                })
            },
            'label': 'PJ:Project ID',
            'sortable': true,
            'name': 'projectId',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        'min-width': "100px"

                    }
                })
            },
            'label': 'POR:Plan Type',
            'sortable': true,
            'name': 'projectCode',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "90px"

                    }
                })
            },
            'label': 'POR:Release Version',
            'sortable': true,
            'name': 'ReleaseVersion',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        'min-width': "108px"

                    }
                })
            },
            'label': 'POR:Current Plan Status',
            'sortable': true,
            'name': 'CurrentPlanStatus',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "95px"

                    }
                })
            },
            'label': 'POR:Tranche',
            'sortable': true,
            'name': 'Tranche',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "160px"

                    }
                })
            },
            'label': 'Approved Build',
            'sortable': true,
            'name': 'ApprovedBuild',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "130px"

                    }
                })
            },
            'label': '2023 Strategic Plan',
            'sortable': true,
            'name': 'tzttStrategicPlan',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "125px"

                    }
                })
            },
            'label': 'Combined Need By',
            'sortable': true,
            'name': 'CombinedNeedBy',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        'min-width': "90px"

                    }
                })
            },
            'label': 'POR:Objective',
            'sortable': true,
            'name': 'Objective',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "100px"

                    }
                })
            },
            'label': 'POR:Category',
            'sortable': true,
            'name': 'Category',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "180px"

                    }
                })
            },
            'label': 'PJ:Project Type',
            'sortable': true,
            'name': 'projectType',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "220px"

                    }
                })
            },
            'label': 'PJ:Completion Objective',
            'sortable': true,
            'name': 'CompletionObjective',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "85px"

                    }
                })
            },
            'label': 'POR:Plan Added Date',
            'sortable': true,
            'name': 'PlanAddedDate',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "70px"

                    }
                })
            },
            'label': 'POR:Unit Type',
            'sortable': true,
            'name': 'UnitType',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "90px"

                    }
                })
            },
            'label': 'NSC Status',
            'sortable': true,
            'name': 'NSCStatus',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "85px"

                    }
                })
            },
            'label': 'ADM Final Disposition',
            'sortable': true,
            'name': 'ADMFinalDisposition',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "74px"

                    }
                })
            },
            'label': 'R:Legacy Sprint ID',
            'sortable': true,
            'name': 'LegacySprintID',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "118px"

                    }
                })
            },
            'label': 'POR:Decom Ring ID',
            'sortable': true,
            'name': 'DecomRingID',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "100px"

                    }
                })
            },
            'label': 'Decomm Flag',
            'sortable': true,
            'name': 'DecommFlag',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        'min-width': "60px"

                    }
                })
            },
            'label': 'Macro Flag',
            'sortable': true,
            'name': 'MacroFlag',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "75px"

                    }
                })
            },
            'label': 'L600 Flag',
            'sortable': true,
            'name': 'LszzFlag',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "70px"

                    }
                })
            },
            'label': '2.5 Flag',
            'sortable': true,
            'name': 'tpfFlag',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "80px"

                    }
                })
            },
            'label': 'Retain Flag',
            'sortable': true,
            'name': 'RetainFlag',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        'min-width': "80px"

                    }
                })
            },
            'label': 'Excal Flag',
            'sortable': true,
            'name': 'ExcalFlag',

        },
        // {
        //     options: {
        //         filter: true,
        //         sort: false,

        //         setCellHeaderProps: () => ({
        //             style: {
        //                 padding: "3px 10px",
        //                 fontSize: 11,
        //                 fontWeight: 500,
        //                 textAlign: "left",
        //                 background: "#143b64",
        //                 color: "white",
        //                 'min-width': "80px"

        //             }
        //         })
        //     },
        //     'label': 'Excal Flag',
        //     'sortable': true,
        //     'name': 'ExcalFlag',

        // },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "115px"

                    }
                })
            },
            'label': 'Primary Strategic Pillar',
            'sortable': true,
            'name': 'PrimaryStrategicPillar',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "135px"

                    }
                })
            },
            'label': 'Strategic Categories',
            'sortable': true,
            'name': 'StrategicCategories',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "110px"

                    }
                })
            },
            'label': 'Excalibur Build',
            'sortable': true,
            'name': 'ExcaliburBuild',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "153px"

                    }
                })
            },
            'label': 'POR:Forecast Plan Type',
            'sortable': true,
            'name': 'ForecastPlanType',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        'min-width': "100px"


                    }
                })
            },
            'label': 'CBE Categories',
            'sortable': true,
            'name': 'CBECategories',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "95px"

                    }
                })
            },
            'label': 'Bundled POR Count',
            'sortable': true,
            'name': 'BundledPORCount',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "150px"

                    }
                })
            },
            'label': 'PJ(1075) Preliminary RFDS Issued',
            'sortable': true,
            'name': 'PJozsfPreliminaryRFDSIssued',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '1075:S',
            'sortable': true,
            'name': 'PJozsf',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "110px"

                    }
                })
            },
            'label': 'PJ(1200) Colo App Submitted',
            'sortable': true,
            'name': 'PJotzzColoAppSubmitted',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "65px"

                    }
                })
            },
            'label': '1200:S',
            'sortable': true,
            'name': 'PJotzzColoApp',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "135px"

                    }
                })
            },
            'label': 'PJ(1225) Colo App Approved',
            'sortable': true,
            'name': 'PJottfColoAppApproved',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '1225:S',
            'sortable': true,
            'name': 'PJottfColoApp',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "135px"

                    }
                })
            },
            'label': 'PJ(1400) Structural Complete',
            'sortable': true,
            'name': 'PJofzzStructuralComplete',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '1400:S',
            'sortable': true,
            'name': 'PJofzz',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "100px"

                    }
                })
            },
            'label': 'PJ:Structural Result',
            'sortable': true,
            'name': 'StructuralResult',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "135px"

                    }
                })
            },
            'label': 'PJ:Mount Analysis Received [Status]',
            'sortable': true,
            'name': 'MountAnalysisReceived[Status]',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "158px"

                    }
                })
            },
            'label': 'PJ:Tower Modification Cost ',
            'sortable': true,
            'name': 'TowerModificationCost',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "182px"

                    }
                })
            },
            'label': 'PJ(2275) Final Construction Drawings Approved',
            'sortable': true,
            'name': 'PJttsfFinalConstructionDrawingsApproved',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '2275:S',
            'sortable': true,
            'name': 'PJttsf',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "135px"

                    }
                })
            },
            'label': 'PJ(2475) Final RFDS Issued',
            'sortable': true,
            'name': 'PJtfsfFinalRFDSIssued',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '2475:S',
            'sortable': true,
            'name': 'PJtfsf',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "94px",
                        "min-width": "188px"

                    }
                })
            },
            'label': 'PJ(3500) All Regulatory Complete',
            'sortable': true,
            'name': 'PJtfzzAllRegulatoryComplete',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '3500:S',
            'sortable': true,
            'name': 'PJtfzz',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "115px"

                    }
                })
            },
            'label': 'PJ(3575) Zoning Approved',
            'sortable': true,
            'name': 'PJtfsfZoningApproved',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        'min-width': "80px"

                    }
                })
            },
            'label': '3575:S',
            'sortable': true,
            'name': 'PJtf',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "165px"

                    }
                })
            },
            'label': 'PJ(3650) Building Permit Applied',
            'sortable': true,
            'name': 'PJtsfzBuildingPermitApplied',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '3650:S',
            'sortable': true,
            'name': 'PJtsfz',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "168px"

                    }
                })
            },
            'label': 'PJ(3675) Building Permit Approved',
            'sortable': true,
            'name': 'PJtssfBuildingPermitApproved',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "65px"

                    }
                })
            },
            'label': '3675:S',
            'sortable': true,
            'name': 'PJtssf',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "190px"

                    }
                })
            },
            'label': 'PJ:Building Permit Expiration Date',
            'sortable': true,
            'name': 'BuildingPermitExpirationDate',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "145px"

                    }
                })
            },
            'label': 'PJ(2010) RAN Leases Executed',
            'sortable': true,
            'name': 'PJtzozRANLeasesExecuted',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '2010:S',
            'sortable': true,
            'name': 'PJtzoz',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "135px"

                    }
                })
            },
            'label': 'PJ(2025) All Leases Executed',
            'sortable': true,
            'name': 'PJtztfAllLeasesExecuted',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '2025:S',
            'sortable': true,
            'name': 'PJtztf',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "75px"

                    }
                })
            },
            'label': 'NSC NLRP',
            'sortable': true,
            'name': 'NSCNLRP',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "145px"

                    }
                })
            },
            'label': 'PJ(3710) RAN Entitlement Complete',
            'sortable': true,
            'name': 'PJtsozRANEntitlementComplete',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '3710:S',
            'sortable': true,
            'name': 'PJtsoz',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "200px"

                    }
                })
            },
            'label': 'PJ(3715) AAV Entitlement Complete',
            'sortable': true,
            'name': 'PJtsofAAVEntitlementComplete',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '3715:S',
            'sortable': true,
            'name': 'PJtsof',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "170px"

                    }
                })
            },
            'label': 'PJ(3720) MW Entitlement Complete',
            'sortable': true,
            'name': 'PJtstzMWEntitlementComplete',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '3720:S',
            'sortable': true,
            'name': 'PJtstz',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "140px"

                    }
                })
            },
            'label': 'PJ(3725) Entitlement Complete',
            'sortable': true,
            'name': 'PJtstfEntitlementComplete',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "66px"

                    }
                })
            },
            'label': '3725:S',
            'sortable': true,
            'name': 'PJtstf',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "70px"

                    }
                })
            },
            'label': '3725:WK',
            'sortable': true,
            'name': 'PJtstfwk',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "95px"

                    }
                })
            },
            'label': '3725:YR-QTR',
            'sortable': true,
            'name': 'PJtstfyrqtr',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "242px"

                    }
                })
            },
            'label': 'PJ(3825) Pre-Construction Walk/Site Survey Complete',
            'sortable': true,
            'name': 'PJtetfPreConstructionWalk/SiteSurveyComplete',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '3825:S',
            'sortable': true,
            'name': 'PJtetf',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "120px"

                    }
                })
            },
            'label': 'PJ(4000) LL NTP Received',
            'sortable': true,
            'name': 'PJfzzzLLNTPReceived',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '4000:S',
            'sortable': true,
            'name': 'PJfzzz',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "170px"

                    }
                })
            },
            'label': 'PJ(4075) Construction NTP Submitted to GC',
            'sortable': true,
            'name': 'PJfzsfConstructionNTPSubmittedtoGC',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '4075:S',
            'sortable': true,
            'name': 'PJfzsfC',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "180px"

                    }
                })
            },
            'label': 'PJ(4100) Construction NTP Accepted by GC',
            'sortable': true,
            'name': 'PJfozzConstructionNTPAcceptedbyGC',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '4100:S',
            'sortable': true,
            'name': 'PJfozzC',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        'min-width': "80px"

                    }
                })
            },
            'label': 'Draft BOM',
            'sortable': true,
            'name': 'DraftBOM',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        'min-width': '200px',
                        background: "#143b64",
                        color: "white",
                        "min-width": "220px"

                    }
                })
            },
            'label': 'PJ(2350) BOM Submitted from Market to Region',
            'sortable': true,
            'name': 'PJttfzBOMSubmittedfromMarkettoRegion',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '2350:S',
            'sortable': true,
            'name': 'PJttfzB',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "172px"

                    }
                })
            },
            'label': 'PJ(3850) BOM Submitted (BOM in BAT)',
            'sortable': true,
            'name': 'PJtefzBOMSubmittedBOMinBAT',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '3850:S',
            'sortable': true,
            'name': 'PJtefzBOMSubmitted',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "182px"

                    }
                })
            },
            'label': 'PJ(3875) BOM Received (BOM in AIMS)',
            'sortable': true,
            'name': 'PJtesfBOMReceivedBOMinAIMS',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "58px"

                    }
                })
            },
            'label': '3875:S',
            'sortable': true,
            'name': 'PJtesf',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "75px"

                    }
                })
            },
            'label': 'BOM Order IDs',
            'sortable': true,
            'name': 'BOMOrderIDs',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "110px"

                    }
                })
            },
            'label': 'BOM Order Type',
            'sortable': true,
            'name': 'BOMOrderType',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "100px"

                    }
                })
            },
            'label': 'BOM Approval Status',
            'sortable': true,
            'name': 'BOMApprovalStatus',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "145px"

                    }
                })
            },
            'label': 'Market Approval Date',
            'sortable': true,
            'name': 'MarketApprovalDate',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "123px"

                    }
                })
            },
            'label': 'Region Approval Date',
            'sortable': true,
            'name': 'RegionApprovalDate',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "130px"

                    }
                })
            },
            'label': 'BOM Order Close Date',
            'sortable': true,
            'name': 'BOMOrderCloseDate',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "130px"

                    }
                })
            },
            'label': 'AIMS Order Eligible',
            'sortable': true,
            'name': 'AIMSOrderEligible',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "170px"

                    }
                })
            },
            'label': 'Civil BOM Submitted from Market to Region',
            'sortable': true,
            'name': 'CivilBOMSubmittedfromMarkettoRegion',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "90px"

                    }
                })
            },
            'label': 'Civil BOM Allocated',
            'sortable': true,
            'name': 'CivilBOMAllocated',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "125px"

                    }
                })
            },
            'label': 'PJ(4250) Civil Construction Start',
            'sortable': true,
            'name': 'PJftfzCivilConstructionStart',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '4250:S',
            'sortable': true,
            'name': 'PJftfzCivilC',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "155px"

                    }
                })
            },
            'label': 'PJ(4225) Construction Start',
            'sortable': true,
            'name': 'PJfttfConstructionStart',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "56px"

                    }
                })
            },
            'label': '4225:S',
            'sortable': true,
            'name': 'PJfttfs',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "68px"

                    }
                })
            },
            'label': '4224:WK',
            'sortable': true,
            'name': 'PJfttfwk',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "80px"

                    }
                })
            },
            'label': 'Original Lock Week',
            'sortable': true,
            'name': 'OriginalLockWeek',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "100px"

                    }
                })
            },
            'label': 'CX Lock Week',
            'sortable': true,
            'name': 'CXLockWeek',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "70px"

                    }
                })
            },
            'label': '4225:YR-QTR',
            'sortable': true,
            'name': 'CXLockWeekyrqytr',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "158px"

                    }
                })
            },
            'label': 'PJ:Construction Start Delay Code',
            'sortable': true,
            'name': 'ConstructionStartDelayCode',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "100px"

                    }
                })
            },
            'label': 'PJ:CS Delay Date',
            'sortable': true,
            'name': 'CSDelayDate',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "90px"

                    }
                })
            },
            'label': 'PJ:Materials Returned',
            'sortable': true,
            'name': 'MaterialsReturned',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "155px"

                    }
                })
            },
            'label': 'PJ(4750) Civil Construction Complete',
            'sortable': true,
            'name': 'PJfsfzCivilConstructionComplete',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '4750:S',
            'sortable': true,
            'name': 'PJfsfzS',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "150px"

                    }
                })
            },
            'label': 'PJ(5175) Construction Complete',
            'sortable': true,
            'name': 'PJfosfConstructionComplete',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '5175:S',
            'sortable': true,
            'name': 'PJfosfS',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "70px"

                    }
                })
            },
            'label': '5175:WK',
            'sortable': true,
            'name': 'PJfosfWK',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "175px"

                    }
                })
            },
            'label': 'PJ:Construction Complete Delay Code',
            'sortable': true,
            'name': 'ConstructionCompleteDelayCode',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "140px"

                    }
                })
            },
            'label': 'PJ(4975) Ethernet Ready for Service',
            'sortable': true,
            'name': 'PJfnsfEthernetReadyforService',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "58px"

                    }
                })
            },
            'label': '4975:S',
            'sortable': true,
            'name': 'PJfnsfE',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "155px"

                    }
                })
            },
            'label': 'PJ(5025) AAV Activation Complete',
            'sortable': true,
            'name': 'PJfztfAAVActivationComplete',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '5025:S',
            'sortable': true,
            'name': 'PJfztfA',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "105px"

                    }
                })
            },
            'label': 'S:Primary AAV Vendor Name',
            'sortable': true,
            'name': 'PrimaryAAVVen',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "130px"

                    }
                })
            },
            'label': 'BKL Recommended Option',
            'sortable': true,
            'name': 'BKLRecomme',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "150px"

                    }
                })
            },
            'label': 'S:Primary AAV Vendor Name.1',
            'sortable': true,
            'name': 'PrimaryAAVVendorName',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "110px"

                    }
                })
            },
            'label': 'S:Temp AAV 3.0 Retain Site',
            'sortable': true,
            'name': 'TempAAVt.zRetainSite',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "80px"

                    }
                })
            },
            'label': '10GUP Program',
            'sortable': true,
            'name': 'ozGUPProgram',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "143px"

                    }
                })
            },
            'label': 'PJ(5525) Approve Close-Out Binder',
            'sortable': true,
            'name': 'PJfftfApproveCloseOutBinder',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '5525:S',
            'sortable': true,
            'name': 'PJfftfApprove',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "135px"

                    }
                })
            },
            'label': 'PJ(5275) Integration Complete',
            'sortable': true,
            'name': 'PJftsfIntegrationComplete',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '5275:S',
            'sortable': true,
            'name': 'PJftsfIntegration',

        },
        {
            options: {
                filter: true,
                sort: false,
                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "150px"

                    }
                })
            },
            'label': 'PJ(5350) Modification Complete',
            'sortable': true,
            'name': 'PJftfzModificationComplete',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '5350:S',
            'sortable': true,
            'name': 'PJftfzModi',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "140px"

                    }
                })
            },
            'label': 'S:On-Air Date - UMTS',
            'sortable': true,
            'name': 'OnAirDateUMTS',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "140px"

                    }
                })
            },
            'label': 'S:On-Air Date - GSM',
            'sortable': true,
            'name': 'OnAirDateGSM',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "100px"

                    }
                })
            },
            'label': 'PJ(5750) L1900 OnAir',
            'sortable': true,
            'name': 'PJfsfzLonzzOnAir',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "58px"

                    }
                })
            },
            'label': '5750:S',
            'sortable': true,
            'name': 'PJfsfzLon',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "105px"

                    }
                })
            },
            'label': 'S:On-Air Date - L1900',
            'sortable': true,
            'name': 'OnAirDateLonzz',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "130px"

                    }
                })
            },
            'label': 'PJ(5800) L2100 OnAir',
            'sortable': true,
            'name': 'PJfezzLtozzOnAir',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '5800:S',
            'sortable': true,
            'name': 'PJfezzLtoz',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        'min-width': "150px"

                    }
                })
            },
            'label': 'S:On-Air Date - L2100',
            'sortable': true,
            'name': 'OnAirDateLtozz',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "160px"

                    }
                })
            },
            'label': 'PJ(5850) L2100 AWS3 OnAir',
            'sortable': true,
            'name': 'PJfefzLtozzAWStOnAir',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '5850:S',
            'sortable': true,
            'name': 'PJfefzLtozz',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "157px"

                    }
                })
            },
            'label': 'S:On-Air Date - L2100 AWS3',
            'sortable': true,
            'name': 'OnAirDateLtozzAWSt',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "110px"

                    }
                })
            },
            'label': 'PJ(5863) L2500 OnAir',
            'sortable': true,
            'name': 'PJfestLtfzzOnAir',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "70px"

                    }
                })
            },
            'label': '5863:S',
            'sortable': true,
            'name': 'PJfest',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "150px"

                    }
                })
            },
            'label': 'S:On-Air Date - N2500',
            'sortable': true,
            'name': 'OnAirDateNtfzz',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "100px"

                    }
                })
            },
            'label': 'PJ(5900) L600 OnAir',
            'sortable': true,
            'name': 'PJfnzzLszzOnAir',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '5900:S',
            'sortable': true,
            'name': 'PJfnzzLszz',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "135px"

                    }
                })
            },
            'label': 'S:On-Air Date - L600',
            'sortable': true,
            'name': 'OnAirDateLszz',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "100px"

                    }
                })
            },
            'label': 'PJ(6000) L700 OnAir',
            'sortable': true,
            'name': 'PJszzzLszzOnAir',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '6000:S',
            'sortable': true,
            'name': 'PJszzzLszz',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "130px"

                    }
                })
            },
            'label': 'S:On-Air Date - L700',
            'sortable': true,
            'name': 'OnAirDateLsevenzz',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "100px"

                    }
                })
            },
            'label': 'PJ(6050) L850 OnAir',
            'sortable': true,
            'name': 'PJszfzLefzOnAir',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '6050:S',
            'sortable': true,
            'name': 'PJszfzLefz',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "100px"

                    }
                })
            },
            'label': 'S:On-Air Date - L850',
            'sortable': true,
            'name': 'OnAirDateLefz',

        },

        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "172px"

                    }
                })
            },
            'label': 'PJ(6100) LTE Unlicensed OnAir',
            'sortable': true,
            'name': 'PJsozzLTEUnlicensedOnAir',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '6100:S',
            'sortable': true,
            'name': 'PJsozzLTEUnlicensed',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "140px"

                    }
                })
            },
            'label': 'S:On-Air Date - LTE Unlicensed',
            'sortable': true,
            'name': 'OnAirDateLTEUnlicensed',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "100px"

                    }
                })
            },
            'label': 'PJ(6400) L3500 OnAir',
            'sortable': true,
            'name': 'PJsfzzLtfzz',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "70px"

                    }
                })
            },
            'label': '6400:S',
            'sortable': true,
            'name': 'PJsfzzLtfzzOnAir',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "145px"

                    }
                })
            },
            'label': 'S:On-Air Date - L3500',
            'sortable': true,
            'name': 'OnAirDateLtfzz',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "130px"

                    }
                })
            },
            'label': 'PJ(6262) N24 OnAir',
            'sortable': true,
            'name': 'PJststNtfOnAir',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '6262:S',
            'sortable': true,
            'name': 'PJststNtfOr',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "67px"

                    }
                })
            },
            'label': 'S:On-Air Date - N24',
            'sortable': true,
            'name': 'OnAirDateNtf',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "98px"

                    }
                })
            },
            'label': 'PJ(6300) N28 OnAir',
            'sortable': true,
            'name': 'PJstzzNteOnAir',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        'min-width': "80px"

                    }
                })
            },
            'label': '6300:S',
            'sortable': true,
            'name': 'PJstzzNteO',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "67px"

                    }
                })
            },
            'label': 'S:On-Air Date - N28',
            'sortable': true,
            'name': 'OnAirDateNte',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "100px"

                    }
                })
            },
            'label': 'PJ(6350) N39 OnAir',
            'sortable': true,
            'name': 'PJstfzNtnOnAir',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '6350:S',
            'sortable': true,
            'name': 'PJstfzNtnO',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "125px"

                    }
                })
            },
            'label': 'S:On-Air Date - N39',
            'sortable': true,
            'name': 'OnAirDateNtn',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "120px"

                    }
                })
            },
            'label': 'PJ(P 5950) N600 OnAir',
            'sortable': true,
            'name': 'PJPfnfzNszzOnAir',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '5950:S',
            'sortable': true,
            'name': 'PJPfnfzNszzOn',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white"

                    }
                })
            },
            'label': 'S:On-Air Date - N600',
            'sortable': true,
            'name': 'OnAirDateNszz',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "100px"

                    }
                })
            },
            'label': 'PJ(6257) N1900 OnAir',
            'sortable': true,
            'name': 'PJstfsNonzzOnAir',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '6257:S',
            'sortable': true,
            'name': 'PJstfsNonzzOn',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "135px"

                    }
                })
            },
            'label': 'S:On-Air Date - N1900',
            'sortable': true,
            'name': 'OnAirDateNonzz',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "110px"

                    }
                })
            },
            'label': 'PJ(6318) N2500 OnAir',
            'sortable': true,
            'name': 'PJstoeNtfzzOnAir',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '6318:S',
            'sortable': true,
            'name': 'PJstoeNtfzzOnA',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "115px"

                    }
                })
            },
            'label': 'PJ(6362) N3700 OnAir',
            'sortable': true,
            'name': 'PJststNtszzOnAir',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '6362:S',
            'sortable': true,
            'name': 'PJststNtszzOnA',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "100px"

                    }
                })
            },
            'label': 'S:On-Air Date - N3700',
            'sortable': true,
            'name': 'OnAirDateNtszz',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "187px"

                    }
                })
            },
            'label': 'S:On-Air_Date - Official Site Level',
            'sortable': true,
            'name': 'OnAir_DateOfficialSiteLevel',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        'min-width': '200px',
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",


                    }
                })
            },
            'label': 'S:Off-Air_Date - Official Site Level',
            'sortable': true,
            'name': 'OffAir_DateOfficialSiteLevel',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "115px"

                    }
                })
            },
            'label': 'PJ:Project Status',
            'sortable': true,
            'name': 'projectStatus',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "230px"

                    }
                })
            },
            'label': 'PJ:Program Name',
            'sortable': true,
            'name': 'ProgramName',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "95px"

                    }
                })
            },
            'label': 'R:Ring Status',
            'sortable': true,
            'name': 'RingStatus',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "65px"

                    }
                })
            },
            'label': 'S:Build Status',
            'sortable': true,
            'name': 'buildStatus',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white"

                    }
                })
            },
            'label': 'S:Site Name',
            'sortable': true,
            'name': 'siteName',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "85px"

                    }
                })
            },
            'label': 'S:Site Class',
            'sortable': true,
            'name': 'siteClass',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "80px"

                    }
                })
            },
            'label': 'S:Site Type',
            'sortable': true,
            'name': 'siteType',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "80px"

                    }
                })
            },
            'label': 'S:Build-out Type',
            'sortable': true,
            'name': 'BuildoutType',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "230px"

                    }
                })
            },
            'label': 'S:Structure Vendor Desc/S:Ground Vendor Desc',
            'sortable': true,
            'name': 'StructureVendorDesc/S',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "120px"

                    }
                })
            },
            'label': 'MLA/NON-MLA',
            'sortable': true,
            'name': 'Structure/S',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "62px"

                    }
                })
            },
            'label': 'R:SAC Vendor',
            'sortable': true,
            'name': 'SACVendor',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "80px"

                    }
                })
            },
            'label': 'PJ:Soft Cost PM',
            'sortable': true,
            'name': 'SoftCostPM',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "95px"

                    }
                })
            },
            'label': 'PJ:Soft Cost Vendor',
            'sortable': true,
            'name': 'SoftCostVendor',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "140px"

                    }
                })
            },
            'label': 'PJ:Hard Cost Vendor',
            'sortable': true,
            'name': 'HardCostVendor',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "83px"

                    }
                })
            },
            'label': 'PJ:General Contractor',
            'sortable': true,
            'name': 'GeneralContractor',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "80px"

                    }
                })
            },
            'label': 'PJ:Special Projects',
            'sortable': true,
            'name': 'SpecialProjects',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "160px"

                    }
                })
            },
            'label': 'S:Last Known Nesting Status Date',
            'sortable': true,
            'name': 'LastKnownNestingStatusDate',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "140px"

                    }
                })
            },
            'label': 'S:Last Known Nesting Status',
            'sortable': true,
            'name': 'LastKnownNestingStatus',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "160px"

                    }
                })
            },
            'label': 'PI:Pier ID',
            'sortable': true,
            'name': 'PierID',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white"

                    }
                })
            },
            'label': 'S:Site Latitude',
            'sortable': true,
            'name': 'latitude',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "105px"

                    }
                })
            },
            'label': 'S:Site Longitude',
            'sortable': true,
            'name': 'longitude',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "230px"

                    }
                })
            },
            'label': 'PJ:Project Name',
            'sortable': true,
            'name': 'projectName',

        },

///////////////////////////////////
/////////////////////////////////






        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '5925:S',
            'sortable': true,
            'name': 'PJfntfN',

        },

        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "105px"

                    }
                })
            },
            'label': 'PJ(5725) L1900 RTT',
            'sortable': true,
            'name': 'PJfstfLonzzRTT',

        },

       {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '5725:S',
            'sortable': true,
            'name': 'PJfstf',

        },

        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "83px"

                    }
                })
            },
            'label': 'S:On-Air Date - L2500',
            'sortable': true,
            'name': 'OnAirDateLtfzz',

        },

        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "95px"

                    }
                })
            },
            'label': 'S:Site Status',
            'sortable': true,
            'name': 'siteStatus',

        },

        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "100px"

                    }
                })
            },
            'label': 'PJ(5875) L600 RTT',
            'sortable': true,
            'name': 'PJfesfLszzRTT',

        },

        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '5775:S',
            'sortable': true,
            'name': 'PJfssfLt',

        },
        
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "138px"

                    }
                })
            },
            'label': 'PJ(5775) L2100 RTT',
            'sortable': true,
            'name': 'PJfssfLtozzRTT',

        },
     
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '6315:S',
            'sortable': true,
            'name': 'PJstofN',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "100px"

                    }
                })
            },
            'label': 'PJ(5925) N600 RTT',
            'sortable': true,
            'name': 'PJfntfNszzRTT',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "100px"
                    }
                })
            },
            'label': 'PJ(6315) N2500 RTT',
            'sortable': true,
            'name': 'PJstofNtfzzRTT',

        },
        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        'min-width': "90px"

                    }
                })
            },
            'label': 'R:Ring Code',
            'sortable': true,
            'name': 'RingCode',

        },

        {
            options: {
                filter: true,
                sort: false,

                setCellHeaderProps: () => ({
                    style: {
                        padding: "3px 10px",
                        fontSize: 11,
                        fontWeight: 500,
                        textAlign: "left",
                        background: "#143b64",
                        color: "white",
                        "min-width": "60px"

                    }
                })
            },
            'label': '5875:S',
            'sortable': true,
            'name': 'PJfesfLs',

        },

    ]


    let data = useSelector((state) => {
        let csvResponse = state.csvResponse
        let totalCount = state.countState.inflightCount
        console.log(csvResponse, totalCount, "csvResponse.inflight")
        if (csvResponse) {
            if (csvResponse.inflight) {
                userArry = csvResponse.inflight
            }
        }
        return { userArry, totalCount }
    })

    console.log(data, "data")


    const AddCusTButton = () => (
        <>
        
            <Button onClick={exportAllData} className="btn btn-primary btn-xs mx-2 py-2 my-3 d-flex justify-content-center align-items-center">
                Export
            </Button>
            <CommonBulkUploader classes={"btn btn-primary btn-xs mx-2 py-2 my-3 d-flex justify-content-center align-items-center"} styles={{ marginBottom: "1rem" }} typeForm={"Inflight Upload File"} shortCode={"inflight"} afterLoad={"/inflight" + dynamic_dashboard} />
        </>
    )


    useEffect(() => {

        dispatch(inflightGetCSVReducerAction("/inflight" + dynamic_dashboard))

    }, [])


    let row = [];

    const handleChange = (e) => {
        console.log("the current anser= ", e.target.value)
    }

    const AddCustomButton = () => (
        <fregment style={{ float: "right", display: "flex", flexDirection: row, justifyContent: "space-between" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                {dynamicValue == "magenta" ? <h3>Magenta Upload</h3> : ""}

                <div>
                    <Button className="btn-xs mt-3 mr-2 float-right mb-2" onClick={() => { filehandleShow(); reset({}); addformtype("Upload ") }}>Upload {dynamicValue} File</Button>
                </div>
            </div>





            <Modal show={fileshow} onHide={filehandleClose} size="md">
                <Modal.Header closeButton>
                    <Modal.Title >{formtype} Crew</Modal.Title>
                </Modal.Header>
                {/* <Modal.Body> */}
                <form onSubmit={(e) => submitHandler(e)} encType="multipart/form-data" className="mx-2">
                    <div className="input-group mb-3 fileSection">


                        <div>{dynamicValue} :</div>
                        <br />
                        <div>
                            <label class="custom-file-upload btn btn-outline-primary btn-xs col-md-12">
                                <input
                                    type="file"
                                    className="form-control btn btn-primary btn-xs"
                                    id="inputGroupFile04"
                                    aria-describedby="inputGroupFileAddon04"
                                    aria-label="Upload"
                                    name="file"
                                    multiple
                                    onChange={uploadCsvFile}
                                />
                                <i class="fa fa-cloud-upload"></i> Choose File
                            </label>
                            {/* <label>Remarks</label>
                  <input type='text' className="form-control" value={remarks} onChange={(e)=>{setRemarks(e.target.value)}}></input> */}

                        </div>

                        <button
                            className="btn btn-outline-primary btn-xs col-xs"
                            type="submit"
                            id="inputGroupFileAddon04"
                        >
                            Submit
                        </button>
                    </div>

                </form>

                {/* <div className="form-row mt-2 p-2">
            <div>
              Crew File :
              <label class="custom-file-upload btn btn-outline-primary btn-xs col-md-12">
                <input style={{ height: "32px" }}
                  type="file"
                  className="form-control btn btn-primary"
                  id="inputGroupFile04"
                  aria-describedby="inputGroupFileAddon04"
                  aria-label="Upload"
                  name="file"
                  onChange={uploadCsvFile}

                />
                <i class="fa fa-cloud-upload"></i> Choose Crew File
              </label>
            </div>
          </div> */}


                {/* </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary btn-xs" onClick={filehandleClose}>
            Close
          </Button>
          <Button variant="primary btn-xs" onClick={submitHandler}>
            Upload File
          </Button>
        </Modal.Footer> */}
            </Modal>




        </fregment>
    )

    //    const data = [
    //     { name: "Joe James", company: "In Progress", city: "Yonkers", state: "NY" },
    //     { name: "John Walsh", company: "Completed", city: "Hartford", state: "CT" },
    //     { name: "Bob Herm", company: "Awaited", city: "Tampa", state: "FL" },
    //     { name: "James Houston", company: "NA", city: "Dallas", state: "TX" },
    //    ];





    return (
        <>
            <div className='mui-t-h'
                style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}
            >



                <ThemeProvider theme={getMuiThemeCommon()}>
                    {console.log(data.userArry, "data.userArry", data.userArry.length)}
                    <MUIDataTable
                        //  title={"Inflight Upload"}
                        data={data.userArry}
                        columns={columns}
                        options={customDataTableOptionObject(AddCusTButton, false, "INFLIGHT_", "", "", false, "inflight", "", data.userArry.length, data.totalCount, true)}
                    />

                </ThemeProvider>
            </div>
        </>
    )

}

export default Inflightbulkfile