import React, { useEffect, useRef, useState } from 'react'
import DatePicker from "react-datepicker";
import { useForm } from 'react-hook-form';
import { AccountReducerAction, FetchSingleRecordAction, addUserAction, popUpUpdate } from '../../../../store/actions/createTicketManageFields';
import { LSSVDashboardAPI, trafficCheckCmts } from '../../../../common/config';
import { useDispatch, useSelector } from 'react-redux';
import { Add } from '@mui/icons-material';
import TTHistoryCmts from './TTHistoryCmts';
import CommonModal from '../CommonModal';
import HistoryCmts from './HistoryCmts';
const moment = require('moment');
const UpdateTraffic = (props) => {
    const dispatch = useDispatch()

    const { register, getValue, setValue, handleSubmit, setError, formState: { errors } } = useForm()
    const [SSVLaunchDate, setSSVLaunchDate] = useState()
    const [siteHealthCheckDate, setSiteHealthCheckDate] = useState()
    const [Ncloser, setNCloser] = useState()
    

    const [commonModalState, setcommonModalState] = useState(false);
    const [commonModalSize, setcommonModalSize] = useState(false);
    const [commonModalBody, setcommonModalBody] = useState("");
    const [commonModalTitle, setcommonModalTitle] = useState("");
    const [commonModalHeader, setcommonModalHeader] = useState("");
    let count = useRef(1)
    const [customData, setcustomData] = useState({
        "CommentsLaunchMS6": "",
        "tt_summary": "",
        "actionstt": ""
      });

    const CommonModalFun = (body, title, header, type = "md") => {

        dispatch(popUpUpdate({}));
        setNCloser(true)
        setcommonModalState(true);
        setcommonModalSize(type);
        setcommonModalTitle(title);
        setcommonModalBody(body);
        setcommonModalHeader(header);
    };

    const option = useSelector((state) => {

        if ((state.popUpState.popstatus.status == 200 && Ncloser)) {
            setcommonModalState(false);
            // console.log(state.popUpState.popstatus, "state.popUpState.popstatus")
            customData[state.popUpState.popstatus.data[0]] = state.popUpState.popstatus.data[1]

            dispatch(popUpUpdate({}));
            dispatch(AccountReducerAction(LSSVDashboardAPI))
            setNCloser(false)
        }
        let fetchData = state.OneCommonResponse.singleIXSite
        if (fetchData && Object.keys(fetchData).length > 0) {
            console.warn("jkxdbwfkce.ielgrbed==", fetchData)

            if (count.current == 1) {
                setValue("trafficCheck", fetchData.trafficCheck)
                setValue("RTWPCheckAllLayers", fetchData.RTWPCheckAllLayers)
                setValue("KeyKPIscCheck", fetchData.KeyKPIscCheck)
                setValue("LLaunchStatus", fetchData.LLaunchStatus)
                // setValue("CommentsLaunchMS6", fetchData.CommentsLaunchMS6)
                customData["CommentsLaunchMS6"]=fetchData.CommentsLaunchMS6
                setValue("TiltsBaselined", fetchData.TiltsBaselined)
                setValue("PrachCSBaselined", fetchData.PrachCSBaselined)
                setValue("prachConfigIndexBaselined", fetchData.prachConfigIndexBaselined)
                setValue("ExpectedcellsizeKMBaselined", fetchData.ExpectedcellsizeKMBaselined)
                // console.log("cmsncjhkae=",new Date(fetchData.LSSVLaunchDate))
                if (fetchData.LSSVLaunchDate && new Date(fetchData.LSSVLaunchDate) != "Invalid Date") {

                    setSSVLaunchDate(new Date(fetchData.LSSVLaunchDate))
                }
                if (fetchData.siteHealthCheckDate && new Date(fetchData.siteHealthCheckDate) != "Invalid Date") {
                    setSiteHealthCheckDate(new Date(fetchData.siteHealthCheckDate))
                }
                count.current = 2
            }
        }

        return ({ fetchData })
    })


    const styles = {
        width: "400px",
    };

    const onSubmit = (data) => {
        let sendData = Object.keys(data)
        sendData.map((item) => {
            if (data[item] == "") {
                delete data.item
            }
        })

        if(SSVLaunchDate){
            data["LSSVLaunchDate"] = moment(SSVLaunchDate).format("MM/DD/YYYY")
        }
        if(siteHealthCheckDate){
            data["siteHealthCheckDate"] = moment(siteHealthCheckDate).format("MM/DD/YYYY")
        }

        console.log("the data is knbjkdhjebvevker=", data)

        dispatch(addUserAction(LSSVDashboardAPI + "/" + props.id, data))
    }

    useEffect(() => {
        dispatch(FetchSingleRecordAction(LSSVDashboardAPI, props.id))
    }, [])
    return (<>
        <form className='row ' onSubmit={handleSubmit(onSubmit)}>
            <div className='form-group  col-6'>
                <label>Traffic check</label>
                <select className="form-control"

                    {...register("trafficCheck")}
                >
                    <option disabled selected value={""}>Select</option>
                    <option value={"Pass"}>Pass</option>
                    <option value={"Fail"}>Fail</option>
                </select>

            </div>
            <div className='form-group  col-6'>
                <label>RTWP check - all layers</label>
                <select className="form-control"
                    {...register("RTWPCheckAllLayers")}
                >
                    <option disabled selected value={""}>Select</option>
                    <option value={"Pass"}>Pass</option>
                    <option value={"Partial Pass"}>Partial Pass</option>
                    <option value={"Fail"}>Fail</option>
                </select>

            </div>
            <div className='form-group  col-6'>
                <label>Key KPIs check</label>
                <select className="form-control"
                    {...register("KeyKPIscCheck")}
                >
                    <option disabled selected value={""}>Select</option>
                    <option value={"Yes"}>Yes</option>
                    <option value={"No"}>No</option>
                </select>

            </div>
            <div className='form-group  col-6'>
                <label>Launch Date</label>
                <DatePicker
                    style={styles}
                    className="form-control"
                    selected={SSVLaunchDate}
                    onChange={(e) => {
                        setSSVLaunchDate(e);
                    }}
                    popperPlacement="bottom"
                    dateFormat="MM/dd/yyyy"
                />

            </div>
            <div className='form-group  col-6'>
                <label>Launch Status</label>
                <select className="form-control"

                    {...register("LLaunchStatus")}
                >
                    <option disabled selected value={""}>Select</option>
                    <option value={"Yes"}>Yes</option>
                    <option value={"Pending"}>Pending</option>
                </select>

            </div>
            <div className='form-group  col-6'>
                <label>Site health Check Date</label>
                <DatePicker
                    style={styles}
                    className="form-control"
                    selected={siteHealthCheckDate}
                    onChange={(e) => {
                        setSiteHealthCheckDate(e);
                    }}
                    popperPlacement="bottom"
                    dateFormat="MM/dd/yyyy"
                />

            </div>
            <div className='form-group  col-6'>
                <label>Tilts Baselined?</label>
                <select className="form-control"

                    {...register("TiltsBaselined")}

                >
                    <option disabled selected value={""}>Select</option>
                    <option value={"Yes"}>Yes</option>
                    <option value={"No"}>No</option>
                </select>

            </div>
            <div className='form-group  col-6'>
                <label>PrachCS Baselined?</label>
                <select className="form-control"
                    {...register("PrachCSBaselined")}
                >
                    <option disabled selected value={""}>Select</option>
                    <option value={"Yes"}>Yes</option>
                    <option value={"No"}>No</option>
                </select>

            </div>
            <div className='form-group  col-6'>
                <label>prachConfigIndex Baselined?</label>
                <select className="form-control"

                    {...register("prachConfigIndexBaselined")}

                >
                    <option disabled selected value={""}>Select</option>
                    <option value={"Yes"}>Yes</option>
                    <option value={"No"}>No</option>
                </select>
            </div>
            <div className='form-group  col-6'>
                <label>Expectedcellsize(KM) Baselined?</label>
                <select className="form-control"
                    {...register("ExpectedcellsizeKMBaselined")}
                >
                    <option disabled selected value={""}>Select</option>
                    <option value={"Yes"}>Yes</option>
                    <option value={"No"}>No</option>
                </select>
            </div>


            <div className='form-group  col-8'>
                <label>Comments - Launch MS6</label>
                <textarea type="text" className="form-control"
                    disabled
                    value={customData["CommentsLaunchMS6"]}
                />

            </div>
            <div className='form-group  col-2'>

                <br/>

                {/* unique_id_up={path.split("/").pop()}  */}
                <p
                    className={"text-black my-0  modalHoverClass"}
                    style={{ padding: "12px" }}
                    onClick={() => {
                        CommonModalFun(
                            <HistoryCmts urldata={trafficCheckCmts} btnMsg={"Add Comment"} uniqueId={props.id} from={"CommentsLaunchMS6"} />,
                            "Add Comment",
                            "Add Comment"
                        )
                    }}
                >

                    {/* <label></label>
                <br/> */}
                    <p style={{ marginTop: "10px" }} className={`badge badge-custom-size badge-primary p-1 my-0`}><Add /></p>
                </p>
            </div>
            <div className='col-12 d-flex justify-content-center'>
                <button className="btn btn-primary btn-xs py-2 px-4 mt-2" type='submit'>
                    Submit
                </button>
            </div>
        </form>


        <CommonModal
            modalSize={commonModalSize}
            setXlShow={setcommonModalState}
            xlShow={commonModalState}
            Body={commonModalBody}
            Header={commonModalHeader}
            Title={commonModalTitle}
        ></CommonModal></>
    )
}

export default UpdateTraffic