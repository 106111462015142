import React from 'react'
import { ThemeProvider } from 'react-bootstrap'
import { customDataTableOptionObject, getMuiThemeCommon } from '../../../common/variables'
import MUIDataTable from 'mui-datatables'

const Sheet = () => {




    let columns=[
        {
          label: "UI",
          name: "",
          //sortable: true,
          options: {
            //filter: true,
            className: "abcd",
            setCellHeaderProps: () => ({
              style: {
                whiteSpace: "wrap",
                left: "0",
                alignItem: "center",
                padding: "1px 1px",
                background: "#143b64",
                minWidth: "140px",
                color: "white",
                position: "sticky",
                zIndex: 101
              },
            }),
            setCellProps: () => ({
              style: {
                whiteSpace: "wrap",
                left: "0",
                overflowY: "hidden",
                minWidth: "140px",
                padding: "1px 1px",
                position: "sticky",
                background: "White",
                zIndex: 99,
              },
            }),
          },
        },
        {
            label: "Status",
            name: "",
            //sortable: true,
            options: {
              //filter: true,
              className: "abcd",
              setCellHeaderProps: () => ({
                style: {
                  whiteSpace: "wrap",
                  left: "0",
                  alignItem: "center",
                  padding: "1px 1px",
                  background: "#143b64",
                  minWidth: "140px",
                  color: "white",
                  position: "sticky",
                  zIndex: 101
                },
              }),
              setCellProps: () => ({
                style: {
                  whiteSpace: "wrap",
                  left: "0",
                  overflowY: "hidden",
                  minWidth: "140px",
                  padding: "1px 1px",
                  position: "sticky",
                  background: "White",
                  zIndex: 99,
                },
              }),
            },
          },
          {
            label: "Project Type",
            name: "",
            //sortable: true,
            options: {
              //filter: true,
              className: "abcd",
              setCellHeaderProps: () => ({
                style: {
                  whiteSpace: "wrap",
                  left: "0",
                  alignItem: "center",
                  padding: "1px 1px",
                  background: "#143b64",
                  minWidth: "140px",
                  color: "white",
                  position: "sticky",
                  zIndex: 101
                },
              }),
              setCellProps: () => ({
                style: {
                  whiteSpace: "wrap",
                  left: "0",
                  overflowY: "hidden",
                  minWidth: "140px",
                  padding: "1px 1px",
                  position: "sticky",
                  background: "White",
                  zIndex: 99,
                },
              }),
            },
          },
        
          {
            label: "TS GC Return",
            name: "",
            //sortable: true,
            options: {
              //filter: true,
              className: "abcd",
              setCellHeaderProps: () => ({
                style: {
                  whiteSpace: "wrap",
                  left: "0",
                  alignItem: "center",
                  padding: "1px 1px",
                  background: "#143b64",
                  minWidth: "140px",
                  color: "white",
                  position: "sticky",
                  zIndex: 101
                },
              }),
              setCellProps: () => ({
                style: {
                  whiteSpace: "wrap",
                  left: "0",
                  overflowY: "hidden",
                  minWidth: "140px",
                  padding: "1px 1px",
                  position: "sticky",
                  background: "White",
                  zIndex: 99,
                },
              }),
            },
          },
        
          {
            label: "HC Vendor",
            name: "",
            //sortable: true,
            options: {
              //filter: true,
              className: "abcd",
              setCellHeaderProps: () => ({
                style: {
                  whiteSpace: "wrap",
                  left: "0",
                  alignItem: "center",
                  padding: "1px 1px",
                  background: "#143b64",
                  minWidth: "140px",
                  color: "white",
                  position: "sticky",
                  zIndex: 101
                },
              }),
              setCellProps: () => ({
                style: {
                  whiteSpace: "wrap",
                  left: "0",
                  overflowY: "hidden",
                  minWidth: "140px",
                  padding: "1px 1px",
                  position: "sticky",
                  background: "White",
                  zIndex: 99,
                },
              }),
            },
          },
          
            {
              label: "GC",
              name: "",
              //sortable: true,
              options: {
                //filter: true,
                className: "abcd",
                setCellHeaderProps: () => ({
                  style: {
                    whiteSpace: "wrap",
                    left: "0",
                    alignItem: "center",
                    padding: "1px 1px",
                    background: "#143b64",
                    minWidth: "140px",
                    color: "white",
                    position: "sticky",
                    zIndex: 101
                  },
                }),
                setCellProps: () => ({
                  style: {
                    whiteSpace: "wrap",
                    left: "0",
                    overflowY: "hidden",
                    minWidth: "140px",
                    padding: "1px 1px",
                    position: "sticky",
                    background: "White",
                    zIndex: 99,
                  },
                }),
              },
            },
          
          {
            label: "Site ID",
            name: "",
            //sortable: true,
            options: {
              //filter: true,
              className: "abcd",
              setCellHeaderProps: () => ({
                style: {
                  whiteSpace: "wrap",
                  left: "0",
                  alignItem: "center",
                  padding: "1px 1px",
                  background: "#143b64",
                  minWidth: "140px",
                  color: "white",
                  position: "sticky",
                  zIndex: 101
                },
              }),
              setCellProps: () => ({
                style: {
                  whiteSpace: "wrap",
                  left: "0",
                  overflowY: "hidden",
                  minWidth: "140px",
                  padding: "1px 1px",
                  position: "sticky",
                  background: "White",
                  zIndex: 99,
                },
              }),
            },
          },
        
          {
            label: "POR Count",
            name: "",
            //sortable: true,
            options: {
              //filter: true,
              className: "abcd",
              setCellHeaderProps: () => ({
                style: {
                  whiteSpace: "wrap",
                  left: "0",
                  alignItem: "center",
                  padding: "1px 1px",
                  background: "#143b64",
                  minWidth: "140px",
                  color: "white",
                  position: "sticky",
                  zIndex: 101
                },
              }),
              setCellProps: () => ({
                style: {
                  whiteSpace: "wrap",
                  left: "0",
                  overflowY: "hidden",
                  minWidth: "140px",
                  padding: "1px 1px",
                  position: "sticky",
                  background: "White",
                  zIndex: 99,
                },
              }),
            },
          },
          
        
          {
            label: "Plan Type",
            name: "",
            //sortable: true,
            options: {
              //filter: true,
              className: "abcd",
              setCellHeaderProps: () => ({
                style: {
                  whiteSpace: "wrap",
                  left: "0",
                  alignItem: "center",
                  padding: "1px 1px",
                  background: "#143b64",
                  minWidth: "140px",
                  color: "white",
                  position: "sticky",
                  zIndex: 101
                },
              }),
              setCellProps: () => ({
                style: {
                  whiteSpace: "wrap",
                  left: "0",
                  overflowY: "hidden",
                  minWidth: "140px",
                  padding: "1px 1px",
                  position: "sticky",
                  background: "White",
                  zIndex: 99,
                },
              }),
            },
          },
        
        
          {
            label: "Project ID",
            name: "",
            //sortable: true,
            options: {
              //filter: true,
              className: "abcd",
              setCellHeaderProps: () => ({
                style: {
                  whiteSpace: "wrap",
                  left: "0",
                  alignItem: "center",
                  padding: "1px 1px",
                  background: "#143b64",
                  minWidth: "140px",
                  color: "white",
                  position: "sticky",
                  zIndex: 101
                },
              }),
              setCellProps: () => ({
                style: {
                  whiteSpace: "wrap",
                  left: "0",
                  overflowY: "hidden",
                  minWidth: "140px",
                  padding: "1px 1px",
                  position: "sticky",
                  background: "White",
                  zIndex: 99,
                },
              }),
            },
          }
        ]
  return (
    <div style={{ marginTop: "-40px" }}>
    {" "}
    <ThemeProvider theme={getMuiThemeCommon()}>
      <MUIDataTable
        data={[]}
        columns={columns} 
        options={customDataTableOptionObject(null , false, "ixDashbord" )}
      />
    </ThemeProvider>
  </div>
  )
}

export default Sheet