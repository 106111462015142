import MUIDataTable from 'mui-datatables'
import React, { useRef } from 'react'
import swal from "sweetalert"
import { useEffect, useState } from 'react'
import FilterData from "../../../icons/Svg/FilterData";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux'
import { SevenTwoAlarm, SevenTwoAlarmFetchSingle, ixModule, sevenTwoActivePending , ranfilters} from '../../../common/config'
import { columnsArray, customDataTableOptionObject, getMuiThemeCommon, more_info, wrapText } from '../../../common/variables'
import { AccountReducerAction, ClearSingleIX, deleteAcccountReducerAction, popUpUpdate,getFilterDropDown } from '../../../store/actions/createTicketManageFields'
import SingleDateModal from '../Custom/Modal Body/SingleDateModal'
import CommonModal from '../Custom/CommonModal'
import RefreshIcon from "@mui/icons-material/Refresh";
import SevenTwoUpdateStatus from '../Custom/Modal Body/sevenTwoUpdateStatus'
import FinalStatus from '../Custom/Modal Body/FinalStatus'
import AdDAudits from '../Custom/Modal Body/AdDAudits'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import AddComments from '../Custom/Modal Body/AddComments'
import SelectBox from '../Custom/Modal Body/SelectBox'
import SeventyTwoHrsFilter from "../Custom/Modal Body/SeventyTwoHrsFilter";

const SevenTwo = () => {
  let [commonModalState, setcommonModalState] = useState()
  let [modalBdy, setModal] = useState(false)
  let [closer, setCloser] = useState(false)


  const [commonModalStateFilter, setcommonModalStateFilter] = useState(false);
  const [commonModalSizeFilter, setcommonModalSizeFilter] = useState(false);
  const [commonModalTitleFilter, setcommonModalTitleFilter] = useState("");
  const [commonModalBodyFilter, setcommonModalBodyFilter] = useState("");
  const [commonModalHeaderFilter, setcommonModalHeaderFilter] = useState("");
  const [fullUrL, setfullUrL] = useState("");
  const [exportApiData, setexportApiData] = useState("");
  const [filterBadges,setFilterBadge] =useState()
  
  const dispatch = useDispatch()
  var heading = useRef("")
  function openModal(hd, tag) {
    heading.current = hd
    console.log("cnkjebfvle", heading.current)
    setcommonModalState(tag)
    setModal(true)

  }
  let audtDetail = false
  let ReportDetail = false
  let rowData = []


  const ClearFilter = () => {
    dispatch(AccountReducerAction(SevenTwoAlarm))
  };

  let AddSevenTwo = () => (
    <>
      <p onClick={filterhandler} className=" mx-2 " style={{ "align-self": "center" }}><FilterData /></p>
      <button
          className="btn btn-xs btn-primary mx-2 my-3"
          data-toggle="tooltip"
          data-placement="top"
          title="Refresh"
          onClick={() => {
            ClearFilter();
            // history.push("/PAG/ran/support/dashboard")
          }}
          style={{ padding: "6px" }}
        >
          <RefreshIcon />
        </button>
      <Link to={"/PAG/sevenTwo/form"} className="btn btn-xs btn-primary mx-2 my-3">
        Add
      </Link>
    </>
  )



  function filterhandler() {

    dispatch(getFilterDropDown([]))
    // dispatch(AccountReducerAction(ssvfilters))
    dispatch(AccountReducerAction(ranfilters))
    setcommonModalStateFilter(true)
    setcommonModalSizeFilter("xl")
    setcommonModalBodyFilter(<SeventyTwoHrsFilter setfullUrL={setfullUrL} setcommonModalState={setcommonModalState} setFilterBadge={setFilterBadge} featureExport={setexportApiData} />)
    setcommonModalTitleFilter("72 Alarm Filters")
    setcommonModalHeaderFilter("72 Alarm Filter")
  }


  let response = useSelector((state) => {
    if (state.popUpState) {
      console.log(state.popUpState.popstatus.status, closer, state.popUpState.popstatus.status == 201 && closer, "popUpStatestatus")
      // console.log(reqPreModal, "openModal");
      // if ((state.popUpState.popstatus.status == 201 && (closer||modalBdy))||(state.popUpState.popstatus.status == 200 && (closer||modalBdy))) {
      if ((state.popUpState.popstatus.status == 201 && closer) || (state.popUpState.popstatus.status == 200 && closer)) {
        // console.log(reqPreModal, "openModal");
        setModal(false);
        setCloser(false);
        // setReqPreModal(false);
        // setcommonModalState(false)
        // setNestingTime(false)
        dispatch(popUpUpdate({}));
        dispatch(AccountReducerAction(SevenTwoAlarm))
      }
    }


    const CommonModalFun = (body, title, header, type = "md") => {
      setcommonModalStateFilter(true);
      setcommonModalSizeFilter(type);
      setcommonModalTitleFilter(title);
      setcommonModalBodyFilter(body);
      setcommonModalHeaderFilter(header);
    };
    let data = state.CommonResponse.sevenTwoSites
    console.log("th eejbhvkf erfhjerkjs", data)

    let proId=""
    let procode=""
    if (data && data.length > 0) {
      rowData = data.map((item, index) => {
        // item["vendorDetial"] = item["crew_status"]?.vendor
        

        item["gcName"]=item["cxixuserId"]?item["cxixuserId"].crewCompany:""
        item["vendorDetial"]=item["cxixuserId"]?item["cxixuserId"].vendor:""

        // item["gcName"] = item["crew_status"]?.name


        proId = proId + item.ndResult ? item.ndResult.projectId : ""
        procode = procode + item.ndResult ? item.ndResult.projectCode : ""
        // if(item.auditAct){
        //    audtDetail={
        //     "vswrValidation":[item["vswrValidation"],"VSWR Validation"],
        //     "rtwpValidation":[item["rtwpValidation"],"RTWP Validation"],
        //     "retValidation":[item["retValidation"],"RET Validation"],
        //     "perameterAudit":[item["perameterAudit"],"Perameter Audit"]
        //   }
        // }
        if (item["troubleTickets"] && item["troubleTickets"].length > 0) {
          let allttIdFlag = []
          let allttOpenFlag = []
          item["troubleTickets"].map((itm) => {
            allttIdFlag.push(itm.ttid)
            allttOpenFlag.push(itm.ttStatus)
          })
          console.log(allttOpenFlag, "allttOpenFlag", allttIdFlag)
          item["ttOpenFlag"] = more_info(allttOpenFlag.join(", "))
          item["ttIdFlag"] = more_info(allttIdFlag.join(", "))
        }

        item["sno"] = index + 1
        if (item["projectbUniqueId"] && item["projectbUniqueId"].length > 0 && item["projectbUniqueId"][0].siteType) {
          item["projectType"] = item["projectbUniqueId"][0].siteType

        }

        // let prooName=""

        console.log(item,"itemitemitemitemitem")
        // let dtData = item["dtResult"] ? item["dtResult"].ProgramName: ""
        let prooName = item["dtResult"] ? item["dtResult"].ProgramName: ""
        // dtData.map((itte)=>{
        //   console.log(itte,"itteitteitte")
        //   // prooName.push(itte.ProgramName)
        // })


        item["programname"] =(
          <p
            className={"text-black my-0 modalHoverClass"}
            data-toggle="tooltip"
            data-placement="top"
            title="Click Here For More Details"
            onClick={() =>
              CommonModalFun(
                <p
                  style={{
                    border: "2px solid black",
                    color: "black",
                    padding: 2,
                  }}
                  className="CommonModal"
                >
                  {prooName}
                </p>,
                "Program Name",
                "Program Name"
              )
            }
          >
            {wrapText(prooName)}
          </p>
        ); 
        item["AddAudit"] = <p

          className='badge badge-secondary'
          onClick={
            () => openModal("Add Audit", <AdDAudits oldData={item} setCloser={setCloser} id={item.ranSupportId} />)
          }
        >
          Update
        </p>

        item["siteLogsFlag"] = <p className="badge backgroundPurple"

          onClick={

            () => {

              let records = []
              let logs = item["siteLogs"]
              records = logs.map((records) => {
                return <tr><td style={{ border: "2px solid black" }}>{records.userName}</td><td style={{ border: "2px solid black" }}>{records.createdOn}</td><td style={{ border: "2px solid black" }}>{records.ActivityStatus}</td><td style={{ border: "2px solid black" }}>{records.ActivityType}</td></tr>
              })

              openModal("Site Logs",
                <>
                  <div
                    className="table-responsive"
                    style={{ color: "black", border: "0px solid black" }}
                  >
                    <table className="table "
                      style={{ height: "auto" }}

                    >
                      <thead>
                        <td style={{ width: "15%;", paddingTop: "11px", fontSize: "13px", height: "45px", border: "2px solid black" }}>Activity Updated By</td>
                        <td style={{ width: "40%;", paddingTop: "11px", fontSize: "13px", height: "45px", border: "2px solid black" }}>Activity Date Time</td>
                        <td style={{ width: "15%;", paddingTop: "11px", fontSize: "13px", height: "45px", border: "2px solid black" }}>Activity Status</td>
                        <td style={{ width: "10%;", paddingTop: "11px", fontSize: "13px", height: "45px", border: "2px solid black" }}>Activity Type</td>
                      </thead>
                      <tbody>{records}</tbody>
                    </table>
                  </div>
                </>

              )
            }
          }

        >Site Logs</p>
        

        item["projectid"] = proId
        item["projectcode"] = procode
        item["activePending"] =

          <p
            className="badge badge-danger"
            onClick={
              () => openModal("72 Alarm Status", <SelectBox setCloser={setCloser} oldData={item["sevenTwoAlarmStatus"]} keyName={"sevenTwoAlarmStatus"} optionsList={["Resume", "Pause"]} label={"72 hours alarm status"} sendUrl={sevenTwoActivePending} id={item.ranSupportId} />)
            }
          >
            {item.sevenTwoAlarmStatus ? item.sevenTwoAlarmStatus : "Update Alarm"}
          </p>
        item["unlockDateByRFModal"] = <p
          className="badge badge-success"
          onClick={
            () => openModal("Unlock date by RF", <SingleDateModal setCloser={setCloser} label="Please select the unlock RF Date" dataKey={"unlockRFDateSevenTwo"} sendUrl={SevenTwoAlarmFetchSingle} uid={item.ranSupportId} />)
          }
        >
          {item["unlockRFDateSevenTwo"] ? item["unlockRFDateSevenTwo"] : "Add Date"}
        </p>

        // if(item["troubleTickets"] &&  item["troubleTickets"].length>0){
        //   item["ttOpenFlag"]=item["troubleTickets"][0]["ttStatus"]
        //   item["ttIdFlag"]=item["troubleTickets"][0]["ttid"]
        //   }

        item["IntegrationAlarmFlag"] = <p
          className={item["IntegrationAlarm"] == "Yes" ? 'badge badge-success' : "badge badge-danger"}
        >
          {item["IntegrationAlarm"]}
        </p>
        item["zeroHrStatusFlag"] = <p className={item["zeroHrStatus"] == "Passed" ? 'badge badge-success' : "badge badge-danger"}>{item["zeroHrStatus"]}</p>
        item["twoFourHrStatusFlag"] = <p className={item["twoFourHrStatus"] == "Passed" ? 'badge badge-success' : "badge badge-danger"}>{item["twoFourHrStatus"]}</p>
        item["FourEightHrStatusFlag"] = <p className={item["FourEightHrStatus"] == "Passed" ? 'badge badge-success' : "badge badge-danger"}>{item["FourEightHrStatus"]}</p>
        item["sevenTwoStatusFlag"] = <p className={item["sevenTwoStatus"] == "Passed" ? 'badge badge-success' : "badge badge-danger"}>{item["sevenTwoStatus"]}</p>

        item["zeroHrReportFlag"] = <p className={item["zeroHrReport"] == "Yes" ? 'badge badge-success' : "badge badge-danger"}>{item["zeroHrReport"]}</p>
        item["twoFourHrReportFlag"] = <p className={item["twoFourHrReport"] == "Yes" ? 'badge badge-success' : "badge badge-danger"}>{item["twoFourHrReport"]}</p>
        item["FourEightHrReportFlag"] = <p className={item["FourEightHrReport"] == "Yes" ? 'badge badge-success' : "badge badge-danger"}>{item["FourEightHrReport"]}</p>
        item["sevenTwoReportFlag"] = <p className={item["sevenTwoReport"] == "Yes" ? 'badge badge-success' : "badge badge-danger"}>{item["sevenTwoReport"]}</p>
        item["siteId"] = item["ndResult"] ? item["ndResult"]["siteId"] : ""
        item["vswrValidationFlag"] = <p className={item["vswrValidation"] == "Yes" ? 'badge badge-success' : "badge badge-danger"}>{item["vswrValidation"]}</p>
        item["rtwpValidationFlag"] = <p className={item["rtwpValidation"] == "Yes" ? 'badge badge-success' : "badge badge-danger"}>{item["rtwpValidation"]}</p>
        item["retValidationFlag"] = <p className={item["retValidation"] == "Yes" ? 'badge badge-success' : "badge badge-danger"}>{item["retValidation"]}</p>
        item["perameterAuditFlag"] = <p className={item["perameterAudit"] == "Yes" ? 'badge badge-success' : "badge badge-danger"}>{item["perameterAudit"]}</p>
        // if(item.sevenTwoWildCard=="true"){

        item["raiseTT"] = <Link className="badge badge-warning" to={"/PAG/trobleTicke/form/SevenTwoWildCard/" + item['ranSupportId']} onClick={() => dispatch(ClearSingleIX([]))}>Raise TT</Link>

        item["setFinalStatus"] = <p

          className={`badge ${item["sevenTwoFinalStatus"] == "Pass" ? "badge-success" : "badge-danger"}`}

        >
          {item["sevenTwoFinalStatus"] ? item["sevenTwoFinalStatus"] : ""}
        </p>
        //  item["raiseTT"]=<Link className="badge badge-warning" to={item.sevenTwoWildCard=="true"?"/PAG/trobleTicke/form/SevenTwoWildCard/"+item['ranSupportId']: "/PAG/trobleTicke/form/ixSevenTwo/"+item['ranSupportId']} onClick={()=>dispatch(ClearSingleIX([]))}>Raise TT</Link>
        // }
        //  if(item["zeroHrStatus"] =="" || item["twoFourHrStatus"]=="" || item["FourEightHrStatus"]=="" || item["sevenTwoStatus"]=="" || item["zeroHrReport"] ==""|| item["twoFourHrReport"] ==""|| item["FourEightHrReport"]=="" || item["sevenTwoReport"]==""){

        // item["setFinalStatus"]=<p

        // className='badge badge-secondary'
        // onClick={

        //   ()=>  swal({
        //     title: "Action Pending",
        //     text: "Please complete status alarm",
        //     icon: "error",
        //     button: "Ok",
        // })
        // }
        // >

        // </p>
        //  } 
        //  else{

        //  }
        item["comment"] = <p

          className={item["sevenTwoComments"] ? '' : "badge badge-warning "}
          onClick={

            () => {
              let tag = <AddComments setCloser={setCloser} id={item.ranSupportId} message={item["sevenTwoComments"] ? item["sevenTwoComments"] : ""} />
              openModal("Add Comments", tag)
            }
          }
        >  {item["sevenTwoComments"] ? more_info(item["sevenTwoComments"]) : "Add Comments"}</p>
        item["monitoringDate"] = <p
          onClick={
            () => openModal("Monitoring Start Date", <SingleDateModal getOld={item["sevenTwoMonitoringDate"]} setCloser={setCloser} label="Please Select Date" dataKey="sevenTwoMonitoringDate" sendUrl={SevenTwoAlarm} uid={item.ranSupportId} />)
          }
          className="badge badge-success"
        >
          {item["sevenTwoMonitoringDate"] ? item["sevenTwoMonitoringDate"] : "Add Date"}
        </p>
        item["monitoringEndDate"] = <p
          onClick={
            () => openModal("Monitoring End Date", <SingleDateModal getOld={item["sevenTwoMonitoringEndDate"]} setCloser={setCloser} label="Please Select Date" dataKey="sevenTwoMonitoringEndDate" sendUrl={SevenTwoAlarm} uid={item.ranSupportId} />)
          }
          className="badge badge-success"
        >
          {item["sevenTwoMonitoringEndDate"] ? item["sevenTwoMonitoringEndDate"] : "Add Date"}
        </p>
        // if(item["ReportAct"]){

        // }
        item["siteType"] = item["ndResult"]["siteType"]
        item["actions"] = <div className="d-flex deleteButton">
          <button onClick={(e) => deleteHandler(item.ranSupportId)} className="btn btn-danger shadow btn-xs sharp d-flex justify-content-center align-items-center"><i className="fa fa-trash"></i>
          </button>
        </div>;

        item["OpenUpdateStatus"] = <p
          className="badge badge-primary"
          onClick={
            () => openModal("Site Report Status", <SevenTwoUpdateStatus setCloser={setCloser} oldData={item} id={item.ranSupportId} />)
          }
        >
          Update Status
        </p>
        return item
      })
    }

  })

  const deleteHandler = (e) => {
    console.log("button delete clicked", e)

    swal({
      title: "Are you sure?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          console.log("the will dele=", willDelete)
          setCloser(true)
          dispatch(popUpUpdate({}));
          dispatch(deleteAcccountReducerAction(SevenTwoAlarm, e))
          dispatch(AccountReducerAction(SevenTwoAlarm));
        }
      });
  }
  console.log("the headers is =", columnsArray("Account	Market	site_id	IX StartDate	sow	IX_SoW	IntegratorLogoutDateTime	ActivityCompleteDate	mPulse TT	PIER TT	TT Status	RTT Closure DateTime	RTT Submission documents"))
  let columns = [
    {
      "name": "sno",
      "label": "S.no",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            outline: "1.5px solid black",
            alignItem: "center",
            left: "0px",
            padding: "1px 5px",
            background: "#143b64",
            minWidth: "30px",
            width: "30px",
            color: "white",
            position: "sticky",
            zIndex: 101
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "70px",
            width: "70px",
            left: "0px",
            padding: "1px 5px",
            outline: "1.5px solid black",
            background: "White",
            position: "sticky",
            zIndex: 100
          },
        }),
      }
    },
    {
      "name": "siteId",
      "label": "Site ID",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            left: "70px",
            alignItem: "center",
            padding: "1px 5px",
            background: "#143b64",
            minWidth: "160px",
            color: "white",
            position: "sticky",
            zIndex: 101
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "70px",
            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 5px",
            outline: "1.5px solid black",
            background: "White",
            position: "sticky",
            zIndex: 100
          },
        }),
      }
    },
    {
      "name": "projectType",
      "label": "Project Type",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            alignItem: "center",
            padding: "1px 5px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 99
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 5px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      }
    },
    {
      "name": "programname",
      "label": "Program Name",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            alignItem: "center",
            padding: "1px 5px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 99
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 5px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      }
    },

    
    {
      "name": "projectcode",
      "label": "Plan Type",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            alignItem: "center",
            padding: "1px 5px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 99
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "165px",
            padding: "1px 5px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      }
    },
    {
      "name": "projectid",
      "label": "Project ID",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            alignItem: "center",
            padding: "1px 5px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 99
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "165px",
            padding: "1px 5px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      }
    },
    {
      "name": "rttCompleteDate",
      "label": "RTT Date",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            outline: "1.5px solid black",
            alignItem: "center",
            padding: "1px 5px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 99
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 5px",
            outline: "1.5px solid black",
            background: "White",
            zIndex: 99
          },
        }),
      }
    },
    {
      "name": "vendorDetial",
      "label": "Vendor",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            alignItem: "center",
            padding: "1px 5px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 99
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 5px",
            outline: "1.5px solid black",
            background: "White",
            zIndex: 99
          },
        }),
      }
    },
    {
      "name": "assignedEngineer",
      "label": "Integrator Name",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            alignItem: "center",
            padding: "1px 5px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 99
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 5px",
            outline: "1.5px solid black",
            background: "White",
            zIndex: 99
          },
        }),
      }
    },
    {
      "name": "gcName",
      "label": "GC",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            alignItem: "center",
            padding: "1px 5px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 99
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 5px",
            outline: "1.5px solid black",
            background: "White",
            zIndex: 99
          },
        }),
      }
    },
    {
      "name": "monitoringDate",
      "label": "Monitoring start Date",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            alignItem: "center",
            padding: "1px 5px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 99
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 5px",
            outline: "1.5px solid black",
            background: "White",
            zIndex: 99
          },
        }),
      }
    },
    {
      "name": "OpenUpdateStatus",
      "label": "Update Report Status",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            alignItem: "center",
            padding: "1px 5px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 99
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 5px",
            outline: "1.5px solid black",
            background: "White",
            zIndex: 99
          },
        }),
      }
    },
    {
      "name": "zeroHrStatusFlag",
      "label": "0 Hour Status",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            alignItem: "center",
            padding: "1px 5px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 99
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 5px",
            outline: "1.5px solid black",
            background: "White",
            zIndex: 99
          },
        }),
      }
    },
    {
      "name": "zeroHrReportFlag",
      "label": "0 Hr Rprt Sent",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            alignItem: "center",
            padding: "1px 5px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 99
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 5px",
            outline: "1.5px solid black",
            background: "White",
            zIndex: 99
          },
        }),
      }

    },
    {
      "name": "twoFourHrStatusFlag",
      "label": "24 Hour Status",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            alignItem: "center",
            padding: "1px 5px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 99
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 5px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      }
    },
    {
      "name": "twoFourHrReportFlag",
      "label": "24 Hr Rprt Sent",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            alignItem: "center",
            padding: "1px 5px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 99
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 5px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      }
    },
    {
      "name": "FourEightHrStatusFlag",
      "label": "48 Hour Status",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            alignItem: "center",
            padding: "1px 5px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 99
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 5px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      }
    },
    {
      "name": "FourEightHrReportFlag",
      "label": "48 Hr Rprt Sent",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            alignItem: "center",
            padding: "1px 5px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 99
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 5px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      }
    },
    {
      "name": "sevenTwoStatusFlag",
      "label": "72 Hour Status",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            alignItem: "center",
            padding: "1px 5px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 99
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 5px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      }
    },
    {
      "name": "sevenTwoReportFlag",
      "label": "72 Hr Rprt Sent",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            alignItem: "center",
            padding: "1px 5px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 99
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 5px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      }
    },
    {
      "name": "setFinalStatus",
      "label": "Final Status",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            alignItem: "center",
            padding: "1px 5px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 99
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 5px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      }
    },
    {
      "name": "IntegrationAlarmFlag",
      "label": "Integration Alarm Free report",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            alignItem: "center",
            padding: "1px 5px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 99
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 5px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      }
    },
    {
      "name": "monitoringEndDate",
      "label": "Monitoring End Date",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            alignItem: "center",
            padding: "1px 5px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 99
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 5px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      }
    },
    {
      "name": "raiseTT",
      "label": "Add Raise TT",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            left: "0",
            alignItem: "center",
            padding: "1px 5px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 99
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 5px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      }
    },
    {
      "name": "ttOpenFlag",
      "label": "TT(s) Opened",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            left: "0",
            alignItem: "center",
            padding: "1px 5px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 99
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 5px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      }
    },
    {
      "name": "ttIdFlag",
      "label": "TT(s) ID(s)",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            left: "0",
            alignItem: "center",
            padding: "1px 5px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 99
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 5px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      }
    },
    {
      "name": "AddAudit",
      "label": "Audit",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            left: "0",
            alignItem: "center",
            padding: "1px 5px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 99
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 5px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      }
    },
    {
      "name": "vswrValidationFlag",
      "label": "VSWR Validation",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            left: "0",
            alignItem: "center",
            padding: "1px 5px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 99
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 5px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      }
    },
    {
      "name": "rtwpValidationFlag",
      "label": "RTWP Validation",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            left: "0",
            alignItem: "center",
            padding: "1px 5px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 99
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 5px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      }
    },
    {
      "name": "retValidationFlag",
      "label": "RET Validation",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            left: "0",
            alignItem: "center",
            padding: "1px 5px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 99
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 5px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      }
    },
    {
      "name": "perameterAuditFlag",
      "label": "Parameter Audit",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            left: "0",
            alignItem: "center",
            padding: "1px 5px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 99
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 5px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      }
    },
    {
      "name": "comment",
      "label": "Comments",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            left: "0",
            alignItem: "center",
            padding: "1px 5px",
            background: "#143b64",
            minWidth: "300px",
            color: "white",
            position: "sticky",
            zIndex: 99
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 5px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      }
    },

    {
      "name": "unlockDateByRFModal",
      "label": "Unlock Date by RF",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            left: "0",
            alignItem: "center",
            padding: "1px 5px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 99
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 5px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      }
    },
    {
      "name": "siteLogsFlag",
      "label": "Site Logs",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            left: "0",
            alignItem: "center",
            padding: "1px 5px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 99
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 5px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      }
    }
    ,
    {
      "name": "activePending",
      "label": "Alarm Status",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            left: "0",
            alignItem: "center",
            padding: "1px 5px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 99
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "160px",
            padding: "1px 5px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      }
    },
    {
      "name": "actions",
      "label": "Action",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            outline: "1.5px solid black",
            left: "0",
            alignItem: "center",
            padding: "1px 5px",
            background: "#143b64",
            minWidth: "50px",
            color: "white",
            position: "sticky",
            zIndex: 99
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            minWidth: "50px",
            padding: "1px 5px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      }
    }





    // {
    //   "name": "",
    //   "label": "Raise TT",
    //   // "sortable": false,
    //   "options": {
    //     filter: true,
    // className: "abcd",
    // setCellHeaderProps: () => ({
    //   style: {

    //     whiteSpace: "wrap",
    //     outline: "1.5px solid black",
    //     left: "0",
    //     alignItem: "center",
    //     padding: "1px 5px",
    //     background: "#143b64",
    //     minWidth: "140px",
    //     color: "white",
    //     position: "sticky",
    //     zIndex: 99
    //   },
    // }),
    // setCellProps: () => ({
    //   style: {
    //     whiteSpace: "wrap",

    //     overflowY: "hidden",
    //     minWidth: "160px",
    //     padding: "1px 5px",
    //     outline:"1.5px solid black",
    //     background: "White",
    //   },
    // }),
    // }
    // },
    // ,
    // {
    //   "name": "",
    //   "label": "Edit",
    //   "options": {
    //     // filter: true,
    // className: "abcd",
    // setCellHeaderProps: () => ({
    //   style: {

    //     whiteSpace: "wrap",
    //     outline: "1.5px solid black",
    //     left: "0",
    //     alignItem: "center",
    //     padding: "1px 5px",
    //     background: "#143b64",
    //     minWidth: "140px",
    //     color: "white",
    //     position: "sticky",
    //     zIndex: 99
    //   },
    // }),
    // setCellProps: () => ({
    //   style: {
    //     whiteSpace: "wrap",

    //     overflowY: "hidden",
    //     minWidth: "160px",
    //     padding: "1px 5px",
    //     outline:"1.5px solid black",
    //     background: "White",
    //   },
    // }),
    // }
    // },
    // ,
    // {
    //   "name": "",
    //   "label": "Delete",
    //   // "sortable": false,
    //   "options": {
    //     // filter: true,
    // className: "abcd",
    // setCellHeaderProps: () => ({
    //   style: {

    //     whiteSpace: "wrap",
    //     outline: "1.5px solid black",
    //     left: "0",
    //     alignItem: "center",
    //     padding: "1px 5px",
    //     background: "#143b64",
    //     minWidth: "140px",
    //     color: "white",
    //     position: "sticky",
    //     zIndex: 99
    //   },
    // }),
    // setCellProps: () => ({
    //   style: {
    //     whiteSpace: "wrap",

    //     overflowY: "hidden",
    //     minWidth: "160px",
    //     padding: "1px 5px",
    //     outline:"1.5px solid black",
    //     background: "White",
    //   },
    // }),
    // }
    // }
  ]
  useEffect(() => {
    dispatch(AccountReducerAction(SevenTwoAlarm))
  }, [])
  return (
    <div>
      <CommonModal
        modalSize={"xm"}
        setXlShow={setModal}
        xlShow={modalBdy}
        Body={commonModalState}
        Header={heading.current}
        Title={""}
      ></CommonModal>


      <CommonModal
        modalSize={commonModalSizeFilter}
        setXlShow={setcommonModalStateFilter}
        xlShow={commonModalStateFilter}
        Body={commonModalBodyFilter}
        Header={commonModalHeaderFilter}
        Title={commonModalTitleFilter}
      ></CommonModal>


      <ThemeProvider theme={getMuiThemeCommon()}>
        <MUIDataTable
          data={rowData}
          columns={columns}
          options={customDataTableOptionObject(AddSevenTwo, false, "ranDashbord", '', '')}
        />
      </ThemeProvider>
    </div>
  )
}

export default SevenTwo