import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AccountReducerAction, LCLmopDashboardReducerAction, LCLmopDashboardfilter, getFilterDropDown } from '../../../../store/actions/createTicketManageFields'
import { mopDataApi, mopFilters } from '../../../../common/config'
import { MopDatabaseAction } from '../../../../store/actions/dashboardActions'

const MopFilterList = ({setFilterBadge, setfullUrL, setcommonModalState, featureExport}) => {
    const dispatch =useDispatch()
    let compOptionArry=[]
    let sowListArry=[]
    let typeOfWorkArry=[]
    let vendorsArry=[]
    let siteID=[]
    let ticketId=[]
    let rfstatus=[]
    let fopDevStatus=[]
    let groupArry=[]
    let sendData=useRef("")
    let filterOptions=useRef([])
    
    let fullURL=""
    let selectedFilter=[]

    const [fullFilter,setfullFilter]=useState({})
    let handleChange=(dropDown,e,dropDowndata)=>{
        // if(e.target.value!=""){
            // if(filterOptions.current.indexOf(e.target.value)==-1){
            //     filterOptions.current.push(e.target.value)
            // }
            // if(dropDowndata.indexOf(e.target.value)!=-1){

            // }
            fullFilter[dropDown]=e.target.value

            // sendData.current=sendData.current +dropDown+"="+e.target.value+"&"
        //console.warn("cknbdbkjreef=",sendData)
    // }
    let selData=[]
    


   
    
    selectedFilter=selData.map((item)=>{
        return <p className='badge badge-info'>{item}</p>
    })

    setfullUrL(fullURL)
    featureExport(fullURL)

    //console.log(fullFilter,fullURL,"fullFilterfullFilter")
    // fullFilter
    // fullFilter.map((oneFilter)=>{
    //   //console.log(oneFilter,"oneFilteroneFilter")  
    // })


    

    }

    const submitData = ()=>{
        let selData=[]
        
        let fullURL="fc=True"
        // dispatch(MopDatabaseAction(mopDataApi + fullURL,true))
        Object.keys(fullFilter).map((oneFilter,index) => {
        
            const value = fullFilter[oneFilter];
            selData.push(value)
            //console.log(oneFilter,"oneFilteroneFilter")
            // if(index!=0){
            //     fullURL="?"+oneFilter+"="+value
            // }else{
                fullURL=fullURL+"&"+oneFilter+"="+encodeURIComponent(value)
            // }
            
        })

        
        selectedFilter=selData.map((item)=>{
            return <p className='badge badge-info'>{item}</p>
        })
        setFilterBadge(selectedFilter)

        //console.log(fullURL,"fullURLfullURL")

        let Filleddata=dispatch(LCLmopDashboardReducerAction({filterUrl:`${"fc=True&"+fullURL}`}))

        console.log(Filleddata,"FilleddataFilleddata")
        dispatch(MopDatabaseAction(mopDataApi+Filleddata),true);
        setcommonModalState(false)

    }

    
    const count =useSelector((state)=>{
        //console.log("the state is =", state);
        let filterObj =state.OneCommonResponse.filterDropDown
        console.warn("jxhbkjvbjacvkecfrwgvw=",filterObj)
        if(filterObj && Object.keys(filterObj).length>0){
            
             compOptionArry= filterObj.companies.sort().map((item,index)=>{
             

                return <option value={item}>{item}</option>
            })
             sowListArry =filterObj.sowList.map((item,index)=>{
             

                return <option value={item}>{item}</option>
            }) 
            typeOfWorkArry =filterObj.typeOfWork.map((item,index)=>{
             

                return <option value={item}>{item}</option>
            })
            rfstatus =filterObj.rfstatus.map((item,index)=>{
             

                return <option value={item}>{item}</option>
            })
            fopDevStatus =filterObj.fopDevStatus.map((item,index)=>{
             

                return <option value={item}>{item}</option>
            })
             vendorsArry =filterObj.vendors.sort().map((item,index)=>{
             

                return <option value={item}>{item}</option>
            })  
            siteID =filterObj.siteIdes.map((item,index)=>{
                

                return <option value={item}>{item}</option>
            })  
            groupArry=filterObj.group.map((item,index)=>{
              

                return <option value={item}>{item}</option>
            })  
            ticketId=filterObj.ticketIdies.sort().map((item,index)=>{
                if(index==0){
                    return <option disabled selected value={""}>Select</option>
                }
                return <option value={item}>{item}</option>
            })  
        }
        compOptionArry.unshift(<option disabled selected value={""}>Select</option>)
        sowListArry.unshift(<option disabled selected value={""}>Select</option>)
        typeOfWorkArry.unshift(<option disabled selected value={""}>Select</option>)
        vendorsArry.unshift(<option disabled selected value={""}>Select</option>)
        siteID.unshift(<option disabled selected value={""}>Select</option>)
        groupArry.unshift(<option disabled selected value={""}>Select</option>)
        ticketId.unshift(<option disabled selected value={""}>Select</option>)
        rfstatus.unshift(<option disabled selected value={""}>Select</option>)
        fopDevStatus.unshift(<option disabled selected value={""}>Select</option>)
            let dropDown={
                "siteId":{"label":"Site ID","values":siteID ,"dType":"S"},
                "ticketId":{"label":"Ticket Ids","values":ticketId ,"dType":"S"},
                "company":{"label":"Company","values":compOptionArry,"dType":"S"},
                "crewvendor":{"label":"Vendors","values":vendorsArry,"dType":"S"},
                "sowDetails":{"label":"SOW","values":sowListArry},
                "typeOfWork":{"label":"Type Of Work","values":typeOfWorkArry},
                "rfstatus":{"label":"RF Approval Status","values":rfstatus},
                "fopDevStatus":{"label":"DEV/FOPs Approval Status","values":fopDevStatus},
                "group":{"label":"Development or Operations Work","values":groupArry},
            }
            
            let dropdownSkel=Object.keys(dropDown).map((item,index)=>{
                
                // //console.warn(",mdscbvbcvwchkuvcwsvr=",dropDown.item)
                //console.warn(",mdscbvbcvwchkuvcwsvr=", dropDown[item]["values"])

                if(dropDown[item]['dType']=="S"){
                    return(
                        <div className='form-group col-3'> 
                            <label>{dropDown[item]["label"]}</label>
                            <input className='form-control' onChange={(e)=>handleChange(item,e,dropDown[item]["values"])} list={index}
                                style={{ height: "32px" }}/>
                                <datalist id={index}>

                                {dropDown[item]["values"]}
                                </datalist>

                        </div>
                    )
                }
                else if(dropDown[item]['dType']=="M"){

                }


                else{
                    return(
                    <div className='form-group col-3'>
                        <label>{dropDown[item]["label"]}</label>
                        <select className='form-control' 
                        
                        onChange={(e)=>handleChange(item,e)}
                        >
                            {
                            dropDown[item]["values"]
                            }
                        </select>
                    </div>
                    )
                        }
            })
    
        return({filterObj,dropDown,dropdownSkel})
    })    


    useEffect(()=>{
        // dispatch(getFilterDropDown([]))
        // dispatch(AccountReducerAction(mopFilters))

    },[])
  return (
    <>
        <div className='row'>
            {count.dropdownSkel}


        </div>

        <button onClick={(e)=>{
            submitData()
        }} className={'btn btn-xs btn-primary mx-2 py-2 btn btn-primary'}>SEARCH</button>
    </>
  )
}

export default MopFilterList