import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { addAcccountReducerAction, addUserAction, popUpUpdate } from '../../../../store/actions/createTicketManageFields'
import DatePicker from 'react-datepicker';
import moment from "moment"
const SingleDateOnlyModal = (props) => {
  const dispatch = useDispatch()
  const [getDat, setDat] = useState()
  const [oneTime, setoneTime] = useState(true)
  const [oldData, setoldData] = useState(true)
  const [error, seterror] = useState(false)

  console.log(getDat, "props.getOld")
  console.log(props.getOld + ":00", props.getOld != oldData, new Date(props.getOld + ":00"), "props.getOld")
  if (props.getOld && props.getOld != oldData) {
    setoneTime(true)
    setoldData(props.getOld)

    if (new Date(props.getOld) != "Invalid Date") {
      setDat(new Date(props.getOld))
    }
  }
  if (props.getOld == "") {
    setDat("")
  }

  const handleSubmit = () => {
    console.log("jbvsugfouergo=",getDat)
    console.log("jbvsugfouergo=",props)

    if(!getDat){
      seterror(true)
      return
    }


    dispatch(addAcccountReducerAction(props.sendUrl + "/" + props.uid, { [props.dataKey]: moment(getDat).format("MM/DD/YYYY") }))

    dispatch(popUpUpdate({}))
    props.setCloser(true)

  }
  console.log("jbvsugfouergo=", props)
  const styles = {
    width: "400px",
  };
  return (
    <div className='form-group'>
      <label>{props.label}</label>

      <DatePicker
        style={styles}
        className="form-control"
        selected={getDat}
        onChange={(e) => { 
          setDat(e);
          seterror(false)
         }}
        popperPlacement="top"
      />

      <p className="error" style={{ display: error ? "block" : "none" }}>
        Required Field
      </p>
      <button onClick={handleSubmit} className="badge-primary badge mt-3">Submit</button>
    </div>
  )
}

export default SingleDateOnlyModal