import { Hidden } from '@mui/material'
import React, { Fragment, useState } from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { VendorScoreCardReducerAction, funVendorScoreCard } from '../../../../store/actions/createTicketManageFields'
import { VendorScoreCardApi } from '../../../../common/config'

const OneTopBoard = ({ setDivBox, firstStepChart, setFirstStepChart, head, headper, headVal, bi, headTitle }) => {


    const dispatch = useDispatch()
    return <Col xl='2' style={{ cursor: "pointer", height: "100%" }}>
        <Card onClick={(e) => {
            setDivBox(prev => {
                if (prev == head) {
                    return ""
                } else {
                    dispatch(funVendorScoreCard([]))
                    dispatch(VendorScoreCardReducerAction(VendorScoreCardApi + head + "/" + "T-Mobile"))
                    return head
                }
            })
            setFirstStepChart(prev => !prev)


        }} style={{ fontSize: "18px", overflow: "hidden", backgroundImage: bi }}>
            <Card.Header style={{ justifyContent: "space-around" }} className=' border-0 pb-0'>
                <Card.Title style={{ fontSize: "18px" }}>{headVal} ({headper}) <br /> {head}</Card.Title>
            </Card.Header>
            <Card.Body style={{ padding: "1rem 2px", backgroundColor: "#ffffff", margin: "10px", borderRadius: "20px" }}>
                <div style={{ display: "flex", justifyContent: "space-around" }}>
                    {
                        headTitle.map((itew) => {
                            return <Card.Text style={{ fontSize: "12px", color: "#000000" }}>{itew[2]} ({itew[1]}) <br /> {itew[0]}</Card.Text>
                        })
                    }
                </div>
            </Card.Body>
        </Card>
    </Col>
}





const UpOneTopBoard = ({ filtering, setDivBox, firstStepChart, setFirstStepChart, head, headper, headVal, bi, headTitle }) => {


    const dispatch = useDispatch()
    return <div className='custom_grid-item'>
        <Card onClick={(e) => {
            setDivBox(prev => {
                if (prev == head) {
                    return ""
                } else {
                    dispatch(funVendorScoreCard([]))
                    dispatch(VendorScoreCardReducerAction(`${VendorScoreCardApi}${head}/T-Mobile${filtering!=""?filtering+"&smartFilter=true":"?smartFilter=true"}`))
                    return head
                }
            })
            setFirstStepChart(prev => !prev)


        }} style={{ fontSize: "18px", overflow: "hidden", backgroundImage: bi }}>
            <Card.Header style={{ justifyContent: "space-around" }} className=' border-0 pb-0'>
                <Card.Title style={{ fontSize: "18px" }}>{headVal} ({headper}) <br /> {head}</Card.Title>
            </Card.Header>
            <Card.Body style={{ padding: "1rem 2px", backgroundColor: "#ffffff", margin: "10px", borderRadius: "20px" }}>
                <div style={{ display: "flex", justifyContent: "space-around" }}>
                    {
                        headTitle.map((itew) => {
                            return <Card.Text style={{ fontSize: "12px", color: "#000000" }}>{itew[2]} ({itew[1]}) <br /> {itew[0]}</Card.Text>
                        })
                    }
                </div>
            </Card.Body>
        </Card>
    </div>
}
const TopBoard = ({ filtering, setDivBox, firstStepChart, setFirstStepChart }) => {

    const [expand, setExpand] = useState(true)


    let siteDowntimeDetails = useSelector((state) => {
        return state.vendorScoreCard.funVendorScoreCardDashboard
    })


    console.log(siteDowntimeDetails, "siteDowntimeDetailssiteDowntimeDetails")
    return <>

        <div>
            <Fragment>

                <div class="custom_grid-container">
                    {
                        siteDowntimeDetails.map((ite) => {
                            return <UpOneTopBoard filtering={filtering} firstStepChart={firstStepChart} setFirstStepChart={setFirstStepChart} setDivBox={setDivBox} head={ite.label} headVal={ite.total} headper={ite.totalPer} bi={ite.bi} headTitle={ite.data} />
                        })
                    }

                </div>
                {/* <Row style={{ justifyContent: "space-around", height: expand ? "200px" : "120px" }}>

                    {
                        siteDowntimeDetails.map((ite) => {
                            return <OneTopBoard firstStepChart={firstStepChart} setFirstStepChart={setFirstStepChart} setDivBox={setDivBox} head={ite.label} headVal={ite.total} headper={ite.totalPer} bi={ite.bi} headTitle={ite.data} />
                        })
                    }

                </Row> */}

                    {/* <OneTopBoard firstStepChart={firstStepChart} setFirstStepChart={setFirstStepChart} setDivBox={setDivBox} head={"Trouble Ticket"} headVal={760} headper={100} bi={"linear-gradient( 135deg, #ABDCFF 10%, #0396FF 100%)"} headTitle={[["Open", "10%", "10"], ["Close", "10%", "10"]]} />
                    <OneTopBoard firstStepChart={firstStepChart} setFirstStepChart={setFirstStepChart} setDivBox={setDivBox} head={"Punctuality"} headVal={760} headper={100} bi={"linear-gradient( 135deg, #2AFADF 10%, #4C83FF 100%)"} headTitle={[["Punctual", "10%", "10"], ["Not Punctual", "10%", "10"]]} />
                    <OneTopBoard firstStepChart={firstStepChart} setFirstStepChart={setFirstStepChart} setDivBox={setDivBox} head={"Site Spilover"} headVal={760} headper={100} bi={"linear-gradient( 135deg, #FFD3A5 10%, #FD6585 100%)"} headTitle={[["Spilover", "10%", "10"], ["No Spilover", "10%", "10"]]} />
                    <OneTopBoard firstStepChart={firstStepChart} setFirstStepChart={setFirstStepChart} setDivBox={setDivBox} head={"Performance"} headVal={760} headper={100} bi={"linear-gradient( 135deg, #EE9AE5 10%, #5961F9 100%)"} headTitle={[["Completed", "10%", "10"], ["Not Completed", "10%", "10"]]} />
                    <OneTopBoard firstStepChart={firstStepChart} setFirstStepChart={setFirstStepChart} setDivBox={setDivBox} head={"Site Downtime"} headVal={760} headper={100} bi={"linear-gradient( 135deg, #FFD3A5 10%, #5961F9 100%)"} headTitle={[["Downtime", "10%", "10"], ["No Downtime", "10%", "10"]]} /> */}

                    {/* <Col xl='2' style={{ height: "100%" }}>
                        <Card style={{ fontSize: "18px", overflow: "hidden", backgroundImage: "" }}>
                            <Card.Header className=' border-0 pb-0'>
                                <Card.Title style={{ fontSize: "18px" }}>Punctuality <br /> 100</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text style={{ fontSize: "14px", color: "#000000" }}>Open - 18</Card.Text>
                                <Card.Text style={{ fontSize: "14px", color: "#000000" }}>Terminated - 7</Card.Text>
                                <Card.Text style={{ fontSize: "14px", color: "#000000" }}>Close - 75</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xl='2' style={{ height: "100%" }}>
                        <Card style={{ fontSize: "18px", overflow: "hidden", backgroundImage: "" }}>
                            <Card.Header className=' border-0 pb-0'>
                                <Card.Title style={{ fontSize: "18px" }}>Site Spilover <br /> 100</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text style={{ fontSize: "14px", color: "#000000" }}>Open - 18</Card.Text>
                                <Card.Text style={{ fontSize: "14px", color: "#000000" }}>Terminated - 7</Card.Text>
                                <Card.Text style={{ fontSize: "14px", color: "#000000" }}>Close - 75</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xl='2' style={{ height: "100%" }}>
                        <Card style={{ fontSize: "18px", overflow: "hidden", backgroundImage: "linear-gradient( 135deg, #EE9AE5 10%, #5961F9 100%)" }}>
                            <Card.Header className=' border-0 pb-0'>
                                <Card.Title style={{ fontSize: "18px" }}>Punctuality <br /> 100</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text style={{ fontSize: "14px", color: "#000000" }}>Open - 18</Card.Text>
                                <Card.Text style={{ fontSize: "14px", color: "#000000" }}>Terminated - 7</Card.Text>
                                <Card.Text style={{ fontSize: "14px", color: "#000000" }}>Close - 75</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xl='2' style={{ height: "100%" }}>
                        <Card style={{ fontSize: "18px", overflow: "hidden", backgroundImage: "linear-gradient( 135deg, #FFD3A5 10%, #5961F9 100%)" }}>
                            <Card.Header className=' border-0 pb-0'>
                                <Card.Title style={{ fontSize: "18px" }}>Site Downtime <br /> 100</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text style={{ fontSize: "14px", color: "#000000" }}>Open - 18</Card.Text>
                                <Card.Text style={{ fontSize: "14px", color: "#000000" }}>Terminated - 7</Card.Text>
                                <Card.Text style={{ fontSize: "14px", color: "#000000" }}>Close - 75</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xl='2' style={{ height: "100%" }}>
                        <Button onClick={(e) => {
                            setExpand(prev => !prev)
                        }}>
                            {expand ? "Close" : "Open"}
                        </Button>
                    </Col> */}
                {/* </Row> */}
            </Fragment>
        </div>
    </>
}


export default TopBoard