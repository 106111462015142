import { stat } from "fs";
import { addDoc, doc, collection, serverTimestamp, updateDoc } from "firebase/firestore";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import moment from "moment";
import { db, storage } from "../../../common/firebase";
import {
   query,
   orderBy,
   onSnapshot,
   where,
   limit,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { async } from "@firebase/util";
import { commonPost } from "../../../services/AdminService";
import { ChatfileUpload } from "../../../common/config";
import swal from "sweetalert";
import { Hidden } from "@mui/material";
import { assigningEngineerAction } from "../../../store/actions/createTicketManageFields";
const MsgBox = ({ mssgArray, setMessages, onClick, avatar1, avatar2, openMsg, PerfectScrollbar, offMsg }) => {
   const [message, setState] = useState("")
   // const [mssgArray,setMessages]=useState([])
   const [toggle, setToggle] = useState(false);
   const [imageUrls, setImageUrls] = useState([]);
   const [sendFile, setSendFile] = useState("")
   // const el = document.getElementById('DZ_W_Contacts_Body3');
   // if (el) {
   //    el.scrollTop = el.scrollHeight;
   //  }
   let scrollRef = useRef()
   let chatInfo = ''
   const dispatch = useDispatch();
   chatInfo = useSelector((state) => {
      let info = state.OneCommonResponse.ChatInfo
      let unsubscribe
      if (Object.keys(info).length > 0) {
         // const q = query(
         //    collection(db, "messages"),
         //    where("ranId","==",info.ranSupportId),
         //    orderBy("createdAt"),
         //    limit(50)
         //  );
         //  unsubscribe = onSnapshot(q, (QuerySnapshot) => {
         //    let messages = [];
         //    console.log("the querysnapshot =",QuerySnapshot)
         //    QuerySnapshot.forEach((doc) => {

         //      messages.push({ ...doc.data(), id: doc.id });

         //    });
         //    console.log("the message array=",messages)
         //    setMessages(messages);
         //  });
         //  unsubscribe();
         return info
      }

   })
   const userDetails = JSON.parse(localStorage.getItem("userDetails"))
   console.log("the user details is=", chatInfo)
   const submitHandler = async (e) => {
      e.preventDefault();
      if (message.trim() === "") {
         alert("Enter valid message");
         return;
      }
      else if (sendFile != "") {
         // const imageRef=ref(storage,`Uploads/${sendFile.name}`)
         let newform = new FormData();
         newform.append("uploadFile", sendFile)
         commonPost(ChatfileUpload, newform)
            .then(async (resp) => {
               console.log("the response 5454=", resp)
               var coll = collection(db, "NTMOMCPS_SITES")
               let docResponse = doc(coll, chatInfo.siteId)
               let finalCollection = collection(docResponse, "MESSAGES")
               console.log("the siynkjnkj=", db,docResponse.firestore,docResponse.firestore.toJSON() , collection(docResponse, "MESSAGES"))
               await updateDoc(docResponse, {
                  latestMessage: {  
                     text:message,
                     createdAt: new Date().getTime(),
                  },
                  isReadMob:false,
                  isReadWeb:true
               })
               await addDoc(finalCollection, {
                  img: resp.data,
                  createdAt: new Date().getTime(),
                  filename: sendFile.name,
                  //   ranId:chatInfo.ranSupportId,
                  user: {
                     _id: userDetails.uniqueid,
                     name: userDetails.name,
                     email: userDetails.email
                  }
                  //   uid:123243254634534,
               })
               // await addDoc(collection(db, "messages"), {
               //    text:url ,
               //    ranId:chatInfo.ranSupportId,
               //    senderId:userDetails.uniqueid,
               //    filename:imageRef.name,
               //    senderName:userDetails.name,
               //  //   avatar: "no url",
               //    createdAt: serverTimestamp(),
               //  //   uid:123243254634534,
               //  });
            }).catch((err) => {
               swal({
                  title: "Alert",
                  text: "Unable to upload file",
                  icon: "error",
                  // button: "Ok",
               })
            })
         // uploadBytes(imageRef,sendFile).then((snapshot)=>{
         //    // console.log("the ref is =",)
         //   getDownloadURL(snapshot.ref).then(async (url)=>{
         //     setImageUrls((prev) => [...prev, url]);
         //     console.log("the url is =",imageRef)

         setSendFile("")
         //   })
         // })
      }

      // const { uid, displayName, photoURL } = auth.currentUser;
      else {
         var coll = collection(db, "NTMOMCPS_SITES")
         let docResponse = doc(coll, chatInfo.siteId)
         let finalCollection = collection(docResponse, "MESSAGES")
         console.log("the siynkjnkj=", db,docResponse.count, collection(docResponse, "MESSAGES"))
         await updateDoc(docResponse, {
            latestMessage: {  
               text:message,
               createdAt: new Date().getTime(),
            },
            isReadMob:false,
            isReadWeb:true
         })
         await addDoc(finalCollection, {
            text: message,
            createdAt: new Date().getTime(),
            //   ranId:chatInfo.ranSupportId,
            user: {
               _id: userDetails.uniqueid,
               name: userDetails.name,
               email: userDetails.email
            }
            //   uid:123243254634534,
         })
      }
      setState("")
   }

   const handleMessage = async (e) => {
      setState(e.target.value)

   }
   const fileChangeHandler = (e) => {
      console.log("selected files=", e.target.files)
      if (e.target.files[0] && e.target.files[0].name) {
         setSendFile(e.target.files[0])
         setState(e.target.files[0].name)
      }
      // const imageRef=ref(storage,`image/${e.target.files[0].name}`)
      // uploadBytes(imageRef,file).then((snapshot)=>{
      //   getDownloadURL(snapshot.ref).then((url)=>{
      //     setImageUrls((prev) => [...prev, url]);
      //     console.log("the url is =",url)
      //   })
      // })
   }
   useEffect(() => {
      
      if (chatInfo.ranSupportId != "") {
         console.log("the rlcfjken=", chatInfo.ranSupportId)
         var coll = collection(db, "NTMOMCPS_SITES")
         let docResponse = doc(coll, chatInfo.siteId)
         let finalCollection = collection(docResponse, "MESSAGES")
            const q = query(
               finalCollection,
               //   where("ranId","==",chatInfo.ranSupportId),
               orderBy("createdAt", "asc"),
               // limit(100)
            );

         updateDoc(docResponse, {
            isReadWeb:true
         })
         const unsubscribe = onSnapshot(q, (QuerySnapshot) => {
            let messages = [];
            console.log("the querysnapshot =", QuerySnapshot)
            QuerySnapshot.forEach((doc) => {

               messages.push({ ...doc.data(), id: doc.id });

            });
            console.log("the message array=", messages)
            let updatedMessage = messages.map((item) => {
               if (item.createdAt) {
                  // moment(new Date(1680117447551)).format("Do MMMM YYYY hh:mm")
                  item.date = moment(new Date(item.createdAt)).format("Do MMMM YYYY hh:mm")
                  return item
               }
            })
            if (updatedMessage.length > 0) {
               console.log("the list of mesages sequences wise=", updatedMessage)
               let messageComponent = updatedMessage.map((item) => {
                  console.log("the senmlerfnerlknfve=", item)
                  if (item) {

                     if (item.user && item.user._id == userDetails.uniqueid) {
                        let tempsymbol = item.user.name.split(" ")
                        // let symbol=tempsymbol[0][0]+tempsymbol[1][0]
                        let symbol = tempsymbol[0] ? tempsymbol[0][0] : "" + tempsymbol[1] ? tempsymbol[1][0] : ""
                        if (item.img || item.filename) {
                           let tempfilename = item.img.split("/")
                           let tempfileExtn = tempfilename.pop()
                           let extn = item.filename ? item.filename.split(".") : tempfileExtn.split(".")
                           let xtn = extn.pop()
                           console.log("the extension is= ", xtn)
                           let imgFormat = ["jpeg", "jpg", "png", "gif"]
                           if (imgFormat.indexOf(xtn.toLowerCase()) != -1) {
                              return <a href={item.img} target="_blank" className="d-flex justify-content-end mb-4">
                                 <img src={item.img} className="msg_cotainer_send" style={{ background: "#86efac", minWidth: "200px", maxWidth: " 240px", fontSize: "12px" }} />
                                 {/* {item.filename} */}
                                 {/* <span className="msg_time_send"></span> */}

                                 <div className="img_cont_msg img_cont_msg_s">
                                    {symbol}
                                 </div>
                                 {/* {item.date} */}
                              </a>
                           }
                           else {

                              return <div className="d-flex justify-content-end mb-4">
                                 <a href={item.img} target="_blank" className="msg_cotainer_send" style={{ background: "#86efac", minWidth: "200px", maxWidth: " 240px", fontSize: "12px" }}>
                                    {item.filename ? item.filename : tempfileExtn}
                                    <span className="msg_time_send">{item.date}</span>
                                 </a>
                                 <div className="img_cont_msg img_cont_msg_s">
                                    {symbol}
                                 </div>
                              </div>
                           }
                        }
                        else {
                           return <div className="d-flex justify-content-end mb-4">
                              <div className="msg_cotainer_send" style={{ background: "#86efac", minWidth: "200px", maxWidth: " 240px", fontSize: "12px" }}>
                                 {item.text}
                                 <span className="msg_time_send">{item.date}</span>
                              </div>
                              <div className="img_cont_msg img_cont_msg_s">
                                 {symbol}
                              </div>
                           </div>
                        }
                     }
                     else if (item.user) {
                        let tempsymbol = item.user.name.split(" ")
                        // let symbol=tempsymbol[0][0]+tempsymbol[1][0]
                        let symbol = tempsymbol[0] ? tempsymbol[0][0] : "" + tempsymbol[1] ? tempsymbol[1][0] : ""
                        if (item.img || item.filename) {
                           let tempfilename = item.img.split("/")
                           let tempfileExtn = tempfilename.pop()
                           let extn = item.filename ? item.filename.split(".") : tempfileExtn.split(".")
                           let xtn = extn.pop()
                           let imgFormat = ["jpeg", "jpg", "png", "gif"]
                           if (imgFormat.indexOf(xtn.toLowerCase()) != -1) {
                              return <a href={item.img} target="_blank" className="d-flex justify-content-start mb-4" >
                                 <div className="img_cont_msg img_cont_msg_r" >
                                    {symbol}
                                 </div>
                                 <p style={{
                                    borderBottom: "2px solid #ff8f16",
                                    width: "fit-content",
                                    fontSize: "11px",
                                    marginBottom: "4px"
                                 }}>{item.user.name}</p>
                                 <img src={item.img} className="msg_cotainer" style={{ background: "#f1f5f9", color: "#222", minWidth: "200px", maxWidth: " 240px", fontSize: "12px" }} />
                                 {/* {item.filename} */}
                                 {/* <span className="msg_time" style={{color:"black"}}></span> */}
                                 {/* {item.date} */}
                              </a>
                           }
                           else {
                              return <div className="d-flex justify-content-start mb-4" >
                                 <div className="img_cont_msg img_cont_msg_r" >
                                    {symbol}
                                 </div>
                                 <p style={{
                                    borderBottom: "2px solid #ff8f16",
                                    width: "fit-content",
                                    fontSize: "11px",
                                    marginBottom: "4px"
                                 }}>{item.user.name}</p>
                                 <a href={item.img} target="_blank" className="msg_cotainer" style={{ background: "#f1f5f9", color: "#222", minWidth: "200px", maxWidth: " 240px", fontSize: "12px" }}>
                                    {item.filename ? item.filename : tempfileExtn}
                                    <span className="msg_time" style={{ color: "black" }}>{item.date}</span>
                                 </a>
                              </div>
                           }
                        }
                        return <div className="d-flex justify-content-start mb-4" >
                           <div className="img_cont_msg img_cont_msg_r" >
                              {symbol}
                           </div>
                           <div className="msg_cotainer" style={{ background: "#f1f5f9", color: "#222", minWidth: "200px", maxWidth: " 240px", fontSize: "12px" }}>
                              <p style={{
                                 borderBottom: "2px solid #ff8f16",
                                 width: "fit-content",
                                 fontSize: "11px",
                                 marginBottom: "4px"
                              }}>{item.user.name}</p>
                              <p style={{ marginLeft: "4px" }}>{item.text}</p>
                              <span className="msg_time" style={{ color: "black" }}>{item.date}</span>
                           </div>
                        </div>
                     }
                  }
               })

               setMessages(messageComponent);
            }
         });
         return () => unsubscribe;
      }

      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      // firstTime = false;
      if (scrollRef.current.scrollTop + scrollRef.current.clientHeight === scrollRef.current.scrollHeight) {
         scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
   }, [chatInfo]);
   useEffect(() => {
      scrollRef.current?.scrollIntoView({ behavior: "smooth" });
   }, [mssgArray])
   return (
      <div
         style={{
            position: "absolute",
            bottom: "-11.5rem"
         }}
         className={`card chat dz-chat-history-box ${openMsg ? "" : "d-none"}`}
      >
         <div className="card-header chat-list-header text-center">
            <Link to={"#"}
               className="dz-chat-history-back"
               onClick={() => { 
                  onClick(); 
                  setMessages([]);
                  dispatch(assigningEngineerAction({assignedEngineer:"",siteId:"",ranSupportId:""})); 
               }}
            >
               <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="15px" height="25px">
                  <path
                     d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z" />
               </svg>
            </Link>
            <div>
               <h6 className="mb-1 text-white-50">Chat with {chatInfo.assignedEngineer}</h6>
               <p className="mb-0 text-white">Site Id : {chatInfo.siteId}</p>
            </div>
            <div style={{ visibility: 'hidden' }} className="dropdown">
               <Link to={"#"}
                  data-toggle="dropdown"
                  aria-expanded="false"
                  onClick={() => setToggle(!toggle)}
               >
                  <svg
                     xmlns="http://www.w3.org/2000/svg"
                     xmlnsXlink="http://www.w3.org/1999/xlink"
                     width="18px"
                     height="18px"
                     viewBox="0 0 24 24"
                     version="1.1"
                  >
                     <g
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                     >
                        <rect x="0" y="0" width="24" height="24" />
                        <circle fill="#000000" cx="5" cy="12" r="2" />
                        <circle fill="#000000" cx="12" cy="12" r="2" />
                        <circle fill="#000000" cx="19" cy="12" r="2" />
                     </g>
                  </svg>
               </Link>
               <ul
                  className={`dropdown-menu dropdown-menu-right ${toggle ? "show" : ""
                     }`}
               >
                  <li
                     className="dropdown-item"
                     onClick={() => setToggle(false)}
                  >
                     <i className="fa fa-user-circle text-primary mr-2"></i>{" "}
                     View profile
                  </li>
                  <li
                     className="dropdown-item"
                     onClick={() => setToggle(false)}
                  >
                     <i className="fa fa-users text-primary mr-2"></i> Add to
                     close friends
                  </li>
                  <li
                     className="dropdown-item"
                     onClick={() => setToggle(false)}
                  >
                     <i className="fa fa-plus text-primary mr-2"></i> Add to
                     group
                  </li>
                  <li
                     className="dropdown-item"
                     onClick={() => setToggle(false)}
                  >
                     <i className="fa fa-ban text-primary mr-2"></i> Block
                  </li>
               </ul>
            </div>
         </div>

         <div
            style={{ overflowY: "scroll !important", background: "#cfcfe7" }}
            className={`card-body msg_card_body dz-scroll overFlowforMsgbox ${openMsg ? " ps--active-y" : " ps--active-y"
               } `}
            id="DZ_W_Contacts_Body3"
         >

            {mssgArray}
            <div ref={scrollRef} style={{ height: "0.5rem" }}></div>
         </div>
         <form className="card-footer type_msg" onSubmit={submitHandler}   >
            <div className="d-flex justify-content-between">
               <div className="d-flex">
                  <textarea
                     style={{
                        border: "none",
                        width: "177px"
                     }}
                     placeholder="Type your message..."
                     value={message}
                     onChange={handleMessage}
                  ></textarea>
                  <label class="filelabel" >
                     <i class="fa fa-paperclip">
                     </i>
                     <input class="FileUpload1" style={{ display: "block !important" }} id="FileInput" name="booking_attachment" type="file" onChange={fileChangeHandler} />
                  </label>
               </div>
               {/* <input type="file" onChange={fileChangeHandler}/> */}
               <div className="input-group-append">
                  <button type="submit" className="btn btn-primary">
                     <i className="fa fa-location-arrow"></i>
                  </button>
               </div>
            </div>
         </form>
      </div>
   );
};

export default MsgBox;