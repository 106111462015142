import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AccountReducerAction, LCLTTReducerAction, getFilterDropDown } from '../../../../store/actions/createTicketManageFields'
import { listtroubleTicketApi, mopDataApi, mopFilters, ranDashboard, ranfilters, ttfilters } from '../../../../common/config'
import { MopDatabaseAction } from '../../../../store/actions/dashboardActions'
import { MultiSelect } from "react-multi-select-component";
const TTFilterList = ({setFilterBadge, setfullUrL, setcommonModalState, featureExport}) => {
    const dispatch =useDispatch()
    let [techaff, settechaff] = useState([]);
    let [commonstate, setcommonstate] = useState({
        company:[],
        vendor:[]
    });
    let compOptionArry=[]
    let sowListArry=[]
    let typeOfWorkArry=[]
    let vendorsArry=[]
    let siteID=[]
    let ticketId=[]
    let pierticketIdies=[]
    
    let groupArry=[]
    let sendData=useRef("")
    let filterOptions=useRef([])
    
    let fullURL="fc=True"
    let selectedFilter=[]

    const [fullFilter,setfullFilter]=useState({})
    let handleChange=(dropDown,e)=>{
        // if(e.target.value!=""){
            if(filterOptions.current.indexOf(e.target.value)==-1){
                filterOptions.current.push(e.target.value)
            }

            fullFilter[dropDown]=e.target.value
            // sendData.current=sendData.current +dropDown+"="+e.target.value+"&"
        console.warn("cknbdbkjreef=",sendData)
    // }
    // let selData=[]
    


    // Object.keys(fullFilter).map((oneFilter,index) => {
        
    //     const value = fullFilter[oneFilter];
    //     selData.push(value)
    //     console.log(oneFilter,"oneFilteroneFilter")
    //     // if(index!=0){
    //     //     fullURL="?"+oneFilter+"="+value
    //     // }else{
    //         fullURL=fullURL+"&"+oneFilter+"="+value
    //     // }
        
    // })
    
    // selectedFilter=selData.map((item)=>{
    //     return <p className='badge badge-info'>{item}</p>
    // })

    // setfullUrL(fullURL)
    // featureExport(fullURL)

    // console.log(fullFilter,fullURL,"fullFilterfullFilter")
    // fullFilter
    // fullFilter.map((oneFilter)=>{
    //   console.log(oneFilter,"oneFilteroneFilter")  
    // })


    

    }

    
    const commonChangleHandler = (e,allval) => {
        console.log("Pow multiple select=", e,allval);
        // settechaff(e);
        console.log("Pow multiple select=",fullFilter[allval])
        let data=e.map((iem)=>{
            console.log(iem,"datadatadata")
            return iem.value
        })


        console.log(data,"datadatadata")

        console.log(data.join(","),fullFilter,allval,"datadatadata")
        fullFilter[allval]=data.join(",")
        // commonstate[allval]=e

        setcommonstate(prevData => ({
            ...prevData,
            [allval]: e
          }))
        console.log(commonstate,"datadatadata")
        console.log(fullFilter,"datadatadata")
        // setcommonstate({})
      };
    const submitData = ()=>{
        let selData=[]
        
        Object.keys(fullFilter).map((oneFilter,index) => {
        
            const value = fullFilter[oneFilter];
            selData.push(value)
            console.log(oneFilter,"oneFilteroneFilter")
            // if(index!=0){
            //     fullURL="?"+oneFilter+"="+value
            // }else{
                fullURL=fullURL+"&"+oneFilter+"="+encodeURIComponent(value)
            // }
            
        })
        selectedFilter=selData.map((item)=>{
            return <p className='badge badge-info'>{item}</p>
        })
        setFilterBadge(selectedFilter)

        
        let Filleddata=dispatch(LCLTTReducerAction({filterUrl:`${"fc=True&"+fullURL}`}))
        console.log(Filleddata,"FilleddataFilleddata")
        dispatch(AccountReducerAction(listtroubleTicketApi + Filleddata,true));
        // dispatch(AccountReducerAction(listtroubleTicketApi+ "?" + fullURL,true,true));
        // dispatch(MopDatabaseAction(mopDataApi + "?" + fullURL,true))
        setcommonModalState(false)
    }

    
    const count =useSelector((state)=>{
        console.log("the state is =", state);
        let filterObj =state.OneCommonResponse.filterDropDown
        console.warn("jxhbkjvbjacvkecfrwgvw=",filterObj)
        if(filterObj && Object.keys(filterObj).length>0){
            
            compOptionArry= filterObj.companies.sort().map((item,index)=>{
                return {
                    value:item,
                    label:item
                }
                // <option value={item}>{item}</option>
            })
            sowListArry =filterObj.ttstatus.map((item,index)=>{
                return <option value={item}>{item}</option>
            }) 
            typeOfWorkArry =filterObj.impact.map((item,index)=>{
                return <option value={item}>{item}</option>
            })
            vendorsArry =filterObj.vendors.sort().map((item,index)=>{
                return {
                    value:item,
                    label:item
                }
                // return <option value={item}>{item}</option>
            })  
            siteID =filterObj.siteUniqueId.sort().map((item,index)=>{
                return <option value={item}>{item}</option>
            }) 
            
            pierticketIdies =filterObj.pierticketIdies.sort().map((item,index)=>{
                return <option value={item}>{item}</option>
            }) 

            
            // groupArry=filterObj.group.map((item,index)=>{
            //     return <option value={item}>{item}</option>
            // })  
            ticketId=filterObj.ticketIdies.sort().map((item,index)=>{
            //     if(index==0){
            //         return <option disabled selected value={""}>Select</option>
            //     }
                return <option value={item}>{item}</option>
            })  
        }
        // compOptionArry.unshift(<option disabled selected value={""}>Select</option>)
        sowListArry.unshift(<option disabled selected value={""}>Select</option>)
        typeOfWorkArry.unshift(<option disabled selected value={""}>Select</option>)
        // vendorsArry.unshift(<option disabled selected value={""}>Select</option>)
        siteID.unshift(<option disabled selected value={""}>Select</option>)
        // groupArry.unshift(<option disabled selected value={""}>Select</option>)
        ticketId.unshift(<option disabled selected value={""}>Select</option>)
        pierticketIdies.unshift(<option disabled selected value={""}>Select</option>)
            let dropDown={
                "siteId":{"label":"Site ID","values":siteID ,"dType":"S"},
                "company":{"label":"Company","values":compOptionArry,"dType":"M"},
                "vendor":{"label":"Vendors","values":vendorsArry,"dType":"M"},
                "ttStatus":{"label":"TT Status","values":sowListArry},
                // "typeOfWork":{"label":"Type Of Work","values":typeOfWorkArry},
                "impact":{"label":"Impact","values":typeOfWorkArry},
                // "group":{"label":"Group","values":groupArry},
                "ttid":{"label":"Ticket Id","values":ticketId ,"dType":"S"},
                "tmoPierTtid":{"label":"Pier Ticket Id","values":pierticketIdies ,"dType":"S"}
                
            }
            
            let dropdownSkel=Object.keys(dropDown).map((item,index)=>{
                
                // console.warn(",mdscbvbcvwchkuvcwsvr=",dropDown.item)
                console.warn(",mdscbvbcvwchkuvcwsvr=", dropDown[item]["values"])

                if(dropDown[item]['dType']=="S"){
                    return(
                        <div className='form-group col-3'> 
                            <label>{dropDown[item]["label"]}</label>
                            <input className='form-control' onChange={(e)=>handleChange(item,e)} list={index}
                                style={{ height: "32px" }}/>
                                <datalist id={index}>

                                {dropDown[item]["values"]}
                                </datalist>

                        </div>
                    )
                }
                else if(dropDown[item]['dType']=="M"){
                    return(
                        <div className='form-group col-3'> 
                            <label>{dropDown[item]["label"]}</label>
                            {/* <input className='form-control' onChange={(e)=>handleChange(item,e)} list={index}
                                style={{ height: "32px" }}/>
                                <datalist id={index}>

                                {dropDown[item]["values"]}
                                </datalist> */}

                            {console.log(commonstate[item],commonstate,item,"commonstateitem")}
                            <MultiSelect
                                options={dropDown[item]["values"]}
                                onChange={(e)=>{
                                    commonChangleHandler(e,item)
                                }}
                                value={commonstate[item]}
                                hasSelectAll={false}
                          />

                        </div>
                    )
                }


                else{
                    return(
                    <div className='form-group col-3'>
                        <label>{dropDown[item]["label"]}</label>
                        <select className='form-control' 
                        
                        onChange={(e)=>handleChange(item,e)}
                        >
                            {
                            dropDown[item]["values"]
                            }
                        </select>
                    </div>
                    )
                        }
            })
    
        return({filterObj,dropDown,dropdownSkel})
    })    


    useEffect(()=>{
            
        // dispatch(getFilterDropDown([]))
        // dispatch(AccountReducerAction(ttfilters))

    },[])
  return (
    <>
        <div className='row'>
            {count.dropdownSkel}

            {console.log(count.dropdownSkel,"count.dropdownSkel")}

        </div>

        <button onClick={(e)=>{
            submitData()
        }} className={'btn btn-xs btn-primary mx-2 py-2 btn btn-primary'}>SEARCH</button>
    </>
  )
}

export default TTFilterList