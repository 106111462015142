import React, { useEffect, useState } from 'react'
// import { Dropdown } from 'react-bootstrap'
// import "../../../css/customStyle.css"
import axios from "axios";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useSelector, useDispatch } from 'react-redux'
import { NetworkDatabaseAction, CSVUploadAction, PAG_maindashboardAction } from "../../../store/actions/dashboardActions"
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
// import DateTimeRangeContainer from 'react-advanced-datetimerange-picker'
// import { FormControl } from 'react-bootstrap'
import moment from "moment"
import "react-datepicker/dist/react-datepicker.css";

// import "../../../css/custom.css"
import "../../../css/customDashboard.css"



// import { Chart } from "react-google-charts";
// import Chart from 'react-apexcharts';
import { GetChartAction } from '../../../store/actions/createTicketManageFields';
import { dashboardCustomer, dashboardVendor, sitesAgingCustomer, sitesCountCustomer, ttAgingVendor, ttCountVendor, warRoomActivity } from '../../../common/config';
import DatePicker from "react-datepicker";
// import { CustomInput } from 'reactstrap';


import candyTheme from "fusioncharts/themes/fusioncharts.theme.candy";
import FusionCharts from "fusioncharts";
import ReactFusioncharts from "react-fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";

ReactFusioncharts.fcRoot(FusionCharts, charts, candyTheme);

const CustomerDashboard = () => {


  let now = new Date();
  let startt = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
  let endd = moment(startt).add(1, "days").subtract(1, "seconds");
  const [accordianState, setAccordianState] = useState(false)
  const [oneTime, setoneTime] = useState(true)
  const [oneTime1, setoneTime1] = useState(true)
  const [siteFile, uploadedFile] = useState();
  const [remarks, setRemarks] = useState();
  const [warchangeWeek, setvwarchangeWeek] = useState("");
  const [warWeek, setwarWeekName] = useState("Year");
  const [changeWeek, setChangeWeek] = useState();
  const [nochangeWeek, setnoChangeWeek] = useState();
  const [Week, setWeekName] = useState("Year");
  const [adingDate, setadingDate] = useState();
  const [start, setstart] = useState(startt);
  const [end, setend] = useState(endd);
  const [startDate, setStartDate] = useState();
  const [warstartDate, setwarStartDate] = useState();

  const [dateRange, setDateRange] = useState([null, null]);
  const [wardateRange, setwarDateRange] = useState([null, null]);
  const [rangestartDate, rangeendDate] = dateRange;
  const [warrangestartDate, warrangeendDate] = wardateRange;



  let columns = []

  let morethanenew = []
  let lessthannew = []
  let assignvendornew = []
  let rowData = []
  var posts = []
  var new_posts = []
  var tableData_new = {};

  var TotalTicket = "--"
  var piechartdata = [
    ["Task", "Hours per Day"]
  ];

  var piechartoptions = {
    is3D: true,
    backgroundColor: 'transparent',
    responsive: true,

    legend: {
      position: 'right',
      textStyle: { fontSize: 10, color: 'black' },

    },
  };

  var downtable_columns = []
  var downtable_data = []

  var unique_weeks = []
  var vendor = []
  var open = []
  var close = []

  const count = useSelector((state) => {

    const pagRoomData = {
      labels: [],
      CX: [],
      IX: [],
      Troubleshooting: []
    }
    let vendornew = []
    let warvendornew = []
    let opennew = []
    let closenew = []

    let cxnew = []
    let ixnew = []
    let troubleshootingnew = []
    let e911new = []

    let weekks = []
    let ticketsData;
    // console.log(state, "state")
    // if (state.chartState.ttVendorChartData && state.chartState.ttVendorChartData.length > 0) {
    let rowData = state.chartState.ttVendorChartData ? state.chartState.ttVendorChartData.length > 0 ? state.chartState.ttVendorChartData : [] : []
    let warrowData = state.chartState.warRoomCoustomer ? state.chartState.warRoomCoustomer.length > 0 ? state.chartState.warRoomCoustomer : [] : []
    let agingrowData = state.chartState.ttVendorAgingChartData ? state.chartState.ttVendorAgingChartData.length > 0 ? state.chartState.ttVendorAgingChartData : [] : []

    ticketsData = state.chartState.vendorDashboard ? state.chartState.vendorDashboard : {}


    if (oneTime1 == oneTime1) {
      if (agingrowData && agingrowData.length > 0) {
        // setoneTime1(false)
        // console.log("agingrowData", agingrowData)
        agingrowData.map((item) => {

          let inner = item.add
          // console.log("agingrowDatainner", inner)

          if (inner.length == 2) {
            if (inner[0].status == "greater") {
              morethanenew.push(inner[0].count)
            }
            if (inner[0].status == "less") {
              lessthannew.push(inner[0].count)
            }
            if (inner[1].status == "greater") {
              morethanenew.push(inner[1].count)
            }
            if (inner[1].status == "less") {
              lessthannew.push(inner[1].count)
            }
          }
          if (inner.length == 1) {
            if (inner[0].status == "less") {
              lessthannew.push(inner[0].count)
              morethanenew.push(0)
            }
            if (inner[0].status == "greater") {
              morethanenew.push(inner[0].count)
              lessthannew.push(0)
            }
          }
          if (inner.length == 0) {
            morethanenew.push(0)
            lessthannew.push(0)
          }
          assignvendornew.push(item._id.vendor)
        })

      }
    }

    if (rowData) {
      rowData.map((item) => {

        if (1 == 1) {
          // console.log(item.data, "item.data")
          let inner = item.data

          // console.log(inner[0].status == "Close", "inner")
          if (inner.length == 2) {
            console.log(inner[0].count+inner[1].count,(+inner[0].count)+(+inner[1].count), "innerstatus")
            // console.log(inner[0].status, "innerstatus")
            if (inner[0].status == "Open") {
              // console.log(inner[0].count, "innerstatus")
              opennew.push(inner[0].count)
            }
            if (inner[0].status == "Close") {
              console.log(inner[0].count,inner[1].count,(+inner[0].count)+(+inner[1].count), "innerstatus")
              closenew.push(inner[0].count+inner[1].count)
            }

            if (inner[1].status == "Open") {
              // console.log(inner[1].count, "innerstatus")
              opennew.push(inner[1].count)
            }
            if (inner[1].status == "Close") {
              console.log(inner[0].count,inner[1].count,(+inner[0].count)+(+inner[1].count), "innerstatus")
              closenew.push(inner[0].count+inner[1].count)
            }

          }


          if (inner.length == 1) {
            // console.log(inner[0].status, "innerstatus")
            if (inner[0].status == "Open") {
              // console.log(inner[0].count, "innerstatus")
              opennew.push(inner[0].count)
              closenew.push(inner[0].count)
            }
            if (inner[0].status == "Close") {
              // console.log(inner[0].count, "innerstatus")
              closenew.push(inner[0].count)
              opennew.push(0)
            }

          }

          if (inner.length == 0) {

            closenew.push(0)
            opennew.push(0)

          }



          vendornew.push(item._id.vendor)
          // console.log(opennew, closenew, vendornew, "innerdsadaas")



        }
      })
    }

    if (warrowData) {
      warrowData.map((item) => {
        // console.log(item, "warrowData")

        if (1 == 1) {
          // console.log(item.data, "item.data")
          let inner = item.data

          // console.log(inner.length, "lengthwarrowData")
          if (inner.length == 4) {
            console.log(inner, "innerwarrowData")
            inner.map((inthe) => {
              // console.log("inthe.status", inthe.status)
              if (inthe.status == "CX") {
                cxnew.push(inthe.count)
              }
              if (inthe.status == "IX") {
                ixnew.push(inthe.count)
              }
              if (inthe.status == "Troubleshooting") {
                troubleshootingnew.push(inthe.count)
              }
              if (inthe.status == "E911") {
                e911new.push(inthe.count)
              }
            })
          }

          if (inner.length == 3) {
            // console.log(inner, "innerwarrowData")
            inner.map((inthe) => {
              // console.log("inthe.status", inthe.status)
              let cxLocal, ixLocal, tlocal, enlocal = 0;
              if (inthe.status == "IX") {
                cxnew.push(inthe.count)
                cxLocal = 1
              }
              if (inthe.status == "E911") {
                ixnew.push(inthe.count)
                ixLocal = 1
              }
              if (inthe.status == "CX") {
                troubleshootingnew.push(inthe.count)
                tlocal = 1
              }
              if (inthe.status == "Troubleshooting") {
                e911new.push(inthe.count)
                enlocal = 1
              }
              if (enlocal == 0) {
                e911new.push(0)
              }
              if (tlocal == 0) {
                troubleshootingnew.push(0)
              }
              if (ixLocal == 0) {
                ixnew.push(0)
              }
              if (cxLocal == 0) {
                e911new.push(0)
              }

            })
          }

          if (inner.length == 2) {
            // console.log(inner, "innerwarrowData")


            inner.map((inthe) => {
              // console.log("inthe.status", inthe.status)
              let cxLocal, ixLocal, tlocal, enlocal = 0;
              if (inthe.status == "IX") {
                cxnew.push(inthe.count)
                cxLocal = 1
              }
              if (inthe.status == "E911") {
                ixnew.push(inthe.count)
                ixLocal = 1
              }
              if (inthe.status == "CX") {
                troubleshootingnew.push(inthe.count)
                tlocal = 1
              }
              if (inthe.status == "Troubleshooting") {
                e911new.push(inthe.count)
                enlocal = 1
              }
              if (enlocal == 0) {
                e911new.push(0)
              }
              if (tlocal == 0) {
                troubleshootingnew.push(0)
              }
              if (ixLocal == 0) {
                ixnew.push(0)
              }
              if (cxLocal == 0) {
                e911new.push(0)
              }

            })
          }

          if (inner.length == 1) {
            // console.log(inner[0].status, "innerstatus")
            if (inner[0].status == "IX") {
              cxnew.push(0)
              ixnew.push(inner[0].count)
              troubleshootingnew.push(0)
              e911new.push(0)
            }
            if (inner[0].status == "CX") {
              cxnew.push(inner[0].count)
              ixnew.push(0)
              troubleshootingnew.push(0)
              e911new.push(0)
            }
            if (inner[0].status == "E911") {
              cxnew.push(0)
              ixnew.push(0)
              troubleshootingnew.push(0)
              e911new.push(inner[0].count)
            }
            if (inner[0].status == "Troubleshooting") {
              cxnew.push(0)
              ixnew.push(0)
              troubleshootingnew.push(inner[0].count)
              e911new.push(0)
            }

          }

          if (inner.length == 0) {
            cxnew.push(0)
            ixnew.push(0)
            troubleshootingnew.push(0)
            e911new.push(0)
          }



          warvendornew.push(item._id.crewCompanyName)
          // console.log(cxnew, ixnew, troubleshootingnew, e911new, "innerdsadaas")



        }
      })
    }

    const check = ["CX", "IX", "Troubleshooting"]
    if (Array.isArray(warrowData) || false) {
      warrowData.map(data => {
        if (Array.isArray(data?.data) || false) {
          const exists = []
          data?.data.forEach(data => {
            if (data.status !== "E911-CallTest") {
              exists.push(data.status)
            }
          })
          const notExist = check.filter(key => !exists.includes(key) && key)
          notExist.map(key => {
            pagRoomData[key].push(0)
          })

          data.data.forEach(item => {
            if (check.includes(item.status)) {
              pagRoomData[item.status] = Array.isArray(pagRoomData[item.status]) ? [...pagRoomData[item.status], item.count] : [item.count]
            }
          })
        }
        if (!pagRoomData.labels.includes(data?._id?.crewCompanyName)) {
          pagRoomData.labels.push(data._id.crewCompanyName || "")
        }
      })
    }

    // console.log("warrowData => ", warrowData)
    // console.log("pagRoomData => ", pagRoomData)

    for (let i = 1; i <= 52; i++) {
      let weekday = "0" + i.toString()
      if (changeWeek == weekday) {
        weekks.push(<option selected value={i < 10 ? "0" + i.toString() : i.toString()}>{i < 10 ? "0" + i.toString() : i.toString()}</option>)
      } else {
        weekks.push(<option value={i < 10 ? "0" + i.toString() : i.toString()}>{i < 10 ? "0" + i.toString() : i.toString()}</option>)
      }
    }

    return { pagRoomData, opennew, closenew, vendornew, weekks, ticketsData, lessthannew, morethanenew, assignvendornew, cxnew, ixnew, troubleshootingnew, e911new, warvendornew }

  })


  const dispatch = useDispatch()
  function accordianHandler() {
    setAccordianState(!accordianState)
  }


  let ttvardata = {
    series: [
      {
        name: "Scheduled Sites",
        data: count.opennew,
      },
      {
        name: "Login Sites",
        data: count.closenew,
      },
    ],
    options: {
      title: {
        text: "Scheduled Sites Vs Login Sites " + Week + " " + changeWeek,
        align: 'left',
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          fontFamily: undefined,
          color: '#263138'
        },
      },
      chart: {
        // background: 'linear-gradient(135deg, #143b64 0%, #0655a8 100%)',
        background: '',
        dropShadow: {
          enabled: false,
          enabledOnSeries: undefined,
          top: 0,
          left: 0,
          blur: 3,
          color: 'black',
          opacity: 1
        },
        type: "bar",
        height: 250,
        toolbar: {
          show: true,
        },
      },

      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          // endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: true,
      },

      legend: {
        show: true,
        fontSize: "12px",
        fontWeight: 300,

        labels: {
          colors: "#000000",
        },
        position: "bottom",
        horizontalAlign: "center",
        markers: {
          width: 19,
          height: 19,
          strokeWidth: 0,
          radius: 19,
          margin: 1,
          strokeColor: "#fff",
          fillColors: ["#f16722", "#143b64"],
          offsetX: 0,
          offsetY: 0,
        },
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return val ? val.toFixed(0) : ""
          },
          style: {
            colors: "#000000",
          },
        },

      },
      // stroke: {
      //   show: true,
      //   width: 2,
      //   colors: ["black"],

      // },
      xaxis: {
        categories: count.vendornew,
        labels: {
          style: {
            colors: "#000000",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: true,
        },

      },
      // grid: {
      //   yaxis: {
      //     lines: {
      //       show: false
      //     },
      //   },
      // },
      fill: {
        colors: ["#f16722", "#143b64"],
        // backgroundColor:"#ff00ff",
        opacity: 1,
      },
      // tooltip: {
      //   y: {
      //     formatter: function (val) {
      //       return val + " Ticket";
      //     },
      //   },
      // },
    }
  }


  let pagWardata = {
    series: [
      {
        name: "CX",
        data: count.cxnew,
      },
      {
        name: "IX",
        data: count.ixnew,
      },
      {
        name: "Troubleshooting",
        data: count.troubleshootingnew,
      },
    ],
    options: {
      title: {
        text: "Weekly PAG War Room Activity Dashboard " + warWeek + " " + warchangeWeek,
        align: 'left',
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          fontFamily: undefined,
          color: '#263138'
        },
      },
      chart: {
        // background: 'linear-gradient(135deg, #143b64 0%, #0655a8 100%)',
        background: '',
        dropShadow: {
          enabled: false,
          enabledOnSeries: undefined,
          top: 0,
          left: 0,
          blur: 3,
          color: 'black',
          opacity: 1
        },
        type: "bar",
        height: 250,
        toolbar: {
          show: true,
        },
      },

      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          // endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: true,
      },

      legend: {
        show: true,
        fontSize: "12px",
        fontWeight: 300,

        labels: {
          colors: "#000000",
        },
        position: "bottom",
        horizontalAlign: "center",
        markers: {
          width: 19,
          height: 19,
          strokeWidth: 0,
          radius: 19,
          margin: 1,
          strokeColor: "#fff",
          fillColors: ["#f16722", "#143b64", "#999999"],
          offsetX: 0,
          offsetY: 0,
        },
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return val ? val.toFixed(0) : ""
          },
          style: {
            colors: "#000000",
          },
        },

      },
      // stroke: {
      //   show: true,
      //   width: 2,
      //   colors: ["black"],

      // },
      xaxis: {
        categories: count.warvendornew,
        labels: {
          style: {
            colors: "#000000",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: true,
        },

      },
      // grid: {
      //   yaxis: {
      //     lines: {
      //       show: false
      //     },
      //   },
      // },
      fill: {
        colors: ["#f16722", "#143b64", "#999999"],
        // backgroundColor:"#ff00ff",
        opacity: 1,
      },
      // tooltip: {
      //   y: {
      //     formatter: function (val) {
      //       return val + " Ticket";
      //     },
      //   },
      // },
    }
  }

  let agingvardata = {
    series: [
      {
        name: "Less Than 48 Hrs",
        data: count.lessthannew,
      },
      {
        name: "More Than 48 Hrs",
        data: count.morethanenew,
      },
    ],
    options: {
      title: {
        text: "Current Sites Ageing As of " + adingDate,
        align: 'left',
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          fontFamily: undefined,
          color: '#263138'
        },
      },
      chart: {
        // background: 'linear-gradient(135deg, #143b64 0%, #0655a8 100%)',
        background: '',
        dropShadow: {
          enabled: false,
          enabledOnSeries: undefined,
          top: 0,
          left: 0,
          blur: 3,
          color: 'black',
          opacity: 1
        },
        type: "bar",
        height: 250,
        toolbar: {
          show: true,
        },
      },

      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          // endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: true,
      },

      legend: {
        show: true,
        fontSize: "12px",
        fontWeight: 300,

        labels: {
          colors: "#000000",
        },
        position: "bottom",
        horizontalAlign: "center",
        markers: {
          width: 19,
          height: 19,
          strokeWidth: 0,
          radius: 19,
          margin: 1,
          strokeColor: "#fff",
          fillColors: ["#f16722", "#143b64"],
          offsetX: 0,
          offsetY: 0,
        },
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            return val ? val.toFixed(0) : ""
          },
          style: {
            colors: "#000000",
          },
        },

      },
      // stroke: {
      //   show: true,
      //   width: 2,
      //   colors: ["black"],

      // },
      xaxis: {
        categories: count.assignvendornew,
        labels: {
          style: {
            colors: "#000000",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: true,
        },

      },
      // grid: {
      //   yaxis: {
      //     lines: {
      //       show: false
      //     },
      //   },
      // },
      fill: {
        colors: ["#f16722", "#143b64"],
        // backgroundColor:"#ff00ff",
        opacity: 1,
      },
      // tooltip: {
      //   y: {
      //     formatter: function (val) {
      //       return val + " Ticket";
      //     },
      //   },
      // },
    }
  }


  function changeWeekF(e, typ = undefined) {
    if (typ == "no") {
      // console.log(e, "changeWeek")
      setChangeWeek(e)
    } else {
      // console.log(e.target.value, "changeWeek")
      setChangeWeek(e.target.value)
    }
  }

  let percent = "--";
  let type = 0;
  // console.log(count.ticketsData, "count.ticketsData")
  if (count.ticketsData) {
    if (oneTime && count.ticketsData.currentWeek) {

      setoneTime(false)
      // setChangeWeek(count.ticketsData.currentWeek)
      // setvwarchangeWeek(count.ticketsData.currentWeek)
      // changeWeekF(count.ticketsData.currentWeek, "no")
      // console.log(count.ticketsData.currentWeek, "count.ticketsData")
      setadingDate(count.ticketsData.adingDate)
    }


    if (count.ticketsData.yesterdayOpen == 0) {
      percent = count.ticketsData.todayOpen * 100
      // percent = 100
      type = 1
    }
    else if (count.ticketsData.todayOpen == 0) {
      percent = 100
      // percent = 100
      type = 2
    }
    else if (count.ticketsData.todayOpen > count.ticketsData.yesterdayOpen) {

      // percent = (count.ticketsData.todayOpen / count.ticketsData.yesterdayOpen) * 100
      percent = ((count.ticketsData.todayOpen-count.ticketsData.yesterdayOpen) / count.ticketsData.yesterdayOpen) * 100
      type = 1
    }
    else if (count.ticketsData.todayOpen < count.ticketsData.yesterdayOpen) {
      // percent = (count.ticketsData.yesterdayOpen / count.ticketsData.todayOpen) * 
      percent = ((count.ticketsData.yesterdayOpen-count.ticketsData.todayOpen) / count.ticketsData.yesterdayOpen) * 100
      type = 2
    }

    else if (count.ticketsData.todayOpen == count.ticketsData.yesterdayOpen) {
      percent = 0
      percent = 100
      type = 1
    }

    percent = percent.toFixed(2)+"%" 
  }

  // let now = new Date();
  // let start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
  // let end = moment(start).add(1, "days").subtract(1, "seconds");
  let ranges = {
    "Today Only": [moment(start), moment(end)],
    "Yesterday Only": [moment(start).subtract(1, "days"), moment(end).subtract(1, "days")],
    "3 Days": [moment(start).subtract(3, "days"), moment(end)]
  }
  let local = {
    "format": "DD-MM-YYYY HH:mm",
    "sundayFirst": false,
    days: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'So'],
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',],
    fromDate: 'From Date',
    toDate: 'To Date',
    selectingFrom: 'Selecting From',
    selectingTo: 'Selecting To',
    maxDate: 'Max Date',
    close: 'Close',
    apply: 'Apply',
    cancel: 'Cancel'
  }

  function applyCallback(startDate, endDate) {
    this.setState({
      start: startDate,
      end: endDate
    }
    )
  }

  function yearWiseCaller(stdate, from) {
    // console.log(stdate, "yearWiseCaller", "logger")

    let minDate = moment(stdate).startOf("year")
    let maxDate = moment(stdate).endOf("year")
    if (from == warRoomActivity) {
      setvwarchangeWeek(minDate.format("yyyy"))
      setwarWeekName("Year ")
    } else {
      setChangeWeek(minDate.format("yyyy"))
      setWeekName("Year ")
    }
    commonapicaller(minDate, maxDate, from)
  }
  function rangeWiseCaller(updateDate, from) {

    // console.log("setDateRange", updateDate)
    let minDate = moment(updateDate[0])
    if (updateDate[1] != null) {
      let maxDate = moment(updateDate[1])
      commonapicaller(minDate, maxDate, from)
      if (from == warRoomActivity) {
        setvwarchangeWeek(minDate.format("MM/DD/yyyy") + " To " + maxDate.format("MM/DD/yyyy"))
        setwarWeekName("From ")
      } else {
        setChangeWeek(minDate.format("MM/DD/yyyy") + " To " + maxDate.format("MM/DD/yyyy"))
        setWeekName("From ")
      }
    }




    // console.log(dateRange, "yearWiseCaller", "logger")
    // console.log(dateRange, "")
  }
  function quaterWiseCaller(date, from) {
    let minDate = moment(date).startOf("quarter")
    let maxDate = moment(date).endOf("quarter")
    commonapicaller(minDate, maxDate, from)
    if (from == warRoomActivity) {
      setvwarchangeWeek(minDate.format("Q") + ", Year " + minDate.format("yyyy"))
      setwarWeekName("Quarter ")
    } else {
      setChangeWeek(minDate.format("Q") + ", Year " + minDate.format("yyyy"))
      setWeekName("Quarter ")
    }
    // console.log(date, "quaterWiseCaller", "logger")
  }
  function monthWiseCaller(date, from) {
    let minDate = moment(date).startOf("month")
    let maxDate = moment(date).endOf("month")
    if (from == warRoomActivity) {
      setvwarchangeWeek(minDate.format("MMMM") + ", Year " + minDate.format("yyyy"))
      setwarWeekName("Month ")
    } else {
      setChangeWeek(minDate.format("MMMM") + ", Year " + minDate.format("yyyy"))
      setWeekName("Month ")
    }
    commonapicaller(minDate, maxDate, from)
  }
  function weekWiseCaller(nowe, from) {
    // console.log(nowe.target.value, startDate, "nowe", "")
    console.warn("change => ", nowe.target.value)
    let minDate = moment().year(nowe.target.value.split("-")[0]).week(`${+nowe.target.value.split("-")[1].replace("W", "") + 1}`).startOf("week").isoWeekday(1)
    let maxDate = moment().year(nowe.target.value.split("-")[0]).week(`${+nowe.target.value.split("-")[1].replace("W", "") + 1}`).endOf("week").isoWeekday(1)
    // console.log(moment().week, maxDate, "maxDatemaxDate")

    if (from == warRoomActivity) {
      setvwarchangeWeek(nowe.target.value.split("-")[1].replace("W", "") + ", Year " + minDate.format("yyyy"))
      setwarWeekName("Week ")
    } else {
      setChangeWeek(nowe.target.value.split("-")[1].replace("W", "") + ", Year " + minDate.format("yyyy"))
      setWeekName("Week ")
    }
    commonapicaller(minDate, maxDate, from)
  }

  function commonapicaller(apiStartDate, apiEndDate, from) {

    // console.log("logger", apiStartDate.week(), apiStartDate.format("MM/DD/YYYY HH:mm:SS"), apiEndDate.format("MM/DD/YYYY"))
    dispatch(GetChartAction(`${from}`, `?start=${apiStartDate.format("MM/DD/YYYY_hh:mm:ss")}&end=${apiEndDate.format("MM/DD/YYYY_hh:mm:ss")}`))
  }

  // let maxDate = moment(start).add(24, "hour")

  useEffect(() => {

    // console.log("ttCountVendor", ttCountVendor)
    // dispatch(GetChartAction(sitesCountCustomer, ""))
    // dispatch(GetChartAction(dashboardCustomer, ""))
    // dispatch(GetChartAction(sitesAgingCustomer, ""))
    // dispatch(GetChartAction(warRoomActivity, ""))

    // console.log("ttCountVendor", ttCountVendor)
    var currentDateR = moment();
    var weekStart = currentDateR.clone().startOf('isoWeek');
    var weekEnd = currentDateR.clone().endOf('isoWeek');

    dispatch(GetChartAction(`${sitesCountCustomer}`, `?start=${moment().startOf("year").format("MM/DD/YYYY_hh:mm:ss")}&end=${moment().endOf("year").format("MM/DD/YYYY_hh:mm:ss")}`))
    // dispatch(GetChartAction(ttCountVendor, ""))
    dispatch(GetChartAction(dashboardCustomer, ""))
    dispatch(GetChartAction(sitesAgingCustomer, ""))
    setChangeWeek(moment().format("YYYY"))
    setvwarchangeWeek(moment().endOf("year").format('YYYY'))
    // setwarWeekName("Year ")

    dispatch(GetChartAction(warRoomActivity, `?start=${moment().startOf("year").format("MM/DD/YYYY_hh:mm:ss")}&end=${moment().endOf("year").format("MM/DD/YYYY_hh:mm:ss")}`))
  }, [nochangeWeek])

  console.group()
  console.warn(count, "count===>")
  console.groupEnd()

  let pagRoom = {
    chart: {
      
      baseFontSize:"10",
      valueFontSize:10,
      valuePosition:'outside',
      caption: "Weekly PAG War Room Activity Dashboard - " + warWeek + " " + warchangeWeek,
      plottooltext:
        "$label <b>$dataValue</b>",
      theme: "candy",
      captionFontSize: "14",
      drawcrossline: "1",
      dataLabel: '1',
      dataLabelFontSize: '14',
      "seriesNameFontSize": "12",

      dataLabelFontBold: '1',
      dataLabelFontColor: '#ffffff',
      dataLabelPadding: '5',
      dataLabelPlacement: 'top',
      plottooltext: "<b>$label</b><br>Value: $value",
      exportEnabled: true,
      exportAtClient: true,
      exportfilename: "PAG War Room"
    },

    categories: [
      {
        category: Array.isArray(count?.pagRoomData?.labels) ? count?.pagRoomData?.labels?.map(data => { return { label: data?.replace('Tower Team', 'TT'), "labelFontSize": "10" } }) : []
      }
    ],
    dataset: [
      {
        seriesname: "CX",

        data: Array.isArray(count?.pagRoomData?.CX) ? count?.pagRoomData?.CX?.map(data => { return { value: data, showValue: "1" } }) : []
      },

      {
        seriesname: "IX",

        data: Array.isArray(count?.pagRoomData?.IX) ? count?.pagRoomData?.IX.map(data => { return { value: data, showValue: "1" } }) : []
      },

      {
        seriesname: "Troubleshooting",

        data: Array.isArray(count?.pagRoomData?.Troubleshooting) ? count?.pagRoomData?.Troubleshooting.map(data => { return { value: data, showValue: "1" } }) : []
      },
    ]
  }

  let loginSites = {
    chart: {
      
      baseFontSize:10,
      labelFontSize:2,
      caption: "Scheduled Sites Vs Login Sites - " + Week + " " + changeWeek,
      dataLabel: '1',
      captionFontSize: 14,
      dataLabelFontSize: '14',
      dataLabelFontBold: '1',
      dataLabelFontColor: '#ffffff',
      dataLabelPadding: '5',
      dataValueFontSize:"5",
      valueFontSize:10,
      valuePosition:'outside',
      "seriesNameFontSize": "12",

      dataLabelPlacement: 'top',
      plottooltext: "<b>$label</b><br>Value: $value",
      theme: "candy",
      drawcrossline: "1",
      exportEnabled: true,
      exportAtClient: true,
      exportfilename: "Scheduled Sites Vs Login Sites"
    },

    categories: [
      {
        category: Array.isArray(count?.vendornew) ? count?.vendornew.map(data => { return { label: data?.replace('Tower Team', 'TT'), "labelFontSize": "10" } }) : [],
      }
    ],
    dataset: [
      {
        seriesname: "Scheduled Sites",

        data: Array.isArray(count?.closenew) ? count?.closenew.map(data => { return { value: data, showValue: "1" } }) : [],
      },
      {
        seriesname: "Login Sites",
        color: "#f48778",
        data: Array.isArray(count?.opennew) ? count?.opennew.map(data => { return { value: data, showValue: "1" } }) : [],
      },
    ]
  }

  console.log("count => ", count)

  return (
    <>
      {/* <div style={{ columns : "2" , columnCount : "1rem"}}> */}
      <div className='mb-4' style={{ display: "grid", gridTemplateColumns: "repeat(2,50%)", gridTemplateRows: "repeat(5,180px)" }}>

        <div style={{ gridColumn: "span 1", gridRow: "span 3", padding: "0rem 0.4rem" }}>
          <div className="ticket-bx" style={{ paddingLeft: "1rem", height: "82%", background: 'rgb(38, 42, 51)', padding: "1.5rem" }}>
            <div className="card-body" style={{ padding: "0.875rem", background: 'rgb(38, 42, 51)' }}>
              <div className="d-sm-flex d-block pb-sm-3 align-items-end">

                <div className="mr-auto pr-3 mb-2 mb-sm-0">
                  <span className="text-white d-block mb-sm-3 mb-2" style={{ fontSize: "17px " }}>Login Today Sites</span>
                  <h2 className="text-white mb-0" style={{ fontSize: "16px " }}>{count.ticketsData ? count.ticketsData.todayOpen : "--"}<span className="fs-18 ml-2">Sites</span></h2>
                </div>


                

                <div className="d-flex flex-wrap ftsize">
                  {type == 1 ? <TrendingUpIcon fontSize='inherit' /> : ""}
                  {type == 2 ? <TrendingDownIcon fontSize='inherit' /> : ""}
                  {console.log(typeof(percent),"typeopercent")}
                  <div className="ml-3">
                    <p className="text-warning fs-22 mb-0">{percent}</p>
                    <span className="fs-13">than last day</span>
                  </div>
                </div>
              </div>
              <div className="mr-auto pr-3 mb-2 mb-sm-0">
                  <span className="text-white d-block mb-sm-3 mb-2" style={{ fontSize: "17px " }}>Login Yesterday Sites</span>
                  <h2 className="text-white mb-0" style={{ fontSize: "16px " }}>{count.ticketsData ? count.ticketsData.yesterdayOpen : "--"}<span className="fs-18 ml-2">Sites</span></h2>
                </div>
              <div className="progress mt-3 mb-3" style={{ height: "20px" }}>
                <div className="progress-bar-striped progress-bar-animated" style={{ width: "86%", height: "100%" }} role="progressbar">
                  <span className="sr-only">86% Complete</span>
                </div>
              </div>
              {/* <p className="fs-12">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad mini</p> */}
              {/* <Link to={"#"} className="text-white">View detail<i className="las la-long-arrow-alt-right scale5 ml-3"></i></Link> */}
            </div>
            <div className="card-body" style={{ padding: "0.875rem" }}>
              <div className="d-sm-flex d-block pb-sm-3 align-items-end">
                <div className="mr-auto pr-3 mb-2 mb-sm-0">
                  <span className="text-white d-block mb-sm-3 mb-2" style={{ fontSize: "17px " }}>Scheduled Sites</span>
                  <h2 className="text-white mb-0" style={{ fontSize: "16px " }}>{count.ticketsData ? count.ticketsData.totalOpen : "--"}<span className="fs-16 ml-2">Sites</span></h2>
                </div>

              </div>
            </div>
          </div>

        </div>

        <div style={{ gridColumn: "span 1", gridRow: "span 3" }}>

          <div className='form-group row' style={{ background: 'rgb(38, 42, 51)', color: "white" }}>
            {/* <label className='mx-2'>Week</label>
      <select onChange={(e) => changeWeekF(e)} style={{ width: '20%' }}>
        {count.weekks}
      </select>
       */}

            {/* <DateTimeRangeContainer

          ranges={ranges}
          start={start}
          end={end}
          local={local}
          maxDate={maxDate}
          style={{display: 'flex', maxWidth: 'fit-content' }}
          applyCallback={applyCallback}
        >
          <FormControl
            id="formControlsTextB"
            type="text"
            label="Text"
            placeholder="Enter text"
          />
        </DateTimeRangeContainer> */}

            {/* <label className='mx-2'>Week</label>
                <select onChange={(e) => changeWeekF(e)} style={{ width: '20%' }}>
                  {count.weekks}
                </select>
                 */}
            {/* <div>
                  <DateTimeRangeContainer
                    smartMode={true}
                    noMobileMode={true}
                    ranges={ranges}
                    start={start}
                    end={end}
                    local={local}
                    maxDate={maxDate}
                    style={{ display: 'flex' }}
                    applyCallback={applyCallback}
                  >
                    <FormControl
                      id="formControlsTextB"
                      type="text"
                      label="Text"
                      placeholder="Enter text"
                    />
                  </DateTimeRangeContainer>
                </div> */}
            <div style={{"flex-basis":'10%'}}>
              <label style={{ color: "white" }}>Year</label>
              <DatePicker
                selected={warstartDate}
                onChange={(date) => {
                  setwarStartDate(date)
                  yearWiseCaller(date, warRoomActivity)
                }}
                showYearPicker
                placeholderText='Select'
                dateFormat="yyyy"
                className='newFormatting'

              />
            </div>
            <div style={{"flex-basis":'15%'}}>
              <label style={{ color: "white" }}>Quarter</label>
              <DatePicker
                selected={warstartDate}
                onChange={(date) => {
                  setwarStartDate(date)
                  quaterWiseCaller(date, warRoomActivity)
                }}
                dateFormat="yyyy, QQQ"
                placeholderText='Select'
                showQuarterYearPicker
                className='newFormatting'
              />

            </div>

            <div style={{"flex-basis":'15%'}}>
              <label style={{ color: "white" }}>Month</label>
              <DatePicker
                selected={warstartDate}
                onChange={(date) => {
                  setwarStartDate(date)
                  monthWiseCaller(date, warRoomActivity)
                }}
                dateFormat="MMM/yyyy"
                placeholderText='Select'
                showMonthYearPicker
                className='newFormatting'
              />


            </div>


            <div style={{"flex-basis":'25%'}}>
              <label style={{ color: "white" }}>Week</label>

              <div class="react-datepicker-wrapper">
                <div class="react-datepicker__input-container">

                  <input type='week'
                    className={'newFormatting'}
                    
                    placeholder='Select Week'
                    style={{ height: "30px" }}
                    // onFocus={(this.type-'week')}
                    // onBlu={(this.type-'text')}
                    format={"DD"}
                    onChange={(date) => {
                      weekWiseCaller(date, warRoomActivity)
                    }}
                  />
                </div>
              </div>

            </div>

            <div style={{"width":'35%'}}>
              <label style={{ color: "white" }}>Select Date</label>
              <DatePicker
                selectsRange={true}
                placeholderText={"Select Date"}
                startDate={warrangestartDate}
                endDate={warrangeendDate}
                className='newFormatting'
                onChange={(update) => {
                  setwarDateRange(update);
                  rangeWiseCaller(update, warRoomActivity)
                }}
              />

            </div>

          </div>

          <div className="mixed-chart ">
            {/* <Chart
                  options={pagWardata.options}
                  series={pagWardata.series}
                  type="bar"
                  height={300}
                /> */}
            <ReactFusioncharts
              type="mscolumn2d"
              width="100%"
              height="262"
              dataFormat="JSON"
              dataSource={pagRoom}
            />
          </div>
          <div>
            <table style={{ width: "100%" }} className="chartTable">
              <thead>
                <tr>
                  <td style={{ fontSize: "11px", border: "1px", borderTop: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{"Activity"}</td>
                    {count?.warvendornew?.length ? count?.warvendornew?.map(data => <td style={{ borderTop: "1px", fontSize: "11px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data?data.replace("Tower Team", "TT") : " "}</td>) : ["DIY", "DIY - TT", "Neticom", "Neticom - TT", "Nokia", "Nokia - TT"].map(data => <td style={{ fontSize: "11px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data || " "}</td>)}
                </tr>
              </thead>
              <tbody>
                <tr style={{ border: "1px", borderStyle: "solid", borderColor: "rgb(38, 42, 51)" }}>
                  <td style={{ fontSize: "11px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>CX</td>
                  {count?.pagRoomData?.CX?.length ? count?.pagRoomData?.CX?.map(data => <td style={{ fontSize: "11px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data || 0}</td>) : Array(6).fill(" ").map(data => <td style={{ fontSize: "10px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data}</td>)}
                </tr>
                <tr style={{ border: "1px", borderStyle: "solid", borderColor: "rgb(38, 42, 51)" }}>
                  <td style={{ fontSize: "11px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>IX</td>
                  {count?.pagRoomData?.IX?.length ? count?.pagRoomData?.IX?.map(data => <td style={{ fontSize: "11px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data || 0}</td>) : Array(6).fill(" ").map(data => <td style={{ fontSize: "10px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data}</td>)}
                </tr>
                <tr style={{ border: "1px", borderStyle: "solid", borderColor: "rgb(38, 42, 51)" }}>
                  <td style={{ fontSize: "11px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>Trouble Shooting</td>
                  {count?.pagRoomData?.Troubleshooting?.length ? count?.pagRoomData?.Troubleshooting?.map(data => <td style={{ fontSize: "11px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data || 0}</td>) : Array(6).fill(" ").map(data => <td style={{ fontSize: "10px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data}</td>)}
                </tr>
              </tbody>
            </table>

          </div>
        </div>

        <div style={{ gridColumn: "span 1", gridRow: "span 3", marginTop: "-5rem", padding: "0rem 0.4rem" }}>
          {/* <div className='d-flex justify-content-end' style={{ background: 'rgb(38, 42, 51)', color: "white", padding: "10px", paddingBottom: "0px" }}>
            <div style={{ cursor: "pointer", background: "black", width: "38px", height: "38px", borderRadius: "50%", padding: "8px" }}>
              <svg fill='white' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M4 19H20V12H22V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V12H4V19ZM14 9H19L12 16L5 9H10V3H14V9Z"></path></svg>
            </div>
          </div> */}
          <div className='form-group row' style={{ background: 'rgb(38, 42, 51)', color: "white" }}>
            {/* <label className='mx-2'>Week</label>
                <select onChange={(e) => changeWeekF(e)} style={{ width: '20%' }}>
                  {count.weekks}
                </select>
                 */}
            {/* <div>
                  <DateTimeRangeContainer
                    smartMode={true}
                    noMobileMode={true}
                    ranges={ranges}
                    start={start}
                    end={end}
                    local={local}
                    maxDate={maxDate}
                    style={{ display: 'flex' }}
                    applyCallback={applyCallback}
                  >
                    <FormControl
                      id="formControlsTextB"
                      type="text"
                      label="Text"
                      placeholder="Enter text"
                    />
                  </DateTimeRangeContainer>
                </div> */}
                
            <div style={{"flex-basis":'10%'}}>
              <label style={{ color: "white" }}>Year</label>
              <DatePicker
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date)
                  yearWiseCaller(date, sitesCountCustomer)
                }}
                showYearPicker
                placeholderText='Select'
                dateFormat="yyyy"
                className='newFormatting'

              />
            </div>
            
            <div style={{"flex-basis":'15%'}}>
              <label style={{ color: "white" }}>Quarter</label>
              <DatePicker
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date)
                  quaterWiseCaller(date, sitesCountCustomer)
                }}
                dateFormat="yyyy, QQQ"
                placeholderText='Select'
                className='newFormatting'
                showQuarterYearPicker
              />

            </div>

            <div style={{"flex-basis":'15%'}}>
              <label style={{ color: "white" }}>Month</label>
              <DatePicker
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date)
                  monthWiseCaller(date, sitesCountCustomer)
                }}
                dateFormat="MMM/yyyy"
                placeholderText='Select'
                className='newFormatting'
                showMonthYearPicker
              />


            </div>


            <div style={{"flex-basis":'25%'}}>
              <label style={{ color: "white" }}>Week</label>

              <div class="react-datepicker-wrapper">
                <div class="react-datepicker__input-container">

                  <input type='week'
                    placeholder='Select Week'
                    style={{ height: "30px" }}
                    className='newFormatting'
                    onChange={(date) => {
                      weekWiseCaller(date, sitesCountCustomer)
                    }}
                  />
                </div>
              </div>

            </div>


            <div style={{"flex-basis":'35%'}}>
              <label style={{ color: "white" }}>Select Date</label>
              <DatePicker
                selectsRange={true}
                placeholderText={"Select Date"}
                startDate={rangestartDate}
                className='newFormatting'
                endDate={rangeendDate}
                onChange={(update) => {
                  setDateRange(update);
                  rangeWiseCaller(update, sitesCountCustomer)
                }}
              />
            </div>
          </div>
          <div className="mixed-chart ">
            {/* <Chart
                  options={ttvardata.options}
                  series={ttvardata.series}
                  type="bar"
                  height={300}
                /> */}
            <ReactFusioncharts
              type="mscolumn2d"
              width="100%"
              height="250"
              dataFormat="JSON"
              dataSource={loginSites}
            />
            <div>
              <table style={{ width: "100%" }} className="chartTable">
                <thead>
                  <tr>
                    <td style={{ background: "rgb(38, 42, 51)", fontSize: "11px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{"Activity"}</td>
                    {count?.vendornew?.length ? count?.vendornew?.map(data => <td style={{ fontSize: "11px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data?data.replace("Tower Team", "TT") : " "}</td>) : ["DIY", "DIY - TT", "Neticom", "Neticom - TT", "Nokia", "Nokia - TT"].map(data => <td style={{ fontSize: "11px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data || " "}</td>)}
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ border: "1px", borderStyle: "solid", borderColor: "rgb(38, 42, 51)" }}>
                    <td style={{ fontSize: "11px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>Login Sites</td>
                    {count?.opennew?.length ? count?.opennew?.map(data => <td style={{ fontSize: "11px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data}</td>) : Array(6).fill(" ").map(data => <td style={{ fontSize: "10px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data}</td>)}
                  </tr>
                  <tr style={{ fontSize: "11px", border: "1px", borderStyle: "solid", borderColor: "rgb(38, 42, 51)" }}>
                    <td style={{ fontSize: "11px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>Schedules Sites</td>
                    {count?.closenew?.length ? count?.closenew?.map(data => <td style={{ border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data}</td>) : Array(5).fill(" ").map(data => <td style={{ fontSize: "10px", border: "1px", borderColor: "black", borderStyle: "solid", background: "rgb(38, 42, 51)", color: "white", display: "table-cell" }}>{data}</td>)}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* <div className="col-xl-6 col-md-6 col-lg-6 boxcol">
          <div className="card border card-border-blue">
            <div className="card-body card-body-custom">
              <div className='form-group d-flex justify-content-start'>
                {/* <label className='mx-2'>Week</label>
            <select onChange={(e) => changeWeekF(e)} style={{ width: '20%' }}>
              {count.weekks}
            </select> */}

        {/* <div className='row row-custom'>
                  <div className='col-4 one-line-col1 pd-0'>
                    <DatePicker
                      selectsRange={true}
                      startDate={rangestartDate}
                      endDate={rangeendDate}
                      onChange={(update) => {
                        setDateRange(update);
                        rangeWiseCaller(update, ttAgingVendor)
                      }}
                    />

                  </div>
                  <div className='col-2 one-line-col2 pd-0'>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(date)
                        yearWiseCaller(date, ttAgingVendor)
                      }}
                      showYearPicker
                      dateFormat="yyyy"

                    />
                  </div>
                  <div className='col-3 one-line-col3 pd-0'>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(date)
                        quaterWiseCaller(ttAgingVendor)
                      }}
                      dateFormat="yyyy, QQQ"
                      showQuarterYearPicker
                    />

                  </div>

                  <div className='col-3 one-line-col4 pd-0'>

                    <DatePicker
                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(date)
                        monthWiseCaller(ttAgingVendor)
                      }}
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                    />
                  </div>


                  <div className='col-2 one-line-col5 pd-0'>
                    <select onChange={(e) => {
                      changeWeekF(e)
                      weekWiseCaller(ttAgingVendor)
                    }}>
                      {count.weekks}
                    </select>

                  </div>

                </div> 
              </div>
             <div className="mixed-chart ">

                <Chart
                  options={agingvardata.options}
                  series={agingvardata.series}
                  type="bar"
                  height={375}
                />


              </div>
            </div>
          </div>
        </div> */}
      </div>

      
        <div style={{visibility:"hidden"}}>
        {setTimeout(()=>{document.querySelectorAll("[pointer-events='bounding-box']").forEach(data=>data.style.display="none")},2000)}
        </div>  
    {/* {setTimeout(()=>{document.querySelectorAll("[pointer-events='bounding-box']").forEach(data=>data.style.display="none")},2000)} */}
    </>
  )
}

export default CustomerDashboard