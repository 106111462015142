import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { addAcccount, addFDatatoran, addLockUnlockData, addRiotData, allTechnologyApi, getAllDropDown, getAllDropDownList, goNoGoAction, managEngineer, managTMOEngineer, rfApprovalActionChange } from '../../../../common/config';
import { addAcccountReducerAction, TierListReducerAction } from '../../../../store/actions/createTicketManageFields';
import { MultiSelect } from 'react-multi-select-component';
import Add from '../../../../icons/Svg/Add';
import Select from 'react-select'
import classnames from 'classnames';
// import { addAcccountReducerAction, updateAcccountReducerAction } from '../../../components/vendor/'
const LockUnlockModalBody = (props) => {

  const { register, setValue, handleSubmit, getValues, watch, reset, formState: { errors } } = useForm({})
  const dispatch = useDispatch();
  let stdata=[]
  const bandoptions = [{ 'label': 'L600', 'value': 'L600' }, { 'label': 'N600', 'value': 'N600' }, { 'label': 'L700', 'value': 'L700' }, { 'label': 'L1900', 'value': 'L1900' }, { 'label': 'L1900 - 2', 'value': 'L1900 - 2' }, { 'label': 'N1900', 'value': 'N1900' }, { 'label': 'L2100', 'value': 'L2100' }, { 'label': 'L2100 - 3', 'value': 'L2100 - 3' }, { 'label': 'L2500', 'value': 'L2500' }, { 'label': 'L2500_2', 'value': 'L2500_2' }, { 'label': 'L2500_3', 'value': 'L2500_3' }, { 'label': 'N2500', 'value': 'N2500' }, { 'label': 'NB-IoT', 'value': 'NB-IoT' }]

  console.log(props.oldData)
  
  console.log(props.oldData,"props.oldData")

  let sectorsonl = {
      "A": "Alpha",
      "B": "Beta",
      "G": "Gamma",
      "D": "Delta",
      "E": "Epsilon",
      "Z": "Zeta"
    }



  var firstVal="Unlock"
  if(props.apiType=="response"){
    props.oldData.map((itt)=>{
      console.log(itt,"ittittitt")
      itt.lock_unlock_data.map((oneVall)=>{
        console.log(oneVall,"oneVall")
        

        let letPassedS=oneVall.sector.split("").map((onnn)=>{
          return {
            label:sectorsonl[onnn],
            value:onnn
          }
        })
        stdata.push({
          passedS: letPassedS,
          tech:{
            value:oneVall.tech,
            label:oneVall.tech
          }
        })
      })
      // itt.lock_unlock_data.map((oneval)=>{
      //   stdata.push({
      //     "tech":oneval.tech
      //   })
      // })
    firstVal=itt.lock_unlock_status
    })
    // setluvalue(firstVal)
    // console.log(props.oldData.lock_unlock_data,"props.oldData.lock_unlock_data")

    
    

  }else{
    stdata=[{
      tech: "",
      passedS: [],
    }]
  }

  console.log(firstVal,"firstValfirstVal")
  const [luvalue, setluvalue] = useState(firstVal);

  // if()
  let [selectProjectCode, setProjectCode] = useState(stdata)

  const [EngineerOpen, setEngineerOpen] = useState([]);
  const [siteFile, uploadedFile] = useState([]);
  


  console.log(props, "propspropspropsprops")

  if (props.type == "Edit") {
    // setValue("value",props.oldData.optionValue) 
  }

  let engineerArray = [];

  let sectors = [
    {
      label: "Alpha",
      value: "A"
    }, {
      label: "Beta",
      value: "B"
    }, {
      label: "Gamma",
      value: "G"
    }, {
      label: "Delta",
      value: "D"
    }, {
      label: "Epsilon",
      value: "E"
    }, {
      label: "Zeta",
      value: "Z"
    }
  ]


  const projectData = useSelector((state) => {
    console.log("the State is=", state);

    if(props.apiType=="response"){
      console.log(props,"propspropsprops")
    }



    let tier = state.CommonResponse.listtier
    if (tier && tier.length != 0) {
      engineerArray = tier.map((item) => {
        return <option>{item.name}</option>;
      })
    }
    return { engineerArray };
  });


  const findengineer = (id) => {
    setEngineerOpen(id)
  }

  const onSubmit = async (data) => {
    console.log("the data to submit is =", data,selectProjectCode)

    // data["dropDownId"]=props.parentuniqueId

    let final_data = []
    selectProjectCode.map((itt) => {
      let miss = itt.passedS.map(oneitt => {
        return oneitt.value
      })

      let tech = itt.tech.label
      
      final_data.push({
        "tech": tech,
        "sector": miss.join("")
      })
      console.log("the data to submit is =", final_data)

    })
    console.log(selectProjectCode, "data")

    let addThis = {
      "siteId": props.siteIdData,
      "parent_id": props.ranIdData,
      "lock_unlock_status":luvalue,
      "lock_unlock_data": final_data
    }


    let newform = new FormData();

    
    newform.append("siteId", props.siteIdData)
    newform.append("parent_id", props.ranIdData)
    newform.append("lock_unlock_status", luvalue)
    newform.append("form_type", props.apiType)
    newform.append("lock_unlock_data", JSON.stringify(final_data))




    console.log(newform, "addThisaddThisaddThis")

    if(props.apiType=="request"){
      dispatch(addAcccountReducerAction(addLockUnlockData, newform))
    }else{
      for (let i = 0; i < siteFile.length; i++) {
        newform.append("all-files", siteFile[i]);
      }
  
      dispatch(addAcccountReducerAction(addLockUnlockData+"/"+props.LU_Id, newform))
  
    }
    // if(props.type=="Add"){
    // dispatch(addAcccountReducerAction(addFDatatoran + "/" + props.unique_id_up, data))
    // }
    // if(props.type=="Edit"){
    //   dispatch(updateAcccountReducerAction(getAllDropDownList,props.oldData.uniqueId,data))
    // }




    // propsprops

    // reset({})
    // setValue("value",'')
  }

  // let sectors = [
  //   {
  //     label: "Alpha",
  //     value: "A"
  //   }, {
  //     label: "Beta",
  //     value: "B"
  //   }, {
  //     label: "Gamma",
  //     value: "G"
  //   }, {
  //     label: "Delta",
  //     value: "D"
  //   }, {
  //     label: "Epsilon",
  //     value: "E"
  //   }, {
  //     label: "Zeta",
  //     value: "Z"
  //   }
  // ]

  const AddTech = () => {
    // setTechnologyError("");
    console.log("calltoAddTech")
    setProjectCode([...selectProjectCode, {
      tech: "",
      passedS: []
    }])
  };
  
  const LockUnlockFun = (e) => {
    setluvalue(e)

    // console.log(e,"LockUnlockFun")
    // let sec = [...selectProjectCode]
    // sec[idx]["lockUnlock"] = e
    // setProjectCode(sec)
    // setTechnologyError("");
    // console.log(e,passedSector)
  };

  const BandSector = (e, idx) => {
    // setTechnologyError("");
    // console.log(e,passedSector)
    let sec = [...selectProjectCode]
    sec[idx]["tech"] = e
    setProjectCode(sec)
  };
  
  const passedSector = (e, idx) => {
    // setTechnologyError("");
    // console.log(e,passedSector)
    let sec = [...selectProjectCode]
    let selected = e.map(oneit => {
      return oneit.label
    })
    let nwsec = []
    sectors.map((ittm) => {
      if (selected.indexOf(ittm.label) != -1) {
        nwsec.push({
          label: ittm.label,
          value: ittm.value
        })
      }
    })
    sec[idx]["passedS"] = nwsec
    setProjectCode(sec)
  };



  const uploadCsvFile = (e) => {
    // let files=[]
    console.log(e.target.files, "files")
    // uploadedFile(e.target.files[0])

    let files = []

    console.log(e.target.files[0], "ON_CHANGE_FILES:", e.target.files.length);

    for (let i = 0; i < e.target.files.length; i++) {
      files.push(e.target.files[i])
    }
    console.log(...files)
    uploadedFile(files)

  };
  useEffect(() => {
    // reset({})
    // setValue("value",'')
    dispatch(TierListReducerAction(managEngineer))
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-row">
        <div className="form-group col-md-4">
          <label htmlFor="inputEmail4">SiteId</label>
          <input style={{ height: "40px" }}
            type="text"
            className="form-control"
            id="inputEmail4"
            name='siteid'
            value={props.siteIdData}
          />
        </div>
        

        <div className="form-group col-md-4">
        <label htmlFor="inputEmail4">Lock/Unlock</label>
            <br/>
            <div
              className="btn-group"
              data-toggle="buttons" >
              <label className={luvalue == 'Lock'?"btn btn-outline-primary light btn-sm active":"btn btn-outline-primary light btn-sm"}
                  onClick={() => { LockUnlockFun('Lock'); }}>
                <input
                  type="radio"
                  // {luvalue == 'Lock'?"":""}
                  // className= {`'${luvalue == 'Lock'?"active":""} position-absolute invisible'`}
                  // className= {`'${luvalue == 'Lock'?"active":""} position-absolute invisible'`}
                  className={"position-absolute invisible"}
                  name="options"
                  id="option5"
                />
                Lock
              </label>
              <label className={luvalue == 'Lock'?"btn btn-outline-primary light btn-sm":"btn btn-outline-primary light btn-sm active"}
                  onClick={() => { LockUnlockFun('Unlock'); }}>
                <input
                  type="radio"
                  // {luvalue == 'Lock'?"":""}
                  // className= {`'${luvalue == 'Lock'?"active":""} position-absolute invisible'`}
                  // className= {`'${luvalue == 'Lock'?"active":""} position-absolute invisible'`}
                  className={"position-absolute invisible"}
                  name="options"
                  id="option5"
                />
                Unlock
              </label>
              
            </div>
        </div>

        
        {props.apiType=="response"?<><div className="form-group col-md-4">
          <label htmlFor="inputEmail4">Logs File</label>
          <div>
            <label class="custom-file-upload btn btn-outline-primary col-md-6">
              <input
                type="file"
                className="form-control btn btn-primary"
                id="inputGroupFile04"
                aria-describedby="inputGroupFileAddon04"
                aria-label="Upload"
                name="file"
                multiple
                onChange={uploadCsvFile}
              />
              <i class="fa fa-cloud-upload"></i> Choose File
            </label>

            <label>{siteFile.length != 0 ? siteFile.length : "No"} File Selected</label>


          </div>
        </div></>:""}
      </div>

      {/* <div onClick={() => AddTech()}>
          <p className={'badge badge-primary fitContent'}><Add /></p>
        </div> */}
      {console.log(selectProjectCode, "selectProjectCode")}
      {selectProjectCode.map((oneitm, index) => {

        { console.log(selectProjectCode, "selectProjectCodeoneitm", oneitm,oneitm.passedS, oneitm.failedS) }
        return <div className="form-row">
          <div className="form-group col-md-3">
            <label htmlFor="inputEmail4">Band</label>
            <Select
              className="text-dark"
              options={bandoptions}
              onChange={(e) => {
                BandSector(e, index)
              }}
              value={oneitm.tech}
            />
          </div>

          <div className="form-group col-md-3">
            <label htmlFor="inputEmail4">Sector</label>
            <MultiSelect
              className="text-dark"
              hasSelectAll={false}
              options={sectors}

              onChange={(e) => {
                passedSector(e, index)
              }}
              value={oneitm.passedS}
            />
          </div>

          <div className="form-group col-md-3">
            
            
          </div>



          {
            selectProjectCode.length - 1 == index ?
              <>
                <div className="form-group col-md-2">
                  <label htmlFor="inputEmail4">Add</label>
                  <div onClick={() => AddTech()}>
                    <p className={'badge badge-primary fitContent'}><Add /></p>
                  </div>
                </div>
              </> : ""
          }


        </div>
      })}



      <button type="submit" className="btn btn-primary btn-xs mt-3">
        Submit
      </button>
    </form>
  )
}

export default LockUnlockModalBody