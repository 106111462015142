import React from 'react'
import { useForm } from 'react-hook-form'

const RSSIVSWR = () => {
    const {register, handleSubmit, formState: { errors }} =useForm()
    const onSubmit=(data)=>{
        console.log("hnsdkjbfksalcbsdvcs  =",data)
    }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
        
        <div className='form-group'>
            <lable style={{color:"black"}}>VSWR RANGE BOTTOM</lable>
            <input type='text' className='form-control' style={{height:"32px"}} {...register("VSWRRangeBottom")} />
        </div>
        <div className='form-group'>
            <lable style={{color:"black"}}>VSWR RANGE TOP</lable>
            <input type='text' className='form-control' style={{height:"32px"}} {...register("VSWRRangeTop")} />
        </div>
        <div className='form-group'>
            <lable style={{color:"black"}}>VSWR DELTA</lable>
            <input type='text' className='form-control' style={{height:"32px"}} {...register("VSWRDelta")} />
        </div>
        <div className='form-group'>
            <lable style={{color:"black"}}>RSSI min</lable>
            <input type='text' className='form-control' style={{height:"32px"}} {...register("RSSIMin")} />
        </div>
        <div className='form-group'>
            <lable style={{color:"black"}}>Rx/Tx Loss</lable>
            <input type='text' className='form-control' style={{height:"32px"}} {...register("RxTxLoss")} />
        </div>
        <div className='form-group'>
            <lable style={{color:"black"}}>TXdBm/RXdBm</lable>
            <input type='text' className='form-control' style={{height:"32px"}} {...register("TxdbnRxdbn")} />
        </div>
        <div className='form-group'>
            <lable style={{color:"black"}}>TXbs</lable>
            <input type='text' className='form-control' style={{height:"32px"}} {...register("Txbs")} />
        </div>
        <div className='form-group'>
            <lable style={{color:"black"}}>BER</lable>
            <input type='text' className='form-control' style={{height:"32px"}} {...register("Ber")} />
        </div>
        <div className='form-group'>
            <lable style={{color:"black"}}>UMTS-RSSI min</lable>
            <input type='text' className='form-control' style={{height:"32px"}} {...register("UMTSRSSIMin")} />
        </div>
        <div className='form-group'>
            <lable style={{color:"black"}}>UMTS-RSSI max</lable>
            <input type='text' className='form-control' style={{height:"32px"}} {...register("UMTSRSSIMax")} />
        </div>
        <button type='submit' className="btn btn-primary btn-xs py-2 px-4 mt-2" >Submit</button>
    </form>
  )
}

export default RSSIVSWR