import React, { Fragment, useState, useEffect } from "react";
import "../../../../css/customStyle.css";
import { SplitButton, ButtonGroup, Dropdown, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import { useForm } from "react-hook-form";
import { NetworkDatabaseAction } from "../../../../store/actions/dashboardActions";
import "../../../../css/formError.css";
import moment from "moment"
import DataList from 'react-datalist-field'
import ReactHTMLDatalist from "react-html-datalist";
import { addCrNumber, allTechnologyApi, listSiteId, listUserApi_two, managEngineer, managTMOEngineer, networkOneSiteDatabaseprojectId, OneSiteDatabase, OneSiteDatabaseProjectCode, projectCode, ranAddSupport, ranOneSiteDatabaseprojectId, ranOneSiteProjectCode, singleProjectApi, updateranticket } from "../../../../common/config";
import { AccountReducerAction, addUserAction, popUpUpdate, TierListReducerAction, UserListReducerAction } from "../../../../store/actions/createTicketManageFields";

import { FetchSingleRecordAction } from "../../../../store/actions/createTicketManageFields";
import { default as ReactSelect, components } from "react-select";
import { getValue } from "@mui/system";

import { Tab, Tabs } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import DatePicker from "react-datepicker";
import { es } from "date-fns/locale";

const CommonCrew = ({ register, setValue, errors, crewType, currentValueName, currentValueId, sst = false, setsst }) => {

    console.log(crewType,"==========crewTypecrewTypecrewType")
    console.log(crewType, currentValueName, currentValueId, "accordianOperneraccordianOperneraccordianOperner")
    const url = window.location.href.split("/")
    let urlindexid = url.pop()


    const [autoData, setautoData] = useState({ foodid: "LAVILA IX" })


    // const {
    //     register,
    //     handleSubmit,
    //     setValue,
    //     getValues,
    //     formState: { errors },
    // } = useForm({});
    let [cxixtype, setcxixtype] = useState(true)
    const dispatch = useDispatch();
    const [onetimer, setonetimer] = useState(true);
    const [onentimer, setonentimer] = useState(true);

    // console.log(newcrewType,"crewType--------crewType")

    
    let [accordianOperner, setAccordianOperner] = useState()
    const [show, setShow] = useState(true);
    console.log(accordianOperner, "accordianOperneraccord============ianOperneraccordianOperner")
    const [cxArray, setcx] = useState([]);
    const [ixArray, setix] = useState([]);
    const [LastIXIndex, setIXLastIndex] = useState(-1);
    const [LastCXIndex, setCXLastIndex] = useState(-1);
    const [Lastcval, setLastcval] = useState(-1);
    const [ncurrentValueId, setcurrentValueId] = useState();

    let array_cx_main = [];
    let array_cx_data = [];
    let array_ix_data = [];
    let array_ix_main = [];
    let listalldata = [];
    const [ixshowinputdata, setixshowinputdata] = useState();
    const [cxshowinputdata, setcxshowinputdata] = useState();
    const [cxSelectedId, setcxSelectedId] = useState();
    const [ixSelectedId, setixSelectedId] = useState();
    let [SelCheckBox, setSelCheckBox] = useState('ixContactNumber')
    let [showData, setshowData] = useState('Contact Number')
    
    // if(currentValueName==""){
    //     SelCheckBox="name"
    //     console.log("me called")
    // }
    let [SelCheckBoxType, setSelCheckBoxType] = useState()
    let history = useHistory()


    let [CustomError, setCustomError] = useState({

    });

    



    const onixOptionChange = (val, val2) => {
        // handleChange("", "IXCrew", "b")
        // let val = e.target.value;
        // setSelCheckBox(val)
        // // setcxSelectedId(cxshowinputdata[val2])
        // setSelCheckBoxType(val2)
        // if (ixshowinputdata) {
        //     console.log(val, val2, autoData, ixshowinputdata, ixshowinputdata[val2], "autoDatavalvalval")
        //     setixSelectedId(ixshowinputdata[val2])
        // }
        // setSelCheckBoxType(val2)
        // console.log(val, "valvalval")
        // // array_ix_main.push(
        // //     <option value={""}>
        // //         Select
        // //     </option>
        // // );


        // setSelCheckBox(val)
        setSelCheckBoxType(val2)
        if (cxshowinputdata) {
            console.log(val, val2, autoData, cxshowinputdata, cxshowinputdata[val2], "autoDatavalvalval")
            setixSelectedId(cxshowinputdata[val2])
        }
        console.log(LastCXIndex,"LastIndexLastIndexLastIndex")
        
        // handleChange("", "IXCrew", "b")

        // let val = e.target.value;
        setSelCheckBox(val)

        if (listalldata) {
            listalldata.map((item) => {
                // if (item.crewType == "IX") {
                if (item.crewType == item.crewType) {
                    if (val == "ixLeadName") {
                        setshowData("Name")
                        array_ix_main.push({ text: item.name, value: item.uniqueid })
                        // array_ix_main.push(<option data-value={item.uniqueid}>{item.name}</option>);
                    } else if (val == "ixEmail") {
                        setshowData("Email")
                        array_ix_main.push({ text: item.email, value: item.uniqueid })
                        // array_ix_main.push(<option data-value={item.uniqueid}>{item.email}</option>);
                    } else if (val == "ixContactNumber") {
                        setshowData("Contact Number")
                        array_ix_main.push({ text: item.mobilenumber, value: item.uniqueid })
                        // array_ix_main.push(<option data-value={item.uniqueid}>{item.mobilenumber}</option>);
                    }
                }
            })
        }


        console.log(array_ix_main,array_ix_data,LastIXIndex,array_ix_data[LastIXIndex],"array_ix_maincasadsaa")
        setix(array_ix_main)
        setcx([]);
        
        if(LastIXIndex!=-1){
            setixSelectedId(array_ix_main[LastIXIndex].text)
            console.log(array_ix_main[LastIXIndex].text)
            setcxSelectedId("")
        }else{
            setixSelectedId("")
        }
        setLastcval("IX")
        // if(LastIndex!=-1){
        //     handleChange(LastIndex, "IXCrew", "b")
        // }else{
        //     handleChange("", "IXCrew", "b")
        // }
    };

    const handleChange = (event, nane, arra) => {

        console.log(event, event, event, nane,arra, array_cx_data, SelCheckBoxType, "event, nane ReactHTMLDatalist")
        let valuenum
        if (typeof (event) === "number") {
            valuenum = event
        } else {
            console.log(event,array_cx_data,SelCheckBoxType,event,arra,"eventeventeventeventevent")
            if (SelCheckBoxType) {
                setcxSelectedId(event)
                setixSelectedId(event)
                valuenum = array_cx_data.findIndex(listalldataone => listalldataone[SelCheckBoxType] == event)
            } else {
                setcxSelectedId(event)
                setixSelectedId(event)
                valuenum = array_cx_data.findIndex(listalldataone => listalldataone[arra] == event)
            }
        }

        console.log(valuenum, valuenum, event, nane, "event, nane ReactHTMLDatalist")

        // if (sst) {
        //     setsst(false)
        // }

        // setLastIndex(valuenum)
        if (valuenum == -1) {
            setcurrentValueId()
            setcxshowinputdata()
            setixshowinputdata()

            setValue("cxCrewId", "")
            setValue("ixCrewId", "")
        } else {


            let ind = valuenum;
            console.log("check_code", ind, nane, array_ix_data, array_cx_data);

            if (nane == "IXCrew") {
                setIXLastIndex(valuenum)
                setCXLastIndex(-1)
                if (array_ix_data[ind]) {
                    setValue("ixCrewId", array_ix_data[ind].uniqueid)
                    setValue("cxCrewId", "")
                    setcxshowinputdata()
                    setixshowinputdata(array_ix_data[ind])
                    setcurrentValueId(array_ix_data[ind].uniqueid)
                    console.log(array_ix_data[ind], "array_x_data[ind]")
                    // setValue(nane + "Name", array_ix_data[ind].name);
                    // setValue(nane + "Email", array_ix_data[ind].email);
                    // setValue(nane + "Phone", array_ix_data[ind].mobilenumber);
                    // setValue(nane + "Vendor", array_ix_data[ind].vendorresult[0].vendorName)
                    // setValue(nane + "Company", array_ix_data[ind].crewresult[0].crewCompanyName)
                    if(arra=="uniqueid"){
                        accordianchange("IXCrew")
                        setixSelectedId(array_ix_data[ind].name)
                    }
                    setixshowinputdata(array_ix_data[ind])
                }
            } else {
                setCXLastIndex(valuenum)
                setIXLastIndex(-1)
                if (array_cx_data[ind]) {
                    setValue("cxCrewId", array_cx_data[ind].uniqueid)
                    setValue("ixCrewId", "")
                    setcurrentValueId(array_cx_data[ind].uniqueid)
                    setixshowinputdata()
                    setcxshowinputdata(array_cx_data[ind])
                    if(arra=="uniqueid"){
                        accordianchange("CXCrew")
                        setcxSelectedId(array_cx_data[ind].email)
                    }
                    console.log(array_cx_data[ind], "array_x_data")
                    // setValue(nane + "Name", array_cx_data[ind].name);
                    // setValue(nane + "Email", array_cx_data[ind].email);
                    // setValue(nane + "Phone", array_cx_data[ind].mobilenumber);
                    // setValue(nane + "Vendor", array_cx_data[ind].vendorresult[0].vendorName)
                    // setValue(nane + "Company", array_cx_data[ind].crewresult[0].crewCompanyName)
                }
            }
        }

    };

    // console.log(LastIndex,"LastIndexLastIndex")
    const oncxOptionChange = (val, val2) => {
        console.log(val, val2, autoData, cxshowinputdata,listalldata, "autoDatavalvalval")

        setSelCheckBoxType(val2)
        if (cxshowinputdata) {
            console.log(val, val2, autoData, cxshowinputdata, cxshowinputdata[val2], "autoDatavalvalval")
            setcxSelectedId(cxshowinputdata[val2])
        }
        console.log(LastCXIndex,"LastIndexLastIndexLastIndex")
        


        // let val = e.target.value;
        setSelCheckBox(val)

        if (listalldata) {
            listalldata.map((item) => {
                // if (item.crewType == "CX") {
                if (item.crewType == item.crewType) {
                    if (val == "cxLeadName") {
                        setshowData("Name")
                        array_cx_main.push({ text: item.name, value: item.uniqueid })
                        // array_cx_main.push(<option data-value={item.uniqueid}>{item.name}</option>);
                    } else if (val == "cxEmail") {
                        setshowData("Email")
                        array_cx_main.push({ text: item.email, value: item.uniqueid })
                        // array_cx_main.push(<option data-value={item.uniqueid}>{item.email}</option>);
                    } else if (val == "cxContactNumber") {
                        setshowData("Mobile Number")
                        array_cx_main.push({ text: item.mobilenumber, value: item.uniqueid })
                        // array_cx_main.push(<option data-value={item.uniqueid}>{item.mobilenumber}</option>);
                    }
                }
            })
        }
        setcx(array_cx_main);
        setix([]);
        
        // if(LastIndex!=-1){
        //     handleChange(LastIndex, "CXCrew", "b")
        // }else{
        //     handleChange("", "CXCrew", "b")
        // }

        // console.log(array_ix_main,array_ix_data,LastIndex,array_ix_data[LastIndex],"array_ix_maincasadsaa")
        // setix(array_ix_main)
        // setcx([]);
        
        console.log(array_cx_main,array_cx_data,LastCXIndex,array_cx_data[LastCXIndex],"array_ix_maincasadsaa")
        if(LastCXIndex!=-1){
            setcxSelectedId(array_cx_main[LastCXIndex].text)
            setixSelectedId("")
        }else{
            setcxSelectedId("")
        }
        
        setLastcval("CX")
    };

    console.log(listalldata, "listalldataamit")

    let data = useSelector((state) => {


        console.log(state, "statestatestate");
        // let list_user=state
        listalldata = state.CommonResponse.listuser;
        console.log(listalldata, "listalldata")
        listalldata.map((item) => {
            if (item.crewType == item.crewType) {
                array_cx_data.push(item);
            }
            if (item.crewType == item.crewType) {
                array_ix_data.push(item);
            }
        })


        // if (show && listalldata.length > 0) {
        //     setShow(false)
        //     console.log(crewType, "setAccordianOperner", ncurrentValueId)
        //     if (crewType == "CXCrew") {
        //         console.log("listalldatacxLeadName")
        //         setSelCheckBox("cxLeadName")
        //         setSelCheckBoxType("name")
        //         oncxOptionChange("cxLeadName")
        //         setValue("cxCrewId", ncurrentValueId)
        //     } else {
        //         console.log("cxLeadName")
        //         setSelCheckBox("ixLeadName")
        //         setSelCheckBoxType("name")
        //         onixOptionChange("ixLeadName")
        //         setValue("ixCrewId", ncurrentValueId)
        //     }
        // }


        // if (onetimer && crewType && ncurrentValueId && ncurrentValueId != '') {
        //     console.log(array_cx_data, ncurrentValueId, array_ix_data, "listalldataone")
        //     if (crewType == "CXCrew") {
        //         if (array_cx_data.length > 0) {
        //             let valuenum = array_cx_data.findIndex(listalldataone => ncurrentValueId == listalldataone.uniqueid)
        //             handleChange(valuenum, crewType)
        //             setonetimer(false)
        //         }
        //     } else if (crewType == "IXCrew") {
        //         if (array_ix_data.length > 0) {
        //             let valuenum = array_ix_data.findIndex(listalldataone => ncurrentValueId == listalldataone.uniqueid)
        //             handleChange(valuenum, crewType)
        //             setonetimer(false)
        //         }
        //     }
        //     // if(ncurrentValueId==""){
        //     //     setonetimer(false)
        //     // }
        // }


        return { array_cx_data, array_ix_data };
    });

    console.log(data, "datadatadata")


    useEffect(() => {
        dispatch(UserListReducerAction(listUserApi_two));
        // if (sst) {
        //     handleChange(0, 'CXCrew')
        // }
    }, [sst]);

    if (sst) {
        console.log("callccs", sst)
        setsst(false)
        console.log("callcce", sst)
        // handleChange(0, 'CXCrew')
    }


    console.log(onentimer,crewType,"onentimeronentimeronentimer")

    if (onentimer) {
        
        console.log(crewType,onentimer,"onentimeronen========timeronentimer")
        console.log(crewType,crewType=="CXCrew",currentValueName=="",array_cx_data, "setonentimersetonentimer")
        if (currentValueName!="" && data.array_cx_data.length!=0) {
            if(crewType=="CXCrew"){
                oncxOptionChange("cxContactNumber","mobilenumber")
            }else{
                onixOptionChange("ixContactNumber","mobilenumber")
            }
            setonentimer(false)
            setAccordianOperner(crewType)
            setcurrentValueId(currentValueId)
            setSelCheckBoxType("mobilenumber")
            handleChange(currentValueName,crewType,"mobilenumber")
            // if(crewType=="CXCrew"){
            //     setcxSelectedId()
            // }


        }

        if(currentValueName=="" && listalldata.length!=0){
            setonentimer(false)
            // setcurrentValueId("name")
            setAccordianOperner(crewType)
            // setSelCheckBoxType("name")
            // setSelCheckBox("cxLeadName")
            oncxOptionChange("cxContactNumber","mobilenumber")
        }
    }

    console.log(errors, Object.keys(errors).length, CustomError, "errors")
    console.log(accordianOperner, "accordianOperneraccordianOperner")
    function accordianchange(e) {

        // setshowinputdata()
        // setcurrentValueId()
        console.log(e,"callert","calltoaccordianchange")
        if ("CXCrew" == e) {
            setSelCheckBox("cxContactNumber")
            setSelCheckBoxType("mobilenumber")
            oncxOptionChange("cxContactNumber")
        } else if ("IXCrew" == e) {
            setSelCheckBox("ixContactNumber")
            setSelCheckBoxType("mobilenumber")
            onixOptionChange("ixContactNumber")
        }
        setAccordianOperner(e)
    }
    return (
        <Fragment>
            {/* <PageTItle activeMenu='validation' motherMenu='Form' /> */}

            <div className="card" style={{ width: "100%", marginTop: "1rem" }}>
                {console.log(accordianOperner,"callert","accordianOperneraccordianOperner")}

                {accordianOperner?
                <Tabs
                    defaultActiveKey={accordianOperner}
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    onSelect={(e) => {
                        accordianchange(e)
                    }}
                >
                    {console.log(SelCheckBox,SelCheckBox=="cxContactNumber",SelCheckBox=="cxEmail",SelCheckBox=="cxLeadName","SelCheckBoxSelCheckBoxSelCheckBoxSelCheckBox")}
                    <Tab eventKey="CXCrew" title="CX Crew">
                        <div className="accordion-body">
                            <div className="form-row mt-2 p-2">
                                <div className="form-group col-md-4">
                                    <label for="exampleFormControlSelect1">
                                        CX Search By work----
                                    </label>


                                    <div>
                                        <div class="form-check-inline">
                                            <label class="form-check-label">
                                                <input
                                                    style={{ height: "20px" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    value="cxContactNumber"
                                                    onChange={(e) => { oncxOptionChange(e.target.value, "mobilenumber") }}
                                                    name="optradio"
                                                    checked={SelCheckBox === "cxContactNumber"}
                                                />
                                                Contact Number
                                            </label>
                                        </div>
                                        {console.log(SelCheckBox,"SelCheckBoxSelCheckBox")}
                                        <div class="form-check-inline">
                                            <label class="form-check-label">
                                                <input
                                                    style={{ height: "20px" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    value="cxEmail"
                                                    onChange={(e) => { oncxOptionChange(e.target.value, "email") }}
                                                    name="optradio"
                                                    checked={SelCheckBox === "cxEmail"}
                                                />
                                                E-mail
                                            </label>
                                        </div>
                                        <div class="form-check-inline disabled">
                                            <label class="form-check-label">
                                                <input
                                                    style={{ height: "20px" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    value="cxLeadName"
                                                    onChange={(e) => { oncxOptionChange(e.target.value, "name") }}
                                                    name="optradio"
                                                    checked={SelCheckBox === "cxLeadName"}
                                                />
                                                Lead Name
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group col-md-5">
                                    <label for="exampleFormControlSelect1">
                                        <span style={{ color: "red" }}>*</span>
                                        Enter Lead {showData}
                                    </label>

                                    {/* <DataList
                                        options={cxArray}
                                        id="text"
                                        value1="value"
                                        onOptionChange={(e)=>{
                                            console.log(e.target,"e.target")
                                        }}
                                        /> */}

                                    <input type="search"
                                        value={cxSelectedId}
                                        onChange={(e) => {
                                            handleChange(e.target.value, "CXCrew", "mobilenumber");
                                        }}
                                        list="crreww" />
                                    <datalist id="crreww">
                                        {cxArray.map((d) => {
                                            return <option key={d.value} value={d.text} />
                                        })}
                                    </datalist>


                                    {console.log(ncurrentValueId, "currentValueIdcurrentValueId")}
                                    {/* <input className='form-control' list="cars"
                                        style={{ height: "32px" }}
                                        defaultValue={whatWork != "Add" && whatWork != undefined ? singleSitestate.siteId : ""}
                                        onBlur={(e) => {
                                            findDSiteId(e.target.value)
                                        }}
                                    /> */}
                                    <input
                                        type="hidden"
                                        {...accordianOperner == "CXCrew" ?
                                            {
                                                ...register("cxCrewId", {
                                                    required: "Please Select CX Crew",
                                                })
                                            } :
                                            {
                                                ...register("cxCrewId", {
                                                    required: false
                                                })
                                            }}
                                    />
                                    {/* {/* {cxArray} */}


                                    {/* <datalist id="crreww">

                                        {cxArray}
                                    </datalist>  */}

                                    {/* <input> */}
                                    {errors.cxCrewId && (
                                        <p className="error">{errors.cxCrewId.message}</p>
                                    )}
                                </div>
                                {/* <div className="form-group col-md-5">
                                    <label for="exampleFormControlSelect1">
                                        <span style={{ color: "red" }}>*</span>
                                        Enter Lead Name
                                    </label>

                                    {console.log(ncurrentValueId,"currentValueIdcurrentValueId")}

                                    <select style={{ height: "32px" }}
                                        className="form-control"
                                        id="exampleFormControlSelect1"
                                        defaultValue={ncurrentValueId}
                                        value={ncurrentValueId}
                                        // {...register("cxCrewId", {

                                        //   onChange: (e) => {
                                        //     handleChange(e, "CXCrew", "b");
                                        //   },
                                        // })}

                                        {...accordianOperner == "CXCrew" ?
                                            {
                                                ...register("cxCrewId", {
                                                    required: "Please Select CX Crew",
                                                    onChange: (e) => {
                                                        handleChange(e.target.selectedIndex, "CXCrew", "b");
                                                    }
                                                })
                                            } :
                                            {
                                                ...register("cxCrewId", {
                                                    required: false
                                                })
                                            }}
                                    >
                                        {cxArray}

                                    </select>
                                    {errors.cxCrewId && (
                                        <p className="error">{errors.cxCrewId.message}</p>
                                    )}
                                </div>{" "} */}
                                <div className="form-group col-md-2 mt-2">
                                    <label for="exampleFormControlSelect1"></label>
                                </div>
                                {console.log(cxshowinputdata, "cxshowinputdatacxshowinputdata")}
                                <div className="form-group col-md-4">
                                    <label for="exampleFormControlSelect1">
                                        CX Crew Vendor
                                    </label>

                                    <input
                                        style={{ height: "32px" }}
                                        type="text"
                                        className="form-control"
                                        readOnly
                                        value={cxshowinputdata ? cxshowinputdata.vendorresult[0] ? cxshowinputdata.vendorresult[0].vendorName : "" : ""}
                                    />
                                </div>
                                <div className="form-group col-md-4 ">
                                    <label for="exampleFormControlSelect1">
                                        CX Crew Lead Contact Number
                                    </label>
                                    <input
                                        style={{ height: "32px" }}
                                        type="text"
                                        className="form-control"
                                        readOnly
                                        value={cxshowinputdata ? cxshowinputdata.mobilenumber : ""}

                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <label for="exampleFormControlSelect1">
                                        CX Crew Lead Name
                                    </label>
                                    <input
                                        style={{ height: "32px" }}
                                        type="text"
                                        className="form-control"
                                        id="input "
                                        readOnly
                                        value={cxshowinputdata ? cxshowinputdata.name : ""}
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <label for="exampleFormControlSelect1">
                                        CX Crew Lead Company{" "}
                                    </label>
                                    <input
                                        style={{ height: "32px" }}
                                        type="text"
                                        className="form-control"
                                        id="input "
                                        readOnly
                                        value={cxshowinputdata ? cxshowinputdata.crewresult[0] ? cxshowinputdata.crewresult[0].crewCompanyName : "" : ""}
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <label for="exampleFormControlSelect1">
                                        CX Crew Lead E-mail ID
                                    </label>
                                    <input
                                        style={{ height: "32px" }}
                                        type="text"
                                        className="form-control"
                                        id="input "
                                        readOnly
                                        value={cxshowinputdata ? cxshowinputdata.email : ""}
                                    />
                                </div>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="IXCrew" title="IX Crew">

                        <div className="accordion-body">
                            <div className="form-row mt-2 p-2">
                                <div className="form-group col-md-4">
                                    <label for="exampleFormControlSelect1">
                                        IX Search By---- 
                                    </label>

                                    <div>
                                        <div class="form-check-inline">
                                            <label class="form-check-label">
                                                <input
                                                    style={{ height: "20px" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    value="ixContactNumber"
                                                    onChange={(e) => { onixOptionChange(e.target.value, "mobilenumber") }}
                                                    name="optradio"
                                                    checked={SelCheckBox === "ixContactNumber"}
                                                />
                                                Contact Number
                                            </label>
                                        </div>
                                        <div class="form-check-inline">
                                            <label class="form-check-label">
                                                <input
                                                    style={{ height: "20px" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    value="ixEmail"
                                                    onChange={(e) => { onixOptionChange(e.target.value, "email") }}
                                                    name="optradio"
                                                    checked={SelCheckBox === "ixEmail"}
                                                />
                                                E-mail
                                            </label>
                                        </div>
                                        <div class="form-check-inline disabled">
                                            <label class="form-check-label">
                                                <input
                                                    style={{ height: "20px" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    value="ixLeadName"
                                                    onChange={(e) => { onixOptionChange(e.target.value, "name") }}
                                                    name="optradio"
                                                    checked={SelCheckBox === "ixLeadName"}
                                                />
                                                Lead Name
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-md-5">
                                    <label for="exampleFormControlSelect1">
                                        <span style={{ color: "red" }}>*</span>
                                        Enter Lead {showData}
                                    </label>

                                    {/* <ReactHTMLDatalist
                                        name={"food_id"}
                                        onChange={(item)=>{
                                            handleChange(item.target.value, "IXCrew", "b")
                                            console.log(item.target.value,item.target.text,"ReactHTMLDatalist")
                                        }}
                                        classNames={"classone classtwo"}
                                        options={ixArray}
                                    /> */}

                                    <input type="search"
                                        value={ixSelectedId}
                                        onChange={(e) => {
                                            handleChange(e.target.value, "IXCrew", "mobilenumber");
                                        }}
                                        list="ixcrreww" />
                                    <datalist id="ixcrreww">
                                        {ixArray.map((d) => {
                                            return <option key={d.value} value={d.text} />
                                        })}
                                    </datalist>


                                    {/* <select style={{ height: "32px" }}
                                        className="form-control"
                                        id="exampleFormControlSelect1"
                                        defaultValue={ncurrentValueId}
                                        value={ncurrentValueId}

                                        {...accordianOperner == "IXCrew" ?
                                            {
                                                ...register("ixCrewId", {
                                                    required: "Please Select IX Crew",
                                                    onChange: (e) => {
                                                        handleChange(e.target.selectedIndex, "IXCrew", "b");
                                                    }
                                                })
                                            } :
                                            {
                                                ...register("ixCrewId", {
                                                    required: false
                                                })
                                            }}
                                    >
                                        {ixArray}
                                    </select> */}


                                    <input
                                        type="hidden"

                                        {...accordianOperner == "IXCrew" ?
                                            {
                                                ...register("ixCrewId", {
                                                    required: "Please Select IX Crew",
                                                })
                                            } :
                                            {
                                                ...register("ixCrewId", {
                                                    required: false
                                                })
                                            }}
                                    />
                                    {/* {ixArray}
                                    </select> */}
                                    {errors.ixCrewId && (
                                        <p className="error">{errors.ixCrewId.message}</p>
                                    )}
                                </div>{" "}
                                <div className="form-group col-md-2 mt-2">
                                    <label for="exampleFormControlSelect1"></label>
                                </div>
                                <div className="form-group col-md-4">
                                    <label for="exampleFormControlSelect1">
                                        IX Crew Vendor
                                    </label>
                                    <input
                                        style={{ height: "32px" }}
                                        type="text"
                                        className="form-control"
                                        readOnly
                                        value={ixshowinputdata ? ixshowinputdata.vendorresult[0] ? ixshowinputdata.vendorresult[0].vendorName : "" : ""}
                                    />
                                </div>
                                <div className="form-group col-md-4 ">
                                    <label for="exampleFormControlSelect1">
                                        IX Crew Lead Contact Number
                                    </label>
                                    <input
                                        style={{ height: "32px" }}
                                        type="text"
                                        className="form-control"
                                        readOnly
                                        value={ixshowinputdata ? ixshowinputdata.mobilenumber : ""}
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <label for="exampleFormControlSelect1">
                                        IX Crew Lead Name
                                    </label>
                                    <input
                                        style={{ height: "32px" }}
                                        type="text"
                                        className="form-control"
                                        id="input "
                                        readOnly
                                        value={ixshowinputdata ? ixshowinputdata.name : ""}
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <label for="exampleFormControlSelect1">
                                        IX Crew Lead Company{" "}
                                    </label>
                                    <input
                                        style={{ height: "32px" }}
                                        type="text"
                                        className="form-control"
                                        id="input "
                                        readOnly
                                        value={ixshowinputdata ? ixshowinputdata.crewresult[0] ? ixshowinputdata.crewresult[0].crewCompanyName : "" : ""}
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <label for="exampleFormControlSelect1">
                                        IX Crew Lead E-mail ID
                                    </label>
                                    <input
                                        style={{ height: "32px" }}
                                        type="text"
                                        className="form-control"
                                        id="input "
                                        readOnly
                                        value={ixshowinputdata ? ixshowinputdata.email : ""}
                                    />
                                </div>
                            </div>
                        </div>
                    </Tab>
                </Tabs>:""}

            </div>
        </Fragment>
    );
};

export default CommonCrew;
