import React from 'react'

const Editpage = () => {
  return (
    <div>
        <div className="card  p-3" style={{ width: "100%" }}>
<form>
  <div className="form-row">
    <div className="form-group col-md-4">
      <label htmlFor="inputEmail4">Site Id</label>
      <select className="form-control" aria-label="Default select example">
  <option selected>select</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
    </div>
    <div className="form-group col-md-4">
      <label htmlFor="inputEmail4">Project Code</label>
      <select className="form-control" aria-label="Default select example">
  <option selected>select</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
    </div>
    <div className="form-group col-md-4">
      <label htmlFor="inputEmail4">Market </label>
      <select className="form-control" aria-label="Default select example">
  <option selected>select</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
    </div>
    <div className="form-group col-md-4">
      <label htmlFor="inputEmail4">Sectors  Impacted</label>
      <select className="form-control" aria-label="Default select example">
  <option selected>select</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
    </div>
    <div className="form-group col-md-4">
      <label htmlFor="inputEmail4">Type Of Work(SI/NSI)</label>
      <select className="form-control" aria-label="Default select example">
  <option selected>select</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
    </div>
    <div className="form-group col-md-4">
      <label htmlFor="inputEmail4">CR NO.</label>
      <select className="form-control" aria-label="Default select example">
  <option selected>select</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
    </div>
    <div className="form-group col-md-4">
      <label htmlFor="inputEmail4">Development Or  Operations Work</label>
      <select className="form-control" aria-label="Default select example">
  <option selected>select</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
    </div>
    <div className="form-group col-md-4">
      <label htmlFor="inputEmail4">Company</label>
      <select className="form-control" aria-label="Default select example">
  <option selected>select</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
    </div>
    <div className="form-group col-md-4">
      <label htmlFor="inputEmail4">Owner</label>
      <select className="form-control" aria-label="Default select example">
  <option selected>select</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
    </div>
    <div className="form-group col-md-4">
      <label htmlFor="inputEmail4">Owner-Email</label>
      <select className="form-control" aria-label="Default select example">
  <option selected>select</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
    </div>
    <div className="form-group col-md-4">
      <label htmlFor="inputEmail4">Owner Contact</label>
      <select className="form-control" aria-label="Default select example">
  <option selected>select</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
    </div>
    <div className="form-group col-md-4">
      <label htmlFor="inputEmail4">SoW</label>
      <select className="form-control" aria-label="Default select example">
  <option selected>select</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
    </div>
    <div className="form-group col-md-4">
      <label htmlFor="inputEmail4">SoW  Details</label>
      <select className="form-control" aria-label="Default select example">
  <option selected>select</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
    </div>
    <div className="form-group col-md-4">
      <label htmlFor="inputEmail4">Justification For Request</label>
      <select className="form-control" aria-label="Default select example">
  <option selected>select</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
    </div>
    <div className="form-group col-md-4">
      <label htmlFor="inputEmail4">Justification Comments </label>
      <select className="form-control" aria-label="Default select example">
  <option selected>select</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
    </div>
    <div className="form-group col-md-4">
      <label htmlFor="inputEmail4">Sector by sector Outage</label>
      <select className="form-control" aria-label="Default select example">
  <option selected>select</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
    </div>
    <div className="form-group col-md-4">
      <label htmlFor="inputEmail4">Crane Needed</label>
      <select className="form-control" aria-label="Default select example">
  <option selected>select</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
    </div>
    <div className="form-group col-md-4">
      <label htmlFor="inputEmail4">Start Date Time </label>
      <select className="form-control" aria-label="Default select example">
  <option selected>select</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
    </div>
    <div className="form-group col-md-4">
      <label htmlFor="inputEmail4">End Date Time</label>
      <select className="form-control" aria-label="Default select example">
  <option selected>select</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
    </div>
    <div className="form-group col-md-4">
      <label htmlFor="inputEmail4">Down Time</label>
      <select className="form-control" aria-label="Default select example">
  <option selected>select</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
    </div>
    <div className="form-group col-md-4">
      <label htmlFor="inputEmail4">Technology </label>
      <select className="form-control" aria-label="Default select example">
  <option selected>select</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
    </div>
  </div>
  <div className="form-group">
    <label htmlFor="inputAddress">Address</label>
    <input
      type="text"
      className="form-control"
      id="inputAddress"
      placeholder="1234 Main St"
    />
  </div>
  <div className="form-group">
    <label htmlFor="inputAddress2">Address 2</label>
    <input
      type="text"
      className="form-control"
      id="inputAddress2"
      placeholder="Apartment, studio, or floor"
    />
  </div>
  <div className="form-row">
    <div className="form-group col-md-6">
      <label htmlFor="inputCity">City</label>
      <input type="text" className="form-control" id="inputCity" />
    </div>
    <div className="form-group col-md-4">
      <label htmlFor="inputState">State</label>
      <select id="inputState" className="form-control">
        <option selected="">Choose...</option>
        <option>...</option>
      </select>
    </div>
    <div className="form-group col-md-2">
      <label htmlFor="inputZip">Zip</label>
      <input type="text" className="form-control" id="inputZip" />
    </div>
  </div>
  <div className="form-group">
    <div className="form-check">
      <input className="form-check-input" type="checkbox" id="gridCheck" />
      <label className="form-check-label" htmlFor="gridCheck">
        Check me out
      </label>
    </div>
  </div>
  <button type="submit" className="btn btn-primary">
    Sign in
  </button>
</form>
</div>





    </div>
  )
}

export default Editpage