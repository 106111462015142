import React from 'react'

const COPApprovalReports = () => {
  return (
    <>
        <h4>COP Approval Reports</h4>
    </>
  )
}

export default COPApprovalReports