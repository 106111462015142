import React,{useEffect, useState} from "react";
import { MultiSelect } from "react-multi-select-component";
import DatePicker from 'react-datepicker';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { AccountReducerAction, FetchSingleRecordAction, TierListReducerAction, addUserAction, clearIxSingleSite, popUpUpdate } from "../../../store/actions/createTicketManageFields";
import { OneSiteDatabase, OneSiteDatabaseProjectCode, OneSiteDatabaseProjectId, accountApi, addRTTForms, allTechnologyApi, ixModule, managEngineer, rttDashboard, siteDblistSiteId } from "../../../common/config";
import { commonzone, currentUrl, fun_time_zonechanger, moment_common_full_date_format } from "../../../common/variables";
import { getdatedatalistnext } from "../../../common/variables";
import moment from "moment";
import { reformArray } from "../../../common/variables";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useRef } from "react";
const RTTForm = () => {
  let history = useHistory()
  let openUrl=window.location.href
  let [selectProjectCode, setProjectCode] = useState([])
  let [singleSitestate, setsingleSitestate] = useState([]);
  let [ctrCount, setCtrCount] = useState(1);
  let [SelselectProjectCode, setSelProjectCode] = useState([])
  let [siteError, setSiteError] = useState(false)
  let [selectProjectId, setProjectId] = useState([])
  let [selectedMarket,setSelectedMarket]=useState()
  const [setdate, setStartDate] = useState();
  const [siteFile, uploadedFile] = useState([]);
  const [rttCompleteDate,setrttCompleteDate]=useState()
  let [startDateError, setStartDateError] = useState(false)
  let [disableButton,setDisable] =useState(false)   
  let [ixLogoutTime ,setIXLogoutTime] =useState()
  let [ixLogoutTimeError ,setIXLogoutTimeError] =useState(false)
  const [closer, setCloser] = useState(false)
  
  const [selectedSiteId,setSelectedSiteId]=useState()
  const [selectedProjectCode,setSelectedProjectCode]=useState()
  const [selectedProjectId,setSelectedProjectId]=useState()
  let count =useRef(1)
  let dispatch =useDispatch()
  
  let allProjects = []
  let accountArry = []
  let siteArray = [];
  let newTtempArray = []
  let projectIdArray = []
  let projectCodeOption = []
  let multipleTechno = []
  let siteInfo = []
  
    const styles = {
      width: "400px",
      
    };
    let [sow, setSow] = useState({
      selectedSow: [],
      sowList: [
        {
          label: "IX",
          value: "IX"
        },
        {
          label: "NEA",
          value: "NEA"
        },
        {
          label: "Troubleshooting",
          value: "Troubleshooting"
        },
        {
          label: "Call Test(PSAP/Speed Test)",
          value: "Call Test(PSAP/Speed Test)"
        }
      ],
      errorState: false
    })
    const { register, getValue, setValue, handleSubmit, setError, formState: { errors } } = useForm()
      let options =useSelector((state)=>{
        let account = state.CommonResponse.accounts;
        if (account.length > 0 && accountArry.length == 0) {
          accountArry = account.map((item) => {
            return <option value={item.uniqueid}>{item.accountName}</option>;
          });
        }
        let fetchData=state.OneCommonResponse.singleIXSite 
        if(fetchData && Object.keys(fetchData).length>0 ){
            console.warn("jkxdbwfkce.ielgrbed==",fetchData)
            if(count.current==1){
              console.log("bjhbjhbajhbdasbhdasda=",fetchData?.ndResult?.market)
              setSelectedMarket(fetchData?.ndResult?.market)
              setSelectedSiteId(fetchData.siteId)
              let projectCode=fetchData?.projectaUniqueId.map((item)=>item.projectCode)
              setSelectedProjectCode(projectCode.join(","))
              let productId =fetchData?.projectaUniqueId.map((item)=>item.projectId)
              setSelectedProjectId(productId.join(","))
              let account = state.CommonResponse.accounts;
              // accountArry=[]
              // if (account.length > 0 && accountArry.length == 0) {
              //   accountArry = account.map((item) => {
              //     if(fetchData.ndResult?.account==item.accountName){
              //       console.log("kjbxlvbadjkeqfdjew=")
              //       return <option selected value={item.uniqueid}>{item.accountName}</option>;
              //     }
              //     else{
              //       return <option value={item.uniqueid}>{item.accountName}</option>;
              //     }
              //     });
              // }
              setValue("account",fetchData.ndResult?.accountId)
              if(fetchData.scheduleDate){

                setStartDate(new Date(fetchData.scheduleDate))
              } 
              if(fetchData.postCheckRequest){

                setIXLogoutTime(new Date(fetchData.postCheckRequest))
              } 
              if(fetchData.rttCompleteDate){

                setrttCompleteDate(new Date(fetchData.rttCompleteDate))
              } 
              setSow((prev) => {
                return { ...prev, selectedSow:fetchData.sowList?.values.map((item)=>{

                  return {"label":item,
                          "value":item    
                    }
                }), errorState: false }
              })
              
            }
            // setSelectedProjectCode(fetchData.)

            count.current=2
        }
        if(state.popUpState){
          console.log(state.popUpState.popstatus.status,closer,(state.popUpState.popstatus.status==201 && closer) || (state.popUpState.popstatus.status==200 && closer),"popUpStatestatus201")
          if((state.popUpState.popstatus.status==201 && closer) || (state.popUpState.popstatus.status==200 && closer)){
            // dispatch(popUpUpdate({}))
            // setCloser(false)
            setCloser(false);
            dispatch(popUpUpdate({}));
            dispatch(AccountReducerAction(rttDashboard))
            history.push("/PAG/rtt/dashboard")
          }
        }
        allProjects = state.CommonResponse.startAwaitedSite
        // console.log("all projects is =",allProjects)
        if (allProjects && allProjects.length > 0) {
    
          siteArray = allProjects.map((project) => {
            // console.log("the project is =========",project)
            return <option>{project.siteId}</option>;
          });
        }

        siteInfo = state.csvResponse.singleSite;
console.log("th e stacdsfcsd=", state)
// console.log( siteInfo, siteInfo.length != 0, "ctrCount")
if (ctrCount == 1 && siteInfo && siteInfo.length != 0) {
  console.log("the single site is =", siteInfo)
  setsingleSitestate(siteInfo)
  setCtrCount(2)
}
let projectCodeArray = state.OneCommonResponse.productCode
if (projectCodeArray && projectCodeArray.length > 0) {
  projectCodeOption = projectCodeArray.map((item, index) => {


    console.log(SelselectProjectCode, item.projectCode, SelselectProjectCode.indexOf(item.projectCode), selectProjectCode.findIndex(abcd => { console.log(abcd); return item.projectCode == abcd.label }), "SelselectProjectCode")
    if (SelselectProjectCode.indexOf(item.projectCode) >= 0 && selectProjectCode.findIndex(abcd => {
      return item.projectCode == abcd.label
    }) == -1) {
      selectProjectCode.push({
        value: item.uniqueid,
        label: item.projectCode,
      })

    }
    return {
      value: item.uniqueid,
      label: item.projectCode,
    };
  })

}
projectIdArray = state.OneCommonResponse.productId
if (projectIdArray && projectIdArray.length > 0) {
  let projectIdOption = projectIdArray.map((item, index) => {
    let availableId = []
    let anyArry = item.data
    availableId = item.data.map((id) => {
      return {
        label: id.projectId,
        value: id.uniqueid
      }
    })

    newTtempArray.push({
      label: item.projectCode,
      value: item.projectCode,
      disabled: true,
    })
    newTtempArray.push(...availableId)

  })
}

return { siteArray, projectCodeOption,accountArry }


    })



    const FetechAllDetail = (id) => {
        // let test = projectIdArray.filter((item) => item.uniqueid == id)[0]
        // console.log(test, "test")
        // setPId(id)
        // setppProjctId(id)
        setProjectId(id)
        let selectedId = id.map((item) => {
          return item.value
        })
        // dispatch(FetchSingleRecordAction(ranDashboard, selectedId));
        dispatch(FetchSingleRecordAction(OneSiteDatabase , selectedId.join(",")))
        // setCtrCount(1)
        setValue("projectUniqueId", selectedId.join(","))
        // /console.log("FetechAllDetail", id)
      }
      const findDSiteId = (id) => {
        console.log(id, "findDSiteId", allProjects)
        if (id != undefined) {
          //   setcustomesite(id)
          //   setProjectId([])
          setProjectCode([])
          setSelProjectCode([])
          //   setTech([])
          findDProjectId(id, 1)
    
          // if(stateonce){
          //   setstateonce(false)
          //   dispatch(clearProjectId())
          // }
    
          setValue("siteId", id)
          let abc =
            setValue("siteUniqueId", allProjects.filter((item) => item.siteId == id)[0].uniqueSiteId)
          setSiteError(false)
          if (allProjects.length > 0)
            console.log("the respasfsdvgfdsbdtrh =", ...allProjects.filter((item) => item.siteId == id))
          let idObject = allProjects.filter((item) => item.siteId == id)
          if (idObject.length > 0) {
            // setond(true)
            dispatch(FetchSingleRecordAction(OneSiteDatabaseProjectCode, allProjects.filter((item) => item.siteId == id)[0].uniqueSiteId))
          }
        }
        else {
          setSiteError(true)
        }
      }
      const findDProjectId = (id, def = 0, pcode = []) => {

        // setCountRender(1)
        // console.log("projectCodeArray", id, projectCodeArray)
        // setppProjectCode(id)
    
        // setppProjctId('')
        
        let selectedIds = []
        let nselectedIds = ""
        if (def == 0) {
          setProjectCode(id)
          selectedIds = id.map((item) => {
            return item.value
          })
          nselectedIds = selectedIds.join(",")
        } else {
          let testpcode = []
    
          // testpcode=projectCodeArray.map((ittm)=>{
          //   if(pcode.indexOf(ittm.label)!=-1){
          //     return {
          //       label:ittm.label,
          //       value:ittm.value
          //     }
          //   }
          // })
          // setProjectCode(testpcode)
          nselectedIds = id
        }
        // console.log(OneSiteDatabase + id, id, projectCodeArray, "projectCodeArray")
    
        setSelProjectCode(nselectedIds.split(","))
        setValue("projectCode", nselectedIds)
        setValue("projectcodeUniqueId", selectedIds.join(","))
        dispatch(FetchSingleRecordAction(OneSiteDatabaseProjectId, nselectedIds ))
    
    
    
      }
     
      const setHandler = (e) => {
        setSow((prev) => {
          return { ...prev, selectedSow: e, errorState: false }
        })
      }
      const uploadCsvFile = (e) => {
        // let files=[]
        // console.log(e.target.files[0].name, "files")
        if(e.target.files){
        let fileExtension=e.target.files[0].name
        let extns=fileExtension.split(".").pop()
        if(extns!="zip"){
                swal({
                    title: "Oops!",
                    text: "Please upload only .zip file",
                    icon: "error",
                    button: "Ok",
                  })
        }
        else{
          uploadedFile(e.target.files[0])
        }
      }
      };
const  onSubmit=(data)=>{
    console.log("nsjkdbwc=",data)
    let senData =new FormData();
   let resp= Object.keys(data).map((item)=>{
    console.log("the  kjnckjasdbcsjkdbcs=",reformArray(sow.selectedSow))
        senData.append(item,data[item])
    }) 
    // data["scheduleDate"] = fun_time_zonechanger(commonzone, setdate).format(moment_common_full_date_format)
    senData.append("scheduleDate",moment(setdate).format(moment_common_full_date_format))
    // fun_time_zonechanger(commonzone , setdate).format(moment_common_full_date_format)
    
    // senData.append("scheduleDate",fun_time_zonechanger(commonzone , setdate).format(moment_common_full_date_format ))
    // senData.append("postCheckRequest",fun_time_zonechanger(commonzone , ixLogoutTime).format("MM/DD/YYYY" ))
    senData.append("postCheckRequest",moment(ixLogoutTime).format(moment_common_full_date_format))
    senData.append("rttCompleteDate", moment(rttCompleteDate).format(moment_common_full_date_format))
    senData.append("sowList",JSON.stringify(reformArray(sow.selectedSow)))
    console.log("t  cn jlkdbcklsdjb=",senData)
    if(siteFile!=[]){
        senData.append("uploadFile", siteFile);
        senData.append("uploadfor", "rttFileUpload")
    }
    dispatch(addUserAction(addRTTForms,senData))
    dispatch(popUpUpdate({}))
    setCloser(true)
}

//   useEffect(()=>{
// dispatch(clearIxSingleSite())
//   },[])

      useEffect(() => {
        dispatch(clearIxSingleSite())
      dispatch(AccountReducerAction(siteDblistSiteId ))
      dispatch(TierListReducerAction(managEngineer))
      dispatch(AccountReducerAction(allTechnologyApi));
      // dispatch(AccountReducerAction(allTechnologyApi));
      dispatch(AccountReducerAction(accountApi));
      let uArry=openUrl.split("/")
      let id =uArry.pop()
      console.log(id,"currecurrentUrlntUrl")
      if(openUrl.includes("edit")){
        dispatch(FetchSingleRecordAction(ixModule,id))
      }
      if(openUrl.includes("WildCard")){
        dispatch(FetchSingleRecordAction(addRTTForms,id))
      }
    }, [])
  return (  <form className="form-row" onSubmit={handleSubmit(onSubmit)}>
    {

openUrl.includes("WildCard") || openUrl.includes("edit")?
      <div className="form-group col-md-3"> 
      <label for="exampleFormControlSelect1">
      Site Id
    </label>
    <input className='form-control' type="text" readOnly value={selectedSiteId}></input>
  </div>
     
        :

<div className="form-group col-md-3">
          <label for="exampleFormControlSelect1">
            Site Id
          </label>
          <input className='form-control' list="cars"
            style={{ height: "32px" }}

            onBlur={(e) => {
              findDSiteId(e.target.value)
            }}
          />
          <p className="error" style={{ display: siteError ? "bolck" : "none" }}>
            Required Field
          </p>
          <datalist id="cars">

            {options.siteArray}
          </datalist>
        </div>

    }
        {/* <div className="form-group col-md-3">
          <label for="exampleFormControlSelect1">
            Site Id
          </label>
          <input className='form-control' list="cars"
            style={{ height: "32px" }}

            onBlur={(e) => {
              findDSiteId(e.target.value)
            }}
          />
          <p className="error" style={{ display: siteError ? "bolck" : "none" }}>
            Required Field
          </p>
          <datalist id="cars">

            {options.siteArray}
          </datalist>
        </div> */}
        {

openUrl.includes("WildCard") || openUrl.includes("edit")?
        <div className="form-group col-md-3"> 
        <label for="exampleFormControlSelect1">
        Project Code
        </label>
        <input className='form-control' type="text" readOnly value={selectedProjectCode}></input>
        </div>:

        <div className="form-group col-md-3">
          <label htmlFor="inputEmail4">Project Code</label>

          <MultiSelect
            className="text-dark"
            hasSelectAll={false}
            options={options.projectCodeOption}
            onChange={findDProjectId}
            value={selectProjectCode}
          />
        </div>
}
{

openUrl.includes("WildCard") || openUrl.includes("edit")?
<div className="form-group col-md-3"> 
<label for="exampleFormControlSelect1">
Project Id
</label>
<input className='form-control' type="text" readOnly value={selectedProjectId}></input>
</div>

  :
        <div className="form-group col-md-3 ">
          <label >Project Id</label>
          <MultiSelect
            className="text-dark"
            hasSelectAll={false}

            options={newTtempArray}
            onChange={FetechAllDetail}
            value={selectProjectId}
          />
          {/* {errors.projectUniqueId && (
                        <p className="error">
                          {errors.projectUniqueId.message}
                        </p>
                      )} */}
        </div>
}
        <div className="form-group col-md-3">
          <label for="exampleFormControlSelect1">Account</label>
          <select style={{ height: "32px" }}
            className="custom-select-style form-control"
            ID="exampleFormControlSelect1"
            {...register("account", {
              required: "Please select account"
            })}
          >
            <option selected disabled value=''>Select...</option>
            {options.accountArry}
          </select>
          {errors.account && (
            <p className="error">
              {errors.account.message}
            </p>
          )}
        </div>
        {

openUrl.includes("WildCard") || openUrl.includes("edit")?
<div className="form-group col-md-3">
<label htmlFor="inputEmail4">Market</label>

<input
  style={{ height: "32px" }}
  type="text"
  readOnly
  className="form-control"
  value={selectedMarket}
/>
</div>
:
       <div className="form-group col-md-3">
       <label htmlFor="inputEmail4">Market</label>

       <input
         style={{ height: "32px" }}
         type="text"
         readOnly
         className="form-control"
         value={
           singleSitestate
             ? singleSitestate.market
             : ""
         }
       />
     </div>

}
        <div className="form-group col-md-3">
          <label htmlFor="inputEmail4">IX Start Date</label>

          <DatePicker
            disabled
            style={styles}
            className="form-control"
            selected={setdate}
            onChange={(e) => { setStartDate(e); setStartDateError(false) }}
            showTimeSelect
            popperPlacement="top"
            // excludeOutOfBoundsTimes
            timeFormat="HH:mm"
            timeIntervals={60}
            timeCaption="Time"
            dateFormat="MM/dd/yyyy hh:mm aa"
            minDate={new Date()}
          //   maxDate={addDays(new Date(), 31)}
          />
          {/* <p className='error' style={{ display: startDateError ? "block" : "none" }}>Required Field</p> */}
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="inputEmail4">SOW</label>
          <MultiSelect
            className="text-dark"
            hasSelectAll={false}
            options={sow.sowList}
            onChange={setHandler}
            value={sow.selectedSow}
          />
          <p className='error' style={{ display: sow.errorState ? "block" : "none" }}>Required Field</p>
          </div>
          <div className="form-group col-md-3">
          <label htmlFor="inputEmail4">IX Logout Date Time</label>

          <DatePicker
            style={styles}
            className="form-control"
            disabled
            selected={ixLogoutTime}
            onChange={(e) => { setIXLogoutTime(e); setIXLogoutTimeError(false) }}
            showTimeSelect
            popperPlacement="top"
            // excludeOutOfBoundsTimes
            timeFormat="HH:mm"
            timeIntervals={60}
            timeCaption="Time"
            dateFormat="MM/dd/yyyy hh:mm aa"
            minDate={new Date()}
          //   maxDate={addDays(new Date(), 31)}
          />
         
          {/* <p className='error' style={{ display: startDateError ? "block" : "none" }}>Required Field</p> */}
        </div>
        <div class="form-group col-md-4">
            
            <label class="custom-file-upload btn btn-outline-primary col-md-8">
              <input
                type="file"
                className="form-control btn btn-primary"
                id="inputGroupFile04"
                aria-describedby="inputGroupFileAddon04"
                aria-label="Upload"
                name="file"
                multiple
                onChange={uploadCsvFile}
              />
              <i class="fa fa-cloud-upload"></i> Choose File
            </label>
            
            <label>{siteFile.length!=0?"1 ":"No"} File Selected</label>
        
          </div>
          {/* <a href= {baseURL+"/uploads/"+fileName} download>Click to download</a> */}
          
          <div className="form-group col-md-3">
            <label>RTT Completion Date</label>
            <DatePicker
                        style={styles}
                        className="form-control"
                        selected={rttCompleteDate}
                        onChange={(e) => { setrttCompleteDate(e); }}
                        // showTimeSelect
                        popperPlacement="top"
                        // excludeOutOfBoundsTimes
                        // timeFormat="None"
                        // timeIntervals={60}
                        // timeCaption="Time"
                        dateFormat="MM/dd/yyyy"
                        // minDate={new Date()}
                        // maxDate={addDays(new Date(), 31)}
                      />
          </div>
        <div className="d-flex justify-content-center mb-5" style={{ width: "100%" }}>
          <button type="submit" className="btn btn-primary btn-xs mr-2 py-2 px-4 mt-2" disabled={ disableButton}>
            Submit
          </button>
          
          <button className="btn btn-danger btn-xs py-2  px-4 mr-2 mt-2" onClick={() => history.push("/PAG/rtt/dashboard")}>Back</button>
        </div>
  </form>)
};

export default RTTForm;
