import React, { useEffect, useState } from "react";

const CustomTable = ({ tableHeader,siteDetail }) => {
  // console.log("the message from table comonent=", crewDetial)
  console.log("the message from table csite details =", siteDetail)
  let columns = tableHeader.map((item) => {
    return <th scope="col" style={{ paddingLeft : "5px" , border  : "2px solid black" , fontSize : "13px" , color : "white", textAlign : "left" , fontWeight : 500 ,  background: "#143b64" , height : "30px"}}>{item}</th>;
  });
  let rowData = []
  let cxData=''
  let ixCrewData=''
  // console.log(crewDetial.uniqueid != null,crewDetial.uniqueid, "the message from table comonent")
  if (siteDetail.cxCrewId.email != undefined) {
    let crewDetial=siteDetail.cxCrewId
    cxData = <tr>
      <td style={{  color : "black" , fontSize : "13px" , border : "2px solid black"}}>{crewDetial.crewType}</td>
      <td style={{  color : "black" , fontSize : "13px" , border : "2px solid black"}}>{crewDetial.vendor}</td>
      <td style={{ color : "black" ,  fontSize : "13px" , border : "2px solid black"}}>{crewDetial.mobilenumber}</td>
      <td style={{ color : "black" , fontSize : "13px" ,  border : "2px solid black"}}>{crewDetial.name}</td>
      <td style={{ color : "black" , fontSize : "13px" ,  border : "2px solid black"}}>{crewDetial.crewLeadCompanyId}</td>
      <td style={{ color : "black" , fontSize : "13px" ,  border : "2px solid black"}}>{crewDetial.email}</td>
    </tr>
    rowData.push(cxData)
    console.log(rowData, "rowData xlaskdjflwe")

  }
  if(siteDetail.ixCrewId.email != undefined){
    let crewDetial=siteDetail.ixCrewId
    ixCrewData=<tr>
    <td style={{  color : "black" , fontSize : "13px" , border : "2px solid black"}}>{crewDetial.crewType}</td>
    <td style={{  color : "black" , fontSize : "13px" , border : "2px solid black"}}>{crewDetial.vendor}</td>
    <td style={{ color : "black" ,  fontSize : "13px" , border : "2px solid black"}}>{crewDetial.mobilenumber}</td>
    <td style={{ color : "black" , fontSize : "13px" ,  border : "2px solid black"}}>{crewDetial.name}</td>
    <td style={{ color : "black" , fontSize : "13px" ,  border : "2px solid black"}}>{crewDetial.crewLeadCompanyId}</td>
    <td style={{ color : "black" , fontSize : "13px" ,  border : "2px solid black"}}>{crewDetial.email}</td>
  </tr>
  rowData.push(ixCrewData)
  }
  // else if()
  // rowData=ixCrewData
  // console.log("ix crew details. =",cxData)
  // console.log("ix crew detailsdsfsd. =",rowData)

  console.log(rowData, "rowData")
  return (
    <>

      {/* <div className="table-responsive " > */}
        <table style={{ width:"100%" , position : "relative" , zIndex : 999 }}>
          <thead >
            <tr >{columns}</tr>
          </thead>
          <tbody>
            {rowData}
          </tbody>
        </table>
      {/* </div> */}
    </>
  );
};

export default CustomTable;
