import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { default as ReactSelect, components } from "react-select";
import { MultiSelect } from "react-multi-select-component";
import { allTechnologyApi, updateGCpagdata, singleProjectApi, updatefops, updatedevelopment, updaterfengineer } from '../../../../common/config';
import { useForm } from "react-hook-form";
import { addAcccountReducerAction, AccountReducerAction, FetchSingleRecordAction } from '../../../../store/actions/createTicketManageFields';
import { useHistory } from "react-router-dom";
const SiteApproval = () => {
  let history = useHistory();
  let technologyArray = []
  let projectCodeOption = []
  // let singleSite = {};
  const [sow, setSow] = useState([])
  const [required_reason, setrequired_reason] = useState()
  const [mop_reason, setmop_reason] = useState()
  let [tech, setTech] = useState([]);
  let [singleSitestate, setsingleSitestate] = useState([]);
  let [sImpact, setSectorImpact] = useState([])
  let [startTime, setIntialTime] = useState()
  let [endTime, setFinalTime] = useState()

  let [ctrCount, setCtrCount] = useState(1);
  let dispatch = useDispatch()
  let userDetails = JSON.parse(window.localStorage.getItem("userDetails"))
  let current_id = history.location.pathname.split("/")[5]
  let whatWork = history.location.pathname.split("/")[2]

  console.log("whatWork", whatWork, "current_id", current_id)

  // if(userDetails.rolename=="RF Engineer"){

  // }

  let options = useSelector((state) => {
    console.log("dfwef=", state);
    // if(userDetails.rolename=="RF Engineer"){
    //   console.log("the resp=",state.OneCommonResponse.singleSite)
    //   let siteInfo=state.OneCommonResponse.singleSite
    //   if(typeof (siteInfo)==typeof({})){
    //     return({singleSite:siteInfo})
    //   }
    // }
    // else{

    let technology = state.CommonResponse.technology;
    if (technology.length > 0 && technologyArray.length == 0) {
      technologyArray = technology.map((item) => {
        return {
          value: item.uniqueid,
          label: item.technologyName,
        };
      });
    }
    let siteInfo = state.OneCommonResponse.singleSite;
    if (ctrCount == 1 && siteInfo.length != 0) {
      console.log("the single site is =", siteInfo)
      setsingleSitestate(siteInfo)
      // singleSite=siteInfo
      // console.log(singleSite,"singleSitesingleSite")
      // setTech(singleSite.technology)
      console.log("the response is the =", state.OneCommonResponse.singleSite)
      setCtrCount(2)
    }
    let projectCodeArray = state.OneCommonResponse.productCode
    if (projectCodeArray && projectCodeArray.length > 0) {
      projectCodeOption = projectCodeArray.map((item) => {
        return <option value={item.uniqueid}>{item.projectCode}</option>
      })
    }


    // console.log(singleSite,"singleSite amit")
    return { technologyArray, projectCodeOption }
    // }
  })

  const FetechAllDetail = (id) => {
    dispatch(FetchSingleRecordAction(singleProjectApi, id));
    setCtrCount(1)
  }


  const changestatus = (e) => {
    console.log(e.target.value == "2", "changestatus")
    if (e.target.value == "Disapproved") {
      setrequired_reason(<input type='text' className='form-control' {...register("reason", { required: "Reason is required" })} />)
    } else {
      setrequired_reason(<input type='text' className='form-control' {...register("reason", { required: false })} />)
    }

  }


  const changeMop = (e) => {
    if (e.target.value == "Day Mop") {
      setmop_reason(<><label htmlFor="inputEmail4">Reason</label><input type='text' className='form-control' {...register("dayMopReason", { required: "Reason is required" })} /></>)
    } else {
      setmop_reason("")
    }

  }


  let sowDetailArray = [
    {
      value: "Preparation Work",
      label: "Preparation Work"
    },
    {
      value: "Antenna Replacement",
      label: "Antenna Replacement"
    },
    {
      value: "Power Upgrade",
      label: "Power Upgrade"
    },
    {
      value: "Integration",
      label: "Integration"
    },
    {
      value: "Other",
      label: "Other"
    }
  ]
  let sectorsImpacted = [
    {
      value: "Alpha",
      label: "Alpha"
    },
    {
      value: "Beta",
      label: "Beta"
    },
    {
      value: "Gamma",
      label: "Gamma"
    },
    {
      value: "Delta",
      label: "Delta"
    },
    {
      value: "Epsilon",
      label: "Epsilon"
    },
    {
      value: "Zeta",
      label: "Zeta"
    }
  ]

  console.log("options.singleSite", options)
  const handleSectorImpect = (e) => {
    setSectorImpact(e)
  }
  const handleSowChanges = (e) => {
    setSow(e)
  }
  const techhandleChange = (e) => {
    console.log("multiselect=", tech)
    // setTechnologyError('')
    setTech(e)
  }
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const handleStartTime = (e) => {
    setIntialTime(e.target.value)
  }
  const endTimehandler = (e) => {
    setFinalTime(e.target.value)
    console.log("the endtime =", endTime)
    console.log("the endtime =", e.target.value)
  }
  const { register, setValue, handleSubmit, getValues, formState: { errors } } = useForm()
  const onSubmit = async (data) => {

    console.log("dataonSubmit", data, "data")
    if (userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager") {
      let sowArry = { values: [] }
      sowArry.values = sow.map((item) => {
        return item.value
      })
      data['sowDetails'] = sowArry
      let sendTech = { values: [] }
      sendTech.values = tech.map((item) => {
        return item.label
      })
      data['technology'] = sendTech
      let sectorsImpacted = { values: [] }
      sectorsImpacted.values = sImpact.map((item) => {
        return item.value
      })
      data['startTime'] = startTime
      data['endTime'] = endTime
      data['sectorsImpacted'] = sectorsImpacted

      dispatch(addAcccountReducerAction(updateGCpagdata + current_id, data))
      history.push("/PAG/network/database")
    }
    else if (userDetails.rolename == "FOPS") {
      data['fopsstatus'] = data['status']
      data['fopsreason'] = data['reason']
      data['fopsaddby'] = "FOPS"
      dispatch(addAcccountReducerAction(updatefops + current_id, data))
      history.push("/PAG/network/database")
    }
    else if (userDetails.rolename == "Development") {
      data['devstatus'] = data['status']
      data['devreason'] = data['reason']
      data['devaddby'] = "Development"
      dispatch(addAcccountReducerAction(updatedevelopment + current_id, data))
      history.push("/PAG/network/database")

    } else if (userDetails.rolename == "RF Engineer") {
      if (whatWork == "dev") {
        data['devstatus'] = data['status']
        data['devreason'] = data['reason']
        data['devaddby'] = "RF Engineer"
        dispatch(addAcccountReducerAction(updatedevelopment + current_id, data))
        history.push("/PAG/network/database")
      } else if (whatWork == "ops") {
        data['fopsstatus'] = data['status']
        data['fopsreason'] = data['reason']
        data['fopsaddby'] = "RF Engineer"
        dispatch(addAcccountReducerAction(updatefops + current_id, data))
        history.push("/PAG/network/database")
      } else if (whatWork == "rf") {
        data['rfstatus'] = data['status']
        data['rfreason'] = data['reason']
        data['rfaddby'] = "RF Engineer"
        dispatch(addAcccountReducerAction(updaterfengineer + current_id, data))
        history.push("/PAG/network/database")
      }

    }

    console.log(data)
  }
  useEffect(() => {
    dispatch(AccountReducerAction(allTechnologyApi));
    dispatch(FetchSingleRecordAction(singleProjectApi, current_id));
  }, [])
  return (
    <div>
      <div className="card  p-3" style={{ width: "100%" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-row">
            <div className="form-group col-md-4">
              <label htmlFor="inputEmail4">Site Id</label>
              <input type='text' className='form-control'
                readOnly
                value={singleSitestate.siteId}
              ></input>
            </div>

            <div className="form-group col-md-4 ">
              <label>Project Code</label>
              <input type='text'
                readOnly className='form-control'

                value={singleSitestate.projectCode}></input>
            </div>


            <div className="form-group col-md-4">
              <label htmlFor="inputEmail4">Market </label>
              <input type='text' className='form-control'
                readOnly

                value={
                  singleSitestate.market
                    ? singleSitestate.market
                    : ""
                }
              ></input>
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="inputEmail4">Account </label>
              <input type='text' className='form-control'
                readOnly

                value={
                  singleSitestate.account
                    ? singleSitestate.account
                    : ""
                }
              ></input>
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="inputEmail4">Development Or  Operations Work</label>
              <input type='text' className='form-control'
                readOnly

                value={
                  singleSitestate.group
                    ? singleSitestate.group
                    : ""
                }
              ></input>
            </div>



            <div className="form-group col-md-4">
              <label htmlFor="inputEmail4">Company</label>

              {
                userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" ? <input
                  readOnly
                  value={userDetails.account}
                  {...register("company")}
                  type='text' className='form-control' /> : <input
                  readOnly
                  value={singleSitestate.company}
                  type='text' className='form-control' />
              }
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="inputEmail4">Owner</label>

              {
                userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" ? <input
                  readOnly
                  value={userDetails.name}
                  {...register("owner")}
                  type='text' className='form-control' /> : <input
                  readOnly
                  value={singleSitestate.owner}
                  type='text' className='form-control' />
              }

            </div>
            <div className="form-group col-md-4">
              <label htmlFor="inputEmail4">Owner-Email</label>


              {
                userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" ? <input
                  readOnly
                  value={userDetails.email}
                  {...register("ownerEmail")}
                  type='text' className='form-control' /> : <input
                  readOnly
                  value={singleSitestate.ownerEmail}
                  type='text' className='form-control' />
              }

            </div>
            <div className="form-group col-md-4">
              <label htmlFor="inputEmail4">Owner Contact</label>

              {
                userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" ? <input
                  readOnly
                  value={userDetails.mobilenumber}
                  {...register("OwnerContact")}
                  type='text' className='form-control' /> : <input
                  readOnly
                  value={singleSitestate.OwnerContact}
                  type='text' className='form-control' />
              }
            </div>

            <div className="form-group col-md-4">
              <label htmlFor="inputEmail4">Type Of Work(SI/NSI)</label>

              {userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" ?
                <select
                  {...register("typeOfWork")}
                  className="form-control" aria-label="Default select example">
                  <option selected value="">select</option>
                  <option value="SI">SI</option>
                  <option value="NSI">NSI</option>
                </select> : <input
                  type='text' className='form-control'
                  readOnly
                  value={
                    singleSitestate.typeOfWork
                      ? singleSitestate.typeOfWork
                      : ""
                  } />
              }
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="inputEmail4">Sector by sector Outage</label>


              {userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" ?
                <select
                  {...register("sectorByOutage")}
                  className="form-control" aria-label="Default select example">
                  <option selected>select</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select> : <input
                  type='text' className='form-control'
                  readOnly
                  value={
                    singleSitestate.sectorByOutage
                      ? singleSitestate.sectorByOutage
                      : ""
                  } />
              }

            </div>

            <div className="form-group col-md-4">
              <label htmlFor="inputEmail4">Crane Needed</label>


              {userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" ?
                <select
                  {...register("CraneNeeded")}
                  className="form-control" aria-label="Default select example">
                  <option selected>select</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>

                </select> : <input
                  type='text' className='form-control'
                  readOnly
                  value={
                    singleSitestate.CraneNeeded
                      ? singleSitestate.CraneNeeded
                      : ""
                  } />
              }

            </div>
            <div className="form-group col-md-4">
              <label htmlFor="inputEmail4">CR NO.</label>

              {userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" ?
                <input
                  {...register("crNumber")}
                  type='text' className='form-control' /> : <input
                  type='text' className='form-control'
                  readOnly
                  value={
                    singleSitestate.crNumber
                      ? singleSitestate.crNumber
                      : ""
                  } />
              }
            </div>

            <div className="form-group col-md-4">
              <label htmlFor="inputEmail4">Justification For Request</label>


              {userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" ?
                <input
                  {...register("justificationForRequest")}
                  type='text' className='form-control' /> : <input type='text' readOnly value={
                    singleSitestate.justificationForRequest
                      ? singleSitestate.justificationForRequest
                      : ""} className='form-control' />
              }
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="inputEmail4">Justification Comments </label>

              {userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" ?
                <input
                  {...register("justificationComment")}
                  type='text' className='form-control' /> : <input type='text' readOnly value={
                    singleSitestate.justificationComment
                      ? singleSitestate.justificationComment
                      : ""} className='form-control' />
              }
            </div>

            <div className="form-group col-md-4">
              <label htmlFor="inputEmail4"> Activity Start Date Time </label>
              {
                userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" ?
                  <input onChange={(e) => handleStartTime(e)} type="datetime-local" class="form-control" min="2023-01-25T12:32" /> :
                  <input  type='text' readOnly value={singleSitestate.startTime ? singleSitestate.startTime : ""} className='form-control' />
              }
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="inputEmail4"> Activity End Date Time</label>
              {
                userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" ?
                  <input onChange={(e) => endTimehandler(e)} class="form-control" type="datetime-local" /> :
                  <input type='text' readOnly value={singleSitestate.endTime ? singleSitestate.endTime : ""} className='form-control' />
              }
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="inputEmail4">Down Time (H)</label>
              {
                userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" ?
                  <input
                    {...register("DownTime")}
                    type='text' className='form-control' /> :
                  <input
                    type='text' className='form-control'
                    value={singleSitestate.DownTime ? singleSitestate.DownTime : ""} />
              }

            </div>
            <div className="form-group col-md-4">
              <label htmlFor="inputEmail4">Sectors Impacted</label>

              {userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" ?
                <MultiSelect
                  className="dark"
                  options={sectorsImpacted}
                  onChange={handleSectorImpect}
                  value={sImpact}
                /> : <textarea
                  type='text' className='form-control'
                  value={singleSitestate.sectorsImpacted ? singleSitestate.sectorsImpacted.values : ""} />}


            </div>

            <div className="form-group col-md-4">
              <label htmlFor="inputEmail4">SoW</label>

              {userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" ?
                <MultiSelect
                  className="dark"
                  options={sowDetailArray}
                  onChange={handleSowChanges}
                  value={sow}
                /> : <textarea
                  type='text' className='form-control'
                  value={singleSitestate.sowDetails ? singleSitestate.sowDetails.values : ""} />}

            </div>
            <div className="form-group col-md-4">
              <label htmlFor="inputEmail4">Technology </label>

              {userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" ?
                <MultiSelect
                  className="dark"
                  options={options.technologyArray}
                  onChange={techhandleChange}
                  value={tech}
                /> : <textarea
                  type='text' className='form-control'
                  value={singleSitestate.technology ? singleSitestate.technology.values : ""} />}

            </div>

            <div className="form-group col-md-4">
              <label htmlFor="inputEmail4">SoW  Details</label>
              {
                userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" ?
                  <textarea minLength={200} maxLength={500} {...register("sowDetail")} type='text' className='form-control' /> :
                  <textarea readOnly value={singleSitestate.sowDetail ? singleSitestate.sowDetail : ""} className='form-control' />
              }

            </div>


            {userDetails.rolename == "Vendor (ASP) IX Crew Manager" || userDetails.rolename == "Vendor (SP) CX Crew Manager" ?
              <>
                <div className="form-group col-md-4">
                  <label htmlFor="inputEmail4">MOP</label>
                  <select
                    {
                    ...register("mop", {
                      onChange: (e) => { changeMop(e) }
                    })
                    }
                    className="form-control" aria-label="Default select example">
                    <option selected>select</option>
                    <option value="Day Mop">Day Mop</option>
                    <option value="Night Mop">Night Mop</option>

                  </select>
                </div>

                <div className="form-group col-md-4">
                  {mop_reason}
                  {errors.reason && (
                    <p className="error">
                      {errors.reason.message}
                    </p>
                  )}

                </div>
              </>
              : ""}

            {userDetails.rolename != "Vendor (ASP) IX Crew Manager" && userDetails.rolename != "Vendor (SP) CX Crew Manager" ?
              <>
                <div className="form-group col-md-4">
                  <label htmlFor="inputEmail4">MOP</label>
                  <input readOnly value={singleSitestate.mop ? singleSitestate.mop : ""} className='form-control' />
                </div>



                <div className="form-group col-md-4">
                  <label htmlFor="inputEmail4">Reason</label>
                  {singleSitestate.mop == "Day Mop" ? <input readOnly value={singleSitestate.dayMopReason ? singleSitestate.dayMopReason : ""} className='form-control' /> : ""}

                </div>
              </>
              : ""}



            {userDetails.rolename != "Vendor (ASP) IX Crew Manager" && userDetails.rolename != "Vendor (SP) CX Crew Manager" ?
              <>
                <div className="form-group col-md-4">
                  <label htmlFor="inputEmail4">Status</label>
                  <select
                    {
                    ...register("status", {
                      onChange: (e) => { changestatus(e) }
                    })
                    }
                    className="form-control" aria-label="Default select example">
                    <option selected>select</option>
                    <option value="Approved">Approved</option>
                    <option value="Disapproved">Disapproved</option>

                  </select>
                </div>

                <div className="form-group col-md-4">
                  <label htmlFor="inputEmail4">Reason</label>
                  {required_reason}
                  {/* {
    required_reason==true?: <input type='text' className='form-control' {...register("reason")} />
  } */}

                  {errors.reason && (
                    <p className="error">
                      {errors.reason.message}
                    </p>
                  )}

                </div>
              </>
              : ""}
          </div>






          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </form>
      </div>





    </div>
  )
}

export default SiteApproval