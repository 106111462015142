import React, { useEffect, useState } from 'react'
import { advancdExportOnecustomDataTableOptionObject, columnsArray, customDataTableOptionObject, digit_more_info, getMuiThemeCommon, wrapText } from '../../../common/variables'
import MUIDataTable from 'mui-datatables'
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { SSVDashboardAPI, baseURL, commonfilters, dynamic_dashboard, export_mop, export_ssv_dashboard, ranDashboard, templatelssv, templatessvurl } from '../../../common/config';
import { AccountReducerAction, ExportXLSXDownloadReducerAction, ResetLCLLSSVReducerAction, ResetLCLSSVReducerAction, clearIxSingleSite, deleteAcccountReducerAction, getFilterDropDown, popUpUpdate } from '../../../store/actions/createTicketManageFields';
import { useDispatch, useSelector } from 'react-redux';
import CommonModal from '../Custom/CommonModal';
import FilterData from "../../../icons/Svg/FilterData";
import SSVEngineer from '../Custom/Modal Body/SSVEngineer';
import SSVDriver from '../Custom/Modal Body/SSVDriver';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import SSVModal from '../Custom/Modal Body/SSVModal';
import CommonBulkUploader from '../Custom/CommonBulkUploader';
import moment from "moment";
import { Button } from 'react-bootstrap';
import RefreshIcon from "@mui/icons-material/Refresh";
import swal from 'sweetalert';
import { common } from '../../../services/AdminService';
import SSVFilterList from '../Custom/Modal Body/SSVFilterList';
import AdvCommonBulkUploader from '../Custom/AdvCommonBulkUploader';
import DownloadNew from '../../../icons/Svg/DownloadNew';
import CommonBulkUploaderButton from '../Custom/CommonBulkUploaderButton';
import Upload from '../../../icons/Svg/Upload';
import SelectBox from '../Custom/Modal Body/SelectBox';
import InputBox from '../Custom/Modal Body/inputBox';
const SSVDashboard = () => {
  const dispatch = useDispatch();

  const [ResetFilter, setResetFilter] = useState(false);
  const [commonModalState, setcommonModalState] = useState(false);
  const [commonModalSize, setcommonModalSize] = useState(false);
  const [commonModalTitle, setcommonModalTitle] = useState("");
  const [commonModalBody, setcommonModalBody] = useState("");
  const [commonModalHeader, setcommonModalHeader] = useState("");
  const [fullUrL, setfullUrL] = useState("");
  const [exportApiData, setexportApiData] = useState("");
  const [filterBadges, setFilterBadge] = useState()
  const [ssvEngineerModal, setModalState] = useState(false)
  const [closer, setCloser] = useState(false)
  let [modalBdy, setModal] = useState(false)
  const [ssvDriveModal, setDriveModalState] = useState(false)
  const [modalBody, setModalBody] = useState({
    heading: "",
    body: ""
  })

  const CommonModalFun = (body, title, header) => {
    setcommonModalBody(body);
    setcommonModalHeader(header);
    setcommonModalState(true);
  };


  let rowData = []

  const deleteHandler = (e, url) => {
    console.log("button delete clicked", e)

    swal({
      title: "Are you sure?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          console.log("the will dele=", willDelete)
          setModalState(true)
          dispatch(deleteAcccountReducerAction(url, e))
          dispatch(AccountReducerAction(SSVDashboardAPI));
        }
      });
  }
  const exportTemplate = () => {
    dispatch(ExportXLSXDownloadReducerAction(templatessvurl, "SSVTemplate_" + moment(new Date()).format("MM-DD-YYYY")))
  }

  function filterhandler() {

    dispatch(getFilterDropDown([]))
    // dispatch(AccountReducerAction(ssvfilters))
    dispatch(AccountReducerAction(commonfilters))
    setcommonModalState(true)
    setcommonModalSize("xl")
    setcommonModalBody(<SSVFilterList setfullUrL={setfullUrL} setcommonModalState={setcommonModalState} setFilterBadge={setFilterBadge} featureExport={setexportApiData} />)
    setcommonModalTitle("SSV Filters")
    setcommonModalHeader("SSV Filter")
  }
  let count = useSelector((state) => {

    if (state.popUpState) {
      if (state.popUpState.popstatus.status == 201 && ssvEngineerModal) {
        setModalState(false);
        dispatch(popUpUpdate({}));
        dispatch(AccountReducerAction(SSVDashboardAPI))
      }
    }
    // console.log("nj,bkjcbhshudkcbdfhbfvbdvf====",dashboardData)
    let sitesdata = state.CommonResponse.SSVDashboardData
    if (sitesdata && sitesdata.length > 0) {
      rowData = sitesdata.map((item) => {
        // item["siteID"]=item.dtResult?item.dtResult.siteName:""
        // item['projectId']=item.dtResult?item.dtResult.projectId:""


        item["SSSCOPApprovedby"]=item?.approverresult?.name
        

        if (item["projectUniqueId"] && item["projectUniqueId"].length > 0) {
          let projectUniqueIdFlag = []
          item["projectUniqueId"].map((itm) => {
            projectUniqueIdFlag.push(itm.projectId)
          })
          let planTypeData = []
          item["projectUniqueId"].map((itm) => {
            planTypeData.push(itm.projectCode)
          })



          let ProgramNameData = []
          

          item["projectUniqueId"].map((itm) => {
            ProgramNameData.push(itm.ProgramName)
          })
          
          item["ProgramName"] = (
            <p
              className={"text-black my-0 modalHoverClass"}
              data-toggle="tooltip"
              data-placement="top"
              title="Click Here For More Details"
              onClick={() =>
                CommonModalFun(
                  <p
                    style={{
                      border: "2px solid black",
                      color: "black",
                      padding: 2,
                    }}
                    className="CommonModal"
                  >
                    {ProgramNameData.join(", ")}
                  </p>,
                  "Program Name",
                  "Program Name"
                )
              }
            >
              {wrapText(ProgramNameData.join(", "))}
            </p>
          )

          item["RemarksLSSVSSSCOPStatusModal"] = (
            <p
              className={"text-black my-0 modalHoverClass"}
              data-toggle="tooltip"
              data-placement="top"
              title="Click Here For More Details"
              onClick={() =>
                CommonModalFun(
                  <p
                    style={{
                      border: "2px solid black",
                      color: "black",
                      padding: 2,
                    }}
                    className="CommonModal"
                  >
                    {item["RemarksLSSVSSSCOPStatus"]}
                  </p>,
                  "Remarks",
                  "Remarks"
                )
              }
            >
              {wrapText(item["RemarksLSSVSSSCOPStatus"])}
            </p>
          )


          
          // item["projectUniqueIdFlag"]=item["projectUniqueId"][0].projectId
          item["projectUniqueIdFlag"] = projectUniqueIdFlag.join(", ")

          item["latitude"] = item["projectUniqueId"][0].latitude

          item["longitude"] = item["projectUniqueId"][0].longitude
          item["planType"] = planTypeData.join(", ")

          item["vendor"] = item["projectUniqueId"][0].HardCostVendor
          item["projectType"] = item["projectUniqueId"][0].projectType
        }


        item["edit"] = <div className="d-flex"><Link to={"/PAG/ssv/edit/" + item.ssvUniqueId} onClick={() => dispatch(clearIxSingleSite())} className="btn btn-primary shadow btn-xs sharp mr-1 d-flex justify-content-center align-items-center"><i className="fa fa-pencil"></i></Link><button onClick={(e) => deleteHandler(item.ssvUniqueId, SSVDashboardAPI)} className="btn btn-danger shadow btn-xs sharp d-flex justify-content-center align-items-center"><i className="fa fa-trash"></i></button></div>
        item["gcdata"] = item["crew_status"] ? item["crew_status"].crewCompanyName : ""
        // item['ssvEngineermodal'] = <p className='badge badge-primary'
        //   onClick={() => { setModalState(true); setModalBody({ body: <SSVEngineer id={item.ssvUniqueId} />, heading: "SSV Details" }) }}>{item.ssvEngineer ? item.ssvEngineer : "SSV Details"}</p>
        item["ssvFileUploadmodal"] =
          <div className=' d-flex justify-content-evenly'>


            <CommonBulkUploaderButton typeForm={"SSV File"} shortCode={`SSVFileUpload__CP__` + item.ssvUniqueId} btnname={<Upload />} classes={"btn btn-primary btn-xs  mx-1"} afterLoad={SSVDashboardAPI} />
            {item.SSVFileUpload ?
              <a href={baseURL + "/uploads/" + item.SSVFileUpload} className="" download><DownloadNew /></a>
              : ""
            }

            {/* <AdvCommonBulkUploader typeForm={"SSV File"} shortCode={"SSVFileUpload"} uId={item.ssvUniqueId} wildCard={false} />
            {item.SSVFileUpload ?
              <a href={baseURL + "/uploads/" + item.SSVFileUpload} className="" download><DownloadNew /></a>
              // <a href={baseURL + "/uploads/" + item.rttFile} className="" download><Download /></a>
              : ""
            } */}
          </div>
        // item['ssvFileUploadmodal'] = <p className='badge badge-primary'
        //   onClick={() => { setModalState(true); setModalBody({ body: <SSVEngineer id={item.ssvUniqueId} />, heading: "SSV Details" }) }}>{item.ssvEngineer ? item.ssvEngineer : "SSV Details"}</p>

        item["SSVStatusModal"] = <p
          className='badge badge-primary'
          onClick={() => {
            setModalState(true);
            setModalBody({ body: <SelectBox setCloser={setCloser} oldData={item["SSVStatus"]} keyName={"SSVStatus"} optionsList={["Pending", "Submitted", "Approved"]} label={"SSV Status"} sendUrl={SSVDashboardAPI} id={item.ssvUniqueId} />, heading: "Update SSV" })
          }}
        >{item.SSVStatus ? item.SSVStatus : "SSV Status"}</p>


        item["SSVCommentIssueModal"]=<p
          className='badge badge-primary'
          onClick={() => { 
            setModalState(true); 
            setModalBody({ body: <InputBox setCloser={setCloser} oldData={item["SSVCommentIssue"]} keyName={"SSVCommentIssue"} label={"Comment/Issue"} sendUrl={SSVDashboardAPI} id ={item.ssvUniqueId}/>, heading: "Comment/Issue" }) }}
        >{item.SSVCommentIssue ? digit_more_info(item.SSVCommentIssue,10) : "Comment"}</p>

        item["SSVStatusModal"] = item.SSVStatus
        item["SSVStatusModal"] = item.SSVStatus

        // item["SSVStatusModal"]=<p
        //   className='badge badge-primary'
        //   onClick={() => { 
        //     setModalState(true); 
        //     setModalBody({ body: <InputBox setCloser={setCloser} oldData={item["SSVStatus"]} keyName={"SSVStatus"} optionsList={["Pending", "Submitted", "Approved"]} label={"SSV Status"} sendUrl={SSVDashboardAPI} id ={item.ssvUniqueId}/>, heading: "Update SSV" }) }}
        // >{item.SSVStatus ? item.SSVStatus : "SSV Status"}</p>



        item["driveDateModal"] = <p className='badge badge-primary' onClick={() => { setModalState(true); setModalBody({ body: <SSVDriver id={item.ssvUniqueId} />, heading: "Drive Test" }) }}>{item.driveDate ? item.driveDate : "Drive Test"}</p>
        item["ssvSubmitDateFlag"] = <p
          className='badge badge-primary'
          onClick={() => { setModalState(true); setModalBody({ body: <SSVModal id={item.ssvUniqueId} />, heading: "Update SSV" }) }}
        >
          {
            item.ssvSubmitDate ? item.ssvSubmitDate : "Update SSV"}
        </p>
        return (item)
      })
      console.log("sdcdscdscfdsfc=", rowData)
    }
    return ({ rowData })
  })

  const ClearFilter = () => {
    // alert("ClearFilter")
    console.log("ClearFilter", "ClearFilter");
    // setfstartdate();
    // setfenddate();
    setResetFilter(true)

    setFilterBadge()
    let Filleddata = dispatch(ResetLCLSSVReducerAction())
    dispatch(AccountReducerAction(SSVDashboardAPI + Filleddata, true));

    // dispatch(AccountReducerAction(listennoTicketApi+Filleddata,true,true));
  };

  const exportAllData = () => {

    
    let Filleddata = dispatch(ResetLCLSSVReducerAction())
    dispatch(ExportXLSXDownloadReducerAction(export_ssv_dashboard,"SSVDASHBOARD_" + moment(new Date()).format("MM-DD-YYYY"),Filleddata))
    // FileSaver.saveAs(baseURL+"/export/ran", "RAN_Support_" + moment(new Date()).format("MM-DD-YYYY"))
    // history.push(baseURL+"/export/ran")
  }

  let AddSSV = () => (
    <>

      <p onClick={filterhandler} className=" mx-2 " style={{ "align-self": "center" }}><FilterData /></p>
      <button
        className="btn btn-xs btn-primary mx-2 my-3"
        data-toggle="tooltip"
        data-placement="bottom"
        title="Refresh"
        onClick={() => { ClearFilter(); }}
        style={{ padding: "6px" }}
      >
        <RefreshIcon />
      </button>
      <Button onClick={exportTemplate} className="btn btn-xs btn-primary mx-2 py-2">
        Template
      </Button>
      <Link to="/PAG/ssv/add" className="btn btn-xs btn-primary mx-2 py-2">Add SSV</Link>
      <CommonBulkUploader styles={{ marginBottom: "1rem" }} typeForm={"SSV  Bulk Uploads"} shortCode={"Bulk_SSV"} afterLoad={"/ssv" + dynamic_dashboard} classes={'btn btn-xs btn-primary mx-2 my-3 py-2'} />



      <Button onClick={exportAllData} className="btn btn-primary btn-xs mx-2 py-2 my-3 float-right d-flex justify-content-center align-items-center">
        Export
      </Button>

    </>
  )

  // console.log("thjcvojdfnvijfsrn=",columnsArray("Site ID	Plan Type	Project ID	Project Type	HC Vendor	GC	SSV Engineer	RTT Date	RTT lookup frm 72 Hr Tracker	Unlock Date	Drive Date	Drive Status	Drive test person	Drive route & site details status	Drive route Sent Date	SSV Submit Date	SSV Appoval Date	SSV Status (Pending, Submitted, Approved)	Comments/issue	re submitted  date"))
  let columns = [
    {
      "name": "siteId",
      "label": "Site ID",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 101,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 100,
          },
        }),
      }
    },
    {
      "name": "planType",
      "label": "Plan Type",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "ProgramName",
      "label": "Program Name",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "240px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "240px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "projectUniqueIdFlag",
      "label": "Project ID",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },

    {
      "name": "projectType",
      "label": "Project Type",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "vendor",
      "label": "HC Vendor",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "gcdata",
      "label": "GC",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "ssvRttDate",
      "label": "RTT Date",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },

    {
      "name": "ssvUUUDate",
      "label": "UUU Date",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "driveDateModal",
      "label": "Drive Date",
      "sortable": false,
      "options": {
        filter: true,
        print: false,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "DriveWeek",
      "label": "Drive Week",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "driverStatus",
      "label": "Drive Status",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "driverTestPerson",
      "label": "Drive test person",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "driveRouteSiteDetailsStatus",
      "label": "Drive route & site details status",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "driveRouteSentDate",
      "label": "Drive route Sent Date",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    // {
    //   "name": "ssvEngineermodal",
    //   // "label": "SSV Details",
    //   "label": "SSV submit",
    //   "sortable": false,
    //   "options": {
    //     filter: true,
    //     print: false,
    //     className: "abcd",
    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",
    //         left: "0",
    //         alignItem: "center",
    //         padding: "1px 1px",
    //         background: "#143b64",
    //         minWidth: "140px",
    //         color: "white",
    //         position: "sticky",
    //         zIndex: 100,
    //         outline: "1.5px solid black"
    //       },
    //     }),
    //     setCellProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",
    //         left: "0",
    //         overflowY: "hidden",
    //         minWidth: "140px",
    //         padding: "1px 1px",
    //         position: "sticky",
    //         background: "White",
    //         zIndex: 99,
    //       },
    //     }),
    //   }
    // },
    {
      "name": "ssvFileUploadmodal",
      // "label": "SSV Details",
      "label": "SSV submit",
      "sortable": false,
      "options": {
        filter: true,
        print: false,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "ssvEngineer",
      // "label": "SSV Details",
      "label": "SSV Engineer",
      "sortable": false,
      "options": {
        filter: true,
        print: false,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },





    // {
    //   "name": "ssvSubmitDateFlag",
    //   "label": "SSV Re-Submit Date",
    //   "sortable": false,
    //   "options": {
    //     filter: true,
    //     print: false,
    //     className: "abcd",
    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",
    //         left: "0",
    //         alignItem: "center",
    //         padding: "1px 1px",
    //         background: "#143b64",
    //         minWidth: "140px",
    //         color: "white",
    //         position: "sticky",
    //         zIndex: 100,
    //         outline: "1.5px solid black"
    //       },
    //     }),
    //     setCellProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",
    //         left: "0",
    //         overflowY: "hidden",
    //         minWidth: "140px",
    //         padding: "1px 1px",
    //         position: "sticky",
    //         background: "White",
    //         zIndex: 99,
    //       },
    //     }),
    //   }
    // },
    {
      "name": "ssvSubmitDate",
      "label": "SSV Submitted Date",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "SSVSubmitWeek",
      "label": "SSV Submit Week",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },

    // {
    //   "name": "SSSCOPStatus",
    //   "label": "SSV Approval",
    //   "sortable": false,
    //   "options": {
    //     filter: true,
    //     className: "abcd",
    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",
    //         left: "0",
    //         alignItem: "center",
    //         padding: "1px 1px",
    //         background: "#143b64",
    //         minWidth: "140px",
    //         color: "white",
    //         position: "sticky",
    //         zIndex: 100,
    //         outline: "1.5px solid black"
    //       },
    //     }),
    //     setCellProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",
    //         left: "0",
    //         overflowY: "hidden",
    //         minWidth: "140px",
    //         padding: "1px 1px",
    //         position: "sticky",
    //         background: "White",
    //         zIndex: 99,
    //       },
    //     }),
    //   }
    // },


    {
      // "name": "RemarksLSSVSSSCOPStatus",
      "name": "SSVStatus",
      "label": "SSV Status (Pending, Submitted, Approved)",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "240px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },




    {
      "name": "RemarksLSSVSSSCOPStatusModal",
      "label": "SSV Approval Remark",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "200px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "200px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "SSSCOPApprovalDate",
      "label": "SSV Approval Date",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "SSVApprovalWeek",
      "label": "SSV Approval Week",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "SSSCOPApprovedby",
      "label": "SSV Approved By",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "SSVCommentIssueModal",
      "label": "Comments/issue",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },

    {
      "name": "latitude",
      "label": "Latitude",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "longitude",
      "label": "Longitude",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },

    // {
    //   "name": "edit",
    //   "label": "Edit",
    //   "sortable": false,
    //   "options": {
    //     filter: true,
    //     className: "abcd",
    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",
    //         left: "0",
    //         alignItem: "center",
    //         padding: "1px 1px",
    //         background: "#143b64",
    //         minWidth: "140px",
    //         color: "white",
    //         position: "sticky",
    //         zIndex: 100,
    //         outline: "1.5px solid black"
    //       },
    //     }),
    //     setCellProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",
    //         left: "0",
    //         overflowY: "hidden",
    //         minWidth: "140px",
    //         padding: "1px 1px",
    //         position: "sticky",
    //         background: "White",
    //         zIndex: 99,
    //       },
    //     }),
    //   }
    // }
  ]


  useEffect(() => {
    dispatch(AccountReducerAction(SSVDashboardAPI));
  }, [])
  return (
    <div>
      <CommonModal
        modalSize={'md'}
        setXlShow={setModalState}
        xlShow={ssvEngineerModal}
        Body={modalBody.body}
        Header={modalBody.heading}
      />
      <CommonModal
        modalSize={commonModalSize}
        setXlShow={setcommonModalState}
        xlShow={commonModalState}
        Body={commonModalBody}
        Header={commonModalHeader}
        Title={commonModalTitle}
      ></CommonModal>

      <ThemeProvider theme={getMuiThemeCommon()}>
        <MUIDataTable
          data={count.rowData}
          columns={columns}
          options={advancdExportOnecustomDataTableOptionObject(AddSSV, false, "ranDashbord",)}
        />
      </ThemeProvider>
    </div>
  )
}

export default SSVDashboard