import React, { useEffect, useState } from 'react';
const ChartTable = ({ header, data }) => {
    return <>
        <table class="chartTable cstmtable_scorecard">
            <tr>
                {header.map((column, colIndex) => {
                    return colIndex==0?<th className='chartTable-heading chartTable-datath' scope="row" key={column}>{column}</th>:<th className='chartTable-heading chartTable-datath' key={column}>{column}</th>
                })}
            </tr>
            {data.map((row, rowIndex) => (

                <tr key={rowIndex}>
                    {header.map((column, colIndex) => {
                        return colIndex==0?<td style={{textAlignLast: "left"}} className='chartTable-heading chartTable-datath' scope="row">{row[column]}</td>:<td className='chartTable-data' style={{height:"10px",textAlignLast: "left"}} key={colIndex}>{row[column]}</td>
                    })}
                </tr>
            ))}
        </table>
    </>
}

export default ChartTable;
