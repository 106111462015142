import React from 'react'

const POSTIXDashBoard = () => {
  return (
    <>
        <h4>POST IX RF SUPPORT Dashboard</h4>
    </>
  )
}

export default POSTIXDashBoard