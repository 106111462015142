import { lazy, Suspense, useEffect } from 'react';
/// Components
import Index from './jsx/index';
import { connect, useDispatch, useSelector } from 'react-redux';
import {  Route, Switch, useLocation, withRouter } from 'react-router-dom';
// action
import { checkAutoLogin } from './services/AuthService';
import { isAuthenticated } from './store/selectors/AuthSelectors';
import useLogout from './services/useAutoLogout';

/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import WebSocketComponent from './services/WebSocketComponent';



const SignUp = lazy(() => import('./jsx/pages/Registration'));
const ForgotPassword = lazy(() => import('./jsx/pages/ForgotPassword'));
const Login = lazy(() => {
    return new Promise(resolve => {
		setTimeout(() => resolve(import('./jsx/pages/Login')), 500);
	});
});
function App (props) {
    const dispatch = useDispatch();
    let loader =useSelector((state)=>{
        let val=state.spinnerState.spinState
        console.log("the loader state=",val,state)
        return {val}
    })
    console.log("rh kfnfkjngrgvsrg=",loader)
    // if(props.history.location.pathname != "/login"){
        const timer = useLogout(1800,dispatch,props.history); //idle logout timer is 30 mins
    // }/
    useEffect(() => {
        checkAutoLogin(dispatch, props.history);
    }, [dispatch, props.history]);
    
    let routes = (  
        <Switch>
            <Route path='/login' component={Login} />
            <Route path='/page-register' component={SignUp} />
            <Route path='/page-forgot-password' component={ForgotPassword} />
        </Switch>
    );
    if (props.isAuthenticated) {
		return (
			<fregment >

                {/* <WebSocketComponent/> */}
                <Suspense fallback={
                    <div id="preloader">

                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>  
                   }
                >
                    <div className={loader.val?'darkBG':""}>

                 {
                    loader.val?
                    <center> <span className="loader"></span></center>
                    :""
                 }   
                 </div>
                    <Index />
                </Suspense>
            </fregment>
        );
	
	}else{
		return (
			<div className="vh-100">
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                  }
                >
                    {routes}
                </Suspense>
			</div>
		);
	}
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
    };
};

export default withRouter(connect(mapStateToProps)(App)); 
