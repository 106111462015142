import React, { Fragment, useState, useEffect } from "react";
import "../../../../css/customStyle.css";
import { SplitButton, ButtonGroup, Dropdown, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import { useForm } from "react-hook-form";
import { NetworkDatabaseAction } from "../../../../store/actions/dashboardActions";
import "../../../../css/formError.css";
import moment from "moment"
import { addCrNumber, allTechnologyApi, listSiteId, listUserApi_two, managEngineer, managTMOEngineer, networkOneSiteDatabaseprojectId, OneSiteDatabase, OneSiteDatabaseProjectCode, projectCode, ranAddSupport, ranOneSiteDatabaseprojectId, ranOneSiteProjectCode, singleProjectApi, updateranticket } from "../../../../common/config";
import { AccountReducerAction, addUserAction, popUpUpdate, TierListReducerAction, UserListReducerAction } from "../../../../store/actions/createTicketManageFields";

import { FetchSingleRecordAction } from "../../../../store/actions/createTicketManageFields";
import { default as ReactSelect, components } from "react-select";
import { getValue } from "@mui/system";

import { Tab, Tabs } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import DatePicker from "react-datepicker";
import { es } from "date-fns/locale";

const AdvancedCommonCrew = ({label,visible,register,setValue,errors, crewType, currentValueName, currentValueId,disenb=false,sst=false,setsst}) => {

    console.log(register,setValue,errors, crewType, currentValueName, currentValueId, "accordianOperneraccordianOperneraccordianOperner")
    const url = window.location.href.split("/")
    let urlindexid = url.pop()

    // const {
    //     register,
    //     handleSubmit,
    //     setValue,
    //     getValues,
    //     formState: { errors },
    // } = useForm({});
    let [cxixtype, setcxixtype] = useState(true)
    const dispatch = useDispatch();
    const [onetimer, setonetimer] = useState(true);
    const [onentimer, setonentimer] = useState(true);
    let [accordianOperner, setAccordianOperner] = useState()
    const [show, setShow] = useState(true);
    console.log(accordianOperner, "accordianOperneraccordianOperneraccordianOperner")
    const [cxArray, setcx] = useState([]);
    const [ixArray, setix] = useState([]);
    const [ncurrentValueId, setcurrentValueId] = useState();

    let array_cx_main = [];
    let array_cx_data = [];
    let array_ix_data = [];
    let array_ix_main = [];
    let listalldata = [];
    const [ixshowinputdata, setixshowinputdata] = useState();
    const [cxshowinputdata, setcxshowinputdata] = useState();

    let [CustomError, setCustomError] = useState({

    });

    if(onentimer){
        console.log(crewType,"setonentimer")
        if(crewType){
            setonentimer(false)
            setAccordianOperner(crewType)
            setcurrentValueId(currentValueId)
            
        }
    }

    let [SelCheckBox, setSelCheckBox] = useState('')
    let history = useHistory()


    const onixOptionChange = (val) => {

        // let val = e.target.value;
        setSelCheckBox(val)
        console.log(val,"valvalval")
        array_ix_main.push(
            <option value={""}>
                Select
            </option>
        );

        if (listalldata) {
            listalldata.map((item) => {
                if (item.crewType == "IX") {
                    if (val == "ixLeadName") {
                        array_ix_main.push(<option value={item.uniqueid}>{item.name}</option>);
                    } else if (val == "ixEmail") {
                        array_ix_main.push(<option value={item.uniqueid}>{item.email}</option>);
                    } else if (val == "ixContactNumber") {
                        array_ix_main.push(<option value={item.uniqueid}>{item.mobilenumber}</option>);
                    }
                }
            })
        }

        setix(array_ix_main)
        setcx([]);
    };

    const handleChange = (event, nane, arra) => {
        console.log(event, nane,"event, nane")
        if(sst){
            setsst(false)     
        } 
        if(event==0){
            setcurrentValueId()
            setcxshowinputdata()
            setixshowinputdata()
            setValue("cxCrewId","")
            setValue("ixCrewId","")
        }else{
            let ind = event - 1;
        console.log("check_code", ind, nane, array_ix_data, array_cx_data);

        if (nane == "IXCrew") {
            if (array_ix_data[ind]) {
                setValue("ixCrewId",array_ix_data[ind].uniqueid)
                setValue("cxCrewId","")
                setcxshowinputdata()
                setcurrentValueId(array_ix_data[ind].uniqueid)
                console.log(array_ix_data[ind],"array_x_data[ind]")
                // setValue(nane + "Name", array_ix_data[ind].name);
                // setValue(nane + "Email", array_ix_data[ind].email);
                // setValue(nane + "Phone", array_ix_data[ind].mobilenumber);
                // setValue(nane + "Vendor", array_ix_data[ind].vendorresult[0].vendorName)
                // setValue(nane + "Company", array_ix_data[ind].crewresult[0].crewCompanyName)

                setixshowinputdata(array_ix_data[ind])
            }
        } else {
            if (array_cx_data[ind]) {
                setValue("cxCrewId",array_cx_data[ind].uniqueid)
                setValue("ixCrewId","")
                setcurrentValueId(array_cx_data[ind].uniqueid)
                setixshowinputdata()
                setcxshowinputdata(array_cx_data[ind])
                console.log(array_cx_data[ind],"array_x_data")
                // setValue(nane + "Name", array_cx_data[ind].name);
                // setValue(nane + "Email", array_cx_data[ind].email);
                // setValue(nane + "Phone", array_cx_data[ind].mobilenumber);
                // setValue(nane + "Vendor", array_cx_data[ind].vendorresult[0].vendorName)
                // setValue(nane + "Company", array_cx_data[ind].crewresult[0].crewCompanyName)
            }
        }
        }

    };

    const oncxOptionChange = (val) => {
        console.log(val,"valvalval")

        // let val = e.target.value;
        setSelCheckBox(val)
        
        array_cx_main.push(
            <option value={""}>
                Select
            </option>
        );
        if (listalldata) {
            listalldata.map((item) => {
                if (item.crewType == "CX") {
                    if (val == "cxLeadName") {
                        array_cx_main.push(<option value={item.uniqueid}>{item.name}</option>);
                    } else if (val == "cxEmail") {
                        array_cx_main.push(<option value={item.uniqueid}>{item.email}</option>);
                    } else if (val == "cxContactNumber") {
                        array_cx_main.push(<option value={item.uniqueid}>{item.mobilenumber}</option>);
                    }
                }
            })
        }
        setcx(array_cx_main);
        setix([]);
    };

    console.log(listalldata, "listalldataamit")

    let data = useSelector((state) => {

        
        console.log(state, "statestatestate");
        // let list_user=state
        listalldata = state.CommonResponse.listuser;
        console.log(listalldata, "listalldata")
        listalldata.map((item) => {
            if (item.crewType == "CX") {
                array_cx_data.push(item);
            }
            if (item.crewType == "IX") {
                array_ix_data.push(item);
            }
        })


        if (show && listalldata.length > 0) {
            setShow(false)
            console.log(crewType, "setAccordianOperner",ncurrentValueId)
            if (crewType == "CXCrew") {
                console.log("listalldatacxLeadName")
                setSelCheckBox("cxLeadName")
                oncxOptionChange("cxLeadName")
                setValue("cxCrewId",ncurrentValueId)
            } else {
                console.log("cxLeadName")
                setSelCheckBox("ixLeadName")
                onixOptionChange("ixLeadName")
                setValue("ixCrewId",ncurrentValueId)
            }
        }


        if (onetimer && crewType && ncurrentValueId  && ncurrentValueId!='') {
            console.log(array_cx_data,ncurrentValueId,array_ix_data,"listalldataone")
            if (crewType == "CXCrew") {
                if(array_cx_data.length > 0){    
                    let valuenum = array_cx_data.findIndex(listalldataone => ncurrentValueId == listalldataone.uniqueid)
                    handleChange(valuenum + 1, crewType)
                    setonetimer(false)
                }
            } else if (crewType == "IXCrew") {
                if(array_ix_data.length > 0){
                    let valuenum = array_ix_data.findIndex(listalldataone => ncurrentValueId == listalldataone.uniqueid)
                    handleChange(valuenum + 1, crewType)
                    setonetimer(false)
                }
            }
            // if(ncurrentValueId==""){
            //     setonetimer(false)
            // }
        }


        return { array_cx_data, array_ix_data };
    });

    console.log(data, "datadatadata")
   

    useEffect(() => {
        dispatch(UserListReducerAction(listUserApi_two));
        if(sst){
            handleChange(0, 'CXCrew')
        }
    }, [sst]);

    if(sst){
        console.log("callccs",sst)
        setsst(false)        
        console.log("callcce",sst)
        // handleChange(0, 'CXCrew')
    }

    console.log(errors, Object.keys(errors).length, CustomError, "errors")
    console.log(accordianOperner,"accordianOperneraccordianOperner")
    function accordianchange(e) {

        // setshowinputdata()
        // setcurrentValueId()
        console.log(e, "accordianchange")
        if ("CXCrew" == e) {
            setSelCheckBox("cxLeadName")
            oncxOptionChange("cxLeadName")
        } else if ("IXCrew" == e) {
            setSelCheckBox("ixLeadName")
            onixOptionChange("ixLeadName")
        }
        setAccordianOperner(e)
    }
    return (
        <Fragment>
            {/* <PageTItle activeMenu='validation' motherMenu='Form' /> */}

            <div className="card" style={{ width: "100%", marginTop: "0rem" }}>
                {console.log(accordianOperner=="IXCrew", "accordianOperner")}
                
                {visible=="both"?
                <Tabs
                    defaultActiveKey={accordianOperner}
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    onSelect={(e) => {
                        accordianchange(e)
                    }}
                >

                    <Tab eventKey="CXCrew" title="CX Crew">
                        <div className="accordion-body">
                            <div className="form-row mt-2 p-2">
                                <div className="form-group col-md-4">
                                    <label for="exampleFormControlSelect1">
                                        CX Search By work----
                                    </label>

                                    <div>
                                        <div class="form-check-inline">
                                            <label class="form-check-label">
                                                <input
                                                    style={{ height: "20px" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    value="cxContactNumber"
                                                    onChange={(e) => { oncxOptionChange(e.target.value) }}
                                                    name="optradio"
                                                    checked={SelCheckBox === "cxContactNumber"}
                                                />
                                                Contact Number
                                            </label>
                                        </div>
                                        <div class="form-check-inline">
                                            <label class="form-check-label">
                                                <input
                                                    style={{ height: "20px" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    value="cxEmail"
                                                    onChange={(e) => { oncxOptionChange(e.target.value) }}
                                                    name="optradio"
                                                    checked={SelCheckBox === "cxEmail"}
                                                />
                                                E-mail
                                            </label>
                                        </div>
                                        <div class="form-check-inline disabled">
                                            <label class="form-check-label">
                                                <input
                                                    style={{ height: "20px" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    value="cxLeadName"
                                                    onChange={(e) => { oncxOptionChange(e.target.value) }}
                                                    name="optradio"
                                                    checked={SelCheckBox === "cxLeadName"}
                                                />
                                                Lead Name
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-md-5">
                                    <label for="exampleFormControlSelect1">
                                        <span style={{ color: "red" }}>*</span>
                                        Enter Lead Name
                                    </label>

                                    {console.log(ncurrentValueId,"currentValueIdcurrentValueId")}

                                    <select style={{ height: "32px" }}
                                        className="form-control"
                                        id="exampleFormControlSelect1"
                                        defaultValue={ncurrentValueId}
                                        value={ncurrentValueId}
                                        // {...register("cxCrewId", {

                                        //   onChange: (e) => {
                                        //     handleChange(e, "CXCrew", "b");
                                        //   },
                                        // })}

                                        {...accordianOperner == "CXCrew" ?
                                            {
                                                ...register("cxCrewId", {
                                                    required: "Please Select CX Crew",
                                                    onChange: (e) => {
                                                        handleChange(e.target.selectedIndex, "CXCrew", "b");
                                                    }
                                                })
                                            } :
                                            {
                                                ...register("cxCrewId", {
                                                    required: false
                                                })
                                            }}
                                    >
                                        {cxArray}

                                    </select>
                                    {errors.cxCrewId && (
                                        <p className="error">{errors.cxCrewId.message}</p>
                                    )}
                                </div>{" "}
                                <div className="form-group col-md-2 mt-2">
                                    <label for="exampleFormControlSelect1"></label>
                                </div>
                                <div className="form-group col-md-4">
                                    <label for="exampleFormControlSelect1">
                                        {label}CX Crew Vendor
                                    </label>

                                    <input
                                        style={{ height: "32px" }}
                                        type="text"
                                        className="form-control"
                                        readOnly
                                        value={cxshowinputdata?cxshowinputdata.vendorresult[0]?cxshowinputdata.vendorresult[0].vendorName:"":""}
                                    />
                                </div>
                                <div className="form-group col-md-4 ">
                                    <label for="exampleFormControlSelect1">
                                    {label}CX Crew Lead Contact Number
                                    </label>
                                    <input
                                        style={{ height: "32px" }}
                                        type="text"
                                        className="form-control"
                                        readOnly
                                        value={cxshowinputdata?cxshowinputdata.mobilenumber:""}
                                        
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <label for="exampleFormControlSelect1">
                                    {label}CX Crew Lead Name
                                    </label>
                                    <input
                                        style={{ height: "32px" }}
                                        type="text"
                                        className="form-control"
                                        id="input "
                                        readOnly
                                        value={cxshowinputdata?cxshowinputdata.name:""}
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <label for="exampleFormControlSelect1">
                                    {label}CX Crew Lead Company{" "}
                                    </label>
                                    <input
                                        style={{ height: "32px" }}
                                        type="text"
                                        className="form-control"
                                        id="input "
                                        readOnly
                                        value={cxshowinputdata?cxshowinputdata.crewresult[0]?cxshowinputdata.crewresult[0].crewCompanyName:"":""}
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <label for="exampleFormControlSelect1">
                                    {label}CX Crew Lead E-mail ID
                                    </label>
                                    <input
                                        style={{ height: "32px" }}
                                        type="text"
                                        className="form-control"
                                        id="input "
                                        readOnly
                                        value={cxshowinputdata?cxshowinputdata.email:""}
                                    />
                                </div>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="IXCrew" title="IX Crew">

                        <div className="accordion-body">
                            <div className="form-row mt-2 p-2">
                                <div className="form-group col-md-4">
                                    <label for="exampleFormControlSelect1">
                                        IX Search By----
                                    </label>

                                    <div>
                                        <div class="form-check-inline">
                                            <label class="form-check-label">
                                                <input
                                                    style={{ height: "20px" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    value="ixContactNumber"
                                                    onChange={(e) => { onixOptionChange(e.target.value) }}
                                                    name="optradio"
                                                    checked={SelCheckBox === "ixContactNumber"}
                                                />
                                                Contact Number
                                            </label>
                                        </div>
                                        <div class="form-check-inline">
                                            <label class="form-check-label">
                                                <input
                                                    style={{ height: "20px" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    value="ixEmail"
                                                    onChange={(e) => { onixOptionChange(e.target.value) }}
                                                    name="optradio"
                                                    checked={SelCheckBox === "ixEmail"}
                                                />
                                                E-mail
                                            </label>
                                        </div>
                                        <div class="form-check-inline disabled">
                                            <label class="form-check-label">
                                                <input
                                                    style={{ height: "20px" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    value="ixLeadName"
                                                    onChange={(e) => { onixOptionChange(e.target.value) }}
                                                    name="optradio"
                                                    checked={SelCheckBox === "ixLeadName"}
                                                />
                                                Lead Name
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-md-5">
                                    <label for="exampleFormControlSelect1">
                                        <span style={{ color: "red" }}>*</span>
                                        Enter Lead Name
                                    </label>


                                    <select style={{ height: "32px" }}
                                        className="form-control"
                                        id="exampleFormControlSelect1"
                                        defaultValue={ncurrentValueId}
                                        value={ncurrentValueId}

                                        {...accordianOperner == "IXCrew" ?
                                            {
                                                ...register("ixCrewId", {
                                                    required: "Please Select IX Crew",
                                                    onChange: (e) => {
                                                        handleChange(e.target.selectedIndex, "IXCrew", "b");
                                                    }
                                                })
                                            } :
                                            {
                                                ...register("ixCrewId", {
                                                    required: false
                                                })
                                            }}
                                    >
                                        {ixArray}
                                    </select>
                                    {errors.ixCrewId && (
                                        <p className="error">{errors.ixCrewId.message}</p>
                                    )}
                                </div>{" "}
                                <div className="form-group col-md-2 mt-2">
                                    <label for="exampleFormControlSelect1"></label>
                                </div>
                                <div className="form-group col-md-4">
                                    <label for="exampleFormControlSelect1">
                                    {label}IX Crew Vendor
                                    </label>
                                    <input
                                        style={{ height: "32px" }}
                                        type="text"
                                        className="form-control"
                                        readOnly
                                        value={ixshowinputdata?ixshowinputdata.vendorresult[0]?ixshowinputdata.vendorresult[0].vendorName:"":""}
                                    />
                                </div>
                                <div className="form-group col-md-4 ">
                                    <label for="exampleFormControlSelect1">
                                    {label} IX Crew Lead Contact Number
                                    </label>
                                    <input
                                        style={{ height: "32px" }}
                                        type="text"
                                        className="form-control"
                                        readOnly
                                        value={ixshowinputdata?ixshowinputdata.mobilenumber:""}
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <label for="exampleFormControlSelect1">
                                    {label}IX Crew Lead Name
                                    </label>
                                    <input
                                        style={{ height: "32px" }}
                                        type="text"
                                        className="form-control"
                                        id="input "
                                        readOnly
                                        value={ixshowinputdata?ixshowinputdata.name:""}
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <label for="exampleFormControlSelect1">
                                    {label}IX Crew Lead Company{" "}
                                    </label>
                                    <input
                                        style={{ height: "32px" }}
                                        type="text"
                                        className="form-control"
                                        id="input "
                                        readOnly
                                        value={ixshowinputdata?ixshowinputdata.crewresult[0]?ixshowinputdata.crewresult[0].crewCompanyName:"":""}
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <label for="exampleFormControlSelect1">
                                    {label}IX Crew Lead E-mail ID
                                    </label>
                                    <input
                                        style={{ height: "32px" }}
                                        type="text"
                                        className="form-control"
                                        id="input "
                                        readOnly
                                        value={ixshowinputdata?ixshowinputdata.email:""}
                                    />
                                </div>
                            </div>
                        </div>
                    </Tab>
                </Tabs>:
                visible=="CX"?
                <div className="accordion-body">
                            <div className="form-row mt-2 p-2">
                                <div className="form-group col-md-4">
                                    <label for="exampleFormControlSelect1">
                                        CX Search By work----
                                    </label>

                                    <div>
                                        <div class="form-check-inline">
                                            <label class="form-check-label">
                                                <input
                                                    style={{ height: "20px" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    value="cxContactNumber"
                                                    onChange={(e) => { oncxOptionChange(e.target.value) }}
                                                    name="optradio"
                                                    checked={SelCheckBox === "cxContactNumber"}
                                                />
                                                Contact Number
                                            </label>
                                        </div>
                                        <div class="form-check-inline">
                                            <label class="form-check-label">
                                                <input
                                                    style={{ height: "20px" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    value="cxEmail"
                                                    onChange={(e) => { oncxOptionChange(e.target.value) }}
                                                    name="optradio"
                                                    checked={SelCheckBox === "cxEmail"}
                                                />
                                                E-mail
                                            </label>
                                        </div>
                                        <div class="form-check-inline disabled">
                                            <label class="form-check-label">
                                                <input
                                                    style={{ height: "20px" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    value="cxLeadName"
                                                    onChange={(e) => { oncxOptionChange(e.target.value) }}
                                                    name="optradio"
                                                    checked={SelCheckBox === "cxLeadName"}
                                                />
                                                Lead Name
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-md-5">
                                    <label for="exampleFormControlSelect1">
                                        <span style={{ color: "red" }}>*</span>
                                        Enter Lead Name
                                    </label>

                                    {console.log(ncurrentValueId,"currentValueIdcurrentValueId")}

                                    <select style={{ height: "32px" }}
                                        className="form-control"
                                        id="exampleFormControlSelect1"
                                        defaultValue={ncurrentValueId}
                                        value={ncurrentValueId}
                                        // {...register("cxCrewId", {

                                        //   onChange: (e) => {
                                        //     handleChange(e, "CXCrew", "b");
                                        //   },
                                        // })}

                                        {
                                            ...register("cxCrewId", {
                                                required: "Please Select CX Crew",
                                                onChange: (e) => {
                                                    handleChange(e.target.selectedIndex, "CXCrew", "b");
                                                }
                                            })
                                        }
                                    >
                                        {cxArray}

                                    </select>
                                    {errors.cxCrewId && (
                                        <p className="error">{errors.cxCrewId.message}</p>
                                    )}
                                </div>{" "}
                                <div className="form-group col-md-2 mt-2">
                                    <label for="exampleFormControlSelect1"></label>
                                </div>
                                <div className="form-group col-md-4">
                                    <label for="exampleFormControlSelect1">
                                        {label}CX Crew Vendor
                                    </label>

                                    <input
                                        style={{ height: "32px" }}
                                        type="text"
                                        className="form-control"
                                        readOnly
                                        value={cxshowinputdata?cxshowinputdata.vendorresult[0]?cxshowinputdata.vendorresult[0].vendorName:"":""}
                                    />
                                </div>
                                <div className="form-group col-md-4 ">
                                    <label for="exampleFormControlSelect1">
                                    {label}CX Crew Lead Contact Number
                                    </label>
                                    <input
                                        style={{ height: "32px" }}
                                        type="text"
                                        className="form-control"
                                        readOnly
                                        value={cxshowinputdata?cxshowinputdata.mobilenumber:""}
                                        
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <label for="exampleFormControlSelect1">
                                    {label}CX Crew Lead Name
                                    </label>
                                    <input
                                        style={{ height: "32px" }}
                                        type="text"
                                        className="form-control"
                                        id="input "
                                        readOnly
                                        value={cxshowinputdata?cxshowinputdata.name:""}
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <label for="exampleFormControlSelect1">
                                    {label}CX Crew Lead Company{" "}
                                    </label>
                                    <input
                                        style={{ height: "32px" }}
                                        type="text"
                                        className="form-control"
                                        id="input "
                                        readOnly
                                        value={cxshowinputdata?cxshowinputdata.crewresult[0]?cxshowinputdata.crewresult[0].crewCompanyName:"":""}
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <label for="exampleFormControlSelect1">
                                    {label}CX Crew Lead E-mail ID
                                    </label>
                                    <input
                                        style={{ height: "32px" }}
                                        type="text"
                                        className="form-control"
                                        id="input "
                                        readOnly
                                        value={cxshowinputdata?cxshowinputdata.email:""}
                                    />
                                </div>
                            </div>
                        </div>:
                        
                        <div className="accordion-body">
                            <div className="form-row mt-2 p-2">
                                {disenb==false?<>
                                <div className="form-group col-md-4">
                                    <label for="exampleFormControlSelect1">
                                        IX Search By----
                                    </label>

                                    <div>
                                        <div class="form-check-inline">
                                            <label class="form-check-label">
                                                <input
                                                    style={{ height: "20px" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    value="ixContactNumber"
                                                    onChange={(e) => { onixOptionChange(e.target.value) }}
                                                    name="optradio"
                                                    checked={SelCheckBox === "ixContactNumber"}
                                                />
                                                Contact Number
                                            </label>
                                        </div>
                                        <div class="form-check-inline">
                                            <label class="form-check-label">
                                                <input
                                                    style={{ height: "20px" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    value="ixEmail"
                                                    onChange={(e) => { onixOptionChange(e.target.value) }}
                                                    name="optradio"
                                                    checked={SelCheckBox === "ixEmail"}
                                                />
                                                E-mail
                                            </label>
                                        </div>
                                        <div class="form-check-inline disabled">
                                            <label class="form-check-label">
                                                <input
                                                    style={{ height: "20px" }}
                                                    type="radio"
                                                    className="form-check-input"
                                                    value="ixLeadName"
                                                    onChange={(e) => { onixOptionChange(e.target.value) }}
                                                    name="optradio"
                                                    checked={SelCheckBox === "ixLeadName"}
                                                />
                                                Lead Name
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-md-5">
                                    <label for="exampleFormControlSelect1">
                                        <span style={{ color: "red" }}>*</span>
                                        Enter Lead Name
                                    </label>


                                    <select style={{ height: "32px" }}
                                        className="form-control"
                                        id="exampleFormControlSelect1"
                                        defaultValue={ncurrentValueId}
                                        value={ncurrentValueId}

                                        {
                                            ...register("ixCrewId", {
                                                required: "Please Select IX Crew",
                                                onChange: (e) => {
                                                    handleChange(e.target.selectedIndex, "IXCrew", "b");
                                                }
                                            })
                                        }
                                    >
                                        {ixArray}
                                    </select>
                                    {errors.ixCrewId && (
                                        <p className="error">{errors.ixCrewId.message}</p>
                                    )}
                                </div>{" "}
                                </>:""}
                                {/* <div className="form-group col-md-2 mt-2">
                                    <label for="exampleFormControlSelect1"></label>
                                </div> */}
                                <div className="form-group col-md-4">
                                    <label for="exampleFormControlSelect1">
                                    {label}IX Crew Vendor
                                    </label>
                                    <input
                                        style={{ height: "32px" }}
                                        type="text"
                                        className="form-control"
                                        readOnly
                                        value={ixshowinputdata?ixshowinputdata.vendorresult[0]?ixshowinputdata.vendorresult[0].vendorName:"":""}
                                    />
                                </div>
                                <div className="form-group col-md-4 ">
                                    <label for="exampleFormControlSelect1">
                                    {label} IX Crew Lead Contact Number
                                    </label>
                                    <input
                                        style={{ height: "32px" }}
                                        type="text"
                                        className="form-control"
                                        readOnly
                                        value={ixshowinputdata?ixshowinputdata.mobilenumber:""}
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <label for="exampleFormControlSelect1">
                                    {label}IX Crew Lead Name
                                    </label>
                                    <input
                                        style={{ height: "32px" }}
                                        type="text"
                                        className="form-control"
                                        id="input "
                                        readOnly
                                        value={ixshowinputdata?ixshowinputdata.name:""}
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <label for="exampleFormControlSelect1">
                                    {label}IX Crew Lead Company{" "}
                                    </label>
                                    <input
                                        style={{ height: "32px" }}
                                        type="text"
                                        className="form-control"
                                        id="input "
                                        readOnly
                                        value={ixshowinputdata?ixshowinputdata.crewresult[0]?ixshowinputdata.crewresult[0].crewCompanyName:"":""}
                                    />
                                </div>
                                <div className="form-group col-md-4">
                                    <label for="exampleFormControlSelect1">
                                    {label}IX Crew Lead E-mail ID
                                    </label>
                                    <input
                                        style={{ height: "32px" }}
                                        type="text"
                                        className="form-control"
                                        id="input "
                                        readOnly
                                        value={ixshowinputdata?ixshowinputdata.email:""}
                                    />
                                </div>
                            </div>
                        </div>}

            </div>
        </Fragment>
    );
};

export default AdvancedCommonCrew;
