import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getAllDropDown, getAllDropDownList } from '../../../../common/config'
import TableComponent from '../../../../common/TableComponent'
import { manageDropdownColumn } from '../../../../common/variables'
import { AccountReducerAction, SingleDropDownAction, deleteAcccountReducerAction } from '../../../../store/actions/createTicketManageFields'
import SingleFieldModal from '../../Custom/SingleFieldModal'

const Ndsite = ({ change }) => {
  let id=''
  if(change && change.uniqueId){
    id=change.uniqueId
  }
  // console.log(change.uniqueId,"changechange")

  let sowId = ''
  const [modalState, setModal] = useState(false)
  const [count, setCount] = useState(1)
  const [oldData, setOldData] = useState(1)
  const [Type, setType] = useState("")
  let rowData = []

  let optionArray = []

  const dispatch = useDispatch()
  const handleClick = (typee) => {
    setModal(true)
    setOldData({})
    setType(typee)
  }
  const addCustomToolbar = () => (
    <button className='btn btn-primary btn-xs mx-2 py-2 my-3' onClick={(e)=>{handleClick("Add")}}>Add</button>
  )

  const editpopupHandler = (item) => {
    <button className='btn btn-xs btn-primary' onClick={(e)=>{handleClick("Edit")}}>Edit</button>
    setType("Edit")
    setOldData(item)
    setModal(true)
  }

 
  const deleteHandler = (item) => {
    <button className='btn btn-xs btn-primary' onClick={(e)=>{handleClick("Delete")}}>Delete</button>
    dispatch(deleteAcccountReducerAction(getAllDropDownList,item,change.dropDownName))
    // Delete_data(item)
  }

  let indexing = 0


  let alldata = useSelector((state) => {

    optionArray=[]
    let options = state.CommonResponse.singleDropDown
    if (options && options.length > 0) {
      optionArray = options.map((item,index) => {
        return {
          sNo: index + 1,
          optionValue: item.optionValue,
          action: <div className="d-flex">
            <Link
              href="#"
              onClick={() => { editpopupHandler(item); }}
              className="btn btn-primary shadow btn-xs sharp mr-1 editButton"
            >
              <i className="fa fa-pencil"></i>
            </Link>
            <Link
              href="#"
              onClick={(e) => deleteHandler(item.uniqueId)}
              className="btn btn-danger shadow btn-xs sharp deleteButton"
            >
              <i className="fa fa-trash"></i>
            </Link>
          </div>
        }
      })
    }

    return { optionArray }
  })

  // use effect
  console.log(alldata, "alldataalldata")





  useEffect(() => {
    
    dispatch(SingleDropDownAction([]))
    if (change && change.dropDownName == "ND-Site Type") {
      dispatch(AccountReducerAction(getAllDropDown + "/" + change.uniqueId))
    }
  }, [count])
  return (
    <>
      {/* <SingleFieldModal modalState={modalState} oldData={oldData} type={Type} setModalState={setModal} parentuniqueId={change.uniqueId} fromName={"ND-Site Type"} label={"ND-Site Type Name"} /> */}
      {/* <TableComponent from={"ND-Site Type"} rowData={alldata.optionArray} columns={manageDropdownColumn("optionValue", "ND-Site Type Name")} isDownload={true} addCustomToolbar={addCustomToolbar} /> */}
    </>
  )
}

export default Ndsite