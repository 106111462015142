import React, { useEffect } from 'react'
import { Tab, Tabs } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { addAcccount, allTechnologyApi, getAllDropDown, getAllDropDownList, goNoGoAction, rfApprovalActionChange, ttcmts } from '../../../../common/config';
import { addAcccountReducerAction, addCommentReducerAction } from '../../../../store/actions/createTicketManageFields';
// import { addAcccountReducerAction, updateAcccountReducerAction } from '../../../components/vendor/'
const HistoryCmts = (props) => {

  const { register, setValue, handleSubmit, getValues, watch, reset, formState: { errors } } = useForm({})
  const dispatch = useDispatch();

  console.log(props, "propspropspropsprops")

 
  const onSubmit = async (data) => {
    console.log("the data to submit is =", data)

    // data["dropDownId"]=props.parentuniqueId
    console.log(data, "data")

    data["type"]=props.from

    // if(props.type=="Add"){
    dispatch(addCommentReducerAction(props.urldata + "/" + props.uniqueId, data))
    // }
    // if(props.type=="Edit"){
    //   dispatch(updateAcccountReducerAction(getAllDropDownList,props.oldData.uniqueId,data))
    // }




    // propsprops

    // reset({})
    // setValue("value",'')
  }
  useEffect(() => {
    // reset({})
    // setValue("value",'')
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      

       <div className="form-group col-md-12">

        {props.isShow}
        {/* <label for="exampleFormControlSelect1"> */}
          {/* RF Site Status */}
        {/* </label> */}
        <textarea style={{ height: "40px" }}
          type="text"
          className="form-control"
          id="input "
          // placeholder="Enter Technology Name"
          {
          ...register("datata")
          }
        > </textarea>
        {errors.mopRFSiteStatus && (
          <p className="error">{errors.mopRFSiteStatus.message}</p>
        )}
      </div>
      <button type="submit" className="btn btn-primary btn-xs mt-3">
        {props.btnMsg}
      </button>
    </form>
  )
}

export default HistoryCmts