import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AccountReducerAction, LCLNestingReducerAction, LCLranDashboardReducerAction, getFilterDropDown } from '../../../../store/actions/createTicketManageFields'
import { mopDataApi, mopFilters, LSSVDashboardAPI, ranNesting_tracker } from '../../../../common/config'
import { MopDatabaseAction } from '../../../../store/actions/dashboardActions'
import DatePicker from "react-datepicker";

const NestingFilter = ({setFilterBadge, setfullUrL, setcommonModalState, featureExport}) => {
    
    const dispatch =useDispatch()
    let compOptionArry=[]
    let sowListArry=[]
    let typeOfWorkArry=[]
    let vendorsArry=[]
    let psowArry=[]
    let siteID=[]
    let assignedEngineer=[]
    let dayNightMop=[]
    let sendData=useRef("")
    let filterOptions=useRef([])
    
    let fullURL="fc=True"
    let selectedFilter=[]

    const [fullFilter,setfullFilter]=useState({})
    let handleChange=(dropDown,e)=>{
        // if(e.target.value!=""){
            // if(filterOptions.current.indexOf(e.target.value)==-1){
            //     filterOptions.current.push(e.target.value)
            // }

            fullFilter[dropDown]=e.target.value
            // sendData.current=sendData.current +dropDown+"="+e.target.value+"&"
        console.warn("cknbdbkjreef=",sendData)
    // }
    

    setfullUrL(fullURL)
    featureExport(fullURL)

    console.log(fullFilter,fullURL,"fullFilterfullFilter")
    // fullFilter
    // fullFilter.map((oneFilter)=>{
    //   console.log(oneFilter,"oneFilteroneFilter")  
    // })


    

    }

    const submitData = ()=>{

        let selData=[]
        
        Object.keys(fullFilter).map((oneFilter,index) => {
        
            const value = fullFilter[oneFilter];
            selData.push(value)
            console.log(oneFilter,"oneFilteroneFilter")
            // if(index!=0){
            //     fullURL="?"+oneFilter+"="+value
            // }else{
                fullURL=fullURL+"&"+oneFilter+"="+encodeURIComponent(value)
            // }
            
        })
        selectedFilter=selData.map((item)=>{
            return <p className='badge badge-info'>{item}</p>
        })
        setFilterBadge(selectedFilter)

        let Filleddata=dispatch(LCLNestingReducerAction({filterUrl:`${"fc=True&"+fullURL}`}))
        
        dispatch(AccountReducerAction(ranNesting_tracker + Filleddata,true));
        setcommonModalState(false)
    }

    
    const count =useSelector((state)=>{
        console.log("the state is =", state);
        let filterObj =state.OneCommonResponse.filterDropDown
        console.warn("jxhbkjvbjacvkecfrwgvw=",filterObj)
        if(filterObj && Object.keys(filterObj).length>0){
           
            siteID =filterObj?.siteIdes.map((item,index)=>{
                return <option value={item}>{item}</option>
            })  
            
        }
        siteID.unshift(<option disabled selected value={""}>Select</option>)
        // (Site ID, FE Login Time, IX Login Time, FE Logout Time, IX Logout Time)
            let dropDown={
                "siteId":{"label":"Site ID","values":siteID ,"dType":"S"},
                "serviceAffected":{"label":"Service Affecting","dType":"M"},
                "nestTyper":{"label":"Un-Nested Time","dType":"M"},
            }
            // a) SSV Dashboard. --> (Site ID, RTT Date, UUU Date, Drive Date, SSV Submit Date, SSV Approval Date, RE submitted date)
            
            let dropdownSkel=Object.keys(dropDown).map((item,index)=>{
                
                // console.warn(",mdscbvbcvwchkuvcwsvr=",dropDown.item)
                console.warn(",mdscbvbcvwchkuvcwsvr=", dropDown[item]["values"])

                if(dropDown[item]['dType']=="S"){
                    return(
                        <div className='form-group col-3'> 
                            <label>{dropDown[item]["label"]}</label>
                            <input className='form-control' onChange={(e)=>handleChange(item,e)} list={index}
                                style={{ height: "32px" }}/>
                                <datalist id={index}>

                                {dropDown[item]["values"]}
                                </datalist>

                        </div>
                    )
                }
                else if(dropDown[item]['dType']=="M"){
                    return(
                        <div className='form-group col-3'> 
                            <label>{dropDown[item]["label"]}</label>
                            <select className='form-control' onChange={(e)=>handleChange(item,e)}
                                style={{ height: "32px" }}>
                                    
                                <option value={""}>Select</option>
                                <option value={"Yes"}>Yes</option>
                                <option value={"No"}>No</option>
                                </select>

                        </div>
                    )
                }            
                else if(dropDown[item]['dType']=="datePicker"){
                    return(
                        <div className='form-group col-3'> 
                            <label>{dropDown[item]["label"]}</label>
                            <input className='form-control' type="date" onChange={(e)=>handleChange(item,e)} style={{ height: "32px" }}/>
                        </div>
                    )
                }    


                else{
                    return(
                    <div className='form-group col-3'>
                        <label>{dropDown[item]["label"]}</label>
                        <select className='form-control' 
                        
                        onChange={(e)=>handleChange(item,e)}
                        >
                            {
                            dropDown[item]["values"]
                            }
                        </select>
                    </div>
                    )
                        }
            })
    
        return({filterObj,dropDown,dropdownSkel})
    })    


    useEffect(()=>{
        
        // dispatch(getFilterDropDown([]))
        // dispatch(AccountReducerAction(ranfilters))

    },[])
  return (
    <>
        <div className='row'>
            {count.dropdownSkel}

            {console.log(count.dropdownSkel,"count.dropdownSkel")}

        </div>

        <button onClick={(e)=>{
            submitData()
        }} className={'btn btn-xs btn-primary mx-2 py-2 btn btn-primary'}>SEARCH</button>
    </>
  )
}

export default NestingFilter