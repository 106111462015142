import React from 'react'

const CxIXApprovals = () => {
  return (
    <>
        <h4>Cx IX Approval</h4>
    </>
  )
}

export default CxIXApprovals