import React, { useEffect, useState } from 'react'
import { advancdExportOnecustomDataTableOptionObject, columnsArray, customDataTableOptionObject, digit_more_info, getMuiThemeCommon, wrapText } from '../../../common/variables'
import MUIDataTable from 'mui-datatables'
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link } from 'react-router-dom/cjs/react-router-dom';
import CommonModal from '../Custom/CommonModal';
import UpdateTraffic from '../Custom/Modal Body/UpdateTraffic';
import NNPBaseline from '../Custom/Modal Body/NNPBaseline';
import SSSCOP from '../Custom/Modal Body/SSSCOP';
import CommonBulkUploader from '../Custom/CommonBulkUploader';
import { LSSVDashboardAPI, SSVDashboardAPI, baseURL, commonfilters, dynamic_dashboard, export_lssv_dashboard, templatelssvurl } from '../../../common/config';
import { Button } from 'react-bootstrap';
import moment from "moment";
import { AccountReducerAction, ExportXLSXDownloadReducerAction, ResetLCLLSSVReducerAction, clearIxSingleSite, deleteAcccountReducerAction, getFilterDropDown, popUpUpdate } from '../../../store/actions/createTicketManageFields';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import FilterData from '../../../icons/Svg/FilterData';
import LSSVFilterList from '../Custom/Modal Body/LSSVFilterList';

import RefreshIcon from "@mui/icons-material/Refresh";
import CommonBulkUploaderButton from '../Custom/CommonBulkUploaderButton';
import Upload from '../../../icons/Svg/Upload';
import DownloadNew from '../../../icons/Svg/DownloadNew';
import InputBox from '../Custom/Modal Body/inputBox';
import SingleDateModal from '../Custom/Modal Body/SingleDateModal';
import SingleDateOnlyModal from '../Custom/Modal Body/SingleDateOnlyModal';

const LaunchSSS = () => {

  const [fullUrL, setfullUrL] = useState("");
  const [exportApiData, setexportApiData] = useState("");
  const [filterBadges, setFilterBadge] = useState()

  const [ResetFilter, setResetFilter] = useState(false);
  const deleteHandler = (e, url) => {
    console.log("button delete clicked", e)

    swal({
      title: "Are you sure?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          console.log("the will dele=", willDelete)
          setcommonModalState(true)
          dispatch(deleteAcccountReducerAction(url, e))
          dispatch(AccountReducerAction(LSSVDashboardAPI));
        }
      });
  }
  const dispatch = useDispatch();
  const [commonModalBody, setcommonModalBody] = useState("");
  const [commonModalState, setcommonModalState] = useState(false);
  const [commonModalHeader, setcommonModalHeader] = useState("");
  const [commonModalTitle, setcommonModalTitle] = useState("");
  const CommonModalFun = (body, title, header) => {
    setcommonModalBody(body);
    setcommonModalHeader(header);
    setcommonModalState(true);
    dispatch(clearIxSingleSite())
  };

  const options = useSelector((state) => {
    if (state.popUpState) {
      if (state.popUpState.popstatus.status == 201 && commonModalState) {
        setcommonModalState(false);
        dispatch(popUpUpdate({}));
        dispatch(AccountReducerAction(LSSVDashboardAPI))
      }
    }
    let sitesdata = state.CommonResponse.LaunchSSVData
    if (sitesdata && sitesdata.length > 0) {
      console.log("kjcbjksbchcbjsver", sitesdata)
      sitesdata.map((item) => {

        item["gcdata"] = item["crew_status"] ? item["crew_status"].crewCompanyName : ""

        item["SSSCOPApprovedBy"] = item?.approverresult?.name
        item["CommentsLaunchMS6Modal"] = (
          <p
            className={"text-black my-0 modalHoverClass"}
            data-toggle="tooltip"
            data-placement="top"
            title="Click Here For More Details"
            onClick={() =>
              CommonModalFun(
                <p
                  style={{
                    border: "2px solid black",
                    color: "black",
                    padding: 2,
                  }}
                  className="CommonModal"
                >
                  {item["CommentsLaunchMS6"]}
                </p>,
                "Comments - Launch MS6",
                "Program Name"
              )
            }
          >
            {wrapText(item["CommentsLaunchMS6"])}
          </p>
        )
        item["edit"] = <div className="d-flex"><Link to={"/PAG/launch/ssv/edit/" + item.ssvUniqueId} onClick={() => dispatch(clearIxSingleSite())} className="btn btn-primary shadow btn-xs sharp mr-1 d-flex justify-content-center align-items-center"><i className="fa fa-pencil"></i></Link> <button onClick={(e) => deleteHandler(item.ssvUniqueId, LSSVDashboardAPI)} className="btn btn-danger shadow btn-xs sharp d-flex justify-content-center align-items-center"><i className="fa fa-trash"></i></button></div>
        item["trafficCheckFlag"] = <p
          className='badge mx-auto badge-success'
          onClick={() =>
            CommonModalFun(
              // <LockUnlockModalBody apiType={"request"} oldData={[]} siteIdData={item.siteId} ranIdData={item.ranSupportId} />
              <UpdateTraffic id={item.ssvUniqueId} />
              ,
              "Traffic Check",
              "Traffic Check"
            )
          }
        >
          {"Update Check Status"}
        </p>

        item["SSVCommentIssueModal"] = <p
          className='badge badge-primary'
          onClick={() => {
            setcommonModalState(true);
            setcommonModalBody(<InputBox setCloser={setcommonModalState} oldData={item["SSVCommentIssue"]} keyName={"SSVCommentIssue"} label={"Comments - NNP & SSS (Date, issue & action item)"} sendUrl={LSSVDashboardAPI} id={item.ssvUniqueId} />)
            setcommonModalHeader("Comments - NNP & SSS (Date, issue & action item)")
          }
          }
        >{item.SSVCommentIssue ? digit_more_info(item.SSVCommentIssue, 10) : "Comment"}</p>

        item["AssignPODateModal"] = <p
          className='badge badge-primary'
          onClick={() => {
            setcommonModalState(true);
            setcommonModalBody(<SingleDateOnlyModal getOld={item["AssignPODate"]} setCloser={setcommonModalState} label="Please Select Date" dataKey="AssignPODate" sendUrl={LSSVDashboardAPI} uid={item.ssvUniqueId} />)

            // setcommonModalBody(<InputBox setCloser={setcommonModalState} oldData={item["AssignPODate"]} keyName={"AssignPODate"} label={"AssignPODate"} sendUrl={LSSVDashboardAPI} id ={item.ssvUniqueId}/>)
            setcommonModalHeader("Assign/PO Date")
          }
          }
        >{item.AssignPODate ? item.AssignPODate : "Assign/PO"}</p>



        item["SSSCOPSubmitDateFlag"] = <p

          className='badge mx-auto badge-success'
          onClick={() =>
            CommonModalFun(
              // <LockUnlockModalBody apiType={"request"} oldData={[]} siteIdData={item.siteId} ranIdData={item.ranSupportId} />
              <SSSCOP id={item.ssvUniqueId} />
              ,
              "SSS COP",
              "SSS COP"
            )
          }
        >
          SSS COP
        </p>
        if (item["projectUniqueId"] && item["projectUniqueId"].length > 0) {
          let projectUniqueIdFlag = []
          item["projectUniqueId"].map((itm) => {
            projectUniqueIdFlag.push(itm.projectId)
          })
          let planTypeData = []
          item["projectUniqueId"].map((itm) => {
            planTypeData.push(itm.projectCode)
          })


          let ProgramNameData = []


          item["projectUniqueId"].map((itm) => {
            ProgramNameData.push(itm.ProgramName)
          })

          item["ProgramName"] = (
            <p
              className={"text-black my-0 modalHoverClass"}
              data-toggle="tooltip"
              data-placement="top"
              title="Click Here For More Details"
              onClick={() =>
                CommonModalFun(
                  <p
                    style={{
                      border: "2px solid black",
                      color: "black",
                      padding: 2,
                    }}
                    className="CommonModal"
                  >
                    {ProgramNameData.join(", ")}
                  </p>,
                  "Program Name",
                  "Program Name"
                )
              }
            >
              {wrapText(ProgramNameData.join(", "))}
            </p>
          )

          item["projectUniqueIdFlag"] = projectUniqueIdFlag.join(", ")
          item["RingCode"] = item["projectUniqueId"][0].RingCode
          item["region"] = item["projectUniqueId"][0].region
          item["marketFlag"] = item["projectUniqueId"][0].market
          item["vendor"] = item["projectUniqueId"][0].HardCostVendor
          item["projectType"] = item["projectUniqueId"][0].projectType
          item["planType"] = planTypeData.join(", ")

        }

        item["ssvFileUploadmodal"] =
          <div className=' d-flex justify-content-evenly'>


            <CommonBulkUploaderButton typeForm={"SSV File"} shortCode={`LSSVFileUpload__CP__` + item.ssvUniqueId} btnname={<Upload />} classes={"btn btn-primary btn-xs  mx-1"} afterLoad={LSSVDashboardAPI} />
            {item.LSSVFileUpload ?
              <a href={baseURL + "/uploads/" + item.LSSVFileUpload} className="" download><DownloadNew /></a>
              : ""
            }
          </div>
        item["NNPBaselineReferenceDateFlag"] = <p
          className='badge mx-auto badge-success'
          onClick={() =>
            CommonModalFun(
              // <LockUnlockModalBody apiType={"request"} oldData={[]} siteIdData={item.siteId} ranIdData={item.ranSupportId} />
              <NNPBaseline id={item.ssvUniqueId} />
              ,
              "NNP Baseline",
              "NNP Baseline"
            )
          }
        >
          Add NNP Baseline
        </p>
        return item
      })

    }
    return { sitesdata }
  })


  const exportTemplate = () => {
    dispatch(ExportXLSXDownloadReducerAction(templatelssvurl, "SSVTemplate_" + moment(new Date()).format("MM-DD-YYYY")))
  }

  function filterhandler() {

    dispatch(getFilterDropDown([]))
    // dispatch(AccountReducerAction(ssvfilters))
    dispatch(AccountReducerAction(commonfilters))
    setcommonModalState(true)
    // setcommonModalSize("xl")
    setcommonModalBody(<LSSVFilterList setfullUrL={setfullUrL} setcommonModalState={setcommonModalState} setFilterBadge={setFilterBadge} featureExport={setexportApiData} />)
    setcommonModalTitle("LSSV Filters")
    setcommonModalHeader("LSSV Filter")
  }
  const ClearFilter = () => {
    // alert("ClearFilter")
    console.log("ClearFilter", "ClearFilter");
    // setfstartdate();
    // setfenddate();
    setResetFilter(true)

    setFilterBadge()
    let Filleddata = dispatch(ResetLCLLSSVReducerAction())
    dispatch(AccountReducerAction(LSSVDashboardAPI + Filleddata, true));

    // dispatch(AccountReducerAction(listennoTicketApi+Filleddata,true,true));
  };
  const exportAllData = () => {


    let Filleddata = dispatch(ResetLCLLSSVReducerAction())
    dispatch(ExportXLSXDownloadReducerAction(export_lssv_dashboard, "SSS AND NNP DASHBOARD_" + moment(new Date()).format("MM-DD-YYYY"), Filleddata))
    // FileSaver.saveAs(baseURL+"/export/ran", "RAN_Support_" + moment(new Date()).format("MM-DD-YYYY"))
    // history.push(baseURL+"/export/ran")
  }
  let AddTicket = () => (
    <>
      <p onClick={filterhandler} className=" mx-2 " style={{ "align-self": "center" }}><FilterData /></p>
      <button
        className="btn btn-xs btn-primary mx-2 my-3"
        data-toggle="tooltip"
        data-placement="bottom"
        title="Refresh"
        onClick={() => { ClearFilter(); }}
        style={{ padding: "6px" }}
      >
        <RefreshIcon />
      </button>
      <Button onClick={exportTemplate} className="btn btn-xs btn-primary mx-2 py-2">
        Template
      </Button>
      <Link className="btn btn-xs btn-primary mx-2 my-3 py-2" to={"/PAG/launch/ssv"} onClick={() => dispatch(clearIxSingleSite())}>Add Launch SSS & NNP</Link>
      <CommonBulkUploader styles={{ marginBottom: "1rem" }} typeForm={"Launch SSS Upload File"} shortCode={"Bulk_LSSV"} afterLoad={"/launchSSSNNP" + dynamic_dashboard} classes={'btn btn-xs btn-primary mx-2 my-3 py-2'} />


      <Button onClick={exportAllData} className="btn btn-primary btn-xs mx-2 py-2 my-3 float-right d-flex justify-content-center align-items-center">
        Export
      </Button>
    </>
  )
  console.log("the response columns is =", columnsArray("Region	Market	Site ID	Ring ID	Plan Type	Project ID	HC Vendor	GC	Launch Eng	SSS & NNP Eng	RTT Date	NCDO and ANR SON Date	Unlock Date	Traffic check	RTWP check - all layers	Key KPIs check	Launch Date	Launch Status	Site health Check Date	Comments - Launch MS6	NNP Baseline Reference Date	NNP 4G Status	Overall NNP 5G Status	Count to Market Avg	SSS COP Submit Date	SSS COP Approval Date	SSS COP Status	Comments - NNP & SSS (Date, issue & action item)	Assign/PO Date	Project Type"))
  let columns = [
    {
      "name": "region",
      "label": "Region",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0px",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "70px",
            color: "white",
            position: "sticky",
            zIndex: 101,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "70px",
            left: "0px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 100,
          },
        }),
      }
    },
    {
      "name": "marketFlag",
      "label": "Market",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            left: "70px",
            background: "#143b64",
            minWidth: "70px",
            color: "white",
            position: "sticky",
            zIndex: 101,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "70px",
            left: "70px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 100,
          },
        }),
      }
    },
    {
      "name": "siteId",
      "label": "Site ID",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            left: "140px",
            color: "white",
            position: "sticky",
            zIndex: 101,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 100,
            left: "140px",
          },
        }),
      }
    },
    {
      "name": "RingCode",
      "label": "Ring ID",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "planType",
      "label": "Plan Type",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },


    {
      "name": "ProgramName",
      "label": "Program Name",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "200px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "200px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "projectUniqueIdFlag",
      "label": "Project ID",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    // {
    //     "name": "projectType",
    //     "label": "Project Type",
    //     "sortable": false,
    //     "options": {
    //     filter: true,
    //     className: "abcd",
    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",
    //         left: "0",
    //         alignItem: "center",
    //         padding: "1px 1px",
    //         background: "#143b64",
    //         minWidth: "140px",
    //         color: "white",
    //         position: "sticky",
    //         zIndex: 100,
    //         outline: "1.5px solid black"
    //       },
    //     }),
    //     setCellProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",
    //         left: "0",
    //         overflowY: "hidden",
    //         minWidth: "140px",
    //         padding: "1px 1px",
    //         position: "sticky",
    //         background: "White",
    //         zIndex: 99,
    //       },
    //     }),
    //     }
    // },
    {
      "name": "SiteCategory",
      "label": "Site Category",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "vendor",
      "label": "HC Vendor",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "gcdata",
      "label": "GC",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "LaunchEngineer",
      "label": "Launch Eng",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "SSSNNPEng",
      "label": "SSS & NNP Eng",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "ssvRttDate",
      "label": "RTT Date",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "LNCDOandANRSONDate",
      "label": "NCDO and ANR SON Date",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "UUUEngineer",
      "label": "UUU Engineer",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "LUUUDate",
      "label": "UUU Date",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "trafficCheckFlag",
      "label": "Traffic check",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        print: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "RTWPCheckAllLayers",
      "label": "RTWP check - all layers",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "KeyKPIscCheck",
      "label": "Key KPIs check",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "TiltsBaselined",
      "label": "Tilts Baselined?",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "PrachCSBaselined",
      "label": "PrachCS Baselined?",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "prachConfigIndexBaselined",
      "label": "prachConfigIndex Baselined?",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "ExpectedcellsizeKMBaselined",
      "label": "Expectedcellsize(KM) Baselined?",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "LSSVLaunchDate",
      "label": "Launch Date",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "launchWeek",
      "label": "Launch Week",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "LLaunchStatus",
      "label": "Launch Status",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "siteHealthCheckDate",
      "label": "Site health Check Date",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "CommentsLaunchMS6Modal",
      "label": "Comments - Launch MS6",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "240px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "NNPBaselineReferenceDateFlag",
      "label": "NNP Baseline Reference Date",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        print: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "NNP4GStatus",
      "label": "NNP 4G Status",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "OverallNNP5GStatus",
      "label": "Overall NNP 5G Status",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "CounttoMarketAvg",
      "label": "Count to Market Avg",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },


    {
      "name": "ssvFileUploadmodal",
      "label": "SSS Submit",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        print: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },

    {
      "name": "LssvEngineer",
      // "label": "SSV Details",
      "label": "SSS Engineer",
      "sortable": false,
      "options": {
        filter: true,
        print: false,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    // {
    //       "name": "SSSCOPSubmitDateFlag",
    //       "label": "SSS COP Submit Date",
    //       "sortable": false,
    //       "options": {
    //       filter: true,
    //       className: "abcd",
    //       print:false,
    //       setCellHeaderProps: () => ({
    //         style: {
    //           whiteSpace: "wrap",
    //           alignItem: "center",
    //           padding: "1px 1px",
    //           background: "#143b64",
    //           minWidth: "140px",
    //           color: "white",
    //           position: "sticky",
    //           zIndex: 100,
    //           outline: "1.5px solid black"
    //         },
    //       }),
    //       setCellProps: () => ({
    //         style: {
    //           whiteSpace: "wrap",
    //           overflowY: "hidden",
    //           minWidth: "140px",
    //           padding: "1px 1px",
    //           position: "sticky",
    //           background: "White",
    //           zIndex: 99,
    //         },
    //       }),
    //       }
    //   },
    {
      "name": "LssvSubmitDate",
      "label": "SSS COP Submit Date",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "SSSCOPSubmitWeek",
      "label": "SSS COP Submit Week",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "RemarksLSSVSSSCOPStatus",
      "label": "SSS COP Approval Remark",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "200px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "200px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "SSSCOPApprovalDate",
      "label": "SSS COP Approval Date",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "SSSCOPApprovalWeek",
      "label": "SSS COP Approval Week",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "SSSCOPApprovedBy",
      "label": "SSS COP Approved By",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "SSVStatus",
      "label": "SSS COP Status",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "SSVCommentIssueModal",
      "label": "Comments - NNP & SSS (Date, issue & action item)",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "240px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "240px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    // {
    //     "name": "CommentsNNP_SSSModal",
    //     "label": "Comments - NNP & SSS (Date, issue & action item)",
    //     "sortable": false,
    //     "options": {
    //     filter: true,
    //     className: "abcd",
    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",
    //         alignItem: "center",
    //         padding: "1px 1px",
    //         background: "#143b64",
    //         minWidth: "240px",
    //         color: "white",
    //         position: "sticky",
    //         zIndex: 100,
    //         outline: "1.5px solid black"
    //       },
    //     }),
    //     setCellProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",
    //         overflowY: "hidden",
    //         minWidth: "140px",
    //         padding: "1px 1px",
    //         position: "sticky",
    //         background: "White",
    //         zIndex: 99,
    //       },
    //     }),
    //     }
    // },
    {
      "name": "AssignPODateModal",
      "label": "Assign/PO Date",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "projectType",
      "label": "Project Type",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "edit",
      "label": "Edit",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    }
  ]

  useEffect(() => {
    dispatch(AccountReducerAction(LSSVDashboardAPI))
  }, [])
  return (
    <div>
      <CommonModal
        modalSize={"xm"}
        setXlShow={setcommonModalState}
        xlShow={commonModalState}
        Body={commonModalBody}
        Header={commonModalHeader}
      ></CommonModal>
      <ThemeProvider theme={getMuiThemeCommon()}>
        <MUIDataTable
          data={options.sitesdata}
          columns={columns}
          options={advancdExportOnecustomDataTableOptionObject(AddTicket, false, "ranDashbord",)}
        />
      </ThemeProvider>
    </div>
  )
}

export default LaunchSSS