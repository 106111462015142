import React, { useState } from 'react'
import MUIDataTable from "mui-datatables";
import { useEffect } from 'react';
import { Row, Card, Col, Dropdown, Button, Modal, Container } from "react-bootstrap";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { accountApi, addUser, deleteUser, listRole_twoApi, getMarket, crewCompanyApi, listUserApi_two, vendorApi, addAcccount, listOneUser, updateUser, CsvUpload } from '../../../../common/config';
import { AccountReducerAction, OneUserReducerAction, addUserAction, deleteAcccountReducerAction, GetMarketReducerAction, getCrewCompanyAction, updateAcccountReducerAction, RoleReducerAction, UserListReducerAction, popUpUpdate, GetOneUserAction } from '../../../../store/actions/createTicketManageFields';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import swal from 'sweetalert';
import { black } from 'material-ui/styles/colors';
import { PDFUploadActionreturn } from '../../../../store/actions/dashboardActions';
import { google_map_api, center, dbdatetodate, customDataTableOptionObject, getMuiThemeCommon, advancdcustomDataTableOptionObject, advancdExportOnecustomDataTableOptionObject } from "../../../../common/variables";
import { minWidth } from '@mui/system';
import CommonBulkUploader from '../../Custom/CommonBulkUploader';
import { width } from '@mui/system';
const AddUsercrew = () => {

  let pStatusArry = []
  let vStatusArry = []
  let mStatusArry = []
  let cStatusArry = []

  // let editTrue=0
  const options = {
    download: "false",
    filterType: 'none',
    selectableRows: false,
    responsive: true
  };

  let accountArry = []
  let userArry = []

  const { register, setValue, handleSubmit, reset, formState: { errors } } = useForm({});
  const [show, setShow] = useState(false);
  const [closer, setCloser] = useState(false);
  
  const [siteFile, uploadedFile] = useState([]);
  const [fileshow, setfileShow] = useState(false);


  const submitHandler = async (e) => {
    e.preventDefault();
    console.log("form==", "form")
    let newform = new FormData();
    newform.append("uploadFile", siteFile);
    newform.append("uploadfor", "Crew")
    console.log("form==", newform)
    console.log("resp", dispatch(PDFUploadActionreturn(CsvUpload, newform, "usercrew")))
    // setShow(false);
  };


  const uploadCsvFile = (e) => {
    // let files=[]
    console.log(e.target.files, "files")
    uploadedFile(e.target.files[0])

  };

  const handleClose = () => {
    reset({})
    setShow(false);
  }
  const handleShow = () => {
    reset({})
    setShow(true)
  }

  const filehandleClose = () => {
    reset({})
    setfileShow(false);
  }
  const filehandleShow = () => {
    reset({})
    setfileShow(true)
  }

  // const uploadCsvFile = (e) => {
  //   let files=[]

  //   console.log(e.target.files[0],"ON_CHANGE_FILES:", e.target.files.length);

  //   for(let i=0;i<e.target.files.length;i++){
  //     files.push(e.target.files[i])
  //   }
  //   console.log(...files)
  //   uploadedFile(files)

  // };



  const [modalCentered, setModalCentered] = useState(false);
  const [newAccount, setAccount] = useState({})
  const [editTrue, seteditTrue] = useState(0)
  const [updateTrue, setupdateTrue] = useState(0)
  const [formtype, addformtype] = useState('')





  let oneaccount = {}


  // setAccount(oneaccount)
  //  console.log(oneaccount,"oneaccount")
  const getMuiTheme = () => createTheme({
    components: {
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            backgroundColor: "transparent",
            color: black
          }
        }
      }
    }
  })


  console.log()
  const updateHandler = (e) => {
    console.log("button update clicked", e)

    dispatch(addUserAction(addAcccount, e))

  }
  const deleteHandler = (e) => {
    console.log("button delete clicked", e)
    swal({
      title: "Are you sure?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if(willDelete){
        dispatch(deleteAcccountReducerAction(deleteUser, e, "crew"))
        setCloser(true)
      }
      });
  }


  const editpopupHandler = (e) => {
    seteditTrue(1)
    reset({})
    console.log("button editpopupHandler clicked", e)
    dispatch(GetOneUserAction([]))
    dispatch(OneUserReducerAction(listOneUser + "/" + e.uniqueid))
    
    dispatch(popUpUpdate({}))
    setCloser(true)

  }

  const seteditdataHandler = (e) => {
    reset({})
    setupdateTrue(1)
    setValue("email", e.email, { shouldTouch: true });
    setValue("password", e.password, { shouldTouch: true });
    setValue("mobilenumber", e.mobilenumber, { shouldTouch: true });
    setValue("name", e.name, { shouldTouch: true });
    // console.log(e.$oid,"e.$oid")
    setValue("userrole", e.userroleuniqueid, { shouldTouch: true });
    setValue("accountId", e.accountuniqueid, { shouldTouch: true });
    setValue("crewType", e.crewType, { shouldTouch: true });
    setValue("crewLeadCompanyId", e.crewLeadCompanyId.$oid, { shouldTouch: true });
    setValue("marketId", e.marketId.$oid, { shouldTouch: true });
    setValue("vendorId", e.vendorId.$oid, { shouldTouch: true });
    setValue("name", e.name, { shouldTouch: true });
    setValue("uniqueid", e.uniqueid, { shouldTouch: true });
    setValue("mobileAppAccess", e.mobileAppAccess, { shouldTouch: true });
    setValue("mopAccess", e.mopAccess, { shouldTouch: true });
  }



  const onSubmit = async (data) => {
    console.log(data, "dassdaas")
    

    if (data.uniqueid != undefined) {
      dispatch(updateAcccountReducerAction(updateUser, data.uniqueid, data, "crew"))
    }
    else {
      dispatch(addUserAction(addUser, data, "crew"))
    }
    
    dispatch(popUpUpdate({}))
    setCloser(true)
    // dispatch(UserListReducerAction(listUserApi_two))

  }

  const dispatch = useDispatch();


  // let datava =useSelector((state)=>{

  // });

  let data = useSelector((state) => {
    console.log("dfwef=", state)
    let onelistuser = state.OneCommonResponse.listuser

    console.log(onelistuser.length, editTrue, "onelistuser")
    if (onelistuser.length > 0 && editTrue == 1) {
      oneaccount = onelistuser[0]
      console.log(oneaccount, "oneaccount")
      seteditTrue(0)
      seteditdataHandler(oneaccount)
    }

    // if (state.popUpState) {
    //   // console.log(state.popUpState.popstatus.status==201,"popUpStatestatus")
    //   if (state.popUpState.popstatus.status == 201 && closer) {
    //     setCloser(false)
    //     handleClose()
    //     popUpUpdate({})
    //   }
    // }

    if (state.popUpState) {
      console.log(state.popUpState.popstatus.status,closer,"popUpStatestatus")
      if ((state.popUpState.popstatus.status == 201 && closer)||(state.popUpState.popstatus.status == 200 && closer)) {
        setCloser(false)
        dispatch(popUpUpdate({}))
        dispatch(UserListReducerAction(listUserApi_two))
        setShow(false)
        
      }
      console.log(state.popUpState.popstatus.status == 409 , closer,"state.popUpState.popstatus.status == 409 && closer")
      if (state.popUpState.popstatus.status == 409) {
        setCloser(false)
        swal({
              title: "Oops",
              text: state.popUpState.popstatus.data.msg,
              icon: "error",
              button: "Ok",
          })
        dispatch(popUpUpdate({}))
        dispatch(UserListReducerAction(listUserApi_two))
        // setShow(false)
        
      }
    }
    let users = state.CommonResponse.listuser

    let account = state.CommonResponse.accounts
    let index = 0
    if (users && users.length != 0) {
      userArry = users.map((item) => {

        console.log(item)
        index = index + 1
        return {

          sNo: index,
          name: item.name,
          mobilenumber: item.mobilenumber,
          email: item.email,
          password: item.password,
          userrole: item.roleresult.poc ? "Yes" : "No",
          // accountName: item.accountName[0],
          mopAccess: item.mopAccess ? "Yes" : "No",
          crewType: item.crewType,
          mobileAppAccess: item.mobileAppAccess,
          marketresult: item.marketresult.length > 0 ? item.marketresult[0].marketName : item.marketresult,
          crewresult: item.crewresult.length > 0 ? item.crewresult[0].crewCompanyName : item.crewCompanyName,
          vendorresult: item.vendorresult.length > 0 ? item.vendorresult[0].vendorName : item.vendorName,
          // accountName: item.accountName,
          accountName: item.accountName,
          action: <div className="d-flex">
            <Link
              href="#"
              onClick={() => { editpopupHandler(item); reset({}); addformtype("Edit"); handleShow(); }}
              className="btn btn-primary shadow btn-xs sharp mr-1"
            >
              <i className="fa fa-pencil"></i>
            </Link>
            <Link
              href="#"
              onClick={(e) => deleteHandler(item.uniqueid)}
              className="btn btn-danger shadow btn-xs sharp"
            >
              <i className="fa fa-trash"></i>
            </Link>
          </div>
          // <button value={item.uniqueid} onClick={(e)=>updateHandler(e)}>Click me</button>

        }
      })
    }


    if (account != 0) {
      accountArry = account.map((item) => {
        return <option value={item.uniqueid}>{item.accountName}</option>
      })
    }


    let userroledata = state.CommonResponse.userrole
    console.log(state.CommonResponse, "state.CommonResponse")
    console.log(userroledata, "projectStatus")
    if (userroledata != 0) {
      pStatusArry = userroledata.map((item) => {
        if (item.poc) {
          return <option value={item.uniqueid}>Yes</option>
        } else {
          return <option value={item.uniqueid}>No</option>
        }
      })

    }

    let marketroledata = state.CommonResponse.markets
    if (marketroledata != 0) {
      mStatusArry = marketroledata.map((item) => {
        return <option value={item.uniqueid}>{item.marketName}</option>
      })

    }

    let crewroledata = state.CommonResponse.crewCompany
    if (crewroledata != 0) {
      cStatusArry = crewroledata.map((item) => {
        return <option value={item.uniqueid}>{item.crewCompanyName}</option>
      })

    }

    let vendorroledata = state.CommonResponse.vendor
    if (vendorroledata != 0) {
      vStatusArry = vendorroledata.map((item) => {
        return <option value={item.uniqueid}>{item.vendorName}</option>
      })

    }

    console.log(pStatusArry, "pStatusArry")

    return { userArry, accountArry, pStatusArry, mStatusArry, oneaccount, cStatusArry, vStatusArry }
  })

  console.log(data, "data")
  const noOfRow = data.accountArry.length
  // data.accountArry.push(
  //   {   
  //       sNo:noOfRow+1,
  //       account:<input type='text' placeholder='Add Account' value={newAccount} onChange={(e)=>setAccount(e.target.value)}/>,
  //       edit : <button onClick={()=>addAccount()}>Add Account</button>

  //   }
  // )




  useEffect(() => {
    dispatch(RoleReducerAction(listRole_twoApi))
    dispatch(AccountReducerAction(accountApi))
    dispatch(UserListReducerAction(listUserApi_two))
    dispatch(GetMarketReducerAction(getMarket))
    dispatch(AccountReducerAction(crewCompanyApi))
    dispatch(AccountReducerAction(vendorApi))

  }, [newAccount])
  const columns = [
    {

      name: "sNo",
      label: "S. No.",
      options: {
        filter: false,
        sort: false,
        searchable:false,
        setCellHeaderProps: () => ({
          style: {

            alignItem: "center",
            background: "#143b64",
            color: "white",
            width:'20px'

          }
        })
      }
    },
    {
      name: "accountName",
      label: "Account",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },

    {
      name: "marketresult",
      label: "Market",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({
          style: {

            alignItem: "center",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      name: "vendorresult",
      label: "Vendor",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({
          style: {

            alignItem: "center",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      name: "mobilenumber",
      label: "Mobile Number",
      options: {
        sort: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      name: "name",
      label: "Name",
      options: {
        sort: true,
        setCellHeaderProps: () => ({
          style: {

            alignItem: "center",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      name: "crewresult",
      label: "Crew Lead Company",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            background: "#143b64",
            color: "white",
            minWidth:"100px"

          }
        })
      }
    },
    {
      name: "email",
      label: "Email",
      options: {
        sort: true,
        setCellHeaderProps: () => ({
          style: {

            alignItem: "center",
            background: "#143b64",
            color: "white"

          }
        })
      }
    }, {
      name: "mobileAppAccess",
      label: "Crew Lead Mobile-App Access",
      options: {
        sort: true,
        setCellHeaderProps: () => ({
          style: {

            alignItem: "center",
            background: "#143b64",
            color: "white",
            minWidth:'200px'

          }
        })
      }
    }, {
      name: "password",
      label: "Password",
      options: {
        sort: true,
        setCellHeaderProps: () => ({
          style: {

            alignItem: "center",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      name: "userrole",
      label: "POC",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({
          style: {

            alignItem: "center",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      name: "mopAccess",
      label: "MOP Access",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({
          style: {

            alignItem: "center",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      name: "crewType",
      label: "Crew Type",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({
          style: {

            alignItem: "center",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        print:false,
        searchable:false,
        setCellHeaderProps: () => ({
          style: {

            alignItem: "center",
            background: "#143b64",
            color: "white"

          }
        })
      }
    }

  ];

  let row = [];

  const handleChange = (e) => {
    console.log("the current anser= ", e.target.value)
  }

  const AddCustomButton = () => (
    <fregment style={{ float: "right", display: "flex", flexDirection: row, justifyContent: "space-between" }}>
      <div>
        <Button className="btn btn-primary btn-xs mx-2 py-2 my-3" onClick={() => { handleShow(); reset({}); addformtype("Add") }}>Add Crew</Button>
        <CommonBulkUploader typeForm={"Crew"} shortCode={"Crew"} classes={'btn btn-primary btn-xs mx-2 py-2 my-3'} afterLoad={listUserApi_two}/>
      </div>
      <Modal show={show} className="fade"  size="lg">
        <Modal.Header>
          <Modal.Title >{formtype} Crew</Modal.Title>
          <Button
                  onClick={() => setShow(false)}
                  variant=""
                  className="close">
                <span>&times;</span>
                </Button>
        </Modal.Header>
        <Modal.Body >

          <div className="card " style={{ width: "100%" }}>
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <form onSubmit={handleSubmit(onSubmit)}>

                  <div>
                    <div className="accordion-body">
                      <div className="form-row mt-2 p-2">
                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                            Name <span style={{ color: "red" }}>*</span>
                          </label>
                          <input style={{ height: "40px" }}
                            type="text"
                            className="form-control"
                            id="input "
                            placeholder="Enter Name"

                            // {
                            // ...register("name")
                            // }
                            {
                              ...register("name",{
                                required: "Please enter name",
                              })
                            }
                          />
                          {errors.name && <p className="error">{errors.name.message}</p>}
                        </div>
                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                            Mobile Number <span style={{ color: "red" }}>*</span>
                          </label>
                          <input style={{ height: "40px" }}
                            type="text"
                            className="form-control"
                            id="input "
                            placeholder="Enter Mobile Number"

                            // {
                            // ...register("mobilenumber")
                            // }
                            {
                              ...register("mobilenumber",{
                                required: "Please enter Mobile Number",
                              })
                            }
                          />
                          {errors.mobilenumber && <p className="error">{errors.mobilenumber.message}</p>}
                        </div>

                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                            Crew Lead Mobile-App Access <span style={{ color: "red" }}>*</span>
                          </label>
                          {/* <input style={{ height: "40px" }}
                            type="text"
                            className="form-control"
                            id="input "
                            placeholder="Enter Mobile Number"

                            {
                            ...register("mobileAppAccess")
                            }
                          /> */}
                          <select
                            style={{ height: "40px" }}
                            className="form-control"
                            id="exampleFormControlSelect1"
                            // {...register("mobileAppAccess")}
                            
                            {
                              ...register("mobileAppAccess",{
                                required: "Please select Mobile App Access",
                              })
                            }
                            >


                            <option disabled selected value={""}>Access Type</option>
                            <option value="Granted">Granted</option>
                            <option value="Rejected">Rejected</option>
                            <option value="Pending">Pending</option>

                          </select>
                          {errors.mobileAppAccess && <p className="error">{errors.mobileAppAccess.message}</p>}
                        </div>



                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                            Password <span style={{ color: "red" }}>*</span>
                          </label>
                          <input style={{ height: "40px" }}
                            type="text"
                            className="form-control"
                            id="input "
                            placeholder="Enter Password"

                              {
                                ...register("password",{
                                  required: "Please enter Password",
                                  minLength:{
                                    value:2,
                                    // value:8,
                                    message:"Minimum 8 digit Password"
                                  },
                                })
                              }
                          />
                          {errors.password && <p className="error">{errors.password.message}</p>}
                        </div>

                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                            Email <span style={{ color: "red" }}>*</span>
                          </label>
                          <input style={{ height: "40px" }}
                            type="text"
                            className="form-control"
                            id="input "
                            placeholder="Enter Email"

                            // {
                            // ...register("email")
                            // }
                            {
                              ...register("email",{
                                required: "Please enter Email",
                                pattern:{
                                  value:/\S+@\S+\.\S+/,
                                  message:"Please Enter A Valid Email"
                                }
                              })
                            }
                          />
                          {errors.email && <p className="error">{errors.email.message}</p>}
                        </div>
                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                            {" "}
                            Market <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            style={{ height: "40px" }}
                            className="form-control"
                            id="exampleFormControlSelect1"
                            // {...register("marketId")}
                            // {...register("userrole")}
                            {
                              ...register("marketId",{
                                required: "Please select Market Id",
                              })
                            }
                            >


                            <option disabled selected value={""}>Select Market</option>
                            {data.mStatusArry}

                          </select>
                          {errors.marketId && <p className="error">{errors.marketId.message}</p>}
                        </div>

                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                            {" "}
                            POC <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            style={{ height: "40px" }}
                            className="form-control"
                            id="exampleFormControlSelect1"
                            // {...register("userrole")}
                            {
                              ...register("userrole",{
                                required: "Please select User Role",
                              })
                            }
                            >


                            <option disabled selected value={""}>Select</option>
                            {data.pStatusArry}

                          </select>
                          {errors.userrole && <p className="error">{errors.userrole.message}</p>}
                        </div>

                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                            {" "}
                            Crew Lead Company <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            style={{ height: "40px" }}
                            className="form-control"
                            id="exampleFormControlSelect1"
                            // {...register("crewLeadCompanyId")}
                            {
                              ...register("crewLeadCompanyId",{
                                required: "Please select Crew Company",
                              })
                            }
                            >


                            <option disabled selected value={""}>Select Company</option>
                            {data.cStatusArry}

                          </select>
                          {errors.crewLeadCompanyId && <p className="error">{errors.crewLeadCompanyId.message}</p>}
                        </div>

                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                            {" "}
                            Vendor <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            style={{ height: "40px" }}
                            className="form-control"
                            id="exampleFormControlSelect1"
                            // {...register("vendorId")}
                            
                            {
                              ...register("vendorId",{
                                required: "Please select Vendor",
                              })
                            }
                            >

                            <option disabled selected value={""}>Select Vendor</option>
                            {data.vStatusArry}

                          </select>
                          {errors.vendorId && <p className="error">{errors.vendorId.message}</p>}
                        </div>


                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                            {" "}
                            Select Account <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            style={{ height: "40px" }}
                            className="form-control"
                            id="exampleFormControlSelect1"
                            // {
                            // ...register("accountId")
                            // }
                            {
                              ...register("accountId",{
                                required: "Please select Account",
                              })
                            }
                          >
                            <option disabled selected value={""}>Select Account</option>
                            {data.accountArry}
                          </select>
                          {errors.accountId && <p className="error">{errors.accountId.message}</p>}
                        </div>

                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                            {" "}
                            Select Crew Type <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            style={{ height: "40px" }}
                            className="form-control"
                            id="exampleFormControlSelect1"
                            // {
                            // ...register("crewType",)
                            // }
                            {
                              ...register("crewType",{
                                required: "Please select Crew Type Access",
                              })
                            }
                          >
                            <option disabled selected value={""}>Select Crew Type</option>
                            <option>IX</option>
                            <option>CX</option>
                            {/* {data.accountArry} */}
                          </select>
                          {errors.crewType && <p className="error">{errors.crewType.message}</p>}
                        </div>

                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                            {" "}
                            MOP Access <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type='checkbox'
                            style={{ height: "40px" }}
                            className=""
                            id="exampleFormControlMopAccess"
                            {
                            ...register("mopAccess")
                            }
                          />
                          {errors.mopAccess && <p className="error">{errors.mopAccess.message}</p>}
                        </div>

                      </div>
                    </div>
                  </div>
                  <button type='submit' className='btn btn-primary col-xs' > Submit</button>
                </form>
              </div>

            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>




      <Modal show={fileshow} onHide={filehandleClose} size="sm">
        <Modal.Header closeButton>
          <Modal.Title >{formtype} Crew</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body> */}
        <form onSubmit={(e) => submitHandler(e)} encType="multipart/form-data" className="mx-2">
          <div className="input-group mb-3 fileSection">

            <div>
              <label class="custom-file-upload btn btn-outline-primary col-md-12">
                <input
                  type="file"
                  className="form-control btn btn-primary"
                  id="inputGroupFile04"
                  aria-describedby="inputGroupFileAddon04"
                  aria-label="Upload"
                  name="file"
                  multiple
                  onChange={uploadCsvFile}
                />
                <i class="fa fa-cloud-upload"></i> Choose File
              </label>


            </div>

            <button
              className=" btn-primary"
              type="submit"
              id="inputGroupFileAddon04"
            >
              Submit
            </button>
          </div>

        </form>

      </Modal>
    </fregment>
  )

  //    const data = [
  //     { name: "Joe James", company: "In Progress", city: "Yonkers", state: "NY" },
  //     { name: "John Walsh", company: "Completed", city: "Hartford", state: "CT" },
  //     { name: "Bob Herm", company: "Awaited", city: "Tampa", state: "FL" },
  //     { name: "James Houston", company: "NA", city: "Dallas", state: "TX" },
  //    ];





  return (
    <>





      <ThemeProvider theme={getMuiThemeCommon()}>

      <MUIDataTable
        title={"List User Crew"}
        data={data.userArry}
        columns={columns}
        // options={advancdcustomDataTableOptionObject(AddCustomButton ,true,"User Crew")}
        options={advancdExportOnecustomDataTableOptionObject(AddCustomButton,true,"UserCrew_","",false,"User Crew",true)}
            
      />
      </ThemeProvider>
    </>
  )
}

export default AddUsercrew