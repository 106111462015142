import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { MultiSelect } from 'react-multi-select-component'
import { useDispatch } from 'react-redux'
import { bulkUpload } from '../../../../common/config'
import { reformArray } from '../../../../common/variables'
import { addAcccountReducerAction } from '../../../../store/actions/createTicketManageFields'

const BulkApproveModal = ({ selectedId }) => {
  let userDetails = JSON.parse(window.localStorage.getItem("userDetails"));
  const dispatch = useDispatch()
  const [department, setDepartment] = useState([])
  const [reason, setReason] = useState('')
  const [onlyOne, setonlyOne] = useState(true)
  const [action, setAction] = useState("")
  const [errors, seterrors] = useState()


  console.log("userType", userDetails)
  let onBehalf = []

  if (userDetails.rolename == "RF Engineer") {
    onBehalf = [
      {
        label: "Development",
        value: "Development"
      },
      {
        label: "Operations",
        value: "Operations"
      },
      {
        label: "RF",
        value: "RF Engineer"
      }
    ]
  }
  if (userDetails.rolename == "Development") {
    if (onlyOne) {
      setDepartment({
        label: "Development",
        value: "Development"
      })
      setonlyOne(false)
    }
  }
  function personalreformArray(orignalArray) {
    let newArray = { values: [] };
    if (orignalArray.length > 0) {
      newArray.values = orignalArray.map((item) => {
        return item.value;
      });
    }
    return newArray;
  }

  if (userDetails.rolename == "FOPS") {
    if (onlyOne) {
      setDepartment({ label: "Operations", value: "Operations" })
      setonlyOne(false)
    }
  }
  const ApprovalRequestHandler = () => {

    console.log(action, "department")

    let newError = {}
    let errorReturn = 0

    if (department.length == 0) {
      errorReturn=1
      newError["department"] = "Department is Required Field"
    }
    if (reason == "") {
      errorReturn=1
      newError["reason"] = "Reason is Required Field"
    }
    if (action == "") {
      errorReturn=1
      newError["action"] = "Action is Required Field"
    }

    if(errorReturn==1){
      seterrors(newError)
      return 
    }
    




    const reqData = {
      ticketId: selectedId,
      status: action,
      department: personalreformArray(department),
      reason: reason
    }
    dispatch(
      addAcccountReducerAction(bulkUpload, reqData)
    );

    console.log("the real response =", reqData)
  }
  const setReasonHandler = (e) => {
    
    if(errors && errors.reason){
      delete errors.reason
    }
    setReason(e.target.value)
  }
  const departmentHandleChange = (e) => {
    // console.log("multiselect=", tech)
    // setTechnologyError('')
    if(errors && errors.department){
      delete errors.department
    }

    // console.log(errors.department,"errorserrorserrors")
    setDepartment(e)

  }
  const actionHandler = (e) => {
    if(errors && errors.action){
      delete errors.action
    }
    setAction(e.target.value)
  }
  return (
    <>
      <div className="form-group">
        <label htmlFor="inputEmail4">Department</label>

        {
          userDetails.rolename == "RF Engineer" ? <MultiSelect
            // className="dark"
            options={onBehalf}
            onChange={departmentHandleChange}
            value={department}
          />
            : <input type='text' className="form-control" value={userDetails.rolename == "Development" ? "Development" : "Operations"}></input>
        }

        {errors && errors.department?<p className="error">{errors.department}</p>:""}

      </div>
      <div className="form-group">
        <label htmlFor="inputEmail4">Reason</label>
        <textarea type='text'
          value={reason}
          onChange={setReasonHandler}
          className='form-control'></textarea>



        {errors && errors.reason?<p className="error">{errors.reason}</p>:""}

      </div>
      <div className="form-group">
        <label htmlFor="inputEmail4">Action</label>

        <select style={{ height: "32px" }}
          onChange={actionHandler}
          className="form-control" aria-label="Default select example">
          <option value={''}>Select</option>
          <option value="Approved">Approve</option>
          <option value="Rejected">Reject</option>
          <option value="Pending">Pending</option>
        </select>

        {errors && errors.action?<p className="error">{errors.action}</p>:""}
          
      </div>
      <Button variant="secondary " onClick={ApprovalRequestHandler}>
        Submit
      </Button>
    </>
  )
}

export default BulkApproveModal