import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import MapView from "./Modal Body/MapView";
import { useHistory } from "react-router-dom";
import { Button, Tab, Tabs } from "react-bootstrap";
import { PDFUploadActionreturn } from "../../../store/actions/dashboardActions";
import { useDispatch, useSelector } from 'react-redux';
import { CsvUpload, ixModule } from "../../../common/config";
import { AccountReducerAction, popUpUpdate } from "../../../store/actions/createTicketManageFields";
import swal from "sweetalert";
const CommonBulkUploaderModal = ({ typeForm, shortCode, reutun,formIx="", afterLoad = "", setfileShow, fileshow,from="" }) => {
  console.log(setfileShow, fileshow, "setfileShow,fileshow")
  const history = useHistory()
  const dispatch = useDispatch();
  // const [fileshow, setfileShow] = useState(false);
  const [closer, setCloser] = useState(false);
  const [siteFile, uploadedFile] = useState([]);



  const filehandleClose = () => {
    setfileShow(false);
  }
  const filehandleShow = () => {
    setfileShow(true)
    dispatch(popUpUpdate({}))
    setCloser(true)
  }

  let data = useSelector((state) => {

    if (state.popUpState) {
      console.log(state.popUpState.popstatus, closer, "popUpStatestatuscommon")
      if ((state.popUpState.popstatus.status == 201 && closer) || (state.popUpState.popstatus.status == 200 && closer)) {
        setCloser(false)
        
        swal({
          title:state.popUpState.popstatus.data.msg,
          icon:state.popUpState.popstatus.data.data.length==0?"error":"success"
        })
        filehandleClose()
        dispatch(popUpUpdate({}))

        if(from=="/PAG/RanDashboard"){
          dispatch(AccountReducerAction(from));
        }
        if(formIx="True"){
          dispatch(AccountReducerAction(ixModule));
        }
      }
    }
  })

  const submitHandler = async (e) => {
    e.preventDefault();
    console.log("form==", "form")
    let newform = new FormData();
    newform.append("uploadFile", siteFile);
    newform.append("uploadfor", shortCode)
    console.log("form==", newform)
    if(formIx=="True"){
      dispatch(PDFUploadActionreturn(CsvUpload+"?ix=True", newform, "noShow", afterLoad))
    }
    else{
      dispatch(PDFUploadActionreturn(CsvUpload, newform, "noShow", afterLoad))

    }
    // setfileShow(false);
    setCloser(true)
  };


  const uploadCsvFile = (e) => {
    // let files=[]
    console.log(e.target.files, "files")
    uploadedFile(e.target.files[0])

  };

  return (
    <>
      <Modal show={fileshow} onHide={filehandleClose} size="md">
        <Modal.Header closeButton>
          <Modal.Title >Upload {typeForm}</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body> */}
        <form onSubmit={(e) => submitHandler(e)} encType="multipart/form-data" className="mx-2">
          <div className="input-group mb-3 fileSection">
            <div class="form-group col-md-12">
              <div>
                <label class="custom-file-upload btn btn-outline-primary col-md-6">
                  <input
                    type="file"
                    className="form-control btn btn-primary"
                    id="inputGroupFile04"
                    aria-describedby="inputGroupFileAddon04"
                    aria-label="Upload"
                    name="file"
                    multiple
                    onChange={uploadCsvFile}
                  />
                  <i class="fa fa-cloud-upload"></i> Choose File
                </label>

                <label>{siteFile.length != 0 ? "1 " : "No"} File Selected</label>


              </div>
            </div>


            <button type="submit" className="btn btn-primary btn-xs mx-1">
              Submit
            </button>
          </div>

        </form>

      </Modal>
    </>
  );
};

export default CommonBulkUploaderModal;
