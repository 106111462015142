import React, { Fragment, useState } from "react";
import "../../../../css/customStyle.css";
import { SplitButton, ButtonGroup, Dropdown, Button } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';

import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import { useForm } from "react-hook-form";

import "../../../../css/formError.css"
import { async } from "q";
const NetworkTwo = () => {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  const [umtsArray,setUmt]=useState([])
  const [lteArray,setLte] =useState([])
  let   [lteCount,setLteCount]=useState(1)
  let [count,setCount]  =useState(1) 
  const addUMTSFields= ()=>{
    setCount(count++);
    

    if(count<=7){
    setUmt([...umtsArray,<div className="form-group col-md-4">
  
    <input style={{height:"40px"}}
      type="text"
      className="form-control"

      placeholder={"UMTS Id "+count}
    />
  </div>])
  setCount(count++)
}
}
const addLTEFields =()=>{
  setLteCount(lteCount++);
    

  if(lteCount<=9){
    setLte([...lteArray,<div className="form-group col-md-4">
  <label for="exampleFormControlSelect1">
    LTE ID {lteCount}
  </label>
  <input style={{height:"40px"}}
    type="text"
    className="form-control"
    placeholder={"LTE Id "+lteCount}
  />
</div>])
setLteCount(lteCount++)
}
}
  const {register,handleSubmit,formState:{errors}} =useForm({});
  const onSubmit= async (data)=>{
    console.log(data)
  }
 
  return (
    <Fragment>
      {/* <PageTItle activeMenu='validation' motherMenu='Form' /> */}

      <div style={{ display: "block", width: "100%"}}>
        {/* <h4>React-Bootstrap Tab Component</h4> */}

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row mt-3">
            {/* <Button className='ml'variant='light'  > <i className="fa fa-angle-double-left" > Back to Grid</i></Button>
      <Button className='ml-2' variant='info'>  <i className="fa fa-angle-double-right" > Submit Request</i></Button>
      <Button className='float-right' variant='info' style={{marginLeft:"600px"}}> <i className="fa fa-save" > Save </i></Button>

      */}

            <div className="card " style={{ width: "100%" }} >
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingOneDetails">
                    <Button
                      className="accordion-button collapsed mr-2"
                      type="button"
                      variant="outline-primary btn-xs"
                    >
                      <span style={{ color: "red" }}>*</span>
              Manage Crews
                    </Button>
                    

                  </h2>
                  <div>
                    <div className="accordion-body">
                      <div className="form-row mt-2 p-2">
                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                            {" "}
                            Account<span style={{color:"red"}}>*</span>
                          </label>
                          <input style={{height:"40px"}} 
                            type="text"
                            className="form-control"
                            id="input "
                            placeholder=""
                            {
                              ...register("siteId",{
                                required:"Site Id is required",
                                minLength:{
                                  value:8,
                                  message:"Please enter valid site id"
                                },
                                maxLength:{
                                  value:16,
                                  message:"Only 16 characters are allowed"
                                }
                              })
                            }
                          />
                          {errors.siteId && <p className="error">{errors.siteId.message}</p>}
                        </div>
                       
                       
                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">Market</label>
                          <input style={{height:"40px"}} 
                            type="text"
                            className="form-control"
                            id="input "
                            placeholder=""
                            {
                              ...register("siteId",{
                                required:"Site Id is required",
                                minLength:{
                                  value:8,
                                  message:"Please enter valid site id"
                                },
                                maxLength:{
                                  value:16,
                                  message:"Only 16 characters are allowed"
                                }
                              })
                            }
                          />
                          {errors.Technology && <p className="error">{errors.Technology.message}</p>}
                        </div>
                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                            Vendor<span style={{color:"red"}}>*</span>{" "}
                          </label>
                          <input style={{height:"40px"}} 
                            type="text"
                            className="form-control"
                            id="input "
                            placeholder=""
                            {
                              ...register("siteId",{
                                required:"Site Id is required",
                                minLength:{
                                  value:8,
                                  message:"Please enter valid site id"
                                },
                                maxLength:{
                                  value:16,
                                  message:"Only 16 characters are allowed"
                                }
                              })
                            }
                          />
                          {errors.crno  && <p className="error">{errors.crno.message}</p>}
                        </div>
                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                          Crew Lead Contact Number<span style={{color:"red"}}>*</span>{" "}
                          </label>
                          <input style={{height:"40px"}} 
                            type="text"
                            className="form-control"
                            id="input "
                            placeholder=""
                            {
                              ...register("siteId",{
                                required:"Site Id is required",
                                minLength:{
                                  value:8,
                                  message:"Please enter valid site id"
                                },
                                maxLength:{
                                  value:16,
                                  message:"Only 16 characters are allowed"
                                }
                              })
                            }
                          />
                          {errors.crno  && <p className="error">{errors.crno.message}</p>}
                        </div>
                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                          Crew Lead Name<span style={{color:"red"}}>*</span>{" "}
                          </label>
                          <input style={{height:"40px"}} 
                            type="text"
                            className="form-control"
                            id="input "
                            placeholder=""
                            {
                              ...register("siteId",{
                                required:"Site Id is required",
                                minLength:{
                                  value:8,
                                  message:"Please enter valid site id"
                                },
                                maxLength:{
                                  value:16,
                                  message:"Only 16 characters are allowed"
                                }
                              })
                            }
                          />
                          {errors.crno  && <p className="error">{errors.crno.message}</p>}
                        </div>
                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                          Crew Lead Company<span style={{color:"red"}}>*</span>{" "}
                          </label>
                          <input style={{height:"40px"}} 
                            type="text"
                            className="form-control"
                            id="input "
                            placeholder=""
                            {
                              ...register("siteId",{
                                required:"Site Id is required",
                                minLength:{
                                  value:8,
                                  message:"Please enter valid site id"
                                },
                                maxLength:{
                                  value:16,
                                  message:"Only 16 characters are allowed"
                                }
                              })
                            }
                          />
                          {errors.crno  && <p className="error">{errors.crno.message}</p>}
                        </div>
                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                          Crew Lead email ID<span style={{color:"red"}}>*</span>{" "}
                          </label>
                          <input style={{height:"40px"}}
                            type="text"
                            className="form-control"
                            id="input "
                            readOnly
                          
                            {...register("accountt",{
                              required:"Please enter Account",
                              maxLength:{
                                value:32,
                                message:"Only 32 characters are allowed"
                              }
                            })}
                          />
                          {errors.accountt  && <p className="error">{errors.accountt.message}</p>}
                        </div>
                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                          Crew Lead Mobile-App Access<span style={{color:"red"}}>*</span>{" "}
                          </label>
                          <input style={{height:"40px"}} 
                            type="text"
                            className="form-control"
                            id="input "
                            placeholder=""
                            {
                              ...register("siteId",{
                                required:"Site Id is required",
                                minLength:{
                                  value:8,
                                  message:"Please enter valid site id"
                                },
                                maxLength:{
                                  value:16,
                                  message:"Only 16 characters are allowed"
                                }
                              })
                            }
                          />
                          {errors.crno  && <p className="error">{errors.crno.message}</p>}
                        </div>
                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                          Crew Lead Mobile-App Password<span style={{color:"red"}}>*</span>{" "}
                          </label>
                          <input style={{height:"40px"}} 
                            type="text"
                            className="form-control"
                            id="input "
                            placeholder=""
                            {
                              ...register("siteId",{
                                required:"Site Id is required",
                                minLength:{
                                  value:8,
                                  message:"Please enter valid site id"
                                },
                                maxLength:{
                                  value:16,
                                  message:"Only 16 characters are allowed"
                                }
                              })
                            }
                          />
                          {errors.crno  && <p className="error">{errors.crno.message}</p>}
                        </div>
                        <div className="form-group col-md-6">
                          <label for="exampleFormControlSelect1">
                          CrewType<span style={{color:"red"}}>*</span>{" "}
                          </label>
                          <select style={{height:"40px"}}
                            className="form-control"
                            id="exampleFormControlSelect1"
                            placeholder="--All--"
                            {
                              ...register("5G")
                            }
                          >
                            <option>CX</option>
                            <option>IX</option>
                           
                          </select>
                          {errors.crno  && <p className="error">{errors.crno.message}</p>}
                        </div>
                     
                      
                    
             
                      </div>
                      
         

            
                    </div>
                  </div>
                </div>
              
            <button type ="submit" className="btn btn-primary btn-xs ">Submit</button>


            
              </div>

             
              
            </div>
          
        
            </div>
           
        
           

            
          
        </form>
      </div>
    </Fragment>
  );
};

export default NetworkTwo;
