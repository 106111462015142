export const RF_Approval_color_code={
    "Pending":"badge-warning",
    "Approved":"badge-success",
    "Not Approved":"badge-danger"
}

export const colors = {
    "Post-check Ongoing" : "postCheckOngoing",
    "pending" : "pending-color pendingBg",
    "Post-Check Completed - Validation ongoing" : "badge-success",
    "Post-Check Completed" : "badge-success",
    "Cancelled" : "badge-danger",
    "Completed" : "badge-success",
    "Ongoing" : "bg-blue-btn",
    "Post-check Released with TT Opened and Nest Extended":"inProgressHold"
}
export const colorCodeObj={
    "Login Awaited - No Show":"loginAwaitedNoShow",
    "Scheduled":"Scheduled",
    "Post-Check Completed - Validation ongoing" : "badge-success",
    "In Progress":"inProgress",
    "In Progress - Hold":"inProgressHold",
    "Completed":"Completed",
    "pending" : "pending-color pendingBg",
  }

export const e911colorCode={
    "E911 Completed":"badge-success", 
    "Speed Test & E911 Completed":"badge-success", 
    "Speed Test Completed":"badge-success", 
    "Not Ready":"pending-color", 
    "Ready":"custom-bg-blue-btn", 
    "Failed":"badge-danger", 
    "NA":"pendingBg"
  }
