import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { approveOrDisapprove, crNumberList, listSiteId } from '../../../../common/config'
import { AccountReducerAction, addUserAction } from '../../../../store/actions/createTicketManageFields'
import MUIDataTable from "mui-datatables";
import { Snooze } from '@mui/icons-material';
const ManageCr = () => {
    let crList=[]
    const dispatch=useDispatch()
    const ApprovalHandler=(item)=>{
       console.log("the item is =.",item)
       dispatch(addUserAction(approveOrDisapprove+item.ranSupportId,{status:"Approve"}))
    }
    const rejectHandler =(item)=>{
        dispatch(addUserAction(approveOrDisapprove+item.ranSupportId,{status:"Not Approve"}))
    }
    const addAction =(item)=>{
        if(item.crStatus=="Pending"){
            return <><button className ="btn btn-sm btn-rounded btn-primary" onClick={()=>ApprovalHandler(item)}>Accept</button>
                   <button className ="btn btn-sm btn-rounded btn-danger" onClick={()=>rejectHandler(item)}>Reject</button></>
        }
        else if(item.ran_status=="Login Requested - CR Not Approved"){
            return <><button className ="btn btn-sm btn-rounded btn-secondary" disabled>Disapproved</button></>
        }
        else{
            console.log(item.ran_status," is equal to =","Login Awaited - CR Pending= ",item.ran_status=="Login Awaited - CR Pending")
            
        }
    }
    const response =useSelector((state)=>{
        console.log("the state is =",state)
        let allCr =state.CommonResponse.crNumberList
        if(allCr && allCr.length >0){
            crList=allCr.map((item,index)=>{
                return {
                    sNo:index+1,
                    crNumber:item.crNumber,
                    status:item.crStatus,
                    siteId:item.uniqueId,
                    // userName:item.userresult.name,
                    action:addAction(item)
                }
            })
        }
        console.log("crlist =",crList)
        return {crList}
    })
const columns=[
    {
       label:"S.No",
       name:"sNo"
    },
    {
        label:"Cr Number",
        name:"crNumber",
        sortable:true
    },
    {
        label :"Status",
        name:"status",
        sirtable:true
    },
    {
        label:"Site Id",
        name:"siteId",
        sortable:true
    },
    // {
    //     label:"Crew Member Name",
    //     name:"userName"
    // },
    {
        label:"Action",
        name:"action"
    }
]
const options = {
        filterType: 'none',
        selectableRows: false,
        responsive: true
      };
useEffect(()=>{
    dispatch(AccountReducerAction(crNumberList))
},[])
  return (
    <>
        <h2>Manage Cr</h2>
        <MUIDataTable
        
        title={"Manage List"}
        data={response.crList}
        columns={columns}
        options={options}
    />
    </>
  )
}

export default ManageCr