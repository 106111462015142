import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { registerField } from "redux-form";
import FormData from "./FormData";

import { Tab, Tabs } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { default as ReactSelect, components } from "react-select";
import {
  addUserAction,
  clearFetchSingleRecordAction,
  FetchSingleRecordAction,
  GetRanSingleProject,
  GetSingleProject,
  popUpUpdate,
  TierListReducerAction,
  UserListReducerAction,
} from "../../../../store/actions/createTicketManageFields";
import {
  crewHandoverApi,
  listUserApi_two,
  managTMOEngineer,
  ranDashboard,
  runPostCheck,
  runPreCheck,
  ixModule
} from "../../../../common/config";
import PreCheck from "./PreCheck";
import PostCheck from "./PostCheck";
import { useHistory } from "react-router-dom";
import { getdatedatalistnext } from "../../../../common/variables";
import GCLogin from "./GcLogin";
import CommonCrew from "./commoncrew";
const OnTheFlyForm = () => {
  let [plannedSow, setPlannedSow] = useState([]);
  let [preTab, setPreTab] = useState(false);

  let [DbName, setDbName] = useState([]);
  let [onTheFly, setOnTheFly] = useState(false);
  let [gcLogin, setGcLogin] = useState(false);
  let [crewInfo, setCrewInfo] = useState(false);
  let [postTab, setPostTab] = useState(false);
  let [cxixtype, setcxixtype] = useState(true)
  const [closer, setCloser] = useState(false);
  const [cxArray, setcx] = useState([]);
  const [ixArray, setix] = useState([]);
  const [onenTimer, setonenTimer] = useState(true);
  const [siteInfo, setsiteInfo] = useState({});
  let [accordianOperner, setAccordianOperner] = useState('CXCrew')
  const dispatch = useDispatch();
  let url = window.location.href.split("/");
  let index = url.pop();
  let activeTab = url.pop();
  console.log("the active tab is =,", activeTab);
  // if(activeTab=="preCheck"){
  //   setPreTab(true)
  // }
  let listalldata = [];
  let array_cx_main = [];
  let array_cx_data = [];
  let array_ix_data = [];
  let array_ix_main = [];
  let history = useHistory()
  let Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  // let curl =window.location.href.split("/")
  console.log("the kjfiuebrfkhgc=",url)
  let dashboardType=url.slice(-1)[0]
  console.log("th dnfwfwr=",dashboardType)
  let siteDetail = useSelector((state) => {

    if (state.popUpState) {
      // console.log(state.popUpState.popstatus.status==201,"popUpStatestatus")
      if ((state.popUpState.popstatus.status == 201 && closer)||(state.popUpState.popstatus.status == 200 && closer) ) {
        setCloser(false);
        dispatch(popUpUpdate({}));

        dispatch(clearFetchSingleRecordAction())
        history.push("/PAG/ran/support/dashboard")
      }
    }

    let siteby = dashboardType=="ixDashboard"?state.OneCommonResponse.singleIXSite :state.OneCommonResponse.singleSite
    // siteInfo = site
    console.log("jbhvwefhvrevgetb",siteby)
    if (siteby && siteby.length !=0 && onenTimer ) {
      console.log("the site id issdcfefe = ",siteby)
      setonenTimer(false)
      setsiteInfo(siteby)
    }

    // console.log("the siteInfo is the =", siteby != {}, siteby == {}, siteInfo);
    let site = siteInfo
    // console.log("the url =", state);
    console.log("the sites mnfjernfjkerfrjgis the =", site);
    listalldata = state.CommonResponse.listuser;
    listalldata.map((item) => {
      if (item.crewType == "CX") {
        array_cx_data.push(item);
      }
      if (item.crewType == "IX") {
        array_ix_data.push(item);
      }
    })


    if (site && site.technology) {


      if (site.ixCrewId && site.ixCrewId.name != undefined) {
        console.log("setDbName",site.ixCrewId)
        DbName.push(site.ixCrewId.email)
        // DbName.push(site.ixCrewId.crewType)
        DbName.push(site.ixCrewId.crewType=="IX"?"IXCrew":"CXCrew")
        DbName.push(site.ixCrewId.uniqueid)

      }
      if (site.cxCrewId && site.cxCrewId.name != undefined) {
        console.log("setDbName",site.cxCrewId)
        DbName.push(site.cxCrewId.email)
        DbName.push(site.cxCrewId.crewType=="IX"?"IXCrew":"CXCrew")
        DbName.push(site.cxCrewId.uniqueid)
      }
    }




    return { site, array_cx_data, array_ix_data };
  });
  const handleChange = (event, nane, arra) => {
    setcxixtype(false)

    let ind = event.target.selectedIndex - 1;
    console.log("array_cx_data,array_ix_data", ind, nane, array_ix_data, array_cx_data);
    if (nane == "IXCrew") {

      console.log("array_ix_data,array_cx_data", array_ix_data[ind]);
      setValue("CXCrew" + "Name", "");
      setValue("CXCrew" + "Email", "");
      setValue("CXCrew" + "Phone", "");
      setValue("CXCrew" + "Vendor", "")
      setValue("CXCrew" + "Company", "")
      setValue("cxCrewId" + "")
      if (array_ix_data[ind]) {
        setValue(nane + "Name", array_ix_data[ind].name);
        setValue(nane + "Email", array_ix_data[ind].email);
        setValue(nane + "Phone", array_ix_data[ind].mobilenumber);
        setValue(nane + "Vendor", array_ix_data[ind].vendorresult[0].vendorName)
        setValue(nane + "Company", array_ix_data[ind].crewresult[0].crewCompanyName)
      }
    } else {
      console.log("array_ix_data,array_cx_data", array_cx_data[ind])
      setValue("IXCrew" + "Name", "");
      setValue("IXCrew" + "Email", "");
      setValue("IXCrew" + "Phone", "");
      setValue("IXCrew" + "Vendor", "")
      setValue("IXCrew" + "Company", "")
      setValue("ixCrewId" + "")


      if (array_cx_data[ind]) {
        setValue(nane + "Name", array_cx_data[ind].name);
        setValue(nane + "Email", array_cx_data[ind].email);
        setValue(nane + "Phone", array_cx_data[ind].mobilenumber);
        setValue(nane + "Vendor", array_cx_data[ind].vendorresult[0].vendorName)
        setValue(nane + "Company", array_cx_data[ind].crewresult[0].crewCompanyName)
      }
    }

  };
  const onOptionChange = (e) => {
    let val = e.target.value;

    array_cx_main.push(
      <option selected disabled>
        Select
      </option>
    );
    array_ix_main.push(
      <option selected disabled>
        Select
      </option>
    );

    if (listalldata) {
      if (val == "LeadName") {
        listalldata.map((item) => {
          if (item.crewType == "CX") {
            array_cx_main.push(<option value={item.uniqueid}>{item.name}</option>);
          }
          if (item.crewType == "IX") {
            array_ix_main.push(<option value={item.uniqueid}>{item.name}</option>);
          }
        });
        console.log(array_ix_main, "array_ix_main");
      } else if (val == "Email") {
        listalldata.map((item) => {
          if (item.crewType == "CX") {
            array_cx_main.push(<option value={item.uniqueid}>{item.email}</option>);
          }
          if (item.crewType == "IX") {
            array_ix_main.push(<option value={item.uniqueid}>{item.email}</option>);
          }
        })
      } else if (val == "ContactNumber") {

        listalldata.map((item) => {
          if (item.crewType == "CX") {
            array_cx_main.push(<option value={item.uniqueid}>{item.mobilenumber}</option>);
          }
          if (item.crewType == "IX") {
            array_ix_main.push(<option value={item.uniqueid}>{item.mobilenumber}</option>);
          }
        })
      }
    }



    setcx(array_cx_main);
    setix(array_ix_main)
  };

  console.log(siteDetail, "siteDetail");
  let plannedSowArray = [
    {
      value: "E911-CallTest",
      label: "E911-Call Test",
    },
    {
      value: "CX",
      label: "CX",
    },
    {
      value: "IX",
      label: "IX",
    },
    {
      value: "troubleShooting",
      label: "Troubleshooting",
    },
  ];
  let pSow = "";
  if (siteDetail.site && siteDetail.site.sowList && siteDetail.site.sowList.values) {
    let pSowArray = siteDetail.site.sowList.values;
    if (pSowArray && pSowArray.length > 0) {
      //  item['technology'].forEach((item)=>{
      //   tec +=item.label +','
      //  })
      console.log("sgrthdhr", pSowArray.length);
      for (let i = 0; i < pSowArray.length; i++) {
        console.log("sgrthdhr", pSowArray[i]);
        pSow += pSowArray[i] + ", ";
      }
      //  item['technology']=tec
    }
  }
  const plannedSowChangleHandler = (e) => {
    console.log("Pow multiple select=", e);
    setPlannedSow(e);
  };
  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    console.log("the send data is =", data);
    // dispatch(addUserAction(runPreCheck+siteDetail.site.ranuniqueId, data))
    dispatch(addUserAction(runPreCheck + siteDetail.site.ranSupportId, data));
  };
  const handoverCrew = () => {
    let data = {}
    console.log("the chew is =", getValues("ixCrewId"), getValues("ixCrewId") == "", getValues("cxCrewId"), getValues("cxCrewId") == "")
    if (getValues("cxCrewId") == "") {
      data['ixCrewId'] = getValues("ixCrewId")
      data['cxCrewId'] = ""
    }
    if (getValues("ixCrewId") == "") {
      data['cxCrewId'] = getValues("cxCrewId")
      data['ixCrewId'] = ""
    }
    data["randashboadId"] = index
    console.log("the send data=", data)
    dispatch(addUserAction(crewHandoverApi + "/" + index, data))
    setCloser(true);
    dispatch(popUpUpdate({}));
  }
  const [cxdata, setcxdata] = useState("");

  const [ixdata, setixdata] = useState("");

  // const [alive , setAlive] = useState("postcheck")

  const abc = (abcd, name) => {
    console.log(abcd, name);

    setcxdata(
      <div class="accordion" id="accordionExample">
        <div class="accordion-item">
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <div className="card card-body">
                <div className="form-row mt-2 p-2">
                  <div className="form-group col-md-4">
                    <label for="exampleFormControlSelect1">
                      CX Search By----
                    </label>
                    <div>
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input
                            style={{ height: "20px" }}
                            type="radio"
                            className="form-check-input"
                            name="optradio"
                          />
                          Contact Number
                        </label>
                      </div>
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input
                            style={{ height: "20px" }}
                            type="radio"
                            className="form-check-input"
                            name="optradio"
                          />
                          E-mail
                        </label>
                      </div>
                      <div class="form-check-inline disabled">
                        <label class="form-check-label">
                          <input
                            style={{ height: "20px" }}
                            type="radio"
                            className="form-check-input"
                            name="optradio"
                          />
                          Lead Name
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label for="exampleFormControlSelect1">
                      <span style={{ color: "red" }}>*</span>
                      Enter Lead Name2
                    </label>
                    <input
                      style={{ height: "40px" }}
                      type="text"
                      className="form-control"
                    />
                  </div>{" "}
                  <div className="form-group col-md-2 mt-2">
                    <label for="exampleFormControlSelect1"></label>
                  </div>
                  <div className="form-group col-md-4">
                    <label for="exampleFormControlSelect1">
                      CX Crew Vendor
                    </label>
                    <select
                      select
                      style={{ height: "40px" }}
                      className="form-control"
                      id="exampleFormControlSelect1"
                      readOnly
                    >
                      <option>--Select--</option>
                      <option>DIY</option>
                      <option>OEM</option>
                      <option>Building</option>
                    </select>
                  </div>
                  <div className="form-group col-md-4 ">
                    <label for="exampleFormControlSelect1">
                      CX Crew Lead Contact Number
                    </label>
                    <input
                      style={{ height: "40px" }}
                      type="text"
                      className="form-control"
                      readOnly
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label for="exampleFormControlSelect1">
                      CX Crew Lead Name
                    </label>
                    <input
                      style={{ height: "40px" }}
                      type="text"
                      className="form-control"
                      id="input "
                      readOnly
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label for="exampleFormControlSelect1">
                      CX Crew Lead Company{" "}
                    </label>
                    <input
                      style={{ height: "40px" }}
                      type="text"
                      className="form-control"
                      id="input "
                      readOnly
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label for="exampleFormControlSelect1">
                      CX Crew Lead E-mail ID
                    </label>
                    <input
                      style={{ height: "40px" }}
                      type="text"
                      className="form-control"
                      id="input "
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    setixdata(
      <div class="accordion" id="accordionExample2">
        <div class="accordion-item">
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <div className="card card-body">
                <div className="form-row mt-2 p-2">
                  <div className="form-group col-md-4">
                    <label for="exampleFormControlSelect1">
                      IX Search By----
                    </label>
                    <div>
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input
                            style={{ height: "20px" }}
                            type="radio"
                            className="form-check-input"
                            name="optradio"
                          />
                          Contact Number
                        </label>
                      </div>
                      <div class="form-check-inline">
                        <label class="form-check-label">
                          <input
                            style={{ height: "20px" }}
                            type="radio"
                            className="form-check-input"
                            name="optradio"
                          />
                          E-mail
                        </label>
                      </div>
                      <div class="form-check-inline disabled">
                        <label class="form-check-label">
                          <input
                            style={{ height: "20px" }}
                            type="radio"
                            className="form-check-input"
                            name="optradio"
                          />
                          Lead Name
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label for="exampleFormControlSelect1">
                      <span style={{ color: "red" }}>*</span>
                      Enter Lead Name2
                    </label>
                    <input
                      style={{ height: "40px" }}
                      type="text"
                      className="form-control"
                    />
                  </div>{" "}
                  <div className="form-group col-md-2 mt-2">
                    <label for="exampleFormControlSelect1"></label>
                  </div>
                  <div className="form-group col-md-4">
                    <label for="exampleFormControlSelect1">
                      IX Crew Vendor
                    </label>
                    <select
                      select
                      style={{ height: "40px" }}
                      className="form-control"
                      id="exampleFormControlSelect1"
                      readOnly
                    >
                      <option>--Select--</option>
                      <option>DIY</option>
                      <option>OEM</option>
                      <option>Building</option>
                    </select>
                  </div>
                  <div className="form-group col-md-4 ">
                    <label for="exampleFormControlSelect1">
                      IX Crew Lead Contact Number
                    </label>
                    <input
                      style={{ height: "40px" }}
                      type="text"
                      className="form-control"
                      readOnly
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label for="exampleFormControlSelect1">
                      IX Crew Lead Name
                    </label>
                    <input
                      style={{ height: "40px" }}
                      type="text"
                      className="form-control"
                      id="input "
                      readOnly
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label for="exampleFormControlSelect1">
                      IX Crew Lead Company{" "}
                    </label>
                    <input
                      style={{ height: "40px" }}
                      type="text"
                      className="form-control"
                      id="input "
                      readOnly
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label for="exampleFormControlSelect1">
                      IX Crew Lead E-mail ID
                    </label>
                    <input
                      style={{ height: "40px" }}
                      type="text"
                      className="form-control"
                      id="input "
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    if (name == "cx") {
      setixdata("");
    }
    if (name == "ix") {
      setcxdata("");
    }
  };


  function gotoback(){
    dispatch(clearFetchSingleRecordAction())
    history.push("/PAG/ran/support/dashboard")
}
  useEffect(() => {
    // dispatch(clearFetchSingleRecordAction())
    console.log("the dispatch action",window.location);
    dispatch(UserListReducerAction(listUserApi_two));
    // dispatch(TierListReducerAction(managTMOEngineer))
    // dispatch(GetRanSingleProject({}))
    let url =window.location.href.split("/")
    console.log("the kjfiuebrfkhgc=",url,window.location)
    let dashboardType=url.slice(-3)[0]
    console.log("vkjfebvejkbfvcjksdfvc=",dashboardType)
    if(dashboardType=="ixDashboard"){
      dispatch(FetchSingleRecordAction(ixModule, index));
    }else{
      console.log("the datmlknqdwen=",dashboardType)
      dispatch(FetchSingleRecordAction(ranDashboard, index));
    }
  }, []);
  console.log(activeTab)
  return (
    <>


      {/* ............................ */}




      {/* ............................ */}


      <div className="card p-2 " style={{ width: "100%" }}>
        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li className="nav-item mr-2" role="presentation">
            <button
              // className={
              // alive === "precheck"
              //  " btn-primary btn-xs"
              // : " btn-light mx-1 btn-xs"
              // }
              className={
                activeTab == "preCheck" ? "nav-link  btn-xs active" : "nav-link  btn-xs "
              }
              id="pills-home-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-home"
              type="button"
              role="tab"
              aria-controls="pills-home"
              aria-selected="false"
            >
              PreCheck
            </button>
          </li>
          <li className="nav-item mr-2" role="presentation">
            <button //onClick={() => {setAlive("onthefly")}}
              //  className={
              // alive === "onthefly"
              //  " btn-primary btn-xs"
              //  " btn-light mx-1 btn-xs"
              // }
              className="nav-link btn-xs"
              id="pills-profile-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-profile"
              type="button"
              role="tab"
              aria-controls="pills-profile"
              aria-selected="false"
            >
              On The Fly
            </button>
          </li>
          <li className="nav-item mr-2" role="presentation">
            <button
              //  className={
              //  " btn-primary btn-xs"
              // " btn-light mx-1 btn-xs"
              // }
              className={
                activeTab == "gcLogin" ? "nav-link  btn-xs active" : "nav-link  btn-xs "
              }

              id="pills-contact-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-contact"
              type="button"
              role="tab"
              aria-controls="pills-contact"
              aria-selected="true"
            >
              GC Login
            </button>
          </li>
          <li className="nav-item mr-2" role="presentation">
            <button
              // className={
              // " btn-primary btn-xs"
              //  " btn-light mx-1 btn-xs"
              // }
              className={
                activeTab == "crewInfo" ? "nav-link  btn-xs active" : "nav-link  btn-xs "
              }
              id="pills-crew-tab"
              data-bs-toggle="pill"
              data-bs-target="#Crew-Information"
              type="button"
              role="tab"
              aria-controls="Crew-Information"
              aria-selected="false"
            >
              Crew Information
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              style={{ fontSize: "12px" }}
              // className={
              // alive === "postcheck"
              //  " btn-primary btn-xs"
              //  " btn-light mx-1 btn-xs"
              // }
              className={
                activeTab == "postCheck" ? "nav-link  btn-xs active" : "nav-link  btn-xs "
              }
              id="pills-post-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-post"
              type="button"
              role="tab"
              aria-controls="pills-post"
              aria-selected="false"
            >
              PostCheck
            </button>
          </li>
        </ul>
        <div className="tab-content" id="pills-tabContent">
          <div
            className={
              activeTab == "preCheck"
                ? "tab-pane fade show active"
                : "tab-pane fade show"
            }
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <PreCheck type={activeTab} />
            {/* <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-row">
                <div className="form-group col-md-4">
                  <label htmlFor="inputEmail4"><span style={{ color: "red" }} >*</span>SiteId</label>
                  <input style={{ height: "40px" }}
                    type="text"
                    className="form-control"
                    id="inputEmail4"
                    name='siteid'
                    readOnly
                    //value={siteDetail.site.ndResult.siteId}
                  
                  />
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="inputPassword4"><span style={{ color: "red" }} >*</span>Technology</label>
                  <input style={{ height: "40px" }}
                    type="text"
                    className="form-control"
                    id="inputPassword4"
                    name='technology'
                    readOnly
                    //value={siteDetail.site.temp}
                    // {...register("technology")}
                  />
                </div>
              
                <div className="form-group col-md-4">
                  <label htmlFor="inputPassword4"><span style={{ color: "red" }} >*</span>
                    Precheck Engineer</label>
                  <input style={{ height: "40px" }} className='form-control' list="Precheck Engineer" name="Precheck Engineer" id="browser"
                  {...register("preCheckEngineer")}
/>
                  <datalist id="Precheck Engineer">
                    <option value="Edge"></option>
                    <option value="Firefox"></option>
                    <option value="Chrome"></option>
                    <option value="Opera"></option>
                    <option value="Safari"></option>
                  </datalist>
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="inputState">Account</label>
                  <input style={{ height: "40px" }}
                    type="text"
                    className="form-control"
                    id="inputPassword4"
                    name='technology'
                    readOnly
                    //value={siteDetail.site.ndResult.account}
                    // {...register("account")}
                  />
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="inputState">Market</label>
                  <input style={{ height: "40px" }}
                    type="text"
                    className="form-control"
                    id="inputPassword4"
                    name='technology'
                    readOnly
                    //value={siteDetail.site.ndResult.market}
                    // {...register("market")}
                  />
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="inputState">GSM Status</label>
                  <select style={{ height: "40px" }} id="inputState" className="form-control"
                  {...register("GSMStatus")}
                  >
                    <option selected disabled value=''>Choose</option>
                    <option value={"Not Reachable"}>Not Reachable</option>
                    <option value={"Enabled"}>Enabled</option>
                    <option value={"Partially Enabled"}>Partially Enabled</option>
                    <option value={"NA"}>NA</option>
                  </select>
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="inputState">UMTS Status</label>
                  <select style={{ height: "40px" }} id="inputState" className="form-control"
                  {...register("UMTSStatus")}
                  >
                  <option selected disabled value=''>Choose</option>
                    <option value={"Not Reachable"}>Not Reachable</option>
                    <option value={"Enabled"}>Enabled</option>
                    <option value={"Partially Enabled"}>Partially Enabled</option>
                    <option value={"NA"}>NA</option>
                  </select>
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="inputState">LTE Status</label>
                  <select style={{ height: "40px" }} id="inputState" className="form-control"
                  {...register("LTEStatus")}
                  >
                  <option selected disabled value=''>Choose</option>
                    <option value={"Not Reachable"}>Not Reachable</option>
                    <option value={"Enabled"}>Enabled</option>
                    <option value={"Partially Enabled"}>Partially Enabled</option>
                    <option value={"NA"}>NA</option>
                  </select>
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="inputState">5G NR Status</label>
                  <select style={{ height: "40px" }} id="inputState" className="form-control"
                   {...register("5GNRStatus")}
                   >
                   <option selected disabled value=''>Choose</option>
                     <option value={"Not Reachable"}>Not Reachable</option>
                     <option value={"Enabled"}>Enabled</option>
                     <option value={"Partially Enabled"}>Partially Enabled</option>
                     <option value={"NA"}>NA</option>
                  </select>
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="inputState">GSM Alarms</label>
                  <select style={{ height: "40px" }} id="inputState" className="form-control" name='LTE IP Contact'
                  {...register("GSMAlarm")}
                  >
                    <option selected disabled value=''>Choose</option>
                    <option value={"Yes"}>yes</option>
                    <option value={"No"}>No</option>
                    <option value={"NA"}>NA</option>
                  </select>
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="inputState">UMTS Alarms</label>
                  <select style={{ height: "40px" }} id="inputState" className="form-control" name='LTE IP Contact'
                  {...register("UMTSAlarm")}
                  >
                    <option selected disabled value=''>Choose</option>
                    <option value={"Yes"}>yes</option>
                    <option value={"No"}>No</option>
                    <option value={"NA"}>NA</option>
                  </select>
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="inputState">LTE Alarm
                  </label>
                  <select style={{ height: "40px" }} id="inputState" className="form-control" name='LTE Alarm'
                  {...register("LTEAlarm")}
                  >
                    <option selected disabled value=''>Choose</option>
                    <option value={"Yes"}>yes</option>
                    <option value={"No"}>No</option>
                    <option value={"NA"}>NA</option>
                  </select>
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="inputState">5G NR Alarms
                  </label>
                  <select style={{ height: "40px" }} id="inputState" className="form-control"
                   {...register("5GNRAlarms")}
                   >
                     <option selected disabled value=''>Choose</option>
                     <option value={"Yes"}>yes</option>
                     <option value={"No"}>No</option>
                     <option value={"NA"}>NA</option>
                  </select>
                </div>
                
               

                
                
                <div className="form-group col-md-4">
                  <label htmlFor="inputCity">Precheck Alarm Issue</label>
                  <textarea style={{ height: "40px" }} type="text" className="form-control" id="inputCity" name='Precheck Alarm Issue' 
                  {...register("precheckAlarmIssue")}
                  />
                </div>
                <div className="form-group col-md-4">
                  <label htmlFor="inputCity">Log In Time Precheck delivered</label>
                  <input style={{ height: "40px" }} type="date" className="form-control" id="inputCity" name='Precheck Alarm Issue'
                  {...register("logInTimePreCheckDelivered")}
                  />
                </div>
                <div class="form-group col-md-4">
                  <div>
                    Upload File:
                    <label class="custom-file-upload btn btn-outline-primary btn-xs col-md-12">
                      <input
                        type="file"
                        className="form-control btn btn-primary "
                        id="inputGroupFile04"
                        aria-describedby="inputGroupFileAddon04"
                        aria-label="Upload"
                        name="file"
                        // {...register("logInTimePreCheckDelivered")}
                      />
                      <i class="fa fa-cloud-upload"></i> Choose File
                    </label>
                  </div>
                </div>
              </div>


              <div className='text-center '>
                <button type="submit" className="btn btn-primary btn-xs mx-1">
                  Submit
                </button>
                <button type="submit" className="btn btn-primary btn-xs  mx-1">
                  Precheck Tool
                </button>
                <button type="submit" className="btn btn-primary btn-xs  mx-1">
                  Raise Trouble Ticket
                </button>
                <button type="submit" className="btn btn-primary btn-xs  mx-1">
                  Back
                </button>
              </div>
            </form> */}
          </div>
          <div
            className="tab-pane fade"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
          >


            {console.log(siteDetail.site.ndResult?siteDetail.site.ndResult.technology:"","siteDetail.site.ndResult")}
            <form>
              <div className="form-row">
                <div className="form-group col-md-3">
                  <label htmlFor="inputEmail4">SiteId</label>
                  <input
                    style={{ height: "40px" }}
                    type="text"
                    className="form-control"
                    id="inputEmail4"
                    name="siteid"
                    readOnly
                    value={
                      siteDetail.site && siteDetail.site.ndResult
                        ? siteDetail.site.ndResult.siteId
                        : ""
                    }
                  />
                </div>
                {
                                                    dashboardType=="ixDashboard"?
                                                    <div className="form-group col-md-3">
                                                    <label for="exampleFormControlSelect1">Pre HW/Layer Configuration</label>
                                                    <input
                                                        style={{ height: "32px" }}
                                                        type="text"
                                                        className="form-control"
                                                        id="input "
                                                        readOnly
                                                        value={
                                                          siteDetail.site?siteDetail.site.techtake ? siteDetail.site.techtake.join(", ") : siteDetail.site.preHWLayerConfiguration?siteDetail.site.preHWLayerConfiguration.values.join(","):"":""
                                                        }
                                                    // {...register("market")}
                                                    />
                                                </div>
                                                    :
          <div className="form-group col-md-4">
            <label htmlFor="inputPassword4"><span style={{ color: "red" }} >*</span>Technology</label>
            <input style={{ height: "40px" }}
              type="text"
              className="form-control"
              id="inputPassword4"
              name='technology'
              readOnly
              value={siteDetail.site ? siteDetail.site.techtake ? siteDetail.site.techtake.join(", ") : "" : ""}
            // {...register("technology")}
            />
          </div>
}
                {/* <div className="form-group col-md-3">
                  <label htmlFor="inputPassword4">Technology</label>
                  <input
                    style={{ height: "40px" }}
                    type="text"
                    className="form-control"
                    id="inputPassword4"
                    name="technology"
                    readOnly
                    value={siteDetail.site && siteDetail.site.techtake ? siteDetail.site.techtake.join(", ") : ""}
                  />
                </div> */}
                <div className="form-group col-md-3">
                  <label htmlFor="inputPassword4">
                    T-Mobile Trouble Ticket ID (IF applicable)
                  </label>
                  <input
                    style={{ height: "40px" }}
                    type="text"
                    className="form-control"
                    id="inputPassword4"
                    name="T-Mobile Trouble Ticket ID (IF applicable)"
                    readOnly
                  />
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="inputState">Account</label>
                  <input
                    style={{ height: "40px" }}
                    type="text"
                    className="form-control"
                    id="inputPassword4"
                    name="Account"
                    readOnly
                    value={
                      siteDetail.site && siteDetail.site.ndResult
                        ? siteDetail.site.ndResult.account
                        : ""
                    }
                  />
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="inputState">Market</label>
                  <input
                    style={{ height: "40px" }}
                    type="text"
                    className="form-control"
                    id="inputPassword4"
                    name="Market"
                    readOnly
                    value={
                      siteDetail.site && siteDetail.site.ndResult
                        ? siteDetail.site.ndResult.market
                        : ""
                    }
                  />
                </div>

                <div className="form-group col-md-3">
                  <label htmlFor="inputState">CX Crew Vendor</label>
                  <input
                    style={{ height: "40px" }}
                    type="text"
                    className="form-control"
                    id="inputPassword4"
                    name="CX Crew Vendor"
                    readOnly
                    value={siteDetail.site && siteDetail.site.cxCrewId ? siteDetail.site.cxCrewId.vendor : ""}
                  />
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="inputState">
                    CX Crew Lead Contact Number
                  </label>
                  <input
                    style={{ height: "40px" }}
                    type="text"
                    className="form-control"
                    id="inputPassword4"
                    name="CX Crew Lead Contact Number"
                    readOnly
                    value={siteDetail.site && siteDetail.site.cxCrewId ? siteDetail.site.cxCrewId.mobilenumber : ""}
                  />
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="inputState">CX Crew Lead Name</label>
                  <input
                    style={{ height: "40px" }}
                    type="text"
                    className="form-control"
                    id="inputPassword4"
                    name="CX Crew Lead Name"
                    readOnly
                    value={siteDetail.site && siteDetail.site.cxCrewId ? siteDetail.site.cxCrewId.name : ""}
                  //value={siteDetail.site.CXCrewVendor}
                  />
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="inputState">CX Crew Lead Company</label>
                  <input
                    style={{ height: "40px" }}
                    type="text"
                    className="form-control"
                    id="inputPassword4"
                    name="CX Crew Lead Company"
                    readOnly
                    value={siteDetail.site && siteDetail.site.cxCrewId ? siteDetail.site.cxCrewId.crewLeadCompanyId : ""}
                  />
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="inputState">CX Crew Lead E-mail ID</label>
                  <input
                    style={{ height: "40px" }}
                    type="text"
                    className="form-control"
                    id="inputPassword4"
                    name="CX Crew Lead E-mail ID"
                    readOnly
                    value={siteDetail.site && siteDetail.site.cxCrewId ? siteDetail.site.cxCrewId.email : ""}
                  />
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="inputCity">IX Crew Vendor</label>
                  <input
                    style={{ height: "40px" }}
                    type="text"
                    className="form-control"
                    id="inputPassword4"
                    name="IX Crew Vendor"
                    readOnly
                    value={siteDetail.site && siteDetail.site.ixCrewId ? siteDetail.site.ixCrewId.vendor : ""}
                  //value={siteDetail.site.IXCrewVendor}
                  />
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="inputCity">
                    IX Crew Lead Contact Number{" "}
                  </label>
                  <input
                    style={{ height: "40px" }}
                    type="text"
                    className="form-control"
                    id="inputPassword4"
                    name="IX Crew Lead Contact Number"
                    readOnly
                    value={siteDetail.site && siteDetail.site.ixCrewId ? siteDetail.site.ixCrewId.mobilenumber : ""}
                  />
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="inputCity">IX Crew Lead Name</label>
                  <input
                    style={{ height: "40px" }}
                    type="text"
                    className="form-control"
                    id="inputPassword4"
                    name="IX Crew Lead Name"
                    readOnly
                    value={siteDetail.site && siteDetail.site.ixCrewId ? siteDetail.site.ixCrewId.name : ""}
                  />
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="inputCity">IX Crew Lead Company</label>
                  <input
                    style={{ height: "40px" }}
                    type="text"
                    className="form-control"
                    id="inputPassword4"
                    name="IX Crew Lead Company"
                    readOnly
                    value={siteDetail.site && siteDetail.site.ixCrewId ? siteDetail.site.ixCrewId.crewLeadCompanyId : ""}
                  />
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="inputCity">IX Crew Lead E-mail ID</label>
                  <input
                    style={{ height: "40px" }}
                    type="text"
                    className="form-control"
                    id="inputPassword4"
                    name="IX Crew Lead E-mail ID"
                    readOnly
                    value={siteDetail.site && siteDetail.site.ixCrewId ? siteDetail.site.ixCrewId.email : ""}
                  />
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="inputCity">Service Affecting?</label>
                  <input
                    style={{ height: "40px" }}
                    type="text"
                    className="form-control"
                    id="inputPassword4"
                    name="Service Affecting?"
                    readOnly
                    value={siteDetail.site && siteDetail.site.serviceAffected}
                  />
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="inputCity">Day MOP or Night MOP</label>
                  <input
                    style={{ height: "40px" }}
                    type="text"
                    className="form-control"
                    id="inputPassword4"
                    name="Day MOP or Night MOP"
                    readOnly
                    value={siteDetail.site && siteDetail.site.dayNightMop}
                  />
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="inputCity">Reason for Day MOP</label>
                  <input
                    style={{ height: "40px" }}
                    type="text"
                    className="form-control"
                    id="inputPassword4"
                    name="Reason for Day MOP"
                    readOnly
                    value={siteDetail.site && siteDetail.site.dayMopReason}
                  />
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="inputCity">TMO Outage Approval?</label>
                  <input
                    style={{ height: "40px" }}
                    type="text"
                    className="form-control"
                    id="inputPassword4"
                    name="TMO Outage Approval?"
                    readOnly
                    value={siteDetail.site && siteDetail.site.RF_Approval}
                  />
                </div>
                {/* <div className="form-group col-md-3">
                  <label htmlFor="inputCity">TMO Outage Approval?</label>
                  <input
                    style={{ height: "40px" }}
                    type="time"
                    className="form-control"
                    id="inputPassword4"
                    name="TMO Outage Approved MW Time"
                    readOnly
                    //value={siteDetail.site.startTime}
                  />
                </div> */}
                <div className="form-group col-md-3">
                  <label htmlFor="inputCity">TMO Outage Approved MW Time</label>
                  <input
                    style={{ height: "40px" }}
                    type="text"
                    className="form-control"
                    id="inputPassword4"
                    name="TMO Outage Approved MW Time"
                    // readOnly
                    value={siteDetail.site && siteDetail.site.RF_Approved_MW_Time && siteDetail.site.RF_Approved_EndTime_Time ? getdatedatalistnext(siteDetail.site.RF_Approved_MW_Time).split(" ")[1] + " to " + getdatedatalistnext(siteDetail.site.RF_Approved_EndTime_Time).split(" ")[1] : ""}
                  />
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="inputCity">TMO Deployment Manager</label>
                  <input
                    style={{ height: "40px" }}
                    type="text"
                    className="form-control"
                    id="inputPassword4"
                    name="TMO Deployment Manager"
                    readOnly
                    value={siteDetail.site && siteDetail.site.tmoDeploymentManager}
                  />
                </div>
                <div className="form-group col-md-3">
                  <label htmlFor="inputCity">Planned SoW</label>
                  {/* <ReactSelect
                    options={plannedSowArray}
                    isMulti
                    name="colors"
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{ Option }}
                    onChange={plannedSowChangleHandler}
                    value={plannedSow}
                    allowSelectAll={true}
                    variant="danger"
                    classNamePrefix="select"
                  /> */}
                  <input type="text" className="form-control"
                    value={pSow}
                  ></input>
                </div>
              </div>
              <div className="text-center mt-3">
                {/* <button className="btn btn-primary btn-xs">Submit</button>
              <button className="btn btn-primary btn-xs mx-2">Back</button> */}
              </div>
            </form>
          </div>
          <div
            className={
              activeTab == "gcLogin"
                ? "tab-pane fade show active"
                : "tab-pane fade show"
            }
            id="pills-contact"
            role="tabpanel"
            aria-labelledby="pills-contact-tab"
          >
            <GCLogin />
            {/* {
            activeTab == "gcLogin"
            ?
            :""} */}
            {/* <>
              <form>
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <label htmlFor="inputEmail4">SiteId</label>
                    <input
                      style={{ height: "40px" }}
                      type="text"
                      className="form-control"
                      id="inputEmail4"
                      name="siteid"
                      readOnly
                    //value={siteDetail.site.ndResult.siteId}
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="inputPassword4">Select Technology</label>
                    <input
                      style={{ height: "40px" }}
                      type="text"
                      className="form-control"
                      id="inputPassword4"
                      name="technology"
                      readOnly
                    //value={siteDetail.site.temp}
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="inputPassword4">New Technology</label>
                    <select
                      style={{ height: "40px" }}
                      id="inputState"
                      className="form-control"
                      name="UMTS"
                    >
                      <option selected="">None...</option>
                      <option>yes</option>
                      <option>No</option>
                    </select>
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="inputPassword4">Planned SoW</label>
                    <select
                      style={{ height: "40px" }}
                      id="inputState"
                      className="form-control"
                      name="UMTS"
                    >
                      <option selected="">None...</option>
                      <option>yes</option>
                      <option>No</option>
                    </select>
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="inputState">Nesting Time</label>
                    <input
                      style={{ height: "40px" }}
                      type="text"
                      className="form-control"
                      id="inputPassword4"
                      name="technology"
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="inputState">Account</label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputPassword4"
                      name="technology"
                      readOnly
                    //value={siteDetail.site.ndResult.account}
                    ></input>
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="inputState">Market</label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputPassword4"
                      name="technology"
                      readOnly
                    //value={siteDetail.site.ndResult.market}
                    ></input>
                  </div>

                  <div className="form-group col-md-4">
                    <label htmlFor="inputState">SoW Details</label>
                    <select
                      style={{ height: "40px" }}
                      id="inputState"
                      className="form-control"
                      name="5G Alarm"
                    >
                      <option selected="">None...</option>
                      <option>yes</option>
                      <option>No</option>
                    </select>
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="inputState">SoW Comment</label>
                    <input
                      style={{ height: "40px" }}
                      type="text"
                      className="form-control"
                      id="inputPassword4"
                      name="technology"
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="inputState">Next Comment</label>
                    <input
                      style={{ height: "40px" }}
                      type="text"
                      className="form-control"
                      id="inputPassword4"
                      name="technology"
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="inputState">Login Time Prechecks</label>
                    <input
                      style={{ height: "40px" }}
                      type="date"
                      className="form-control"
                      id="inputPassword4"
                      name="technology"
                    />
                  </div>
                </div>
              </form>
            </>
            <div className="text-center mt-3">
              <button className="btn btn-primary btn-xs">Update</button>
              <button className="btn btn-primary btn-xs mx-2">Back</button>
            </div> */}
          </div>
          <div
            className={
              activeTab == "crewInfo"
                ? "tab-pane fade show active"
                : "tab-pane fade show"
            }
            id="Crew-Information"
            role="tabpanel"
            aria-labelledby="pills-crew-tab"
          >
            <>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-row">
                  <div className="form-group col-md-3">
                    <label htmlFor="inputEmail4">SiteId</label>
                    <input
                      style={{ height: "40px" }}
                      type="text"
                      className="form-control"
                      id="inputEmail4"
                      name="siteid"
                      readOnly
                      value={
                        siteDetail.site && siteDetail.site.ndResult
                          ? siteDetail.site.ndResult.siteId
                          : ""
                      } //value={siteDetail.site.ndResult.siteId}
                    />
                  </div>
                  {
                                                    dashboardType=="ixDashboard"?
                                                    <div className="form-group col-md-3">
                                                    <label for="exampleFormControlSelect1">Pre HW/Layer Configuration</label>
                                                    <input
                                                        style={{ height: "32px" }}
                                                        type="text"
                                                        className="form-control"
                                                        id="input "
                                                        readOnly
                                                        value={
                                                          siteDetail.site?siteDetail.site.techtake ? siteDetail.site.techtake.join(", ") : siteDetail.site.preHWLayerConfiguration?siteDetail.site.preHWLayerConfiguration.values.join(","):"":""
                                                        }
                                                    // {...register("market")}
                                                    />
                                                </div>
                                                    :
          <div className="form-group col-md-4">
            <label htmlFor="inputPassword4"><span style={{ color: "red" }} >*</span>Technology</label>
            <input style={{ height: "40px" }}
              type="text"
              className="form-control"
              id="inputPassword4"
              name='technology'
              readOnly
              value={siteDetail.site ? siteDetail.site.techtake ? siteDetail.site.techtake.join(", ") : "" : ""}
            // {...register("technology")}
            />
          </div>
}
                  {/* <div className="form-group col-md-3">
                    <label htmlFor="inputPassword4"> Technology</label>
                    <input
                      style={{ height: "40px" }}
                      type="text"
                      className="form-control"
                      id="inputPassword4"
                      name="technology"
                      // value={
                      //   siteDetail.site && siteDetail.site.ndResult?siteDetail.site.ndResult.technology
                      //     ? siteDetail.site.ndResult.technology.values.join(",")
                      //     : "" : ""
                      // }

                      value={siteDetail.site.techtake ? siteDetail.site.techtake.join(", ") : ""}
                      
                    />
                  </div> */}
                  <div className="form-group col-md-3">
                    <label htmlFor="inputPassword4">Account</label>
                    <input
                      style={{ height: "40px" }}
                      type="text"
                      className="form-control"
                      id="inputPassword4"
                      name="technology"
                      readOnly
                      value={siteDetail.site && siteDetail.site.ndResult ? siteDetail.site.ndResult.account : ""}
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label htmlFor="inputPassword4">Market</label>
                    <input
                      style={{ height: "40px" }}
                      type="text"
                      className="form-control"
                      id="inputPassword4"
                      name="technology"
                      readOnly
                      value={siteDetail.site && siteDetail.site.ndResult ? siteDetail.site.ndResult.market : ""}
                    />
                  </div>
                  {/* <div className="form-group col-md-3">
                    <label htmlFor="inputPassword4">HO Cx Crew Vendor</label>
                    <input
                      style={{ height: "40px" }}
                      type="text"
                      className="form-control"
                      id="inputPassword4"
                      name="technology"
                      readOnly
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label htmlFor="inputPassword4">
                      HO Cx Crew Lead Contact Number
                    </label>
                    <input
                      style={{ height: "40px" }}
                      type="text"
                      className="form-control"
                      id="inputPassword4"
                      name="technology"
                      readOnly
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label htmlFor="inputPassword4">HO Cx Crew Lead Name</label>
                    <input
                      style={{ height: "40px" }}
                      type="text"
                      className="form-control"
                      id="inputPassword4"
                      name="technology"
                      readOnly
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label htmlFor="inputPassword4">
                      HO Cx Crew Lead Company
                    </label>
                    <input
                      style={{ height: "40px" }}
                      type="text"
                      className="form-control"
                      id="inputPassword4"
                      name="technology"
                      readOnly
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label htmlFor="inputPassword4">
                      HO Cx Crew Lead E-mail ID
                    </label>
                    <input
                      style={{ height: "40px" }}
                      type="text"
                      className="form-control"
                      id="inputPassword4"
                      name="technology"
                      readOnly
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label htmlFor="inputPassword4">
                      HO Cx Crew Lead E-mail ID
                    </label>
                    <input
                      style={{ height: "40px" }}
                      type="text"
                      className="form-control"
                      id="inputPassword4"
                      name="technology"
                      readOnly
                    />
                  </div>

                  <div className="form-group col-md-3">
                    <label htmlFor="inputPassword4">HO Ix Crew Vendor</label>
                    <input
                      style={{ height: "40px" }}
                      type="text"
                      className="form-control"
                      id="inputPassword4"
                      name="technology"
                      readOnly
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label htmlFor="inputPassword4">
                      HO Ix Crew Lead Contact Number
                    </label>
                    <input
                      style={{ height: "40px" }}
                      type="text"
                      className="form-control"
                      id="inputPassword4"
                      name="technology"
                      readOnly
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label htmlFor="inputPassword4">HO Ix Crew Lead Name</label>
                    <input
                      style={{ height: "40px" }}
                      type="text"
                      className="form-control"
                      id="inputPassword4"
                      name="technology"
                      readOnly
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label htmlFor="inputPassword4">
                      HO Ix Crew Lead Company
                    </label>
                    <input
                      style={{ height: "40px" }}
                      type="text"
                      className="form-control"
                      id="inputPassword4"
                      name="technology"
                      readOnly
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label htmlFor="inputPassword4">
                      HO Ix Crew Lead E-mail ID
                    </label>
                    <input
                      style={{ height: "40px" }}
                      type="text"
                      className="form-control"
                      id="inputPassword4"
                      name="technology"
                      readOnly
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label htmlFor="inputPassword4">
                      HO Ix Crew Lead E-mail ID
                    </label>
                    <input
                      style={{ height: "40px" }}
                      type="text"
                      className="form-control"
                      id="inputPassword4"
                      name="technology"
                      readOnly
                    />
                  </div> */}
                </div>
              </form>
              {console.log(DbName[1],DbName[0],DbName[2],"DbName[1],DbName[0],DbName[2]")}
              {
                DbName[1] && DbName[0] && DbName[2] ?
                  <CommonCrew errors={errors} register={register} setValue={setValue} crewType={DbName[1]} currentValueName={DbName[0]} currentValueId={DbName[2]} />
                  : ""

              }

              {/* 
              <div className="text-center">
                <button
                  onClick={(e) => abc(e, "cx")}
                  class="btn btn-primary mx-2 btn-xs"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                >
                  CX
                </button>
                <button
                  onClick={(e) => abc(e, "ix")}
                  class="btn btn-primary mx-2 btn-xs"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  IX
                </button>
                {/* <button
                  onClick={(e) => abc(e, "")}
                  class="btn btn-primary mx-2 btn-xs"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo,#collapseOne"
                  aria-expanded=""
                  aria-controls="collapseTwo,collapseOne"
                >
                  Both
                </button> */}
              {/* </div> 
              {cxdata}
              {ixdata} */}

              {/* <div className="form-group col-md-3">
                <label htmlFor="inputCity">Day MOP or Night MOP</label>
                <select
                  style={{ height: "40px" }}
                  id="inputState"
           x<option selected="">Choose...</option>
                  <option>Night Mop</option>
                  <option>Day Mop</option>
                </select>{" "}
              </div> */}
            </>
            <div className="text-center mt-3">
              {
                activeTab == "crewInfo" ?
                  <>
                    <button className="btn btn-primary btn-xs mx-2"
                      // disabled={siteDetail.site ? siteDetail.site.numberStatus < 8 ? false : true : false}
                      onClick={() => {
                        if (siteDetail.site && siteDetail.site.numberStatus < 8) {
                          handoverCrew()
                        }
                      }

                      }>Submit</button>
                      <button className="btn btn-primary btn-xs mx-2" onClick={()=>{gotoback()}}>Back</button>
                  </> :""
                } 
                  
                  {/* <button className="btn btn-primary btn-xs mx-2"
                  disabled={true}>Submit</button>
                  <button className="btn btn-primary btn-xs mx-2" onClick={()=>{gotoback()}}>Back</button> */}
            </div>

            <div className="card  p-2 mt-4" style={{ width: "100%" }}>
              <FormData />
            </div>
          </div>
          <div
            className={
              activeTab == "postCheck"
                ? "tab-pane fade show active"
                : "tab-pane fade show"
            }
            id="pills-post"
            role="tabpanel"
            aria-labelledby="pills-post-tab"
          >
            <PostCheck type={activeTab} />
          </div>
        </div>
      </div>



    </>
  );
};

export default OnTheFlyForm;
