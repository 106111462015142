import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import { useEffect } from "react";
import moment from "moment";
import CommonModal from '../Custom/CommonModal';
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Row,
  Card,
  Col,
  Dropdown,
  Button,
  Modal,
  Container,
} from "react-bootstrap";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  accountApi,
  addUser,
  deleteUser,
  listRole_twoApi,
  getMarket,
  crewCompanyApi,
  listUserApi_two,
  vendorApi,
  addAcccount,
  listOneUser,
  updateUser,
  CsvUpload,
  dynamic_dashboard,
  export_networkMDB_dashboard,
  ranfilters,
  nwfilters,
  networkDBLoggger,
  networkDBMerger
} from "../../../common/config";
import {
  AccountReducerAction,
  OneUserReducerAction,
  addUserAction,
  deleteAcccountReducerAction,
  GetMarketReducerAction,
  getCrewCompanyAction,
  updateAcccountReducerAction,
  RoleReducerAction,
  UserListReducerAction,
  GetCSVReducerAction,
  networkGetCSVReducerAction,
  ExportXLSXDownloadReducerAction,
  getFilterDropDown,
  networkGetLoggerReducerAction,
  networkMergerReducerAction,
  popUpUpdate
} from "../../../store/actions/createTicketManageFields";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { black } from "material-ui/styles/colors";
import { PDFUploadActionreturn } from "../../../store/actions/dashboardActions";
import TablePagination from "@mui/material/TablePagination";
import { customDataTableOptionObject, getMuiThemeCommon } from "../../../common/variables";
import NetworkDBFilters from "../Custom/Modal Body/NetworkDBFilters";
import FilterData from "../../../icons/Svg/FilterData";

const NetworkDatabasebulkfile = (props) => {

  const [commonModalState, setcommonModalState] = useState(false);
  const [commonModalSize, setcommonModalSize] = useState(false);
  const [commonModalTitle, setcommonModalTitle] = useState("");
  const [commonModalBody, setcommonModalBody] = useState("");
  const [commonModalHeader, setcommonModalHeader] = useState("");
  const [closer, setCloser] = useState(false);
  
  const [fullUrL, setfullUrL] = useState("");
  const [exportApiData, setexportApiData] = useState("");
  const [filterBadges, setFilterBadge] = useState()
  const [OneTimere, setOneTimere] = useState(false);
  const [ResetFilter, setResetFilter] = useState(false);
  // const history = useHistory();
  const url = window.location.href.split("/");
  let dynamicValue = url.pop();
  let pStatusArry = [];
  let vStatusArry = [];
  let mStatusArry = [];
  let cStatusArry = [];

  function filterhandler() {

    dispatch(getFilterDropDown([]))
    // dispatch(AccountReducerAction(ssvfilters))
    dispatch(AccountReducerAction(nwfilters))
    setcommonModalState(true)
    setcommonModalSize("xl")
    setcommonModalBody(<NetworkDBFilters setfullUrL={setfullUrL} setcommonModalState={setcommonModalState} setFilterBadge={setFilterBadge} featureExport={setexportApiData} />)
    setcommonModalTitle("Network DB Filters")
    setcommonModalHeader("Network DB Filter")
  }

  // let editTrue=0
  const options = {
    download: "false",
    filterType: "none",
    selectableRows: false,
    responsive: true,
    print: false,
    rowsPerPage: 15,
    count: 100,
    rowsPerPageOptions: [15, 30, 45, 60],
    fixedHeader: true,
    fixedFooter: true,
    tableBodyHeight: "100%",
    responsive: "scroll",
    overflowY: "scroll",
  };

  let accountArry = [];
  let userArry = [];

  let columns = [];

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});
  const [show, setShow] = useState(false);
  const [siteFile, uploadedFile] = useState([]);
  const [fileshow, setfileShow] = useState(false);
  const [page, setPage] = useState(2);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [senter, setsenter] = useState(2);


  const exportAllData = () => {
    dispatch(ExportXLSXDownloadReducerAction(export_networkMDB_dashboard + fullUrL, "NETWORKDATABASE_" + moment(new Date()).format("MM-DD-YYYY")))
    // FileSaver.saveAs(baseURL+"/export/ran", "RAN_Support_" + moment(new Date()).format("MM-DD-YYYY"))
    // history.push(baseURL+"/export/ran")
  }

  const handleChangePage = (abc) => {
    setPage(page + 1);
    console.log("handleChangePage");
    if (abc.target.dataset.testid == "KeyboardArrowRightIcon") {
      setsenter(1);
      dispatch(
        networkGetCSVReducerAction(
          "/" + dynamicValue + dynamic_dashboard + "?page=" + page * 10
        )
      );
      setPage(page + 1);
    } else if (abc.target.dataset.testid == "KeyboardArrowLeftIcon") {
      setPage(page - 1);
    }
  };

  const handleChangeRowsPerPage = () => {
    console.log("handleChangeRowsPerPage");
    setRowsPerPage(11);
    setPage(0);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    console.log("form==", "form");
    let newform = new FormData();
    newform.append("uploadFile", siteFile);
    newform.append("uploadfor", dynamicValue);
    console.log("form==", newform);
    console.log(
      "resp",
      dispatch(PDFUploadActionreturn(CsvUpload, newform, "usercrew"))
    );
    setShow(false);
  };

  const uploadCsvFile = (e) => {
    // let files=[]
    console.log(e.target.files, "files");
    uploadedFile(e.target.files[0]);
  };

  const filehandleClose = () => {
    reset({});
    setfileShow(false);
  };
  const filehandleShow = () => {
    reset({});
    setfileShow(true);
  };

  // const uploadCsvFile = (e) => {
  //   let files=[]
  //   console.log(e.target.files[0],"ON_CHANGE_FILES:", e.target.files.length);
  //   for(let i=0;i<e.target.files.length;i++){
  //     files.push(e.target.files[i])
  //  }
  //   console.log(...files)
  //   uploadedFile(files)
  // };

  const [modalCentered, setModalCentered] = useState(false);
  const [newAccount, setAccount] = useState({});
  const [editTrue, seteditTrue] = useState(0);
  const [updateTrue, setupdateTrue] = useState(0);
  const [formtype, addformtype] = useState("");

  let oneaccount = {};

  // setAccount(oneaccount)
  //  console.log(oneaccount,"oneaccount")
  // const getMuiTheme = () => createTheme({
  //     components: {
  //         MUIDataTableBodyCell: {
  //             styleOverrides: {
  //                 root: {
  //                     backgroundColor: "transparent",
  //                     color: black
  //                 }
  //             }
  //         }
  //     }
  // })

  console.log();
  const updateHandler = (e) => {
    console.log("button update clicked", e);

    dispatch(addUserAction(addAcccount, e));
  };
  const deleteHandler = (e) => {
    console.log("button delete clicked", e);
    dispatch(deleteAcccountReducerAction(deleteUser, e, "crew"));
  };

  const editpopupHandler = (e) => {
    seteditTrue(1);
    reset({});
    console.log("button editpopupHandler clicked", e);
    dispatch(OneUserReducerAction(listOneUser + "/" + e.uniqueid));
  };

  const onSubmit = async (data) => {
    console.log(data, "dassdaas");

    if (data.uniqueid != undefined) {
      dispatch(
        updateAcccountReducerAction(updateUser, data.uniqueid, data, "crew")
      );
    } else {
      dispatch(addUserAction(addUser, data, "crew"));
    }
  };

  const dispatch = useDispatch();
  let keyArray = [];
  let labelMapping = [];



  const ClearFilter = () => {
    dispatch(networkGetCSVReducerAction("/networkDatabase" + dynamic_dashboard))
  };

  columns = [
    {
      name: "market",
      selector: "market",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            left: "0px",
            zIndex: 101,
            minWidth: "70px",
            whiteSpace: "wrap",
            alignItem: "center",
            "max-width": "90px",
            padding: "1px 1px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0px",
            zIndex: 100,
            position: "sticky",
            minWidth: "70px",
            overflowY: "hidden",
            padding: "1px 1px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      },
      label: "Market Combined",
    },
    {
      name: "RingCode",
      selector: "RingCode",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            left: "76px",
            zIndex: 101,
            minWidth: "70px",
            whiteSpace: "wrap",
            alignItem: "center",
            "max-width": "80px",
            padding: "1px 1px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "76px",
            zIndex: 100,
            position: "sticky",
            minWidth: "70px",
            overflowY: "hidden",
            padding: "1px 1px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      },
      label: "R:Ring Code",
    },
    {
      name: "siteId",
      selector: "siteId",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            left: "146px",
            zIndex: 101,
            minWidth: "70px",
            whiteSpace: "wrap",
            alignItem: "center",
            "max-width": "80px",
            padding: "1px 1px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "146px",
            zIndex: 100,
            position: "sticky",
            minWidth: "70px",
            overflowY: "hidden",
            padding: "1px 1px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      },
      label: "S:Site Code",
    },
    {
      name: "state",
      selector: "state",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            "max-width": "70px",
            "min-width": "70px",
            padding: "1px 1px",
            background: "#143b64",
            color: "white",
            zIndex: 100,
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            zIndex: 99,
            minWidth: "70px",
            overflowY: "hidden",
            padding: "1px 1px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      },
      label: "S:State",
    },
    {
      name: "siteType",
      selector: "siteType",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            minWidth: "150px",
            padding: "1px 1px",
            background: "#143b64",
            color: "white",
            zIndex: 100,
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            zIndex: 99,
            minWidth: "70px",
            overflowY: "hidden",
            padding: "1px 1px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      },
      label: "S:Site Type",
    },
    {
      name: "siteClass",
      selector: "siteClass",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            minWidth: "150px",
            padding: "1px 1px",
            background: "#143b64",
            color: "white",
            zIndex: 100,
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            zIndex: 99,
            minWidth: "70px",
            overflowY: "hidden",
            padding: "1px 1px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      },
      label: "S:Site Class",
    },
    {
      name: "sectorCoverageTypeSummary",
      selector: "sectorCoverageTypeSummary",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            minWidth: "250px",
            padding: "1px 1px",
            background: "#143b64",
            color: "white",
            zIndex: 100,
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            zIndex: 99,
            minWidth: "70px",
            overflowY: "hidden",
            padding: "1px 1px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      },
      label: "S:Sector Coverage Type Summary (New, Provision, On-Air Only)",
    },
    {
      name: "siteName",
      selector: "siteName",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            minWidth: "150px",
            padding: "1px 1px",
            background: "#143b64",
            color: "white",
            zIndex: 100,
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            zIndex: 99,
            minWidth: "70px",
            overflowY: "hidden",
            padding: "1px 1px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      },
      label: "S:Site Name",
    },
    {
      name: "address",
      selector: "address",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            zIndex: 100,
            alignItem: "center",
            minWidth: "150px",
            padding: "1px 1px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            zIndex: 99,
            minWidth: "70px",
            overflowY: "hidden",
            padding: "1px 1px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      },
      label: "S:Address",
    },
    {
      name: "address2",
      selector: "address2",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            zIndex: 100,
            alignItem: "center",
            minWidth: "150px",
            padding: "1px 1px",
            background: "#143b64",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            zIndex: 99,
            minWidth: "70px",
            overflowY: "hidden",
            padding: "1px 1px",
            outline: "1.5px solid black",
            background: "White",
          },
        }),
      },
      label: "S:Address2",
    },
    {
      name: "city",
      selector: "city",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            minWidth: "150px",
            padding: "1px 1px",
            background: "#143b64",
            color: "white",
          },
        }),
      },
      label: "S:City",
    },
    {
      name: "zip",
      selector: "zip",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            minWidth: "150px",
            padding: "1px 1px",
            background: "#143b64",
            color: "white",
          },
        }),
      },
      label: "S:Zip",
    },
    {
      name: "county",
      selector: "county",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            minWidth: "150px",
            padding: "1px 1px",
            background: "#143b64",
            color: "white",
          },
        }),
      },
      label: "S:County",
    },
    {
      name: "ProgramName",
      selector: "ProgramName",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            "min-width": "200px",
            "max-width": "240px",
            padding: "1px 1px",
            background: "#143b64",
            color: "white",
          },
        }),
      },
      label: "PJ:Program Name",
    },
    {
      name: "projectCode",
      selector: "projectCode",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            minWidth: "150px",
            padding: "1px 1px",
            background: "#143b64",
            color: "white",
          },
        }),
      },
      label: "POR:Plan Type",
    },
    {
      name: "projectId",
      selector: "projectId",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            minWidth: "150px",
            padding: "1px 1px",
            background: "#143b64",
            color: "white",
          },
        }),
      },
      label: "PJ:Project ID",
    },
    {
      name: "CurrentPlanStatus",
      selector: "CurrentPlanStatus",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            minWidth: "150px",
            padding: "1px 1px",
            background: "#143b64",
            color: "white",
          },
        }),
      },
      label: "POR:Current Plan Status",
    },
    {
      name: "latitude",
      selector: "latitude",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            minWidth: "150px",
            padding: "1px 1px",
            background: "#143b64",
            color: "white",
          },
        }),
      },
      label: "Latitude",
    },
    {
      name: "longitude",
      selector: "longitude",
      sortable: true,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            minWidth: "150px",
            padding: "1px 1px",
            background: "#143b64",
            color: "white",
          },
        }),
      },
      label: "Longitude",
    },
  ];

  let data = useSelector((state) => {

    if(state.popUpState){
      console.log(state.popUpState.popstatus.status,closer,(state.popUpState.popstatus.status==201 && closer) || (state.popUpState.popstatus.status==200 && closer),"popUpStatestatus201")
      if((state.popUpState.popstatus.status==201 && closer) || (state.popUpState.popstatus.status==200 && closer)){
        dispatch(popUpUpdate({}))
        setCloser(false)
        setcommonModalState(false)
        dispatch(networkGetLoggerReducerAction(networkDBLoggger));
      }
    }
    let csvResponse = state.csvResponse;
    let totalCount = state.countState.networkDBCount
    let Logger = csvResponse.networkLogger
    if (csvResponse) {
      if (csvResponse.networkdatabase) {
        userArry = csvResponse.networkdatabase;
      }
    }
    return { userArry, totalCount, Logger};
  });


  const mergeopernerLogger = () => {
    dispatch(networkMergerReducerAction(networkDBMerger));
    setCloser(true)
    dispatch(popUpUpdate({}));
  }


  const opernerLogger = () => {

    setcommonModalBody(<>
    <button style={{"float":"right"}} onClick={mergeopernerLogger} className="btn btn-xs btn-primary mx-2 py-2">Update</button>
    <h5>Last Update Status : {data.Logger.LastMergingStatus}</h5>
    <h5>Last Update Start At : {data.Logger.LastMergingStartAt}</h5>
    <h5>Last Update Finish At : {data.Logger.LastMergingFinishAt}</h5>

    <h5>Last Magenta Entry Missing Sites : {data.Logger.PendingArra.join(", ")}</h5>
    </>)
    setcommonModalState(true)

    setcommonModalSize("md")
    setcommonModalTitle("Inflight and Magenta Merger")
    setcommonModalHeader("Inflight and Magenta Merger")

    
  };

  console.log(data, "data");

  const AddCusTButton = () => (
    <>

      <Button onClick={opernerLogger} className="btn btn-primary btn-xs mx-2 py-2 my-3 d-flex justify-content-center align-items-center" >
        Update NW DB
      </Button>
      <p onClick={filterhandler} className=" mx-2 " style={{ "align-self": "center" }}><FilterData /></p>
      <>
        <button
          className="btn btn-xs btn-primary mx-2 my-3"
          data-toggle="tooltip"
          data-placement="top"
          title="Refresh"
          onClick={() => {
            ClearFilter();
            // history.push("/PAG/ran/support/dashboard")
          }}
          style={{ padding: "6px" }}
        >
          <RefreshIcon />
        </button>
      </>

      <Button onClick={exportAllData} className="btn btn-primary btn-xs mx-2 py-2 my-3 d-flex justify-content-center align-items-center" >
        Export
      </Button>
      {/* <CommonBulkUploader styles={{ marginBottom: "1rem" }} typeForm={"Magenta Upload File"} shortCode={"magenta"} afterLoad={"/magenta" + dynamic_dashboard} /> */}
    </>
  )

  useEffect(() => {


    dispatch(networkGetLoggerReducerAction(networkDBLoggger));
    dispatch(
      networkGetCSVReducerAction("/networkDatabase" + dynamic_dashboard)
    );
  }, []);

  //    const data = [
  //     { name: "Joe James", company: "In Progress", city: "Yonkers", state: "NY" },
  //     { name: "John Walsh", company: "Completed", city: "Hartford", state: "CT" },
  //     { name: "Bob Herm", company: "Awaited", city: "Tampa", state: "FL" },
  //     { name: "James Houston", company: "NA", city: "Dallas", state: "TX" },
  //    ];

  return (
    <>

      <CommonModal
        modalSize={commonModalSize}
        setXlShow={setcommonModalState}
        xlShow={commonModalState}
        Body={commonModalBody}
        Header={commonModalHeader}
        Title={commonModalTitle}
      ></CommonModal>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {dynamicValue == "magenta" ? <h3>Magenta Upload</h3> : ""}
        <h3>{dynamicValue == "inflight" ? <h3>Inflight Upload</h3> : ""} </h3>
        <div>
          <Button
            className="btn-xs mt-3 mr-2 float-right mb-2"
            onClick={() => {
              filehandleShow();
              reset({});
              addformtype("Upload ");
            }}
          >
            Upload {dynamicValue} File
          </Button>
        </div>
      </div>
      <div className="mui-t-h"
        style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}
      >


        <ThemeProvider theme={getMuiThemeCommon()}>
          <MUIDataTable
            data={data.userArry}
            columns={columns}
            options={customDataTableOptionObject(AddCusTButton, false, "Network_Database_", "", "", false, "networKDatabase", "", data.userArry.length, data.totalCount, true)}
          // options={customDataTableOptionObject(AddCusTButton,true,"Magenta_","","",false,"magenta","",data.userArry.length,data.totalCount)}

          />
        </ThemeProvider>
      </div>
    </>
  );
};

export default NetworkDatabasebulkfile;
