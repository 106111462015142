import React, { Fragment } from 'react'

// import PageTItle from '../../../layouts/PageTitle'
import { SplitButton, ButtonGroup, Dropdown,Button } from 'react-bootstrap';
import DropdownMultiselect from 'react-multiselect-dropdown-bootstrap';


import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';



const ElementTwo = () => {

 

  return (
    <Fragment>
    {/* <PageTItle activeMenu='validation' motherMenu='Form' /> */}

    <div style={{ display: 'block', width: "100%", padding: 30 }}>
    {/* <h4>React-Bootstrap Tab Component</h4> */}
    
    <Tabs defaultActiveKey="first">
  <Tab eventKey="first"   title="Current" >


   
      <div className='row mt-3'>


        
     
     <Button className='ml'variant='light'  > <i className="fa fa-angle-double-left" > Back to Grid</i></Button>
      <Button className='ml-2' variant='info'>  <i className="fa fa-angle-double-right" > Submit Request</i></Button>
      <Button className='float-right' variant='info' style={{marginLeft:"600px"}}> <i className="fa fa-save" > Save </i></Button>

     
     

       <div className='card mt-3' style={{width:"100%"}}>
         
     

       <div className="accordion accordion-flush" id="accordionFlushExample">
  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingOneDetails">
      <Button
        className="accordion-button collapsed mr-2"
        type="button"
        variant='outline-primary btn-xxs'

        data-bs-toggle="collapse"
        data-bs-target="#flush-collapseOneDetails"
        aria-expanded="false"
        aria-controls="flush-collapseOneDetails"
      >
On-Demand Request Details      </Button>
    </h2>
    <div
      id="flush-collapseOneDetails"
      className="accordion-collapse collapse"
      aria-labelledby="flush-headingOneDetails"
      data-bs-parent="#accordionFlushExample"
    >
      <div className="accordion-body">
      <div className="form-row mt-2 p-2">
     
     <div className="form-group col-md-5">
     <label for="exampleFormControlSelect1"><i className="fa fa-info-circle"> On-Demand Request Module</i></label>
     <input type="text" className="form-control" id="inputAddress" placeholder="" />

     </div>
     <div className="form-group col-md-5">
     <label for="exampleFormControlSelect1"><i className="fa fa-info-circle"> On-Demand Request Technology</i></label>
     <input type="text" className="form-control" id="inputAddress" placeholder="" />

     </div>
     <div className="form-group col-md-12">
     <label for="exampleFormControlSelect1">On Demand Request OutPut </label>
     <input style={{height:"60px"}} type="text" className="form-control" id="inputAddress" placeholder="" />

     </div>
     <div className="form-group col-md-4">
     <label for="exampleFormControlSelect1">On Demand Request OutPut </label>
     <div>
     <Button  variant="light"><i className='fa fa-edit'> Add Notes</i></Button>

      </div>
     </div>
   
    
     
   </div>

      </div>
    </div>
  </div>
      </div>
    
   
     </div>
     <div className='card' style={{width:"100%"}}>
         
    

         <div className="accordion accordion-flush" id="accordionFlushExample">
  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingOnea">
      <Button
        className="accordion-button collapsed mr-2"
        type="button"
        variant='outline-primary btn-xxs'

        data-bs-toggle="collapse"
        data-bs-target="#flush-collapseOnea"
        aria-expanded="false"
        aria-controls="flush-collapseOnea"
      >
Action Request      </Button>
    </h2>
    <div
      id="flush-collapseOnea"
      className="accordion-collapse collapse"
      aria-labelledby="flush-headingOnea"
      data-bs-parent="#accordionFlushExample"
    >
      <div className="accordion-body">
      <div className="form-row mt-2 p-2">
           <div className="form-group col-md-4">
           <label for="exampleFormControlSelect1"><i className='fa fa-info-circle'> Request</i></label>
           <select className="form-control" id="exampleFormControlSelect1">
       <option>(Select)</option>
       <option>2</option>
       <option>3</option>
       <option>4</option>
       <option>5</option>
     </select>
           </div>
           <div className="form-group col-md-4">
           <label for="exampleFormControlSelect1">Technology</label>
           <input type="text" className="form-control" id="inputAddress" placeholder="" />

           </div>
           <div className="form-group col-md-4">
           <label for="exampleFormControlSelect1">Sector</label>
           <input type="text" className="form-control" id="inputAddress" placeholder="" />

           </div>
           <div className="form-group col-md-6">
           <label for="exampleFormControlSelect1">Notes-Additional Information</label>
           <input style={{height:"60px"}} type="text" className="form-control" id="inputAddress" placeholder="" />

           </div>
           <div className="form-group col-md-4">
           <label for="exampleFormControlSelect1">Phone Number used (for cbn request) </label>
           <input  type="text" className="form-control" id="inputAddress" placeholder="" />

           </div>
       
         </div>
        
      </div>
    </div>
  </div>
        </div>
        
    
       </div>
     
   

     <div className='card' style={{width:"100%"}}>
    
       <div className="accordion accordion-flush" id="accordionFlushExample">
  <div className="accordion-item">
    <h2 className="accordion-header" id="flush-headingOneEng">
      <Button
        className="accordion-button collapsed mr-2"
        type="button"
        variant='outline-primary btn-xxs'

        data-bs-toggle="collapse"
        data-bs-target="#flush-collapseOneEng"
        aria-expanded="false"
        aria-controls="flush-collapseOneEng"
      >
SOW Completion (Filed Eng)    

</Button>
    </h2>
    <div
      id="flush-collapseOneEng"
      className="accordion-collapse collapse"
      aria-labelledby="flush-headingOneEng"
      data-bs-parent="#accordionFlushExample"
    >
      <div className="accordion-body">
      <div className="form-row mt-2 p-2">
         <div className="form-group col-md-3">
         <label for="exampleFormControlSelect1">SOW Completed</label>
         <select className="form-control" id="exampleFormControlSelect1">
       <option>(Select)</option>
       <option>2</option>
       <option>3</option>
       <option>4</option>
       <option>5</option>
     </select>
         </div>
         <div className="form-group col-md-3">
         <label for="exampleFormControlSelect1">Reason For Incompleted SOW </label>
   <select className="form-control" id="exampleFormControlSelect1">
     <option>(Select)</option>
     <option>2</option>
     <option>3</option>
     <option>4</option>
     <option>5</option>
   </select>
         </div>
        
         <div className="form-group col-md-6">
         <label for="exampleFormControlSelect1">SAW Reason Incompleted</label>
         <textarea type="text" className="form-control" id="inputAddress" placeholder="" />

         </div>
         
        <div className="form-group col-md-4">
         <label for="exampleFormControlSelect1">Filed Tech/Engineer Notes For Check Out</label>
         
<div >
<Button  variant='light'><i className='fa fa-edit'>  Add Notes</i>  </Button>

</div>

       </div>
       </div>
       
      </div>
    </div>
  </div>
</div>  

   
     </div>
   
 </div>
 </Tab>
      

      <Tab eventKey="second" title="History">
     .........................
      </Tab>
      <Tab eventKey="third" title="Files">
  .................
      </Tab>
      <Tab eventKey="Fourth" title="Notes">
       ................
      </Tab>
     
    </Tabs>
  </div>

   
   
    

   

  </Fragment>
  )
}

export default ElementTwo
