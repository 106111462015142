import React, { useState, useEffect } from 'react';
import './DraggableModal.css';
import CalculateDowntime from '../Custom/Modal Body/CalculateDownTime';

const DraggableDiv = ({setShowHtml}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: window.innerWidth / 2, y: window.innerHeight / 2 });
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setPosition({ x: window.innerWidth / 2, y: window.innerHeight / 2 });
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleMouseDown = (e) => {
    if (e.target.classList.contains('draggable-header')) {
      setIsDragging(true);
      setStartPosition({ x: e.clientX, y: e.clientY });
    }
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const deltaX = e.clientX - startPosition.x;
      const deltaY = e.clientY - startPosition.y;
      setPosition({ x: position.x + deltaX, y: position.y + deltaY });
      setStartPosition({ x: e.clientX, y: e.clientY });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleClose = () => {
    setIsVisible(false);
    setShowHtml(false);
  };

  if (!isVisible) return null;

  return (
    <div
      className="parent"
      style={{ left: `${position.x}px`, top: `${position.y}px` }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    >
      <div className="draggable-header">
        <div className="header-text">Downtime Data</div>
        <button className="close-button" onClick={handleClose}>×</button>
      </div>
      <div className="draggable-content">
        <CalculateDowntime />
      </div>
    </div>
  );
};

export default DraggableDiv;
