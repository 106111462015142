import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal';
import MapView from "./Modal Body/MapView";
import { useHistory } from "react-router-dom";
import { Button, Tab, Tabs } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import { PDFUploadActionreturn } from "../../../store/actions/dashboardActions";
import { useDispatch, useSelector } from 'react-redux';
import { baseURL, CsvUpload, rttDashboard, zipUpploader } from "../../../common/config";
import { AccountReducerAction, popUpUpdate } from "../../../store/actions/createTicketManageFields";
import swal from "sweetalert";
import moment from "moment";
import Upload from "../../../icons/Svg/Upload";
const AdvCommonBulkUploader = ({ typeForm,shortCode,reutun,btnname="Upload File",classes="btn-xs mt-3 mr-2 mb-2 float-right",afterLoad="",uId,fileName,wildCard}) => {
  const history = useHistory()
  const dispatch = useDispatch();
  const [fileshow, setfileShow] = useState(false);
  const [closer, setCloser] = useState(false);
  const [siteFile, uploadedFile] = useState([]);
  const [selectDate,setSelectedDate] =useState("")
  const [getDat,setDat]=useState("")
  // const [id,setId]=useState(fileName)
  
  

  const filehandleClose = () => {
    setfileShow(false);
  }
  const filehandleShow = () => {
    setfileShow(true)
    dispatch(popUpUpdate({}))
    setfileShow(true)
  }

  let data = useSelector((state) => {
    
    if (state.popUpState) {
      console.log(state.popUpState.popstatus.status,fileshow,"popUpStatestatus")
      if ((state.popUpState.popstatus.status == 201 && fileshow)||(state.popUpState.popstatus.status == 200 && fileshow)) {
        setfileShow(false)
        filehandleClose()
        dispatch(popUpUpdate({}))
        dispatch(AccountReducerAction(rttDashboard))
      }
    }
  })

  const submitHandler = async (e) => {
    e.preventDefault();
    console.log("form==", "form")
    let newform = new FormData();
    if(siteFile!=[] && getDat!="" && getDat!=null){
    newform.append("uploadFile", siteFile);
    newform.append("uploadfor", shortCode)
    newform.append("rttCompleteDate", moment(getDat).format("MM/DD/YYYY"))
    console.log("form==", newform)
    // dispatch(PDFUploadActionreturn(zipUpploader+"/"+uId, newform))
    // setfileShow(false)
    if(wildCard){
      dispatch(PDFUploadActionreturn(zipUpploader+"/"+uId+"?wildCard=true", newform))
    }
    else{
      dispatch(PDFUploadActionreturn(zipUpploader+"/"+uId, newform))
    }
  }
    else{
      swal({
        title: "Oops!",
        text: "Please select file and date",
        icon: "warning",
        button: "Ok",
      })
    }
    // setfileShow(false);
  };

  const styles = {
    width: "400px",

  };
  const uploadCsvFile = (e) => {
    // let files=[]
    // console.log(e.target.files[0].name, "files")
    if(e.target.files){
    let fileExtension=e.target.files[0].name
    let extns=fileExtension.split(".").pop()
    if(extns!="zip"){
            swal({
                title: "Oops!",
                text: "Please upload only .zip file",
                icon: "error",
                button: "Ok",
              })
    }
    else{
      uploadedFile(e.target.files[0])
    }
  }
  };

  return (
    <>
      <Modal show={fileshow} onHide={filehandleClose} size="md">
        <Modal.Header closeButton>
          <Modal.Title >Upload {typeForm}</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body> */}
        <form onSubmit={(e) => submitHandler(e)} encType="multipart/form-data" className="mx-2 my-3">
          
          <div class="form-group col-md-12">
            
              <label class="custom-file-upload btn btn-outline-primary col-md-8">
                <input
                  type="file"
                  className="form-control btn btn-primary"
                  id="inputGroupFile04"
                  aria-describedby="inputGroupFileAddon04"
                  aria-label="Upload"
                  name="file"
                  multiple
                  onChange={uploadCsvFile}
                />
                <i class="fa fa-cloud-upload"></i> Choose File
              </label>
              
              <label>{siteFile.length!=0?"1 ":"No"} File Selected</label>
          
            </div>
            {/* <a href= {baseURL+"/uploads/"+fileName} download>Click to download</a> */}

            
            <div className="form-group">
              <label>RTT Completion Date</label>
              <DatePicker
                          style={styles}
                          className="form-control"
                          selected={getDat}
                          onChange={(e) => { setDat(e); }}
                          // showTimeSelect
                          popperPlacement="top"
                          // excludeOutOfBoundsTimes
                          // timeFormat="None"
                          // timeIntervals={60}
                          // timeCaption="Time"
                          dateFormat="MM/dd/yyyy"
                          // minDate={new Date()}
                          // maxDate={addDays(new Date(), 31)}
                        />
            </div>
            <button type="submit" className="btn btn-primary btn-xs mt-1">
              Submit
            </button>
          
        </form>

      </Modal> 
      
      <p onClick={() => { filehandleShow();}}><Upload /></p>
    </>
  );
};

export default AdvCommonBulkUploader;
