import React from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { SevenTwoAlarm } from '../../../../common/config'
import { addAcccountReducerAction, popUpUpdate } from '../../../../store/actions/createTicketManageFields'
import { useState } from 'react'
const AdDAudits = (props) => {
    const dispatch=useDispatch()
    const optionarray=["Yes","No"]
    const [getDat,setDat]=useState("")
    const {register,setValue,handleSubmit,setError,formState:{errors}}=useForm()
    if(getDat!=props.oldData.ranSupportId){
        setValue("vswrValidation",props.oldData.vswrValidation)
        setValue("rtwpValidation",props.oldData.rtwpValidation)
        setValue("retValidation",props.oldData.retValidation)
        setValue("perameterAudit",props.oldData.perameterAudit)
        setDat(props.oldData.ranSupportId)
    }
    
    
    const onSubmit=async (data)=>{
        let userDetails =JSON.parse(window.localStorage.getItem("userDetails"))
        // console.log("the response is =",props.id)
        console.log("the response is =",data)
       
        // if(props.addOrUpdate){
        //     let savedData=props.addOrUpdate
            let changesArray=[]
            let keysarray=Object.keys(data)
            // console.log("hvtyiwadcvyiefstf===",savedData)
            keysarray.map((item)=>{
                if(data[item]==""){
                    delete data[item]
                }
            })
            let audtDetail={
                "vswrValidation":"VSWR Validation",
                "rtwpValidation":"RTWP Validation",
                "retValidation":"RET Validation",
                "perameterAudit":"Perameter Audit"
              }
            let savedKey=Object.keys(data)
            savedKey.map((item)=>{
                // console.log("nkjhbhjkbvergv=",savedData[item][0],"===cldjkhvijckvkdfsvg==",data[item])
                // if(data[item] && savedData[item][0]!="" && savedData[item][0]!=data[item]){
                    let changes={
                        "userName":userDetails.name,
                        "ixID":props.id
                    }
                    changes["ActivityStatus"]=data[item]
                    changes["ActivityType"]=audtDetail[item]
                    changesArray.push(changes)
            //     }
            })
            data["logsData"]=changesArray
            dispatch(addAcccountReducerAction(SevenTwoAlarm+props.id,data))
            // props.setCloser(true)
            dispatch(popUpUpdate({}))
            props.setCloser(true)
        // }
        // else{
        // console.log("bjhbdc==",data)
        // data.auditAct=1
        // dispatch(addAcccountReducerAction(SevenTwoAlarm+props.id,data))
        // }
    } 

    let resp=optionarray.map((item)=>{
        return <option value={item}>{item}</option>
    })
    resp.unshift(<option  disabled selected value={""}>Select</option>)
  return (
    <form className='form-row' onSubmit={handleSubmit(onSubmit)}>
        <div className='form-group col-6'>
            <label>VSWR Validation</label>
            <select className='form-control' 
            {
                ...register("vswrValidation")
            
            }
            >
                {resp}
            </select>
        </div>
        <div className='form-group col-6'>
            <label>RTWP Validation</label>
            <select className='form-control'
            {
                ...register("rtwpValidation")
            
            }
            >
                {resp}
            </select>
        </div>
        <div className='form-group col-6'>
            <label>RET Validation</label>
            <select className='form-control'
            {
                ...register("retValidation")
            
            }
            >
                {resp}
            </select>
        </div>
        <div className='form-group col-6'>
            <label>Parameter Audit</label>
            <select className='form-control'
            {
                ...register("perameterAudit")
            
            }
            >
                {resp}
            </select>
        </div>
       
        <div className='col-12 align-content-center d-flex' >
         <button type="submit" className="badge badge-secondary">Submit</button>

       </div>
    </form>
  )
}

export default AdDAudits