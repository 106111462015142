import React from 'react'
import { useSelector } from 'react-redux'

const CommonTable = (props) => {
    let tempVar={}
    let data =useSelector((state)=>{
      let barData=state.OneCommonResponse.barData
      console.log("fkmebhfvekr",barData)
      if(barData && barData.XaxisLabel){
        tempVar=barData

       
      }
      return {tempVar}
    })

    const SingleDropDownAction=(data)=>{
        let tdData =Array.isArray(data)?data.map((item)=>{
            
            return <td style={{  color : "black" , fontSize : "13px" , border : "2px solid black"}}>{item}</td>
        })
        :[]
        return tdData
    }
    console.log(",mnviueasgfcoiuwlabefgouergv===",data)
  return (
   <>
        {console.log(",mnviueasgfcoiuwlabefgouergv===",data)}
        <table style={{ width:"100%" ,border  : "2px solid black", zIndex : 999 }}>
          <thead >{
            Array.isArray(data.tempVar[props.columnName])?data.tempVar[props.columnName].map((item)=>{
                return  <th scope="col" style={{ paddingLeft : "5px" , border  : "2px solid black" , fontSize : "13px" , color : "white", textAlign : "left" , fontWeight : 500 ,  background: "#143b64" , height : "30px"}}>{item}</th>
            }
            )
            :<th scope="col" style={{ paddingLeft : "5px" , border  : "2px solid black" , fontSize : "13px" , color : "white", textAlign : "left" , fontWeight : 500 ,  background: "#143b64" , height : "30px"}}>No Data</th>
          }
           
          </thead>
          <tbody>
          {
            Array.isArray(data.tempVar[props.rowData])?data.tempVar[props.rowData].map((row)=>{
                return  <tr>{SingleDropDownAction(row)}</tr>
            }
            )
            : <tr> <td style={{  color : "black" , fontSize : "13px" , border : "2px solid black"}}>No Record Found</td></tr>
          }


          </tbody>
        </table>

    </>
  )
}

export default CommonTable