import React, { Fragment, useState, useEffect } from "react";
import { SplitButton, ButtonGroup, Dropdown, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import { useForm } from "react-hook-form";
import { default as ReactSelect, components } from "react-select";
import { getValue } from "@mui/system";
import { Tab, Tabs } from 'react-bootstrap';
import { useHistory, useLocation } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import CommonCrew from "../../Forms/Ran Form/commoncrew";
import { commonzone, time_zonechanger } from "../../../../common/variables";
import { AccountReducerAction, addAcccountReducerAction, popUpUpdate } from "../../../../store/actions/createTicketManageFields";
import { managevendorscorecardApi } from "../../../../common/config";
const ManageVendorScoreCard = () => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({});

  const [closer, setCloser] = useState(false);
  const [one, setone] = useState(true);
  const dispatch = useDispatch();




  const onSubmit = async (data) => {
    console.log(data, "dataonsubmitmanageutilization")

    dispatch(addAcccountReducerAction(managevendorscorecardApi, data))

    setCloser(true)
    popUpUpdate({})


  }


  let data = useSelector((state) => {

    console.log("full state", state)
    let manageUtilization = state.CommonResponse.manageUtilization ? state.CommonResponse.manageUtilization[0] : {}
    if (state.popUpState) {
      console.log(state.popUpState.popstatus.status, closer, "popUpStatestatus")
      if ((state.popUpState.popstatus.status == 204 && closer) || (state.popUpState.popstatus.status == 201 && closer) || (state.popUpState.popstatus.status == 200 && closer)) {
        setCloser(false)
        dispatch(popUpUpdate({}))
        dispatch(AccountReducerAction(managevendorscorecardApi))
      }
    }

    return { manageUtilization }


  })

  console.log(data.manageUtilization, "datadatadata")

  if (data.manageUtilization && one) {
    setone(false)
    Object.keys(data.manageUtilization).forEach((key, value) => {

      console.log(key, value, data.manageUtilization, "key,value")
      setValue(key, data.manageUtilization[key])
    })

  }

  useEffect(() => {
    dispatch(AccountReducerAction(managevendorscorecardApi))
  }, []);

  return (
    <Fragment>
      {/* <PageTItle activeMenu='validation' motherMenu='Form' /> */}

      <div style={{ display: "block", width: "100%", marginTop: "-1.4rem" }}>
        {/* <h4>React-Bootstrap Tab Component</h4> */}

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row mt-3">
            <div className="card " style={{ width: "100%", marginLeft: "10px" }}>

              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div className="accordion-item">

                  <div>
                    <div className="accordion-body">
                      <div className="form-row mt-2 p-2">
                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4">Punctuality Rate </label>
                          <input
                            style={{ height: "32px" }}
                            type="number"
                            min={0}
                            max={100}
                            className="form-control"
                            id="inputEmail4"
                            name="siteid"
                            {...register("Performance")}
                          />
                        </div>


                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4">Site Spilover Rate</label>
                          <input
                            style={{ height: "32px" }}
                            type="number"
                            min={0}
                            max={100}
                            className="form-control"
                            id="inputEmail4"
                            name="siteid"
                            {...register("SiteGoingoutofApprovedMWRate")}
                          />
                        </div>


                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4">Trouble Ticket Rate</label>
                          <input
                            style={{ height: "32px" }}
                            type="number"
                            min={0}
                            max={100}
                            className="form-control"
                            id="inputEmail4"
                            name="siteid"
                            {...register("TroubleTicketRate")}
                          />
                        </div>


                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4">Punctuality Rate</label>
                          <input
                            style={{ height: "32px" }}
                            type="number"
                            min={0}
                            max={100}
                            className="form-control"
                            id="inputEmail4"
                            name="siteid"
                            {...register("PunctualityRate")}
                          />
                        </div>


                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4">Site Downtime Rate</label>
                          <input
                            style={{ height: "32px" }}
                            type="number"
                            min={0}
                            max={100}
                            className="form-control"
                            id="inputEmail4"
                            name="siteid"
                            {...register("72HRAlarmDashboard")}
                          />
                        </div>


                        <div className="form-group col-md-3">
                          <label htmlFor="inputEmail4">Total Percentage</label>
                          <input
                            style={{ height: "32px" }}
                            type="number"
                            min={0}
                            max={100}
                            value={
                              (+getValues("Performance")) + 
                              (+getValues("TroubleTicketRate")) +
                              (+getValues("SiteGoingoutofApprovedMWRate")) + 
                              (+getValues("PunctualityRate"))+
                              (+getValues("72HRAlarmDashboard"))  }
                            className="form-control"
                            id="inputEmail4"
                            name="siteid"
                            {...register("TotalPercentage")}
                          />
                        </div>


                      </div>
                    </div>
                  </div>


                  <div className="d-flex justify-content-center align-items-center mt-4">
                    <button type="submit" className="btn btn-primary btn-xs mx-2">
                      Submit
                    </button>
                  </div>
                  {/* <button onClick={() => raiseTT()} type="button" className="btn btn-primary btn-xs mx-2">
                    Raise Trouble Ticket
                  </button> */}
                </div >
              </div >
            </div >

            <div className="card " style={{ width: "100%", marginLeft: "10px" }}>

              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div className="accordion-item">

                  <div>

                  </div>


                  {/* <button onClick={() => raiseTT()} type="button" className="btn btn-primary btn-xs mx-2">
                    Raise Trouble Ticket
                  </button> */}
                </div >
              </div >
            </div >


          </div>
        </form >

      </div >
    </Fragment >
  );
};

export default ManageVendorScoreCard;
