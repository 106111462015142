import React, { useEffect, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import "../../../css/customStyle.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import DataTableExtensions from "react-data-table-component-extensions";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  NetworkDatabaseAction,
  CSVUploadAction,
} from "../../../store/actions/dashboardActions";
import { useHistory } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import {
  google_map_api,
  center,
  columnsArray,
  customDataTableOptionObject,
  getMuiThemeCommon,
  dbdatetomoment,
  advancdcustomDataTableOptionObject,
  smart_more_info,
  rancustomDataTableOptionObject,
} from "../../../common/variables";
import moment from "moment";
import { AccountReducerAction, ExportXLSXDownloadReducerAction, LCLranSlaReducerAction, LCLslaDashboardfilter, ResetslaDashboardReducerAction, getFilterDropDown } from "../../../store/actions/createTicketManageFields";
import { commonfilters, export_ran_tracker, export_sla_ran_report, ranDashboard, ranSla, ranTracker } from "../../../common/config";
import CommonModal from "../Custom/CommonModal";
import DatePicker from "react-datepicker";
import swal from "sweetalert";
import SLAReportFilter from "../Custom/Modal Body/SLAReportFilter";
import FilterData from "../../../icons/Svg/FilterData";
const RanSlaReport = () => {
  const [fstartdate, setfstartdate] = useState();
  const [fenddate, setfenddate] = useState();
  const [OneTimere, setOneTimere] = useState(false);
  const [accordianState, setAccordianState] = useState(false);
  const [siteFile, uploadedFile] = useState();
  const [remarks, setRemarks] = useState();
  const [markersdatacount, setmarkerscount] = useState(1);
  const [markersdata, setmarkersdata] = useState([]);
  const [selectedCenter, setSelectedCenter] = useState(null);
  const history = useHistory();
  const [ResetFilter, setResetFilter] = useState(false);
  
  const [commonModalState, setcommonModalState] = useState(false);
  const [commonModalSize, setcommonModalSize] = useState(false);
  const [commonModalBody, setcommonModalBody] = useState("");
  const [commonModalTitle, setcommonModalTitle] = useState("");
  const [commonModalHeader, setcommonModalHeader] = useState("");
  const [filterBadges, setFilterBadge] = useState()
  const [fullUrL, setfullUrL] = useState("");
  const [commonModalBodyFilter, setcommonModalBodyFilter] = useState("");
  const [commonModalStateFilter, setcommonModalStateFilter] = useState(false);
  const [commonModalHeaderFilter, setcommonModalHeaderFilter] = useState("");
  const [commonModalSizeFilter, setcommonModalSizeFilter] = useState(false);
  const [commonModalTitleFilter, setcommonModalTitleFilter] = useState("");


  
  const [exportApiData, setexportApiData] = useState("");


  const sortedOrder={
    // name: 'scheduleDate',
    // direction: 'desc'
  }


  
  let columns = [
    
    
    {
      label: "Schedule Date",
      name: "scheduleDate",
      sortable: true,
      options: {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 101
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      },
    },
    {
      label: "Week #",
      name: "scheduleWeek",
      options: {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            position: "sticky",
            zIndex: 101,
            minWidth: "68px",
            left: "138px",
            color: "white",
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            outline:"1.5px solid black",
            overflowY: "hidden",
            minWidth: "68px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
            left: "138px",
          },
        }),
      },
    },
    
    // {
    //   "name": "scheduleDate",
    //   "label": "Schedule Date",
    //   "sortable": false,
    //   options: {
    //     filter: true,

    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "nowrap",
    //         alignItem: "center",
    //         padding: "1px 1px",

    //         background: "#143b64",
    //         color: "white",
    //       },
    //     }),
    //     setCellProps: () => ({
    //       style: {
    //         whiteSpace: "nowrap",

    //         overflowY: "hidden",

    //         padding: "1px 1px",

    //         background: "White",
    //       },
    //     }),
    //   }
    // },
    // {
    //   "name": "scheduleWeek",
    //   "label": "Week #",
    //   "sortable": false,
    //   options: {
    //     filter: true,

    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",
    //         // minWidth:"150px",
    //         background: "#143b64",
    //         color: "white"

    //       }
    //     })
    //   }
    // },

    
    {
      label: "Site ID",
      name: "siteId",
      options: {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            position: "sticky",
            zIndex: 101,
            minWidth: "68px",
            left: "208px",
            color: "white",
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            outline:"1.5px solid black",
            overflowY: "hidden",
            minWidth: "68px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
            left: "208px",
          },
        }),
      },
    },
    {
      name: "preCheckEngineer",
      label: "Precheck Engineer",
      sortable: false,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            background: "#143b64",
            color: "white",
            padding: "1px 1px",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",

            overflowY: "hidden",
            
            padding: "1px 1px",

            background: "White",
          },
        }),
      },
    },
    {
      name: "sow",
      label: "Sow",
      sortable: false,
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            background: "#143b64",
            padding: "1px 1px",
            "min-width" : "260px",
            "max-width" : "300px",
            color: "white",
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
          

            overflowY: "hidden",

            padding: "1px 1px",

            background: "White",
          },
        }),
      },
    },
    {
      name: "crewEmail",
      label: "Crew Email id",
      sortable: false,
      options: {
        filter: true,
        
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            minWidth: "180px",
            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "preCheckRequest",
      label: "Login Tiime Precheck Requested",
      sortable: false,
      options: {
        filter: true,
        
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "max-width": "180px",
            "min-width": "160px",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "preCheckDelivered",
      label: "Login Time Precheck delivered",
      sortable: false,
      options: {
        filter: true,
        
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "max-width": "180px",
            "min-width": "160px",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "preSlaMetBadge",
      label: "Precheck Sla Met",
      sortable: false,
      options: {
        filter: true,
        print:false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "min-width": "110px",
            background: "#143b64",
            color: "white",
          },
        }),
      },
    }, {
      name: "preSlaMetView",
      label: "Precheck Sla Met",
      sortable: false,
      options: {
        filter: true,
        display:false,
      },
    },
    {
      name: "Pre_sla_remarks_modal",
      label: "Pre Remark",
      sortable: false,
      options: {
        print:false,
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            background: "#143b64",
            color: "white",
            "min-width":"250px",
            "max-width":"250px"
          },
        }),
      },
    },
    
    {
      name: "Pre_sla_remarks",
      label: "Pre Remark",
      customBodyRender: (item) => console.log("from customBodyRender =", item),
      sortable: true,
      options: {
        filter: true,
        display:false
      }
    },
    {
      name: "postCheckEngineer",
      label: "Postcheck Engineer",
      sortable: false,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "postCheckRequest",
      label: "Postcheck Requested",
      sortable: false,
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            "max-width": "150px",
            "min-width": "140px",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "postCheckDelivered",
      label: "Logout Time Postcheck Delivered",
      sortable: false,
      options: {
        filter: true,
        
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "max-width": "190px",
            "min-width": "170px",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "postSlaMetView",
      label: "Post Check Sla Met",
      sortable: false,
      
      options: {
        filter: true,
        display:false,
        // customBodyRender: (item) => <p className="badge badge-primary">{item}</p>,
      },
    },
    {
      name: "postSlaMetBadge",
      label: "Post Check Sla Met",
      sortable: false,
     
      options: {
        filter: true,
        print:false,
        // customBodyRender: (item) => <p className="badge badge-primary">{item}</p>,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "max-width": "130px",
            "min-width": "120px",
            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "Post_sla_remarks_modal",
      label: "Post Remark",
      sortable: false,
      options: {
        filter: true,
        print: false,
        setCellHeaderProps: () => ({
          style: {
            background: "#143b64",
            "min-width" : "250px",
            "max-width" : "250px",
            color: "white",
          },
        }),
      },
    },
    {
      name: "Post_sla_remarks",
      label: "Post Remark",
      customBodyRender: (item) => console.log("from customBodyRender =", item),
      sortable: true,
      options: {
        filter: true,
        display:false
      }
    },
  ];
  let rowData = [];
  let rowDataOne = [];
  let mark = [];
  let row = [];
  let datamark = [];

  function createmarker(mark) {
    console.log(datamark, "createmarkerdatamark");

    setmarkersdata(datamark);

    console.log(...markersdata, "markersdata");
  }
  const count = useSelector((state) => {

    
    let totalCount=state.countState.ranSlaCount
    console.log("the state is=", state);
    let sitesdata = state.sites.sites.tableData;
    if (sitesdata) {
      if (markersdatacount == 1) {
        // setmarkersdata(sitesdata)
        console.log(sitesdata, "sitesdata");
        createmarker(sitesdata);
        sitesdata.map((marker) => {
          if (marker.latitude != "" && marker.longitute != "") {
            datamark.push({
              latitude: parseFloat(marker.latitude),
              longitute: parseFloat(marker.longitute),
              id: marker.latitude,
            });
          }
        });

        console.log(datamark, "datamark");
        setmarkerscount(0);
      }
      // sitesdata.map(item=>{
      //   if(item.latitude!='' && item.longitute!=''){
      //     if(markersdatacount==1){
      //       createmarker(mark)
      //       setmarkerscount(0)
      //     }

      //   }
      // })
    }
    let randata = state.CommonResponse.ranDashboard;
    if (randata && randata.length != 0) {
      rowDataOne = randata[0];
      Object.keys(rowDataOne).map((key, index) => {
        console.log(key, "key");
        if (key != "projectStatusDescr") {
          // columns.push(
          //   {
          //     name: key,
          //     selector: key,
          //     sortable: true
          //     }
          //   )
        }
      });
    }
    console.log(mark, "mark");
    console.log(state.CommonResponse.ranSlaTracker,"state.CommonResponse.ranSlaTracker")
    const CommonModalFun = (body, title, header, type = "md") => {
      setcommonModalState(true);
      setcommonModalSize(type);
      setcommonModalTitle(title);
      setcommonModalBody(body);
      setcommonModalHeader(header);
    };
  
    // if (Object.keys(state.sites.sites).length > 0) {
    if (
      state.CommonResponse.ranSlaTracker &&
      state.CommonResponse.ranSlaTracker.length > 0
    ) {
      rowData = state.CommonResponse.ranSlaTracker.map((item, index) => {
        item["action"] = (
          <>
            <button
              className="btn btn-success shadow btn-xs sharp mr-1"
              data-toggle="modal"
              data-target="#exampleModal"
            >
              <i className="fa fa-eye"></i>
            </button>
            <button className="btn btn-primary shadow btn-xs sharp mr-1">
              <i className="fa fa-pencil"></i>
            </button>
            <button className="btn btn-danger shadow btn-xs sharp">
              <i className="fa fa-trash"></i>
            </button>
          </>
        );
        item["5GId"] = "";
        item["LTEId"] = "";
        item["UMTSId"] = "";
        item["mixedModeId"] = "";
        item["siteId"] = item.ndResult.siteId;

        
        const myMomentObject = dbdatetomoment(item["scheduleDate"])

        item["scheduleWeek"] = myMomentObject.isoWeekday(1).week()
        console.log("item is =", item["technology"]);
        // item['technology']=''
        let tec = "";
        if (item.cxCrewId.email != undefined) {
          item["crewEmail"] = item.cxCrewId.email;
        }
        if (item.ixCrewId.email != undefined) {
          item["crewEmail"] = item.ixCrewId.email;
        }
        let techArray = item["technology"];
        if (techArray && techArray.length != 0) {
          //  item['technology'].forEach((item)=>{
          //   tec +=item.label +','
          //  })
          console.log("sgrthdhr", techArray.length);
          for (let i = 0; i < techArray.length; i++) {
            console.log("sgrthdhr", techArray[i].label);
            tec += techArray[i].label + ",";
          }
          //  item['technology']=tec
        }
        console.log("item is tec =", tec);

        //  console.log("item is =",item['technology'])
        // item['technology']=tec
        item["temp"] = tec;
        if (item["sowDetails"]?.values && item["sowDetails"]?.values.length > 0) {
          item["sow"] = item["sowDetails"]?.values.join(", ");
        }
        // item["preSlaMetBadge"]=<p className={item['preSlaMet']=="SLA Met"?"badge badge-success":"badge badge-danger"}>{item['preSlaMet']=="SLA Met"?"Yes":"No"}</p>
        // item["postSlaMetBadge"]=<p className={item['postSlaMet']=="SLA Met"?"badge badge-success":"badge badge-danger"}>{item['postSlaMet']=="SLA Met"?"Yes":"No"}</p>
        
        item["preSlaMetBadge"] = <p className={item['preSlaMet'] ? item['preSlaMet'] == "SLA Met" ? "badge badge-success" : "badge badge-danger" : ""} >{item['preSlaMet'] ? item['preSlaMet'] == "SLA Met" ? "Yes" : "No" : ""}</p>
        item["postSlaMetBadge"] = <p className={item['postSlaMet'] ? item['postSlaMet'] == "SLA Met" ? "badge badge-success" : "badge badge-danger" : ""}>{item['postSlaMet'] ? item['postSlaMet'] == "SLA Met" ? "Yes" : "No" : ""}</p>
        
        item["preSlaMetView"]=item['preSlaMet']=="SLA Met"?"Yes":"No"
        item["Pre_sla_remarks_modal"]=smart_more_info(item["Pre_sla_remarks"],"","Pre SLA Remarks",CommonModalFun)
        item["Post_sla_remarks_modal"]=smart_more_info(item["Post_sla_remarks"],"","Post SLA Remarks",CommonModalFun)
        item["postSlaMetView"]=item['postSlaMet']=="SLA Met"?"Yes":"No"
        return item;
      });
      console.log("jdbcjhbsdaikc", state);
    }
    return { rowData,totalCount, columns, mark };
  });

  const options = {
    download: false,
    viewColumns: false,
    rowsPerPage: 100,
    count: 100,
    rowsPerPageOptions: [15, 30, 45 , 60],
    fixedHeader: true,
    fixedSelectColumn: true,
    tableBodyMaxHeight: "600px",
    print: false,

    customToolbar: null,
    searchPlaceholder: "Search by Site ID",
    onFilterChange: (changedColumn, filterList) => {
      console.log("inside filter=", changedColumn, filterList);
    },
    selectableRows: false,
    responsive: "scroll",
    overflowY: "scroll",

    // searchOpen: true,
    // disable search icon
    // search: false,

    // onDownload: (buildHead, buildBody, columns, data) => {
    //   buildHead = () => {
    //      return ["col-1","col-2"]
    //   }
    //   buildBody = () => {
    //    return [["A", "B"],["C", "D"]];
    //   }
    //   return "\uFEFF" + buildHead() + buildBody();
    // },
    // rowsSelected: selectedRows,
    // rowSelectionKey: "id",
    // onRowSelectionChange: (
    //      currentRowsSelected,
    //      allRowsSelected,
    //      rowsSelected,  // this is the array of `rowSelectionKey` values
    //    ) => {
    //       setSelectedRows(allRowsSelected)
    //    },
    // onTableChange: (action, state) => {
    //   console.log(action);
    //   console.dir(state);
    // },
    // customToolbarSelect: selectedRows => (
    //   <Tooltip title="edit">
    //     <button
    //       onClick={() => {
    //         console.log("the selected rows =",selectedRows)
    //         // console.log(rows[selectedRows.data[0].dataIndex]);
    //       }}
    //       style={{
    //         marginRight: "24px",
    //         height: "48px",
    //         top: "50%",
    //         display: "block",
    //         position: "relative",
    //         transform: "translateY(-50%)"
    //       }}
    //     >Click Me
    //       {/* <EditIcon /> */}
    //     </button>
    //   </Tooltip>
    // ),

    filterType: "dropdown",
    responsive: "scrollfullHeight",

    rowsPerPage: 15,

    // expandableRows: false,
    // expandableRows:true,
    // renderExpandableRow:(data,rowMeta)=>{
    //   console.log(" ",data,"  rowMeta =",rowData[rowMeta.rowIndex])
    //   return (
    //     <React.Fragment>
    //         <div>
    //           <ul className='row mx-2 '>
    //             <li className='col-md-5'><span>Market :</span><span>{rowData[rowMeta.rowIndex].market}</span></li>
    //             <li className='col-md-5'><span>Account :</span><span>{rowData[rowMeta.rowIndex].account}</span></li>
    //             <li className='col-md-5'><span>Group :</span><span>{rowData[rowMeta.rowIndex].group}</span></li>
    //             <li className='col-md-5'><span>Account :</span><span>{rowData[rowMeta.rowIndex].account}</span></li>
    //             <li className='col-md-5'><span>Company :</span><span>{rowData[rowMeta.rowIndex].company}</span></li>
    //             <li className='col-md-5'><span>Owner :</span><span>{rowData[rowMeta.rowIndex].owner}</span></li>
    //             <li className='col-md-5'><span>Owner-Email :</span><span>{rowData[rowMeta.rowIndex].email}</span></li>
    //             <li className='col-md-5'><span>Owner Contact :</span><span>{rowData[rowMeta.rowIndex].OwnerContact}</span></li>
    //             <li className='col-md-5'><span>Type Of Work(SI/NSI) :</span><span>{rowData[rowMeta.rowIndex].typeOfWork}</span></li>
    //             <li className='col-md-5'><span>Sector by sector Outage :</span><span>{rowData[rowMeta.rowIndex].sectorByOutage}</span></li>
    //             <li className='col-md-5'><span>Crane Needed :</span><span>{rowData[rowMeta.rowIndex].CraneNeeded}</span></li>
    //             <li className='col-md-5'><span>CR NO :</span><span>{rowData[rowMeta.rowIndex].crNumber}</span></li>
    //             <li className='col-md-5'><span>Justification Comments :</span><span>{rowData[rowMeta.rowIndex].justificationComment}</span></li>
    //             <li className='col-md-5'><span>Start Date Time :</span><span>{rowData[rowMeta.rowIndex].startTime}</span></li>
    //             <li className='col-md-5'><span>End Date Time :</span><span>{rowData[rowMeta.rowIndex].endTime}</span></li>
    //             <li className='col-md-5'><span>Down Time (H) :</span><span>{rowData[rowMeta.rowIndex].DownTime}</span></li>
    //             <li className='col-md-5'><span>Sectors Impacted :</span><span>{rowData[rowMeta.rowIndex].sectorsImpacted ?
    //               rowData[rowMeta.rowIndex].sectorsImpacted?.values.map((item)=>{
    //                 return item+','
    //               })
    //               :''
    //           }</span></li>
    //             < li className='col-md-5'><span>SoW :</span><span>{rowData[rowMeta.rowIndex].sowDetails ?
    //               rowData[rowMeta.rowIndex].sowDetails?.values.map((item)=>{
    //                 return item+','
    //               })
    //               :''
    //           }</span></li>
    //             <li className='col-md-5'><span>Technology :</span><span>{rowData[rowMeta.rowIndex].technology?.values.length?
    //               rowData[rowMeta.rowIndex].technology?.values.map((item)=>{
    //                 return item+','
    //               })
    //               :''
    //           }</span></li>
    //             <li className='col-md-5'><span>SoW  Details :</span><span>{rowData[rowMeta.rowIndex].sowDetail}</span></li>
    //             <li className='col-md-5'><span>MOP :</span><span>{rowData[rowMeta.rowIndex].mop}</span></li>
    //             {/* <li><span>Account :</span><span>{rowData[rowMeta.rowIndex].account}</span></li> */}
    //           </ul>
    //         </div>

    //     </React.Fragment>
    //   )
    // }
  };

  const mapStyles = {
    width: "100%",
    height: "100%",
  };

  const dispatch = useDispatch();
  function accordianHandler() {
    setAccordianState(!accordianState);
  }

  createTheme("solarized", {
    text: {
      primary: "inherit",
      secondary: "inherit",
    },
    background: {
      default: "inherit",
    },
    context: {
      background: "inherit",
      text: "inherit",
    },
    divider: {
      default: "inherit",
    },
    button: {
      default: "inherit",
      hover: "rgba(0,0,0,.08)",
      focus: "rgba(255,255,255,.12)",
      disabled: "rgba(255, 255, 255, .34)",
    },
    sortFocus: {
      default: "inherit",
    },
  });

  let getMuiTheme = () =>
    createTheme({
      components: {
        MuiButtonBase: {
          styleOverrides: {
            root: {
              "line-height": "1 !important",
              padding: "6px 0px",
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              maxHeight: 45,
              fontSize: 13,
              overflowY: "auto",
              padding: "0px 1px",
              width: "fit-content",
              margin: "auto",

              // maxHeight: 45,
              //   fontSize: 10,
              //   overflowY: "hidden",
              //   overflowX: "auto",
              //   padding: "3px",
              //   margin: "auto",
              //   textAlign: "center",
              //   width: "max-content",
            },
          },
        },
        // MuiToolbar: {
        //   styleOverrides: {
        //     root: {
        //       backgroundColor: '#f00',

        //     },
        //   },
        // },
        MuiTableCell: {
          styleOverrides: {
            head: {
              maxHeight: 20,
              fontSize: 15,
              margin: 0,
              paddingTop: 0,
              paddingBottom: 0,
            },
          },
        },
        MUIDataTableSelectCell: {
          styleOverrides: {
            headerCell: {
              backgroundColor: "blue",
              padding: 0,
            },
          },
        },
        MuiTableFooter: {
          styleOverrides: {
            root: {
              "& .MuiToolbar-root": {
                backgroundColor: "white",
              },
            },
          },
        },
      },
    });
  console.log("yhe row data isn=", rowData);
  //on submiting the excel
  const submitHandler = async (e) => {
    e.preventDefault();
    console.log("remarks =", remarks);
    console.log("file=", siteFile);
    console.log(columns);
    const form = new FormData();
    form.append("uploadFile", siteFile);
    form.append("uploadfor", "Network_Database");
    // form.append("remarks",remarks);
    setRemarks("");
    // uploadedFile()
    dispatch(CSVUploadAction(form));
    console.log("form data =", form);
  };
  const uploadCsvFile = (e) => {
    console.log(e.target.files[0]);
    uploadedFile(e.target.files[0]);
  };

  const containerStyle = {
    width: "400px",
    height: "400px",
    position: "initial",
  };

  const AnyReactComponent = ({ text }) => <div>{text}</div>;

  const handleApiLoaded = (map, maps) => {
    // use map and maps objects
  };


  
  const ClearFilter = () => {
    
    console.log("ClearFilter", "ClearFilter");
    setfstartdate();
    setfenddate();

    setOneTimere(true)

    setResetFilter(true)

    let Filleddata=dispatch(ResetslaDashboardReducerAction())
    // dispatch(AccountReducerAction(LSSVDashboardAPI + Filleddata,true));
    dispatch(AccountReducerAction(ranSla + Filleddata, true));
        
    // dispatch(AccountReducerAction(ranTracker,true,true));
  };


  const exportAllData = () => {

    
    dispatch(ExportXLSXDownloadReducerAction(export_sla_ran_report,"RANSLAREPORT_" + moment(new Date()).format("MM-DD-YYYY"),exportApiData))
    // FileSaver.saveAs(baseURL+"/export/ran", "RAN_Support_" + moment(new Date()).format("MM-DD-YYYY"))
    // history.push(baseURL+"/export/ran")
  }

  
  const setDateFilter = (e) => {
    e.preventDefault();
    // dateRange[0].startDate = moment(fstartdate).format("mm/dd/yyyy");
    // dateRange[0].endDate = moment(fenddate).format("mm/dd/yyyy");
    // console.log("the filtered data=", dateRange);
    let currentUrl = window.location.href;
    let urlArray = currentUrl.split("?");

    // if (currentUrl.includes("?")) {
    //   dispatch(
    //     AccountReducerAction(
    //       ranDashboard +
    //         "?" +
    //         urlArray.pop() +
    //         "&start=" +
    //         dateRange[0].startDate +
    //         "&end=" +
    //         dateRange[0].endDate
    //     )
    //   );
    // } else {

    if (fstartdate == undefined && fenddate == undefined) {
      swal("Oops", "Please select Start & End Date", "info");
      return;
    }
    if (fstartdate == undefined) {
      swal("Oops", "Please select Start Date", "info");
      return;
    }

    if (fenddate == undefined) {
      swal("Oops", "Please select End Date", "info");
      return;
    }

    if (fenddate < fstartdate) {
      
      swal("Oops", "End Date Should be Greater than Start Date", "info");
      return;


    } else {  
      setResetFilter(true)
      setexportApiData(ranSla + "?start=" + moment(fstartdate).format("MM/DD/yyyy") + "&end=" + moment(fenddate).format("MM/DD/yyyy"))
    
      let Filleddata=dispatch(LCLranSlaReducerAction({dateUrl:`start=${moment(fstartdate).format("yyyy-MM-DD")}&end=${moment(fenddate).format("yyyy-MM-DD")}`}))
      console.log(Filleddata,"FilleddataFilleddata")
      dispatch(AccountReducerAction(ranSla + Filleddata,true));
    
      // dispatch(
      //   AccountReducerAction(
      //     ranSla +
      //     "?start=" +
      //     moment(fstartdate).format("yyyy-MM-DD") +
      //     "&end=" +
      //     moment(fenddate).format("yyyy-MM-DD")
      //   )
      // );
    }
    // }
  };

  function filterhandler() {
    dispatch(getFilterDropDown([]))
    dispatch(AccountReducerAction(commonfilters))
    // dispatch(AccountReducerAction(ranfilters))
    setcommonModalStateFilter(true)
    setcommonModalSizeFilter("xl")
    setcommonModalBodyFilter(<SLAReportFilter setfullUrL={setfullUrL} setcommonModalState={setcommonModalStateFilter} setFilterBadge={setFilterBadge} featureExport={setexportApiData} />)
    setcommonModalTitleFilter("SLA Report Filters")
    setcommonModalHeaderFilter("SLA Report Filter")
  }

  const AddCustomButton = () => (
    <fregment
      style={{
        float: "right",
        display: "flex",
        flexDirection: row,
        justifyContent: "space-between",
      }}
    >
      <p onClick={filterhandler} className=" mx-2 " style={{ "align-self": "center" }}><FilterData /></p>

      <>
        <button
          className="btn btn-xs btn-primary mx-2 my-3"
          data-toggle="tooltip"
          data-placement="top"
          title="Refresh"
          onClick={() => {
            ClearFilter();
            // history.push("/PAG/ran/support/dashboard")
          }}
          style={{ padding: "6px" }}
        >
          <RefreshIcon />
        </button>
      </>


      <div
        style={{
          width: "46vh",
          display: "flex",
          alignItems: "center",

          margin: "18px 10px auto 10px"
        }}
      >
        <DatePicker
          placeholderText="MM/DD/YYYY"
          id="color-of-datepicker"
          className="date-picker-changes "
          selected={fstartdate}
          autoComplete={"off"}
          onChange={(e) => {
            setfstartdate(e);
            // setfenddate(e);
          }}
          dateFormat="MM/dd/yyyy"
        />
        <DatePicker
          placeholderText="MM/DD/YYYY"
          className="date-picker-changes mx-1"
          id="color-of-datepicker"
          selected={fenddate}
          autoComplete={"off"}
          onChange={(e) => {
            setfenddate(e);
          }}
          minDate={fstartdate}
          dateFormat="MM/dd/yyyy"
        />
      </div>
      <button
        variant="float-right"
        className="btn btn-primary btn-xs mx-2 py-2 my-3 float-right d-flex justify-content-center align-items-center"
        // onClick={() => setDatePicker(!showDatePicker)}
        onClick={(e) => setDateFilter(e)}
      >
        <i class="fa fa-filter mx-2"></i>Filter
      </button>

      {/* {userDetails.rolename !== "GC Login"
        // && (
        // <button
        //   className="btn btn-primary btn-xs mx-2 py-2 my-3 d-flex justify-content-center align-items-center"
        //   data-toggle="modal"
        //   data-target="#exampleModall"
        //   onClick={() => setMapModal(true)}
        // >
        //   Map View
        // </button>
        // )
      } */}
      
      <Button onClick={exportAllData} className="btn btn-primary btn-xs mx-2 py-2 my-3 d-flex justify-content-center align-items-center">
        Export
      </Button>

      {/* <ExportToExcel
        apiData={excelData}
        fileName={"RanDashboard_Tracker" + moment(new Date()).format("MMDDYYYY")}
      /> */}
    </fregment>
  );

  useEffect(() => {
    dispatch(AccountReducerAction(ranSla));

    if (!datamark) {
      setmarkersdata(datamark);
    }
    console.log(markersdata, "markersdata");
  }, [markersdata]);
  return (
    <>
      <div className="row sla-report-change">
        <div className="basic-dropdown px-3 my-3 pb-0"></div>

        <div className="basic-dropdown px-3 my-3 pb-0"></div>

        <div
          className={
            accordianState
              ? "col-12 card px-3 pt-3 pb-0 showAccordian"
              : "col-12 card px-3 pt-3 pb-0 hideAccordian"
          }
          style={{ display: accordianState ? "block" : "none" }}
        >
          <form
            onSubmit={submitHandler}
            encType="multipart/form-data"
            className="mx-2"
          >
            <div className="input-group mb-3 fileSection">
              <div>
                <label class="custom-file-upload btn btn-outline-primary col-md-12">
                  <input
                    type="file"
                    className="form-control btn btn-primary"
                    id="inputGroupFile04"
                    aria-describedby="inputGroupFileAddon04"
                    aria-label="Upload"
                    name="file"
                    onChange={uploadCsvFile}
                  />
                  <i class="fa fa-cloud-upload"></i> Choose File
                </label>
                {/* <label>Remarks</label>
              <input type='text' className="form-control" value={remarks} onChange={(e)=>{setRemarks(e.target.value)}}></input> */}
              </div>``

              <button
                className="btn btn-outline-primary col-xm-12"
                type="submit"
                id="inputGroupFileAddon04"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
        <div className="mui-t-h"
          style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}
        >

          <ThemeProvider theme={getMuiThemeCommon()}>
            <MUIDataTable
              title={""}
              data={rowData}
              columns={columns}
              // options={advancdcustomDataTableOptionObject(AddCustomButton, true,"RAN_SLA_REPORT_","","",sortedOrder)}
              options={rancustomDataTableOptionObject(AddCustomButton,false,"RanSlaReport", "","",sortedOrder,AccountReducerAction,ranSla,history.location.search,rowData.length,count.totalCount,[fstartdate,fenddate],OneTimere,setOneTimere,setResetFilter,ResetFilter)}
            />
          </ThemeProvider>
          {/* <DataTableExtensions 
						
						columns={columns}
						data={rowData}
						exportHeaders={true}
						
						>
						<DataTable
              columns={count.columns}
              data={count.rowData}
							theme='solarized'
							defaultSortAsc={false}
							pagination
							highlightOnHover
						/>
						</DataTableExtensions> */}
        </div>
      </div>


      <CommonModal
        modalSize={commonModalSizeFilter}
        setXlShow={setcommonModalStateFilter}
        xlShow={commonModalStateFilter}
        Body={commonModalBodyFilter}
        Header={commonModalHeaderFilter}
        Title={commonModalTitleFilter}
      ></CommonModal>

      
      <CommonModal
        modalSize={commonModalSize}
        setXlShow={setcommonModalState}
        xlShow={commonModalState}
        Body={commonModalBody}
        Header={commonModalHeader}
        Title={commonModalTitle}
      ></CommonModal>
    </>
  );
};

export default RanSlaReport;
