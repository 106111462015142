import React, { useRef,useState } from 'react'
import MUIDataTable from "mui-datatables";
import { useEffect } from 'react';
import { Row, Card, Col,Dropdown, Button, Modal, Container } from "react-bootstrap";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { vendorApi, addVendor, deleteVendor, MOPInstructionApi } from '../../../../common/config';
import { AccountReducerAction,OneAccountReducerAction, addAcccountReducerAction,deleteAcccountReducerAction, popUpUpdate, updateAcccountReducerAction } from '../../../../store/actions/createTicketManageFields';
import { useDispatch,useSelector } from 'react-redux';
import { google_map_api, center, dbdatetodate, customDataTableOptionObject, advancdcustomDataTableOptionObject, advancdExportOnecustomDataTableOptionObject, getMuiThemeCommon } from "../../../../common/variables";
import CommonBulkUploader from '../../Custom/CommonBulkUploader';
import swal from 'sweetalert';
const ManageMOPInstruction = () => {
    const svg1 = (
      <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect x="0" y="0" width="24" height="24"></rect>
          <circle fill="#000000" cx="5" cy="12" r="2"></circle>
          <circle fill="#000000" cx="12" cy="12" r="2"></circle>
          <circle fill="#000000" cx="19" cy="12" r="2"></circle>
        </g>
      </svg>
    );

   let objectId=''
   const [modalCentered, setModalCentered] = useState(false);
   const [closer, setCloser] = useState(false);
   const [dValue,setDvalue] =useState('')
   const [rowId,setRowId]=useState('')
   const [errors, setErrors] = useState({});
   const [checkAddUpdate,setAddUpdate] =useState(true)
  //  const [newAccount,setAccount]=useState('')
      const newVendor=useRef()
   let oneaccount=""
   
   
   // setAccount(oneaccount)
   console.log(oneaccount,"oneaccount")
    const getMuiTheme = () => createTheme({
      components: {
        MUIDataTableBodyCell: {
          styleOverrides:{
            root: {
                backgroundColor: "transparent"
            }
          }
        }
      }
    })
    const updateHandler =(e)=>{
        console.log("button update clicked",e)

        dispatch(updateAcccountReducerAction(addVendor,e,{vendorName:newVendor}))

    }
    const deleteHandler =(e)=>{
      console.log("button delete clicked",e)
      swal({
        title: "Are you sure?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if(willDelete){
          dispatch(deleteAcccountReducerAction(deleteVendor,e))
          setCloser(true)
        }
        });
      
    }
    

    const editpopupHandler =(e)=>{
     console.log("lkm kljnkjnjkbi;ki=",e)
      console.log("button editpopupHandler clicked",e)
      // dispatch(OneAccountReducerAction(accountApi+"/"+e))
      setModalCentered(true)
      // dispatch(deleteAcccountReducerAction(deleteAcccount,e))
      

      
      
    }
    const updateAccount =()=>{
      console.log("object id =",rowId)

      if(newVendor.current.value==""){
        setErrors({"vendor":"Vendor is Manodatry"})
        return false
      }
      dispatch(updateAcccountReducerAction(addVendor,rowId,{vendorName:newVendor.current.value}))
      
      setCloser(true)
    }
    const addAccount=()=>{

        console.log("Add Account=",newVendor)
            // newAccount.current.value=''

          if(newVendor.current.value==""){
            setErrors({"vendor":"Vendor is Manodatry"})
            return false
          }
          dispatch(addAcccountReducerAction(addVendor,{vendorName:newVendor.current.value}))
          // setModalCentered(false)
          newVendor.current.value=''

          console.log("input field=",newVendor)
          // dispatch(AccountReducerAction(vendorApi))
          setCloser(true)

    }
    const dispatch =useDispatch();
    let accountArry =[]

    
    // let datava =useSelector((state)=>{

    // });
    
    let data =useSelector((state)=>{
        console.log("dfwef=",state)


        if (state.popUpState) {
          console.log(state.popUpState.popstatus.status,closer,"popUpStatestatus")
          if ((state.popUpState.popstatus.status == 201 && closer)||(state.popUpState.popstatus.status == 200 && closer)) {
            setCloser(false)
            dispatch(popUpUpdate({}))
            dispatch(AccountReducerAction(MOPInstructionApi))
            setModalCentered(false)
            
          }
          console.log(state.popUpState.popstatus.status == 409 , closer,"state.popUpState.popstatus.status == 409 && closer")
          if (state.popUpState.popstatus.status == 409) {
            setCloser(false)
            dispatch(popUpUpdate({}))
            dispatch(AccountReducerAction(vendorApi))
            // setModalCentered(false)
            
          }
        }
        
        
        let account=state.CommonResponse.mopInstructions
        // console.log("response =dscvfsfvd=",state.CommonResponse)
        // if(account.length !=0){
        //   accountArry=account.map((item,index)=>{
        //     console.log(item,"mopInstructions")
        //     return item
        //   })
        // }
        
      
        console.log(account)
      
      return {account}
      })

      // const noOfRow=data.accountArry.length
      // data.accountArry.push(
      //   {   
      //       sNo:noOfRow+1,
      //       account:<input type='text' placeholder='Add Account' value={newAccount} onChange={(e)=>setAccount(e.target.value)}/>,
      //       edit : <button onClick={()=>addAccount()}>Add Account</button>
            
      //   }
      // )

    console.log("data =",data)
    useEffect(()=>{
        dispatch(AccountReducerAction(MOPInstructionApi))
        
    },[newVendor])
    const columns = [
        {
          name: "SiteCode",
          label: "S:Site Code",
          options: {
           filter: true,
           sort: true,
           setCellHeaderProps: () => ({
             style: {
              
                 alignItem:"center",
               background:"#143b64",
               color:"white"
   
             }
           })
          }
         },
         {
          name: "SiteAccess",
          label: "S:24x7 Site Access?",
          options: {
           filter: true,
           sort: true,
           setCellHeaderProps: () => ({
             style: {
              
                 alignItem:"center",
               background:"#143b64",
               color:"white"
   
             }
           })
          }
         },
         {
          name: "AccessDetails",
          label: "S:Site Access Details",
          options: {
           filter: true,
           sort: true,
           setCellHeaderProps: () => ({
             style: {
              
                 alignItem:"center",
               background:"#143b64",
               color:"white"
   
             }
           })
          }
         },
         {
          name: "AccessDetailsUpdated",
          label: "S:Site Access Details Updated by",
          options: {
           filter: true,
           sort: true,
           setCellHeaderProps: () => ({
             style: {
              
                 alignItem:"center",
               background:"#143b64",
               color:"white"
   
             }
           })
          }
         },
        // {
        //  name: "edit",
        //  label: "Action",
        //  options: {
        //   filter: false,
        //   sort: false,
        //   print:false,
        //   searchable:false,
        //   setCellHeaderProps: () => ({
        //     style: {
             
        //         alignItem:"center",
        //       background:"#143b64",
        //       color:"white"
  
        //     }
        //   })
        //  }
        // },
        
       ];
       
       let row=[];
       
       const handleChange = (e) => {
        console.log("the current anser= ", e.target.value)
      }
    
      const AddCustomButton = () => (
        <fregment style={{ float: "right", display: "flex", flexDirection: row, justifyContent: "space-between" }}>
          {/* <Button
          variant="primary btn-xs mb-2"
          type="button"
          className="btn btn-primary btn-xs mx-2 py-2 my-3"
          onClick={() =>{ setModalCentered(true);setDvalue('') ;setErrors({});setAddUpdate(true)}}>
            Add MOP Instruction
          </Button> */}
          <CommonBulkUploader typeForm={"MOP Instruction"} shortCode={"MOP_Instruction"} classes={"btn btn-primary btn-xs mx-2 py-2 my-3"} afterLoad={vendorApi}/>
          {/* <Modal className="fade" show={modalCentered}>
            <Modal.Header>
            {checkAddUpdate?<Modal.Title>Add MOP Instruction</Modal.Title>:<Modal.Title>Update Record</Modal.Title>}
                <Button
                  onClick={() => setModalCentered(false)}
                  variant=""
                  className="close">
                <span>&times;</span>
                </Button>
                </Modal.Header>
                <Modal.Body>
                  <input type='text' placeholder='Add Vendor' ref={newVendor} defaultValue={dValue}/>
                  
                  {errors && errors.vendor ? <p className="error">{errors.vendor}</p> : ""}
                
                </Modal.Body>
                <Modal.Footer>
                  <Button
                  onClick={() => setModalCentered(false)}
                  variant="danger btn-xs"
                >
                  Close
                </Button>
                {checkAddUpdate?<Button onClick={()=>{addAccount()}} variant="primary btn-xs">Save changes</Button>
                  :<Button onClick={()=>{updateAccount()}} variant="primary btn-xs">Update changes</Button>  
              }
                </Modal.Footer>
            </Modal> */}
        </fregment>
      )
       
    //    const data = [
    //     { name: "Joe James", company: "In Progress", city: "Yonkers", state: "NY" },
    //     { name: "John Walsh", company: "Completed", city: "Hartford", state: "CT" },
    //     { name: "Bob Herm", company: "Awaited", city: "Tampa", state: "FL" },
    //     { name: "James Houston", company: "NA", city: "Dallas", state: "TX" },
    //    ];
       
       const options = {
        download:"false",
         filterType: 'none',
         selectableRows: false
       };
      
  return (
    <>
      

      {console.log(data.account,"account")}
		  <ThemeProvider theme={getMuiThemeCommon()}>

        <MUIDataTable
        
          //  title={"Manage MOP Instruction"}
           title={"Manage Site Access"}
           
            data={data.account}
            columns={columns}
            // options={advancdcustomDataTableOptionObject(AddCustomButton,true,"vendors")}
            options={advancdExportOnecustomDataTableOptionObject(AddCustomButton,true,"Vendor_","",false,"Vendor Name",false)}
        />
      </ThemeProvider>
    </>
  )
}

export default ManageMOPInstruction