import { InsertEmoticonRounded } from '@mui/icons-material';
import React,{useState,useEffect} from 'react'
import { useSelector } from 'react-redux';

const Historytable = ({id}) => {

    let siteInfo={}
    let siteDetail= useSelector((state)=>{
      console.log("the url =",state)
    let site =state.OneCommonResponse.singleSite
    
    if(typeof(site)== typeof({}) ){
      siteInfo=site
      console.log("th e sit e is sit=",siteInfo)
      return {siteInfo}
    }
    
    })

  return (
    

    <div className="table-responsive table-dark ">
      <table className="table  "  border="1" >
        <tbody>
           
          <tr>
            <td scope="col">Scheduled_Date</td>
            <td scope="col">Site Id</td>
            <td scope="col">Project Code</td>
            <td scope="col">Technology</td>
            <td scope="col">Service Affecting</td>
            <td scope="col">Planned SoW</td>
            <td scope="col">Activity Status(Technology Level)</td>
            <td scope="col">Cancelled/Rollback Reason</td>
            <td scope="col">Trouble Ticket</td>
            <td scope="col">Trouble Ticket Id</td>
            <td scope="col">Ativity WentOut of Approved Window</td>
            <td scope="col">Time After Approved Window</td>
    
          </tr>
                <tr>
                <td>{siteDetail? siteDetail.siteInfo.date?siteDetail.siteInfo.date:"":""}</td>
              <td>{siteDetail? siteDetail.siteInfo.ndResult?siteDetail.siteInfo.ndResult.siteId:"":""}</td>
              <td>{siteDetail? siteDetail.siteInfo.ndResult?siteDetail.siteInfo.ndResult.projectCode:"":""}</td>
              {/* <td>{siteDetail?siteDetail.siteInfo.ndResult? siteDetail.siteInfo.ndResult.technology.map((techitem)=>(
              <>{techitem.label}</>
              )):"":""}
              </td> */}
                <td>{siteDetail? siteDetail.siteInfo.serviceAffected:""}</td>
                <td>
                {siteDetail?siteDetail.siteInfo.sowDetails? 
                siteDetail.siteInfo.sowDetails.values?siteDetail.siteInfo.sowDetails.values.map((item)=>{
                  return item
                })
                :"":"":''}
                </td>
              <td></td>
              <td></td>
                <td>{siteDetail?siteDetail.siteInfo.ndResult? siteDetail.siteInfo.ndResult.troubleTickets:"":""}</td>
                <td>{siteDetail? siteDetail.siteInfo.ndResult?siteDetail.siteInfo.ndResult.ttId:"":""}</td>
                <td >{siteDetail? siteDetail.siteInfo.ActivityWentOutOfApprovedWindow?siteDetail.siteInfo.ActivityWentOutOfApprovedWindow:"":""}</td>
                <td >{siteDetail? siteDetail.siteInfo.TimeAfterApprovedWindow?siteDetail.siteInfo.TimeAfterApprovedWindow:"":""}</td>
                </tr>

             
          
        </tbody>
        
      </table>
    </div>
  )
}

export default Historytable;