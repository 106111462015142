import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LSSVDashboardAPI, OneSiteDatabase, OneSiteDatabaseProjectCode, OneSiteDatabaseProjectId, managEngineer, siteDblistSiteId, vendorApi } from '../../../common/config';
import { AccountReducerAction, FetchSingleRecordAction, TierListReducerAction, addUserAction, clearFetchSingleRecordAction, clearGetSingleProject, clearProjectId, popUpUpdate } from '../../../store/actions/createTicketManageFields';
import DatePicker from "react-datepicker";
import { MultiSelect } from 'react-multi-select-component';
import { useForm } from 'react-hook-form';
import CommonCrew from '../Forms/Ran Form/commoncrew';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const moment = require('moment');
const AddLaunchSSV = () => {
  let history = useHistory()
  let siteArray = [];
  let allProjects = []
  let projectCodeOption = []
  let newTtempArray = []
  let siteInfo = []
  let projectIdArray = []
  let vStatusArry = []
  let engineerArray = []
  let siteData = {}
  
  let projectCodeArray=[];
  let dispatch = useDispatch()
  let [oneprid, setoneprid] = useState('')
  let [DbName, setDB] = useState([]);
  let [pncurrentValueId, setpncurrentValueId] = useState(false)
  let [rttDateError, setRttDateError] = useState(false)
  let [UUUDateError, setUUUDateError] = useState(false);
  let [NCDOandANRSONDateError, setNCDOandANRSONDateError] = useState(false);
  
  let [siteError, setSiteError] = useState(false)
  let [rttDate, setRTTDate] = useState("");
  let [NCDOandANRSONDate, setNCDOandANRSONDate] = useState("");
  let [singleSitestate, setsingleSitestate] = useState({});
  let [ctrCount, setCtrCount] = useState(1);
  let [unlockDate, setUnlockDate] = useState("")

  let [selectedSited, setSelectId] = useState()
  let [selectedProjectCode, setSelectProjectCode] = useState()
  let [regionState, setRegion] = useState()
  let [ringCode, setRingCode] = useState()
  let [projectType, setProjectType] = useState()
  let [HCVendor, setHCVendor] = useState()
  let [selectedMarket, setMarket] = useState()
  let [selectedProjectId, setSelectProjectId] = useState()
  let [SelselectProjectCode, setSelProjectCode] = useState([])
  const [launchEngineer, setLaunchEngineerOpen] = useState([]);
  const [EngineerOpen, setEngineerOpen] = useState([]);
  const [uuuEng, setUUUEngineer] = useState()
  const [closer, setCloser] = useState(false)
  let [selectProjectId, setProjectId] = useState([])
  let [selectProjectCode, setProjectCode] = useState([])
  let count = useRef(1)
  let currentUrl = window.location.href
  const { register, getValue, setValue, handleSubmit, setError, formState: { errors } } = useForm()
  let options = useSelector((state) => {
    console.log("nbajkvjcwce=", currentUrl)
    let fetchData = state.OneCommonResponse.singleIXSite
    let vendorroledata = state.CommonResponse.vendor
    if (vendorroledata != 0) {
      vStatusArry = vendorroledata.map((item) => {
        // console.log(ven, item.uniqueid, "ven==item.uniqueid")
        return <option value={item.uniqueid}>{item.vendorName}</option>
        // if(ven==item.uniqueid){
        //   return <option selected value={item.uniqueid}>{item.vendorName}</option>
        // }else{
        //   return <option value={item.uniqueid}>{item.vendorName}</option>
        // }
      })

    }
    if (fetchData && Object.keys(fetchData).length > 0) {
      console.log("nbjkbjhbq", fetchData)
      siteData = fetchData

      if (vendorroledata != 0) {
        vStatusArry = vendorroledata.map((item) => {
          // if (fetchData.vendor == item.vendorName) {
          //   console.log("kjxbjhbalvahduofwjklfcwhrfw===", fetchData.vendor == item.vendorName)
          //   return <option selected value={item.uniqueid}>{item.vendorName}</option>
          // }
          // else {
            return <option value={item.uniqueid}>{item.vendorName}</option>
          // }
          // console.log(ven, item.uniqueid, "ven==item.uniqueid")
          // if(ven==item.uniqueid){
          //   return <option selected value={item.uniqueid}>{item.vendorName}</option>
          // }else{
          //   return <option value={item.uniqueid}>{item.vendorName}</option>
          // }
        })

      }

      if (count.current == 1) {
        if (fetchData.crew_status && fetchData.crew_status.name != undefined) {
          console.log("setDbName")
          DbName.push(fetchData.crew_status.name)
          DbName.push(fetchData.crew_status.crewType == "IX" ? "IXCrew" : "CXCrew")
          DbName.push(fetchData.crew_status["unqId"])

        }
        // if (fetchData.crew_status.name != undefined) {
        //     console.log("setDbName")
        //     DbName.push(fetchData.crew_status.name)
        //     DbName.push(fetchData.crew_status.crewType=="CX"?"CXCrew":"IXCrew")
        //     DbName.push(fetchData.crew_status["unqId"])
        // }
        setValue("ssvPlanType", fetchData.ssvPlanType)
        setValue("projectType", fetchData.projectType)
        setValue("SiteCategory", fetchData.SiteCategory)
        setValue("vendor", fetchData.vendor)
        setValue("LaunchEngineer", fetchData.LaunchEngineer)
        setValue("UUUEngineer", fetchData.UUUEngineer)
        setValue("SSSNNPEng", fetchData.SSSNNPEng)
        // setsingleSitestate(siteInfo)
        setSelectId(fetchData.siteId)
        setMarket(fetchData.projectUniqueId[0].market)
        // if (fetchData.projectUniqueId.length > 0) {
        // }
        if (fetchData.projectUniqueId.length > 0) {
          setsingleSitestate(fetchData.projectUniqueId[0])
          setRegion(fetchData.projectUniqueId[0].region)
          setRingCode(fetchData.projectUniqueId[0].RingCode)
          setProjectType(fetchData.projectUniqueId[0]?.projectType)
          setHCVendor(fetchData.projectUniqueId[0]?.HardCostVendor)
          
        }
        let projectCode = fetchData["projectUniqueId"].map((item) => item.projectCode)
        setSelectProjectCode(projectCode.join(","))
        let productId = fetchData["projectUniqueId"].map((item) => item.projectId)
        setSelectProjectId(productId.join(","))
        console.log("ghjvkjbhdsavcsdjvfd=", fetchData["siteId"])
        if (fetchData.ssvRttDate) {

          setRTTDate(new Date(fetchData.ssvRttDate))
        }
        if (fetchData.LUUUDate) {
          setUnlockDate(new Date(fetchData.LUUUDate))
        }

        
        if (fetchData.LNCDOandANRSONDate) {
          setNCDOandANRSONDate(new Date(fetchData.LNCDOandANRSONDate))
        }

        
        console.log("codercode")
        count.current = 2
      }
      console.log("jbhjagdagsvdjvdjhvww=====", DbName)
      console.log("codercoding")
    }
    if (state.popUpState) {
      console.log(state.popUpState.popstatus.status, closer, (state.popUpState.popstatus.status == 201 && closer) || (state.popUpState.popstatus.status == 200 && closer), "popUpStatestatus201")
      if ((state.popUpState.popstatus.status == 201 && closer) || (state.popUpState.popstatus.status == 200 && closer)) {
        // dispatch(popUpUpdate({}))
        // setCloser(false)
        setDB([])
        setCloser(false);
        dispatch(popUpUpdate({}));
        dispatch(AccountReducerAction(LSSVDashboardAPI))
        history.push("/PAG/launchSSSNNP/dashboard")
      }
    }
    allProjects = state.CommonResponse.startAwaitedSite
    // console.log("all projects is =",allProjects)
    if (allProjects && allProjects.length > 0) {

      siteArray = allProjects.map((project) => {
        // console.log("the project is =========",project)
        return <option>{project.siteId}</option>;
      });
    }
    projectCodeArray = state.OneCommonResponse.productCode
    if (projectCodeArray && projectCodeArray.length > 0) {
      projectCodeOption = projectCodeArray.map((item, index) => {


        console.log(SelselectProjectCode, item.projectCode, SelselectProjectCode.indexOf(item.projectCode), selectProjectCode.findIndex(abcd => { console.log(abcd); return item.projectCode == abcd.label }), "SelselectProjectCode")
        if (SelselectProjectCode.indexOf(item.projectCode) >= 0 && selectProjectCode.findIndex(abcd => {
          return item.projectCode == abcd.label
        }) == -1) {
          selectProjectCode.push({
            value: item.uniqueid,
            label: item.projectCode,
          })

        }
        return {
          value: item.uniqueid,
          label: item.projectCode,
        };
      })

    }
    projectIdArray = state.OneCommonResponse.productId
    if (projectIdArray && projectIdArray.length > 0) {
      let projectIdOption = projectIdArray.map((item, index) => {
        let availableId = []
        let anyArry = item.data
        availableId = item.data.map((id) => {
          return {
            label: id.projectId,
            group: item.projectCode,
            value: id.uniqueid
          }
        })

        newTtempArray.push({
          label: item.projectCode,
          value: item.projectCode,
          disabled: true,
        })
        newTtempArray.push(...availableId)

      })
    }
    siteInfo = state.csvResponse.singleSite;
    console.log("th e stacdsfcsd=", state)
    // console.log( siteInfo, siteInfo.length != 0, "ctrCount")
    if (ctrCount == 1 && siteInfo && siteInfo.length != 0) {
      console.log("the single site is =", siteInfo)
      setsingleSitestate(siteInfo)
      setCtrCount(2)
    }

    vStatusArry.shift(<option disabled selected value={""}>Select</option>)
    let tier = state.CommonResponse.listtier
    if (tier && tier.length != 0) {
      engineerArray = tier.map((item) => {
        return <option>{item.name}</option>;
      })
    }
    return { siteArray, projectCodeOption, vStatusArry, engineerArray, siteData }
  })
  const styles = {
    width: "400px",
  };
  const findDSiteId = (id) => {
    console.log(id, "findDSiteId", allProjects)

    let getter=allProjects.filter((item) => item.siteId == id)
    
    dispatch(clearGetSingleProject())
    setProjectCode([])
    setSelProjectCode([])
    setsingleSitestate({})
    setSelectProjectId([])
    setProjectId([])
    if (id != undefined && getter.length>0) {
      //   setcustomesite(id)
      //   setProjectId([])
      
      //   setTech([])
      // findDProjectId(id, 1)

      // if(stateonce){
      //   setstateonce(false)
      //   dispatch(clearProjectId())
      // }

      setValue("siteId", id)
      let abc = setValue("siteUniqueId", allProjects.filter((item) => item.siteId == id)[0].uniqueSiteId)
      setSiteError(false)
      if (allProjects.length > 0)
        console.log("the respasfsdvgfdsbdtrh =", ...allProjects.filter((item) => item.siteId == id))
      let idObject = allProjects.filter((item) => item.siteId == id)
      if (idObject.length > 0) {
        // setond(true)
        dispatch(FetchSingleRecordAction(OneSiteDatabaseProjectCode, allProjects.filter((item) => item.siteId == id)[0].uniqueSiteId))
      }
    }
    else {
      setSiteError(true)
    }
  }
  const findengineer = (id) => {
    setEngineerOpen(id)
  }
  const findLaunchEngineer = (id) => {
    setLaunchEngineerOpen(id)
  }
  const findUUUEngineer = (id) => {
    setUUUEngineer(id)
  }

  console.log(singleSitestate,"singleSitestate")
  // const findDProjectId = (id, def = 0, pcode = []) => {

  //   // setCountRender(1)
  //   // console.log("projectCodeArray", id, projectCodeArray)
  //   // setppProjectCode(id)

  //   // setppProjctId('')
  //   let selectedIds = []
  //   let nselectedIds = ""
  //   if (def == 0) {
  //     setProjectCode(id)
  //     selectedIds = id.map((item) => {
  //       return item.value
  //     })
  //     nselectedIds = selectedIds.join(",")
  //   } else {
  //     let testpcode = []

  //     // testpcode=projectCodeArray.map((ittm)=>{
  //     //   if(pcode.indexOf(ittm.label)!=-1){
  //     //     return {
  //     //       label:ittm.label,
  //     //       value:ittm.value
  //     //     }
  //     //   }
  //     // })
  //     // setProjectCode(testpcode)
  //     nselectedIds = id
  //   }
  //   // console.log(OneSiteDatabase + id, id, projectCodeArray, "projectCodeArray")

  //   setSelProjectCode(nselectedIds.split(","))
  //   setValue("projectCode", nselectedIds)
  //   setValue("projectcodeUniqueId", selectedIds.join(","))
  //   dispatch(FetchSingleRecordAction(OneSiteDatabaseProjectId, nselectedIds))



  // }

  const findDProjectId = (id, def = 0, pcode = []) => {
    console.log(id,"findDProjectId")
    // setCountRender(1)
    // console.log("projectCodeArray", id, projectCodeArray)
    // setppProjectCode(id)

    // setppProjctId('')
    // let selectedIds = []
    // let nselectedIds = ""
    // if (def == 0) {
    //   setProjectCode(id)
    //   selectedIds = id.map((item) => {
    //     return item.value
    //   })
    //   nselectedIds = selectedIds.join(",")
    // } else {
    //   let testpcode = []

    //   // testpcode=projectCodeArray.map((ittm)=>{
    //   //   if(pcode.indexOf(ittm.label)!=-1){
    //   //     return {
    //   //       label:ittm.label,
    //   //       value:ittm.value
    //   //     }
    //   //   }
    //   // })
    //   // setProjectCode(testpcode)
    //   nselectedIds = id
    // }
    // // console.log(OneSiteDatabase + id, id, projectCodeArray, "projectCodeArray")

    // setSelProjectCode(nselectedIds.split(","))
    // setValue("projectCode", nselectedIds)
    // setValue("projectcodeUniqueId", selectedIds.join(","))
    // dispatch(FetchSingleRecordAction(OneSiteDatabaseProjectId, nselectedIds))

    // setCountRender(1)
    setProjectCode(id)
    let selectedIds = []
    let selectedname = []
    selectedIds = id.map((item) => {
      selectedname.push(item.label)
      return item.value
    })

    if (id.length == 0) {
      // clearProjectId
      dispatch(clearProjectId())
    }

    // setSelProjectCodename(selectedname)


    console.log("projectCodeArray", selectedIds.join(","), id)
    let test = projectCodeArray.filter((item) => item.uniqueid == id)[0]
    console.log(test, "codertest")

    // if (test) {
    //   if (test.length > 0) {
    //     setppProjectCode(test.projectCode)
    //   }
    // }

    console.log(OneSiteDatabase + id, id, projectCodeArray, "projectCodeArray")
    setoneprid(id)
    // dispatch(FetchSingleRecordAction(networkOneSiteDatabaseprojectId, selectedIds))   //from project Database

    // dispatch(FetchSingleRecordAction(OneSiteDatabaseProjectId, nselectedIds))  // from network Database
    let newProjectIdSel = []
    let newIdProjectIdSel = []
    let temp_new_id = []
    selectProjectId.map((item) => {
      if (selectedname.indexOf(item.group) != -1) {
        newProjectIdSel.push(item)
        newIdProjectIdSel.push(item.value)

        temp_new_id.push(item.mainId)
      }
    })
    setProjectId(newProjectIdSel)
    // console.log(tech, newProjectIdSel, selectedIds, "techkatech")
    // setTecheer(newProjectIdSel)
    FetechAllDetail(newProjectIdSel)
    // findDProjectId(newProjectIdSel)


    setValue("projectId", newIdProjectIdSel.join(","))
    setValue("projectcodeUniqueId", selectedIds.join(","))


    dispatch(FetchSingleRecordAction(OneSiteDatabaseProjectId, selectedIds.join(",")))



  }

  const FetechAllDetail = (id) => {
    // let test = projectIdArray.filter((item) => item.uniqueid == id)[0]
    // console.log(test, "test")
    // setPId(id)
    // setppProjctId(id)
    setProjectId(id)
    let selectedId = id.map((item) => {
      return item.value
    })
    // dispatch(FetchSingleRecordAction(ranDashboard, selectedId));
    dispatch(FetchSingleRecordAction(OneSiteDatabase, selectedId.join(",")))
    // setCtrCount(1)
    setValue("projectUniqueId", selectedId.join(","))
    
    setCtrCount(1)
    // /console.log("FetechAllDetail", id)
  }
  const onSubmit = (data) => {

    // setRttDateError(true)
    // setUUUDateError(true)
    // rttDate == "" && 
    if (unlockDate == "" && NCDOandANRSONDate=="") {
      // setRttDateError(true)
      setUUUDateError(true)
      setNCDOandANRSONDateError(true)
      return
    }
    // if (rttDate == "") {
    //   setRttDateError(true)
    //   return
    // }
    if (unlockDate == "") {
      setUUUDateError(true)
      return
    }
    if (NCDOandANRSONDate == "") {
      setNCDOandANRSONDateError(true)
      return
    }

    

    // setRttDateError(false)
    setUUUDateError(false)
    setNCDOandANRSONDateError(false)
    data["LNCDOandANRSONDate"] = moment(NCDOandANRSONDate).format("MM/DD/YYYY")
    data["LUUUDate"] = moment(unlockDate).format("MM/DD/YYYY")
    console.log("the data is knbjkdhjebvevker=", data)
    if(currentUrl.includes("edit")){
      dispatch(addUserAction(LSSVDashboardAPI+"/"+currentUrl.split("/").pop(), data))
    }else{
      dispatch(addUserAction(LSSVDashboardAPI, data))
    }
    dispatch(popUpUpdate({}))
    setCloser(true)
  }
  useEffect(() => {
    setDB([])
    dispatch(AccountReducerAction(siteDblistSiteId))
    dispatch(AccountReducerAction(vendorApi))
    dispatch(TierListReducerAction(managEngineer))
    dispatch(FetchSingleRecordAction(LSSVDashboardAPI, currentUrl.split("/").pop()))
  }, [])
  return (
    <>
      <form className='row' onSubmit={handleSubmit(onSubmit)}>
        {

          currentUrl.includes("edit") == false
            // console.log(":,mbhjvbxjsaxas",currentUrl.includes("edit"))
            ?
            <div className="form-group col-md-3">
              <label for="exampleFormControlSelect1">
                Site Id<span style={{ color: "red" }}>*</span>
              </label>
              <input className='form-control' list="cars"
                style={{ height: "32px" }}

                onBlur={(e) => {
                  findDSiteId(e.target.value)
                }}
              />
              <p className="error" style={{ display: siteError ? "bolck" : "none" }}>
                Required Field
              </p>
              <datalist id="cars">

                {options.siteArray}
              </datalist>
            </div>
            :
            <div className="form-group col-md-3">
              <label for="exampleFormControlSelect1">
                Site Id<span style={{ color: "red" }}>*</span>
              </label>
              <input className='form-control' type="text" defaultValue={selectedSited} disabled>
              </input>
            </div>
        }

        {

          currentUrl.includes("edit") == false
            // console.log(":,mbhjvbxjsaxas",currentUrl.includes("edit"))
            ?
            <div className="form-group col-md-3">
              <label htmlFor="inputEmail4">Plan Type<span style={{ color: "red" }}>*</span></label>

              <MultiSelect
                className="text-dark"
                hasSelectAll={false}
                options={options.projectCodeOption}
                onChange={findDProjectId}
                value={selectProjectCode}
              />
            </div>

            :
            <div className="form-group col-md-3">
              <label for="exampleFormControlSelect1">
                Project Code<span style={{ color: "red" }}>*</span>
              </label>
              <input className='form-control' type="text" defaultValue={selectedProjectCode} disabled>
              </input>
            </div>
        }
        {

          currentUrl.includes("edit") == false
            // console.log(":,mbhjvbxjsaxas",currentUrl.includes("edit"))
            ?
            <div className="form-group col-md-3 ">
              <label >Project Id<span style={{ color: "red" }}>*</span></label>
              <MultiSelect
                className="text-dark"
                hasSelectAll={false}

                options={newTtempArray}
                onChange={FetechAllDetail}
                value={selectProjectId}
              />
              {/* {errors.projectUniqueId && (
                   <p className="error">
                     {errors.projectUniqueId.message}
                   </p>
                 )} */}
            </div>

            :
            <div className="form-group col-md-3">
              <label for="exampleFormControlSelect1">
                Project Id<span style={{ color: "red" }}>*</span>
              </label>
              <input className='form-control' type="text" defaultValue={selectedProjectId} disabled>
              </input>
            </div>
        }
        {/* <div className='form-group col-md-3'>
          <label>Region</label>
          <input type="text" className='form-control ' value={singleSitestate.region || regionState} readOnly />
        </div>
        <div className='form-group col-md-3'>
          <label>Market</label>
          <input type="text" className='form-control ' value={singleSitestate.market || selectedMarket} readOnly />
        </div>
        <div className='form-group col-md-3'>
          <label>Ring ID</label>
          <input type="text" className='form-control ' value={singleSitestate.RingCode || ringCode} readOnly />
        </div>
        
        <div className='form-group col-md-3'>
          <label>Project Type</label>
          <input type="text" className='form-control ' value={singleSitestate?.projectType || projectType} readOnly />
        </div> */}

        {/* {singleSitestate.region} */}
        
        <div className='form-group col-md-3'>
          <label>Region</label>
          <input type="text" className='form-control ' defaultValue={singleSitestate.region} readOnly />
        </div>
        <div className='form-group col-md-3'>
          <label>Market</label>
          <input type="text" className='form-control ' defaultValue={singleSitestate.market} readOnly />
        </div>
        <div className='form-group col-md-3'>
          <label>Ring ID</label>
          <input type="text" className='form-control ' defaultValue={singleSitestate.RingCode} readOnly />
        </div>
        
        <div className='form-group col-md-3'>
          <label>Project Type</label>
          <input type="text" className='form-control ' defaultValue={singleSitestate?.projectType} readOnly />
        </div>

        {/* <div className='form-group col-md-3'>
       <label>Plan Type<span style={{ color: "red" }}>*</span></label>
       <input type ="text"  className='form-control '
       {
         ...register("ssvPlanType",{required :"Please enter ssv type"})
       }
       
       />
         {errors.ssvPlanType && (<p className='error'>{errors.ssvPlanType.message}</p>)}
   </div> */}

        {/* <div className='form-group col-md-3'>
          <label>Project Type<span style={{ color: "red" }}>*</span></label>
          <input type="text" className='form-control '

            {
            ...register("projectType", { required: "Please enter project type" })
            }

          />
          {errors.projectType && (<p className='error'>{errors.projectType.message}</p>)}
        </div> */}


        <div className='form-group col-3'>
          <label>Site Category<span style={{ color: "red" }}>*</span></label>
          <select
            className='form-group'
            {
            ...register("SiteCategory", { required: "Please enter Site Category" })
            }
          >
            <option disabled selected value={""}>Select</option>
            <option value={"Core"}>Core</option>
            <option value={"Rural"}>Rural</option>
            <option value={"Hybrid"}>Hybrid</option>
          </select>
          {errors.projectUniqueId && (<p className='error'>{errors.SiteCategory.message}</p>)}
        </div>
        {/* <div className='form-group col-md-3'>
          <label>HC Vendor</label>
          <select className='form-control '
            {...register("vendor")}
          >
            {options.vStatusArry}
          </select>
        </div> */}
        {/* <div className='form-group col-md-3'>
          <label>HC Vendor</label>
          <input type="text" className='form-control ' value={singleSitestate?.HardCostVendor || HCVendor} readOnly />
        </div> */}
        
        <div className='form-group col-md-3'>
          <label>HC Vendor</label>
          <input type="text" className='form-control ' defaultValue={singleSitestate?.HardCostVendor} readOnly />
        </div>
        {/* <div className='form-group col-md-3'>
       <label>GC</label>
       <input type ="text"  className='form-control '/>
   </div> */}
        <div className='form-group col-md-3'>
          <label>RTT Date<span style={{ color: "red" }}>*</span></label>
          <DatePicker
            style={styles}
            className="form-control"
            selected={rttDate}
            onChange={(e) => {
              setRTTDate(e);
              //  setRttDateError(true)
            }}

            disabled
            popperPlacement="bottom"
            dateFormat="MM/dd/yyyy"
          />
          <p className='error' style={{ display: rttDateError ? "block" : "none" }}>Please select Date</p>
        </div>

        <div className='form-group col-md-3'>
          <label>NCDO and ANR SON Date<span style={{ color: "red" }}>*</span></label>
          <DatePicker
            style={styles}
            className="form-control"
            selected={NCDOandANRSONDate}
            onChange={(e) => {
              setNCDOandANRSONDate(e);
              //  setRttDateError(true)
            }}
            popperPlacement="bottom"
            dateFormat="MM/dd/yyyy"
          />
          <p className='error' style={{ display: NCDOandANRSONDateError ? "block" : "none" }}>Please select Date</p>
        </div>


        

        <div className='form-group col-md-3'>
          <label>UUU Date<span style={{ color: "red" }}>*</span></label>
          <DatePicker
            style={styles}
            className="form-control"
            selected={unlockDate}
            onChange={(e) => {
              setUnlockDate(e);
              //  setUUUDateError(true)
            }}
            popperPlacement="bottom"
            dateFormat="MM/dd/yyyy"
          />
          <p className='error' style={{ display: UUUDateError ? "block" : "none" }}>Please select Date</p>
        </div>
        <div className="form-group col-md-3">
          <label>Launch Engineer<span style={{ color: "red" }}>*</span></label>

          <input style={{ height: "32px" }}
            type="text"
            {...register("LaunchEngineer", {
              required: "Please select Launch Engineer"
            })}
            onBlur={(e) => { findLaunchEngineer(e.target.value) }}
            list="engOpen"
            className="form-control"
            ID="inputAddress"
            defaultValue={launchEngineer}
            placeholder="Select Engineer"
          />

          <datalist id="engOpen">
            {options.engineerArray}
          </datalist>
          {errors.LaunchEngineer && (
            <p className="error">
              {errors.LaunchEngineer.message}
            </p>
          )}
        </div>
        <div className="form-group col-md-3">
          <label>UUU Engineer<span style={{ color: "red" }}>*</span></label>

          <input style={{ height: "32px" }}
            type="text"
            {...register("UUUEngineer", {
              required: "Please select UUU Engineer"
            })}
            onBlur={(e) => { findUUUEngineer(e.target.value) }}
            list="engOpen"
            className="form-control"
            ID="inputAddress"
            defaultValue={uuuEng}
            placeholder="Select Engineer"
          />

          <datalist id="engOpen">
            {options.engineerArray}
          </datalist>
          {errors.UUUEngineer && (
            <p className="error">
              {errors.UUUEngineer.message}
            </p>
          )}
        </div>
        <div className="form-group col-md-3">
          <label>SSS & NNP Eng<span style={{ color: "red" }}>*</span></label>

          <input style={{ height: "32px" }}
            type="text"
            {...register("SSSNNPEng", {
              required: "Please select SSS & NNP Engineer"
            })}
            onBlur={(e) => { findengineer(e.target.value) }}
            list="engOpen"
            className="form-control"
            ID="inputAddress"
            defaultValue={EngineerOpen}
            placeholder="Select Engineer"
          />

          <datalist id="engOpen">
            {options.engineerArray}
          </datalist>
          {errors.SSSNNPEng && (
            <p className="error">
              {errors.SSSNNPEng.message}
            </p>
          )}
        </div>
        {console.log(DbName, "DbNameDbName")}
        {/* <CommonCrew errors={errors} register={register} setValue={setValue} crewType={"CXCrew"} currentValueName={""} currentValueId={""} sst={pncurrentValueId} setsst={setpncurrentValueId} /> */}
        {/* {
                          
            DbName[1] && DbName[0] && DbName[2] ?
            <CommonCrew errors={errors} register={register} setValue={setValue} crewType={DbName[1]} currentValueName={DbName[0]} currentValueId={DbName[2]} sst={pncurrentValueId} setsst={setpncurrentValueId} />
          :  <CommonCrew errors={errors} register={register} setValue={setValue} crewType={"CXCrew"} currentValueName={""} currentValueId={""} sst={pncurrentValueId} setsst={setpncurrentValueId} />

} */}


        {
          DbName[1] && DbName[0] && DbName[2] ?
            console.log("yess", "terere") :
            console.log("mnoo", "terere")

        }
        {
          currentUrl.includes("edit") ?
            DbName[1] && DbName[0] && DbName[2] ?
              <CommonCrew errors={errors} register={register} setValue={setValue} crewType={DbName[1]} currentValueName={DbName[0]} currentValueId={DbName[2]} />
              : "" : ""
        }

        {
          !currentUrl.includes("edit") ?
            <CommonCrew errors={errors} register={register} setValue={setValue} crewType={"CXCrew"} currentValueName={""} currentValueId={""} sst={pncurrentValueId} setsst={setpncurrentValueId} /> :
            ""
        }
        <div className='col-12 d-flex justify-content-center  mb-2'>
          <button className="btn btn-primary btn-xs py-2 mr-2 px-4" type='submit'>
            Submit
          </button>
          <button className="btn btn-danger btn-xs py-2  px-4 mr-2" onClick={() => {
            setDB([]);
            history.push("/PAG/launchSSSNNP/dashboard")
          }}>Back</button>
        </div>

      </form>

      <br />
      <br />
    </>
  )
}

export default AddLaunchSSV