import React, { useEffect, useState } from 'react'
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { center, currentUrl, google_map_api } from '../../../../common/variables';
import PlaceIcon from '@mui/icons-material/Place';
import { QrCode } from '@mui/icons-material';
import marker_image from "../../../../images/marker.png";
// "" '../../../../images';
import { useDispatch, useSelector } from 'react-redux';
import { store } from '../../../../store/store';
const RanMapView = ({ filteredMapData }) => {

  console.log(filteredMapData, "filteredMapData in MapView")
  const dispatch = useDispatch()
  const google = window.google
  let dropDownId = []
  let sitesall = []
  const [selected, setSelected] = useState({});
  const [si_nsi, setsi_nsi] = useState('');

  const [Centernotload, setCenternotload] = useState(true);
  const [center, setCenter] = useState();
  let openedPage = currentUrl.split("PAG/")
  console.log("vfdvdfv=", openedPage[1], "==dwcferfv==", openedPage[1] == "ran/support/dashboard")
  console.log(store.getState(), "store")
  const response = useSelector((state) => {
    sitesall = state.CommonResponse.ranDashboard
    console.log(sitesall[0].ndResult, Centernotload, "sitesall")
    if (Centernotload) {
      setCenter({
        lat: sitesall[0].ndResult.latitude,
        lng: sitesall[0].ndResult.longitude
      })
      setCenternotload(false)
    }

    console.log(center, "sitesall")
  })

  const google_marker = {
    "yellow": "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png",
    "blue": "https://maps.gstatic.com/mapfiles/ms2/micons/blue-dot.png",
    "red": "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
    "gray": "https://maps.gstatic.com/mapfiles/ms2/micons/msmarker.shadow.png",
    "lightgreen": "http://maps.google.com/mapfiles/ms/icons/green-dot.png"
  }
  const status_color = {
    "Scheduled": google_marker["yellow"],
    "Requests": google_marker["blue"],
    "Progress": google_marker["red"],
    "Completed": google_marker["lightgreen"],
    "Log Out": google_marker["lightgreen"],
    
  }
  const containerStyle = {
    width: "400px",
    height: "400px",
    position: "initial",
  };

  const onSelect = item => {
    setSelected(item);
  }

  useEffect(() => { }, []);



  const markShow = (marking) => {
    // if (marking.typeOfWork == "SI") {
    //   if (marking.group == "Development") {
    //     if (marking.rfstatus == "Pending" && marking.devstatus == "Pending") {
    //       return google_marker["blue"]
    //     }
    //     if (marking.rfstatus == "Approved" && marking.devstatus == "Approved" && marking.crewAssign != 2) {
    //       return google_marker["red"]
    //     }
    //     return google_marker["gray"]
    //   }
    //   if (marking.group == "Operation") {
    //     if (marking.rfstatus == "Pending" && marking.devstatus == "Pending") {
    //       return google_marker["blue"]
    //     }
    //     if (marking.rfstatus == "Approved" && marking.devstatus == "Approved" && marking.crewAssign != 2) {
    //       return google_marker["red"]
    //     }
    //     return google_marker["yellow"]
    //   }
    // }

    // if (marking.typeOfWork == "NSI") {
    //   if (marking.group == "Development") {
    //     if (marking.rfstatus == "Rejected" || marking.devstatus == "Rejected" || marking.crewAssign == 2) {
    //       return google_marker["gray"]
    //     }
    //     return google_marker["yellow"]
    //   }
    //   if (marking.group == "Operation") {
    //     if (marking.rfstatus == "Rejected" && marking.fopsstatus == "Rejected" || marking.crewAssign == 2) {
    //       return google_marker["gray"]
    //     }
    //     return google_marker["yellow"]
    //   }
    // }

    return google_marker["gray"]
    console.log(marking, "marking")
  }









  function markmaker(onest) {

    console.log(onest.typeOfWork == "SI", "onest.typeOfWork")
    if (onest.typeOfWork == "SI") {
      if (onest.goNo == "Go") {
        return google_marker["red"]
      } else {
        return google_marker["blue"]
      }
    } else {
      return google_marker["yellow"]
    }
  }

  function callforcreate(onesite) {


    console.log(onesite,si_nsi,"si_nsi", "onesite.data", filteredMapData.indexOf(onesite.ranSupportId), status_color[onesite.ranBucket], onesite.ranBucket)

    if (filteredMapData.length == 0 || (filteredMapData.length > 0 && filteredMapData.indexOf(onesite.ranSupportId) != -1)) {

      if (si_nsi == '' || (si_nsi=="SI" && onesite.serviceAffected=="Yes") || (si_nsi=="NSI" && onesite.serviceAffected=="No") ) {
        return <Marker
          position={{ lat: onesite.ndResult.latitude, lng: onesite.ndResult.longitude, }}
          PinView={{ glyphColor: 'white', }}
          icon={{ url: status_color[onesite.ranBucket] }}
          text="My Marker"
          title={onesite.ndResult.siteId + ", " + onesite.ndResult.siteAddress}
          description='bye'
          onClick={() => onSelect(onesite)}
          // onMouseOver={() => onSelect(onesite)}
          slug={"Current location"}
          name={"Current location"}>

        </Marker>
      }

    }


  }








  return (
    <>
      <select style={{width: "100px"}} onChange={(e) => { setsi_nsi(e.target.value) }}>
        <option value={""} selected>Select</option>
        <option value={"SI"}>SI</option>
        <option value={"NSI"}>NSI</option>
      </select>
      <div>
        <LoadScript googleMapsApiKey={google_map_api}>
          <GoogleMap
            mapContainerStyle={containerStyle}

            center={center}
            zoom={8}
          >
            {
              sitesall.map((marker) => callforcreate(marker))
              //  => {
              //         callforcreate(onedata)
              //       })}
              //       {/* {marker.data[0].latitude != undefined && marker.data[0].longitude != undefined ?

              //          : ""} */}
              //     </>))
            }

            {
              selected.projectCode &&
              (
                selected.ndResult.latitude != undefined && selected.ndResult.longitude != undefined ? <InfoWindow
                  position={{
                    lat: selected.ndResult.latitude,
                    lng: selected.ndResult.longitude,
                  }}
                  clickable={true}
                  onCloseClick={() => setSelected({})}
                >{
                    <>
                      <p className='text-black'>
                        {/* Ticket Id: {selected.ticketId}<br />
                        Type of Work: {selected.typeOfWork}<br /> */}
                        {`${selected.ndResult.siteId},${selected.ndResult.siteAddress}`}
                        {/* Project Id: {selected.projectId}<br />
                        Project Code: {selected.projectCode}<br /> */}
                        {/* Bucket: <label>{selected.ranBucket}</label><br />
                        Status: <label>{selected.ranStatus}</label><br /> */}
                      </p>
                    </>
                  }
                </InfoWindow>
                  : ""
              )
            }

          </GoogleMap>
        </LoadScript>
      </div>
    </>
  )
}

export default RanMapView