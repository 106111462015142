import { baseURL } from '../common/config';
import {axiosInstance,axiosInstanceFile} from './AxiosInstance';

var api_PAG_maindashboard="/PAG/maindashboard"

var api_PAG_NetworkDatabase="/PAG/NetworkDatabase"

var api_PAG_UploadTicket   = "/addPagData"
var api_PAG_dashboard="/PAG/dashboard"
var api_PAG_CsvUppload="/csv_upload"
var api_PAG_PDFUpload="/pdf_upload"

export  function PAG_maindashboard() {
    return axiosInstance.get(baseURL+api_PAG_maindashboard);
}

// Netwoork Database Api

export async function NetworkDatabase(){
    return await axiosInstance.get(baseURL+api_PAG_NetworkDatabase)
}

// Upload Excel File
export function UploadExcelFile(uploadData){
    return  axiosInstance.post(baseURL+api_PAG_CsvUppload,uploadData)
}

export function UploadPDFFile(url,uploadData){
    return  axiosInstance.post(baseURL+url,uploadData)
}




// upload Create ticket form
export function uploadTicketData(data){
    return axiosInstance.post(baseURL+api_PAG_UploadTicket,data)
}

export function PAG_dashboard() {
    return axiosInstance.get(baseURL+api_PAG_dashboard);
}

export function createPost(postData) {
    return axiosInstance.post(`posts.json`, postData);
}

export function updatePost(post, postId) {
    return axiosInstance.put(`posts/${postId}.json`, post);
}

export function deletePost(postId) {
    return axiosInstance.delete(`posts/${postId}.json`);
}

export function formatPosts(postsData) {
    let posts = [];
    for (let key in postsData) {
        posts.push({ ...postsData[key], id: key });
    }

    return posts;
}
