import React, { useRef, useState } from 'react'
import MUIDataTable from "mui-datatables";
import { useEffect } from 'react';
import { Row, Card, Col, Dropdown, Button, Modal, Container } from "react-bootstrap";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { accountApi, addAcccount, deleteAcccount, geoFencing } from '../../../../common/config';
import { customDataTableOptionObject } from "../../../../common/variables";
import { AccountReducerAction, OneAccountReducerAction, addAcccountReducerAction, deleteAcccountReducerAction, updateAcccountReducerAction, popUpUpdate } from '../../../../store/actions/createTicketManageFields';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
const ManageGeoFencing = () => {
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  let row = [];
  let objectId = ''
  const [modalCentered, setModalCentered] = useState(false);
  const [dValue, setDvalue] = useState('')
  const [rowId, setRowId] = useState('')
  const [checkAddUpdate, setAddUpdate] = useState(0)
  const [closer, setCloser] = useState(false)
  

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({});
  //  const [newAccount,setAccount]=useState('')
  const newAccount = useRef()
  let oneaccount = ""


  // setAccount(oneaccount)
  console.log(oneaccount, "oneaccount")
  const getMuiTheme = () => createTheme({
    components: {
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            backgroundColor: "transparent"
          }
        }
      }
    }
  })
  const updateHandler = () => {


    // console.log(getValues("precheckGeoFencing"))
    // console.log(getValues("precheckGeoFencing"))
    dispatch(addAcccountReducerAction(geoFencing, { "precheckGeoFencing": getValues("precheckGeoFencing"), "postcheckGeoFencing": getValues("postcheckGeoFencing") }))

    setCloser(true)
    popUpUpdate({})
  }
  const deleteHandler = (e) => {
    console.log("button delete clicked", e)
    dispatch(deleteAcccountReducerAction(deleteAcccount, e))

  }


  const editpopupHandler = (e) => {
    console.log("lkm kljnkjnjkbi;ki=", e)
    console.log("button editpopupHandler clicked", e)
    // dispatch(OneAccountReducerAction(accountApi+"/"+e))

    setModalCentered(true)
    // dispatch(deleteAcccountReducerAction(deleteAcccount,e))




  }
  const updateAccount = () => {
    console.log("object id =", rowId)
    dispatch(updateAcccountReducerAction(addAcccount, rowId, { accountName: newAccount.current.value }))

    setModalCentered(false)
  }
  const addAccount = () => {

    console.log("Add Account=", newAccount)
    // newAccount.current.value=''
    dispatch(addAcccountReducerAction(addAcccount, { accountName: newAccount.current.value }))
    setModalCentered(false)
    newAccount.current.value = ''

    console.log("input field=", newAccount)
    dispatch(AccountReducerAction(accountApi))
    setModalCentered(false)
  }
  const dispatch = useDispatch();
  let accountArry = []


  // let datava =useSelector((state)=>{

  // });

  let data = useSelector((state) => {
    console.log("dfwef=", state)
    let geoFencingdata = state.CommonResponse.geoFencing
    console.log("geoFencing=",state.popUpState.popstatus.status, closer, geoFencingdata.length)

    if(state.popUpState){
      // console.log(state.popUpState.popstatus.status==201,"popUpStatestatus")
      if((state.popUpState.popstatus.status==204 && closer)||(state.popUpState.popstatus.status==200 && closer)||(state.popUpState.popstatus.status==201 && closer)){
        setCloser(false)
        popUpUpdate({})
        console.log("geoFencing","geoFencing")
        dispatch(AccountReducerAction(geoFencing))
        setAddUpdate(checkAddUpdate+1)
      }
    }
    if (geoFencingdata && geoFencingdata.length > 0) {
      setValue("postcheckGeoFencing", geoFencingdata[0].postcheckGeoFencing)
      setValue("precheckGeoFencing", geoFencingdata[0].precheckGeoFencing)
    }

    return { geoFencingdata }
  })

  console.log("data =", data)
  useEffect(() => {
    dispatch(AccountReducerAction(geoFencing))
  }, [checkAddUpdate])
  const columns = [
    {

      name: "sNo",
      label: "S.no",
      options: {
        filter: false,
        sort: false,
        searchable:false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      name: "account",
      label: "Account",
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      name: "edit",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        print:false,
        searchable:false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },

  ];

  const handleChange = (e) => {
    console.log("the current anser= ", e.target.value)
  }

  //    const data = [
  //     { name: "Joe James", company: "In Progress", city: "Yonkers", state: "NY" },
  //     { name: "John Walsh", company: "Completed", city: "Hartford", state: "CT" },
  //     { name: "Bob Herm", company: "Awaited", city: "Tampa", state: "FL" },
  //     { name: "James Houston", company: "NA", city: "Dallas", state: "TX" },
  //    ];

  const options = {
    download: "false",
    filterType: 'none',
    selectableRows: false
  };

  function changedata() {
    console.log(getValues("precheckGeoFencing"))
    console.log(getValues("precheckGeoFencing"))

  }

  return (
    <>
      <div className='row'>

        <div className='col-md-6'>
          <h4>App Geofencing Range : Precheck/ Login Site</h4>

          <div className='row'>

            <div className='col-md-6'>
              <input style={{ height: "32px", width: "32px" }}
                type="text"
                {...register("precheckGeoFencing")}

                className="form-control"
                ID="inputAddress"
                placeholder="Pre Check GeoFencing"
              />
            </div>
            <div className='col-md-6'>
              <input style={{ height: "32px", "width": "100px" }}
                type="button"
                className='btn btn-primary'
                value={"Update"}
                onClick={updateHandler}
              />
            </div>
          </div>


        </div>
        <div className='col-md-6'>
          <h4>App Geofencing Range : Postcheck</h4>

          <div className='row'>

            <div className='col-md-6'>
              <input style={{ height: "32px", "min-width": "60% !important" }}
                type="text"
                {...register("postcheckGeoFencing")}

                className="form-control"
                ID="inputAddress"
                placeholder="Post Check GeoFencing"
              />
            </div>
            <div className='col-md-6'>


              <input style={{ height: "32px", "width": "100px" }}
                type="button"
                className='btn btn-primary'
                value={"Update"}
                onClick={updateHandler}

              />
            </div>
          </div>


        </div>
      </div>
    </>
  )
}

export default ManageGeoFencing