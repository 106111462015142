import React,{useState,useRef, useEffect} from 'react'
import { advancdExportOnecustomDataTableOptionObject, advancdcustomDataTableOptionObject, getMuiThemeCommon } from '../../../../common/variables'
import MUIDataTable from 'mui-datatables'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import swal from 'sweetalert';
import { Row, Card, Col,Dropdown, Button, Modal, Container } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { AccountReducerAction, addAcccountReducerAction, deleteAcccountReducerAction, popUpUpdate, updateAcccountReducerAction } from '../../../../store/actions/createTicketManageFields';
import { timeZoneApi } from '../../../../common/config';
const ManageTimeZone = () => {
    const [modalCentered, setModalCentered] = useState(false);
    const [errors, setErrors] = useState({});
    const [checkAddUpdate,setAddUpdate] =useState(true)
    const [dValue,setDvalue] =useState('')
    const [rowId,setRowId]=useState('')
    const [closer,setCloser] =useState(true)
    const timeZoneName=useRef('')
    const timeZone=useRef('')
    const timeZoneValue=useRef('')
    const dispatch =useDispatch()
    let row=[];
    let rowData=[]
    let zones =useSelector((state)=>{
        let times =state.CommonResponse.timeZones
        if(times && times.length>0 ){
            rowData =times.map((item)=>{
                item["action"] =<><button className="btn btn-primary shadow btn-xs sharp mr-1 editButton" onClick={() =>{ editpopupHandler(item); setDvalue(item);setAddUpdate(false);setRowId(item.uniqueid)}}><i className="fa fa-pencil"></i></button>
                <button onClick={(e)=>deleteHandler(item.uniqueid)} className="btn btn-danger shadow btn-xs sharp deleteButton"><i className="fa fa-trash"></i></button></>
                return item
            })
        }
        console.log("adwefgetegregtfwrgregre",state)
        return {rowData}
    })

    const editpopupHandler =(e)=>{
      console.log("lkm kljnkjnjkbi;ki=",e)
       console.log("button editpopupHandler clicked",e)
       // dispatch(OneAccountReducerAction(accountApi+"/"+e))
       setErrors({})
       setModalCentered(true)
       // dispatch(deleteAcccountReducerAction(deleteAcccount,e))
     }

    const deleteHandler =(e)=>{
      console.log("button delete clicked",e)

      swal({
        title: "Are you sure?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if(willDelete){
          dispatch(deleteAcccountReducerAction(timeZoneApi,e))
          setCloser(true)
        }
        });

        
      
    }
    
    const addTimeZone=()=>{

        let sendData ={}
        let errorr ={}
        console.log(errors,"errors")
        let ctr=0
        if(timeZoneName.current.value==""){
          errorr["timeZoneName"]="Zone Full Name is Manodatry"
          ctr=1
        }else{
          sendData["zone_full_name"]=timeZoneName.current.value
        }
        if(timeZoneValue.current.value==""){
          errorr["timeZoneValue"]="Zone Value is Manodatry"
          ctr=1
        }else{
          sendData["zone_value"]=timeZoneValue.current.value
        }
        if(timeZone.current.value==""){
          errorr["timeZone"]="Zone is Manodatry"
          ctr=1
        }else{
          sendData["zone"]=timeZone.current.value
        }

        console.log(sendData,errorr,errors,"sendDatasendDatasendData")
        if(ctr==1){
          setErrors(errorr)
          return false
        }
      
        

        // console.log("Add Account=",newMarket.current.value," account value=",accountRef.current.value)
            // newAccount.current.value=''

            console.log("the Data is =",sendData)
          dispatch(addAcccountReducerAction(timeZoneApi,sendData))
          setCloser(true)
        //   setModalCentered(false)
        //   newMarket.current.value=''

        //   console.log("input field=",newMarket)
        //   dispatch(GetMarketReducerAction(getMarket))
          // setModalCentered(false)
    }


    
    const updateTimeZone =()=>{
        // let sendData ={
        //     "zone_full_name":timeZoneName.current.value,
        //     "zone":timeZone.current.value,
        //     "zone_value":timeZoneValue.current.value
        // }

        // console.log("object id =",sendData)

        let sendData ={}
        let errorr ={}
        console.log(errors,"errors")
        let ctr=0
        if(timeZoneName.current.value==""){
          errorr["timeZoneName"]="Zone Full Name is Manodatry"
          ctr=1
        }else{
          sendData["zone_full_name"]=timeZoneName.current.value
        }
        if(timeZoneValue.current.value==""){
          errorr["timeZoneValue"]="Zone Value is Manodatry"
          ctr=1
        }else{
          sendData["zone_value"]=timeZoneValue.current.value
        }
        if(timeZone.current.value==""){
          errorr["timeZone"]="Zone is Manodatry"
          ctr=1
        }else{
          sendData["zone"]=timeZone.current.value
        }

        console.log(sendData,errorr,errors,"sendDatasendDatasendData")
        if(ctr==1){
          setErrors(errorr)
          return false
        }
        
        dispatch(updateAcccountReducerAction(timeZoneApi,rowId,sendData))
        // dispatch(updateAcccountReducerAction(addMarketApi,rowId,{marketName:newMarket.current.value,account:accountRef.current.value,timezone :"63aaebbc1a9fe73c55656e23"}))
        
        // setModalCentered(false)
        setCloser(true)
      }
    let columns=[

        {
            name: "zone_full_name",
            label: "Timezone Name",
            options: {
             filter: true,
             setCellHeaderProps: () => ({
               style: {
                   whiteSpace: "wrap",
                   alignItem:"center",
                 background:"#143b64",
                 color:"white",
                 outline:"1.5px solid black"
     
               }
             })
            }
           },
           {
            name: "zone",
            label: "Timezone",
            options: {
             filter: true,
             setCellHeaderProps: () => ({
               style: {
                   whiteSpace: "wrap",
                   alignItem:"center",
                 background:"#143b64",
                 color:"white",
                 outline:"1.5px solid black"
     
               }
             })
            }
           },
           {
            name: "zone_value",
            label: "Value(hrs)",
            options: {
             filter: true,
             setCellHeaderProps: () => ({
               style: {
                   whiteSpace: "wrap",
                   alignItem:"center",
                 background:"#143b64",
                 color:"white",
                 outline:"1.5px solid black"
     
               }
             })
            }
           },
           {
            name: "action",
            label: "Action",
            options: {
              filter: false,
              sort: false,
              print:false,
              searchable:false,
             setCellHeaderProps: () => ({
               style: {
                   whiteSpace: "wrap",
                   alignItem:"center",
                   width:"130px",
                 background:"#143b64",
                 color:"white",
                 outline:"1.5px solid black"
     
               }
             })
            }
           },
    ]

    let data =useSelector((state)=>{
      console.log("dfwef=",state)

      if (state.popUpState) {
        console.log(state.popUpState.popstatus.status,closer,"popUpStatestatus")
        if ((state.popUpState.popstatus.status == 201 && closer)||(state.popUpState.popstatus.status == 200 && closer)) {
          setCloser(false)
          dispatch(popUpUpdate({}))
          dispatch(AccountReducerAction(timeZoneApi))
          setModalCentered(false)
          setDvalue('')
          // newAccount.current.value=''
          // timeZoneName.current.value=""
          // timeZone.current.value=""
          // timeZoneValue.current.value=""
          
        }
      }
    })


    const AddCustomButton = () => (
        <fregment style={{ float: "right", display: "flex", flexDirection: row, justifyContent: "space-between" }}>
         <Button
          variant="primary btn-xs "
          type="button"
          className="btn btn-primary btn-xs mx-2 py-2 my-3"
          onClick={() =>{ setModalCentered(true);setDvalue('');setErrors({});setAddUpdate(true)}}>
            Add Timezone
          </Button>
          <Modal className="fade" show={modalCentered}>
            <Modal.Header>
                {checkAddUpdate?<Modal.Title>Add Timezone</Modal.Title>:<Modal.Title>Update Market</Modal.Title>}
                <Button
                  onClick={() => setModalCentered(false)}
                  variant=""
                  className="close">
                <span>&times;</span>
                </Button>
                </Modal.Header>
                <Modal.Body>
                    <div className='form-group'>
                        <label>TimeZone Name</label>
                        <input type='text' className='form-control' placeholder='Add TimeZone Name' ref={timeZoneName} defaultValue={dValue.zone_full_name}/>
                        {errors && errors.timeZoneName ? <p className="error">{errors.timeZoneName}</p> : ""}
                    </div>
                    <div className='form-group'>
                        <label>TimeZone Full Name</label>
                        <input type='text' className='form-control' placeholder='Add TimeZone Full Name' ref={timeZone} defaultValue={dValue.zone}/>
                        {errors && errors.timeZone ? <p className="error">{errors.timeZone}</p> : ""}
                    </div>
                    <div className='form-group'>
                        <label>Value(hrs)</label>
                        <input type='number' className='form-control' placeholder='Add TimeZone Value' ref={timeZoneValue} defaultValue={dValue.zone_value}/>
                        {errors && errors.timeZoneValue ? <p className="error">{errors.timeZoneValue}</p> : ""}
                    </div>
                  
                </Modal.Body>
                <Modal.Footer>
                  <Button
                  onClick={() => setModalCentered(false)}
                  variant="danger btn-xs"
                >
                  Close
                </Button>
                {checkAddUpdate?
                <Button 
                onClick={()=>{addTimeZone()}}
                 variant="primary btn-xs">Save changes</Button>
                  :<Button
                   onClick={
                    ()=>{updateTimeZone()}
                } 
                variant="primary btn-xs">Update changes</Button>  
              }
                </Modal.Footer>
            </Modal>
              
        </fregment>
      )


      useEffect(()=>{
        dispatch(AccountReducerAction(timeZoneApi))
      },[])
  return (
    <>
     
          

    <ThemeProvider theme={getMuiThemeCommon()}>

  <MUIDataTable
  
     title={"Time Zone"}
      data={zones.rowData}
      columns={columns}
      // options={advancdcustomDataTableOptionObject(AddCustomButton)}
      
      options={advancdExportOnecustomDataTableOptionObject(AddCustomButton,true,"Timezone_","",false,"Timezone",false)}
  />
</ThemeProvider>
</>
  )
}

export default ManageTimeZone