import { height, padding } from "@mui/system";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getdatedatalistnext } from "../../../../common/variables";
import OneSiteMapView from "./OneSiteMapView";


import '../../../../css/borderCustom.css'
import { Modal } from "react-bootstrap";
import CommonModal from "../CommonModal";
import ImageGallery from 'react-image-gallery';
import ReactImagesCarousel from 'react-images-carousel';
import Lightbox, { ImagesListType } from 'react-spring-lightbox';
import { Carousel } from 'react-responsive-carousel'

import {
    ButtonBack, ButtonFirst, ButtonLast, ButtonNext,
    CarouselProvider, DotGroup, ImageWithZoom, Slide, Slider
} from 'pure-react-carousel';
import s from 'pure-react-carousel/dist/react-carousel.es.css';
import { baseURL } from "../../../../common/config";
import ImageCarasoul from "../ImageCarasoul";




const CBN_RIOT_Logs = ({ type, cbn_riot_details }) => {


    console.log(type,cbn_riot_details,"cbn_riot_detailscbn_riot_details")

    const [commonModalState, setcommonModalState] = useState(false);
    const [commonModalSize, setcommonModalSize] = useState(false);
    const [commonModalBody, setcommonModalBody] = useState("");

    const [currentImageIndex, setCurrentIndex] = useState(0);

    const gotoPrevious = () =>
        currentImageIndex > 0 && setCurrentIndex(currentImageIndex - 1);

    // const gotoNext = () =>
    //     currentImageIndex + 1 < images.length &&
    //     setCurrentIndex(currentImageIndex + 1);


    // const images: ImagesListType = [{
    //     src: 'https://timellenberger.com/static/blog-content/dark-mode/win10-dark-mode.jpg',
    //     loading: 'lazy',
    //     alt: 'Windows 10 Dark Mode Setting',
    // },
    // {
    //     src: 'https://timellenberger.com/static/blog-content/dark-mode/macos-dark-mode.png',
    //     loading: 'lazy',
    //     alt: 'macOS Mojave Dark Mode Setting',
    // },
    // {
    //     src: 'https://timellenberger.com/static/blog-content/dark-mode/android-9-dark-mode.jpg',
    //     loading: 'lazy',
    //     alt: 'Android 9.0 Dark Mode Setting',
    // },];
    let siteDetail = useSelector((state) => {
        console.log("the url =", state);
        let site = state.OneCommonResponse.singleSite;
        console.log("the sites is the =", site);
        let pSow = "";
        let pSowArray = site["sowList"] ? site["sowList"].values : [];
        if (pSowArray && pSowArray.length > 0) {
            //  item['technology'].forEach((item)=>{
            //   tec +=item.label +','
            //  })
            console.log("sgrthdhr", pSowArray.length);
            for (let i = 0; i < pSowArray.length; i++) {
                console.log("sgrthdhr", pSowArray[i]);
                pSow += pSowArray[i] + " , ";
            }
            //  item['technology']=tec
        }
        let ttidsData = []
        if (site.troubleTickets && site.troubleTickets.length > 0) {
            ttidsData = site.troubleTickets.map((itemInner) => {
                return itemInner.ttid;
            });
        }
        let sowList = "";
        let sowArray = site["sowDetails"] ? site["sowDetails"].values : [];
        if (sowArray && sowArray.length > 0) {
            //  item['technology'].forEach((item)=>{
            //   tec +=item.label +','
            //  })
            console.log("sgrthdhr", sowArray.length);
            for (let i = 0; i < sowArray.length; i++) {
                console.log("sgrthdhr", sowArray[i]);
                sowList += sowArray[i] + " , ";
            }
            //  item['technology']=tec
        }
        return { site, pSow, sowList, ttidsData };
    });

    const CommonModalFun = (body, type = "md") => {
        setcommonModalState(true);
        // let images=body.map((oneti)=>{
        //     return {
        //         original: baseURL+oneti,
        //         thumbnail: baseURL+oneti,
        //         thumbnailHeight:"10px",
        //         thumbnailWidth:"10px",
        //         originalHeight:"300px",
        //         originalWidth:"300px"
        //     }
        // })
        let images=[]
        const sliderData = body.map((oneti,index) => {
            let divContainer={
                url:baseURL + oneti,
                
            }
           
            images.push(divContainer)
            return  divContainer


        })
        console.log(body, "bodybodybody")

        // setcommonModalBody(<ImageGallery items={images} />);
        if(sliderData && sliderData.length>0){
        setcommonModalBody(<ImageCarasoul slides={sliderData}/>
            
    //    <ImageCarasoul imgArray={sliderData}/>
        //     <Carousel>
        //         {sliderData}
        //         {/* visibleSlides={3}
        //         totalSlides={sliderData.length}
        //         step={3}
        //         dragStep={2}
        //         naturalSlideWidth={400}
        //         naturalSlideHeight={500}
        //         hasMasterSpinner */}
        
        // </Carousel>
                /* <h2>Carousel (With Master Loading Spinner)</h2>
    <p>
      This spinner will go away after all the images have loaded. You might want to use
      Chrome dev tools to throttle the network connection so you can see the spinner.
    </p> */
                // <Slider className={s.headline}>
                //     {
                //         body.map((oneti, inde) => {
                //             return <Slide index={inde}>
                //                 <ImageWithZoom src={baseURL + oneti} />
                //             </Slide>
                //         })}

                // </Slider>


            //     {body.length > 0 ?<>
            //     <ButtonFirst>First</ButtonFirst>
            //     <ButtonBack>Back</ButtonBack>
            //     <ButtonNext>Next</ButtonNext>
            //     <ButtonLast>Last</ButtonLast></> :<p style={{color:"black"}}>File Not Available</p>}
            //     <DotGroup />
            // </CarouselProvider>
        )
    }else{
        setcommonModalBody(<h5>No Image Available</h5>)
    }
    // setcommonModalBody(<Lightbox
    //     isOpen={true}
    //     onPrev={gotoPrevious}
    //     onNext={gotoNext}
    //     images={images}
    //     currentIndex={currentImageIndex}
    //     /* Add your own UI */
    //     // renderHeader={() => (<CustomHeader />)}
    //     // renderFooter={() => (<CustomFooter />)}
    //     // renderPrevButton={() => (<CustomLeftArrowButton />)}
    //     // renderNextButton={() => (<CustomRightArrowButton />)}
    //     // renderImageOverlay={() => (<ImageOverlayComponent >)}

    //     /* Add styling */
    //     // className="cool-class"
    //     // style={{ background: "grey" }}

    //     /* Handle closing */
    //     // onClose={handleClose}

    //     /* Use single or double click to zoom */
    //     // singleClickToZoom

    //     /* react-spring config for open/close animation */
    //     // pageTransitionConfig={{
    //     //   from: { transform: "scale(0.75)", opacity: 0 },
    //     //   enter: { transform: "scale(1)", opacity: 1 },
    //     //   leave: { transform: "scale(0.75)", opacity: 0 },
    //     //   config: { mass: 1, tension: 320, friction: 32 }
    //     // }}
    // />)


    };

    console.log(cbn_riot_details, " -the single sites is");

    return (
        <>
            <>


                <CommonModal
                    modalSize={"md"}
                    setXlShow={setcommonModalState}
                    xlShow={commonModalState}
                    Body={commonModalBody}
                    Header={"Gallery"}
                    Title={"Gallery"}
                    isOverflow={true}
                ></CommonModal>
                {/* <div className="card p-2" > */}

                {cbn_riot_details ? cbn_riot_details.length > 0 ? cbn_riot_details.map((itdata) => {
                    return <><table
                        className=" table-bordered showone ran_cbn_riot"
                        id="table"
                        style={{ height: "10px !important", overflow: "scroll" }}
                    >

                        <thead>
                            <tr style={{ color: "white", background: "#13B497" }}>

                                <th
                                    style={{
                                        color: "white",
                                        color: "white",
                                        fontSize: "11px",
                                        minWidth: "150px"
                                    }}
                                >
                                    Date
                                </th>
                                <th
                                    style={{
                                        color: "white",
                                        color: "white",
                                        fontSize: "11px",
                                        minWidth: "180px"
                                    }}
                                >
                                    Site Id
                                </th>
                                <th
                                    style={{
                                        color: "white",
                                        color: "white",
                                        fontSize: "11px",
                                        minWidth: "180px",
                                        border: "1px solid black !important"
                                    }}
                                >
                                    Status
                                </th>


                                {console.log(itdata,"itdataitdataitdata")}

                                {type == "cbn" ? itdata?.cbn_data.map((oneittdata) => {
                                    return <>
                                        <th style={{
                                            color: "white",
                                            color: "white",
                                            fontSize: "11px",
                                            minWidth: "180px"
                                        }}
                                        >
                                            {oneittdata.tech}
                                        </th>
                                    </>
                                }) : type == "riot"?itdata?.riot_data.map((oneittdata) => {
                                    return <>
                                        <th style={{
                                            color: "white",
                                            color: "white",
                                            fontSize: "11px",
                                            minWidth: "180px"
                                        }}
                                        >
                                            {oneittdata.tech}
                                        </th>
                                    </>
                                }):itdata?.preriot_data.map((oneittdata) => {
                                    return <>
                                        <th style={{
                                            color: "white",
                                            color: "white",
                                            fontSize: "11px",
                                            minWidth: "180px"
                                        }}
                                        >
                                            {oneittdata.tech}
                                        </th>
                                    </>
                                })}

                                {type != "cbn"?
                                <th
                                    style={{
                                        color: "white",
                                        color: "white",
                                        fontSize: "11px",
                                        minWidth: "180px",
                                        border: "1px solid black !important"
                                    }}
                                >
                                    Gallery
                                </th>:""}


                            </tr>
                        </thead>
                        <tbody>
                            {/* {siteDetail.site.sowComments} */}
                            {
                                <>
                                    <tr>
                                        <td className="ng-binding" style={{ height: "10px !important" }} rowSpan={2}>{itdata?.timeStamp}</td>
                                        <td className="ng-binding" style={{ height: "10px !important" }} rowSpan={2}>{itdata?.siteId}</td>
                                        <td className="ng-binding cbn_riot_passed" style={{ height: "10px !important" }}>{type != "cbn" ? type =="riot" ? "RIOT" : "PRE RIOT" : "CBN" }{" Result Passed"}</td>
                                        {type == "cbn" ? itdata?.cbn_data.map((oneittdata) => {
                                            return <>
                                                <td style={{ height: "10px !important" }} className="ng-binding">
                                                    {oneittdata.findd}
                                                </td>
                                            </>
                                        }) : type=="riot"?itdata?.riot_data.map((oneittdata) => {
                                            return <>
                                                <td style={{ height: "10px !important" }} className="ng-binding">
                                                    {oneittdata.findd}
                                                </td>
                                            </>
                                        }):itdata?.preriot_data.map((oneittdata) => {
                                            return <>
                                                <td style={{ height: "10px !important" }} className="ng-binding">
                                                    {oneittdata.findd}
                                                </td>
                                            </>
                                        })}
                                        {type != "cbn"?
                                        <td className="ng-binding" style={{ height: "10px !important" }} rowSpan={2}><button className="btn btn-primary" onClick={() => CommonModalFun(itdata.web_file)}>Images</button></td>
                                        :""}
                                    </tr>
                                    <tr>
                                        {/* <td className="ng-binding">{itdata.timeStamp}</td>  
                                                <td className="ng-binding">{itdata.siteId}</td> */}
                                        <td className="ng-binding cbn_riot_failed">{type == "cbn" ? "CBN" : "RIOT"}{" Result Failed"}</td>

                                        {type == "cbn" ? itdata?.cbn_data.map((oneittdata) => {
                                            return <>
                                                <td style={{ height: "10px !important" }} className="ng-binding">
                                                    {oneittdata.miss}
                                                </td>
                                            </>
                                        }) : type=="riot"?itdata?.riot_data.map((oneittdata) => {
                                            return <>
                                                <td style={{ height: "10px !important" }} className="ng-binding">
                                                    {oneittdata.miss}
                                                </td>
                                            </>
                                        }):itdata?.preriot_data.map((oneittdata) => {
                                            return <>
                                                <td style={{ height: "10px !important" }} className="ng-binding">
                                                    {oneittdata.miss}
                                                </td>
                                            </>
                                        })}
                                    </tr>
                                </>
                                // <td className="ng-binding">{itdata.crStatus}</td>
                            }

                        </tbody>
                    </table>
                        <br />
                    </>
                }):
            //     <table
            //     className=" table-bordered showone ran_cbn_riot"
            //     id="table"
            //     style={{ height: "10px !important", overflow: "scroll" }}
            // >

            //     <thead> <tr style={{ color: "white", background: "#13B497" }}>

            //     <th
            //         style={{
            //             color: "white",
            //             color: "white",
            //             fontSize: "11px",
            //             minWidth: "150px"
            //         }}
            //     >
            //         Date
            //     </th>
            //     <th
            //         style={{
            //             color: "white",
            //             color: "white",
            //             fontSize: "11px",
            //             minWidth: "180px"
            //         }}
            //     >
            //         Site Id
            //     </th>
            //     <th
            //         style={{
            //             color: "white",
            //             color: "white",
            //             fontSize: "11px",
            //             minWidth: "180px",
            //             border: "1px solid black !important"
            //         }}
            //     >
            //         Status
            //     </th></tr></thead></table> 
                
                 
                <h1 style={{"position": "absolute",top: "48%",left: "40%","font-size": "20px"}}>No {type == "cbn"?"CBN":"RIOT"} Historical Data</h1>
                 : <span className="customloader">Loading</span>}
                {/* </div> */}
            </>
        </>
    );
};

export default CBN_RIOT_Logs;
