import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AccountReducerAction, LCLTTCOLReducerAction, LCLTTReducerAction, addAcccountReducerAction, getFilterDropDown } from '../../../../store/actions/createTicketManageFields'
import { listtroubleTicketApi, mopDataApi, mopFilters, ranDashboard, ranfilters, ttfilters, userTableData } from '../../../../common/config'
import { MopDatabaseAction } from '../../../../store/actions/dashboardActions'
import { MultiSelect } from "react-multi-select-component";
const ViewColumnTT = ({setFilterBadge,setcolFilterCall, setfullUrL, setcommonModalState, featureExport}) => {
    const dispatch =useDispatch()
    
    let [techaff, settechaff] = useState([]);
    
    let [timer, oneTimer] = useState(true);
    let allColumns=[]
    let selectedColumns=[]
    const submitData = ()=>{
        let selData=[]
        console.log(selectedColumns,"selectedColumnsselectedColumns")
        let data={
            "data":selectedColumns,
            "typeData":"tt"
        }

        dispatch(addAcccountReducerAction(userTableData,data))
        setcommonModalState(false)
        setcolFilterCall(true)
    }


    const colChange = (e,val) =>{
        console.log("colChange",e.target.checked,val)

        // let cols=[]

        // let indes=listColumns.findIndex(valee=>{
        //     if(valee.name==changedColumn){
        //     return valee.label
        //     }
            
        // })
        

        // console.log(cols,listColumns[indes].label,"onViewColumnsChange")
        if(e.target.checked){
            dispatch(LCLTTCOLReducerAction(val,"add"))
        }else{
            dispatch(LCLTTCOLReducerAction(val,"remove"))
        }
    }
    
    const count =useSelector((state)=>{
        
        allColumns=state.lclFilterDashboard.ttalldata
        console.log(state.lclFilterDashboard.ttexport,"state.lclFilterDashboard")
        selectedColumns=state.lclFilterDashboard.ttexport
        if(timer){
            // allColumns.map(oneitm=>{
            //     return {
            //         label:oneitm,
            //         value:oneitm
            //     }
            // })
            
            settechaff(allColumns)

            oneTimer(false)
        }
        console.log(state.lclFilterDashboard.ttalldata,"viewColumnTT")

        return {
            allColumns,selectedColumns
        }
    })
    

    console.log(count,"countcountcountcountcountcount")


    useEffect(()=>{
            
        // dispatch(getFilterDropDown([]))
        // dispatch(AccountReducerAction(ttfilters))

    },[])
  return (
    <>
        <div className='row'>
                    {/* <MultiSelect
                      className="text-dark"
                      options={techaff}
                    //   onChange={}
                      value={[]}
                    /> */}
            {techaff.map((itm)=>{
                return (<div className={'col-md-6'}>
                    <div className='row'>
                        <div className={'col-md-1'}>
                            <input value={itm} checked={count.selectedColumns.indexOf(itm)!=-1} onChange={(e)=>{
                                colChange(e,itm)
                            }} type={'checkbox'}/>
                        </div>
                        <div className={'col-md-8'}>
                            {itm}
                        </div>
                        
                    </div>
                </div>)
            })}
            
            {/* {count.dropdownSkel}
            <div
            {console.log(count.dropdownSkel,"count.dropdownSkel")} */}

        </div>

        <button onClick={(e)=>{
            submitData()
        }} className={'btn btn-xs btn-primary mx-2 py-2 btn btn-primary'}>Submit</button>
    </>
  )
}

export default ViewColumnTT