// import React from 'react';
// import Chart from 'react-apexcharts';

// const StackedColumnChart = () => {
//   const options = {
//       chart: {
//         stacked: true,
//       },
//       xaxis: {
//         categories: ['Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5'],
//       },
//       plotOptions: {
//         bar: {
//           horizontal: false,
//         },
//       },
//       dataLabels: {
//         enabled: false,
//       },
//       legend: {
//         position: 'top',
//       },
//       grid: {
//         show: false,
//       },
//       barWidth: 50, // Specify the width in pixels
//   };

//   const series = [
//     {
//       name: 'Not Punctual',
//       data: [4, 0, 0, 0, 0, 14, 0, 0, 1, 0, 0, 1, 0, 6, 2, 0, 7, 31, 1, 0, 0, 12, 0, 1, 3, 0, 8, 5, 0, 0, 2, 0, 7, 2, 2, 0, 0, 8, 0, 0, 1, 0, 0, 2, 5, 47, 0, 3, 21, 0, 6, 0, 1, 2, 15, 1, 6, 0, 12, 6, 0, 1, 1, 0, 9, 1, 2, 0, 3, 4, 1, 1, 11, 0, 9, 6, 0, 0, 2, 6, 14, 7, 7, 1, 0, 0, 5, 8, 2, 0, 0, 2, 1, 0, 4, 1, 0, 4, 34, 0, 8, 1, 2, 0, 1, 0, 0, 3, 0, 1, 0, 0, 0, 1, 34, 4, 0, 0, 7, 0, 1, 12, 0, 0, 4, 0, 65, 1, 0, 15, 0, 2, 0, 1, 14, 8, 0, 0, 0, 11, 0, 2, 5, 17, 0, 0, 0, 0, 9, 18, 0, 1, 18, 2, 0, 1, 2, 0, 24, 0, 0, 0, 0, 5, 2, 2, 4, 14, 1, 0, 0, 0, 0, 0, 0, 1, 3, 3, 32, 0, 10, 1, 5, 1, 0, 0, 0, 0, 4, 2, 0, 4, 2, 1, 3, 1, 7, 0, 0, 4, 0, 2, 0, 0, 0, 0, 0, 1, 0, 0, 6, 2, 4, 0, 21, 0, 0, 0, 0, 14, 0, 0, 1, 11, 2, 0, 0, 16, 0, 9, 5, 4, 35, 29, 0, 1, 0, 5, 7, 1, 37, 0, 0, 1, 2, 0, 1, 2, 0, 2, 4, 0, 1, 4, 46, 4, 0, 25, 7, 0, 11, 6, 0, 1, 1, 17, 0, 1, 22, 0, 8, 0, 1, 3, 0, 1, 0, 1, 0, 0, 2, 0, 7, 2, 25, 0, 0, 2, 0, 3, 0, 0, 0, 8, 12, 1, 0, 4, 0, 1, 0, 0, 0, 7, 8, 1, 0, 1, 0, 2, 0, 0, 16, 1, 3, 2, 2, 0, 42, 20, 0, 0, 8, 1, 32, 8, 2, 15, 0, 0, 1, 0, 0, 0, 14, 0, 3, 4, 0, 0, 1, 0, 0, 1, 41, 1, 0, 0, 2, 21, 0, 0, 5, 0, 0, 1, 0, 0, 0, 0, 0],
//     },
//     {
//       name: 'Punctual',
//       data: [10, 1, 2, 1, 7, 1, 1, 108, 21, 3, 5, 48, 5, 13, 7, 1, 228, 224, 3, 1, 11, 82, 6, 3, 8, 3, 153, 24, 41, 55, 24, 1, 31, 0, 31, 5, 54, 137, 14, 4, 2, 19, 1, 5, 12, 216, 2, 59, 185, 2, 38, 4, 0, 7, 36, 12, 61, 28, 48, 32, 4, 19, 10, 10, 52, 27, 17, 1, 24, 34, 40, 0, 38, 2, 31, 49, 2, 83, 6, 46, 67, 34, 115, 73, 4, 6, 63, 109, 23, 10, 13, 1, 3, 1, 25, 8, 3, 11, 167, 17, 57, 2, 6, 1, 2, 3, 3, 9, 3, 1, 1, 5, 3, 12, 217, 82, 3, 1, 31, 8, 1, 24, 2, 22, 2, 2, 310, 3, 9, 98, 5, 4, 8, 36, 38, 12, 2, 4, 1, 27, 19, 2, 37, 251, 1, 1, 99, 4, 133, 70, 4, 1, 135, 4, 91, 3, 7, 13, 176, 8, 1, 2, 2, 21, 5, 0, 5, 118, 1, 21, 21, 2, 18, 6, 3, 18, 19, 20, 213, 2, 34, 11, 14, 60, 8, 2, 1, 12, 12, 8, 10, 3, 5, 1, 23, 10, 154, 3, 1, 14, 2, 86, 1, 18, 34, 1, 1, 3, 1, 57, 33, 33, 22, 46, 153, 21, 1, 1, 27, 26, 2, 7, 1, 63, 44, 15, 1, 23, 25, 55, 8, 11, 110, 54, 4, 20, 10, 9, 80, 2, 92, 12, 121, 8, 20, 1, 5, 0, 4, 4, 11, 23, 14, 59, 157, 35, 2, 54, 9, 10, 12, 29, 2, 14, 9, 160, 1, 13, 189, 8, 66, 1, 1, 2, 30, 1, 1, 0, 43, 1, 33, 1, 24, 5, 195, 10, 1, 31, 19, 79, 1, 5, 1, 59, 166, 36, 23, 40, 4, 1, 4, 1, 9, 19, 14, 4, 18, 5, 22, 34, 106, 1, 105, 3, 9, 10, 0, 41, 125, 78, 3, 10, 137, 15, 142, 28, 99, 37, 34, 2, 6, 7, 23, 6, 35, 2, 43, 34, 47, 3, 20, 12, 1, 14, 133, 23, 20, 1, 6, 83, 2, 5, 74, 9, 1, 5, 3, 12, 2, 1, 2],
//     }
//   ];

//   return (
//     <div id="chart">
//       <Chart
//         options={options}
//         series={series}
//         type="bar"
//         height={400}
//       />
//     </div>
//   );
// };

// export default StackedColumnChart;

// import React from 'react';
// import Chart from 'react-apexcharts';

// class StackedBarChart extends React.Component {
//   constructor(props) {
//     super(props);

//     // Assuming you have 200 data points
//     const numDataPoints = 200;

//     // Minimum width of each bar in pixels
//     const minBarWidth = 50;

//     // Calculating the width of each bar to fit all data points
//     const totalWidth = numDataPoints * minBarWidth;

//     this.state = {
//       options: {
//         chart: {
//           stacked: true,
//           zoom: {
//             enabled: true, // Enable zooming
//           },
//           toolbar: {
//             autoSelected: 'zoom', // Automatically select zoom tool
//           },
//           pan: {
//             enabled: true, // Enable panning
//           },
//         },
//         xaxis: {
//           categories: Array.from({ length: numDataPoints }, (_, i) => `Category ${i + 1}`),
//         },
//         plotOptions: {
//           bar: {
//             horizontal: false,
//             columnWidth: 20, // Set the columnWidth to 20px
//           }
//         },
//         dataLabels: {
//           enabled: false,
//         },
//         legend: {
//           position: 'top',
//         },
//         grid: {
//           show: false,
//         },
//         barWidth: Math.max(minBarWidth, Math.floor(totalWidth / numDataPoints)),
//       },
//       series: [
//         {
//           name: 'Series 1',
//           data: Array.from({ length: numDataPoints }, () => Math.floor(Math.random() * 100)),
//         },
//       ],
//     };
//   }

//   render() {
//     return (
//       <Chart
//         options={this.state.options}
//         series={this.state.series}
//         type="bar"
//         height={350}
//         width="100%"
//       />
//     );
//   }
// }

// export default ;



import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useDispatch, useSelector } from 'react-redux';
import { VendorScoreCardApi } from '../../../../common/config';
import { VendorScoreCardReducerAction } from '../../../../store/actions/createTicketManageFields';
import StackedBarChart from './StackedColumnChart';
import MultiSeriesChart from './MultiSeriesChart';
import ChartTable from './ChartTable';
import BarLineTwoAxis from './BarLineTwoAxis';
import GLineChart from './GLineChart';
import ApexChartDemo from './ApexChartDemo';
import PanningChart from './PanningChart';
import MyNewChart from './MyNewChart';
import RechartLineChart from './RechartLineChart';
import ExcelExport from './ExcelExport';
// import ChartWithZoomScroll from './ChartWithZoomScroll';

const ChartGroup = ({ filtering }) => {

    let vendorScoreGraphList = useSelector((state) => {
        return state.vendorScoreCard.funVendorScoreCard
    })



    return <>
        {
            vendorScoreGraphList.map((mainItm) => {
                return <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">

                    {/* {
                    mainItm.chartType == "bar"&&<StackedBarChart colors={mainItm.data.color} yAxis={mainItm.yAxis} chartStack={mainItm.chartStack} toollabel={mainItm.toollabel} chartType={mainItm.chartType} minBarWidth={mainItm.minBarWidth} numDataPoints={mainItm.numDataPoints} nextUrl={mainItm.nextUrl} backUrl={mainItm.backUrl} data={mainItm.data} />
                } */}


                    {/* <ChartWithZoomScroll/> */}
                    {/* <ApexChartDemo/> */}
                    {/* {<PanningChart/>} */}
                    {/* {
                        <StackedBarChart colors={mainItm.data.color} yAxis={mainItm.yAxis} chartStack={mainItm.chartStack} toollabel={mainItm.toollabel} chartType={mainItm.chartType} minBarWidth={mainItm.minBarWidth} numDataPoints={mainItm.numDataPoints} nextUrl={mainItm.nextUrl} backUrl={mainItm.backUrl} data={mainItm.data} />
                    } */}

                    {/* {
                        
                    } */}
                    {/* {alert(mainItm.toollabel)} */}
                    {

                        <RechartLineChart extraFilter={mainItm.extraFilter} filtering={filtering} toollabeling={mainItm.toollabeling} table={mainItm.table} emsg={mainItm.emsg} toollabel={mainItm.toollabel} xAxis={mainItm.xAxis} yAxis={mainItm.yAxis} lisData={mainItm.lisData} colrenameList={mainItm.colrenameList} dic_col={mainItm.dic_col} final_dta={mainItm.final_dta} nextUrl={mainItm.nextUrl} minBarWidth={mainItm.minBarWidth} numDataPoints={mainItm.numDataPoints} backUrl={mainItm.backUrl} />

                    }
                    {/* <MyNewChart colrenameList={mainItm.colrenameList} dic_col={mainItm.dic_col} final_dta={mainItm.final_dta} nextUrl={mainItm.nextUrl} minBarWidth={mainItm.minBarWidth} numDataPoints={mainItm.numDataPoints} backUrl={mainItm.backUrl} data={mainItm.data} /> */}
                    {/* {
                    mainItm.chartType == "BarLineTwoAxis"&&<BarLineTwoAxis colors={mainItm.data.color} yAxis={mainItm.yAxis} chartStack={mainItm.chartStack} toollabel={mainItm.toollabel} chartType={mainItm.chartType} minBarWidth={mainItm.minBarWidth} numDataPoints={mainItm.numDataPoints} nextUrl={mainItm.nextUrl} backUrl={mainItm.backUrl} data={mainItm.data} />
                } */}


                    {/* <GLineChart /> */}

                    <div style={{ width: "96%", maxHeight: "200px", margin: "20px", overflow: "scroll" }}>
                        <ChartTable header={mainItm.table.header} data={mainItm.table.data} />
                    </div>
                    {/* <MultiSeriesChart yAxis={mainItm.yAxis} toollabel={mainItm.toollabel} chartType={mainItm.chartType} minBarWidth={mainItm.minBarWidth} numDataPoints={mainItm.numDataPoints} nextUrl={mainItm.nextUrl} backUrl={mainItm.backUrl} data={mainItm.data} /> */}
                </div>
            })
        }
    </>
};

export default ChartGroup;
