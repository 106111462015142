import React from "react";

const FilterData = () => {
  return (
    <svg 
    
    width="24"
    height="24"
    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M10 18H14V16H10V18ZM3 6V8H21V6H3ZM6 13H18V11H6V13Z"></path>
    </svg>
  );
};

export default FilterData;
