import React, { Fragment, useState, useEffect, memo } from "react";
import "../../../../css/customStyle.css";
import { SplitButton, ButtonGroup, Dropdown, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import { useForm } from "react-hook-form";
import { NetworkDatabaseAction } from "../../../../store/actions/dashboardActions";
import "../../../../css/formError.css";
import moment from "moment"
import { addCrNumber, allTechnologyApi, listSiteId, listUserApi_two, managEngineer, managTMOEngineer, networkOneSiteDatabaseprojectId, OneSiteDatabase, OneSiteDatabaseProjectCode, projectCode, ranAddSupport, ranOneSiteDatabaseprojectId, ranOneSiteProjectCode, singleProjectApi, updateranticket } from "../../../../common/config";
import { AccountReducerAction, addUserAction, popUpUpdate, TierListReducerAction, UserListReducerAction } from "../../../../store/actions/createTicketManageFields";

import { FetchSingleRecordAction } from "../../../../store/actions/createTicketManageFields";
import { default as ReactSelect, components } from "react-select";
import { getValue } from "@mui/system";

import { Tab, Tabs } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import DatePicker from "react-datepicker";
import { es } from "date-fns/locale";


const AdvmultipleTechnologyForm = ({ setXlShow,formAll,seleCname,setValue, register,getValues, dynmaicForm, editable, selTecch, labelLing, array }) => {


    const dispatch = useDispatch();
    // if(selTecch.length>0){
    //     setXlShow(false)
    // }
    let [listSelectTech, setlistSelectTech] = useState()
    let [newRadioAdd, setnewRadioAdd] = useState(0)
    let [OneTImer, setOneTImer] = useState(true)


    console.log(setXlShow,formAll,seleCname, register, setValue, dynmaicForm, editable, selTecch, labelLing, array, "AdvmultipleTechnologyForm")




    let newtechListtechList = (id, itm, itmlabl) => {

    }


    let allDataForm={}

    let usedSelTech = []
    let uniqueselTecch = []
    selTecch.map((ittm) => {

        console.log(ittm,"ittmittmittmittmittm")
        if (usedSelTech.indexOf(ittm.label) == -1) {
            usedSelTech.push(ittm.label)
            uniqueselTecch.push(ittm)
        }
    })


    let listALlSlect = [{
        label: "Alpha Sector",
        value: "alphaSector"
    }, {
        label: "Beta Sector",
        value: "betaSector"
    }, {
        label: "Gamma Sector",
        value: "gammaSector"
    }, {
        label: "Delta Sector",
        value: "deltaSector"
    }, {
        label: "Epsilon Sector",
        value: "epsilonSector"
    }, {
        label: "Zeta Sector",
        value: "zetaSector"
    }]


    console.log(selTecch,seleCname, "techListtechListselTecchselTecch")
    
    const setDataList = (id, itm, itmlabl,value) => {
        setValue("sectorNested." +  id + "." + itmlabl,value)
        console.log(id+"."+itmlabl,id, itm, itmlabl,value,"setDataListsetDataListsetDataList")

    }
    const techListtechList = (id, itm, itmlabl,seleCnameL) => {

        console.log(id, id, itm, itmlabl,seleCnameL, "nnnntechListtechList")
        setOneTImer(false)
        


        let oneSelect = listALlSlect.map((oneS) => {

            if (editable) {

                setValue("techie", itmlabl)
                return <>

                    <div className="form-group col-md-3">
                        <label for={itm + "_SC_" + oneS.value}>{itmlabl} {oneS.label}</label>
                        {console.log(seleCnameL+oneS.value,"snester")}
                        <select
                            style={{ height: "32px" }}
                            className="form-control"
                            id={itm + "_SC_" + oneS.value}
                            {
                            ...register(seleCnameL+oneS.value)

                            }
                        >

                            <option selected disabled value="">Select</option>

                            {labelLing.map((onee) => {
                                return <option value={onee}>{onee}</option>
                            })}
                        </select>
                    </div>
                </>
            } else {
                return <>
                    <div className="form-group col-md-3">
                        <label for={itm + "_SC_" + oneS.value}>{itmlabl} {oneS.label}</label>
                        {console.log(seleCnameL+"sectorNested." + itmlabl + "." + oneS.value,"snester")}
                        <select
                            style={{ height: "32px" }}
                            className="form-control"
                            id={itm + "_SC_" + oneS.value}
                            {
                            ...register(seleCnameL+"sectorNested." + itmlabl + "." + oneS.value)

                            }
                        >

                            <option value="">Select</option>

                            {labelLing.map((onee) => {
                                return <option value={onee}>{onee}</option>
                            })}
                        </select>
                    </div>
                </>
            }

        })


        setlistSelectTech(oneSelect)


    }

    // const {
    //     register,
    //     handleSubmit,
    //     setValue,
    //     getValues,
    //     formState: { errors },
    // } = useForm({});




    return (
        <Fragment>
            {/* <PageTItle activeMenu='validation' motherMenu='Form' /> */}
            <div className="techList">
                {uniqueselTecch.map((itm,index) => {
                    console.log(itm,editable, "editableitmitm")

                    return <>

                        {editable && itm.value!='' && itm.label!="" ? OneTImer ? techListtechList(itm.value, itm.value, itm.label, seleCname) : "" : ""}
                        <input name={`${seleCname}global`} value={itm.value} checked={newRadioAdd==index} onChange={((e) => {
                            setnewRadioAdd(index)
                        })} type="radio">

                        </input>
                        <label>{itm.label}</label></>
                })}
            </div>

            <div className="form-group col-md-12">
                {/* <div className="form-row"> */}

                    {console.log(uniqueselTecch,listALlSlect,"listALlSlectlistALlSlect")}
                    {uniqueselTecch.map((itm,index) => {
                        
                        return <>
                        {console.log(newRadioAdd,index,newRadioAdd===index,"newRadioAdd==index")}
                        <div className={newRadioAdd===index?"form-row":"form-row showiingnone"}>
                            {
                            listALlSlect.map((oneS) => {
                                return <>
                                    <div className="form-group col-md-3">
                                        <label for={itm.label + "_SC_" + oneS.value}>{ itm.label} {oneS.label}</label>

                                        {console.log("sectorNested." +  itm.label + "." + oneS.value,"snester")}
                                        <select
                                            style={{ height: "32px" }}
                                            className="form-control"
                                            
                                            // value={getValues("sectorNested." +  itm.label + "." + oneS.value)}
                                            id={ itm.label + "_SC_" + oneS.value}
                                            {
                                            ...register("sectorNested." +  itm.label + "." + oneS.value,{
                                                onChange:(e)=>{
                                                    setDataList(itm.label,"_SC_",oneS.value,e.target.value)
                                                }
                                            })

                                            }
                                        >

                                            <option value="">Select</option>

                                            {labelLing.map((onee) => {
                                                return <option value={onee}>{onee}</option>
                                            })}
                                        </select>
                                    </div>
                                </>
                            })
                            }
                        </div>
                        </>
                        }
                    )}
                {/* </div> */}
            </div>
            {/* {array.map((itm) => {
                return <>
                </>
            })} */}

        </Fragment>
    );
};

export default memo(AdvmultipleTechnologyForm);
