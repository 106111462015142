import React, { useState } from 'react'
import MUIDataTable from "mui-datatables";
import moment from "moment";
import { useEffect } from 'react';
import { Row, Card, Col, Dropdown, Button, Modal, Container } from "react-bootstrap";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { accountApi, addUser, deleteUser, listRole_twoApi, getMarket, crewCompanyApi, listUserApi_two, vendorApi, addAcccount, listOneUser, updateUser, CsvUpload, dynamic_dashboard, export_magenta_dashboard } from '../../../common/config';
import { AccountReducerAction, OneUserReducerAction, addUserAction, deleteAcccountReducerAction, GetMarketReducerAction, getCrewCompanyAction, updateAcccountReducerAction, RoleReducerAction, UserListReducerAction, GetCSVReducerAction, magentaGetCSVReducerAction, ExportXLSXDownloadReducerAction } from '../../../store/actions/createTicketManageFields';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { black } from 'material-ui/styles/colors';
import { PDFUploadActionreturn } from '../../../store/actions/dashboardActions';
import { google_map_api, center, dbdatetodate, customDataTableOptionObject, getMuiThemeCommon } from "../../../common/variables";

import TablePagination from '@mui/material/TablePagination';
import CommonBulkUploader from '../Custom/CommonBulkUploader';
import { more_info } from '../../../common/variables';
import CommonModal from "../Custom/CommonModal";

const Magentabulkfile = (props) => {
    const [closer, setCloser] = useState(false);
    const [popissue, setpopissue] = useState(false);
    const [commonModalState, setcommonModalState] = useState(false);
    const [commonModalSize, setcommonModalSize] = useState(false);
    const [commonModalBody, setcommonModalBody] = useState("");
    const [commonModalTitle, setcommonModalTitle] = useState("");
    const [commonModalHeader, setcommonModalHeader] = useState("");

    console.log(props, "props")

    const url = window.location.href.split("/")
    let dynamicValue = url.pop()
    let pStatusArry = []
    let vStatusArry = []
    let mStatusArry = []
    let cStatusArry = []

    // let editTrue=0
    const options = {
        download: false,


        onFilterChange: (changedColumn, filterList) => {
            console.log("inside filter=", changedColumn, filterList,)
        },
        selectableRows: false,
        onDownload: false,
        filterType: "dropdown",
        responsive: "scrollMaxHeight",
        rowsPerPage: 10,
    };

    let accountArry = []
    let userArry = []

    // let columns = [
    //     {
    //         "name": "siteType",
    //         "selector": "siteType",
    //         "sortable": true,
    //         "label": "S:Site Type",
    //         options: {
    //           filter: true,
    //           sort: false,

    //           setCellHeaderProps: () => ({
    //             style: {
    //              padding : "3px 10px",
    //               fontSize : 13,
    //               fontWeight : 500,
    //              textAlign : "left",
    //                 alignItem:"center",
    //               background:"#143b64",
    //               color:"white"

    //             }
    //           })
    //          }
    //         },

    //     {
    //         "name": "siteClass",
    //         "selector": "siteClass",
    //         "sortable": true,
    //         "label": "S:Site Class",
    //         options: {
    //             filter: true,
    //             setCellProps: () => ({
    //               style: {
    //                 innerWidth:"2px",
    //                 height: "1px",
    //                 whiteSpace: "wrap",
    //                 position: "sticky",
    //                 left: "89px",
    //                 background: "White",
    //                 zIndex: 99
    //               }
    //             }),
    //             setCellHeaderProps: () => ({
    //               style: {
    //                 minHeight: "1px",
    //                 whiteSpace: "wrap",
    //                 position: "sticky",
    //                 left: "89px",
    //                 background:"#143b64",
    //                 // background: "#2393b9",
    //                 zIndex: 101,
    //                 color:"white"
    //               }
    //             })
    //         }
    //     },{
    //         "name": "sectorCoverageTypeSummary",
    //         "selector": "sectorCoverageTypeSummary",
    //         "sortable": true,
    //         "label": "S:Sector Coverage Type Summary (New, Provision, On-Air Only)",
    //         options: {
    //             filter: false,
    //             setCellProps: () => ({
    //               style: {
    //                 whiteSpace: "wrap",
    //                 position: "sticky",
    //                 left: "169px",
    //                 background: "White",
    //                 zIndex: 99
    //               }
    //             }),
    //             setCellHeaderProps: () => ({
    //               style: {
    //                 whiteSpace: "wrap",
    //                 position: "sticky",
    //                 left: "169px",
    //                 background:"#143b64",
    //                 // background: "#2393b9",
    //                 zIndex: 101,
    //                 color:"white",
    //                 "min-width" : "190px",
    //                 "max-width" : "200px"
    //               }
    //             })
    //           }
    //     },{
    //         "name": "siteName",
    //         "selector": "siteName",
    //         "sortable": true,
    //         "label": "S:Site Name",
    //         options: {
    //           filter: true,
    //           sort: false,

    //           setCellHeaderProps: () => ({
    //             style: {
    //              padding : "3px 10px",
    //               fontSize : 13,
    //               fontWeight : 500,
    //              textAlign : "left",
    //                 alignItem:"center",
    //               background:"#143b64",
    //               color:"white"

    //             }
    //           })
    //          }

    //     },
    //     {
    //         "name": "address",
    //         "selector": "address",
    //         "sortable": true,
    //         "label": "S:Address", options: {
    //           filter: true,
    //           sort: false,

    //           setCellHeaderProps: () => ({
    //             style: {
    //              padding : "3px 10px",
    //               fontSize : 13,
    //               fontWeight : 500,
    //              textAlign : "left",
    //                 alignItem:"center",
    //               background:"#143b64",
    //               color:"white"

    //             }
    //           })
    //          }
    //         },
    //         {
    //         "name": "address2",
    //         "selector": "address2",
    //         "sortable": true,
    //         "label": "S:Address 2",
    //         options: {
    //           filter: true,
    //           sort: false,

    //           setCellHeaderProps: () => ({
    //             style: {
    //              padding : "3px 10px",
    //               fontSize : 13,
    //               fontWeight : 500,
    //              textAlign : "left",
    //                 alignItem:"center",
    //               background:"#143b64",
    //               color:"white"

    //             }
    //           })
    //          }
    //         },
    //     {
    //         "name": "city",
    //         "selector": "city",
    //         "sortable": true,
    //         "label": "S:City",
    //         options: {
    //           filter: true,
    //           sort: false,

    //           setCellHeaderProps: () => ({
    //             style: {
    //              padding : "3px 10px",
    //               fontSize : 13,
    //               fontWeight : 500,
    //              textAlign : "left",
    //                 alignItem:"center",
    //               background:"#143b64",
    //               color:"white"

    //             }
    //           })
    //          }
    //         },
    //     {
    //         "name": "state",
    //         "selector": "state",
    //         "sortable": true,
    //         "label": "S:State",
    //         options: {
    //           filter: true,
    //           sort: false,

    //           setCellHeaderProps: () => ({
    //             style: {
    //              padding : "3px 10px",
    //               fontSize : 13,
    //               fontWeight : 500,
    //              textAlign : "left",
    //                 alignItem:"center",
    //               background:"#143b64",
    //               color:"white"

    //             }
    //           })
    //          }
    //         },
    //     {
    //         "name": "zip",
    //         "selector": "zip",
    //         "sortable": true,
    //         "label": "S:Zip",
    //         options: {
    //           filter: true,
    //           sort: false,

    //           setCellHeaderProps: () => ({
    //             style: {
    //              padding : "3px 10px",
    //               fontSize : 13,
    //               fontWeight : 500,
    //              textAlign : "left",
    //                 alignItem:"center",
    //               background:"#143b64",
    //               color:"white"

    //             }
    //           })
    //          }
    //         },
    //     {
    //         "name": "country",
    //         "selector": "country",
    //         "sortable": true,
    //         options: {
    //           filter: true,
    //           sort: false,

    //           setCellHeaderProps: () => ({
    //             style: {
    //              padding : "3px 10px",
    //               fontSize : 13,
    //               fontWeight : 500,
    //              textAlign : "left",
    //                 alignItem:"center",
    //               background:"#143b64",
    //               color:"white"

    //             }
    //           })
    //          }
    //         },]


    let columns = [{
        'name': 'region',
        'selector': 'projectCode',
        'sortable': true,
        'label': 'Rgn:Region',
        options: {
            filter: true,
            sort: false,

            setCellHeaderProps: () => ({
                style: {
                    padding: "3px 10px",
                    fontSize: 11,
                    fontWeight: 500,
                    textAlign: "left",
                    background: "#143b64",
                    color: "white",
                    'min-width': "100px"

                }
            })
        },
    }, {
        'name': 'market',
        'selector': 'projectCode',
        'sortable': true,
        'label': 'M:Market',
        options: {
            filter: true,
            sort: false,

            setCellHeaderProps: () => ({
                style: {
                    padding: "3px 10px",
                    fontSize: 11,
                    fontWeight: 500,
                    textAlign: "left",
                    background: "#143b64",
                    color: "white",
                    'min-width': "100px"

                }
            })
        },
    }, {
        'name': 'siteId',
        'selector': 'projectCode',
        'sortable': true,
        'label': 'S:Site Code',
        options: {
            filter: true,
            sort: false,

            setCellHeaderProps: () => ({
                style: {
                    padding: "3px 10px",
                    fontSize: 11,
                    fontWeight: 500,
                    textAlign: "left",
                    background: "#143b64",
                    color: "white",
                    'min-width': "110px"

                }
            })
        },
    }, {
        'name': 'buildStatus',
        'selector': 'projectCode',
        'sortable': true,
        'label': 'S:Build Status',
        options: {
            filter: true,
            sort: false,

            setCellHeaderProps: () => ({
                style: {
                    padding: "3px 10px",
                    fontSize: 11,
                    fontWeight: 500,
                    textAlign: "left",
                    background: "#143b64",
                    color: "white",
                    'min-width': "110px"

                }
            })
        },
    }, {
        'name': 'siteStatus',
        'selector': 'projectCode',
        'sortable': true,
        'label': 'S:Site Status',
        options: {
            filter: true,
            sort: false,

            setCellHeaderProps: () => ({
                style: {
                    padding: "3px 10px",
                    fontSize: 11,
                    fontWeight: 500,
                    textAlign: "left",
                    background: "#143b64",
                    color: "white",
                    'min-width': "110px"

                }
            })
        },
    }, {
        'name': 'latitude',
        'selector': 'projectCode',
        'sortable': true,
        'label': 'S:Site Latitude',
        options: {
            filter: true,
            sort: false,

            setCellHeaderProps: () => ({
                style: {
                    padding: "3px 10px",
                    fontSize: 11,
                    fontWeight: 500,
                    textAlign: "left",
                    background: "#143b64",
                    color: "white"

                }
            })
        },
    }, {
        'name': 'longitute',
        'selector': 'projectCode',
        'sortable': true,
        'label': 'S:Site Longitude',
        options: {
            filter: true,
            sort: false,

            setCellHeaderProps: () => ({
                style: {
                    padding: "3px 10px",
                    fontSize: 11,
                    fontWeight: 500,
                    textAlign: "left",
                    background: "#143b64",
                    color: "white"

                }
            })
        },
    }, {
        'name': 'siteType',
        'selector': 'projectCode',
        'sortable': true,
        'label': 'S:Site Type',
        options: {
            filter: true,
            sort: false,

            setCellHeaderProps: () => ({
                style: {
                    padding: "3px 10px",
                    fontSize: 11,
                    fontWeight: 500,
                    textAlign: "left",
                    background: "#143b64",
                    color: "white",
                    'min-width': "110px"

                }
            })
        },
    }, {
        'name': 'siteClass',
        'selector': 'projectCode',
        'sortable': true,
        'label': 'S:Site Class',
        options: {
            filter: true,
            sort: false,

            setCellHeaderProps: () => ({
                style: {
                    padding: "3px 10px",
                    fontSize: 11,
                    fontWeight: 500,
                    textAlign: "left",
                    background: "#143b64",
                    color: "white",
                    'min-width': "110px"

                }
            })
        },
    }, {
        'name': 'sectorCoverageTypeSummary',
        'selector': 'projectCode',
        'sortable': true,
        'label': 'S:Sector Coverage Type Summary (New, Provision, On-Air Only)',
        options: {
            filter: true,
            sort: false,

            setCellHeaderProps: () => ({
                style: {
                    padding: "3px 10px",
                    fontSize: 11,
                    'min-width': '200px',
                    fontWeight: 500,
                    textAlign: "left",
                    background: "#143b64",
                    color: "white",
                    "min-width": "300px"

                }
            })
        },
    }, {
        'name': 'siteName',
        'selector': 'projectCode',
        'sortable': true,
        'label': 'S:Site Name',
        options: {
            filter: true,
            sort: false,

            setCellHeaderProps: () => ({
                style: {
                    padding: "3px 10px",
                    fontSize: 11,
                    fontWeight: 500,
                    textAlign: "left",
                    background: "#143b64",
                    color: "white",
                    'min-width': "180px"

                }
            })
        },
    }, {
        'name': 'address',
        'selector': 'projectCode',
        'sortable': true,
        'label': 'S:Address',
        options: {
            filter: true,
            sort: false,

            setCellHeaderProps: () => ({
                style: {
                    padding: "3px 10px",
                    fontSize: 11,
                    fontWeight: 500,
                    textAlign: "left",
                    background: "#143b64",
                    color: "white",
                    'min-width': "100px"

                }
            })
        },
    }, {
        'name': 'address2',
        'selector': 'projectCode',
        'sortable': true,
        'label': 'S:Address 2',
        options: {
            filter: true,
            sort: false,

            setCellHeaderProps: () => ({
                style: {
                    padding: "3px 10px",
                    fontSize: 11,
                    fontWeight: 500,
                    textAlign: "left",
                    background: "#143b64",
                    color: "white",
                    'min-width': "100px"

                }
            })
        },
    },
    {
        'name': 'city',
        'selector': 'projectCode',
        'sortable': true,
        'label': 'S:City',
        options: {
            filter: true,
            sort: false,

            setCellHeaderProps: () => ({
                style: {
                    padding: "3px 10px",
                    fontSize: 11,
                    fontWeight: 500,
                    textAlign: "left",
                    background: "#143b64",
                    color: "white",
                    "min-width": "120px"

                }
            })
        },
    },
    {
        'name': 'state',
        'selector': 'projectCode',
        'sortable': true,
        'label': 'S:State',
        options: {
            filter: true,
            sort: false,

            setCellHeaderProps: () => ({
                style: {
                    padding: "3px 10px",
                    fontSize: 11,
                    fontWeight: 500,
                    textAlign: "left",
                    background: "#143b64",
                    color: "white",
                    "min-width": "100px"

                }
            })
        },
    }, 
    {
        'name': 'zip',
        'selector': 'projectCode',
        'sortable': true,
        'label': 'S:Zip',
        options: {
            filter: true,
            sort: false,

            setCellHeaderProps: () => ({
                style: {
                    padding: "3px 10px",
                    fontSize: 11,
                    fontWeight: 500,
                    textAlign: "left",
                    background: "#143b64",
                    color: "white",
                    "min-width": "70px"

                }
            })
        },
    }, 
    {
        'name': 'county',
        'selector': 'projectCode',
        'sortable': true,
        'label': 'S:County',
        options: {
            filter: true,
            sort: false,

            setCellHeaderProps: () => ({
                style: {
                    padding: "3px 10px",
                    fontSize: 11,
                    fontWeight: 500,
                    textAlign: "left",
                    background: "#143b64",
                    color: "white",
                    "min-width": "80px"

                }
            })
        },
    },
     {
        'name': 'country',
        'selector': 'projectCode',
        'sortable': true,
        'label': 'S:Country',
        options: {
            filter: true,
            sort: false,

            setCellHeaderProps: () => ({
                style: {
                    padding: "3px 10px",
                    fontSize: 11,
                    fontWeight: 500,
                    textAlign: "left",
                    background: "#143b64",
                    color: "white",
                    "min-width": "80px"

                }
            })
        },
    },
    {
        'name': 'crossStreet',
        'selector': 'projectCode',
        'sortable': true,
        'label': 'S:Cross Street',
        options: {
            filter: true,
            sort: false,

            setCellHeaderProps: () => ({
                style: {
                    padding: "3px 10px",
                    fontSize: 11,
                    fontWeight: 500,
                    textAlign: "left",
                    background: "#143b64",
                    color: "white",
                    'min-width': "100px"

                }
            })
        },
    }, {
        'name': 'structureHeight_feet',
        'selector': 'projectCode',
        'sortable': true,
        'label': 'S:Structure Height (<i>in feet</i>)',
        options: {
            filter: true,
            sort: false,

            setCellHeaderProps: () => ({
                style: {
                    padding: "3px 10px",
                    fontSize: 11,
                    fontWeight: 500,
                    'min-width': '200px',
                    textAlign: "left",
                    background: "#143b64",
                    color: "white",
                    "min-width": "193px"

                }
            })
        },
    }, {
        'name': 'RAD_Center_feet',
        'selector': 'projectCode',
        'sortable': true,
        'label': 'S:RAD Center (AGL) (<i>in feet</i>)',
        options: {
            filter: true,
            sort: false,

            setCellHeaderProps: () => ({
                style: {
                    padding: "3px 10px",
                    fontSize: 11,
                    fontWeight: 500,
                    'min-width': '200px',
                    textAlign: "left",
                    background: "#143b64",
                    color: "white"

                }
            })
        },
    }, {
        'name': 'siteAccess',
        'selector': 'projectCode',
        'sortable': true,
        'label': 'S:24x7 Site Access?',
        options: {
            filter: true,
            sort: false,

            setCellHeaderProps: () => ({
                style: {
                    padding: "3px 10px",
                    fontSize: 11,
                    fontWeight: 500,
                    textAlign: "left",
                    background: "#143b64",
                    color: "white",
                    "min-width": "120px"

                }
            })
        },
    }, {
        'name': 'siteAccessDetail',
        // 'selector': 'projectCode',
        'sortable': true,
        'label': 'S:Site Access Details',
        options: {
            filter: true,
            sort: false,

            setCellHeaderProps: () => ({
                style: {
                    padding: "3px 10px",
                    fontSize: 11,
                    fontWeight: 500,
                    textAlign: "left",
                    'min-width': '500px',
                    background: "#143b64",
                    color: "white"

                }
            })
        },
    }, {
        'name': 'siteAccessDetailsUpdatedby',
        'selector': 'projectCode',
        'sortable': true,
        'label': 'S:Site Access Details Updated by',
        options: {
            filter: true,
            sort: false,

            setCellHeaderProps: () => ({
                style: {
                    padding: "3px 10px",
                    fontSize: 11,
                    fontWeight: 500,
                    'min-width': '200px',
                    textAlign: "left",
                    background: "#143b64",
                    color: "white",
                    "min-width": "140px"

                }
            })
        },
    }, 
   ]


    let row = [];

    const handleChange = (e) => {
        console.log("the current anser= ", e.target.value)
    }

    const AddCustomButton = () => (
        <fregment style={{ float: "right", display: "flex", flexDirection: row, justifyContent: "space-between" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                {dynamicValue == "magenta" ? <h3></h3> : ""}
                <h3 >{dynamicValue == "inflight" ? <h3>Inflight Upload</h3> : ""} </h3>
                <div>
                    <Button className="btn-xs mt-3 mr-2 float-right mb-2" onClick={() => { filehandleShow(); reset({}); addformtype("Upload ") }}>Upload {dynamicValue} File</Button>
                </div>
            </div>





            <Modal show={fileshow} onHide={filehandleClose} size="md">
                <Modal.Header closeButton>
                    <Modal.Title >{formtype} Crew</Modal.Title>
                </Modal.Header>
                {/* <Modal.Body> */}
                <form onSubmit={(e) => submitHandler(e)} encType="multipart/form-data" className="mx-2">
                    <div className="input-group mb-3 fileSection">


                        <div>{dynamicValue} :</div>
                        <br />
                        <div>
                            <label class="custom-file-upload btn btn-outline-primary btn-xs col-md-12">
                                <input
                                    type="file"
                                    className="form-control btn btn-primary btn-xs"
                                    id="inputGroupFile04"
                                    aria-describedby="inputGroupFileAddon04"
                                    aria-label="Upload"
                                    name="file"
                                    multiple
                                    onChange={uploadCsvFile}
                                />
                                <i class="fa fa-cloud-upload"></i> Choose File
                            </label>
                            {/* <label>Remarks</label>
                  <input type='text' className="form-control" value={remarks} onChange={(e)=>{setRemarks(e.target.value)}}></input> */}

                        </div>

                        <button
                            className="btn btn-outline-primary btn-xs col-xs"
                            type="submit"
                            id="inputGroupFileAddon04"
                        >
                            Submit
                        </button>
                    </div>

                </form>

                {/* <div className="form-row mt-2 p-2">
            <div>
              Crew File :
              <label class="custom-file-upload btn btn-outline-primary btn-xs col-md-12">
                <input style={{ height: "32px" }}
                  type="file"
                  className="form-control btn btn-primary"
                  id="inputGroupFile04"
                  aria-describedby="inputGroupFileAddon04"
                  aria-label="Upload"
                  name="file"
                  onChange={uploadCsvFile}

                />
                <i class="fa fa-cloud-upload"></i> Choose Crew File
              </label>
            </div>
          </div> */}


                {/* </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary btn-xs" onClick={filehandleClose}>
            Close
          </Button>
          <Button variant="primary btn-xs" onClick={submitHandler}>
            Upload File
          </Button>
        </Modal.Footer> */}
            </Modal>




        </fregment>
    )

    const { register, setValue, handleSubmit, reset, formState: { errors } } = useForm({});
    const [show, setShow] = useState(false);
    const [siteFile, uploadedFile] = useState([]);
    const [fileshow, setfileShow] = useState(false);
    const [page, setPage] = useState(2);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [senter, setsenter] = useState(2);

    const handleChangePage = (abc) => {
        setPage(page + 1);
        console.log("handleChangePage",)
        if (abc.target.dataset.testid == "KeyboardArrowRightIcon") {
            setsenter(1)
            dispatch(magentaGetCSVReducerAction("/" + dynamicValue + dynamic_dashboard + "?page=" + page * 10))
            setPage(page + 1);
        } else if (abc.target.dataset.testid == "KeyboardArrowLeftIcon") {
            setPage(page - 1);
        }
    };

    const handleChangeRowsPerPage = () => {

        console.log("handleChangeRowsPerPage")
        setRowsPerPage(11);
        setPage(0);
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        console.log("form==", "form")
        let newform = new FormData();
        newform.append("uploadFile", siteFile);
        newform.append("uploadfor", dynamicValue)
        console.log("form==", newform)
        console.log("resp", dispatch(PDFUploadActionreturn(CsvUpload, newform, "usercrew")))
        setShow(false);
    };


    const uploadCsvFile = (e) => {
        // let files=[]
        console.log(e.target.files, "files")
        uploadedFile(e.target.files[0])

    };



    const filehandleClose = () => {
        reset({})
        setfileShow(false);
    }
    const filehandleShow = () => {
        reset({})
        setfileShow(true)
    }

    // const uploadCsvFile = (e) => {
    //   let files=[]

    //   console.log(e.target.files[0],"ON_CHANGE_FILES:", e.target.files.length);

    //   for(let i=0;i<e.target.files.length;i++){
    //     files.push(e.target.files[i])
    //   }
    //   console.log(...files)
    //   uploadedFile(files)

    // };



    const [modalCentered, setModalCentered] = useState(false);
    const [newAccount, setAccount] = useState({})
    const [editTrue, seteditTrue] = useState(0)
    const [updateTrue, setupdateTrue] = useState(0)
    const [formtype, addformtype] = useState('')





    let oneaccount = {}


    // setAccount(oneaccount)
    //  console.log(oneaccount,"oneaccount")
    let getMuiTheme = () =>
        createTheme({
            components: {
                MUIDataTable: {
                    styleOverrides: {
                        root: {
                            //   backgroundColor: '#red',
                            height: 700
                        },
                        paper: {
                            boxShadow: 'none',
                        },
                    },
                },
                MUIDataTableBodyCell: {
                    styleOverrides: {
                        root: {
                            maxHeight: 45,
                            fontSize: 11,
                            overflowY: "auto",
                            padding: "0px 2px !important",
                            margin: 0,
                            textAlign: 'center'
                        },
                    }
                },
                // MuiToolbar: {
                //   styleOverrides: {
                //     root: {
                //       backgroundColor: '#f00',

                //     },
                //   },
                // },
                MuiTableCell: {
                    styleOverrides: {
                        head: {
                            maxHeight: 20,
                            fontSize: 11,
                            margin: 0,
                            paddingTop: 0,
                            paddingBottom: 0
                        },
                    },
                },
                MUIDataTableSelectCell: {
                    styleOverrides: {
                        headerCell: {
                            backgroundColor: 'blue',
                        },
                    },
                },
                MuiTableFooter: {
                    styleOverrides: {
                        root: {
                            '& .MuiToolbar-root': {
                                backgroundColor: 'white',
                            },
                        },
                    },
                },
            },
        });


    console.log()
    const updateHandler = (e) => {
        console.log("button update clicked", e)

        dispatch(addUserAction(addAcccount, e))

    }
    const deleteHandler = (e) => {
        console.log("button delete clicked", e)
        dispatch(deleteAcccountReducerAction(deleteUser, e, "crew"))
    }


    const editpopupHandler = (e) => {
        seteditTrue(1)
        reset({})
        console.log("button editpopupHandler clicked", e)
        dispatch(OneUserReducerAction(listOneUser + "/" + e.uniqueid))
    }

    const CommonModalFun = (body, title, header, type = "md") => {
        setcommonModalState(true);
        setcommonModalSize(type);
        // popUpUpdate({})

        // dispatch(clearFetchSingleRecordAction())
        setCloser(true);
        setpopissue(true);
        setcommonModalTitle(title);
        setcommonModalBody(body);
        setcommonModalHeader(header);
    };


    const onSubmit = async (data) => {
        console.log(data, "dassdaas")

        if (data.uniqueid != undefined) {
            dispatch(updateAcccountReducerAction(updateUser, data.uniqueid, data, "crew"))
        }
        else {
            dispatch(addUserAction(addUser, data, "crew"))
        }

    }

    const dispatch = useDispatch();
    let keyArray = []
    let labelMapping = []


    let currentUrl = window.location.href
    let data = useSelector((state) => {
        let csvResponse = state.csvResponse
        let totalCount = state.countState.magentaCount
        if (csvResponse) {
            if (csvResponse.magenta) {
                userArry = csvResponse.magenta.map((item) => {

                    item["siteAccessDetail"] = (
                        <p
                            className={"text-black my-0 modalHoverClass"}
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Click Here For More Details"
                            onClick={() =>
                                CommonModalFun(
                                    <p
                                        style={{
                                            border: "2px solid black",
                                            color: "black",
                                            padding: 2,
                                        }}
                                        className="CommonModal"
                                    >
                                        {item["siteAccessDetails"]}
                                    </p>,
                                    "Comment",
                                    "site Access Detail"
                                )
                            }
                        >
                            {more_info(item["siteAccessDetails"])}
                        </p>
                    );

                    return item
                })
            }
        }
        return { userArry, totalCount }
    })

    console.log(data, "data")

    const exportAllData = () => {

    
        dispatch(ExportXLSXDownloadReducerAction(export_magenta_dashboard,"MAGENTA_" + moment(new Date()).format("MM-DD-YYYY")))
        // FileSaver.saveAs(baseURL+"/export/ran", "RAN_Support_" + moment(new Date()).format("MM-DD-YYYY"))
        // history.push(baseURL+"/export/ran")
      }


    const AddCusTButton = () => (
        <>
            
            <Button onClick={exportAllData} className="btn btn-primary btn-xs mx-2 py-2 my-3 d-flex justify-content-center align-items-center">
                Export
            </Button>
            <CommonBulkUploader styles={{ marginBottom: "1rem" }} typeForm={"Magenta Upload File"} shortCode={"magenta"} classes={"btn btn-primary btn-xs mx-2 py-2 my-3 d-flex justify-content-center align-items-center"} afterLoad={"/magenta" + dynamic_dashboard} />
        </>
    )

    useEffect(() => {

        dispatch(magentaGetCSVReducerAction("/magenta" + dynamic_dashboard))

    }, [])




    //    const data = [
    //     { name: "Joe James", company: "In Progress", city: "Yonkers", state: "NY" },
    //     { name: "John Walsh", company: "Completed", city: "Hartford", state: "CT" },
    //     { name: "Bob Herm", company: "Awaited", city: "Tampa", state: "FL" },
    //     { name: "James Houston", company: "NA", city: "Dallas", state: "TX" },
    //    ];



    let fileName = "Mergenta_" + moment(new Date()).format("MM-DD-YYYY")

    return (
        <>

            <CommonModal
                modalSize={commonModalSize}
                setXlShow={setcommonModalState}
                xlShow={commonModalState}
                Body={commonModalBody}
                Header={commonModalHeader}
                Title={commonModalTitle}
            ></CommonModal>

            <div className='mui-t-h'
                style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}
            >


                <ThemeProvider theme={getMuiThemeCommon()}>

                    <MUIDataTable
                        // title={"Magenta Upload"}
                        data={data.userArry}
                        columns={columns}
                        options={customDataTableOptionObject(AddCusTButton, false, "Magenta_", "", "", false, "magenta", "", data.userArry.length, data.totalCount, true)}
                    />

                </ThemeProvider>
            </div>
        </>
    )

}

export default Magentabulkfile