import React, { useEffect, useRef, useState } from 'react'
import { Tab, Tabs } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch,useSelector } from 'react-redux';

import { addAcccount, getAllDropDown, getAllDropDownList } from '../../../common/config';
import { AccountReducerAction, addAcccountReducerAction, popUpUpdate, updateAcccountReducerAction } from '../../../store/actions/createTicketManageFields';
const SingleFieldModal = (props) => {

  const { register, setValue, handleSubmit, getValues, watch, reset, formState: { errors } } = useForm({})
  const dispatch =useDispatch();
  // const newValRef=useRef(props.oldData)
  
  const [closer, setCloser] = useState(false);
  const [checkkk, setcheckkk] = useState(0);
  const [firstLoad, setfirstLoad] = useState(true);
  const [lastType, setLastType] = useState("");

  let oneValue=""
  console.log(props,"propsprops")

  let data =useSelector((state)=>{
    if ((state.popUpState.popstatus.status == 201 && closer)||(state.popUpState.popstatus.status == 200 && closer)) {
      setCloser(false)
      dispatch(popUpUpdate({}))
      dispatch(AccountReducerAction(getAllDropDown + "/" + props.fromName))
      props.setModalState(false)
      setfirstLoad(true)
      console.log(state.popUpState.popstatus.status,"props.typeprops.type")
    }
  })

  console.log(props.type,getValues("value"),"props.typeprops.type")

  // if(props.type==="Edit"){
  //   oneValue=props.oldData.optionValue
  // }else{
  //   oneValue=""
  // }
  // if(props.oldData && props.oldData!=getValues("value")){
  //   setValue("value",props.oldData?props.oldData:"") 
  // }
  const onSubmit=async (data)=>{
    console.log("the data to submit is =",data)
    
    data["dropDownId"]=props.parentuniqueId
    console.log(data,"data")

    if(props.type=="Add"){
      dispatch(addAcccountReducerAction(getAllDropDownList,data))
    }
    if(props.type=="Edit"){
      dispatch(updateAcccountReducerAction(getAllDropDownList,props.oldData.uniqueId,data))
    }

    setCloser(true)
    dispatch(popUpUpdate({}))
    
    // setValue("value",'')



    



    

    // propsprops
    
    // reset({})
  }

  console.log(lastType!=props.type,props.type,props.oldData.optionValue,getValues("value"),props.type=="Edit",props.oldData.optionValue!=getValues("value"),props.type=="Edit"&&props.oldData.optionValue!=getValues("value"),"lastTypelastType")
  if(lastType!=props.type){
    if(props.type=="Edit"||props.type=="Add"){
      if(firstLoad){
        if(props.type=="Edit" ){
          setValue("value",props.oldData.optionValue)
        }
        if(props.type=="Add"){
          setValue("value","")
        }
        setfirstLoad(false)
        setLastType(props.type)
      }
    }
  }
    console.log(props.type=="Edit" && props.oldData.optionValue!=getValues("value"),"propsoldDataoptionValue")
    if(props.type=="Edit" && props.oldData.optionValue!=getValues("value")){
      setValue("value",props.oldData.optionValue)
      // setfirstLoad(true)
      console.log(props.oldData.optionValue,"propsoldDataoptionValue")
      setLastType(props.type)
    }
  
    if(props.type=="Add" && ""!=getValues("value")){
      setValue("value","")
      // setfirstLoad(true)
      setLastType(props.type)
    }
  
  
  // 
  console.log(oneValue,"oneValue",props.oldData.optionValue,getValues("value"),props.type,"oneValueoneValueoneValuetesttt")
useEffect(()=>{
    // reset({})
      
    console.log(oneValue,props.type,"oneValueoneValueoneValuedsdadsadad")
    // setValue("value",'')
})
  
    return (
    <Modal
      size="xm"
      className="fade"
      show={props.modalState}
    //   onHide={() => {
    //     props.setModalState(false);
    //     // reset({})
    //     setfirstLoad(true)
    //     // setValue("value",'')
    // }}
      aria-labelledby="example-modal-sizes-title-xl"
    >
      <Modal.Header className={"CommonModalHeader"} onClick={() => {
        props.setModalState(false)
        setfirstLoad(true)
      }} closeButton 
      // style={{ backgroundColor: "#143b64" }}
      >
        <Modal.Title id="example-modal-sizes-title-xl " className='text-white'>
          <h4 className={'CommonModalHeaderHeading'}>{props.type} {props.fromName}</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "white" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group col-md-12">
                          <label for="exampleFormControlSelect1">
                            {props.label}
                          </label>
                          <input style={{ height: "40px" }}
                            type="text"
                            className="form-control"
                            id="input "
                            // defaultValue={EngineerOpen}
                            // placeholder="Enter Technology Name"
                            {
                            ...register("value", {
                              required: "Please enter " +props.label
                              })
                            }
                            // defaultValue={props.oldData.optionValue?props.oldData.optionValue:""}
                          />
                          {errors.value && (
                            <p className="error">{errors.value.message}</p>
                          )}
                        </div>
                        <button type="submit" className="btn btn-primary btn-xs mt-3">
                        Submit
                  </button>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default SingleFieldModal