
import React, { Component, useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { AccountReducerAction, getBarChartData } from "../../../../store/actions/createTicketManageFields";
import { secondNest, thirdNest } from "../../../../common/config";
import { Button } from "@mui/material";
import BackSign from "../../../../icons/Svg/BackSign";
import swal from "sweetalert";

const ScoreBarChart = ({ internalfilter, globalPieType, count, setCount, settwoCount, setfivercount, setThreeCount, noOfBarChart, filter }) => {


  // alert(filter,"filter")
const keyRef = useRef()
  const dispatch = useDispatch()
  let [customWidth, setCustomWidth] = useState("")
  let [typeChart, settypeChart] = useState("bar")

  let customDataArray = useRef()

  let [completeData, setCompleteData] = useState({})
  let nxtURL = useRef()


  const key = useSelector(state => state.popUpState.internallyFilter)
  const actualData = useSelector((state) => {
     

    console.log("countering state", count)


    console.log("state", state)
    let barData = state.OneCommonResponse.barData

    let internallyFilter = state.popUpState.internallyFilter
    console.log("fkmebhfvekr", barData)
    if (barData && barData.graphData && count) {
      setCompleteData(barData)


      tempVar = barData
      customDataArray.current = barData.XaxisLabel
      nxtURL.current = barData.nextUrl
      console.log(";kiwjaghkjdbewrf=", barData.chartType)


      if (["DowntimeRate"].indexOf(globalPieType) != -1) {
        settypeChart("line")
      } else {
        settypeChart("bar")
      }
      console.log(";kiwjaghkjdbewrf=", typeChart)


      setData((prev) => {
        return {
          ...prev,
          options: {
            ...prev.options,
            chart: {
              // chart: {

              stacked: barData.stacked,
            },

            xaxis: {
              categories: barData.XaxisLabel,
              scrollbar: {
                enabled: true,
              }
            },

            events: {
              dataPointSelection: (event, chartContext, config) => handleSliceClick(event, chartContext, config, internallyFilter),
            },
            yaxis: barData.yaxis ? barData.yaxis : [],
            colors: barData.colorCodeArray || [],
            plotOptions: {
              bar: {
                columnWidth: barData.width,
                // horizontal: barData.horizontal?barData.horizontal:false,
                horizontal: false,
                dataLabels: {
                  position: 'top', // Place labels at the top of the bars
                  style: {
                    // Set the desired label color
                  },
                },

              },

            },


            stroke: {
              width: barData.strokewidth ? barData.strokewidth : .5,
              curve: 'smooth',
              // colors: ["#78716c"]
            },

            markers: {
              size: 2,
            },

            color: barData.strokecolor ? barData.strokecolor : [],


            dataLabels: {
              enabled: false,
              position: 'top', // Place labels at the top of the bars
              style: {
                colors: ['#52525b'], // Set the desired label text color
              },
            },
            tooltip: {
              // tooltip: {
              custom: function ({ series, seriesIndex, dataPointIndex, w }) {

                console.log(barData, barData.toolTip, noOfBarChart, dataPointIndex, "noOfBarChart")
                // const clickedSliceData = noOfBarChart?.toolTip[dataPointIndex];

                return '<div class="custom-tooltip-new">' +
                  '<span>' + w.globals.initialSeries[seriesIndex].data[dataPointIndex] + '</span>' +
                  '</div>';
              },
            },
          },
          // series: barData.graphData.sort((a, b) => b?._id?.localeCompare(a._id)),
          series: barData.graphData
        }
      })
      setCustomWidth(barData.chartWidth)


      console.log("getDatagetDatagetData", getData)
      // if(barData.chartWidth && barData.chartWidth.includes("rem")){
      //   if(window.innerWidth-1000>parseInt(barData.chartWidth.substring(0,barData.chartWidth.length-3))){
      //     setCustomWidth("100%")
      //     console.log("cbhjwbdhjlhbdguweklff",(window.innerWidth-1000))
      //     console.log("cbhjwbdhjlhbdguweklff",parseInt(barData.chartWidth.substring(0,barData.chartWidth.length-3)))
      //   }
      //   else{
      //     setCustomWidth(barData.chartWidth)
      //   }
      // }
      // else{
      //   setCustomWidth(barData.chartWidth)
      // }
      // setCustomWidth(barData.chartWidth?barData.chartWidth:"100%")
      setCount(false)
      return { tempVar, barData, internallyFilter }
      console.log(";cewbfvgcekirvgfrgv=", getData)

    }




    return ({ internallyFilter })
  })

  function handleSliceClick(event, chartContext, config, actualDataing) {

    // console.log("cjkabsgckvlkaesvhucfeiurk",config.w.config,config.dataPointIndex)
    // console.log("cjkabsgckvlkaesvhucfeiurk",customDataArray.current)
    console.log("Lalit key => ", keyRef.current)

    if (nxtURL.current != "") {
      dispatch(getBarChartData({}))
      setCount(true)
      settwoCount(true)
      setfivercount(true)
      setThreeCount(true)

      console.log("filterfilterfilter", filter)

      const clickedSliceIndex = config.dataPointIndex;
      console.log("wfdefwffe", config)
      console.log("wfdefwffe", chartContext)
      console.log("wfdefwffe", event)
      let clickedSliceData = customDataArray.current[clickedSliceIndex];
      // if(clickCount.current<1){
      //   urlArray.push(clickedSliceData.trim()+"/")
      // }
      // if(clickCount.current==2){
      //   urlArray.push(clickedSliceData.trim()+"/")
      // }
      // console.log('Clicked slice index:',urlArray);
      // console.log('Custom data:', clickCount.current);
      // let sendUrl=secondNest
      // if(clickCount.current>1 && clickCount.current<4){
      //   sendUrl=thirdNest + urlArray[0]
      //   console.log("dqwdwe")
      //   // sendUrl=thirdNest + urlArray.join(",").replace(",","").substring(0, urlArray.join(",").replace(",","").length - 1)  +"/"
      // }
      // if(clickCount.current>3 && clickCount.current<7){
      //   // sendUrl=thirdNest + urlArray[0]
      //   console.log("dqwdwe")  
      //   sendUrl=thirdNest + urlArray.join(",").replace(",","").substring(0, urlArray.join(",").replace(",","").length - 1)  +"/"
      // }
      // if(6>clickCount.current>3){
      //   console.log("dklnwkjdbhlwebfhflwe")
      //   sendUrl=thirdNest + urlArray.join(",").replace(",","").substring(0, urlArray.join(",").replace(",","").length - 1)  +"/"
      // }
      // else if(clickCount.current==2){
      //   sendUrl=thirdNest
      // }


      let lclinternalFilter = filter == "" ? "?internalFilter=" : "&internalFilter="
      console.log(actualDataing, "actualDatainternalfilter", "302SBC lalit", actualDataing?.internallyFilter)
      // alert(actualDataing.internallyFilter + "302SBC")
      dispatch(AccountReducerAction(nxtURL.current + clickedSliceData + filter + lclinternalFilter + keyRef.current))
    } else {
      swal({
        title: "Oops",
        text: "This is the Last Stage Of Drilldown",
        icon: "info",
        button: "Ok",
      })
    }
    // clickCount.current +=1


  }
  let tempVar = {}
  console.log("thhjbkawefber", noOfBarChart)





  let [getData, setData] = useState({
    options: {
      chart: {
        // chart: {
        zoom: {
          enabled: true,
          type: 'x',
          resetIcon: {
            offsetX: -10,
            offsetY: 0,
            fillColor: '#fff',
            strokeColor: '#37474F'
          },
          selection: {
            background: '#90CAF9',
            border: '#0D47A1'
          },
        },
        stacked: true,
        // },
        id: "basic-bar",
        events: {
          dataPointSelection: (event, chartContext, config) => handleSliceClick(event, chartContext, config, actualData),
        },
      },
      xaxis: {
        categories: [],
        scrollbar: {
          enabled: true,
        }
      },

      plotOptions: {
        bar: {
          vertical: true,
          columnWidth: '8px',

        }
      },
      stroke: {
        width: 1,
        colors: ["black"]
      },
      dataLabels: {

        position: 'top', // Place labels at the top of the bars
        style: {
          colors: ['black'], // Set the desired label text color
        },
      },
      title: {
        text: "",
        align: 'center',
        // margin: 2,
        offsetX: -50,
        offsetY: 30,
        style: {
          fontSize: '2rem',
          letterSpacing: 3,
          // fontWeight:  'bold',
          // fontFamily:  undefined,
          color: '#263238'
        },
      },
      tooltip: {
        // tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const clickedSliceData = noOfBarChart?.toolTip[dataPointIndex];

          return '<div class="custom-tooltip">' +
            '<span>' + clickedSliceData + '</span>' +
            '</div>';
        },
      },
    },
    series: [],
    theme: {
      mode: 'light',
    }

  })


  useEffect(() => {
    console.log("key => ", key)
    keyRef.current = key
  }, [key])


  console.log("getDatagetDatagetData", actualData)


  const backtowork = () => {

    dispatch(getBarChartData({}))
    setCount(true)
    settwoCount(true)
    setfivercount(true)
    setThreeCount(true)
    console.log("backtowork", completeData)

    let lclinternalFilter = filter == "" ? "?internalFilter=" : "&internalFilter="
    // alert(actualData.internallyFilter + "250SBC")
    dispatch(AccountReducerAction(completeData.backUrl + filter + lclinternalFilter + actualData.internallyFilter))
  };




  console.log(actualData, "datadatadata")




  return (
    <div>


      {console.log(internalfilter, "internalfilter")}

      {console.log(completeData, 'completeData')}
      {completeData && completeData.backUrl && completeData.backUrl != "" ? <>
        <p className="text-black" style={{ width: "100%", cursor: "pointer" }} onClick={() => {
          backtowork()
        }}><BackSign /> BACK</p>
      </> : ""}


      {/* {completeData && completeData.backUrl && completeData.backUrl != "" ? <>
        <p className="text-black" style={{  width:"100%",cursor:"pointer"}} onClick={() => {
          backtowork()
        }}>BACK</p>
      </> : ""} */}


      {completeData && completeData.filteration ? <>
        <p className="text-black" style={{ width: "100%", cursor: "pointer" }}>
          {completeData.filteration}
        </p>
      </> : ""}

      <div style={{ width: "100%", overflowX: "scroll" }}>
        {console.log("kcnwelfkjnwenfd", window.innerWidth)}
        <center style={{ width: customWidth, overflowX: "scroll" }} className='stacked-chart shadow-lg p-3 mb-5 bg-white rounded "scrollable-navigation-chart-container"'>
          {/* width:data.graphData.chartWidth?data.graphData.chartWidth:"100%" */}
          <ReactApexChart
            options={getData.options}
            series={getData.series}
            type={"bar"}
            height={"400rem"}

          />



          {completeData && completeData.fakeLabel ? <>
            <p className="text-black" style={{ width: "100%", cursor: "pointer" }}>
              {completeData.fakeLabel}
            </p>
          </> : ""}
        </center>
      </div>
    </div>
  );

}

export default ScoreBarChart;