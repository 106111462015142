import React, { Component, useEffect, useState } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
import { common } from '../../../../services/AdminService';
import { firstNest, gameOfFilter, ranfilters, scoreCard, scorecardpiefilters, secondNest, secondStage } from '../../../../common/config';
import { AccountReducerAction, AccountReducerActionWithoutLoad, ChartStageWise, getBarChartData, setLoaderAction } from '../../../../store/actions/createTicketManageFields';
import { useDispatch, useSelector } from 'react-redux';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import DatePicker from "react-datepicker";
//var CanvasJSReact = require('@canvasjs/react-charts');
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import swal from "sweetalert";
import moment from "moment";
import { MultiSelect } from 'react-multi-select-component';
import ReactApexChart from 'react-apexcharts';
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;


const PieScoreUpdated = (props) => {


  console.log(props, "propspropsprops")

  const dispatch = useDispatch()


  const [legend, setLegend] = useState([])
  const [smartVal, setsmartVal] = useState({})
  const [cttr, setcttr] = useState(0)

  const [filterBadges, setFilterBadges] = useState([])
  // const [globalFilter, setGlobalFilter] = useState({
  //   "company": [],
  //   "vendor": [],
  //   "market": [],
  //   "weekly_monthly": ""
  // })
  const [globalFilter, setGlobalFilter] = useState({
    "company": "",
    "vendor": "",
    "market": "",
    "weekly_monthly": ""
  })
  const [fstartdate, setfstartdate] = useState();
  const [fenddate, setfenddate] = useState();
  let [openFilter, setFilter] = useState(false)
  let [SecondGraph, setSecondGraph] = useState([])
  let [AccountGraph, setAccountGraph] = useState("")
  let compOptionArry = []
  let marketArry = []
  let typeOfWorkArry = []
  let vendorsArry = []
  let psowArry = []
  let siteID = []
  let assignedEngineer = []
  let dayNightMop = []

  const [options, setoptions] = useState({
    animationEnabled: true,
    exportEnabled: false,
    theme: "light1", // "light1", "dark1", "dark2"
    title: {
      text: "SCORECARD"
    },
    data: [{
      type: "pie",
      indexLabel: "{label}: {y}%",
      startAngle: 0,
      dataPoints: [],
      borderColor: "black",
      borderWidth: 1,
      showInLegend: true,
    }]
  })


  const count = useSelector((state) => {
    console.log("the state is =", state);
    let filterObj = state.OneCommonResponse.filterDropDown
    let internallyFilter = state.popUpState.internallyFilter
    console.warn("jxhbkjvbjacvkecfrwgvw=", filterObj)
    if (filterObj && Object.keys(filterObj).length > 0) {

      // compOptionArry = filterObj?.companies.sort().map((item, index) => {
      //   return { label: item, value: item }
      // })
      // marketArry = filterObj?.market.map((item, index) => {
      //   return { label: item, value: item }
      // })
      // typeOfWorkArry = filterObj?.requestForm.map((item, index) => {
      //   return <option value={item}>{item}</option>
      // })

      compOptionArry = filterObj?.companies.sort().map((item, index) => {
        return <option value={item}>{item}</option>
      })
      marketArry = filterObj?.market.map((item, index) => {
        return <option value={item}>{item}</option>
      })

      vendorsArry = filterObj?.vendors.sort().map((item, index) => {
        return <option value={item}>{item}</option>
      })
      typeOfWorkArry = filterObj?.requestForm.map((item, index) => {
        return <option value={item}>{item}</option>
      })
      psowArry = filterObj?.plannedSow.map((item, index) => {
        return <option value={item}>{item}</option>
      })
      // vendorsArry = filterObj?.vendors.sort().map((item, index) => {
      //   return { label: item, value: item }
      // })
      siteID = filterObj?.siteIdes.map((item, index) => {
        return <option value={item}>{item}</option>
      })
      dayNightMop = filterObj.dayNightMop.map((item, index) => {
        return <option value={item}>{item}</option>
      })
      assignedEngineer = filterObj.assignedEngineer.map((item, index) => {
        if (index == 0) {
          return <option disabled selected value={""}>Select</option>
        }
        return <option value={item}>{item}</option>
      })
    }

    typeOfWorkArry.unshift(<option disabled selected value={""}>Select</option>)
    siteID.unshift(<option disabled selected value={""}>Select</option>)
    dayNightMop.unshift(<option disabled selected value={""}>Select</option>)
    assignedEngineer.unshift(<option disabled selected value={""}>Select</option>)
    psowArry.unshift(<option disabled selected value={""}>Select</option>)
    compOptionArry.unshift(<option disabled selected value={""}>Select</option>)
    vendorsArry.unshift(<option disabled selected value={""}>Select</option>)
    marketArry.unshift(<option disabled selected value={""}>Select</option>)


    console.log(compOptionArry, "compOptionArrycompOptionArrycompOptionArry")

    let dropDown = {
      "siteId": { "label": "Site ID", "values": siteID, "dType": "S" },
      // "crewDataResult.crewLeadCompanyId": { "label": "GC Company", "values": <><MultiSelect selectionLimit={1} className="text-dark" hasSelectAll={false} options={compOptionArry} onChange={(e)=>{handleChange("company",e)}} value={globalFilter["company"]}/></>, "dType": "S" },
      // "crewDataResult.vendorId": { "label": "Hard Cost Vendor", "values": <><MultiSelect selectionLimit={1} className="text-dark" hasSelectAll={false} options={vendorsArry} onChange={(e)=>{handleChange("vendor",e)}} value={globalFilter["vendor"]}/></>, "dType": "S" },
      // "market": { "label": "Market", "values": <><MultiSelect className="text-dark" hasSelectAll={false} options={marketArry} onChange={(e)=>{handleChange("market",e)}} value={globalFilter["market"]}/></> },
      "crewDataResult.crewLeadCompanyId": {
        "label": "GC Company", "values": [
          <select
            value={globalFilter["company"]}
            style={{ height: "40px", borderBottom: "1px solid black", borderRadius: "0px" }}
            onChange={(e) => { handleChange("company", e.target.value) }}>

            {compOptionArry}
          </select>
        ], "dType": "S"
      },
      "crewDataResult.vendorId": {
        "label": "Hard Cost Vendor", "values": [
          <select
            value={globalFilter["vendor"]}
            style={{ height: "40px", borderBottom: "1px solid black", borderRadius: "0px" }}
            onChange={(e) => { handleChange("vendor", e.target.value) }}>

            {vendorsArry}
          </select>
        ], "dType": "S"
      },
      "market": {
        "label": "Market", "values": [
          <select
            value={globalFilter["market"]}
            style={{ height: "40px", borderBottom: "1px solid black", borderRadius: "0px" }}
            onChange={(e) => { handleChange("market", e.target.value) }}>

            {marketArry}
          </select>
        ], "dType": "S"
      },
      "requestForm": { "label": "Requested From", "values": typeOfWorkArry },
      "plannedSow": { "label": "Planned SOW", "values": psowArry },
      "dayNightMop": { "label": "Day Night MOP", "values": dayNightMop },
      "assignedEngineer": { "label": "Assigned Engineer", "values": assignedEngineer, "dType": "S" },
      "weekly_monthly": {
        "label": "Chart View", "values": [
          <select
            value={globalFilter["weekly_monthly"]}
            style={{ height: "40px", borderBottom: "1px solid black", borderRadius: "0px" }}
            onChange={(e) => { handleChange("weekly_monthly", e.target.value) }}>
            <option disabled selected value={""}>Select</option>,
            <option value={"Weekly"}>Weekly</option>,
            <option value={"Monthly"}>Monthly</option>
          </select>
        ], "dType": "S"
      }
    }



    return ({ filterObj, dropDown, internallyFilter })
  })


  console.log(count, "cmpcountcountcount")


  const handleSliceClick = (e) => {
    // Check if the clicked data point has a URL property


    console.log(SecondGraph.indexOf(e.dataPoint.parent), SecondGraph, e.dataPoint.parent, "handleSliceClickhandleSliceClick")

    // if(["PunctualityRate"].indexOf(e.dataPoint.parent)!=-1){
    //   // props.setStageTwo(true)
    //   dispatch(ChartStageWise(secondStage + e.dataPoint.parent+props.filter,"two"))
    // }


    // dispatch(AccountReducerAction(firstNest + e.dataPoint.parent+props.filter))
    console.log(props.filter == "" ? "?internalFilter=" : "&internalFilter", "props.filter")

    console.log("setGlobalPieType", "setGlobalPieType")
    let lclinternalFilter = props.filter == "" ? "?internalFilter=" : "&internalFilter="
    // alert(props.internalfilter)


    console.log(lclinternalFilter,e.dataPoint.parent, "props.filter", props.filter, "lclinternalFilter")

    if (props.filter.search("market") != -1 || props.filter.search("vendor") != -1 || props.filter.search("company") != -1 || props.filter.search("weekly_monthly") != -1) {
      dispatch(AccountReducerAction(gameOfFilter + e.dataPoint.parent + "/" + "T-Mobile" + props.filter + lclinternalFilter + count.internallyFilter))
    } else {
      dispatch(AccountReducerAction(secondNest + e.dataPoint.parent + "/" + "T-Mobile" + props.filter + lclinternalFilter + count.internallyFilter))
    }
    props.setGlobalPieType(e.dataPoint.parent)



    // console.log('Clicked slice index:', clickedSliceIndex);
    // console.log(props, 'Cutstom data:', clickedSliceData);
    // setFilter(true)
    props.setType(true)


    dispatch(getBarChartData({}))

    props.setCount(true)
    props.settwoCount(true)
    props.setThreeCount(true)
    props.setfivercount(true)



    // if(["PunctualityRate","TroubleTicketRate"].indexOf(e.dataPoint.parent)!=-1){
    //   props.setFourCount(true)
    // }

    if (e.dataPoint.url) {
      // window.open(e.dataPoint.url, '_blank'); // Open URL in a new tab
    }
  };


  const handleEvent = (event, picker) => {
    console.log("dwedfwefdwerfw", picker.startDate.format('YYYY-MM-DD'));
    console.log("dwedfwefdwerfw", picker.endDate.format('YYYY-MM-DD'));
  }

  useEffect(() => {

    props.setGlobalPieType("")
    dispatch(AccountReducerActionWithoutLoad(scorecardpiefilters, false))

    let dataArr = []

    console.log(props.filter, "props.filter")

    dispatch(setLoaderAction(true))
    common(scoreCard + props.filter).then((res) => {


      dispatch(setLoaderAction(false))
      console.log("the gmaeclomese =>", res?.data?.data)
      console.log("the resp =", res?.data?.data.AccountGraph)
      let datese = []
      setSecondGraph(res?.data?.data.second_graph)
      setAccountGraph(res?.data?.data.AccountGraph)

      let ctt=0
      if (res && res.data && res?.data?.data && res?.data?.data.parent_label && res?.data?.data.parent_label.length > 0) {






        let personalParent = ""
        let personalParentIndex = -2
        res?.data?.data.parent_label.map((itm, index) => {

          console.log("djwkhdljiwbef", itm, index)
          if (index == 1) {
            itm[1] = "Not Punctual"
          }
          if (index == 2) {
            itm[1] = "Punctual"
          }
          if (index == 7) {
            itm[1] = "Completed"
          }
          if (index == 8) {
            itm[1] = "Not Completed"
          }


          if (itm[0] == "parent") {
            personalParent = itm[1]
            personalParentIndex = personalParentIndex + 2
            datese.push(<><span style={{ color: "black", fontSize: "12px" }}>{itm[1]} - {parseFloat(itm[2]).toFixed(2)}%</span><br /></>)
          } else {

            console.log("lkncljwbvdwefwergf", res?.data?.data)
            if(itm[2]==0){
              ctt=ctt+1
            }
            dataArr.push({
              parent: res?.data?.data.getSingleData[personalParentIndex],
              y: +parseFloat(itm[2]).toFixed(2),
              label: itm[1],
              color: res?.data?.data.comp_databackgroundNew[index]
              // backgroundColor: {
              //   type: 'linearGradient',
              //   colorStops: [
              //     { offset: 0, color: '#FF6384' },
              //     { offset: 1, color: '#FF0000' },
              //   ],
              // },

            })


            console.log("dataArrdataArrdataArr", dataArr)
            datese.push(<><span style={{ color: "black", fontSize: "10px" }}><span style={{ marginLeft: "40px", marginRight: "10px", padding: "0px 6px", borderRadius: "50%", backgroundColor: `${res?.data?.data.comp_databackgroundNew[index]}` }}></span>{itm[1]} - {parseFloat(itm[2]).toFixed(2)}%</span><br /></>)
          }
        })
      }

      let final_cal=[]
      setcttr(ctt)
      if(ctt != 10){
        final_cal=dataArr
      }


      setoptions((prev) => {
        return {
          ...prev,
          data: [{
            ...prev.data,
            type: "pie",
            dataPoints: final_cal,
            click: handleSliceClick,
            borderColor: "black",
            borderWidth: 1
          }]
        }
      })

      console.log(options,ctt == 10, "optionsoptionsoptions")





      setLegend(datese)






      // setChartData((prev) => {
      //   return {
      //     ...prev,
      //     options: { },
      //     series: {
      //       ...prev.series,
      //       data: res.data.data.puntualityRate,
      //       customData: res.data.data.getSingleData
      //     }
      //   }
      // })
      // customData.current = res.data.data.getSingleData
    })

  }, [props.filter])





  const SetResetFilterGlobal = (e) => {
    e.preventDefault();


    console.log("fstartdate", fstartdate, "fstartdate")


    // setGlobalFilter({
    //   "company": [],
    //   "vendor": [],
    //   "market": []
    // })

    setGlobalFilter({
      "company": "",
      "vendor": "",
      "market": "",
      "weekly_monthly": ""
    })

    setFilterBadges([])

    setfenddate()
    setfstartdate()


    // setFilter(false)
    props.setType(false)
    props.setStageTwo(false)
    props.setGlobalPieType("")
    props.setFilter("")



    dispatch(getBarChartData({}))
    dispatch(AccountReducerActionWithoutLoad(scorecardpiefilters, true))
  }
  const SetFilterGlobal = (e) => {


    e.preventDefault();

    console.log("fstartdate", globalFilter, "globalFilterglobalFilterglobalFilter", fstartdate, fenddate, "fstartdate")


    if (fstartdate != undefined && fenddate == undefined) {
      swal("Oops", "Please select End Date", "info");
      return;
    }
    if (fenddate != undefined && fstartdate == undefined) {
      swal("Oops", "Please select Start Date", "info");
      return;
    }


    console.log(moment(fenddate).diff(moment(fstartdate), 'months'),"moment(fstartdate).diff(moment(fenddate), 'months')")

    if (fenddate != undefined && fstartdate != undefined && globalFilter["weekly_monthly"]== "Monthly") {
      if (moment(fenddate).diff(moment(fstartdate), 'months') < 1 && globalFilter["weekly_monthly"] == "Monthly") {
        swal("Oops", "You Select Chart View as a Month Wise but select less than one month.", "warning");
        return
      }
    }




    console.log(globalFilter, "globalFilter367")
    // dateRange[0].startDate = formatDate(dateRange[0].startDate);
    // dateRange[0].endDate = formatDate(dateRange[0].endDate);
    // console.log("the filtered data=", dateRange);


    let badgemaker = []

    // let finalcompany = globalFilter["company"].map((itm) => {

    //   badgemaker.push(<p className='badge badge-info'>{itm.value}</p>)
    //   return encodeURIComponent(itm.value)
    // })
    // let finalvendor = globalFilter["vendor"].map((itm) => {
    //   badgemaker.push(<p className='badge badge-info'>{itm.value}</p>)
    //   return encodeURIComponent(itm.value)
    // })
    // let finalmarket = globalFilter["market"].map((itm) => {
    //   badgemaker.push(<p className='badge badge-info'>{itm.value}</p>)
    //   return encodeURIComponent(itm.value)
    // })




    // console.log(finalcompany.join("_filter_"), finalvendor.join("_filter_"), finalmarket.join("_filter_"), "finalcompany,finalvendor,finalmarket")

    let strFilter = ""



    // console.log("filtergamebylog", globalFilter, finalcompany, finalvendor, finalmarket)


    // if (finalcompany.length > 0) {
    //   let newstrFilter = (strFilter != "" ? "&" : "?") + "company=" + finalcompany.join("_filter_")
    //   strFilter = strFilter + newstrFilter
    // }

    // if (finalvendor.length > 0) {
    //   let newstrFilter = (strFilter != "" ? "&" : "?") + "vendor=" + finalvendor.join("_filter_")
    //   strFilter = strFilter + newstrFilter
    // }

    // if (finalmarket.length > 0) {
    //   let newstrFilter = (strFilter != "" ? "&" : "?") + "market=" + finalmarket.join("_filter_")
    //   strFilter = strFilter + newstrFilter
    // }
    if (globalFilter["market"] != "") {
      let newstrFilter = (strFilter != "" ? "&" : "?") + "market=" + globalFilter["market"]
      strFilter = strFilter + newstrFilter
    }
    if (globalFilter["company"] != "") {
      let newstrFilter = (strFilter != "" ? "&" : "?") + "company=" + globalFilter["company"]
      strFilter = strFilter + newstrFilter
    }
    if (globalFilter["vendor"] != "") {
      let newstrFilter = (strFilter != "" ? "&" : "?") + "vendor=" + globalFilter["vendor"]
      strFilter = strFilter + newstrFilter
    }
    if (globalFilter["weekly_monthly"] != "") {
      let newstrFilter = (strFilter != "" ? "&" : "?") + "weekly_monthly=" + globalFilter["weekly_monthly"]
      strFilter = strFilter + newstrFilter
    }


    console.log(strFilter, "strFilterstrFilter")



    if (fstartdate != undefined && fenddate != undefined) {
      let start = moment(fstartdate).format("MM-DD-YYYY");
      let end = moment(fenddate).format("MM-DD-YYYY");
      let newstrFilter = (strFilter != "" ? "&" : "?") + "start=" + start + "&end=" + end
      strFilter = strFilter + newstrFilter

      badgemaker.push(<p className='badge badge-info'>{moment(fstartdate).format("MM/DD/YYYY")}</p>)
      badgemaker.push(<p className='badge badge-info'>{moment(fenddate).format("MM/DD/YYYY")}</p>)

    }
    setFilterBadges(badgemaker)


    console.log("finalApiCallerUrl", strFilter)

    props.setFilter(strFilter)
    let currentUrl = window.location.href;
    props.setType(false)


    dispatch(getBarChartData({}))
  };
  let currentDate = new Date()
  let previousDate = currentDate
  previousDate.setMonth(currentDate.getMonth() - 1)
  // let smartVal={

  // }


  let handleChange = (dropDownName, e) => {
    console.log("smartVal", smartVal, "smartVal")

    // if (dropDownName != "weekly_monthly") {
    //   smartVal[dropDownName] = (e.map((itm) => {
    //     return itm.value
    //   })).join(",")
    // } else {
    smartVal[dropDownName] = e
    // }


    console.log("smartVal", smartVal, "smartVal")


    const queryString = Object.keys(smartVal).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(smartVal[key])}`).join('&');
    console.log(queryString);


    console.log(smartVal, "smartVal")
    let trl = scorecardpiefilters + "?" + queryString
    console.log(trl, "trltrl")
    dispatch(AccountReducerActionWithoutLoad(trl, true))

    console.log(dropDownName, "globalFilter")


    setGlobalFilter({
      ...globalFilter,
      [dropDownName]: e
    })

  }


  console.log(globalFilter, "globalFilter")


  const dateMyStyle = {
    border: "2px solid #1d548f !important",
    height: "24px",
    borderRadius: "4px !important"
  }

  let index = 0
  return (
    <>
      {filterBadges?.length > 0 ? <p style={{ color: "black", border: "0px solid black" }} className={"badge"}>Selected Filter : &nbsp;&nbsp;</p> : ""}{filterBadges}

      <div className='row'>

        <div className='col-md-1.5' style={{ padding: "5px", width:"128px" }}>
          <label>{count.dropDown["market"]["label"]}</label>
          {/* <input className='form-control' onChange={(e) => handleChange("market", e)} list={"market"}
            style={{ height: "24px", border: "2px solid #1d548f !important", borderRadius: "4px !important" }} /> */}
          {/* <datalist id={"market"}> */}
          {count.dropDown["market"]["values"]}
          {/* </datalist> */}

        </div>
        <div className='col-md-1.5' style={{ padding: "5px", width:"128px" }}>
          <label>{count.dropDown["crewDataResult.vendorId"]["label"]}</label>
          {/* <input className='form-control' onChange={(e) => handleChange("market", e)} list={"vendor"}
            style={{ height: "24px", border: "2px solid #1d548f !important", borderRadius: "4px !important" }} /> */}
          {/* <datalist id={"vendor"}> */}
          {count.dropDown["crewDataResult.vendorId"]["values"]}
          {/* </datalist> */}



        </div>
        <div className='col-md-1.5' style={{ padding: "5px" , width:"128px"}}>
          <label>{count.dropDown["crewDataResult.crewLeadCompanyId"]["label"]}</label>
          {/* <input className='form-control' onChange={(e) => handleChange("market", e)} list={"vendor"}
            style={{ height: "24px", border: "2px solid #1d548f !important", borderRadius: "4px !important" }} /> */}
          {/* <datalist id={"vendor"}> */}
          {count.dropDown["crewDataResult.crewLeadCompanyId"]["values"]}
          {/* </datalist> */}



        </div>

        <div className='col-md-1.5' style={{ padding: "5px", width:"128px" }}>
          <label>{count.dropDown["weekly_monthly"]["label"]}</label>
          {/* <input className='form-control' onChange={(e) => handleChange("company", e)} list={"companny"}
            style={{ height: "24px", border: "2px solid #1d548f !important", borderRadius: "4px !important" }} /> */}
          {/* <datalist id={"companny"}> */}
          {count.dropDown["weekly_monthly"]["values"]}
          {/* </datalist> */}

        </div>

        <div className='col-md-3' style={{ padding: "5px" }}>
          <label>Date</label>
          <div
            style={{
              width: "auto",
              display: "flex",
              alignItems: "center",
              margin: "0px"
            }}>
            <DatePicker
              placeholderText="MM/DD/YYYY"
              id="date-picker-ui-changes"
              className="date-picker-changes "
              popperPlacement='bottom'
              selected={fstartdate}
              autoComplete={"off"}
              onChange={(e) => {
                setfstartdate(e);
                // setfenddate(e);
              }}
              dateFormat="MM/dd/yyyy"
            />
            <DatePicker
              placeholderText="MM/DD/YYYY"
              className="date-picker-ui-changes mx-1"
              style={{ height: "40px", borderBottom: "1px solid black", borderRadius: "0px" }}
              id="date-picker-ui-changes"
              popperPlacement='bottom'
              autoComplete={"off"}
              selected={fenddate}
              onChange={(e) => {
                setfenddate(e);
              }}
              dateFormat="MM/dd/yyyy"
            />

          </div>

        </div>


        <div className='col-md-1' style={{ alignSelf: "flex-end",paddingBottom:"8px",paddingLeft:"1px",paddingRight:"2px"}}>
          <button
            className="btn btn-primary btn-xs p-2 d-flex justify-content-center align-items-center"
            style={{ marginTop: "40px!important;" }}
            // onClick={() => setDatePicker(!showDatePicker)}
            onClick={(e) => SetFilterGlobal(e)}
          >
            <i class="fa fa-filter mx-2"></i>Filter
          </button>

        </div>


        <div className='col-md-1' style={{ alignSelf: "flex-end",paddingBottom:"8px",paddingLeft:"2px",paddingRight:"1px" }}>
          <button
            className="btn btn-primary btn-xs mx-2 p-2 d-flex justify-content-center align-items-center"
            style={{ marginTop: "40px!important;" }}
            // onClick={() => setDatePicker(!showDatePicker)}
            onClick={(e) => SetResetFilterGlobal(e)}
          >
            <i class="fa fa-filter mx-2"></i>Reset
          </button>

        </div>


      </div>

      {console.log("optionsoptionsoptionsoptions",options)}
      <br />
      <br />
      <div className='row'>
        <div className='col-md-6'>
          <div style={{ width: "100%",display: "flex",justifContent: "center",flexDirection: "column",alignItems: "center" }}>
            {
            
              cttr!=10?<CanvasJSChart options={options} />:
              <>
              <h1>SCORECARD</h1>
              <div class="circle">
                <h4>No Data Found</h4>  
              </div> 
              </>
            }
          </div>
        </div>
        <div className='col-md-2'>

        </div>
        <div className='col-md-4'>
          <div style={{ padding: "20px" }}>
            {/* <DateRangePicker 
      onApply ={handleEvent}
        initialSettings={{ startDate: previousDate, endDate:currentDate }}
      >
        <button className='badge badge-warning'>Date Filter</button>
      </DateRangePicker> */}

            {legend}

          </div>
        </div>
      </div>
    </>
  );
};


export default PieScoreUpdated




