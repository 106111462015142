import React from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { SevenTwoAlarm } from '../../../../common/config'
import { addAcccountReducerAction, popUpUpdate } from '../../../../store/actions/createTicketManageFields'

const AddComments = (props) => {
    const [commt,setCommt]=useState(props.message)
    const dispatch=useDispatch()
    const handleChange=(e)=>{
        setCommt(e.target.value)
    }
    const submitHaandler=(e)=>{
        dispatch(addAcccountReducerAction(SevenTwoAlarm+props.id,{"sevenTwoComments":commt}))
        
        dispatch(popUpUpdate({}))
        props.setCloser(true)
    }
  return (
    <>
    <div className='form-group'>
        <label>Add Comment</label>
        <textarea rows="11" cols="60" value={commt} onChange={handleChange}></textarea>        
    </div>
    <button className='badge badge-success' onClick={submitHaandler}>Submit</button>
    </>
  )
}

export default AddComments