import React, { useState, useRef } from "react";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AccountReducerAction, addAcccountReducerAction, clearAcccountReducerAction, clearIxDashboard, FetchSingleRecordAction, getIXDashboard, GetMarketReducerAction, popUpUpdate } from "../../../store/actions/createTicketManageFields";
import { accountApi, baseURL, getMarket, ixDasboard, ixModule, ranDashboard, siteDblistSiteId, zipUpploader } from "../../../common/config";
import { useCallback } from "react";
import { Link } from "react-router-dom";
import AdvOneSiteMapView from "../Custom/Modal Body/AdvOneSiteMapView";
import CommonModal from "../Custom/CommonModal";
import swal from "sweetalert";
import Download from '../../../icons/Svg/Download'
import { PDFUploadActionreturn } from "../../../store/actions/dashboardActions";
import SelectBox from "../Custom/Modal Body/SelectBox";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
const eventColors = {
	approved: "bg-success text-white",
	rejected: "bg-danger text-white",
	pending: "bg-warning text-white",
};

const localizer = momentLocalizer(moment);

const IXCalendar = (props) => {
	let dispatch = useDispatch()

	const Location = useLocation()
	const history = useHistory()
	let allProjects = []
	let siteArray = [];
	let currentUrl = window.location.href

	console.log(Location?.state?.reload, "Location.state")


	let siteDetail = {}
	let accountArry = []
	const [events, setEvents] = useState([])
	const [modalContent, setModalContent] = useState()
	const [commonModalState, setcommonModalState] = useState(false);
	const [LastSite, setLastSite] = useState(false)
	const [closer, setCloser] = useState(false)

	const [showSite, setShowSite] = useState(false)
	const [reloadCal, setreloadCal] = useState(false)
	const [OldDataCount, setOldDataCount] = useState(0)

	const counter = useRef(1)

	// const newcounter = useRef(1)
	const [newcounter, setnewcounter] = useState(true)


	const [siteFile, uploadedFile] = useState([]);

	let [modalBdy, setModal] = useState(false)
	var heading = useRef("")
	var abcd = 1
	console.log(Location.state, currentUrl, "Location.state")
	if (currentUrl.includes("calendera")) {
		console.log(currentUrl, "currentUrlcurrentUrlcurrentUrl")

		console.log("going to clear old dta", "testIx1")
		dispatch(clearIxDashboard())
		dispatch(getIXDashboard([]))
		history.push("/PAG/ix/calender", { reload: false })
		setnewcounter(true)
	}
	// if(abcd==1){
	// 	if(Location.state){

	// 		console.log(Location.state,"Location.state")
	// 		if(Location.state.reload){
	// 			console.log(Location.state,"Location.state")
	// 			// counter.current=1

	// 			// setnewcounter(true)
	// 			history.replace({
	// 				...history.location,
	// 				state:undefined
	// 			})

	// 			console.log(Location.state,"Location.state")
	// 		}
	// 	}

	// 	abcd=2
	// }


	console.log(Location.state, "Location.state")


	const [countAccount, setCountAccount] = useState({
		account: 1,
		market: 1
	})
	const [filter, setFilter] = useState({
		account: "",
		market: "",
		sow: "",
		siteId: "",
	});

	console.log(filter,"filterfilterfilter")
	const [data, setData] = useState({
		account: [],
		market: [],
		sow: [
			{ id: "IX", name: "IX", visiblity: true },
			{ id: "NEA", name: "NEA", visiblity: false },
			{ id: "Troubleshooting", name: "Troubleshooting", visiblity: true },
			{ id: "Call Test(PSAP/Speed Test)", name: "Call Test(PSAP/Speed Test)", visiblity: true }
		],
	})
	console.log("cljkwchblkcbneivkefrvr=e ", counter)
	function openModal(hd, tag) {
		heading.current = hd
		console.log("cnkjebfvle", heading.current)
		setModalContent(tag)
		setModal(true)

	}
	let option = useSelector((state) => {

		allProjects = state.CommonResponse.startAwaitedSite
		// console.log("all projects is =",allProjects)
		if (allProjects && allProjects.length > 0) {

			siteArray = allProjects.map((project) => {
				// console.log("the project is =========",project)
				return <option>{project.siteId}</option>;
			});
		}
		let sitesdata = state.CommonResponse.listIXData;

		if (state.popUpState) {
			console.log(state.popUpState.popstatus.status, closer, (state.popUpState.popstatus.status == 201 && closer) || (state.popUpState.popstatus.status == 200 && closer), "popUpStatestatus201")
			if ((state.popUpState.popstatus.status == 201 && closer) || (state.popUpState.popstatus.status == 200 && closer)) {
				// dispatch(popUpUpdate({}))
				// setCloser(false)
				dispatch(FetchSingleRecordAction(ixModule, LastSite.id))
				dispatch(popUpUpdate({}));
				setCloser(false);
			}
		}

		console.log("going to clear old dta", state.CommonResponse.listIXData, "testIx")
		console.log(newcounter, "newcounternewcounter", sitesdata.length, OldDataCount, sitesdata)
		// if(sitesdata && sitesdata.length>0 && counter.current==1){
		if (sitesdata && sitesdata.length > 0 && (newcounter || sitesdata.length != OldDataCount)) {
			// if(sitesdata && sitesdata.length>0 && newcounter){

			console.log("the localjneoirfjioserfjero==", state)
			let scheduleSites = sitesdata.map((item) => {
				let flags = {
					"Scheduled": "green",
					"Ongoing": "dark-blue",
					"Cancelled": "red",
					"Rollback": "orange"

				}

				let reformDate = new Date(moment(item.scheduleDate).format("YYYY,MM,DD"))
				let twoFour = reformDate.setDate(reformDate.getDate() + 1);
				return {
					id: item.ranSupportId,
					title: item.siteId || item.ndResult.siteId,
					allDay: true,
					colors: flags[item.SchedulerStatus || item.postActivityStatusSiteLevel] || "blue",
					start: new Date(moment(item.scheduleDate).format("YYYY,MM,DD")),
					end: new Date(moment(item.scheduleDate).format("YYYY,MM,DD")),
				}
			})
			console.log("the ;l,ckmfsfwdfvewv==", scheduleSites)
			console.log("c sjavcjkhsvcekucjefvbv=", events, "   counter ", counter.current)
			setEvents(scheduleSites)
			counter.current = 2
			setOldDataCount(sitesdata.length)
			setnewcounter(false)
			setreloadCal(true)

		}
		let siteby = state.OneCommonResponse.singleIXSite
		console.log("kcbskgvcuykdsvckfrege=", siteby)
		if (siteby) {
			console.log("bcsaudfuaewlfb====", siteby)
			siteDetail = siteby
		}
		console.log("th kdfenfersf=", siteby)
		let account = state.CommonResponse.accounts;

		if (account.length > 0 && accountArry.length == 0 && countAccount.account == 1) {
			accountArry = account.map((item, index) => {
				if (index == 0) {
					// setoneTimeAccount(false)
					// setValue("account", item.uniqueid)
					// return <option selected value={item.uniqueid}>{item.accountName}</option>
				}

				return {
					id: item.accountName,
					name: item.accountName,
					visiblity: true
				};
				return {
					id: item.uniqueid,
					name: item.accountName
				};
			});

			//   data.account.push(accountArry)
			setData((prevState) => {
				// {	...prevState,
				return { ...prevState, account: accountArry }
			}
			)
			setCountAccount((prevState) => { return { ...prevState, account: 2 } })

			console.log("the v;lfmfdknvd=", data)
		}
		let marketArry = []
		let market = state.CommonResponse.markets;
		console.log("lnrfijern=", market)
		if (market.length > 0 && countAccount.market == 1) {
			marketArry = market.map((item) => {
				return { id: item.marketName, name: item.marketName, visiblity: true };
				// return {id:item.uniqueid,name:item.marketName};
			});

			setData((prevState) => {
				// {	...prevState,
				return { ...prevState, market: marketArry }
			}
			)
			setCountAccount((prevState) => { return { ...prevState, market: 2 } })
		}
		return { siteArray, siteDetail, accountArry, sitesdata }
	})
		// [
		// 	{
		// 		title: "All Day Event very long title",
		// 		allDay: true,
		// 		start: new Date(2023, 3, 1),
		// 		end: new Date(2023, 3, 2 + 1),
		// 	},
		// 	{
		// 		title: "Long Event",
		// 		allDay: true,
		// 		start: new Date(2023, 3, 7),
		// 		end: new Date(2023, 3, 10),
		// 	},
		// 	{
		// 		title: "DTS STARTS",
		// 		allDay: true,
		// 		start: new Date(2016, 2, 13, 0, 0, 0),
		// 		end: new Date(2016, 2, 20, 0, 0, 0),
		// 	},
		// 	{
		// 		title: "DTS ENDS",
		// 		start: new Date(2016, 10, 6, 0, 0, 0),
		// 		end: new Date(2016, 10, 13, 0, 0, 0),
		// 	},
		// 	{
		// 		title: "Some Event",
		// 		start: new Date(2023, 3, 9, 0, 0, 0),
		// 		end: new Date(2023, 3, 9, 0, 0, 0),
		// 	},
		// 	{
		// 		title: "Conference",
		// 		start: new Date(2023, 3, 11),
		// 		end: new Date(2023, 3, 13),
		// 		desc: "Big conference for important people",
		// 	},
		// 	{
		// 		title: "Meeting",
		// 		start: new Date(2023, 3, 12, 10, 30, 0, 0),
		// 		end: new Date(2023, 3, 12, 12, 30, 0, 0),
		// 		desc: "Pre-meeting meeting, to prepare for the meeting",
		// 	},
		// 	{
		// 		title: "Lunch",
		// 		start: new Date(2023, 3, 12, 12, 0, 0, 0),
		// 		end: new Date(2023, 3, 12, 13, 0, 0, 0),
		// 		desc: "Power lunch",
		// 	},
		// 	{
		// 		title: "Meeting",
		// 		start: new Date(2023, 3, 12, 14, 0, 0, 0),
		// 		end: new Date(2023, 3, 12, 15, 0, 0, 0),
		// 	},
		// 	{
		// 		title: "Happy Hour",
		// 		start: new Date(2023, 3, 12, 17, 0, 0, 0),
		// 		end: new Date(2023, 3, 12, 17, 30, 0, 0),
		// 		desc: "Most important meal of the day",
		// 	},
		// 	{
		// 		title: "Dinner",
		// 		start: new Date(2023, 3, 12, 20, 0, 0, 0),
		// 		end: new Date(2023, 3, 12, 21, 0, 0, 0),
		// 	},
		// 	{
		// 		title: "Birthday Party",
		// 		start: new Date(2023, 3, 13, 7, 0, 0),
		// 		end: new Date(2023, 3, 13, 10, 30, 0),
		// 	},
		// 	{
		// 		title: "Birthday Party 2",
		// 		start: new Date(2023, 3, 13, 7, 0, 0),
		// 		end: new Date(2023, 3, 13, 10, 30, 0),
		// 	},
		// 	{
		// 		title: "Birthday Party 3",
		// 		start: new Date(2023, 3, 13, 7, 0, 0),
		// 		end: new Date(2023, 3, 13, 10, 30, 0),
		// 	},
		// 	{
		// 		title: "Late Night Event",
		// 		start: new Date(2023, 3, 17, 19, 30, 0),
		// 		end: new Date(2023, 3, 18, 2, 0, 0),
		// 	},
		// 	{
		// 		title: "Multi-day Event",
		// 		start: new Date(2023, 3, 20, 19, 30, 0),
		// 		end: new Date(2023, 3, 20, 2, 0, 0),
		// 	},
		// ]);

		;

	const handleChange = (event) => {

		console.log(event,"eventhandleChange")
		setFilter((prev) => {
			return { ...prev, [event.target.id]: event.target.value };
		});
	};
	//  console.warn("calender data => ",option)
	const handleSelectEvent = useCallback((event) => {
		setShowSite(!showSite)
		console.log(event, "event.idevent.id")
		setLastSite(event)

		dispatch(FetchSingleRecordAction(ixModule, event.id))
		console.log("testlog")
	},
		[]
	)
	const handleFilter = () => {
		// alert(JSON.stringify(filter));
		// dispatch(FetchSingleRecordAction(ixModule+"?filter=True",))
		dispatch(getIXDashboard([]))
		counter.current = 1
		let completeFilter = ""
		console.log(filter, completeFilter, "filterfilter")

		completeFilter = `${completeFilter}${filter["account"] != "" ? "&account=" + filter["account"] : ""}`
		console.log(filter, completeFilter, "filterfilter")
		completeFilter = `${completeFilter}${filter["market"] != "" ? "&market=" + filter["market"] : ""}`
		console.log(filter, completeFilter, "filterfilter")
		completeFilter = `${completeFilter}${filter["sow"] != "" ? "&sow=" + filter["sow"] : ""}`
		completeFilter = `${completeFilter}${filter["siteId"] != "" ? "&siteId=" + filter["siteId"] : ""}`
		console.log(filter, completeFilter, "filterfilter")
		console.log(ixModule + "?ix=calender&filter=True" + completeFilter, "filterfilter")
		dispatch(AccountReducerAction(ixModule + "?ix=calender&filter=True" + completeFilter))
		// dispatch(addAcccountReducerAction(ixModule+"?filter=True",filter))
	};
	const uploadCsvFile = (e) => {
		// let files=[]
		// console.log(e.target.files[0].name, "files")
		if (e.target.files) {
			let fileExtension = e.target.files[0].name
			let extns = fileExtension.split(".").pop()
			if (extns != "zip") {
				swal({
					title: "Oops!",
					text: "Please upload only .zip file",
					icon: "error",
					button: "Ok",
				})
			}
			else {
				uploadedFile(e.target.files[0])
			}
		}
	};
	const clearFilter = () => {
		dispatch(getIXDashboard([]))
		counter.current = 1
		setFilter({
			account: "",
			market: "",
			sow: "",
			siteId:""
		});
		dispatch(AccountReducerAction(ixModule + "?ix=calender"))
	}
	const submitHandler = async (id) => {
		// e.preventDefault();
		console.log("form==", id)
		let newform = new FormData();
		if (siteFile != []) {
			newform.append("uploadFile", siteFile);
			newform.append("uploadfor", "ixCalender")
			// newform.append("rttCompleteDate", moment(getDat).format("MM/DD/YYYY"))
			console.log("form==", newform)
			dispatch(PDFUploadActionreturn(zipUpploader + "/" + id + "?ixScript=True", newform))


			setCloser(true)
			dispatch(popUpUpdate({}));
			// setfileShow(false)
		}
		else {
			swal({
				title: "Oops!",
				text: "Please select file and date",
				icon: "warning",
				button: "Ok",
			})
		}
		// setfileShow(false);
	};
	// console.log("ix calender => ",option)
	console.log("lndcvcvgeiufvdiyckw=", events)
	useEffect(() => {
		// console.log(filter);

		dispatch(AccountReducerAction(ixModule + "?ix=calender"))
		dispatch(AccountReducerAction(accountApi));
		dispatch(GetMarketReducerAction(getMarket));
		dispatch(AccountReducerAction(siteDblistSiteId))

	}, []);

	return (
		<div className="row">
			<div className="col-lg-8">
				<div style={{ display: "grid", gridTemplateColumns: "repeat(6,1fr)", gap: "1rem", margin: "1rem auto 1.2rem auto", alignItems: "end" }}>
					<div>
						<label style={{ color: "black", fontSize: "16px", fontWeight: 500 }}>Site Id</label>
						<input style={{ height: "40px", padding: "6px", borderRadius: "6px", border: "1px solid black", color: "#2b2b2b" }} type="text" id={"siteId"} value={filter["siteId"]} list="siteIdList" onChange={handleChange}></input>
						<datalist id="siteIdList">
							{option.siteArray}
						</datalist>
					</div>
					{Object.keys(data).map((key) => {
						console.log("the data is =", key)
						return (
							<div>

								{console.log(filter[key], "filter.keyfilter.key")}
								<label style={{ color: "black", fontSize: "16px", fontWeight: 500 }}>{key.charAt(0).toUpperCase() + key.slice(1)}</label>
								<select id={key} value={filter[key]} style={{ height: "40px", padding: "6px", borderRadius: "6px", border: "1px solid black", color: "#2b2b2b" }} onChange={handleChange}>
									<option style={{ color: "gray", fontWeight: 400 }} value="">
										Select
									</option>
									{data[key].map((ele) => {
										if (ele.visiblity) {
											return (
												<option style={{ color: "gray", fontWeight: 400 }} value={ele.id}>
													{ele.name}
												</option>
											);
										}

									})}
								</select>
							</div>
						);
					})}
					<button onClick={() => { handleFilter() }} className="btn btn-success" style={{ height: "40px" }}>
						Search
					</button>
					<button onClick={() => { clearFilter() }} className="btn btn-primary" style={{ height: "40px" }}>
						RESET
					</button>
					{/* <button style={{ justifySelf: "end", height: "40px", width: "40px", borderRadius: "100%", background: "#e7efff", position: "relative", border: "none" }}>
						<span style={{ position: "absolute", top: "-4px", right: 0, background: "red", color: "white", width: "22px", height: "22px", borderRadius: "100%", display: "grid", placeItems: "center" }}>1</span>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
							<path fill="none" d="M0 0h24v24H0z" />
							<path d="M5 18h14v-6.969C19 7.148 15.866 4 12 4s-7 3.148-7 7.031V18zm7-16c4.97 0 9 4.043 9 9.031V20H3v-8.969C3 6.043 7.03 2 12 2zM9.5 21h5a2.5 2.5 0 1 1-5 0z" />
						</svg>
					</button> */}
				</div>
				<BigCalendar
					onSelectEvent={handleSelectEvent}
					localizer={localizer}
					events={events}
					startAccessor="start"
					endAccessor="end"
					style={{ height: 500 }}

					onView={() => {
						if (reloadCal) {

							setreloadCal(false)
						}
					}}
					eventPropGetter={(events) => {
						const backgroundColor = events.colors
						// const color = myEventsList.color ? myEventsList.color : 'blue';
						return { style: { backgroundColor } }
					}}

				/>
			</div>
			<div className="col-lg-4 py-4" style={{ background: "#ffffe7", display: showSite ? "block" : "none" }}>
				<div className="row">
					<div className="col-7" style={{ paddingRight: "0px" }}>
						<h5>Selected Schedule :</h5>
						<h4 style={{ color: "#b1b329", marginTop: "2rem" }}>
							{option.siteDetail ? option.siteDetail.siteId || (option.siteDetail.ndResult ? option.siteDetail.ndResult.siteId : "") : ""}
							<p onClick={(e) => setcommonModalState(true)} style={{ display: "inline" }}	>
								<svg fill="white" style={{ background: "green", marginTop: "-4px", marginLeft: "3px", borderRadius: "3px", padding: "3px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18">
									<path fill="none" d="M0 0h24v24H0z" />
									<path d="M18.364 17.364L12 23.728l-6.364-6.364a9 9 0 1 1 12.728 0zM12 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0-2a2 2 0 1 1 0-4 2 2 0 0 1 0 4z" />
								</svg>
							</p>
						</h4>
						<p style={{ color: "rgb(112 112 112)", fontSize: "13px" }}>
							<span onClick={() => openModal("Site Status", <SelectBox keyName={"postActivityStatusSiteLevel"} optionsList={["Cancelled", "Re-Scheduled"]} label={"Site Status"} id={option.siteDetail.ranSupportId} extraKey={"updateSiteStatus"} />)}><span style={{ color: "black" }}>Status :</span>{option.siteDetail ? option.siteDetail.SchedulerStatus || (option.siteDetail.postActivityStatusSiteLevel) : ""}</span><br />
							<span style={{ color: "black" }}>SOW :</span>{option.siteDetail ? option.siteDetail.sowList ? option?.siteDetail?.sowList?.values.join(", ") : "" : ""}<br />
							{/* <span style={{ color: "black" }}>Assigned FE :</span>{ `${option?.siteDetail?.ixCrewDetails?.name || "" } ${ option?.siteDetail?.ixCrewDetails?.mobilenumber ? `(${option?.siteDetail?.ixCrewDetails?.mobilenumber})` : "" }`}<br /> */}
							<span style={{ color: "black" }}>Assigned FE :</span>{`${option?.siteDetail?.ixCrewId?.name || option?.siteDetail?.cxCrewId?.name} ${option?.siteDetail?.ixCrewId?.mobilenumber ? `(${option?.siteDetail?.ixCrewId?.mobilenumber})` : `(${option?.siteDetail?.cxCrewId?.mobilenumber})`}`}<br />
							<span style={{ color: "black" }}>Integration Engineer :</span>{option?.siteDetail?.SchedulerAssignedIntegrationEngineer || option?.siteDetail?.assignedEngineer}<br />
							<span style={{ color: "black" }}>Scheduled Notes :{option.siteDetail ? option.siteDetail?.SchedulerNote : ""}</span>
						</p>
					</div>
					<div className="col-5 mt-2">
						<p style={{ color: "rgb(112 112 112)", fontSize: "13px", marginTop: "5rem" }}>
							<span style={{ color: "black" }}>Account :</span>{option.siteDetail ? (option.siteDetail.account) || option.siteDetail.ndResult ? option.siteDetail.ndResult.account : "" : ""}<br />
							{/* <span style={{ color: "black" }}>Market :</span>{option.siteDetail?option.siteDetail.pUniqueIdDetails?(option.siteDetail.pUniqueIdDetails[0].market)|| (option.siteDetail.ndResult?option.siteDetail.ndResult.market:""):"":""}<br /> */}
							<span style={{ color: "black" }}>Market :</span>{option?.siteDetail?.ndResult?.market}<br />
							<span style={{ color: "black" }}>MOP :</span> {option.siteDetail ? option.siteDetail.sheduleShift || option.siteDetail.dayNightMop : ""}<br />
							<span style={{ color: "black" }}>Pre-Integration :</span> {option.siteDetail ? option.siteDetail.SchedulerPreIntegration || (option.siteDetail.techtake ? option.siteDetail.techtake.join(",") : "") : ""}
						</p>
					</div>
				</div>
				{/* <div className="mt-4 d-flex justify-content-center align-items-center">
					<Link  to ={`/PAG/ix/module/FLogin/${option.siteDetail?option.siteDetail.uniqueid:""}`} className="btn text-white" style={{ background: "#3a86ff" }}>
						FE Login
						
					</Link>
					<Link to ={`/PAG/ix/module/FLogout/${option.siteDetail?option.siteDetail.uniqueid:""}`} className="btn text-white" style={{ background: "green", marginLeft: "0.8rem" }}>
						FE Logout
					</Link>
				</div>
				<div className="mt-3 d-flex justify-content-center align-items-center">
					<Link to ={`/PAG/ix/module/ILogin/${option.siteDetail?option.siteDetail.uniqueid:""}`} className="btn btn-success text-white" style={{background:"#8338ec"}}>Integration Login</Link>
					<Link to ={`/PAG/ix/module/ILogout/${option.siteDetail?option.siteDetail.uniqueid:""}`} className="btn text-white" style={{ background: "#fb5607", marginLeft: "0.8rem" }}>
						Integration Logout
					</Link>
				</div>
				<div className="mt-3 d-flex justify-content-center align-items-center">
					<button className="btn text-white" style={{ background: "#ff006E" }}>
						Timeline
					</button>
				</div> */}
				<h6 style={{ color: "black" }} className="mt-5">
					Script <span className="text-danger"> *</span>
				</h6>
				<div className="d-flex align-items-center mt-2">
					<input type="file" multiple name="script" placeholder="Choose a file" style={{ maxWidth: "230px", borderRadius: "10px", display: "block", padding: "5px", fontSize: "13px", border: "1px solid #2b2b2b" }} onChange={uploadCsvFile} />
					<button className="btn btn-dark text-white" style={{ height: "36px", fontSize: "11px", marginLeft: "1rem" }} onClick={() => submitHandler(option.siteDetail.ranSupportId)}
					>
						Upload Script

					</button>
					{
						option.siteDetail.ixScriptFile ?
							<a href={baseURL + "/uploads/" + option.siteDetail.ixScriptFile} className="badge badge-success" download><Download /></a>
							: ""
					}
				</div>
			</div>

			<CommonModal
				modalSize={"md"}
				setXlShow={setcommonModalState}
				xlShow={commonModalState}
				Body={<AdvOneSiteMapView siteDetail={option.siteDetail.ndResult} />}
				Header={"Site Location"}
				Title={""}
			></CommonModal>
			<CommonModal
				modalSize={"md"}
				setXlShow={setModal}
				xlShow={modalBdy}
				Body={modalContent}
				Header={heading.current}
				Title={""}
			></CommonModal>

			{/* <AdvOneSiteMapView siteDetail={option.siteDetail.pUniqueIdDetails?option.siteDetail.pUniqueIdDetails[0]:""}/> */}
		</div>

	);
};

export default IXCalendar;
