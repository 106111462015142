import { Settings } from '@mui/icons-material';
import React,{useEffect,useState} from 'react'
import { useDispatch,useSelector } from 'react-redux';
import { ranDashboard } from '../../../common/config';
import { AccountReducerAction, FetchSingleRecordAction } from '../../../store/actions/createTicketManageFields';

const Table1 = ({id}) => {
  const dispatch =useDispatch()
  // let siteDetail={siteInfo:{}}
   let siteInfo={}
// const[data,setData]=useState([]);
  // let  siteDetail={siteInfo:{}}
  let siteDetail =useSelector((state)=>{
  console.log("the url =",state)
let site =state.OneCommonResponse.singleSite

if(typeof(site)== typeof({}) ){
  siteInfo=site
  console.log("th e sit e is sit=",siteInfo)
  return {siteInfo}
}

})
  console.log("the site data is =",siteDetail? siteDetail.siteInfo:"")
  return (
    <div >   
        <table className="table-bordered " style={{ width: "100%" }}>
            <tr>
                
            <th>Sow Details:</th>
                      <td>  {
                        siteDetail? siteDetail.siteInfo.sowDetails?siteDetail.siteInfo.sowDetails.values.map((item)=>{
                          return item+","
                        })
                        :'':''
                      }
                      </td>
            </tr>
        
                 
            <tr>
                
            <th>Sow Comment:</th>
            <td >{siteDetail? siteDetail.siteInfo.sowComments:""}</td>
            </tr>
            <tr>
                
            <th>Nesting Time:</th>
            <td >{siteDetail? siteDetail.siteInfo.nestingTime:""}</td>
            </tr>
              
            </table>
            </div>
  )
}

export default Table1;