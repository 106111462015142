// import React, { useEffect, useState, ComponentType, type } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import RanSiteTracker from "../ProjectTrackerComponent/RanSiteTracker";
// import { mopDashSiteStatus, projectList, projectListApi, ranSiteStatus, ttDashSiteStatus } from "../../../common/config";
// import { AccountReducerAction } from "../../../store/actions/createTicketManageFields";
// import { Tab, Tabs } from "react-bootstrap";
// import Mop from "../Mop/Mop";
// import { getMuiThemeCommon } from "../../../common/variables";
// import { ThemeProvider } from "@mui/material";
// import MUIDataTable from "mui-datatables";


// const ProjectList = () => {



//     const dispatch = useDispatch();


//     const [ResetFilter, setResetFilter] = useState(false);
//     const [fullUrL, setfullUrL] = useState('');

//     const [exportApiData, setexportApiData] = useState("");
//     const [counter, setcounter] = useState("");

//     const count = useSelector((state) => {

//         let ProjectListResp = state.CommonResponse.ProjectList;


//         return { ProjectListResp };
//     })


//     console.log(count)



//     useEffect(() => {

//         dispatch(AccountReducerAction(projectListApi))
//         // dispatch(AccountReducerAction(ttDashSiteStatus))
//         // dispatch(AccountReducerAction(ranSiteStatus));
//     }, [counter])

//     return (
//         <>
//             <h1>Hello</h1>
//             {count.ProjectListResp.map((itm) => {
//                 return itm["_id"]
//             })}


//             <ThemeProvider theme={getMuiThemeCommon()}>

//                 <MUIDataTable

//                     title={"Manage Account"}
//                     data={data.accountArry}
//                     columns={columns}
//                     options={advancdExportOnecustomDataTableOptionObject(AddCustomButton, true, "Account_", "", false, "Account Name", false)}
//                 // options={optios}
//                 />
//             </ThemeProvider>
//         </>
//     );
// };

// export default ProjectList





import React, { useRef, useState } from 'react'
import MUIDataTable from "mui-datatables";
import { useEffect } from 'react';
import { Row, Card, Col, Dropdown, Button, Modal, Container } from "react-bootstrap";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { accountApi, addAcccount, deleteAcccount, projectListApi } from '../../../common/config';
import swal from 'sweetalert';
import { advancdExportOnecustomDataTableOptionObject, customDataTableOptionObject, getMuiThemeCommon } from "../../../common/variables";
import { AccountReducerAction, OneAccountReducerAction, addAcccountReducerAction, deleteAcccountReducerAction, popUpUpdate, setOneProjectList, updateAcccountReducerAction } from '../../../store/actions/createTicketManageFields';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const ProjectList = () => {
    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                <circle fill="#000000" cx="19" cy="12" r="2"></circle>
            </g>
        </svg>
    );
    let row = [];
    let objectId = ''
    const [modalCentered, setModalCentered] = useState(false);
    const [errors, setErrors] = useState({});
    const [dValue, setDvalue] = useState('')
    const [rowId, setRowId] = useState('')
    const [checkAddUpdate, setAddUpdate] = useState(true)
    const [closer, setCloser] = useState(true)
    const [counter, setcounter] = useState("");

    //  const [newAccount,setAccount]=useState('')
    const newAccount = useRef()
    let oneaccount = ""

    let history=useHistory()


    // setAccount(oneaccount)
    console.log(oneaccount, "oneaccount")
    const getMuiTheme = () => createTheme({
        components: {
            MUIDataTableBodyCell: {
                styleOverrides: {
                    root: {
                        backgroundColor: "transparent"
                    }
                }
            }
        }
    })
    const updateHandler = (e) => {
        console.log("button update clicked", e)

        dispatch(updateAcccountReducerAction(addAcccount, e, { accountName: newAccount }))

    }
    const deleteHandler = (e) => {
        console.log("button delete clicked", e)

        swal({
            title: "Are you sure?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(deleteAcccountReducerAction(deleteAcccount, e))
                setCloser(true)
            }
        });

    }


    const editpopupHandler = (e) => {
        console.log("lkm kljnkjnjkbi;ki=", e)
        console.log("button editpopupHandler clicked", e)
        // dispatch(OneAccountReducerAction(accountApi+"/"+e))
        setErrors({})
        setModalCentered(true)
        // dispatch(deleteAcccountReducerAction(deleteAcccount,e))
    }
    const updateAccount = () => {
        console.log("object id =", rowId)
        if (newAccount.current.value == "") {
            setErrors({ "account": "Account is Manodatry" })
            return false
        }
        dispatch(updateAcccountReducerAction(addAcccount, rowId, { accountName: newAccount.current.value }))
        setCloser(true)
    }
    const addAccount = () => {
        if (newAccount.current.value == "") {
            setErrors({ "account": "Account is Manodatry" })
            return false
        }
        dispatch(addAcccountReducerAction(addAcccount, { accountName: newAccount.current.value }))
        setCloser(true)
        console.log("input field=", newAccount)
    }
    const dispatch = useDispatch();
    let accountArry = []


    // let datava =useSelector((state)=>{

    // });


    const nextPageHandler = (mark) => {

        // dispatch(AccountReducerAction(listtroubleTicketApi + "/" + mark));
        dispatch(setOneProjectList([]))
        history.push("/PAG/ViewProjectList/" + mark)

    }

    let data = useSelector((state) => {
        console.log("dfwef=", state)


        let totalCount=state.countState.listplCount

        if (state.popUpState) {
            console.log(state.popUpState.popstatus.status, closer, "popUpStatestatus")
            if ((state.popUpState.popstatus.status == 201 && closer) || (state.popUpState.popstatus.status == 200 && closer)) {
                setCloser(false)
                dispatch(popUpUpdate({}))
                // dispatch(AccountReducerAction(accountApi))
                setModalCentered(false)
                // newAccount.current.value=''
                setDvalue('')

            }
        }
        let ProjectListResp = state.CommonResponse.ProjectList

        let final_sending_data=[]

        let sNoNumber=1

        console.log(ProjectListResp,"ProjectListResp")

        if(ProjectListResp && ProjectListResp){
            ProjectListResp?.map((oneItm)=>{
                console.log(oneItm,"dasdasdasdasdsa")
                console.log([... new Set(oneItm.dataColl)].join(","),"oneItmoneItmProjectListResp")
                let final_innner_sending_data={}
                final_innner_sending_data["exist"]=[... new Set(oneItm.dataColl)].join(", ")

                // oneItm.data.map((innerOneItm)=>{
                    console.log(oneItm,"oneItmoneItm")
                    
                    final_innner_sending_data["edit"]=<><button className="btn btn-primary shadow btn-xs sharp mr-1 editButton" onClick={(e)=>{nextPageHandler(oneItm.data[0].data.siteIdCommon)}}><i className="fa fa-eye"></i></button></>
    
                    final_innner_sending_data["siteIdCommon"]=oneItm.data[0].data.siteIdCommon
                    final_innner_sending_data["account"]=oneItm.data[0].data.account
                    final_innner_sending_data["market"]=oneItm.data[0].data.market
                    final_innner_sending_data["sNo"]=sNoNumber
                    
                // })
    
                sNoNumber=sNoNumber+1
                final_sending_data.push(final_innner_sending_data)
    
            })
        }
        


        return { ProjectListResp,final_sending_data,totalCount }
    })

    // const noOfRow = data.accountArry.length
    // data.accountArry.push(
    //   {   
    //       sNo:noOfRow+1,
    //       account:<input type='text' placeholder='Add Account' value={newAccount} onChange={(e)=>setAccount(e.target.value)}/>,
    //       edit : <button onClick={()=>addAccount()}>Add Account</button>

    //   }
    // )

    console.log("data =", data)
    useEffect(() => {
        
        dispatch(AccountReducerAction(projectListApi))
    },[counter])
    const columns = [
        {

            name: "sNo",
            label: "S.no",
            options: {
                filter: false,
                sort: false,
                searchable: false,
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "wrap",
                        width: "20px",
                        alignItem: "center",
                        background: "#143b64",
                        color: "white"

                    }
                })
            }
        },
        {
            name: "siteIdCommon",
            label: "Site Id",
            options: {
                filter: false,
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "wrap",
                        alignItem: "center",
                        width: "70px",
                        background: "#143b64",
                        color: "white"

                    }
                })
            }
        },
        {
            name: "account",
            label: "Account",
            options: {
                filter: false,
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "wrap",
                        alignItem: "center",
                        background: "#143b64",
                        width: "70px",
                        color: "white"

                    }
                })
            }
        },
        {
            name: "market",
            label: "Market",
            options: {
                filter: false,
                sort: false,
                print: false,
                searchable: false,
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "wrap",
                        alignItem: "center",
                        width: "70px",
                        background: "#143b64",
                        color: "white"

                    }
                })
            }
        },{
            name: "exist",
            label: "Activities",
            options: {
                filter: false,
                sort: false,
                print: false,
                searchable: false,
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "wrap",
                        alignItem: "center",
                        width: "20px",
                        background: "#143b64",
                        color: "white"

                    }
                })
            }
        },{
            name: "edit",
            label: "View",
            options: {
                filter: false,
                sort: false,
                print: false,
                searchable: false,
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "wrap",
                        alignItem: "center",
                        width: "20px",
                        background: "#143b64",
                        color: "white"

                    }
                })
            }
        },

    ];

    const handleChange = (e) => {
        console.log("the current anser= ", e.target.value)
    }

    const AddCustomButton = () => (
        <fregment style={{ float: "right", display: "flex", flexDirection: row, justifyContent: "space-between" }}>
            {/* <Button
                variant="primary btn-xs  "
                type="button"
                className="btn btn-primary btn-xs mx-2 py-2 my-3"
                onClick={() => { setModalCentered(true); setDvalue(''); setErrors({}); setAddUpdate(true) }}>
                Add Account
            </Button>
            <Modal className="fade" show={modalCentered}>
                <Modal.Header>
                    {checkAddUpdate ? <Modal.Title>Add Account</Modal.Title> : <Modal.Title>Update Record</Modal.Title>}
                    <Button
                        onClick={() => setModalCentered(false)}
                        variant=""
                        className="close">
                        <span>&times;</span>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <input type='text' placeholder='Add Account' required ref={newAccount} defaultValue={dValue} />

                    {errors && errors.account ? <p className="error">{errors.account}</p> : ""}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => setModalCentered(false)}
                        variant="danger btn-xs"
                    >
                        Close
                    </Button>
                    {checkAddUpdate ? <Button onClick={() => { addAccount() }} variant="primary btn-xs">Save changes</Button>
                        : <Button onClick={() => { updateAccount() }} variant="primary btn-xs">Update changes</Button>
                    }
                </Modal.Footer>
            </Modal> */}

        </fregment>
    )
    //    const data = [
    //     { name: "Joe James", company: "In Progress", city: "Yonkers", state: "NY" },
    //     { name: "John Walsh", company: "Completed", city: "Hartford", state: "CT" },
    //     { name: "Bob Herm", company: "Awaited", city: "Tampa", state: "FL" },
    //     { name: "James Houston", company: "NA", city: "Dallas", state: "TX" },
    //    ];

    const options = {
        download: "false",
        filterType: 'none',
        selectableRows: false
    };
    const optios = {
        filterType: "dropdown",
        responsive: "scroll"
    };
    return (
        <>




            <ThemeProvider theme={getMuiThemeCommon()}>

                <MUIDataTable

                    title={"Project List"}
                    data={data.final_sending_data}
                    columns={columns}
                    // options={customDataTableOptionObject(AddCustomButton, true, "Account_", "", false, "Account Name", false)}
                    // options={customDataTableOptionObject(AddCustomButton,true,"Project_List_","","",false,"projectList","",data.userArry.length,data.totalCount,true)}
                    options={customDataTableOptionObject(AddCustomButton,true,"Project_List_","","",false,"projectList","",data.final_sending_data.length,data.totalCount,true)}
                // options={optios}
                />
            </ThemeProvider>
        </>
    )
}

export default ProjectList