import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import CustomClearIndicator from '../../PluginsMenu/Select2/MultiSelect'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { colourOptions } from '../../PluginsMenu/Select2/data';
import { default as ReactSelect, components } from "react-select";
import { useDispatch, useSelector } from 'react-redux';
import { AccountReducerAction, addUserAction } from '../../../../store/actions/createTicketManageFields';
import { getAllDropDown, ranDashboard, reqPreCheck } from '../../../../common/config';
const RequestPreCheck = ({ id }) => {
  const [sow, setSow] = useState()
  const url = window.location.href.split("/")
  let dashboardType=url.slice(-2)[0]
  console.log("bdwkcbwdkjcbwhdvwgfcvwiebdw=",dashboardType)
  const dispatch = useDispatch();
  let sowDetailArray = [
    {
      value: "Preparation Work",
      label: "Preparation Work"
    },
    {
      value: "Antenna Replacement",
      label: "Antenna Replacement"
    },
    {
      value: "Power Upgrade",
      label: "Power Upgrade"
    },
    {
      value: "Integration",
      label: "Integration"
    },
    {
      value: "Other",
      label: "Other"
    }
  ]
  // const response =useSelector((state)=>{
  //     console.log("the stae is =",state)
  //     let dropDowns =state.CommonResponse.allDropDown
  //     if(dropDowns && dropDowns.length>0){
  //          dropDowns.map((dropDown)=>{
  //             if(dropDown.dropDownName=="soeDetails"){
  //                 let options =dropDown.options
  //                 if(options && options.length>0){
  //                     sowDetailArray=options.map((value)=>{
  //                         return {
  //                             value:value.value,
  //                             label:value.value
  //                         }
  //                     })

  //                 }
  //             }
  //         })
  //     }
  //     return {sowDetailArray}
  //})    
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = async (data) => {
    let sowArry = { values: [] }
    sowArry.values = sow.map((item) => {
      return item.value
    })
    data['sowDetails'] = sowArry
    console.log("the u is =", id)
if(dashboardType=="ix"){
  dispatch(addUserAction(reqPreCheck + id+"?ix=True", data))
}
else{
  dispatch(addUserAction(reqPreCheck + id, data))
  
}
    // dispatch(AccountReducerAction(ranDashboard))
    console.log(" the modal form data", data)
  }
  const handleSowChanges = (e) => {
    setSow(e)
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="mb-3" >
        <Form.Label>Select SOW Details </Form.Label>

        <ReactSelect
          options={sowDetailArray}
          // styles={customStyles}
          isMulti
          name="colors"
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{ Option }}
          onChange={handleSowChanges}
          value={sow}
          allowSelectAll={true}
          variant="danger"
          classNamePrefix="select"
        />
      </Form.Group>
      <Form.Group
        className="mb-3"

      >
        <Form.Label>Enter SOW Comment</Form.Label>
        <Form.Control as="textarea" rows={3}

          {
          ...register("sowComments")
          }
        />
      </Form.Group>
      <Form.Group className="mb-3" >
        <Form.Label>Enter Nesting Time</Form.Label>
        <Form.Control
          type="number"
          placeholder=""
          autoFocus
          min={1}
          max={24}
          {
          ...register("nestingTime")
          }
        />
      </Form.Group>
      <div className='d-flex justify-content-center align-items-center'>
        <Button variant="primary" style={{ fontSize: "12px", padding: "6px 10px" }} type='submit'>
          Request PreCheck
        </Button>
      </div>
    </Form>
  )
}

export default RequestPreCheck