import React from 'react'

const PostTracker = () => {
  return (
    <>
        <h4>Post IX RF Support Tracker</h4>
    </>
  )
}

export default PostTracker