import React, { useRef, useState } from 'react'
import MUIDataTable from "mui-datatables";
import { useEffect } from 'react';
import { Row, Card, Col, Dropdown, Button, Modal, Container } from "react-bootstrap";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { crewCompanyApi, addCrewCompany, deleteCrewCompany, vendorApi } from '../../../../common/config';
import { AccountReducerAction, OneAccountReducerAction, addAcccountReducerAction, deleteAcccountReducerAction, updateAcccountReducerAction, popUpUpdate } from '../../../../store/actions/createTicketManageFields';
import { useDispatch, useSelector } from 'react-redux';
import { google_map_api, center, dbdatetodate, customDataTableOptionObject, advancdcustomDataTableOptionObject, rancustomDataTableOptionObject, advancdExportOnecustomDataTableOptionObject, getMuiThemeCommon } from "../../../../common/variables";
import { useForm } from 'react-hook-form';
import { MultiSelect } from 'react-multi-select-component';
import CommonBulkUploader from '../../Custom/CommonBulkUploader';
const ManageCrewCompany = () => {
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  let objectId = ''
  const [modalCentered, setModalCentered] = useState(false);
  const [closer, setCloser] = useState(false);

  const [dValue, setDvalue] = useState('')
  const [rowId, setRowId] = useState('')
  let [vendorSelect, setvendorSelect] = useState([])

  const [checkAddUpdate, setAddUpdate] = useState(true)
  //  const [newAccount,setAccount]=useState('')
  const newAccount = useRef()
  let oneaccount = ""


  // setAccount(oneaccount)
  console.log(oneaccount, "oneaccount")
  const getMuiTheme = () => createTheme({
    components: {
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            backgroundColor: "transparent"
          }
        }
      }
    }
  })
  const updateHandler = (e) => {
    console.log("button update clicked", e)

    dispatch(updateAcccountReducerAction(addCrewCompany, e, { accountName: newAccount }))

  }
  const deleteHandler = (e) => {
    console.log("button delete clicked", e)
    setCloser(true)
    dispatch(deleteAcccountReducerAction(deleteCrewCompany, e))

  }


  const editpopupHandler = (e) => {
    console.log("lkm kljnkjnjkbi;ki=", e)
    console.log("button editpopupHandler clicked", e)
    // dispatch(OneAccountReducerAction(accountApi+"/"+e))

    setModalCentered(true)
    setValue("crewCompanyName", e.crewCompanyName)
    setValue("emailCC", e.emailCC)
    setValue("emailTo", e.emailTo)
    let data = e.vendor ? e.vendor.values.map((it) => {
      return {
        "value": it,
        "label": it
      }
    }) : []
    setvendorSelect(data)
    setValue("crewCompanyName", e.crewCompanyName)
    // dispatch(deleteAcccountReducerAction(deleteAcccount,e))




  }


  const addpopupHandler = (e) => {
    console.log("lkm kljnkjnjkbi;ki=", e)
    console.log("button editpopupHandler clicked", e)
    // dispatch(OneAccountReducerAction(accountApi+"/"+e))

    setModalCentered(true)
    setValue("crewCompanyName", "")
    setValue("emailCC", "")
    setValue("emailTo", "")

    setvendorSelect([])
    // dispatch(deleteAcccountReducerAction(deleteAcccount,e))

  }
  const updateAccount = () => {
    console.log("object id =", rowId)
    dispatch(updateAcccountReducerAction(addCrewCompany, rowId, { crewCompanyName: newAccount.current.value }))

    setModalCentered(false)
  }
  const onSubmit = async (data) => {


    let seven = { values: [] }
    seven.values = vendorSelect.map((item) => {
      return item.value
    })
    data['vendor'] = seven
    console.log("Add Account=", newAccount)
    // newAccount.current.value=''
    if (checkAddUpdate) {
      dispatch(addAcccountReducerAction(addCrewCompany, data))
      // setModalCentered(false)
    } else {
      dispatch(updateAcccountReducerAction(addCrewCompany, rowId, data))
      // setModalCentered(false)
    }

    setCloser(true);
    dispatch(popUpUpdate({}));

  }
  const dispatch = useDispatch();

  let vendorArray = []
  let technologyArray = []




  const VendorHandler = (e) => {
    console.log("Pow multiple select=", e);
    setvendorSelect(e);
  };
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({});

  let accountArry = []



  // const onSubmit = async (data) => {
  //   console.log("data is ===", data);
  // }/

  // let datava =useSelector((state)=>{

  // });

  let data = useSelector((state) => {
    console.log("dfwef=", state)
    let neeaccount = state.CommonResponse.accounts
    if (neeaccount.length != 0 && newAccount == "") {
      oneaccount = neeaccount[0].accountName

    }


    if (state.popUpState) {
      // console.log(state.popUpState.popstatus.status==201,"popUpStatestatus")
      if ((state.popUpState.popstatus.status == 201 && closer)||(state.popUpState.popstatus.status == 200 && closer)) {
        setCloser(false);
        dispatch(popUpUpdate({}));
        dispatch(AccountReducerAction(crewCompanyApi))
        setModalCentered(false);
      }
      console.log(state.popUpState.popstatus.status == 409 , closer,"state.popUpState.popstatus.status == 409 && closer")
      if (state.popUpState.popstatus.status == 409) {
        setCloser(false)
        dispatch(popUpUpdate({}))
        dispatch(AccountReducerAction(crewCompanyApi))
        // setModalCentered(false)
            
      }
    }


    let vendorroledata = state.CommonResponse.vendor
    if (vendorroledata != 0) {
      vendorArray = vendorroledata.map((item) => {
        console.log("vendor", item.vendorName)
        return {
          "value": item.vendorName,
          "label": item.vendorName
        }
      })

    }

    let account = state.CommonResponse.crewCompany
    console.log("response =dscvfsfvd=", state.CommonResponse)
    if (account.length != 0) {
      accountArry = account.map((item, index) => {
        return {
          sNo: index + 1,
          crewCompany: item.crewCompanyName,
          vendor: item.vendor ? item.vendor.values ? item.vendor.values.join(", ") : "" : "",
          emailCC: item.emailCC,
          emailTo: item.emailTo,
          edit: <><button className="btn btn-primary shadow btn-xs sharp mr-1 editButton" onClick={() => { editpopupHandler(item); setDvalue(item); setAddUpdate(false); setRowId(item.uniqueid) }}><i className="fa fa-pencil"></i></button>
            <button onClick={(e) => deleteHandler(item.uniqueid)} className="btn btn-danger shadow btn-xs sharp deleteButton"><i className="fa fa-trash"></i></button></>
          //   <Dropdown>

          //   <Dropdown.Toggle
          //     variant="success"
          //     className="light sharp i-false"
          //   >
          //     {svg1}
          //   </Dropdown.Toggle>
          //   <Dropdown.Menu>

          //     <Dropdown.Item onClick={() =>{ editpopupHandler(item); setDvalue(item.accountName);setAddUpdate(false);setRowId(item.uniqueid)}}>Edit</Dropdown.Item>
          //     <Dropdown.Item onClick={(e)=>deleteHandler(item.uniqueid)}>Delete</Dropdown.Item>
          //   </Dropdown.Menu>
          // </Dropdown>
          // <button value={item.uniqueid} onClick={(e)=>updateHandler(e)}>Click me</button>

        }
      })
    }


    console.log(accountArry, vendorArray, "vendorArray")

    return { accountArry, vendorArray }
  })

  const noOfRow = data.accountArry.length
  // data.accountArry.push(
  //   {   
  //       sNo:noOfRow+1,
  //       account:<input type='text' placeholder='Add Account' value={newAccount} onChange={(e)=>setAccount(e.target.value)}/>,
  //       edit : <button onClick={()=>addAccount()}>Add Account</button>

  //   }
  // )

  console.log("data =", data)
  useEffect(() => {
    dispatch(AccountReducerAction(crewCompanyApi))
    dispatch(AccountReducerAction(vendorApi))
  }, [newAccount])
  const columns = [
    {

      name: "sNo",
      label: "S.No.",
      options: {
        filter: false,
        sort: false,
        searchable:false,
        setCellHeaderProps: () => ({
          style: {

            alignItem: "center",
            background: "#143b64",
            color: "white",
            minWidth:"50px",
            maxWidth:"50px"

          }
        })
      }
    },
    {
      name: "crewCompany",
      label: "Crew Company",
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {

            alignItem: "center",
            background: "#143b64",
            color: "white"

          }
        })
      }
    },
    {
      name: "vendor",
      label: "Vendor",
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {

            alignItem: "center",
            background: "#143b64",
            color: "white",
            minWidth:"100px",
            maxWidth:"100px"

          }
        })
      }
    },
    {
      name: "emailTo",
      label: "Email To",
      options: {
        filter: true,

        setCellHeaderProps: () => ({
          style: {

            alignItem: "center",
            background: "#143b64",
            color: "white",
            minWidth:"100px",
            maxWidth:"100px"

          }
        })
      }
    },
    {
      name: "emailCC",
      label: "Email CC",
      options: {
        filter: true,
        setCellHeaderProps: () => ({
          style: {

            alignItem: "center",
            background: "#143b64",
            color: "white",
            minWidth:"100px",
            maxWidth:"100px"

          }
        })
      }
    },
    {
      name: "edit",
      label: "Action",
      sortable: true,
      options: {
        filter: false,
        sort: false,
        print:false,
        searchable:false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "max-width": "300px",
            "min-width": "300px",
           background:"#143b64",
            color:"white"

          }
        })
      }
    }
    // {
    //   name: "edit",
    //   label: "Action",
    //   options: {
    //     filter: true,
    //     sort: false,
    //     print:false,
    //     setCellHeaderProps: () => ({
    //       style: {

    //         alignItem: "center",
    //         background: "#143b64",
    //         color: "white",
    //         minWidth:"100px",
    //         maxWidth:"100px"

    //       }
    //     })
    //   }
    // },

  ];

  let row = [];

  const handleChange = (e) => {
    console.log("the current anser= ", e.target.value)
  }

  const AddCustomButton = () => (
    <fregment style={{ float: "right", display: "flex", flexDirection: row, justifyContent: "space-between" }}>
      <Button
        variant="primary btn-xs mb-2"
        type="button"
        className="btn btn-primary btn-xs mx-2 py-2 my-3"
        onClick={() => { setModalCentered(true); addpopupHandler(); setDvalue(''); setAddUpdate(true) }}>
        Add Crew Company
      </Button>
      
      <CommonBulkUploader typeForm={"Crew Company"} shortCode={"CrewCompany"} classes={'btn btn-primary btn-xs mx-2 py-2 my-3'} afterLoad={crewCompanyApi}/>

      <Modal className="fade" show={modalCentered}>
        <Modal.Header>
          {checkAddUpdate ? <Modal.Title>Add Crew Company</Modal.Title> : <Modal.Title>Update Record</Modal.Title>}
          <Button
            onClick={() => setModalCentered(false)}
            variant=""
            className="close">
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>

          <form onSubmit={handleSubmit(onSubmit)}>


            <div className="form-group col-md-12">
              <label for="exampleFormControlSelect1">
                {" "}
                Company Name
              </label>
              <input style={{ height: "32px" }}
                type="text"
                {...register("crewCompanyName", {
                  required: "Please enter Company Name"
                })}

                className="form-control"
                ID="inputAddress"
                placeholder="Enter Company Name"
              />
              {errors.crewCompanyName && <p className="error">{errors.crewCompanyName.message}</p>}
            </div>
            <div className="form-group col-md-12">
              <label htmlFor="inputEmail4">Vendor </label>

              <MultiSelect
                options={data.vendorArray}
                onChange={VendorHandler}
                value={vendorSelect}
              />
              {/* {errors.crewCompanyName && <p className="error">{errors.crewCompanyName.message}</p>} */}
              

            </div>

            <div className="form-group col-md-12">
              <label for="exampleFormControlSelect1">
                {" "}
                Email To
              </label>
              <input style={{ height: "32px" }}
                type="text"
                {...register("emailTo",{
                  required: "Please enter Email TO"
                })}

                className="form-control"
                ID="inputAddress"
                placeholder="Enter Email To"
              />
              
              {errors.emailTo && <p className="error">{errors.emailTo.message}</p>}
            </div>
            <div className="form-group col-md-12">
              <label for="exampleFormControlSelect1">
                {" "}
                Email CC
              </label>
              <input style={{ height: "32px" }}
                type="text"
                {...register("emailCC",{
                  required: "Please enter Email CC"
                })}

                className="form-control"
                ID="inputAddress"
                placeholder="Enter Email CC "
              />
              
              {errors.emailCC && <p className="error">{errors.emailCC.message}</p>}
            </div>


            <button type="submit" className="btn btn-primary btn-xs">
              Submit
            </button>

          </form>
        </Modal.Body>
        {/* <Modal.Footer>
            {/* <Button
              onClick={() => setModalCentered(false)}
              variant="danger  btn-xs"
            >
              Close
            </Button>
            {checkAddUpdate ? <Button type="submit" variant="primary btn-xs">Save changes</Button>
              : <Button onClick={() => { updateAccount() }} variant="primary btn-xs">Update changes</Button>
            } 

          </Modal.Footer> */}
      </Modal>
    </fregment>
  )

  //    const data = [
  //     { name: "Joe James", company: "In Progress", city: "Yonkers", state: "NY" },
  //     { name: "John Walsh", company: "Completed", city: "Hartford", state: "CT" },
  //     { name: "Bob Herm", company: "Awaited", city: "Tampa", state: "FL" },
  //     { name: "James Houston", company: "NA", city: "Dallas", state: "TX" },
  //    ];

  console.log("lkjnbvbvhdsaufvcjsd==",data.accountArry)
  console.log("lkjnbvbvhdsaufvcjsd==",columns)
  const options = {
    download: "false",
    filterType: 'none',
    selectableRows: false
  };

  return (
    <>




      <ThemeProvider theme={getMuiThemeCommon()}>

        <MUIDataTable

          title={"Crew Company"}
          data={data.accountArry}
          columns={columns}
          // options={advancdcustomDataTableOptionObject(AddCustomButton,true ,"Search By Time Zone" ,"Crew Companies")}
          options={advancdExportOnecustomDataTableOptionObject(AddCustomButton,true,"CrewCompany_","",false,"Crew Company",false)}
        />
      </ThemeProvider>
    </>
  )
}

export default ManageCrewCompany