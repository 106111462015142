import React, { useEffect, useState } from 'react'
import PieScore from '../Custom/ScoreCardComponent/PieScore'
import ScoreBarChart from '../Custom/ScoreCardComponent/ScoreBarChart'
import CommonTable from '../Custom/CommonTable'
import { common } from '../../../services/AdminService'
import { gameOfFilter, scoreCard, secondNest } from '../../../common/config'
import PieFilterData from '../Custom/ScoreCardComponent/PieFilterData'
import PieScoreUpdated from '../Custom/ScoreCardComponent/PieScoreUpdated'
import PunctualityRateStageFourScoreBarChart from '../Custom/ScoreCardComponent/PunctualityRateStageFourScoreBarChart'
import PunctualityRateStageThreeBarChart from '../Custom/ScoreCardComponent/PunctualityRateStageThreeBarChart'
import PunctualityRateStageTwoBarChart from '../Custom/ScoreCardComponent/PunctualityRateStageTwoBarChart'
import ScoreBarChartLine from '../Custom/ScoreCardComponent/ScoreBarChartLine'
import ExistPunctualityRateStageFourScoreBarChart from '../Custom/ScoreCardComponent/ExistPunctualityRateStageFourScoreBarChart'
import TWOTOPunctualityRateStageFourScoreBarChart from '../Custom/ScoreCardComponent/TWOTOPunctualityRateStageFourScoreBarChart'
import { AccountReducerAction, UPDATEINTERNALFILTER, getBarChartData } from '../../../store/actions/createTicketManageFields'
import { useDispatch } from 'react-redux'

const ScoreCard = () => {

  const dispatch = useDispatch()

  const [pieHideOrUnHide, setType] = useState(false)
  const [StageTwo, setStageTwo] = useState(false)
  let [count, setCount] = useState(true)
  let [filter, setFilter] = useState("")
  let [internalfilter, setinternalFilter] = useState("")
  let [innerfilter, setinnerfilter] = useState("")
  const [twopieHideOrUnHide, settwoType] = useState(false)
  let [twocount, settwoCount] = useState(true)
  let [threecount, setThreeCount] = useState(true)
  let [fourcount, setFourCount] = useState(true)

  let [fivercount, setfivercount] = useState(true)
  let [twofilter, settwoFilter] = useState("")
  let [globalPieType, setGlobalPieType] = useState("")

  const [noOfBarChart, setBarData] = useState({
    "XaxisLabel": [],
    "graphData": [],
    "toolTip": []
  })

  const [noOftwoBarChart, settwoBarData] = useState({
    "XaxisLabel": [],
    "graphData": [],
    "toolTip": []
  })


  return (
    <div className='pb-4' >

      <PieScoreUpdated internalfilter={internalfilter} setFilter={setFilter} filter={filter} setfivercount={setfivercount} fivercount={fivercount} count={count} setCount={setCount} twocount={twocount} settwoCount={settwoCount} threecount={threecount} setThreeCount={setThreeCount} fourcount={fourcount} setFourCount={setFourCount} setBarData={setBarData} setStageTwo={setStageTwo} noOfBarChart={noOfBarChart} setType={setType} setGlobalPieType={setGlobalPieType} />
      {/* {StageTwo? */}
      {/* ""} */}

      {/* {
        ["DowntimeRate"].indexOf(globalPieType) != -1 ?
          <><PunctualityRateStageTwoBarChart internalfilter={internalfilter} typeOfChart={"bar"} filter={twofilter} setFilter={settwoFilter} twocount={twocount} setCount={setCount} settwoCount={settwoCount} setThreeCount={setThreeCount} setfivercount={setfivercount} noOfBarChart={noOftwoBarChart} />

            <CommonTable columnName={"columnNameTwo"} rowData={"rowDataTwo"}/></>
          : ""

      } */}


      {
        ["TroubleTicketRate"].indexOf(globalPieType) != -1 ? <>
          <select onClick={((e) => { console.log("clicked") })} onChange={(e) => {
            // alert(e.target.value)
            dispatch(UPDATEINTERNALFILTER(e.target.value))


            dispatch(getBarChartData({}))
            setThreeCount(true)
            setfivercount(true)
            settwoCount(true)
            setCount(true)

            let lclinternalFilter = filter == "" ? "?internalFilter=" : "&internalFilter="
            console.log("clicked", "81")
            // alert(filter)
            if (filter == "") {
              dispatch(AccountReducerAction(secondNest + "TroubleTicketRate" + "/" + "T-Mobile" + filter + lclinternalFilter + e.target.value))
            } else {
              dispatch(AccountReducerAction(gameOfFilter + "TroubleTicketRate" + "/" + "T-Mobile" + filter + lclinternalFilter + e.target.value))
            }
          }} style={{ width: "125px" }}>
            <option value="">Both</option>
            <option>SI</option>
            <option>Degradation</option>
            <option>NSI</option>
          </select>
        </> : ""
      }

      {console.log(internalfilter, "internalfilter")}

      {/* {
            noOfBarChart.map((item)=>{
                return <ScoreBarChart />
            })
        } */}

      {pieHideOrUnHide ?
        <>
          {
            ["SiteDowntimeRate"].indexOf(globalPieType) != -1 ?
              <ScoreBarChartLine internalfilter={internalfilter} globalPieType={globalPieType} filter={filter} setFilter={setFilter} count={count} setCount={setCount} settwoCount={settwoCount} setThreeCount={setThreeCount} setfivercount={setfivercount} noOfBarChart={noOfBarChart} /> :
              <ScoreBarChart internalfilter={internalfilter} globalPieType={globalPieType} filter={filter} setFilter={setFilter} count={count} setCount={setCount} settwoCount={settwoCount} setThreeCount={setThreeCount} setfivercount={setfivercount} noOfBarChart={noOfBarChart} />}


          <CommonTable columnName={"columnName"} rowData={"rowData"} />
        </>
        : <></>
      }
      {
        ["PunctualityRate", "SiteDowntimeRate", "TroubleTicketRate", "SiteGoingoutofApprovedMWRate"].indexOf(globalPieType) != -1 ?
          <><PunctualityRateStageTwoBarChart internalfilter={internalfilter} typeOfChart={"bar"} filter={twofilter} setFilter={settwoFilter} twocount={twocount} setCount={setCount} settwoCount={settwoCount} setThreeCount={setThreeCount} setfivercount={setfivercount} noOfBarChart={noOftwoBarChart} />

            <CommonTable columnName={"columnNameTwo"} rowData={"rowDataTwo"} /></>
          : ""

      }

      {/* {
          ["TroubleTicketRate"].indexOf(globalPieType)!=-1?
          <PunctualityRateStageTwoBarChart typeOfChart={"line"} filter={twofilter} setFilter={settwoFilter} twocount={twocount} setCount={setCount} settwoCount={settwoCount} setThreeCount={setThreeCount} noOfBarChart={noOftwoBarChart} /> : ""
        } */}

      {
        ["PunctualityRate", "TroubleTicketRate", "SiteGoingoutofApprovedMWRate"].indexOf(globalPieType) != -1 ?

          <>
            <PunctualityRateStageThreeBarChart internalfilter={internalfilter} filter={twofilter} setFilter={settwoFilter} threecount={threecount} setCount={setCount} settwoCount={settwoCount} setThreeCount={setThreeCount} setfivercount={setfivercount} noOfBarChart={noOftwoBarChart} />
            <CommonTable columnName={"columnNameThree"} rowData={"rowDataThree"} /></>
          : ""
      }
      {
        ["TroubleTicketRate", "PunctualityRate"].indexOf(globalPieType) != -1 ? <>
          <TWOTOPunctualityRateStageFourScoreBarChart internalfilter={internalfilter} typeOfChart={"bar"} setfivercount={setfivercount} fivercount={fivercount} filter={twofilter} setFilter={settwoFilter} twocount={twocount} setCount={setCount} settwoCount={settwoCount} setThreeCount={setThreeCount} noOfBarChart={noOftwoBarChart} />
          <CommonTable columnName={"columnNameFour"} rowData={"rowDataFour"} />
        </> : ""
      }
      {/* {
          ["PunctualityRate","TroubleTicketRate"].indexOf(globalPieType)!=-1?
          <PunctualityRateStageFourScoreBarChart globalPieType={globalPieType} filter={twofilter} setFilter={settwoFilter} fourcount={fourcount} setFourCount={setFourCount} noOfBarChart={noOftwoBarChart} /> : ""
        } */}






    </div>
  )
}

export default ScoreCard