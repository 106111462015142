// import React, { useEffect, useState, ComponentType, type } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import RanSiteTracker from "../ProjectTrackerComponent/RanSiteTracker";
// import { mopDashSiteStatus, projectList, projectListApi, ranSiteStatus, ttDashSiteStatus } from "../../../common/config";
// import { AccountReducerAction } from "../../../store/actions/createTicketManageFields";
// import { Tab, Tabs } from "react-bootstrap";
// import Mop from "../Mop/Mop";
// import { getMuiThemeCommon } from "../../../common/variables";
// import { ThemeProvider } from "@mui/material";
// import MUIDataTable from "mui-datatables";


// const ProjectList = () => {



//     const dispatch = useDispatch();


//     const [ResetFilter, setResetFilter] = useState(false);
//     const [fullUrL, setfullUrL] = useState('');

//     const [exportApiData, setexportApiData] = useState("");
//     const [counter, setcounter] = useState("");

//     const count = useSelector((state) => {

//         let ProjectListResp = state.CommonResponse.ProjectList;


//         return { ProjectListResp };
//     })


//     console.log(count)



//     useEffect(() => {

//         dispatch(AccountReducerAction(projectListApi))
//         // dispatch(AccountReducerAction(ttDashSiteStatus))
//         // dispatch(AccountReducerAction(ranSiteStatus));
//     }, [counter])

//     return (
//         <>
//             <h1>Hello</h1>
//             {count.ProjectListResp.map((itm) => {
//                 return itm["_id"]
//             })}


//             <ThemeProvider theme={getMuiThemeCommon()}>

//                 <MUIDataTable

//                     title={"Manage Account"}
//                     data={data.accountArry}
//                     columns={columns}
//                     options={advancdExportOnecustomDataTableOptionObject(AddCustomButton, true, "Account_", "", false, "Account Name", false)}
//                 // options={optios}
//                 />
//             </ThemeProvider>
//         </>
//     );
// };

// export default ProjectList





import React, { useRef, useState } from 'react'
import MUIDataTable from "mui-datatables";
import { useEffect } from 'react';
import { Row, Card, Col, Dropdown, Button, Modal, Container } from "react-bootstrap";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { OneprojectList, accountApi, addAcccount, deleteAcccount, projectListApi } from '../../../common/config';
import swal from 'sweetalert';
import { advancdExportOnecustomDataTableOptionObject, customDataTableOptionObject, getMuiThemeCommon } from "../../../common/variables";
import { AccountReducerAction, OneAccountReducerAction, addAcccountReducerAction, deleteAcccountReducerAction, popUpUpdate, setOneProjectList, updateAcccountReducerAction } from '../../../store/actions/createTicketManageFields';
import { useDispatch, useSelector } from 'react-redux';
import { Chrono } from "react-chrono";

import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import CustomSlider from '../Custom/CustomSlider';
import { MultiSelect } from 'react-multi-select-component';
const ViewProjectList = () => {
    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <rect x="0" y="0" width="24" height="24"></rect>
                <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                <circle fill="#000000" cx="19" cy="12" r="2"></circle>
            </g>
        </svg>
    );
    let row = [];
    let objectId = ''
    const [modalCentered, setModalCentered] = useState(false);
    const [PList, setPList] = useState(false);
    const [oldPList, setoldPList] = useState(false);
    const [selectedPList, setselectedPList] = useState([]);
    const [finalselectedPList, setfinalselectedPList] = useState([]);
    const [errors, setErrors] = useState({});
    const [dValue, setDvalue] = useState('')
    const [rowId, setRowId] = useState('')
    const [checkAddUpdate, setAddUpdate] = useState(true)
    const [closer, setCloser] = useState(true)
    const [counter, setcounter] = useState("");
    const [accordianState, setAccordianState] = useState("");
    const [stateCLICK, setstateCLICK] = useState({ value: 0, previous: 0 });

    //  const [newAccount,setAccount]=useState('')
    const newAccount = useRef()
    let oneaccount = ""


    const [value, setValue] = useState(0);
    const [previous, setPrevious] = useState(0);

    const dates = ['Jan 1', 'Feb 1', 'Mar 1', 'Apr 1', 'May 1'];
    const content = ['Event 1', 'Event 2', 'Event 3', 'Event 4', 'Event 5'];

    const onChange = (index) => {
        setValue(index);
        setPrevious(index);
    };


    // setAccount(oneaccount)


    const changePlisting = (data) => {
        // alert(data)
        setselectedPList(data)

        let dtww = data.map((itm) => {
            return itm.label
        })
        setfinalselectedPList(dtww)
    };
    console.log(oneaccount, "oneaccount")
    const getMuiTheme = () => createTheme({
        components: {
            MUIDataTableBodyCell: {
                styleOverrides: {
                    root: {
                        backgroundColor: "transparent"
                    }
                }
            }
        }
    })


    const history = useHistory()
    const { urlsiteid } = useParams();
    console.log()

    const VALUES = [
        '04/26/2023 09:46',
        '05/26/2023 09:46',
        '06/26/2023 09:46',
        '07/26/2023 09:46',
        '08/26/2023 09:46',
        '09/26/2023 09:46',
        '10/26/2023 09:46',
    ];







    const updateHandler = (e) => {
        console.log("button update clicked", e)

        dispatch(updateAcccountReducerAction(addAcccount, e, { accountName: newAccount }))

    }
    const deleteHandler = (e) => {
        console.log("button delete clicked", e)

        swal({
            title: "Are you sure?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(deleteAcccountReducerAction(deleteAcccount, e))
                setCloser(true)
            }
        });

    }


    const editpopupHandler = (e) => {
        console.log("lkm kljnkjnjkbi;ki=", e)
        console.log("button editpopupHandler clicked", e)
        // dispatch(OneAccountReducerAction(accountApi+"/"+e))
        setErrors({})
        setModalCentered(true)
        // dispatch(deleteAcccountReducerAction(deleteAcccount,e))
    }
    const updateAccount = () => {
        console.log("object id =", rowId)
        if (newAccount.current.value == "") {
            setErrors({ "account": "Account is Manodatry" })
            return false
        }
        dispatch(updateAcccountReducerAction(addAcccount, rowId, { accountName: newAccount.current.value }))
        setCloser(true)
    }
    const addAccount = () => {
        if (newAccount.current.value == "") {
            setErrors({ "account": "Account is Manodatry" })
            return false
        }
        dispatch(addAcccountReducerAction(addAcccount, { accountName: newAccount.current.value }))
        setCloser(true)
        console.log("input field=", newAccount)
    }
    const dispatch = useDispatch();
    let accountArry = []

    // let ProjectListResp={}


    // let datava =useSelector((state)=>{

    // });

    let ProjectListResp = []

    let data = useSelector((state) => {
        console.log("dfwef=", state)

        if (state.popUpState) {
            console.log(state.popUpState.popstatus.status, closer, "popUpStatestatus")
            if ((state.popUpState.popstatus.status == 201 && closer) || (state.popUpState.popstatus.status == 200 && closer)) {
                setCloser(false)
                dispatch(popUpUpdate({}))
                // dispatch(AccountReducerAction(accountApi))
                setModalCentered(false)
                // newAccount.current.value=''
                setDvalue('')

            }
        }
        ProjectListResp = state.OneCommonResponse.OneProjectList

        let final_sending_data = []

        // ProjectListResp.map((oneItm) => {


        //     let final_innner_sending_data = {}

        //     oneItm.data.map((innerOneItm) => {
        //         console.log(innerOneItm, "oneItmoneItm")

        //         final_innner_sending_data["siteIdCommon"] = innerOneItm.data.siteIdCommon
        //         final_innner_sending_data["viewButton"] = <><button>View </button></>

        //     })

        //     final_sending_data.push(final_innner_sending_data)

        // })


        if (ProjectListResp.unique && oldPList != ProjectListResp.unique) {
            console.log(ProjectListResp.unique, "ProjectListResp.unique")
            setoldPList(ProjectListResp.unique)
            let dat = ProjectListResp.unique.map((itm) => {
                return {
                    value: itm,
                    label: itm
                }
            })
            setPList(dat)

            // setselectedPList(dat)
        }




        return { ProjectListResp, final_sending_data }
    })


    // const noOfRow = data.accountArry.length
    // data.accountArry.push(
    //   {   
    //       sNo:noOfRow+1,
    //       account:<input type='text' placeholder='Add Account' value={newAccount} onChange={(e)=>setAccount(e.target.value)}/>,
    //       edit : <button onClick={()=>addAccount()}>Add Account</button>

    //   }
    // )

    console.log("data =", data.ProjectListResp)
    useEffect(() => {
        dispatch(AccountReducerAction(OneprojectList + urlsiteid))
    }, [counter])
    const columns = [
        {

            name: "sNo",
            label: "S.no",
            options: {
                filter: false,
                sort: false,
                searchable: false,
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "wrap",
                        width: "130px",
                        alignItem: "center",
                        background: "#143b64",
                        color: "white"

                    }
                })
            }
        },
        {
            name: "siteIdCommon",
            label: "Site Id",
            options: {
                filter: false,
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "wrap",
                        alignItem: "center",
                        background: "#143b64",
                        color: "white"

                    }
                })
            }
        },
        {
            name: "viewButton",
            label: "Action",
            options: {
                filter: false,
                sort: false,
                print: false,
                searchable: false,
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "wrap",
                        alignItem: "center",
                        width: "130px",
                        background: "#143b64",
                        color: "white"

                    }
                })
            }
        },

    ];

    const handleChange = (e) => {
        console.log("the current anser= ", e.target.value)
    }

    const AddCustomButton = () => (
        <fregment style={{ float: "right", display: "flex", flexDirection: row, justifyContent: "space-between" }}>
            <Button
                variant="primary btn-xs  "
                type="button"
                className="btn btn-primary btn-xs mx-2 py-2 my-3"
                onClick={() => { setModalCentered(true); setDvalue(''); setErrors({}); setAddUpdate(true) }}>
                Add Account
            </Button>
            <Modal className="fade" show={modalCentered}>
                <Modal.Header>
                    {checkAddUpdate ? <Modal.Title>Add Account</Modal.Title> : <Modal.Title>Update Record</Modal.Title>}
                    <Button
                        onClick={() => setModalCentered(false)}
                        variant=""
                        className="close">
                        <span>&times;</span>
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    <input type='text' placeholder='Add Account' required ref={newAccount} defaultValue={dValue} />

                    {errors && errors.account ? <p className="error">{errors.account}</p> : ""}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={() => setModalCentered(false)}
                        variant="danger btn-xs"
                    >
                        Close
                    </Button>
                    {checkAddUpdate ? <Button onClick={() => { addAccount() }} variant="primary btn-xs">Save changes</Button>
                        : <Button onClick={() => { updateAccount() }} variant="primary btn-xs">Update changes</Button>
                    }
                </Modal.Footer>
            </Modal>

        </fregment>
    )
    //    const data = [
    //     { name: "Joe James", company: "In Progress", city: "Yonkers", state: "NY" },
    //     { name: "John Walsh", company: "Completed", city: "Hartford", state: "CT" },
    //     { name: "Bob Herm", company: "Awaited", city: "Tampa", state: "FL" },
    //     { name: "James Houston", company: "NA", city: "Dallas", state: "TX" },
    //    ];

    const options = {
        download: "false",
        filterType: 'none',
        selectableRows: false
    };
    const optios = {
        filterType: "dropdown",
        responsive: "scroll"
    };


    const all_db_list = {
        "MOP": [{
            kee: "Ticket Id",
            val: "ticketId"
        }, {
            kee: "Account",
            val: "account"
        }, , {
            kee: "Market",
            val: "market"
        }, {
            kee: "Project Code",
            val: "projectCode",
            cstmFun: (data) => { return data.join(", ") }
        }, {
            kee: "Project Id",
            val: "projectId",
            cstmFun: (data) => {
                return data.join(", ")
            }
        }, {
            kee: "Group",
            val: "group"
        }, {
            kee: "Go / No Go",
            val: "goNo",
            cstmFun: function (data) {
                // alert(data)
                return data
            }
        }, {
            kee: "Type Of Work",
            val: "typeOfWork"
        }],
        "RAN Support": [
            // {
            //     kee:"Site Id",
            //     val:"siteIdCommon"
            // },
            {
                kee: "Schedule Date",
                val: "scheduleDate"
            }, {
                kee: "Account",
                val: "account"
            }, , {
                kee: "Market",
                val: "market"
            }, {
                kee: "Project Code",
                val: "projectCode",
                cstmFun: (data) => { return data.join(", ") }
            }, {
                kee: "Project Id",
                val: "projectId",
                cstmFun: (data) => {
                    return data.join(", ")
                }
            }, {
                kee: "RAN Bucket",
                val: "ranBucket"
            }, {
                kee: "RAN Status",
                val: "ranStatus"
            }
        ],
        "Trouble Ticket": [{
            kee: "TT Id",
            val: "ttid"
        }, {
            kee: "Account",
            val: "account"
        }, , {
            kee: "Market",
            val: "market"
        }, {
            kee: "Project Code",
            val: "projectCode",
            cstmFun: (data) => { return data.join(", ") }
        }, {
            kee: "Project Id",
            val: "projectId",
            cstmFun: (data) => {
                return data.join(", ")
            }
        }, {
            kee: "Impact",
            val: "impact"
        }],
        "E911": [{
            kee: "Site Id",
            val: "siteIdCommon"
        }, {
            kee: "Account",
            val: "account"
        }, , {
            kee: "Market",
            val: "market"
        }, {
            kee: "Project Code",
            val: "projectCode",
            cstmFun: (data) => { return data.join(", ") }
        }, {
            kee: "Project Id",
            val: "projectId",
            cstmFun: (data) => {
                return data.join(", ")
            }
        },]
    }


    const all_time_list = {
        "MOP": [{
            kee: "MOP Created At",
            val: "mopCreatedAt",
            Table: [
                [
                    { kee: "Last MOP Update At", val: "mopUpdatedAt" },
                    { kee: "CR Number", val: "crNumber" }
                ], [
                    { kee: "MOP Start Time", val: "mopStartTime" },
                    { kee: "MOP End Time", val: "mopEndTime" }
                ], [
                    { kee: "MOP Down Time", val: "mopDownTime" },
                    { kee: "Crane Needed", val: "CraneNeeded" }
                ]
            ],
        }, {
            kee: "RF Last Update",
            val: "lastUpdated_rf",
            Table: [
                [
                    { kee: "RF Status", val: "rfstatus" }
                ], [
                    { kee: "RF Reason", val: "rfreason" }
                ]
            ],
        }, {
            kee: "Dev. Last Update",
            val: "lastUpdated_dev",
            condval: "group",
            cond: {
                Eq: "Development",
            },
            Table: [[
                { kee: "Dev. Status", val: "devstatus" }
            ], [
                { kee: "Dev. Reason", val: "devreason" }
            ]]
        }, {
            kee: "FOPS Last Update",
            val: "lastUpdated_fops",
            condval: "group",
            cond: {
                Eq: "Operation",

            },
            Table: [[
                { kee: "FOPS Status", val: "fopsstatus" }
            ], [
                { kee: "FOPS Reason", val: "fopsreason" }
            ]]
        },],
        "RAN Support": [
            {
                kee: "Schedule Date",
                val: "scheduleDate",
                Table: [
                    [
                        { kee: "Schedule Date", val: "scheduleDate" },
                        { kee: "Request Form", val: "requestForm" }
                    ], [
                        { kee: "RF Approval", val: "RF_Approval" },
                        { kee: "CR Number", val: "crNumber" }
                    ], [
                        { kee: "Schedule Date", val: "scheduleDate" }
                    ]
                ],
            }, {
                kee: "Pre-Check Request",
                val: "preCheckRequest",
                Table: [[
                    { kee: "Schedule Date", val: "GSMAlarmPre" },
                    { kee: "Request Form", val: "UMTSAlarmPre" }
                ], [
                    { kee: "LTE Alarm", val: "LTEAlarmPre" },
                    { kee: "FiveG Alarm", val: "FiveGAlarmPre" }
                ], [
                    { kee: "BSC IP Contact", val: "BSCIpContactPre" },
                    { kee: "UMTS IP Contact", val: "UMTSIpContactPre" }
                ], [
                    { kee: "LTE IP Contact", val: "LTEIpContactPre" },
                    { kee: "FiveG IP Contact", val: "fiveGIpContactPre" }
                ],]
            }, {
                kee: "Pre-Check Delivered",
                val: "preCheckDelivered",
                Table: []
            }, {
                kee: "RIOT",
                val: "RIOT_status",
                condval: "RIOT_status",
                cond: {
                    notEq: 0,

                },
                Table: []
            }, {
                kee: "CBN",
                val: "CBN_status",
                Table: []
            }, {
                kee: "Post-Check Request",
                val: "postCheckRequest",
                Table: []
            }, {
                kee: "Post-Check Delivered",
                val: "postCheckDelivered",
                Table: []
            }
        ],

        "Trouble Ticket": [{
            kee: "TT Open Date",
            val: "ttOpenDate",
            Table: [
                [
                    { kee: "TMO Pier TT Id", val: "tmoPierTtid" },
                    { kee: "TT Category", val: "ttCategory" },
                ],
                // [
                //     // { kee: "Tech Affected", val: "techAffected.values",cstmFun: (data) => {return data.join(", ")} },
                //     // { kee: "tt data", val: "techAffected.values",cstmFun: (data) => {return data.join(", ")} }
                // ], 
                // [
                //     { kee: "MOP Down Time", val: "mopDownTime" },
                //     { kee: "Crane Needed", val: "CraneNeeded" }
                // ], 
                // [
                //     {
                //         kee: "TT Desc.",
                //         val: "tt_desc", 
                //         cstmFun: (data) => {

                //             fi
                //             return data.join(", ")
                //         }
                //     },
                //     { kee: "Crane Needed", val: "CraneNeeded" }
                // ]


            ],
        }, {
            kee: "TT Close Date",
            val: "ttCloseDate",
            Table: [[
                { kee: "Engineer Closing TT", val: "engineerClosingtt" }
            ]]
        }],
        "E911": [{
            kee: "Add Date",
            val: "addDate",
            Table: []
        }, {
            kee: "Integration Date",
            val: "integrationDate",
            Table: []
        }, {
            kee: "E911 Call Test Date",
            val: "ennoCallTestDate",
            Table: [[
                { kee: "Test Phone Number", val: "testPhoneNumber" }
            ]]
        }]
    }


    const nextPageHandler = () => {

        // dispatch(AccountReducerAction(listtroubleTicketApi + "/" + mark));
        dispatch(setOneProjectList([]))
        history.push("/PAG/ProjectList")

    }


    return (
        <>



            <div className='row'>

                <div className='col-md-2'>
                    <button className="btn btn-primary shadow btn-xs sharp mr-1 editButton" onClick={(e) => { nextPageHandler() }}><i className="fa fa-arrow-left"></i></button>
                </div>
            </div>
            <h4>{"Site Id"} - {urlsiteid}</h4>
            <div className='row'>
                <div className='col-md-2'>
                    <label>
                        Select Project Id
                    </label>
                </div>
                <div className='col-md-3'>
                    <MultiSelect
                        className="text-dark"
                        hasSelectAll={true}
                        options={PList}
                        onChange={(e) => {
                            changePlisting(e)
                        }}
                        value={selectedPList}
                    />
                </div>
            </div>

            <br/>

            <div className='row' style={{float:"left",marginLeft:"20px"}}>

                {
                    selectedPList.length>0?
                        selectedPList.map((itm)=>{
                            return <p className='badge badge-primary'> {itm.value} </p>
                        }):
                        <><p className='badge badge-primary' style={{marginLeft:"10px"}}> No Project Id Selected </p> </>
                }
                
            </div>
            <br/>
            <br/>


            <div className="accordion accordion-flush" id="accordionFlushExample" style={{ paddingBottom: "20px" }}>

                {
                    data?.ProjectListResp?.data?.map((oneittm) => {
                        let val = false
                        console.log(val, "ProjectListResp")
                        return <>
                            {
                                oneittm.data.data.projectId.map((itte) => {

                                    console.log(selectedPList, itte, selectedPList.includes(itte), "selectedPList.includes(itte)")
                                    if (finalselectedPList.includes(itte)) {
                                        val = true
                                    }
                                })
                            }
                            {console.log(val, "valvalvalval")}

                            {
                                val ?
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id={"#flush-collapseone_" + oneittm.data.data.unqId}>
                                            <Button
                                                className="accordion-button collapsed mr-2"
                                                type="button"
                                                variant='outline-primary btn-xxs'

                                                onClick={() => {
                                                    setAccordianState(accordianState == "flush-collapseone_" + oneittm.data.data.unqId ? "" : "flush-collapseone_" + oneittm.data.data.unqId)
                                                }}

                                                data-bs-toggle="collapse"
                                                data-bs-target={"#flush-collapseone_" + oneittm.data.data.unqId}
                                                aria-expanded="false"
                                                aria-controls={"#flush-collapseone_" + oneittm.data.data.unqId}
                                            >
                                                {oneittm.data.dbname} - {oneittm.data.data.commonCreateDateStr}
                                            </Button>
                                        </h2>

                                        <div
                                            id={"#flush-collapseone_" + oneittm.data.data.unqId}
                                            className={`accordion-collapse${accordianState == "flush-collapseone_" + oneittm.data.data.unqId ? "" : " collapse"}`}
                                            aria-labelledby={"#flush-collapseone_" + oneittm.data.data.unqId}
                                            data-bs-parent="#accordionFlushExample"
                                        >
                                            <div className="accordion-body">

                                                <div style={{ padding: "10px" }}>
                                                    <h4></h4>

                                                    <table>

                                                        <tr>
                                                            {
                                                                all_db_list[oneittm.data.dbname].map((all_db_listOne) => {
                                                                    return <>
                                                                        <th style={{ color: "white", fontSize: "11px", minWidth: "180px", border: "1px black solid !important" }}>
                                                                            {all_db_listOne.kee}
                                                                        </th>
                                                                    </>
                                                                })
                                                            }
                                                        </tr>
                                                        <tr>
                                                            {
                                                                all_db_list[oneittm.data.dbname].map((all_db_listOne) => {
                                                                    return <>
                                                                        <td class={"ng-binding"} style={{ border: "1px black solid" }}>
                                                                            {"cstmFun" in all_db_listOne ? all_db_listOne.cstmFun(oneittm.data.data[all_db_listOne.val]) : oneittm.data.data[all_db_listOne.val]}
                                                                            {/* {oneittm.data.data[all_db_listOne.val]} */}
                                                                        </td>
                                                                    </>
                                                                })
                                                            }
                                                        </tr>
                                                    </table>



                                                    <CustomSlider dataForShow={oneittm.data.data} all_time_list={all_time_list} dbname={oneittm.data.dbname} />
                                                </div>
                                            </div>
                                        </div>

                                    </div > : <></>
                            }
                        </>
                    })
                }

            </div>
        </>
    )
}

export default ViewProjectList