import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { MultiSelect } from 'react-multi-select-component'
import { useDispatch, useSelector } from 'react-redux'
import { bulkUpload, runPostCheck, runPreCheck, sla_remarks_add } from '../../../../common/config'
import { reformArray } from '../../../../common/variables'
import { addAcccountReducerAction, addUserAction, popUpUpdate } from '../../../../store/actions/createTicketManageFields'
import { useHistory } from 'react-router-dom'

const SlaMetRemark = ({ selectedId,formDataState,setchackerSlaData,setdataSlaData,SLAType,SLAMet }) => {
  let userDetails = JSON.parse(window.localStorage.getItem("userDetails"));
  const dispatch = useDispatch()
  const [department, setDepartment] = useState([])
  const [reason, setReason] = useState('')
  const [onlyOne, setonlyOne] = useState(true)
  const [action, setAction] = useState("")
  const [errors, seterrors] = useState()
  const [closer, setCloser] = useState(false)

  const history = useHistory();

  console.log("userType", userDetails)
  let onBehalf = []

  
  const url = window.location.href.split("/")
  let dashboardType=url.slice(-3)[0]


  
  let siteDetail = useSelector((state) => {

    console.log(closer,dashboardType,state.popUpState.popstatus.status,"statestatestatestatestatestatestatestatestate")
    
    if((state.popUpState.popstatus.status==200 && closer)||(state.popUpState.popstatus.status==201 && closer)){
      // history.push("/PAG/ran/support/dashboard")
      console.log()
      if(dashboardType=="ixDashboard"){

        history.push("/PAG/ix/dashboard")
      }
      else{
        history.push("/PAG/ran/support/dashboard")

      }
      setCloser(false)
    }
  })

  const ApprovalRequestHandler = () => {

    console.log(action, "department")

    let newError = {}
    let errorReturn = 0

    if (reason == "") {
      errorReturn=1
      newError["reason"] = "Reason is Required Field"
    }

    if(errorReturn==1){
      seterrors(newError)
      return 
    }
    


    const reqData = {
      selectedId: selectedId,
      reason: reason,
      SLAType:SLAType
    }
    
    setdataSlaData(reason)
    dispatch(popUpUpdate({}));
    setCloser(true)
    
    
    dispatch(addAcccountReducerAction(sla_remarks_add, reqData));

    console.log("buyvfwgytfdytwevdw",dashboardType)
    if(SLAType=="Pre"){
      if(dashboardType=="ixDashboard"){
      dispatch(addUserAction(runPreCheck + selectedId+"?ix=True", formDataState))
    }
    else{
      
      dispatch(addUserAction(runPreCheck + selectedId, formDataState))
      }
    }
    if(SLAType=="Post"){
      if(dashboardType=="ixDashboard"){
        console.log("kcjbdsaucvdkysuvgcuy")
        dispatch(addUserAction(runPostCheck + selectedId+"?ix=True", formDataState))
      }
      else{
        console.log("wobbbyuwdgsi")
        dispatch(addUserAction(runPostCheck + selectedId, formDataState))
      }
    }

    

    console.log("the real response =", reqData,formDataState)
  }
  const setReasonHandler = (e) => {
    
    if(errors && errors.reason){
      delete errors.reason
    }
    setReason(e.target.value)
  }
 
  return (
    <>
      
      <div className="form-group">
        <label htmlFor="inputEmail4">SLA Type</label>
        <textarea type='text'
          value={SLAType}
          className='form-control'>

        </textarea>



        {errors && errors.reason?<p className="error">{errors.reason}</p>:""}

      </div>
      
      <div className="form-group">
        <label htmlFor="inputEmail4">SLA Met</label>
        <textarea type='text'
          value={SLAMet}
          className='form-control'>
        
        </textarea>



        {errors && errors.reason?<p className="error">{errors.reason}</p>:""}

      </div>
      
      <div className="form-group">
        <label htmlFor="inputEmail4">SLA Not Met Remarks</label>
        <textarea type='text'
          value={reason}
          onChange={setReasonHandler}
          className='form-control'></textarea>



        {errors && errors.reason?<p className="error">{errors.reason}</p>:""}

      </div>
      
      <Button variant="secondary " onClick={ApprovalRequestHandler}>
        Submit
      </Button>
    </>
  )
}

export default SlaMetRemark