import { async } from "q";
import { baseURL } from "../common/config";
import {axiosInstance, axiosInstanceFile, axiosInstanceblobFile, axiosInstancestreamFile } from './AxiosInstance';

var bucketList  ='/PAG/listBucket'





export async function common(reqUrl){
    return await axiosInstance.get(baseURL+reqUrl)
}

export async function commonOne(reqUrl,id){
    return await axiosInstance.get(baseURL+reqUrl+'/'+id)
}


export async function commonPost(reqUrl,data){
    return await axiosInstance.post(baseURL+reqUrl,data)
}

export async function commonDelete(reqUrl,data){
    return await axiosInstance.delete(baseURL+reqUrl+"/"+data)
}
export async function commonDeleteWithFormData(reqUrl,data){
    console.log("the deelllle=",data)
    return await axiosInstance.delete(baseURL+reqUrl,data)
}

export async function commonupdate(reqUrl,id,data){
    return await axiosInstance.post(baseURL+reqUrl+"/"+id,data)
}



export async function commondownload(reqUrl,type,data){
    if(type=="blob"){
        return await axiosInstanceblobFile.post(baseURL+reqUrl,data)
    }else{
        return await axiosInstancestreamFile.post(baseURL+reqUrl,data)
    }
}