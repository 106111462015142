import React, { useEffect, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import "../../../css/customStyle.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import moment from "moment";
import DataTableExtensions from "react-data-table-component-extensions";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import {
  NetworkDatabaseAction,
  CSVUploadAction,
} from "../../../store/actions/dashboardActions";
import { useHistory } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import {
  google_map_api,
  center,
  customDataTableOptionObject,
  getMuiThemeCommon,
  dbdatetomoment,
  getdatedatalistnext,
  rancustomDataTableOptionObject,
} from "../../../common/variables";
import DatePicker from "react-datepicker";
import { AccountReducerAction, ExportXLSXDownloadReducerAction } from "../../../store/actions/createTicketManageFields";
import { export_ran_daily, export_ran_tracker, ranDashboard, ranTracker } from "../../../common/config";
import { Select } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { ExportToExcel } from "../../../common/ExportToExcel";
import swal from "sweetalert";
const RanSupportDailyReport = () => {
  const [accordianState, setAccordianState] = useState(false);
  const [siteFile, uploadedFile] = useState();
  const [remarks, setRemarks] = useState();
  const [markersdatacount, setmarkerscount] = useState(1);
  const [filterm, setfilterm] = useState(0);
  const [filterd, setfilterd] = useState(1);
  const [markersdata, setmarkersdata] = useState([]);
  const [selectedCenter, setSelectedCenter] = useState(null);
  
  const [exportApiData, setexportApiData] = useState("");
  
  const [fstartdate, setfstartdate] = useState();
  
  const [ResetFilter, setResetFilter] = useState(false);
  const [popissue, setpopissue] = useState(false);
  
  const [OneTimere, setOneTimere] = useState(false);
  const [fenddate, setfenddate] = useState();
  const history = useHistory();
  let excelData = [];
  let columns = [
    // {
    //   name: "scheduleWeek",
    //   label: "Week #",
    //   sortable: false,
    //   options: {
    //     filter: true,
       
    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",
    //         "max-width": "60px",
    //         "min-width": "45px",
    //         background: "#143b64",
    //         color: "white",
    //       },
    //     }),
    //   },
    // },
    // {
    //   name: "scheduleDate",
    //   label: "Schedule Date  ",
    //   sortable: false,
    //   options: {
    //     filter: true,
       
    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "nowrap",
    //         "max-width": "150px",
    //         "min-width": "140px",
    //         background: "#143b64",
    //         color: "white",
    //       },
    //     }),
    //   },
    // },
    // {
    //   name: "siteIdd",
    //   label: "Site ID",
    //   sortable: false,
    //   options: {
    //     filter: true,
       
    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "nowrap",
    //         background: "#143b64",
    //         color: "white",   "min-width" : "90px",
    //         "max-width" : "140px"
    //       },
    //     }),
    //   },
    // },
    
    
    {
      label: "Schedule Date",
      name: "scheduleDate",
      sortable: true,
      options: {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 101
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            left: "0",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      },
    },
    {
      label: "Week #",
      name: "scheduleWeek",
      options: {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            position: "sticky",
            zIndex: 101,
            minWidth: "68px",
            left: "138px",
            color: "white",
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            outline:"1.5px solid black",
            overflowY: "hidden",
            minWidth: "68px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
            left: "138px",
          },
        }),
      },
    },
    
    // {
    //   "name": "scheduleDate",
    //   "label": "Schedule Date",
    //   "sortable": false,
    //   options: {
    //     filter: true,

    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "nowrap",
    //         alignItem: "center",
    //         padding: "1px 1px",

    //         background: "#143b64",
    //         color: "white",
    //       },
    //     }),
    //     setCellProps: () => ({
    //       style: {
    //         whiteSpace: "nowrap",

    //         overflowY: "hidden",

    //         padding: "1px 1px",

    //         background: "White",
    //       },
    //     }),
    //   }
    // },
    // {
    //   "name": "scheduleWeek",
    //   "label": "Week #",
    //   "sortable": false,
    //   options: {
    //     filter: true,

    //     setCellHeaderProps: () => ({
    //       style: {
    //         whiteSpace: "wrap",
    //         // minWidth:"150px",
    //         background: "#143b64",
    //         color: "white"

    //       }
    //     })
    //   }
    // },

    
    {
      label: "Site ID",
      name: "siteIdd",
      options: {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            position: "sticky",
            zIndex: 101,
            minWidth: "68px",
            left: "208px",
            color: "white",
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            outline:"1.5px solid black",
            overflowY: "hidden",
            minWidth: "68px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
            left: "208px",
          },
        }),
      },
    },
    {
      name: "dayNightMop",
      label: "Day/Night MOP",
      sortable: false,
      options: {
        filter: true,
       
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            minWidth: "200px",
            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "pSow",
      label: "Planned SoW",
      sortable: false,
      options: {
        filter: true,
       
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
           "min-width"  :"200px" , 
           "max-width" : "300px",
            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "ssowDetails",
      label: "SoW Details",
      sortable: false,
      options: {
        filter: true,
       
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
           "min-width" : "250px",
           "max-width" : "300px",
           background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "serviceAffected",
      label: "Service Affecting",
      sortable: false,
      options: {
        filter: true,
       
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "postBSCIpContact",
      label: "GSM UP",
      sortable: false,
      options: {
        filter: true,
       
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "postGSMAlarm",
      label: "GSM Alarms",
      sortable: false,
      options: {
        filter: true,
       
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "postGsmDownTime",
      label: "GSM Downtime",
      sortable: false,
      options: {
        filter: true,
       
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "postUmtsIpContact",
      label: "UMTS UP",
      sortable: false,
      options: {
        filter: true,
       
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "postUMTSlAlarm",
      label: "UMTS Alarms",
      sortable: false,
      options: {
        filter: true,
       
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "postUmtsDownTime",
      label: "UMTS Downtime",
      sortable: false,
      options: {
        filter: true,
       
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "postLTEIPContact",
      label: "LTE UP",
      sortable: false,
      options: {
        filter: true,
       
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "postLteAlarm",
      label: "LTE Alarms",
      sortable: false,
      options: {
        filter: true,
       
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "postLteDownTime",
      label: "LTE Downtime",
      sortable: false,
      options: {
        filter: true,
       
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "post5GIpContact",
      label: "5G UP",
      sortable: false,
      options: {
        filter: true,
       
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "post5GAlarm",
      label: "5G Alarms",
      sortable: false,
      options: {
        filter: true,
       
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "post5GDownTime",
      label: "5G Downtime",
      sortable: false,
      options: {
        filter: true,
       
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "postActivityStatusSiteLevel",
      label: "Activity Status (Site Level)",
      sortable: false,
      options: {
        filter: true,
       
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "max-width": "140px",
            "min-width": "123px",
            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "ttOpen",
      label: "TT(s) Opened?",
      sortable: false,
      options: {
        filter: true,
       
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "ttIds",
      label: "MPULSE TT ID(s)",
      sortable: false,
      options: {
        filter: true,
       
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            minWidth:"250px",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "pierttIds",
      label: "PIER TT ID(s)",
      sortable: false,
      options: {
        filter: true,
       
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            minWidth:"250px",
            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "TMOOutageApprovedMWTime",
      label: "TMO Outage Approved MW Time",
      sortable: false,
      options: {
        filter: true,
       
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            minWidth: "200px",
            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "activityWentOutOfApprovedWindow",
      label: "Activity went out of approved window?",
      sortable: false,
      options: {
        filter: true,
       
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "max-width": "180px",
            "min-width": "170px",
            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "timeAfterApprovedWindow",
      label: "Time after approved window",
      sortable: false,
      options: {
        filter: true,
       
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "max-width": "180px",
            "min-width": "170px",
            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "gcType",
      label: "GC Type",
      sortable: false,
      options: {
        filter: true,
       
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "GCVendor",
      label: "GC Vendor",
      sortable: false,
      options: {
        filter: true,
       
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "GCCompany",
      label: "GC Company",
      sortable: false,
      options: {
        filter: true,
       
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "GCName",
      label: "GC Name",
      sortable: false,
      options: {
        filter: true,
       
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            "min-width" : "120px",
            "max-width" : "160px",
            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "GCEmail",
      label: "GC Email",
      sortable: false,
      options: {
        filter: true,
       
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            "min-width" : "185px",
            "max-width" : "240px",
            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "GCContact",
      label: "GC Contact",
      sortable: false,
      options: {
        filter: true,
       
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "GCLoginTime",
      label: "GC Login Time",
      sortable: false,
      options: {
        filter: true,
       
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            minWidth: "150px",
            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "GCLogoutTime",
      label: "GC Logout Time",
      sortable: false,
      options: {
        filter: true,
       
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            minWidth: "150px",
            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "requestForm",
      label: "Login by App or Email/Bridge",
      sortable: false,
      options: {
        filter: true,
       
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            "max-width": "140px",
            "min-width": "130px",
            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
    {
      name: "SiteStatus",
      label: "Site Status",
      sortable: false,
      options: {
        filter: true,
       
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",

            background: "#143b64",
            color: "white",
          },
        }),
      },
    },
  ];
  let rowData = [];
  let rowDataOne = [];
  let mark = [];
  let row = [];
  let datamark = [];

  function createmarker(mark) {
    console.log(datamark, "createmarkerdatamark");

    setmarkersdata(datamark);

    console.log(...markersdata, "markersdata");
  }
  const count = useSelector((state) => {
    console.log("the state is=", state);
    if (
      state.CommonResponse.ranTracker &&
      state.CommonResponse.ranTracker &&
      state.CommonResponse.ranTracker.length > 0
    ) {
      rowData = state.CommonResponse.ranTracker.map((item) => {
        item["LTEId"] = "";
        item["UMTSId"] = "";
        item["mixedModeId"] = "";
        console.log("item is =", item["technology"]);
        item["gsmAlarm"] = item.postGSMAlarm;
        item["siteIdd"] = item.ndResult.siteId;
        if (item.cxCrewId.name) {
          item["gcType"] = item["cxCrewId"]["crewType"];
          item["GCContact"] = item["cxCrewId"]["mobilenumber"];
          item["GCName"] = item["cxCrewId"]["name"];
          item["GCVendor"] = item["cxCrewId"]["vendor"];
          item["GCCompany"] = item["cxCrewId"]["crewLeadCompanyId"];
          item["GCEmail"] = item["cxCrewId"]["email"];
        } else if (item.ixCrewId.name) {
          item["gcType"] = item["ixCrewId"]["crewType"];
          item["GCContact"] = item["ixCrewId"]["mobilenumber"];
          item["GCName"] = item["ixCrewId"]["name"];
          item["GCVendor"] = item["ixCrewId"]["vendor"];
          item["GCCompany"] = item["ixCrewId"]["crewLeadCompanyId"];
          item["GCEmail"] = item["ixCrewId"]["email"];
        }
        
        // item["gcType"] = item["cxixuserIdresult"]["crewType"];
        // item["GCContact"] = item["cxixuserIdresult"]["mobilenumber"];
        // item["GCName"] = item["cxixuserIdresult"]["name"];
        // item["GCVendor"] = item["cxixuserIdresult"]["vendor"];
        // item["gcCompany"] = item["cxixuserIdresult"]["crewLeadCompanyId"];
        // item["GCEmail"] = item["cxixuserIdresult"]["email"];
        let pierttidsData=item.troubleTickets.map((itemInner) => {
            return itemInner.tmoPierTtid;
          });

        item["pierttIds"] = pierttidsData.join(", ");
        
        let ttidsData=item.troubleTickets.map((itemInner) => {
            return itemInner.ttid;
        });

        item["ttIds"] = ttidsData.join(", ");

        // item["TMOOutageApprovedMWTime"] = getdatedatalistnext(item["RF_Approved_MW_Time"]).split(" ")[1] + " to " + getdatedatalistnext(item["RF_Approved_EndTime_Time"]).split(" ")[1]
        // item['technology']=''
        item["TMOOutageApprovedMWTime"] = item["RF_Approved_MW_Time"]&&item["RF_Approved_EndTime_Time"]?getdatedatalistnext(item["RF_Approved_MW_Time"]).split(" ")[1] + " to " + getdatedatalistnext(item["RF_Approved_EndTime_Time"]).split(" ")[1]:""
          
        const myMomentObject = dbdatetomoment(getdatedatalistnext(item["scheduleDate"]))
        item["scheduleWeek"] = myMomentObject.isoWeekday(1).week()
        let pSow = "";
        let pSowArray = item["sowList"]?.values || []
        if (pSowArray && pSowArray.length > 0) {
          //  item['technology'].forEach((item)=>{
          //   tec +=item.label +','
          //  })
          console.log("sgrthdhr", pSowArray.length);
          for (let i = 0; i < pSowArray.length; i++) {
            console.log("sgrthdhr", pSowArray[i]);
            pSow += pSowArray[i] + ", ";
          }
          //  item['technology']=tec
        }
        // console.log("item is tec =", tec);
        
        let sowDetailsArr = item["sowDetails"]?item["sowDetails"]["values"]:[]
        
        item["ssowDetails"]=sowDetailsArr?sowDetailsArr.join(", "):""
        //  console.log("item is =",item['technology'])
        // item['technology']=tec
        console.log("the psow is =", pSow);
        item["pSow"] = pSow;
        let tec = "";
        // let techArray = item["technology"];
        // item["sowDetails"]=item["sowDetails"].value.join("\n")
        // if (techArray.length != 0) {
        //   //  item['technology'].forEach((item)=>{
        //   //   tec +=item.label +','
        //   //  })
        //   console.log("sgrthdhr", techArray.length);
        //   for (let i = 0; i < techArray.length; i++) {
        //     console.log("sgrthdhr", techArray[i].label);
        //     tec += techArray[i].label + ", ";
        //   }
        //   //  item['technology']=tec
        // }

        item['technology']=item["techtake"] ? item["techtake"].join(",") : ""


        console.log("item is tec =", tec);
        item["ttOpen"] = item.ttCount > 0 ? "Yes" : "No";
        // item["gcEmail"] = item.cxixuserId ? item.cxixuserId.email : "";
        // item["gcName"] = item.cxixuserId ? item.cxixuserId.name : "";
        // item["gcmobilenumber"] = item.cxixuserId
        //   ? item.cxixuserId.mobilenumber
        //   : "";
        item["Loginby"] = "MOP";
        item["gcmobilenumber"] = item.cxixuserId
        item["GCLoginTime"] = item.preCheckRequest ? getdatedatalistnext(item.preCheckRequest) : ""
        item["GCLogoutTime"] = item.postCheckDelivered ? getdatedatalistnext(item.postCheckDelivered): ""
        // const currentCSTTime = new Date().toLocaleString("en-US", { timeZone: "America/Chicago" });
        // const currentTime = new Date(currentCSTTime);
        // console.log(currentTime,"currentTimecurrentTime")
        // if(item.postCheckDelivered){
        //   item["SiteStatus"]="Logged Out"
        // }else if(item["ranStatus"]=="Login Awaited - No Show"){
        //   item["SiteStatus"]="No Show"
        // }else if(item["ranStatus"]=="Activity Cancelled"){
        //   item["SiteStatus"]="Cancelled"
        // }else if(item["GCLoginTime"]==""&& item["RF_Approved_EndTime_Time"]<currentTime ){
        //   item["SiteStatus"]="No Show"
        // }else if(item["GCLoginTime"]==""&& item["RF_Approved_EndTime_Time"]>currentTime ){
        //   item["SiteStatus"]="Awaited"
        // }else{
        //   item["SiteStatus"]="Ongoing"
        // }

        
        const currentTime = new Date(new Date().toLocaleString("en-US", { timeZone: "America/Chicago" }));
        console.log(currentTime, "currentTime");

        if (item.postCheckDelivered) {
          item["SiteStatus"] = "Logged Out";
        } else if (item["ranStatus"] === "Login Awaited - No Show") {
          item["SiteStatus"] = "No Show";
        } else if (item["ranStatus"] === "Activity Cancelled") {
          item["SiteStatus"] = "Cancelled";
        } else if (!item["GCLoginTime"] && new Date(item["RF_Approved_EndTime_Time"]) < currentTime) {
          item["SiteStatus"] = "No Show";
        } else if (!item["GCLoginTime"] && new Date(item["RF_Approved_EndTime_Time"]) > currentTime) {
          item["SiteStatus"] = "Awaited";
        } else {
          item["SiteStatus"] = "Ongoing";
        }

        

        //  console.log("item is =",item['technology'])
        // item['technology']=tec
        item["temp"] = tec;
        console.log("the row data is=", item);
        return item;
      });
      excelData = state.CommonResponse.ranTracker.map((item) => {
        let excelObject = {
          "Week #": item["scheduleWeek"],
          "Schedule Date": item["scheduleDate"],
          "Site ID": item["siteIdd"],
          "Day/Night MOP": item["dayNightMop"],
          "Planned SoW": item["pSow"],
          "SOW Details": item["ssowDetails"],
          "Service Affecting": item["serviceAffected"],
          "GSM UP": item["postBSCIpContact"],
          "GSM Alarms": item["postGSMAlarm"],
          "GSM Downtime": item["postGsmDownTime"],
          "UMTS UP": item["postUmtsIpContact"],
          "UMTS Alarms": item["postUMTSlAlarm"],
          "UMTS Downtime": item["postUmtsDownTime"],
          "LTE UP": item["postLTEIPContact"],
          "LTE Alarms": item["postLteAlarm"],
          "LTE Downtime": item["postLteDownTime"],
          "5G UP": item["post5GIpContact"],
          "5G Alarms": item["post5GAlarm"],
          "5G Downtime": item["post5GDownTime"],
          "Activity Status(Site Level)": item["postActivityStatusSiteLevel"],
          "TT(s) Opened?": item["ttOpen"],
          "MPULSE TT ID(s)": item["ttIds"],
          "PIER TT ID(s)": item["pierttIds"],
          "TMO Outage Approved MW Time": item["RF_Approved_MW_Time"]&&item["RF_Approved_EndTime_Time"]?getdatedatalistnext(item["RF_Approved_MW_Time"]).split(" ")[1] + " to " + getdatedatalistnext(item["RF_Approved_EndTime_Time"]).split(" ")[1]:"",
          "Activity went out of approved window?": item["activityWentOutOfApprovedWindow"],
          "Time after approved window": item["timeAfterApprovedWindow"],
          "GC Type" : item["gcType"],
          "GC Contact": item["GCContact"],
          "GC Name" : item["GCName"],
          "GC Vendor" : item["GCVendor"],
          "GC Company" : item["GCCompany"],
          "GC Email" : item["GCEmail"],
          "GC Login Time": item["GCLoginTime"],
          "GC Logout Time": item["GCLogoutTime"],
          "Login by App or Email/Bridge": item["Loginby"],
          "Site Status": item[""],
        };
        //   "Week No.": item[""],
        //   "Schedule Date  ": item["mopStartTime"],
        //   "Site ID": item["siteIdd"],
        //   "Day MOP/Night MOP": item["mop"],
        //   "Planned SoW": item["pSow"],
        //   "SoW Details": item["ssowDetails"],
        //   "Service Affecting": item["serviceAffected"],
        //   "GSM UP": item[""],
        //   "GSM Alarms": item["postGSMAlarm"],
        //   "GSM Downtime": item["postGsmDownTime"],
        //   "UMTS UP": item[""],
        //   "UMTS Alarms": item["postUMTSlAlarm"],
        //   "UMTS Downtime": item["postUmtsDownTime"],
        //   "LTE UP": item[""],
        //   "LTE Alarms": item["postLteAlarm"],
        //   "LTE Downtime": item["postLteDownTime"],
        //   "5G UP": item[""],
        //   "5G Alarms": item["post5GAlarm"],
        //   "5G Downtime": item["post5GDownTime"],
        //   "Activity Status(Site Level)": item["postActivityStatusSiteLevel"],
        //   "TT(s) Opened?": item["ttOpen"],
        //   "MPULSE TT ID(s)": item[""],
        //   "PIER TT ID(s)": item[""],
        //   "TMO Outage Approved MW Time": item[""],
        //   "Activity went out of approved window?":
        //     item["activityWentOutOfApprovedWindow"],
        //   "Time after approved window": item["timeAfterApprovedWindow"],
        //   "GC Type": item[""],
        //   "GC Vendor": item[""],
        //   "GC Company": item[""],
        //   "GC Name": item["gcName"],
        //   "GC Email": item["gcEmail"],
        //   "GC Contact": item["gcmobilenumber"],
        //   "GC Login Time": item[""],
        //   "GC Logout Time": item[""],
        //   "Login by App or Email/Bridge": item["Loginby"],
        //   "Site Status": item[""],
        // };
        return excelObject;
      });
      console.log("jdbcjhbsdaikc", state);

      
    }
    
    let totalCount=state.countState.ranTrackerCount


    return { rowData,totalCount, columns, mark };
  });
  console.log("the tracker rows =", excelData);
  const options = {
    filterType: "none",
    selectableRows: false,
    responsive: true,
  };

  const mapStyles = {
    width: "100%",
    height: "100%",
  };

  const dispatch = useDispatch();
  function accordianHandler() {
    setAccordianState(!accordianState);
  }

  createTheme("solarized", {
    text: {
      primary: "inherit",
      secondary: "inherit",
    },
    background: {
      default: "inherit",
    },
    context: {
      background: "inherit",
      text: "inherit",
    },
    divider: {
      default: "inherit",
    },
    button: {
      default: "inherit",
      hover: "rgba(0,0,0,.08)",
      focus: "rgba(255,255,255,.12)",
      disabled: "rgba(255, 255, 255, .34)",
    },
    sortFocus: {
      default: "inherit",
    },
  });



  const sortedOrder={
    // name: 'scheduleDate',
    // direction: 'desc'
  }
  let getMuiTheme = () =>
    createTheme({
      components: {
        MuiButtonBase: {
          styleOverrides: {
            root: {
              "line-height": "1 !important",
              padding: "6px 0px",
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              maxHeight: 45,
              fontSize: 13,
              overflowY: "auto",
              padding: "0px 2px !important",

              margin: "auto",

              // maxHeight: 45,
              //   fontSize: 10,
              //   overflowY: "hidden",
              //   overflowX: "auto",
              //   padding: "3px",
              //   margin: "auto",
              //   textAlign: "center",
              //   width: "max-content",
            },
          },
        },
        // MuiToolbar: {
        //   styleOverrides: {
        //     root: {
        //       backgroundColor: '#f00',

        //     },
        //   },
        // },
        MuiTableCell: {
          styleOverrides: {
            head: {
              maxHeight: 20,
              fontSize: 15,
              margin: 0,
              paddingTop: 0,
              paddingBottom: 0,
            },
          },
        },
        MUIDataTableSelectCell: {
          styleOverrides: {
            headerCell: {
              backgroundColor: "blue",
            },
          },
        },
        MuiTableFooter: {
          styleOverrides: {
            root: {
              "& .MuiToolbar-root": {
                backgroundColor: "white",
              },
            },
          },
        },
      },
    });
  console.log("yhe row data isn=", rowData);

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  //on submiting the excel

  const setDateFilter = (e) => {
    e.preventDefault();

    if (fstartdate == undefined && fenddate == undefined) {
      swal("Oops", "Please select Start & End Date", "info");
      return;
    }
    if (fstartdate == undefined) {
      swal("Oops", "Please select Start Date", "info");
      return;
    }

    if (fenddate == undefined) {
      swal("Oops", "Please select End Date", "info");
      return;
    }
    if (fstartdate > fenddate) {
      swal("Oops", "End Date is greater than Start Date", "info");
      return;
    }
    // dateRange[0].startDate = formatDate(dateRange[0].startDate);
    // dateRange[0].endDate = formatDate(dateRange[0].endDate);
    // console.log("the filtered data=", dateRange);
    let start = formatDate(fstartdate);
    let end = formatDate(fenddate);
    let currentUrl = window.location.href;
    let urlArray = currentUrl.split("?");
    // if (currentUrl.includes("?")) {
    //   dispatch(
    //     AccountReducerAction(
    //       ranDashboard +
    //       "?" +
    //       urlArray.pop() +
    //       "&start=" +
    //       start +
    //       "&end=" +
    //       end
    //     )
    //   );
    // } else {

    
    setResetFilter(true)
    setfilterm(0)
    setfilterd(1)


    setexportApiData(ranTracker + "?start=" + moment(fstartdate).format("MM/DD/YYYY") + "&end=" + moment(fenddate).format("MM/DD/YYYY"))
    dispatch(
      AccountReducerAction(
        ranTracker +
        "?start=" +
        moment(fstartdate).format("yyyy-MM-DD") +
        "&end=" +
        moment(fenddate).format("yyyy-MM-DD")
      )
    );
      // dispatch(
      //   AccountReducerAction(ranTracker + "?start=" + start + "&end=" + end,true)
      // );
    // }
  };

  const filterdhandleChange = (e) => {
    ClearFilter(false)
    setfilterd(e.target.value)
    let url=ranTracker+"?"+"filterd"+"="+e.target.value+"&"+"filterm"+"="+filterm
    setexportApiData(url)
    dispatch(AccountReducerAction(url));
    console.log("the current anser= ", e.target.value);
  };


  const ClearFilter = (val=true) => {
    console.log("ClearFilter", "ClearFilter");
    setfstartdate();
    setfenddate();
    setOneTimere(true)
    setResetFilter(true)
    if(val){
      dispatch(AccountReducerAction(ranDashboard,true,true)); 
      setfilterm(0)
      setfilterd(1)
    }
  };

  const filtermhandleChange = (e) => {
    ClearFilter(false)
    setfilterm(e.target.value)
    let url=ranTracker+"?"+"filterd"+"="+filterd+"&"+"filterm"+"="+e.target.value
    
    setexportApiData(url)
    dispatch(AccountReducerAction(url));
  };


  const exportAllData = () => {
    dispatch(ExportXLSXDownloadReducerAction(export_ran_daily,"RANDAILYACTIVITY_" + moment(new Date()).format("MM-DD-YYYY"),exportApiData))
  }
  const AddCustomButton = () => (
    <fregment
      style={{
        float: "right",
        display: "flex",
        flexDirection: row,
        justifyContent: "space-between",
      }}
    >
      
      <>
        <button
          className="btn btn-xs btn-primary mx-2 my-3"
          data-toggle="tooltip"
          data-placement="top"
          title="Refresh"
          onClick={() => {
            ClearFilter();
            history.push("/PAG/ran/support/dashboard")
          }}
          style={{ padding: "6px" }}
        >
          <RefreshIcon />
        </button>
      </>

      <div
        style={{
          width: "46vh",
          display: "flex",
          alignItems: "center",
          margin: "18px 10px auto 10px"
        }}>
        <DatePicker
          placeholderText="MM/DD/YYYY"
          id="color-of-datepicker"
          className="date-picker-changes "
          popperPlacement=  'left'
          selected={fstartdate}
          autoComplete={"off"}
          onChange={(e) => {
            setfstartdate(e);
            // setfenddate(e);
          }}
          dateFormat="MM/dd/yyyy"
        />
        <DatePicker
          placeholderText="MM/DD/YYYY"
          className="date-picker-changes mx-1"
          id="color-of-datepicker"
          popperPlacement=  'left'
          autoComplete={"off"}
          selected={fenddate}
          onChange={(e) => {
            setfenddate(e);
          }}
          dateFormat="MM/dd/yyyy"
        />
      </div>
      <button
        variant="float-right"
        className="btn btn-primary btn-xs mx-2 py-2 my-3 float-right d-flex justify-content-center align-items-center"
        // onClick={() => setDatePicker(!showDatePicker)}
        onClick={(e) => setDateFilter(e)}
      >
        <i class="fa fa-filter mx-2"></i>Filter
      </button>



      <select
        className="btn btn-xs btn-primary mx-1 my-3 py-1 px-1"
        onChange={(e) => filtermhandleChange(e)}
        value={filterm}
        style={{ padding: "5px" , width : "110px" }}
      >
        <option value={1}>Day MOP</option>
        <option value={2}>Night MOP</option>
        <option value={0}>Both</option>
      </select>
      <select
        className="btn btn-xs btn-primary mx-1 my-3 py-1 px-1"
        onChange={(e) => filterdhandleChange(e)}
        value={filterd}
        style={{ padding: 5 , width : "120px"}}
      >
        <option value={1}>Last 24Hrs</option>
        <option value={2}>Last 48Hrs</option>
        <option value={7}>Last Week</option>
      </select>

      
      <Button onClick={exportAllData} className="btn btn-primary btn-xs mx-2 py-2 my-3 d-flex justify-content-center align-items-center">
        Export
      </Button>

      {/* <ExportToExcel
        apiData={excelData}
        fileName={"RanDailyActivity_" + moment(new Date()).format("MM-DD-YYYY")}   

        // fileName={"RanDailyActivity_" + moment(new Date()).format("MM/DD/YYYY")}
      /> */}
    </fregment>
  );
  const submitHandler = async (e) => {
    e.preventDefault();
    console.log("remarks =", remarks);
    console.log("file=", siteFile);
    const form = new FormData();
    form.append("uploadFile", siteFile);
    form.append("uploadfor", "Network_Database");
    // form.append("remarks",remarks);
    setRemarks("");
    // uploadedFile()
    dispatch(CSVUploadAction(form));
    console.log("form data =", form);
  };
  const uploadCsvFile = (e) => {
    console.log(e.target.files[0]);
    uploadedFile(e.target.files[0]);
  };

  const setDataVariables = async () => {
    dispatch(NetworkDatabaseAction());
  };

  const containerStyle = {
    width: "400px",
    height: "400px",
    position: "initial",
  };

  const AnyReactComponent = ({ text }) => <div>{text}</div>;

  const handleApiLoaded = (map, maps) => {
    // use map and maps objects
  };

  useEffect(() => {
    
    let url=ranTracker+"?"+"filterd"+"="+filterd+"&"+"filterm"+"="+filterm
    dispatch(AccountReducerAction(url));
    setexportApiData(url)
    // setDataVariables();
    if (!datamark) {
      setmarkersdata(datamark);
    }
    console.log(markersdata, "markersdata");
  }, [markersdata]);
  return (
    <>
      <div className="mui-t-h"
        style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }}
      >
        <ThemeProvider theme={getMuiThemeCommon()}>
          <MUIDataTable
           
            data={rowData}
            columns={columns}
            // options={customDataTableOptionObject(AddCustomButton,false,"","","",sortedOrder)}
            options={rancustomDataTableOptionObject(AddCustomButton,false,"RanDailyActivity", "","",sortedOrder,AccountReducerAction,ranTracker,history.location.search,rowData.length,count.totalCount,[fstartdate,fenddate],OneTimere,setOneTimere,setResetFilter,ResetFilter)}
          />
        </ThemeProvider>
      </div>
    </>
  );
};

export default RanSupportDailyReport;
