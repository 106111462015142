import React, { useEffect, useState } from "react";

import { Chrono } from "react-chrono";


const CustomSlider = ({ dataForShow, all_time_list, dbname }) => {



    console.log(dataForShow, all_time_list, dbname, "dataForShow, all_time_list, dbname")

    const items = [];
    all_time_list[dbname].map((ittm) => {


        console.log(!("cond" in ittm), ittm, dataForShow[ittm["val"]], "checkcpmd")

        let showForThis = 0

        if ((!("cond" in ittm)) && dataForShow[ittm["val"]] != undefined) {
            showForThis = 1
        }

        if ("cond" in ittm && dataForShow[ittm["val"]] != undefined) {
            if ("notEq" in ittm["cond"]) {
                if (dataForShow[ittm["condval"]] != ittm["cond"]["notEq"]) {
                    showForThis = 1
                }
            }
            if ("Eq" in ittm["cond"]) {
                if (dataForShow[ittm["condval"]] == ittm["cond"]["Eq"]) {
                    showForThis = 1
                }
            }
        }


        if (showForThis == 1) {
            let tblesData = ittm.Table.map((onews) => {
                return <tr >
                    {
                        onews.map((childonews) => {


                            console.log("cstmFun" in childonews,childonews,"45 cstmFun in childonews")
                            return <>
                                <td style={{ color: "white", fontSize: "11px", minWidth: "100%", backgroundColor: "#143b64", border: "1px black solid" }}>{childonews.kee}</td>
                                {
                                    "cstmFun" in childonews ?
                                        <td class={"ng-binding"} style={{ border: "1px black solid" }}>{childonews.cstmFun(dataForShow[childonews.val])}</td> :
                                        <td class={"ng-binding"} style={{ border: "1px black solid" }}>{dataForShow[childonews.val]}</td>
                                }
                            </>
                        })}
                </tr>
            })


            let tbles = <table style={{ width: "100%" }}>{tblesData}</table>

            console.log(ittm.Table, "ittm.Table")

            console.log("cstmFun" in ittm,ittm,"cstmFun in ittm")
            console.log("cstmFun" in ittm,ittm,"cstmFun in ittm")
            let oedata = {
                title: ittm.kee + "\n" + dataForShow[ittm.val],
                cardDetailedText: tbles
            }
            items.push(oedata)
        }





    })





    return (
        <>
            <div style={{ width: "100%", height: "400px" }}>
                <Chrono items={items}
                    fontSizes={{
                        title: "1rem"
                    }}

                    theme={{
                        primary: '#2393b9',
                        secondary: '#153b64',
                        cardBgColor: 'white',
                        titleColor: '#153b64',
                        titleColorActive: 'white',
                    }}


                    mode="HORIZONTAL"
                    cardWidth={1000}
                    cardHeight={300}
                    contentDetailsHeight={300}
                />
            </div>
        </>
    );

}



export default CustomSlider;