// import React, { useEffect, useRef } from 'react';
// // import { Chart } from 'ant-design-charts';

// const MyNewChart = () => {
//   const containerRef = useRef(null);

// //   useEffect(() => {
// //     if (containerRef.current) {
// //       const data = [
// //         { year: '1991', value: 3 },
// //         { year: '1992', value: 4 },
// //         { year: '1993', value: 3.5 },
// //         { year: '1994', value: 5 },
// //         { year: '1995', value: 4.9 },
// //         { year: '1996', value: 6 },
// //         { year: '1997', value: 7 },
// //         { year: '1998', value: 9 },
// //         { year: '1999', value: 13 }
// //       ];
// //       const config = {
// //         data,
// //         xField: 'year',
// //         yField: 'value',
// //         height: 400,
// //         point: {
// //           size: 5,
// //           shape: 'diamond',
// //         },
// //         label: {
// //           style: {
// //             fill: '#aaa',
// //           },
// //         },
// //       };
// //       const chart = new Chart(containerRef.current, config);
// //       chart.render();
// //       return () => {
// //         chart.destroy();
// //       };
// //     }
// //   }, []);

//   return <div ref={containerRef} />;
// };

// export default MyNewChart;



// import React, { useState } from 'react';
// import Chart from 'react-apexcharts';

// const MyNewChart = () => {
//   // State to manage chart options
//   const [chartOptions, setChartOptions] = useState({
//     options: {
//       chart: {
//         id: 'basic-bar',
//         events: {
//           dataPointSelection: function(event, chartContext, config) {
//             // Handle data point selection
//             console.log(config.series[config.seriesIndex].data[config.dataPointIndex]);
//           }
//         }
//       },
//       xaxis: {
//         categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep']
//       }
//     },
//     series: [{
//       name: 'Sales',
//       data: [30, 40, 45, 50, 49, 60, 70, 91, 125]
//     }]
//   });

//   return (
//     <div>
//       <Chart options={chartOptions.options} series={chartOptions.series} type="bar" height={350} />
//     </div>
//   );
// };

// export default MyNewChart;




import React from 'react';
import { ComposedChart, XAxis, YAxis, Tooltip, Legend, Line, Bar, ReferenceLine, Brush } from 'recharts';

const MyNewChart = () => {
    const data = [
        { name: 'Jan', uv: 4000, pv: 2400, amt: 2400 },
    ];

    return (
        <ComposedChart
            width={800}
            height={400}
            data={data}
            margin={{
                top: 20,
                right: 80,
                bottom: 20,
                left: 20,
            }}
        >
            <XAxis dataKey="name" />
            <YAxis yAxisId="left" />
            <YAxis yAxisId="right" orientation="right" />

            <Tooltip />
            {/* <Legend /> */}


            <Legend verticalAlign="top" wrapperStyle={{ lineHeight: '40px' }} />
            {/* <ReferenceLine y={0} stroke="#000" /> */}
            <Brush dataKey="name" height={30} stroke="#8884d8" />
            <Bar yAxisId="left" dataKey="pv" fill="#8884d8" barSize={40} />
            <Line yAxisId="right" type="monotone" dataKey="uv" stroke="#82ca9d" />
        </ComposedChart>
    );
};

export default MyNewChart;
