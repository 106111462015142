// // // Import the functions you need from the SDKs you need
// // import { initializeApp } from "firebase/app";
// // // TODO: Add SDKs for Firebase products that you want to use
// // // https://firebase.google.com/docs/web/setup#available-libraries
// // import { getFirestore } from "firebase/firestore";
// // // Your web app's Firebase configuration
// // const firebaseConfig = {
// //   apiKey: "AIzaSyDmLYhZVfqJwQFVjmbNecIVz3Tw0AfiEKY",
// //   authDomain: "react-chat-room123.firebaseapp.com",
// //   projectId: "react-chat-room123",
// //   storageBucket: "react-chat-room123.appspot.com",
// //   messagingSenderId: "456665753289",
// //   appId: "1:456665753289:web:aa8ad7283e24965ab94f18"
// // };

// // // Initialize Firebase
// // const app = initializeApp(firebaseConfig);
// // export const db = getFirestore(app);

// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// import { getFirestore } from "firebase/firestore";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyDZXe9t1hKGHT4S4VjP3f7lQReyv7-Eipw",
//   authDomain: "testtmo-8f356.firebaseapp.com",
//   projectId: "testtmo-8f356",
//   storageBucket: "testtmo-8f356.appspot.com",
//   messagingSenderId: "587658267063",
//   appId: "1:587658267063:web:7b1bf3c10bf224d09f4032",
//   measurementId: "G-297GEVZEWJ"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);


// export const db = getFirestore(app);




// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCeZ_cPQwv484qzcs_MgqvMqatjkVMIS3M",
  authDomain: "react-chat-room123.firebaseapp.com",
  projectId: "tmo-full-version",
  storageBucket: "tmo-full-version.appspot.com",
  messagingSenderId: "456665753289",
  appId: "1:19229608658:android:fd5e311d8cf8ddc45cfb0b"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);