import React, { useRef,useState } from 'react'
import MUIDataTable from "mui-datatables";
import { useEffect } from 'react';
import { Row, Card, Col,Dropdown, Button, Modal, Container } from "react-bootstrap";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { accountApi, addMarketApi, deleteAcccount, deleteMarket, getAllDropDown, getAllDropDownList, getMarket, pierTTGroupapi, timeZoneApi } from '../../../../common/config';
import { advancdExportOnecustomDataTableOptionObject, customDataTableOptionObject, getMuiThemeCommon } from "../../../../common/variables";

import { addMarketReducerAction,AccountReducerAction,OneAccountReducerAction, addAcccountReducerAction,deleteAcccountReducerAction, updateAcccountReducerAction, GetMarketReducerAction, popUpUpdate } from '../../../../store/actions/createTicketManageFields';
import { useDispatch,useSelector } from 'react-redux';
import { timeZones } from '../../../../common/variables';
import CustomeModal from '../../Custom/CustomeModal';
import RSSIVSWR from '../../Custom/Modal Body/RSSIVSWR';
import CommonModal from '../../Custom/CommonModal';
import SFP from '../../Custom/SFP';
import { MultiSelect } from "react-multi-select-component";
const ManagePierTT = () => {
    let accountArry =[]
    let timezoneArry =[]

    
    // let Options =useSelector((state)=>{
      
      
    // })

    // let accountOptions =useSelector((state)=>{
      
    //     let account=state.CommonResponse.accounts
    //     if(account !=0){
    //       accountArry=account.map((item)=>{
    //         return <option value={item.uniqueid}>{item.accountName}</option>
    //       })
    //     }
      
    //   return accountArry
    //   })
      // console.log("Account OPtion=",accountOptions)
      // console.log("All OPtion=",Options)

  const [errorss ,setError] =useState(false)
  const [errT ,setTerror] =useState(false)
   const [modalCentered, setModalCentered] = useState(false);
   const [closer, setCloser] = useState(false);
   const [dValue,setDvalue] =useState('')
   const [rowId,setRowId]=useState('')
   const [tmoDMGdata,settmoDMGdata]=useState([])
   const [checkAddUpdate,setAddUpdate] =useState(true)
   const [reqPreModal, setReqPreModal] = useState(false);
   const [rssiModal,setRssIModal] =useState(false)
   const [modalBody,setModalBody] =useState()
  //  const [newAccount,setAccount]=useState('')
      const newMarket=useRef()
      const accountRef =useRef()
      const timeZoneRef =useRef()
   let oneMarket=""
   
   const timeZonesOptions=timeZones.map((zones)=>{
    return <option value={zones}>{zones}</option>
   })

   let tmoDMG = [
    
   ];

   console.log("the stae is sbkbckjsdvcfv=",errorss)
  const tmoDMGHandler = (e) => {
    console.log("Pow multiple select=", e);
    settmoDMGdata(e);
    setTerror(false)
  };

   // setAccount(oneaccount)
   console.log(oneMarket,"oneaccount")
    const getMuiTheme = () => createTheme({
      components: {
        MUIDataTableBodyCell: {
          styleOverrides:{
            root: {
                backgroundColor: "transparent"
            }
          }
        }
      }
    })
    const updateHandler =(e)=>{
        console.log("button update clicked",e)

        dispatch(updateAcccountReducerAction(addMarketApi,e,{marketName:newMarket.current.value,account:accountRef.current.value,timezone :"63aaebbc1a9fe73c55656e23"}))

    }
    const deleteHandler =(e)=>{
      console.log("button delete clicked",e)
      dispatch(deleteAcccountReducerAction(pierTTGroupapi,e))
      setCloser(true)
      
    }
    
    const editpopupHandler =(e)=>{
     console.log("lkm kljnkjnjkbi;ki=",e)
      console.log("button editpopupHandler clicked",e)

      // dValue
      let arrarr=[]
      e.group.map((ittm)=>{
        
        arrarr.push({
          label:ittm,
          value:ittm
        })
        
      })
      settmoDMGdata(arrarr)
      setModalCentered(true)
 
    }
    const updateAccount =()=>{
      console.log("object id =",rowId)
      if(newMarket.current.value==""){
        setError(true)
        return null

      }
      else if(tmoDMGdata.length==0){
        setTerror(true)
        return null
      }
      let arr=[]
      tmoDMGdata.map((oneItm)=>{
        arr.push(oneItm.label)
      })

      dispatch(updateAcccountReducerAction(pierTTGroupapi,rowId,{groupName:newMarket.current.value,group:arr}))
      
      // setModalCentered(false)
          setCloser(true)
    }
    const addMarket=()=>{

      let arr=[]
      tmoDMGdata.map((oneItm)=>{
        arr.push(oneItm.label)
      })
      console.log(arr,newMarket.current.value,"newMarket.current.valuenewMarket.current.value")
      console.log("the currenty value is =",newMarket.current.value)
      if(newMarket.current.value==""){
        setError(true)
        return null

      }
      else if(arr.length==0){
        setTerror(true)
        return null
      }
      // setError(false)


        // console.log("Add Account=",newMarket.current.value," account value=",accountRef.current.value)
            // newAccount.current.value=''
          dispatch(addAcccountReducerAction(pierTTGroupapi,{groupName:newMarket.current.value,group:arr}))
          // setModalCentered(false)
          // newMarket.current.value=''

          // console.log("input field=",newMarket)
          // dispatch(GetMarketReducerAction(getMarket))
          // setModalCentered(false)
          setCloser(true)

    }
    const dispatch =useDispatch();
    let marketArry =[]

    let data =useSelector((state)=>{


      if (state.popUpState) {
        console.log(state.popUpState.popstatus.status,closer,"popUpStatestatus")
        if ((state.popUpState.popstatus.status == 201 && closer)||(state.popUpState.popstatus.status == 200 && closer)) {
          setCloser(false)
          settmoDMGdata([])
          dispatch(popUpUpdate({}))
          dispatch(AccountReducerAction(pierTTGroupapi))
          setModalCentered(false)
          
        }
      }

      let account=state.CommonResponse.accounts
      // if(account !=0){
        accountArry=[]
        console.log("lkjahdcliajgcjgayccwc= ",account)
        accountArry=account.map((item)=>{
          return <option value={item.uniqueid}>{item.accountName}</option>
        })
      // }

      tmoDMG=[]
      let timezone=state.CommonResponse.timeZones
      let singleDropDown=state.CommonResponse.singleDropDown

      singleDropDown.map((onesingle)=>{
        console.log(onesingle)
        tmoDMG.push({
          value:onesingle.optionValue,
          label:onesingle.optionValue
        })
      })
      // if(timezone !=0){
        timezoneArry=[]
        timezoneArry=timezone.map((item)=>{
          return <option value={item.uniqueid}>{item.zone}</option>
        })
      // }
    
        console.log("dfwef=",state)
        let availableMarkets=state.CommonResponse.markets
        if(availableMarkets.length !=0){
          oneMarket=availableMarkets[0].marketName
        }

        let markets=state.CommonResponse.pierTTGroup


        console.log("response =dscvfsfvd=",state.CommonResponse)
        if(markets.length !=0){
            marketArry=markets.map((item,index)=>{
            return {
                    sNo:index+1,
                    account :item.groupName,
                    market :item.group.join(", "),
                    action   :<><button className="btn btn-primary shadow btn-xs sharp mr-1 editButton" onClick={() =>{ editpopupHandler(item); setDvalue(item);setAddUpdate(false);setRowId(item.uniqueid)}}><i className="fa fa-pencil"></i></button>
                    <button onClick={(e)=>deleteHandler(item.uniqueid)} className="btn btn-danger shadow btn-xs sharp deleteButton"><i className="fa fa-trash"></i></button></>
                }
          })
        }
        
      
        console.log(marketArry)
      
      return {marketArry,accountArry,timezoneArry}
      })

      const noOfRow=data.marketArry.length
      // data.accountArry.push(
      //   {   
      //       sNo:noOfRow+1,
      //       account:<input type='text' placeholder='Add Account' value={newAccount} onChange={(e)=>setAccount(e.target.value)}/>,
      //       edit : <button onClick={()=>addAccount()}>Add Account</button>
            
      //   }
      // )

    console.log("data =",data)
    useEffect(()=>{
        dispatch(AccountReducerAction(getAllDropDownList))
        // dispatch(GetMarketReducerAction(getMarket))
        dispatch(AccountReducerAction(pierTTGroupapi))
        dispatch(AccountReducerAction(getAllDropDown+"/Group"))
        // dispatch(AccountReducerAction(timeZoneApi))
    },[])
    const columns = [
        {
      
         name: "sNo",
         label: "S.no",
         options: {
          filter: false,
          sort: false,
          searchable:false,
          setCellHeaderProps: () => ({
            style: {
                whiteSpace: "wrap",
                alignItem:"center",
              background:"#143b64",
              color:"white"
  
            }
          })
         }
        },
        {
      
          name: "account",
          label: "Group Assigned to TT in PIER",
          options: {
           filter: true,
           setCellHeaderProps: () => ({
             style: {
                 whiteSpace: "wrap",
                 alignItem:"center",
               background:"#143b64",
               color:"white"
   
             }
           })
          }
         },
        {
         name: "market",
         label: "T-Mobile Deployment Managers Group",
         options: {
          filter: true,
          setCellHeaderProps: () => ({
            style: {
                whiteSpace: "wrap",
                alignItem:"center",
              background:"#143b64",
              color:"white"
  
            }
          })
         }
        },{
          name: "action",
          label: "Action ",
          options: {
            filter: false,
            sort: false,
            print:false,
            searchable:false,
           setCellHeaderProps: () => ({
             style: {
                 whiteSpace: "wrap",
                 alignItem:"center",
                 width:"130px",
               background:"#143b64",
               color:"white"
   
             }
           })
          }
         },
       ];
       let row=[];
       const handleChange = (e) => {
        console.log("the current anser= ", e.target.value)
      }
    
      const AddCustomButton = () => (
        <fregment style={{ float: "right", display: "flex", flexDirection: row, justifyContent: "space-between" }}>
         <Button
          variant="primary btn-xs "
          type="button"
          className="btn btn-primary btn-xs mx-2 py-2 my-3 d-flex justify-content-center align-items-center"
          onClick={() =>{ setModalCentered(true);setDvalue('') ;setAddUpdate(true)}}>
            Add Pier TT Group
          </Button>
          <Modal className="fade" show={modalCentered}>
            <Modal.Header>
                {checkAddUpdate?<Modal.Title>Add Market</Modal.Title>:<Modal.Title>Update Market</Modal.Title>}
                <Button
                  onClick={() => setModalCentered(false)}
                  variant=""
                  className="close">
                <span>&times;</span>
                </Button>
                </Modal.Header>
                <Modal.Body>
                <div className="form-group col-md-12">
                  <label htmlFor="inputCity">Group Assigned to TT in PIER</label>
                  <input type='text' className='form-control' placeholder='Group Assigned to TT in PIER' ref={newMarket} defaultValue={dValue.groupName} onChange={()=>setError(false)}/>
                  <p className='error' style={{display:errorss?"block":"none"}}>Required Field </p>
                </div>  
                <div className="form-group col-md-12">
                  <label for="exampleFormControlSelect1">
                    T-Mobile Deployment Managers Group
                  </label>
                  <MultiSelect
                    options={tmoDMG}
                    onChange={tmoDMGHandler}
                    value={tmoDMGdata}
                  />
                  <p className='error' style={{display:errT?"block":"none"}}>Required Field </p>
                  {/* {errorlen > 0 && ttdata.length == 0 ? <p className="error">{customError.tTType}</p> : ""} */}
                </div>
                
                </Modal.Body>
                <Modal.Footer>
                  <Button
                  onClick={() => setModalCentered(false)}
                  variant="danger btn-xs"
                >
                  Close
                </Button>
                {checkAddUpdate?<Button onClick={()=>{addMarket()}} variant="primary btn-xs">Save changes</Button>
                  :<Button onClick={()=>{updateAccount()}} variant="primary btn-xs">Update changes</Button>  
              }
                </Modal.Footer>
            </Modal>
              
        </fregment>
      )
    //    const data = [
    //     { name: "Joe James", company: "In Progress", city: "Yonkers", state: "NY" },
    //     { name: "John Walsh", company: "Completed", city: "Hartford", state: "CT" },
    //     { name: "Bob Herm", company: "Awaited", city: "Tampa", state: "FL" },
    //     { name: "James Houston", company: "NA", city: "Dallas", state: "TX" },
    //    ];
       
       const options = {
        download:"false",
         filterType: 'none',
         selectableRows: false
       };
      
  return (
    <>

		  <ThemeProvider theme={getMuiThemeCommon()}>

        <MUIDataTable
        
           title={"Manage Pier TT"}
            data={data.marketArry}
            columns={columns}
            // options={customDataTableOptionObject(AddCustomButton,true,"Market")}
            options={advancdExportOnecustomDataTableOptionObject(AddCustomButton,true,"ManagePierTT_","",false,"Market Name")}
            
        />
      </ThemeProvider>
    </>
  )
}

export default ManagePierTT