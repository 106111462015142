import React, { useEffect, useState } from 'react'
import {
  GoogleMap,
  LoadScript,
  Marker,
  InfoWindowF,
} from "@react-google-maps/api";
import { center, currentUrl, google_map_api } from '../../../../common/variables';
import PlaceIcon from '@mui/icons-material/Place';
import { QrCode } from '@mui/icons-material';
import marker_image from "../../../../images/marker.png";
// "" '../../../../images';
import { useDispatch, useSelector } from 'react-redux';
import { store } from '../../../../store/store';

import './stylee.css'
import { UsInfowindow } from './infowinmemo';

const OneMapMarker = (props) => {

  console.log(props,"propsOneMapMarker")
  const [selected, setSelected] = useState({});
  
  
  const onSelect = item => {
    // console.log(item,"item clicker please show")
    setSelected(item);
    // <UsInfowindow></UsInfowindow>
    // setInfo(true)
  }

  function onCloseSelect(){
    // console.log(item,"item clicker please show")
    setSelected({});
    // setInfo(false)
  }


  // function callforcreate(onesite) {
  //   // console.log(onesite, onesite.data.length, "onesite.data")

  //   let abc=[]
  //   let interHtml

  //   // console.log(onesite.data,onesite,onesite.data.length == 1,"onesite.data.lengthonesite.data.length")
    
  //   if (onesite.data.length == 1) {
  //     console.log(onesite.data[0],onesite.data[0].ticket ? onesite.data[0].typeOfWork=="SI"?google_marker["blue"]:google_marker["yellow"] : google_marker["gray"],"onesite.data.lengthonesite.data.length")
  //     return 
  


  return(
      <>
      <Marker
          position={props.position}
          PinView={props.PinView}
          icon={props.icon}
          text="My Marker"
          title={props.title}
          description='bye'
          onClick={() => onSelect(props.onesite)}
          slug={"Current location"}
          name={"Current location"}>

        </Marker>

        {console.log(selected,"selectedselectedselected")}
        {
          selected ? selected.length > 0 ?
          selected[0].siteId &&
          (
            selected[0].latitude != undefined && selected[0].longitude != undefined ? 
            <UsInfowindow selected={selected}></UsInfowindow>
              : ""
          ):"":""
        }

      </>
    )
  //   } else {

  //     let valee=false
  //     let newvalee=0
  //     let newvaleetype=""

      
  //     onesite.data.map((ittww)=>{
  //       if(ittww.ticket){
  //         newvaleetype=ittww.typeOfWork
          
  //         newvalee=newvalee+1
  //       }
  //       if(ittww.typeOfWork=="NSI"){
  //         console.log(ittww,"ittww.ticketIdittww.ticketId")
  //       }
  //       if(ittww.ticket){
  //         valee=true
  //       }

  //     })

  //     return <>

  //     {/* {console.log(onesite.data,"onesite.data[0].latitude")} */}

      
  //       <Marker
  //         position={{ lat: onesite.data[0].latitude, lng: onesite.data[0].longitude, }}
  //         PinView={{ glyphColor: 'white', }}
  //         icon={{ url: valee ? (newvalee==1) ? newvaleetype=="SI" ? google_marker["blue"] : google_marker["yellow"] :google_marker["lightgreen"]:google_marker["gray"] }}
  //         text="My Marker"
  //         description='bye'
  //         onClick={() => onSelect(onesite)}
  //         slug={"Current location"}
  //         name={"Current location"}>

  //       </Marker>

  //       {/* {console.log(selected, "selected[0]")} */}
  //       {
  //         Info?selected && selected.data && selected.data[0].siteId &&
  //         (
  //           selected.data[0].latitude != undefined && selected.data[0].longitude != undefined ? <InfoWindowF
  //             position={{
  //               lat: selected.data[0].latitude,
  //               lng: selected.data[0].longitude,
  //             }}
  //             clickable={true}
  //             onCloseClick={() => onCloseSelect()}
  //           >{
  //               <>
  //                 <table>
  //                   <tr className='maptabletr'>
  //                     <th>Ticket Id</th>
  //                     <th>Site Id</th>
  //                     <th>Type of Work</th>
  //                     <th>Status</th>
  //                     <th>Location</th>
  //                   </tr>

  //                   {/* {console.log(selected.data, "selected.data")} */}

  //                   {
  //                     interHtml=selected.data.map((oneitm) => {
  //                       // console.log(abc,oneitm,"abcabc")
  //                       if(oneitm.ticket){
  //                         abc.push(oneitm.siteId)
  //                         return <>
  //                         <tr className='maptabletr'>
  //                           <td className='maptabletd'>{oneitm.ticket?oneitm.ticket:"NA"}</td>
  //                           <td className='maptabletd'>{oneitm.siteId}</td>
  //                           <td className='maptabletd'>{oneitm.typeOfWork?oneitm.typeOfWork:"NA"}</td>
  //                           <td className='maptabletd'>{oneitm.goNo?oneitm.goNo:"NA"}</td>
  //                           <td className='maptabletd'>{oneitm.address}</td>
  //                       </tr>
  //                       </>
  //                     }
  //                     })
  //                   }
  //                   {
  //                     interHtml=selected.data.map((oneitm) => {
  //                       // console.log(abc,oneitm,"abcabc")
  //                       if((abc.indexOf(oneitm.siteId)==-1)){
  //                         abc.push(oneitm.siteId)
  //                         return <>
  //                         <tr className='maptabletr'>
  //                           <td className='maptabletd'>{oneitm.ticket?oneitm.ticket:"NA"}</td>
  //                           <td className='maptabletd'>{oneitm.siteId}</td>
  //                           <td className='maptabletd'>{oneitm.typeOfWork?oneitm.typeOfWork:"NA"}</td>
  //                           <td className='maptabletd'>{oneitm.goNo?oneitm.goNo:"NA"}</td>
  //                           <td className='maptabletd'>{oneitm.address}</td>
  //                         </tr>
  //                         </>
  //                       }
  //                       })
  //                   }

  //                   {/* {interHtml} */}
  //                 </table>

  //               </>
  //             }
  //           </InfoWindowF>
  //             : ""
  //         ):""
  //       }

  //     </>

  //   }
  // }








  // return (
  //   <>
  //     <div>
  //       <LoadScript googleMapsApiKey={google_map_api}>
  //         <GoogleMap
  //           mapContainerStyle={containerStyle}

  //           center={center}
  //           zoom={8}
  //         >
  //           {
  //             sitesall.map((marker) => callforcreate(marker))
  //             //  => {
  //             //         callforcreate(onedata)
  //             //       })}
  //             //       {/* {marker.data[0].latitude != undefined && marker.data[0].longitude != undefined ?

  //             //          : ""} */}
  //             //     </>))
  //           }

  //           {/* {
  //             selected.projectCode &&
  //             (
  //               selected.latitude != undefined && selected.longitude != undefined ? <InfoWindow
  //                 position={{
  //                   lat: selected.latitude,
  //                   lng: selected.longitude,
  //                 }}
  //                 clickable={true}
  //                 onCloseClick={() => setSelected({})}
  //               >{
  //                   <>
  //                     <p className='text-black'>
  //                       Ticket Id: {selected.ticketId}<br />
  //                       Type of Work: {selected.typeOfWork}<br />
  //                       Site Id: {selected.siteId}<br />
  //                       Project Id: {selected.projectId}<br />
  //                       Project Code: {selected.projectCode}<br />
  //                       Status: <label>{selected.goNo}</label><br /></p>
  //                   </>
  //                 }
  //               </InfoWindow>
  //                 : ""
  //             )
  //           } */}

  //         </GoogleMap>
  //       </LoadScript>
  //     </div>
  //   </>
  // )


  return (<></>)
}

export default OneMapMarker