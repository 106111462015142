import React, { useState } from "react";

/// React router dom
import { Link } from "react-router-dom";


/// images
import logo from "../../../images/logo.png";
import mpag_logo from '../../../images/mpag_Logo.png'
import logoText from "../../../images/logo-text.png";
import { userDetails } from "../../../common/variables";

const NavHader = () => {
   
   const [toggle, setToggle] = useState(false);
   return (
      <div className="nav-header" style={{zIndex:450}} >


   {/* <Link to="/dashboard" className="brand-logo"> */}
            <img className="logo-abbr" src={mpag_logo} alt="" />
            {/* <img className="logo-compact" src={logoText} alt="" />
            <img className="brand-title" src={logoText} alt="" /> */}
         {/* </Link> */}

         <div className="nav-control"  onClick={() => setToggle(!toggle)}>
            <div className={`hamburger ${toggle ? "is-active" : ""}`}>
               <span className="line"></span>
               <span className="line"></span>
               <span className="line"></span>
            </div>
         </div>
      </div>
   );
};

export default NavHader;
