import React, { useEffect, useState } from 'react'
import { advancdExportOnecustomDataTableOptionObject, columnsArray, customDataTableOptionObject, getMuiThemeCommon } from '../../../common/variables'
import MUIDataTable from 'mui-datatables'
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link } from 'react-router-dom/cjs/react-router-dom';
import CommonModal from '../Custom/CommonModal';
import UpdateTraffic from '../Custom/Modal Body/UpdateTraffic';
import NNPBaseline from '../Custom/Modal Body/NNPBaseline';
import SSSCOP from '../Custom/Modal Body/SSSCOP';
import CommonBulkUploader from '../Custom/CommonBulkUploader';
import { LSSVApproverDashboardAPI, LSSVDashboardAPI, SSVDashboardAPI, baseURL, commonfilters, dynamic_dashboard, mergedlSSVApproverDashboardAPI, templatelssvurl } from '../../../common/config';
import { Button } from 'react-bootstrap';
import moment from "moment";
import { AccountReducerAction, ExportXLSXDownloadReducerAction, ResetLCLLSSVReducerAction, clearIxSingleSite, deleteAcccountReducerAction, getFilterDropDown, popUpUpdate } from '../../../store/actions/createTicketManageFields';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import FilterData from '../../../icons/Svg/FilterData';
import LSSVFilterList from '../Custom/Modal Body/LSSVFilterList';

import RefreshIcon from "@mui/icons-material/Refresh";
import DownloadNew from '../../../icons/Svg/DownloadNew';

const MergedLaunchSSSApproval = () => {

  const [fullUrL, setfullUrL] = useState("");
  const [exportApiData, setexportApiData] = useState("");
  const [filterBadges, setFilterBadge] = useState()

  const [ResetFilter, setResetFilter] = useState(false);
  const deleteHandler = (e, url) => {
    console.log("button delete clicked", e)

    swal({
      title: "Are you sure?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {
          console.log("the will dele=", willDelete)
          setcommonModalState(true)
          dispatch(deleteAcccountReducerAction(url, e))
          dispatch(AccountReducerAction(LSSVApproverDashboardAPI));
        }
      });
  }
  const dispatch = useDispatch();
  const [commonModalBody, setcommonModalBody] = useState("");
  const [commonModalState, setcommonModalState] = useState(false);
  const [commonModalHeader, setcommonModalHeader] = useState("");
  const [commonModalTitle, setcommonModalTitle] = useState("");
  const CommonModalFun = (body, title, header) => {
    setcommonModalBody(body);
    setcommonModalHeader(header);
    setcommonModalState(true);
    dispatch(clearIxSingleSite())
  };

  const options = useSelector((state) => {
    if (state.popUpState) {
      if (state.popUpState.popstatus.status == 201 && commonModalState) {
        setcommonModalState(false);
        dispatch(popUpUpdate({}));
        dispatch(AccountReducerAction(mergedlSSVApproverDashboardAPI))
      }
    }
    let sitesdata = state.CommonResponse.LaunchmergedApproverData
    if (sitesdata && sitesdata.length > 0) {
      console.log("kjcbjksbchcbjsver", sitesdata)
      sitesdata.map((item) => {

        
        item["SSSCOPSubmitDateFlag"] = item["SSSCOPSubmitDate"]


        item["SSSCOPApprovedBy"]=item?.approverresult?.name



        item["ssvFileUploadmodal"] =
          <div className=' d-flex justify-content-evenly'>
            {item.LSSVFileUpload ?
              <a href={baseURL + "/uploads/" + item.LSSVFileUpload} className="" download><DownloadNew /></a>
              : ""
            }
          </div>
        
        item["SSSCOPStageUpdate"] = <p

          className='badge mx-auto badge-success'
          onClick={() =>
            CommonModalFun(
              // <LockUnlockModalBody apiType={"request"} oldData={[]} siteIdData={item.siteId} ranIdData={item.ranSupportId} />
              <SSSCOP valueFrom={item.dashboardType=="LSSV Dashboard"?"SSS":"SSV"} from={item.dashboardType=="LSSV Dashboard"?"lssv":"ssv"} id={item.ssvUniqueId} />
              ,
              "Update",
              "Update"
            )
          }
        >
          Update
        </p>
        if (item["projectUniqueId"] && item["projectUniqueId"].length > 0) {
          let projectUniqueIdFlag = []
          item["projectUniqueId"].map((itm) => {
            projectUniqueIdFlag.push(itm.projectId)
          })
          let planTypeData = []
          item["projectUniqueId"].map((itm) => {
            planTypeData.push(itm.projectCode)
          })

          item["projectUniqueIdFlag"] = projectUniqueIdFlag.join(", ")
          item["RingCode"] = item["projectUniqueId"][0].RingCode
          item["region"] = item["projectUniqueId"][0].region
          item["marketFlag"] = item["projectUniqueId"][0].market
          item["vendor"] = item["projectUniqueId"][0].HardCostVendor
          item["projectType"] = item["projectUniqueId"][0].projectType
          item["planType"] = planTypeData.join(", ")

        }
        
        return item
      })

    }
    return { sitesdata }
  })


  const exportAllData = () => {
    dispatch(ExportXLSXDownloadReducerAction(templatelssvurl, "SSVTemplate_" + moment(new Date()).format("MM-DD-YYYY")))
  }

  function filterhandler() {

    dispatch(getFilterDropDown([]))
    // dispatch(AccountReducerAction(ssvfilters))
    dispatch(AccountReducerAction(commonfilters))
    setcommonModalState(true)
    // setcommonModalSize("xl")
    setcommonModalBody(<LSSVFilterList setfullUrL={setfullUrL} setcommonModalState={setcommonModalState} setFilterBadge={setFilterBadge} featureExport={setexportApiData} />)
    setcommonModalTitle("LSSV Filters")
    setcommonModalHeader("LSSV Filter")
  }
  const ClearFilter = () => {
    // alert("ClearFilter")
    console.log("ClearFilter", "ClearFilter");
    // setfstartdate();
    // setfenddate();
    setResetFilter(true)

    setFilterBadge()
    let Filleddata = dispatch(ResetLCLLSSVReducerAction())
    dispatch(AccountReducerAction(LSSVApproverDashboardAPI + Filleddata, true));

    // dispatch(AccountReducerAction(listennoTicketApi+Filleddata,true,true));
  };

  let AddTicket = () => (
    <>
      {/* <p onClick={filterhandler} className=" mx-2 " style={{ "align-self": "center" }}><FilterData /></p>
      <button
        className="btn btn-xs btn-primary mx-2 my-3"
        data-toggle="tooltip"
        data-placement="bottom"
        title="Refresh"
        onClick={() => { ClearFilter(); }}
        style={{ padding: "6px" }}
      >
        <RefreshIcon />
      </button>
      <Button onClick={exportAllData} className="btn btn-xs btn-primary mx-2 py-2">
        Template
      </Button>
      <Link className="btn btn-xs btn-primary mx-2 my-3 py-2" to={"/PAG/launch/ssv"} onClick={() => dispatch(clearIxSingleSite())}>Add Launch SSS & NNP</Link>
      <CommonBulkUploader styles={{ marginBottom: "1rem" }} typeForm={"Launch SSS Upload File"} shortCode={"Bulk_LSSV"} afterLoad={"/launchSSSNNP" + dynamic_dashboard} classes={'btn btn-xs btn-primary mx-2 my-3 py-2'} /> */}
    </>
  )
  console.log("the response columns is =", columnsArray("Region	Market	Site ID	Ring ID	Plan Type	Project ID	HC Vendor	GC	Launch Eng	SSS & NNP Eng	RTT Date	NCDO and ANR SON Date	Unlock Date	Traffic check	RTWP check - all layers	Key KPIs check	Launch Date	Launch Status	Site health Check Date	Comments - Launch MS6	NNP Baseline Reference Date	NNP 4G Status	Overall NNP 5G Status	Count to Market Avg	SSS COP Submit Date	SSS COP Approval Date	SSS COP Status	Comments - NNP & SSS (Date, issue & action item)	Assign/PO Date	Project Type"))
  let columns = [
    {
      "name": "siteId",
      "label": "Site ID",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {

            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            left: "0px",
            color: "white",
            position: "sticky",
            zIndex: 101,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 100,
            left: "0px",
          },
        }),
      }
    },
    {
      "name": "planType",
      "label": "Plan Type",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "projectUniqueIdFlag",
      "label": "Project ID",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "LssvSubmitDate",
      "label": "SSS COP Submit Date",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        print: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    
    {
      "name": "ssvRttDate",
      "label": "RTT Date",
      "sortable": false,
      "options": {
      filter: true,
      className: "abcd",
      setCellHeaderProps: () => ({
        style: {
          whiteSpace: "wrap",
          alignItem: "center",
          padding: "1px 1px",
          background: "#143b64",
          minWidth: "140px",
          color: "white",
          position: "sticky",
          zIndex: 100,
          outline: "1.5px solid black"
        },
      }),
      setCellProps: () => ({
        style: {
          whiteSpace: "wrap",
          overflowY: "hidden",
          minWidth: "140px",
          padding: "1px 1px",
          position: "sticky",
          background: "White",
          zIndex: 99,
        },
      }),
      }
  },
  {
    "name": "LUUUDate",
    "label": "UUU Date",
    "sortable": false,
    "options": {
    filter: true,
    className: "abcd",
    setCellHeaderProps: () => ({
      style: {
        whiteSpace: "wrap",
        alignItem: "center",
        padding: "1px 1px",
        background: "#143b64",
        minWidth: "140px",
        color: "white",
        position: "sticky",
        zIndex: 100,
        outline: "1.5px solid black"
      },
    }),
    setCellProps: () => ({
      style: {
        whiteSpace: "wrap",
        overflowY: "hidden",
        minWidth: "140px",
        padding: "1px 1px",
        position: "sticky",
        background: "White",
        zIndex: 99,
      },
    }),
    }
},
    {
      "name": "SSSCOPApprovalDate",
      "label": "SSS COP Approval Date",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "SSSCOPApprovedBy",
      "label": "SSS COP Approval By",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },

    
    
    {
      "name": "SSSCOPStatus",
      "label": "SSS COP Status",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
    {
      "name": "ssvFileUploadmodal",
      "label": "SSS COP File",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "140px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },

    
    {
      "name": "RemarksLSSVSSSCOPStatus",
      "label": "Remarks",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "240px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "140px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },{
      "name": "dashboardType",
      "label": "Dashboard Type",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "120px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "120px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },{
      "name": "SSSCOPStageUpdate",
      "label": "Update",
      "sortable": false,
      "options": {
        filter: true,
        className: "abcd",
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "wrap",
            alignItem: "center",
            padding: "1px 1px",
            background: "#143b64",
            minWidth: "120px",
            color: "white",
            position: "sticky",
            zIndex: 100,
            outline: "1.5px solid black"
          },
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: "wrap",
            overflowY: "hidden",
            minWidth: "120px",
            padding: "1px 1px",
            position: "sticky",
            background: "White",
            zIndex: 99,
          },
        }),
      }
    },
  ]
  

  useEffect(() => {
    dispatch(AccountReducerAction(mergedlSSVApproverDashboardAPI))
  }, [])
  return (
    <div>
      <CommonModal
        modalSize={"xm"}
        setXlShow={setcommonModalState}
        xlShow={commonModalState}
        Body={commonModalBody}
        Header={commonModalHeader}
      ></CommonModal>
      <ThemeProvider theme={getMuiThemeCommon()}>
        <MUIDataTable
          data={options.sitesdata}
          columns={columns}
          options={advancdExportOnecustomDataTableOptionObject(AddTicket, true, "ranDashbord",)}
        />
      </ThemeProvider>
    </div>
  )
}

export default MergedLaunchSSSApproval